import React from 'react';
import { EstatePriceHistory } from '~/types/estate.types';
import { Icon, List, Popup } from 'semantic-ui-react';
import estateL10n from '~/L10n/estate.L10n';

import Time from '~/components/Base/Time';

const PriceHistoryPopup = ({ priceHistory }: { priceHistory: EstatePriceHistory[] }) => {
    const priceLength = priceHistory ? priceHistory.length : 0;
    const priceGrewUp = priceLength > 1 && priceHistory[priceLength - 1].price < priceHistory[priceLength - 2].price;

    if (priceLength < 2) {
        return null;
    }

    return (
        <Popup
            position="bottom center"
            size="tiny"
            trigger={<Icon size="small" name={priceGrewUp ? 'arrow up' : 'arrow down'} color={priceGrewUp ? 'green' : 'red'} />}
            content={
                <List>
                    {priceHistory.map(({ price, currency, time }) => (
                        <List.Item key={time}>
                            <List.Content>
                                <List.Header>
                                    {price.toLocaleString()}
                                    {estateL10n.CURRENCY_TYPES_FIND(currency)}
                                </List.Header>
                                <List.Description>
                                    <Time time={time} />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            }
            style={{ maxHeight: '300px', overflowY: 'auto' }}
            hoverable
        />
    );
};

export default React.memo(PriceHistoryPopup);
