import { observable, action, runInAction } from 'mobx';
import debounce from '../common/debounce';
import { ITEM_TYPES } from '~/types/notifications.types';
import * as dadataApi from '~/api/dadataApi';
import { withAbortController } from './helpers/decorators.helpers';
import commonStore from './commonStore';

export type SearchModuleItem = {
    key: number | string;
    title: string;
    description: string;
    image?: string | null;
    price?: string;
    state: any;
    backgroundColor?: string;
    className?: string;
};

export type SearchModuleResult = { list: SearchModuleItem[]; count: number };

type GLOBAL_SEARCH_MODULES = ITEM_TYPES | 'dadata';

export type SearchModuleType = {
    moduleName: GLOBAL_SEARCH_MODULES;
    title: string;
    search: (abortController: AbortController | null, query: string, start: number, limit: number) => Promise<SearchModuleResult>;
};

export const GLOBAL_SEARCH_LIMIT = 5;
export const GLOBAL_SEARCH_EXPANDED_LIMIT = 15;

export const DADATA_MODULE_NAME = 'dadata';

const LOCAL_STORAGE_SELECTED_KEY = 'searchSelectedResults';

class GlobalSearchStore {
    constructor() {
        if (!commonStore.isMobile) {
            this.regModule({
                title: 'Адреса ',
                moduleName: DADATA_MODULE_NAME,
                search: this.dadataAddressSearchList
            });
        }

        const selectedResults = localStorage.getItem(LOCAL_STORAGE_SELECTED_KEY);
        if (selectedResults) {
            this.selectedResults = JSON.parse(selectedResults);
        }
    }

    @observable
    loadingLists = false;

    @observable
    open = false;
    blockHide = false;

    @action
    show() {
        this.open = true;
    }

    @action
    hide() {
        if (!this.blockHide) {
            this.open = false;
        }
        this.blockHide = false;
    }

    @observable
    results: any = {};

    searchModules: Array<SearchModuleType> = [];

    regModule(searchModule: SearchModuleType) {
        this.searchModules.push(searchModule);
    }

    strictModule: GLOBAL_SEARCH_MODULES | null = null;

    @action
    setStrictModule = async (moduleName: GLOBAL_SEARCH_MODULES) => {
        if (this.strictModule && this.strictModule !== DADATA_MODULE_NAME) {
            this.startPosition += GLOBAL_SEARCH_EXPANDED_LIMIT;
        } else {
            this.strictModule = moduleName;
        }

        this.blockHide = true;
        await this.fetchSearchData(this.searchQuery);
        this.blockHide = false;
    };

    @observable
    searchQuery: string = '';
    @action
    setSearchQuery(query: string) {
        this.searchQuery = query;
    }

    search(query: string) {
        this.searchQuery = query;
        if (query) {
            this.debounceSearch();
        } else {
            this.resetSearch();
        }
    }

    startPosition = 0;

    debounceSearch = debounce(() => {
        this.fetchSearchData(this.searchQuery);
    }, 650);

    fetchSearchesAC: AbortController | null = null;

    @withAbortController('fetchSearchesAC')
    @action
    async fetchSearchData(query: string) {
        this.loadingLists = true;

        await Promise.all(
            this.searchModules.map(async ({ title, moduleName, search }, index) => {
                if (this.strictModule === DADATA_MODULE_NAME && !['estate', 'owner', DADATA_MODULE_NAME].includes(moduleName)) {
                    delete this.results[index];
                    return null;
                }

                try {
                    const { list, count } = await search(
                        this.fetchSearchesAC,
                        query,
                        this.startPosition,
                        this.strictModule ? GLOBAL_SEARCH_EXPANDED_LIMIT : GLOBAL_SEARCH_LIMIT
                    );

                    if (count > 0) {
                        this.results[index] = {
                            modulename: moduleName,
                            title,
                            count,
                            key: title,
                            results: list,
                            from: this.startPosition
                        };
                    } else {
                        delete this.results[index];
                    }
                } catch (e) {
                    console.log(e);
                }
            })
        );

        this.loadingLists = false;
    }

    @observable
    selectedResults: any[] = [];

    @action
    addSelectedResult(result: any) {
        const foundIndex = this.selectedResults.findIndex(({ key, className }) => result.key === key && result.className === className);
        if (~foundIndex) {
            this.selectedResults.splice(foundIndex, 1);
        }
        this.selectedResults.unshift(result);
        this.selectedResults = this.selectedResults.slice(0, 10);
        localStorage.setItem(LOCAL_STORAGE_SELECTED_KEY, JSON.stringify(this.selectedResults));
    }

    @action
    resetSearch() {
        this.searchQuery = '';
        this.startPosition = 0;
        this.strictModule = null;
        this.results = {};
        this.blockHide = false;
    }

    dadataAddressSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        try {
            const addresses = await dadataApi.fetchAddressSuggestions(query, 3);

            return {
                list: addresses.map(({ value }) => ({
                    key: value,
                    title: value,
                    description: value,
                    state: null
                })),
                count: addresses.length
            };
        } catch (e) {
            return {
                list: [],
                count: 0
            };
        }
    };
}

export default new GlobalSearchStore();
