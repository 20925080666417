import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Statistic } from 'semantic-ui-react';

import userStore from '~/stores/userStore';
import DatePicker from '~ui/DatePicker';
import ConfirmTrigger from '~ui/ConfirmTrigger';
import Button from '~ui/Button';
import Progress from '~ui/Progress';
import Grid from '~ui/Grid';
import { TableCell, TableRow } from '~ui/Table';

const BalanceResetToDateRow = ({ user_id }: { user_id: number }) => {
    const [fromTime, setFromTime] = useState<null | number>(null);

    const handleResetByTime = async () => {
        await userStore.resetBalance(user_id, fromTime);
    };

    const handleBalanceTime = (fromTime: number) => {
        userStore.fetchUserBalanceToTime(user_id, fromTime);
        setFromTime(fromTime);
    };

    const { userBalanceToTime, loadingUserBalance, userBalanceInfo } = userStore.getItem(user_id).property || {};

    return (
        <TableRow>
            <TableCell />
            <TableCell>
                <DatePicker
                    label="Дата"
                    minDate={userBalanceInfo?.lastRedeemTime}
                    maxDate={Date.now()}
                    value={fromTime}
                    onChange={handleBalanceTime}
                />
            </TableCell>
            <TableCell>
                <Grid columns={2} spacing={0} justify="space-between">
                    <Grid.Column>
                        {fromTime && (
                            <Statistic size="mini">
                                <Statistic.Label>К списанию</Statistic.Label>
                                <Statistic.Value>{userBalanceToTime < 0 ? `${userBalanceToTime.toLocaleString()}` : '0'} ₽</Statistic.Value>
                            </Statistic>
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        <ConfirmTrigger
                            trigger={
                                <Button size="small" color="secondary" variant="contained" disabled={loadingUserBalance}>
                                    Списать
                                </Button>
                            }
                            content={`Спишется ${userBalanceToTime || 0} рублей`}
                            header="Списать на определенную дату?"
                            confirmButton="Списать"
                            onConfirm={handleResetByTime}
                        />
                    </Grid.Column>
                    <Progress show={loadingUserBalance} size={64} />
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default observer(BalanceResetToDateRow);
