import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { Theme, makeStyles, withStyles, createStyles } from '@material-ui/core/styles';

const SmallAvatar = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 24,
            height: 24,
            border: `2px solid ${theme.palette.background.paper}`
        }
    })
)(Avatar);

type TwoAvatarsProps = {
    mainAvatarUrl: string;
    mainTitle?: string;
    secondAvatarUrl: string;
    secondTitle?: string;
};

const TwoAvatars = ({ mainAvatarUrl, secondAvatarUrl, mainTitle, secondTitle }: TwoAvatarsProps) => (
    <Badge
        overlap="circle"
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        badgeContent={<SmallAvatar alt={secondTitle} src={secondAvatarUrl} />}
    >
        <Avatar alt={mainTitle} src={mainAvatarUrl} />
    </Badge>
);

export default React.memo(TwoAvatars);
