import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import * as cellCallsApi from '~/api/cell/cellCallsApi';

import Progress from '~ui/Progress';
import CallEstateEditingMode from '~/components/Items/CallEstate/CallEstateEditingMode';
import { CellCall, CONTACT_PHONE_TYPE_ID } from '~/types/cellCalls.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ListError from '~/components/ListError';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MLS } from '~/types/estate.types';

const CallEstate = ({ match }: RouteComponentProps<{ phone: string }>) => {
    const { phone } = match.params;

    const [call, setCall] = useState<CellCall | null>(null);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        cellCallsApi
            .fetchLastCellCallByPhone(phone)
            .then(call => {
                setCall(call);
            })
            .catch(setErrors);
    }, [phone]);

    return (
        <div style={{ padding: '1rem 2rem' }}>
            {errors && <ListError errors={errors} />}
            {!call && !errors.length && <Progress show />}
            {call && (
                <CallEstateEditingMode
                    item_id={call.call_id}
                    contact_id={call.type === CONTACT_PHONE_TYPE_ID ? call.item_id || CREATING_ITEM_ID : CREATING_ITEM_ID}
                    goToContactOnCreate
                    defaultBase={ESTATE_BASE_MAIN}
                />
            )}
        </div>
    );
};

export default withRouter(observer(CallEstate));
