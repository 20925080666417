import React, { Fragment, useState } from 'react';
import { Button as ButtonSemantic } from 'semantic-ui-react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

import ownerStore from '~/stores/ownerStore';
import { Modal, ModalHeader, ModalActions, ModalContent } from '../../../Base/ui/Modal';
import { NumberInput } from '~ui/TextInput';
import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';

type OwnerIsAlreadySoldButtonProps = {
    estate_id: number;
    disabled: boolean;
};

const OwnerIsAlreadySoldButton = ({ estate_id, disabled }: OwnerIsAlreadySoldButtonProps) => {
    const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);
    const handleClickBtn = () => {
        setConfirmationPopupIsOpen(!confirmationPopupIsOpen);
    };

    const { price } = estateStore.getItem(estate_id).item;
    const [soldPrice, setSoldPrice] = useState(price);
    const handleChangePrice = (event: React.ChangeEvent, { value }: { value: number }) => {
        setSoldPrice(value);
    };

    const [toggleConfirmPrice, setToggleConfirmPrice] = useState(true);
    const handleToggleConfirmPrice = () => {
        setToggleConfirmPrice(!toggleConfirmPrice);
    };

    const handleToggleIsAlreadySold = () => {
        ownerStore.toggleIsAlreadySold(estate_id, toggleConfirmPrice && soldPrice ? soldPrice : null);
        setConfirmationPopupIsOpen(false);
    };

    return (
        <Fragment>
            <ButtonSemantic
                fluid
                basic
                content="Уже продан"
                labelPosition="left"
                icon="remove"
                size="tiny"
                color="red"
                onClick={handleClickBtn}
                disabled={disabled}
                title="Эта кнопка активируется после вашего звонка на номер собственника"
            />

            {confirmationPopupIsOpen && (
                <Modal onClose={handleClickBtn} fullScreen={commonStore.isMobile} maxWidth="sm">
                    <ModalHeader>Объект уже продан</ModalHeader>

                    <ModalContent dividers>
                        <p>
                            <b>Собственник подтвердил, что уже продал объект?</b>
                        </p>
                        <p>
                            Если вы хотите удалить Собственника по любой другой причине, то можно воспользоваться другими кнопками "удалить"
                            в карточке данного Собственника.
                        </p>

                        <div style={{ display: 'flex', justifyContent: 'space-around', flexFlow: 'row wrap' }}>
                            <NumberInput
                                label="За какую цену был продан?"
                                value={soldPrice}
                                min={0}
                                onChange={handleChangePrice}
                                fullWidth={false}
                                extraLabel="руб."
                                disabled={!toggleConfirmPrice}
                            />

                            <FormControlLabel
                                control={<Checkbox checked={toggleConfirmPrice} onChange={handleToggleConfirmPrice} />}
                                label="Я уточнил(а) цену продажи"
                            />
                        </div>
                    </ModalContent>

                    <ModalActions>
                        <Button onClick={handleClickBtn} color="secondary">
                            Отменить
                        </Button>
                        <div style={{ position: 'relative' }}>
                            <Button variant="outlined" onClick={handleToggleIsAlreadySold} color="primary">
                                Подтверждаю, Продан
                            </Button>
                        </div>
                    </ModalActions>
                </Modal>
            )}
        </Fragment>
    );
};

export default OwnerIsAlreadySoldButton;
