import React, { useState, useEffect, SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '~ui/TextInput';

import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import fetchApi from '~/common/fetchApi';

import { isCorrectPassport } from './PassportNumberInput';
import { objectToGraphql } from '~/common/graphql';

type FindInnData = {
    surname: string;
    name: string;
    patronymic: string;
    birthday: string;
    passport: string;
    issued_at: string;
};

const findPersonalInn = async (passportData: FindInnData): Promise<string> => {
    const graphql = `{
        findPersonalInn(passportData: ${objectToGraphql(passportData)})
      }`;

    const data = await fetchApi.getGQ<{ findPersonalInn: string }>(graphql);
    return data.findPersonalInn;
};

const useStyles = makeStyles(({ palette }) => ({
    success: {
        color: palette.primary.main,
        fontWeight: 500
    }
}));

type InnInputTypeProps = {
    item_id: number;
    surname: string;
    name: string;
    patronymic: string;
    tax_number: string;
    series: string;
    number: string;
    birthday: string;
    issued_at: string;
};

const InnInputType = (props: InnInputTypeProps) => {
    const { item_id, issued_at, birthday, name, number, tax_number, patronymic, series, surname } = props;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [inn, setInn] = useState('-');

    useEffect(() => {
        if (surname?.trim() && name?.trim() && patronymic?.trim() && issued_at && birthday && isCorrectPassport(series, number)) {
            setLoading(true);
            findPersonalInn({ birthday, issued_at, name, surname, patronymic, passport: `${series}${number}` })
                .then(inn => {
                    setInn(inn);
                    mortgagePersonStore.setEditingItem(item_id, { tax_number: inn });
                })
                .finally(() => setLoading(false));
        }
    }, [surname, name, issued_at, patronymic, series, number]);

    const handleChange = (event: SyntheticEvent, { value }: { value? }) => {
        mortgagePersonStore.setEditingItem(item_id, { tax_number: value });
    };

    const helperText = inn === tax_number ? <span className={classes.success}>ИНН найден в базе Налоговой</span> : '10 или 12 цифр';

    return (
        <TextInput
            label="Инн"
            helperText={helperText}
            pattern={`^([0-9]{10}|[0-9]{12})$`}
            mask="000000000000"
            value={tax_number}
            name="tax_number"
            onChange={handleChange}
            loading={loading}
        />
    );
};

export default React.memo(InnInputType);
