import React, { ReactNode, CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import MUITable from '@material-ui/core/Table';
import TableBodyMUI from '@material-ui/core/TableBody';
import TableCellMUI, { TableCellProps, SortDirection as SortDirectionUI } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHeadMUI from '@material-ui/core/TableHead';
import TableRowMUI, { TableRowProps } from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooterMUI from '@material-ui/core/TableFooter';

export type SortDirection = SortDirectionUI;

import commonStore from '~/stores/commonStore';

export const TableHead = TableHeadMUI;
export const TableBody = TableBodyMUI;
export const TableFooter = TableFooterMUI;

const useStylesTableRow = makeStyles(({ palette, spacing }) => ({
    error: {
        background: '#fff6f6'
    },
    head: {
        background: '#f9fafb'
    },
    sizeSmall: {
        paddingTop: spacing(0.5),
        paddingBottom: spacing(0.5)
    }
}));

export const TableRow = (props: TableRowProps & { negative?: boolean }) => {
    const classes = useStylesTableRow();
    const { children, negative, ...otherProps } = props;

    return (
        <TableRowMUI
            {...otherProps}
            classes={
                negative
                    ? {
                          root: classes.error
                      }
                    : {
                          head: classes.head,
                          footer: classes.head
                      }
            }
        >
            {children}
        </TableRowMUI>
    );
};

const useStylesTableCell = makeStyles(({ palette, spacing }) => ({
    sizeSmall: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1)
    },
    paddingNone: {
        padding: spacing(0.75, 3, 0.75, 2),
        '&:last-child': {
            padding: spacing(0.75, 2)
        }
    }
}));

const useStylesTableCellNegative = makeStyles(({ palette, spacing }) => ({
    root: {
        background: '#fff6f6'
    }
}));

export const TableCell = (props: TableCellProps & { negative?: boolean }) => {
    const classes = useStylesTableCell();
    const classesNegative = useStylesTableCellNegative();
    const { sortDirection, children, onClick, negative, ...otherProps } = props;

    return (
        // <TableCellMUI {...props} style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}>

        <TableCellMUI {...otherProps} classes={{ ...classes, ...(negative ? classesNegative : {}) }}>
            {sortDirection === undefined && children}
            {sortDirection !== undefined && (
                <TableSortLabel active={sortDirection !== false} direction={sortDirection || 'asc'} onClick={onClick}>
                    {children}
                </TableSortLabel>
            )}
        </TableCellMUI>
    );
};

type TableProps = {
    children?: ReactNode;
    size?: 'small' | 'medium';
    maxHeight?: string | number;
    compact?: boolean;
    style?: CSSProperties;
    elevation?: number;
};

export const Table = (props: TableProps) => {
    const { children, size, maxHeight, compact, style = {}, elevation = 1 } = props;

    let sizeAdjusted = size || (commonStore.isMobile ? 'medium' : 'small');

    return (
        <TableContainer component={Paper} elevation={elevation} style={{ maxHeight: maxHeight || null, ...style }}>
            <MUITable stickyHeader={Boolean(maxHeight)} size={sizeAdjusted} padding={compact ? 'none' : undefined}>
                {children}
            </MUITable>
        </TableContainer>
    );
};

export default Table;
