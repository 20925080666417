import React from 'react';
import wishStore from '~/stores/wishStore';
import { DELETING_CAUSE_IS_REALTOR } from '~/types/wish.types';
import Button from '~ui/Button';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

type WishContactIsRealtorButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishContactIsRealtorButton = ({ wish_id, disabled }: WishContactIsRealtorButtonProps) => {
    const handleToggleIsRealtor = () => {
        wishStore.deleteWishWithCause(wish_id, DELETING_CAUSE_IS_REALTOR);
    };

    return (
        <Button
            fullWidth
            startIcon={<EmojiPeopleIcon htmlColor="white" />}
            variant="outlined"
            onClick={handleToggleIsRealtor}
            disabled={disabled}
            style={{ background: '#fbbd08', color: 'white' }}
        >
            Это Риелтор
        </Button>
    );
};

export default WishContactIsRealtorButton;
