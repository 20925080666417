import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const LoadingItemPreviewContent = () => (
    <div style={{ minWidth: '240px' }}>
        <Skeleton variant="rect" height={118} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
    </div>
);

export default React.memo(LoadingItemPreviewContent);
