import React, { ReactNode, useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { fetchExportStatisticsByExportBase } from '~/api/export/exportStatisticsApi';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Modal, { ModalContent } from '~ui/Modal';
import exportStatisticsStore from '~/stores/export/exportStatisticsStore';
import { EstateStatInExportBase } from '~/types/exportStatistics.types';
import EstateLinkWithPreview from '~/components/Lists/Estate/EstateLinkWithPreview';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';
import { printTableNumber } from '~/components/Items/Estate/SubBlocks/StatisticsMode/EstateStatisticsTable';
import estateL10n from '~/L10n/estate.L10n';
import commonStore from '~/stores/commonStore';
import Progress from '~ui/Progress';
import ListError from '~/components/ListError';

const StatisticsByExportBaseTable = ({ export_base_id }: { export_base_id: number }) => {
    const [statData, setStatData] = useState<EstateStatInExportBase[]>([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        fetchExportStatisticsByExportBase(export_base_id, exportStatisticsStore.listFilter)
            .then(data => {
                setStatData(data);
            })
            .catch(errors => {
                setErrors(errors);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Progress show={loading} size={54} />
            <ListError errors={errors} />
            <TableContainer>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Объект</TableCell>
                            <TableCell align="right">Дней</TableCell>
                            <TableCell align="right">Расход</TableCell>
                            <TableCell align="left">Ответсыенный(е)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statData.map(({ daysCount, estate, estate_id, major_user_ids, major_users, totalCost }, index) => (
                            <TableRow key={estate_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <EstateLinkWithPreview estate={estate} />
                                </TableCell>
                                <TableCell align="right">{daysCount}</TableCell>
                                <TableCell align="right">{printTableNumber(totalCost, estateL10n.CURRENCY_TYPES_FIND(1))}</TableCell>
                                <TableCell align="left">
                                    <UsersLinkList usersList={major_users} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const StatisticsByExportBaseModal = ({ value, export_base_id }: { value: string; export_base_id: number }) => {
    return (
        <Modal
            header="Объекты в экспорте"
            maxWidth="md"
            fullScreen={commonStore.isMobile}
            trigger={
                <Link component="button" variant="body2">
                    {value}
                </Link>
            }
        >
            <ModalContent>
                <StatisticsByExportBaseTable export_base_id={export_base_id} />
            </ModalContent>
        </Modal>
    );
};

export default StatisticsByExportBaseModal;
