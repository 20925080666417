import { observable, action, computed, runInAction } from 'mobx';
import * as statisticsApi from '~/api/statisticsApi';
import {
    UserStatistics,
    UserStatisticsTable,
    UserStatisticsFilter,
    ChartStatistics,
    STAT_CHART_TYPES,
    ChartGlobalStatistics,
    UserKpi
} from '~/types/statistics.types';
import { DAILY_CALLS_KEY } from '~/types/statistics.types';
import ListStorePrototype from './prototypes/ListStore.prototype';
import { OrderDirectionType } from './prototypes/ListStore.prototype';
import { CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK, calendarItemTypes } from '~/types/calendar.types';
import { CALENDAR_VIEW_TYPES } from '~/types/calendar.types';

type DealPropertyType = {
    loadingChart: boolean;
    chartData: ChartStatistics[];
};

export type ChartType = {
    active: boolean;
    key: string;
    color: string;
    title: string;
};

export const STAT_MODE_TABLE = 'table';
export const STAT_MODE_CHART_DAY = 'chartDay';
export const STAT_MODE_CHART_WEEK = 'chartWeek';
export const STAT_MODE_CHART_MONTH = 'chartMonth';
export const STAT_MODE_KPI = 'kpi';

export type StatModeType = 'chartDay' | 'table' | 'chartWeek' | 'chartMonth' | 'kpi';

// @ts-ignore
class StatisticStore extends ListStorePrototype<UserStatistics, UserStatisticsTable, DealPropertyType, UserStatisticsFilter> {
    listFilterClear = {
        group_id: [],
        major_user_id: []
    };

    constructor() {
        super('user_id', 'statistics', statisticsApi);
        this.clearFilter();
    }

    orderBy = DAILY_CALLS_KEY;

    group_id = -1;

    @observable
    allCharts: ChartType[] = [
        { active: true, key: 'callsOutgoingCount', color: '#8884d8', title: 'Исходящие' },
        { active: true, key: 'callsIncomingCount', color: '#f03825', title: 'Входящие' },
        { active: true, key: 'openDealsCount', color: '#82ca9d', title: 'Сделки' },
        { active: true, key: 'cianCount', color: '#ff7300', title: 'Экспорт ЦИАН' },
        { active: true, key: 'cianAddedCount', color: '#001dff', title: 'Новые ЦИАН' },
        { active: true, key: 'callsToEstateCount', color: '#ffdc41', title: 'Звонки по объектам' },
        { active: true, key: 'showingsCount', color: '#006400', title: 'Показы' },
        { active: true, key: 'usersCount', color: '#64141c', title: 'Агенты' }
    ];

    disablePrepareNextList = true;

    @computed
    get allChartsByKey(): Map<string, ChartType> {
        const chartMap = new Map();
        this.allCharts.forEach(chart => {
            chartMap.set(chart.key, chart);
        });
        return chartMap;
    }

    handleSort = (clickedColumn: string) => {
        this.list = [];
        if (this.orderBy !== clickedColumn) {
            this.orderBy = clickedColumn;
            this.orderDirection = 'descending';
        } else {
            this.orderDirection = this.orderDirection === 'ascending' ? 'descending' : 'ascending';
        }

        this.fetchList();
    };

    @action
    async fetchUserChartData(user_id: number, chartType: STAT_CHART_TYPES) {
        this.setProperty(user_id, { loadingChart: true, chartData: [] });
        const chartData = await statisticsApi.fetchChartStatistics(user_id, chartType);
        this.setProperty(user_id, { loadingChart: false, chartData });
    }

    @observable
    chartStatistics: ChartGlobalStatistics[] = [];
    chartAC: AbortController | null;

    @action
    async fetchGlobalChartStatistics(period: CALENDAR_VIEW_TYPES) {
        if (this.chartAC) {
            this.chartAC.abort();
        }

        const controller = window.AbortController ? new window.AbortController() : undefined;
        if (controller) {
            window.setTimeout(() => {
                this.chartAC = controller;
            }, 0);
        }

        this.loadingList = true;
        try {
            const chartStatistics = await statisticsApi.fetchGlobalChartStatistics(
                period,
                this.listFilter.group_id,
                this.listFilter.major_user_id,
                controller
            );

            this.chartStatistics = chartStatistics;
            this.loadingList = false;
        } catch (e) {
            console.log(e);
        }

        this.chartAC = null;
    }

    @observable
    globalKpiList: UserKpi[] = [];
    @observable
    kpiOrderBy: keyof UserKpi;
    @observable
    kpiDirection: OrderDirectionType = 'descending';

    @action sortGlobalKpi(orderBy: keyof UserKpi) {
        if (orderBy === this.kpiOrderBy) {
            this.kpiDirection = this.kpiDirection === 'descending' ? 'ascending' : 'descending';
        } else {
            this.kpiOrderBy = orderBy;
            this.kpiDirection = 'descending';
        }

        const [sortMin, sortMax] = this.kpiDirection === 'descending' ? [1, -1] : [-1, 1];

        this.loadingList = true;
        this.globalKpiList = this.globalKpiList.slice().sort((globalKpi1, globalKpi2) => {
            if (orderBy === 'major_user') {
                return -1;
            }

            return globalKpi1[orderBy] < globalKpi2[orderBy] ? sortMin : sortMax;
        });
        window.setTimeout(() => (this.loadingList = false), 0);
    }

    @action
    changeFilter<T extends keyof UserStatisticsFilter>(what: T, value: UserStatisticsFilter[T]) {
        this.listFilter[what] = value;
        if (this.statMode === STAT_MODE_TABLE) {
            this.debounceFilterFetch();
        } else if (this.statMode === STAT_MODE_KPI) {
            this.fetchGlobalKpi();
        } else {
            this.fetchGlobalChartStatistics(
                this.statMode === STAT_MODE_CHART_WEEK
                    ? CALENDAR_VIEW_TYPE_WEEK
                    : this.statMode === STAT_MODE_CHART_DAY
                    ? CALENDAR_VIEW_TYPE_DAY
                    : CALENDAR_VIEW_TYPE_MONTH
            );
        }
    }

    @observable statMode: StatModeType = 'table';

    @action setStatMode(statMode: StatModeType) {
        this.statMode = statMode;
    }

    @action async fetchGlobalKpi() {
        this.loadingList = true;
        this.list = [];
        this.globalKpiList = await statisticsApi.fetchGlobalKpi(this.listFilter['major_user_id'], this.listFilter['group_id']);
        this.sortGlobalKpi('kpi');
        this.loadingList = false;
    }
}

export default new StatisticStore();
