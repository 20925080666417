import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Loader, Form, Ref, Icon } from 'semantic-ui-react';

import HandlerEditingBlockWrapper from '../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../Items/HandlerEditingBlockWrapper';
import ListError from '../ListError';
import callEventStore from '~/stores/cell/callEventStore';
import { checkFormValid } from '~/common/forms';

import authStore from '~/stores/authStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CallEventBlock from './CallEventModal/CallEventBlock';
import MeetingEventBlock from './MeetingEvents/MeetingEventBlock';
import EventTypeBlock from './SubBlocks/EventTypeBlock';
import meetingEventStore from '~/stores/meetingEventStore';
import {
    CALL_EVENT_ITEM_TYPE_CONTACT,
    CALL_EVENT_ITEM_TYPE_OWNER,
    EVENT_TYPE_CALL,
    EVENT_TYPE_MAIN,
    EVENT_TYPE_MEETING
} from '~/types/events.types';
import CalendarEventsStorePrototype from '~/stores/prototypes/CalendarEventsStore.prototype';
import history from '../../history';
import commonStore from '~/stores/commonStore';

export const getStepDayMidday = (dayStep: number): number => {
    const date = new Date();
    date.setHours(12, 0, 0, 0);
    date.setTime(date.getTime() + dayStep * 24 * 3600000);
    return date.getTime() / 1000;
};

export const getDayOfYear = (timeMS: number): number => {
    const now = new Date(timeMS);
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now.getTime() - start.getTime() + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    return Math.floor(diff / 86400000);
};

const DEFAULT_CALL_TIME_MAX_DELTA = 1800;
const DEFAULT_DATE_SET_VALUE = 1;

type EventModalProps = HandlerEditingWrappedProps & {
    item_id: number;
    onClose: () => void;
    contact_id: number | null;
    owner_id?: number | null;
    startTime?: number;
    endTime?: number;
    eventType?: number | null;
};

type EventModalState = {
    eventType: number;
    store: CalendarEventsStorePrototype<any, any>;
};

@observer
class EventModal extends Component<EventModalProps, EventModalState> {
    constructor(props: EventModalProps) {
        super(props);

        const { item_id } = props;

        const eventType = props.eventType || EVENT_TYPE_CALL;
        const store = eventType === EVENT_TYPE_CALL ? callEventStore : meetingEventStore;

        this.state = {
            eventType,
            store
        };

        store.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const {
                location: { state }
            } = history;
            const from = state ? state.from : null;
            const contact_id = props.contact_id || (from && from.storeName === 'contactStore' ? from.item_id : null);
            const owner_id = props.owner_id || null;

            this.matchCreatingEvent(eventType, store, contact_id, owner_id);
        } else {
            this.matchCreatingEvent(eventType, store, null, null);
        }
    }

    matchCreatingEvent(
        eventType: number,
        store: CalendarEventsStorePrototype<any, any>,
        fromContactId?: number | null,
        fromOwnerId?: number | null
    ) {
        const { contact_id, item_id } = this.props;

        const startTime = this.props.startTime || getStepDayMidday(DEFAULT_DATE_SET_VALUE);
        const endTime = this.props.endTime || startTime + DEFAULT_CALL_TIME_MAX_DELTA;

        const eventObject = {
            description: '',

            notificationEnable: false,
            assign_users_ids: [authStore.currentUser.user_id],
            enable: true
        };

        switch (eventType) {
            case EVENT_TYPE_CALL:
                const callEventObject = {
                    ...eventObject,
                    item_id: contact_id || fromOwnerId || fromContactId || null,
                    item_type: fromOwnerId ? CALL_EVENT_ITEM_TYPE_OWNER : CALL_EVENT_ITEM_TYPE_CONTACT,
                    callHasReleased: false,
                    call_event_id: CREATING_ITEM_ID,
                    title: '',
                    callTimeMin: startTime,
                    callTimeMax: endTime
                };

                store.setEditingItem(item_id, callEventObject);
                break;

            case EVENT_TYPE_MEETING:
                const meetingEventObject = {
                    ...eventObject,
                    item_id: contact_id || fromOwnerId || fromContactId || null,
                    item_type: fromOwnerId ? CALL_EVENT_ITEM_TYPE_OWNER : CALL_EVENT_ITEM_TYPE_CONTACT,
                    meeting_event_id: CREATING_ITEM_ID,
                    meetingHasReleased: false,
                    goal: '',
                    startTime,
                    endTime
                };

                store.setEditingItem(item_id, meetingEventObject);
                break;

            default:
                break;
        }
    }

    $form: HTMLElement;

    handleSave = async (event: React.SyntheticEvent) => {
        if (!checkFormValid(this.$form, false)) {
            return;
        }

        event.preventDefault();

        const { item_id, onClose } = this.props;
        const { store } = this.state;

        if (item_id === CREATING_ITEM_ID) {
            await store.createItem();
        } else {
            await store.saveItem(item_id);
        }

        const { errors } = store.getItem(item_id);
        if (!errors.length) {
            onClose();
        }
    };

    handleRemove = async () => {
        const { item_id, onClose } = this.props;
        const { store } = this.state;

        await store.removeItem(item_id);
        onClose();
    };

    handleChangeEventType = (event: React.SyntheticEvent, { value: eventType }: { value: number }) => {
        const store = eventType === EVENT_TYPE_CALL ? callEventStore : meetingEventStore;
        store.fetchItem(this.props.item_id);
        this.matchCreatingEvent(eventType, store);
        this.setState({
            eventType,
            store
        });
    };

    handleClose = (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onClose();
    };

    render() {
        const { onClose, item_id, endTime, startTime, contact_id, owner_id } = this.props;
        const { eventType, store } = this.state;
        const { loadingItem, errors } = store.getItem(item_id);

        return (
            <Modal
                dimmer="inverted"
                size={commonStore.isMobile ? 'fullscreen' : 'small'}
                open
                closeIcon
                onClose={this.handleClose}
                closeOnDimmerClick
            >
                {item_id === CREATING_ITEM_ID && !this.props.eventType && authStore.canCreate(meetingEventStore.moduleName) && (
                    <Modal.Header>
                        <EventTypeBlock eventType={eventType} onChange={this.handleChangeEventType} />
                    </Modal.Header>
                )}

                <Modal.Header>
                    {eventType === EVENT_TYPE_CALL && (
                        <Fragment>
                            <Icon name="phone square" /> Запланированный звонок
                        </Fragment>
                    )}
                    {eventType === EVENT_TYPE_MEETING && (
                        <Fragment>
                            <Icon name="handshake outline" /> Встреча
                        </Fragment>
                    )}
                </Modal.Header>

                <Modal.Content>
                    <ListError errors={Array.from(errors || [])} />

                    <Ref innerRef={$form => (this.$form = $form)}>
                        <Form size="small">
                            {eventType === EVENT_TYPE_CALL && (
                                <CallEventBlock
                                    item_id={item_id}
                                    onClose={onClose}
                                    contact_id={contact_id}
                                    owner_id={owner_id}
                                    callTimeMin={startTime}
                                    callTimeMax={endTime}
                                />
                            )}

                            {eventType === EVENT_TYPE_MEETING && (
                                <MeetingEventBlock
                                    item_id={item_id}
                                    onClose={onClose}
                                    contact_id={contact_id}
                                    owner_id={owner_id}
                                    startTime={startTime}
                                    endTime={endTime}
                                />
                            )}
                        </Form>
                    </Ref>

                    <Loader disabled={!loadingItem} />
                </Modal.Content>

                <Modal.Actions>
                    <Button size="tiny" onClick={onClose}>
                        Отмена
                    </Button>

                    {item_id !== CREATING_ITEM_ID && (
                        <Button size="tiny" color="red" onClick={this.handleRemove}>
                            Удалить
                        </Button>
                    )}

                    <Button size="tiny" loading={loadingItem} color="green" onClick={this.handleSave}>
                        Сохранить
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(callEventStore, EventModal);
