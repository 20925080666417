import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';

import { PhonesWithCallList } from './PhonesList';
import { Phone } from '~/types/users.types';

type PhonesListWithMessengersProps = {
    phones: Phone[];
    showExport?: boolean;
};

export const DenseListItem = withStyles((theme: Theme) =>
    createStyles({
        dense: {
            paddingTop: 0,
            paddingBottom: 0
        }
    })
)(ListItem) as any;

const PhonesListWithMessengers = ({ phones, showExport }: PhonesListWithMessengersProps) => (
    <Fragment>
        {phones &&
            phones.length > 0 &&
            phones.map(({ phone }, index) => (
                <DenseListItem disableGutters dense key={`phone_${phone}_${index}`}>
                    <ListItemIcon>
                        <PhoneIcon fontSize="small" style={showExport && index === 0 ? { color: 'green' } : null} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle1">
                                <PhonesWithCallList
                                    key={`phone_${phone}_${index}`}
                                    withoutItemWrapper
                                    phone={phone}
                                    showExport={showExport && index === 0}
                                />
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title="Открыть чат в WhatsApp">
                            <IconButton href={`https://wa.me/${phone}`} target="_blank">
                                <WhatsAppIcon fontSize="small" style={{ color: 'green' }} />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </DenseListItem>
            ))}
    </Fragment>
);

export default PhonesListWithMessengers;
