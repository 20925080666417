import React, { Component, Fragment, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { Input, Form, Dropdown } from 'semantic-ui-react';
import callEventStore from '~/stores/cell/callEventStore';
import taskL10n from '../../../L10n/tasks.L10n';
import DatePicker from '../../Base/DatePicker';
import CalendarEventsStorePrototype from '~/stores/prototypes/CalendarEventsStore.prototype';

type EventDatesPickersProps = {
    item_id: number;
    startTime: number;
    endTime: number;
    store: CalendarEventsStorePrototype<any, any>;
    setUnixTime: (string, number) => void;
};

type EventDatesPickersState = {
    duration: number;
    durationType: number;
};

const MINUTE_SECONDS = 60;
const HOUR_SECONDS = 3600;
const DAY_SECONDS = 86400;

export const matchDurationType = (duration: number): number => {
    if (duration % DAY_SECONDS === 0) {
        return 3;
    } else if (duration % HOUR_SECONDS === 0) {
        return 2;
    }
    return 1;
};

export const matchDuration = (durationSeconds: number, durationType: number): number => {
    switch (durationType) {
        case 3:
            return durationSeconds / DAY_SECONDS;
        case 2:
            return durationSeconds / HOUR_SECONDS;
        default:
            return durationSeconds / MINUTE_SECONDS;
    }
};

export const matchDurationSeconds = (durationType: number): number => {
    switch (durationType) {
        case 3:
            return DAY_SECONDS;
        case 2:
            return HOUR_SECONDS;
        default:
            return MINUTE_SECONDS;
    }
};

const DEFAULT_DURATION_TIME_MINUTES = 30;

@observer
class EventDatesPickers extends Component<EventDatesPickersProps, EventDatesPickersState> {
    constructor(props: EventDatesPickersProps) {
        super(props);

        this.state = {
            duration: DEFAULT_DURATION_TIME_MINUTES,
            durationType: matchDurationType(props.endTime - props.startTime)
        };
    }

    static getDerivedStateFromProps(props: EventDatesPickersProps, state: EventDatesPickersState) {
        const durationSeconds = props.endTime - props.startTime;
        return {
            duration: matchDuration(durationSeconds, state.durationType)
        };
    }

    handleChangeDurationType = (event: SyntheticEvent, { value }: { value: number }) => {
        const { store } = this.props;

        store.setEditingItem(this.props.item_id, {
            [store === callEventStore ? 'callTimeMax' : 'endTime']: this.props.startTime + this.state.duration * matchDurationSeconds(value)
        });

        this.setState({ durationType: value });
    };

    handleChangeDuration = (event: SyntheticEvent, { value }: { value: string }) => {
        const { store } = this.props;

        store.setEditingItem(this.props.item_id, {
            [store === callEventStore ? 'callTimeMax' : 'endTime']:
                this.props.startTime + Number(value) * matchDurationSeconds(this.state.durationType)
        });
    };

    handleCallTimeMinChange = (time: number) => {
        const { store } = this.props;

        this.props.setUnixTime(store === callEventStore ? 'callTimeMin' : 'startTime', time);
        this.props.setUnixTime(
            store === callEventStore ? 'callTimeMax' : 'endTime',
            time + this.state.duration * matchDurationSeconds(this.state.durationType)
        );
    };

    render() {
        const { item_id, setUnixTime, store } = this.props;

        let startTime;
        let endTime;

        if (store === callEventStore) {
            const { callTimeMin, callTimeMax } = store.getItem(item_id).editingItem;
            startTime = callTimeMin;
            endTime = callTimeMax;
        } else {
            const { editingItem } = store.getItem(item_id);
            startTime = editingItem.startTime;
            endTime = editingItem.endTime;
        }

        const { duration, durationType } = this.state;

        return (
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Дата и время начала</label>
                    <DatePicker time={startTime} onChange={this.handleCallTimeMinChange} timeIntervals={5} />
                </Form.Field>

                <Form.Field>
                    <label>Интервал</label>

                    <Input
                        placeholder="интервал"
                        name="duration"
                        value={duration}
                        type="number"
                        size="small"
                        min={0}
                        step={1}
                        onChange={this.handleChangeDuration}
                        label={
                            <Dropdown
                                value={durationType}
                                onChange={this.handleChangeDurationType}
                                options={taskL10n.DURATION_TYPES_DROPDOWN}
                            />
                        }
                        labelPosition="right"
                        className="crm-Estate__filter_priceMax"
                    />
                </Form.Field>

                <Form.Field>
                    <label>Дата и время завершения</label>
                    <DatePicker
                        time={endTime}
                        onChange={time => setUnixTime(store === callEventStore ? 'callTimeMax' : 'endTime', time)}
                        timeIntervals={5}
                    />
                </Form.Field>
            </Form.Group>
        );
    }
}

export default EventDatesPickers;
