import React from 'react';
import { ContactLinksProps } from './ContactLink';
import ContactPreview from '../../Items/Contact/ContactPreview';
import ContactLink from './ContactLink';

const ContactLinkWithPreview = ({ contact }: ContactLinksProps) => (
    <ContactPreview item_id={contact.contact_id} trigger={<ContactLink contact={contact} />} />
);

export default ContactLinkWithPreview;
