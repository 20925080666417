import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { nl2br } from '~/api/commentsApi';

const LifeComplexTextAbout = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const newbuilding = lifeComplexStore.getItem(newbuilding_id).item;

    const description = newbuilding?.about || '';

    if (!description) {
        return null;
    }

    return (
        <Paper style={{ padding: '1rem' }}>
            <Typography variant="h5">О жилом комплексе «{newbuilding?.name}»</Typography>
            <Typography style={{ paddingTop: '1rem' }} dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
        </Paper>
    );
};

export default observer(LifeComplexTextAbout);
