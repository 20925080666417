import React from 'react';
import wishStore from '~/stores/wishStore';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import estateL10n from '~/L10n/estate.L10n';
import { nl2br } from '~/api/commentsApi';
import { DELETING_CAUSE_IS_ALREADY_BUY, DELETING_CAUSE_IS_REALTOR, DELETING_CAUSE_THE_PHONE_IS_WRONG } from '~/types/wish.types';

const wishKeys = {
    enable: 'Архив',

    type: 'Продажа / Аренда',
    town: 'Город',
    description: 'Описание',
    mortgageApproved: 'Ипотека',

    deal_id: 'Номер сделки',
    estate: 'Объект',
    updateTime: 'Время обновления',
    propertyType: 'Тип недвижимости',
    main_metro_id: 'Станции метро',

    priceMax: 'Цена до',
    priceMin: 'Цена от',

    deleteCause: 'Причина удаления',
    takeWishToWork: 'Забронировал покупателя',
    freeWishBook: 'Отправил в свободные',
    extendWishBooking: 'Продление бронирования'
};

const printWishVal = (key: string, value: unknown): string => {
    switch (key) {
        case 'enable':
            return value ? 'нет' : 'да';
        case 'createTime':
        case 'updateTime':
            return Number(value) > 0 ? new Date(Number(value) * 1000).toLocaleString() : 'н/д';
        case 'propertyType':
            return estateL10n.PROPERTY_TYPE_FIND(value);
        case 'description':
            return nl2br(String(value));
        case 'deleteCause':
            switch (value) {
                case DELETING_CAUSE_IS_ALREADY_BUY:
                    return 'Уже куплено';
                case DELETING_CAUSE_IS_REALTOR:
                    return 'Это Риэлтор (добавлен тэг)';
                case DELETING_CAUSE_THE_PHONE_IS_WRONG:
                    return 'Неверный номер';
                default:
                    return 'н/д';
            }
        default:
            break;
    }

    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(wishStore, wishKeys, printWishVal);
