import React from 'react';
import config from '~/config/ui.config';

import Time from '~/components/Base/Time';
import UserAvatar from '~/components/Base/ui/UserAvatar';

import { ChatListType } from '~/types/chat.types';
import { WhatsappChatTitle } from '~/types/whatsapp.types';

const WhatsappCell = ({
    chat,
    active,
    onSelectUser
}: {
    chat: WhatsappChatTitle;
    active: boolean;
    onSelectUser: (chatType: ChatListType, whatsapp_chat_id: number, contact_id: number) => void;
}) => {
    const { lastMessage, countUnreadMessages, waContact, whatsapp_chat_id, contact_id } = chat;

    return (
        <div
            className={`crm-Chat__user_item ${active ? 'active' : ''}`}
            onClick={() => onSelectUser('whatsapp', whatsapp_chat_id, contact_id | 0)}
        >
            <div className="crm-Chat__user_row">
                <div className="crm-Chat__item_avatar">
                    <div className="crm-Chat__item_avatar_mode">
                        <UserAvatar
                            name={waContact.contactName || waContact.pushname}
                            src={waContact.photoUrl || config.defaultAvatarUrl}
                            size={40}
                        />
                    </div>
                </div>

                <div className="crm-Chat__info">
                    <span className="crm-Chat__info_name">{waContact.contactName || waContact.pushname}</span>
                    <div className="crm-Chat__info_des">{lastMessage.message.substring(0, 25) + '...'}</div>
                    <div className="crm-Chat__info_time">
                        <Time time={lastMessage.createTime} />
                    </div>
                </div>

                <div className="crm-Chat__unread">
                    {countUnreadMessages > 0 && <div className="crm-Chat__unread_badge">{countUnreadMessages}</div>}
                </div>
            </div>
        </div>
    );
};

export default React.memo(WhatsappCell);
