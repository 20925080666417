import React from 'react';
import { observer } from 'mobx-react';
import estateStore from '~/stores/estateStore';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import Select from '~/components/Base/ui/Select';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

const handleChangeRegion: HandleChangeType = (event, { value }) => {
    estateStore.changeFilter('regionId', value);
};

const FilterEstateRegion = ({ onChange, fullWidth, value }: { value?: number; fullWidth?: boolean; onChange?: HandleChangeType }) => {
    const {
        listFilter: { regionId },
        filterRegions
    } = estateStore;
    const { loadingRegions } = exportingBaseStore;

    return (
        <Select
            value={value ?? regionId}
            options={filterRegions}
            disabled={loadingRegions}
            loading={loadingRegions}
            onChange={onChange || handleChangeRegion}
            style={{ minWidth: '150px', top: '5px', height: '25px' }}
            name="regionId"
            fullWidth={fullWidth}
        />
    );
};

export default observer(FilterEstateRegion);
