import React from 'react';
import wishStore from '~/stores/wishStore';
import Button from '~ui/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

type WishIsNotActuallyButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishIsNotInterestedButton = ({ wish_id, disabled }: WishIsNotActuallyButtonProps) => {
    const handleToggleIsNotInterested = () => {
        wishStore.freeWishBook(wish_id);
    };

    return (
        <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={handleToggleIsNotInterested}
            disabled={disabled}
            startIcon={<ErrorOutlineIcon />}
        >
            Не буду работать (Отправить в свободные)
        </Button>
    );
};

export default WishIsNotInterestedButton;
