import React, { Component, Fragment, SyntheticEvent, ReactElement } from 'react';
import { Icon, Sticky, Button } from 'semantic-ui-react';
import Divider from '~ui/Divider';
import Confirm from '~ui/Confirm';
import { observer } from 'mobx-react';
import cs from 'classnames';
import isEqual from '~/common/isEqual';

import { EstateLinkState } from '../../Lists/Estate/EstateLink';
import estateStore from '~/stores/estateStore';

import AddressBlockEditing from './EditBlocks/AddressBlockEditing';
import PrivateBlockEditing from './EditBlocks/PrivateBlockEditing';
import PropertyBlockEditing from './EditBlocks/PropertyBlockEditing';
import PriceBlockEditing from './EditBlocks/PriceBlockEditing';
import PhotosBlockEditing from './EditBlocks/PhotosBlockEditing';
import TypeBlockEditing from './EditBlocks/TypeBlockEditing';
import HouseBlockEditing from './EditBlocks/HouseBlockEditing';
import ComfortBlockEditing from './EditBlocks/ComfortBlockEditing';
import EstateAccessBlockEditing from './EditBlocks/EstateAccessBlockEditing';
import OfficeDetailsBlockEditing from './EditBlocks/OfficeDetailsBlockEditing';
import OfficeBuildingBlockEditing from './EditBlocks/OfficeBuildingBlockEditing';
import InfrastructureBlockEditing from './EditBlocks/InfrastructureBlockEditing';
import CountryHouseEditing from './EditBlocks/CountryHouseEditing';
import LandBlockEditing from './EditBlocks/LandBlockEditing';
import GarageBlockEditing from './EditBlocks/GarageBlockEditing';
import AvailableFromEditing from './EditBlocks/AvailableFromEditing';
import CommerceLandEditing from './EditBlocks/CommerceLandEditing';
import WarehouseBlockEditing from './EditBlocks/WarehouseBlockEditing';
import ParkingBlockEditing from './EditBlocks/ParkingBlockEditing';
import ShoppingBlockEditing from './EditBlocks/ShoppingBlockEditing';
import ShoppingInfrastructureBlockEditing from './EditBlocks/ShoppingInfrastructureBlockEditing';
import BusinessBlockEditing from './EditBlocks/BusinessBlockEditing';
import AgentBonusBlockEditing from './EditBlocks/AgentBonusBlockEditing';
import BuildingProperyBlockEditing from './EditBlocks/BuildingProperyBlockEditing';

import ItemAddModeWrapper from '../ItemAddModeWrapper';
import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';
import {
    ESTATE_PROPERTY_COUNTRY_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_BUILDING
} from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CreationButtonsSet from '../Common/CreationButtonsSet';
import commonStore from '~/stores/commonStore';
import { checkFormValid, whetherInputIsInvalid } from '~/common/forms';

type EstateAddModeState = {
    step: number;
    confirmAddressModal: boolean | null;
    completedSteps: boolean[];
};

import ContactMailIcon from '@material-ui/icons/ContactMail';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import MoneyIcon from '@material-ui/icons/Money';

import Steps, { StepType } from '~ui/Steps';

const STEP_ADDRESS = 0;
const STEP_MAIN = 1;
const STEP_PHOTOS = 2;
const STEP_PRICE = 3;

const estateSteps: StepType[] = [
    { id: STEP_ADDRESS, title: 'Адрес / Контакт', subtitle: 'Метро, Шоссе, Карта, ЖК', icon: <ContactMailIcon /> },
    { id: STEP_MAIN, title: 'Параметры', subtitle: 'Описание', icon: <FormatListNumberedIcon /> },
    { id: STEP_PHOTOS, title: 'Фото / Видео', subtitle: 'Ссылка на Youtube', icon: <AddAPhotoIcon /> },
    { id: STEP_PRICE, title: 'Стоимость', subtitle: 'Кадастровый номер', icon: <MoneyIcon /> }
];

@observer
class EstateAddMode extends Component<ItemAddModeWrapperProps, EstateAddModeState> {
    state: EstateAddModeState = {
        step: STEP_ADDRESS,
        confirmAddressModal: null,
        completedSteps: [false, false, false, false]
    };

    handleChangeStep = (step: number) => {
        if (this.state.step === STEP_ADDRESS && this.state.confirmAddressModal === null) {
            this.setState({ confirmAddressModal: true });
        } else {
            this.setState({ step });
        }
    };

    $aboutRef: HTMLFormElement;
    $priceRef: HTMLFormElement;

    handleCreate = async (event: SyntheticEvent) => {
        let $input = whetherInputIsInvalid(this.$aboutRef);
        let step = STEP_MAIN;
        if (!$input) {
            $input = whetherInputIsInvalid(this.$priceRef);
            step = STEP_PRICE;
        }
        if ($input) {
            await this.setState({ step });
            if ($input) {
                $input.focus();
                $input.reportValidity();
                $input.scrollIntoView();
            }
        } else {
            this.props.handleCreate(event);
        }
    };

    checkValidBlock = ($ref: HTMLFormElement): boolean => {
        if (!$ref) {
            return false;
        } else if ($ref && checkFormValid($ref, false)) {
            return true;
        }
        return false;
    };

    componentDidUpdate() {
        const { editingItem } = estateStore.getItem(CREATING_ITEM_ID);
        const { address, contact_id, photos, price } = editingItem;

        const completedSteps = [...this.state.completedSteps];

        completedSteps[STEP_ADDRESS] = Boolean(address && contact_id);
        completedSteps[STEP_MAIN] = this.checkValidBlock(this.$aboutRef);
        completedSteps[STEP_PHOTOS] = photos && photos.length > 0;
        completedSteps[STEP_PRICE] = this.checkValidBlock(this.$priceRef) && Boolean(price);

        if (!isEqual(this.state.completedSteps, completedSteps)) {
            this.setState({ completedSteps });
        }
    }

    render() {
        const { loadingItem, editingItem } = estateStore.getItem(CREATING_ITEM_ID);
        const { propertyType, type } = editingItem;
        const { loadingPhotos, loadingMetros } = estateStore;
        const { step, completedSteps, confirmAddressModal } = this.state;

        return (
            <Fragment>
                <Sticky>
                    <TypeBlockEditing item_id={CREATING_ITEM_ID} editing={propertyType === 0} propertyType={propertyType} type={type} />
                </Sticky>

                {propertyType > 0 && (
                    <Fragment>
                        <Divider />

                        <Steps
                            steps={estateSteps}
                            activeStep={step}
                            completedSteps={completedSteps}
                            setActiveStep={this.handleChangeStep}
                            collapsed={commonStore.isMobile}
                        />

                        <div className={cs({ 'crm-Main__hidden': step !== STEP_ADDRESS })}>
                            {confirmAddressModal && (
                                <Confirm
                                    header="Точка на карте"
                                    content="Вы убедились, что точка на карте стоит верно?"
                                    confirmButton="Да"
                                    onCancel={() => {
                                        this.setState({ confirmAddressModal: null });
                                    }}
                                    onConfirm={() => {
                                        this.setState({ confirmAddressModal: false });
                                    }}
                                />
                            )}
                            <EstateAccessBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                            <Divider />
                            <AddressBlockEditing estate_id={CREATING_ITEM_ID} {...editingItem} />
                        </div>

                        <form className={cs({ 'crm-Main__hidden': step !== STEP_MAIN })} ref={$ref => (this.$aboutRef = $ref)}>
                            {ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) && (
                                <Fragment>
                                    <HouseBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <PropertyBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {ESTATE_PROPERTY_COUNTRY_ARR.includes(propertyType) && (
                                <Fragment>
                                    {propertyType !== ESTATE_PROPERTY_TYPE_LAND && (
                                        <Fragment>
                                            <CountryHouseEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                            <Divider />
                                        </Fragment>
                                    )}
                                    <LandBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {[ESTATE_PROPERTY_TYPE_OFFICE, ESTATE_PROPERTY_TYPE_FREE_PURPOSE].includes(propertyType) && (
                                <Fragment>
                                    <OfficeDetailsBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <AvailableFromEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <OfficeBuildingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && (
                                        <Fragment>
                                            <Divider />
                                            <InfrastructureBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}

                            {[ESTATE_PROPERTY_TYPE_WAREHOUSE, ESTATE_PROPERTY_TYPE_INDUSTRY].includes(propertyType) && (
                                <Fragment>
                                    <OfficeBuildingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <ParkingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <AvailableFromEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <WarehouseBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <OfficeDetailsBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {ESTATE_PROPERTY_TYPE_BUILDING === propertyType && (
                                <Fragment>
                                    <OfficeBuildingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <AvailableFromEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <BuildingProperyBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {ESTATE_PROPERTY_TYPE_SHOPPING === propertyType && (
                                <Fragment>
                                    <OfficeBuildingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <ShoppingBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <AvailableFromEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <ShoppingInfrastructureBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                    <Divider />
                                    <OfficeDetailsBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {ESTATE_PROPERTY_TYPE_BUSINESS === propertyType && (
                                <Fragment>
                                    <BusinessBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                </Fragment>
                            )}

                            {ESTATE_PROPERTY_GARAGE_ARR.includes(propertyType) && (
                                <GarageBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                            )}

                            {ESTATE_PROPERTY_TYPE_COMMERCE_LAND === propertyType && (
                                <CommerceLandEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                            )}

                            {[ESTATE_PROPERTY_TYPE_ROOM, ESTATE_PROPERTY_TYPE_FLAT, ...ESTATE_PROPERTY_HOUSES].includes(propertyType) && (
                                <Fragment>
                                    <Divider />
                                    <ComfortBlockEditing item_id={CREATING_ITEM_ID} type={type} {...editingItem} />
                                </Fragment>
                            )}
                        </form>

                        <div className={cs({ 'crm-Main__hidden': step !== STEP_PHOTOS })}>
                            <PhotosBlockEditing estate_id={CREATING_ITEM_ID} {...editingItem} />
                        </div>

                        <form className={cs({ 'crm-Main__hidden': step !== STEP_PRICE })} ref={$ref => (this.$priceRef = $ref)}>
                            <Fragment>
                                <PrivateBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />
                                <Divider />
                            </Fragment>

                            <PriceBlockEditing
                                type={type}
                                item_id={CREATING_ITEM_ID}
                                clientFee={type === ESTATE_TYPE_RENT_ID ? 100 : null}
                                agentFee={type === ESTATE_TYPE_RENT_ID ? 50 : null}
                                {...editingItem}
                            />

                            {type === ESTATE_TYPE_SELL_ID && <AgentBonusBlockEditing item_id={CREATING_ITEM_ID} {...editingItem} />}
                        </form>

                        <Divider />
                        <div>
                            {step > STEP_ADDRESS && (
                                <Button
                                    basic
                                    floated="left"
                                    onClick={this.handleChangeStep.bind(this, step - 1)}
                                    size="tiny"
                                    labelPosition="left"
                                >
                                    <Icon name="arrow left" />
                                    Назад
                                </Button>
                            )}
                            {step < STEP_PRICE && (
                                <Button
                                    floated="right"
                                    positive={completedSteps[step]}
                                    onClick={this.handleChangeStep.bind(this, step + 1)}
                                    size="tiny"
                                    icon
                                    labelPosition="right"
                                >
                                    Дальше
                                    <Icon name="arrow right" />
                                </Button>
                            )}

                            <br />
                            <br />
                            <CreationButtonsSet
                                loading={Boolean(loadingItem || loadingPhotos || loadingMetros)}
                                onCreate={this.handleCreate}
                                onClearForm={this.props.clearForm}
                            />
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(estateStore, EstateLinkState, EstateAddMode);
