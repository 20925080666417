import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ContactIdBlockEditing from '../../Common/ContactIDBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { ContactWithPhonesList } from '../../../Base/ClientShowBlock';
import { WarningMessage } from '~ui/Message';

type ContactEstateBlockShowProps = EditingWrappedProps & { handleSave: () => void };

@observer
class ContactEstateBlockShow extends Component<ContactEstateBlockShowProps, { contact_id: number }> {
    constructor(props: ContactEstateBlockShowProps) {
        super(props);

        this.state = {
            contact_id: 0
        };

        const { item } = estateStore.getItem(props.item_id);
        if (item && Number(item.contact_id) > 0) {
            estateStore.contactByEstate(props.item_id, Number(item.contact_id));
        }
    }

    UNSAFE_componentWillUpdate(props: ContactEstateBlockShowProps) {
        const { item, editingItem } = estateStore.getItem(props.item_id);

        if (item && Number(item.contact_id) > 0 && item.contact_id !== this.state.contact_id) {
            estateStore.contactByEstate(this.props.item_id, Number(item.contact_id));
            this.setState({ contact_id: Number(item.contact_id) });
        }

        // if (item && Number(editingItem.contact_id) > 0 && item.contact_id !== editingItem.contact_id) {
        //     this.props.handleSave();
        // }
    }

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const { contact_id } = item;

        let ContactElement;

        if (loadingItem) {
            ContactElement = () => null;
        } else if (Number(contact_id) > 0) {
            const { contact, contactLoading } = estateStore.getItem(item_id).property;

            ContactElement = () => <ContactWithPhonesList contact={contactLoading ? null : contact} />;
        } else {
            ContactElement = () => <WarningMessage>Контакт не закреплен</WarningMessage>;
        }

        return (
            <Card fluid color="yellow">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Продавец (Посредник)</Header>
                            <Grid columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <ContactElement />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && (
                        <div className="crm-Item__editingMode">
                            <ContactIdBlockEditing
                                store={estateStore}
                                editing={editing}
                                item_id={item_id}
                                value={contact_id}
                                direction="left"
                            />
                        </div>
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, ContactEstateBlockShow);
