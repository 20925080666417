import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';

import { EstateLinkState } from '../../Lists/Estate/EstateLink';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS, ESTATE_TYPE_SELL } from '~/types/estate.types';
import ownerStore from '~/stores/ownerStore';
import history from '../../../history';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ConfirmTrigger from '../../Base/ui/ConfirmTrigger';

@observer
class OwnersTabMenu extends Component<TabMenuWrapperProps> {
    handleCreateEstate = async () => {
        const new_estate_id = await ownerStore.createEstateFromOwner(this.props.item_id);
        if (new_estate_id) {
            history.push(EstateLinkState(new_estate_id, ESTATE_TYPE_SELL, ESTATE_BASE_MAIN));
        }
    };

    render() {
        const { contact_id, item_id } = this.props;
        const { loadingItem } = ownerStore.getItem(item_id);

        return (
            <Fragment>
                {!loadingItem && Number(contact_id) > CREATING_ITEM_ID && (
                    <ConfirmTrigger
                        as={MenuItem}
                        trigger={
                            <Fragment>
                                <Icon name="building" />
                                Создать листинг (Черновик)
                            </Fragment>
                        }
                        header="Создать листинг (Черновик)"
                        content="После создания Черновика, он будет находиться у вас в Архиве"
                        confirmButton="Создать"
                        onConfirm={this.handleCreateEstate}
                    />
                )}
                <MenuItem component={'a' as any} href={`/print/owners-${item_id}`} target="_blank">
                    <Icon name="print" />
                    На печать
                </MenuItem>
                {loadingItem && (
                    <MenuItem>
                        <Fragment>
                            <Icon name="building" />
                            Черновик в процессе создания...
                        </Fragment>
                    </MenuItem>
                )}
            </Fragment>
        );
    }
}

export default TabMenuWrapper(ownerStore, EstateLinkState, OwnersTabMenu, {
    base: ESTATE_BASE_OWNERS,
    callEventModal: true,
    meetingEventModal: true
});
