import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dimmer, Loader, Grid, Divider, Checkbox, Image, Table } from 'semantic-ui-react';
import Modal, { ModalContent, ModalBtnAction } from '~ui/Modal';

import UserDropdown from '../../ItemsDropdowns/UserDropdown';
import estateStore from '~/stores/estateStore';
import ListError from '~/components/ListError';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import config from '~/config/ui.config';
import ButtonGroup from '~/components/Base/ButtonGroup';
import { HandleChangeType } from '../../Items/HandlerEditingBlockWrapper';
import { ManageEstateArrayType } from '~/types/estate.types';
import deepCopy from '~/common/deepCopy';

type ManageEstatesArrayProps = {
    onClose: () => void;
};

@observer
export default class ManageEstatesArray extends Component<ManageEstatesArrayProps, ManageEstateArrayType> {
    state = {
        major_user_ids: [],
        export_user_id: 0,
        disableAll: false,
        disableBasesIds: [],
        enableBasesIds: [],
        setToFree: false
    };

    handleChangeMajorUsers = (major_user_ids: number | Array<number>) => {
        if (major_user_ids instanceof Array) {
            this.setState({ major_user_ids });
            if (major_user_ids.length) {
                this.setState({ export_user_id: major_user_ids[0] });
            }
        }
    };

    handleChangeExportUser = (user_id: string | number | Array<number>) => {
        if (!this.state.disableAll) {
            const export_user_id = user_id === '' ? 0 : user_id;

            if (typeof export_user_id === 'number') {
                this.setState({ export_user_id });
            }
        }
    };

    handleSave = async () => {
        if (await estateStore.manageItemArray(deepCopy(this.state))) {
            this.props.onClose();
        }
    };

    handleDisableAll = () => {
        this.setState({
            disableAll: !this.state.disableAll
        });
    };

    handleChangeExportBase: HandleChangeType = (event, { name: export_base_id, value }) => {
        let { disableBasesIds, enableBasesIds } = this.state;
        disableBasesIds = [...disableBasesIds];
        enableBasesIds = [...enableBasesIds];

        let foundIndex = enableBasesIds.indexOf(export_base_id);
        if (~foundIndex) {
            enableBasesIds.splice(foundIndex, 1);
        }

        foundIndex = disableBasesIds.indexOf(export_base_id);
        if (~foundIndex) {
            disableBasesIds.splice(foundIndex, 1);
        }

        if (Number(value) === 1) {
            enableBasesIds.push(Number(export_base_id));
        } else if (Number(value) === 2) {
            disableBasesIds.push(Number(export_base_id));
        }

        this.setState({
            enableBasesIds,
            disableBasesIds
        });
    };

    handleSetToFree = () => {
        if (!this.state.setToFree) {
            this.setState({ major_user_ids: [], setToFree: true });
        } else {
            this.setState({ setToFree: false });
        }
    };

    render() {
        const { onClose } = this.props;
        const { major_user_ids, export_user_id, disableAll, enableBasesIds, disableBasesIds, setToFree } = this.state;
        const { loadingManageItemArray, manageItemArrayErrors } = estateStore;

        const { loadingList, exportingBases } = exportingBaseStore;

        const actions: ModalBtnAction[] = [
            { onClick: onClose, label: 'Отмена', disabled: loadingManageItemArray, variant: 'outlined' },
            {
                onClick: this.handleSave,
                label: 'Сохранить',
                disabled: loadingManageItemArray,
                color: 'primary',
                loading: loadingManageItemArray
            }
        ];

        return (
            <Modal maxWidth="md" onClose={onClose} header="Изменение параметров у объектов" actions={actions}>
                <ModalContent dividers>
                    <ListError errors={manageItemArrayErrors} />

                    <Grid verticalAlign="middle">
                        <Grid.Column width={6}>&nbsp;</Grid.Column>
                        <Grid.Column width={10}>
                            <Checkbox toggle label="Перевести объекты в архив" checked={disableAll} onClick={this.handleDisableAll} />
                        </Grid.Column>
                        <Grid.Column width={6}>Ответственный(е)</Grid.Column>
                        <Grid.Column width={10}>
                            <UserDropdown groups_id={[]} upward={false} user_id={major_user_ids} onChange={this.handleChangeMajorUsers} />
                            &nbsp;&nbsp;
                            <Checkbox checked={setToFree} onClick={this.handleSetToFree} toggle label="в Свободные" />
                        </Grid.Column>
                    </Grid>
                    <Grid verticalAlign="middle">
                        <Grid.Column width={6}>Экспорт установлен на</Grid.Column>
                        <Grid.Column width={10}>
                            <UserDropdown
                                groups_id={[]}
                                upward={false}
                                user_id={disableAll ? 0 : export_user_id}
                                onChange={this.handleChangeExportUser}
                            />
                        </Grid.Column>
                    </Grid>

                    <Divider />

                    <Table size="small" compact>
                        <Table.Body>
                            {exportingBases
                                .filter(({ enable }) => enable)
                                .map(({ export_base_id, name, image }) => {
                                    let value = 0;
                                    if (~enableBasesIds.indexOf(export_base_id)) {
                                        value = 1;
                                    } else if (~disableBasesIds.indexOf(export_base_id)) {
                                        value = 2;
                                    }

                                    return (
                                        <Table.Row key={export_base_id}>
                                            <Table.Cell>
                                                <Image
                                                    avatar
                                                    src={config.publicUrl + image}
                                                    style={value > 0 || disableAll ? {} : { filter: 'grayscale()' }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{name}</Table.Cell>
                                            <Table.Cell>
                                                <ButtonGroup
                                                    size="small"
                                                    buttonSet={[
                                                        [0, 'не менять'],
                                                        [1, 'первый тариф', null, { background: 'green', color: 'white' }],
                                                        [2, 'выключить', null, { background: 'red', color: 'white' }]
                                                    ]}
                                                    name={export_base_id}
                                                    value={disableAll ? 2 : value}
                                                    handleChange={this.handleChangeExportBase}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>

                    {(loadingManageItemArray || loadingList) && (
                        <Dimmer active inverted>
                            <Loader active size="medium" />
                        </Dimmer>
                    )}
                </ModalContent>
            </Modal>
        );
    }
}
