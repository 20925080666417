import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Loader, Form, Divider } from 'semantic-ui-react';

import cellCallStore from '~/stores/cell/cellCallStore';
import deepCopy from '../../../../common/deepCopy';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import EditField from '../../../Base/EditField';
import ItemDropdown from '../../../Items/Common/ItemDropdow';
import callSourceStore from '~/stores/cell/callSourceStore';
import contactStore from '~/stores/contactStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { HandleChangeType } from '../../HandlerEditingBlockWrapper';
import ListError from '../../../ListError';

import PersonalBlockEditing from '../../Contact/EditBlocks/PersonalBlockEditing';
import TagsBlockEditing from '../../Contact/EditBlocks/TagsBlockEditing';
import estateStore from '~/stores/estateStore';

type FindCallModalProps = HandlerEditingWrappedProps & {
    handleClose: () => void;
    estate_id: number;
};

type FindCallModalState = {
    contact_id: number | null;
    call_id: number;
    loadingContact: boolean;
};

@observer
class FindCallModal extends Component<FindCallModalProps, FindCallModalState> {
    constructor(props: FindCallModalProps) {
        super(props);

        cellCallStore.fetchItem(CREATING_ITEM_ID);
        cellCallStore.setEditingItem(CREATING_ITEM_ID, { call_id: CREATING_ITEM_ID, comment: '' });

        this.state = {
            contact_id: null,
            call_id: CREATING_ITEM_ID,
            loadingContact: false
        };
    }

    handleSave = async () => {
        const { handleClose, estate_id } = this.props;
        const { call_id } = this.state;

        await cellCallStore.saveItem(call_id);
        estateStore.callsByEstate(estate_id);
        handleClose();
    };

    handleCellCallChange = (event: React.SyntheticEvent, { value: call_id }: { value: number }) => {
        (async () => {
            this.setState({ call_id, loadingContact: true });
            await cellCallStore.fetchItem(call_id);

            cellCallStore.setEditingItem(call_id, { estate_id: this.props.estate_id });

            const { item } = cellCallStore.getItem(call_id);
            if (item) {
                const contact_id = item.itemContact ? item.itemContact.contact_id : CREATING_ITEM_ID;
                await contactStore.fetchItem(contact_id);
                this.setState({ contact_id, loadingContact: false });
            }
        })();
    };

    handleChange: HandleChangeType = (event, { value, name }) => {
        const { call_id } = this.state;
        cellCallStore.setEditingItem(call_id, { [name]: value });
    };

    handleTextAreaChange = ({ value, name }: { name: string; value: string }) => {
        cellCallStore.setEditingItem(this.state.call_id, {
            [name]: value
        });
    };

    render() {
        const { handleClose } = this.props;
        const { contact_id, call_id, loadingContact } = this.state;

        const { loadingItem = false, errors = [], editingItem = {} } = call_id !== null ? cellCallStore.getItem(call_id) : {};
        const { estate_id, source_id, comment } = editingItem;

        const { allCallSourcesDropdown, loadingFetchAllSources } = callSourceStore;
        const contact = contact_id !== null ? contactStore.getItem(contact_id) : null;

        return (
            <Modal dimmer="inverted" size="small" open={true} closeIcon onClose={handleClose}>
                <Modal.Header>Поиск звонка</Modal.Header>

                <Modal.Content>
                    <ListError errors={Array.from(errors || [])} />

                    <div className="crm-Item__editingMode">
                        <Form size="small">
                            <ItemDropdown
                                name="call_id"
                                store={cellCallStore}
                                item_id={Number(call_id)}
                                onChange={this.handleCellCallChange}
                                placeholder="Выбрать звонок"
                                noResultsMessage="Звонок не найден"
                            />
                        </Form>

                        {contact_id !== null && contact && !loadingContact && (
                            <Fragment>
                                <Divider />

                                <b>{contact_id === CREATING_ITEM_ID ? 'Создание' : 'Редактирование'} контакта:</b>

                                {!contact.loadingItem && (
                                    <PersonalBlockEditing item_id={contact_id} {...deepCopy(contact.item)} simpleMode={true} />
                                )}
                                {!contact.loadingItem && <TagsBlockEditing item_id={contact_id} {...deepCopy(contact.item)} />}
                                {contact.loadingItem && <Loader active />}

                                <Divider />

                                <EditField.Drop
                                    onChange={this.handleChange}
                                    label="Источник"
                                    loading={loadingFetchAllSources}
                                    name="source_id"
                                    value={source_id}
                                    options={allCallSourcesDropdown}
                                />

                                <EditField.Area
                                    label="Комментарий"
                                    value={comment || null}
                                    name="comment"
                                    onChange={this.handleTextAreaChange}
                                />
                            </Fragment>
                        )}
                    </div>

                    {loadingItem && <Loader active />}
                </Modal.Content>

                <Modal.Actions>
                    <Button size="tiny" disabled={!estate_id || !source_id} color="green" onClick={this.handleSave}>
                        Сохранить
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(cellCallStore, FindCallModal);
