import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

import UserAvatar from '~/components/Base/ui/UserAvatar';
import Typography from '@material-ui/core/Typography';
import history from '~/history';
import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';
import { EstateLinkState, estateLinkTitle } from '~/components/Lists/Estate/EstateLink';
import { ESTATE_TYPE_RENT, ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL } from '~/types/estate.types';
import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';
import Progress from '~/components/Base/ui/Progress';
import ListError from '~/components/ListError';

type ChatHeaderProps = {
    item_id: number;
};

@observer
export default class EstateChatHeader extends Component<ChatHeaderProps, ChatHeaderProps> {
    constructor(props: ChatHeaderProps) {
        super(props);
        estateStore.fetchItem(props.item_id);
        this.state = { ...props };
    }

    static getDerivedStateFromProps(nextProps: ChatHeaderProps, currentState: ChatHeaderProps) {
        if (nextProps.item_id !== currentState.item_id) {
            estateStore.fetchItem(nextProps.item_id);
        }
        return {
            item_id: nextProps.item_id
        };
    }

    render() {
        const { item: estate, loadingItem, errors } = estateStore.getItem(this.props.item_id);
        if (!estate || loadingItem) {
            return <Progress show size={48} />;
        }

        if (errors && errors.length) {
            return <ListError errors={errors} />;
        }

        const { address, propertyType, roomsCount, estate_id, type, base, photos } = estate;
        let photoSrc = photos.length ? photos[0].filename : null;

        if (photoSrc) {
            const { src } = matchEstateThumbnailPhotoUrl(photoSrc);
            photoSrc = src;
        }

        return (
            <Button
                onClick={() =>
                    history.push(EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, base))
                }
                style={{ padding: 0, margin: 0 }}
            >
                <UserAvatar name={address} src={photoSrc} size={commonStore.isMobile ? 36 : 60} />

                <Typography variant="h6" noWrap style={{ marginLeft: '0.5em' }}>
                    {estateLinkTitle(propertyType, roomsCount, address)}
                </Typography>
            </Button>
        );
    }
}
