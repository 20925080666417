import React, { Component, Fragment } from 'react';
import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button, Icon, SemanticICONS } from 'semantic-ui-react';
import ShadowFragment from '../../Base/ShadowFragment';
import ItemDropdown from './ItemDropdow/index';
import { Link } from 'react-router-dom';

import { EditingWrappedProps } from '../EditingBlockWrapper';
import EditButton from '../../Base/EditButton';

import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';

type ItemIDBlockEditingProps = EditingWrappedProps & {
    value?: number | null;
    store: ItemStoreInterface<any>;

    itemStore: ListStoreInterface;
    itemLinkEmit: (item: any) => ReactNode;
    placeholder: string;
    noResultsMessage: string;
    icon: SemanticICONS;
    createTitle: string;
    pathname: string;

    searchingItemIdTitle: string;
    base?: string;
    clearable?: boolean;

    onChange?: (index: number) => void;
    direction?: 'left' | 'right';
};

type ItemIDBlockEditingState = {
    editing: boolean;
};

@observer
class ItemIDBlockEditing extends Component<ItemIDBlockEditingProps, ItemIDBlockEditingState> {
    constructor(props: ItemIDBlockEditingProps) {
        super(props);

        const { store, item_id, value, searchingItemIdTitle, itemStore } = props;

        store.setEditingItem(item_id, {
            [searchingItemIdTitle]: value || null
        });

        if (typeof value === 'number') {
            try {
                itemStore.getItem(value);
            } catch (error) {
                itemStore.fetchItem(value);
            }
        }
    }

    state = {
        editing: false
    };

    handleEditing = () => {
        this.setState({
            editing: !this.state.editing
        });
    };

    handleChange = (event: React.SyntheticEvent, { name, value }: { name: string; value: string }) => {
        const { store, item_id, itemStore, searchingItemIdTitle, base, onChange } = this.props;
        const valNumber = Number(value);

        itemStore.fetchItem(valNumber, base);

        store.setEditingItem(item_id, {
            [searchingItemIdTitle]: valNumber
        });
        if (valNumber) {
            this.setState({
                editing: false
            });
        }

        if (typeof onChange === 'function') {
            onChange(valNumber);
        }
    };

    static getDerivedStateFromProps(nextProps: ItemIDBlockEditingProps, oldState: ItemIDBlockEditingState) {
        const { store, item_id, value, itemStore, searchingItemIdTitle } = nextProps;
        const { editingItem } = store.getItem(item_id);

        if (editingItem[searchingItemIdTitle] > 0 && editingItem[searchingItemIdTitle] !== value) {
            itemStore.fetchItem(editingItem[searchingItemIdTitle]);
            return {
                editing: false
            };
        }
        return oldState;
    }

    render() {
        const {
            item_id,
            editing,
            store,
            itemStore,
            itemLinkEmit,
            placeholder,
            noResultsMessage,
            icon,
            createTitle,
            pathname,
            searchingItemIdTitle,
            clearable,
            direction
        } = this.props;
        const { editingItem } = store.getItem(item_id);
        const searching_item_id_value: number = editingItem[searchingItemIdTitle];

        if (editing || this.state.editing) {
            return (
                <Grid verticalAlign="middle">
                    <Grid.Column width={12}>
                        <ItemDropdown
                            name={searchingItemIdTitle}
                            store={itemStore}
                            item_id={searching_item_id_value}
                            onChange={this.handleChange}
                            placeholder={placeholder}
                            noResultsMessage={noResultsMessage}
                            clearable={clearable}
                            direction={direction}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button
                            size="mini"
                            color="green"
                            title={createTitle}
                            as={Link}
                            to={{
                                pathname,
                                state: {
                                    modal: true,
                                    from: { storeName: `${store.moduleName.replace(/s$/, '')}Store`, item_id }
                                }
                            }}
                            compact
                        >
                            <Icon name="plus" />
                            <Icon name={icon} />
                        </Button>
                    </Grid.Column>
                </Grid>
            );
        }

        const { item, loadingItem } = itemStore.getItem(searching_item_id_value);

        return (
            <div className="crm-FluentButtons crm-FluentButtons__itemDropdown">
                <div className="crm-FluentButtons__itemDropdown_link">
                    <EditButton onClick={this.handleEditing} />
                    <ShadowFragment shadow={loadingItem} print={!loadingItem && itemLinkEmit(item)} />
                </div>
            </div>
        );
    }
}

export default ItemIDBlockEditing;
