import React, { PureComponent, SyntheticEvent } from 'react';
import { Icon } from 'semantic-ui-react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { matchIconName } from '~/components/Base/ItemLink';
import ItemLinkSimple from '~/components/Base/ItemLinkSimple';
import Time from '~/components/Base/Time';
import { ITEM_TYPES, ITEM_TYPES_OBJECT, Notification } from '~/types/notifications.types';
import urlify from '~/common/urlify';
import { nl2br } from '~/api/commentsApi';

type NotiListItemProps = {
    noty: Notification;
    readNoty?: (noty_id: number) => void;
    onItemLinkClick?: (event: SyntheticEvent, noty_id: number) => void;
    onItemClick?: (noty_id: number, item_type: ITEM_TYPES, item: ITEM_TYPES_OBJECT) => void;
};

// Только классновый компонет, т.к функциональный нельзя обернуть анимацией <Slide />
class NotiListItem extends PureComponent<NotiListItemProps> {
    render() {
        const { noty, readNoty, onItemLinkClick, onItemClick } = this.props;
        const { noty_id, updateTime, title, description, item_type, item } = noty;
        const iconName = matchIconName(item_type);

        return (
            <ListItem
                button
                key={noty_id}
                alignItems="flex-start"
                onClick={onItemClick ? () => onItemClick(noty_id, item_type, item) : null}
                dense
                disableGutters
            >
                <ListItemAvatar>
                    <>
                        {item.avatarUrl && <Avatar src={item.avatarUrl} />}
                        {!item.avatarUrl && <Icon circular size="large" name={iconName} />}
                    </>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            {item_type !== 'user' ? title : ''}{' '}
                            <span onClick={onItemLinkClick ? event => onItemLinkClick(event, noty_id) : null}>
                                <ItemLinkSimple item_type={item_type} item={item} />
                            </span>
                        </>
                    }
                    secondary={
                        <>
                            <Time time={updateTime} />
                            {' — '}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                                style={{ display: 'inline' }}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </>
                    }
                />
                {readNoty && (
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => readNoty(noty_id)}>
                            <HighlightOffIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        );
    }
}

export default NotiListItem;
