import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Form, Checkbox, Loader, Icon } from 'semantic-ui-react';
import Divider from '~ui/Divider';

import EditField from '../../Base/EditField';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import callEventStore from '~/stores/cell/callEventStore';
import ButtonGroup from '../../Base/ButtonGroup';
import UserDropdown from '../../ItemsDropdowns/UserDropdown/index';
import HiddenBlock from '../../Base/HiddenBlock';

import EventDatesPickers from '../SubBlocks/EventDatesPickers';
import EventNoty from '../SubBlocks/EventNoty';
import { ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import authStore from '~/stores/authStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ContactIDBlockEditing from '../../Items/Common/ContactIDBlockEditing';
import { getDayOfYear, getStepDayMidday } from '../EventModal';
import DatePicker from '../../Base/DatePicker';
import { CALL_EVENT_ITEM_TYPE_CONTACT, CALL_EVENT_ITEM_TYPE_OWNER } from '~/types/events.types';
import { CALL_EVENT_ITEM_TYPE } from '~/types/events.types';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_OWNERS } from '~/types/estate.types';
import EstateLinkWithPreview from '../../Lists/Estate/EstateLinkWithPreview';

type CallEventModalProps = HandlerEditingWrappedProps & {
    onClose: () => void;
    contact_id: number | null;
    owner_id: number | null;
    callTimeMin?: number;
    callTimeMax?: number;
};

type CallEventModalState = {
    showTimeDetails: boolean;
    groups_id: Array<number>;
    dateSetValue: number;
    item_type: CALL_EVENT_ITEM_TYPE;
};

const DEFAULT_CALL_TIME_MAX_DELTA = 1800;
const DEFAULT_DATE_SET_VALUE = 1;

@observer
class CallEventBlock extends Component<CallEventModalProps, CallEventModalState> {
    constructor(props: CallEventModalProps) {
        super(props);

        this.state = {
            showTimeDetails: false,
            groups_id:
                authStore.getPermission(callEventStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP ? [authStore.currentUser.group_id] : [],
            dateSetValue: DEFAULT_DATE_SET_VALUE,
            item_type: props.owner_id ? CALL_EVENT_ITEM_TYPE_OWNER : CALL_EVENT_ITEM_TYPE_CONTACT
        };

        if (props.owner_id && this.state.item_type === CALL_EVENT_ITEM_TYPE_OWNER) {
            estateStore.fetchItem(props.owner_id, ESTATE_BASE_OWNERS);
        }
    }

    handleToggleTimeDetail = () => {
        this.setState({ showTimeDetails: !this.state.showTimeDetails });
    };

    handleAssignUsersChange = (value: number | Array<number>) => {
        if (value instanceof Array) {
            callEventStore.setEditingItem(this.props.item_id, {
                assign_users_ids: value
            });
        }
    };

    handleDateSetChange = (event: React.SyntheticEvent, { value }: { value: number }) => {
        this.setState({ dateSetValue: value });

        const callTimeMin = getStepDayMidday(value);
        callEventStore.setEditingItem(this.props.item_id, {
            callTimeMin,
            callTimeMax: callTimeMin + DEFAULT_CALL_TIME_MAX_DELTA
        });
    };

    handleCallTimeMinChange = (time: number) => {
        this.props.setUnixTime('callTimeMin', time);
        this.props.setUnixTime('callTimeMax', time + DEFAULT_CALL_TIME_MAX_DELTA);
    };

    static getDerivedStateFromProps(props: CallEventModalProps, state: CallEventModalState) {
        const { item_id, item_type, callTimeMin } = callEventStore.getItem(props.item_id).editingItem;

        if (item_id && item_type === CALL_EVENT_ITEM_TYPE_OWNER && state.item_type !== CALL_EVENT_ITEM_TYPE_OWNER) {
            estateStore.fetchItem(item_id, ESTATE_BASE_OWNERS);
            state.item_type = CALL_EVENT_ITEM_TYPE_OWNER;
        }

        if (callTimeMin) {
            return {
                ...state,
                dateSetValue: getDayOfYear(callTimeMin * 1000) - getDayOfYear(Date.now())
            };
        }
        return state;
    }

    async handleCallHasReleased(call_event_id: number, released: boolean) {
        await callEventStore.changeCallEventReleased(this.props.item_id, released);
        this.props.onClose();
    }

    render() {
        const { item_id: call_event_id, handleChange, handleTextAreaChange, setUnixTime } = this.props;
        const { showTimeDetails, groups_id, dateSetValue } = this.state;
        const { editingItem, loadingItem } = callEventStore.getItem(call_event_id);

        if (!Object.keys(editingItem).length) {
            return null;
        }

        const {
            callTimeMin,
            callTimeMax,
            title,
            description,
            assign_users_ids,
            item_id,
            item_type,
            callHasReleased,
            notificationEnable,
            notificationTime
        } = editingItem;

        let estate;
        let loadingEstate;

        if (this.state.item_type === CALL_EVENT_ITEM_TYPE_OWNER && item_id) {
            estate = estateStore.getItem(item_id).item;
            loadingEstate = estateStore.getItem(item_id).loadingItem;
        }

        return (
            <Fragment>
                <Grid stackable verticalAlign="middle">
                    <Grid.Column width={2}>
                        <label className="crm-Item__labelWide">Кому</label>
                    </Grid.Column>
                    <Grid.Column width={call_event_id === CREATING_ITEM_ID ? 14 : 9}>
                        {editingItem && item_type === CALL_EVENT_ITEM_TYPE_CONTACT && (
                            <ContactIDBlockEditing
                                store={callEventStore as any}
                                editing={item_id === null}
                                item_id={call_event_id}
                                value={item_id}
                                contactKey="item_id"
                            />
                        )}
                        {editingItem && item_type === CALL_EVENT_ITEM_TYPE_OWNER && estate && (
                            <Fragment>
                                <Icon tilte="Собственник" name="male" color="brown" />
                                <EstateLinkWithPreview estate={estate} base={ESTATE_BASE_OWNERS} />
                            </Fragment>
                        )}
                        {editingItem && item_type === CALL_EVENT_ITEM_TYPE_OWNER && loadingEstate && <Loader size="mini" />}
                    </Grid.Column>
                    {call_event_id !== CREATING_ITEM_ID && (
                        <Grid.Column width={5}>
                            <Checkbox
                                label="Звонок состоялся"
                                onClick={this.handleCallHasReleased.bind(this, call_event_id, !callHasReleased)}
                                className="crm-callEvents__feedItem_checkbox"
                                checked={Boolean(callHasReleased)}
                            />
                        </Grid.Column>
                    )}
                </Grid>

                <Divider />

                <Grid stackable>
                    <Grid.Column width={10}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Сегодня'],
                                [1, 'Завтра'],
                                [2, 'Послезавтра'],
                                [3, 'Через 3 дня']
                            ]}
                            value={dateSetValue}
                            name="dateSetValue"
                            handleChange={this.handleDateSetChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {editingItem && !loadingItem && (
                            <EventNoty
                                store={callEventStore as any}
                                item_id={call_event_id}
                                startTime={callTimeMin}
                                notificationEnable={notificationEnable}
                                notificationTime={notificationTime}
                            />
                        )}
                    </Grid.Column>
                </Grid>
                <br />

                {!showTimeDetails && editingItem && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">Когда</label>

                            <div className="crm-Estate__reportTime_picker">
                                <DatePicker time={callTimeMin} onChange={this.handleCallTimeMinChange} timeIntervals={15} />
                            </div>
                        </Form.Field>
                    </Form.Group>
                )}

                {editingItem && (
                    <HiddenBlock labelOpen="детально" labelHide="кратко" onToggle={this.handleToggleTimeDetail}>
                        <EventDatesPickers
                            store={callEventStore as any}
                            item_id={call_event_id}
                            startTime={callTimeMin}
                            endTime={callTimeMax}
                            setUnixTime={setUnixTime}
                        />
                    </HiddenBlock>
                )}

                <Divider />

                <EditField.Text label="Тема" value={title} name="title" onChange={handleChange} fluid />

                {editingItem && (
                    <HiddenBlock label="дополнительно" defaultOpen={Boolean(description)}>
                        <Fragment>
                            <EditField.Area label="Описание" onChange={handleTextAreaChange} value={description} name="description" />
                            <Form.Group>
                                <Form.Field inline size="mini" className="crm-Tasks__assignedUsers">
                                    <label className="crm-Estate__field_label">Ответственный(е)</label>
                                    <div className="ui tiny form crm-Tasks__assignedUsers_dropdown">
                                        <UserDropdown
                                            user_id={assign_users_ids}
                                            groups_id={groups_id}
                                            onChange={this.handleAssignUsersChange}
                                        />
                                    </div>
                                </Form.Field>
                            </Form.Group>
                        </Fragment>
                    </HiddenBlock>
                )}
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(callEventStore, CallEventBlock);
