import React, { useMemo, useState, ReactNode, ComponentType, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { PultDivider, PultGrow } from '~ui/FilterPult';
import authStore from '~/stores/authStore';

import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SettingsIcon from '@material-ui/icons/Settings';
import CachedIcon from '@material-ui/icons/Cached';
import kanbanStore from '~/stores/kanbanStore';
import kpiStore from '~/stores/kpiStore';
import ListError from '~/components/ListError';
import Button from '~ui/Button';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(({ spacing }) => ({
    pult: {
        width: '100%',
        marginLeft: 0,
        padding: spacing(1, 0),
        marginBottom: spacing(1)
    },
    marginLeft: {
        marginLeft: '1rem'
    }
}));

type TopPultWithButtonProps = {
    children: ReactNode;
    store: typeof kpiStore | typeof kanbanStore;
    ModalNode: ComponentType<{ onClose: () => void }>;
    PultFilter?: ReactNode;
    toggleButtons: { id: number; title: string }[];
    activeToggleId: number;
    changeToggleId: (id: number) => void;
};

const TopPultWithButton = ({
    children,
    ModalNode,
    store,
    PultFilter,
    toggleButtons,
    activeToggleId,
    changeToggleId
}: TopPultWithButtonProps) => {
    const classes = useStyles({});

    useMemo(() => {
        store.fetchList();
    }, []);

    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const toggleOpenSettingsModal = () => {
        setOpenSettingsModal(!openSettingsModal);
    };

    const { loadingList, listErrors } = store;

    const handleToggleId = (event: MouseEvent, activeTab: number) => {
        changeToggleId(activeTab);
    };

    return (
        <>
            <Paper className={classes.pult}>
                <Toolbar variant="dense">
                    <LoaderAwait active={loadingList} dimmer />

                    {activeToggleId && (
                        <Tabs
                            value={activeToggleId}
                            onChange={handleToggleId}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            {toggleButtons.map(({ title, id }) => (
                                <Tab label={title} value={id} key={id} style={{ minWidth: '100px' }} />
                            ))}
                        </Tabs>
                    )}

                    {PultFilter && (
                        <>
                            <PultDivider />
                            {PultFilter}
                        </>
                    )}

                    <PultGrow />

                    <Button icon={<CachedIcon />} color="inherit" tooltip="Обновить" onClick={() => store.fetchList()} />
                    {authStore.canCreate(store.moduleName) && (
                        <Button
                            icon={<SettingsIcon />}
                            color="inherit"
                            tooltip="Настройка"
                            onClick={toggleOpenSettingsModal}
                            className={classes.marginLeft}
                        />
                    )}

                    {openSettingsModal && <ModalNode onClose={toggleOpenSettingsModal} />}
                </Toolbar>
            </Paper>

            <ListError errors={listErrors} />
            <LoaderAwait active={loadingList} />

            {children}
        </>
    );
};

export default observer(TopPultWithButton);
