import React, { PureComponent, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import ownerStore from '~/stores/ownerStore';
import ConfirmTrigger from '../../../Base/ui/ConfirmTrigger';

type OwnerIsNotActuallyProps = {
    estate_id: number;
    disabled: boolean;
};

class OwnerIsNotActually extends PureComponent<OwnerIsNotActuallyProps> {
    handleToggleIsAlreadySold = async () => {
        await ownerStore.toggleDisableItem(this.props.estate_id, false);
    };

    render() {
        const { disabled } = this.props;

        return (
            <ConfirmTrigger
                trigger={
                    <Button
                        fluid
                        content="Неверный номер"
                        labelPosition="left"
                        icon="remove"
                        size="tiny"
                        color="red"
                        disabled={disabled}
                        title="Эта кнопка активируется после вашего звонка на номер собственника"
                    />
                }
                header="Неверный номер?"
                content="Объявление собственника уйдет в архив"
                confirmButton="Подтверждаю"
                onConfirm={this.handleToggleIsAlreadySold}
                disabled={disabled}
            />
        );
    }
}

export default OwnerIsNotActually;
