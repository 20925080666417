import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { GQMainContactFields, GQMainUserFields } from '../contactsApi';
import { CellCall, CellCallFilter } from '~/types/cellCalls.types';
import { GQUserMainFields } from '../userApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

export const GQCallsMainFields = `
    call_id
    externalId
    outer_id
    downloadLink
    answerTime
    releaseTime
`;

export const GQCallsFields = `
    ${GQCallsMainFields}
    phone
    abonent
    direction
    major_user_id
    ${GQMainUserFields}
    type
    item_id
    itemContact {
        ${GQMainContactFields}
    }
    tags_ids
    itemUser {
        ${GQUserMainFields}
    }
    startTime
    
    estate_id
    source_id
    comment
    sourceTitle
    color
    
    enable
`;

export const fetchItem = async (id: number): Promise<CellCall> => {
    const graphql = `{
        fetchCellCall(call_id: ${id}) {
            ${GQCallsFields}
        } 
      }`;

    const data: { fetchCellCall: CellCall } = await fetchApi.getGQ(graphql);
    return data.fetchCellCall;
};

export const fetchLastCellCallByPhone = async (phone: string): Promise<CellCall> => {
    const graphql = `{
        fetchLastCellCallByPhone(phone: "${phone}") {
            ${GQCallsFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchLastCellCallByPhone: CellCall }>(graphql);
    return data.fetchLastCellCallByPhone;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    callsFilter: CellCallFilter,
    controller?: AbortController
): Promise<{ list: CellCall[]; count: number }> => {
    const graphql = `{
            fetchCellCalls(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(callsFilter)}) {
                list {${GQCallsFields}}
                count
            } 
          }`;

    const data: { fetchCellCalls: { list: CellCall[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchCellCalls;
};

export const saveItem = async (call_id: number, cellCallDiff: CellCall): Promise<number> => {
    const graphql = `mutation { 
      updateCellCall(call_id: ${call_id}, cellCallDiff: ${objectToGraphql(cellCallDiff)})
    }`;

    const data = await fetchApi.postGQ<{ updateCellCall: number }>(graphql);
    return data.updateCellCall;
};

export const fetchCellCallsByContact = async (
    contact_id: number,
    limit: number,
    offset: number
): Promise<{ list: Array<CellCall>; count: number }> => {
    const graphql = `{
        fetchCellCallsByContact(contact_id: ${contact_id}, limit: ${limit}, offset: ${offset}) {
            list {
                ${GQCallsFields}
                reason
                assign_user_id
                assignUser {
                    ${GQUserMainFields}
                }
            }
            count
        } 
      }`;

    const data: { fetchCellCallsByContact: { list: CellCall[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchCellCallsByContact;
};

export const fetchCellCallsByEstate = async (estate_id: number): Promise<CellCall[]> => {
    const graphql = `{
        fetchCellCallsByEstate(estate_id: ${estate_id}) {
            ${GQCallsFields}
        } 
      }`;

    const data: { fetchCellCallsByEstate: CellCall[] } = await fetchApi.getGQ(graphql);
    return data.fetchCellCallsByEstate;
};

export const removeCellCall = async (call_id: number): Promise<boolean> => {
    const graphql = `mutation { 
      removeCellCall(call_id: ${call_id})
    }`;

    const data: { removeCellCall: boolean } = await fetchApi.postGQ(graphql);
    return data.removeCellCall;
};

export const makeCall = async (phone: string): Promise<boolean> => {
    const graphql = `mutation { 
      makeCall(phone: "${phone}")
    }`;

    const data: { makeCall: boolean } = await fetchApi.postGQ(graphql);
    return data.makeCall;
};

export const reportOpenCellCallDownload = async (call: Partial<CellCall>): Promise<boolean> => {
    const graphql = `mutation { 
      reportOpenCellCallDownload(call: ${objectToGraphql(call)})
    }`;

    const data: { reportOpenCellCallDownload: boolean } = await fetchApi.postGQ(graphql);
    return data.reportOpenCellCallDownload;
};
