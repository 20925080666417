import React, { Component, ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import SearchIcon from '@material-ui/icons/Search';

import Divider from '~ui/Divider';
import TextInput from '~ui/TextInput';
import Select from '~ui/Select';
import Checkbox from '~ui/Checkbox';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import cellCallStore from '~/stores/cell/cellCallStore';
import { CALL_TYPE_INCOMING, CALL_TYPE_OUTGOING, CellCallFilter } from '~/types/cellCalls.types';

import FilterCell from '~/components/Lists/Common/FilterCell';
import Grid from '@material-ui/core/Grid';
import callSourceStore from '~/stores/cell/callSourceStore';
import ButtonGroup from '~/components/Base/ButtonGroup';

import TagsSelect from '~/components/Base/TagsSelect';

@observer
class FilterContactsMoreDetails extends Component<FilterMoreDetailsProps, {}> {
    changeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        cellCallStore.changeFilter('search', event.target.value);
    };

    handleTagsIds = (tags_ids: number[]) => {
        cellCallStore.changeFilter('tags_ids', tags_ids);
    };

    handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        cellCallStore.changeFilter(event.target.name as keyof CellCallFilter, event.target.checked);
    };

    handleChangeDirection = (event: React.SyntheticEvent, { value }: { value: string }) => {
        if (Number(value) !== CALL_TYPE_INCOMING) {
            cellCallStore.changeFilter('source_id', 0);
        }

        cellCallStore.changeFilter('direction', Number(value));
    };

    handleChange = (event, { type, name, value }: { type: string; name: keyof CellCallFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        cellCallStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    render() {
        const { search, tagsTogether, direction, source_id, tags_ids, durationFromSec } = cellCallStore.listFilter;
        const { allCallSourcesDropdown, loadingFetchAllSources } = callSourceStore;

        return (
            <div>
                <TextInput onChange={this.changeSearch} label="Номер телефона" value={search} fullWidth startLabel={<SearchIcon />} />

                <Divider />

                <FilterCell title="Тэги контактов">
                    <Grid item sm={6} xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={tagsTogether} onChange={this.handleCheckbox} name="tagsTogether" />}
                            label="тэги вместе"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TagsSelect tags_ids={tags_ids} setTagIds={this.handleTagsIds} label="Выбрать тэг(и)" />
                    </Grid>
                </FilterCell>

                <FilterCell title="Направление / Источник" titleFullWidth>
                    <Grid item sm={6} xs={12}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [CALL_TYPE_INCOMING, 'Входящие'],
                                [CALL_TYPE_OUTGOING, 'Исходящие']
                            ]}
                            name="direction"
                            value={direction}
                            handleChange={this.handleChangeDirection}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Select
                            label="Источник"
                            options={allCallSourcesDropdown}
                            value={source_id}
                            onChange={this.handleChange}
                            name="source_id"
                            loading={loadingFetchAllSources}
                            disabled={direction !== CALL_TYPE_INCOMING}
                            fullWidth
                        />
                    </Grid>
                </FilterCell>

                <FilterCell title="Длительность" titleFullWidth>
                    <Grid item sm={6} xs={12}>
                        <ButtonGroup
                            buttonSet={[
                                [null, 'Все'],
                                [-1, 'Пропущенные'],
                                [0, 'Состоявшиеся'],
                                [30, 'От 30 секунд'],
                                [60, 'От 60 секунд']
                            ]}
                            name="durationFromSec"
                            value={durationFromSec}
                            handleChange={this.handleChange}
                        />
                    </Grid>
                </FilterCell>
            </div>
        );
    }
}

export default FilterContactsMoreDetails;
