import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Dropdown, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import EditField from '~/components/Base/EditField';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import dealStore from '~/stores/dealStore';
import estateL10n from '~/L10n/estate.L10n';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_NEWBUILDING_ID, DEAL_TYPE_ACCOMPANIMENT_ID, DEAL_TYPE_MORTGAGE_ID } from '~/types/deals.types';
import DatePicker from '~/components/Base/DatePicker';

export const deadTimeTitle = (type: number) =>
    type === DEAL_TYPE_EXCLUSIVE_ID ? 'Эксклюзив до' : type === DEAL_TYPE_MORTGAGE_ID ? 'Одобрение до' : 'Аванс истекает';

type DealPriceEditingProps = HandlerEditingWrappedProps & {
    price?: number;
    type?: number;
    currency?: number;
    isMortgage?: boolean;
    bank_id?: number;
    mortgagePercent?: number;
    mortgageDuration?: number;
    deposit?: number;
    depositDeadTime?: number;
    exclusiveDeadTime?: number;
    depositTime?: number;
    firstDeposit: number | null;
};

@observer
class DealPriceEditing extends Component<DealPriceEditingProps> {
    constructor(props: DealPriceEditingProps) {
        super(props);

        const deal = {
            price: props.price || 0,
            type: props.type || ESTATE_TYPE_SELL_ID,
            currency: props.currency || 1,
            isMortgage: props.isMortgage || null,
            bank_id: props.bank_id || null,
            mortgagePercent: props.mortgagePercent || null,
            mortgageDuration: props.mortgageDuration || null,
            deposit: props.deposit || null,
            depositDeadTime: props.depositDeadTime || null,
            exclusiveDeadTime: props.exclusiveDeadTime || null,
            depositTime: props.depositTime || null,
            firstDeposit: props.firstDeposit || null
        };
        dealStore.setEditingItem(props.item_id, deal);

        dealStore.fetchBanks();
    }

    render() {
        const { item_id, handlePrice, handleChange, handleCheckbox, setUnixTime, editing } = this.props;

        const {
            price,
            currency,
            type,
            isMortgage,
            bank_id,
            mortgagePercent,
            mortgageDuration,
            deposit,
            exclusiveDeadTime,
            depositDeadTime,
            depositTime,
            firstDeposit
        } = dealStore.getItem(item_id).editingItem;

        const { loadingBanks, banks } = dealStore;
        const DEAD_TIME_TITLE = deadTimeTitle(type);

        return (
            <Fragment>
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">
                            Цена
                            {!editing && ` объекта`}
                        </label>
                        <Input
                            autoComplete="off"
                            size="mini"
                            label={
                                <Dropdown
                                    value={currency}
                                    name="currency"
                                    onChange={handleChange}
                                    options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                />
                            }
                            labelPosition="right"
                            placeholder="Цена"
                            value={price ? price.toLocaleString() : ''}
                            onChange={handlePrice}
                            name="price"
                            className="crm-Estate__field_rightMargin"
                        />
                        {type === ESTATE_TYPE_RENT_ID && ' в месяц '}
                        {[ESTATE_TYPE_SELL_ID, DEAL_TYPE_ACCOMPANIMENT_ID, DEAL_TYPE_NEWBUILDING_ID].includes(type) && (
                            <Fragment>
                                {editing && <br />}
                                <Checkbox
                                    checked={Boolean(isMortgage)}
                                    name="isMortgage"
                                    toggle
                                    label={<label>Ипотека</label>}
                                    onClick={handleCheckbox}
                                />
                            </Fragment>
                        )}
                    </Form.Field>
                </Form.Group>
                {isMortgage && (
                    <Grid>
                        <Grid.Column computer={editing ? 8 : 4} tablet={16} mobile={16}>
                            <EditField.Drop
                                loading={loadingBanks}
                                label="Банк"
                                name="bank_id"
                                value={bank_id}
                                onChange={handleChange}
                                options={banks}
                            />
                        </Grid.Column>
                        <Grid.Column computer={editing ? 8 : 4} tablet={8} mobile={16}>
                            <EditField.InputNumber
                                label="Ставка"
                                onChange={handleChange}
                                min={0}
                                max={100}
                                value={mortgagePercent}
                                name="mortgagePercent"
                                step={0.1}
                                extraLabel="%"
                            />
                        </Grid.Column>
                        <Grid.Column computer={editing ? 8 : 4} tablet={8} mobile={16}>
                            <EditField.InputNumber
                                label="Срок"
                                onChange={handleChange}
                                min={0}
                                max={100}
                                value={mortgageDuration}
                                name="mortgageDuration"
                                step={1}
                                extraLabel="лет"
                            />
                        </Grid.Column>
                        <Grid.Column computer={editing ? 8 : 4} tablet={8} mobile={16}>
                            <EditField.InputNumber
                                label="Перв. Взнос"
                                onChange={handleChange}
                                min={0}
                                max={99999999}
                                value={firstDeposit}
                                name="firstDeposit"
                                step={1}
                                extraLabel={estateL10n.CURRENCY_TYPES_FIND(1)}
                            />
                        </Grid.Column>
                    </Grid>
                )}
                <Grid stackable verticalAlign="middle">
                    {![DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type) && (
                        <Fragment>
                            <Grid.Column width={editing ? 16 : 8}>
                                <label className="crm-Estate__field_label">Внесенный аванс</label>
                                <Input
                                    size={'mini'}
                                    placeholder="Внесенный аванс"
                                    value={deposit ? deposit.toLocaleString() : ''}
                                    onChange={handlePrice}
                                    name="deposit"
                                    className="crm-Estate__field_rightMargin"
                                />
                                {estateL10n.CURRENCY_TYPES_FIND(1)}
                            </Grid.Column>
                            <Grid.Column width={editing ? 5 : 3} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                <label className={`crm-Estate__field_label ${!editing ? 'crm-Item__datePickerLabel' : ''}`}>
                                    Дата внесения аванса
                                </label>
                            </Grid.Column>
                            <Grid.Column width={editing ? 11 : 5} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                <div className="crm-Tasks__deadLine_picker">
                                    <DatePicker time={depositTime} onChange={time => setUnixTime('depositTime', time)} clearable />
                                </div>
                            </Grid.Column>
                        </Fragment>
                    )}

                    {type !== ESTATE_TYPE_RENT_ID && (
                        <Fragment>
                            <Grid.Column width={editing ? 5 : 3} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                <label className={`crm-Estate__field_label ${!editing ? 'crm-Item__datePickerLabel' : ''}`}>
                                    {DEAD_TIME_TITLE}
                                </label>
                            </Grid.Column>

                            <Grid.Column width={editing ? 11 : 5} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                <div className="crm-Tasks__deadLine_picker">
                                    <DatePicker
                                        time={
                                            [DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type)
                                                ? exclusiveDeadTime
                                                : depositDeadTime
                                        }
                                        onChange={time =>
                                            setUnixTime(
                                                [DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type)
                                                    ? 'exclusiveDeadTime'
                                                    : 'depositDeadTime',
                                                time
                                            )
                                        }
                                        clearable
                                    />
                                </div>
                            </Grid.Column>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealPriceEditing);
