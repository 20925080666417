import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DateRange, DesktopDateRangePicker } from '@material-ui/pickers';
import Divider from '@material-ui/core/Divider';

import { deltaCreateTimes } from '../../Lists/ListFilterWrapper';
import { toDateString } from '~/common/time';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    otherRange: {
        fontWeight: 500
    }
}));

const OPEN_CALENDAR_VALUE = 1;

const DatesSelect = ({
    value,
    onChange,
    handleOpenCalendar,
    startTime,
    endTime
}: {
    value: number | null;
    onChange: (value: number) => void;
    handleOpenCalendar: () => void;
    startTime: number | null;
    endTime: number | null;
}) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log('handleChange', Number(event.target.value));
        onChange(Number(event.target.value));
    };

    let otherPeriodTitle = '';
    if (startTime || endTime) {
        otherPeriodTitle += `с ${startTime ? toDateString(startTime) : '..'}`;
        otherPeriodTitle += ` по ${endTime ? toDateString(endTime) : '..'}`;
    } else {
        otherPeriodTitle = 'Указать период';
    }

    return (
        <FormControl className={classes.formControl}>
            <Select value={value === null ? OPEN_CALENDAR_VALUE : value} onChange={handleChange} autoWidth>
                {deltaCreateTimes.map(({ value, text }) => (
                    <MenuItem key={value} value={value}>
                        {text}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem value={OPEN_CALENDAR_VALUE} className={classes.otherRange} onClick={handleOpenCalendar}>
                    {otherPeriodTitle}
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export type DATES_RANGE_VALUE = {
    startTime: number | null;
    endTime: number | null;
    deltaTime: number | null;
};

const useUpdateEffect = (callback, deps) => {
    const hasMount = useRef(false);

    useEffect(() => {
        if (hasMount.current) {
            callback();
        } else {
            hasMount.current = true;
        }
    }, deps);
};

const DateRangeSelect = ({ onChange, initialValue }: { onChange: (times: DATES_RANGE_VALUE) => void; initialValue: number }) => {
    const [open, setOpen] = useState(false);
    const [deltaTime, setDeltaTime] = React.useState<number | null>(initialValue);
    const [startTime, setStartTime] = React.useState<number | null>(null);
    const [endTime, setEndTime] = React.useState<number | null>(null);

    const handleOpenCalendar = () => {
        setOpen(true);
        setStartTime(null);
        setEndTime(null);
    };

    const handleToggleClose = () => {
        setOpen(false);
    };

    useUpdateEffect(() => {
        console.log({ deltaTime, startTime, endTime });
        if ((deltaTime === OPEN_CALENDAR_VALUE || deltaTime === null) && (startTime || endTime)) {
            onChange({ startTime, endTime, deltaTime: null });
        } else if (deltaTime !== OPEN_CALENDAR_VALUE && deltaTime !== null) {
            onChange({ startTime: null, endTime: null, deltaTime });
        }
    }, [deltaTime, startTime, endTime]);

    const handleChangeDate = (date: DateRange<Date>) => {
        const [fromDate, toDate] = date;
        if (fromDate || toDate) {
            setDeltaTime(null);
            setStartTime(null);
            setEndTime(null);
            if (fromDate) {
                setStartTime(fromDate.getTime() / 1000);
            }
            if (toDate) {
                setEndTime(toDate.getTime() / 1000);
            }
            if (fromDate && toDate) {
                handleToggleClose();
            }
        }
    };

    const [value, setValue] = useState<DateRange<Date>>([null, null]);

    return (
        <DesktopDateRangePicker
            reduceAnimations
            value={value}
            onChange={handleChangeDate}
            mask={'__.__.____'}
            open={open}
            maxDate={new Date()}
            onClose={handleToggleClose}
            renderInput={(startProps, endProps) => (
                <DatesSelect
                    startTime={startTime}
                    endTime={endTime}
                    value={deltaTime}
                    onChange={setDeltaTime}
                    handleOpenCalendar={handleOpenCalendar}
                />
            )}
        />
    );
};

export default DateRangeSelect;
