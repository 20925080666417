import React, { useState, ChangeEvent } from 'react';

import { observer } from 'mobx-react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import TableCell from '@material-ui/core/TableCell';
import commonStore from '~/stores/commonStore';

import Progress from '../../Base/ui/Progress';
import Select from '../../Base/ui/Select';

type ExportBaseRegionsProps = {
    export_base_id: number;
    colSpan: number;
    region_id: number;
    onRegionChange: (region_id: number) => void;
};

const ExportBaseRegions = ({ export_base_id, colSpan, region_id, onRegionChange }: ExportBaseRegionsProps) => {
    const [showRegionButton, setShowRegionButton] = useState(false);

    const [newRegionId, changeNewRegionId] = useState(0);
    const handleChangeNewRegion = (event: ChangeEvent<HTMLInputElement>) => {
        changeNewRegionId(Number(event.target.value));
    };

    const handleChangeRegionId = (event: ChangeEvent, { value }: { value: number }) => {
        onRegionChange(value);
    };

    const { regionIds } = exportingBaseStore.getItem(export_base_id).editingItem;
    const { regions, loadingRegions, regionsById } = exportingBaseStore;

    const regionsAdjusted =
        regions.length > 0
            ? regionIds.map(region_id => {
                  const { title, type } = regionsById.get(region_id) || {};
                  return {
                      key: region_id,
                      value: region_id,
                      text: `${title} ${type}`
                  };
              })
            : [];

    const regionsAllAdjusted =
        regions.length > 0
            ? regions
                  .filter(({ region_id }) => !regionIds.includes(region_id))
                  .map(({ region_id, title, type }) => ({ key: region_id, value: region_id, text: `${title} ${type}` }))
            : [];

    return (
        <>
            <TableCell align="left" colSpan={1}>
                <Progress show={loadingRegions} size={36} />

                <Select
                    label="Регион"
                    value={region_id}
                    options={regionsAdjusted}
                    disabled={loadingRegions}
                    loading={loadingRegions}
                    onChange={handleChangeRegionId}
                />
            </TableCell>

            <TableCell align="left" colSpan={colSpan - 1}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    {!commonStore.isMobile && !showRegionButton && (
                        <Tooltip title="Добавить регион">
                            <IconButton onClick={() => setShowRegionButton(!showRegionButton)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!commonStore.isMobile && showRegionButton && (
                        <>
                            <Select
                                label="Добавить регион"
                                value={newRegionId || ''}
                                options={regionsAllAdjusted}
                                disabled={loadingRegions}
                                loading={loadingRegions}
                                onChange={handleChangeNewRegion}
                                style={{ minWidth: '200px' }}
                            />

                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => exportingBaseStore.addRegionId(export_base_id, newRegionId)}
                                disabled={loadingRegions}
                            >
                                Добавить
                            </Button>

                            <Tooltip title="Свернуть" style={{ marginLeft: '1em' }}>
                                <IconButton onClick={() => setShowRegionButton(!showRegionButton)}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Grid>
            </TableCell>
        </>
    );
};

export default observer(ExportBaseRegions);
