import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';

import { DOMCLICK_API_KEY } from '~/types/settings.types';
import DomclickApiSettingsField from '~/components/Ads/BaseApiSettings/Fields/DomclickApiSettingsField';
import ApiSettingsWrapper from '~/components/Ads/BaseApiSettings/ApiSettingsWrapper';

const DomclickApiSettings = () => {
    return (
        <ApiSettingsWrapper
            apiKey={DOMCLICK_API_KEY}
            FieldComponent={DomclickApiSettingsField}
            defaultSettingsValue={{ token: '', reportUrl: '' }}
        >
            <Typography>
                Что нужно сделать, чтобы получить доступ к обратному фиду?
                <ul>
                    <li>
                        1.&nbsp;
                        <a href="https://helpdesk.domclick.ru/create" target="_blank" rel="noreferrer">
                            Создать обращение
                        </a>
                        &nbsp; в службу поддержки от лица руководителя компании
                    </li>
                    <li>2. Получить ссылку и токен (ключ доступа) в ответном сообщении от службы поддержки</li>
                </ul>
            </Typography>
            <a
                href="https://blog.domclick.ru/voprosy-i-otvety/partnyoram/obuyavleniya-na-domklik/razmeshenie-obuyavlenij-dlya-kompanij/poluchenie-statistiki-po-api"
                target="_blank"
                rel="noreferrer"
            >
                Источник
            </a>
        </ApiSettingsWrapper>
    );
};

export default observer(DomclickApiSettings);
