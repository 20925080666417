import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExportFeedSettingsEditing from './Blocks/ExportFeedSettingsEditing';
import { useToggle } from '~ui/Modal';

type ExportFeedSettingsProps = {
    export_base_id: number;
};

const ExportFeedSettings = ({ export_base_id }: ExportFeedSettingsProps) => {
    const [settingsIsOpen, toggleIsOpen] = useToggle();

    return (
        <Box flex={1} m={1}>
            <Accordion expanded={settingsIsOpen} onChange={toggleIsOpen}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Настройки фида</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ExportFeedSettingsEditing export_base_id={export_base_id} onClose={toggleIsOpen} />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ExportFeedSettings;
