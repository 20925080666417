import React, { Component, Fragment } from 'react';
import Divider from '~ui/Divider';
import { observer } from 'mobx-react';
import EditField from '../../../Base/EditField';

import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import wishStore from '~/stores/wishStore';
import FilterEstateMain from '../../../Lists/Estate/FilterBlocks/FilterEstateMain';

type WishFilterEditionProps = HandlerEditingWrappedProps & {
    propertyType?: number;
    type?: number;

    priceMin?: number | null;
    priceMax?: number | null;
    priceM2Min?: number | null;
    priceM2Max?: number | null;

    totalAreaMin?: number | null;
    totalAreaMax?: number | null;
    livingAreaMin?: number | null;
    livingAreaMax?: number | null;
    kitchenAreaMin?: number | null;
    kitchenAreaMax?: number | null;
    floorNumberMin?: number | null;
    floorNumberMax?: number | null;
    floorsCountMin?: number | null;
    lastFloorOnly?: boolean | null;

    materialType?: Array<number>;
    buildYearMin?: number | null;
    buildYearMax?: number | null;

    roomsCount?: Array<number>;

    main_metro_id?: Array<number>;
    transportType?: number | null;
    transportTime?: number | null;

    hasLoggia?: boolean | null;
    hasBalcone?: boolean | null;
    wcType?: number | null;

    description?: string;
    mortgageApproved?: boolean;
    town?: string;
    cityDistrict?: string;
};

@observer
class WishFilterEdition extends Component<WishFilterEditionProps> {
    constructor(props: WishFilterEditionProps) {
        super(props);

        const item = {
            propertyType: props.propertyType || 1,
            type: props.type || 1,

            priceMin: props.priceMin || null,
            priceMax: props.priceMax || null,
            priceM2Min: props.priceM2Min || null,
            priceM2Max: props.priceM2Max || null,

            totalAreaMin: props.totalAreaMin || null,
            totalAreaMax: props.totalAreaMax || null,
            livingAreaMin: props.livingAreaMin || null,
            livingAreaMax: props.livingAreaMax || null,
            kitchenAreaMin: props.kitchenAreaMin || null,
            kitchenAreaMax: props.kitchenAreaMax || null,
            floorNumberMin: props.floorNumberMin || null,
            floorNumberMax: props.floorNumberMax || null,
            floorsCountMin: props.floorsCountMin || null,
            lastFloorOnly: props.lastFloorOnly || null,

            materialType: props.materialType || [],
            buildYearMin: props.buildYearMin || null,
            buildYearMax: props.buildYearMax || null,

            roomsCount: props.roomsCount || [],
            main_metro_id: props.main_metro_id || [],

            transportType: props.transportType || null,
            transportTime: props.transportTime || null,

            hasLoggia: props.hasLoggia || null,
            hasBalcone: props.hasBalcone || null,
            wcType: props.wcType || 0,

            description: props.description || '',
            mortgageApproved: props.mortgageApproved || null,
            town: props.town || null,
            cityDistrict: this.props.cityDistrict || null
        };

        wishStore.setEditingItem(props.item_id, item);
    }

    handleMetroChange = (metroIds: number[]) => {
        wishStore.setEditingItem(this.props.item_id, {
            main_metro_id: [...metroIds]
        });
    };

    render() {
        const { item_id, handleChange, handleTextAreaChange } = this.props;
        const editingItem: any = wishStore.getItem(item_id).editingItem;
        const { description, mortgageApproved, town, cityDistrict } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Area label="Описание" value={description || null} name="description" onChange={handleTextAreaChange} />

                <EditField.ButtonGroup
                    label="Ипотека"
                    buttonSet={[
                        [null, 'Неизвестно'],
                        [false, 'Не одобрена'],
                        [true, 'Одобрена']
                    ]}
                    name="mortgageApproved"
                    value={typeof mortgageApproved !== 'boolean' ? null : mortgageApproved}
                    handleChange={handleChange}
                />

                <EditField.Text label="Город" name="town" value={town} onChange={handleChange} />
                <EditField.Text label="Район" name="cityDistrict" value={cityDistrict} onChange={handleChange} />

                <Divider />

                <FilterEstateMain
                    propertyTypes={[this.props.propertyType]}
                    main_metro_id={[]}
                    simpleMode
                    handleMetroChange={this.handleMetroChange}
                    handleChange={handleChange}
                    {...editingItem}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(wishStore, WishFilterEdition);
