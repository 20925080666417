import React, { Component, PureComponent, Fragment } from 'react';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import Time from '../../../Base/Time';
import ShadowFragment from '../../../Base/ShadowFragment';

import ItemContactsEditing from './ItemContactsEditing';
import ListWithContactsStorePrototype from '~/stores/prototypes/listWithContactsStore.prototype';
import EditingBlockWrapper from '../../EditingBlockWrapper';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { USER_MALE_SEX_ID } from '~/types/users.types';
import contactStore from '~/stores/contactStore';
import { User } from '~/types/users.types';
import { Contact } from '~/types/contacts.types';

import PhonesListWithMessengers, { DenseListItem } from '../../../Base/PhonesListWithMessengers';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { WarningMessage } from '~ui/Message';

export type ItemContactsProps = {
    store: ListWithContactsStorePrototype<any, any, any, any>;
    item_id: number;
};

const ItemContacts = (store: ListWithContactsStorePrototype<any, any, any, any>) => {
    @observer
    class ItemContactsClass extends Component<EditingWrappedProps> {
        render() {
            const { item_id, editing } = this.props;
            const { item, loadingItem } = store.getItem(item_id);
            const { innerPhones, phones, email, enable, onlineTime, sex, dismissalTime }: Partial<User & Contact> = item || {};

            return (
                <Card fluid color="brown">
                    <Card.Content>
                        {!editing && (
                            <Fragment>
                                <List dense disablePadding>
                                    {!loadingItem && (
                                        <PhonesListWithMessengers showExport={store === userStore} phones={Array.from(phones)} />
                                    )}

                                    {(email || loadingItem) && (
                                        <DenseListItem disableGutters dense>
                                            <ListItemIcon>
                                                <AlternateEmailIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <ShadowFragment
                                                        print={
                                                            !loadingItem && (
                                                                <Typography variant="subtitle1">
                                                                    <a href={`mailto:${email}`}>{email}</a>
                                                                </Typography>
                                                            )
                                                        }
                                                    />
                                                }
                                            />
                                        </DenseListItem>
                                    )}
                                    {innerPhones &&
                                        innerPhones.map(innerPhone => (
                                            <DenseListItem disableGutters dense key={innerPhone}>
                                                <ListItemIcon>
                                                    <HeadsetMicIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={innerPhone} />
                                            </DenseListItem>
                                        ))}
                                </List>
                                {phones && !phones.length && <WarningMessage>Телефон не указан</WarningMessage>}
                            </Fragment>
                        )}

                        {editing && !loadingItem && (
                            <ItemContactsEditing
                                store={store}
                                phones={phones && phones.map(({ phone, phone_id }) => ({ phone, phone_id }))}
                                email={email}
                                item_id={item_id}
                                innerPhones={innerPhones && [...innerPhones]}
                            />
                        )}
                    </Card.Content>
                    {!editing && store === userStore && enable && (
                        <Card.Content extra>
                            <ShadowFragment print={!loadingItem && <Time time={onlineTime || 0} status={true} />} />
                        </Card.Content>
                    )}
                    {!editing && store === userStore && !loadingItem && !enable && (
                        <Card.Content extra>
                            {!enable &&
                                `${sex === USER_MALE_SEX_ID ? 'Уволился' : 'Уволилась'} ${
                                    dismissalTime ? new Date(dismissalTime * 1000).toLocaleDateString('ru') : '---'
                                }`}
                        </Card.Content>
                    )}
                </Card>
            );
        }
    }

    return ItemContactsClass;
};

@observer
class ItemContactsWrapper extends Component<{ store: typeof userStore | typeof contactStore; item_id: number }> {
    render() {
        const { store, item_id } = this.props;

        // Тянем loadingItem, чтобы подписаться на реактивность mobx
        const { loadingItem } = store.getItem(item_id);

        const ItemContactsWithStore = EditingBlockWrapper(store, ItemContacts(store), (item_id: number) => {
            store.saveContacts(item_id);
        });

        return <ItemContactsWithStore item_id={item_id} />;
    }
}

export default ItemContactsWrapper;
