import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import developerStore from '~/stores/lifeComplexes/developerStore';
import Select from '~ui/Select';

const DeveloperSelect = ({ developer_id, onChange }: { developer_id: number | null; onChange: (developer_id: number) => void }) => {
    useMemo(() => {
        developerStore.fetchList();
    }, []);

    const { list, loadingList } = developerStore;

    const handleChange = (_, { value }: { value: number }) => {
        onChange(value);
    };

    return (
        <Select
            loading={loadingList}
            label="Выберите застройщика"
            options={list.map(({ developer_id, name }) => ({ value: developer_id, text: name }))}
            value={developer_id}
            onChange={handleChange}
            fullWidth
        />
    );
};

export default observer(DeveloperSelect);
