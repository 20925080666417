import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { GQUserMainFields } from './userApi';
import { MeetingEvent, MeetingEventFilter } from '~/types/events.types';
import { GQMainContactFields } from './contactsApi';
import { GQEstateMainFields } from './estateApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const GQMeetingEventMainFields = `
    meeting_event_id
    goal
    description
    
    item_id
    item_type
    contact {
        ${GQMainContactFields}
        phones {
            phone_id
            phone
        }
    }
    estate {
        ${GQEstateMainFields}
    }
    
    startTime
    endTime
    
    notificationEnable
    notificationTime
    meetingHasReleased
    
    enable
`;

const GQMainEventFields = `
    ${GQMeetingEventMainFields}
    
    assign_users_ids
    assign_users {
        ${GQUserMainFields}    
    }
    
    createTime
    updateTime
`;

export const fetchItem = async (id: number): Promise<MeetingEvent> => {
    const graphql = `{
        fetchMeetingEvent(meeting_event_id: ${id}) {
            ${GQMainEventFields}
        } 
      }`;

    const data: { fetchMeetingEvent: MeetingEvent } = await fetchApi.getGQ(graphql);
    return data.fetchMeetingEvent;
};

export const createItem = async (meetingEvent: MeetingEvent): Promise<number> => {
    const graphql = `mutation { 
          createMeetingEvent(meetingEventInput: ${objectToGraphql(meetingEvent)})
        }`;

    const data: { createMeetingEvent: number } = await fetchApi.postGQ(graphql);
    return data.createMeetingEvent;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    meetingEventFilter: MeetingEventFilter,
    controller?: AbortController
): Promise<{ list: MeetingEvent[]; count: number }> => {
    const graphql = `{
        fetchMeetingEvents(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
        sort: "${orderDirection}", filter: ${objectToGraphql(meetingEventFilter)}) {
            list {${GQMeetingEventMainFields}}
            count
        } 
      }`;

    const data: { fetchMeetingEvents: { list: MeetingEvent[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchMeetingEvents;
};

export const saveItem = async (meeting_event_id: number, meetingEvent: MeetingEvent): Promise<number> => {
    const graphql = `mutation { 
        updateMeetingEvent(meeting_event_id: ${meeting_event_id}, meetingEventDiff: ${objectToGraphql(meetingEvent)})
    }`;

    const data: { updateMeetingEvent: number } = await fetchApi.postGQ(graphql);
    return data.updateMeetingEvent;
};

export const meetingEventsByContact = async (contact_id: number): Promise<MeetingEvent[]> => {
    const graphql = `{
        meetingEventsByContact(contact_id: ${contact_id}) {
            ${GQMeetingEventMainFields}
        } 
      }`;

    const data: { meetingEventsByContact: MeetingEvent[] } = await fetchApi.getGQ(graphql);
    return data.meetingEventsByContact;
};
