import React, { Fragment } from 'react';
import estateL10n from '../../../../L10n/estate.L10n';
import MonoString from '../../../Base/MonoString';

export default ({
    materialType,
    buildYearMin,
    buildYearMax
}: {
    materialType?: Array<number> | null;
    buildYearMin: number | null;
    buildYearMax: number | null;
}) => (
    <Fragment>
        {(buildYearMin || buildYearMax) && (
            <div>
                <MonoString template={'Постройки: ? – ? гг.'} values={[buildYearMin || '...', buildYearMax || '...']} />
            </div>
        )}
        {materialType && (
            <MonoString
                template={materialType.map(materialTypeNumber => estateL10n.HOUSE_MATERIAL_TYPES_FIND(materialTypeNumber)).join(', ')}
            />
        )}
    </Fragment>
);
