import React, { Component, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import estateStore from '~/stores/estateStore';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import FilterCell from '~/components/Lists/Common/FilterCell';

import FilterEstateOtherParams from './FilterEstateOtherParams';
import FilterMoreDetailsMain from './FilterMoreDetailsMain';
import FilterEstateRegion from './FilterEstateRegion';
import FilterEstateDeltaTime from '~/components/Lists/Estate/FilterBlocks/FilterEstateDeltaTime';

import ButtonGroup from '~/components/Base/ButtonGroup';
import { ListFilterHistoryList } from '~/components/Lists/Common/ListFilterHistory';
import { adjustEstateListBtns } from '~/components/Lists/Estate';
import { ESTATE_BASE_FOREIGN } from '~/types/estate.types';

enum ESTATE_SEARCH_TABS {
    MAIN,
    SETTINGS,
    HISTORY
}

// Нельзя превратить в const FilterMoreDetailsBlock () => т.к ListFilterWrapper иначе не сработает
@observer
class FilterMoreDetailsBlock extends Component<FilterMoreDetailsProps, { tabName: ESTATE_SEARCH_TABS }> {
    constructor(props: FilterMoreDetailsProps) {
        super(props);
        this.state = {
            tabName: ESTATE_SEARCH_TABS.MAIN
        };
    }

    handleChangeBase = (event, { value }) => {
        // @ts-ignore
        estateStore.changeFilterBase(value);
    };

    handleChangeTab = (event: SyntheticEvent, tabName: ESTATE_SEARCH_TABS) => {
        this.setState({ tabName });
    };

    render() {
        const { propertyType, type, base } = estateStore.listFilter;
        const { listFiltersSaved } = estateStore;

        const { handleChange } = this.props;
        const { tabName } = this.state;

        return (
            <div>
                <FilterCell title="Регион">
                    {base !== ESTATE_BASE_FOREIGN && (
                        <>
                            <Grid item xs={6}>
                                <FilterEstateRegion />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <ButtonGroup
                                    buttonSet={adjustEstateListBtns(propertyType)}
                                    name="base"
                                    value={base === undefined ? null : base}
                                    handleChange={this.handleChangeBase}
                                    stackable
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item sm={6} xs={12}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [1, 'Продажа'],
                                [2, 'Аренда']
                            ]}
                            name="type"
                            value={type}
                            handleChange={handleChange}
                        />
                    </Grid>
                </FilterCell>

                <Tabs value={tabName} indicatorColor="secondary" textColor="secondary" onChange={this.handleChangeTab} variant="fullWidth">
                    <Tab label="Параметры" value={ESTATE_SEARCH_TABS.MAIN} />
                    <Tab label="Установки" value={ESTATE_SEARCH_TABS.SETTINGS} disabled={base === ESTATE_BASE_FOREIGN} />
                    <Tab label="Сохраненные" value={ESTATE_SEARCH_TABS.HISTORY} />
                </Tabs>

                <div style={{ padding: '1rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    {tabName === ESTATE_SEARCH_TABS.MAIN && <FilterMoreDetailsMain {...this.props} />}
                    {tabName === ESTATE_SEARCH_TABS.SETTINGS && <FilterEstateOtherParams {...this.props} />}
                    {tabName === ESTATE_SEARCH_TABS.HISTORY && <ListFilterHistoryList filters={listFiltersSaved} />}
                </div>

                <FilterCell title="Актуальность" hideDivider>
                    <Grid item xs={6}>
                        <FilterEstateDeltaTime />
                    </Grid>
                </FilterCell>
            </div>
        );
    }
}

export default FilterMoreDetailsBlock;
