import React, { PureComponent, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';

import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import userStore from '~/stores/userStore';
import { UserLinkState } from '../../Lists/Users/UserLink';
import uiConfig from '../../../config/ui.config';

class UserTabMenu extends PureComponent<TabMenuWrapperProps> {
    handleShareLink = async () => {
        const { item_id } = this.props;
        const { item } = userStore.getItem(item_id);
        if (item && uiConfig.userShare) {
            const { siteLink, firstName, lastName } = item;
            const { link, title, text } = uiConfig.userShare;

            const shareConf = {
                url: link.replace('[siteLink]', String(siteLink)),
                title: title(firstName, lastName),
                text: text(firstName, lastName)
            };
            window.navigator['share'](shareConf);
        }
    };

    render() {
        const { item_id } = this.props;
        const { item } = userStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { siteLink } = item;

        return (
            <Fragment>
                {uiConfig.userShare && window.navigator['share'] && (
                    <MenuItem onClick={this.handleShareLink}>
                        <Icon name="share square" />
                        Поделиться
                    </MenuItem>
                )}
                {uiConfig.userShare && (
                    <MenuItem component={'a' as any} href={uiConfig.userShare.link.replace('[siteLink]', String(siteLink))} target="_blank">
                        <Icon name="external" />
                        Открыть на сайте
                    </MenuItem>
                )}
            </Fragment>
        );
    }
}

export default TabMenuWrapper(userStore, UserLinkState, UserTabMenu, {
    attachDocuments: true
});
