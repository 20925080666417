import React, { Fragment, PureComponent } from 'react';
import { Comment, Input, Icon, Ref } from 'semantic-ui-react';
import { CommentType } from '~/types/comments.types';
import Time from '../../../Base/Time';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';
import config from '../../../../config/ui.config';
import authStore from '~/stores/authStore';
import { UserLinkType } from '~/types/users.types';
import urlify from '../../../../common/urlify';
import { nl2br } from '~/api/commentsApi';
import UserAvatar from '~ui/UserAvatar';

type CommentsFeedProps = {
    comments: CommentType[];
    minimal?: boolean;
    reply_to: number | null;
    setReply: (reply_to: number | null) => void;
    sendComment: (comment: string) => Promise<void>;
    isReplies?: boolean;
    removeComment: (comment_id: number) => void;
};

const AVAILABLE_DELETING_TIME = 900; // 15 mins

const availableDeleting = (user: UserLinkType, time: number, replies?: CommentType[]): boolean => {
    return (
        (!replies || replies.length === 0) &&
        user.user_id === authStore.currentUser.user_id &&
        Date.now() / 1000 - time < AVAILABLE_DELETING_TIME
    );
};

class CommentsFeed extends PureComponent<CommentsFeedProps> {
    $input: any = null;

    setReply = (reply_to: number | null) => {
        this.props.setReply(reply_to);
        setTimeout(() => {
            if (this.$input) {
                this.$input.querySelector('input').focus();
            }
        });
    };

    handleKeyPress = ({ key, target: { value } }: { key: string; target: { value: string } }) => {
        if (key === 'Enter') {
            this.props.sendComment(value);
        }
    };

    handleSend = () => {
        if (this.$input) {
            const { value } = this.$input.querySelector('input');
            this.props.sendComment(value);
        }
    };

    render() {
        const { comments, minimal, reply_to, isReplies } = this.props;
        const { currentUser } = authStore;

        const SendReply = (
            <Comment>
                <div className="avatar">
                    <UserAvatar src={currentUser.avatarUrl} firstName={currentUser.firstName} lastName={currentUser.lastName} />
                </div>
                <Comment.Content>
                    <Ref innerRef={$input => (this.$input = $input)}>
                        <Input
                            fluid
                            icon={<Icon color="teal" title="Отправить" name="send" link onClick={this.handleSend} />}
                            placeholder="Ответ..."
                            onKeyPress={this.handleKeyPress}
                        />
                    </Ref>
                </Comment.Content>
            </Comment>
        );

        return (
            <Fragment>
                {comments.map(({ user, comment, comment_id, time, replies }) => (
                    <Comment key={`comment_${comment_id}`}>
                        <div className="avatar">
                            <UserAvatar src={user.avatarUrl} firstName={user.firstName} lastName={user.lastName} />
                        </div>

                        <Comment.Content>
                            <Comment.Author>
                                <UserLinkWithPreview user={user} />
                                <Comment.Metadata>
                                    <Time time={time} />
                                </Comment.Metadata>
                            </Comment.Author>
                            <Comment.Text>
                                <span dangerouslySetInnerHTML={{ __html: urlify(nl2br(comment)) }} />
                            </Comment.Text>
                            <Comment.Actions>
                                <Comment.Action
                                    active={comment_id === reply_to}
                                    onClick={this.setReply.bind(this, comment_id === reply_to ? null : comment_id)}
                                >
                                    {comment_id !== reply_to ? (
                                        'Ответить'
                                    ) : (
                                        <Fragment>
                                            Отменить
                                            <Icon name="remove" />
                                        </Fragment>
                                    )}
                                </Comment.Action>
                                {!minimal && !isReplies && comment_id !== reply_to && availableDeleting(user, time, replies) && (
                                    <Comment.Action onClick={this.props.removeComment.bind(this, comment_id)}>Скрыть</Comment.Action>
                                )}
                            </Comment.Actions>
                        </Comment.Content>

                        {comment_id === reply_to && !isReplies && <Comment.Group>{SendReply}</Comment.Group>}
                        {comment_id === reply_to && isReplies && SendReply}

                        {!isReplies && replies.length > 0 && (
                            <Comment.Group>
                                <CommentsFeed
                                    isReplies={true}
                                    comments={replies}
                                    minimal={minimal}
                                    reply_to={reply_to}
                                    setReply={this.props.setReply}
                                    sendComment={this.props.sendComment}
                                    removeComment={this.props.removeComment}
                                />
                            </Comment.Group>
                        )}
                    </Comment>
                ))}
            </Fragment>
        );
    }
}

export default CommentsFeed;
