import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import PreventDblClick from '../../Base/PreventDblClick';
import EstateTableBody from '../../Lists/Estate/EstateTableBody';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import history from '../../../history';
import bottomTabStore from '~/stores/bottomTabStore';
import userStore from '~/stores/userStore';
import { WarningMessage } from '~ui/Message';

type UserEstatesModeProps = {
    user_id: number;
};

@observer
class UserEstatesMode extends Component<UserEstatesModeProps> {
    componentDidMount() {
        userStore.fetchEstatesByUser(this.props.user_id);
    }

    handleSetEstateFilter = () => {
        const { user_id } = this.props;
        const { item } = userStore.getItem(user_id);
        userStore.setEstateFilterFromUser(user_id);

        if (item) {
            bottomTabStore.addTab({
                item_id: user_id,
                item_type: userStore.moduleName,
                item: { user_id, firstName: item.firstName, lastName: item.lastName, enable: item.enable }
            });
        }

        history.push({
            pathname: `/estate/crm`
        });
    };

    render() {
        const { user_id } = this.props;
        const { estates, loadingEstates } = userStore.getItem(user_id).property;

        return (
            <PreventDblClick>
                <Table structured size="small" sortable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Местоположение</Table.HeaderCell>
                            <Table.HeaderCell>Площадь</Table.HeaderCell>
                            <Table.HeaderCell>Этаж</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {estates && <EstateTableBody estateList={estates} simpleMode />}
                </Table>
                {!loadingEstates && estates && estates.length === 0 && <WarningMessage>Не найдено ни одного листинга</WarningMessage>}

                <Button
                    icon="search"
                    loading={!estates || loadingEstates}
                    className="crm-Item__addMode_button"
                    primary
                    onClick={this.handleSetEstateFilter}
                    size="tiny"
                    content="Открыть в поиске Недвижимости"
                />
            </PreventDblClick>
        );
    }
}

export default UserEstatesMode;
