import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon, Label } from 'semantic-ui-react';

import tagStore from '~/stores/tagStore';

@observer
class TagContact extends Component<{ tag_id: number }> {
    render() {
        const { loadingFetchAllTags, allTags } = tagStore;

        if (loadingFetchAllTags) return null;

        try {
            const { color, title, description } = tagStore.findById(this.props.tag_id);

            return (
                <Label as="a" color={color} title={description}>
                    {title}
                </Label>
            );
        } catch (error) {
            console.error(error.message);
            return null;
        }
    }
}

export default TagContact;
