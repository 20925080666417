import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import showingStore from '~/stores/showingStore';

import MajorUserEditing from '../EditBlocks/MajorUserEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';

@observer
class MajorUserShowBlock extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = showingStore.getItem(item_id);

        if (!item || loadingItem) {
            return null;
        }

        const { major_user } = item;

        return !editing ? (
            <Card fluid color="blue">
                <Card.Content>
                    <Header size="tiny">Сотрудник</Header>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>{major_user && <UserLinkWithPreview avatar user={major_user} />}</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        ) : (
            <Card fluid color="yellow">
                <Card.Content>
                    <MajorUserEditing item_id={item_id} editing={editing} {...item} />
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(showingStore, MajorUserShowBlock);
