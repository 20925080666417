import React from 'react';
import { Popup, List, Icon } from 'semantic-ui-react';

export default () => (
    <Popup
        position="bottom center"
        size="small"
        trigger={<Icon className="crm-Main__hiddenMobile" circular color="blue" name="question" />}
        wide="very"
        content={
            <List divided relaxed>
                <List.Item>
                    <List.Header>
                        <Icon name="building outline" /> Поиск Недвижимости
                    </List.Header>
                    <List.Item>По адресу: "Фрун 50", "Ленинск"</List.Item>
                    <List.Item>По ЖК: "ЖК Wel", "жк Аллы"</List.Item>
                    <List.Item>По цене: "7000000"</List.Item>
                    <List.Item>По id: "300230"</List.Item>
                </List.Item>
                <List.Item>
                    <List.Header>
                        <Icon name="male" color="brown" />
                        Поиск Собственников
                    </List.Header>
                    <List.Item>По телефону: "+7 (999) 111-22-33", "9168943", "79123743" (В любом формате от 7 цифр)</List.Item>
                    <List.Item>По адресу: "Фрун 50", "Ленинск"</List.Item>
                    <List.Item>По id: "432731"</List.Item>
                </List.Item>
                <List.Item>
                    <List.Header>
                        <Icon name="address card outline" />
                        Поиск Контактов
                    </List.Header>
                    <List.Item>По телефону: "+7 (999) 111-22-33", "9168943", "79123743" (В любом формате от 7 цифр)</List.Item>
                    <List.Item>По имени: "Алла", "Иванов"</List.Item>
                    <List.Item>По id: "182345"</List.Item>
                </List.Item>
                <List.Item>
                    <List.Header>
                        {' '}
                        <Icon name="users" />
                        Поиск Сотрудников
                    </List.Header>
                    <List.Item>По телефону: "+7 (999) 111-22-33", "9168943", "79123743" (В любом формате)</List.Item>
                    <List.Item>По имени: "Алла", "Иванов"</List.Item>
                    <List.Item>По email: "ivanov", "pertov@ma"</List.Item>
                    <List.Item>По id: "182345"</List.Item>
                </List.Item>
            </List>
        }
    />
);
