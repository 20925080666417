import React, { ReactNode, SyntheticEvent, ReactElement, useState, CSSProperties, useEffect, useCallback, useReducer } from 'react';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import cs from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '~ui/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import LoaderAwait from '../LoaderAwait';
import Progress from './Progress';

export const useToggle = (defaultValue?: boolean): [boolean, () => void] => {
    return useReducer(state => !state, defaultValue || false);
};

const styles = ({ spacing, palette }: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500]
        },
        closeButtonContrast: {
            color: palette.primary.contrastText
        },
        titleHeader: {
            display: 'flex',
            alignItems: 'center'
        }
    });

interface DialogTitleProps extends WithStyles<typeof styles> {
    children: React.ReactNode;
    onClose: () => void;
    contrast?: boolean;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, contrast, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.titleHeader}>
                {children}
            </Typography>
            {onClose ? (
                <Button
                    tooltip="Закрыть окно"
                    icon={<CloseIcon />}
                    className={cs({ [classes.closeButton]: true, [classes.closeButtonContrast]: contrast })}
                    onClick={onClose}
                />
            ) : null}
        </MuiDialogTitle>
    );
});

type ModalHeaderProps = {
    children: ReactNode;
    onClose?: () => void;
    contrast?: boolean;
};

const useStylesModalHeader = makeStyles(({ palette, spacing }) =>
    createStyles({
        root: {
            background: palette.primary.main,
            color: palette.primary.contrastText,
            paddingBottom: '2rem'
        }
    })
);

export const ModalHeader = React.memo((props: ModalHeaderProps) => {
    const classes = useStylesModalHeader();
    return (
        <DialogTitle
            classes={
                props.contrast && {
                    root: classes.root
                }
            }
            onClose={props.onClose}
            contrast={props.contrast}
        >
            {props.children}
        </DialogTitle>
    );
});

const useStylesModalActions = makeStyles(({ palette, spacing }) =>
    createStyles({
        root: {
            paddingTop: 0
        }
    })
);

export const ModalActions = (props: { children: ReactNode; contrast?: boolean }) => {
    const classes = useStylesModalActions();
    return (
        <DialogActions
            classes={
                props.contrast && {
                    root: classes.root
                }
            }
        >
            {props.children}
        </DialogActions>
    );
};

const ModalContentStyles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(2)
    }
});

type ModalContentProps = {
    children: ReactNode;
    onClose?: () => void;
    contrast?: boolean;
    dividers?: boolean;
    style?: CSSProperties;
    className?: string;
};

const useStylesModalContent = makeStyles(({ palette, spacing }) =>
    createStyles({
        root: {
            position: 'relative',
            top: '-1rem',
            background: 'white',
            borderRadius: '1rem 1rem 0 0'
        }
    })
);

export const ModalContent = withStyles(ModalContentStyles)((props: ModalContentProps) => {
    const classes = useStylesModalContent();
    const { children, contrast, ...other } = props;

    return (
        <MuiDialogContent
            {...other}
            classes={
                contrast && {
                    root: classes.root
                }
            }
        >
            {children}
        </MuiDialogContent>
    );
});

export const ModalContentText = DialogContentText;

export type ModalBtnAction = {
    onClick: (event: SyntheticEvent) => void | Promise<void>;
    label: string;
    disabled?: boolean;
    color?: 'secondary' | 'primary' | 'default' | 'inherit';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    loading?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
};

type ModalProps = {
    children: ReactNode;
    header?: ReactNode;
    actions?: ReactElement | ModalBtnAction[];
    onClose?: (event?: SyntheticEvent) => void;
    fullScreen?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    loading?: boolean;
    trigger?: ReactNode;
    transition?: 'slideUp';
    style?: CSSProperties;
    onMount?: () => void;
    disableBackdropClick?: boolean;
    contrastHeader?: boolean;
};

const TransitionSlideUp = React.forwardRef(
    (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);

export const Modal = (props: ModalProps) => {
    const {
        onMount,
        disableBackdropClick,
        onClose,
        style,
        children,
        header,
        actions,
        fullScreen,
        fullWidth,
        maxWidth,
        loading,
        trigger,
        transition,
        contrastHeader
    } = props;

    const [open, setOpen] = useState(!trigger);
    const handleToggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    useEffect(() => {
        if (onMount) {
            onMount();
        }
    }, []);

    const handleClose = onClose || handleToggleOpen;

    return (
        <>
            {trigger && <span onClick={handleToggleOpen}>{trigger}</span>}
            <Dialog
                onClose={handleClose}
                open={open}
                fullScreen={fullScreen}
                fullWidth={typeof fullWidth === 'boolean' ? fullWidth : Boolean(maxWidth)}
                maxWidth={maxWidth || 'md'}
                TransitionComponent={transition === 'slideUp' ? TransitionSlideUp : undefined}
                PaperProps={{ style }}
                disableBackdropClick={disableBackdropClick || loading}
            >
                {header && (
                    <ModalHeader contrast={contrastHeader} onClose={handleClose}>
                        {header}
                    </ModalHeader>
                )}
                {children}
                {actions && !Array.isArray(actions) && <DialogActions>{actions}</DialogActions>}
                {Array.isArray(actions) && (
                    <ModalActions contrast={contrastHeader}>
                        {actions.map(({ onClick, variant, label, loading, disabled, color, startIcon, endIcon }, index) => (
                            <Button
                                key={`btn-${index}`}
                                disabled={disabled}
                                color={color}
                                onClick={onClick}
                                variant={variant || 'contained'}
                                endIcon={endIcon}
                                startIcon={startIcon}
                                loading={loading}
                            >
                                {label}
                            </Button>
                        ))}
                    </ModalActions>
                )}

                <LoaderAwait dimmer active={loading} />
            </Dialog>
        </>
    );
};

export default Modal;
