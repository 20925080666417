import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import HistoryIcon from '@material-ui/icons/History';
import ListAltIcon from '@material-ui/icons/ListAlt';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState, TAB_ID_HISTORY } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT } from '../ItemWrapper';

import requestStore from '~/stores/requestStore';

import RequestAddMode from './RequestAddMode';
import RequestShowMode from './RequestShowMode';
import RequestHistoryMode from './RequestHistoryMode';

import RequestTabMenu from './RequestTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';

const RequestItem = ({ item_id, tabName, param }: ItemWrapperState) => {
    const { errors, item } = requestStore.getItem(item_id);
    const { enable, major_user_ids, contact_id } = item || {};

    const requestTabs: MenuItem[] = [
        { name: 'Описание', id: '', icon: <ListAltIcon /> },
        { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
    ];

    return (
        <Fragment>
            <RequestTabMenu
                itemTabs={requestTabs}
                item_id={item_id}
                param={param}
                tabName={tabName}
                enable={Boolean(enable)}
                major_user_id={major_user_ids ? Array.from(major_user_ids) : []}
                contact_id={contact_id}
            />

            <div className="crm-List__sidebar_modalBox">
                {errors && <ItemErrors errors={errors} tabName={tabName} />}
                {tabName === TAB_ID_CREATING && <RequestAddMode param={param} />}
                {tabName === TAB_ID_DEFAULT && <RequestShowMode request_id={item_id} />}
                {tabName === TAB_ID_HISTORY && <RequestHistoryMode item_id={item_id} />}
            </div>
        </Fragment>
    );
};

export default ItemWrapper(requestStore, observer(RequestItem));
