import React from 'react';
import { TableHead, TableRow, TableCell } from '~ui/Table';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const MlsFixPricesTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Объект</TableCell>
                <TableCell>Контакт</TableCell>
                <TableCell>Агент</TableCell>
                <TableCell>Фикс.Цена</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default MlsFixPricesTableHeader;
