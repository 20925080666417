import { proxyL10nHandler } from '../common/localizations';

const SITE_STATUS = [
    [0, 'нет'],
    [1, 'Лучший месяца'],
    [2, 'Лучший года']
];

const usersL10n = new Proxy({}, proxyL10nHandler);

usersL10n.SITE_STATUS = SITE_STATUS;

export default usersL10n;
