import { UserLinkType } from './users.types';
import { Contact } from './contacts.types';
import { EstateLinkType } from './estate.types';
import { CellCall } from './cellCalls.types';

export const EVENT_TYPE_MAIN = 0;
export const EVENT_TYPE_CALL = 1;
export const EVENT_TYPE_MEETING = 2;

export type CallEventLinkType = {
    readonly call_event_id: number;
    callTimeMin: number;
    callTimeMax: number;

    callHasReleased: boolean;
};

export type EventInBase = {
    description: string;

    notificationEnable: boolean;
    notificationTime?: number;

    item_id: number | null;
    item_type: CALL_EVENT_ITEM_TYPE;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type MainEventFields = EventInBase &
    (
        | {
              item_type: 'contact';
              contact: Contact;
          }
        | {
              item_type: 'owner';
              estate: EstateLinkType & { ownerPhones: { phone: string }[] };
          }
    );

export const CALL_EVENT_ITEM_TYPE_CONTACT = 'contact';
export const CALL_EVENT_ITEM_TYPE_OWNER = 'owner';

export type CALL_EVENT_ITEM_TYPE = 'contact' | 'owner';

export type CallEventInBase = CallEventLinkType &
    EventInBase & {
        notificationHasCalled: boolean;
        call_id: number | null;
        title: string;
    };

export type CallEvent = CallEventInBase &
    MainEventFields & {
        assign_users_ids: number[];
        assign_users: UserLinkType[];
    } & (
        | {
              call_id: null;
          }
        | {
              call_id: number;
              call: CellCall;
          }
    );

export type CallEventFilter = {
    contact_id: number | null;
    deltaCallTime: number | null;
    major_user_id: number[];
    group_id: number[];
    itemType: number;
    callHasReleased: null | boolean;
    enable: boolean;
};

export type MeetingEventLinkType = {
    readonly meeting_event_id: number;
    startTime: number;
    endTime: number;
};

export type MeetingEvent = MeetingEventLinkType &
    MainEventFields & {
        goal: string;

        assign_users_ids: number[];
        assign_users?: UserLinkType[];

        meetingHasReleased: boolean;
    };

export type MeetingEventFilter = {
    contact_id?: number;
    enable: boolean;
};
