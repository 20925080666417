import { observable, action, computed, reaction } from 'mobx';
import * as mortgageRequestApi from '~/api/mortgageRequest/mortgageRequestApi';

import { MortgageRequest } from '~/types/mortgageRequest.types';
import ItemEditingPrototype from '~/stores/prototypes/ItemEditing.prototype';

class MortgageRequestStore extends ItemEditingPrototype<MortgageRequest> {
    constructor() {
        super('contact_id', 'contact', mortgageRequestApi);
    }
}

export default new MortgageRequestStore();
