import React, { Fragment, Component, SyntheticEvent, MouseEventHandler } from 'react';
import { observer } from 'mobx-react';
import { Search, Button, Icon, Ref } from 'semantic-ui-react';
import globalSearchStore, { DADATA_MODULE_NAME, GLOBAL_SEARCH_EXPANDED_LIMIT, GLOBAL_SEARCH_LIMIT } from '~/stores/globalSearchStore';

import history from '../../history';
import commonStore from '~/stores/commonStore';

import GlobalSearchInfo from './GlobalSearchInfo';
import { ITEM_TYPES } from '~/types/notifications.types';
import deepCopy from '../../common/deepCopy';

const stopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
};

const categoryRenderer = ({
    title,
    count,
    modulename,
    from
}: {
    title: string;
    count: number;
    modulename: ITEM_TYPES | 'dadata';
    from: number;
}) => {
    const showClearBtn = count < from + GLOBAL_SEARCH_EXPANDED_LIMIT && from > 0;
    const showNextBtn =
        modulename !== DADATA_MODULE_NAME &&
        ((count > GLOBAL_SEARCH_LIMIT && !showClearBtn && count > from + GLOBAL_SEARCH_EXPANDED_LIMIT) ||
            (from === 0 && count > GLOBAL_SEARCH_LIMIT && !globalSearchStore.strictModule));

    return (
        <div>
            <b>
                {modulename === 'owner' && <Icon name="male" color="brown" />}
                {modulename === 'comment' && <Icon name="comment" color="grey" />}
                {modulename === DADATA_MODULE_NAME && <Icon name="search" color="blue" />}
                {title}
            </b>
            {count > 0 && (
                <Fragment>
                    <br />
                    Найдено: {count}
                </Fragment>
            )}
            {showNextBtn && (
                <Fragment>
                    <br />
                    <Button
                        onMouseDown={globalSearchStore.setStrictModule.bind(globalSearchStore, modulename)}
                        onClick={stopPropagation}
                        basic
                        compact
                        size="mini"
                        color={globalSearchStore.strictModule ? 'green' : null}
                    >
                        Еще
                    </Button>
                </Fragment>
            )}
            {globalSearchStore.strictModule && (
                <Fragment>
                    <br />
                    <Button
                        onClick={e => {
                            stopPropagation(e);
                            globalSearchStore.resetSearch();
                        }}
                        basic
                        compact
                        color="red"
                        size="mini"
                    >
                        Очистить
                    </Button>
                </Fragment>
            )}
        </div>
    );
};

@observer
class GlobalSearch extends Component<{}> {
    $searchInput: any;

    handleResultSelect = (event: SyntheticEvent, { result }: { result: { state: any; title: string } }) => {
        const { state, title } = result;

        if (state) {
            globalSearchStore.addSelectedResult(deepCopy(result));
            history.push(deepCopy(state));
            globalSearchStore.hide();
            this.$searchInput.querySelector('input').blur();
        } else {
            // это значит клик по подсказке с адресом
            globalSearchStore.setSearchQuery(title);
            globalSearchStore.setStrictModule(DADATA_MODULE_NAME);
        }
    };

    handleSearchChange = async (event: SyntheticEvent, { value }: { value: string }) => {
        await globalSearchStore.search(value);
    };

    handleFocus = () => {
        globalSearchStore.show();
    };

    handleBlur = () => {
        setTimeout(globalSearchStore.hide.bind(globalSearchStore), 0);
    };

    handleClick = (event: { target: { classList: string[] } }) => {
        const classList = Array.from(event.target.classList);
        if (classList.includes('close') && classList.includes('icon')) {
            globalSearchStore.resetSearch();
            globalSearchStore.hide();
            setTimeout(() => {
                this.$searchInput.querySelector('input').focus();
                globalSearchStore.show();
            }, 0);
        }
    };

    render() {
        const { loadingLists, results, open, searchQuery, selectedResults } = globalSearchStore;

        return (
            <div className="crm-Header__search">
                <Ref innerRef={$el => (this.$searchInput = $el)}>
                    <Search
                        size="tiny"
                        fluid
                        placeholder="Номер карточки, Адрес, ФИО, телефон, email"
                        category
                        loading={loadingLists}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={this.handleSearchChange}
                        results={
                            !searchQuery && selectedResults.length
                                ? {
                                      0: {
                                          count: selectedResults.length,
                                          from: 0,
                                          key: 'Недавние',
                                          modulename: 'estate',
                                          results: selectedResults,
                                          title: 'Недавние '
                                      }
                                  }
                                : results
                        }
                        value={searchQuery}
                        noResultsMessage="Ничего не найдено"
                        categoryRenderer={categoryRenderer}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        open={open}
                        icon={searchQuery ? 'close' : 'search'}
                        onClick={this.handleClick}
                    />
                </Ref>
                {!commonStore.isMobile && <GlobalSearchInfo />}
            </div>
        );
    }
}

export default GlobalSearch;
