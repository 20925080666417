import { observable, action, runInAction } from 'mobx';
import * as ownerApi from '~/api/ownerApi';
import estateStore from './estateStore';
import authStore from './authStore';
import { Owner } from '~/types/owners.types';
import { CellCall } from '~/types/cellCalls.types';
import ItemStorePrototype, { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import contactStore from './contactStore';
import { printNewbuildingTitle } from './helpers/estate.store.helpers';
import { estateAbout, EstateLinkState } from '../components/Lists/Estate/EstateLink';
import { ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS, ESTATE_TYPE_RENT, ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL } from '~/types/estate.types';
import estateL10n from '../L10n/estate.L10n';
import * as estateApi from '~/api/estateApi';
import { SearchModuleResult } from './globalSearchStore';
import globalSearchStore from './globalSearchStore';
import { catchItemError, nProgress } from './helpers/decorators.helpers';

type OwnerPropertyType = {
    loadingCalls: boolean;
    calls: CellCall[];

    callHasDone: boolean;
};

class OwnerStore extends ItemStorePrototype<Owner, OwnerPropertyType> {
    constructor() {
        super('estate_id', 'owner', ownerApi);

        globalSearchStore.regModule({
            title: 'Собственники',
            moduleName: this.moduleName,
            search: this.globalSearchList
        });
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        const { list, count } = await estateApi.searchEstateDropdown(
            {
                enable: true,
                search: query,
                base: ESTATE_BASE_OWNERS
            },
            start,
            limit,
            abortController
        );

        return {
            list: list.map(
                ({
                    estate_id,
                    address,
                    price,
                    currency,
                    description,
                    propertyType,
                    roomsCount,
                    totalArea,
                    type,
                    newbuilding,
                    newbuildingHouse
                }) => ({
                    key: estate_id,
                    title: `${address}${newbuilding ? `, ${printNewbuildingTitle({ newbuilding, newbuildingHouse })}` : ''}`,
                    state: EstateLinkState(
                        estate_id,
                        type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL,
                        ESTATE_BASE_OWNERS
                    ),
                    description: `Собственник: ${estateAbout(propertyType, roomsCount)}, ${totalArea || '-'} м²`,
                    price: `${price.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(currency)}${
                        type === ESTATE_TYPE_RENT_ID ? '/мес' : ''
                    }`,
                    className: this.moduleName
                })
            ),
            count
        };
    };

    @observable
    loadingError: string = '';

    @action
    setLoadingError(error: string) {
        this.loadingError = error;
    }

    @observable
    loadingFile = false;

    @action
    startLoadingOwners() {
        this.loadingFile = true;
        this.loadingError = '';
        this.loadingMessage = '';
    }

    @action
    stopLoadingOwners() {
        this.loadingFile = false;
    }

    @observable
    loadingMessage: string = '';

    @action
    setMessage(message: string) {
        this.loadingMessage = message;
    }

    @observable
    loadingAssign = false;

    @action
    async assignOwner(estate_id: number): Promise<number> {
        const owner = this.getItem(estate_id);
        owner.loadingItem = true;
        this.ownersErrors = [];

        try {
            await ownerApi.assignOwner(estate_id);
            const assignTime = Math.round(Date.now() / 1000);

            const { item } = owner;
            if (!item) {
                throw new Error();
            }

            item.assignOwnersHistory &&
                item.assignOwnersHistory.push({
                    assignTime,
                    user_id: authStore.currentUser.user_id,
                    user: authStore.currentUser
                });

            return estate_id;
        } catch (errors) {
            this.ownersErrors = errors;
            return 0;
        } finally {
            owner.loadingItem = false;
        }
    }

    whetherOwnerHasAssigned(estate_id: number): boolean {
        const { item } = this.getItem(estate_id);
        if (!item) {
            return false;
        }

        const { assignOwnersHistory } = item;
        let theOwnerHasAssigned = false;

        if (assignOwnersHistory) {
            theOwnerHasAssigned = assignOwnersHistory.some(({ user_id }) => user_id === authStore.currentUser.user_id);
        }
        return theOwnerHasAssigned;
    }

    @observable
    ownersErrors: string[] = [];

    @action
    @nProgress
    async toggleIsAlreadySold(estate_id: number, soldPrice: number | null) {
        this.ownersErrors = [];
        const owner = this.getItem(estate_id).item;
        const estate = estateStore.getItem(estate_id).item;

        if (owner && estate) {
            try {
                owner.isAlreadySold = !owner.isAlreadySold;
                await ownerApi.toggleIsAlreadySold(estate_id, owner.isAlreadySold, soldPrice);
                owner.enable = false;
                estate.enable = false;
            } catch (errors) {
                this.ownersErrors = errors;
            }
        }
    }

    @action
    async toggleDisableItem(id: number, enable: boolean, isRealtor?: boolean) {
        const estate = estateStore.getItem(id);
        const owner = this.getItem(id).item;

        if (estate.item && owner) {
            try {
                await ownerApi.toggleDisableOwner(id, enable, isRealtor);
                estate.item.enable = enable;
                owner.enable = enable;
            } catch (errors) {
                this.ownersErrors = errors;
            }
        }
    }

    @action
    async takeOwnerForWork(estate_id: number, contact_id: number): Promise<number> {
        this.ownersErrors = [];
        const owner = this.getItem(estate_id).item;
        const estate = estateStore.getItem(estate_id).item;
        this.getItem(estate_id).loadingItem = true;

        let new_contact_id = contact_id;

        if (contact_id === CREATING_ITEM_ID) {
            new_contact_id = await contactStore.createItem();
            // if (!new_contact_id) {
            //     this.ownersErrors = contactStore.getItem(CREATING_ITEM_ID).errors;
            //     this.getItem(estate_id).loadingItem = false;
            //     return new_contact_id;
            // }
        } else {
            await contactStore.saveItem(contact_id);
        }

        if (owner && estate) {
            try {
                // owner.enable = false;
                // estate.enable = false;

                new_contact_id = await ownerApi.takeOwnerForWork(estate_id, new_contact_id);
                window.setTimeout(() => {
                    estateStore.fetchItem(estate_id, ESTATE_BASE_OWNERS, true);
                    this.fetchItem(estate_id, null, true);
                }, 0);

                return new_contact_id;
            } catch (errors) {
                this.ownersErrors = errors;
                return 0;
            } finally {
                this.getItem(estate_id).loadingItem = false;
            }
        }
        return 0;
    }

    @action
    async callsByOwner(owner_id: number) {
        this.setProperty(owner_id, { calls: [], loadingCalls: true });
        const calls = await ownerApi.fetchCellCallsByOwner(owner_id);
        this.setProperty(owner_id, { calls, loadingCalls: false });
    }

    @catchItemError
    async createEstateFromOwner(estate_id: number): Promise<number> {
        return await ownerApi.createEstateFromOwner(estate_id);
    }

    @action
    async checkWhetherWasCall(estate_id: number): Promise<boolean> {
        const callHasDone = await ownerApi.checkWhetherWasCall(estate_id);
        this.setProperty(estate_id, { callHasDone });
        return callHasDone;
    }

    getItemForAccess(id: number): any {
        return estateStore.getItem(id);
    }
}

export default new OwnerStore();
