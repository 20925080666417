import React, { Component, Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import showingStore from '~/stores/showingStore';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import UserDropdown from '../../../ItemsDropdowns/UserDropdown';

type MajorUserEditingProps = HandlerEditingWrappedProps & {
    major_user_id?: number;
};

@observer
class MajorUserEditing extends Component<MajorUserEditingProps> {
    constructor(props: MajorUserEditingProps) {
        super(props);

        const item = {
            major_user_id: props.major_user_id
        };

        showingStore.setEditingItem(props.item_id, item);
    }

    handleChangeUserId = (user_id: number | Array<number>) => {
        const { item_id } = this.props;

        if (typeof user_id === 'number') {
            showingStore.setEditingItem(item_id, {
                major_user_id: user_id
            });
        }
    };

    render() {
        const { item_id } = this.props;
        const { major_user_id } = showingStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Grid columns={1} stackable>
                    <Grid.Column>
                        <label className="crm-Item__labelWide" style={{ marginRight: '1em' }}>
                            Ответственный сотрудник
                        </label>
                        <UserDropdown user_id={major_user_id} onChange={this.handleChangeUserId} />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(showingStore, MajorUserEditing);
