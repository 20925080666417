import fetchApi from '~/common/fetchApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { Owner } from '~/types/owners.types';
import { CellCall } from '~/types/cellCalls.types';
import { GQCallsFields } from './cell/cellCallsApi';

export const assignOwner = async (estate_id: number): Promise<number> => {
    const graphql = `mutation {
          assignOwner(estate_id: ${estate_id})
        }`;

    const data: { assignOwner: number } = await fetchApi.postGQ(graphql);
    return data.assignOwner;
};

export const fetchItem = async (estate_id: number): Promise<Owner> => {
    const graphql = `{
        fetchOwner(estate_id: ${estate_id}) {
            isAlreadySold
            enable
            isBooked {
                owners_book_id
                estate_id
                major_user_id
                major_user {
                    ${GQUserMainFields}
                }
                bookTime
                expireTime
            }
            assignOwnersHistory {
                user{
                    ${GQUserMainFields}
                    avatarUrl
                }
                user_id
                assignTime
            } 
        } 
    }`;

    const data: { fetchOwner: Owner } = await fetchApi.getGQ(graphql);
    return data.fetchOwner;
};

export const toggleDisableOwner = async (estate_id: number, enable: boolean, isRealtor?: boolean): Promise<number> => {
    const graphql = `mutation { 
          toggleDisableOwner(estate_id: ${estate_id}, enable: ${String(enable)}, isRealtor: ${String(isRealtor || false)})
        }`;

    const data: { toggleDisableOwner: number } = await fetchApi.postGQ(graphql);
    return data.toggleDisableOwner;
};

export const toggleIsAlreadySold = async (estate_id: number, isAlreadySold: boolean, soldPrice: number | null): Promise<number> => {
    const graphql = `mutation { 
          toggleIsAlreadySold(estate_id: ${estate_id}, isAlreadySold: ${String(isAlreadySold)}, soldPrice: ${soldPrice})
        }`;

    const data: { toggleIsAlreadySold: number } = await fetchApi.postGQ(graphql);
    return data.toggleIsAlreadySold;
};

export const fetchItemHistory = async (estate_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchOwnerChangeHistory(estate_id: ${estate_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchOwnerChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchOwnerChangeHistory;
};

export const takeOwnerForWork = async (estate_id: number, contact_id: number): Promise<number> => {
    const graphql = `mutation { 
          takeOwnerForWork(estate_id: ${estate_id}, contact_id: ${contact_id})
        }`;

    const data: { takeOwnerForWork: number } = await fetchApi.postGQ(graphql);
    return data.takeOwnerForWork;
};

export const fetchCellCallsByOwner = async (owner_id: number): Promise<CellCall[]> => {
    const graphql = `{
        fetchCellCallsByOwner(owner_id: ${owner_id}) {
            ${GQCallsFields}
        } 
      }`;

    const data: { fetchCellCallsByOwner: CellCall[] } = await fetchApi.getGQ(graphql);
    return data.fetchCellCallsByOwner;
};

export const createEstateFromOwner = async (owner_id: number): Promise<number> => {
    const graphql = `mutation {
          createEstateFromOwner(owner_id: ${owner_id})
        }`;

    const data: { createEstateFromOwner: number } = await fetchApi.postGQ(graphql);
    return data.createEstateFromOwner;
};

export const checkWhetherWasCall = async (owner_id: number): Promise<boolean> => {
    const graphql = `{
        checkWhetherWasCall(owner_id: ${owner_id})
      }`;

    const data: { checkWhetherWasCall: boolean } = await fetchApi.getGQ(graphql);
    return data.checkWhetherWasCall;
};
