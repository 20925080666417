import React, { ReactNode } from 'react';

const MonoString = ({
    template,
    values,
    children
}: {
    template?: string;
    values?: Array<number | string | null>;
    children?: ReactNode;
}) => {
    if (children) {
        return <span className="crm-List__monoField">{children}</span>;
    }

    const valuesPop = values ? values.map(val => (val ? val.toLocaleString() : '-')) : [];
    return <span className="crm-List__monoField">{(template || '').replace(/\?/g, () => valuesPop.shift())}</span>;
};

export default MonoString;
