import React, { Fragment, Component } from 'react';
import { Button, Grid, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import MajorUserBlockEditing from '../Common/MajorUserBlockEditing';

import ConfirmTrigger from '../../Base/ui/ConfirmTrigger';
import contactStore from '~/stores/contactStore';
import Modal, { ModalContent, ModalActions } from '~ui/Modal';
import commonStore from '~/stores/commonStore';

const confirmHeader = 'Поменять ответственных у контакта?';
const confirmContent =
    'Если вы убрали себя из ответственных, то у вас пропадет доступ к редактированию данного контакта и всех его объектов. Если ответственных не осталось, то объекты контакта уйдут в свободные / экспорт будет снят';

type MajorUserPopupProps = {
    item_id: number;
    major_user_id: number | Array<number>;
    handleMajorUserPopupClose: () => void;
    handleMajorUserPopupOpen: () => void;
    handleSaveMajorUser: () => void;
};

@observer
class MajorUserPopup extends Component<MajorUserPopupProps> {
    constructor(props: MajorUserPopupProps) {
        super(props);

        const contact = {
            changeExportMajorUser: true
        };
        contactStore.setEditingItem(props.item_id, contact);
    }

    toggleChaneExportUser = () => {
        const { item_id } = this.props;
        const { changeExportMajorUser } = contactStore.getItem(item_id).editingItem;
        contactStore.setEditingItem(item_id, { changeExportMajorUser: !changeExportMajorUser });
    };

    render() {
        const { major_user_id, item_id, handleMajorUserPopupClose, handleSaveMajorUser } = this.props;
        const { changeExportMajorUser, major_user_ids } = contactStore.getItem(item_id).editingItem;

        // TODO: убрать style={{ overflowY: 'visible' }} после рефакторинга UserDropdown
        return (
            <Fragment>
                <Modal
                    maxWidth="sm"
                    fullScreen={commonStore.isMobile}
                    onClose={handleMajorUserPopupClose}
                    header="Поменять ответственных"
                    style={{ overflowY: 'visible' }}
                >
                    <ModalContent dividers style={{ overflowY: 'visible' }}>
                        <Grid columns={1}>
                            <Grid.Column>
                                <MajorUserBlockEditing
                                    enableAllGroups
                                    store={contactStore}
                                    major_user_id={major_user_id}
                                    item_id={item_id}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox
                                    checked={major_user_ids && major_user_ids.length > 1 ? false : changeExportMajorUser}
                                    name="onlineOnly"
                                    label={<label>Поменять ответственного на экспорт</label>}
                                    onClick={this.toggleChaneExportUser}
                                    disabled={major_user_ids && major_user_ids.length > 1}
                                />
                            </Grid.Column>
                        </Grid>
                    </ModalContent>
                    <ModalActions>
                        <ConfirmTrigger
                            trigger={
                                <Button
                                    size="tiny"
                                    // disabled={major_user_id instanceof Array && major_user_id.length === 0}
                                    primary
                                >
                                    {major_user_id instanceof Array ? `Сохранить` : `Поменять`}
                                </Button>
                            }
                            content={confirmContent}
                            header={confirmHeader}
                            confirmButton="Поменять"
                            onConfirm={async () => handleSaveMajorUser()}
                        />
                    </ModalActions>
                </Modal>
            </Fragment>
        );
    }
}

export default MajorUserPopup;
