import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ContactLinkType } from '~/types/contacts.types';
import Time from '~/components/Base/Time';
import ListComments from '~/components/Lists/Common/ListComments';

import ContactLink from '~/components/Lists/Contacts/ContactLink';

import { CommentType } from '~/types/comments.types';
import TagsList from '~/components/Items/Contact/ShowBlocks/SubBlocks/TagsList';
import isEqual from '~/common/isEqual';

import CallIcon from '@material-ui/icons/Call';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(({ palette }: Theme) => ({
    root: {
        borderRadius: '3px',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#fff',
        position: 'relative',
        padding: '10px 7px 3px',
        cursor: 'pointer',
        maxWidth: '250px',
        marginBottom: '7px',
        fontSize: '0.875rem',
        minWidth: '230px',
        color: palette.text.primary,
        '&:hover': {
            backgroundColor: '#f0f0f0',
            color: '#000'
        }
    },
    comments: {
        color: palette.text.primary
    },
    header: {
        borderBottom: '1px solid #eee',
        paddingBottom: '6px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative'
    },
    label: {
        position: 'absolute',
        right: '-5px',
        top: 0,
        color: palette.text.primary,
        fontSize: '0.725rem',
        background: '#fff',
        padding: '0 5px'
    },
    tags: {
        paddingTop: '9px',
        textAlign: 'right'
    },
    lastCallTime: {
        padding: '4px 0',
        borderTop: '1px solid #eee',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem'
    }
}));

export type ContactBoardCardProps = {
    contact_id: number;
    lastCallTime: number;
    contact: ContactLinkType;
    updateTime: number;
    comments: CommentType[];
    tags_ids: number[];
};

const CustomCard = ({ contact_id, contact, comments, updateTime, tags_ids, lastCallTime }: ContactBoardCardProps) => {
    const classes = useStyles();

    return (
        <article data-id={contact_id} className={classes.root}>
            <header className={classes.header}>
                <ContactLink contact={contact} page="kanban" />
                <span className={classes.label}>
                    <Time time={updateTime} />
                </span>
            </header>
            <div className={classes.comments}>
                <ListComments comments={comments} item_id={contact_id} item_type="CONTACT" />
            </div>
            {tags_ids.length > 0 && (
                <div className={classes.tags}>
                    <TagsList tags_ids={tags_ids} size="tiny" />
                </div>
            )}
            {lastCallTime && (
                <Tooltip title="Время последнего звонка">
                    <div className={classes.lastCallTime}>
                        <CallIcon fontSize="small" />
                        <Time time={lastCallTime} />
                    </div>
                </Tooltip>
            )}
        </article>
    );
};

export default React.memo(CustomCard, isEqual);
