import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import ConfirmTrigger from '~/components/Base/ui/ConfirmTrigger';

import Container from '@material-ui/core/Container';

import Button from '~ui/Button';
import Grid from '~ui/Grid';
import { useToggle } from '~ui/Modal';

import BalancePaymentModal from './BalancePaymentModal';
import BalanceResetToDateRow from './BalanceResetToDateRow';
import Table, { TableRow, TableCell } from '~ui/Table';
import ShadowFragment from '~/components/Base/ShadowFragment';

const UserBalanceTab = ({ user_id }: { user_id: number }) => {
    useMemo(() => {
        userStore.getUserBalanceInfo(user_id);
    }, []);

    const [showAdvanceMode, toggleAdvanceMode] = useToggle();
    const [showPaymentModal, togglePaymentModal] = useToggle();

    const handleResetTransactions = async () => {
        await userStore.resetBalance(user_id);
    };

    const handleResetPayments = async () => {
        await userStore.resetUserPayments(user_id);
    };

    const { userBalanceInfo } = userStore.getItem(user_id).property || {};

    return (
        <Container maxWidth="md">
            <Table size="medium">
                <TableRow>
                    <TableCell>Расход на рекламу</TableCell>
                    <TableCell>
                        <ShadowFragment print={userBalanceInfo?.transactionsSum?.toLocaleString()} /> ₽
                    </TableCell>
                    <TableCell>
                        <Grid columns={2} spacing={0} justify="space-between">
                            <Grid.Column>
                                <Button size="small" onClick={toggleAdvanceMode} variant="contained" disableElevation>
                                    Списать на дату
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                <ConfirmTrigger
                                    trigger={
                                        <Button size="small" color="secondary" variant="outlined" disableElevation>
                                            Обнулить
                                        </Button>
                                    }
                                    content="Спишется весь расход за рекламу и звонки"
                                    header="Обнулить расход у сотрудника?"
                                    confirmButton="Обнулить"
                                    onConfirm={handleResetTransactions}
                                />
                            </Grid.Column>
                        </Grid>
                    </TableCell>
                </TableRow>
                {showAdvanceMode && <BalanceResetToDateRow user_id={user_id} />}

                <TableRow>
                    <TableCell>Начисления</TableCell>
                    <TableCell>
                        <ShadowFragment print={userBalanceInfo?.paymentsSum?.toLocaleString()} /> ₽
                    </TableCell>
                    <TableCell>
                        <Grid columns={2} spacing={0} justify="space-between">
                            <Grid.Column>
                                <Button primary size="small" onClick={togglePaymentModal} variant="contained" disableElevation>
                                    Начислить / Списать
                                </Button>
                                {showPaymentModal && <BalancePaymentModal user_id={user_id} onClose={togglePaymentModal} />}
                            </Grid.Column>
                            <Grid.Column>
                                <ConfirmTrigger
                                    trigger={
                                        <Button size="small" color="secondary" variant="outlined" disableElevation>
                                            Обнулить
                                        </Button>
                                    }
                                    content="Спишутся все начисления у сотрудника"
                                    header="Обнулить начисления у сотрудника?"
                                    confirmButton="Обнулить"
                                    onConfirm={handleResetPayments}
                                />
                            </Grid.Column>
                        </Grid>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <b>Баланс</b>
                    </TableCell>
                    <TableCell style={{ width: '200px' }}>
                        <b>
                            <ShadowFragment print={userBalanceInfo?.balance?.toLocaleString()} /> ₽
                        </b>
                    </TableCell>
                    <TableCell />
                </TableRow>
            </Table>
        </Container>
    );
};

export default observer(UserBalanceTab);
