import * as historyChangesApi from '~/api/historyChangesApi';

import ItemListsStorePrototype from './prototypes/ItemListsStore.prototype';
import { HistoryItemChange } from '~/types/historyChanges.types';

class HistoryChangeStore extends ItemListsStorePrototype<HistoryItemChange, number> {
    adjustItemToId(history: HistoryItemChange): number {
        return history.change_id;
    }
}

export default new HistoryChangeStore('user_id', historyChangesApi);
