import { ThemeOptions } from '@material-ui/core/styles';

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: '#00abd1',
            contrastText: '#fff'
        },
        secondary: {
            main: '#d12600'
        },
        text: {
            primary: '#181f31'
        }
    },
    overrides: {
        MuiBottomNavigation: {
            root: {
                backgroundColor: '#f5f5f5',
                boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.1), 0px -4px 5px 0px rgba(0,0,0,0.07), 0px -1px 10px 0px rgba(0,0,0,0.06)',
                zIndex: 20
            }
        }
    }
};

export default theme;
