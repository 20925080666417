import React, { Component, Fragment } from 'react';
import { Menu, Form, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ListFilterWrapper, { deltaCreateTimes } from '../ListFilterWrapper';
import wishStore from '~/stores/wishStore';
import { WishFilter } from '~/types/wish.types';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import FilterWishMoreDetails from './FilterBlocks/FilterWishMoreDetails';

@observer
class FilterWishes extends Component<ListFilterWrapperProps> {
    render() {
        const { deltaTime } = wishStore.listFilter;
        const { handleChange, setBlockOutsideClick } = this.props;

        return (
            <Fragment>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            placeholder="Время"
                            closeOnChange
                            selection
                            options={deltaCreateTimes}
                            value={deltaTime}
                            onChange={handleChange}
                            name="deltaTime"
                            onClick={setBlockOutsideClick}
                        />
                    </Form>
                </Menu.Item>
            </Fragment>
        );
    }
}

export default ListFilterWrapper(wishStore, FilterWishes, FilterWishMoreDetails);
