import { ITEM_TYPES } from './notifications.types';
import { ItemAttached } from './tasks.types';

export const CALENDAR_VIEW_TYPE_DAY = 'day';
export const CALENDAR_VIEW_TYPE_WEEK = 'week';
export const CALENDAR_VIEW_TYPE_MONTH = 'month';
export const CALENDAR_VIEW_TYPE_AGENDA = 'agenda';

export type CALENDAR_VIEW_TYPES = 'day' | 'week' | 'month' | 'agenda';

export const calendarItemTypes = {
    callEvent: 'Звонки',
    meetingEvent: 'Встречи',
    showing: 'Показы',
    deal: 'Сделки',
    user: 'ДР Сотрудников',
    contact: 'ДР Контактов'
};

export type CalendarEventType = {
    item_id: number;
    item_type: ITEM_TYPES;
    title: string;
    allDay: boolean;
    start: Date;
    end: Date;
    hasReleased?: boolean;

    attached?: ItemAttached[];
};

export type CALENDAR_ITEM_TYPE = keyof typeof calendarItemTypes;
