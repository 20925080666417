import { observable, action, runInAction, reaction } from 'mobx';
import moment from 'moment';
import * as commentsApi from '~/api/commentsApi';
import { CommentType, COMMENT_TYPE } from '~/types/comments.types';
import globalSearchStore, { SearchModuleResult } from './globalSearchStore';
import { ITEM_TYPES } from '~/types/notifications.types';
import { ItemLinkTitle, ItemState } from '../components/Base/ItemLinkSimple';
import config from '../config/ui.config';
import authStore from './authStore';
import { ACCESS_COMPANY, ACCESS_READ, ACCESS_WRITE } from '~/types/access.types';
import accessStore from './accessStore';
import wait from '../common/wait';
import commonStore from './commonStore';

type COMMENT_ITEM = {
    comments: CommentType[];
    comment: string;
    reply_to: number | null;
    loading: boolean;
    errors?: Array<string>;
};

class CommentStore {
    @observable
    comments: Map<string, COMMENT_ITEM> = new Map();

    moduleName: ITEM_TYPES;

    globalSearchRegistered = false;

    constructor() {
        this.moduleName = 'comment';

        reaction(
            () => authStore.currentUser,
            () => {
                if (authStore.getPermission(accessStore.moduleName, ACCESS_READ) === ACCESS_COMPANY && !this.globalSearchRegistered) {
                    if (!commonStore.isMobile) {
                        globalSearchStore.regModule({
                            title: 'Комментарии',
                            moduleName: this.moduleName,
                            search: this.globalSearchList
                        });
                    }
                    this.globalSearchRegistered = true;
                }
            }
        );
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        if (query.length < 5) {
            return { list: [], count: 0 };
        }

        const { list, count } = await commentsApi.findCommentsByText(query, start, limit, abortController);

        return {
            list: list.map(({ comment, user, item, item_link_type, comment_id, time }) => ({
                key: comment_id,
                title: `✎ ${user.lastName} ${user.firstName}: ${comment}`,
                description: `${ItemLinkTitle({ item, item_type: item_link_type })} (${moment(time * 1000).calendar()} )`,
                state: ItemState({ item, item_type: item_link_type }),
                className: this.moduleName,
                image: user.avatarUrl || config.defaultAvatarUrl
            })),
            count
        };
    };

    @action
    async fetchComments(item_type: COMMENT_TYPE, item_id: number) {
        const commentKey = `${item_type}_${item_id}`;

        let comment = '';
        const commentItem = this.comments.get(commentKey);
        if (commentItem) {
            comment = commentItem.comment;
        }

        this.comments.set(commentKey, {
            comments: [],
            loading: true,
            comment,
            reply_to: null
        });

        try {
            // Ждем, когда откроется пушер:
            await wait(200);
            const comments = await commentsApi.fetchComments(item_type, item_id);

            this.comments.set(commentKey, {
                comments,
                loading: false,
                comment,
                reply_to: null
            });
        } catch (errors) {
            this.comments.set(commentKey, {
                comments: [],
                errors,
                loading: false,
                comment,
                reply_to: null
            });
        }
    }

    @action
    async updateComments(item_type: COMMENT_TYPE, item_id: number) {
        const comments = await commentsApi.fetchComments(item_type, item_id);
        const commentKey = `${item_type}_${item_id}`;
        const oldComments = this.comments.get(commentKey);
        this.comments.set(commentKey, {
            ...oldComments,
            comments
        });
    }

    @action
    async addComment(item_type: COMMENT_TYPE, item_id: number): Promise<CommentType[]> {
        const item = this.getItem(item_type, item_id);
        item.loading = true;

        const comments = await commentsApi.addComment(item_type, item_id, item.comment.trim(), item.reply_to);

        item.comment = '';
        item.loading = false;
        item.comments = comments;

        return comments;
    }

    @action
    setReplyTo(item_type: COMMENT_TYPE, item_id: number, reply_to: number | null) {
        const item = this.getItem(item_type, item_id);
        item.comment = '';
        item.reply_to = reply_to;
    }

    @observable
    getItem(item_type: COMMENT_TYPE, item_id: number): COMMENT_ITEM {
        const commentKey = `${item_type}_${item_id}`;
        const commentItem = this.comments.get(commentKey);
        if (!commentItem) {
            throw Error(`Key isn't exist`);
        }
        return commentItem;
    }

    @action
    setComment(item_type: COMMENT_TYPE, item_id: number, comment: string) {
        const item = this.getItem(item_type, item_id);
        item.comment = comment;
    }

    @action
    async removeComment(item_type: COMMENT_TYPE, item_id: number, comment_id: number) {
        const item = this.getItem(item_type, item_id);
        item.loading = true;

        const comments = await commentsApi.removeComment(item_type, item_id, comment_id);

        item.loading = false;
        item.comments = comments;
    }
}

export default new CommentStore();
