import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { SemanticCOLORS } from 'semantic-ui-react';

import ContactShowMode from './ContactShowMode';
import ContactAddMode from './ContactAddMode';
import ContactCallsMode from './ContactCallsMode';
import ContactWhatsappMode from './ContactWhatsappMode';
import ContactMortgageMode from './ContactMortgageMode';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';
import contactStore from '~/stores/contactStore';
import { ItemWrapperState } from '../ItemWrapper';
import ContactTabMenu from './ContactTabMenu';
import ItemErrors from '../ItemErrors';
import { execDaily } from '~/common/time';
import ContactHistoryMode from './ContactHistoryMode';
import { matchTabLabel, MenuItem } from '../TabMenuWrapper';
import ContactIsAlreadyCreated from './ShowBlocks/SubBlocks/ContactIsAlreadyCreated';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIcon from '@material-ui/icons/Phone';
import HistoryIcon from '@material-ui/icons/History';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const TAB_WHATSAPP = 'whatsapp';
const TAB_CALLS = 'calls';
const TAB_MORTGAGE_REQUEST = 'mortgage';

@observer
class ContactItem extends Component<ItemWrapperState> {
    checkWhetherPhoneIsNotUsedInvoked = false;

    componentDidUpdate() {
        const { item_id } = this.props;
        const { loadingItem, item } = contactStore.getItem(item_id);
        if (item && !loadingItem && !this.checkWhetherPhoneIsNotUsedInvoked) {
            const { phones } = contactStore.getItem(item_id).item;

            if (phones.length) {
                this.checkWhetherPhoneIsNotUsedInvoked = true;
                phones.map(({ phone }) => contactStore.checkWhetherPhoneIsNotUsed(item_id, phone));
            }
        }
    }

    render() {
        const { item_id, tabName, param } = this.props;
        const { loadingItem, errors, item } = contactStore.getItem(item_id);
        const { enable, major_user_ids, documentsCount, cellCallsCount, restrictedAccess, nearestUncompletedCallEvent } = item || {};

        let callTabTitle = `Звонки`;
        let callTabTitleColor: SemanticCOLORS = 'green';

        if (nearestUncompletedCallEvent) {
            if (nearestUncompletedCallEvent.callTimeMax * 1000 < Date.now()) {
                callTabTitleColor = 'red';
                callTabTitle += ' – 📅 просрочен звонок';
            } else {
                callTabTitle += ` – 📅 ${execDaily(nearestUncompletedCallEvent.callTimeMin * 1000).printTime}`;
            }
        }

        const ContactTabs: MenuItem[] = [
            { name: 'Обзор', id: '', color: 'blue', icon: <AccountBoxIcon /> },
            {
                name: callTabTitle,
                id: TAB_CALLS,
                color: callTabTitleColor,
                label: matchTabLabel(cellCallsCount),
                icon: <PhoneIcon color={callTabTitleColor === 'red' ? 'error' : 'inherit'} />
            },
            {
                name: 'WhatsApp',
                id: TAB_WHATSAPP,
                icon: <WhatsAppIcon />
            },
            {
                name: 'Ипотека',
                id: TAB_MORTGAGE_REQUEST,
                icon: <AccountBalanceIcon />
            }
        ];

        const { contactIsAlreadyCreated } = contactStore.getItem(item_id).property;

        if (!restrictedAccess) {
            ContactTabs.push({ name: 'История', id: TAB_ID_HISTORY, color: 'brown', icon: <HistoryIcon /> });
        }

        return (
            <Fragment>
                <ContactTabMenu
                    itemTabs={ContactTabs}
                    item_id={item_id}
                    param={param}
                    tabName={tabName}
                    enable={Boolean(enable)}
                    major_user_id={major_user_ids ? Array.from(major_user_ids) : []}
                    documentsCount={documentsCount}
                    contact_id={item_id}
                />

                <div className="crm-List__sidebar_modalBox">
                    {errors && <ItemErrors errors={errors} tabName={tabName} />}
                    {[TAB_ID_CREATING, TAB_ID_DEFAULT].includes(tabName) && contactIsAlreadyCreated && (
                        <ContactIsAlreadyCreated showInfo={tabName === TAB_ID_DEFAULT} contactOrUser={contactIsAlreadyCreated} />
                    )}
                    {tabName === TAB_ID_CREATING && <ContactAddMode param={param} />}
                    {tabName === TAB_ID_DEFAULT && (
                        <ContactShowMode
                            restrictedAccess={Boolean(restrictedAccess)}
                            loading={loadingItem}
                            contact_id={item_id}
                            major_user_ids={major_user_ids}
                        />
                    )}
                    {tabName === TAB_CALLS && <ContactCallsMode contact_id={item_id} />}
                    {tabName === TAB_WHATSAPP && <ContactWhatsappMode contact_id={item_id} />}
                    {tabName === TAB_ID_HISTORY && <ContactHistoryMode item_id={item_id} />}
                    {tabName === TAB_MORTGAGE_REQUEST && <ContactMortgageMode contact_id={item_id} />}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(contactStore, ContactItem);
