import { observable, action, runInAction } from 'mobx';
import * as triggerApi from '~/api/triggersApi';
import { Trigger } from '~/types/triggers.types';
import ListStorePrototype from './prototypes/ListStore.prototype';
import deepCopy from '../common/deepCopy';
import { DropdownType } from './prototypes/ListStore.prototype';

class TriggerStore extends ListStorePrototype<Trigger> {
    constructor() {
        super('trigger_id', 'settings', triggerApi);
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const { item } = this.getItem(id);
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }

    tasksTriggers: DropdownType[] = [];

    @observable
    loadingTasksTriggers = true;

    @action
    async fetchTasksTriggers() {
        if (!this.tasksTriggers.length) {
            this.tasksTriggers = (await triggerApi.fetchTasksTriggers()).map(({ trigger, title }) => ({
                key: trigger,
                value: trigger,
                text: title
            }));

            runInAction(() => {
                this.loadingTasksTriggers = false;
            });
        }
    }
}

export default new TriggerStore();
