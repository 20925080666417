import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import './estate.less';
import cs from 'classnames';

import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';
import exportStore from '~/stores/export/exportStore';

import EstateAddMode from './EstateAddMode';
import EstateShowMode from './EstateShowMode';
import EstateReportsMode from './EstateReportsMode';
import EstateCallsMode from './EstateCallsMode';
import EstateTabMenu from './EstateTabMenu';
import OwnersTabMenu from './OwnersTabMenu';
import ExternalTabMenu from './ExternalTabMenu';
import MlsTabMenu from './MlsTabMenu';
import EstateMlsMode from './EstateMlsMode';
import EstateOwnersMode from './EstateOwnersMode';
import EstateWishesMode from './EstateWishesMode';
import EstateHistoryMode from './EstateHistoryMode';
import OwnersHistoryMode from './OwnersHistoryMode';
import OwnersOutgoingCallsMode from './OwnersOutgoingCallsMode';
import EstateSameMode, { sameEstatesDefaultFilter } from './EstateSameMode';
import EstateExternalMode from './EstateExternalMode';
import EstateExportMode from './EstateExportMode';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';
import authStore from '~/stores/authStore';
import { ESTATE_BASE_MLS, ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL } from '~/types/estate.types';
import showingStore from '~/stores/showingStore';
import wishStore from '~/stores/wishStore';
import dealStore from '~/stores/dealStore';
import ownerStore from '~/stores/ownerStore';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import { matchTabLabel, MenuItem } from '../TabMenuWrapper';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import ExploreIcon from '@material-ui/icons/Explore';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HistoryIcon from '@material-ui/icons/History';
import StorageIcon from '@material-ui/icons/Storage';
import EstateSMAMode from './EstateSMAMode';
import { FOREIGN_GEO_LAT_MAX } from '~/stores/helpers/export.store.helpers';

const TAB_ID_WISHES = 'wishes';
const TAB_ID_CALLS = 'calls';
const TAB_ID_CALLS_OUTGOING = 'outgoing';
const TAB_ID_SHOWINGS = 'showings';
const TAB_ID_SAME_ESTATES = 'same';
const TAB_ID_SMA_ESTATES = 'sma';
const TAB_ID_EXPORT = 'export';

@observer
class EstateItem extends Component<ItemWrapperState> {
    constructor(props: ItemWrapperState) {
        super(props);

        if (props.base === ESTATE_BASE_OWNERS) {
            ownerStore.fetchItem(props.item_id);
        }
        if (props.item_id > CREATING_ITEM_ID) {
            estateStore.fetchSameEstates(props.item_id, props.base, { ...sameEstatesDefaultFilter });
        }
    }

    componentDidUpdate() {
        const { item_id } = this.props;
        const { item, loadingItem, property } = estateStore.getItem(item_id);
        if (item && !loadingItem && !property.wishesLocationType) {
            estateStore.firstFetchWishesByEstate(item_id);
        }
    }

    render() {
        let { item_id, tabName, base, param } = this.props;

        switch (base) {
            case ESTATE_BASE_MLS:
                if (![TAB_ID_WISHES, TAB_ID_HISTORY, TAB_ID_SAME_ESTATES].includes(tabName)) {
                    tabName = ESTATE_BASE_MLS;
                }
                break;
            case ESTATE_BASE_OWNERS:
                if (![TAB_ID_WISHES, TAB_ID_HISTORY, TAB_ID_CALLS_OUTGOING, TAB_ID_SAME_ESTATES].includes(tabName)) {
                    tabName = ESTATE_BASE_OWNERS;
                }
                break;
            case ESTATE_BASE_EXTERNAL:
                if (![TAB_ID_WISHES, TAB_ID_HISTORY, TAB_ID_SAME_ESTATES].includes(tabName)) {
                    tabName = ESTATE_BASE_EXTERNAL;
                }
                break;
            default:
                break;
        }

        const { modeSearchOnMap } = estateStore;
        const { errors, item, loadingItem, property } = estateStore.getItem(item_id);
        const {
            enable,
            major_user_ids,
            showingsCount,
            documentsCount,
            callsCount,
            contact_id,
            exclusiveDealId,
            exportingErrorsCount,
            geo_lat,
            newbuilding_id
        } = item || {};

        const probablyWishesCount = property.wishes ? property.wishes.length : undefined;
        const sameEstatesCount = property.sameEstates ? property.sameEstates.length : undefined;

        let EstateTabs: MenuItem[] = [];
        let enableOwnersMenu = null;

        if (base === ESTATE_BASE_MAIN) {
            EstateTabs = [{ name: 'Описание', id: '', color: 'blue', icon: <HomeIcon /> }];

            if (authStore.canRead(exportStore.moduleName, major_user_ids)) {
                EstateTabs.push({
                    name: `Экспорт`,
                    id: TAB_ID_EXPORT,
                    color: 'error',
                    label: Number(exportingErrorsCount) || 0,
                    icon: <ImportExportIcon />
                });
            }

            if (authStore.canEdit(estateStore.moduleName, major_user_ids)) {
                EstateTabs.push({
                    name: `Звонки`,
                    id: TAB_ID_CALLS,
                    color: 'yellow',
                    label: matchTabLabel(callsCount),
                    icon: <PhoneIcon />
                });
            }

            if (authStore.canRead(showingStore.moduleName, major_user_ids)) {
                EstateTabs.push({
                    name: `Показы`,
                    id: TAB_ID_SHOWINGS,
                    color: 'green',
                    label: matchTabLabel(showingsCount),
                    icon: <ExploreIcon />
                });
            }
        } else if (base === ESTATE_BASE_OWNERS) {
            EstateTabs = [
                {
                    name: 'Собственник',
                    id: base,
                    color: 'brown',
                    icon: <EmojiPeopleIcon />
                }
            ];

            const hasAssigned = ownerStore.whetherOwnerHasAssigned(item_id);

            if (hasAssigned || [ACCESS_COMPANY, ACCESS_GROUP].includes(authStore.getPermission(estateStore.moduleName, ACCESS_WRITE))) {
                EstateTabs.push({
                    name: 'Прозвоны',
                    id: TAB_ID_CALLS_OUTGOING,
                    color: 'yellow',
                    icon: <PhoneIcon />
                });

                enableOwnersMenu = [ACCESS_COMPANY].includes(authStore.getPermission(estateStore.moduleName, ACCESS_WRITE));
                if (!enableOwnersMenu) {
                    const { item: owner } = ownerStore.getItem(item_id);
                    enableOwnersMenu =
                        hasAssigned && owner && (!owner.isBooked || owner.isBooked.major_user_id === authStore.currentUser.user_id);
                }
            }
        } else {
            EstateTabs = [
                {
                    name: newbuilding_id ? 'Квартира в ЖК' : 'Внешний объект',
                    id: base,
                    color: 'red',
                    icon: <StorageIcon />
                }
            ];
        }

        if (authStore.canRead(wishStore.moduleName, major_user_ids)) {
            if (base === ESTATE_BASE_MAIN) {
                EstateTabs.push({
                    // name: true ? 'Похожие' : 'СМА',
                    name: 'Похожие',
                    id: TAB_ID_SMA_ESTATES,
                    color: 'green',
                    label: matchTabLabel(sameEstatesCount),
                    icon: <BallotIcon />
                });
            } else {
                EstateTabs.push({
                    name: 'Похожие',
                    id: TAB_ID_SAME_ESTATES,
                    color: 'green',
                    label: matchTabLabel(sameEstatesCount),
                    icon: <BallotIcon />
                });
            }

            EstateTabs.push({
                name: 'Покупатели',
                id: TAB_ID_WISHES,
                color: 'red',
                label: matchTabLabel(probablyWishesCount),
                icon: <AccountBalanceWalletIcon />
            });
        }

        EstateTabs.push({ name: 'История', id: TAB_ID_HISTORY, color: 'brown', icon: <HistoryIcon /> });

        return (
            <Fragment>
                {base === ESTATE_BASE_MAIN && (
                    <EstateTabMenu
                        itemTabs={EstateTabs}
                        item_id={item_id}
                        param={param}
                        tabName={tabName}
                        enable={Boolean(enable)}
                        major_user_id={major_user_ids ? Array.from(major_user_ids) : []}
                        documentsCount={documentsCount}
                        contact_id={contact_id}
                        additionalDocuments={
                            exclusiveDealId
                                ? [
                                      {
                                          item_type: dealStore.moduleName,
                                          item_id: exclusiveDealId,
                                          sourceTitle: 'С прикрепленного эксклюзива'
                                      }
                                  ]
                                : []
                        }
                    />
                )}

                {base === ESTATE_BASE_OWNERS && (
                    <OwnersTabMenu
                        itemTabs={EstateTabs.slice(0, enableOwnersMenu ? Infinity : 1)}
                        item_id={item_id}
                        param={param}
                        tabName={tabName}
                        enable={Boolean(enable)}
                        major_user_id={major_user_ids ? Array.from(major_user_ids) : []}
                        owner_id={enableOwnersMenu ? item_id : null}
                        contact_id={enableOwnersMenu ? contact_id : null}
                    />
                )}

                {base === ESTATE_BASE_EXTERNAL && (
                    <ExternalTabMenu
                        itemTabs={EstateTabs}
                        item_id={item_id}
                        param={param}
                        tabName={tabName}
                        enable={Boolean(enable)}
                        major_user_id={[]}
                    />
                )}

                {base === ESTATE_BASE_MLS && (
                    <MlsTabMenu
                        itemTabs={EstateTabs}
                        item_id={item_id}
                        param={param}
                        tabName={tabName}
                        enable={Boolean(enable)}
                        major_user_id={[]}
                    />
                )}

                <div
                    className={cs({ 'crm-List__sidebar_modalBox': true, 'crm-List__sidebar_modalBox_onMap': modeSearchOnMap })}
                    style={
                        commonStore.isMobile && [TAB_ID_DEFAULT, ESTATE_BASE_EXTERNAL, ESTATE_BASE_OWNERS].includes(tabName)
                            ? { paddingLeft: 0, paddingRight: 0 }
                            : null
                    }
                >
                    {errors && <ItemErrors errors={errors} tabName={tabName} />}
                    {tabName === TAB_ID_CREATING && <EstateAddMode param={param} />}
                    {tabName === ESTATE_BASE_MLS && (
                        <EstateMlsMode estate_id={item_id} isForeign={geo_lat && geo_lat < FOREIGN_GEO_LAT_MAX} />
                    )}
                    {tabName === ESTATE_BASE_OWNERS && (
                        <EstateOwnersMode estate_id={item_id} enable={Boolean(enable)} contact_id={contact_id} />
                    )}
                    {tabName === TAB_ID_DEFAULT && (
                        <EstateShowMode
                            loading={loadingItem}
                            estate_id={item_id}
                            major_user_ids={major_user_ids ? Array.from(major_user_ids) : []}
                            enable={Boolean(enable)}
                        />
                    )}
                    {tabName === ESTATE_BASE_EXTERNAL && <EstateExternalMode estate_id={item_id} newbuilding_id={newbuilding_id} />}
                    {tabName === TAB_ID_SHOWINGS && <EstateReportsMode estate_id={item_id} />}
                    {tabName === TAB_ID_CALLS && <EstateCallsMode estate_id={item_id} />}
                    {tabName === TAB_ID_EXPORT && <EstateExportMode estate_id={item_id} />}
                    {tabName === TAB_ID_WISHES && <EstateWishesMode estate_id={item_id} base={base} />}

                    {tabName === TAB_ID_CALLS_OUTGOING && <OwnersOutgoingCallsMode estate_id={item_id} />}
                    {tabName === TAB_ID_SAME_ESTATES && <EstateSameMode estate_id={item_id} base={base} />}
                    {tabName === TAB_ID_SMA_ESTATES && <EstateSMAMode estate_id={item_id} />}

                    {tabName === TAB_ID_HISTORY && base === ESTATE_BASE_MAIN && (
                        <EstateHistoryMode item_id={item_id} base={ESTATE_BASE_MAIN} />
                    )}
                    {tabName === TAB_ID_HISTORY && base === ESTATE_BASE_OWNERS && <OwnersHistoryMode item_id={item_id} />}
                    {tabName === TAB_ID_HISTORY && base === ESTATE_BASE_MLS && (
                        <EstateHistoryMode item_id={item_id} base={ESTATE_BASE_MLS} />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(estateStore, EstateItem);
