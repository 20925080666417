import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';
import { Header, Grid } from 'semantic-ui-react';
import { InfoMessage } from '~ui/Message';
import notificationStore from '~/stores/notificationStore';
import OutsideClick from '~/components/Base/OutsideClick';
import { ItemState } from '~/components/Base/ItemLinkSimple';
import { ITEM_TYPES, ITEM_TYPES_OBJECT } from '~/types/notifications.types';
import history from '~/history';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popover from '@material-ui/core/Popover';
import Divider from '~ui/Divider';

import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import NotificationsArchiveModal from '~/components/Header/Notifications/NotificationsArchiveModal';
import NotiListItem from '~/components/Header/Notifications/NotyListItem';
import HeaderAnimatedIconButton from '~/components/Header/Notifications/HeaderAnimatedIconButton';

type HeaderNotificationsState = {
    popupIsOpen: boolean;
    showArchive: boolean;
};

@observer
export default class HeaderNotifications extends Component<{}, HeaderNotificationsState> {
    state = {
        popupIsOpen: false,
        showArchive: false
    };

    handleReadNotification = (noty_id: number) => {
        notificationStore.readNotification(noty_id);
    };

    handleReadAllNotifications = () => {
        notificationStore.readAllNotifications();
    };

    handleItemClick = (noty_id: number, item_type: ITEM_TYPES, item: ITEM_TYPES_OBJECT) => {
        this.setState({ popupIsOpen: false });
        notificationStore.readNotification(noty_id);
        history.push(ItemState({ item_type, item }));
    };

    handleItemLinkClick = (event: React.SyntheticEvent, noty_id: number) => {
        event.stopPropagation();
        this.setState({ popupIsOpen: false });
        notificationStore.readNotification(noty_id);
    };

    handleTogglePopup = () => {
        this.setState({ popupIsOpen: !this.state.popupIsOpen });
    };

    handleClosePopup = () => {
        this.setState({ popupIsOpen: false });
    };

    handleToggleArchive = () => {
        this.setState({ showArchive: !this.state.showArchive });
    };

    $triggerRef: HTMLElement;

    render() {
        const { notificationsList, isTooLongNotReadMessages } = notificationStore;
        const { popupIsOpen, showArchive } = this.state;
        const count = notificationsList.filter(({ enable }) => enable).length;

        return (
            <Fragment>
                <HeaderAnimatedIconButton
                    count={count}
                    icon={<NotificationsActiveIcon />}
                    onToggle={this.handleTogglePopup}
                    setRef={$ref => (this.$triggerRef = $ref)}
                />

                <Popover
                    open={popupIsOpen || isTooLongNotReadMessages}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    anchorEl={this.$triggerRef}
                >
                    <OutsideClick onOutsideClick={this.handleClosePopup} style={{ padding: '1em' }}>
                        <Grid className="crm-Header__notifications_header">
                            <Grid.Column width={8}>
                                <Header as="h4">Уведомления</Header>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                {count > 0 && (
                                    <Button size="small" variant="text" onClick={this.handleReadAllNotifications}>
                                        Очистить все
                                    </Button>
                                )}
                            </Grid.Column>
                        </Grid>
                        <Divider />

                        {count === 0 && <InfoMessage variant="outlined">У вас нет новых уведомлений</InfoMessage>}

                        {count > 0 && (
                            <List dense disablePadding>
                                {notificationsList.map(noty => {
                                    const { enable, noty_id } = noty;

                                    return (
                                        <Slide direction="left" in={enable} mountOnEnter unmountOnExit key={noty_id}>
                                            <NotiListItem
                                                noty={noty}
                                                readNoty={this.handleReadNotification}
                                                onItemLinkClick={this.handleItemLinkClick}
                                                onItemClick={this.handleItemClick}
                                            />
                                        </Slide>
                                    );
                                })}
                            </List>
                        )}

                        <Divider />
                        <ButtonGroup style={{ display: 'flex' }}>
                            {count > 0 && (
                                <Button variant="text" size="small" style={{ flex: 1 }} onClick={this.handleReadAllNotifications}>
                                    Очистить все
                                </Button>
                            )}
                            <Button variant="text" size="small" color="primary" style={{ flex: 1 }} onClick={this.handleToggleArchive}>
                                Архив
                            </Button>
                        </ButtonGroup>
                    </OutsideClick>
                </Popover>

                {showArchive && <NotificationsArchiveModal onClose={this.handleToggleArchive} />}
            </Fragment>
        );
    }
}
