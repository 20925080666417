import React from 'react';

import Table, { TableBody, TableCell, TableRow } from '~ui/Table';
import TableContainer from '@material-ui/core/TableContainer';

import Paper from '@material-ui/core/Paper';

import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';

import EstateLinkWithPreview from '~/components/Lists/Estate/EstateLinkWithPreview';
import EstateTableAreaBlock from '~/components/Lists/Estate/SubBlocks/EstateTableAreaBlock';
import EstateTableFloorAndHouseBlock from '~/components/Lists/Estate/SubBlocks/EstateTableFloorAndHouseBlock';
import estateL10n from '~/L10n/estate.L10n';
import EstateTableTimes from '~/components/Lists/Estate/SubBlocks/EstateTableTimes';
import EstateTableNewbuilding from '~/components/Lists/Estate/SubBlocks/EstateTableNewbuilding';
import MainMetro from '~/components/Lists/Estate/MainMetro';

import MonoString from '~/components/Base/MonoString';
import SmaEstateMenu from './SmaEstateMenu';
import { SmaEstateItemType } from '~/types/estateSma.types';
import EstateSmaOuterLink from '~/components/Items/Estate/EstateSmaBlocks/EstateSmaOuterLink';

const EstateSmaTable = ({
    estateList,
    sma_estate_id,
    enableEditing
}: {
    estateList: SmaEstateItemType[];
    sma_estate_id: number;
    enableEditing: boolean;
}) => {
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableBody>
                    {estateList.map(estate => {
                        const {
                            type,
                            propertyType,
                            description,
                            estate_id,
                            cityDistrict,
                            cityDistrictType,
                            metros,
                            address,
                            houseNumberAdjusted,
                            roomsCount,
                            totalArea,
                            livingArea,
                            kitchenArea,
                            allRoomsArea,
                            floorNumber,
                            floorsCount,
                            materialType,
                            createTime,
                            updateTime,
                            price,
                            currency,
                            base,
                            photosCount,
                            newbuilding,
                            main_metro_id,

                            buildYear,
                            buildingIsNotReadyYet,

                            landArea,
                            landAreaUnitType,

                            enable,

                            source,
                            sourceInnerId,

                            externalUrlAvailable,
                            priceInSma
                        } = estate;

                        return (
                            <TableRow key={estate_id} hover>
                                <TableCell>
                                    <EstateTableTimes
                                        byCreateTime={false}
                                        enable={enable}
                                        createTime={createTime}
                                        updateTime={updateTime}
                                    />
                                </TableCell>
                                <TableCell>
                                    <EstateLinkWithPreview
                                        estate={{
                                            type,
                                            address,
                                            estate_id,
                                            roomsCount,
                                            propertyType,
                                            enable,
                                            houseNumberAdjusted
                                        }}
                                        base={base}
                                    />
                                    <div>
                                        <EstateTableNewbuilding newbuilding={newbuilding} simpleMode />
                                        {!newbuilding && metros && <MainMetro metros={metros} main_metro_id={main_metro_id} />}
                                        {!newbuilding && (!metros || !metros.length) && cityDistrict && (
                                            <span className="crm-Estate__fieldNowrap">
                                                {cityDistrict} {cityDistrictType}
                                            </span>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <EstateTableAreaBlock
                                        propertyType={propertyType}
                                        totalArea={totalArea}
                                        livingArea={livingArea}
                                        kitchenArea={kitchenArea}
                                        allRoomsArea={allRoomsArea}
                                        landArea={landArea}
                                        landAreaUnitType={landAreaUnitType}
                                        roomsCount={roomsCount}
                                    />
                                </TableCell>
                                <TableCell>
                                    <EstateTableFloorAndHouseBlock
                                        propertyType={propertyType}
                                        floorsCount={floorsCount}
                                        floorNumber={floorNumber}
                                        materialType={materialType}
                                        buildYear={buildYear}
                                        buildingIsNotReadyYet={buildingIsNotReadyYet}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <MonoString>
                                        {(priceInSma || price || 0).toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(currency)}{' '}
                                        {type === ESTATE_TYPE_RENT_ID && '/мес'}
                                    </MonoString>
                                    <br />
                                    {totalArea && (
                                        <MonoString>
                                            {type === ESTATE_TYPE_SELL_ID &&
                                                Number(totalArea) > 0 &&
                                                `${Math.round((priceInSma || price) / Number(totalArea)).toLocaleString()} за м²`}
                                        </MonoString>
                                    )}
                                </TableCell>

                                <TableCell align="right">
                                    {source && sourceInnerId && (
                                        <EstateSmaOuterLink
                                            source={source}
                                            sourceInnerId={sourceInnerId}
                                            externalUrlAvailable={externalUrlAvailable}
                                        />
                                    )}
                                </TableCell>

                                {enableEditing && (
                                    <TableCell align="right">
                                        <SmaEstateMenu
                                            sma_estate_id={sma_estate_id}
                                            estate_id={estate_id}
                                            base={base}
                                            externalUrlAvailable={externalUrlAvailable}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EstateSmaTable;
