import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';
import developerStore from '~/stores/lifeComplexes/developerStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import Select from '~ui/Select';
import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import { InfoMessage } from '~ui/Message';
import Progress from '~ui/Progress';
import { useToggle } from '~ui/Modal';
import Button from '~ui/Button';

import LoaderAwait from '~/components/Base/LoaderAwait';
import ListError from '~/components/ListError';
import LifeComplexFeedNewbuildings from '~/components/Settings/LifeComplexFeeds/Blocks/LifeComplexFeedNewbuildings';
import DeveloperFeedTestModal from './DeveloperFeedTestModal';

const LifeComplexFeedUrlEditing = ({ developer_feed_id, developer_id }: { developer_feed_id: number; developer_id: number }) => {
    const [showTestModal, toggleTestModal] = useToggle();

    useMemo(() => {
        developerFeedStore.fetchItem(developer_feed_id);
        developerFeedStore.setEditingItem(developer_feed_id, { developer_id });
    }, [developer_feed_id]);

    const [expanded, toggleExpanded] = useToggle();

    const handleChange = useCallback((_, { name, value }: { name: string; value: unknown }) => {
        // developerFeedStore.setProperty(developer_feed_id, { errors: [], developerFeedInfo: null });
        developerFeedStore.setEditingItem(developer_feed_id, {
            [name]: value
        });
    }, []);

    const handleChangeFormat = (_, { value: format }: { value: 'cian2' | 'yandex' }) => {
        developerFeedStore.setEditingItem(developer_feed_id, { format });
    };

    const { editingItem, property, loadingItem, errors } = developerFeedStore.getItem(developer_feed_id);
    const { feedUrl, format } = editingItem;
    const { errors: feedErrors, loadingFeedInfo, developerFeedInfo } = property;

    useEffect(() => {
        if (feedUrl && format === 'domclick') {
            developerFeedStore.checkDomclickFeed(developer_feed_id).then(developerFeedInfo => {
                if (!developerFeedInfo) return;
                const { developer } = developerFeedInfo;
                if (developer_id === CREATING_ITEM_ID) {
                    developerStore.setEditingItem(developer_id, {
                        url: developer?.site || '',
                        logoUrl: developer?.logo || '',
                        name: developer?.name || ''
                    });
                }
            });
        }
    }, [feedUrl, format, developer_feed_id]);

    const handleCreate = async () => {
        if (await developerFeedStore.createItem()) {
            toggleExpanded();
        }
    };

    const handleDelete = async () => {
        await developerFeedStore.toggleDisableItem(developer_feed_id, false);
    };

    const isLoading = loadingItem || loadingFeedInfo;

    return (
        <Accordion expanded={expanded} disabled={isLoading} style={{ width: '100%' }}>
            <AccordionSummary
                style={{ cursor: 'initial' }}
                expandIcon={
                    isLoading ? (
                        <Progress size={16} show />
                    ) : (
                        <Badge badgeContent={!expanded ? developerFeedInfo?.lifeComplexes?.length : null} color="secondary">
                            <Button
                                icon={<ExpandMoreIcon />}
                                onClick={toggleExpanded}
                                tooltip={`${expanded ? 'Скрыть' : 'Открыть'} список жилых комплексов`}
                            />
                        </Badge>
                    )
                }
            >
                <Grid disableGutter>
                    <Grid.Column width={9}>
                        <TextInput
                            type="url"
                            value={feedUrl}
                            name="feedUrl"
                            onChange={handleChange}
                            minLength={2}
                            required
                            label="Url фида"
                        />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Select
                            value={format}
                            label="Формат"
                            onChange={handleChangeFormat}
                            fullWidth
                            options={[
                                { value: 'cian2', text: 'Циан-2' },
                                // { value: 'yandex', text: 'Яндекс' },
                                { value: 'domclick', text: 'Домклик' }
                            ]}
                        />
                    </Grid.Column>
                    {(errors?.length > 0 || feedErrors?.length > 0) && (
                        <Grid.Column>
                            <ListError errors={[...(errors || []), ...(feedErrors || [])]} />
                        </Grid.Column>
                    )}
                </Grid>
            </AccordionSummary>

            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                {format === 'domclick' && <Typography variant="subtitle2">Жилые комплексы</Typography>}
                <Grid disableGutter>
                    {isLoading && (
                        <Grid.Column>
                            <InfoMessage header="Проверка фида">
                                <LoaderAwait linear active />
                            </InfoMessage>
                        </Grid.Column>
                    )}
                    {developerFeedInfo && (
                        <Grid.Column>
                            <LifeComplexFeedNewbuildings developer_feed_id={developer_feed_id} />
                        </Grid.Column>
                    )}
                    {developer_id !== CREATING_ITEM_ID && (
                        <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            {developer_feed_id !== CREATING_ITEM_ID && (
                                <Button variant="text" size="small" onClick={handleDelete}>
                                    Удалить фид
                                </Button>
                            )}
                            <Button variant="outlined" color="primary" size="small" onClick={toggleTestModal}>
                                Тест/Загрузить фид
                            </Button>
                            <Button variant="contained" primary size="small" onClick={handleCreate}>
                                {developer_feed_id === CREATING_ITEM_ID ? 'Добавить фид' : 'Сохранить фид'}
                            </Button>
                        </Grid.Column>
                    )}
                    {developer_id === CREATING_ITEM_ID && (
                        <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                            <Button variant="outlined" color="primary" size="small" onClick={toggleTestModal}>
                                Тест фид
                            </Button>
                        </Grid.Column>
                    )}
                    {showTestModal && (
                        <DeveloperFeedTestModal feedUrl={feedUrl} format={format} developer_id={developer_id} onClose={toggleTestModal} />
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default observer(LifeComplexFeedUrlEditing);
