import { observable, action, runInAction } from 'mobx';
import * as taskApi from '~/api/taskApi';

import { Task, TaskFilter } from '~/types/tasks.types';
import ListStorePrototype, { OrderDirectionType } from './prototypes/ListStore.prototype';
import authStore from './authStore';
import { HistoryChange } from '~/types/historyChanges.types';

type TaskItemPropertyType = {
    loadingHistory: boolean;
    history: HistoryChange[];
};

class TaskStore extends ListStorePrototype<Task, Task, TaskItemPropertyType, TaskFilter> {
    listFilterClear = {
        enable: true,
        group_id: [],
        major_user_id: []
    };

    orderDirection: OrderDirectionType = 'descending';
    orderBy = 'task_id';

    constructor() {
        super('task_id', 'task', taskApi);
        this.clearFilter();
    }

    @action
    async changeStatus(task_id: number, status: number) {
        await this.setEditingItem(task_id, { status });
        await this.saveItem(task_id);
    }

    @action
    async saveItem(id: number): Promise<Partial<Task> | boolean> {
        const updatedTask: Partial<Task> | boolean = await super.saveItem(id);

        if (typeof updatedTask !== 'boolean') {
            [
                ['observer_users_ids', 'observer_users'],
                ['assign_users_ids', 'assign_users']
            ].forEach(usersSetTitle => {
                if (updatedTask[usersSetTitle[0]] && updatedTask[usersSetTitle[0]] instanceof Array) {
                    const usersSet = updatedTask[usersSetTitle[0]].reduce((set, user_id) => {
                        try {
                            set.push(authStore.findUserById(user_id));
                        } catch (e) {}
                        return set;
                    }, []);

                    this.mergeItem(id, { [usersSetTitle[1]]: usersSet });
                }
            });
        }

        return updatedTask;
    }
}

export default new TaskStore();
