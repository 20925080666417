import { ContactLinkWithPhonesType, ContactLinkType } from './contacts.types';
import { UserLinkType } from './users.types';
import { CommentType } from './comments.types';

export const REQUEST_TYPE_SELL = 1;
export const REQUEST_TYPE_RENT = 2;
export const REQUEST_TYPE_BUY = 3;
export const REQUEST_TYPE_HIRE = 4;
export const REQUEST_TYPE_NEWBUILDING = 6;
export const REQUEST_TYPE_MORTGAGE = 7;
export const REQUEST_TYPE_HUNTING = 5;

export const REQUEST_LIST_HUNTING = 'hh';

export type RequestLinkType = {
    readonly request_id: number;
    type: number;
    propertyType: number;
    enable: boolean;
};

export type RequestInBaseType = RequestLinkType & {
    contact_id: number | null;
    requestJson?: string;
    status: number | null;
    createTime: number;
    updateTime: number;
};

type RequestMainFieldsType = RequestInBaseType & {
    major_user_ids: number[];
    major_users: UserLinkType[];
    tags_ids: number[];
};

export type RequestTableType = RequestMainFieldsType & {
    contact?: ContactLinkType;
    comments?: CommentType[];
};

export type RequestType = RequestMainFieldsType & {
    requestObj: any;
    contact?: ContactLinkWithPhonesType;
};

export type RequestFilter = {
    enable: boolean;
    status: number[];
    group_id: number[];
    major_user_id: number[];
    type: number;
    tags_ids?: number[];
    tagsTogether?: boolean;

    startTime: number | null;
    endTime: number | null;
    deltaTime: number | null;
};
