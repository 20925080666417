import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';
import LoaderAwait from '~/components/Base/LoaderAwait';

import Table, { TableBody, SortDirection } from '~ui/Table';
import ListError from '~/components/ListError';

import TableKpiHead from './TableKpiHead';
import TableKpiBody from './TableKpiBody';
import TableKpiFooterSum from './TableKpiFooterSum';
import SkeletonTable from '~ui/SkeletonTable';

const POINT_KPI = 0;

const TableKpi = ({ kpi_id, showStatFooter }: { kpi_id: number; showStatFooter?: boolean }) => {
    const [sortPoint, setSortPoint] = useState(POINT_KPI);

    useEffect(() => {
        setSortPoint(POINT_KPI);
        kpiStore.fetchItem(kpi_id);
    }, [kpi_id]);

    const [sortDirection, setSortDirection] = useState<SortDirection>(false);
    const setSort = (point_id: number) => {
        setSortPoint(point_id);
        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    };

    const { item, loadingItem, property } = kpiStore.getItem(kpi_id);

    if (loadingItem || !item) {
        return <LoaderAwait active dimmer />;
    }

    const { points, redValue, greenValue } = item;
    const { statistics, statisticsErrors, statisticsLoading } = property;

    return (
        <>
            <ListError errors={statisticsErrors} />
            <Table maxHeight="calc(100vh - 150px)" compact>
                <TableKpiHead kpi_id={kpi_id} sortDirection={sortDirection} sortPoint={sortPoint} handleSortPoint={setSort} />
                <TableBody>
                    {statisticsLoading && <SkeletonTable rows={10} columns={points.length + 3} />}
                    {statistics && (
                        <TableKpiBody
                            statistics={statistics}
                            sortPoint={sortPoint}
                            sortDirection={sortDirection}
                            points={points}
                            redValue={redValue}
                            greenValue={greenValue}
                        />
                    )}
                </TableBody>
                {showStatFooter && statistics && <TableKpiFooterSum statistics={statistics} />}
            </Table>
        </>
    );
};

export default observer(TableKpi);
