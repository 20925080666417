import React, { ChangeEvent, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Container, Grid } from 'semantic-ui-react';
import './home.less';
import authStore from '~/stores/authStore';
import calendarStore from '~/stores/calendarStore';

import HomeCalendar from './HomeCalendar';
import HomeStatistics from './HomeStatistics';
import statisticStore from '~/stores/statisticStore';
import UserEstateMap from './UserEstateMap';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MapIcon from '@material-ui/icons/Map';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import commonStore from '~/stores/commonStore';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
    }
});

const TAB_CALENDAR = 'calendar';
const TAB_STAT = 'stat';
const TAB_MAP = 'map';

const HomeMobile = () => {
    const classes = useStyles();

    const [tabId, setTabId] = useState(TAB_CALENDAR);
    const handleTabChange = (event: ChangeEvent, newValue: string) => {
        setTabId(newValue);
    };

    return (
        <div className="crm-Item__bottomNav">
            {tabId === TAB_CALENDAR && <HomeCalendar />}
            {tabId === TAB_STAT && (
                <div className="crm-Home__statTab">
                    <HomeStatistics />
                </div>
            )}
            {tabId === TAB_MAP && (
                <div className="crm-Flex__center">
                    <UserEstateMap user_id={authStore.currentUser.user_id} />
                </div>
            )}

            <BottomNavigation value={tabId} onChange={handleTabChange} className={classes.root}>
                <BottomNavigationAction label="Календарь" value={TAB_CALENDAR} icon={<CalendarTodayIcon />} />
                <BottomNavigationAction label="Статистика" value={TAB_STAT} icon={<EqualizerIcon />} />
                <BottomNavigationAction label="Карта" value={TAB_MAP} icon={<MapIcon />} />
            </BottomNavigation>
        </div>
    );
};

const Home = () => {
    const { isMobile } = commonStore;
    const isStatAvailable = authStore.canRead(statisticStore.moduleName, authStore.currentUser.user_id);

    if (isMobile) {
        return <HomeMobile />;
    }

    return (
        <Grid padded="horizontally" stackable relaxed>
            <Grid.Row>
                <Grid.Column computer={isStatAvailable ? 12 : 16} tablet={16} mobile={16} style={{ paddingRight: 0 }}>
                    <Container className="crm-Home">
                        {authStore.canRead(calendarStore.moduleName, authStore.currentUser.user_id) && <HomeCalendar />}
                    </Container>
                </Grid.Column>
                {isStatAvailable && (
                    <Grid.Column computer={4} tablet={16} mobile={16}>
                        <Container className="crm-Home">
                            <HomeStatistics />
                        </Container>
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

export default Home;
