import fetchApi, { grabMessageFromError } from '../common/fetchApi';

import { NEW_PASSWORD_TYPE } from '~/stores/authStore';
import { objectToGraphql } from '../common/graphql';

export const logIn = async (email: string, password: string): Promise<{ token: string }> => {
    try {
        const answer: { token: string } = await fetchApi.post('/auth/login', { email, password });
        return answer;
    } catch (e) {
        throw grabMessageFromError(e);
    }
};

export const resetPassword = async (email: string): Promise<{ answer: string }> => {
    try {
        const answer: { answer: string } = await fetchApi.post('/auth/resetPassword', { email });
        return answer;
    } catch (e) {
        throw grabMessageFromError(e);
    }
};

export const changePassword = async (password: NEW_PASSWORD_TYPE): Promise<boolean> => {
    const graphql = `mutation { 
      updatePassword(inputPassword: ${objectToGraphql(password)})
    }`;

    const data: { updatePassword: boolean } = await fetchApi.postGQ(graphql);
    return data.updatePassword;
};
