import React, { useState, ReactNode } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type InfoPopupProps = {
    children?: ReactNode;
    content?: string | ReactNode;
    size?: 'small' | 'large' | 'default' | 'tiny';
};

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '400px'
    },
    paperHuge: {
        padding: theme.spacing(2),
        maxWidth: '600px'
    }
}));

const InfoPopup = ({ children, content, size = 'default' }: InfoPopupProps) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <span
                className="crm-Main__hiddenMobile"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ cursor: 'pointer' }}
            >
                <HelpOutlineIcon fontSize={size === 'tiny' ? 'small' : size} color={size === 'large' ? 'secondary' : 'primary'} />
            </span>
            <Popover
                className={classes.popover}
                classes={{
                    paper: size === 'large' ? classes.paperHuge : classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Typography>
                    {children}
                    {!children && content && <>{content}</>}
                </Typography>
            </Popover>
        </>
    );
};

export default React.memo(InfoPopup);
