import React from 'react';
import { Grid, Loader, Dimmer, Icon, Header } from 'semantic-ui-react';

import Comments from '../Common/Comments/index';
import ShowingReportDetailsShow from './ShowBlocks/ShowingReportDetailsShow';
import AddedItemsShow from './ShowBlocks/AddedItemsShow';

import LabelArchive from '../Common/LabelArchive';

const ShowingReportShowMode = ({ loading, showing_id, enable }: { loading: boolean; showing_id: number; enable: boolean }) => {
    return (
        <Grid stackable>
            <Grid.Column width={8}>
                <Header>
                    <Icon name="compass" />
                    Показ №{showing_id}
                </Header>

                {!loading && !enable && <LabelArchive />}

                <ShowingReportDetailsShow item_id={showing_id} />

                {!loading && <Comments item_id={showing_id} item_type="SHOWING" />}

                {loading && (
                    <Dimmer active inverted>
                        <Loader active size="large" />
                    </Dimmer>
                )}
            </Grid.Column>

            <Grid.Column width={8}>
                <AddedItemsShow item_id={showing_id} />
            </Grid.Column>
        </Grid>
    );
};

export default ShowingReportShowMode;
