import React, { useMemo, ReactNode, ComponentType } from 'react';
import { observer } from 'mobx-react';

import Button from '~ui/Button';
import settingStore from '~/stores/settingStore';
import { BaseApiEditingInnerProps } from '~/components/Ads/BaseApiSettings/BaseApiEditingWrapper';

const CianApiSettings = <T extends object>({
    apiKey,
    children,
    FieldComponent,
    defaultSettingsValue
}: {
    apiKey: string;
    children: ReactNode;
    FieldComponent: ComponentType<BaseApiEditingInnerProps>;
    defaultSettingsValue: T;
}) => {
    useMemo(() => {
        settingStore.fetchItem(apiKey, null, true);
    }, []);

    const { editingItem, loadingItem } = settingStore.getItem(apiKey);

    let countFields = 0;

    if (editingItem?.value) {
        const settings: T[] = JSON.parse(editingItem?.value);
        countFields = settings.length;
    }

    const handleAddField = () => {
        let settings: T[] = JSON.parse(editingItem?.value);
        settingStore.setEditingItem(apiKey, { value: JSON.stringify([...settings, defaultSettingsValue]) });
    };

    return (
        <>
            {children}

            {Array.from(Array(countFields).keys()).map(index => (
                <FieldComponent apiIndex={index} key={index} />
            ))}

            <Button primary onClick={handleAddField} disabled={loadingItem}>
                Добавить Ключ
            </Button>
        </>
    );
};

export default observer(CianApiSettings);
