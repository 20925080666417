import React, { Fragment } from 'react';
import estateL10n from '../../../../L10n/estate.L10n';
import MonoString from '../../../Base/MonoString';

export default ({ roomsCount, short }: { roomsCount?: Array<number> | null; short?: boolean }) => (
    <MonoString
        template={
            roomsCount ? roomsCount.map(rooms => estateL10n[short ? 'ROOMS_COUNT_FIND' : 'ROOMS_COUNT_LONG_FIND'](rooms)).join(', ') : ''
        }
    />
);
