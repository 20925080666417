import React, { useState, useEffect, ReactNode } from 'react';
import Rating from '@material-ui/lab/Rating';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';

type DynamicActiveButtonProps = {
    positiveInfo: string;
    negativeInfo: string;
    size?: 'small' | 'large' | 'medium';
    enable: boolean;
    titleOnEnable: string;
    titleOnDisable: string;
    onChange: (value: boolean) => Promise<void>;
    icon?: ReactNode;
};

export const DynamicActiveButton = ({
    positiveInfo,
    negativeInfo,
    size,
    enable,
    titleOnDisable,
    titleOnEnable,
    onChange,
    icon
}: DynamicActiveButtonProps) => {
    const [showInfo, setShowInfo] = useState(false);
    const handleCloseInfo = () => {
        setShowInfo(false);
    };

    const [error, setError] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowInfo(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [enable]);

    const handleChange = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            await onChange(!enable);
        } catch (errors) {
            setError(errors[0]);
        }

        setShowInfo(true);
    };

    return (
        <>
            <Tooltip title={enable ? titleOnEnable : titleOnDisable}>
                <Rating size={size || 'small'} icon={icon} name={positiveInfo} max={1} value={enable ? 1 : null} onClick={handleChange} />
            </Tooltip>

            {showInfo && (
                <Portal>
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        onClose={handleCloseInfo}
                        message={error ? `Ошибка: ${error}` : enable ? positiveInfo : negativeInfo}
                    />
                </Portal>
            )}
        </>
    );
};
