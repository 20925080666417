import React, { PureComponent, SyntheticEvent, ComponentType } from 'react';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import { format } from 'date-fns';
import GlobalError from '~/components/Base/GlobalError';
import * as crmChangesApi from '~/api/crmChangesApi';

export type HandleChangeType = (
    event: SyntheticEvent,
    data: { type?: string; name?: string; value?: any; array?: string; index?: number }
) => void;

export type HandlerEditingProps = {
    item_id: number;
    editing?: boolean;
};

type HandlerEditingWrappedInnerProps = {
    handleChange: HandleChangeType;
    handleCheckbox: (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => void;
    handleTextAreaChange: (data: { name: string; value: string }) => void;
    handleDate: (name: string, timeSec: number) => void;
    setUnixTime: (name: string, time: number) => void;
    handlePrice: (
        event: SyntheticEvent,
        { name, value, array, index }: { name: string; value: string; array?: string; index?: number }
    ) => void;
};

export type HandlerEditingWrappedProps = HandlerEditingProps & HandlerEditingWrappedInnerProps;

const HandlerEditingBlockWrapper = <TProps extends HandlerEditingProps & { [otherProperty: string]: any }>(
    store: ItemStoreInterface<object>,
    WrappedComponent: ComponentType<HandlerEditingWrappedInnerProps & HandlerEditingProps & { [otherProperty: string]: any }>
) => {
    return class extends PureComponent<TProps, { isError: string | null }> {
        state = { isError: null };

        handleChange: HandleChangeType = (event, { type, name, value, array, index }) => {
            event.preventDefault();
            event.stopPropagation();

            const { item_id } = this.props;

            if (type === 'clearable' && value === '') {
                store.setEditingItem(item_id, {
                    [name]: null
                });
            } else if (array && typeof index === 'number') {
                // @ts-ignore
                store.changeArrayValue(item_id, array, index, name, type === 'number' ? Number(value) : value);
            } else {
                store.setEditingItem(item_id, {
                    [name]: type === 'number' ? Number(value) : value
                });
            }
        };

        handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
            event.preventDefault();
            event.stopPropagation();

            store.setEditingItem(this.props.item_id, {
                [name]: checked
            });
        };

        handleTextAreaChange = ({ name, value }: { name: string; value: string }) => {
            store.setEditingItem(this.props.item_id, {
                [name]: value
            });
        };

        handleDate = (name: string, timeSec: number) => {
            store.setEditingItem(this.props.item_id, {
                [name]: format(new Date(timeSec * 1000), 'yyyy-MM-dd')
            });
        };

        setUnixTime = (name: string, time: number) => {
            store.setEditingItem(this.props.item_id, {
                [name]: time || null
            });
        };

        handlePrice = (
            event: SyntheticEvent,
            { name, value, array, index }: { name: string; value: string; array?: string; index?: number }
        ) => {
            if (array && typeof index === 'number') {
                store.changeArrayValue(
                    this.props.item_id,
                    // @ts-ignore
                    array,
                    index,
                    name,
                    typeof value === 'string' ? Number(value.replace(/\D/g, '')) : Number(value)
                );
            } else {
                store.setEditingItem(this.props.item_id, {
                    [name]: typeof value === 'string' ? Number(value.replace(/\D/g, '')) : Number(value)
                });
            }
        };

        componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
            console.log('componentDidCatch', error);
            console.log('errorInfo', errorInfo);
            crmChangesApi.sendUiError(error, errorInfo, 'HandlerEditingBlockWrapper');
            this.setState({ isError: error.message });
        }

        render() {
            if (this.state.isError) {
                return <GlobalError error={this.state.isError} />;
            }

            return (
                <WrappedComponent
                    handleChange={this.handleChange}
                    handleTextAreaChange={this.handleTextAreaChange}
                    handleDate={this.handleDate}
                    handleCheckbox={this.handleCheckbox}
                    setUnixTime={this.setUnixTime}
                    handlePrice={this.handlePrice}
                    {...this.props}
                />
            );
        }
    };
};

export default HandlerEditingBlockWrapper;
