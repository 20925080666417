import React, { Component, Fragment, useState } from 'react';
import { ReactNode } from 'react';
import { List, Popup, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import Table, { TableBody, TableCell, TableHead, TableRow } from '~ui/Table';

import Time from '~/components/Base/Time';
import UserLinkWithPreview from '../Lists/Users/UserLinkWithPreview';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import ownerStore from '~/stores/ownerStore';
import { ITEM_TYPES } from '~/types/notifications.types';
import SkeletonTable from '~ui/SkeletonTable';
import Modal, { ModalContent } from '~ui/Modal';
import commonStore from '~/stores/commonStore';

type ItemHistoryModeWrapperProps = {
    item_id: number;
    base?: string;
};

const adjustPrintVal = (value: string | ReactNode): string | ReactNode => {
    if (typeof value === 'string') {
        if (value.length > 100) {
            return `${value.slice(0, 100)}...`;
        }
    }

    return value;
};

const HistoryItem = ({ item, keys, printVal }: { item: any; keys: any; printVal: (string, unknown) => string | ReactNode }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const itemKeys = Object.keys(item);

    if (itemKeys && itemKeys.length > 0) {
        const ItemTrigger = (
            <span
                style={{ cursor: 'pointer' }}
                title="Нажмите, чтобы посмотреть остальные изменения"
                className={itemKeys.length > 1 ? `crm-User__linkList_trigger` : ''}
            >
                {keys[itemKeys[0]] || itemKeys[0]}: {adjustPrintVal(printVal(itemKeys[0], item[itemKeys[0]]))}
                {itemKeys.length > 1 && (
                    <Fragment>
                        &nbsp;<b style={{ fontWeight: 500 }}>{`+${itemKeys.length - 1}`}</b>
                    </Fragment>
                )}
            </span>
        );

        const ListHistory = (
            <List>
                {itemKeys.map(key => (
                    <List.Item key={key}>
                        {keys[key] || key}: {printVal(key, item[key])}
                    </List.Item>
                ))}
            </List>
        );

        return (
            <Fragment>
                <Popup size="tiny" trigger={ItemTrigger} flowing on="click" wide="very" style={{ maxHeight: '50%', overflow: 'auto' }}>
                    {ListHistory}
                    <Button size="mini" onClick={() => setOpenModal(!openModal)}>
                        Открыть в окне
                    </Button>
                </Popup>
                {openModal && (
                    <Modal header="Изменения" fullScreen={commonStore.isMobile} onClose={() => setOpenModal(!openModal)}>
                        <ModalContent>{ListHistory}</ModalContent>
                    </Modal>
                )}
            </Fragment>
        );
    }

    return null;
};

const historyFactory = {} as Record<ITEM_TYPES, { keys: any; printVal: (string, unknown) => string | ReactNode }>;

const mainKeys = {
    enable: 'Архив',
    updateTime: 'Время обновления',
    createTime: 'Время создания',
    contact_id: '№ контакта',
    estate_id: '№ объекта',
    parseError: 'Ошибка парсинга данных'
};

const printValDefault = (key: string, value: any): string => {
    return String(value);
};

export const HistoryItemFactory = ({ item_type, changeJson }: { item_type: ITEM_TYPES; changeJson: string }) => {
    const { keys, printVal } = historyFactory[item_type] || { keys: {}, printVal: null };
    try {
        const item = JSON.parse(changeJson);
        return <HistoryItem item={item} keys={{ ...mainKeys, ...keys }} printVal={printVal || printValDefault} />;
    } catch (e) {
        console.log(changeJson);
        return <span>Ошибка JSON</span>;
    }
};

const ItemHistoryModeWrapper = (store: ItemStoreInterface<any>, keys: any, printVal: (string, unknown) => string | ReactNode) => {
    historyFactory[store.moduleName] = {
        keys,
        printVal
    };

    @observer
    class ItemAddModeWrapperClass extends Component<ItemHistoryModeWrapperProps> {
        constructor(props: ItemHistoryModeWrapperProps) {
            super(props);

            if (store === ownerStore) {
                ownerStore.fetchItem(props.item_id);
            }

            store.fetchItemHistory(props.item_id, props.base);
        }

        render() {
            const { item_id } = this.props;
            const { loadingHistory, history } = store.getItem(item_id).history;

            const firstLoading = !history || !history.length;

            return (
                <Table size="small" compact>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Сотрудник</TableCell>
                            <TableCell>Новые значения</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadingHistory && firstLoading && <SkeletonTable columns={3} rows={10} />}

                        {history.map(({ change_id, releaseTime, major_user, changeJson }) => {
                            let HistoryItemField;

                            try {
                                HistoryItemField = <HistoryItem item={JSON.parse(changeJson)} keys={keys} printVal={printVal} />;
                            } catch (error) {
                                console.info(changeJson);
                                console.error(error);
                                HistoryItemField = (
                                    <HistoryItem item={{ parseError: 'да', history: changeJson }} keys={keys} printVal={printVal} />
                                );
                            }

                            return (
                                <TableRow key={change_id}>
                                    <TableCell>
                                        <Time time={releaseTime} />
                                    </TableCell>
                                    <TableCell>{major_user && <UserLinkWithPreview user={major_user} />}</TableCell>
                                    <TableCell>{HistoryItemField}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            );
        }
    }

    return ItemAddModeWrapperClass;
};

export default ItemHistoryModeWrapper;
