import { proxyL10nHandler } from '../common/localizations';
import {
    REQUEST_TYPE_SELL,
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_HUNTING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_MORTGAGE
} from '~/types/requests.types';

const REQUEST_TYPE = [
    [REQUEST_TYPE_SELL, 'Продать'],
    [REQUEST_TYPE_RENT, 'Сдать'],
    [REQUEST_TYPE_BUY, 'Купить'],
    [REQUEST_TYPE_HIRE, 'Снять'],
    [REQUEST_TYPE_NEWBUILDING, 'Новостройка'],
    [REQUEST_TYPE_MORTGAGE, 'Ипотека'],
    [REQUEST_TYPE_HUNTING, 'Рекрутинг']
];

const REQUEST_STATUS = [
    [1, 'Не отработано'],
    [2, 'В работе'],
    [3, 'Повторно связаться'],
    [4, 'Отложено на месяц'],
    [5, 'Отложено на год'],
    [6, 'Выполнено'],
    [7, 'Отмена, архив']
];

const requestL10n = new Proxy({}, proxyL10nHandler);

requestL10n.REQUEST_TYPE = REQUEST_TYPE;
requestL10n.REQUEST_STATUS = REQUEST_STATUS;

export default requestL10n;
