const wait = async (ms: number) => {
    const promiseMs = new Promise(resolve => {
        window.setTimeout(() => {
            resolve(null);
        }, ms);
    });
    return await promiseMs;
};

export default wait;
