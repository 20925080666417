import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

import Paper from '@material-ui/core/Paper';

import Grid from '~ui/Grid';
import { useToggle } from '~ui/Modal';

import MajorUserBlockEditing from '~/components/Items/Common/MajorUserBlockEditing';

type LifeComplexMajorUsersEditingProps = HandlerEditingWrappedProps & {
    major_user_ids?: number[];
};

const LifeComplexMajorUsersEditing = (props: LifeComplexMajorUsersEditingProps) => {
    const { item_id } = props;
    const [showModal, toggleModal] = useToggle();

    useMemo(() => {
        const item = {
            major_user_ids: props.major_user_ids || []
        } as const;
        lifeComplexStore.setEditingItem(item_id, item);
    }, [item_id]);

    const { major_user_ids } = lifeComplexStore.getItem(item_id).editingItem;

    return (
        <Paper style={{ padding: '1rem 1rem 0' }}>
            <Grid>
                <Grid.Column>
                    <MajorUserBlockEditing
                        fluid
                        enableAllGroups
                        store={lifeComplexStore}
                        major_user_id={major_user_ids}
                        item_id={item_id}
                    />
                </Grid.Column>
            </Grid>
        </Paper>
    );
};

export default HandlerEditingBlockWrapper(lifeComplexStore, observer(LifeComplexMajorUsersEditing));
