import { objectToGraphql } from '~/common/graphql';
import fetchApi from '../../common/fetchApi';
import { MortgageJob } from '~/types/mortgageRequest.types';

const GQMortgageJob = `
    mortgage_job_id
    mortgage_person_id

    title
    tax_number
    phones
    position
    customer_type
    actual_address
    experience_cid
    monthly_salary
    proof_of_income_salary
    date_of_employment
    employees_count
    business_share

    createTime
    updateTime
    enable
`;

export const fetchItem = async (mortgage_job_id: number): Promise<MortgageJob> => {
    const graphql = `{
        fetchMortgageJob(mortgage_job_id: ${mortgage_job_id}) {
            ${GQMortgageJob}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchMortgageJob: MortgageJob }>(graphql);
    return data.fetchMortgageJob;
};

export const createItem = async ({ mortgage_person_id }: { mortgage_person_id: number }): Promise<number> => {
    const graphql = `mutation { 
          createMortgageJob(mortgage_person_id: ${mortgage_person_id})
        }`;

    const data = await fetchApi.postGQ<{ createMortgageJob: number }>(graphql);
    return data.createMortgageJob;
};

export const saveItem = async (mortgage_job_id: number, mortgageJobInput: MortgageJob): Promise<number> => {
    const graphql = `mutation { 
        updateMortgageJob(mortgage_job_id: ${mortgage_job_id}, mortgageJobInput: ${objectToGraphql(mortgageJobInput)})
    }`;

    const data = await fetchApi.postGQ<{ updateMortgageJob: number }>(graphql);
    return data.updateMortgageJob;
};
