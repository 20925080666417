import { observable, action, reaction, runInAction, computed } from 'mobx';
import * as dealApi from '~/api/dealApi';

import ListStorePrototype from './prototypes/ListStore.prototype';
import { DropdownType } from './prototypes/ListStore.prototype';
import {
    Deal,
    DEAL_TYPE_ACCOMPANIMENT,
    DEAL_TYPE_ACCOMPANIMENT_ID,
    DEAL_TYPE_MORTGAGE,
    DEAL_TYPE_MORTGAGE_ID,
    DEAL_TYPE_NEWBUILDING,
    DEAL_TYPE_NEWBUILDING_ID,
    DealFilter,
    DealTableBodyType
} from '~/types/deals.types';
import { Contact, ContactLinkType } from '~/types/contacts.types';
import contactStore from './contactStore';
import authStore from './authStore';
import { CalendarEventType } from '~/types/calendar.types';
import { ESTATE_TYPE_RENT, ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { DEAL_TYPE_EXCLUSIVE, DEAL_TYPE_EXCLUSIVE_ID } from '~/types/deals.types';
import { UserLinkType } from '~/types/users.types';
import { HistoryChange } from '~/types/historyChanges.types';
import { SearchModuleResult } from './globalSearchStore';
import globalSearchStore from './globalSearchStore';
import { ACCESS_COMPANY, ACCESS_WRITE } from '~/types/access.types';
import { DealLinkState } from '~/components/Lists/Deals/DealLink';
import { estateAbout } from '~/components/Lists/Estate/EstateLink';
import { isEqualWithoutFields } from '~/common/isEqual';

export const DEAL_SELLER = 'seller';
export const DEAL_BUYER = 'buyer';
type DealParticipant = 'seller' | 'buyer';

export const DEAL_PARTICIOANT_ADD = 'add';
export const DEAL_PARTICIOANT_REMOVE = 'remove';
type DealParticipantOperation = 'add' | 'remove';

type DealPropertyType = {
    loadingHistory: boolean;
    history: HistoryChange[];
};

class DealStore extends ListStorePrototype<Deal, DealTableBodyType, DealPropertyType, DealFilter> {
    listFilterClear = {
        enable: true,
        group_id: [],
        major_user_id: [],
        search: '',
        status: null,
        isExclusive: null,
        deltaCreateTime: 0,
        type: 0
    };

    orderBy = 'deal_id';

    globalSearchDidntRegistered = true;

    constructor() {
        super('deal_id', 'deal', dealApi);
        this.clearFilter();

        reaction(
            () => authStore.currentUser,
            () => {
                if (authStore.getPermission(this.moduleName, ACCESS_WRITE) === ACCESS_COMPANY && this.globalSearchDidntRegistered) {
                    globalSearchStore.regModule({
                        title: 'Договоры',
                        moduleName: this.moduleName,
                        search: this.globalSearchList
                    });
                    this.globalSearchDidntRegistered = false;
                }
            }
        );
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        const { list, count } = await dealApi.fetchList(
            limit,
            start,
            'updateTime',
            'descending',
            {
                search: query
            },
            abortController
        );

        return {
            list: list.map(({ deal_id, type, estate, estateOuterUrl }) => ({
                key: deal_id,
                title: `Договор №${deal_id}`.trim(),
                description: estate ? `${estateAbout(estate.propertyType, estate.roomsCount)} ${estate.address}` : estateOuterUrl,
                state: DealLinkState(
                    deal_id,
                    type === DEAL_TYPE_EXCLUSIVE_ID
                        ? DEAL_TYPE_EXCLUSIVE
                        : type === ESTATE_TYPE_RENT_ID
                        ? ESTATE_TYPE_RENT
                        : ESTATE_TYPE_SELL
                )
            })),
            count
        };
    };

    @action
    clearFilter() {
        const type = this.listFilter && this.listFilter['type'];
        super.clearFilter();
        if (type) {
            this.listFilter['type'] = type;
        }
    }

    @action
    async changeParticipant(type: DealParticipant, operation: DealParticipantOperation, item_id: number, contact_id: number) {
        const editingItem = this.getItem(item_id).editingItem;

        let idsList: Array<number> = [];
        let participantsList: Array<ContactLinkType> = [];

        switch (type) {
            case DEAL_SELLER:
                idsList = editingItem.sellers_ids;
                participantsList = editingItem.sellers;
                break;
            case DEAL_BUYER:
                idsList = editingItem.buyers_ids;
                participantsList = editingItem.buyers;
                break;
            default:
                break;
        }

        switch (operation) {
            case DEAL_PARTICIOANT_ADD:
                if (idsList.indexOf(contact_id) === -1) {
                    let contact: Partial<Contact> | null | void;

                    try {
                        contact = contactStore.findFromList(contact_id);
                    } catch (e) {
                        await contactStore.fetchItem(contact_id);
                        contact = contactStore.getItem(contact_id).item;
                    }

                    if (contact) {
                        runInAction(() => {
                            idsList.push(contact_id);
                            // @ts-ignore
                            participantsList.push(contact);
                        });
                    }
                }
                break;

            case DEAL_PARTICIOANT_REMOVE:
                const index = idsList.indexOf(contact_id);
                if (index > -1) {
                    idsList.splice(index, 1);
                    participantsList.splice(index, 1);
                }
                break;

            default:
                break;
        }
    }

    @action
    addSeller(deal_id: number, contact_id: number) {
        this.changeParticipant(DEAL_SELLER, DEAL_PARTICIOANT_ADD, deal_id, contact_id);
    }

    @action
    addBuyer(deal_id: number, contact_id: number) {
        this.changeParticipant(DEAL_BUYER, DEAL_PARTICIOANT_ADD, deal_id, contact_id);
    }

    @action
    addUserFee(deal_id: number, majorUser?: UserLinkType) {
        const { usersFees } = this.getItem(deal_id).editingItem;
        usersFees.push({
            deal_id,
            user_id: majorUser ? majorUser.user_id : authStore.currentUser.user_id,
            fee: 0,
            currency: 1,
            user: majorUser || authStore.currentUser
        });
    }

    @action
    removeUserFee(deal_id: number, index: number) {
        const { usersFees } = this.getItem(deal_id).editingItem;
        usersFees.splice(index, 1);
    }

    @observable
    loadingBanks = false;
    banks: DropdownType[] = [];

    async fetchBanks() {
        if (!this.banks.length) {
            this.loadingBanks = true;

            const banks = await dealApi.fetchBanks();
            this.banks = banks.map(({ bank_id, title }) => ({ text: title, value: bank_id, key: bank_id }));

            this.loadingBanks = false;
        }
    }

    dropdownList: DealTableBodyType[] = [];

    @action
    async fetchItemDropdownOptions(search: string) {
        this.loadingDropdownOptions = true;
        this.dropdownList = (
            await dealApi.fetchList(20, 0, 'updateTime', 'descending', {
                ...this.listFilterClear,
                search
            })
        ).list;

        this.itemDropdownOptions = this.dropdownList.map(({ deal_id, estateType, estate }) => ({
            key: deal_id,
            value: deal_id,
            text: `№${deal_id} :: ${estate ? estate.address : ''}`
        }));

        this.loadingDropdownOptions = false;
    }

    @action
    setRouteFilter(param: string) {
        switch (param) {
            case ESTATE_TYPE_RENT:
                this.listFilter['type'] = ESTATE_TYPE_RENT_ID;
                break;
            case ESTATE_TYPE_SELL:
                this.listFilter['type'] = ESTATE_TYPE_SELL_ID;
                break;
            case DEAL_TYPE_EXCLUSIVE:
                this.listFilter['type'] = DEAL_TYPE_EXCLUSIVE_ID;
                break;
            case DEAL_TYPE_ACCOMPANIMENT:
                this.listFilter['type'] = DEAL_TYPE_ACCOMPANIMENT_ID;
                break;
            case DEAL_TYPE_NEWBUILDING:
                this.listFilter['type'] = DEAL_TYPE_NEWBUILDING_ID;
                break;
            case DEAL_TYPE_MORTGAGE:
                this.listFilter['type'] = DEAL_TYPE_MORTGAGE_ID;
                break;
            default:
                break;
        }
    }

    @computed
    get listToCalendar(): CalendarEventType[] {
        return this.list.reduce((events, deal) => {
            if (deal.releaseTime) {
                events.push({
                    item_id: deal.deal_id,
                    item_type: this.moduleName,
                    start: new Date(deal.releaseTime * 1000),
                    end: new Date(deal.releaseTime * 1000 + 3600000),
                    title: `Сделка №${deal.deal_id}`,
                    allDay: false
                });
            }

            return events;
        }, []);
    }

    @action
    async fetchListCalendar(releaseTimeMin: number, releaseTimeMax: number) {
        this.listFilter = { ...this.listFilter, releaseTimeMin, releaseTimeMax };

        this.fetchList();
    }

    @computed get filterIsEmpty(): boolean {
        return isEqualWithoutFields(this.listFilter, this.listFilterClear, ['type']);
    }

    @action
    async saveItem(id: number): Promise<boolean> {
        let needToReset = false;
        if (this.getItem(id).editingItem['parent_deal_id']) {
            needToReset = true;
        }
        await super.saveItem(id);
        if (needToReset) {
            this.fetchItem(id, null, true);
        }
        return true;
    }

    validationItem(deal: Deal): string[] {
        const errors = [];

        if (!deal.buyers_ids.length && !deal.sellers_ids.length) {
            errors.push('Задайте покупателя или продавца');
        }

        return errors;
    }
}

export default new DealStore();
