import React, { Component, Fragment } from 'react';

import ownerStore from '~/stores/ownerStore';
import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import { ReactNode } from 'react';

const ownersKeys = {
    assignOwner: 'Получил доступ',
    isAlreadySold: 'Уже продано',
    enable: 'Архив',
    isRealtor: 'Это Риелтор',
    soldPrice: 'Цена продажи'
};

const printOwnerVal = (key: string, value: unknown): string | ReactNode => {
    switch (key) {
        case 'enable':
            return value ? 'нет' : 'да';
        default:
            break;
    }

    if (value instanceof Array) {
        return value.join(', ');
    } else if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(ownerStore, ownersKeys, printOwnerVal);
