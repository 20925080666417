import React from 'react';
import { Link } from 'react-router-dom';
import { RequestLinkType } from '~/types/requests.types';
import { printRequestType } from '../../Items/Request/EditBlocks/RequestTypeEditing';
import { REQUEST_LIST_HUNTING, REQUEST_TYPE_HUNTING } from '~/types/requests.types';

type RequestLinkProps = {
    request: RequestLinkType;
};

export const RequestLinkState = (request_id: number, param: string) => ({
    pathname: `/requests/${param}/overview/${request_id}`,
    state: { modal: true, switchFromTab: false }
});

const RequestLink = ({ request: { request_id, type, propertyType, enable } }: RequestLinkProps) => {
    return (
        <span className={enable ? '' : 'crm-Item__disabled'}>
            <Link to={RequestLinkState(request_id, type === REQUEST_TYPE_HUNTING ? REQUEST_LIST_HUNTING : 'list')}>
                Заявка №{request_id} на {printRequestType(type, propertyType)}
            </Link>
        </span>
    );
};

export default RequestLink;
