import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import contactStore from '~/stores/contactStore';
import mortgageRequestStore from '~/stores/mortgageRequest';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';

import CalculationMortgageEditing from './Mortgage/CalculationMortgageEditing';
import MortgagePersonsList from './Mortgage/MortgagePersonsList';

import Steps, { StepType } from '~ui/Steps';
import { WarningMessage } from '~ui/Message';
import Button from '~ui/Button';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ListError from '~/components/ListError';
import LoaderAwait from '~/components/Base/LoaderAwait';
import MortgageDemoCalculator from '~/components/Items/Contact/MortgageDemoCalculator';

const mortgageSteps: StepType[] = [
    { id: 0, title: 'Параметры ипотеки', subtitle: 'Город покупки, стоимость', icon: <AccountBalanceIcon /> },
    { id: 1, title: 'Заемчщики, Созаемщики', subtitle: 'ФИО, Паспорт, Проживание, Занятость', icon: <PeopleIcon /> },
    { id: 2, title: 'Отправка заявки', subtitle: 'Отпрка в банки', icon: <ListIcon /> }
];

const MortgageRequestCreate = observer(({ contact_id }: { contact_id: number }) => {
    useMemo(() => {
        mortgageRequestStore.fetchItem(CREATING_ITEM_ID);
    }, []);

    const handleCreate = async () => {
        await mortgageRequestStore.createEmptyItem({ contact_id });
        contactStore.fetchItem(contact_id, null, true);
    };

    const { loadingItem, errors } = mortgageRequestStore.getItem(CREATING_ITEM_ID);

    return (
        <>
            <ListError errors={errors} />
            <WarningMessage header="Заявка в Банк(и) на получение ипотеки">
                Заявка еще не создавалась.
                <Button primary variant="outlined" size="small" onClick={handleCreate} loading={loadingItem}>
                    Создать
                </Button>
            </WarningMessage>

            <MortgageDemoCalculator />
        </>
    );
});

const ContactMortgageMode = ({ contact_id }: { contact_id: number }) => {
    useEffect(() => {
        contactStore.fetchItem(contact_id);
    }, [contact_id]);
    const [step, setStep] = useState(0);

    const { item, loadingItem } = contactStore.getItem(contact_id);
    if (!item || loadingItem) {
        return <LoaderAwait active />;
    }

    const { mortgage_request_id } = item;

    if (!mortgage_request_id) {
        return <MortgageRequestCreate contact_id={contact_id} />;
    }

    return (
        <>
            <Steps
                steps={mortgageSteps}
                activeStep={step}
                completedSteps={[false, false, false]}
                setActiveStep={setStep}
                collapsed={commonStore.isMobile}
            />
            {step === 0 && <CalculationMortgageEditing item_id={mortgage_request_id} />}
            {step === 1 && <MortgagePersonsList mortgage_request_id={mortgage_request_id} />}
            {/*{step === 2 && <JobMortgageEditing item_id={mortgage_request_id} />}*/}
        </>
    );
};

export default observer(ContactMortgageMode);
