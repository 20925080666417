import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Dropdown, Grid, List, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import { Deal, UserFee } from '~/types/deals.types';
import dealStore from '~/stores/dealStore';
import estateL10n from '../../../../L10n/estate.L10n';
import UserDropdown from '../../../ItemsDropdowns/UserDropdown';
import authStore from '~/stores/authStore';

type DealPaymentsEditingProps = HandlerEditingWrappedProps & {
    fee?: number;
    feeCurrency?: number;
    usersFees?: Array<UserFee>;
};

@observer
class DealPaymentsEditing extends Component<DealPaymentsEditingProps> {
    constructor(props: DealPaymentsEditingProps) {
        super(props);

        const deal = {
            fee: props.fee || 0,
            feeCurrency: props.feeCurrency || 1,
            usersFees: props.usersFees || []
        };
        dealStore.setEditingItem(props.item_id, deal);
    }

    handleAddUserFee = (event: React.SyntheticEvent) => {
        event.preventDefault();

        dealStore.addUserFee(this.props.item_id);
    };

    handleRemoveUserFee = (index: number) => {
        dealStore.removeUserFee(this.props.item_id, index);
    };

    changeUserId = (index: number, user_id: number) => {
        dealStore.changeArrayValue(this.props.item_id, 'usersFees', index, 'user_id', user_id);
        dealStore.changeArrayValue(this.props.item_id, 'usersFees', index, 'user', authStore.findUserById(user_id));
    };

    render() {
        const { item_id, editing, handlePrice, handleChange, handleCheckbox } = this.props;

        const { fee, feeCurrency, usersFees } = dealStore.getItem(item_id).editingItem;

        return (
            <Fragment>
                {editing && <br />}
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Общая комиссия (Агенты)</label>
                        <Input
                            size={'mini'}
                            label={
                                <Dropdown
                                    value={feeCurrency}
                                    name="feeCurrency"
                                    onChange={handleChange}
                                    options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                />
                            }
                            labelPosition="right"
                            placeholder="Общая комиссия"
                            value={fee ? fee.toLocaleString() : ''}
                            onChange={handlePrice}
                            name="fee"
                            className="crm-Estate__field_rightMargin"
                        />
                        <a href="#none" onClick={this.handleAddUserFee} className="crm-Estate__fieldNowrap">
                            + риелторская комиссия
                        </a>
                    </Form.Field>
                </Form.Group>
                <List>
                    {usersFees &&
                        usersFees.map(({ user_id, fee, currency }, index) => (
                            <List.Item key={index}>
                                <Grid verticalAlign="middle">
                                    <Grid.Column width={1}>
                                        <Icon
                                            link
                                            name="close"
                                            title="Убрать сотрудника"
                                            onClick={this.handleRemoveUserFee.bind(this, index)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={7} className={editing ? 'ui form mini' : ''}>
                                        <UserDropdown
                                            upward
                                            user_id={user_id}
                                            onChange={user_id => {
                                                if (typeof user_id === 'number') {
                                                    this.changeUserId(index, user_id);
                                                }
                                            }}
                                            disableClearable
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Input
                                            size="mini"
                                            label={
                                                <Dropdown
                                                    value={currency}
                                                    name="currency"
                                                    onChange={handleChange}
                                                    options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                                    array="usersFees"
                                                    index={index}
                                                />
                                            }
                                            labelPosition="right"
                                            placeholder="Риелторская комиссия"
                                            value={fee ? fee.toLocaleString() : ''}
                                            onChange={handlePrice}
                                            name="fee"
                                            className="crm-Estate__field_rightMargin"
                                            array="usersFees"
                                            index={index}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </List.Item>
                        ))}
                </List>
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealPaymentsEditing);
