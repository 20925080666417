import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import Grid from '~ui/Grid';
import Button from '~ui/Button';

import contactStore from '~/stores/contactStore';
import KanbanListDropdown from '~/components/Base/KanbanListDropdown';

const KanbanListBlockEditing = ({ contact_id, kanban_list_ids_default }: { contact_id: number; kanban_list_ids_default: number[] }) => {
    useMemo(() => {
        contactStore.setEditingItem(contact_id, { kanban_list_ids: [...kanban_list_ids_default] });
    }, []);

    const {
        editingItem: { kanban_list_ids }
    } = contactStore.getItem(contact_id);

    const handleAddBoard = () => {
        contactStore.setEditingItem(contact_id, { kanban_list_ids: [...kanban_list_ids, null] });
    };

    return (
        <Grid spacing={2}>
            {kanban_list_ids.map((kanban_list_id, index) => (
                <Grid.Column key={kanban_list_id}>
                    <KanbanListDropdown
                        kanban_list_id={kanban_list_id}
                        onChange={(event, { value }: { value: number }) => {
                            contactStore.changeArrayValue(contact_id, 'kanban_list_ids', index, null, value);
                        }}
                    />
                </Grid.Column>
            ))}

            <Grid.Column>
                <Button color="secondary" fullWidth onClick={handleAddBoard}>
                    + добавить доску
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(KanbanListBlockEditing);
