import React from 'react';
import { Link } from 'react-router-dom';
import { MeetingEventLinkType } from '~/types/events.types';
import Time from '../../Base/Time';

type MeetingLinkProps = {
    meetingEvent: MeetingEventLinkType;
};

export const MeetingEventLinkUrl = (meeting_event_id: number) => `/modal/events/meetingEvent/${meeting_event_id}`;

export const MeetingEventLinkState = (meeting_event_id: number) => ({
    pathname: MeetingEventLinkUrl(meeting_event_id),
    state: { modal: true }
});

const MeetingEventLink = ({ meetingEvent: { startTime, meeting_event_id } }: MeetingLinkProps) => {
    return (
        <Link to={MeetingEventLinkState(meeting_event_id)}>
            Встреча, <Time time={startTime * 1000} />
        </Link>
    );
};

export default MeetingEventLink;
