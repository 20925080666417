import React, { useMemo, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import PhoneIcon from '@material-ui/icons/Phone';
import StorageIcon from '@material-ui/icons/Storage';

import Select from '~ui/Select';
import Progress from '~ui/Progress';
import TextInput from '~ui/TextInput';
import sppAgencyStore from '~/stores/sppAgencyStore';
import Grid from '~ui/Grid';
import { checkFormValid } from '~/common/forms';
import { COLORS_DROPDOWN } from '~/components/Settings/Tags/TagModal';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const SppAgencyModal = ({ mls_company_id, onClose }: { mls_company_id: number; onClose: () => void }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [saving, setSaving] = useState(false);

    useMemo(() => {
        sppAgencyStore.fetchItem(mls_company_id);

        if (mls_company_id === CREATING_ITEM_ID) {
            sppAgencyStore.setEditingItem(mls_company_id, {});
        }
    }, []);

    const handleChange = useCallback((event, { name, value }: { name: string; value: unknown }) => {
        sppAgencyStore.setEditingItem(mls_company_id, {
            [name]: value
        });
    }, []);

    const commitChanges = () => {
        const { errors } = sppAgencyStore.getItem(mls_company_id);
        if (!errors.length) {
            sppAgencyStore.fetchList();
            onClose();
        }
    };

    const handleSave = async () => {
        if (checkFormValid(formRef.current, true)) {
            if (mls_company_id === CREATING_ITEM_ID) {
                await sppAgencyStore.createItem();
            } else {
                setSaving(true);
                await sppAgencyStore.saveItem(mls_company_id);
                setSaving(false);
            }

            commitChanges();
        }
    };

    const handleDelete = async () => {
        sppAgencyStore.setEditingItem(mls_company_id, {
            enable: false
        });
        await sppAgencyStore.saveItem(mls_company_id);

        commitChanges();
    };

    const handleChangePhone = (event, { value }: { value: string }) => {
        handleChange(event, { name: 'phone', value: value.replace(/\D/gi, '') });
    };

    const handleChangeFormat = (event, { value: format }: { value: 'cian2' | 'yandex' }) => {
        sppAgencyStore.setEditingItem(mls_company_id, { format });
    };

    const { editingItem, loadingItem, errors } = sppAgencyStore.getItem(mls_company_id);
    const { phone, title, url, logoUrl, shortTitle, feedUrl, format, color } = editingItem;

    const modalActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleSave,
            label: mls_company_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            loading: loadingItem || saving,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (mls_company_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            loading: loadingItem || saving,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            loading={loadingItem || saving}
            header={
                <>
                    <StorageIcon /> &nbsp; Агентство в СПП
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                <form ref={formRef}>
                    <Grid stackable>
                        <Grid.Column width={9}>
                            <TextInput value={feedUrl} name="feedUrl" onChange={handleChange} minLength={2} required label="Url фида" />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Select
                                value={format}
                                label="Формат"
                                onChange={handleChangeFormat}
                                fullWidth
                                options={[
                                    { value: 'cian2', text: 'Циан-2' },
                                    { value: 'yandex', text: 'Яндекс' },
                                    { value: 'aristo', text: 'aristodevelopers.com' }
                                ]}
                            />
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <TextInput value={url} name="url" onChange={handleChange} minLength={2} required label="Url сайта компании" />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Select value={color} onChange={handleChange} name="color" options={COLORS_DROPDOWN} label="Цвет" fullWidth />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <TextInput
                                value={logoUrl}
                                name="logoUrl"
                                onChange={handleChange}
                                minLength={2}
                                required
                                label="Url логотипа компании"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <TextInput
                                value={title}
                                name="title"
                                onChange={handleChange}
                                minLength={2}
                                required
                                label="Название компании"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <TextInput
                                value={shortTitle}
                                name="shortTitle"
                                onChange={handleChange}
                                maxLength={7}
                                required
                                label="Короткое Название"
                            />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <TextInput
                                value={phone}
                                name="phone"
                                onChange={handleChangePhone}
                                mask="+7 (000) 000-00-00"
                                startLabel={<PhoneIcon />}
                                pattern={phoneRegExp}
                                label="Контактный Телефон"
                                required
                            />
                        </Grid.Column>
                    </Grid>
                </form>
                {loadingItem && <Progress show />}
            </ModalContent>
        </Modal>
    );
};

export default observer(SppAgencyModal);
