import React, { Component } from 'react';
import { Grid, Ref } from 'semantic-ui-react';
import EstateInfoBlock from './ShowBlocks/EstateInfoBlock';
import PriceBlockShow from './ShowBlocks/PriceBlockShow';
import ExportBlockShow from './ShowBlocks/ExportBlockShow';
import ContactEstateBlockShow from './ShowBlocks/ContactEstateBlockShow';
import Comments from '../Common/Comments';
import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';
import isEqual from '~/common/isEqual';

type EstateShowModeProps = {
    estate_id: number;
    major_user_ids: number[];
    loading: boolean;
    enable: boolean;
};

class EstateShowMode extends Component<EstateShowModeProps> {
    contextRef: HTMLElement;

    shouldComponentUpdate(nextProps: EstateShowModeProps): boolean {
        return !isEqual(this.props, nextProps);
    }

    render() {
        const { estate_id, major_user_ids, loading, enable } = this.props;

        return (
            <Ref innerRef={$el => (this.contextRef = $el)}>
                <Grid stackable>
                    <Grid.Column width={10} className="crm-Main__notOverflow">
                        <EstateInfoBlock estate_id={estate_id} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <PriceBlockShow item_id={estate_id} />

                        {!loading && authStore.canEdit(estateStore.moduleName, major_user_ids) && (
                            <ContactEstateBlockShow item_id={estate_id} />
                        )}

                        {!loading && enable && authStore.canRead(exportStore.moduleName, major_user_ids) && (
                            <ExportBlockShow item_id={estate_id} />
                        )}

                        <Comments item_id={estate_id} item_type="ESTATE" />
                    </Grid.Column>
                </Grid>
            </Ref>
        );
    }
}

export default EstateShowMode;
