import React, { Fragment } from 'react';
import MonoString from '../../../Base/MonoString';

export default ({
    priceMin,
    priceMax,
    priceM2Min,
    priceM2Max,
    mortgageApproved,
    short
}: {
    priceMin: number | null;
    priceMax: number | null;
    priceM2Min: number | null;
    priceM2Max: number | null;
    mortgageApproved?: boolean | null;
    short?: boolean;
}) => {
    const template = short ? '? – ?' : 'от ? до ? ₽';

    return (
        <Fragment>
            {(priceMin || priceMax) && (
                <div>
                    <MonoString template={template} values={[priceMin || '...', priceMax || '...']} />
                </div>
            )}
            {(priceM2Min || priceM2Max) && (
                <div>
                    <MonoString template={`${template}/м²`} values={[priceM2Min || '..', priceM2Max || '..']} />
                </div>
            )}
            {typeof mortgageApproved === 'boolean' && (
                <div>
                    <MonoString template={`ипотека ?`} values={[mortgageApproved ? 'одобрена' : 'не одобрена']} />
                </div>
            )}
        </Fragment>
    );
};
