import fetchApi from '../common/fetchApi';

import { KpiInBase, KpiHistory, UserStatisticsFilter } from '~/types/kpi.types';
import { KpiGQLFields } from '~/api/kpiApi';
import { UserStatisticsOptions } from '~/stores/userStatisticsStore';
import { objectToGraphql } from '~/common/graphql';

export const fetchItemList = async (user_id: number, filter: UserStatisticsFilter): Promise<KpiHistory[]> => {
    const graphql = `{
        fetchUserStatisticsList(user_id: ${user_id}, filter: ${objectToGraphql(filter)}) {
            kpi_id
            item_id
            kpi
            time
        }
      }`;

    const { fetchUserStatisticsList } = await fetchApi.getGQ<{ fetchUserStatisticsList: KpiHistory[] }>(graphql);
    return fetchUserStatisticsList;
};

export const fetchOptions = async (user_id: number): Promise<UserStatisticsOptions> => {
    const graphql = `{
        fetchUserKpis(user_id: ${user_id}) {
            ${KpiGQLFields}
        }
      }`;

    const { fetchUserKpis } = await fetchApi.getGQ<{ fetchUserKpis: KpiInBase[] }>(graphql);
    return { userKpis: fetchUserKpis };
};

export const fetchItemListCount = async (user_id: number): Promise<number> => {
    return 0;
};
