import React, { Component, Fragment } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';

import ItemAddModeWrapper from '../ItemAddModeWrapper';
import requestStore from '~/stores/requestStore';
import estateStore from '~/stores/estateStore';
import { RequestLinkState } from '../../Lists/Requests/RequestLink';

import RequestContactIdEditing from './EditBlocks/RequestContactIdEditing';
import RequestTypeEditing from './EditBlocks/RequestTypeEditing';
import RequestFilterEditing from './EditBlocks/RequestFilterEditing';
import RequestEstateEditing from './EditBlocks/RequestEstateEditing';
import RequestHuntingEdition from './EditBlocks/RequestHuntingEdition';
import RequestStatusEditing from './EditBlocks/RequestStatusEditing';
import CreationButtonsSet from '../Common/CreationButtonsSet';
import { ESTATE_BASE_MAIN, TRANSPORT_TYPE_WALK } from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import {
    REQUEST_LIST_HUNTING,
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_HUNTING,
    REQUEST_TYPE_MORTGAGE,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_SELL
} from '~/types/requests.types';

type RequestAddModeProps = RouteComponentProps<{ param: string }> & ItemAddModeWrapperProps;

@observer
class RequestAddMode extends Component<RequestAddModeProps> {
    constructor(props: RequestAddModeProps) {
        super(props);

        const { param: requestType } = props.match.params;

        if (requestType === REQUEST_LIST_HUNTING) {
            requestStore.setEditingItem(CREATING_ITEM_ID, { type: REQUEST_TYPE_HUNTING });
        }

        estateStore.fetchItem(CREATING_ITEM_ID);

        estateStore.setEditingItem(CREATING_ITEM_ID, {
            [estateStore.item_id]: CREATING_ITEM_ID,
            propertyType: 1
        });
    }

    render() {
        const {
            loadingItem,
            editingItem: { propertyType, type }
        } = requestStore.getItem(CREATING_ITEM_ID);

        const shortRequest = [REQUEST_TYPE_HUNTING, REQUEST_TYPE_MORTGAGE, REQUEST_TYPE_NEWBUILDING].includes(type);

        return (
            <Fragment>
                <RequestContactIdEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <RequestTypeEditing item_id={CREATING_ITEM_ID} type={type} editing={propertyType === 0 && !shortRequest} />

                {propertyType > 0 && [REQUEST_TYPE_SELL, REQUEST_TYPE_RENT].includes(type) && (
                    <>
                        <RequestEstateEditing item_id={CREATING_ITEM_ID} />
                        <Divider />
                    </>
                )}

                {propertyType > 0 && [REQUEST_TYPE_BUY, REQUEST_TYPE_HIRE].includes(type) && (
                    <RequestFilterEditing
                        requestObj={{
                            propertyType,
                            type: type - 2,
                            main_metro_id: [],
                            transportType: TRANSPORT_TYPE_WALK,
                            base: ESTATE_BASE_MAIN
                        }}
                        item_id={CREATING_ITEM_ID}
                    />
                )}

                {shortRequest && <RequestHuntingEdition item_id={CREATING_ITEM_ID} requestObj={{ description: '' }} />}

                <RequestStatusEditing item_id={CREATING_ITEM_ID} />

                {(propertyType > 0 || shortRequest) && (
                    <Fragment>
                        <Divider />
                        <CreationButtonsSet
                            loading={Boolean(loadingItem)}
                            onCreate={this.props.handleCreate}
                            onClearForm={this.props.clearForm}
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(requestStore, RequestLinkState, withRouter(RequestAddMode));
