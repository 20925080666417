import React from 'react';
import { Card } from 'semantic-ui-react';

import { Estate } from '~/types/estate.types';
import AgentBonus from '~/components/Base/AgentBonus';

const AgentBonusShow = ({ estate }: { estate: Estate; loadingItem: boolean; printMode?: boolean }) => {
    const { agentBonusType, agentBonusCurrency, agentBonusValue } = estate || {};

    return (
        <Card.Content>
            <b>Бонус агенту</b>
            <Card.Description>
                <AgentBonus agentBonusCurrency={agentBonusCurrency} agentBonusType={agentBonusType} agentBonusValue={agentBonusValue} />
            </Card.Description>
        </Card.Content>
    );
};

export default AgentBonusShow;
