import { observable, action, runInAction, computed } from 'mobx';
import * as estateSMAApi from '~/api/estateSMAApi';
import { ESTATE_BASES, ShareEstateId } from '~/types/estate.types';

import ItemListsStorePrototype from './prototypes/ItemListsStore.prototype';
import { SmaEstateItemType, SmaOptionsType } from '~/types/estateSma.types';

class EstateSMAStore extends ItemListsStorePrototype<SmaEstateItemType, ShareEstateId, {}, SmaOptionsType> {
    adjustItemToId(estate: SmaEstateItemType): ShareEstateId {
        return { estate_id: estate.estate_id, base: estate.base };
    }

    @action
    async estateToSma(sma_estate_id: number, estate_id: number, base: ESTATE_BASES, enable: boolean) {
        await estateSMAApi.toggleEstateToSma(sma_estate_id, estate_id, base, enable);
    }
}

export default new EstateSMAStore('estate_id', estateSMAApi);
