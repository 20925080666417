import fetchApi from '~/common/fetchApi';
import { ExportReportsFilter, EstateExportStatus } from '~/types/exportReports.types';
import { objectToGraphql } from '~/common/graphql';
import { GQEstateMainFields } from '~/api/estateApi';

const ExportErrorListGQ = `
    report_estate_id
    export_base_id
    status
    externalId
    message
    reportTime
    estate_id
    estate {
        ${GQEstateMainFields}
        avatarUrl
    }
`;

export const fetchExportErrorsList = async (
    filter: ExportReportsFilter,
    start: number = 0,
    limit: number
): Promise<EstateExportStatus[]> => {
    const graphql = `{
        fetchExportErrorsList(filter: ${objectToGraphql(filter)}, start: ${start}, limit: ${limit}){
            ${ExportErrorListGQ}
        }
      }`;

    const data: { fetchExportErrorsList: EstateExportStatus[] } = await fetchApi.getGQ(graphql);
    return data.fetchExportErrorsList;
};
