import React, { Component, Fragment } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { MetroStation } from '~/types/estate.types';

import fetchApi from '../../../../common/fetchApi';
import MetroLogo from '../../../Base/MetroLogo';
import estateStore from '~/stores/estateStore';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import Modal, { ModalContent, ModalHeader } from '~ui/Modal';

const METRONAMES_REVERT = {
    'Петровско- Разумовская': 'Петровско-Разумовская',
    Фонвизинскаяа: 'Фонвизинская',
    'Библиотека имени Ленина': 'Библиотека им.Ленина',
    Озерная: 'Озёрная'
};

const orderMetroStations = (metroStations: Array<MetroStation>): Array<MetroStation> => {
    const metroGroups: any = metroStations.reduce((acc, metro) => {
        acc[metro['metro_line_id']] = acc[metro['metro_line_id']] || [];
        acc[metro['metro_line_id']].push(metro);
        return acc;
    }, {});

    const result = Object.keys(metroGroups).reduce((acc, metro_line_id) => {
        return [...acc, ...metroGroups[Number(metro_line_id)].sort((m1: MetroStation, m2: MetroStation) => m1.order < m2.order)];
    }, []);

    return result;
};

type MetroMapBlockProps = {
    simpleMode?: boolean;
    onChangeMetro: (ids: number[]) => void;
    store?: ListStoreInterface;
    main_metro_ids: number[];
};

type MetroMapBlockState = {
    isOpenMetromap: boolean;
    metroSelected: MetroStation[];
};

@observer
class MetroMapBlock extends Component<MetroMapBlockProps, MetroMapBlockState> {
    state = {
        isOpenMetromap: false,
        metroSelected: []
    };

    metroMap = '';

    constructor(props: MetroMapBlockProps) {
        super(props);
        fetchApi.loadPlain('/static/metromap/yandex_metro.svg').then(mapXml => {
            this.metroMap = mapXml;
        });
    }

    initMetroMap = async () => {
        const { main_metro_ids } = this.props;
        const { metroSelected } = this.state;
        const { metroStationsList } = estateStore;

        const $mapStations = Array.from(this.$metroMap.querySelectorAll("g[id^='label']"));

        main_metro_ids.map(async main_metro_id => {
            const foundMetro = metroStationsList.find(({ metro_id }) => metro_id === main_metro_id);
            if (foundMetro) {
                const $foundStation = $mapStations.find($el => {
                    const yandexMetroName = $el.textContent.replace(/ё/g, 'е');
                    return (METRONAMES_REVERT[yandexMetroName] || yandexMetroName) === foundMetro.name;
                });

                if ($foundStation) {
                    $foundStation.setAttribute('fill', 'red');
                    foundMetro.yandex_id = $foundStation.getAttribute('id');

                    const foundMetroSelected = metroSelected.find(({ metro_id }) => metro_id === main_metro_id);
                    if (!foundMetroSelected) {
                        metroSelected.push(foundMetro);
                    }
                }
            }
        });

        this.setState({ metroSelected: [...metroSelected] });
    };

    handleToggleMetromap = () => {
        const { isOpenMetromap } = this.state;
        this.setState({ isOpenMetromap: !isOpenMetromap });
    };

    componentDidUpdate(prevProps: MetroMapBlockProps, prevState: MetroMapBlockState) {
        if (this.state.isOpenMetromap && !prevState.isOpenMetromap) {
            setTimeout(() => {
                this.initMetroMap();
            }, 0);
        }
    }

    handleMetroMapClick = async ({ target }: { target: any }) => {
        let g;

        try {
            const stationAttribute = target.getAttribute('id').match(/station-(\d+)/);
            g = target.closest('g').parentNode.querySelector(`#label-${stationAttribute[1]}`);
        } catch (error) {
            g = target.closest('g');
        }

        const { metroStationsList } = estateStore;

        const yandex_id = g ? g.getAttribute('id') : '';

        if (yandex_id.indexOf('label-') === 0) {
            let yandexMetroName = g.querySelector('tspan')
                ? Array.from(g.querySelectorAll('tspan'))
                      .map((element: any) => element.textContent)
                      .join(' ')
                : g.textContent;
            yandexMetroName = yandexMetroName.replace(/ё/g, 'е');

            let foundMetros = metroStationsList
                .filter(({ name }) => name.toLowerCase() === (METRONAMES_REVERT[yandexMetroName] || yandexMetroName).toLowerCase())
                .map(metro => {
                    metro.yandex_id = yandex_id;
                    return metro;
                });

            if (foundMetros.length > 0) {
                g.setAttribute('fill', 'red');

                foundMetros = foundMetros.filter(metro => {
                    const index = this.state.metroSelected.findIndex(({ metro_id }) => metro_id === metro.metro_id);
                    if (index > -1) {
                        this.delStationByIndex(index);
                        return false;
                    }
                    return true;
                });

                if (foundMetros.length > 0) {
                    await this.setState({
                        metroSelected: orderMetroStations([...this.state.metroSelected, ...foundMetros])
                    });
                }

                // this.selectMetro(g);

                this.changeMetro();
            } else {
                g.setAttribute('fill', 'blue');
            }
        }
    };

    $metroMap: HTMLElement | null;

    removeStation = (id: number) => {
        const index = this.state.metroSelected.findIndex(({ metro_id }) => metro_id === id);
        if (index > -1) {
            this.delStationByIndex(index);
            this.changeMetro();
        }
    };

    delStationByIndex = (index: number) => {
        const metroSelected = this.state.metroSelected;

        const { yandex_id } = metroSelected[index];
        const $el: HTMLElement | null = this.$metroMap ? this.$metroMap.querySelector(`#${yandex_id || ''}`) : null;

        if ($el) {
            $el.removeAttribute('fill');
        }

        metroSelected.splice(index, 1);

        this.setState({
            metroSelected
        });
    };

    changeMetro = () => {
        this.props.onChangeMetro(this.state.metroSelected.map(({ metro_id }) => metro_id));
    };

    handleMetroRemove = ({ target: { id } }: { target: { id: number } }) => {
        this.removeStation(Number(id));
    };

    clearMetroMap = () => {
        while (this.state.metroSelected.length > 0) {
            this.delStationByIndex(0);
        }
        this.changeMetro();
    };

    render() {
        const { simpleMode, store } = this.props;

        let listCount;
        let loadingList;

        if (store) {
            listCount = store.listCount;
            loadingList = store.loadingList;
        }

        const { isOpenMetromap, metroSelected } = this.state;

        return (
            <Fragment>
                <Button title="Открыть схему метро" size="mini" basic color="red" onClick={this.handleToggleMetromap}>
                    <MetroLogo color="red" size={12} /> &nbsp; Схема
                </Button>
                &nbsp;
                {isOpenMetromap && (
                    <Modal fullScreen>
                        <ModalContent style={{ overflowX: 'auto', position: 'relative' }}>
                            <div className="crm-Estate__metroMap_buttons">
                                <Button size="mini" basic onClick={this.clearMetroMap}>
                                    Очистить
                                </Button>
                                <Button size="mini" basic loading={loadingList} primary onClick={this.handleToggleMetromap}>
                                    Показать ({listCount})
                                </Button>
                            </div>

                            <div className="crm-Estate__metroMap_box">
                                {metroSelected.map(({ metro_id, name, color }) => (
                                    <div key={metro_id} style={{ color: `#${color}` }} className="crm-Estate__metroMap_item">
                                        {name} <Icon link name="close" title="убрать" id={metro_id} onClick={this.handleMetroRemove} />
                                    </div>
                                ))}
                            </div>
                            <div
                                ref={$el => (this.$metroMap = $el)}
                                className="crm-Estate__metroMap"
                                onClick={this.handleMetroMapClick}
                                dangerouslySetInnerHTML={{ __html: this.metroMap }}
                            />
                        </ModalContent>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default MetroMapBlock;
