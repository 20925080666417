import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import Chip from '@material-ui/core/Chip';

import estateStore from '~/stores/estateStore';
import { EstateFilter } from '~/types/estate.types';
import { estateSearchLabels } from '~/stores/helpers/estate.store.helpers';

type EstateFilterKeyof = keyof EstateFilter;

const EstateMapFilterChips = ({ type }: { type: 'map' | 'list' }) => {
    const handleDeleteChip = (properties: EstateFilterKeyof[]) => {
        properties.forEach((prop: EstateFilterKeyof) => {
            estateStore.listFilter = { ...estateStore.listFilter, [prop]: estateStore.listFilterClear[prop] };
        });
        if (type === 'map') {
            estateStore.mapChange();
        } else {
            estateStore.fetchList();
        }
    };

    return (
        <Fragment>
            {estateSearchLabels(estateStore.listFilter).map(({ label, key }) => (
                <Chip key={label} label={label} onDelete={() => handleDeleteChip(typeof key === 'string' ? [key] : key)} />
            ))}
        </Fragment>
    );
};

export default observer(EstateMapFilterChips);
