import config from '../config/ui.config';
import fetchApi from '../common/fetchApi';
import { emptyAddressObject } from '~/common/address';
import { EstateAddressStrict } from '~/types/estate.types';
import { adjustEstateAddressShort } from '~/common/address';

export const fetchYandexAddressByCoords = async (map_lat: number, map_lon: number): Promise<Partial<EstateAddressStrict>> => {
    const url = `${config.serverUrl}/address/coordinates?map_lat=${map_lat}&map_lon=${map_lon}`;

    try {
        const answer: any = await fetchApi.get(url);

        const { Address } = answer.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData;

        const addressObject = {
            ...emptyAddressObject,
            address: Address.formatted
        };

        Address.Components.forEach(({ kind, name }: { kind: string; name: string }) => {
            switch (kind) {
                case 'province':
                    addressObject['region'] = name;
                    break;
                case 'locality':
                    addressObject['town'] = name;
                    break;
                case 'street':
                    addressObject['street'] = name;
                    break;
                case 'house':
                    addressObject['houseNumber'] = name;
                    break;
                case 'district':
                    addressObject['cityDistrict'] = name;
                    break;
                case 'area':
                    if (~name.indexOf('район')) {
                        addressObject['district'] = name;
                    } else if (~name.indexOf('поселение')) {
                        addressObject['settlement'] = name;
                    } else if (~name.indexOf('городской округ') && !addressObject['district']) {
                        addressObject['district'] = name;
                    } else {
                        console.warn(kind, name);
                    }
                    break;

                default:
                    console.warn(kind, name);
            }
        });

        addressObject.address = adjustEstateAddressShort(addressObject);

        return addressObject;
    } catch (error) {
        throw new Error(error.message);
    }
};
