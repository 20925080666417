import React from 'react';

import Time from '~/components/Base/Time';
import { TableCell, TableRow, TableBody } from '~ui/Table';

import RequestLink from './RequestLink';
import { RequestTableType } from '~/types/requests.types';
import UsersLinkList from '../Users/UsersLinkList';
import ListTagsPopup from '../Contacts/ListTagsPopup';
import ContactLinkWithPreview from '../Contacts/ContactLinkWithPreview';
import requestL10n from '~/L10n/request.L10n';
import ListComments from '~/components/Lists/Common/ListComments';

const RequestTableBody = ({ requestsList, orderBy }: { requestsList: Array<RequestTableType>; orderBy: string }) => {
    return (
        <TableBody>
            {requestsList.length > 0 &&
                requestsList.map(
                    ({
                        request_id,
                        status,
                        comments,
                        type,
                        propertyType,
                        enable,
                        updateTime,
                        createTime,
                        contact,
                        major_users,
                        tags_ids
                    }) => {
                        return (
                            <TableRow key={request_id}>
                                <TableCell>
                                    <b>
                                        <RequestLink request={{ request_id, propertyType, type, enable }} />
                                    </b>
                                    {orderBy !== 'createTime' && (
                                        <div>
                                            Актуальность:&nbsp;
                                            <b>
                                                <Time time={updateTime} />
                                            </b>
                                        </div>
                                    )}
                                    {orderBy === 'createTime' && (
                                        <div>
                                            Создание:&nbsp;
                                            <b>
                                                <Time time={createTime} />
                                            </b>
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <b>{requestL10n.REQUEST_STATUS_FIND(status) || 'н/д'}</b>
                                </TableCell>
                                <TableCell>{contact && <ContactLinkWithPreview contact={contact} />}</TableCell>
                                <TableCell>
                                    <ListTagsPopup tags_ids={tags_ids} />
                                </TableCell>
                                <TableCell>
                                    <ListComments comments={comments} item_id={request_id} item_type="REQUEST" />
                                </TableCell>
                                <TableCell>
                                    <UsersLinkList usersList={major_users} />
                                </TableCell>
                            </TableRow>
                        );
                    }
                )}
        </TableBody>
    );
};

export default RequestTableBody;
