import React, { ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import kanbanStore from '~/stores/kanbanStore';
import ConfirmTrigger from '~ui/ConfirmTrigger';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    success: {
        color: palette.success.dark
    },
    titleField: {
        display: 'flex',
        marginRight: spacing(6)
    }
}));

const BoardListFieldEditing = ({ board_id, onClose }: { board_id: number; onClose: () => void }) => {
    const classes = useStyles();

    const {
        editingItem: { title }
    } = kanbanStore.getItem(board_id);

    const handleEditTitle = (event: ChangeEvent<HTMLInputElement>) => {
        kanbanStore.setEditingItem(board_id, { title: event.target.value });
    };

    const handleCancel = () => {
        const { item } = kanbanStore.getItem(board_id);

        kanbanStore.setEditingItem(board_id, { title: item.title });
        onClose();
    };

    const handleDelete = async () => {
        await kanbanStore.toggleDisableItem(board_id, false);
        onClose();
    };

    const handleSave = () => {
        kanbanStore.saveItem(board_id);
        onClose();
    };

    return (
        <ListItem dense disableGutters>
            <ListItemIcon onClick={handleCancel}>
                <Tooltip title="Отмена">
                    <IconButton edge="start" color="secondary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={
                    <div className={classes.titleField}>
                        <TextField
                            margin="none"
                            size="small"
                            value={title}
                            onChange={handleEditTitle}
                            autoFocus
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                        />
                    </div>
                }
            />
            <ListItemSecondaryAction>
                <ConfirmTrigger
                    trigger={
                        <Tooltip title="Удалить">
                            <IconButton edge="end" color="secondary">
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    header="Удалить доску и все списки?"
                    content="Все карточки с этой доски станут Неразобранными"
                    confirmButton="Удалить Доску"
                    onConfirm={handleDelete}
                />

                <Tooltip title="Сохранить">
                    <IconButton edge="end" onClick={handleSave} className={classes.success}>
                        <DoneIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default observer(BoardListFieldEditing);
