import React, { Fragment } from 'react';
import { Label, SemanticSIZES } from 'semantic-ui-react';
import TagContact from '~/components/Settings/Tags/TagContact';

const TagsList = ({ tags_ids, size }: { tags_ids: number[]; size?: SemanticSIZES }) => (
    <Fragment>
        {tags_ids.length > 0 && (
            <Label.Group size={size}>
                {tags_ids.map(tag_id => (
                    <TagContact tag_id={tag_id} key={tag_id} />
                ))}
            </Label.Group>
        )}
    </Fragment>
);

export default TagsList;
