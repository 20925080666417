import { objectToGraphql } from '../common/graphql';
import fetchApi from '../common/fetchApi';
import { LearningMaterial } from '~/types/learning.types';
import { GQUserMainFields } from './userApi';

const LearningGQLFields = `
    material_id
    title
    description
    url
    major_user_id
    major_user {
        ${GQUserMainFields}
    }
    files {
        file_id
        originalname
        ext
        filename
    }
    createTime
    updateTime
    enable
`;

export const createItem = async (learningMaterial: LearningMaterial): Promise<number> => {
    const graphql = `mutation { 
          createLeaningMaterial(learningInput: ${objectToGraphql(learningMaterial)})
        }`;

    const data: { createLeaningMaterial: number } = await fetchApi.postGQ(graphql);
    return data.createLeaningMaterial;
};

export const saveItem = async (material_id: number, learninDiff: LearningMaterial): Promise<number> => {
    const graphql = `mutation {
        updateLearningMaterial(material_id: ${material_id}, learningMaterialDiff: ${objectToGraphql(learninDiff)})
    }`;

    const data: { updateLearningMaterial: number } = await fetchApi.postGQ(graphql);
    return data.updateLearningMaterial;
};

export const fetchItem = async (id: number): Promise<LearningMaterial> => {
    const graphql = `{
            fetchLearningMaterial(material_id: ${id}) {
            } 
          }`;

    const data: { fetchLearningMaterial: LearningMaterial } = await fetchApi.getGQ(graphql);
    return data.fetchLearningMaterial;
};

export const fetchList = async (): Promise<{ list: Array<LearningMaterial>; count: number }> => {
    const graphql = `{
            fetchLearningMaterials{
                list {
                    ${LearningGQLFields}     
                }
                count
            }
          }`;

    const data: { fetchLearningMaterials: { list: LearningMaterial[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchLearningMaterials;
};
