import { action, observable } from 'mobx';
import * as userStatisticsApi from '~/api/userStatisticsApi';

import { KpiInBase, KpiHistory, UserStatisticsFilter } from '~/types/kpi.types';
import ItemListsStorePrototype from '~/stores/prototypes/ItemListsStore.prototype';
import { DAY_SEC } from '~/common/time';

const DefaultStatFilter = {
    kpi_id: 0,
    deltaTimeSec: 30 * DAY_SEC,
    fromTimeSec: null,
    toTimeSec: null
};

export type UserStatisticsOptions = {
    userKpis: KpiInBase[];
};

class UserStatisticsStore extends ItemListsStorePrototype<KpiHistory, number, UserStatisticsFilter, UserStatisticsOptions> {
    itemListFilterClear: UserStatisticsFilter = DefaultStatFilter;

    adjustItemToId(kpi: KpiHistory) {
        return kpi.kpi_id;
    }
}

export default new UserStatisticsStore('kpi_id', userStatisticsApi);
