import { proxyL10nHandler } from '../common/localizations';

const PRIORITY = [
    [1, 'обычная'],
    [2, 'срочная'],
    [3, 'не срочная']
];

const STATUS = [
    [1, 'в ожидание'],
    [2, 'в процессе'],
    [3, 'ожидает провекри'],
    [4, 'закрыта']
];

const DURATION_TYPES = [
    [1, 'минут'],
    [2, 'часов'],
    [3, 'дней']
];

const tasksL10n = new Proxy({}, proxyL10nHandler);

tasksL10n.PRIORITY = PRIORITY;
tasksL10n.STATUS = STATUS;
tasksL10n.DURATION_TYPES = DURATION_TYPES;

export default tasksL10n;
