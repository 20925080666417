import React, { useMemo, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { Newbuilding } from '~/types/newbuildings.types';

import EstateInfoBlock from './ShowBlocks/EstateInfoBlock';
import PriceBlockShow from './ShowBlocks/PriceBlockShow';
import ExternalBlockShow from './ShowBlocks/ExternalBlockShow';
import PreventEditing from '../../Base/PreventEditing';
import Comments from '../Common/Comments';
import FixPriceBlockShow from '~/components/Items/Estate/ShowBlocks/FixPriceBlockShow';
import LifeComplexMajorUsers from '~/components/Items/LifeComplex/ShowBlocks/LifeComplexMajorUsers';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

type EstateExternalModeProps = {
    estate_id: number;
    newbuilding_id: number | null;
};

const EstateExternalMode = (props: EstateExternalModeProps) => {
    const { estate_id, newbuilding_id } = props;

    const [newbuilding, setNewbuilding] = useState<Newbuilding | null>(null);

    useMemo(() => {
        if (newbuilding_id) {
            lifeComplexStore.fetchItem(newbuilding_id).then(() => {
                const newbuilding = lifeComplexStore.getItem(newbuilding_id).item;
                setNewbuilding(newbuilding);
            });
        }
    }, [newbuilding_id]);

    return (
        <PreventEditing>
            <Grid stackable reversed="mobile">
                <Grid.Column width={10}>
                    <EstateInfoBlock estate_id={estate_id} />
                </Grid.Column>
                <Grid.Column width={6}>
                    <PriceBlockShow item_id={estate_id} />
                    {newbuilding_id && <FixPriceBlockShow item_id={estate_id} />}
                    {newbuilding_id && <LifeComplexMajorUsers item_id={newbuilding_id} />}
                    {/*<ExternalBlockShow item_id={estate_id} />*/}
                    <Comments item_id={estate_id} item_type="externalEstate" />
                </Grid.Column>
            </Grid>
        </PreventEditing>
    );
};

export default EstateExternalMode;
