import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import sourcePhoneStore from '~/stores/cell/sourcePhoneStore';

import SourcePhoneModal from './SourcePhoneModal';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

import ContainerHeader from '~ui/ContainerHeader';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import LoaderAwait from '~/components/Base/LoaderAwait';
import settingStore from '~/stores/settingStore';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';
import { WarningMessage } from '~ui/Message';

import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import InfoPopup from '~/components/Base/InfoPopup';

const SourcePhonesTitle = React.memo(() => (
    <span>
        Настройка колл-центра&nbsp;
        <InfoPopup>
            Ссылка на звонок для Колл-центра: <b>{document.location.origin + '/calls/list/incoming/7XXXXXXXXXX'}</b>
        </InfoPopup>
    </span>
));

const SourcePhonesSettings = () => {
    const [operatorIndex, setOperatorIndex] = useState<number | null>(null);

    useEffect(() => {
        sourcePhoneStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = sourcePhoneStore;

    return (
        <Fragment>
            <ContainerHeader
                handleCreate={() => setOperatorIndex(CREATING_ITEM_ID)}
                title={<SourcePhonesTitle />}
                enableCreating={authStore.canCreate(settingStore.moduleName)}
                icon={<HeadsetMicIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ title, source_phone_id, phone }, index) => (
                        <ListItem button key={source_phone_id} onClick={() => setOperatorIndex(source_phone_id)}>
                            <ListItemText primary={title} secondary={adjustPhoneNumber(phone)} />
                        </ListItem>
                    ))}
                </List>

                {!loadingList && list.length === 0 && (
                    <WarningMessage>Не подключено ни одного телефона-источника для колл-центра</WarningMessage>
                )}

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {operatorIndex !== null && <SourcePhoneModal source_phone_id={operatorIndex} onClose={() => setOperatorIndex(null)} />}
        </Fragment>
    );
};

export default observer(SourcePhonesSettings);
