import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import StorageIcon from '@material-ui/icons/Storage';
import LinkIcon from '@material-ui/icons/Link';

import ContainerHeader from '~ui/ContainerHeader';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';
import sppAgencyStore from '~/stores/sppAgencyStore';
import authStore from '~/stores/authStore';
import settingStore from '~/stores/settingStore';
import Button from '~ui/Button';

import SppAgencyModal from './SppAgencyModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

const SppSettings = () => {
    const [mlsAgencyId, setMlsAgencyId] = useState<number | null>(null);

    useMemo(() => {
        sppAgencyStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = sppAgencyStore;

    return (
        <>
            <ContainerHeader
                title="Настройка СПП импорта"
                handleCreate={() => setMlsAgencyId(CREATING_ITEM_ID)}
                enableCreating={authStore.canCreate(settingStore.moduleName)}
                icon={<StorageIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List>
                    {list.map(({ mls_company_id, title, logoUrl, url }) => (
                        <ListItem key={mls_company_id} button onClick={() => setMlsAgencyId(mls_company_id)}>
                            <ListItemAvatar>
                                <Avatar src={logoUrl} />
                            </ListItemAvatar>
                            <ListItemText primary={title} />
                            <ListItemSecondaryAction>
                                <Button icon={<LinkIcon />} href={url} target="_blank" tooltip="Открыть сайт компании" />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {mlsAgencyId !== null && <SppAgencyModal mls_company_id={mlsAgencyId} onClose={() => setMlsAgencyId(null)} />}
        </>
    );
};

export default observer(SppSettings);
