import React, { PureComponent } from 'react';
import ListPagination, { ListPaginationProps } from './ListPaginationNew';
import { TableFooter } from '~ui/Table';

class ListFooter extends PureComponent<ListPaginationProps> {
    render() {
        return (
            <TableFooter className="crm-Print__hidden">
                <ListPagination {...this.props} />
            </TableFooter>
        );
    }
}

export default ListFooter;
