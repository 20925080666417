import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon, Form, Ref } from 'semantic-ui-react';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import EditField from '../../Base/EditField';
import callSourceStore from '~/stores/cell/callSourceStore';
import { checkFormValid } from '~/common/forms';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import { CallSource } from '~/types/cellCalls.types';
import { COLORS_DROPDOWN } from '../Tags/TagModal';
import commonStore from '~/stores/commonStore';

type CallSourceModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

@observer
class CallSourceModal extends Component<CallSourceModalProps> {
    $form: HTMLFormElement | null = null;

    constructor(props: CallSourceModalProps) {
        super(props);

        const { item_id } = this.props;

        callSourceStore.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const callSource: Partial<CallSource> = {
                source_id: item_id,
                title: '',
                description: '',
                color: 'red',
                enable: true
            };

            callSourceStore.setEditingItem(item_id, callSource);
        }
    }

    handleCreateSource = async (event: React.SyntheticEvent) => {
        if (checkFormValid(this.$form, true)) {
            event.preventDefault();
            event.stopPropagation();

            const { item_id, handleClose } = this.props;

            if (item_id === CREATING_ITEM_ID) {
                await callSourceStore.createItem();
                const { errors } = callSourceStore.getItem(item_id);
                if (errors && errors.length) {
                    return;
                }
            } else {
                await callSourceStore.saveItem(item_id);
            }

            handleClose();
            callSourceStore.fetchList();
        }
    };

    handleDelete = async () => {
        const { item_id, handleClose } = this.props;
        callSourceStore.setEditingItem(item_id, {
            enable: false
        });
        await callSourceStore.saveItem(item_id);

        handleClose();

        callSourceStore.fetchList();
    };

    render() {
        const { handleClose, item_id, handleChange, handleTextAreaChange } = this.props;
        const { editingItem, loadingItem, errors } = callSourceStore.getItem(item_id);

        const { title, description, color } = editingItem || {};

        const modalActions: ModalBtnAction[] = [
            { onClick: handleClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
            {
                onClick: this.handleCreateSource,
                label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
                disabled: loadingItem,
                color: 'primary',
                variant: 'outlined',
                startIcon: <SaveIcon />
            }
        ];

        if (item_id > CREATING_ITEM_ID) {
            modalActions.splice(1, 0, {
                onClick: this.handleDelete,
                label: 'Удалить',
                disabled: loadingItem,
                variant: 'text',
                color: 'secondary',
                startIcon: <DeleteIcon />
            });
        }

        return (
            <Modal
                maxWidth="sm"
                onClose={handleClose}
                actions={modalActions}
                fullScreen={commonStore.isMobile}
                loading={loadingItem}
                header={
                    <>
                        <Icon name="database" color={color} /> &nbsp; Источник звонков
                    </>
                }
            >
                <ModalContent>
                    <ListError errors={Array.from(errors || [])} />

                    {!loadingItem && editingItem && (
                        <form ref={$el => (this.$form = $el)}>
                            <div className="ui form small">
                                <div className="crm-Item__editingMode crm-Triggers__modalField">
                                    <EditField.Text
                                        label="Название"
                                        placeholder="Тэг"
                                        value={title}
                                        name="title"
                                        onChange={handleChange}
                                        required=".{3,}"
                                    />

                                    <EditField.Drop
                                        label="Цвет"
                                        name="color"
                                        value={color}
                                        onChange={handleChange}
                                        options={COLORS_DROPDOWN}
                                    />

                                    <EditField.Area
                                        label="Описание источника"
                                        value={description}
                                        name="description"
                                        onChange={handleTextAreaChange}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(callSourceStore, CallSourceModal);
