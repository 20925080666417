import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Icon } from 'semantic-ui-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Comments from '../Common/Comments/index';

import LabelArchive from '../Common/LabelArchive';
import ShadowFragment from '~/components/Base/ShadowFragment';
import wishStore from '~/stores/wishStore';
import authStore from '~/stores/authStore';

import WishFilterShow from './ShowBlocks/WishFilterShow';
import WishInterestedEstatesShow from './ShowBlocks/WishInterestedEstatesShow';
import ClientShowMode from '../../Base/ClientShowBlock';
import estateL10n from '~/L10n/estate.L10n';
import { wishTypeTitle } from '../../Lists/Wishes/WishLink';
import LoaderAwait from '~/components/Base/LoaderAwait';
import WishIsNotActuallyButton from './WishButtons/WishIsNotActuallyButton';
import WishContactIsRealtorButton from './WishButtons/WishContactIsRealtorButton';
import WishIsAlreadyBuyButton from './WishButtons/WishIsAlreadyBuyButton';
import { AdditionalComments } from '../Common/Comments';
import WishTakeToWorkButton from './WishButtons/WishTakeToWorkButton';
import WishIsNotInterestedButton from './WishButtons/WishIsNotInterestedButton';
import WishExtendBookingButton from './WishButtons/WishExtendBookingButton';

import { InfoMessage } from '~ui/Message';
import Time from '~/components/Base/Time';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import UserAvatar from '~ui/UserAvatar';
import commonStore from '~/stores/commonStore';
import settingStore from '~/stores/settingStore';

type WishShowModeProps = {
    wish_id: number;
};

@observer
class WishShowMode extends Component<WishShowModeProps> {
    render() {
        const { wish_id } = this.props;
        const { item, loadingItem } = wishStore.getItem(wish_id);

        const { type, propertyType, enable, contact, major_users, contact_id, isBooked, bookedUser } = item || {};
        const multiTypes: AdditionalComments[] = Number(contact_id)
            ? [{ item_id: Number(contact_id), item_type: 'CONTACT', title: 'С карточки контакта' }]
            : [];

        return (
            <Grid stackable>
                <Grid.Column width={9}>
                    {!enable && <LabelArchive />}

                    <h3>
                        <Icon name="spy" />
                        <ShadowFragment
                            shadow={!item}
                            print={
                                <span>
                                    {wishTypeTitle(type)} на {estateL10n.PROPERTY_TYPE_GENETIVE_FIND(propertyType)}
                                </span>
                            }
                        />
                    </h3>

                    <WishFilterShow item_id={wish_id} />
                    <LoaderAwait immediately={loadingItem && !!item} active={loadingItem} dimmer />
                </Grid.Column>

                <Grid.Column width={7}>
                    {(!isBooked ||
                        isBooked.major_user_id === authStore.currentUser.user_id ||
                        authStore.canEdit(wishStore.moduleName, isBooked.major_user_id)) && (
                        <ClientShowMode title="Контакт" contact={contact} major_users={major_users} />
                    )}

                    {item && item.interestedEstates.length > 0 && <WishInterestedEstatesShow wish_id={wish_id} />}

                    {enable && !isBooked && (
                        <List>
                            {settingStore?.mainConfig?.enableWishBooking && (
                                <ListItem>
                                    <WishTakeToWorkButton disabled={loadingItem} wish_id={wish_id} />
                                </ListItem>
                            )}
                            <ListItem>
                                <WishIsAlreadyBuyButton disabled={loadingItem} wish_id={wish_id} />
                            </ListItem>
                            <ListItem>
                                <WishContactIsRealtorButton disabled={loadingItem} wish_id={wish_id} />
                            </ListItem>
                            <ListItem>
                                <WishIsNotActuallyButton disabled={loadingItem} wish_id={wish_id} />
                            </ListItem>
                        </List>
                    )}

                    {enable && isBooked && isBooked.major_user_id === authStore.currentUser.user_id && (
                        <List>
                            {settingStore?.mainConfig?.enableExtendBooking && (
                                <ListItem>
                                    <WishExtendBookingButton disabled={loadingItem} wish_id={wish_id} />
                                </ListItem>
                            )}
                            <ListItem>
                                <WishIsNotInterestedButton disabled={loadingItem} wish_id={wish_id} />
                            </ListItem>
                        </List>
                    )}

                    {isBooked && bookedUser && (
                        <InfoMessage header="Покупатель зарезервирован" style={{ marginTop: '1rem' }}>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemAvatar>
                                        <UserAvatar
                                            firstName={bookedUser.firstName}
                                            lastName={bookedUser.lastName}
                                            src={bookedUser.avatarUrl}
                                            size={commonStore.isMobile ? 36 : 48}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<UserLinkWithPreview user={bookedUser} />}
                                        secondary={
                                            isBooked.expireTime ? (
                                                <>
                                                    до <Time time={isBooked.expireTime} />
                                                </>
                                            ) : (
                                                'Бессрочно'
                                            )
                                        }
                                    />
                                </ListItem>
                            </List>
                        </InfoMessage>
                    )}

                    <Comments item_id={wish_id} item_type="WISH" multiTypes={multiTypes} />
                </Grid.Column>
            </Grid>
        );
    }
}

export default WishShowMode;
