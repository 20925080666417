import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Table, Icon, Dimmer, Loader } from 'semantic-ui-react';
import statisticStore from '~/stores/statisticStore';
import UserLinkWithPreview from '../Lists/Users/UserLinkWithPreview';
import authStore from '~/stores/authStore';
import statisticsConfig from '../../config/statistics.config';
import { DAILY_CALLS_KEY, WEEK_CALLS_KEY } from '~/types/statistics.types';

@observer
class TableStatisticsDefault extends Component<{}> {
    componentDidMount() {
        statisticStore.group_id = -1;
        statisticStore.fetchList();
    }

    render() {
        const { list, loadingList, orderBy, orderDirection } = statisticStore;

        const statisticsTotal = {
            dailyCalls: 0,
            weekCalls: 0,
            weekShowings: 0,
            weekMeetingEvents: 0,
            exclusives: 0,
            cianCount: 0,
            weekCianCreated: 0,
            weekCallsToEstate: 0,
            weekSmaShares: 0
        };

        const statKeys = [
            'dailyCalls',
            'weekCalls',
            'weekShowings',
            'weekMeetingEvents',
            'weekCallsToEstate',
            'cianCount',
            'weekCianCreated',
            'exclusives',
            'weekSmaShares'
        ];

        return (
            <Fragment>
                <Table size="small" sortable celled compact className="crm-Statistics__modal_table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="crm-Statistics__modal_firstCol">№</Table.HeaderCell>
                            <Table.HeaderCell className="crm-Statistics__modal_userCol" />
                            <Table.HeaderCell
                                sorted={orderBy === DAILY_CALLS_KEY ? orderDirection : null}
                                onClick={() => statisticStore.handleSort(DAILY_CALLS_KEY)}
                                title="Количество исходящих звонков за сегодня"
                            >
                                <Icon name="phone" /> сегодня
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === WEEK_CALLS_KEY ? orderDirection : null}
                                onClick={() => statisticStore.handleSort(WEEK_CALLS_KEY)}
                                title="Количество исходящих звонков за 7 дней"
                            >
                                <Icon name="phone" /> 7 д.
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'weekShowings' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('weekShowings')}
                                title="Количество показов за 7 дней"
                            >
                                <Icon name="compass" /> 7 д.
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'weekMeetingEvents' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('weekMeetingEvents')}
                                title="Количество встреч за 7 дней"
                            >
                                <Icon name="handshake outline" /> 7 д.
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'weekCallsToEstate' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('weekCallsToEstate')}
                                title="Количество звонков по объектам за 7 дней"
                            >
                                <Icon name="phone" />
                                <Icon name="arrow right" color="green" />
                                <Icon name="building" color="orange" /> 7 д.
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'cianCount' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('cianCount')}
                                title="Количество уникальных id в ЦИАН и Авито и Домклик"
                            >
                                Платные
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'weekCianCreated' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('weekCianCreated')}
                                title="Добавлено уникальных id в ЦИАН и Авито и Домклик за неделю"
                            >
                                Платные, 7д
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'exclusives' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('exclusives')}
                                title="Количество не просроченных эксклюзивов"
                            >
                                Экскл.
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={orderBy === 'exclusives' ? orderDirection : null}
                                onClick={() => statisticStore.handleSort('exclusives')}
                                title="Количество отправленных СМА за 7 дней"
                            >
                                СМА, 7д
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {list.map((stat, index) => {
                            statKeys.forEach(key => {
                                statisticsTotal[key] += stat[key];
                            });

                            const {
                                major_user,
                                major_user_id,
                                dailyCalls,
                                weekCalls,
                                weekShowings,
                                weekMeetingEvents,
                                exclusives,
                                cianCount,
                                weekCianCreated,
                                weekCallsToEstate,
                                weekSmaShares
                            } = stat;

                            return (
                                <Table.Row key={index} active={major_user_id === authStore.currentUser.user_id}>
                                    <Table.Cell className="crm-Statistics__modal_firstCol">{index + 1}</Table.Cell>
                                    <Table.Cell className="crm-Statistics__modal_userCol">
                                        <UserLinkWithPreview avatar target="_blank" user={major_user} />
                                    </Table.Cell>
                                    <Table.Cell
                                        positive={dailyCalls > statisticsConfig[DAILY_CALLS_KEY].green}
                                        negative={dailyCalls < statisticsConfig[DAILY_CALLS_KEY].red}
                                        textAlign="right"
                                    >
                                        {dailyCalls}
                                    </Table.Cell>
                                    <Table.Cell
                                        positive={weekCalls > statisticsConfig[WEEK_CALLS_KEY].green}
                                        negative={weekCalls < statisticsConfig[WEEK_CALLS_KEY].red}
                                        textAlign="right"
                                    >
                                        {weekCalls}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">{weekShowings}</Table.Cell>
                                    <Table.Cell textAlign="right">{weekMeetingEvents}</Table.Cell>
                                    <Table.Cell textAlign="right">{weekCallsToEstate}</Table.Cell>
                                    <Table.Cell textAlign="right">{cianCount}</Table.Cell>
                                    <Table.Cell textAlign="right">{weekCianCreated}</Table.Cell>
                                    <Table.Cell textAlign="right">{exclusives}</Table.Cell>
                                    <Table.Cell textAlign="right">{weekSmaShares}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell className="crm-Statistics__modal_firstCol" />
                            <Table.HeaderCell className="crm-Statistics__modal_userCol">Итого:</Table.HeaderCell>
                            {statKeys.map(key => (
                                <Table.HeaderCell key={key} textAlign="right">
                                    {statisticsTotal[key].toLocaleString()}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Footer>
                </Table>

                {loadingList && (
                    <Dimmer active inverted>
                        <Loader active size="medium" />
                    </Dimmer>
                )}
            </Fragment>
        );
    }
}

export default TableStatisticsDefault;
