import React, { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, ModalActions, ModalContent } from '~ui/Modal';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';

import SnoozeIcon from '@material-ui/icons/Snooze';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { NumberInput } from '~ui/TextInput';
import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';
import callEventStore from '~/stores/cell/callEventStore';
import { DAY_MS } from '~/common/time';
import * as commentsApi from '~/api/commentsApi';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import authStore from '~/stores/authStore';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap'
    }
}));

const DELETE_STATUS_ALREADY_SOLD = 1;
const DELETE_STATUS_CHANGED_DECIDING = 2;
const DELETE_STATUS_TEMPORARY = 3;
const DELETE_STATUS_MISTAKE = 4;

const EstateToArchiveModal = ({ estate_id, onClose }: { estate_id: number; onClose: () => void }) => {
    const classes = useStyles();

    const { price, contact_id } = estateStore.getItem(estate_id).item;
    const [soldPrice, setSoldPrice] = useState(price);
    const handleChangePrice = (event: React.ChangeEvent, { value }: { value: number }) => {
        setSoldPrice(value);
    };

    const [toggleConfirm, setToggleConfirm] = useState(true);
    const handleToggleConfirm = () => {
        setToggleConfirm(!toggleConfirm);
    };

    const [deleteStatus, setDeleteStatus] = useState(0);
    const [rememberDate, setCreateDate] = useState<Date>(new Date(Date.now() + DAY_MS * 30));
    const [deleting, setDeleting] = useState(false);

    const handleTemporaryDelete = async () => {
        if (toggleConfirm) {
            setDeleting(true);
            const title = `Отложена продажа. Позвонить ${rememberDate.toLocaleDateString('ru')}`;
            const callTimeMin = Math.floor(rememberDate.getTime() / 1000);

            callEventStore.fetchItem(CREATING_ITEM_ID);
            callEventStore.setEditingItem(CREATING_ITEM_ID, {
                item_type: 'contact',
                item_id: contact_id,
                title,
                description: `Позвонить по объекту № ${estate_id}`,
                assign_users_ids: [authStore.currentUser.user_id],
                notificationEnable: true,
                callTimeMin,
                callTimeMax: callTimeMin + 600
            });

            await Promise.all([commentsApi.addComment('ESTATE', estate_id, title), callEventStore.createItem()]);
        }

        await handleDelete(DELETE_STATUS_TEMPORARY);
    };

    const handleDelete = async (deleteStatus: number) => {
        const status = {
            enable: false,
            deleteStatus
        };
        if (deleteStatus === DELETE_STATUS_ALREADY_SOLD && toggleConfirm) {
            status['soldPrice'] = Number(soldPrice);
        }
        estateStore.setEditingItem(estate_id, status);

        setDeleting(true);
        await estateStore.saveItem(estate_id);
        onClose();
    };

    return (
        <Modal header="Удаление объекта" fullScreen={commonStore.isMobile} maxWidth="sm" onClose={onClose} loading={deleting}>
            <ModalContent>
                {!deleteStatus && (
                    <>
                        <Typography variant="subtitle1">По какой причине вы удаляете объект?</Typography>

                        <List>
                            <ListItem button onClick={() => setDeleteStatus(DELETE_STATUS_TEMPORARY)}>
                                <ListItemIcon>
                                    <SnoozeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Снят с продажи на время" />
                            </ListItem>
                            <ListItem button onClick={() => setDeleteStatus(DELETE_STATUS_ALREADY_SOLD)}>
                                <ListItemIcon>
                                    <AccountBalanceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Объект уже продан" />
                            </ListItem>
                            <ListItem button onClick={() => handleDelete(DELETE_STATUS_CHANGED_DECIDING)}>
                                <ListItemIcon>
                                    <MoneyOffIcon />
                                </ListItemIcon>
                                <ListItemText primary="Собственник передумал продавать" />
                            </ListItem>
                            <ListItem button onClick={() => handleDelete(DELETE_STATUS_MISTAKE)}>
                                <ListItemIcon>
                                    <ErrorOutlineIcon />
                                </ListItemIcon>
                                <ListItemText secondary="Объект был создан по ошибке" />
                            </ListItem>
                        </List>
                    </>
                )}

                {deleteStatus === DELETE_STATUS_ALREADY_SOLD && (
                    <div className={classes.root}>
                        <NumberInput
                            label="За какую цену был продан?"
                            value={soldPrice}
                            min={0}
                            onChange={handleChangePrice}
                            fullWidth={false}
                            extraLabel="руб."
                            disabled={!toggleConfirm}
                        />

                        <FormControlLabel
                            control={<Checkbox checked={toggleConfirm} onChange={handleToggleConfirm} />}
                            label="Я уточнил(а) цену продажи"
                        />
                    </div>
                )}

                {deleteStatus === DELETE_STATUS_TEMPORARY && (
                    <div className={classes.root}>
                        <DatePicker
                            label="Напомнить о звонке?"
                            value={rememberDate}
                            onChange={setCreateDate}
                            mask="__.__.____"
                            renderInput={props => <TextField {...props} helperText="дд.мм.г" />}
                            disabled={!toggleConfirm}
                        />

                        <FormControlLabel
                            control={<Checkbox checked={toggleConfirm} onChange={handleToggleConfirm} />}
                            label="Добавить звонок в календарь"
                        />
                    </div>
                )}
            </ModalContent>

            <ModalActions>
                <Button onClick={onClose} color="secondary">
                    Отменить
                </Button>
                {[DELETE_STATUS_ALREADY_SOLD, DELETE_STATUS_TEMPORARY].includes(deleteStatus) && (
                    <Button
                        variant="outlined"
                        onClick={() => (deleteStatus === DELETE_STATUS_TEMPORARY ? handleTemporaryDelete() : handleDelete(deleteStatus))}
                        color="primary"
                    >
                        {deleteStatus === DELETE_STATUS_TEMPORARY ? `Убрать в архив` : `Подтверждаю, продан`}
                    </Button>
                )}
            </ModalActions>
        </Modal>
    );
};

type EstateToArchiveModalProps = {
    estate_id: number;
    trigger: ReactNode;
};

const EstateToArchiveModalTrigger = ({ estate_id, trigger }: EstateToArchiveModalProps) => {
    const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false);
    const handleToggleOpenBtn = () => {
        setConfirmationPopupIsOpen(!confirmationPopupIsOpen);
    };

    return (
        <>
            <span onClick={handleToggleOpenBtn}>{trigger}</span>
            {confirmationPopupIsOpen && <EstateToArchiveModal estate_id={estate_id} onClose={handleToggleOpenBtn} />}
        </>
    );
};

export default EstateToArchiveModalTrigger;
