import React, { Fragment } from 'react';
import cs from 'classnames';
import { Popup, Icon, Label, SemanticCOLORS } from 'semantic-ui-react';
import { ESTATE_BASE_EXTERNAL, ESTATE_BASE_MLS, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import { ESTATE_BASES, MlsAgency } from '~/types/estate.types';
import { matchSourceUrl } from '../../../Items/Estate/ShowBlocks/ExternalBlockShow';
import { EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';

const EstateTableBase = ({
    base,
    agency,
    estate_id,
    exportingBaseCount,
    owner_source_id,
    isFake,
    exclusiveDealId,
    isTop3,
    source,
    sourceInnerId,
    agencyName,
    exportingErrorsCount,
    enable,
    lastOutgoingCallToOwnerTime
}: {
    base: ESTATE_BASES;
    agency?: MlsAgency;
    estate_id: number;
    exportingBaseCount?: number;
    owner_source_id?: number;
    isFake?: boolean | null;
    exclusiveDealId?: number | null;
    isTop3?: boolean | null;
    source?: string | null;
    sourceInnerId?: string | null;
    agencyName?: string | null;
    exportingErrorsCount?: number;
    enable: boolean;
    lastOutgoingCallToOwnerTime: number | null;
}) => {
    if (base === ESTATE_BASE_EXTERNAL) {
        return (
            <Popup
                trigger={
                    <span className="crm-Estate__mlsIcon">
                        <Icon name="database" color="red" />
                        <div>{agencyName || source}</div>
                    </span>
                }
                content={
                    <div>
                        {agencyName}
                        <br />
                        <b>Источник: </b>
                        <a
                            title="Открыть в новом окне ссылку на объявление"
                            target="_blank"
                            rel="noreferrer"
                            href={matchSourceUrl(source, sourceInnerId)}
                        >
                            {source} <Icon name="external" />
                        </a>
                    </div>
                }
                size="tiny"
                hoverable
            />
        );
    }

    if (base === ESTATE_BASE_MLS && agency) {
        return (
            <Popup
                trigger={
                    <span className="crm-Estate__mlsIcon">
                        <Icon name="suitcase" color={agency.color as SemanticCOLORS} />
                        {agency.shortTitle}
                    </span>
                }
                content={`МЛС. ${agency.title}`}
                size="tiny"
            />
        );
    }
    if (base === ESTATE_BASE_OWNERS) {
        return (
            <Popup
                trigger={
                    <span className="crm-Estate__mlsIcon">
                        <Icon name="male" color="brown" />
                        Собств.
                    </span>
                }
                content="Собственник"
                size="tiny"
            />
        );
    }

    const isExporting = enable && Boolean(exportingBaseCount);
    const exportHasBaned = isExporting && matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake) === EXPIRED_CALL_STATUS_ERROR;
    const exportHasWarned = isExporting && matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake) === EXPIRED_CALL_STATUS_WARNING;

    return (
        <Fragment>
            <span
                title={isFake ? 'Фейк' : exclusiveDealId ? 'Эксклюзив' : null}
                className={cs({ List__monoField: true, 'crm-Estate__fakeLink': isFake, 'crm-Estate__exclusiveLink': exclusiveDealId })}
            >
                {estate_id}
            </span>
            &nbsp;
            {isExporting && (
                <Fragment>
                    {exportHasWarned && (
                        <Popup
                            wide
                            trigger={<Icon name="warning sign" color="yellow" />}
                            size="small"
                            content="Давно нет звонка с Продавцом (Посредником)"
                        />
                    )}
                    <Popup
                        wide
                        trigger={
                            <span className="crm-Estate__mlsIcon">
                                {!exportHasBaned && (
                                    <Label size="mini" circular color={Number(exportingBaseCount) > 6 ? 'green' : 'orange'}>
                                        {exportingBaseCount}
                                    </Label>
                                )}
                                {exportHasBaned && <Icon name="warning sign" />}
                                {isTop3 && <Icon name="trophy" color="yellow" />}
                                {exportingErrorsCount > 0 && <Icon name="warning circle" color="red" />}
                            </span>
                        }
                        size="small"
                        content={
                            exportHasBaned
                                ? `У этого объекта в скором веремени будет отключен экспорт, т.к. давно не было звонка или сообщений в WhatsApp с Продавцом (Посредником)`
                                : `${exportingErrorsCount ? `Ошибок экспорта: ${exportingErrorsCount}` : 'Во сколько баз экспортируется'}
                                ${isTop3 ? '; ТОП-3 Циан' : ''}`
                        }
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default EstateTableBase;
