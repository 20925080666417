import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Button, Placeholder } from 'semantic-ui-react';
import moment from 'moment';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import taskStore from '~/stores/taskStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import TaskDeadLineEditing from '../EditBlocks/TaskDeadLineEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import authStore from '~/stores/authStore';

@observer
class TaskDeadLineShow extends Component<EditingWrappedProps, { loadingStatus: boolean }> {
    state = {
        loadingStatus: false
    };

    handleStatus = async (status: number) => {
        this.setState({
            loadingStatus: true
        });

        await taskStore.changeStatus(this.props.item_id, status);

        this.setState({
            loadingStatus: false
        });
    };

    canChangeStatus = (major_user_id: number, assign_users_ids: Array<number>, status: number) => {
        let canChangeStatus;

        if (status === 1 || status === 2) {
            if (assign_users_ids && assign_users_ids.includes(authStore.currentUser.user_id)) {
                canChangeStatus = true;
            }
        } else if (status === 3) {
            canChangeStatus = authStore.canEdit(taskStore.moduleName, major_user_id);
        }

        return canChangeStatus;
    };

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = taskStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { deadLine, createTime, needApproval, status, major_user_id, assign_users_ids, finishTime } = item;
        const { loadingStatus } = this.state;

        const canChangeStatus = this.canChangeStatus(major_user_id, assign_users_ids, status);

        return (
            <Fragment>
                <Card fluid color="yellow">
                    <Card.Content>
                        {!editing && (
                            <Fragment>
                                <Header size="tiny">Статус</Header>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>Крайний срок</Grid.Column>
                                        <Grid.Column>
                                            <ShadowFragment
                                                shadow={loadingItem}
                                                print={deadLine && deadLine > 0 ? moment(deadLine * 1000).calendar() : 'нет'}
                                            />
                                            {status < 4 && needApproval && (
                                                <b className="crm-Estate__fieldNowrap"> требует подтверждения</b>
                                            )}
                                        </Grid.Column>
                                        <Grid.Column>Создана</Grid.Column>
                                        <Grid.Column>
                                            <ShadowFragment shadow={loadingItem} print={moment(createTime * 1000).calendar()} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {finishTime && (
                                        <Grid.Row>
                                            <Grid.Column>Завершена</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment shadow={loadingItem} print={moment(finishTime * 1000).calendar()} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Fragment>
                        )}
                        {editing && <TaskDeadLineEditing item_id={item_id} {...item} />}
                    </Card.Content>
                </Card>

                {canChangeStatus && (
                    <div className="crm-Item__addMode_buttons">
                        {status === 2 && (
                            <Button loading={loadingStatus} className="crm-Item__addMode_button" onClick={this.handleStatus.bind(this, 1)}>
                                Отложить
                            </Button>
                        )}
                        {status === 1 && (
                            <Button
                                className="crm-Item__addMode_button"
                                primary
                                loading={loadingStatus}
                                disabled={loadingItem}
                                onClick={this.handleStatus.bind(this, 2)}
                            >
                                Приступить
                            </Button>
                        )}
                        {status === 2 && (
                            <Button
                                loading={loadingStatus}
                                color="green"
                                className="crm-Item__addMode_button"
                                onClick={this.handleStatus.bind(this, needApproval ? 3 : 4)}
                            >
                                Завершить
                            </Button>
                        )}

                        {status === 3 && (
                            <Fragment>
                                <Button
                                    loading={loadingStatus}
                                    color="red"
                                    className="crm-Item__addMode_button"
                                    onClick={this.handleStatus.bind(this, 1)}
                                >
                                    Отклонить
                                </Button>
                                <Button
                                    loading={loadingStatus}
                                    className="crm-Item__addMode_button"
                                    primary
                                    onClick={this.handleStatus.bind(this, 4)}
                                >
                                    Подтвердить
                                </Button>
                            </Fragment>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default EditingBlockWrapper(taskStore, TaskDeadLineShow);
