import { observable, action } from 'mobx';
import * as chatApi from '~/api/chatApi';

import { ChatType, ChatFilter, ChatListType, ChatTitle, NotReadMessage, ChatMessage } from '~/types/chat.types';
import authStore from './authStore';
import { nProgressItem } from './helpers/decorators.helpers';
import ListStorePrototype from './prototypes/ListStore.prototype';
import commonStore from './commonStore';
import whatsappStore from '~/stores/whatsappStore';
import wait from '~/common/wait';

// @ts-ignore
class ChatStore extends ListStorePrototype<ChatType, ChatTitle, null, ChatFilter> {
    @observable
    loadingList = true;

    constructor() {
        super('user_id', 'chat', chatApi);

        authStore.registerInitFunc(async () => {
            this.listFilterClear = {
                type: 'users',
                user_id: authStore.currentUser.user_id
            };
            this.clearFilter();

            this.notReadMessagesList = await chatApi.fetchNotReadMessages();
            this.fetchIntervalId = window.setInterval(async () => {
                this.notReadMessagesList = await chatApi.fetchNotReadMessages();
            }, 5000);
        });

        authStore.registerCloseFunc(() => {
            clearInterval(this.fetchIntervalId);
        });
    }

    fetchIntervalId: number;

    loadNextChats = () => {
        this.pageSizeChange(this.pagination.pageSize + commonStore.defaultPageSize);
    };

    @observable
    notReadMessagesList: NotReadMessage[] = [];

    disablePrepareNextList = true;

    @action
    changeFilter<T extends keyof ChatFilter>(what: T, value: ChatFilter[T]) {
        this.pagination.pageSize = commonStore.defaultPageSize;
        this.list = [];
        this.loadingList = true;
        super.changeFilter(what, value);
    }

    @nProgressItem
    @action
    async sendMessage(chat_id: number, chatType: ChatListType, message: string) {
        const { item } = this.getItem(chat_id);

        if (!item) {
            throw Error('Item hasn`t exist');
        }

        if (item.chatType === 'whatsapp') {
            if (await whatsappStore.sendMessageToPhone(item.phone, message)) {
                await wait(500);
                this.fetchItem(chat_id, chatType);
                this.fetchList();
            }
            return;
        }

        const createTime = Math.round(Date.now() / 1000);
        item.messages = item.messages || [];

        const { firstName, lastName, middleName, enable, avatarUrl, user_id } = authStore.currentUser;

        const chatMessage: ChatMessage = {
            chat_id,
            message_id: createTime,
            message,
            sender_id: user_id,
            sender: {
                firstName,
                lastName,
                middleName,
                user_id,
                enable,
                avatarUrl
            },
            messageType: 1,
            createTime,
            deliveryTime: null,
            readTime: null,
            enable: true
        };

        // @ts-ignore
        item.messages.push(chatMessage);

        if (chatType === 'users') {
            await chatApi.sendPrivateMessage(chat_id, message);
        } else if (chatType === 'avito') {
            await chatApi.sendAvitoChatMessage(chat_id, message);
        } else if (chatType === 'cian') {
            await chatApi.sendCianChatMessage(chat_id, message);
        }
        await this.fetchList();
    }

    @action
    readChatMessage = async (message_id: number) => {
        const foundIndex = this.notReadMessagesList.findIndex(message => message.message_id === message_id);
        if (~foundIndex) {
            chatApi.readChatMessage(message_id);
            this.notReadMessagesList.splice(foundIndex, 1);
            this.notReadMessagesList = [...this.notReadMessagesList];
        }
    };

    @action
    readAllNotReadMessages = async () => {
        this.notReadMessagesList = [];
        await chatApi.readAllNotReadMessages();
        this.fetchList();
    };
}

export default new ChatStore();
