import React from 'react';
import wishStore from '~/stores/wishStore';
import Button from '~ui/Button';
import WorkIcon from '@material-ui/icons/Work';

type WishIsNotActuallyButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishTakeToWorkButton = ({ wish_id, disabled }: WishIsNotActuallyButtonProps) => {
    const handleToggleIsAlreadySold = () => {
        wishStore.takeWishToWork(wish_id);
    };

    return (
        <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleToggleIsAlreadySold}
            disabled={disabled}
            startIcon={<WorkIcon />}
        >
            Взять в работу
        </Button>
    );
};

export default WishTakeToWorkButton;
