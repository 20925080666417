import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import './learning.less';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Fab from '@material-ui/core/Fab';
import commonStore from '~/stores/commonStore';

import LearningAddModal from './LearningAddModal';
import LearningMaterialCard from './LearningMaterialCard';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import learningStore from '~/stores/learningStore';
import ListError from '../ListError';
import PerfectScrollbar from 'react-perfect-scrollbar';
import authStore from '~/stores/authStore';
import { ACCESS_READ, ACCESS_SELF } from '~/types/access.types';
import NotEnoughAccess from '../Base/NotEnoughAccess';
import Progress from '../Base/ui/Progress';

import LearningShowModal from '~/components/Learning/LearningShowModal';
import history from '~/history';

const Learning = () => {
    useMemo(() => {
        learningStore.fetchList();
    }, []);

    const [editingId, setEditingModal] = useState<null | number>(null);

    const closeEditingModal = () => setEditingModal(null);
    const openEditingModal = (item_id: number) => setEditingModal(item_id);

    const { learning_id }: { learning_id: string } = useParams();
    const showingId = Number(learning_id) || null;

    const openShowingModal = (item_id: number) => {
        history.push(`/learning/${item_id}`);
    };
    const closeShowingModal = () => {
        history.push(`/learning`);
    };

    const handleDelete = async (item_id: number) => {
        await learningStore.toggleDisableItem(item_id);
        closeEditingModal();
    };

    if (!authStore.matchAccess(learningStore.moduleName, ACCESS_READ, ACCESS_SELF)) {
        return <NotEnoughAccess />;
    }

    const { list, loadingList, listErrors } = learningStore;

    return (
        <Fragment>
            <div className="crm-User">
                <Header as="h3">
                    <Icon name="graduation" />
                    Обучающие материалы
                </Header>

                <div className="crm-List__addButton">
                    {!commonStore.isMobile && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={() => openEditingModal(CREATING_ITEM_ID)}
                            style={{ color: 'white' }}
                        >
                            Добавить
                        </Button>
                    )}

                    {commonStore.isMobile && (
                        <Fab size="small" color="primary" style={{ color: 'white' }} onClick={() => openEditingModal(CREATING_ITEM_ID)}>
                            <AddIcon />
                        </Fab>
                    )}
                </div>
            </div>

            {editingId !== null && <LearningAddModal item_id={editingId} handleClose={closeEditingModal} />}

            <PerfectScrollbar>
                <div className="crm-Segment crm-Learning__materials">
                    <ListError errors={listErrors} />

                    <Progress show={loadingList} size={54} />

                    {!loadingList &&
                        list &&
                        list
                            .filter(({ enable }) => enable)
                            .map(material => (
                                <LearningMaterialCard
                                    key={material.material_id}
                                    learningMaterial={material}
                                    onHandleEdit={() => setEditingModal(material.material_id)}
                                    onHandleDelete={() => handleDelete(material.material_id)}
                                    onOpenModal={() => openShowingModal(material.material_id)}
                                />
                            ))}
                </div>
            </PerfectScrollbar>

            {showingId && !loadingList && (
                <LearningShowModal learningMaterial={learningStore.findById(showingId)} onClose={closeShowingModal} />
            )}
        </Fragment>
    );
};

export default observer(Learning);
