import React, { useState } from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Skeleton from '@material-ui/lab/Skeleton';
import { MessageTitle, WarningMessage } from '~ui/Message';

import { Swiper, SwiperSlide } from 'swiper/react';
import Navigation from 'swiper/modules/navigation/navigation';
import Pagination from 'swiper/modules/pagination/pagination';

import Box from '@material-ui/core/Box';

import PhotoGalleryLightbox from '~/components/Base/Photos/PhotoGalleryLightbox';
import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';

import LifeComplexPhotosEditing from '../EditBlocks/LifeComplexPhotosEditing';
import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';

const LifeComplexPhotosShow = ({ item_id, editing }: EditingWrappedProps) => {
    const [initPhotoIndex, setInitPhoto] = useState<number | null>(null);

    const { item: newbuilding, loadingItem } = lifeComplexStore.getItem(item_id);
    const photoSet = newbuilding?.photos.map(({ filename }) => matchEstateThumbnailPhotoUrl(filename, false, 0)) || [];

    if (editing) {
        return <LifeComplexPhotosEditing newbuilding_id={item_id} photos={newbuilding?.photos} />;
    }

    if (loadingItem) {
        return <Skeleton variant="rect" height={250} />;
    }

    if (!loadingItem && !photoSet.length) {
        return (
            <WarningMessage>
                <MessageTitle>У данного ЖК нет фотографий</MessageTitle>
            </WarningMessage>
        );
    }

    return (
        <Box width="100%" height="calc(400px + 0.5rem)" pt={1}>
            <Swiper
                navigation={true}
                pagination={{
                    clickable: true
                }}
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >
                {photoSet.map(({ src }, index) => (
                    <SwiperSlide
                        key={src}
                        style={{
                            backgroundImage: `url(${src})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            cursor: 'pointer'
                        }}
                        title="Открыть фотографию"
                        onClick={() => setInitPhoto(index)}
                    ></SwiperSlide>
                ))}
            </Swiper>

            {initPhotoIndex !== null && (
                <PhotoGalleryLightbox photoSet={photoSet} initPhotoIndex={initPhotoIndex} onClose={() => setInitPhoto(null)} />
            )}
        </Box>
    );
};

export default EditingBlockWrapper(lifeComplexStore, observer(LifeComplexPhotosShow), null, { disableSwapping: true });
