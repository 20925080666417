import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Item, Icon, List } from 'semantic-ui-react';

import ItemPreviewWrapper, { ItemPreviewWrapperProps } from '../Common/ItemPreview';
import UserLink from '../../Lists/Users/UserLink';
import Time from '../../Base/Time';
import PhonesList from '../../Base/PhonesList';
import userStore from '~/stores/userStore';
import UserAvatar from '~ui/UserAvatar';

@observer
export class UserPreview extends Component<ItemPreviewWrapperProps> {
    render() {
        const { item_id, onImgLoad, handleClose } = this.props;
        const { item } = userStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { avatarUrl, group, appointment, onlineTime, phones, firstName, lastName } = item;
        item.middleName = '';

        return (
            <Item.Group className="crm-Item__preview_vertical">
                <Item>
                    {avatarUrl && <Item.Image onLoad={onImgLoad} size="tiny" src={avatarUrl} />}

                    {!avatarUrl && (
                        <div className="ui tiny image">
                            <UserAvatar variant="rounded" size={120} firstName={firstName} lastName={lastName} />
                        </div>
                    )}

                    <Item.Content>
                        <Item.Header className="crm-Estate__fieldNowrap" onClick={handleClose}>
                            <Icon name="user" />
                            <UserLink user={item} />
                        </Item.Header>

                        <Item.Meta>{appointment}</Item.Meta>
                        {group && group.group_id > 0 && (
                            <Item.Description>
                                <Icon name={group['parent_id'] ? 'users' : 'building'} />
                                {group.name}
                            </Item.Description>
                        )}

                        <Item.Description>
                            <List>
                                <PhonesList phones={[...(phones || []).slice(0, 1)]} />
                            </List>
                        </Item.Description>

                        <Item.Extra>
                            <Time time={onlineTime} status />
                        </Item.Extra>
                    </Item.Content>
                </Item>
            </Item.Group>
        );
    }
}

export default ItemPreviewWrapper(userStore, UserPreview);
