import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, List, Placeholder } from 'semantic-ui-react';
import deepCopy from '../../../../common/deepCopy';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import DealPaymentsEditing from '../EditBlocks/DealPaymentsEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';

@observer
class DealPaymentsShowing extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { fee, feeCurrency, usersFees } = item;

        return (
            <Card fluid color="brown">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Комиссия (Агенты)</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Общая комиссия</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={fee && `${fee.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(feeCurrency)}`}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <List>
                                {usersFees &&
                                    usersFees.map(({ user_id, fee, currency, user }, index) => (
                                        <List.Item key={index}>
                                            <Grid columns={2}>
                                                <Grid.Column verticalAlign="middle">
                                                    {user && <UserLinkWithPreview avatar user={user} />}
                                                </Grid.Column>
                                                <Grid.Column verticalAlign="middle">
                                                    {fee
                                                        ? `${fee.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(currency)}`
                                                        : 'не указано'}
                                                </Grid.Column>
                                            </Grid>
                                        </List.Item>
                                    ))}
                            </List>
                        </Fragment>
                    )}
                    {editing && (
                        <DealPaymentsEditing
                            item_id={item_id}
                            editing={editing}
                            fee={fee}
                            feeCurrency={feeCurrency}
                            usersFees={deepCopy(usersFees)}
                        />
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealPaymentsShowing);
