import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './user.less';
import uiConfig from '~/config/ui.config';

import UserShowMode from './UserShowMode';
import UserAddMode from './UserAddMode';
import UserBalanceMode from './UserBalanceMode';
import UserHistoryMode from './UserHistoryMode';
import UserEstatesMode from './UserEstatesMode';
import UserActionsHistoryMode from './UserActionsHistoryMode';
import UserStatistics from './UserStatistics';

import userStore from '~/stores/userStore';
import calendarStore from '~/stores/calendarStore';

import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';
import { ItemWrapperState } from '../ItemWrapper';
import ItemErrors from '../ItemErrors';
import UserTabMenu from './UserTabMenu';
import authStore from '~/stores/authStore';
import { matchTabLabel, MenuItem } from '../TabMenuWrapper';

import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import HistoryIcon from '@material-ui/icons/History';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import EqualizerIcon from '@material-ui/icons/Equalizer';

const ESTATE_TAB_ID = 'estate';
const BALANCE_TAB_ID = 'balance';
const ACTIONS_TAB_ID = 'actions';
const STAT_TAB_ID = 'statistics';

type UserItemProps = RouteComponentProps<{}> & ItemWrapperState;

@observer
class UserItem extends Component<UserItemProps> {
    render() {
        const { item_id, tabName, param } = this.props;
        const { errors, item, loadingItem } = userStore.getItem(item_id);
        const { enable, documentsCount, estatesCount } = item || {};

        const userTabs: MenuItem[] = [
            { name: 'Профиль', id: '', icon: <PersonIcon /> },
            { name: 'Листинг', id: ESTATE_TAB_ID, label: matchTabLabel(estatesCount), icon: <HomeIcon /> }
        ];

        if (authStore.canRead('balance', item_id)) {
            userTabs.push({
                name: 'Баланс',
                id: BALANCE_TAB_ID,
                icon: <MoneyIcon />
            });
        }

        if (authStore.canRead('statistics', item_id)) {
            userTabs.push({
                name: 'Статистика',
                id: STAT_TAB_ID,
                icon: <EqualizerIcon />
            });
        }

        if (authStore.canRead(calendarStore.moduleName, item_id)) {
            userTabs.push({ name: 'Действия', id: ACTIONS_TAB_ID, icon: <LineStyleIcon /> });
        }

        if (authStore.canEdit(userStore.moduleName, item_id)) {
            userTabs.push({ name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> });
        }

        return (
            <Fragment>
                <UserTabMenu
                    itemTabs={userTabs}
                    item_id={item_id}
                    param={param}
                    tabName={tabName}
                    enable={Boolean(enable)}
                    major_user_id={item_id}
                    documentsCount={documentsCount}
                    owner_id={uiConfig.userShare ? item_id : null}
                />

                <div className="crm-List__sidebar_modalBox">
                    {errors && <ItemErrors errors={errors} tabName={tabName} />}
                    {tabName === TAB_ID_DEFAULT && <UserShowMode loading={loadingItem} user_id={item_id} />}
                    {tabName === ESTATE_TAB_ID && <UserEstatesMode user_id={item_id} />}
                    {tabName === TAB_ID_CREATING && <UserAddMode />}
                    {tabName === BALANCE_TAB_ID && <UserBalanceMode user_id={item_id} />}
                    {tabName === TAB_ID_HISTORY && <UserHistoryMode item_id={item_id} />}
                    {tabName === ACTIONS_TAB_ID && <UserActionsHistoryMode user_id={item_id} />}
                    {tabName === STAT_TAB_ID && <UserStatistics user_id={item_id} />}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(userStore, withRouter(UserItem));
