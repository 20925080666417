import React, { useState } from 'react';
import { observer } from 'mobx-react';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';

import * as newbuildingsApi from '~/api/newbuildingsApi';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { InfoMessage } from '~ui/Message';
import Select from '~ui/Select';
import Button from '~ui/Button';
import Progress from '~ui/Progress';
import ListError from '~/components/ListError';

const LCFeedNewbuildingBuildingEditing = ({
    developer_feed_id,
    domclick_lc_id,
    domclick_building_id
}: {
    developer_feed_id: number;
    domclick_lc_id: number;
    domclick_building_id: number;
}) => {
    const { houses } = developerFeedStore.getItem(developer_feed_id).property;
    const { buildings, newbuilding_id } = developerFeedStore.getDomclickComplex(developer_feed_id, domclick_lc_id);
    const { newbuilding_house_id, title } = buildings.find(building => building.domclick_building_id === domclick_building_id);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const [newbuildingHouseId, setNewbuildingHouseId] = useState<number | null>(newbuilding_house_id || null);

    const handleChange = (_, { value }: { value: number }) => {
        setNewbuildingHouseId(value);
    };

    const handleSave = async (): Promise<void> => {
        try {
            setErrors([]);
            setLoading(true);
            await newbuildingsApi.updateDomclickNewbuildingHouse({
                domclick_lc_id,
                newbuilding_house_id: newbuildingHouseId,
                domclick_house_id: domclick_building_id,
                newbuilding_id,
                name: title,
                enable: true
            });
            await developerFeedStore.checkDomclickFeed(developer_feed_id);
            await developerFeedStore.fetchNewbuildingHouses(developer_feed_id, domclick_lc_id);
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    const housesOptions: DropdownType[] = houses
        ? houses.map(({ newbuilding_house_id, name }) => ({ value: newbuilding_house_id, key: newbuilding_house_id, text: name }))
        : [];

    return (
        <>
            <ListError errors={errors} />
            <InfoMessage header="Сопоставление корпуса" style={{ flex: 1 }}>
                <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                    <Select
                        style={{ minWidth: '300px', background: 'white' }}
                        value={newbuildingHouseId}
                        label="Выберите корпус"
                        options={housesOptions}
                        onChange={handleChange}
                        fullWidth
                    />
                    <Button
                        loading={loading}
                        primary
                        variant="outlined"
                        disabled={!newbuildingHouseId || newbuilding_house_id === newbuildingHouseId}
                        onClick={handleSave}
                    >
                        Сохранить
                    </Button>
                </Box>
            </InfoMessage>
        </>
    );
};

const LCFeedNewbuildingBuilding = ({
    developer_feed_id,
    domclick_lc_id,
    domclick_building_id
}: {
    developer_feed_id: number;
    domclick_lc_id: number;
    domclick_building_id: number;
}) => {
    const { houses, loadingHouses } = developerFeedStore.getItem(developer_feed_id).property;
    const { buildings } = developerFeedStore.getDomclickComplex(developer_feed_id, domclick_lc_id);
    const { title, flatsInFeedCount, newbuilding_house_id } = buildings.find(
        building => building.domclick_building_id === domclick_building_id
    );

    return (
        <Accordion disabled={!houses || loadingHouses}>
            <AccordionSummary expandIcon={loadingHouses ? <Progress size={16} show /> : <ExpandMoreIcon />}>
                <Box>
                    <Typography color={newbuilding_house_id ? 'textPrimary' : 'error'} style={{ fontWeight: 500 }}>
                        {title}
                    </Typography>
                    <Typography variant="caption">Квартир в фиде: {flatsInFeedCount}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <LCFeedNewbuildingBuildingEditing
                    developer_feed_id={developer_feed_id}
                    domclick_lc_id={domclick_lc_id}
                    domclick_building_id={domclick_building_id}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default observer(LCFeedNewbuildingBuilding);
