import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';

import Grid from '~ui/Grid';
import Button from '~ui/Button';
import { Switch } from '~ui/Checkbox';

import settingStore from '~/stores/settingStore';
import { MainExportSettingsConfig, MAIN_EXPORT_SETTINGS_KEY } from '~/types/settings.types';

import ButtonGroup from '~/components/Base/ButtonGroup';
import LoaderAwait from '~/components/Base/LoaderAwait';
import SettingsEditingWrapper, { SettingsEditingWrapperProps } from '~/components/Settings/SettingsEditingWrapper';
import ListError from '~/components/ListError';

const MainExportSettings = ({ handleEditValue, handleSave, handleCheckbox }: SettingsEditingWrapperProps) => {
    const { editingItem, loadingItem, errors } = settingStore.getItem(MAIN_EXPORT_SETTINGS_KEY);

    let settings: MainExportSettingsConfig | null = null;

    if (editingItem?.value) {
        settings = JSON.parse(editingItem?.value);
    }

    let enableOfficePhone = settings?.enableOfficePhone;
    let enableAgentPhone = settings?.enableAgentPhone;
    let firstPhoneToExport = settings?.firstPhoneToExport || 'agent';

    return (
        <div style={{ position: 'relative' }}>
            <LoaderAwait dimmer immediately active={loadingItem} />
            <ListError errors={errors} />

            <Grid spacing={2} stackable justify="center">
                <Grid.Column>
                    <Switch
                        label="Включить телефон агента в экспорт"
                        checked={enableAgentPhone}
                        name="enableAgentPhone"
                        handleChange={handleCheckbox}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        label="Включить общий телефон в экспорт"
                        checked={enableOfficePhone}
                        name="enableOfficePhone"
                        handleChange={handleCheckbox}
                    />
                </Grid.Column>
                {enableOfficePhone && enableAgentPhone && (
                    <>
                        <Grid.Column width={6}>
                            <Typography>Первый номер телефона</Typography>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <ButtonGroup
                                buttonSet={[
                                    ['agent', 'Агента'],
                                    ['office', 'Общий']
                                ]}
                                name="firstPhoneToExport"
                                value={firstPhoneToExport}
                                handleChange={handleEditValue}
                            />
                        </Grid.Column>
                    </>
                )}

                <Grid.Column width={3}>
                    <Button fullWidth secondary variant="outlined" onClick={handleSave}>
                        Сохранить
                    </Button>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default SettingsEditingWrapper(observer(MainExportSettings), MAIN_EXPORT_SETTINGS_KEY);
