import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import estateStore from '~/stores/estateStore';
import { EstatePreviewMap } from './MapView/EstateMapList';

@observer
class EstateMobileCardsView extends Component<{}> {
    render() {
        const { list } = estateStore;

        return (
            <Fragment>
                {list.map(({ estate_id, base }) => (
                    <div key={estate_id} className="crm-Estate__cardView">
                        <EstatePreviewMap base={base} estate_id={estate_id} />
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default EstateMobileCardsView;
