import React, { Component } from 'react';
import { observer } from 'mobx-react';

import cellCallStore from '~/stores/cell/cellCallStore';

import Modal, { ModalContent } from '~ui/Modal';

import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

import CallEstateEditingMode from '~/components/Items/CallEstate/CallEstateEditingMode';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type CallEstateModalProps = HandlerEditingWrappedProps & {
    handleClose: () => void;
    contact_id: number;
    goToContactOnCreate: boolean;
};

@observer
class CallEstateModal extends Component<CallEstateModalProps> {
    render() {
        const { handleClose, item_id, contact_id, goToContactOnCreate } = this.props;

        return (
            <Modal maxWidth="md" onClose={handleClose} header="Звонок по объекту">
                <ModalContent>
                    <CallEstateEditingMode
                        onClose={handleClose}
                        item_id={item_id}
                        contact_id={contact_id || CREATING_ITEM_ID}
                        goToContactOnCreate={goToContactOnCreate}
                    />
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(cellCallStore, CallEstateModal);
