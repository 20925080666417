import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon, Button, Image, Accordion, List } from 'semantic-ui-react';

import commonStore from '~/stores/commonStore';

import history from '../../history';
import authStore from '~/stores/authStore';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Scrollbar from '~/components/Base/Scrollbar';
import UserAvatar from '~ui/UserAvatar';

type LeftMenuMobileState = {
    activeProfile: boolean;
};

@observer
export default class LeftMenuMobile extends React.Component<{ children: ReactNode }, LeftMenuMobileState> {
    state = {
        activeProfile: false
    };

    toggleVisibility = (event: React.SyntheticEvent) => {
        event.preventDefault();
        commonStore.toggleMenu();
    };

    handleToggleExpandedMenu = () => {
        commonStore.toggleExpanded();
    };

    handleToggleProfile = () => {
        this.setState({ activeProfile: !this.state.activeProfile });
    };

    handleGoToMyProfile = (event: React.SyntheticEvent) => {
        this.toggleVisibility(event);
        history.push({
            pathname: `/users/list/profile/${authStore.currentUser.user_id}`,
            state: {
                modal: true
            }
        });
    };

    render() {
        const { activeProfile } = this.state;
        const { showMenu } = commonStore;
        const { currentUser } = authStore;

        return (
            <SwipeableDrawer
                anchor="left"
                open={showMenu}
                onClose={() => commonStore.toggleMenu()}
                onOpen={() => commonStore.toggleMenu()}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
            >
                <Link className="crm-leftMenu__logo" to="/">
                    <span className="crm-leftMenu__logo_item">
                        <img src="/static/brand/brand-min.png" alt="agentbase.ru" />
                    </span>

                    <div className="crm-leftMenu__close">
                        <Button basic icon="chevron left" onClick={this.toggleVisibility} />
                    </div>
                </Link>

                <div className="crm-leftMenu__scrollBoxMobile">
                    <div className="crm-leftMenu__mobileMenu">
                        <div className="crm-leftMenu__mobileMenu_avatar">
                            <UserAvatar src={currentUser.avatarUrl} firstName={currentUser.firstName} lastName={currentUser.lastName} />
                        </div>

                        <Accordion fluid>
                            <Accordion.Title onClick={this.handleToggleProfile} active={activeProfile}>
                                {currentUser.firstName} {currentUser.lastName}
                                <Icon name="dropdown" />
                            </Accordion.Title>
                            <Accordion.Content active={activeProfile}>
                                <List link>
                                    <List.Item as="a" active onClick={this.handleGoToMyProfile}>
                                        Мой профиль
                                    </List.Item>
                                    <List.Item as="a" onClick={authStore.logout.bind(authStore)} active>
                                        Выйти
                                    </List.Item>
                                </List>
                            </Accordion.Content>
                        </Accordion>
                    </div>
                    {this.props.children}
                </div>
            </SwipeableDrawer>
        );
    }
}
