import fetchApi from '~/common/fetchApi';
import { ExportingBaseFeed } from '~/types/exports.types';
import { objectToGraphql } from '~/common/graphql';

export const fetchItemList = async (export_base_id: number): Promise<ExportingBaseFeed[]> => {
    const graphql = `{
        fetchExportingFeeds(export_base_id: ${export_base_id}) {
            feed_id
            export_base_id
            name
            format
            feedurl
            searchParams
            config
        
            createTime
            updateTime
            enable    
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchExportingFeeds: ExportingBaseFeed[] }>(graphql);
    return data.fetchExportingFeeds;
};

export const createFeedInBase = async (export_base_id: number, feed: Partial<ExportingBaseFeed>): Promise<number> => {
    const graphql = `mutation { 
          createFeedInBase(export_base_id: ${export_base_id}, feed: ${objectToGraphql(feed)})
        }`;

    const data = await fetchApi.postGQ<{ createFeedInBase: number }>(graphql);
    return data.createFeedInBase;
};

export const updateListItem = async (
    export_base_id: number,
    feed_id: number,
    editingItem: Partial<ExportingBaseFeed>
): Promise<boolean> => {
    const graphql = `mutation {
        updateFeedInBase(export_base_id: ${export_base_id}, feed_id: ${feed_id}, feed: ${objectToGraphql(editingItem)})
      }`;

    const data = await fetchApi.postGQ<{ updateFeedInBase: boolean }>(graphql);
    return data.updateFeedInBase;
};
