import { observable, action, computed } from 'mobx';
import * as newbuildingsApi from '~/api/newbuildingsApi';
import deepCopy from '~/common/deepCopy';
import debounce from '~/common/debounce';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';

import { LifeComplexApartmentsFilter, NebuildingHouseInfo, Newbuilding } from '~/types/newbuildings.types';
import { HistoryChange } from '~/types/historyChanges.types';
import { EstateTableItem } from '~/types/estate.types';

import ListStorePrototype, { OrderDirectionType, PaginationType } from '~/stores/prototypes/ListStore.prototype';
import isEqual from '~/common/isEqual';

type NewbuildingPropertyType = {
    loadingHistory: boolean;
    history: HistoryChange[];

    loadingApartments: boolean;
    apartments: EstateTableItem[];
    apartmentsErrors: string[];
    apartmentsFilter: LifeComplexApartmentsFilter;
    apartmentsPagination: PaginationType;
    apartmentsCount: number;
    apartmentsOrderBy: string;
    apartmentsOrderDirection: OrderDirectionType;
    debounceChange: () => void;
    apartmentsAC: AbortController | null;

    loadingHousesInfo: boolean;
    housesInfo: NebuildingHouseInfo[];
};

const defaultApartmentsFilter: LifeComplexApartmentsFilter = {
    newbuilding_house_id: null,
    roomsCount: [],
    priceMin: null,
    priceMax: null,
    totalAreaMin: null,
    totalAreaMax: null
};

// const ALL_DEVELOPERS = 'ALL_DEVELOPERS';

class LifeComplexStore extends ListStorePrototype<Newbuilding, Newbuilding, NewbuildingPropertyType, {}> {
    constructor() {
        super('newbuilding_id', 'newbuilding', newbuildingsApi);

        // const memoryListJSON = window.localStorage.getItem(ALL_DEVELOPERS);
        // if (memoryListJSON) {
        //     this.loadingDevelopers = false;
        //     this.developers = JSON.parse(memoryListJSON);
        // }
    }

    @action
    async fetchApartments(newbuilding_id: number): Promise<void> {
        let { apartmentsFilter, apartmentsOrderBy, apartmentsOrderDirection, apartmentsPagination, apartmentsAC } = this.getItem(
            newbuilding_id
        ).property;

        if (apartmentsAC) {
            apartmentsAC.abort();
        }
        apartmentsAC = new AbortController();

        apartmentsPagination = apartmentsPagination || getDefaultPagination();
        const { activePage, pageSize } = apartmentsPagination;
        const offset = (activePage - 1) * pageSize;

        this.setProperty(newbuilding_id, {
            apartmentsAC,
            apartmentsErrors: [],
            loadingApartments: true,
            apartments: [],
            apartmentsPagination
        });

        try {
            const { list, count } = await newbuildingsApi.fetchNewbuildingApartments(
                newbuilding_id,
                pageSize,
                offset,
                apartmentsOrderBy,
                apartmentsOrderDirection,
                apartmentsFilter,
                apartmentsAC
            );

            this.setProperty(newbuilding_id, {
                apartments: list,
                apartmentsPagination: matchPaginationTotalPages(apartmentsPagination, count),
                apartmentsCount: count,
                loadingApartments: false,
                apartmentsAC: null
            });
        } catch (apartmentsErrors) {
            if (!apartmentsErrors[0]?.includes('The user aborted a request')) {
                this.setProperty(newbuilding_id, {
                    apartmentsErrors,
                    apartmentsAC: null,
                    loadingApartments: false
                });
            }
        }
    }

    @action
    async fetchHousesInfo(newbuilding_id: number): Promise<void> {
        this.setProperty(newbuilding_id, { loadingHousesInfo: true, housesInfo: [] });
        const housesInfo = await newbuildingsApi.fetchNewbuildingHousesInfo(newbuilding_id);
        this.setProperty(newbuilding_id, { loadingHousesInfo: false, housesInfo });
    }

    @action
    resetApartmentsFilter(newbuilding_id: number): void {
        this.setProperty(newbuilding_id, {
            apartmentsFilter: deepCopy(defaultApartmentsFilter),
            apartmentsOrderBy: 'roomsCount',
            apartmentsOrderDirection: 'ascending'
        });
    }

    @action
    changeApartmentsFilter<T extends keyof LifeComplexApartmentsFilter>(
        newbuilding_id: number,
        property: T,
        value: LifeComplexApartmentsFilter[T]
    ): void {
        const { apartmentsFilter } = this.getItem(newbuilding_id).property;
        this.setProperty(newbuilding_id, { apartmentsFilter: { ...apartmentsFilter, [property]: value } });

        const debounceChange =
            this.getItem(newbuilding_id).property.debounceChange || debounce(() => this.fetchApartments(newbuilding_id), 500);
        this.setProperty(newbuilding_id, { debounceChange });
        debounceChange();
    }

    @action
    async changeApartmentsPaginationPage(newbuilding_id: number, pageNumber: number): Promise<void> {
        const { apartmentsPagination } = this.getItem(newbuilding_id).property;
        this.setProperty(newbuilding_id, { apartmentsPagination: { ...apartmentsPagination, activePage: pageNumber } });
        this.fetchApartments(newbuilding_id);
    }

    @action
    async changeApartmentsPaginationPageSize(newbuilding_id: number, pageSize: number): Promise<void> {
        this.setProperty(newbuilding_id, { apartmentsPagination: { pageSize: pageSize, activePage: 1, totalPages: 1 } });
        this.fetchApartments(newbuilding_id);
    }

    @action
    clearApartmentsFilter(newbuilding_id: number): void {
        this.resetApartmentsFilter(newbuilding_id);
        this.changeApartmentsPaginationPage(newbuilding_id, 1);
    }

    isApartmentsFilterEmpty(newbuilding_id: number): boolean {
        return isEqual(defaultApartmentsFilter, this.getItem(newbuilding_id).property.apartmentsFilter);
    }

    @action
    handleApartmentsSort(newbuilding_id: number, orderBy: string): void {
        let { apartmentsOrderBy, apartmentsOrderDirection } = this.getItem(newbuilding_id).property;
        if (orderBy === apartmentsOrderBy) {
            apartmentsOrderDirection = apartmentsOrderDirection === 'ascending' ? 'descending' : 'ascending';
        } else {
            apartmentsOrderDirection = 'ascending';
        }
        this.setProperty(newbuilding_id, { apartmentsOrderBy: orderBy, apartmentsOrderDirection });
        this.fetchApartments(newbuilding_id);
    }

    // @observable
    // loadingDevelopers: boolean = true;
    //
    // @observable
    // developers: Developer[] = [];
    //
    // @action
    // async fetchAllDevelopers() {
    //     this.loadingDevelopers = true;
    //     this.developers = await newbuildingsApi.fetchDevelopers();
    //     this.loadingDevelopers = false;
    //     window.localStorage.setItem(ALL_DEVELOPERS, JSON.stringify(this.developers));
    // }
    //
    // @action
    // async createDeveloper(name: string, logoUrl: string): Promise<number> {
    //     const developer_id = await newbuildingsApi.createDeveloper({ name, logoUrl });
    //     this.fetchAllDevelopers();
    //     return developer_id;
    // }
}

export default new LifeComplexStore();
