import React, { useState, useEffect, ReactNode } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as dadataApi from '~/api/dadataApi';
import {
    DaDataAddress,
    DaDataAddressSuggestion,
    DaDataFio,
    DaDataFmsUnit,
    DaDataFmsUnitSuggestion,
    DaDataNameSuggestion,
    DaDataParty,
    DaDataPartySuggestion
} from '~/types/dadata.types';
import { HandleChangeType } from '~/components/Base/EditField';

const SUGGESTIONS_COUNT = 7;

type DadataTypes = {
    NAME: DaDataFio;
    SURNAME: DaDataFio;
    PATRONYMIC: DaDataFio;
    ADDRESS: DaDataAddress;
    FMS_UNIT: DaDataFmsUnit;
    PARTY: DaDataParty;
};

type DataInputType = keyof DadataTypes;

async function fetchDaData(query: string, type: 'ADDRESS'): Promise<DaDataAddressSuggestion[]>;
async function fetchDaData(query: string, type: 'FMS_UNIT'): Promise<DaDataFmsUnitSuggestion[]>;
async function fetchDaData(query: string, type: 'PARTY'): Promise<DaDataPartySuggestion[]>;
async function fetchDaData(query: string, type: 'SURNAME' | 'NAME' | 'PATRONYMIC'): Promise<DaDataNameSuggestion[]>;
async function fetchDaData(
    query: string,
    type: DataInputType
): Promise<(DaDataNameSuggestion | DaDataAddressSuggestion | DaDataFmsUnitSuggestion | DaDataPartySuggestion)[]> {
    switch (type) {
        case 'ADDRESS':
            return await dadataApi.fetchAddressSuggestions(query, SUGGESTIONS_COUNT);
        case 'SURNAME':
        case 'NAME':
        case 'PATRONYMIC':
            return await dadataApi.fetchNamesSuggestions(query, SUGGESTIONS_COUNT, [type]);
        case 'FMS_UNIT':
            return await dadataApi.fetchFmsUnitSuggestions(query, SUGGESTIONS_COUNT);
        case 'PARTY':
            return await dadataApi.fetchPartySuggestions(query, SUGGESTIONS_COUNT);
        default:
            const error: never = type;
    }
}

type OptionType<T> = {
    value: string;
    data: T;
};

async function getDaDataOptions(query: string, type: 'ADDRESS'): Promise<OptionType<DaDataAddress>[]>;
async function getDaDataOptions(query: string, type: 'FMS_UNIT'): Promise<OptionType<DaDataFmsUnit>[]>;
async function getDaDataOptions(query: string, type: 'PARTY'): Promise<OptionType<DaDataParty>[]>;
async function getDaDataOptions(query: string, type: 'SURNAME' | 'NAME' | 'PATRONYMIC'): Promise<OptionType<DaDataFio>[]>;
async function getDaDataOptions(
    query: string,
    type: DataInputType
): Promise<OptionType<DaDataAddress | DaDataFmsUnit | DaDataFio | DaDataParty>[]> {
    switch (type) {
        case 'ADDRESS':
            return (await fetchDaData(query, type)).map(({ value, data }) => ({ value, data }));
        case 'SURNAME':
            return (await fetchDaData(query, type)).map(({ data }) => ({ value: data.surname, data }));
        case 'NAME':
            return (await fetchDaData(query, type)).map(({ data }) => ({ value: data.name, data }));
        case 'PATRONYMIC':
            return (await fetchDaData(query, type)).map(({ data }) => ({ value: data.patronymic, data }));
        case 'FMS_UNIT':
            return (await fetchDaData(query, type)).map(({ data }) => ({ value: data.name, data }));
        case 'PARTY':
            return (await fetchDaData(query, type)).map(({ value, data }) => ({ value, data }));
        default:
            const error: never = type;
    }
}

const renderSimple = ({ value }: { value: string }) => value;

const renderFmsUnit = ({ value, data }: OptionType<DaDataFmsUnit>) => {
    return (
        <div style={{ borderBottom: '1px solid #eee', width: '100%' }}>
            <div>{value}</div>
            <div>
                <i>{data.code}</i>
            </div>
        </div>
    );
};

const renderParty = ({ value, data }: OptionType<DaDataParty>) => (
    <div style={{ borderBottom: '1px solid #eee', width: '100%' }}>
        <div>{value}</div>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100%' }}>
            <i>{data?.inn}</i>
            <span style={{ paddingLeft: '1rem' }}>{data?.address.value}</span>
        </div>
    </div>
);

const renderDaDataOption = <T extends DataInputType>(type: T): ((data: OptionType<DadataTypes[T]>) => ReactNode) => {
    switch (type) {
        case 'SURNAME':
        case 'NAME':
        case 'PATRONYMIC':
        case 'ADDRESS':
            return renderSimple;
        case 'PARTY':
            // @ts-ignore
            return renderParty;
        case 'FMS_UNIT':
            // @ts-ignore
            return renderFmsUnit;
    }
};

type TextInputDadataProps<T extends DataInputType> = {
    label: string;
    value: string;
    name: string;
    onChange: HandleChangeType;
    type: T;
    onDaDataSelect?: (data: DadataTypes[T]) => void;
    helperText?: string;
    variant?: 'standard' | 'outlined' | 'classic' | 'filled';
    size?: 'medium' | 'small';
};

const TextInputDadata = <T extends DataInputType>({
    type,
    label,
    name,
    value,
    onChange,
    helperText,
    onDaDataSelect,
    variant,
    size
}: TextInputDadataProps<T>) => {
    const [skipLoading, setSkip] = useState(false);

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event, { type: 'text', name, value: event.target.value });
    };

    const [options, setOptions] = useState<OptionType<DadataTypes[T]>[]>([]);

    useEffect(() => {
        if (value && !skipLoading) {
            // @ts-ignore
            getDaDataOptions(value, type).then((options: OptionType<DadataTypes[T]>[]) => {
                setOptions(options);
            });
        } else if (!skipLoading) {
            setOptions([]);
        } else {
            setSkip(false);
        }
    }, [value]);

    const onAutocompleteChange = (event, { value, data }: OptionType<DadataTypes[T]>, reason) => {
        onChange(event, { type: 'text', name, value });
        setSkip(true);
        if (onDaDataSelect) {
            onDaDataSelect(data);
        }
    };

    return (
        <Autocomplete
            freeSolo
            disableClearable
            options={options}
            filterOptions={a => a}
            getOptionLabel={({ value }) => value}
            onChange={onAutocompleteChange}
            renderOption={renderDaDataOption(type) as any}
            renderInput={params => (
                <TextField
                    onChange={handleChangeValue}
                    label={label}
                    variant={variant === 'classic' ? 'outlined' : variant || 'standard'}
                    fullWidth
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    multiline
                    rowsMax={4}
                    helperText={helperText}
                    inputProps={{ ...params.inputProps, value: value || '' }}
                    style={variant === 'classic' ? { background: 'white' } : null}
                    size={size || 'medium'}
                />
            )}
        />
    );
};

export default React.memo(TextInputDadata);
