import { objectToGraphql } from '~/common/graphql';
import fetchApi from '~/common/fetchApi';
import { TExpiredCalls } from '~/types/expiredCalls.types';
import settingStore from '~/stores/settingStore';
import { DAY_SEC } from '~/common/time';

export const EXPIRED_CALL_STATUS_GOOD = 1;
export const EXPIRED_CALL_STATUS_WARNING = 2;
export const EXPIRED_CALL_STATUS_ERROR = 3;

export const matchExpiredCallStatus = (lastCallTimeMs: number, isFake: boolean | null = null): 1 | 2 | 3 => {
    if (isFake) {
        return EXPIRED_CALL_STATUS_GOOD;
    }

    const config = settingStore.mainConfig;

    const { enableExpiredCalls, lastOutgoingCallTimesBan, lastOutgoingCallTimesWarning } = config;
    if (!enableExpiredCalls) {
        return EXPIRED_CALL_STATUS_GOOD;
    }

    const currentTime = Date.now();
    const deltaCallTime = (currentTime - lastCallTimeMs) / 1000;

    if (!lastOutgoingCallTimesWarning && !lastOutgoingCallTimesBan) {
        return EXPIRED_CALL_STATUS_GOOD;
    }

    if (deltaCallTime < lastOutgoingCallTimesWarning * DAY_SEC) {
        return EXPIRED_CALL_STATUS_GOOD;
    } else if (deltaCallTime < lastOutgoingCallTimesBan * DAY_SEC) {
        return EXPIRED_CALL_STATUS_WARNING;
    }

    return EXPIRED_CALL_STATUS_ERROR;
};

const GQExpiredCallsFields = `
    expired_call_id
    contact_id
    major_user_id
    assign_user_id

    reason
    callTime

    createTime
    updateTime
    enable 
`;

export const createItem = async (expiredCallInput: TExpiredCalls): Promise<number> => {
    const graphql = `mutation { 
          createExpiredCall(expiredCallInput: ${objectToGraphql(expiredCallInput)})
        }`;

    const data: { createExpiredCall: number } = await fetchApi.postGQ(graphql);
    return data.createExpiredCall;
};
