import React, { useState } from 'react';
import { observer } from 'mobx-react';

import ListTabsEditingModal from '~/components/PultToolbar/Common/CreateItemModal/ListTabsEditingModal';
import CreateItemModal from '~/components/PultToolbar/Common/CreateItemModal';
import kpiStore from '~/stores/kpiStore';

import KpiItemListEditing from './KpiItemListEditing';

const KpiModal = ({ onClose }: { onClose: () => void }) => {
    const { list: kpis } = kpiStore;

    const [activeKpiId, setActiveKpiId] = useState(kpis[0]?.kpi_id || 0);

    const [showCreateModal, setCreateModal] = useState(false);
    const toggleCreateModal = () => {
        setCreateModal(!showCreateModal);
    };

    return (
        <ListTabsEditingModal
            maxWidth="md"
            header="Настройка коэффициентов Kpi"
            onClose={onClose}
            onCreate={toggleCreateModal}
            tabs={kpis.map(({ title, kpi_id }) => ({ title, id: kpi_id }))}
            tabId={activeKpiId}
            onChangeTabId={setActiveKpiId}
        >
            {activeKpiId > 0 && <KpiItemListEditing kpi_id={activeKpiId} />}
            {showCreateModal && (
                <CreateItemModal store={kpiStore} onClose={toggleCreateModal} header="Добавить kpi" text="Введите название нового Kpi" />
            )}
        </ListTabsEditingModal>
    );
};

export default observer(KpiModal);
