import React from 'react';
import { Link } from 'react-router-dom';
import { TaskLinkType } from '~/types/tasks.types';
import moment from 'moment';

type TaskLinkProps = {
    task: TaskLinkType;
};

export const TaskLinkState = (task_id: number) => ({
    pathname: `/tasks/list/overview/${task_id}`,
    state: { modal: true, switchFromTab: false }
});

const TaskLink = ({ task: { task_id, createTime } }: TaskLinkProps) => {
    return (
        <Link to={TaskLinkState(task_id)}>
            Задача №{task_id} ({moment(createTime * 1000).calendar()})
        </Link>
    );
};

export default TaskLink;
