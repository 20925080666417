import React, { Component, Fragment } from 'react';
import { Menu, Table, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import PreventDblClick from '../../Base/PreventDblClick';
import WishTableBody from '../../Lists/Wishes/WishTableBody';
import debounce from '../../../common/debounce';
import ApproximationInput from '../Common/ApproximationInput';
import { DEFAULT_APPROXIMATION_RANGE } from '~/stores/wishStore';
import bottomTabStore from '~/stores/bottomTabStore';
import history from '../../../history';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import ownerStore from '~/stores/ownerStore';
import LoaderAwait from '../../Base/LoaderAwait';
import ButtonGroup from '../../Base/ButtonGroup';
import { WarningMessage } from '~ui/Message';

type EstateWishesModeProps = {
    estate_id: number;
    base: string;
};

type EstateWishesModeState = {
    approximation: number;
};

@observer
class EstateWishesMode extends Component<EstateWishesModeProps, EstateWishesModeState> {
    debounceWishes: () => void;

    constructor(props: EstateWishesModeProps) {
        super(props);

        this.state = {
            approximation: DEFAULT_APPROXIMATION_RANGE
        };

        this.debounceWishes = debounce(() => estateStore.wishesByEstate(this.props.estate_id, this.state.approximation), 350);
    }

    handleChageApproximation = (approximation: number) => {
        this.setState({ approximation });
        this.debounceWishes();
    };

    handleSetWishFilter = () => {
        const { estate_id } = this.props;
        const { item } = estateStore.getItem(estate_id);
        estateStore.setWishFilterFromEstate(estate_id, this.state.approximation);

        if (item) {
            const { address, type, propertyType, enable, base } = item;
            const roomsCount = item.roomsCount || null;

            bottomTabStore.addTab({
                item_id: estate_id,
                item_type: base === ESTATE_BASE_MAIN ? estateStore.moduleName : ownerStore.moduleName,
                item: { estate_id, address, type, roomsCount, propertyType, enable }
            });
        }

        history.push({
            pathname: `/wishes/list`
        });
    };

    handleChangeLocationType = (event: React.SyntheticEvent, { value: wishesLocationType }: { value: number }) => {
        estateStore.setProperty(this.props.estate_id, { wishesLocationType });
        this.debounceWishes();
    };

    render() {
        const { estate_id } = this.props;
        const { approximation } = this.state;
        const { wishes, loadingWishes, wishesLocationType } = estateStore.getItem(estate_id).property;

        return (
            <PreventDblClick>
                <ApproximationInput approximation={approximation} onChange={this.handleChageApproximation}>
                    <Menu.Item className="crm-List__filter_dropdown">
                        <ButtonGroup
                            disabled={!wishesLocationType}
                            buttonSet={[
                                [1, 'По метро'],
                                [2, 'По городу']
                            ]}
                            name="locationType"
                            value={wishesLocationType}
                            handleChange={this.handleChangeLocationType}
                        />
                    </Menu.Item>
                </ApproximationInput>

                <LoaderAwait active={loadingWishes} size="large" />
                <Table structured size="small" sortable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Комнат</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Метро</Table.HeaderCell>
                            <Table.HeaderCell>Площадь</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {wishes && <WishTableBody wishList={wishes} shortMode={true} />}
                </Table>

                {!loadingWishes && wishes && wishes.length === 0 && (
                    <WarningMessage>Не получилось подобрать покупателей. Попробуйте увеличить уровень погрешности поиска.</WarningMessage>
                )}

                <Button
                    icon="search"
                    loading={!wishes || loadingWishes}
                    className="crm-Item__addMode_button"
                    primary
                    onClick={this.handleSetWishFilter}
                    size="tiny"
                    content="Открыть в поиске Покупателей"
                />
            </PreventDblClick>
        );
    }
}

export default EstateWishesMode;
