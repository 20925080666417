import React from 'react';
import { Link } from 'react-router-dom';
import { CallEventLinkType } from '~/types/events.types';
import { Icon } from 'semantic-ui-react';
import Time from '../../Base/Time';

type CellCallProps = {
    callEvent: CallEventLinkType;
    hideIcon?: boolean;
};

export const CallEventLinkState = (call_event_id: number) => ({
    pathname: `/modal/events/callEvent/${call_event_id}`,
    state: { modal: true, dialog: true }
});

const CallEventLink = ({ callEvent: { call_event_id, callTimeMin, callHasReleased, callTimeMax }, hideIcon }: CellCallProps) => {
    const eventReleased = callHasReleased === true;
    const eventExceed = callHasReleased === false && callTimeMax * 1000 < Date.now();

    return (
        <Link to={CallEventLinkState(call_event_id)} className="crm-Estate__fieldNowrap">
            {!hideIcon && <Icon name="phone" color={eventReleased ? 'green' : eventExceed ? 'red' : 'grey'} />}
            <Time time={callTimeMin} />
        </Link>
    );
};

export default CallEventLink;
