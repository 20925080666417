import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Grid from '~ui/Grid';
import { NumberInput, PriceInput } from '~ui/TextInput';
import { calcPayment } from '~/common/mortgageCalc';
import estateStore from '~/stores/estateStore';
import ItemDropdown from '~/components/Items/Common/ItemDropdow';
import ButtonGroup from '~/components/Base/ButtonGroup';

const useStyles = makeStyles(({ spacing }) => ({
    inputs: {
        '& > *': {
            marginBottom: spacing(2)
        }
    }
}));

const matchPeriodMonth = (period: number, periodType: number): number => {
    return periodType === 1 ? period : 12 * period;
};

const MortgageDemoCalculator = () => {
    const classes = useStyles();

    const [totalSum, setTotalSum] = useState(0);
    const handleChangeTotalSum = (event, { value }: { value: number }) => {
        setTotalSum(value);
        if (value - firstSum > 0) {
            changeMortgageSum(value - firstSum);
        }
    };

    const [firstSum, setFirstSum] = useState(0);
    const changeFirstSum = (value: number) => {
        setFirstSum(value);
        if (totalSum - value > 0) {
            changeMortgageSum(totalSum - value);
        }
    };
    const handleChangeFirstSum = (event, { value }: { value: number }) => {
        changeFirstSum(value);
        setFirstSumPerc(Math.floor((100 * value) / totalSum));
    };

    const [firstSumPerc, setFirstSumPerc] = useState(0);
    const handleChangeFirstSumPerc = (event, { value }: { value: number }) => {
        changeFirstSum(Math.floor((totalSum * value) / 100));
        setFirstSumPerc(value);
    };

    const [mortgageSum, setMortgageSum] = useState(0);
    const changeMortgageSum = (sum: number) => {
        setMortgageSum(sum);
        setMonthlyPayment(calcPayment(sum, matchPeriodMonth(period, periodType), percent));
    };
    const handleChangeMortgageSum = (event, { value }: { value: number }) => {
        changeMortgageSum(value);
        if (totalSum - value > 0) {
            setFirstSum(totalSum - value);
        }
    };

    const [percent, setPercent] = useState(6.5);
    const handleChangePercent = (event, { value }: { value: number }) => {
        setPercent(value);
        setMonthlyPayment(calcPayment(mortgageSum, matchPeriodMonth(period, periodType), value));
    };

    const [period, setPeriod] = useState(20);
    const handleChangePeriodMonth = (event, { value }: { value: number }) => {
        setPeriod(value);
        setMonthlyPayment(calcPayment(mortgageSum, matchPeriodMonth(value, periodType), percent));
    };

    const [periodType, setPeriodType] = useState(0);
    const togglePaymentType = () => {
        setPeriodType(1 - periodType);
        setPeriod(periodType === 0 ? period * 12 : Math.floor(period / 12));
    };

    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const totalPayments = Math.ceil(monthlyPayment * matchPeriodMonth(period, periodType));

    const [estate_id, setEstateId] = useState<null | number>(null);
    const handleChangeEstateId = (event, { value }: { value: number }) => {
        estateStore.fetchItem(value).then(() => {
            const { price } = estateStore.getItem(value).item;
            setTotalSum(price);
            changeMortgageSum(price - firstSum);
        });
        setEstateId(value);
    };

    return (
        <Container maxWidth="sm">
            <ItemDropdown
                name="estate_id"
                store={estateStore}
                item_id={estate_id}
                onChange={handleChangeEstateId}
                placeholder="Выбрать объект"
                noResultsMessage="Объект не найден"
            />

            <Grid columns={2} alignItems="flex-start">
                <Grid.Column className={classes.inputs}>
                    <PriceInput fullWidth label="Стоимость объекта" value={totalSum} onChange={handleChangeTotalSum} />
                    <PriceInput fullWidth label="Первоначальный взнос" value={firstSum} onChange={handleChangeFirstSum} />
                    <NumberInput
                        fullWidth
                        label="Первоначальный взнос, %"
                        value={firstSumPerc}
                        extraLabel="%"
                        onChange={handleChangeFirstSumPerc}
                    />
                    <PriceInput fullWidth label="Сумма кредита" value={mortgageSum} onChange={handleChangeMortgageSum} />
                    <NumberInput
                        fullWidth
                        label="Процентная ставка"
                        value={percent}
                        min={0.1}
                        step={0.1}
                        extraLabel="%"
                        onChange={handleChangePercent}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NumberInput fullWidth label="Срок займа" value={period} min={0} step={1} onChange={handleChangePeriodMonth} />
                        <ButtonGroup
                            size="small"
                            value={periodType}
                            buttonSet={[
                                [0, 'лет'],
                                [1, 'мес.']
                            ]}
                            handleChange={togglePaymentType}
                        />
                    </div>
                </Grid.Column>
                <Grid.Column className={classes.inputs}>
                    <PriceInput variant="outlined" fullWidth label="Ежемесячный платеж" value={monthlyPayment} />
                    <PriceInput variant="outlined" fullWidth label="Общая сумма выплат" value={totalPayments} />
                    <PriceInput variant="outlined" fullWidth label="Общая сумма переплаты" value={totalPayments - mortgageSum} />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default MortgageDemoCalculator;
