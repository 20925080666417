import { objectToGraphql } from '~/common/graphql';
import fetchApi from '../../common/fetchApi';
import { MortgagePerson } from '~/types/mortgageRequest.types';

const GQMortgagePerson = `
    mortgage_person_id
    mortgage_request_id
    
    person_type
    surname
    name
    patronymic
    email
    tax_number
    insurance_number

    passport_series
    passport_number
    birthday
    issued_at
    gender_cid
    birth_place
    issuer_code
    issued_by_name

    registration_address
    actual_address
    reason_for_living
    family_cid
    education_cid
    children_count
    total_seniority
    previous_surname
    previous_name
    previous_patronymic
    
    main_job_id
    jobs_ids
    mobile_phones
    
    createTime
    updateTime
    enable   
`;

export const fetchItem = async (mortgage_person_id: number): Promise<MortgagePerson> => {
    const graphql = `{
        fetchMortgagePerson(mortgage_person_id: ${mortgage_person_id}) {
            ${GQMortgagePerson}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchMortgagePerson: MortgagePerson }>(graphql);
    return data.fetchMortgagePerson;
};

export const createItem = async (mortgagePersonInput: Partial<MortgagePerson>): Promise<number> => {
    const graphql = `mutation { 
          createMortgagePerson(mortgagePersonInput: ${objectToGraphql(mortgagePersonInput)})
        }`;

    const data = await fetchApi.postGQ<{ createMortgagePerson: number }>(graphql);
    return data.createMortgagePerson;
};

export const saveItem = async (mortgage_person_id: number, mortgagePersonInput: MortgagePerson): Promise<number> => {
    const graphql = `mutation { 
        updateMortgagePerson(mortgage_person_id: ${mortgage_person_id}, mortgagePersonInput: ${objectToGraphql(mortgagePersonInput)})
    }`;

    const data = await fetchApi.postGQ<{ updateMortgagePerson: number }>(graphql);
    return data.updateMortgagePerson;
};
