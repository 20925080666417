import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown, Form } from 'semantic-ui-react';
import Checkbox from '~ui/Checkbox';

import UsersAndGroupsDropdown from '~/components/Lists/Common/UsersAndGroupsDropdown';

import BoardsSettingsModal from './BoardsSettingsModal';
import ContactsBoard from './ContactsBoard';
import kanbanStore from '~/stores/kanbanStore';
import tagStore from '~/stores/tagStore';

import TopPultWithButton from '../Common/TopPultWithButton';

const useStyles = makeStyles(({ spacing }) => ({
    marginLeft: {
        marginLeft: '1rem'
    }
}));

const ContactsKanban = () => {
    const classes = useStyles({});

    const {
        list: boards,
        listFilter: { board_id, tags_ids, tagsTogether }
    } = kanbanStore;

    const setActiveBoardId = (board_id: number | null) => {
        kanbanStore.changeFilter('board_id', board_id);
    };

    useMemo(() => {
        if (boards.length > 0) {
            setTimeout(() => {
                setActiveBoardId(boards[0].board_id);
            }, 0);
        }
    }, [boards.length]);

    const { allTagsDropdown, loadingFetchAllTags } = tagStore;

    const handleTagsIds = (event: React.SyntheticEvent, { value }: { value: number[] }) => {
        kanbanStore.changeFilter('tags_ids', value);
    };

    const handleTagsTogether = () => {
        kanbanStore.changeFilter('tagsTogether', !tagsTogether);
    };

    return (
        <TopPultWithButton
            store={kanbanStore}
            ModalNode={BoardsSettingsModal}
            PultFilter={
                <>
                    <UsersAndGroupsDropdown store={kanbanStore} shortWidth />

                    <Form size="mini" className={classes.marginLeft}>
                        <Dropdown
                            multiple={true}
                            selection
                            options={allTagsDropdown}
                            search
                            placeholder="Тэги контактов"
                            value={tags_ids}
                            noResultsMessage="Тэг не найден"
                            onChange={handleTagsIds}
                            loading={loadingFetchAllTags}
                        />
                    </Form>

                    <span className={classes.marginLeft}>
                        <Checkbox name="tagsTogether" checked={tagsTogether} label="тэги вместе" onChange={handleTagsTogether} />
                    </span>
                </>
            }
            toggleButtons={boards.map(({ title, board_id }) => ({ title, id: board_id }))}
            activeToggleId={board_id}
            changeToggleId={setActiveBoardId}
        >
            {boards.length > 0 && board_id > 0 && <ContactsBoard board_id={board_id} />}
        </TopPultWithButton>
    );
};

export default observer(ContactsKanban);
