import React from 'react';
import { Link } from 'react-router-dom';
import { ESTATE_BASE_MAIN, ESTATE_BASES } from '~/types/estate.types';

type LifeComplexLinkProps = {
    newbuilding: { newbuilding_id: number; name: string };
    base?: ESTATE_BASES;
    target?: '_blank';
};

export const LifeComplexLinkState = (new_building_id: number, base: ESTATE_BASES = ESTATE_BASE_MAIN) => ({
    pathname: `/complex/${ESTATE_BASE_MAIN}/overview/${new_building_id}`,
    state: { modal: true, switchFromTab: false }
});

const LifeComplexLink = ({ newbuilding, target }: LifeComplexLinkProps) => (
    <Link to={LifeComplexLinkState(newbuilding.newbuilding_id)} title="Открыть карточку ЖК" target={target}>
        <span style={{ whiteSpace: 'nowrap' }}>
            ЖК «<b>{newbuilding.name}</b>»
        </span>
    </Link>
);

export default LifeComplexLink;
