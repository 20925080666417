import React, { PureComponent } from 'react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';

import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { LifeComplexLinkState } from './LifeComplexLink';

class LifeComplexMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(lifeComplexStore, LifeComplexLinkState, LifeComplexMenu, {
    disableDropdownMenu: true,
    base: ESTATE_BASE_MAIN
});
