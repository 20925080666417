import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';

import Grid from '~ui/Grid';
import { useToggle } from '~ui/Modal';
import Button from '~ui/Button';

import DeveloperSelect from './Developer/DeveloperSelect';
import CreateNewDeveloperModal from './Developer/CreateNewDeveloperModal';

type LifeComplexDeveloperEditingProps = HandlerEditingWrappedProps & {
    developer_id?: number | null;
};

const LifeComplexDeveloperEditing = (props: LifeComplexDeveloperEditingProps) => {
    const { item_id } = props;
    const [showModal, toggleModal] = useToggle();

    useMemo(() => {
        const item = {
            developer_id: props.developer_id || null
        } as const;
        lifeComplexStore.setEditingItem(item_id, item);
    }, [item_id]);

    const { developer_id } = lifeComplexStore.getItem(item_id).editingItem;

    const handleChangeDeveloperId = (developer_id: number) => {
        lifeComplexStore.setEditingItem(item_id, { developer_id });
    };

    return (
        <Paper style={{ padding: '1rem 1rem 0' }}>
            <Grid>
                <Grid.Column>
                    <DeveloperSelect developer_id={developer_id} onChange={handleChangeDeveloperId} />
                </Grid.Column>
                <Grid.Column>
                    <Button fullWidth color="secondary" startIcon={<AddIcon />} onClick={toggleModal}>
                        Создать нового застройщика
                    </Button>
                    {showModal && <CreateNewDeveloperModal onClose={toggleModal} onCreateDeveloper={handleChangeDeveloperId} />}
                </Grid.Column>
            </Grid>
        </Paper>
    );
};

export default HandlerEditingBlockWrapper(lifeComplexStore, observer(LifeComplexDeveloperEditing));
