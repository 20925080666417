import React, { ReactNode, ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import TabMUI from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    if (value !== index) {
        return null;
    }

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    );
};

export const useVerticalTabStyles = makeStyles(({ palette }) => ({
    root: {
        backgroundColor: '#f5f6fa',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${palette.divider}`,
        backgroundColor: palette.background.paper
    }
}));

export const Tab = TabMUI;

export type TabType<T extends string | number> = {
    label: string;
    icon: ReactElement;
    value: T;
    path?: string;
};

type TabsMenuProps<T extends string | number> = {
    children: ReactNode;
    value: T;
    tabs: TabType<T>[];
    handleChange?: (value: T) => void;
};

const TabsMenu = <T extends string | number>({ children, value, tabs, handleChange }: TabsMenuProps<T>) => {
    const classes = useVerticalTabStyles();

    const handleChangeTab = useCallback((event, value) => {
        if (handleChange) {
            handleChange(value);
        }
    }, []);

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                value={value}
                className={classes.tabs}
                onChange={handleChangeTab}
            >
                {tabs.map(({ label, icon, value, path }) => (
                    <Tab key={label} label={label} icon={icon} component={path && Link} value={value} to={path} />
                ))}
            </Tabs>
            {children}
        </div>
    );
};

export default TabsMenu;
