import React, { PureComponent, Fragment } from 'react';

import { EditingWrappedProps } from '../EditingBlockWrapper';

import ItemIDBlockEditing from './ItemIDBlockEditing';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MLS } from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import EstateLinkWithPreview from '../../Lists/Estate/EstateLinkWithPreview';

type EstateIDBlockEditingProps = EditingWrappedProps & {
    value?: number;
    store: ItemStoreInterface<any>;
    isMls?: boolean;
    onChange?: (index: number) => void;
    clearable?: boolean;
};

class EstateIDBlockEditing extends PureComponent<EstateIDBlockEditingProps, {}> {
    constructor(props: EstateIDBlockEditingProps) {
        super(props);
        this.state = {};

        if (props.isMls) {
            estateStore.fetchItemDropdownBase = ESTATE_BASE_MLS;
        }
    }

    componentWillUnmount() {
        estateStore.fetchItemDropdownBase = ESTATE_BASE_MAIN;
    }

    // componentDidUpdate(prevProps: Readonly<EstateIDBlockEditingProps>, prevState: Readonly<{}>, snapshot?: any): void {
    //     if (this.props.isMls !== prevProps.isMls) {
    //         estateStore.fetchItemDropdownBase = this.props.isMls ? ESTATE_BASE_MLS : ESTATE_BASE_MAIN;
    //         estateStore.itemDropdownOptions = [];
    //     }
    // }

    render() {
        const { editing, item_id, store, value, isMls, onChange, clearable } = this.props;

        return (
            <ItemIDBlockEditing
                base={isMls ? ESTATE_BASE_MLS : ESTATE_BASE_MAIN}
                searchingItemIdTitle={estateStore.item_id}
                itemStore={estateStore}
                itemLinkEmit={(item: any) => <EstateLinkWithPreview estate={item} />}
                editing={editing}
                item_id={item_id}
                store={store}
                value={value}
                placeholder="Выбрать объект"
                noResultsMessage="Объект не найден"
                icon="building"
                pathname={`/estate/sell/overview/${CREATING_ITEM_ID}`}
                createTitle="Создать объект"
                onChange={onChange}
                clearable={clearable}
            />
        );
    }
}

export default EstateIDBlockEditing;
