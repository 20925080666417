import React, { Component, Fragment } from 'react';
import Divider from '~ui/Divider';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';
import EstateAndContactEditing from './EditBlocks/EstateAndContactEditing';
import ShowingReportDetailsEditing from './EditBlocks/ShowingReportDetailsEditing';

import showingStore from '~/stores/showingStore';
import ItemAddModeWrapper from '../ItemAddModeWrapper';
import { ShowingLinkState } from '../../Lists/Showings/ShowingLink';
import CreationButtonsSet from '../Common/CreationButtonsSet';

type ShowingReportAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

@observer
class ShowingReportAddMode extends Component<ShowingReportAddModeProps> {
    render() {
        const { loadingItem, editingItem } = showingStore.getItem(0);

        return (
            <Fragment>
                <EstateAndContactEditing item_id={0} {...editingItem} />
                <Divider />
                <ShowingReportDetailsEditing item_id={0} {...editingItem} />
                <CreationButtonsSet loading={loadingItem} onCreate={this.props.handleCreate} onClearForm={this.props.clearForm} />
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(showingStore, ShowingLinkState, withRouter(ShowingReportAddMode));
