export const checkFormValid = ($form: HTMLFormElement | HTMLElement, withReport: boolean) => {
    const $inputs: (HTMLInputElement | HTMLTextAreaElement)[] = [
        ...Array.from($form.getElementsByTagName('input')),
        ...Array.from($form.getElementsByTagName('textarea'))
    ];

    let valid = true;

    $inputs.forEach(input => {
        if (!input.checkValidity()) {
            valid = false;
        }
    });

    if (!valid && withReport && 'reportValidity' in $form) {
        $form.reportValidity();
    }

    return valid;
};

export const whetherInputIsInvalid = ($form: HTMLElement): false | HTMLInputElement | HTMLTextAreaElement => {
    const $inputs: (HTMLInputElement | HTMLTextAreaElement)[] = [
        ...Array.from($form.getElementsByTagName('input')),
        ...Array.from($form.getElementsByTagName('textarea'))
    ];

    for (const $input of $inputs) {
        if (!$input.checkValidity()) {
            return $input;
        }
    }

    return false;
};

export const onInvalidInput = ({ target }: { target: HTMLInputElement }) => {
    if (target.parentElement) {
        target.setCustomValidity(target.parentElement.title);
    }
};
