import React, { useState } from 'react';
import { observer } from 'mobx-react';

import contactStore from '~/stores/contactStore';
import commonStore from '~/stores/commonStore';

import UserDropdown from '~/components/ItemsDropdowns/UserDropdown';
import TagsSelect from '~/components/Base/TagsSelect';
import ListError from '~/components/ListError';
import deepCopy from '~/common/deepCopy';

import Modal, { ModalBtnAction, ModalContent, useToggle } from '~ui/Modal';
import Grid from '~ui/Grid';
import { Switch } from '~ui/Checkbox';
import LoaderAwait from '~/components/Base/LoaderAwait';

type ManageContactsArrayProps = {
    onClose: () => void;
};

const ManageContactsArray = ({ onClose }: ManageContactsArrayProps) => {
    const [major_user_ids, setMajorUserIds] = useState<number[]>([]);
    const [disableAll, toggleDisableAll] = useToggle();
    const [shiftCallEvents, toggleCallEvents] = useToggle();
    const [tags_ids, setTagsIds] = useState<number[]>([]);

    const handleChangeMajorUsers = (major_user_ids: number | Array<number>) => {
        if (major_user_ids instanceof Array) {
            setMajorUserIds(major_user_ids);
        }
    };

    const handleSave = async () => {
        if (await contactStore.manageItemArray(deepCopy({ major_user_ids, disableAll, tag_ids: tags_ids, shiftCallEvents }))) {
            onClose();
        }
    };

    const { loadingManageItemArray, manageItemArrayErrors } = contactStore;

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', disabled: loadingManageItemArray },
        {
            onClick: handleSave,
            label: 'Сохранить',
            loading: loadingManageItemArray,
            disabled: loadingManageItemArray,
            variant: 'outlined',
            color: 'primary'
        }
    ];

    return (
        <Modal
            disableBackdropClick={loadingManageItemArray}
            fullScreen={commonStore.isMobile}
            header="Изменение параметров у контактов"
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            style={{ overflowY: 'visible' }}
        >
            <ModalContent style={{ overflowY: 'visible' }}>
                <ListError errors={manageItemArrayErrors} />

                <Grid>
                    <Grid.Column width={12}>
                        <Switch label="Перевести контакты в архив" checked={disableAll} onClick={toggleDisableAll} />
                    </Grid.Column>
                    <Grid.Column width={4}>Ответственный(е)</Grid.Column>
                    <Grid.Column width={8}>
                        <UserDropdown fluid groups_id={[]} upward={false} user_id={major_user_ids} onChange={handleChangeMajorUsers} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Switch label="Перенести запланированные звонки" checked={shiftCallEvents} onClick={toggleCallEvents} />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <TagsSelect setTagIds={setTagsIds} tags_ids={tags_ids} />
                    </Grid.Column>
                </Grid>

                <LoaderAwait active={loadingManageItemArray} />
            </ModalContent>
        </Modal>
    );
};

export default observer(ManageContactsArray);
