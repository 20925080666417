import React, { Component, Fragment } from 'react';
import { Card, Header, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import UserGrantAccessEditing from '../EditBlocks/UserGrantAccessEditing';
import UsersLinkList from '../../../Lists/Users/UsersLinkList';
import ShadowFragment from '../../../Base/ShadowFragment';

@observer
class UserGrantAccessShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = userStore.getItem(item_id);

        const { grantUsersIds, grantUsersUnderIds, grant_users_ids = [], grant_users_under_ids = [] } = item || {};

        return (
            <Card fluid color="green">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Наставник(и)</Header>
                            <Card.Description>
                                <Grid columns={2}>
                                    {grant_users_ids.length > 0 && (
                                        <Fragment>
                                            <Grid.Column>
                                                <b>{grant_users_ids.length > 1 ? 'Личные наставники' : 'Личный наставник'}</b>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <UsersLinkList usersList={grantUsersIds} />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    {grant_users_under_ids.length > 0 && (
                                        <Fragment>
                                            <Grid.Column>
                                                <b>Наставник над</b>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <UsersLinkList usersList={grantUsersUnderIds} />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    {grant_users_ids.length === 0 && grant_users_under_ids.length === 0 && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ShadowFragment shadow={loadingItem} print="Наставник не задан" />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                </Grid>
                            </Card.Description>
                        </Fragment>
                    )}
                    {editing && !loadingItem && (
                        <UserGrantAccessEditing
                            editing
                            item_id={item_id}
                            grant_users_ids={grant_users_ids}
                            grant_users_under_ids={grant_users_under_ids}
                        />
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(userStore, UserGrantAccessShow);
