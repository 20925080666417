import React, { Component, Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import estateStore from '~/stores/estateStore';
import config from '~/config/ui.config';
import commonStore from '~/stores/commonStore';

import SortablePhotosList from '~/components/Base/Photos/SortablePhotosList';

type PhotosBlockEditingProps = {
    estate_id: number;
};

@observer
class PhotosListBlockEditing extends Component<PhotosBlockEditingProps> {
    handleRadioPlan = (event: React.SyntheticEvent, { id }: { id: number }) => {
        const { photos } = estateStore.getItem(this.props.estate_id).editingItem;

        const newPhotos = photos.map((photo, i) => ({ ...photo, isPlan: !photo.isPlan && i === id - 1, rotate: 0 }));
        estateStore.setEditingItem(this.props.estate_id, { photos: newPhotos });
    };

    handleDeletePhoto = (index: number) => {
        let { photos } = estateStore.getItem(this.props.estate_id).editingItem;
        photos = [...photos];
        photos.splice(index, 1);
        estateStore.setEditingItem(this.props.estate_id, { photos });
    };

    handleRotatePhoto = (id: number) => {
        const { estate_id } = this.props;

        const { photos } = estateStore.getItem(estate_id).editingItem;
        const { rotate } = photos[id];

        estateStore.changeArrayValue(estate_id, 'photos', id, 'rotate', ((rotate || 0) + 270) % 360);
    };

    handleNextTrademark = (id: number) => {
        const { estate_id } = this.props;

        const { photos } = estateStore.getItem(estate_id).editingItem;
        const { watermark } = photos[id];

        estateStore.changeArrayValue(estate_id, 'photos', id, 'watermark', ((watermark || 0) + 1) % config.estatePhotos.watermarks.length);
    };

    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (oldIndex !== newIndex) {
            estateStore.movePhoto(this.props.estate_id, oldIndex, newIndex);
        }
    };

    render() {
        const { estate_id } = this.props;
        const { photos } = estateStore.getItem(estate_id).editingItem;

        const {
            estatePhotos: { estatePhotoUrl, sizes, estateTempPhotoUrl }
        } = config;

        const photoSet =
            photos &&
            photos.map(({ filename, isLoading, originalname, rotate, isPlan, watermark }) => {
                return {
                    rotate,
                    isPlan,
                    watermark,
                    isLoading,
                    ...(originalname || isLoading
                        ? {
                              src: estateTempPhotoUrl + filename + `?${rotate || 0}-${watermark || 0}`,
                              tempSrc: estateTempPhotoUrl + filename
                          }
                        : {
                              src: estatePhotoUrl + sizes[2].dir + '/' + sizes[2].prefix + filename,
                              tempSrc: estateTempPhotoUrl + filename,
                              srcSet: sizes.map(
                                  ({ dir, prefix, width }) => estatePhotoUrl + dir + '/' + prefix + filename + ` ` + width + 'w'
                              )
                          })
                };
            });

        return (
            <Fragment>
                {photoSet && photoSet.length > 0 && (
                    <Grid columns={commonStore.isMobile ? 2 : estate_id === CREATING_ITEM_ID ? 5 : 3} className="crm-Estate__photolist">
                        <SortablePhotosList
                            item_id={estate_id}
                            photoSet={photoSet}
                            onSortEnd={this.onSortEnd}
                            handleRadioPlan={this.handleRadioPlan}
                            handleRotatePhoto={this.handleRotatePhoto}
                            handleDeletePhoto={this.handleDeletePhoto}
                            useDragHandle
                            handleNextTrademark={this.handleNextTrademark}
                            axis="xy"
                        />
                    </Grid>
                )}
            </Fragment>
        );
    }
}

export default PhotosListBlockEditing;
