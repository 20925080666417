import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header, Dropdown } from 'semantic-ui-react';
import FilterUsers from './FilterUsers';
import { observer } from 'mobx-react';

import UsersTableBody from './UsersTableBody';
import ListFooter from '../Common/ListFooter';
import userStore from '~/stores/userStore';
import ListWrapper from '../ListWrapper';
import { ListWrapperProps } from '../ListWrapper';
import authStore from '~/stores/authStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ListError from '../../ListError';

@observer
class UsersList extends Component<ListWrapperProps, { showSortMenu: boolean }> {
    state = {
        showSortMenu: false
    };

    toggleShowMenu = () => {
        this.setState({ showSortMenu: !this.state.showSortMenu });
    };

    handleSortMenu = (orderBy: string) => {
        this.props.handleSort(orderBy);
        this.toggleShowMenu();
    };

    render() {
        const { showSortMenu } = this.state;
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, pagination, nextListLoaded, listErrors } = userStore;

        const showToggleMenuLabel = ['lastName', 'onlineTime'].includes(orderBy);

        return (
            <Fragment>
                <div className="crm-List">
                    <Header as="h3">
                        <Icon name="users" />
                        Сотрудники
                    </Header>

                    {authStore.canCreate(userStore.moduleName) && (
                        <div className="crm-List__addButton">
                            <Button
                                icon
                                labelPosition="left"
                                primary
                                size="tiny"
                                as={Link}
                                to={{
                                    pathname: `/users/list/profile/${CREATING_ITEM_ID}`,
                                    state: { modal: true }
                                }}
                            >
                                <Icon name="add user" /> Добавить
                            </Button>
                        </div>
                    )}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <ListError errors={listErrors} />

                    <FilterUsers />

                    <Segment className="crm-List__segment">
                        <Table structured size="small" sortable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell onClick={this.toggleShowMenu}>
                                        <Dropdown
                                            text={orderBy === 'onlineTime' ? 'Дата захода' : 'Имя'}
                                            icon={showToggleMenuLabel ? `sort content ${orderDirection}` : null}
                                            open={showSortMenu}
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    content="По Фамилии"
                                                    icon={`sort alphabet ${orderDirection}`}
                                                    onClick={() => this.handleSortMenu('lastName')}
                                                />
                                                <Dropdown.Item
                                                    content="По дате захода"
                                                    icon={`sort numeric ${orderDirection}`}
                                                    onClick={() => this.handleSortMenu('onlineTime')}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={orderBy === 'group_id' ? orderDirection : null}
                                        onClick={() => handleSort('group_id')}
                                    >
                                        Отдел
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Звонки</Table.HeaderCell>
                                    <Table.HeaderCell>Контакты</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <UsersTableBody usersList={Array.from(list)} />

                            <ListFooter
                                colSpan={3}
                                loading={loadingList}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={userStore.pageChange}
                                pageSizeChange={userStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(userStore, UsersList);
