// @flow

const serverUrl = 'https://data.ogrk24.ru';
const feedUrl = 'https://data.ogrk24.ru/xml/';

export default {
    serverUrl,
    // photoUploadUrl: serverUrl + '/uploadPhotosHandler',
    photoUploadUrl: 'https://data.ogrk24.ru/photoServer/upload.php',
    feedUrl,
    pushNotifications: {
        operator: 'onesignal',
        appId: 'f50d77d4-b505-4676-b126-7bed00578733'
    },
    crmOutgoingCalls: true,
    callsServerUrl: 'https://data.ogrk24.ru:2987/',
    whatsappServerUrl: 'https://wa-stream.topstore.pro:',
    whatsappFilesUrl: 'https://wa-files.topstore.pro/',
    whatsappDefaultPort: 52000
};

