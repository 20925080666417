import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';

import TextInput, { NumberInput, PriceInput } from '~ui/TextInput';
import Grid from '~ui/Grid';
import ButtonGroup from '~/components/Base/ButtonGroup';
import DatePicker from '~ui/DatePicker';
import DadataInput from '~ui/DadataInput';
import mortgageJobStore from '~/stores/mortgageRequest/mortgageJobStore';
import { DaDataParty } from '~/types/dadata.types';
import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import { checkFormValid } from '~/common/forms';
import Button from '~ui/Button';
import ListError from '~/components/ListError';
import MortgagePhonesList from './SubBlocks/MortgagePhonesList';

const JobMortgageEditing = ({ item_id, handleChange }: HandlerEditingWrappedProps) => {
    const { editingItem, loadingItem, errors } = mortgageJobStore.getItem(item_id);
    const {
        title,
        tax_number,
        phones,
        position,
        customer_type,
        actual_address,
        experience_cid,
        monthly_salary,
        proof_of_income_salary,
        date_of_employment,
        employees_count,
        business_share
    } = editingItem;

    const changeJobTitle = useCallback(
        (data: DaDataParty) => {
            mortgageJobStore.setEditingItem(item_id, { tax_number: data['inn'], actual_address: data?.address.value });
        },
        [item_id]
    );

    const formRef = useRef<HTMLFormElement>(null);

    const handleSave = () => {
        if (checkFormValid(formRef.current, true)) {
            mortgageJobStore.saveItem(item_id);
        }
    };

    const handleDelete = async () => {
        await mortgageJobStore.toggleDisableItem(item_id, false);
        const { mortgage_person_id } = mortgageJobStore.getItem(item_id).item;
        mortgagePersonStore.fetchItem(mortgage_person_id, null, true);
    };

    const handleChangePhones = useCallback(
        (phones: string[]) => {
            mortgageJobStore.setEditingItem(item_id, { phones });
        },
        [item_id]
    );

    return (
        <form ref={formRef}>
            <Container maxWidth="sm">
                <ListError errors={errors} />

                <Grid stackable disableGutter>
                    <Grid.Column width={3}>
                        <Typography variant="subtitle1">Вид занятости</Typography>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <ButtonGroup
                            value={customer_type}
                            name="customer_type"
                            handleChange={handleChange}
                            options={[
                                { value: 'employee', text: 'По найму' },
                                { value: 'owner', text: 'Собственник бизнеса' },
                                { value: 'individual_entrepreneur', text: 'ИП' },
                                { value: 'lawyer', text: 'Адвокат' },
                                { value: 'notary', text: 'Нотариус' }
                            ]}
                        />
                    </Grid.Column>
                </Grid>
                <Grid alignItems="flex-start" stackable>
                    <Grid.Column width={9}>
                        <DadataInput
                            type="PARTY"
                            onDaDataSelect={changeJobTitle}
                            label="Название основной работы"
                            onChange={handleChange}
                            value={title}
                            name="title"
                        />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <TextInput
                            label="ИНН"
                            helperText="10 или 12 цифр"
                            pattern={`^([0-9]{10}|[0-9]{12})$`}
                            mask="000000000000"
                            value={tax_number}
                            name="tax_number"
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <TextInput label="Факт. адрес организации" value={actual_address} name="actual_address" onChange={handleChange} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {phones && (
                            <MortgagePhonesList item_id={item_id} phones={phones} onChange={handleChangePhones} label="Рабочий телефон" />
                        )}
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <TextInput label="Должность" value={position} name="position" onChange={handleChange} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <NumberInput
                            label="Стаж на текущием месте работы"
                            name="experience_cid"
                            value={experience_cid}
                            extraLabel="мес."
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <PriceInput label="Заявленный доход в месяц" name="monthly_salary" value={monthly_salary} onChange={handleChange} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <PriceInput
                            label="Подтвержденный доход в месяц"
                            name="proof_of_income_salary"
                            value={proof_of_income_salary}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <DatePicker label="Дата устройства на работу" variant="standard" openTo="year" value={date_of_employment} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <NumberInput
                            label="Количество сотрудников"
                            name="employees_count"
                            value={employees_count}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    {customer_type === 'owner' && (
                        <Grid.Column width={6}>
                            <NumberInput
                                label="Доля в бизнесе"
                                name="business_share"
                                value={business_share}
                                extraLabel="%"
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    )}
                </Grid>

                <div className="crm-Item__addMode_buttons">
                    <Button variant="text" color="secondary" loading={loadingItem} onClick={handleDelete}>
                        Удалить
                    </Button>
                    <Button variant="outlined" color="primary" loading={loadingItem} onClick={handleSave}>
                        Сохранить
                    </Button>
                </div>
            </Container>
        </form>
    );
};

export default HandlerEditingBlockWrapper(mortgageJobStore, observer(JobMortgageEditing));
