import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Loader, Dimmer, Icon, Header } from 'semantic-ui-react';

import Comments from '../Common/Comments/index';
import TaskTitleShow from './ShowBlocks/TaskTitleShow';
import TaskParticipantsShow from './ShowBlocks/TaskParticipantsShow';
import TaskDeadLineShow from './ShowBlocks/TaskDeadLineShow';
import TaskAttachedShow from './ShowBlocks/TaskAttachedShow';

import LabelArchive from '../Common/LabelArchive';
import taskStore from '~/stores/taskStore';

type TaskShowModeProps = {
    task_id: number;
};

@observer
class TaskShowMode extends Component<TaskShowModeProps> {
    render() {
        const { task_id } = this.props;
        const { item, loadingItem } = taskStore.getItem(task_id);

        const enable = item ? item.enable : true;
        const title = item && item.title;
        const priority = item && item.priority;
        const attached = item && item.attached;

        return (
            <Grid stackable>
                <Grid.Column width={8}>
                    <h3>
                        <Icon name="tasks" />
                        <span>
                            {title}
                            {priority === 2 && (
                                <span className="crm-Estate__fieldNowrap">
                                    &nbsp;
                                    <Icon title="Срочная задача" name="lightning" color="red" />
                                    (срочная)
                                </span>
                            )}
                            {priority === 3 && (
                                <span className="crm-Estate__fieldNowrap">
                                    &nbsp;
                                    <Icon title="Не срочная задача" name="pause" color="grey" />
                                    (не срочная)
                                </span>
                            )}
                        </span>
                    </h3>

                    {!loadingItem && !enable && <LabelArchive />}

                    <TaskTitleShow item_id={task_id} />

                    {attached && attached.length > 0 && <TaskAttachedShow task_id={task_id} />}

                    {!loadingItem && <Comments item_id={task_id} item_type="TASK" />}

                    {loadingItem && (
                        <Dimmer active inverted>
                            <Loader active size="large" />
                        </Dimmer>
                    )}
                </Grid.Column>

                <Grid.Column width={8}>
                    <TaskDeadLineShow item_id={task_id} />

                    <TaskParticipantsShow item_id={task_id} />
                </Grid.Column>
            </Grid>
        );
    }
}

export default TaskShowMode;
