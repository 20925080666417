import * as estateSmaShareApi from '~/api/estateSmaShareApi';

import ItemListsStorePrototype from './prototypes/ItemListsStore.prototype';
import { SmaOptionsType, SMAShareType } from '~/types/estateSma.types';

class EstateSMAShareStore extends ItemListsStorePrototype<SMAShareType, number, {}, SmaOptionsType> {
    adjustItemToId(smaShare: SMAShareType): number {
        return smaShare.sma_id;
    }

    async createSmaShare(sma_id: number, phone: string, message: string): Promise<void> {
        await estateSmaShareApi.createSmaShare(sma_id, phone, message);
    }
}

export default new EstateSMAShareStore('sma_id', estateSmaShareApi);
