import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import config from '../../../../config/server.config';

import AvatarEditor from 'react-avatar-editor';
import userStore from '~/stores/userStore';

import { authHeaders } from '../../../../common/fetchApi';

type UserPhotoEditingBlockProps = { item_id: number };

@observer
class UserPhotoEditingBlock extends Component<UserPhotoEditingBlockProps, { scale: number }> {
    file: HTMLInputElement;

    state = {
        scale: 1.2
    };

    constructor(props: UserPhotoEditingBlockProps) {
        super(props);

        userStore.setEditingItem(props.item_id, { user_id: props.item_id, photo: null });
    }

    handleWheel = (event: { deltaY: number }) => {
        let { scale } = this.state;
        if (event.deltaY < 0 && scale < 5) {
            scale += 0.1;
        } else if (scale > 0.5) {
            scale -= 0.1;
        }
        this.setState({ scale });
    };

    handleSetFile = ({ target: { files, result } }: any) => {
        const reader = new FileReader();
        reader.onload = evt => {
            userStore.setEditingItem(this.props.item_id, { photo: evt.target.result });
        };
        reader.readAsDataURL(files[0]);

        const formData = new FormData();

        formData.append('file', files[0]);

        const request = new XMLHttpRequest();

        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                const { filename } = JSON.parse(request.response).response;

                userStore.setEditingItem(this.props.item_id, { tempFilename: filename });
            }
        };

        const { authorization } = authHeaders();

        request.open('POST', config.photoUploadUrl);

        request.setRequestHeader('authorization', authorization || '');
        request.send(formData);
    };

    render() {
        const { scale } = this.state;
        const { item_id } = this.props;
        const { photo } = userStore.getItem(item_id).editingItem;

        return (
            <Fragment>
                <div onWheel={this.handleWheel} className="crm-User__photoBlock_editor">
                    <AvatarEditor
                        ref={image => (userStore.getItem(item_id).property.imageData = image)}
                        image={photo}
                        width={250}
                        height={250}
                        border={25}
                        color={[255, 255, 255, 0.6]}
                        scale={scale}
                        rotate={0}
                    />
                </div>
                <div className="crm-User__photoBlock_buttonsSet">
                    <input type="file" ref={file => (this.file = file)} onChange={this.handleSetFile} />
                </div>
            </Fragment>
        );
    }
}

export default UserPhotoEditingBlock;
