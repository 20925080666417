import React from 'react';

import Time from '~/components/Base/Time';
import { TableCell, TableRow, TableBody } from '~ui/Table';

import { ESTATE_BASE_MLS, MlsEstateFixPrice } from '~/types/estate.types';
import EstateLinkWithPreview from '~/components/Lists/Estate/EstateLinkWithPreview';
import Price from '~/components/Base/Price';
import AgentBonus from '~/components/Base/AgentBonus';
import PhonesList, { adjustPhoneNumber } from '~/components/Base/PhonesList';

const MlsFixPricesTableBody = ({ list, orderBy }: { list: MlsEstateFixPrice[]; orderBy: string }) => {
    return (
        <TableBody>
            {list.length > 0 &&
                list.map(
                    ({
                        estate_fix_price_id,
                        base,
                        updateTime,
                        createTime,
                        status,
                        price,
                        currency,
                        database,
                        major_user_id,
                        agentLastName,
                        agentFirstName,
                        agentPhone,
                        phone,
                        estate,
                        estate_id,
                        agentBonusCurrency,
                        agentBonusType,
                        agentBonusValue
                    }) => {
                        return (
                            <TableRow key={estate_fix_price_id}>
                                <TableCell>
                                    <b>
                                        <Time time={createTime} />
                                    </b>
                                </TableCell>

                                <TableCell>
                                    {estate && <EstateLinkWithPreview estate={estate} base={base} />}
                                    {!estate && `estate_id#${estate_id}`}
                                </TableCell>
                                <TableCell>{adjustPhoneNumber(phone)}</TableCell>
                                <TableCell>
                                    <div>
                                        <b>{database}</b> {agentFirstName} {agentLastName}
                                    </div>
                                    {agentPhone && <div>{adjustPhoneNumber(agentPhone)}</div>}
                                </TableCell>
                                <TableCell>
                                    <Price price={price} currency={currency} />
                                    <br />
                                    Бонус:&nbsp;
                                    <AgentBonus
                                        agentBonusCurrency={agentBonusCurrency}
                                        agentBonusType={agentBonusType}
                                        agentBonusValue={agentBonusValue}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    }
                )}
        </TableBody>
    );
};

export default MlsFixPricesTableBody;
