import React from 'react';
import { Popup, Icon, List } from 'semantic-ui-react';

import { EstateAddressStrict } from '~/types/estate.types';

const AddressPopup = ({ address }: { address: EstateAddressStrict }) => {
    const {
        fias,
        kladr,

        street,
        streetType,
        houseNumber,
        houseNumberType,
        blockNumber,
        blockNumberType,

        town,
        townType,
        region,
        regionType,
        district,
        cityDistrict,
        districtType,
        cityDistrictType,
        settlement,
        settlementType,
        postcode
    } = address;

    return (
        <Popup
            trigger={<Icon circular color="blue" name="info" size="small" title="Информация об адресе" style={{ cursor: 'pointer' }} />}
            size="small"
            on="click"
            content={
                <List>
                    <List.Item>
                        <List.Header>Регион</List.Header>
                        {`${regionType || ''} ${region || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Адмн.образование</List.Header>
                        {`${districtType || ''} ${district || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Населенный пункт</List.Header>
                        {`${townType || ''} ${town || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Район</List.Header>
                        {`${cityDistrictType || ''} ${cityDistrict || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Поселение</List.Header>
                        {`${settlement || ''} ${settlementType || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Почтовый код</List.Header>
                        {`${postcode || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Улица</List.Header>
                        {`${streetType || ''} ${street || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Дом</List.Header>
                        {`${houseNumberType || ''} ${houseNumber || ''} ${blockNumberType || ''} ${blockNumber || ''}`.trim()}
                    </List.Item>
                    <List.Item>
                        <List.Header>Фиас</List.Header>
                        {`${fias || ''}`}
                    </List.Item>
                    <List.Item>
                        <List.Header>Кладр</List.Header>
                        {`${kladr || ''}`}
                    </List.Item>
                </List>
            }
            position="bottom center"
        />
    );
};

export default AddressPopup;
