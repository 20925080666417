import React, { Fragment, PureComponent } from 'react';
import { ReactNode } from 'react';
import cs from 'classnames';
import { Icon, Popup, List } from 'semantic-ui-react';
import { CALENDAR_VIEW_TYPE_AGENDA, CALENDAR_VIEW_TYPES, CalendarEventType } from '~/types/calendar.types';
import ItemLink, { matchIconName } from '../Base/ItemLink';
import { CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK } from '~/types/calendar.types';
import { ItemAttached } from '~/types/tasks.types';
import commonStore from '~/stores/commonStore';

const EventAttached = ({ attached, horizontal }: { attached: ItemAttached[]; horizontal?: boolean }) => (
    <List horizontal={horizontal}>
        {attached.map(({ item_id, item_type, item }) => (
            <List.Item key={item_id}>
                <List.Icon name={matchIconName(item_type)} />
                <List.Content>
                    <ItemLink item_type={item_type} item={item} />
                </List.Content>
            </List.Item>
        ))}
    </List>
);

export const CalendarEvent = (viewType: CALENDAR_VIEW_TYPES) => {
    class CalendarEvent extends PureComponent<{ event: CalendarEventType }> {
        render() {
            const { event } = this.props;
            const { hasReleased, end, attached, title, item_type } = event;

            const eventReleased = typeof hasReleased === 'boolean' && hasReleased === true;
            const eventExceed = typeof hasReleased === 'boolean' && hasReleased === false && end < new Date();

            const Trigger = (
                <Fragment>
                    <Icon name={matchIconName(item_type)} color={eventReleased ? 'green' : eventExceed ? 'red' : null} />
                    {title}
                </Fragment>
            );

            return (
                <span className="crm-Calendar__event">
                    {[CALENDAR_VIEW_TYPE_WEEK, CALENDAR_VIEW_TYPE_MONTH].includes(viewType) &&
                        (attached ? (
                            <Popup flowing hoverable mouseEnterDelay={0} mouseLeaveDelay={300} trigger={<span>{Trigger}</span>}>
                                <EventAttached attached={attached} />
                            </Popup>
                        ) : (
                            Trigger
                        ))}

                    {[CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_AGENDA].includes(viewType) && (
                        <Fragment>
                            {Trigger}
                            {!commonStore.isMobile && (
                                <div className="crm-Calendar__event_inlineList">
                                    {attached && <EventAttached attached={attached} horizontal />}
                                </div>
                            )}
                        </Fragment>
                    )}
                </span>
            );
        }
    }

    return CalendarEvent;
};

const CalendarEventWrapper = ({ event, children }: { event: CalendarEventType; children: ReactNode }) => {
    const eventExceed = typeof event.hasReleased === 'boolean' && event.hasReleased === false && event.end < new Date();

    return (
        <div
            className={cs({
                'crm-Calendar__event_item': true,
                'crm-Calendar__event_exceed': eventExceed,
                'crm-Calendar__event_meeting': event.item_type === 'meetingEvent' && !eventExceed,
                'crm-Calendar__event_call': event.item_type === 'callEvent' && !eventExceed,
                'crm-Calendar__event_showing': event.item_type === 'showing' && !eventExceed
            })}
        >
            {children}
        </div>
    );
};

export default CalendarEventWrapper;
