import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Divider from '~ui/Divider';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';

import ItemAddModeWrapper from '../ItemAddModeWrapper';
import wishStore from '~/stores/wishStore';

import WishContactIdEditingProps from './EditBlocks/WishContactIdEditing';
import WishFilterEdition from './EditBlocks/WishFilterEdition';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CreationButtonsSet from '../Common/CreationButtonsSet';
import { WishLinkState } from '../../Lists/Wishes/WishLink';

type WishAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

@observer
class WishAddMode extends Component<WishAddModeProps> {
    render() {
        const { loadingItem } = wishStore.getItem(CREATING_ITEM_ID);

        return (
            <Fragment>
                <WishContactIdEditingProps item_id={CREATING_ITEM_ID} />
                <Divider />

                <WishFilterEdition item_id={CREATING_ITEM_ID} />

                <CreationButtonsSet loading={Boolean(loadingItem)} onCreate={this.props.handleCreate} onClearForm={this.props.clearForm} />
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(wishStore, WishLinkState, withRouter(WishAddMode));
