import React, { Component, Fragment } from 'react';
import { Menu, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import ListFilterWrapper from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import accessStore from '~/stores/accessStore';

import FilterUsersMoreDetails from './FilterBlocks/FilterUsersMoreDetails';

@observer
class FilterUsers extends Component<ListFilterWrapperProps> {
    constructor(props: ListFilterWrapperProps) {
        super(props);
        accessStore.fetchList();
    }

    render() {
        const { handleChange, setUnixTime, handleCheckbox } = this.props;
        const { loadingList, accessModesDropdown } = accessStore;
        const { search, onlineOnly, access_id } = userStore.listFilter;

        return (
            <Fragment>
                <Menu.Item>
                    <Input
                        transparent
                        value={search}
                        className="icon"
                        icon="search"
                        placeholder="ФИО, Должность, Номер, Внутренний номер"
                        name="search"
                        onChange={handleChange}
                    />
                </Menu.Item>
                <Menu.Item>
                    <div className="ui tiny input">
                        <Dropdown
                            selection
                            name="access_id"
                            value={access_id}
                            onChange={handleChange}
                            placeholder="Доступ"
                            options={accessModesDropdown || []}
                            loading={loadingList}
                            clearable
                            type="clearable"
                        />
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <Checkbox checked={onlineOnly} name="onlineOnly" label={<label>онлайн</label>} onClick={handleCheckbox} />
                </Menu.Item>
            </Fragment>
        );
    }
}

export default ListFilterWrapper(userStore, FilterUsers, FilterUsersMoreDetails, { disableMainUserDropdown: true });
