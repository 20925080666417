import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import taskStore from '~/stores/taskStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import TaskParticipantsEditing from '../EditBlocks/TaskParticipantsEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import UsersLinkList from '../../../Lists/Users/UsersLinkList';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';

@observer
class TaskParticipantsShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = taskStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { assign_users, assign_users_ids, observer_users, observer_users_ids, major_user_id, major_user } = item;

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Участники</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        Ответственны
                                        {assign_users && assign_users.length > 1 ? 'е' : 'й'}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <UsersLinkList avatar usersList={assign_users} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Постановщик</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!major_user}
                                            print={major_user && <UserLinkWithPreview avatar user={major_user} />}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {observer_users && observer_users.length > 0 && (
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            Наблюдател
                                            {observer_users.length > 1 ? 'и' : 'ь'}
                                        </Grid.Column>
                                        <Grid.Column>
                                            <UsersLinkList avatar usersList={observer_users} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        </Fragment>
                    )}
                    {editing && (
                        <TaskParticipantsEditing
                            item_id={item_id}
                            assign_users_ids={Array.from(assign_users_ids)}
                            observer_users_ids={observer_users_ids ? Array.from(observer_users_ids) : []}
                            major_user_id={major_user_id}
                        />
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(taskStore, TaskParticipantsShow);
