import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Grid, Dropdown } from 'semantic-ui-react';
import EditField from '../../../Base/EditField';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { Estate } from '~/types/estate.types';

type LandBlockEditingProps = HandlerEditingWrappedProps & {
    settlementName?: string | null;
    settlement_cian_id?: number | null;

    landArea?: number | null;
    landAreaUnitType?: number;
    landStatus?: number | null;

    hasGas?: boolean | null;
    hasDrainage?: boolean | null;
    hasElectricity?: boolean | null;
    hasWater?: boolean | null;

    description?: string;
};

export const LAND_INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasElectricity', 'электричество'],
    ['hasGas', 'газ'],
    ['hasDrainage', 'канализация'],
    ['hasWater', 'водоснабжение']
];

@observer
class LandBlockEditing extends Component<LandBlockEditingProps> {
    constructor(props: LandBlockEditingProps) {
        super(props);

        const item = {
            estate_id: props.item_id
        };
        estateStore.setEditingItem(item.estate_id, {
            settlementName: props.settlementName || null,
            settlement_cian_id: props.settlement_cian_id || null,

            landArea: props.landArea || null,
            landAreaUnitType: props.landAreaUnitType || 2,
            landStatus: props.landStatus || null,

            hasGas: props.hasGas || null,
            hasDrainage: props.hasDrainage || null,
            hasElectricity: props.hasElectricity || null,
            hasWater: props.hasWater || null,

            description: props.description || ''
        });
    }

    render() {
        const { item_id, handleChange, handleCheckbox, handleTextAreaChange } = this.props;
        const editingItem = estateStore.getItem(item_id).editingItem;
        const { settlementName, landArea, landAreaUnitType, landStatus, description, settlement_cian_id } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Text
                    label="Название коттеджного поселка"
                    placeholder="Название коттеджного поселка"
                    value={settlementName}
                    name="settlementName"
                    onChange={handleChange}
                />
                <EditField.InputNumber
                    label="ID Коттеджного поселка В ЦИАН"
                    placeholder="ID Коттеджного поселка В ЦИАН"
                    value={settlement_cian_id}
                    name="settlement_cian_id"
                    onChange={handleChange}
                />

                <Grid columns={2}>
                    {LAND_INFRASTURCTURE_FIELDS.map(field => (
                        <Grid.Column key={field[0]}>
                            <Checkbox
                                checked={Boolean(editingItem[field[0]])}
                                name={field[0]}
                                label={<label>{field[1]}</label>}
                                onChange={handleCheckbox}
                            />
                        </Grid.Column>
                    ))}
                </Grid>

                <br />

                <EditField.InputNumber
                    label="Участок"
                    onChange={handleChange}
                    min={1}
                    max={9999999}
                    value={landArea}
                    name="landArea"
                    step={0.1}
                    required
                    extraLabel={
                        <Dropdown
                            value={landAreaUnitType}
                            name="landAreaUnitType"
                            onChange={handleChange}
                            options={estateL10n.LAND_AREA_TYPES_DROPDOWN}
                        />
                    }
                />

                <EditField.Drop
                    onChange={handleChange}
                    label="Категория земли"
                    name="landStatus"
                    value={landStatus}
                    options={estateL10n.LAND_AREA_STATUS_TYPE_DROPDOWN}
                />

                <EditField.Area name="description" label="Описание" value={description} onChange={handleTextAreaChange} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, LandBlockEditing);
