import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Dropdown } from 'semantic-ui-react';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandleChangeType } from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';
import {
    Deal,
    DEAL_STATUS_MORTGAGE_APPLY,
    DEAL_STATUS_MORTGAGE_APPROVE,
    DEAL_STATUS_MORTGAGE_EXECUTE,
    DEAL_STATUS_MORTGAGE_EXPIRED,
    DEAL_STATUS_MORTGAGE_INIT,
    DEAL_STATUS_MORTGAGE_REJECT,
    DEAL_TYPE_ACCOMPANIMENT_ID,
    DEAL_TYPE_MORTGAGE,
    DEAL_TYPE_MORTGAGE_ID,
    DEAL_TYPE_NEWBUILDING_ID,
    DealStatusType
} from '~/types/deals.types';
import dealStore from '~/stores/dealStore';
import dealL10n from '../../../../L10n/deals.L10n';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { DEAL_STATUS_BREAK, DEAL_STATUS_CLOSE, DEAL_STATUS_DONE, DEAL_STATUS_OPEN, DEAL_TYPE_EXCLUSIVE_ID } from '~/types/deals.types';
import DatePicker from '../../../Base/DatePicker';

export const DEAL_TIMES_STATUS = [
    ['dealOpenTime', 'Открытие'],
    ['dealDoneTime', 'Проведение'],
    ['dealCloseTime', 'Закрытие'],
    ['dealBreakTime', 'Развал']
];

type DealStatusEditingProps = HandlerEditingWrappedProps & {
    status?: DealStatusType;
    description?: string;
    releaseTime?: number;
    type?: number;
    dealOpenTime?: number | null;
    dealDoneTime?: number | null;
    dealCloseTime?: number | null;
    dealBreakTime?: number | null;
};

@observer
class DealStatusEditing extends Component<DealStatusEditingProps> {
    constructor(props: DealStatusEditingProps) {
        super(props);

        const deal: Partial<Deal> = {
            status: props.status || 0,
            description: props.description || '',
            releaseTime: props.releaseTime || null,
            type: props.type || ESTATE_TYPE_SELL_ID,

            dealOpenTime: props.dealOpenTime || null,
            dealDoneTime: props.dealDoneTime || null,
            dealCloseTime: props.dealCloseTime || null,
            dealBreakTime: props.dealBreakTime || null
        };
        dealStore.setEditingItem(props.item_id, deal);
    }

    handleStatusChange: HandleChangeType = (event, { value: status }) => {
        if (status === '') {
            status = 0;
        }

        let change: Partial<Deal> = {
            status
        };

        const time = Math.round(Date.now() / 1000);

        if (!this.props.editing) {
            change = { ...change, dealOpenTime: null, dealDoneTime: null, dealCloseTime: null, dealBreakTime: null };
        }

        switch (status) {
            case DEAL_STATUS_OPEN:
                change = { ...change, dealOpenTime: time };
                break;
            case DEAL_STATUS_DONE:
                change = { ...change, dealDoneTime: time };
                break;
            case DEAL_STATUS_CLOSE:
                change = { ...change, dealCloseTime: time };
                break;
            case DEAL_STATUS_BREAK:
                change = { ...change, dealBreakTime: time };
                break;

            case 0:
            case DEAL_STATUS_MORTGAGE_INIT:
            case DEAL_STATUS_MORTGAGE_APPLY:
            case DEAL_STATUS_MORTGAGE_APPROVE:
            case DEAL_STATUS_MORTGAGE_EXECUTE:
            case DEAL_STATUS_MORTGAGE_EXPIRED:
            case DEAL_STATUS_MORTGAGE_REJECT:
                break;
            default:
                return;
        }

        dealStore.setEditingItem(this.props.item_id, change);
    };

    render() {
        const { item_id, handleTextAreaChange, setUnixTime, editing } = this.props;

        const { editingItem } = dealStore.getItem(item_id);
        const { description, status, releaseTime, type } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Grid stackable verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={editing ? 5 : 3}>
                            <label className="crm-Estate__field_label">Статус</label>
                        </Grid.Column>
                        <Grid.Column width={editing ? 11 : 13}>
                            <Dropdown
                                selection
                                name="status"
                                value={status}
                                onChange={this.handleStatusChange}
                                placeholder="Статус"
                                options={
                                    type === DEAL_TYPE_MORTGAGE_ID
                                        ? dealL10n.DEAL_STATUS_MORTGAGE_TYPE_DROPDOWN
                                        : dealL10n.DEAL_STATUS_TYPE_DROPDOWN
                                }
                                clearable
                                type="clearable"
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {![DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type) && (
                            <Fragment>
                                <Grid.Column width={editing ? 5 : 3} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                    <label className={`crm-Estate__field_label ${!editing ? 'crm-Item__datePickerLabel' : ''}`}>
                                        Ориентировочная дата проведения
                                    </label>
                                </Grid.Column>

                                <Grid.Column width={editing ? 11 : 13} className={editing ? 'crm-Item__datePickerLabel_column' : ''}>
                                    <div className="crm-Tasks__deadLine_picker">
                                        <DatePicker
                                            time={releaseTime || null}
                                            onChange={time => setUnixTime('releaseTime', time)}
                                            clearable
                                        />
                                    </div>
                                </Grid.Column>
                            </Fragment>
                        )}
                        {editing &&
                            [ESTATE_TYPE_SELL_ID, ESTATE_TYPE_RENT_ID, DEAL_TYPE_ACCOMPANIMENT_ID, DEAL_TYPE_NEWBUILDING_ID].includes(
                                type
                            ) &&
                            DEAL_TIMES_STATUS.map(([key, title]) => (
                                <Fragment key={key}>
                                    <Grid.Column width={5} className="crm-Item__datePickerLabel_column">
                                        <label className="crm-Estate__field_label">{title}</label>
                                    </Grid.Column>

                                    <Grid.Column width={11} className="crm-Item__datePickerLabel_column">
                                        <div className="crm-Tasks__deadLine_picker">
                                            <DatePicker
                                                time={editingItem[key] || null}
                                                onChange={time => setUnixTime(key, time)}
                                                clearable
                                            />
                                        </div>
                                    </Grid.Column>
                                </Fragment>
                            ))}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <EditField.Area label="Комментарий" value={description} name="description" onChange={handleTextAreaChange} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealStatusEditing);
