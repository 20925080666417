import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';

import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

import ListError from '~/components/ListError';
import LoaderAwait from '~/components/Base/LoaderAwait';
import LifeComplexFeedUrlEditing from '~/components/Settings/LifeComplexFeeds/Blocks/LifeComplexFeedUrlEditing';

const LifecomplexFeedsList = ({ developer_id }: { developer_id: number }) => {
    const [showAddFeed, toggleAddFeed] = useToggle();

    useEffect(() => {
        developerFeedStore.list = [];
        developerFeedStore.changeFilter('developer_id', developer_id);
    }, [developer_id]);

    const { list: feeds, loadingList: loadingFeeds, listErrors } = developerFeedStore;

    return (
        <Box display="flex" flexDirection="column" style={{ minHeight: '5rem' }}>
            <LoaderAwait active={loadingFeeds} linear />
            <ListError errors={listErrors} />
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }} pt={2}>
                {feeds.map(({ developer_feed_id }) => (
                    <LifeComplexFeedUrlEditing key={developer_feed_id} developer_feed_id={developer_feed_id} developer_id={developer_id} />
                ))}
                {!showAddFeed && !loadingFeeds && (
                    <Button startIcon={<AddIcon />} variant="outlined" onClick={toggleAddFeed}>
                        Добавить фид
                    </Button>
                )}
                {showAddFeed && <LifeComplexFeedUrlEditing developer_feed_id={0} developer_id={developer_id} />}
            </Box>
        </Box>
    );
};

export default observer(LifecomplexFeedsList);
