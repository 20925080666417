import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalContent, ModalBtnAction } from '~ui/Modal';
import Progress from '~ui/Progress';

import EditField from '../../Base/EditField';
import tagStore from '~/stores/tagStore';
import { checkFormValid } from '~/common/forms';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';
import commonStore from '~/stores/commonStore';
import authStore from '~/stores/authStore';

type TagModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

export const COLORS_DROPDOWN: DropdownType[] = [
    { key: 'red', text: 'Красный', value: 'red' },
    { key: 'orange', text: 'Оранжевый', value: 'orange' },
    { key: 'yellow', text: 'Желтый', value: 'yellow' },
    { key: 'olive', text: 'Оливковый', value: 'olive' },
    { key: 'green', text: 'Зеленый', value: 'green' },
    { key: 'teal', text: 'Морской волны', value: 'teal' },
    { key: 'blue', text: 'Синий', value: 'blue' },
    { key: 'violet', text: 'Фиолетовый', value: 'violet' },
    { key: 'purple', text: 'Пурпурный', value: 'purple' },
    { key: 'pink', text: 'Розовый', value: 'pink' },
    { key: 'brown', text: 'Коричневый', value: 'brown' },
    { key: 'grey', text: 'Серый', value: 'grey' },
    { key: 'black', text: 'Черный', value: 'black' }
];

@observer
class TagModal extends Component<TagModalProps> {
    $form: HTMLFormElement;

    constructor(props: TagModalProps) {
        super(props);

        const { item_id } = this.props;

        tagStore.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const tag = {
                tag_id: item_id,
                title: '',
                description: '',
                color: 'red',
                enable: true
            } as const;

            tagStore.setEditingItem(item_id, tag);
        }
    }

    handleCreateTag = async (event: React.SyntheticEvent) => {
        if (checkFormValid(this.$form, true)) {
            event.preventDefault();
            event.stopPropagation();

            const { item_id, handleClose } = this.props;

            if (item_id === CREATING_ITEM_ID) {
                await tagStore.createItem();
                const { errors } = tagStore.getItem(item_id);
                if (errors && errors.length) {
                    return;
                }
            } else {
                await tagStore.saveItem(item_id);
            }

            handleClose();
            tagStore.fetchList();
        }
    };

    handleDelete = async () => {
        const { item_id, handleClose } = this.props;
        tagStore.toggleDisableItem(item_id, false);
        handleClose();
    };

    render() {
        const { handleClose, item_id, handleChange, handleTextAreaChange } = this.props;
        const { editingItem, loadingItem, errors } = tagStore.getItem(item_id);

        const { title, description, color } = editingItem || {};

        const modalActions: ModalBtnAction[] = [{ onClick: handleClose, label: 'Отмена', variant: 'text', color: 'primary' }];
        if (item_id !== CREATING_ITEM_ID && authStore.canDelete(tagStore.moduleName, [])) {
            modalActions.push({
                onClick: this.handleDelete,
                label: 'Удалить',
                variant: 'text',
                color: 'secondary'
            });
        }
        modalActions.push({
            onClick: this.handleCreateTag,
            label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            color: 'primary',
            disabled: !title || title.length < 3,
            variant: 'outlined'
        });

        return (
            <Modal maxWidth="sm" onClose={handleClose} actions={modalActions} fullScreen={commonStore.isMobile}>
                <ModalHeader onClose={handleClose}>
                    <Icon name="tag" color={color} /> Тэг контактов
                </ModalHeader>

                <ModalContent>
                    <ListError errors={Array.from(errors || [])} />

                    {!loadingItem && editingItem && (
                        <form ref={$el => (this.$form = $el)} className="ui form small">
                            <div className="crm-Item__editingMode crm-Triggers__modalField">
                                <EditField.Text
                                    label="Тэг"
                                    placeholder="Тэг"
                                    value={title}
                                    name="title"
                                    onChange={handleChange}
                                    required=".{3,}"
                                />

                                <EditField.Drop label="Цвет" name="color" value={color} onChange={handleChange} options={COLORS_DROPDOWN} />

                                <EditField.Area
                                    label="Описание тэга"
                                    value={description}
                                    name="description"
                                    onChange={handleTextAreaChange}
                                />
                            </div>
                        </form>
                    )}

                    {loadingItem && <Progress show />}
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(tagStore, TagModal);
