import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import requestL10n from '../../../../L10n/request.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';
import requestStore from '~/stores/requestStore';

type RequestStatusEditingProps = HandlerEditingWrappedProps & {
    status?: number | null;
};

@observer
class RequestStatusEditing extends Component<RequestStatusEditingProps> {
    constructor(props: RequestStatusEditingProps) {
        super(props);

        const request = {
            status: props.status || null
        };
        requestStore.setEditingItem(props.item_id, request);
    }

    render() {
        const { handleChange, item_id } = this.props;
        const { status } = requestStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Drop
                    label="Статус"
                    options={requestL10n.REQUEST_STATUS_DROPDOWN}
                    name="status"
                    value={status}
                    onChange={handleChange}
                    clearable
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, RequestStatusEditing);
