import fetchApi from '~/common/fetchApi';
import { Developer } from '~/types/newbuildings.types';
import { objectToGraphql } from '~/common/graphql';

export const GQDeveloperFields = `
    developer_id
    name
    logoUrl
    url
`;

export const fetchItem = async (developer_id: number): Promise<Developer> => {
    const graphql = `{
        fetchDeveloper(developer_id: ${developer_id}) {
            ${GQDeveloperFields}
            feedsCount
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchDeveloper: Developer }>(graphql);
    return data.fetchDeveloper;
};

export const saveItem = async (developer_id: number, developer: Developer): Promise<number> => {
    const graphql = `mutation { 
        updateDeveloper(developer_id: ${developer_id}, developerDiff: ${objectToGraphql(developer)})
    }`;

    const data = await fetchApi.postGQ<{ updateDeveloper: number }>(graphql);
    return data.updateDeveloper;
};

export const createItem = async (developer: Partial<Developer>): Promise<number> => {
    const graphql = `mutation { 
        createDeveloper(developer: ${objectToGraphql(developer)})
    }`;

    const data = await fetchApi.postGQ<{ createDeveloper: number }>(graphql);
    return data.createDeveloper;
};

export const fetchList = async (): Promise<{ list: Developer[]; count: number }> => {
    const graphql = `{ 
        fetchDevelopers {
            ${GQDeveloperFields}  
        }
    }`;

    const data = await fetchApi.getGQ<{ fetchDevelopers: Developer[] }>(graphql);
    return {
        list: data.fetchDevelopers,
        count: data.fetchDevelopers.length
    };
};
