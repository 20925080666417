import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'semantic-ui-react';
import statisticStore from '~/stores/statisticStore';
import kpiStore from '~/stores/kpiStore';

import TableStatistics from '../../Analytics/TableStatistics';
import commonStore from '~/stores/commonStore';
import UsersAndGroupsDropdown from '../../Lists/Common/UsersAndGroupsDropdown';

type StatisticsModalProps = {
    onClose: (event: React.SyntheticEvent) => void;
};

@observer
class StatisticsModal extends Component<StatisticsModalProps> {
    render() {
        const { onClose } = this.props;
        const { mainTableKpi } = kpiStore;

        return (
            <Modal dimmer="inverted" open closeIcon onClose={onClose} size="fullscreen">
                <Modal.Header className="crm-Statistics__modal_header">
                    Сводная таблица показателей
                    {!commonStore.isMobile && (
                        <div className="crm-Statistics__modal_groupDropdown">
                            {!mainTableKpi && <UsersAndGroupsDropdown store={statisticStore} disableUsers />}
                            {mainTableKpi && <UsersAndGroupsDropdown disableUsers store={kpiStore} />}
                        </div>
                    )}
                </Modal.Header>

                <Modal.Content>
                    <TableStatistics />
                </Modal.Content>
            </Modal>
        );
    }
}

export default StatisticsModal;
