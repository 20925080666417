import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import wishStore from '~/stores/wishStore';
import { WishLinkState } from '../../Lists/Wishes/WishLink';

class WishTabMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(wishStore, WishLinkState, WishTabMenu, {
    callEventModal: true
});
