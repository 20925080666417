import React, { PureComponent, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';

import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import dealStore from '~/stores/dealStore';
import { DealLinkState } from '../../Lists/Deals/DealLink';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import history from '../../../history';

class DealTabMenu extends PureComponent<TabMenuWrapperProps> {
    handleAddDeal = () => {
        const { item_id } = this.props;
        history.push({
            pathname: `/deals/sell/overview/${CREATING_ITEM_ID}`,
            state: {
                modal: true,
                from: { storeName: 'dealStore', item_id }
            }
        });
    };

    render() {
        return (
            <Fragment>
                <MenuItem onClick={this.handleAddDeal}>
                    <Icon name="money" />
                    Прикрепить сделку
                </MenuItem>
            </Fragment>
        );
    }
}

export default TabMenuWrapper(dealStore, DealLinkState, DealTabMenu, {
    attachDocuments: true,
    showDocumentsForAll: true
});
