import { action, observable } from 'mobx';
import * as kpiApi from '~/api/kpiApi';
import ItemListWithPultStore from './prototypes/ItemListWithPultStore.prototype';
import { KpiStatistics, KpiStoreFilter, KpiType } from '~/types/kpi.types';
import settingStore from '~/stores/settingStore';
import debounce from '~/common/debounce';
import authStore from '~/stores/authStore';

const KPIS_LIST_KEY = 'KPIS_LIST';

type KpiStoreProperty = {
    statistics: KpiStatistics[];
    statisticsErrors: string[];
    statisticsLoading: boolean;
};

class KpiStore extends ItemListWithPultStore<KpiType, KpiType, KpiStoreProperty, KpiStoreFilter> {
    LIST_MEM_KEY = KPIS_LIST_KEY;

    listFilterClear = {
        kpi_id: null,
        major_user_id: [],
        group_id: []
    };

    constructor() {
        super('kpi_id', settingStore.moduleName, kpiApi);
        authStore.registerInitFunc(this.loadMainTableKpi.bind(this));

        this.clearFilter();
    }

    @observable
    loadingMainTable = true;
    @observable
    mainTableKpi: number | null = null;

    async loadMainTableKpi() {
        this.mainTableKpi = await kpiApi.fetchMainTableKpi();
        this.loadingMainTable = false;
    }

    validationItem(kpi: Partial<KpiType>): string[] {
        const errors = [];

        if (!kpi.title) {
            errors.push('Задайте название kpi');
        }

        return errors;
    }

    debounceFilterFetch = debounce(() => {
        this.fetchKpiTable(this.listFilter.kpi_id);
    }, 350);

    @action
    async deleteKpiPoint(kpi_id: number, pointIndex: number): Promise<void> {
        this.changeArrayValue(kpi_id, 'points', pointIndex, 'enable', false);
        await this.saveItem(kpi_id);
    }

    @action
    async fetchKpiTable(kpi_id: number): Promise<void> {
        this.setProperty(kpi_id, { statistics: [], statisticsErrors: [], statisticsLoading: true });
        try {
            const statistics = await kpiApi.fetchKpiStatistics(kpi_id, this.listFilter);
            this.setProperty(kpi_id, { statistics });
        } catch (statisticsErrors) {
            this.setProperty(kpi_id, { statisticsErrors });
        } finally {
            this.setProperty(kpi_id, { statisticsLoading: false });
        }
    }

    @action
    async saveItem(item_id: number): Promise<boolean> {
        const result = await super.saveItem(item_id);
        if (result) {
            this.fetchKpiTable(item_id);
        }
        return result;
    }
}

export default new KpiStore();
