import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { Icon } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import './bottom.less';
import bottomTabStore from '~/stores/bottomTabStore';
import ItemLink, { matchIconName } from '../Base/ItemLink';

@observer
class BottomTabs extends Component<{}> {
    render() {
        const { tabs } = bottomTabStore;

        return (
            <Fragment>
                {tabs.map(({ item_id, item_type, item }) => (
                    <div key={`${item_type}_${item_id}`} className="crm-Bottom__tab">
                        <Icon name={matchIconName(item_type)} />
                        <ItemLink item_type={item_type} item={item} />
                        &nbsp;
                        <Tooltip title="Закрыть вкладку">
                            <CloseIcon
                                fontSize="small"
                                className="crm-Bottom__remove"
                                onClick={bottomTabStore.removeTab.bind(bottomTabStore, item_type, item_id)}
                            />
                        </Tooltip>
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default BottomTabs;
