import fetchApi, { grabMessageFromError } from '../common/fetchApi';

import { objectToGraphql } from '../common/graphql';
import { Tag } from '~/types/contacts.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const TagGQLFields = `
    tag_id
    title
    color
    description
    createTime
    updateTime
    enable
`;

export const createItem = async (tag: Tag): Promise<number> => {
    const graphql = `mutation { 
          createTag(inputTag: ${objectToGraphql(tag)})
        }`;

    const data: { createTag: number } = await fetchApi.postGQ(graphql);
    return data.createTag;
};

export const saveItem = async (tag_id: number, tagDiff: Tag): Promise<number> => {
    const graphql = `mutation { 
        updateTag(tag_id: ${tag_id}, tagDiff: ${objectToGraphql(tagDiff)})
    }`;

    const data: { updateTag: number } = await fetchApi.postGQ(graphql);
    return data.updateTag;
};

export const fetchItem = async (id: number): Promise<Tag> => {
    const graphql = `{
            fetchTag(tag_id: ${id}) {
                ${TagGQLFields}
            } 
          }`;

    const data: { fetchTag: Tag } = await fetchApi.getGQ(graphql);
    return data.fetchTag;
};

export const fetchList = async (): Promise<{ list: Array<Tag>; count: number }> => {
    const graphql = `{
            fetchTags{
                list {
                    ${TagGQLFields}
                }
                count
            }
          }`;

    const data: { fetchTags: { list: Tag[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchTags;
};

export const fetchAllTags = async (): Promise<Array<Tag>> => {
    const graphql = `{
            fetchAllTags{
                ${TagGQLFields}
            }
          }`;

    const data: { fetchAllTags: Tag[] } = await fetchApi.getGQ(graphql);
    return data.fetchAllTags;
};
