import React, { Component, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import mortgageL10n from '~/L10n/mortgage.L10n';

import Typography from '@material-ui/core/Typography';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import TextInput from '~ui/TextInput';
import documentStore from '~/stores/documentStore';
import config from '~/config/ui.config';
import Time from '../Base/Time';
import Confirm from '../Base/ui/Confirm';
import MonoString from '../Base/MonoString';
import UserLinkWithPreview from '../Lists/Users/UserLinkWithPreview';

import { TableCell, TableRow } from '~ui/Table';
import Button from '~ui/Button';
import Select from '~ui/Select';

type DocumentRowState = {
    editing: boolean;
    askDeletingModal: boolean;
};

@observer
export default class DocumentRow extends Component<{ index: number }, DocumentRowState> {
    state = {
        editing: false,
        askDeletingModal: false
    };

    handleChangeDir = (event: SyntheticEvent, { value }: { value: number }) => {
        const { index } = this.props;
        documentStore.editDocument(index, { dir_id: value }, true);
    };

    handleChangeDocType = (event: SyntheticEvent, { value }: { value: number }) => {
        const { index } = this.props;
        documentStore.editDocument(index, { documentType: value }, true);
    };

    handleChangeTitle = (event: SyntheticEvent, { value }: { value: string }) => {
        documentStore.editDocument(this.props.index, { title: value });
    };

    handleEditMode = (event: SyntheticEvent) => {
        this.setState({ editing: !this.state.editing });
    };

    handleSaveDoc = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        documentStore.saveDocument(this.props.index);
        this.setState({ editing: false });
    };

    deleteDoc = () => {
        documentStore.deleteDocument(this.props.index);
    };

    handleToggleAskingModal = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ askDeletingModal: !this.state.askDeletingModal });
    };

    render() {
        const { editing, askDeletingModal } = this.state;
        const { index } = this.props;
        const { documentDirs, loadingDirs, documents, documentDirsDropdown } = documentStore;

        if (documents.length <= index) {
            return null;
        }

        const { title, dir_id, documentType, doc_id, originalname, filename, major_user, createTime } = documents[index];

        const dir = documentDirs.find(dir => dir.dir_id === dir_id);
        if (!dir) {
            throw new Error('Dir didn`t find');
        }

        return (
            <>
                <TableRow>
                    <TableCell>
                        {!editing && (
                            <a
                                href={`${config.documentsDir}?filename=${filename}&doc_id=${doc_id}`}
                                target="_blank"
                                title="Просмотреть"
                                style={{ display: 'flex', alignItems: 'center' }}
                                rel="noreferrer"
                            >
                                <SearchIcon fontSize="small" />
                                <MonoString template="?" values={[title || originalname]} />
                            </a>
                        )}
                        {editing && <TextInput size="small" value={title} onChange={this.handleChangeTitle} />}
                    </TableCell>
                    <TableCell>
                        {!editing && <Typography variant="body1">{mortgageL10n.DOCUMENT_TYPE_FIND(documentType)}</Typography>}
                        {editing && (
                            <Select
                                label="Документ"
                                fullWidth
                                value={documentType}
                                onChange={this.handleChangeDocType}
                                options={mortgageL10n.DOCUMENT_TYPE_DROPDOWN}
                                loading={loadingDirs}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        {!editing && (
                            <>
                                <UserLinkWithPreview user={major_user} />
                                <Time time={createTime} />
                            </>
                        )}
                        {editing && (
                            <Select
                                value={dir_id}
                                label="Папка"
                                onChange={this.handleChangeDir}
                                options={documentDirsDropdown}
                                loading={loadingDirs}
                            />
                        )}
                    </TableCell>
                    <TableCell /*textAlign="right"*/>
                        {!editing && (
                            <>
                                <Button
                                    icon={<EditIcon fontSize="small" color="action" />}
                                    tooltip="Редактировать"
                                    onClick={this.handleEditMode}
                                />

                                <Button
                                    icon={<DeleteForeverIcon fontSize="small" color="error" />}
                                    tooltip="Удалить"
                                    onClick={this.handleToggleAskingModal}
                                />

                                <Button
                                    href={`${config.documentsDir}?filename=${filename}&download=true&doc_id=${doc_id}`}
                                    target="_blank"
                                    icon={<CloudDownloadIcon fontSize="small" color="primary" />}
                                    tooltip="Скачать"
                                />
                            </>
                        )}
                        {editing && (
                            <>
                                <Button
                                    onClick={this.handleEditMode}
                                    icon={<CloseIcon fontSize="small" color="secondary" />}
                                    tooltip="Отменить"
                                />
                                <Button
                                    onClick={this.handleSaveDoc}
                                    icon={<DoneIcon fontSize="small" color="primary" />}
                                    tooltip="Сохранить"
                                />
                            </>
                        )}
                    </TableCell>
                </TableRow>
                {askDeletingModal && (
                    <Confirm
                        header={'Удаление документа'}
                        content="Вы действительно хотите удалить этот документ?"
                        confirmButton="Удалить"
                        onConfirm={this.deleteDoc}
                        onCancel={this.handleToggleAskingModal}
                    />
                )}
            </>
        );
    }
}
