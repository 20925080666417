import React, { PureComponent, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import TabMenuWrapper from '../TabMenuWrapper';
import contactStore from '~/stores/contactStore';
import { ContactLinkState } from '../../Lists/Contacts/ContactLink';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import MergeContactsModal from './Modals/MergeContactsModal';
import authStore from '~/stores/authStore';
import MajorUserPopup from '../Common/MajorUserPopup';

type ContactTabMenuState = {
    showMergeContactModal: boolean;
    majorUserPopupOpen: boolean;
};

class ContactTabMenu extends PureComponent<TabMenuWrapperProps, ContactTabMenuState> {
    state = {
        majorUserPopupOpen: false,
        showMergeContactModal: false
    };

    handleMergeContactModal = () => {
        this.setState({ showMergeContactModal: !this.state.showMergeContactModal });
    };

    handleMajorUserPopupOpen = () => {
        this.setState({ majorUserPopupOpen: true });
    };

    handleSaveMajorUser = () => {
        contactStore.saveItem(this.props.item_id);
        this.handleMajorUserPopupClose();
    };

    handleMajorUserPopupClose = () => {
        this.setState({ majorUserPopupOpen: false });
    };

    render() {
        const { item_id } = this.props;
        const { showMergeContactModal, majorUserPopupOpen } = this.state;

        const { item } = contactStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { major_user_ids } = item;

        const canDelete = authStore.canDelete(contactStore.moduleName, [...major_user_ids]);
        const canEdit = authStore.canEdit(contactStore.moduleName, [...major_user_ids]);

        return (
            <Fragment>
                {canDelete && canEdit && (
                    <Fragment>
                        <Divider style={{ margin: '5px 0' }} />
                        <MenuItem onClick={this.handleMergeContactModal}>
                            <Icon name="address book outline" />
                            Объединить с другим контактом
                        </MenuItem>
                    </Fragment>
                )}

                {canEdit && (
                    <Fragment>
                        <Divider style={{ margin: '5px 0' }} />
                        <MenuItem onClick={this.handleMajorUserPopupOpen}>
                            <Icon name="user" />
                            Поменять ответственных
                        </MenuItem>
                    </Fragment>
                )}

                {majorUserPopupOpen && (
                    <MajorUserPopup
                        major_user_id={major_user_ids}
                        handleMajorUserPopupClose={this.handleMajorUserPopupClose}
                        handleMajorUserPopupOpen={this.handleMajorUserPopupOpen}
                        handleSaveMajorUser={this.handleSaveMajorUser}
                        item_id={item_id}
                    />
                )}

                {showMergeContactModal && <MergeContactsModal contact_id={item_id} onClose={this.handleMergeContactModal} />}
            </Fragment>
        );
    }
}

export default TabMenuWrapper(contactStore, ContactLinkState, ContactTabMenu, {
    attachDocuments: true,
    callEventModal: true,
    wrappedOnBottom: true
});
