import React, { useState } from 'react';
import Lightbox from 'react-images';

const PhotoGalleryLightbox = ({
    photoSet,
    initPhotoIndex,
    onClose
}: {
    photoSet: { src: string }[];
    onClose: () => void;
    initPhotoIndex: number;
}) => {
    console.log('initPhotoIndex', initPhotoIndex);
    const [currentImage, setCurrentImage] = useState(initPhotoIndex);
    const handleImageNumber = (index: number) => {
        setCurrentImage(index);
    };

    const handlePrevImage = () => {
        setCurrentImage(currentImage - 1);
    };
    const handleNextImage = () => {
        setCurrentImage(currentImage + 1);
    };

    return (
        <Lightbox
            images={photoSet}
            isOpen={true}
            onClose={onClose}
            onClickPrev={handlePrevImage}
            onClickNext={handleNextImage}
            currentImage={currentImage}
            imageCountSeparator=" из "
            leftArrowTitle="Предыдущая (клавиша: стрелка влево)"
            rightArrowTitle="Следующая (клавиша: стрелка вправо)"
            showThumbnails={true}
            onClickThumbnail={handleImageNumber}
        />
    );
};

export default PhotoGalleryLightbox;
