import React, { Fragment } from 'react';
import { Popup } from 'semantic-ui-react';
import estateL10n from '../../../../L10n/estate.L10n';
import estateStore from '~/stores/estateStore';
import MetroLogo from '../../../Base/MetroLogo';

const MetroStation = ({ metro_id }: { metro_id: number }) => {
    const { color, name } = estateStore.findMetroById(metro_id);
    return (
        <span className="crm-Estate__metroList_itemShow" key={metro_id}>
            <MetroLogo color={`#${color || ''}`} /> {name}
        </span>
    );
};

const MAX_METRO_INLINE = 2;

const MetroList = ({
    transportType,
    transportTime,
    main_metro_id,
    enablePopup = false
}: {
    main_metro_id: Array<number>;
    transportType?: number | null;
    transportTime?: number | null;
    enablePopup?: boolean;
}) => (
    <Fragment>
        {transportTime && (
            <div>
                Не более {transportTime} минут {estateL10n.TRANSPORT_TYPES_FIND(transportType)}
            </div>
        )}

        {(!enablePopup || main_metro_id.length <= MAX_METRO_INLINE) &&
            main_metro_id.map(metro_id => <MetroStation key={metro_id} metro_id={metro_id} />)}

        {enablePopup && main_metro_id.length > MAX_METRO_INLINE && (
            <Popup
                size="small"
                hoverable
                wide
                trigger={
                    <span className="crm-User__linkList_trigger">
                        {main_metro_id.slice(0, MAX_METRO_INLINE).map(metro_id => (
                            <MetroStation key={metro_id} metro_id={metro_id} />
                        ))}
                        <b>{`+${main_metro_id.length - MAX_METRO_INLINE}`}</b>
                    </span>
                }
            >
                <div>
                    {main_metro_id.map(metro_id => (
                        <MetroStation key={metro_id} metro_id={metro_id} />
                    ))}
                </div>
            </Popup>
        )}
    </Fragment>
);

export default MetroList;
