import React from 'react';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import requestStore from '~/stores/requestStore';

const requestKeys = {};

const printRequestVal = (key: string, value: unknown): string => {
    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(requestStore, requestKeys, printRequestVal);
