import React, { Component, Fragment } from 'react';
import { Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import CallEventsTableHeaders from './CallEventsTableHeaders';
import CallEventsTableBody from './CallEventsTableBody';
import FilterCallEvents from './FilterCallEvents';

import ListFooter from '../Common/ListFooterNew';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import callEventStore from '~/stores/cell/callEventStore';
import ListError from '~/components/ListError';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Table, { TableHead } from '~ui/Table';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';

@observer
class CallEventsList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, nextListLoaded, pagination, listErrors } = callEventStore;

        return (
            <Container maxWidth="lg">
                <div className="crm-List">
                    <Box display="flex" alignItems="center">
                        <DateRangeIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                        <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                            Запланированные звонки
                        </Typography>
                    </Box>

                    {listErrors && <ListError errors={listErrors} />}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterCallEvents />

                    <Segment className="crm-List__segment">
                        <Table size="small">
                            <TableHead>
                                <ListPagination
                                    loading={loadingList}
                                    colSpan={3}
                                    pagination={pagination}
                                    listCount={listCount}
                                    pageChange={callEventStore.pageChange}
                                    pageSizeChange={callEventStore.pageSizeChange}
                                    nextListLoaded={nextListLoaded}
                                />
                            </TableHead>

                            <CallEventsTableHeaders orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <CallEventsTableBody callEventsList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={3}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={callEventStore.pageChange}
                                pageSizeChange={callEventStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Container>
        );
    }
}

export default ListWrapper(callEventStore, CallEventsList);
