import { action, computed } from 'mobx';
import * as requestApi from '~/api/requestApi';
import deepCopy from '../common/deepCopy';

import ListStorePrototype from './prototypes/ListStore.prototype';
import { RequestType, RequestFilter, RequestTableType } from '~/types/requests.types';
import estateStore from './estateStore';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import { REQUEST_LIST_HUNTING, REQUEST_TYPE_HUNTING } from '~/types/requests.types';
import { isEqualWithoutFields } from '~/common/isEqual';
import { HistoryChange } from '~/types/historyChanges.types';

type RequestItemPrototypeType = {
    loadingHistory: boolean;
    history: HistoryChange[];
};

class RequestStore extends ListStorePrototype<RequestType, RequestTableType, RequestItemPrototypeType, RequestFilter> {
    listFilterClear = {
        enable: true,
        status: [],
        group_id: [],
        major_user_id: [],
        type: 0,
        tags_ids: [],
        tagsTogether: false,

        startTime: null,
        endTime: null,
        deltaTime: null
    };

    orderBy = 'updateTime';

    constructor() {
        super('request_id', 'request', requestApi);
        this.clearFilter();
    }

    validationItem(request: Partial<RequestType>): Array<string> {
        const errors: Array<string> = [];

        if (!request.contact_id) {
            errors.push('Заполните контакт');
        }
        if (!request.requestJson) {
            errors.push('Заполните описание');
        }

        return errors;
    }

    @action
    async createItem(): Promise<number> {
        const { editingItem } = this.getItem(CREATING_ITEM_ID);

        if (editingItem.type && editingItem.type < 3) {
            const estate = estateStore.getItem(CREATING_ITEM_ID).editingItem;
            editingItem.requestObj = deepCopy(estate);
        }

        editingItem.requestJson = JSON.stringify(editingItem.requestObj);
        delete editingItem.requestObj;

        return await super.createItem();
    }

    @action
    setRouteFilter(param: string) {
        switch (param) {
            case REQUEST_LIST_HUNTING:
                this.listFilter['type'] = REQUEST_TYPE_HUNTING;
                break;
            default:
                this.listFilter['type'] = 0;
                break;
        }
    }

    @action
    clearFilter() {
        const type = this.listFilter && this.listFilter['type'];
        super.clearFilter();
        if (type) {
            this.listFilter['type'] = type;
        }
    }

    @computed get filterIsEmpty(): boolean {
        return isEqualWithoutFields(this.listFilter, this.listFilterClear, ['type']);
    }
}

export default new RequestStore();
