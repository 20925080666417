import React, { SyntheticEvent, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';
import { makeStyles } from '@material-ui/core/styles';

import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';

import TextField from '@material-ui/core/TextField';
import Select from '~ui/Select';
import Divider from '~ui/Divider';
import Progress from '~ui/Progress';
import cellOperatorStore from '~/stores/cell/cellOperatorStore';
import { CellOperatorType } from '~/types/cellCalls.types';

const BEELINE_ID = 1;
const MEGAFON_ID = 2;

const operatorsOptions = [
    { value: 0, text: 'Выберите оператора' },
    { value: BEELINE_ID, text: 'Билайн' },
    { value: MEGAFON_ID, text: 'Мегафон' }
];

const useStyles = makeStyles(({ spacing }) => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
        gridGap: spacing(2),
        gridAutoRows: 'min-content',
        alignItems: 'center'
    }
}));

const BeelineOptions = observer(
    ({
        cell_operator_id,
        handleChange
    }: {
        cell_operator_id: number;
        handleChange: (name: keyof CellOperatorType, value: unknown) => void;
    }) => {
        const { editingItem } = cellOperatorStore.getItem(cell_operator_id);

        return (
            <>
                <div>Токен</div>
                <div>
                    <TextField
                        label="Токен"
                        value={editingItem['token'] || ''}
                        onChange={event => handleChange('token', event.target.value)}
                        fullWidth
                    />
                </div>
            </>
        );
    }
);

const MegafonOptions = observer(
    ({
        cell_operator_id,
        handleChange
    }: {
        cell_operator_id: number;
        handleChange: (name: keyof CellOperatorType, value: unknown) => void;
    }) => {
        const { editingItem } = cellOperatorStore.getItem(cell_operator_id);
        const subdomain = window.location.host.split('.')[0];
        const callServerAddress = `https://data.agentbase.ru/calls/${subdomain}`;

        return (
            <>
                <div>Адрес Облачной АТС</div>
                <div>
                    <TextField value={editingItem['url'] || ''} onChange={event => handleChange('url', event.target.value)} fullWidth />
                </div>

                <div>Ключ для авторизации в Облачной АТС</div>
                <div>
                    <TextField value={editingItem['token'] || ''} onChange={event => handleChange('token', event.target.value)} fullWidth />
                </div>

                <div>Адрес вашей CRM</div>
                <div>
                    <TextField
                        InputProps={{
                            readOnly: true
                        }}
                        value={callServerAddress}
                        fullWidth
                    />
                </div>

                <div>Ключ для авторизации в вашей CRM</div>
                <div>
                    <TextField
                        InputProps={{
                            readOnly: true
                        }}
                        value={editingItem['externalUserId']}
                        fullWidth
                    />
                </div>
            </>
        );
    }
);

const cellOperatorEmpty = {
    operator: 0,
    name: '',
    token: '',
    externalUserId: null,
    url: null
} as const;

const CellOperatorsSettingsModal = ({ cell_operator_id, onClose }: { cell_operator_id: number; onClose: () => void }) => {
    const classes = useStyles();

    useMemo(() => {
        cellOperatorStore.fetchItem(cell_operator_id);

        if (cell_operator_id === CREATING_ITEM_ID) {
            cellOperatorStore.setEditingItem(cell_operator_id, cellOperatorEmpty);
        }
    }, []);

    const handleChangeOperator = (event: SyntheticEvent, { value }: { value: number }) => {
        cellOperatorStore.setEditingItem(cell_operator_id, cellOperatorEmpty);
        handleChange('operator', value);
        if (value === MEGAFON_ID) {
            const externalUserId = Math.random().toString(36).substring(2);
            handleChange('externalUserId', externalUserId);
            handleChange('name', 'Мегафон');
        } else {
            handleChange('name', 'Билайн');
        }
    };

    const handleChange = useCallback((name: keyof CellOperatorType, value: unknown) => {
        cellOperatorStore.setEditingItem(cell_operator_id, {
            [name]: value
        });
    }, []);

    const commitChanges = (cell_operator_id: number) => {
        const { errors } = cellOperatorStore.getItem(cell_operator_id);
        if (!errors.length) {
            onClose();
            cellOperatorStore.fetchList();
        }
    };

    const handleSave = async () => {
        if (cell_operator_id === CREATING_ITEM_ID) {
            await cellOperatorStore.createItem();
        } else {
            await cellOperatorStore.saveItem(cell_operator_id);
        }

        commitChanges(cell_operator_id);
    };

    const handleDelete = async () => {
        cellOperatorStore.setEditingItem(cell_operator_id, {
            enable: false
        });
        await cellOperatorStore.saveItem(cell_operator_id);

        commitChanges(cell_operator_id);
    };

    const { editingItem, loadingItem, errors } = cellOperatorStore.getItem(cell_operator_id);

    const modalActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleSave,
            label: cell_operator_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            disabled: loadingItem,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (cell_operator_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            disabled: loadingItem,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            loading={loadingItem}
            header={
                <>
                    <SettingsPhoneIcon /> &nbsp; Оператор мобильной связи
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                <div className={classes.grid}>
                    <div>Оператор</div>
                    <div>
                        <Select value={editingItem['operator'] || 0} onChange={handleChangeOperator} options={operatorsOptions} fullWidth />
                    </div>
                    <div>Название (внутри СРМ)</div>
                    <div>
                        <TextField
                            value={editingItem['name'] || ''}
                            onChange={event => handleChange('name', event.target.value)}
                            fullWidth
                        />
                    </div>
                </div>
                {editingItem['operator'] > CREATING_ITEM_ID && <Divider />}
                <div className={classes.grid}>
                    {editingItem['operator'] === BEELINE_ID && (
                        <BeelineOptions cell_operator_id={cell_operator_id} handleChange={handleChange} />
                    )}
                    {editingItem['operator'] === MEGAFON_ID && (
                        <MegafonOptions cell_operator_id={cell_operator_id} handleChange={handleChange} />
                    )}
                </div>

                {loadingItem && <Progress show />}
            </ModalContent>
        </Modal>
    );
};

export default observer(CellOperatorsSettingsModal);
