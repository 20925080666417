import { objectToGraphql } from '~/common/graphql';
import fetchApi from '../../common/fetchApi';
import { MortgageRequest } from '~/types/mortgageRequest.types';
import { GQUserMainFields } from '~/api/userApi';

const GQMortgageRequest = `
    mortgage_request_id
    contact_id
    
    proof_of_income
    insurance_scheme
    apartment_type
    customer_type
    credit_term
    initial_fee
    apartment_price
    credit_amount
    has_maternal_capital
    comment
    
    mortgage_person_ids

    major_user_id
    major_user {
        ${GQUserMainFields}
    }
    
    createTime
    updateTime
    enable   
`;

export const fetchItem = async (mortgage_request_id: number): Promise<MortgageRequest> => {
    const graphql = `{
        fetchMortgageRequest(mortgage_request_id: ${mortgage_request_id}) {
            ${GQMortgageRequest}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchMortgageRequest: MortgageRequest }>(graphql);
    return data.fetchMortgageRequest;
};

export const createItem = async ({ contact_id }: { contact_id: number }): Promise<number> => {
    const graphql = `mutation { 
          createMortgageRequest(contact_id: ${contact_id})
        }`;

    const data = await fetchApi.postGQ<{ createMortgageRequest: number }>(graphql);
    return data.createMortgageRequest;
};

export const saveItem = async (mortgage_request_id: number, mortgageRequestInput: MortgageRequest): Promise<number> => {
    const graphql = `mutation { 
        updateMortgageRequest(mortgage_request_id: ${mortgage_request_id}, mortgageRequestInput: ${objectToGraphql(mortgageRequestInput)})
    }`;

    const data = await fetchApi.postGQ<{ updateMortgageRequest: number }>(graphql);
    return data.updateMortgageRequest;
};
