import React from 'react';
import { EstateLinksProps } from './EstateLink';
import EstateLink from './EstateLink';
import EstatePreview from '../../Items/Estate/EstatePreview';

const EstateLinkWithPreview = ({ estate, base, textNoWrap, isForeign }: EstateLinksProps) => (
    <EstatePreview
        item_id={estate.estate_id}
        base={base}
        trigger={<EstateLink estate={estate} textNoWrap={textNoWrap} base={base} isForeign={isForeign} />}
    />
);

export default EstateLinkWithPreview;
