import React, { useState, useRef } from 'react';

import { observer } from 'mobx-react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { TariffPriceHeaderOptions } from '~/types/exports.types';

import SettingsIcon from '@material-ui/icons/Settings';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';

import Button from '~ui/Button';
import Checkbox from '~ui/Checkbox';
import { useToggle } from '~ui/Modal';
import Grid from '~ui/Grid';
import TextInput, { useHandleChange } from '~ui/TextInput';
import Select from '~ui/Select';

import { MIN_DAYS_OPTIONS } from '~/components/Ads/Export/ExportFeedTariffsPrices';

const TariffPriceHeader = ({
    index,
    export_base_id,
    options
}: {
    index: number;
    export_base_id: number;
    options: TariffPriceHeaderOptions;
}) => {
    const $openBtn = useRef(null);
    const [open, toggleOpen] = useToggle();
    const [updating, setUpdating] = useState(false);

    const [name, setName] = useHandleChange<string>(options.name);
    const [minDays, handleMinDays] = useHandleChange<number>(options.minDays);
    const [isPremium, togglePremium] = useToggle(options.isPremium);

    const handleSave = async () => {
        setUpdating(true);
        await exportingBaseStore.updateTariffPrice(export_base_id, index, { name, isPremium, minDays });
        exportingBaseStore.fetchItem(export_base_id);
    };

    return (
        <>
            <Button size="small" icon={<SettingsIcon />} tooltip="Настройки тарифа" ref={$openBtn} onClick={toggleOpen} />
            <Popover
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={$openBtn.current}
                onClose={toggleOpen}
            >
                <Box p={2} maxWidth={300}>
                    <Grid spacing={1}>
                        <Grid.Column>
                            <TextInput
                                value={name}
                                onChange={setName}
                                label="Наименование"
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled={updating}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Select label="Период оплаты" fullWidth value={minDays} options={MIN_DAYS_OPTIONS} onChange={handleMinDays} />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox size="small" checked={isPremium} label="Премиальный тариф" onClick={togglePremium} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button color="secondary" variant="outlined" fullWidth onClick={handleSave} loading={updating}>
                                Применить
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default observer(TariffPriceHeader);
