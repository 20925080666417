import { observable, action, reaction, runInAction } from 'mobx';
import { ITEM_TYPES, ITEM_TYPES_OBJECT } from '~/types/notifications.types';

export type BottomTabType = {
    item_id: number;
    item_type: ITEM_TYPES;
    item: ITEM_TYPES_OBJECT;
};

const bottomTabsMemKey = 'bottomTabs';

class BottomTabStore {
    moduleName = 'bottomTabs';

    @observable
    tabs: BottomTabType[] = [];

    constructor() {
        window.addEventListener('storage', this.setTabsFromStorage);
        this.setTabsFromStorage();
    }

    @action
    addTab(tab: BottomTabType) {
        if (!this.tabs.find(({ item_id, item_type }) => item_id === tab.item_id && item_type === tab.item_type)) {
            this.tabs.push(tab);
            this.setTabsToStorage();
        }
    }

    @action
    removeTab(item_type: ITEM_TYPES, item_id: number) {
        const findIndex = this.tabs.findIndex(tab => item_id === tab.item_id && item_type === tab.item_type);
        if (~findIndex) {
            this.tabs.splice(findIndex, 1);
            this.tabs = [...this.tabs];
            this.setTabsToStorage();
        }
    }

    setTabsToStorage = () => {
        window.localStorage.setItem(bottomTabsMemKey, JSON.stringify(this.tabs));
    };

    setTabsFromStorage = () => {
        const memoryTabsJSON = window.localStorage.getItem(bottomTabsMemKey);
        if (memoryTabsJSON) {
            this.tabs = JSON.parse(memoryTabsJSON);
        }
    };
}

export default new BottomTabStore();
