import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from '~/common/isEqual';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';

export type StepType = { id: number; title: string; subtitle: string; icon: ReactElement };

const useStyles = makeStyles(({ palette, spacing }) => ({
    active: {
        background: palette.grey[100]
    },
    rootMin: {
        padding: spacing(0.5),
        overflow: 'hidden'
    }
}));

const Steps = ({
    activeStep,
    steps,
    completedSteps,
    setActiveStep,
    collapsed = false,
    secondary
}: {
    activeStep: number;
    steps: StepType[];
    completedSteps: boolean[];
    setActiveStep: (stepId: number) => void;
    collapsed?: boolean;
    secondary?: boolean;
}) => {
    const classes = useStyles();
    const activeColor = secondary ? 'secondary' : 'primary';

    return (
        <Stepper nonLinear activeStep={activeStep} className={secondary ? classes.rootMin : null}>
            {steps.map(({ id, subtitle, title, icon }) => {
                let isActive = activeStep === id;
                let iconAdjusted = React.cloneElement(icon, {
                    color: isActive ? activeColor : 'inherit',
                    fontSize: secondary ? 'default' : 'large'
                });

                return (
                    <Step key={title}>
                        <StepButton
                            onClick={() => setActiveStep(id)}
                            completed={completedSteps[id]}
                            icon={completedSteps[id] ? <DoneIcon fontSize="large" color={activeColor} /> : iconAdjusted}
                            optional={!collapsed ? <Typography variant="caption">{subtitle}</Typography> : null}
                            className={isActive ? classes.active : null}
                        >
                            {!collapsed && (
                                <StepLabel>
                                    <Typography variant="subtitle2" color={isActive ? activeColor : 'inherit'}>
                                        {title}
                                    </Typography>
                                </StepLabel>
                            )}
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default React.memo(Steps, isEqual);
