import React, { ChangeEvent, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, ModalContent } from '~ui/Modal';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(({ palette }: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: palette.background.paper,
        display: 'flex',
        minHeight: 324
    },
    leftBox: {
        borderRight: `1px solid ${palette.divider}`
    },
    tabs: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    button: {
        padding: '12px'
    },
    tabPanel: {
        padding: '0 1rem',
        width: '100%'
    }
}));

type ListTabsEditingModalProps = {
    onClose: () => void;
    onCreate: () => void;
    tabs: { title: string; id: number }[];
    children: ReactNode;
    tabId: number;
    onChangeTabId: (tabId: number) => void;
    header: string;
    maxWidth?: 'sm' | 'md';
};

const ListTabsEditingModal = ({ onClose, maxWidth, tabs, children, onCreate, tabId, onChangeTabId, header }: ListTabsEditingModalProps) => {
    const classes = useStyles();

    const handleActiveBoard = (event: ChangeEvent, activeTab: number) => {
        onChangeTabId(activeTab);
    };

    return (
        <Modal fullWidth maxWidth={maxWidth || 'sm'} header={header} onClose={onClose}>
            <ModalContent dividers>
                <div className={classes.root}>
                    <div className={classes.leftBox}>
                        {tabs.length > 0 && (
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={tabId}
                                onChange={handleActiveBoard}
                                className={classes.tabs}
                            >
                                {tabs.map(({ title, id }) => (
                                    <Tab label={title} key={id} value={id} />
                                ))}
                            </Tabs>
                        )}
                        <div className={classes.tabs}>
                            <Button variant="text" color="primary" className={classes.button} startIcon={<AddIcon />} onClick={onCreate}>
                                Добавить
                            </Button>
                        </div>
                    </div>

                    <div role="tabpanel" className={classes.tabPanel}>
                        {children}
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );
};

export default React.memo(ListTabsEditingModal);
