import { observable, action, runInAction, computed } from 'mobx';
import * as userApi from '~/api/userApi';
import ListWithContactsStorePrototype from './prototypes/listWithContactsStore.prototype';
import { User, UserFilter, UserGroup, UserTableItem, UserBalanceInfo } from '~/types/users.types';
import deepCopy from '~/common/deepCopy';
import config from '~/config/ui.config';
import { UserLinkState } from '~/components/Lists/Users/UserLink';
import wait from '~/common/wait';

import authStore from './authStore';
import globalSearchStore from './globalSearchStore';
import * as exportsApi from '~/api/export/exportsApi';
import { ExportUserDay } from '~/types/exports.types';
import { SearchModuleResult } from './globalSearchStore';
import { DropdownType, OrderDirectionType, PaginationType } from './prototypes/ListStore.prototype';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import { catchItemError } from './helpers/decorators.helpers';
import { EstateFilter, EstateTableItem } from '~/types/estate.types';
import * as estateApi from '~/api/estateApi';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import estateStore from './estateStore';
import escapedRegExp from '../common/escapedRegExp';

export type UserItemTypeProperty = {
    loadingExportHistory: boolean;
    exportHistory: ExportUserDay[];
    exportHistoryCount: number;
    exportHistoryPagination: PaginationType;

    imageData?: any;
    loadingUserBalance: boolean;
    userBalanceToTime: number;
    userBalanceInfo: UserBalanceInfo;

    loadingEstates: boolean;
    estates: EstateTableItem[] | null;
};

class UserStore extends ListWithContactsStorePrototype<User, UserTableItem, UserItemTypeProperty, UserFilter> {
    orderDirection: OrderDirectionType = 'ascending';

    listFilterClear = {
        major_user_id: [],
        group_id: [],
        search: '',
        onlineOnly: false,
        enable: true,
        access_id: null,
        fromDateUserDismissal: null,
        toDateUserDismissal: null,
        fromDateUserInState: null,
        toDateUserInState: null
    };

    constructor() {
        super('user_id', 'user', userApi);
        this.clearFilter();

        globalSearchStore.regModule({
            title: 'Сотрудники',
            moduleName: this.moduleName,
            search: this.globalSearchList
        });

        authStore.registerInitFunc(this.fetchGroups.bind(this));
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        let search = query;
        const numberString = search.replace(/\D/g, '');
        if (numberString.length * 2 > search.length && query.length > 5) {
            search = numberString;
        }

        const queryRegexp = escapedRegExp(search);

        const list = authStore.userAndGroups
            .filter(({ firstName, lastName, user_id, email, phones }) =>
                Number(search)
                    ? user_id === Number(search) || phones.some(({ phone }) => phone.includes(search))
                    : `${lastName} ${firstName} ${lastName} ${email}`.match(queryRegexp)
            )
            .map(({ group_id, firstName, lastName, user_id, avatarUrl }) => {
                let description = '';
                const group = userApi.findGroupById(this.groups, group_id);
                if (group) {
                    description = group.name;
                }

                return {
                    key: user_id,
                    title: `${lastName} ${firstName}`,
                    description,
                    image: avatarUrl || config.defaultAvatarUrl,
                    state: UserLinkState(user_id),
                    className: this.moduleName
                };
            });

        await wait(0);

        return {
            list: list.slice(start, limit),
            count: list.length
        };
    };

    @action
    mergeItem(id: number, item: Partial<User>) {
        const { group_id } = this.getItem(id).editingItem;
        const newItem = deepCopy({
            ...item,
            group: userApi.findGroupById(this.groups, Number(group_id))
        });
        super.mergeItem(id, newItem);
    }

    findGroupById(group_id: number): UserGroup | null {
        return userApi.findGroupById(this.groups, group_id);
    }

    @computed
    get getOfficesDropdown(): DropdownType[] {
        return this.groups.map(({ group_id, name }) => ({ key: group_id, value: group_id, text: name }));
    }

    @computed
    get getGroupsDropdown(): DropdownType[] {
        return [
            ...this.getOfficesDropdown,
            ...this.allSubGroups.map(({ group_id, name }) => ({ key: group_id, value: group_id, text: name }))
        ];
    }

    async uploadPhoto(user_id: number, newUserId = 0) {
        const { imageData } = this.getItem(user_id).property;
        if (!imageData) {
            throw Error(`Image doesn't exist`);
        }
        const canvas = imageData.getImage();
        const canvasScaled = imageData.getImageScaledToCanvas();

        const imagebase64 = canvas.toDataURL();
        const avatar_imagebase64 = canvasScaled.toDataURL();

        return await userApi.loadAvatar(newUserId || user_id, imagebase64, avatar_imagebase64);
    }

    @action
    async loadAvatar(user_id: number) {
        runInAction(() => {
            this.getItem(user_id).loadingItem = true;
        });

        const photos = await this.uploadPhoto(user_id);
        await this.saveItem(user_id);

        this.mergeItem(user_id, photos);
        runInAction(() => {
            this.getItem(user_id).loadingItem = false;
        });
    }

    @action
    async saveItem(user_id: number): Promise<boolean | Partial<User>> {
        const { editingItem } = this.getItem(user_id);
        if (editingItem['grant_users_ids']) {
            const grantUsersIds = editingItem['grant_users_ids'].map(user_id => authStore.findUserById(user_id));
            this.mergeItem(user_id, { grantUsersIds });
        }
        if (editingItem['grant_users_under_ids']) {
            const grantUsersUnderIds = editingItem['grant_users_under_ids'].map(user_id => authStore.findUserById(user_id));
            this.mergeItem(user_id, { grantUsersUnderIds });
        }

        return super.saveItem(user_id);
    }

    @observable groups: UserGroup[] = [];
    @observable loadingGroups: boolean = false;

    @action
    async fetchGroups() {
        const memKey = 'allGroups';
        const memJSON = localStorage.getItem(memKey);
        if (memJSON) {
            this.groups = JSON.parse(memJSON);
            authStore.adjustSubgroups(this.groups);
        } else {
            this.loadingGroups = true;
        }

        this.groups = await userApi.fetchGroups();
        authStore.adjustSubgroups(this.groups);
        this.loadingGroups = false;
        localStorage.setItem(memKey, JSON.stringify(this.groups));
    }

    @computed get allSubGroups(): UserGroup[] {
        return this.groups.reduce((acc, { subgroups }) => {
            acc.push(...subgroups);
            return acc;
        }, []);
    }

    @action
    async editGroup(group_id: number, group: Partial<UserGroup>) {
        userApi.resetGroupsCache();
        await userApi.editGroup(group_id, group);
        await this.fetchGroups();
    }

    validationItem(user: User): Array<string> {
        const errors: Array<string> = [];

        if (!user.access_id) {
            errors.push('Укажите доступ');
        }
        if (!user.appointment) {
            errors.push('Заполните должность');
        }

        return errors;
    }

    @action
    async createItem(): Promise<number> {
        const emptyItem = this.getItem(CREATING_ITEM_ID);
        const user = emptyItem.editingItem;

        delete user.user_id;
        const photo = user.photo;
        delete user.photo;

        if (!user.birthday) {
            delete user.birthday;
        }

        user.phones = user.phones.map(({ phone }) => ({ phone, phone_id: CREATING_ITEM_ID }));

        const newUserId = await super.createItem();

        if (newUserId && photo) {
            await this.uploadPhoto(0, newUserId);
        }

        return newUserId;
    }

    @catchItemError
    async resetBalance(user_id: number, fromTime?: number | null) {
        const { item } = this.getItem(user_id);
        if (item) {
            this.setProperty(user_id, { loadingUserBalance: true });
            item.balance = await userApi.resetBalance(user_id, fromTime || null);

            this.getUserBalanceInfo(user_id);
            this.setProperty(user_id, { userBalanceToTime: 0 });
        }
    }

    @catchItemError
    async resetUserPayments(user_id: number) {
        const { item } = this.getItem(user_id);
        if (item) {
            this.setProperty(user_id, { loadingUserBalance: true });
            item.balance = await userApi.resetUserPayments(user_id);

            this.getUserBalanceInfo(user_id);
        }
    }

    @action
    async fetchItemDropdownOptions(search: string) {
        const { userAndGroups } = authStore;

        this.loadingDropdownOptions = true;

        this.itemDropdownOptions = userAndGroups
            .map(({ firstName, lastName, user_id, avatarUrl }) => ({
                text: `${firstName} ${lastName}`,
                value: user_id,
                key: user_id,
                image: { avatar: true, src: avatarUrl || config.defaultAvatarUrl }
            }))
            .filter(({ text }) => ~text.indexOf(search));

        runInAction(() => (this.loadingDropdownOptions = false));
    }

    @action
    async balanceHistoryByUser(user_id: number) {
        const exportHistoryPagination = this.getItem(user_id).property.exportHistoryPagination || getDefaultPagination();

        const { activePage, pageSize } = exportHistoryPagination;
        const offset = (activePage - 1) * pageSize;

        this.setProperty(user_id, { exportHistory: [], loadingExportHistory: true, exportHistoryPagination });

        const { list, count } = await exportsApi.fetchUserExportHistory(user_id, pageSize, offset);

        this.setProperty(user_id, {
            exportHistory: list,
            loadingExportHistory: false,
            exportHistoryPagination: matchPaginationTotalPages(exportHistoryPagination, count),
            exportHistoryCount: count
        });
    }

    @action
    async changeBalancePaginationPage(user_id: number, pageNumber: number) {
        const { exportHistoryPagination } = this.getItem(user_id).property;
        exportHistoryPagination.activePage = pageNumber;
        this.balanceHistoryByUser(user_id);
    }

    @action
    async changeBalancePaginationPageSize(user_id: number, pageSize: number) {
        const exportHistoryPagination = { pageSize: pageSize, activePage: 1, totalPages: 1 };
        this.setProperty(user_id, { exportHistoryPagination });
        this.balanceHistoryByUser(user_id);
    }

    @action
    async resetPassword(user_id: number) {
        this.getItem(user_id).loadingItem = true;
        this.getItem(user_id).errors = [];

        try {
            await userApi.resetPassword(user_id);
        } catch (errors) {
            this.getItem(user_id).errors = errors;
        }

        this.getItem(user_id).loadingItem = false;
    }

    @action
    async fetchUserBalanceToTime(user_id: number, fromTime: number) {
        this.setProperty(user_id, { loadingUserBalance: true });
        const userBalanceToTime = await userApi.fetchUserBalance(user_id, fromTime);
        this.setProperty(user_id, { loadingUserBalance: false, userBalanceToTime });
    }

    @action
    async getUserBalanceInfo(user_id: number) {
        this.setProperty(user_id, { loadingUserBalance: true });
        const userBalanceInfo = await userApi.getUserBalanceInfo(user_id);
        this.setProperty(user_id, { loadingUserBalance: false, userBalanceInfo });
    }

    matchEstateFilterFromUser(user_id: number): EstateFilter {
        return deepCopy({ ...estateStore.listFilterClear, base: ESTATE_BASE_MAIN, major_user_id: [user_id], regionId: 0 });
    }

    @action
    async fetchEstatesByUser(user_id: number) {
        this.setProperty(user_id, { estates: null, loadingEstates: true });
        const { list } = await estateApi.fetchList(1000, 0, 'estate_id', 'descending', this.matchEstateFilterFromUser(user_id));
        this.setProperty(user_id, { estates: list, loadingEstates: false });
    }

    @action
    setEstateFilterFromUser(user_id: number) {
        estateStore.resetPagination();
        estateStore.listFilter = this.matchEstateFilterFromUser(user_id);
        estateStore.debounceFilterFetch();
    }
}

export default new UserStore();
