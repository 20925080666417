import React from 'react';
import { IObservableArray } from 'mobx';
import PreventDblClick from '../Base/PreventDblClick';
import { ErrorMessage, MessageTitle } from '~ui/Message';
import { TAB_ID_CREATING } from '~/components/Items/ItemWrapper';

const ItemErrors = ({ errors, tabName }: { errors: Array<string> | IObservableArray<string>; tabName?: string }) => {
    if (errors.length === 0) {
        return null;
    }

    const lastChar = errors.length === 1 ? 'а' : 'и';

    return (
        <PreventDblClick>
            <ErrorMessage className="crm-Main__itemErrors">
                <MessageTitle>
                    {tabName
                        ? `При ${tabName === TAB_ID_CREATING ? 'создании' : 'сохранении'} возникл${lastChar} ошибк${lastChar}`
                        : `Ошибк${lastChar} с сервера:`}
                </MessageTitle>
                {errors.map((errorText, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: errorText }} />
                ))}
            </ErrorMessage>
        </PreventDblClick>
    );
};

export default ItemErrors;
