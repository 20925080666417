import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Label } from 'semantic-ui-react';

import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import Checkbox from '~ui/Checkbox';

import serverConfig from '../../../config/server.config';
import ItemPreviewWrapper, { ItemPreviewWrapperProps } from '../Common/ItemPreview';
import { EstateLinkState } from '../../Lists/Estate/EstateLink';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_MAIN, ESTATE_BASES } from '~/types/estate.types';
import UsersLinkList from '../../Lists/Users/UsersLinkList';
import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';
import EstateIsFavorite from '../../Lists/Estate/SubBlocks/EstateIsFavorite';
import commonStore from '~/stores/commonStore';
import EstatePreviewContent from './SubBlocks/EstatePreviewContent';
import isEqual from '~/common/isEqual';

type EstatePreviewItemState = {
    imgHasLoaded: boolean;
};

type EstatePreviewItemProps = {
    item_id: number;
    base: ESTATE_BASES;
    onImgLoad?: () => void;
    handleClose: (event: React.SyntheticEvent) => void;
    classes: any;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

export const estatePreviewStyles = {
    card: {
        maxWidth: 345,
        minWidth: 345,
        position: 'relative'
    },
    cardFullWidth: {
        maxWidth: '100%',
        minWidth: '100%',
        position: 'relative'
    },
    media: {
        height: 160,
        position: 'relative'
    }
};

const preventEvent = e => {
    e.stopPropagation();
    e.preventDefault();
};

@observer
class EstatePreviewItemBefore extends Component<EstatePreviewItemProps, EstatePreviewItemState> {
    state = {
        imgHasLoaded: false
    };

    handleOpenEstate = (event: SyntheticEvent) => {
        const { handleClose } = this.props;
        handleClose(event);
    };

    onImgLoad = () => {
        this.setState({ imgHasLoaded: true });
        if (this.props.onImgLoad) {
            this.props.onImgLoad();
        }
    };

    render() {
        const { item_id, base, classes, onMouseEnter, onMouseLeave } = this.props;
        const { imgHasLoaded } = this.state;
        const { item } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { photos, major_users, geo_lat, geo_lon, isFavorite, isHidden } = item;
        let photoUrl = photos.length ? matchEstateThumbnailPhotoUrl(photos[0].filename).src : '';

        if (!photoUrl) {
            photoUrl = `${serverConfig.serverUrl}/estate/mapPhoto?geo_lon=${geo_lon}&geo_lat=${geo_lat}`;
        }

        const images = [
            {
                original: photoUrl
            },
            ...photos
                .slice(1, 5)
                .map(photo => matchEstateThumbnailPhotoUrl(photo.filename).src)
                .map(original => ({ original }))
        ];

        const shareId = estateStore.matchSelectedItemsFiled(item);
        const isChecked = estateStore.selectedItemsIds && Boolean(estateStore.selectedItemsIds.find(item => isEqual(item, shareId)));

        return (
            <Card
                className={classes[commonStore.isMobile || estateStore.modeSearchOnMap ? 'cardFullWidth' : 'card']}
                onDoubleClick={preventEvent}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <CardMedia className={classes.media}>
                    <ImageGallery
                        items={images}
                        showNav={!commonStore.isMobile && photos.length > 1}
                        showFullscreenButton={false}
                        showThumbnails={false}
                        showPlayButton={false}
                        showBullets={photos.length > 1}
                        lazyLoad
                    />
                </CardMedia>

                <CardActionArea component="div" onClick={this.handleOpenEstate} title="Открыть карточку">
                    <Link to={EstateLinkState(item_id, 'all', base)} style={{ color: 'inherit' }}>
                        <EstatePreviewContent item_id={item_id} base={base} />
                    </Link>
                </CardActionArea>

                <CardActions disableSpacing>
                    <Checkbox onClick={() => estateStore.toggleItemSelected(shareId)} checked={isChecked} />

                    {base === ESTATE_BASE_MAIN && (
                        <Fragment>
                            {(!major_users || !major_users.length) && (
                                <Label icon="lightbulb outline" color="teal" content="Свободный объект" size="small" />
                            )}
                            {major_users && <UsersLinkList avatar usersList={major_users} />}
                        </Fragment>
                    )}

                    <div style={{ marginLeft: 'auto' }}>
                        <EstateIsFavorite
                            hiddenButton
                            isHidden={isHidden}
                            isFavorite={isFavorite}
                            estate_id={item_id}
                            base={base}
                            size="large"
                        />
                    </div>
                </CardActions>
            </Card>
        );
    }
}

export const EstatePreviewItem = withStyles(estatePreviewStyles as any)(EstatePreviewItemBefore);

@observer
class EstatePreview extends Component<ItemPreviewWrapperProps & { onMouseEnter?: () => void; onMouseLeave?: () => void }> {
    render() {
        return <EstatePreviewItem {...this.props} />;
    }
}

export default ItemPreviewWrapper(estateStore, EstatePreview);
