import { observable, action } from 'mobx';
import * as sourcePhonesApi from '~/api/cell/sourcePhonesApi';
import ListStorePrototype, { OrderDirectionType } from '../prototypes/ListStore.prototype';
import deepCopy from '~/common/deepCopy';
import { SourcePhone } from '~/types/sourcePhones.types';
import authStore from '~/stores/authStore';

const SOURCE_PHONE_MEM_KEY = 'sourcePhonesMem';

class SourcePhoneStore extends ListStorePrototype<SourcePhone> {
    constructor() {
        super('source_phone_id', 'settings', sourcePhonesApi);
        authStore.registerInitFunc(this.fetchList.bind(this));

        const memoryListJSON = window.localStorage.getItem(SOURCE_PHONE_MEM_KEY);
        if (memoryListJSON) {
            this.list = JSON.parse(memoryListJSON);
        }
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const sourceItem = this.getItem(id).item;
        if (sourceItem) {
            this.setEditingItem(id, deepCopy(sourceItem));
        }
    }

    @action
    async fetchList(orderBy?: string, orderDirection?: OrderDirectionType) {
        await super.fetchList();
        window.localStorage.setItem(SOURCE_PHONE_MEM_KEY, JSON.stringify(this.list));
    }

    findByPhone(phone: string): SourcePhone | null {
        const sourcePhone = this.list.find(sourcePhone => sourcePhone.phone === phone);
        if (!sourcePhone) {
            return null;
        }

        return deepCopy(sourcePhone);
    }

    matchListAccessRead() {}
}

export default new SourcePhoneStore();
