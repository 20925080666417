import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

import Time from '../../Base/Time';
import ShowingLink, { ShowingLinkState } from './ShowingLink';
import history from '../../../history';
import { ShowingTableItem } from '~/types/showings.types';
import { SHOWING_TYPE_MLS, SHOWING_TYPE_OUTER } from '~/types/showings.types';
import UserLinkWithPreview from '../Users/UserLinkWithPreview';
import ContactLinkWithPreview from '../Contacts/ContactLinkWithPreview';
import EstateLinkWithPreview from '../Estate/EstateLinkWithPreview';

const ShowingsTableBody = ({ showingsList, hideEstate }: { showingsList: Array<ShowingTableItem>; hideEstate?: boolean }) => (
    <Table.Body>
        {showingsList.length > 0 &&
            showingsList.map(({ showing_id, contact, estate, showingTime, updateTime, major_user, estateOuterUrl, showingType }) => {
                return (
                    <Table.Row key={showing_id}>
                        <Table.Cell>
                            <b>
                                <ShowingLink showing={{ showing_id, showingTime }} />
                            </b>
                            <div>
                                Актуальность: <Time time={updateTime} />
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <UserLinkWithPreview user={major_user} />
                        </Table.Cell>
                        <Table.Cell>
                            <ContactLinkWithPreview contact={contact} />
                        </Table.Cell>
                        {!hideEstate && (
                            <Table.Cell>
                                {showingType < SHOWING_TYPE_OUTER && estate && <EstateLinkWithPreview estate={estate} />}
                                {showingType === SHOWING_TYPE_OUTER && (
                                    <a target="_blank" href={estateOuterUrl} rel="noreferrer">
                                        Внешний объект <Icon name="external" />
                                    </a>
                                )}
                            </Table.Cell>
                        )}
                    </Table.Row>
                );
            })}
    </Table.Body>
);

export default ShowingsTableBody;
