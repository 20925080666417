import React from 'react';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const EstateSumStatisticsPie = ({ data }: { data: any }) => (
    <PieChart width={250} height={250}>
        <Pie data={data} cx={125} cy={125} labelLine fill="#8884d8" dataKey="value" label outerRadius={80} animationDuration={500}>
            {data.map(({ color }, index) => (
                <Cell key={`cell-${index}`} fill={color} />
            ))}
        </Pie>
        <Tooltip />
    </PieChart>
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                width: '230px',
                minWidth: '230px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        content: {
            flex: '1 0 auto'
        },
        cover: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        table: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

const CardWithStatPie = ({ data, title, subTitle }: { data: any; title: string; subTitle: string }) => {
    const classes = useStyles();
    let sum = 0;

    return (
        <Card className={classes.root}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {subTitle}
                    </Typography>
                </CardContent>
                <div className={classes.table}>
                    <Table size={data.length > 3 ? 'small' : null}>
                        <TableBody>
                            {data.map(({ name, value, color }) => {
                                sum += value;
                                return (
                                    <TableRow key={name}>
                                        <TableCell component="th" scope="row">
                                            <span style={{ color, fontWeight: 400 }}>{name}</span>
                                        </TableCell>
                                        <TableCell align="right">{value}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {data.length > 1 && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Всего</TableCell>
                                    <TableCell align="right">{sum}</TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </div>
            </div>
            <CardMedia className={classes.cover}>
                <EstateSumStatisticsPie data={data} />
            </CardMedia>
        </Card>
    );
};

export default CardWithStatPie;
