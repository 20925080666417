import React, { useState } from 'react';
import Modal, { ModalContent } from '~ui/Modal';

import config from '~/config/ui.config';
import Avatar from '@material-ui/core/Avatar';
import StorageIcon from '@material-ui/icons/Storage';
import { AVITO_EXPORT_BASE_ID, CIAN_EXPORT_BASE_ID, DOMCLICK_EXPORT_BASE_ID, YANDEX_EXPORT_BASE_ID } from '~/types/exports.types';
import TabsMenu, { TabPanel, Tab, TabType } from '~/components/Base/TabsMenu';

import CianApiSettings from './BaseApiSettings/CianApiSettings';
import YandexApiSettings from './BaseApiSettings/YandexApiSettings';
import DomclickApiSettings from './BaseApiSettings/DomclickApiSettings';
import AvitoApiSettings from './BaseApiSettings/AvitoApiSettings';
import MainExportSettings from './BaseApiSettings/MainExportSettings';

const TAB_MAIN_EXPORT_SETTINGS = 0;

const AdsSettingsModal = ({ onClose }: { onClose: () => void }) => {
    const [tabName, setTabName] = useState<number>(TAB_MAIN_EXPORT_SETTINGS);

    const adsTabs: TabType<number>[] = [
        {
            label: 'Общие настройки',
            value: TAB_MAIN_EXPORT_SETTINGS,
            icon: <StorageIcon />
        },
        {
            label: 'ЦИАН',
            value: CIAN_EXPORT_BASE_ID,
            icon: <Avatar src={config.publicUrl + 'cian.png'} style={tabName !== CIAN_EXPORT_BASE_ID ? { filter: 'grayscale()' } : {}} />
        },
        {
            label: 'Авито',
            value: AVITO_EXPORT_BASE_ID,
            icon: <Avatar src={config.publicUrl + 'avito.png'} style={tabName !== AVITO_EXPORT_BASE_ID ? { filter: 'grayscale()' } : {}} />
        },
        {
            label: 'Яндекс.Недвижимость',
            value: YANDEX_EXPORT_BASE_ID,
            icon: (
                <Avatar src={config.publicUrl + 'yandex.png'} style={tabName !== YANDEX_EXPORT_BASE_ID ? { filter: 'grayscale()' } : {}} />
            )
        },
        {
            label: 'Домклик',
            value: DOMCLICK_EXPORT_BASE_ID,
            icon: (
                <Avatar
                    src={config.publicUrl + 'sberbank.png'}
                    style={tabName !== DOMCLICK_EXPORT_BASE_ID ? { filter: 'grayscale()' } : {}}
                />
            )
        }
    ];

    return (
        <Modal maxWidth="md" onClose={onClose} header="Настройки экспорта, Api внешних источников">
            <ModalContent>
                <TabsMenu value={tabName} tabs={adsTabs} handleChange={value => setTabName(value)}>
                    <TabPanel value={tabName} index={TAB_MAIN_EXPORT_SETTINGS}>
                        <MainExportSettings />
                    </TabPanel>

                    <TabPanel value={tabName} index={CIAN_EXPORT_BASE_ID}>
                        <CianApiSettings />
                    </TabPanel>

                    <TabPanel value={tabName} index={AVITO_EXPORT_BASE_ID}>
                        <AvitoApiSettings />
                    </TabPanel>

                    <TabPanel value={tabName} index={YANDEX_EXPORT_BASE_ID}>
                        <YandexApiSettings />
                    </TabPanel>

                    <TabPanel value={tabName} index={DOMCLICK_EXPORT_BASE_ID}>
                        <DomclickApiSettings />
                    </TabPanel>
                </TabsMenu>
            </ModalContent>
        </Modal>
    );
};

export default AdsSettingsModal;
