import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import { UploadingPhoto } from '~/types/estate.types';

import PhotosUpload from '~/components/Base/Photos/PhotosUpload';
import LifeComplexPhotosListEditing from './LifeComplexPhotosListEditing';

const LifeComplexPhotosEditing = (props: { newbuilding_id: number; photos?: UploadingPhoto[] }) => {
    const { newbuilding_id } = props;

    useMemo(() => {
        const lifeComplexEditing = {
            newbuilding_id,
            photos: props.photos || []
        };
        lifeComplexStore.setEditingItem(newbuilding_id, lifeComplexEditing);
    }, []);

    const onLoadFile = (error, file) => {
        if (error) {
            return;
        }
        const { serverId } = file;

        const { response }: { response: UploadingPhoto } = JSON.parse(serverId);
        const { photos } = lifeComplexStore.getItem(newbuilding_id).editingItem;
        lifeComplexStore.setEditingItem(newbuilding_id, { photos: [...photos, response] });
    };

    return (
        <>
            <PhotosUpload onLoadFile={onLoadFile} />
            <LifeComplexPhotosListEditing newbuilding_id={newbuilding_id} />
        </>
    );
};

export default observer(LifeComplexPhotosEditing);
