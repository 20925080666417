import { objectToGraphql } from '~/common/graphql';
import fetchApi from '../common/fetchApi';
import { GQContactPhones, GQMainContactFields } from './contactsApi';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { Wish, WishFilter, WishTableType } from '~/types/wish.types';
import { GQEstateMainFields } from './estateApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { CoordinatesSquare } from '~/types/estate.types';

const GQWishMainFields = `
    wish_id
    type
    propertyType
    enable  
`;

const GQWishTableFields = `
    ${GQWishMainFields}
    propertyTypes
    
    roomsCount
    totalAreaMin
    totalAreaMax
    livingAreaMin
    livingAreaMax
    kitchenAreaMin
    kitchenAreaMax
    floorNumberMin
    floorNumberMax
    floorsCountMin
    lastFloorOnly
    materialType
    buildYearMin
    buildYearMax
    hasLoggia
    hasBalcone
    wcType

    transportType
    transportTime
    main_metro_id
    
    description
    town
    cityDistrict
    lastCallTime
    priceMin
    priceMax
    priceM2Min
    priceM2Max
    mortgageApproved
    
    contact_id
    
    major_user_ids
    major_users {
        ${GQUserMainFields}    
    }
    
    isBooked {
        major_user_id
        bookTime
        expireTime  
    }
    bookedUser {
        ${GQUserMainFields}    
    }
    
    showingsCount
    callsCount

    createTime
    updateTime
`;

const GQWishFields = `
    ${GQWishTableFields}
    
    interestedEstates {
        ${GQEstateMainFields}
        startTime
    }

    contact {
        ${GQMainContactFields}
        ${GQContactPhones}
    }
`;

export const fetchItem = async (id: number): Promise<Wish> => {
    const graphql = `{
        fetchWish(wish_id: ${id}) {
            ${GQWishFields}
        } 
      }`;

    const data: { fetchWish: Wish } = await fetchApi.getGQ(graphql);
    return data.fetchWish;
};

export const createItem = async (wish: Wish): Promise<number> => {
    const graphql = `mutation { 
          createWish(wishInput: ${objectToGraphql(wish)})
        }`;

    const data: { createWish: number } = await fetchApi.postGQ(graphql);
    return data.createWish;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    wishFilter: Partial<WishFilter>,
    controller?: AbortController
): Promise<{ list: Array<WishTableType>; count: number }> => {
    const graphql = `{
        fetchWishes(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
        sort: "${orderDirection}", filter: ${objectToGraphql(wishFilter)}) {
            list {${GQWishTableFields}}
            count
        } 
      }`;

    const data: { fetchWishes: { list: Array<WishTableType>; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchWishes;
};

export const saveItem = async (wish_id: number, wish: Wish): Promise<number> => {
    const graphql = `mutation { 
        updateWish(wish_id: ${wish_id}, wishDiff: ${objectToGraphql(wish)})
    }`;

    const data = await fetchApi.postGQ<{ updateWish: number }>(graphql);
    return data.updateWish;
};

export const wishesByContact = async (contact_id: number): Promise<Wish[]> => {
    const graphql = `{
        wishesByContact(contact_id: ${contact_id}) {
            ${GQWishMainFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ wishesByContact: Wish[] }>(graphql);
    return data.wishesByContact;
};

export const fetchItemHistory = async (wish_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchWishChangeHistory(wish_id: ${wish_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchWishChangeHistory: HistoryChange[] }>(graphql);
    return data.fetchWishChangeHistory;
};

export const deleteWishWithCause = async (wish_id: number, deleteCause: number): Promise<number> => {
    const graphql = `mutation { 
        deleteWishWithCause(wish_id: ${wish_id}, deleteCause: ${deleteCause})
    }`;

    const data = await fetchApi.postGQ<{ deleteWishWithCause: number }>(graphql);
    return data.deleteWishWithCause;
};

export const takeWishToWork = async (wish_id: number): Promise<number> => {
    const graphql = `mutation { 
        takeWishToWork(wish_id: ${wish_id})
    }`;

    const data = await fetchApi.postGQ<{ takeWishToWork: number }>(graphql);
    return data.takeWishToWork;
};

export const freeWishBook = async (wish_id: number): Promise<boolean> => {
    const graphql = `mutation { 
        freeWishBook(wish_id: ${wish_id})
    }`;

    const data = await fetchApi.postGQ<{ freeWishBook: boolean }>(graphql);
    return data.freeWishBook;
};

export const fetchItemsOnMap = async (
    wishFilter: Partial<WishFilter>,
    coordinates: CoordinatesSquare,
    limit: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    controller?: AbortController
): Promise<Wish[]> => {
    const graphql = `{
        fetchWishesOnMap(filter: ${objectToGraphql(wishFilter)}, orderBy: "${orderBy}", sort: "${orderDirection}", 
                            coordinates: ${objectToGraphql(coordinates)}, limit: ${limit}) {
            wish_id
            estate_id
            price
            geo_lat
            geo_lon
        } 
      }`;

    const data: { fetchWishesOnMap: Wish[] } = await fetchApi.getGQ(graphql, controller);
    return data.fetchWishesOnMap;
};

export const fetchItemsCountOnMap = async (
    wishFilter: Partial<WishFilter>,
    coordinates: CoordinatesSquare,
    orderBy: string,
    controller?: AbortController
): Promise<number> => {
    const graphql = `{
        fetchWishesCountOnMap(filter: ${objectToGraphql(wishFilter)}, coordinates: ${objectToGraphql(coordinates)})
      }`;

    const data = await fetchApi.getGQ<{ fetchWishesCountOnMap: number }>(graphql, controller);
    console.log(' data.fetchWishesCountOnMap', data.fetchWishesCountOnMap);
    return data.fetchWishesCountOnMap;
};
