import React, { Component, Fragment } from 'react';
import { Card, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import UserDropdown from '../../../ItemsDropdowns/UserDropdown';

type UserGrantAccessEditingProps = HandlerEditingWrappedProps & {
    grant_users_ids: number[];
    grant_users_under_ids: number[];
};

@observer
class UserGrantAccessEditing extends Component<UserGrantAccessEditingProps> {
    constructor(props: UserGrantAccessEditingProps) {
        super(props);

        const user = {
            grant_users_ids: props.grant_users_ids || [],
            grant_users_under_ids: props.grant_users_under_ids || []
        };
        userStore.setEditingItem(props.item_id, user);
    }

    handleGrantUsersChange = (major_user_ids: number | Array<number>) => {
        if (major_user_ids instanceof Array) {
            userStore.setEditingItem(this.props.item_id, { grant_users_ids: major_user_ids });
        }
    };

    handleGrantUsersUnderChange = (major_user_ids: number | Array<number>) => {
        if (major_user_ids instanceof Array) {
            userStore.setEditingItem(this.props.item_id, { grant_users_under_ids: major_user_ids });
        }
    };

    render() {
        const { item_id } = this.props;
        const { grant_users_ids, grant_users_under_ids } = userStore.getItem(item_id).editingItem;

        return (
            <Card.Meta>
                <Grid columns={1} stackable>
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            <b>Личный наставник(и)</b>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle">
                            <UserDropdown user_id={grant_users_ids || []} onChange={this.handleGrantUsersChange} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column verticalAlign="middle">
                            <b>Наставник над</b>
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle">
                            <UserDropdown user_id={grant_users_under_ids || []} onChange={this.handleGrantUsersUnderChange} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Meta>
        );
    }
}

export default HandlerEditingBlockWrapper(userStore, UserGrantAccessEditing);
