import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import isEqual from '~/common/isEqual';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SearchIcon from '@material-ui/icons/Search';
import ApartmentIcon from '@material-ui/icons/Apartment';

import Button from '~ui/Button';

import { ESTATE_PROPERTY_FLATS_ARR } from '~/types/estate.types';

import estateStore from '~/stores/estateStore';
import { AWAIT_POPUP_TIME_CLOSE_MS, AWAIT_POPUP_TIME_MS } from '~/components/Items/Common/ItemPreview';
import { Newbuilding } from '~/types/newbuildings.types';
import history from '~/history';
import { LifeComplexLinkState } from '~/components/Items/LifeComplex/LifeComplexLink';

type EstateTableNewbuildingProps = {
    newbuilding?: Newbuilding;
    simpleMode?: boolean;
};

const setFilterNewbuildningName = (name: string) => {
    estateStore.changeFilter('propertyTypes', ESTATE_PROPERTY_FLATS_ARR);
    estateStore.changeFilter('newbuildingContains', name);
};

const EstateTableNewbuilding = ({ newbuilding, simpleMode }: EstateTableNewbuildingProps) => {
    if (!newbuilding) {
        return null;
    }

    const openLifeComplex = () => {
        history.push(LifeComplexLinkState(newbuilding.newbuilding_id, 'crm'));
    };

    return (
        <span style={{ paddingRight: '15px' }}>
            <Icon name="building" color="grey" />
            ЖК «
            {!simpleMode && (
                <Popup
                    wide
                    mouseEnterDelay={AWAIT_POPUP_TIME_MS}
                    mouseLeaveDelay={AWAIT_POPUP_TIME_CLOSE_MS}
                    hoverable
                    on="hover"
                    trigger={<b style={{ cursor: 'context-menu', fontWeight: 400 }}>{newbuilding.name}</b>}
                    size="small"
                >
                    <List disablePadding dense>
                        <ListItem divider disableGutters>
                            <Button onClick={openLifeComplex} fullWidth primary variant="outlined" startIcon={<ApartmentIcon />}>
                                Открыть карточку ЖК
                            </Button>
                        </ListItem>
                        <ListItem disableGutters>
                            <Button
                                startIcon={<SearchIcon />}
                                onClick={setFilterNewbuildningName.bind(setFilterNewbuildningName, newbuilding.name)}
                                fullWidth
                                color="secondary"
                                variant="outlined"
                            >
                                Добавить ЖК в фильтр
                            </Button>
                        </ListItem>
                    </List>
                </Popup>
            )}
            {simpleMode && <b style={{ fontWeight: 400 }}>{newbuilding.name}</b>}»
        </span>
    );
};

export default React.memo(EstateTableNewbuilding, isEqual);
