import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';
import { ButtonInSet } from '~/components/Base/ButtonGroup';
import config from '~/config/ui.config';
import authStore from '~/stores/authStore';
import { ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import { ESTATE_BASE_FOREIGN, ESTATE_BASE_GROUP, ESTATE_BASES, ESTATE_PROPERTY_FLATS_ARR } from '~/types/estate.types';

import EstatesList, { AVAILABLE_NOT_FLAT_BASES } from './EstatesList';
import EstatesMapNewView from './MapView/EstatesMapNewView';
import EstateMapMobile from './MapView/EstateMapMobile';
import EstateForeignList from './EstateForeignList';

export const MIN_LIST_COUNT_TO_DEADER_PAGIN = 10;

export const adjustEstateListBtns = (propertyType: number): ButtonInSet[] => {
    const estateListMenuOrder = [...config.estateListMenuOrder] as ButtonInSet[];

    if (authStore.getPermission(estateStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP) {
        estateListMenuOrder.push([ESTATE_BASE_GROUP, 'Отдел']);
    }

    if (propertyType !== 0 && !ESTATE_PROPERTY_FLATS_ARR.includes(propertyType)) {
        return estateListMenuOrder.map(([baseType, title]) => [baseType, title, !AVAILABLE_NOT_FLAT_BASES.includes(String(baseType))]);
    }

    return estateListMenuOrder;
};

const EstatesViews = () => {
    const { param: urlBase }: { param: ESTATE_BASES } = useParams();
    const { base: filterBase } = estateStore.listFilter;

    useMemo(() => {
        if (urlBase === ESTATE_BASE_FOREIGN) {
            estateStore.changeFilterBaseWithoutFetch(urlBase);
            if (!estateStore.modeSearchOnMap) {
                estateStore.toggleSearchOnMap();
            }
        }
        if (filterBase === ESTATE_BASE_FOREIGN && urlBase !== ESTATE_BASE_FOREIGN) {
            estateStore.changeFilterBaseWithoutFetch(urlBase);
            if (estateStore.modeSearchOnMap) {
                estateStore.toggleSearchOnMap();
            }
        }
    }, [urlBase, filterBase]);

    useEffect(() => {
        if (urlBase === ESTATE_BASE_FOREIGN) {
            estateStore.changeFilterBaseWithoutFetch(urlBase);
        }
    }, [urlBase]);

    const { modeSearchOnMap } = estateStore;
    const { isMobile } = commonStore;

    if (modeSearchOnMap) {
        return isMobile ? <EstateMapMobile /> : <EstatesMapNewView />;
    }
    if (urlBase === ESTATE_BASE_FOREIGN) {
        return <EstateForeignList />;
    }

    return <EstatesList />;
};

export default observer(EstatesViews);
