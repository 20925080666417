import { observable, action } from 'mobx';
import * as callsSourcesApi from '~/api/cell/callsSourcesApi';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import deepCopy from '../../common/deepCopy';
import { DropdownType } from '../prototypes/ListStore.prototype';
import { CallSource } from '~/types/cellCalls.types';
import authStore from '../authStore';

class CallSourceStore extends ListStorePrototype<CallSource> {
    constructor() {
        super('source_id', 'settings', callsSourcesApi);
        authStore.registerInitFunc(this.fetchAllCallSources.bind(this));
    }

    @observable
    loadingFetchAllSources = true;

    allCallSources: CallSource[] = [];
    allCallSourcesDropdown: DropdownType[] = [];

    @action
    async fetchAllCallSources() {
        this.allCallSources = await callsSourcesApi.fetchAllCallSources();

        this.allCallSourcesDropdown = this.allCallSources.map(({ source_id, title }) => ({
            text: title,
            value: source_id,
            key: source_id
        }));

        this.loadingFetchAllSources = false;
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const sourceItem = this.getItem(id).item;
        if (sourceItem) {
            this.setEditingItem(id, deepCopy(sourceItem));
        }
    }

    @action
    async createItem(): Promise<number> {
        const source_id = await super.createItem();

        this.loadingFetchAllSources = true;
        await callsSourcesApi.fetchAllCallSources();
        this.loadingFetchAllSources = false;

        return source_id;
    }

    findById(source_id: number): CallSource {
        const callSource = this.allCallSources.find(callSource => callSource.source_id === source_id);

        if (!callSource) {
            throw new Error(`Call Source didn't find: ${source_id}`);
        }

        return callSource;
    }
}

export default new CallSourceStore();
