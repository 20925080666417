import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import taskStore from '~/stores/taskStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import TaskTitleEditing from '../EditBlocks/TaskTitleEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { nl2br } from '~/api/commentsApi';
import tasksL10n from '../../../../L10n/tasks.L10n';

@observer
class TaskTitleShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = taskStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { description, priority } = item;

        return (
            <Card fluid color="red">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Описание</Header>
                            {priority > 1 && (
                                <Grid columns={2}>
                                    <Grid.Column>Приоритет</Grid.Column>
                                    <Grid.Column>{tasksL10n.PRIORITY_FIND(priority)}</Grid.Column>
                                </Grid>
                            )}
                            <ShadowFragment
                                shadow={loadingItem}
                                print={<span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />}
                            />
                        </Fragment>
                    )}
                    {editing && <TaskTitleEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(taskStore, TaskTitleShow);
