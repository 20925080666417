import React, { Component, Fragment } from 'react';
import { Search, Button, Icon, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import debounce from '../../../../../common/debounce';
import NewbuildingBasePopup from './NewbuildingBasePopup';

import estateStore from '~/stores/estateStore';
import { NEWBUILDING_BASE_AVITO } from '~/types/newbuildings.types';
import { ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION } from '~/types/estate.types';

type AvitoHouseBlockEditingProps = {
    estate_id: number;
};

type AvitoHouseBlockEditingState = {
    avitoSearch: string;
    avito_house_id: number;
    isOpen: boolean;
};

const resultRenderer = ({ address, title }: { address: string; title: string }) => (
    <div>
        <b>{title}</b>
        <br />
        {address}
    </div>
);

@observer
class AvitoHouseBlockEditing extends Component<AvitoHouseBlockEditingProps, AvitoHouseBlockEditingState> {
    state = {
        avitoSearch: '',
        isOpen: false,
        avito_house_id: 0
    };

    handleOnFocus = () => this.setState({ isOpen: true });

    handleOnBlur = () => {
        // Даем возможность отработать this.handleSearchChange
        setTimeout(() => {
            this.setState({ isOpen: false });
        }, 100);
    };

    handleSearchChange = (event: React.SyntheticEvent, { value }: { value: string }) => {
        this.setState({ avitoSearch: value, isOpen: true });
        this.debounceAvitoSearch();
    };

    debounceAvitoSearch = debounce(() => {
        (async () => {
            await estateStore.findBaseHouses(this.props.estate_id, this.state.avitoSearch, NEWBUILDING_BASE_AVITO);
        })();
    }, 350);

    handleChange = (evt: React.SyntheticEvent, { result: { value } }: { result: { value: number } }) => {
        const { estate_id } = this.props;
        const { suggestedAvitoHouses } = estateStore.getItem(estate_id).property;

        const house = suggestedAvitoHouses.find(({ avito_house_id }) => avito_house_id === value);
        if (!house) {
            return;
        }

        this.setState({ avito_house_id: value, isOpen: false, avitoSearch: house.name });

        estateStore.setProperty(estate_id, { avitoHouse: house });
    };

    handleSave = () => {
        estateStore.updateBaseHouse(this.props.estate_id, this.state.avito_house_id, NEWBUILDING_BASE_AVITO);
    };

    render() {
        const { estate_id } = this.props;
        const { avitoSearch, isOpen, avito_house_id } = this.state;

        const {
            property: { avitoHouse, suggestedAvitoHouses, loadingAvitoHouse },
            item
        } = estateStore.getItem(estate_id);
        const { propertyType } = item || {};

        const suggestedHousesList =
            suggestedAvitoHouses && suggestedAvitoHouses.length
                ? suggestedAvitoHouses.map(({ avito_house_id, address, name, developer }) => ({
                      key: avito_house_id,
                      value: avito_house_id,
                      address,
                      title: name + (developer ? ` (${developer})` : '')
                  }))
                : [];

        const isNebuilding = [ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType);

        return (
            <Message color={isNebuilding ? 'olive' : null}>
                <Message.Header>
                    Нет сопоставления с базой ЖК Авито&nbsp;
                    <NewbuildingBasePopup />
                </Message.Header>
                {isNebuilding && <p>Для того, чтобы был возможен экспорт в Авито этот дом надо найти в базе ЖК от Авито.</p>}
                {!isNebuilding && (
                    <p>
                        Вы можете попробовать сопоставить этот дом с базой Авито. Не все уже введенные в строй ЖК присутствуют в этом
                        списке.
                    </p>
                )}

                <Search
                    icon="search"
                    className="crm-Estate__address_find crm-Estate__address_avitoSearch"
                    showNoResults={false}
                    size="tiny"
                    loading={loadingAvitoHouse}
                    onResultSelect={this.handleChange}
                    onSearchChange={this.handleSearchChange}
                    results={suggestedHousesList}
                    value={avitoSearch}
                    resultRenderer={resultRenderer}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    open={isOpen}
                />

                {avitoHouse && (
                    <div>
                        <b>
                            <Icon name="building" />
                            {avitoHouse.name}
                        </b>
                        <br />
                        {avitoHouse.address}
                        <br />
                        <Button primary size="mini" onClick={this.handleSave}>
                            Сохранить
                        </Button>
                    </div>
                )}
            </Message>
        );
    }
}

export default AvitoHouseBlockEditing;
