import React from 'react';
import { Link } from 'react-router-dom';
import { ContactLinkType } from '~/types/contacts.types';
import { Icon } from 'semantic-ui-react';
import { CONTACT_TYPE_COMPANY_ID, CONTACT_TYPE_PRIVATE_PERSON_ID } from '~/types/contacts.types';

export type ContactLinksProps = {
    contact: ContactLinkType;
    page?: 'list' | 'kanban';
};

export const ContactLinkState = (contact_id: number, param: string = 'list') => ({
    pathname: `/contacts/${param || 'list'}/profile/${contact_id}`,
    state: { modal: true, switchFromTab: false }
});

export const matchContactTitle = ({ contactType, companyName, firstName, lastName, middleName }: ContactLinkType): string =>
    contactType === CONTACT_TYPE_COMPANY_ID
        ? (companyName || '').trim()
        : `${firstName || ''} ${middleName || ''} ${lastName || ''}`.trim();

const ContactLink = ({ contact, page = 'list' }: ContactLinksProps) => {
    let fullName = matchContactTitle(contact);

    if (fullName.length === 0) {
        fullName = String(contact.contact_id);
    }

    return (
        <Link to={ContactLinkState(contact.contact_id, page)}>
            {contact.contactType === CONTACT_TYPE_COMPANY_ID && <Icon name="briefcase" />}
            {fullName}
        </Link>
    );
};

export default ContactLink;
