import React, { Fragment } from 'react';
import wishStore from '~/stores/wishStore';
import { DELETING_CAUSE_IS_ALREADY_BUY } from '~/types/wish.types';

import Button from '~ui/Button';
import Modal, { ModalContent, ModalActions, useToggle } from '~ui/Modal';

import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

type WishIsAlreadyBuyButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishIsAlreadyBuyButton = ({ wish_id, disabled }: WishIsAlreadyBuyButtonProps) => {
    const [confirmationPopupIsOpen, togglePopup] = useToggle();

    const handleToggleIsAlreadySold = () => {
        wishStore.deleteWishWithCause(wish_id, DELETING_CAUSE_IS_ALREADY_BUY);
        togglePopup();
    };

    return (
        <Fragment>
            <Button
                fullWidth
                variant="outlined"
                startIcon={<CancelPresentationIcon />}
                color="secondary"
                onClick={togglePopup}
                disabled={disabled}
            >
                Уже куплено
            </Button>

            {confirmationPopupIsOpen && (
                <Modal onClose={togglePopup} header="Покупатель уже купил нужный ему объект" maxWidth="sm">
                    <ModalContent>
                        <p>
                            <b>Покупатель подтвердил, что уже купил объект?</b>
                        </p>
                        <p>
                            Если вы хотите удалить Покупателя по любой другой причине, то можно воспользоваться другими кнопками "удалить" в
                            карточке данного Покупателя.
                        </p>
                    </ModalContent>
                    <ModalActions>
                        <Button onClick={handleToggleIsAlreadySold} color="secondary" startIcon={<CancelPresentationIcon />}>
                            Подтверждаю, Куплено
                        </Button>
                        <Button onClick={togglePopup}>Отмена</Button>
                    </ModalActions>
                </Modal>
            )}
        </Fragment>
    );
};

export default WishIsAlreadyBuyButton;
