import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Placeholder } from 'semantic-ui-react';
import moment from 'moment';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import showingStore from '~/stores/showingStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import ShowingReportDetailsEditing from '../EditBlocks/ShowingReportDetailsEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { nl2br } from '~/api/commentsApi';

@observer
class ShowingReportDetailsShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = showingStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { description, showingTime } = item;

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Детали</Header>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={6}>
                                        <span className="crm-Item__labelWide">Дата показа</span>
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={
                                                !loadingItem && (
                                                    <span title={new Date(showingTime * 1000).toLocaleString()}>
                                                        {moment(showingTime * 1000).calendar()}
                                                    </span>
                                                )
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <span className="crm-Item__labelWide">Информация</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={!loadingItem && nl2br(description)} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <ShowingReportDetailsEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(showingStore, ShowingReportDetailsShow);
