import React, { Component, Fragment } from 'react';
import { Button, Divider, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import ownerStore from '~/stores/ownerStore';
import history from '../../../../history';
import { ContactLinkState } from '../../../Lists/Contacts/ContactLink';
import deepCopy from '../../../../common/deepCopy';

import PersonalBlockEditing from '../../Contact/EditBlocks/PersonalBlockEditing';
import TagsBlockEditing from '../../Contact/EditBlocks/TagsBlockEditing';
import ItemContactsEditing from '../../Common/ItemContacts/ItemContactsEditing';

import contactStore from '~/stores/contactStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import estateStore from '~/stores/estateStore';
import ContactIsAlreadyCreated from '../../Contact/ShowBlocks/SubBlocks/ContactIsAlreadyCreated';
import LoaderAwait from '../../../Base/LoaderAwait';

type OwnerCreateContactModalProps = {
    estate_id: number;
    contact_id: number;
    onClose: () => void;
};

const OWNERS_TAG_ID = 1;

@observer
class OwnerCreateContactModal extends Component<OwnerCreateContactModalProps> {
    constructor(props: OwnerCreateContactModalProps) {
        super(props);

        contactStore.fetchItem(props.contact_id);
    }

    handleTakeOwner = async () => {
        this.props.onClose();
        const contact_id = await ownerStore.takeOwnerForWork(this.props.estate_id, this.props.contact_id);
        if (contact_id) {
            history.push(ContactLinkState(Number(contact_id)));
        }
    };

    componentDidMount() {
        const { contact_id, estate_id } = this.props;

        if (contact_id === CREATING_ITEM_ID) {
            contactStore.addTag(contact_id, OWNERS_TAG_ID);
            const { item: estate } = estateStore.getItem(estate_id);

            if (estate) {
                const phones = estate.ownerPhones.length
                    ? estate.ownerPhones.map(({ phone }) => ({ phone, phone_id: CREATING_ITEM_ID }))
                    : [];

                contactStore.setEditingItem(contact_id, {
                    firstName: (estate.ownerName || '').trim(),
                    phones
                });
            }
        }
    }

    render() {
        const { contact_id, estate_id, onClose } = this.props;
        const {
            item: contact,
            loadingItem: loadingContact,
            property: { contactIsAlreadyCreated }
        } = contactStore.getItem(contact_id);
        const { loadingItem } = ownerStore.getItem(estate_id);

        return (
            <Modal open onClose={onClose} closeIcon dimmer="inverted" size="small">
                <Modal.Header>{contact_id === CREATING_ITEM_ID ? 'Создание' : 'Изменение'} контакта</Modal.Header>
                {!loadingContact && (contact || contact_id === CREATING_ITEM_ID) && (
                    <Modal.Content>
                        {contactIsAlreadyCreated && <ContactIsAlreadyCreated contactOrUser={contactIsAlreadyCreated} />}

                        <b style={{ color: 'red' }}>Укажите реальный (не подмененный) телефон Собственника</b>
                        <ItemContactsEditing
                            store={contactStore}
                            phones={contact && contact.phones ? deepCopy(contact.phones) : []}
                            item_id={contact_id}
                        />

                        <Divider />
                        <PersonalBlockEditing item_id={contact_id} {...deepCopy(contact)} simpleMode={true} />

                        <Divider />
                        <TagsBlockEditing item_id={contact_id} {...deepCopy(contact)} />
                    </Modal.Content>
                )}

                <LoaderAwait active={loadingContact} />

                <Modal.Actions>
                    <Button
                        onClick={this.handleTakeOwner}
                        color="green"
                        labelPosition="left"
                        icon="male"
                        content="Создать контакт"
                        size="tiny"
                        disabled={Boolean(loadingItem || loadingContact || contactIsAlreadyCreated)}
                        loading={loadingItem}
                    />
                    <Button onClick={onClose} size="tiny" content="Отмена" disabled={loadingItem} />
                </Modal.Actions>
            </Modal>
        );
    }
}

export default OwnerCreateContactModal;
