import { observable, action, runInAction } from 'mobx';
import * as callEventApi from '~/api/cell/callEventApi';

import CalendarEventsStorePrototype from '../prototypes/CalendarEventsStore.prototype';
import { CallEvent, CallEventFilter } from '~/types/events.types';
import deepCopy from '../../common/deepCopy';
import { CREATING_ITEM_ID } from '../prototypes/ItemStore.prototype';
import contactStore from '../contactStore';
import calendarStore from '../calendarStore';
import { CALL_EVENT_ITEM_TYPE_CONTACT } from '~/types/events.types';
import { nProgress } from '../helpers/decorators.helpers';
import { CalendarEventType } from '~/types/calendar.types';

class CallEventStore extends CalendarEventsStorePrototype<CallEvent, CallEventFilter> {
    listFilterClear = {
        contact_id: null,
        major_user_id: [],
        group_id: [],
        deltaCallTime: -1,
        itemType: 0,
        callHasReleased: null,
        enable: true
    };

    orderBy = 'callTimeMin';

    constructor() {
        super('call_event_id', 'callEvent', callEventApi);
        this.clearFilter();
    }

    @action
    async fetchItem(id: number) {
        await super.fetchItem(id, null, true);
        const sourceItem = this.getItem(id).item || {};
        this.setEditingItem(id, deepCopy(sourceItem));
    }

    @action
    async createItem(): Promise<number> {
        const editingItem = this.getItem(CREATING_ITEM_ID).editingItem;

        const call_event_id = await super.createItem();

        if (editingItem.item_id && editingItem.item_type === CALL_EVENT_ITEM_TYPE_CONTACT) {
            contactStore.fetchCallsEventsByContact(editingItem.item_id);
        }

        calendarStore.addNewEvent({
            item_id: call_event_id,
            item_type: this.moduleName,
            title: editingItem.title,
            allDay: false,
            start: new Date(editingItem.callTimeMin * 1000),
            end: new Date(editingItem.callTimeMax * 1000)
        });

        return call_event_id;
    }

    @action
    async saveItem(call_event_id: number): Promise<boolean | Partial<CallEvent>> {
        const { editingItem } = this.getItem(call_event_id);

        await super.saveItem(call_event_id);

        if (editingItem.item_id && editingItem.item_type === CALL_EVENT_ITEM_TYPE_CONTACT) {
            contactStore.fetchCallsEventsByContact(editingItem.item_id);
        }

        const mergedEvent: Partial<CalendarEventType> = {
            ...(editingItem.title && { title: editingItem.title })
        };

        if (editingItem.callHasReleased !== undefined) {
            mergedEvent.hasReleased = editingItem.callHasReleased;
        }

        ['callTimeMin', 'callTimeMax'].forEach(timeKey => {
            if (editingItem[timeKey]) {
                mergedEvent[timeKey === 'callTimeMin' ? 'start' : 'end'] = new Date(editingItem[timeKey] * 1000);
            }
        });

        calendarStore.mergeEvent({
            item_id: call_event_id,
            item_type: this.moduleName,
            allDay: false,
            ...mergedEvent
        });

        return Boolean(call_event_id);
    }

    @action
    async changeCallEventReleased(call_event_id: number, callHasReleased: boolean) {
        this.setEditingItem(call_event_id, { callHasReleased });
        await this.saveItem(call_event_id);
    }

    @nProgress
    @action
    async setCallEventHasReleased(call_event_id: number) {
        this.mergeList(call_event_id, { callHasReleased: true });
        await callEventApi.saveItem(call_event_id, { callHasReleased: true });
    }

    @nProgress
    @action
    async changeItemDates(item_id: number, callTimeMin: number, callTimeMax: number) {
        await this.ApiModule.saveItem(item_id, { callTimeMin, callTimeMax });
    }

    validationItem(callEvent: CallEvent): string[] {
        const errors: string[] = [];

        if (!callEvent.item_id) {
            errors.push('Заполните "С Кем"');
        }

        if (!callEvent.assign_users_ids.length) {
            errors.push('Не назначен ответсвенный(е)');
        }

        return errors;
    }
}

const store = new CallEventStore();
calendarStore.registerEventsStore(store);

export default store;
