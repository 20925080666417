import React, { useCallback } from 'react';
import { Image, Popup } from 'semantic-ui-react';

import estateStore from '~/stores/estateStore';
import history from '~/history';
import serverConfig from '~/config/server.config';

const handleOpenMap = (user_id: number) => {
    estateStore.setFilterMapUserId(user_id);

    history.push({
        pathname: `/estate/crm`
    });
};

const UserEstateMap = ({ user_id }: { user_id: number }) => {
    const mapUrl = `${serverConfig.serverUrl}/estate/userMapPhoto?user_id=${user_id}&_r=${Math.random()}`;

    const memoizedCallback = useCallback(() => {
        handleOpenMap(user_id);
    }, [user_id]);

    return (
        <Popup
            trigger={<Image src={mapUrl} wrapped as={'a'} onClick={memoizedCallback} />}
            content={<div>Показать объекты на карте</div>}
        />
    );
};

export default React.memo(UserEstateMap);
