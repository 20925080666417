import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button, Feed, Icon, List, Grid } from 'semantic-ui-react';
import ownerStore from '~/stores/ownerStore';
import PreventDblClick from '../../../Base/PreventDblClick';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';
import Time from '../../../Base/Time';
import InfoPopup from '../../../Base/InfoPopup';
import OwnerIsAlreadySoldButton from './OwnerIsAlreadySoldButton';
import OwnerTakeToWorkButton from './OwnerTakeToWorkButton';
import OwnerIsRealtorButton from './OwnerIsRealtorButton';
import OwnerIsNotActually from './OwnerIsNotActually';
import OwnerBookedInfo from './OwnerBookedInfo';
import ListError from '../../../ListError';
import estateStore from '~/stores/estateStore';
import authStore from '~/stores/authStore';

type OwnerAssignButtonBlockProps = {
    estate_id: number;
};

type OwnerAssignButtonBlockState = {
    showAssignHistory: boolean;
};

const printAssignCount = (count: number): string => {
    if (count > 1 && count < 5) {
        return `${count} раза`;
    }

    return `${count} раз`;
};

const CHECK_INTERVAL_WAS_CALL_MS = 3000;

@observer
class OwnerAssignButtonBlock extends Component<OwnerAssignButtonBlockProps, OwnerAssignButtonBlockState> {
    checkInterval: number;

    constructor(props: OwnerAssignButtonBlockProps) {
        super(props);

        (async () => {
            const callHasDone = await ownerStore.checkWhetherWasCall(props.estate_id);
            if (!callHasDone) {
                this.checkInterval = window.setInterval(async () => {
                    const callHasDone = await ownerStore.checkWhetherWasCall(props.estate_id);
                    if (callHasDone) {
                        clearInterval(this.checkInterval);
                    }
                }, CHECK_INTERVAL_WAS_CALL_MS);
            }
        })();

        ownerStore.ownersErrors = [];
    }

    componentWillUnmount() {
        clearInterval(this.checkInterval);
    }

    state = {
        showAssignHistory: false
    };

    handleOpenContact = async () => {
        const { estate_id } = this.props;
        await ownerStore.assignOwner(estate_id);
    };

    handleOpenHistory = () => {
        this.setState({ showAssignHistory: !this.state.showAssignHistory });
    };

    render() {
        const { estate_id } = this.props;
        const { item: owner, loadingItem, property, errors } = ownerStore.getItem(estate_id);
        if (!owner) {
            return null;
        }

        let contact_id: number | null = null;

        const { item: estate } = estateStore.getItem(estate_id);
        if (estate) {
            contact_id = estate.contact_id;
        }

        const { assignOwnersHistory, enable, isBooked, isAlreadySold } = owner;
        const { showAssignHistory } = this.state;
        const { loadingAssign, ownersErrors } = ownerStore;

        const theOwnerHasAssigned = ownerStore.whetherOwnerHasAssigned(estate_id);
        const { callHasDone } = property;

        const enableStatusButtons = !isBooked || authStore.canDelete(ownerStore.moduleName, isBooked.major_user_id);

        return (
            <PreventDblClick>
                <Grid textAlign="right" style={{ margin: '-0.5rem -0.5rem -2rem' }}>
                    <Grid.Column>
                        <InfoPopup size="large">
                            <b>Информация о собственниках:</b>
                            <List bulleted>
                                <List.Item>
                                    Вы можете добавить встречу или запланировать звонок с Собственником через правое верхнее меню, а также
                                    создать Объект, если вы уже взяли собственника "в работу"
                                </List.Item>
                                <List.Item>Когда вы берете Собственника "в работу", он закрепляется за вами на 48 часов</List.Item>
                                <List.Item>
                                    Собственник, недоступный для взятия "в работу" (закрепленный на 48 часов), помечается в таблице желтым
                                    цветом
                                </List.Item>
                                <List.Item>
                                    Через 48 часов Собственник снова появится в общем доступе, если за это время вы не заключите Листинг
                                </List.Item>
                                <List.Item>
                                    Если вы заключили Листинг с этим Собственником, обратитесь в Администрацию, чтобы убрать этого
                                    Собственника в Архив
                                </List.Item>
                                <List.Item>Вы можете найти всех ваших закрепленных собственников в разделе "Мои"</List.Item>
                                <List.Item>
                                    Вы можете убрать собственника в Архив через кнопки "Уже продан" и "Это риелтор / Неверный номер", эти
                                    кнопки активируются после совершения вами звонка на номер Собственника
                                </List.Item>
                            </List>
                        </InfoPopup>
                    </Grid.Column>
                </Grid>

                <ListError errors={ownersErrors} />
                <ListError errors={errors} />
                {isBooked && <OwnerBookedInfo {...isBooked} />}

                {!isBooked && enable && (!theOwnerHasAssigned || loadingItem) && (
                    <div className="crm-Item__addMode_buttons">
                        <Button
                            content="Открыть собственника"
                            loading={loadingItem || loadingAssign}
                            disabled={loadingItem || loadingAssign}
                            labelPosition="left"
                            icon="male"
                            size="tiny"
                            color="brown"
                            onClick={this.handleOpenContact}
                        />
                    </div>
                )}

                {enableStatusButtons && !loadingItem && enable && estate && theOwnerHasAssigned && (
                    <div className="crm-Estate__ownersBtns">
                        <List verticalAlign="middle">
                            {!isBooked && (
                                <List.Item>
                                    <List.Content>
                                        <OwnerTakeToWorkButton disabled={!callHasDone} contact_id={contact_id} estate_id={estate_id} />
                                    </List.Content>
                                </List.Item>
                            )}
                            <List.Item>
                                <List.Content>
                                    <OwnerIsAlreadySoldButton disabled={!callHasDone} estate_id={estate_id} />
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content>
                                    <OwnerIsRealtorButton disabled={!callHasDone} estate_id={estate_id} />
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content>
                                    <OwnerIsNotActually disabled={!callHasDone} estate_id={estate_id} />
                                </List.Content>
                            </List.Item>
                        </List>
                    </div>
                )}

                {!loadingItem && assignOwnersHistory && (
                    <div className="crm-Estate__ownerHistory">
                        Доступ получали:
                        <button
                            onClick={this.handleOpenHistory}
                            title="Нажмите, чтобы посмотреть историю получения доступа"
                            className="crm-Estate__ownerHistory_btn"
                        >
                            {showAssignHistory ? 'скрыть' : printAssignCount(assignOwnersHistory.length)}
                        </button>
                        {showAssignHistory && (
                            <div className="crm-Estate__ownerHistory_list">
                                <Feed>
                                    {assignOwnersHistory.map(({ user, assignTime }) => (
                                        <Feed.Event key={assignTime}>
                                            <Feed.Label>
                                                {user.avatarUrl ? <img alt="" src={user.avatarUrl} /> : <Icon name="user" color="red" />}
                                            </Feed.Label>

                                            <Feed.Content>
                                                <Feed.Summary>
                                                    <UserLinkWithPreview user={user} />
                                                    <Feed.Date>
                                                        <Time time={assignTime} status={false} />
                                                    </Feed.Date>
                                                </Feed.Summary>
                                            </Feed.Content>
                                        </Feed.Event>
                                    ))}
                                </Feed>
                            </div>
                        )}
                    </div>
                )}
            </PreventDblClick>
        );
    }
}

export default OwnerAssignButtonBlock;
