import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import OfficeBuildingBlockEditing from '../EditBlocks/OfficeBuildingBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import {
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_WAREHOUSE
} from '~/types/estate.types';

@observer
class OfficeBuildingBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            buildingName,
            buildYear,
            buildingType,
            buildingClassType,
            totalAreaBuilding,
            landArea,
            landType,
            landAreaUnitType,
            statusType,
            developer,
            managementCompany,
            ventilationType,
            conditioningType,
            extinguishingSystemType,
            heatingType,
            propertyType
        } = item || {};

        return (
            <Card fluid color="red">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Здание</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Название БЦ</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={buildingName} />
                                    </Grid.Column>

                                    {[ESTATE_PROPERTY_TYPE_OFFICE, ESTATE_PROPERTY_TYPE_WAREHOUSE, ESTATE_PROPERTY_TYPE_INDUSTRY].includes(
                                        propertyType
                                    ) && (
                                        <Fragment>
                                            <Grid.Column>Год постройки</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment shadow={loadingItem} print={buildYear} />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    <Grid.Column>Тип здания</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.OFFICE_BUILDING_TYPE_FIND(buildingType)} />
                                    </Grid.Column>

                                    {[
                                        ESTATE_PROPERTY_TYPE_OFFICE,
                                        ESTATE_PROPERTY_TYPE_WAREHOUSE,
                                        ESTATE_PROPERTY_TYPE_SHOPPING,
                                        ESTATE_PROPERTY_TYPE_INDUSTRY
                                    ].includes(propertyType) && (
                                        <Fragment>
                                            <Grid.Column>Класс Здания</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment
                                                    shadow={loadingItem}
                                                    print={estateL10n.OFFICE_CLASS_TYPE_FIND(buildingClassType)}
                                                />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    <Grid.Column>Площадь здания</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={totalAreaBuilding} /> м²
                                    </Grid.Column>

                                    <Grid.Column>Участок</Grid.Column>
                                    <Grid.Column>
                                        {Number(landArea) > 0 && `${landArea || '-'} ${estateL10n.LAND_AREA_TYPES_FIND(landAreaUnitType)}`}
                                        {landType === 1 && ' в собственности'}
                                        {landType === 2 && ' в аренде'}
                                    </Grid.Column>

                                    <Grid.Column>Категория</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.OFFICE_STATUS_TYPE_FIND(statusType)} />
                                    </Grid.Column>

                                    <Grid.Column>Девелопер</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={developer} />
                                    </Grid.Column>

                                    <Grid.Column>Управляющая компания</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={managementCompany} />
                                    </Grid.Column>

                                    <Grid.Column>Вентиляция</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.OFFICE_VENTILATION_TYPE_FIND(ventilationType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Кондиционирование</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.OFFICE_CONDITIONING_TYPE_FIND(conditioningType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Система пожаротушения</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.OFFICE_EXTINGUISTING_SYSTEM_TYPE_FIND(extinguishingSystemType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Отопление</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.OFFICE_HEATING_TYPE_FIND(heatingType)} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <OfficeBuildingBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, OfficeBuildingBlockShow);
