import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';

import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import ConfirmTrigger from '~ui/ConfirmTrigger';

import MenuItem from '@material-ui/core/MenuItem';

export type DisableItemConfirmProps = {
    item_id: number;
    enable: boolean;
    store: ItemStoreInterface<any>;
};

const DisableItemConfirm = ({ item_id, enable, store }: DisableItemConfirmProps) => {
    const toggleDisable = async () => {
        await store.toggleDisableItem(item_id, !enable);
    };

    return (
        <ConfirmTrigger
            trigger={
                <MenuItem>
                    <Icon name="trash" />
                    <span className="text">{enable ? 'В архив' : 'Вернуть из архива'}</span>
                </MenuItem>
            }
            header={enable ? `Отправить карточку в Архив` : 'Вернуть из архива'}
            content={`Подтвердите, что вы хотите ${enable ? 'удалить' : 'вернуть'} эту карточку`}
            confirmButton={enable ? 'В архив' : 'Вернуть из архива'}
            onConfirm={toggleDisable}
        />
    );
};

export default DisableItemConfirm;
