import React, { ReactNode, useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { fetchExportRedeemBalance } from '~/api/export/exportStatisticsApi';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Modal, { ModalContent } from '~ui/Modal';
import exportStatisticsStore from '~/stores/export/exportStatisticsStore';
import { UserBalanceReset } from '~/types/exportStatistics.types';
import { printTableNumber } from '~/components/Items/Estate/SubBlocks/StatisticsMode/EstateStatisticsTable';
import estateL10n from '~/L10n/estate.L10n';
import commonStore from '~/stores/commonStore';
import Progress from '~ui/Progress';
import ListError from '~/components/ListError';
import Time from '~/components/Base/Time';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';

const RedeemBalanceModalTable = () => {
    const [statData, setStatData] = useState<UserBalanceReset[]>([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const { listFilter } = exportStatisticsStore;
        if (listFilter.propertyTypes.length || listFilter.type) {
            setErrors(['Таблица доступна, только когда не указан Тип недвижимости в фильтре']);
            setLoading(false);
            return;
        }

        const { major_user_id, fromTimeSec, toTimeSec, deltaTimeSec, group_id } = listFilter;
        fetchExportRedeemBalance({ major_user_id, fromTimeSec, toTimeSec, deltaTimeSec, group_id })
            .then(data => {
                setStatData(data);
            })
            .catch(errors => {
                setErrors(errors);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    let totalRedeemSum = 0;

    return (
        <>
            <Progress show={loading} size={54} />
            <ListError errors={errors} />
            {!errors.length && (
                <TableContainer>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Дата</TableCell>
                                <TableCell>Сотрудник</TableCell>
                                <TableCell align="right">Сумма</TableCell>
                                <TableCell>Кто списал</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {statData.map(({ redeemTime, resetBalance, user, major_user }, index) => {
                                totalRedeemSum += resetBalance;
                                return (
                                    <TableRow key={redeemTime}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <Time time={redeemTime} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <UserLinkWithPreview user={user} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {printTableNumber(resetBalance, estateL10n.CURRENCY_TYPES_FIND(1))}
                                        </TableCell>
                                        <TableCell align="left">
                                            <UserLinkWithPreview user={major_user} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={3}>
                                    <b>Итого</b>
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                    {printTableNumber(totalRedeemSum, estateL10n.CURRENCY_TYPES_FIND(1))}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

const RedeemBalanceModal = ({ value }: { value: string }) => {
    return (
        <Modal
            header="Списания баланса сотрудника(ов)"
            maxWidth="md"
            fullScreen={commonStore.isMobile}
            trigger={
                <Link component="button" variant="body2">
                    {value}
                </Link>
            }
        >
            <ModalContent>
                <RedeemBalanceModalTable />
            </ModalContent>
        </Modal>
    );
};

export default RedeemBalanceModal;
