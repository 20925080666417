import { action } from 'mobx';
import ListStorePrototype from './ListStore.prototype';
import deepCopy from '~/common/deepCopy';

abstract class ItemListWithPultStorePrototype<
    ItemObjectType,
    ItemListType = ItemObjectType,
    ItemPropertyType = {},
    ItemListFilter = {}
> extends ListStorePrototype<ItemObjectType, ItemObjectType, ItemPropertyType, ItemListFilter> {
    abstract LIST_MEM_KEY: string;

    onAfterInitHook(): void {
        const memoryListJSON = window.localStorage.getItem(this.LIST_MEM_KEY);
        if (memoryListJSON) {
            this.list = JSON.parse(memoryListJSON);
        }
    }

    @action
    async fetchItem(id: number, base?: string | null): Promise<void> {
        await super.fetchItem(id);
        const { item } = this.getItem(id);
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }

    @action
    async createItem(): Promise<number> {
        const item_id = await super.createItem();
        this.fetchList();

        return item_id;
    }

    @action
    async saveItem(item_id: number): Promise<boolean> {
        if (await super.saveItem(item_id)) {
            setTimeout(async () => {
                await this.fetchList();
                this.fetchItem(item_id);
                this.debounceFilterFetch();
            }, 0);
            return true;
        }
        return false;
    }

    @action
    async fetchList(): Promise<void> {
        await super.fetchList();
        window.localStorage.setItem(this.LIST_MEM_KEY, JSON.stringify(this.list));
    }

    matchListAccessRead() {}
}

export default ItemListWithPultStorePrototype;
