import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon, Placeholder } from 'semantic-ui-react';

import taskStore from '~/stores/taskStore';
import ItemLink from '../../../Base/ItemLink';

@observer
class TaskAttachedShow extends Component<{ task_id: number }> {
    render() {
        const { task_id } = this.props;
        const { item } = taskStore.getItem(task_id);

        if (!item) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { attached } = item;

        return (
            <Card fluid color="grey">
                <Card.Content>
                    <Header size="tiny">
                        <Icon name="attach" /> Карточк
                        {attached && attached.length === 1 && 'а'}
                        {attached && attached.length > 1 && 'и'}
                    </Header>
                    <Grid columns={2}>
                        {attached &&
                            attached.map(({ item_id, item_type, item }) => (
                                <Fragment key={item_id}>
                                    <Grid.Column>
                                        <ItemLink item_type={item_type} item={item} />
                                    </Grid.Column>
                                </Fragment>
                            ))}
                    </Grid>
                </Card.Content>
            </Card>
        );
    }
}

export default TaskAttachedShow;
