import React, { Fragment } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Button from '@material-ui/core/Button';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const orderBySet: [string, string, OrderDirectionType][] = [
    ['По дате обновления', 'updateTime', 'descending'],
    ['По дате создания', 'createTime', 'descending'],
    ['Цена по возрастанию', 'price', 'ascending'],
    ['Цена по убыванию', 'price', 'descending']
];

const OrderByButton = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'transparent',
        textTransform: 'none'
    }
}))(Button);

export default function MapOrderBy({ onChange }: { onChange: (orderBy: string, orderDirection: OrderDirectionType) => void }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        onChange(orderBySet[index][1], orderBySet[index][2]);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <OrderByButton onClick={handleClickListItem} size="small" startIcon={<ArrowDropDownIcon />}>
                {orderBySet[selectedIndex][0]}
            </OrderByButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {orderBySet.map(([option], index) => (
                    <MenuItem key={option} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
}
