import React, { useEffect, useState } from 'react';

import ImageGallery from 'react-image-gallery';

import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';
import LoaderAwait from '~/components/Base/LoaderAwait';
import commonStore from '~/stores/commonStore';
import { UploadingPhoto } from '~/types/estate.types';

const AWAIT_FOR_PHOTOS_SHOWING_MS = 400;

type PhotoGalleryProps = {
    photos: UploadingPhoto[];
    updatingPhotos: boolean;
    loadingItem: boolean;
};

const PhotoGalleryMobile = ({ photos, updatingPhotos, loadingItem }: PhotoGalleryProps) => {
    const [waitingForShowing, setWaitingForShowing] = useState(true); // wait while the card will be opened (for good performance)
    const waitingInterval = window.setTimeout(() => {
        setWaitingForShowing(false);
    }, AWAIT_FOR_PHOTOS_SHOWING_MS);

    useEffect(() => {
        return () => {
            clearInterval(waitingInterval);
        };
    }, []);

    const images = photos
        ? photos.map(photo => ({
              original: matchEstateThumbnailPhotoUrl(photo.filename, false, 2).src,
              fullscreen: matchEstateThumbnailPhotoUrl(photo.filename, false, 0).src
          }))
        : [];

    return (
        <div className="crm-Estate__photoMobile">
            <ImageGallery
                items={images}
                showNav={!commonStore.isMobile && photos.length > 1}
                showFullscreenButton={true}
                showThumbnails={false}
                showPlayButton={false}
                showBullets={images.length > 1}
                lazyLoad
            />

            <LoaderAwait active={waitingForShowing || loadingItem || updatingPhotos} size="medium" />
        </div>
    );
};

export default PhotoGalleryMobile;
