import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { SourcePhone } from '~/types/sourcePhones.types';
import deepCopy from '~/common/deepCopy';

const SourcePhoneGQLFields = `
    source_phone_id
    phone
    source_id
    title
    tags_ids

    createTime
    updateTime
    enable
`;

export const createItem = async (inputSourcePhone: SourcePhone): Promise<number> => {
    console.log(deepCopy(inputSourcePhone));
    const graphql = `mutation { 
          createSourcePhone(inputSourcePhone: ${objectToGraphql(inputSourcePhone)})
        }`;

    const data = await fetchApi.postGQ<{ createSourcePhone: number }>(graphql);
    return data.createSourcePhone;
};

export const saveItem = async (source_phone_id: number, sourcePhoneDiff: SourcePhone): Promise<number> => {
    console.log(deepCopy(sourcePhoneDiff));

    const graphql = `mutation { 
        updateSourcePhone(source_phone_id: ${source_phone_id}, sourcePhoneDiff: ${objectToGraphql(sourcePhoneDiff)})
    }`;

    const data = await fetchApi.postGQ<{ updateSourcePhone: number }>(graphql);
    return data.updateSourcePhone;
};

export const fetchItem = async (source_phone_id: number): Promise<SourcePhone> => {
    const graphql = `{
            fetchSourcePhone(source_phone_id: ${source_phone_id}) {
                ${SourcePhoneGQLFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchSourcePhone: SourcePhone }>(graphql);
    return data.fetchSourcePhone;
};

export const fetchList = async (): Promise<{ list: SourcePhone[]; count: number }> => {
    const graphql = `{
            fetchSourcePhones{
                list {
                    ${SourcePhoneGQLFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchSourcePhones: { list: SourcePhone[]; count: number } }>(graphql);
    return data.fetchSourcePhones;
};
