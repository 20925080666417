import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import ListIcon from '@material-ui/icons/List';
import ShareIcon from '@material-ui/icons/Share';

import ProgressiveTabs, { ProgressiveTab } from '~ui/ProgressiveTabs';
import EstateSameMode from './EstateSameMode';
import EstateSMAShow from './EstateSmaBlocks/EstateSMAShow';
import SmaShareShow from './EstateSmaBlocks/SmaShareShow';

import commonStore from '~/stores/commonStore';

import { fetchOptions } from '~/api/estateSMAApi';
import { SmaOptionsType } from '~/types/estateSma.types';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';

const TAB_SMA = 'sma';
const TAB_ESTATES_SAME = 'same';
const TAB_SMA_SHARE = 'share';

const EstateSMAMode = ({ estate_id }: { estate_id: number }) => {
    const [options, setOptions] = useState<SmaOptionsType | null>(null);

    useMemo(() => {
        fetchOptions(estate_id).then(options => {
            setOptions(options);
        });
    }, []);

    const tabs: ProgressiveTab[] = [
        {
            label: 'Похожие',
            value: TAB_ESTATES_SAME,
            icon: <EqualizerIcon />,
            tabElement: <EstateSameMode estate_id={estate_id} base={ESTATE_BASE_MAIN} />
        },
        {
            label: 'СМА',
            value: TAB_SMA,
            icon: <ListIcon />,
            tabElement: <EstateSMAShow estate_id={estate_id} />
        }
    ];
    if (!commonStore.isMobile) {
        tabs.push({
            disabled: !options,
            label: 'Отправить СМА',
            value: TAB_SMA_SHARE,
            icon: <ShareIcon />,
            tabElement: options && <SmaShareShow sma_id={options.sma_id} estate_id={estate_id} />
        });
    }

    return <ProgressiveTabs tabs={tabs} showBottomLabels />;
};

export default observer(EstateSMAMode);
