import React from 'react';
import { observer } from 'mobx-react';

import UserTransactionsTab from './ShowBlocks/Balance/UserTransactionsTab';
import UserBalanceTab from './ShowBlocks/Balance/UserBalanceTab';
import ProgressiveTabs, { ProgressiveTab } from '~ui/ProgressiveTabs';
import ListIcon from '@material-ui/icons/List';

const TAB_MAIN = 'main';
const TAB_TRANSACTIONS = 'transactions';

const UserBalanceMode = ({ user_id }: { user_id: number }) => {
    const tabs: ProgressiveTab[] = [
        {
            label: 'Обзор',
            value: TAB_MAIN,
            icon: <ListIcon />,
            tabElement: <UserBalanceTab user_id={user_id} />
        },
        {
            label: 'Расход на рекламу',
            value: TAB_TRANSACTIONS,
            icon: <ListIcon />,
            tabElement: <UserTransactionsTab user_id={user_id} />
        }
    ];

    return <ProgressiveTabs tabs={tabs} defaultValue={TAB_MAIN} />;
};

export default observer(UserBalanceMode);
