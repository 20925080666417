import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import estateL10n from '~/L10n/estate.L10n';
import history from '~/history';

import showingStore from '~/stores/showingStore';
import estateStore from '~/stores/estateStore';

import ContactIdBlockEditing from '../../Common/ContactIDBlockEditing';
import EstateIDBlockEditing from '../../Common/EstateIDBlockEditing';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';

import { SHOWING_TYPE_INNER, SHOWING_TYPE_MLS, SHOWING_TYPE_OUTER } from '~/types/showings.types';
import { DEAL_ESTATE_TYPE } from '~/types/deals.types';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MY } from '~/types/estate.types';

import TextInput from '~ui/TextInput';
import Select from '~ui/Select';
import Grid from '~ui/Grid';
import Checkbox from '~ui/Checkbox';

const useStyles = makeStyles(({ spacing }) => ({
    estate: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(1),
        '& > *': {
            marginRight: spacing(2)
        }
    }
}));

type EstateAndContactEditingProps = HandlerEditingWrappedProps & {
    contact_id?: number;
    estate_id?: number;
    showingType?: DEAL_ESTATE_TYPE;
    estateOuterUrl?: string;
};

const EstateAndContactEditing = (props: EstateAndContactEditingProps) => {
    const classes = useStyles();

    useMemo(() => {
        const {
            location: {
                state: { from }
            }
        } = history;

        const item = {
            showing_id: props.item_id,
            showingType: props.showingType || SHOWING_TYPE_INNER,
            contact_id: props.contact_id || (from && from.storeName === 'contactStore' ? from.item_id : null),
            estate_id: props.estate_id || (from && from.storeName === 'estateStore' && from.item_id) || null,
            estateOuterUrl: props.estateOuterUrl || null
        };

        showingStore.setEditingItem(props.item_id, item);
    }, []);

    const [showMy, setShowMy] = useState(true);
    const toggleShowMy = () => {
        setShowMy(!showMy);
    };
    useEffect(() => {
        estateStore.fetchItemDropdownBase = showMy ? ESTATE_BASE_MY : ESTATE_BASE_MAIN;
        estateStore.debounceItemDropdownOptions();
    }, [showMy]);

    const { item_id, editing } = props;
    const { contact_id, estate_id, estateOuterUrl, showingType } = showingStore.getItem(item_id).editingItem;

    return (
        <div className="crm-Item__editingMode">
            <Grid columns={editing ? 1 : 2} stackable spacing={0}>
                <Grid.Column>
                    <div className={classes.estate}>
                        <label className="crm-Item__labelWide">Объект</label>
                        <Select
                            name="showingType"
                            value={showingType}
                            onChange={props.handleChange}
                            options={estateL10n.SHOWING_TYPES_DROPDOWN}
                        />
                        {showingType === SHOWING_TYPE_INNER && <Checkbox label="Мои" checked={showMy} onChange={toggleShowMy} />}
                    </div>
                    {showingType < SHOWING_TYPE_OUTER && (
                        <EstateIDBlockEditing
                            isMls={showingType === SHOWING_TYPE_MLS}
                            item_id={item_id}
                            store={showingStore}
                            editing={estate_id === null}
                            value={Number(estate_id)}
                        />
                    )}
                    {showingType === SHOWING_TYPE_OUTER && (
                        <TextInput
                            type="url"
                            variant="classic"
                            required
                            name="estateOuterUrl"
                            value={estateOuterUrl}
                            onChange={props.handleChange}
                            size="small"
                            label="URL на объект, например из базы ЦИАН"
                        />
                    )}
                </Grid.Column>
                <Grid.Column>
                    <label className="crm-Item__labelWide" style={{ marginBottom: '10px' }}>
                        Клиент (или Посредник)
                    </label>
                    <ContactIdBlockEditing store={showingStore} editing={contact_id === null} item_id={item_id} value={contact_id} />
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default HandlerEditingBlockWrapper(showingStore, observer(EstateAndContactEditing));
