import React, { Component, Fragment } from 'react';
import { Input, Form, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import EditField from '../../../Base/EditField';

import taskStore from '~/stores/taskStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import tasksL10n from '../../../../L10n/tasks.L10n';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type TitleBlockEditingProps = HandlerEditingWrappedProps & {
    title?: string;
    description?: string;
    priority?: number;
};

@observer
class TaskTitleEditing extends Component<TitleBlockEditingProps> {
    constructor(props: TitleBlockEditingProps) {
        super(props);

        const item = {
            task_id: this.props.item_id,
            title: this.props.title || '',
            description: this.props.description || '',
            priority: this.props.priority || 1
        };
        taskStore.setEditingItem(item.task_id, item);
    }

    render() {
        const { item_id, handleChange, handleTextAreaChange } = this.props;
        const { title, description, priority } = taskStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field style={{ width: '100%' }} className={`crm-Tasks__title ${item_id > 0 ? 'crm-Tasks__title_editing' : ''}`}>
                        <label className="crm-Estate__field_label">Задача</label>
                        <Input
                            placeholder="Введите название задачи"
                            required
                            size="small"
                            value={title || ''}
                            name="title"
                            onChange={handleChange}
                        />

                        {item_id !== CREATING_ITEM_ID && <label className="crm-Estate__field_label">Приоритет</label>}
                        <Dropdown
                            selection
                            options={tasksL10n.PRIORITY_DROPDOWN}
                            name="priority"
                            value={priority}
                            onChange={handleChange}
                        />
                    </Form.Field>
                </Form.Group>

                <EditField.Area name="description" label="Описание" value={description} onChange={handleTextAreaChange} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(taskStore, TaskTitleEditing);
