import React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import './statistics.less';

import statisticStore, {
    STAT_MODE_CHART_DAY,
    STAT_MODE_CHART_MONTH,
    STAT_MODE_CHART_WEEK,
    STAT_MODE_KPI,
    STAT_MODE_TABLE,
    StatModeType
} from '~/stores/statisticStore';
import kpiStore from '~/stores/kpiStore';
import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import TableStatistics from './TableStatistics';
import ChartStatistics from './ChartStatistics';
import { ACCESS_COMPANY, ACCESS_READ, ACCESS_GROUP } from '~/types/access.types';
import ButtonGroup from '../Base/ButtonGroup';
import { CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK } from '~/types/calendar.types';
import UsersAndGroupsDropdown from '../Lists/Common/UsersAndGroupsDropdown';

const StatisticsModal = () => {
    const { mainTableKpi } = kpiStore;
    const { statMode } = statisticStore;

    const handleChangeMode = (event: React.SyntheticEvent, { value }: { value: StatModeType }) => {
        statisticStore.setStatMode(value);
    };

    return (
        <Container className="crm-Home" style={{ width: 'calc(100% - 40px)' }}>
            <Box display="flex" alignItems="center" paddingBottom={1}>
                <Box display="flex" alignItems="center" style={{ gap: '2rem' }} flex={1}>
                    <Typography variant="h6" noWrap>
                        Сводная таблица показателей
                    </Typography>
                    {[ACCESS_COMPANY, ACCESS_GROUP].includes(authStore.getPermission(statisticStore.moduleName, ACCESS_READ)) && (
                        <ButtonGroup
                            buttonSet={[
                                [STAT_MODE_TABLE, 'Основная таблица'],
                                [STAT_MODE_CHART_DAY, 'График (день)'],
                                [STAT_MODE_CHART_WEEK, 'График (неделя)'],
                                [STAT_MODE_CHART_MONTH, 'График (месяц)']
                            ]}
                            name="statMode"
                            value={statMode}
                            handleChange={handleChangeMode}
                        />
                    )}
                </Box>
                {!commonStore.isMobile && (
                    <Box minWidth={200}>
                        {statMode === STAT_MODE_TABLE && mainTableKpi && <UsersAndGroupsDropdown disableUsers store={kpiStore} />}
                        {((statMode === STAT_MODE_TABLE && !mainTableKpi) || statMode !== STAT_MODE_TABLE) && (
                            <UsersAndGroupsDropdown
                                store={statisticStore}
                                disableUsers={[STAT_MODE_TABLE, STAT_MODE_KPI].includes(statMode)}
                            />
                        )}
                    </Box>
                )}
            </Box>

            <Box position="relative">
                {statMode === STAT_MODE_TABLE && <TableStatistics />}
                {statMode === STAT_MODE_CHART_DAY && <ChartStatistics period={CALENDAR_VIEW_TYPE_DAY} />}
                {statMode === STAT_MODE_CHART_WEEK && <ChartStatistics period={CALENDAR_VIEW_TYPE_WEEK} />}
                {statMode === STAT_MODE_CHART_MONTH && <ChartStatistics period={CALENDAR_VIEW_TYPE_MONTH} />}
            </Box>
        </Container>
    );
};

export default observer(StatisticsModal);
