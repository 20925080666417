import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Menu } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import PreventDblClick from '../../Base/PreventDblClick';
import wishStore, { DEFAULT_APPROXIMATION_RANGE } from '~/stores/wishStore';
import EstateTableBody from '../../Lists/Estate/EstateTableBody';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import history from '../../../history';
import ApproximationInput from '../Common/ApproximationInput';
import debounce from '../../../common/debounce';
import ButtonGroup from '../../Base/ButtonGroup';
import bottomTabStore from '~/stores/bottomTabStore';
import { WarningMessage } from '~ui/Message';

type WishEstatesModeProps = {
    wish_id: number;
};

type WishEstatesModeState = {
    approximation: number;
};

@observer
class WishEstatesMode extends Component<WishEstatesModeProps, WishEstatesModeState> {
    debounceEstate: () => void;

    constructor(props: WishEstatesModeProps) {
        super(props);

        this.state = {
            approximation: DEFAULT_APPROXIMATION_RANGE
        };

        this.debounceEstate = debounce(() => {
            const { wish_id } = this.props;
            wishStore.fetchEstatesByWish(wish_id, this.state.approximation);
        }, 350);
    }

    handleSetEstateFilter = () => {
        const { wish_id } = this.props;
        const { item } = wishStore.getItem(wish_id);
        wishStore.setEstateFilterFromWish(wish_id, this.state.approximation);

        if (item) {
            bottomTabStore.addTab({
                item_id: wish_id,
                item_type: wishStore.moduleName,
                item: { wish_id, type: item.type, enable: item.enable }
            });
        }

        history.push({
            pathname: `/estate/sell`
        });
    };

    handleChageApproximation = (approximation: number) => {
        this.setState({ approximation });
        this.debounceEstate();
    };

    handleChangeLocationType = (event: React.SyntheticEvent, { value: locationType }: { value: number }) => {
        wishStore.setProperty(this.props.wish_id, { locationType });
        this.debounceEstate();
    };

    render() {
        const { wish_id } = this.props;
        const { approximation } = this.state;
        const { estates, loadingEstates, locationType } = wishStore.getItem(wish_id).property;

        return (
            <PreventDblClick>
                <ApproximationInput approximation={approximation} onChange={this.handleChageApproximation}>
                    <Menu.Item className="crm-List__filter_dropdown">
                        <ButtonGroup
                            disabled={!locationType}
                            buttonSet={[
                                [1, 'По метро'],
                                [2, 'По городу']
                            ]}
                            name="locationType"
                            value={locationType}
                            handleChange={this.handleChangeLocationType}
                        />
                    </Menu.Item>
                </ApproximationInput>

                <Table structured size="small" sortable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Местоположение</Table.HeaderCell>
                            <Table.HeaderCell>Площадь</Table.HeaderCell>
                            <Table.HeaderCell>Этаж</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {estates && <EstateTableBody estateList={estates} simpleMode={true} />}
                </Table>
                {!loadingEstates && estates && estates.length === 0 && <WarningMessage>Не получилось подобрать объекты</WarningMessage>}

                <Button
                    icon="search"
                    loading={!estates || loadingEstates}
                    className="crm-Item__addMode_button"
                    primary
                    onClick={this.handleSetEstateFilter}
                    size="tiny"
                    content="Открыть в поиске Недвижимости"
                />
            </PreventDblClick>
        );
    }
}

export default WishEstatesMode;
