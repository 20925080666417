import React, { Component, Fragment } from 'react';
import { Form, Ref } from 'semantic-ui-react';
import { ComponentType } from 'react';
import NotEnoughAccess from '../Base/NotEnoughAccess';
import { observer } from 'mobx-react';

import authStore from '~/stores/authStore';
import history from '../../history';
import { checkFormValid } from '../../common/forms';
import { LinkStateType } from './TabMenuWrapper';
import { GlobalStores } from '../../index';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';

export type ItemAddModeWrapperProps = {
    handleCreate: (event: React.SyntheticEvent) => void;
    clearForm: (event: React.SyntheticEvent) => void;
};

const ItemAddModeWrapper = (
    store: ListStoreInterface,
    ItemLinkState: LinkStateType,
    WrappedComponent: ComponentType<ItemAddModeWrapperProps>
) => {
    @observer
    class ItemAddModeWrapperClass extends Component<{ param?: string }, { clearing: boolean }> {
        constructor(props: { param?: string }) {
            super(props);

            store.setEditingItem(CREATING_ITEM_ID, {
                [store.item_id]: CREATING_ITEM_ID
            });

            this.state = {
                clearing: false
            };
        }

        $form: HTMLElement;

        checkAccess = (): boolean => {
            return authStore.canCreate(store.moduleName);
        };

        handleCreate = async (event: React.SyntheticEvent) => {
            if (checkFormValid(this.$form, false)) {
                event.preventDefault();
                event.stopPropagation();

                const item_id = await store.createItem();
                if (!item_id) {
                    return;
                }

                const {
                    location: {
                        state: { from, callback }
                    }
                } = history;

                if (from) {
                    // setTimeout нужен, чтобы произошел fetchItem в данном модуле, а потом уже произвести изменение в модуле из роутера
                    setTimeout(() => {
                        GlobalStores[from.storeName].setEditingItem(from.item_id, {
                            [store.item_id]: item_id
                        });
                    }, 0);
                }

                if (callback) {
                    // setTimeout нужен, чтобы произошел fetchItem в данном модуле, а потом уже произвести изменение в модуле из роутера
                    setTimeout(() => {
                        const store = GlobalStores[callback.storeName];
                        const func = store[callback.method];

                        if (typeof func === 'function') {
                            func.call(store, callback.item_id, item_id);
                        } else {
                            throw Error(`Method doen't exits: ${callback.storeName}.${callback.method}`);
                        }
                    }, 0);
                }

                if (item_id > 0) {
                    history.replace(ItemLinkState(item_id, this.props.param || ''));
                }
            }
        };

        clearForm = (event: React.SyntheticEvent) => {
            event.preventDefault();
            store.clearEditingItem(CREATING_ITEM_ID);
            this.setState({ clearing: true });
            setTimeout(() => this.setState({ clearing: false }), 0);
        };

        render() {
            const { clearing } = this.state;

            if (clearing) {
                return null;
            }

            return this.checkAccess() ? (
                <Ref innerRef={$form => (this.$form = $form)}>
                    <Form className="crm-Estate__addForm">
                        <WrappedComponent handleCreate={this.handleCreate} clearForm={this.clearForm} />
                    </Form>
                </Ref>
            ) : (
                <NotEnoughAccess />
            );
        }
    }

    return ItemAddModeWrapperClass;
};

export default ItemAddModeWrapper;
