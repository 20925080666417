import React from 'react';
import { Modal, ModalContent } from '~ui/Modal';
import { AdditionalDocType } from '~/types/documents.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import DocumentsTable from './DocumentsTable';
import commonStore from '~/stores/commonStore';

type DocumentsDropModalProps = {
    item_id: number;
    item_type: ITEM_TYPES;
    additionalTypes?: AdditionalDocType[];
    onClose: () => void;
};

const DocumentsDropModal = (props: DocumentsDropModalProps) => {
    const { onClose, item_id, item_type, additionalTypes } = props;

    return (
        <Modal header={`Загрузка документов №${item_id}`} maxWidth="md" fullScreen={commonStore.isMobile} onClose={onClose}>
            <ModalContent>
                <DocumentsTable item_id={item_id} item_type={item_type} additionalTypes={additionalTypes} />
            </ModalContent>
        </Modal>
    );
};

export default DocumentsDropModal;
