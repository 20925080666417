import { observable, action } from 'mobx';
import * as whatsappApi from '~/api/whatsappApi';
import { io as socketIoClient, Socket } from 'socket.io-client';
import serverConfig from '~/config/server.config';
import { WAChatType, WAChatMessage } from '~/types/whatsapp.types';

import authStore from './authStore';
import ItemListsStorePrototype from '~/stores/prototypes/ItemListsStore.prototype';
import commonStore from '~/stores/commonStore';

export type WAClientStatus = 'qr' | 'authInProgress' | 'init' | 'ready' | 'phoneNotConnected' | 'phoneNotMatched' | 'disconnect';

class WhatsappStore extends ItemListsStorePrototype<WAChatMessage, number, { whatsapp_chat_id: number | null }, WAChatType> {
    itemListFilterClear = { whatsapp_chat_id: null };

    constructor() {
        super('whatsapp_chat_id', whatsappApi);

        authStore.registerInitFunc(this.initSocketConnection);
    }

    adjustItemToId(chat: WAChatMessage): number {
        return chat.whatsapp_chat_id;
    }

    @action
    async fetchWAChatOptions(contact_id: number) {
        const options = await whatsappApi.fetchWhatsappChatOptions(contact_id);
        this.setListOptions(contact_id, options);
        if (options.chats.length) {
            this.changeListFilter(contact_id, 'whatsapp_chat_id', options.chats[0].whatsapp_chat_id);
        }
    }

    @action
    loadNextPage = async (contact_id: number) => {
        const itemList = this.getItemList(contact_id);
        const { loadingList, pagination, filter } = itemList;

        if (!loadingList) {
            const { activePage, pageSize } = pagination;
            const offset = activePage * pageSize;

            itemList.loadingList = true;
            const data = await this.apiModule.fetchItemList(contact_id, filter, { pageSize, offset });

            // activePage проверяем, т.к. при activePage===1 в WAMessagesList.tsx происходит скачек скролинга
            if (data.length || activePage < 2) {
                itemList.list = [...itemList.list, ...data];
                itemList.pagination = { ...itemList.pagination, activePage: activePage + 1 };
            }

            setTimeout(() => {
                itemList.loadingList = false;
            }, 0);
        }
    };

    socket: Socket | null = null;

    @observable
    whatsappError: string | null = null;

    @action
    setError(error: string) {
        this.whatsappError = error;
        setTimeout(() => {
            this.whatsappError = null;
        }, 5000);
    }

    initSocketConnection = () => {
        const { currentUser } = authStore;
        if (!commonStore.isMobile && serverConfig.whatsappServerUrl && currentUser.whatsappIsEnable) {
            const port = serverConfig.whatsappDefaultPort + currentUser.user_id;
            const waSocketUrl = serverConfig.whatsappServerUrl + `${port}/`;
            this.socket = socketIoClient(waSocketUrl, { forceNew: true, autoConnect: true });

            this.socket.on('connect', () => {
                console.log('connect');
                // either with send()
                this.socket.send('Hello!');
            });

            this.socket.on('status', this.handleWhatsappCommands);

            this.socket.on('disconnect', () => this.setStatus('disconnect'));

            this.socket.on('error', ({ error }) => {
                this.setError(error);
            });
        }
    };

    @observable
    whatsappQRCode: string | null = null;

    @observable
    whatsappStatus: WAClientStatus = 'init';

    @observable
    whatsappConnectedPhone: string | null = null;

    @action
    setStatus(status: WAClientStatus) {
        this.whatsappStatus = status;
    }

    @action
    handleWhatsappCommands = ({ status, qr, phone }: { status: WAClientStatus; qr?: string; phone?: string }) => {
        console.log('handleWhatsappCommands', status, qr);
        this.whatsappQRCode = status === 'qr' ? qr : null;
        this.whatsappConnectedPhone = ['phoneNotMatched', 'ready'].includes(status) ? phone : null;
        this.setStatus(status);
    };

    async sendMessageToPhone(phone: string, message: string): Promise<boolean> {
        if (this.socket && this.whatsappStatus === 'ready') {
            await this.socket.emit('send_message', { message, phone });
            return true;
        }
        this.setError('Нет соединения с Whatsapp');
        return false;
    }

    sendMessage = async (contact_id: number, message: string, phone: string): Promise<void> => {
        if (await this.sendMessageToPhone(phone, message)) {
            setTimeout(() => {
                this.fetchItemList(contact_id);
            }, 0);
        }
    };
}

export default new WhatsappStore();
