import React, { useMemo } from 'react';
import { Modal, ModalActions, ModalContent } from '~ui/Modal';

import Button from '~ui/Button';

import { observer } from 'mobx-react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import commonStore from '~/stores/commonStore';

import ExportFeedSettingsEditing from '~/components/Items/ExportFeed/Blocks/ExportFeedSettingsEditing';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

const DEFAULT_TARIFF_NAME = 'Бесплатное';

const AddExportBaseModal = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        exportingBaseStore.fetchItem(CREATING_ITEM_ID);
        exportingBaseStore.setEditingItem(CREATING_ITEM_ID, {
            name: '',
            image: '',
            config: '{}',
            format: 'cian',
            feedurl: '',
            enable: true,
            tariffs: [],
            regionName: '',
            regionIds: [0]
        });
    }, []);

    // const [tariffName, setTariffName] = useState(DEFAULT_TARIFF_NAME);

    const handleCreate = async () => {
        if (await exportingBaseStore.createItem()) {
            onClose();
            await exportingBaseStore.loadExportingBases();
            exportingBaseStore.fetchExportingBasesStatistics();
        }
    };

    const { loadingItem, errors } = exportingBaseStore.getItem(CREATING_ITEM_ID);

    return (
        <Modal
            onClose={!loadingItem ? onClose : null}
            fullScreen={commonStore.isMobile}
            fullWidth
            maxWidth="sm"
            header="Создание рекламной площадки"
        >
            <ModalContent dividers>
                <ListError errors={errors} />
                <ExportFeedSettingsEditing export_base_id={CREATING_ITEM_ID} />

                {/*<Grid item xs={12} sm={5}>*/}
                {/*    <TextField*/}
                {/*        autoFocus*/}
                {/*        margin="none"*/}
                {/*        label="Название тарифа"*/}
                {/*        required*/}
                {/*        value={tariffName}*/}
                {/*        onChange={event => setTariffName(event.target.value)}*/}
                {/*        error={!tariffName}*/}
                {/*        fullWidth*/}
                {/*    />*/}
                {/*</Grid>*/}
            </ModalContent>

            <ModalActions>
                <Button disabled={loadingItem} onClick={onClose} color="secondary">
                    Отменить
                </Button>

                <Button disabled={loadingItem} variant="outlined" onClick={handleCreate} color="primary" loading={loadingItem}>
                    Создать
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default observer(AddExportBaseModal);
