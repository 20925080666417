import React, { Fragment } from 'react';

import ContactLinkWithPreview from '../../../../Lists/Contacts/ContactLinkWithPreview';
import UserLinkWithPreview from '../../../../Lists/Users/UserLinkWithPreview';
import { ContactLinkType } from '~/types/contacts.types';
import { UserLinkType } from '~/types/users.types';
import InfoPopup from '~/components/Base/InfoPopup';
import { ErrorMessage } from '~ui/Message';

const ContactIsAlreadyCreated = ({ contactOrUser, showInfo }: { contactOrUser: ContactLinkType | UserLinkType; showInfo?: boolean }) => {
    let contact;
    let user;
    if (contactOrUser['contact_id']) {
        contact = {
            contact_id: contactOrUser['contact_id'],
            contactType: contactOrUser['contactType'],
            companyName: contactOrUser['companyName'],
            firstName: contactOrUser['firstName'],
            lastName: contactOrUser['lastName'],
            middleName: contactOrUser['middleName']
        };
    } else if (contactOrUser['user_id']) {
        user = {
            user_id: contactOrUser['user_id'],
            firstName: contactOrUser['firstName'],
            lastName: contactOrUser['lastName'],
            middleName: contactOrUser['middleName'],
            avatarUrl: contactOrUser['avatarUrl'],
            enable: contactOrUser['enable']
        };
    }

    return (
        <ErrorMessage>
            {contact && (
                <Fragment>
                    Телефон найден у контакта: <ContactLinkWithPreview contact={contact} />
                </Fragment>
            )}
            {user && (
                <Fragment>
                    Телефон найден у сотрудника: <UserLinkWithPreview user={user} />
                </Fragment>
            )}
            {showInfo && (
                <InfoPopup size="small">
                    Один и тот же номер телефона встречается в нескольких местах.
                    <br />
                    Можно объеденить эти контакты, или убрать лишний телефон в одной из карточек.
                </InfoPopup>
            )}
        </ErrorMessage>
    );
};

export default React.memo(ContactIsAlreadyCreated);
