import React, { useState, useEffect, useMemo } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';

import TextInput from '~ui/TextInput';
import Button from '~ui/Button';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const checkValidPhone = (phone: string) => phone.replace(/\D/gi, '').length === 11 && phone[0] === '7';

const PhoneInput = ({
    value,
    onAddPhone,
    onChange,
    canAddPhone,
    label
}: {
    value: string;
    onAddPhone: () => void;
    onChange: (phone: string) => void;
    canAddPhone: boolean;
    label: string;
}) => {
    const [phone, setPhone] = useState(value);
    const [phoneIsValid, setPhoneIsValid] = useState(checkValidPhone(phone));

    useEffect(() => {
        setPhoneIsValid(checkValidPhone(phone));
    }, [phone]);

    useEffect(() => {
        onChange(phoneIsValid ? phone.replace(/\D/gi, '') : '');
    }, [phoneIsValid]);

    return (
        <TextInput
            mask="+7 (000) 000-00-00"
            startLabel={<PhoneIcon />}
            extraLabel={
                canAddPhone && phoneIsValid ? (
                    <Button onClick={onAddPhone} tooltip="Добавить еще один" primary icon={<AddIcCallIcon />} />
                ) : null
            }
            value={phone}
            label={label}
            onChange={(event, { value }) => setPhone(value)}
            pattern={phoneRegExp}
        />
    );
};

const MortgagePhonesList = ({
    item_id,
    phones,
    onChange,
    label
}: {
    item_id: number;
    phones: string[];
    onChange: (phones: string[]) => void;
    label: string;
}) => {
    const [phonesList, setPhonesList] = useState<string[]>([]);
    useMemo(() => {
        const initPhones = !phones || phones.length === 0 ? [''] : [...phones];
        setPhonesList(initPhones);
    }, [item_id]);

    const addPhone = () => {
        setPhonesList([...phonesList, '']);
    };

    const changePhone = (index: number) => (phone: string) => {
        phonesList[index] = phone;
        setPhonesList([...phonesList]);
        onChange(phonesList.filter(phone => phone !== ''));
    };

    return (
        <>
            {phonesList.map((phone, index) => (
                <PhoneInput
                    key={`phone-${index}-${item_id}`}
                    value={phone}
                    onAddPhone={addPhone}
                    onChange={changePhone(index)}
                    canAddPhone={phonesList.length === index + 1}
                    label={label}
                />
            ))}
        </>
    );
};

export default MortgagePhonesList;
