import fetchApi from '../common/fetchApi';

import { Notification } from '~/types/notifications.types';

export const GQItemObjects = `
    firstName
    lastName
    middleName
    user_id
    avatarUrl

    task_id
    createTime

    estate_id
    type
    address
    houseNumberAdjusted
    roomsCount
    propertyType

    contact_id
    contactType
    companyName
    
    deal_id
    
    request_id
    
    wish_id
    
    call_event_id
    callTimeMin
    callTimeMax
    
    meeting_event_id
    startTime
    endTime
    
    showing_id
    showingTime
    
    request_id
    
    export_base_id
    name

    enable
`;

const NotyGQLFields = `
    noty_id
    major_user_id
    title
    description

    item_type
    item_id
    item {
        ${GQItemObjects}
    }

    createTime
    updateTime
    enable
`;

export const INITAL_DOC_TITLE = 'CRM Agentbase';

export const blinkTitle = () => {
    document.title = document.title === INITAL_DOC_TITLE ? '+ новое уведомление' : INITAL_DOC_TITLE;
};

export const fetchNotifications = async (
    isArchive: boolean = false,
    start: number = 0,
    limit: number = 100
): Promise<Array<Notification>> => {
    const graphql = `{
        fetchNotifications(isArchive: ${isArchive}, start: ${start}, limit: ${limit}){
            ${NotyGQLFields}
        }
      }`;

    const data: { fetchNotifications: Notification[] } = await fetchApi.getGQ(graphql);
    return data.fetchNotifications;
};

export const readNotification = async (noty_id: number): Promise<number> => {
    const graphql = `mutation{
            readNotification(noty_id: ${noty_id})
          }`;

    const data: { readNotification: number } = await fetchApi.postGQ(graphql);
    return data.readNotification;
};

export const readAllNotifications = async (): Promise<number> => {
    const graphql = `mutation{
            readAllNotifications
          }`;

    const data: { readAllNotifications: number } = await fetchApi.postGQ(graphql);
    return data.readAllNotifications;
};
