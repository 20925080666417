import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import './tasks.less';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HistoryIcon from '@material-ui/icons/History';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState, TAB_ID_HISTORY } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT } from '../ItemWrapper';
import taskStore from '~/stores/taskStore';

import TaskTabMenu from './TaskTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';

import TaskAddMode from './TaskAddMode';
import TaskShowMode from './TaskShowMode';
import TaskHistoryMode from './TaskHistoryMode';

const TaskItem = ({ item_id, tabName, param }: ItemWrapperState) => {
    const { errors, item } = taskStore.getItem(item_id);
    const { enable, major_user_id } = item || {};

    const taskTabs: MenuItem[] = [
        { name: 'Описание', id: '', icon: <FormatListNumberedIcon /> },
        { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
    ];

    return (
        <Fragment>
            <TaskTabMenu itemTabs={taskTabs} item_id={item_id} tabName={tabName} enable={Boolean(enable)} major_user_id={major_user_id} />
            <div className="crm-List__sidebar_modalBox">
                {errors && <ItemErrors errors={errors} tabName={tabName} />}
                {tabName === TAB_ID_DEFAULT && <TaskShowMode task_id={item_id} />}
                {tabName === TAB_ID_CREATING && <TaskAddMode />}
                {tabName === TAB_ID_HISTORY && <TaskHistoryMode item_id={item_id} />}
            </div>
        </Fragment>
    );
};

export default ItemWrapper(taskStore, observer(TaskItem), () => true);
