import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import AddressPopup from '~/components/Base/Address/AddressPopup';
import AddressOnMapBtn from '~/components/Base/Address/AddressOnMapBtn';

const LifeComplexTitleShow = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const newbuilding = lifeComplexStore.getItem(newbuilding_id).item;
    if (!newbuilding) {
        return (
            <>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </>
        );
    }

    const { address, geo_lat, geo_lon } = newbuilding;

    return (
        <>
            <Typography variant="h4">ЖК «{newbuilding.name}»</Typography>
            <Box marginTop={1}>
                {address}
                &nbsp;
                <AddressPopup address={newbuilding} />
                <AddressOnMapBtn address={address} geo_lat={geo_lat} geo_lon={geo_lon} />
            </Box>
        </>
    );
};

export default observer(LifeComplexTitleShow);
