import { Task, TaskFilter } from '~/types/tasks.types';
import { objectToGraphql } from '~/common/graphql';
import fetchApi from '../common/fetchApi';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { GQItemObjects } from './notificationsApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { HistoryChange } from '~/types/historyChanges.types';

const GQTaskFields = `
    task_id
    status
    priority
    
    major_user_id
    major_user {
        ${GQUserMainFields}
    }
    
    title
    description
    needApproval
    deadLine
    
    observer_users_ids
    observer_users {
        ${GQUserMainFields}
    }
    assign_users_ids
    assign_users {
        ${GQUserMainFields}
    }
    
    attached {
        item_id
        item_type
        item {
            ${GQItemObjects}
        }
    }
    
    createTime
    updateTime
    finishTime
    enable
`;

export const fetchItem = async (id: number): Promise<Task> => {
    const graphql = `{
        fetchTask(task_id: ${id}) {
            ${GQTaskFields}
        } 
      }`;

    const data: { fetchTask: Task } = await fetchApi.getGQ(graphql);
    return data.fetchTask;
};

export const createItem = async (task: Task): Promise<number> => {
    const graphql = `mutation { 
          createTask(taskInput: ${objectToGraphql(task)})
        }`;

    const data: { createTask: number } = await fetchApi.postGQ(graphql);
    return data.createTask;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    taskFilter: TaskFilter,
    controller?: AbortController
): Promise<{ list: Array<Task>; count: number }> => {
    const graphql = `{
            fetchTasks(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(taskFilter)}) {
                list {${GQTaskFields}}
                count
            } 
          }`;

    const data: { fetchTasks: { list: Task[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchTasks;
};

export const saveItem = async (task_id: number, task: Task): Promise<number> => {
    const graphql = `mutation { 
        updateTask(task_id: ${task_id}, taskDiff: ${objectToGraphql(task)})
    }`;

    const data: { updateTask: number } = await fetchApi.postGQ(graphql);
    return data.updateTask;
};

export const fetchItemHistory = async (task_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchTaskChangeHistory(task_id: ${task_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchTaskChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchTaskChangeHistory;
};
