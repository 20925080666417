import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

import CellOperatorsSettingsModal from './CellOperatorsSettingsModal';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';

import ContainerHeader from '~ui/ContainerHeader';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import LoaderAwait from '~/components/Base/LoaderAwait';
import settingStore from '~/stores/settingStore';
import cellOperatorStore from '~/stores/cell/cellOperatorStore';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';

const CellOperatorsSettings = () => {
    const [operatorIndex, setOperatorIndex] = useState<number | null>(null);

    useEffect(() => {
        cellOperatorStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = cellOperatorStore;

    return (
        <Fragment>
            <ContainerHeader
                handleCreate={() => setOperatorIndex(CREATING_ITEM_ID)}
                title="Настройка операторов телефонии"
                enableCreating={authStore.canCreate(settingStore.moduleName)}
                icon={<SettingsPhoneIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ name, cell_operator_id }, index) => (
                        <ListItem button key={cell_operator_id} onClick={() => setOperatorIndex(cell_operator_id)}>
                            <ListItemText primary={name} />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {operatorIndex !== null && (
                <CellOperatorsSettingsModal cell_operator_id={operatorIndex} onClose={() => setOperatorIndex(null)} />
            )}
        </Fragment>
    );
};

export default observer(CellOperatorsSettings);
