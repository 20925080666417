import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'semantic-ui-react';

import { EstateFilter } from '~/types/estate.types';

import { Modal, ModalContent } from '~/components/Base/ui/Modal';
import estateStore from '~/stores/estateStore';
import EstateTableBody from '~/components/Lists/Estate/EstateTableBody';
import ListError from '~/components/ListError';
import ListFooter from '~/components/Lists/Common/ListFooter';
import EstateMapFilterChips from '~/components/Lists/Estate/MapView/EstateMapFilterChips';
import EstateSmaFilterModal from './EstateSmaFilterModal';
import FilterEstateRegion from '~/components/Lists/Estate/FilterBlocks/FilterEstateRegion';
import ButtonGroup from '~/components/Base/ButtonGroup';
import { adjustEstateListBtns } from '~/components/Lists/Estate';

const useStyles = makeStyles(() => ({
    topFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

const FindEstatesModal = ({ onClose, estate_id }: { estate_id: number; onClose: () => void }) => {
    const classes = useStyles();

    const handleChangeBase = (event, { value }) => {
        // @ts-ignore
        estateStore.changeFilterBase(value);
    };

    const handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: keyof EstateFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        estateStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    const { list, loadingList, listErrors, pagination, listCount, nextListLoaded, listFilter } = estateStore;
    const { base, propertyType, type } = listFilter;

    return (
        <Modal onClose={onClose} header="Поиск объектов для СМА" fullScreen>
            <ModalContent>
                <div className={classes.topFilter}>
                    <FilterEstateRegion />
                    <ButtonGroup
                        buttonSet={[
                            [0, 'Все'],
                            [1, 'Продажа'],
                            [2, 'Аренда']
                        ]}
                        name="type"
                        value={type}
                        handleChange={handleChange}
                    />
                    <ButtonGroup
                        buttonSet={adjustEstateListBtns(propertyType)}
                        name="base"
                        value={base === undefined ? null : base}
                        handleChange={handleChangeBase}
                    />
                </div>
                <div>
                    <EstateSmaFilterModal estate_id={estate_id} />
                    <EstateMapFilterChips type="list" />
                </div>

                <ListError errors={listErrors} />

                <Table structured size="small" sortable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Местоположение</Table.HeaderCell>
                            <Table.HeaderCell>Площадь</Table.HeaderCell>
                            <Table.HeaderCell>Этаж</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {list && <EstateTableBody smaEstateId={estate_id} estateList={list} simpleMode />}

                    <ListFooter
                        loading={loadingList}
                        colSpan={5}
                        pagination={pagination}
                        listCount={listCount}
                        pageChange={estateStore.pageChange}
                        pageSizeChange={estateStore.pageSizeChange}
                        nextListLoaded={nextListLoaded}
                        scrollToElementId="crm_ListTop"
                    />
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default observer(FindEstatesModal);
