import React, { SyntheticEvent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { ExportStatListPult } from '~/types/exportStatistics.types';
import { ACCESS_GROUP, ACCESS_READ } from '~/types/access.types';
import { AVITO_EXPORT_BASE_ID, CIAN_EXPORT_BASE_ID, DOMCLICK_EXPORT_BASE_ID, YANDEX_EXPORT_BASE_ID } from '~/types/exports.types';

import authStore from '~/stores/authStore';
import exportStatisticsStore from '~/stores/export/exportStatisticsStore';

import ToggleButton from '@material-ui/lab/ToggleButton';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import VisibilityIcon from '@material-ui/icons/Visibility';

import DateRangeSelect, { DATES_RANGE_VALUE } from '../Base/ui/DateRangeSelect';
import { StyledToggleButtonGroup, PultDivider, Pult } from '~ui/FilterPult';
import PropertyTypeBlock from '../Lists/Estate/FilterBlocks/PropertyTypeBlock';
import UsersAndGroupsDropdownNew from '../Lists/Common/UsersAndGroupsDropdownNew';
import GlobalError from '~/components/Base/GlobalError';

const ExportStatisticsFilter = () => {
    const [error, setError] = useState<string | null>(null);
    const disableGroups = authStore.getPermission(exportStatisticsStore.moduleName, ACCESS_READ) === ACCESS_GROUP;

    useMemo(() => {
        if (disableGroups) {
            exportStatisticsStore.listFilter['group_id'] = [authStore?.currentUser?.group_id];
        }
        exportStatisticsStore.fetchList().catch(error => setError(error.message));
    }, []);

    const { activeCharts, activeBases, deltaTimeSec } = exportStatisticsStore.listFilter;

    const handleActiveBases = (event, activeBases) => {
        exportStatisticsStore.changeFilter('activeBases', activeBases);
    };

    const handleActiveCharts = (event, activeCharts) => {
        exportStatisticsStore.changeFilter('activeCharts', activeCharts);
    };

    const handleTimesChange = ({ startTime, endTime, deltaTime }: DATES_RANGE_VALUE) => {
        exportStatisticsStore.changeFilter('deltaTimeSec', deltaTime);
        exportStatisticsStore.changeFilter('fromTimeSec', startTime);
        exportStatisticsStore.changeFilter('toTimeSec', endTime);
    };

    const handleChangeFilter = <T extends keyof ExportStatListPult>(
        event: SyntheticEvent,
        { name, value }: { name: T; value: ExportStatListPult[T] }
    ) => {
        exportStatisticsStore.changeFilter(name, value);
    };

    const { type, propertyTypes, major_user_id, group_id } = exportStatisticsStore.listFilter;

    if (error) {
        return <GlobalError error={error} />;
    }

    return (
        <div>
            <Pult>
                <PropertyTypeBlock type={type} propertyTypes={propertyTypes} handleChange={handleChangeFilter} />
                <PultDivider />
                <UsersAndGroupsDropdownNew
                    disableGroups={disableGroups}
                    group_id={group_id}
                    major_user_id={major_user_id}
                    handleChange={handleChangeFilter}
                />
            </Pult>
            <Pult>
                <StyledToggleButtonGroup size="small" value={activeBases} onChange={handleActiveBases}>
                    <ToggleButton value={CIAN_EXPORT_BASE_ID}>ЦИАН</ToggleButton>
                    <ToggleButton value={YANDEX_EXPORT_BASE_ID}>Яндекс</ToggleButton>
                    <ToggleButton value={AVITO_EXPORT_BASE_ID}>Авито</ToggleButton>
                    <ToggleButton value={DOMCLICK_EXPORT_BASE_ID}>ДомКлик</ToggleButton>
                    <ToggleButton value={0}>Сумма</ToggleButton>
                </StyledToggleButtonGroup>

                <PultDivider />

                <StyledToggleButtonGroup size="small" value={activeCharts} onChange={handleActiveCharts}>
                    <ToggleButton value="views">
                        <VisibilityIcon /> Просмотры
                    </ToggleButton>
                    <ToggleButton value="phoneShows">
                        <RingVolumeIcon /> Показы телефона
                    </ToggleButton>
                </StyledToggleButtonGroup>

                <PultDivider />

                <DateRangeSelect onChange={handleTimesChange} initialValue={deltaTimeSec} />
            </Pult>
        </div>
    );
};

export default observer(ExportStatisticsFilter);
