import React, { Component, Fragment } from 'react';
import { Grid, Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import DatePicker from '../../../Base/DatePicker';
import { getUnixTime } from 'date-fns';
import estateStore from '~/stores/estateStore';
import ButtonGroup from '../../../Base/ButtonGroup';

type AvailableFromEditingProps = HandlerEditingWrappedProps & {
    availableFrom?: number;
    isOccupied?: boolean | null;
};

@observer
class AvailableFromEditing extends Component<AvailableFromEditingProps> {
    constructor(props: AvailableFromEditingProps) {
        super(props);

        const item = {
            estate_id: props.item_id,
            availableFrom: props.availableFrom || null,
            isOccupied: props.isOccupied || null
        };
        estateStore.setEditingItem(item.estate_id, item);
    }

    render() {
        const { item_id, setUnixTime, handleChange } = this.props;
        const { availableFrom, isOccupied } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Помещение занято</label>
                        <ButtonGroup
                            buttonSet={[
                                [false, 'нет'],
                                [true, 'да']
                            ]}
                            name="isOccupied"
                            value={isOccupied}
                            handleChange={handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <span className="crm-Item__labelWide crm-Estate__availableLine_label">Дата освобождения</span>

                        <div className="crm-Tasks__deadLine_picker">
                            <DatePicker
                                disabled={isOccupied !== true}
                                time={availableFrom || null}
                                onChange={time => setUnixTime('availableFrom', time)}
                                clearable
                            />
                        </div>
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, AvailableFromEditing);
