import React from 'react';
import { Link } from 'react-router-dom';
import { UserLinkType } from '~/types/users.types';
import { Icon, Image } from 'semantic-ui-react';
import cs from 'classnames';
import UserAvatar from '~ui/UserAvatar';

export type UserLinksProps = {
    user: UserLinkType;
    icon?: boolean;
    target?: string;
    avatar?: boolean;
};

export const UserLinkState = (user_id: number) => ({
    pathname: `/users/list/profile/${user_id}`,
    state: { modal: true }
});

const UserLink = ({ user: { user_id, firstName, lastName, middleName, enable, avatarUrl }, icon, avatar, target }: UserLinksProps) => (
    <div className="crm-User__link">
        {icon && <Icon name="user" />}
        {avatar && <UserAvatar src={avatarUrl} name={`${lastName} ${firstName}`} size={24} />}
        <Link to={UserLinkState(user_id)} target={target} className={cs({ 'crm-Item__disabled': !enable, 'crm-User__link_a': avatar })}>
            {lastName} {firstName} {middleName}
        </Link>
    </div>
);

export default UserLink;
