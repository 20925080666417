import React, { Component, Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { Estate } from '~/types/estate.types';

import ContactIdBlockEditing from '../../Common/ContactIDBlockEditing';
import history from '../../../../history';
import PreventDblClick from '../../../Base/PreventDblClick';

type EstateAccessBlockEditingProps = {
    item_id: number;
    contact_id?: number | null;
};

@observer
class EstateAccessBlockEditing extends Component<EstateAccessBlockEditingProps> {
    constructor(props: EstateAccessBlockEditingProps) {
        super(props);

        const {
            location: {
                state: { from }
            }
        } = history;

        const item = {
            estate_id: props.item_id,
            contact_id: from && from.storeName === 'contactStore' ? from.item_id : props.contact_id || null
        };
        estateStore.setEditingItem(props.item_id, item);
    }

    render() {
        const { item_id } = this.props;
        const { contact_id } = estateStore.getItem(item_id).editingItem;

        return (
            <PreventDblClick>
                <div className="crm-Item__editingMode">
                    <Grid columns={1} stackable>
                        <Grid.Column>
                            <label className="crm-Item__labelWide">Собственник (Посредник)</label>
                            <ContactIdBlockEditing store={estateStore} item_id={item_id} editing={contact_id === null} value={contact_id} />
                        </Grid.Column>
                    </Grid>
                </div>
            </PreventDblClick>
        );
    }
}

export default EstateAccessBlockEditing;
