import { Access } from './access.types';
import { EstateAddress } from './estate.types';
import { KpiInBase } from './kpi.types';

export const USER_MALE_SEX_ID = 1;

export type UserLinkType = {
    user_id: number;
    firstName: string;
    lastName: string;
    middleName?: string;
    avatarUrl?: string | null;
    enable: boolean;
};

export type UserLinkWithPhones = UserLinkType & {
    phones: Phone[];
};

export type UserAndGroup = UserLinkType & {
    group_id: number;
    avatarUrl: string | null;
    email: string;
    phones: Phone[];
};

export type UserMainFields = UserLinkType & {
    birthday: string | null;
    group_id: number | null;
    access_id: number;
    dismissalTime?: number;
    recruitmentTime: number;
};

export type UserTypeInBase = UserMainFields & {
    major_user_id: number;
    email: string;
    password_hash: string;
    createTime: number;
    updateTime: number;
    onlineTime: number | null;
    appointment: string;
    sex: number;
    group_id: number;
};

export type UserTableItem = UserMainFields & {
    user_id: number;
    group: UserGroup | null;
    appointment: string;
    sex: number;
    createTime: number;
    updateTime: number;
    onlineTime: number | null;
    phones: Phone[];

    dailyOutgoingCalls?: number;
    weekOutgoingCalls?: number;
};

export type UserSeo = {
    promoDescription?: string;
    isSiteProfileShow?: boolean;
    siteStatus?: number;
    siteLink?: string;
};

export type User = UserTableItem &
    UserSeo & {
        access: Access;
        photoUrl: string;
        avatarUrl: string;
        photo: any;
        innerPhones: string[];
        email: string;
        hiddenDescription: string;
        balance: number;
        documentsCount: number;
        estatesCount: number;

        grant_users_ids: number[];
        grantUsersIds: UserLinkType[];
        grant_users_under_ids: number[];
        grantUsersUnderIds: UserLinkType[];

        tempFilename?: string;
        icsCalendarKey?: string;
        main_metro_id?: number[];
        userAddress?: EstateAddress;

        whatsappIsEnable: boolean | null;
        telegramUsername: string | null;
    };

export type UserGroupInBase = {
    readonly group_id: number;
    name: string;
    parent_id: number;
    chief_id: number | null;
    region_id: number | null;
    usersCount: number;

    enable: boolean;
};

export type UserGroup = UserGroupInBase & {
    chief: User | null;
    subgroups: UserGroup[];
};

export type Phone = {
    phone_id: number;
    phone: string;
};

export type UserFilter = {
    fromDateUserDismissal: number | null;
    toDateUserDismissal: number | null;
    fromDateUserInState: number | null;
    toDateUserInState: number | null;
    group_id: number[];
    major_user_id: number[];
    search: string;
    onlineOnly: boolean;
    access_id: number | null;
    enable: boolean;
};

export type UserStatisticsFilter = {
    kpis: KpiInBase[];
};

export type UserBalanceInfo = {
    lastRedeemTime: number;
    paymentsSum: number;
    transactionsSum: number;
    balance: number;
};
