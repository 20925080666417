import React, { Component, Fragment } from 'react';
import { Search } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import contactStore from '~/stores/contactStore';

import HandlerEditingBlockWrapper, { HandleChangeType, HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import { CONTACT_TYPE_PRIVATE_PERSON_ID, CONTACT_TYPE_COMPANY_ID } from '~/types/contacts.types';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import ButtonGroup from '~/components/Base/ButtonGroup';
import DadataInput from '~ui/DadataInput';
import { DaDataFio } from '~/types/dadata.types';

const resultRenderer = ({ title }) => title;

type PersonalBlockEditingProps = HandlerEditingWrappedProps & {
    companyName?: string;
    contactType?: number;
    simpleMode?: boolean;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    sex?: number;
    inn?: string | null;
};

@observer
class PersonalBlockEditing extends Component<PersonalBlockEditingProps, { selected: boolean }> {
    state = {
        selected: false
    };

    constructor(props: PersonalBlockEditingProps) {
        super(props);

        const item = {
            firstName: props.firstName || '',
            lastName: props.lastName || '',
            middleName: props.middleName || '',
            sex: typeof props.sex === 'boolean' ? props.sex : null,
            contactType: props.contactType || CONTACT_TYPE_PRIVATE_PERSON_ID,
            companyName: props.companyName || '',
            inn: props.inn || null
        };
        contactStore.setEditingItem(props.item_id, item);
    }

    searchNames = (evt: React.SyntheticEvent, data: { value: string }) => {
        contactStore.fetchNames(data.value);
        contactStore.setProperty(this.props.item_id, { searchNameQuery: data.value });
        this.setState({ selected: false });
    };

    handleNameChange = async (event: React.SyntheticEvent, dataProp: { result: { value: number } }) => {
        const { names } = contactStore;
        const { item_id } = this.props;
        const value = dataProp.result.value;
        contactStore.setProperty(item_id, { searchNameQuery: names[value].value });

        const { name, patronymic, surname, gender } = names[value].data;

        const item = {
            firstName: name || '',
            lastName: surname || '',
            middleName: patronymic || '',
            sex: gender === 'MALE' ? 1 : gender === 'FEMALE' ? 2 : null
        };

        contactStore.setEditingItem(item_id, item);
        this.setState({ selected: true });
    };

    handleContactTypeChange: HandleChangeType = (event, { value }) => {
        const { item_id } = this.props;

        if (value === CONTACT_TYPE_COMPANY_ID) {
            contactStore.setEditingItem(item_id, {
                firstName: null,
                lastName: null,
                middleName: null,
                sex: null,
                contactType: CONTACT_TYPE_COMPANY_ID
            });
            contactStore.setProperty(item_id, { searchNameQuery: '' });
        } else {
            contactStore.setEditingItem(item_id, { inn: null, companyName: null, contactType: CONTACT_TYPE_PRIVATE_PERSON_ID });
        }
    };

    changeGender = ({ gender }: DaDataFio) => {
        switch (gender) {
            case 'MALE':
                contactStore.setEditingItem(this.props.item_id, { sex: 1 });
                break;
            case 'FEMALE':
                contactStore.setEditingItem(this.props.item_id, { sex: 2 });
                break;
        }
    };

    render() {
        const { item_id, simpleMode, handleChange } = this.props;
        const { selected } = this.state;
        const {
            editingItem: { firstName, lastName, middleName, sex, contactType, companyName, inn },
            property: { searchNameQuery }
        } = contactStore.getItem(item_id);

        const { loadingNames, names } = contactStore;
        const namesList = names.map(({ value }, i) => ({ key: i, value: i, title: value }));

        return (
            <>
                <Grid columns={simpleMode ? 1 : 2}>
                    <Grid.Column>
                        <label className="crm-Item__labelWide" style={{ paddingRight: '1rem' }}>
                            Тип контакта
                        </label>
                        <ButtonGroup
                            buttonSet={[
                                [CONTACT_TYPE_PRIVATE_PERSON_ID, 'Физическое лицо'],
                                [CONTACT_TYPE_COMPANY_ID, 'Юридическое лицо']
                            ]}
                            name="contactType"
                            value={contactType}
                            handleChange={this.handleContactTypeChange}
                        />
                    </Grid.Column>
                </Grid>

                {contactType === CONTACT_TYPE_COMPANY_ID && (
                    <Grid columns={2} stackable>
                        <Grid.Column>
                            <TextInput
                                label="Название компании"
                                size="small"
                                value={companyName || ''}
                                name="companyName"
                                onChange={handleChange}
                                variant="classic"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <TextInput
                                label="ИНН"
                                size="small"
                                value={inn || ''}
                                name="inn"
                                onChange={handleChange}
                                maxLength={12}
                                variant="classic"
                            />
                        </Grid.Column>
                    </Grid>
                )}
                {!simpleMode && contactType === CONTACT_TYPE_PRIVATE_PERSON_ID && (
                    <Grid spacing={0}>
                        <Grid.Column>
                            <label className="crm-Item__labelWide">Фамилия Имя Отчество (свободная форма)</label>
                            <div className="ui form small">
                                <Search
                                    className={`crm-Estate__address_find ${
                                        searchNameQuery && !selected ? 'crm-Estate__address_notSelected' : ''
                                    }`}
                                    showNoResults={false}
                                    size="tiny"
                                    loading={loadingNames}
                                    onResultSelect={this.handleNameChange}
                                    onSearchChange={this.searchNames}
                                    results={namesList}
                                    value={searchNameQuery}
                                    resultRenderer={resultRenderer}
                                />
                            </div>
                        </Grid.Column>
                    </Grid>
                )}
                {contactType === CONTACT_TYPE_PRIVATE_PERSON_ID && (
                    <Grid columns={2} stackable>
                        <Grid.Column>
                            <DadataInput
                                type="SURNAME"
                                label="Фамилия"
                                value={lastName || ''}
                                name="lastName"
                                onDaDataSelect={this.changeGender}
                                onChange={handleChange}
                                variant="classic"
                                size="small"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <DadataInput
                                type="NAME"
                                label="Имя"
                                value={firstName || ''}
                                name="firstName"
                                onDaDataSelect={this.changeGender}
                                onChange={handleChange}
                                variant="classic"
                                size="small"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <DadataInput
                                type="PATRONYMIC"
                                label="Отчество"
                                value={middleName || ''}
                                name="middleName"
                                onDaDataSelect={this.changeGender}
                                onChange={handleChange}
                                variant="classic"
                                size="small"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ButtonGroup
                                buttonSet={[
                                    [1, 'Муж'],
                                    [2, 'Жен']
                                ]}
                                name="sex"
                                value={sex}
                                handleChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid>
                )}
            </>
        );
    }
}

export default HandlerEditingBlockWrapper(contactStore, PersonalBlockEditing);
