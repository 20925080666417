import React, { useState, Fragment, ReactNode, ChangeEvent, MouseEvent } from 'react';
import cs from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { PaginationType } from '~/stores/prototypes/ListStore.prototype';
import commonStore from '~/stores/commonStore';
import Select from '~ui/Select';
import MUIPagination from '@material-ui/lab/Pagination';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import Progress from '~ui/Progress';

const pageSizeOptions = [5, 10, 15, 20, 30, 50, 100].map(value => ({ text: `${value} записей`, value }));

export type PaginationProps = {
    pagination: PaginationType;
    listCount: number;
    loadingCount?: boolean;
    pageChange: (pageNumber: number) => void;
    pageSizeChange: (index: number) => void;
    children?: ReactNode;
    showPult?: boolean;
    scrollToElementId?: string;
    reverseOnMobile?: boolean;
    hideGoToPage?: boolean;
};

const DEFAULT_PAGE_NUMBER = 1;
const MAX_PAGE_NUMBERS_WITHOUT_PAGER = 9;

const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    reverse: {
        [breakpoints.down('sm')]: {
            flexFlow: 'column',
            marginBottom: '1rem'
        }
    },
    flexAlignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    pultText: {
        margin: '0 0.5em'
    },
    countBox: {
        position: 'relative'
    }
}));

const Pagination = (props: PaginationProps) => {
    const classes = useStyles();
    const { isMobile } = commonStore;

    const [pageNumber, changePageNumber] = useState(0);
    const {
        pagination,
        listCount,
        loadingCount,
        pageChange,
        pageSizeChange,
        reverseOnMobile = true,
        showPult = false,
        hideGoToPage = false,
        children,
        scrollToElementId
    } = props;

    const scrollToTop = () => {
        if (scrollToElementId) {
            document.getElementById(scrollToElementId).scrollIntoView(true);
        }
    };

    const handleChangePageNumber = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        changePageNumber(Number(value) || DEFAULT_PAGE_NUMBER);
        scrollToTop();
    };

    const handleClickGo = () => {
        pageChange(pageNumber || DEFAULT_PAGE_NUMBER);
        scrollToTop();
    };

    const handlePageChange = (event: MouseEvent<HTMLButtonElement>, activePage: number) => {
        pageChange(Number(activePage));
        scrollToTop();
    };

    const handleChangePageSize = (event: React.SyntheticEvent, { value }: { value: number }) => {
        pageSizeChange(value);
        scrollToTop();
    };

    return (
        <div className={cs({ [classes.root]: true, [classes.reverse]: reverseOnMobile })}>
            <div className={cs({ [classes.root]: true, [classes.reverse]: reverseOnMobile })}>
                <MUIPagination
                    onChange={handlePageChange}
                    count={pagination.totalPages}
                    page={pagination.activePage}
                    shape="rounded"
                    siblingCount={1}
                    hideNextButton={isMobile}
                    hidePrevButton={isMobile}
                />

                {!isMobile && (
                    <div className={classes.flexAlignCenter}>
                        <span className={classes.pultText}>На странице</span>
                        <Select options={pageSizeOptions} value={pagination.pageSize} onChange={handleChangePageSize} />
                    </div>
                )}

                {!hideGoToPage && !showPult && !isMobile && pagination.totalPages > MAX_PAGE_NUMBERS_WITHOUT_PAGER && (
                    <Fragment>
                        &nbsp;&nbsp;
                        <InputBase
                            value={pageNumber || ''}
                            type="number"
                            className="crm-List__pagination_pager"
                            placeholder="Перейти на указанный номер страницы"
                            onChange={handleChangePageNumber}
                        />
                        <IconButton size="small" type="submit" title="Перейти на указанный номер страницы" onClick={handleClickGo}>
                            <DirectionsIcon />
                        </IconButton>
                    </Fragment>
                )}
                {showPult && children}
            </div>

            <div className={classes.countBox}>
                Найдено:
                <Progress show={loadingCount} linear color="primary" />
                {!loadingCount && listCount.toLocaleString()}
            </div>
        </div>
    );
};

export default Pagination;
