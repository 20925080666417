import React, { useState } from 'react';
import { observer } from 'mobx-react';

import PassportMortgageEditing from './PassportMortgageEditing';
import MortgageJobsList from './MortgageJobsList';
import MortgagePersonContacts from './MortgagePersonContacts';
import MortgagePersonDocuments from './MortgagePersonDocuments';
import Steps, { StepType } from '~ui/Steps';
import commonStore from '~/stores/commonStore';

import ContactMailIcon from '@material-ui/icons/ContactMail';
import WorkIcon from '@material-ui/icons/Work';
import ContactsIcon from '@material-ui/icons/Contacts';
import FolderIcon from '@material-ui/icons/Folder';
import DocumentsTable from '~/components/Documents/DocumentsTable';

const mortgagePersonSteps: StepType[] = [
    { id: 0, title: 'Персональные данные', subtitle: 'ФИО, Паспорт, Проживание', icon: <ContactMailIcon /> },
    { id: 1, title: 'Контакты, инфо', subtitle: 'Телефоны, Стаж', icon: <ContactsIcon /> },
    { id: 2, title: 'Трудоустройство', subtitle: 'Место работы', icon: <WorkIcon /> },
    { id: 3, title: 'Документы', subtitle: 'Сканы паспорта, справок', icon: <FolderIcon /> }
];

const MortgagePersonEditing = ({ mortgage_person_id }: { mortgage_person_id: number }) => {
    const [step, setStep] = useState(0);

    return (
        <>
            <Steps
                steps={mortgagePersonSteps}
                activeStep={step}
                completedSteps={[false, false, false]}
                setActiveStep={setStep}
                collapsed={commonStore.isMobile}
                secondary
            />
            {step === 0 && <PassportMortgageEditing item_id={mortgage_person_id} />}
            {step === 1 && <MortgagePersonContacts item_id={mortgage_person_id} />}
            {step === 2 && <MortgageJobsList mortgage_person_id={mortgage_person_id} />}
            {step === 3 && <MortgagePersonDocuments item_id={mortgage_person_id} />}
        </>
    );
};

export default observer(MortgagePersonEditing);
