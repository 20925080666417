import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';
import { Modal, ModalContent } from '~ui/Modal';

import commonStore from '~/stores/commonStore';
import Time from '../Base/Time';
import LoaderAwait from '~/components/Base/LoaderAwait';

type CRMChangesModalProps = {
    onClose: () => void;
};

const CRMChangesModal = ({ onClose }: CRMChangesModalProps) => {
    useEffect(() => {
        commonStore.fetchCRMChanges();
    }, []);

    const { loadingCRMChanges, crmChangesList } = commonStore;

    return (
        <Modal onClose={onClose} header="История нововведений в CRM">
            <ModalContent>
                <Table size="small" sortable celled compact className="crm-Home__statisticsModal_table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '130px' }}>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Изменение</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {crmChangesList.map(({ releaseTime, title }) => (
                            <Table.Row key={releaseTime}>
                                <Table.Cell style={{ width: '130px' }}>
                                    <Time time={releaseTime} />
                                </Table.Cell>
                                <Table.Cell>{title}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <LoaderAwait active={loadingCRMChanges} dimmer />
            </ModalContent>
        </Modal>
    );
};

export default observer(CRMChangesModal);
