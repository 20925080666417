import React, { PureComponent } from 'react';
import { ReactNode } from 'react';
import { Button, Icon, Checkbox, Popup, List } from 'semantic-ui-react';
import { messages } from './index';
import commonStore from '~/stores/commonStore';
import { CALENDAR_ITEM_TYPE, CALENDAR_VIEW_TYPES } from '~/types/calendar.types';
import { calendarItemTypes } from '~/types/calendar.types';
import UserDropdown from '../ItemsDropdowns/UserDropdown';
import CalendarIOSSettings from './CalendarIOSSettings';

export const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

type CalendarToolbarProps = {
    view: string;
    views: CALENDAR_VIEW_TYPES[];
    label: ReactNode;
    onNavigate: (str: string) => void;
    onView: (type: CALENDAR_VIEW_TYPES) => void;
};

const CalendarToolbarState = {
    user_id: -1,
    groups_id: [] as number[]
};

const CalendarToolbarWrapper = ({
    onRefresh,
    onCreateCallEvent,
    itemTypes,
    itemTypesAvailable = [],
    onItemTypeClick,
    onChangeUser,
    user_id,
    groups_id
}: {
    onRefresh: () => void;
    onCreateCallEvent: () => void;
    itemTypes?: CALENDAR_ITEM_TYPE[];
    itemTypesAvailable?: CALENDAR_ITEM_TYPE[];
    onItemTypeClick?: (type: CALENDAR_ITEM_TYPE) => void;
    onChangeUser?: null | ((user_id: number | Array<number>) => void);
    user_id?: number;
    groups_id?: number[];
}) => {
    CalendarToolbarState.user_id = Number(user_id);
    CalendarToolbarState.groups_id = groups_id || [];

    class CalendarToolbar extends PureComponent<CalendarToolbarProps> {
        navigate = (action: string) => {
            this.props.onNavigate(action);
        };

        view = (view: CALENDAR_VIEW_TYPES) => {
            this.props.onView(view);
        };

        viewNamesGroup() {
            const viewNames = this.props.views;
            const view = this.props.view;

            if (viewNames.length > 1) {
                return (
                    <Button.Group size="mini">
                        {viewNames.map(name => (
                            <Button key={name} primary={view === name} onClick={this.view.bind(null, name)}>
                                {messages[name]}
                            </Button>
                        ))}
                    </Button.Group>
                );
            }
        }

        handleChangeFilter = (event: React.SyntheticEvent, { name }: { name: CALENDAR_ITEM_TYPE }) => {
            event.preventDefault();
            event.stopPropagation();

            if (typeof onItemTypeClick === 'function') {
                onItemTypeClick(name);
            }
        };

        render() {
            const { label } = this.props;

            return (
                <div className="rbc-toolbar">
                    <Button.Group size="mini" className="rbc-btn-group" basic>
                        {!commonStore.isMobile && <Button onClick={this.navigate.bind(null, navigate.TODAY)}>{messages.today}</Button>}
                        <Button onClick={this.navigate.bind(null, navigate.PREVIOUS)}>{messages.previous}</Button>
                        <Button onClick={this.navigate.bind(null, navigate.NEXT)}>{messages.next}</Button>
                    </Button.Group>

                    <span className="rbc-toolbar-label">{label}</span>

                    {!commonStore.isMobile && <span className="rbc-btn-group">{this.viewNamesGroup()}</span>}

                    <span className="rbc-btn-group">
                        <Button.Group size="mini">
                            <Button animated color="green" title="Добавить событие" onClick={onCreateCallEvent}>
                                <Button.Content hidden>
                                    <Icon name="calendar" />
                                </Button.Content>
                                <Button.Content visible>
                                    <Icon name="plus" />
                                </Button.Content>
                            </Button>
                            <Popup
                                wide
                                hoverable
                                title="Фильтр событий в календаре"
                                trigger={
                                    <Button
                                        color={itemTypes && itemTypes.length !== itemTypesAvailable.length ? 'red' : null}
                                        icon="filter"
                                    />
                                }
                            >
                                <List relaxed>
                                    <List.Item>
                                        <List.Header icon="calendar" content="События в календаре" />
                                    </List.Item>
                                    {itemTypesAvailable.map(key => (
                                        <List.Item key={key} name={key}>
                                            <Checkbox
                                                label={calendarItemTypes[key]}
                                                checked={itemTypes && itemTypes.includes(key)}
                                                onClick={this.handleChangeFilter}
                                                name={key}
                                            />
                                        </List.Item>
                                    ))}
                                </List>
                            </Popup>
                            {!commonStore.isMobile && <Button icon="refresh" title="Обновить" onClick={onRefresh} />}
                            {window.navigator.userAgent.includes('iPhone') && <CalendarIOSSettings />}
                        </Button.Group>

                        {typeof onChangeUser === 'function' && (
                            <span className="rbc-btn-group crm-Calendar__userDropdown">
                                <div className="ui mini form crm-Calendar__userDropdown_box">
                                    <UserDropdown
                                        user_id={CalendarToolbarState.user_id}
                                        groups_id={CalendarToolbarState.groups_id}
                                        onChange={onChangeUser}
                                    />
                                </div>
                            </span>
                        )}
                    </span>
                </div>
            );
        }
    }

    return CalendarToolbar;
};

export default CalendarToolbarWrapper;
