import React, { ReactNode, Component, Fragment } from 'react';
import { Search, Dropdown, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { NewBuildingHouse } from '~/types/newbuildings.types';
import { NEWBUILDING_BASE_AVITO, NEWBUILDING_BASE_YANDEX } from '~/types/newbuildings.types';
import debounce from '../../../../common/debounce';

type FindNewBuildingBlockEditingProps = {
    estate_id: number;
    editing: boolean;
    newbuilding_id: number | null;
    newbuilding_house_id: number | null;
    propertyType: number;
    address: string;
};

type FindNewBuildingBlockEditingState = {
    searchNewbuildingsValue: number | null;
    houses: NewBuildingHouse[];
    houseId: number | null;
    open: boolean;
};

const resultRenderer = ({ address, title }: { address: string; title: string }) => (
    <div>
        <b>{title}</b>
        <br />
        {address}
    </div>
);

@observer
class FindNewBuildingBlockEditing extends Component<FindNewBuildingBlockEditingProps, FindNewBuildingBlockEditingState> {
    debounceNewbuildings: () => void;

    constructor(props: FindNewBuildingBlockEditingProps) {
        super(props);

        this.state = {
            searchNewbuildingsValue: null,
            houses: [],
            houseId: null,
            open: false
        };

        const { estate_id, newbuilding_id } = props;

        if (newbuilding_id) {
            (async () => {
                try {
                    const houses = await estateStore.initNewbuildingFromProps(estate_id);
                    this.setState({ searchNewbuildingsValue: newbuilding_id, houses, houseId: props.newbuilding_house_id || null });

                    await estateStore.matchBaseHouse(estate_id, NEWBUILDING_BASE_YANDEX);
                    await estateStore.matchBaseHouse(estate_id, NEWBUILDING_BASE_AVITO);
                } catch (error) {}
            })();
        }

        const estate = {
            estate_id,
            newbuilding_id: newbuilding_id || null,
            newbuilding_house_id: props.newbuilding_house_id || null,
            propertyType: props.propertyType || 0,
            address: props.address || ''
        };
        estateStore.setEditingItem(estate_id, estate);

        this.debounceNewbuildings = debounce(() => {
            const { estate_id } = this.props;
            estateStore.fetchNewbuildingsSuggestions(estate_id, 15);
        }, 350);
    }

    handleChange = async (event: React.SyntheticEvent, { result: { id } }: { result: { id: number } }) => {
        const { estate_id } = this.props;
        const { newbuildings } = estateStore.getItem(estate_id).property;

        const {
            newbuilding_id,
            name,
            houses,

            street,
            streetType,
            houseNumber,
            houseNumberType,
            blockNumber,
            blockNumberType,

            town,
            townType,
            region,
            regionType,
            district,
            cityDistrict,
            districtType,
            cityDistrictType,
            settlement,
            settlementType,
            postcode,

            address,
            fias,
            kladr,
            geo_lat,
            geo_lon
        } = newbuildings[id];
        this.setState({ searchNewbuildingsValue: id, houses, houseId: null });

        const estate = {
            estate_id,
            newbuilding_id,
            newbuilding_house_id: null,

            street,
            streetType,
            houseNumber,
            houseNumberType,
            blockNumber,
            blockNumberType,

            town,
            townType,
            region,
            regionType,
            district,
            cityDistrict,
            districtType,
            cityDistrictType,
            settlement,
            settlementType,
            postcode,

            address,
            fias,
            kladr,
            geo_lat,
            geo_lon
        };
        estateStore.setEditingItem(estate_id, estate);
        estateStore.setProperty(estate_id, { searchAddressQuery: address, newbuildingsQuery: name, newbuildings: [] });

        estateStore.fetchNearestMetro(estate_id, Number(geo_lon), Number(geo_lat), region);
    };

    searchNewbuilding = (evt: React.SyntheticEvent, { value }: { value: string }) => {
        estateStore.setProperty(this.props.estate_id, { newbuildingsQuery: value });
        this.debounceNewbuildings();

        this.setState({
            houses: []
        });
    };

    handleHouseChange = (evt: React.SyntheticEvent, { value }: { value: number | string }) => {
        const { estate_id } = this.props;

        if (typeof value === 'string') {
            this.setState({ houseId: null });
            estateStore.setEditingItem(estate_id, { newbuilding_house_id: null });
            estateStore.mergeItem(estate_id, {
                newbuildingHouse: undefined
            });
            return;
        }

        this.setState({ houseId: value });

        const house: NewBuildingHouse = this.state.houses.find(({ newbuilding_house_id }) => newbuilding_house_id === value);
        if (!house) {
            return;
        }

        const {
            newbuilding_house_id,
            street,
            streetType,
            houseNumber,
            houseNumberType,
            blockNumber,
            blockNumberType,

            town,
            townType,
            region,
            regionType,
            district,
            cityDistrict,
            districtType,
            cityDistrictType,
            settlement,
            settlementType,
            postcode,

            address,
            fias,
            kladr,
            geo_lat,
            geo_lon
        } = house;

        const estate = {
            estate_id,
            newbuilding_house_id,
            street,
            streetType,
            houseNumber,
            houseNumberType,
            blockNumber,
            blockNumberType,

            town,
            townType,
            region,
            regionType,
            district,
            cityDistrict,
            districtType,
            cityDistrictType,
            settlement,
            settlementType,
            postcode,

            address,
            fias,
            kladr,
            geo_lat,
            geo_lon
        };

        estateStore.setEditingItem(estate_id, estate);
        estateStore.setProperty(estate_id, { searchAddressQuery: address });

        estateStore.matchBaseHouse(estate_id, NEWBUILDING_BASE_YANDEX);
        estateStore.matchBaseHouse(estate_id, NEWBUILDING_BASE_AVITO);

        estateStore.fetchNearestMetro(estate_id, Number(geo_lon), Number(geo_lat), region);
    };

    handleClearNewbuilding = (event: React.SyntheticEvent) => {
        event.preventDefault();

        const { estate_id } = this.props;

        this.setState({ houseId: null, houses: [] });
        estateStore.setEditingItem(estate_id, {
            newbuilding_house_id: null,
            newbuilding_id: null
        });
        estateStore.setProperty(estate_id, { newbuildingsQuery: '' });
        estateStore.mergeItem(estate_id, {
            newbuilding: undefined,
            newbuildingHouse: undefined
        });
    };

    handleFocus = () => {
        this.setState({ open: true });
    };

    handleBlur = () => {
        setTimeout(() => this.setState({ open: false }), 0);
    };

    render() {
        const { houses, houseId, open } = this.state;
        const { editing, estate_id } = this.props;

        const { newbuildingsQuery, loadingNewbuildings, newbuildings = [] } = estateStore.getItem(estate_id).property;

        const housesList = houses.map(({ name, newbuilding_house_id }) => ({
            key: newbuilding_house_id,
            value: newbuilding_house_id,
            text: name
        }));

        const newbuildingsList = newbuildings.map(({ address, name }, i) => ({ key: i, id: i, address, title: name }));

        return (
            <Fragment>
                {editing && <br />}
                <div className="crm-Estate__address_newbuilding">
                    <div>
                        <label className="crm-Estate__field_label">Название ЖК</label>
                        <Search
                            icon={
                                newbuildingsQuery && (
                                    <Icon
                                        onClick={this.handleClearNewbuilding}
                                        name="remove"
                                        className="crm-Estate__address_newbuildingClear"
                                        title="Нажмите, чтобы убрать ЖК"
                                    />
                                )
                            }
                            className="crm-Estate__address_find"
                            showNoResults={false}
                            size="tiny"
                            loading={loadingNewbuildings}
                            onResultSelect={this.handleChange}
                            onSearchChange={this.searchNewbuilding}
                            results={newbuildingsList}
                            value={newbuildingsQuery}
                            resultRenderer={resultRenderer}
                            open={open}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            // onSelectionChange={this.handleSelectionChange}
                        />
                    </div>
                    <div className="crm-Estate__address_house">
                        <label>Корпус ЖК</label>
                        <Dropdown
                            size="mini"
                            fluid
                            selection
                            options={housesList}
                            value={houseId}
                            placeholder="корпус"
                            onChange={this.handleHouseChange}
                            disabled={!housesList.length}
                            loading={loadingNewbuildings}
                            clearable
                            type="clearable"
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default FindNewBuildingBlockEditing;
