import React from 'react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';
import LoaderAwait from './Base/LoaderAwait';

import { ErrorMessage, SuccessMessage } from '~ui/Message';
import TextInput from '~ui/TextInput';
import Checkbox from '~ui/Checkbox';
import Grid from '~ui/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type LoginFormState = {
    showForgotPassword: boolean;
};

@observer
class LoginForm extends React.Component<{}, LoginFormState> {
    state = {
        showForgotPassword: false
    };

    handleEmailChange = event => authStore.setEmail(event.target.value);
    handlePasswordChange = (event, { value }: { value: string }) => authStore.setPassword(value);

    handleSubmitForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        authStore.login();
    };

    handleResetPassword = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        authStore.resetPassword();
    };

    handleToggleFrgtPswd = () => {
        this.setState({ showForgotPassword: !this.state.showForgotPassword });
    };

    render() {
        const { inProgress, errors, answer, values, loggingOutInProgress } = authStore;
        const { showForgotPassword } = this.state;

        const Errors = Array.from(errors).length > 0 && (
            <ErrorMessage>
                {Array.from(errors).map(error => (
                    <p key={error}>{error}</p>
                ))}
            </ErrorMessage>
        );

        return (
            <div className="crm-Login" style={{ backgroundImage: `url('/static/bg_login.jpg')` }}>
                <div className="crm-Login__box">
                    {!showForgotPassword && (
                        <form onSubmit={this.handleSubmitForm}>
                            <Grid justify="center" spacing={4}>
                                <LoaderAwait
                                    active={inProgress || loggingOutInProgress}
                                    immediately={loggingOutInProgress}
                                    dimmer
                                    size="large"
                                />

                                <Grid.Column xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <img src="/static/brand/brand-logo.png" height="48" alt="agentbase.ru" />
                                    {!commonStore.isMobile && (
                                        <Typography variant="h4" align="center">
                                            Добро Пожаловать
                                        </Typography>
                                    )}
                                </Grid.Column>
                                <Grid.Column xs={12}>
                                    <TextField
                                        fullWidth
                                        label="E-mail address"
                                        type="email"
                                        required
                                        onChange={this.handleEmailChange}
                                        autoComplete="username"
                                        value={values.email}
                                        size="medium"
                                        variant="outlined"
                                    />
                                </Grid.Column>
                                <Grid.Column xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        required
                                        onChange={this.handlePasswordChange}
                                        value={values.password}
                                        size="medium"
                                        variant="outlined"
                                        minLength={4}
                                        autoComplete="current-password"
                                    />
                                </Grid.Column>
                                {Errors && <Grid.Column xs={12}>{Errors}</Grid.Column>}
                                {/*{!commonStore.isMobile && (*/}
                                {/*    <Grid.Column xs={6}>*/}
                                {/*        /!*<Checkbox label="чужой компьютер" />*!/*/}
                                {/*    </Grid.Column>*/}
                                {/*)}*/}
                                <Grid.Column
                                    style={{ display: 'flex', justifyContent: commonStore.isMobile ? 'flex-start' : 'flex-end' }}
                                    xs={commonStore.isMobile ? 12 : 6}
                                >
                                    <Button variant="text" color="default" startIcon={<LockOpenIcon />} onClick={this.handleToggleFrgtPswd}>
                                        Забыли пароль?
                                    </Button>
                                </Grid.Column>
                                <Grid.Column xs={12}>
                                    <Button type="submit" fullWidth color="primary" variant="contained" size="large">
                                        Войти
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </form>
                    )}
                    {showForgotPassword && (
                        <form onSubmit={this.handleResetPassword}>
                            <Grid justify="center" spacing={4}>
                                <LoaderAwait active={inProgress} dimmer size="large" />

                                <Grid.Column xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <img src="/static/brand/brand-logo.png" height="48" alt="agentbase.ru" />
                                    <Typography variant="h5" align="center">
                                        Сбросить пароль
                                    </Typography>
                                </Grid.Column>
                                {(Errors || answer) && (
                                    <Grid.Column xs={12}>
                                        {Errors}
                                        {answer && <SuccessMessage>{answer}</SuccessMessage>}
                                    </Grid.Column>
                                )}
                                <Grid.Column xs={12}>
                                    <Button
                                        variant="text"
                                        color="default"
                                        startIcon={<ArrowBackIcon />}
                                        onClick={this.handleToggleFrgtPswd}
                                    >
                                        назад
                                    </Button>
                                </Grid.Column>

                                <Grid.Column xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="E-mail address"
                                        type="email"
                                        required
                                        onChange={this.handleEmailChange}
                                        autoComplete="username"
                                        variant="outlined"
                                        value={values.email}
                                    />
                                </Grid.Column>
                                <Grid.Column xs={12}>
                                    <Button color="secondary" type="submit" fullWidth variant="contained" size="large">
                                        Сбросить
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

export default LoginForm;
