import React, { createRef, Fragment } from 'react';
import { Popup } from 'semantic-ui-react';
import UserLink from './UserLink';
import { UserLinkType } from '~/types/users.types';
import UserLinkWithPreview from './UserLinkWithPreview';

const UsersLinkList = ({ usersList, avatar = false }: { usersList?: Array<UserLinkType>; avatar?: boolean }) => {
    // Очищаем дубли
    let usedUserId: number[] = [];
    const usersListAdjusted = (usersList || []).filter(({ user_id }) => {
        if (usedUserId.includes(user_id)) {
            return false;
        }
        usedUserId.push(user_id);
        return true;
    });

    return (
        <Fragment>
            {usersListAdjusted && usersListAdjusted.length === 1 ? (
                <UserLinkWithPreview icon={!avatar} avatar={avatar} key={usersListAdjusted[0].user_id} user={usersListAdjusted[0]} />
            ) : null}
            {usersListAdjusted && usersListAdjusted.length > 1 && (
                <Popup
                    size="small"
                    hoverable
                    trigger={
                        <span className="crm-User__linkList_trigger">
                            <UserLink icon={true} key={usersListAdjusted[0].user_id} user={usersListAdjusted[0]} />
                            <b>{`+${usersListAdjusted.length - 1}`}</b>
                        </span>
                    }
                >
                    <div>
                        {usersListAdjusted.map(user => (
                            <UserLink icon={!avatar} key={user.user_id} user={user} avatar={avatar} />
                        ))}
                    </div>
                </Popup>
            )}
        </Fragment>
    );
};

export default React.memo(UsersLinkList);
