import React, { PureComponent, SyntheticEvent } from 'react';
import PreventDblClick from '../../Base/PreventDblClick';
import Confirm from '~ui/Confirm';
import Button from '~ui/Button';

type CreationButtonsSetProps = {
    loading: boolean;
    onCreate: (event: SyntheticEvent) => void;
    onClearForm: (event: SyntheticEvent) => void;
};

type CreationButtonsSetState = {
    showClearConfirm: boolean;
};

class CreationButtonsSet extends PureComponent<CreationButtonsSetProps, CreationButtonsSetState> {
    state = {
        showClearConfirm: false
    };

    toggleClearConfirm = () => {
        this.setState({ showClearConfirm: !this.state.showClearConfirm });
    };

    render() {
        const { loading, onCreate, onClearForm } = this.props;
        const { showClearConfirm } = this.state;

        return (
            <PreventDblClick>
                <div className="crm-Item__addMode_buttons">
                    <Button variant="text" onClick={this.toggleClearConfirm}>
                        Очистить
                    </Button>
                    <Button variant="contained" color="primary" loading={loading} onClick={onCreate}>
                        Создать
                    </Button>
                </div>

                {showClearConfirm && (
                    <Confirm
                        header="Очистить карточку"
                        content="Вы действительно хотите очистить карточку?"
                        confirmButton="Очистить"
                        onCancel={this.toggleClearConfirm}
                        onConfirm={onClearForm}
                    />
                )}
            </PreventDblClick>
        );
    }
}

export default CreationButtonsSet;
