import React, { useState, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import TocIcon from '@material-ui/icons/Toc';
import MapIcon from '@material-ui/icons/Map';
import Fab from '@material-ui/core/Fab';

import EstateMapFilter from './EstateMapFilter';
import EstateMapFilterChips from './EstateMapFilterChips';
import estateStore from '~/stores/estateStore';
import PreventSwipe from '~/components/Base/PreventSwipe';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fav: {
            margin: theme.spacing(1),
            textTransform: 'capitalize'
        },
        bottom: {
            bottom: '1em',
            zIndex: 1000,
            position: 'absolute'
        },
        icon: {
            marginRight: theme.spacing(1)
        }
    })
);

export default function EstateMapMobileInner() {
    const classes = useStyles({});
    const [showFilter, toggleShowFilter] = useState(false);

    return (
        <Fragment>
            <div className="crm-Estate__mapSearch " id="mapSearch">
                <PreventSwipe>
                    <div className="crm-Map__chipsMobile">
                        <div className="crm-Map__chipsMobile_scrollBox">
                            <Fab
                                variant="extended"
                                className={classes.fav}
                                size="small"
                                color="primary"
                                onClick={() => toggleShowFilter(!showFilter)}
                            >
                                <MapIcon className={classes.icon} />
                                Фильтр
                            </Fab>
                            <EstateMapFilterChips type="map" />
                        </div>
                    </div>
                </PreventSwipe>

                <Fab
                    variant="extended"
                    size="small"
                    className={`${classes.fav} ${classes.bottom}`}
                    onClick={() => estateStore.toggleSearchOnMap()}
                >
                    <TocIcon className={classes.icon} />
                    Таблица
                </Fab>
            </div>

            <Modal size="fullscreen" open={showFilter} onClose={() => toggleShowFilter(!showFilter)} closeIcon dimmer="inverted">
                <Modal.Header>Уточните детали для поиска</Modal.Header>
                <Modal.Content>
                    <EstateMapFilter onCloseFilter={() => toggleShowFilter(!showFilter)} />
                </Modal.Content>
            </Modal>
        </Fragment>
    );
}
