import React from 'react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

import ListError from '~/components/ListError';
import Button from '~ui/Button';
import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';

@observer
export default class PasswordChangeTab extends React.Component<{}> {
    handlePasswordChange = (event: React.SyntheticEvent, { name, value }: { name: string; value: string }) => {
        event.preventDefault();

        authStore.setEditingPassword(name, value);
    };

    handleChangePassword = () => {
        authStore.changePassword();
    };

    render() {
        const {
            editingPassword: { new1, new2, old },
            passwordErrors,
            loadingPassportChange
        } = authStore;

        return (
            <>
                <ListError errors={Array.from(passwordErrors)} />

                <Grid alignItems="flex-end" justify="center" stackable>
                    <Grid.Column>
                        <TextInput
                            type="password"
                            label="Текущий пароль"
                            size="small"
                            value={old}
                            autoComplete="new-password"
                            name="old"
                            onChange={this.handlePasswordChange}
                            variant="classic"
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <TextInput
                            type="password"
                            label="Новый пароль"
                            size="small"
                            value={new1}
                            autoComplete="new-password"
                            name="new1"
                            onChange={this.handlePasswordChange}
                            variant="classic"
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <TextInput
                            type="password"
                            label="Повторите новый пароль"
                            size="small"
                            value={new2}
                            autoComplete="new-password"
                            name="new2"
                            onChange={this.handlePasswordChange}
                            variant="classic"
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={this.handleChangePassword}
                            loading={loadingPassportChange}
                        >
                            Поменять
                        </Button>
                    </Grid.Column>
                </Grid>
            </>
        );
    }
}
