import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';

import config from '../../../config/ui.config';
import history from '~/history';
import { fetchUserChatId } from '~/api/chatApi';

import UserInfoShow from './ShowBlocks/UserInfoShow';
import ItemContacts from '../Common/ItemContacts';
import UserPhotoBlock from './ShowBlocks/UserPhotoShow';
import userStore from '~/stores/userStore';
import UserBalanceBlock from './ShowBlocks/UserBalanceBlock';
import UserSEOShow from './ShowBlocks/UserSEOShow';
import UserGeoShow from './ShowBlocks/UserGeoShow';
import UserGrantAccessShow from './ShowBlocks/UserGrantAccessShow';
import UserWhatsappShowBlock from './ShowBlocks/UserWhatsappShowBlock';
import UserTelegramShowBlock from './ShowBlocks/UserTelegramShowBlock';

import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';

import LoaderAwait from '../../Base/LoaderAwait';

import Button from '~ui/Button';
import SendIcon from '@material-ui/icons/Send';

const UserShowMode = ({ user_id, loading }: { user_id: number; loading: boolean }) => {
    const [loadingChatId, setLoadingChatId] = useState(false);

    const handleChatId = async () => {
        setLoadingChatId(true);
        const chat_id = await fetchUserChatId(user_id);
        history.push(`/chat/users/${chat_id}/${user_id}`);
    };

    return (
        <Grid stackable reversed="mobile">
            <Grid.Column width={6}>
                <LoaderAwait active={loading} dimmer size="large" />

                <div className="crm-Main__hiddenMobile">
                    <UserPhotoBlock item_id={user_id} />
                </div>

                <ItemContacts store={userStore} item_id={user_id} />

                {authStore.canEdit(userStore.moduleName, user_id) && <UserWhatsappShowBlock item_id={user_id} />}
                {authStore.canEdit(userStore.moduleName, user_id) && <UserTelegramShowBlock item_id={user_id} />}

                {!loading && authStore.canRead('balance', user_id) && <UserBalanceBlock item_id={user_id} />}
            </Grid.Column>
            <Grid.Column width={10}>
                <UserInfoShow item_id={user_id} />
                <UserGeoShow item_id={user_id} />
                <UserGrantAccessShow item_id={user_id} />

                {config.enableUsersSEO && !commonStore.isMobile && !loading && authStore.canEdit(userStore.moduleName, user_id) && (
                    <UserSEOShow item_id={user_id} />
                )}

                {!loading && user_id !== authStore.currentUser.user_id && (
                    <Button
                        loading={loadingChatId}
                        variant="contained"
                        color="primary"
                        style={{ width: '100%', marginTop: '1em', color: 'white' }}
                        endIcon={<SendIcon />}
                        onClick={handleChatId}
                    >
                        Открыть чат
                    </Button>
                )}
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(UserShowMode);
