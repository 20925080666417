import React, { ReactElement, Component, ComponentType, Fragment, createRef, RefObject } from 'react';
import { ESTATE_BASES } from '~/types/estate.types';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import commonStore from '~/stores/commonStore';
import isEqual from '../../../../common/isEqual';
import ItemPreviewWrapperInner from './ItemPreviewWrapperInner';
import deepCopy from '~/common/deepCopy';

export const AWAIT_POPUP_TIME_MS = 700;
export const AWAIT_POPUP_TIME_CLOSE_MS = 300;

export type ItemPreviewProps = {
    item_id: number;
    base: ESTATE_BASES;
    trigger: ReactElement;
};

export type ItemPreviewWrapperProps = ItemPreviewProps & {
    onImgLoad: () => void;
    handleClose: (event: React.SyntheticEvent) => void;
};

type PopupTriggerProps = {
    trigger: ReactElement;
    onClick?: (event: React.SyntheticEvent) => void;
    onMouseLeave: () => void;
    onMouseEnter: () => void;
    context: RefObject<HTMLElement>;
    item_id: number;
};

export class PopupTrigger extends Component<PopupTriggerProps> {
    shouldComponentUpdate(nextProps: PopupTriggerProps) {
        if (nextProps.item_id !== this.props.item_id) {
            return true;
        }

        const { children: children1, ...nextTriggerProps } = nextProps.trigger.props;
        const { children: children2, ...thisTriggerProps } = this.props.trigger.props;
        return !isEqual(deepCopy(nextTriggerProps), deepCopy(thisTriggerProps));
    }

    render() {
        const { trigger, onClick, context, onMouseLeave, onMouseEnter } = this.props;

        return (
            <span title="" ref={context} onMouseDown={onClick} onMouseUp={onClick} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                {trigger}
            </span>
        );
    }
}

export type ItemPreviewWrapperState = {
    openPopup: boolean;
};

function ItemPreviewWrapper<TProps extends ItemPreviewProps>(
    store: ListStoreInterface,
    WrappedComponent: ComponentType<ItemPreviewWrapperProps>
): any {
    const ItemPreviewInner = ItemPreviewWrapperInner(store, WrappedComponent);

    class PreviewWrapperClass extends Component<TProps, ItemPreviewWrapperState> {
        state = {
            openPopup: false
        };

        showingTimeout: number | null = null;
        closingTimeout: number | null = null;

        onOpen = () => {
            const { item_id, base } = this.props;

            try {
                store.getItem(item_id);
            } catch (e) {
                store.fetchItem(item_id, base);
            }

            this.setState({ openPopup: true });
            this.showingTimeout = null;
        };

        onClose = async () => {
            clearTimeout(this.showingTimeout);
            clearTimeout(this.closingTimeout);
            this.showingTimeout = null;
            this.setState({ openPopup: false });
            this.closingTimeout = window.setTimeout(async () => {
                await this.setState({ openPopup: false });
                this.render();
            }, 0);
        };

        // For outerClick
        awaitClose = () => {
            clearTimeout(this.showingTimeout);
            setTimeout(() => this.setState({ openPopup: false }), AWAIT_POPUP_TIME_CLOSE_MS);
        };

        handleClose = (event: React.SyntheticEvent) => {
            event.stopPropagation();
            this.onClose();
        };

        handleMouseEnter = () => {
            clearTimeout(this.closingTimeout);
            this.closingTimeout = null;

            if (!this.showingTimeout) {
                this.showingTimeout = window.setTimeout(this.onOpen, AWAIT_POPUP_TIME_MS);
            }
        };

        handleMouseLeave = () => {
            clearTimeout(this.showingTimeout);
            this.showingTimeout = null;

            if (!this.closingTimeout) {
                this.closingTimeout = window.setTimeout(this.onClose, AWAIT_POPUP_TIME_CLOSE_MS);
            }
        };

        componentWillUnmount() {
            clearTimeout(this.showingTimeout);
            clearTimeout(this.closingTimeout);
        }

        shouldComponentUpdate(nextProps: ItemPreviewProps, nextState: ItemPreviewWrapperState) {
            return (
                nextState.openPopup !== this.state.openPopup ||
                nextProps.item_id !== this.props.item_id ||
                nextProps.trigger !== this.props.trigger
            );
        }

        contextRef: RefObject<HTMLElement> = createRef();

        render() {
            const { item_id, trigger, base } = this.props;
            const { openPopup } = this.state;

            if (commonStore.isMobile) {
                return trigger;
            }

            return (
                <Fragment>
                    <PopupTrigger
                        context={this.contextRef}
                        onClick={this.onClose}
                        onMouseLeave={this.handleMouseLeave}
                        onMouseEnter={this.handleMouseEnter}
                        trigger={trigger}
                        item_id={item_id}
                    />
                    <ItemPreviewInner
                        base={base}
                        item_id={item_id}
                        context={this.contextRef}
                        handleMouseLeave={!openPopup ? this.onClose : this.handleMouseLeave}
                        handleMouseEnter={this.handleMouseEnter}
                        handleClose={this.handleClose}
                        awaitClose={this.awaitClose}
                        openPopup={openPopup}
                    />
                </Fragment>
            );
        }
    }

    return PreviewWrapperClass;
}

export default ItemPreviewWrapper;
