import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import developerStore from '~/stores/lifeComplexes/developerStore';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';

import { HandleChangeType } from '~/components/Base/EditField';
import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

const CreateNewDeveloperModal = ({
    onClose,
    onCreateDeveloper
}: {
    onClose: () => void;
    onCreateDeveloper: (developer_id: number) => void;
}) => {
    useMemo(() => {
        developerStore.fetchItem(CREATING_ITEM_ID);
        developerStore.setEditingItem(CREATING_ITEM_ID, { name: '', logoUrl: null });
    }, []);

    const onCreate = async () => {
        if (await developerStore.createItem()) {
            onClose();
        }
    };

    const modalActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'default' },
        { onClick: onCreate, label: 'Создать', variant: 'contained', color: 'primary' }
    ];

    const handleChangeName: HandleChangeType = (event, { value }) => {
        developerStore.setEditingItem(CREATING_ITEM_ID, { name: value });
    };

    const handleChangeLogoUrl: HandleChangeType = (event, { value }) => {
        developerStore.setEditingItem(CREATING_ITEM_ID, { logoUrl: value });
    };

    const {
        loadingItem,
        editingItem: { name, logoUrl },
        errors
    } = developerStore.getItem(CREATING_ITEM_ID);

    return (
        <Modal maxWidth="sm" actions={modalActions} onClose={onClose} header="Создание застройщика" loading={loadingItem}>
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <TextInput
                            label="Название Девелопера"
                            value={name}
                            name="name"
                            onChange={handleChangeName}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            type="url"
                            label="Ссылка на логотип"
                            value={logoUrl}
                            name="logoUrl"
                            onChange={handleChangeLogoUrl}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(CreateNewDeveloperModal);
