import React, { useState } from 'react';
import { observer } from 'mobx-react';
import WAMessagesList from './WAMessagesList';

import ItemErrors from '~/components/Items/ItemErrors';

import whatsappStore from '~/stores/whatsappStore';
import authStore from '~/stores/authStore';

import { ChatSendBox } from '~/components/Chat/ChatSendArea';

const WAContactChat = ({ contact_id }: { contact_id: number }) => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        errors,
        filter,
        options: { chats }
    } = whatsappStore.getItemList(contact_id);
    const { waChat } = chats.find(({ whatsapp_chat_id }) => filter.whatsapp_chat_id === whatsapp_chat_id);

    const handleSend = async () => {
        setLoading(true);
        await whatsappStore.sendMessage(contact_id, message, waChat.phone);
        setMessage('');
        setLoading(false);
    };

    return (
        <>
            <WAMessagesList contact_id={contact_id} />

            {waChat.user_id === authStore.currentUser.user_id && (
                <div className="crm-Chat__footer">
                    <ItemErrors errors={errors} />

                    <ChatSendBox
                        message={message}
                        onSend={handleSend}
                        onChange={setMessage}
                        disabled={!message.length || loading || whatsappStore.whatsappStatus !== 'ready'}
                    />
                </div>
            )}
        </>
    );
};

export default observer(WAContactChat);
