import React from 'react';

import Time, { DELTA_ONLINE_TIME } from '~/components/Base/Time';
import UserAvatar from '~/components/Base/ui/UserAvatar';

import { ChatListType, UserChatTitle } from '~/types/chat.types';

const UserCell = ({
    chat_id,
    chat,
    active,
    onSelectUser
}: {
    chat_id: number;
    chat: UserChatTitle;
    active: boolean;
    onSelectUser: (chatType: ChatListType, chat_id: number, user_id: number) => void;
}) => {
    const { user, lastMessage, countUnreadMessages } = chat;
    const userName = `${user.firstName} ${user.lastName}`.trim();

    const isOnline = Date.now() - user.onlineTime * 1000 < DELTA_ONLINE_TIME;

    return (
        <div className={`crm-Chat__user_item ${active ? 'active' : ''}`} onClick={() => onSelectUser('users', chat_id, chat.user_id)}>
            <div className="crm-Chat__user_row">
                <div className="crm-Chat__item_avatar">
                    <div className="crm-Chat__item_avatar_mode">
                        <UserAvatar name={userName} src={user.avatarUrl} size={40} showStatus isOnline={isOnline} />
                    </div>
                </div>

                <div className="crm-Chat__info">
                    <span className="crm-Chat__info_name">
                        {user.lastName} {user.firstName}
                    </span>
                    <div className="crm-Chat__info_des">{lastMessage.message.substring(0, 25) + '...'}</div>
                    <div className="crm-Chat__info_time">
                        <Time time={lastMessage.createTime} />
                    </div>
                </div>

                <div className="crm-Chat__unread">
                    {countUnreadMessages > 0 && <div className="crm-Chat__unread_badge">{countUnreadMessages}</div>}
                </div>
            </div>
        </div>
    );
};

export default React.memo(UserCell);
