import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Header, Grid, Label, Icon } from 'semantic-ui-react';

import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import EstateAddressBlockShow from './AddressBlockShow';
import PropertyBlockShow from './PropertyBlockShow';
import OfficeDetailsBlockShow from './OfficeDetailsBlockShow';
import HouseBlockShow from './HouseBlockShow';
import estateL10n from '../../../../L10n/estate.L10n';
import PhotosBlockShow from './PhotosBlockShow';
import ComfortBlockShow from './ComfortBlockShow';
import InfrastructureBlockShow from './InfrastructureBlockShow';
import OfficeBuildingBlockShow from './OfficeBuildingBlockShow';
import CountryHouseShow from './CountryHouseShow';
import LandBlockShow from './LandBlockShow';
import GarageBlockShow from './GarageBlockShow';
import CommerceLandShow from './CommerceLandShow';
import WarehouseBlockShow from './WarehouseBlockShow';
import ParkingBlockShow from './ParkingBlockShow';
import ShoppingBlockShow from './ShoppingBlockShow';
import ShoppingInfrastructureBlockShow from './ShoppingInfrastructureBlockShow';
import BusinessBlockShow from './BusinessBlockShow';

import {
    Estate,
    ESTATE_BASE_EXTERNAL,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_BASE_MAIN,
    ESTATE_BASE_OWNERS,
    ESTATE_PROPERTY_COUNTRY_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_COTTAGE,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_HOUSE,
    ESTATE_PROPERTY_TYPE_HOUSE_PART,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_PROPERTY_TYPE_TOWNHOUSE,
    ESTATE_TYPE_RENT_ID,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_BUILDING
} from '~/types/estate.types';
import LoaderAwait from '../../../Base/LoaderAwait';
import EstateIsFavorite from '../../../Lists/Estate/SubBlocks/EstateIsFavorite';
import { ErrorMessage, MessageTitle } from '~ui/Message';

const printFloorsCount = (floorsCount?: number | null) => {
    switch (floorsCount) {
        case 1:
            return 'одноэтажный';
        case 2:
            return 'двухэтажный';
        case 3:
            return 'трехэтажный';
        case 4:
            return '4-х этажный';
        default:
            return `${floorsCount || 0}-и этажный`;
    }
};

export const printLandAreaUnitType = (landArea: number, landAreaUnitType: number): string => {
    const landAreaMod10 = landArea % 10;

    if (landAreaMod10 === 1) {
        if (landAreaUnitType === 1) {
            return 'гектар';
        } else {
            return 'сотка';
        }
    } else if (landAreaMod10 < 5) {
        if (landAreaUnitType === 1) {
            return 'гектара';
        } else {
            return 'сотки';
        }
    }
    if (landAreaUnitType === 1) {
        return 'гектар';
    } else {
        return 'соток';
    }
};

export const printEstateAbout = (estate: Estate): string => {
    const {
        roomsCount,
        totalArea,
        propertyType,
        roomsForSaleCount,
        allRoomsArea,
        buildingClassType,
        floorsCount,
        landArea,
        landAreaUnitType,
        buildingName
    } = estate;

    switch (propertyType) {
        case ESTATE_PROPERTY_TYPE_FLAT:
            return `${estateL10n.ROOMS_COUNT_LONG_FIND(roomsCount)}, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_NEWBUILDING:
            return `Новостройка, ${estateL10n.ROOMS_COUNT_LONG_FIND(roomsCount)}, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION:
            return `Переуступка, ${estateL10n.ROOMS_COUNT_LONG_FIND(roomsCount)}, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_ROOM:
            return (
                (roomsForSaleCount === 1 ? 'Комната' : `${roomsForSaleCount || ''} комнаты`) +
                ` ${allRoomsArea || 0} м² в ${estateL10n.ROOMS_COUNT_LONG_FROM_FIND(roomsCount)}`
            );
        case ESTATE_PROPERTY_TYPE_PART:
            return `Доля в ${estateL10n.ROOMS_COUNT_LONG_FROM_FIND(roomsCount)}, ${totalArea || '-'} м²`;

        case ESTATE_PROPERTY_TYPE_HOUSE:
            return `Дом/Дача (${printFloorsCount(floorsCount)}) ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_COTTAGE:
            return `Коттедж (${printFloorsCount(floorsCount)}) ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_TOWNHOUSE:
            return `Таунхаус (${printFloorsCount(floorsCount)}) ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_HOUSE_PART:
            return `Часть дома (${printFloorsCount(floorsCount)}) ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_LAND:
            return `Участок, ${landArea || '-'} ${printLandAreaUnitType(Number(landArea), landAreaUnitType || 2)}`;

        case ESTATE_PROPERTY_TYPE_GARAGE:
            return `Гараж, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_GARAGE_BOX:
            return `Бокс, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_CAR_PLACEMENT:
            return `Машиноместо${buildingName ? ` (${buildingName})` : ','} ${totalArea || '-'} м²`;

        case ESTATE_PROPERTY_TYPE_OFFICE:
            return `Офис ${buildingClassType ? `(${estateL10n.OFFICE_CLASS_TYPE_FIND(buildingClassType)}) ` : ''}${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_WAREHOUSE:
            return `Склад ${buildingClassType ? `(${estateL10n.OFFICE_CLASS_TYPE_FIND(buildingClassType)}) ` : ''}${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_INDUSTRY:
            return `Производство ${buildingClassType ? `(${estateL10n.OFFICE_CLASS_TYPE_FIND(buildingClassType)}) ` : ''}
                ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_FREE_PURPOSE:
            return `Помещение свободного назначения, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_SHOPPING:
            return `Торговая площадь, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_BUSINESS:
            return `Готовый бизнес, ${totalArea || '-'} м²`;
        case ESTATE_PROPERTY_TYPE_BUILDING:
            return `Здание, ${totalArea || '-'} м²`;

        case ESTATE_PROPERTY_TYPE_COMMERCE_LAND:
            return `Земля ${landArea || '-'} ${printLandAreaUnitType(Number(landArea), landAreaUnitType || 2)}`;

        default:
            break;
    }
    return '';
};

type EstateInfoBlockProps = {
    estate_id: number;
    hideFavoriteBlock?: boolean;
};

export const EstatePropertyShowInfo = ({ estate_id, propertyType }: { estate_id: number; propertyType: number }) => {
    return (
        <>
            {ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) && (
                <Fragment>
                    <PropertyBlockShow item_id={estate_id} />
                    <ComfortBlockShow item_id={estate_id} />
                    <HouseBlockShow item_id={estate_id} />
                </Fragment>
            )}

            {ESTATE_PROPERTY_COUNTRY_ARR.includes(propertyType) && <LandBlockShow item_id={estate_id} />}

            {ESTATE_PROPERTY_HOUSES.includes(propertyType) && <CountryHouseShow item_id={estate_id} />}
            {ESTATE_PROPERTY_HOUSES.includes(propertyType) && <ComfortBlockShow item_id={estate_id} />}

            {ESTATE_PROPERTY_GARAGE_ARR.includes(propertyType) && <GarageBlockShow item_id={estate_id} />}

            {[ESTATE_PROPERTY_TYPE_OFFICE, ESTATE_PROPERTY_TYPE_FREE_PURPOSE].includes(propertyType) && (
                <Fragment>
                    <OfficeDetailsBlockShow item_id={estate_id} />
                    {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && <InfrastructureBlockShow item_id={estate_id} />}
                    <OfficeBuildingBlockShow item_id={estate_id} />
                </Fragment>
            )}

            {propertyType === ESTATE_PROPERTY_TYPE_COMMERCE_LAND && <CommerceLandShow item_id={estate_id} />}

            {[ESTATE_PROPERTY_TYPE_WAREHOUSE, ESTATE_PROPERTY_TYPE_INDUSTRY].includes(propertyType) && (
                <Fragment>
                    <OfficeDetailsBlockShow item_id={estate_id} />
                    <WarehouseBlockShow item_id={estate_id} />
                    <OfficeBuildingBlockShow item_id={estate_id} />
                    <ParkingBlockShow item_id={estate_id} />
                </Fragment>
            )}

            {propertyType === ESTATE_PROPERTY_TYPE_SHOPPING && (
                <Fragment>
                    <OfficeDetailsBlockShow item_id={estate_id} />
                    <ShoppingBlockShow item_id={estate_id} />
                    <OfficeBuildingBlockShow item_id={estate_id} />
                    <ShoppingInfrastructureBlockShow item_id={estate_id} />
                </Fragment>
            )}

            {propertyType === ESTATE_PROPERTY_TYPE_BUILDING && (
                <Fragment>
                    <OfficeDetailsBlockShow item_id={estate_id} />
                </Fragment>
            )}

            {propertyType === ESTATE_PROPERTY_TYPE_BUSINESS && <BusinessBlockShow item_id={estate_id} />}
        </>
    );
};

@observer
class EstateInfoBlock extends Component<EstateInfoBlockProps, {}> {
    render() {
        const { estate_id, hideFavoriteBlock } = this.props;
        const { loadingItem, item } = estateStore.getItem(estate_id);
        const { isFake, propertyType, base, enable, isFavorite, isHidden, deleteStatus, soldPrice } = item || {};

        return (
            <Fragment>
                <Header>
                    {item && !enable && (
                        <ErrorMessage>
                            <MessageTitle>Объект в архиве</MessageTitle>
                            {deleteStatus && <>Причина удаления: {estateL10n.DELETE_STATUS_FIND(deleteStatus)}</>}
                            {soldPrice && (
                                <>
                                    {' '}
                                    (Цена продажи: {soldPrice.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(1)})
                                </>
                            )}
                        </ErrorMessage>
                    )}
                    <Grid stackable>
                        <Grid.Column width={13}>
                            {base === ESTATE_BASE_MAIN && (
                                <Fragment>
                                    {isFake && (
                                        <Label color="yellow" tag>
                                            Фейк
                                        </Label>
                                    )}
                                    №{estate_id}
                                    &nbsp;
                                </Fragment>
                            )}
                            {base === ESTATE_BASE_OWNERS && <Icon name="male" color="brown" title="Собственник" />}
                            {base === ESTATE_BASE_EXTERNAL && <Icon name="database" color="red" title="Объявление со сторонней площадки" />}
                            {item && <ShadowFragment shadow={loadingItem} print={printEstateAbout(item)} />}
                        </Grid.Column>
                        <Grid.Column width={3} textAlign="right">
                            {item && !hideFavoriteBlock && (
                                <EstateIsFavorite
                                    hiddenButton
                                    size="large"
                                    isHidden={isHidden}
                                    isFavorite={isFavorite}
                                    estate_id={estate_id}
                                    base={base}
                                />
                            )}
                        </Grid.Column>
                    </Grid>
                </Header>

                <ShadowFragment shadow={!item} print={item && <EstateAddressBlockShow item_id={estate_id} />} />

                <PhotosBlockShow item_id={estate_id} />

                <EstatePropertyShowInfo estate_id={estate_id} propertyType={propertyType} />

                <LoaderAwait immediately={!!item && loadingItem} active={loadingItem} size="large" dimmer />
            </Fragment>
        );
    }
}

export default EstateInfoBlock;
