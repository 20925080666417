import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Divider from '~ui/Divider';

import UserInfoBoxEditing from './EditBlocks/UserInfoEditing';
import ItemContactsEditing from '../Common/ItemContacts/ItemContactsEditing';

import { UserLinkState } from '../../Lists/Users/UserLink';
import userStore from '~/stores/userStore';
import ItemAddModeWrapper, { ItemAddModeWrapperProps } from '../ItemAddModeWrapperNew';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CreationButtonsSet from '~/components/Items/Common/CreationButtonsSet';

type UserAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

@observer
class UserAddMode extends Component<UserAddModeProps> {
    render() {
        const { loadingItem } = userStore.getItem(CREATING_ITEM_ID);

        return (
            <Fragment>
                <UserInfoBoxEditing item_id={0} />
                <Divider />
                <ItemContactsEditing store={userStore} phones={[{ phone: '', phone_id: 0 }]} item_id={0} />
                <Divider />
                <CreationButtonsSet loading={loadingItem} onCreate={this.props.handleCreate} onClearForm={this.props.clearForm} />
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(userStore, UserLinkState, withRouter(UserAddMode));
