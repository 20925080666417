import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Divider } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import ShadowFragment from '../../../Base/ShadowFragment';
import EditingBlockWrapper from '../../EditingBlockWrapper';

import estateL10n from '../../../../L10n/estate.L10n';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import ParkingBlockEditing from '../EditBlocks/ParkingBlockEditing';
import { printParkingsCount } from './OfficeDetailsBlockShow';

@observer
class ParkingBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const { parkingLocationType, parkingPurposeType, parkingPlacesCount, parkingPriceEntry, parkingCurrency, parkingIsFree } =
            item || {};

        return (
            <Card fluid color="green">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Парковка</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Расположение парковки</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.PARKING_LOCATION_TYPE_FIND(parkingLocationType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Назначение парковки</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.PARKING_PURPOSE_TYPE_FIND(parkingPurposeType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>{Number(parkingPlacesCount) > 0 && printParkingsCount(parkingPlacesCount)}</Grid.Column>

                                    <Grid.Column>
                                        {Number(parkingPriceEntry) > 0 && (
                                            <span className="crm-Estate__fieldNowrap">{`${parkingPriceEntry} ${estateL10n.CURRENCY_TYPES_FIND(
                                                parkingCurrency
                                            )} за въезд`}</span>
                                        )}
                                        &nbsp;
                                        {parkingIsFree && 'бесплатно'}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <ParkingBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, ParkingBlockShow);
