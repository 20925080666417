import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Dropdown, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { Estate } from '~/types/estate.types';
import ButtonGroup from '../../../Base/ButtonGroup';
import { OneFourGroup } from './PropertyBlockEditing';

import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { YES_NO_SET } from './ComfortBlockEditing';

const wikiMapiaUrl = (geo_lat: number, geo_lon: number) => `http://wikimapia.org/#lang=ru&lat=${geo_lat}&lon=${geo_lon}&z=18&m=w`;
const yandexMapsUrl = (geo_lat: number, geo_lon: number) => `https://yandex.ru/maps/?text=${geo_lat},${geo_lon}`;

type HouseBlockEditingProps = HandlerEditingWrappedProps & {
    buildYear?: number | null;
    buildingIsNotReadyYet?: boolean | null;
    materialType?: number | null;
    buildingSeries?: string | null;
    ceilingHeight?: number | null;
    passengerLiftsCount?: number;
    cargoLiftsCount?: number;
    hasGarbageChute?: boolean | null;
    hasRamp?: boolean | null;
    parking?: number | null;

    geo_lat?: number | null;
    geo_lon?: number | null;
    houseNumber?: string | null;
};

@observer
class HouseBlockEditing extends Component<HouseBlockEditingProps> {
    constructor(props: HouseBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            buildYear: props.buildYear || null,
            buildingIsNotReadyYet: props.buildingIsNotReadyYet || null,
            materialType: props.materialType || null,
            buildingSeries: props.buildingSeries || null,
            ceilingHeight: props.ceilingHeight || null,
            passengerLiftsCount: props.passengerLiftsCount || 0,
            cargoLiftsCount: props.cargoLiftsCount || 0,
            hasGarbageChute: props.hasGarbageChute || null,
            hasRamp: props.hasRamp || null,
            parking: props.parking || 0,

            geo_lat: props.geo_lat || null,
            geo_lon: props.geo_lon || null,
            houseNumber: props.houseNumber || null
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    render() {
        const { handleCheckbox, handleChange, item_id } = this.props;

        const {
            buildYear,
            buildingIsNotReadyYet,
            materialType,
            buildingSeries,
            ceilingHeight,
            passengerLiftsCount,
            cargoLiftsCount,
            hasGarbageChute,
            hasRamp,
            parking,

            geo_lat,
            geo_lon,
            houseNumber
        } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                {geo_lat && (
                    <Fragment>
                        <a
                            className="crm-Estate__address_find_mapLink"
                            title="Поискать информацию о доме на WikiMapia"
                            target="_blank"
                            href={wikiMapiaUrl(Number(geo_lat), Number(geo_lon))}
                            rel="noreferrer"
                        >
                            <img src="/static/wikimapia.ico" alt="WikiMapia ico" />
                            &nbsp;
                            <b>wikimapia</b>
                            &nbsp;
                            <Icon name="external" />
                        </a>
                        <a
                            className="crm-Estate__address_find_mapLink"
                            title="Поискать информацию о доме на Яндекс Картах"
                            target="_blank"
                            href={yandexMapsUrl(Number(geo_lat), Number(geo_lon))}
                            rel="noreferrer"
                        >
                            <img src="/static/yandexmaps.png" alt="Яндекс Карты" />
                            &nbsp;
                            <b>Яндекс Карты</b>
                            &nbsp;
                            <Icon name="external" />
                        </a>
                    </Fragment>
                )}

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Год постройки</label>
                        <div className="crm-Estate__fieldNowrap">
                            <Input
                                type="number"
                                placeholder="год"
                                size="mini"
                                value={buildYear || ''}
                                name="buildYear"
                                onChange={handleChange}
                                min={1}
                                max={2100}
                            />
                            &nbsp;&nbsp;
                            <Checkbox
                                checked={Boolean(buildingIsNotReadyYet)}
                                name="buildingIsNotReadyYet"
                                label={<label>не сдан</label>}
                                onClick={handleCheckbox}
                                className="crm-Estate__field_rightMargin"
                            />
                            <Input
                                placeholder="серия"
                                size="mini"
                                value={buildingSeries || ''}
                                name="buildingSeries"
                                onChange={handleChange}
                                style={{ width: '100px' }}
                            />
                        </div>
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Тип дома</label>
                        <Dropdown
                            selection
                            options={estateL10n.HOUSE_MATERIAL_TYPES_DROPDOWN}
                            name="materialType"
                            value={materialType}
                            onChange={handleChange}
                            clearable
                            type="clearable"
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Высота потолков</label>
                        <Input
                            value={ceilingHeight || ''}
                            size="mini"
                            name="ceilingHeight"
                            onChange={handleChange}
                            label={{
                                basic: true,
                                content: 'см'
                            }}
                            labelPosition="right"
                            type="number"
                            min={100}
                            max={999}
                            required
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Пассажирских лифтов</label>
                        <OneFourGroup name="passengerLiftsCount" value={passengerLiftsCount} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Грузовых лифтов</label>
                        <OneFourGroup name="cargoLiftsCount" value={cargoLiftsCount} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Тип парковки</label>
                        <ButtonGroup
                            buttonSet={estateL10n.HOUSE_PARKING_TYPES}
                            name="parking"
                            value={parking}
                            handleChange={handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Пандус</label>
                        <ButtonGroup buttonSet={YES_NO_SET} name="hasRamp" value={hasRamp} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Мусоропровод</label>
                        <ButtonGroup buttonSet={YES_NO_SET} name="hasGarbageChute" value={hasGarbageChute} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, HouseBlockEditing);
