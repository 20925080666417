import { observable, action, runInAction, computed } from 'mobx';
import arrayMove from 'array-move';
import * as estateApi from '~/api/estateApi';
import * as newbuildingsApi from '~/api/newbuildingsApi';
import * as addressApi from '~/api/addressApi';
import * as exportsApi from '~/api/export/exportsApi';
import * as cellCallsApi from '~/api/cell/cellCallsApi';
import * as wishApi from '~/api/wishApi';
import {
    Estate,
    UploadingPhoto,
    MetroDistance,
    EstateFilter,
    MetroStation,
    ESTATE_BASES,
    EstateTableItem,
    TEstateDropdown,
    ShareEstateId,
    ESTATE_BASE_OWNERS,
    ESTATE_BASE_EXTERNAL,
    ESTATE_BASE_GROUP
} from '~/types/estate.types';
import escapedRegExp from '../common/escapedRegExp';

import ListStoreMapPrototype from './prototypes/ListStoreMapPrototype.prototype';
import * as contactsApi from '~/api/contactsApi';
import * as showingApi from '~/api/showingApi';
import globalSearchStore from './globalSearchStore';
import { SearchModuleResult } from './globalSearchStore';
import { estateAbout, EstateLinkState } from '~/components/Lists/Estate/EstateLink';
import { ESTATE_BASE_MAIN, ESTATE_TYPE_RENT, ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL } from '~/types/estate.types';
import exportStore from './export/exportStore';
import exportingBaseStore from './export/exportingBaseStore';
import { DropdownType } from './prototypes/ListStore.prototype';
import { disableIconHiglight, highlightActiveEstateIdOn } from '~/common/osmMapApi';
import { Highway } from '~/types/houses.types';
import estateL10n from '../L10n/estate.L10n';
import contactStore from './contactStore';
import authStore from './authStore';
import { NEWBUILDING_BASE_YANDEX } from '~/types/newbuildings.types';
import { NewBuildingHouse, NEWBUILDING_BASES } from '~/types/newbuildings.types';
import {
    validEstate,
    printNewbuildingTitle,
    estateDropdownTitle,
    ListFilterInitial,
    adjustEstateFilter,
    matchEstateThumbnailPhotoUrl
} from './helpers/estate.store.helpers';
import { EstateItemTypeProperty } from './helpers/estate.store.helpers';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import { isEqualWithoutFields } from '~/common/isEqual';
import { nProgressItem, catchOnlyError, withAbortController } from './helpers/decorators.helpers';
import deepCopy from '../common/deepCopy';
import { DEFAULT_APPROXIMATION_RANGE } from './wishStore';
import wishStore from './wishStore';
import { TSameEstatesFilter } from '~/api/estateApi';
import settingStore from '~/stores/settingStore';

const DEFAULT_REGION_MEMORY_KEY = 'default-region-id';

class EstateStore extends ListStoreMapPrototype<Estate, EstateTableItem, EstateItemTypeProperty, EstateFilter, ShareEstateId> {
    liveDraft = true;
    listFilterClear = ListFilterInitial;
    orderBy = 'updateTime';
    enableLoadingCount = true;

    disablePrepareNextList = true;

    constructor() {
        super('estate_id', 'estate', estateApi);

        globalSearchStore.regModule({
            title: 'Недвижимость',
            moduleName: this.moduleName,
            search: this.globalSearchList
        });

        authStore.registerInitFunc(this.fetchMetroStations.bind(this));
        authStore.registerInitFunc(this.fetchHighways.bind(this));
        if (window.localStorage[DEFAULT_REGION_MEMORY_KEY]) {
            this.listFilterClear.regionId = Number(window.localStorage[DEFAULT_REGION_MEMORY_KEY]);
        } else {
            authStore.registerInitFunc(this.setDefaultRegionId.bind(this));
        }
        authStore.registerCloseFunc(() => window.localStorage.removeItem(DEFAULT_REGION_MEMORY_KEY));

        this.clearFilter();
    }

    setDefaultRegionId() {
        const { currentUser } = authStore;
        if (currentUser && currentUser.group && currentUser.group_id && currentUser.group.region_id) {
            this.listFilterClear.regionId = currentUser.group.region_id;
            window.localStorage[DEFAULT_REGION_MEMORY_KEY] = this.listFilterClear.regionId;
            this.clearFilter();
        }
    }

    @observable
    selectedItemsIds: ShareEstateId[] = [];

    matchSelectedItemsFiled(estate: EstateTableItem): ShareEstateId {
        const { estate_id, base } = estate;
        return { estate_id, base };
    }

    globalSearchList = async (
        abortController: AbortController,
        query: string,
        start: number,
        limit: number
    ): Promise<SearchModuleResult> => {
        const { list, count } = await estateApi.searchEstateDropdown(
            {
                enable: true,
                search: query,
                base: this.fetchItemDropdownBase
            },
            start,
            limit,
            abortController
        );

        return {
            list: list.map(
                ({
                    estate_id,
                    address,
                    price,
                    currency,
                    description,
                    propertyType,
                    roomsCount,
                    totalArea,
                    type,
                    newbuilding,
                    newbuildingHouse,
                    photos,
                    major_users
                }) => ({
                    key: estate_id,
                    title: `${address}${newbuilding ? `, ${printNewbuildingTitle({ newbuilding, newbuildingHouse })}` : ''}`,
                    state: EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, ESTATE_BASE_MAIN),
                    description: `${estateAbout(propertyType, roomsCount)}, ${totalArea || '-'} м² (${
                        major_users ? major_users.map(({ firstName, lastName }) => `${lastName} ${firstName}`).join('; ') : ''
                    })`,
                    price: `${price.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(currency)}${
                        type === ESTATE_TYPE_RENT_ID ? '/мес' : ''
                    }`,
                    image: photos && photos.length ? matchEstateThumbnailPhotoUrl(photos[0].filename).src : null,
                    className: this.moduleName
                })
            ),
            count
        };
    };

    @action
    setRouteFilter(param: string) {
        this.listFilter['type'] = param === ESTATE_TYPE_RENT ? 2 : param === ESTATE_TYPE_SELL ? 1 : 0;
    }

    @action
    async fetchNewbuildingsSuggestions(estate_id: number, count: number) {
        const { newbuildingsQuery } = this.getItem(estate_id).property;
        const { region } = this.getItem(estate_id).editingItem;
        this.setProperty(estate_id, { loadingNewbuildings: true });

        const newbuildings = await newbuildingsApi.fetchNewbuildingsSuggestions(newbuildingsQuery, region, count);
        this.setProperty(estate_id, { loadingNewbuildings: false, newbuildings });
    }

    @action
    async fetchNearestNewbuildings(estate_id: number, count: number) {
        const { geo_lat, geo_lon } = this.getItem(estate_id).editingItem;

        if (geo_lat && geo_lon) {
            this.setProperty(estate_id, { loadingNewbuildings: true, newbuildingsQuery: '', newbuildings: [] });
            const newbuildings = await newbuildingsApi.fetchNearestNewbuildings(geo_lat, geo_lon, count);
            this.setProperty(estate_id, { loadingNewbuildings: false, newbuildings });
        }
    }

    @observable
    loadingMetros = false;

    @action
    async fetchNearestMetro(estate_id: number, geo_lon: number, geo_lat: number, region: string) {
        this.loadingMetros = true;

        const range = ['Москва', 'Московская'].includes(region) ? 20000 : 6000;
        const metros = geo_lon > 0 && geo_lat > 0 ? (await estateApi.fetchNearestMetros(geo_lon, geo_lat, range)) || [] : [];
        this.setEditingItem(estate_id, {
            main_metro_id: metros[0] ? metros[0].metro_id : 0,
            metros
        });
        this.loadingMetros = false;
    }

    @action
    editMetroList(estate_id: number, id: number, name: string, value: string | number) {
        const { metros } = this.getItem(estate_id).editingItem;
        metros[id][name] = value;
    }

    @action
    removeMetroFromList(estate_id: number, id: number) {
        const { metros } = this.getItem(estate_id).editingItem;
        metros.splice(id, 1);
        this.setEditingItem(estate_id, {
            main_metro_id: metros && metros.length > 0 ? metros[0].metro_id : 0
        });
    }

    @action
    addMetroToList(estate_id: number) {
        const { metros } = this.getItem(estate_id).editingItem;
        metros.push({
            metro_id: metros.length + 1,
            time: 1,
            transportType: 1
        });
        this.setEditingItem(estate_id, {
            main_metro_id: metros[0].metro_id
        });
    }

    validationItem(estate: Estate): Array<string> {
        return validEstate(estate);
    }

    @action
    async createItem(): Promise<number> {
        const emptyItem = this.getItem(CREATING_ITEM_ID);
        const estate = emptyItem.editingItem;

        estate.metros = (estate.metros || []).map(({ metro_id, time, transportType }: MetroDistance) => ({
            metro_id,
            time,
            transportType
        }));

        return await super.createItem();
    }

    @observable
    loadingPhotos = false;

    @action
    turnLoadingPhotos(direction: boolean) {
        this.loadingPhotos = direction;
    }

    @action
    addPhotoTemp(estate_id: number, photo: UploadingPhoto) {
        const { photos } = this.getItem(estate_id).editingItem;
        runInAction(() => {
            photos.push(photo);
        });
        // this.setEditingItem(estate_id, { photos });
    }

    @action
    removePhotoTemp(estate_id: number, filename: string) {
        const { photos } = this.getItem(estate_id).editingItem;
        const foundIndex = photos.findIndex(photo => photo.filename === filename);
        runInAction(() => {
            photos.splice(foundIndex, 1);
        });
    }

    @observable
    metroStationsListLoading = true;
    @observable
    metroStationsList: MetroStation[] = [];

    async fetchMetroStations() {
        if (!this.metroStationsList.length) {
            this.metroStationsList = await estateApi.fetchAllMetro();
            this.metroStationsListLoading = false;
        }
    }

    @observable
    highwaysListLoading = true;
    highwaysList: Highway[] = [];

    async fetchHighways() {
        if (!this.highwaysList.length) {
            this.highwaysList = await estateApi.fetchAllHighways();
            this.highwaysListLoading = false;
        }
    }

    @computed
    get metroStationsListDropdown(): Array<DropdownType> {
        return this.metroStationsList.map(({ metro_id, name }) => ({ key: metro_id, value: metro_id, text: name }));
    }

    findMetroById(metro_id: number): MetroStation {
        const metro = this.metroStationsList.find(metro => metro.metro_id === metro_id);
        if (!metro) {
            throw new Error(`Метро ${metro_id} не найдено`);
        }
        return metro;
    }

    @action
    async contactByEstate(estate_id: number, contact_id: number) {
        this.setProperty(estate_id, { contact: null, contactLoading: true });
        const contact = await contactsApi.fetchItem(contact_id);
        this.setProperty(estate_id, { contact, contactLoading: false });
    }

    @action
    async showingsByEstate(estate_id: number, enable: boolean) {
        this.setProperty(estate_id, { showings: [], loadingShowings: true });
        const showings = await showingApi.fetchShowingsByItem('estate', estate_id, typeof enable === 'boolean' ? enable : true);
        this.setProperty(estate_id, { showings, loadingShowings: false });
    }

    @action
    async callsByEstate(estate_id: number) {
        this.setProperty(estate_id, { calls: [], loadingCalls: true });
        const calls = await cellCallsApi.fetchCellCallsByEstate(estate_id);
        this.setProperty(estate_id, { calls, loadingCalls: false });
    }

    @action
    async wishesByEstate(estate_id: number, approximation: number) {
        this.setProperty(estate_id, { wishes: [], loadingWishes: true });
        const {
            item,
            property: { wishesLocationType }
        } = this.getItem(estate_id);

        if (item) {
            await this.fetchMetroStations();
            const { list: wishes } = await wishApi.fetchList(
                100,
                0,
                'wish_id',
                'descending',
                adjustEstateFilter(item, approximation, wishesLocationType)
            );
            this.setProperty(estate_id, { wishes, loadingWishes: false });
        }
    }

    @action
    async firstFetchWishesByEstate(estate_id: number) {
        const { item } = this.getItem(estate_id);
        if (item) {
            const wishesLocationType = item.town === 'Москва' ? 1 : 2;
            this.setProperty(estate_id, { wishesLocationType });
            await this.wishesByEstate(estate_id, DEFAULT_APPROXIMATION_RANGE);
        }
    }

    fetchItemDropdownBase: ESTATE_BASES = ESTATE_BASE_MAIN;
    dropdownList: Array<TEstateDropdown> = [];
    dropdownAC: AbortController | null = null;

    @withAbortController('dropdownAC')
    @action
    async fetchItemDropdownOptions(search: string) {
        this.loadingDropdownOptions = true;

        this.dropdownList = (
            await estateApi.searchEstateDropdown(
                /*50, 0, 'updateTime', 'descending', */ {
                    enable: true,
                    search,
                    base: this.fetchItemDropdownBase
                },
                0,
                50,
                this.dropdownAC
            )
        ).list;

        const searchReg = escapedRegExp(search);

        this.itemDropdownOptions = this.dropdownList.map(estate => {
            let text = estateDropdownTitle(estate);
            if (!text.match(searchReg)) {
                text += ` // ${search}`;
            }

            return {
                key: estate.estate_id,
                value: estate.estate_id,
                text
            };
        });

        this.loadingDropdownOptions = false;
    }

    @action
    async saveItem(id: number): Promise<Record<string, any> | boolean> {
        const { editingItem, item } = this.getItem(id);

        if (editingItem.photos) {
            this.setProperty(id, { updatingPhotos: true });
        }

        if (item && editingItem.newbuilding_id) {
            const { newbuildings } = this.getItem(id).property;
            const newbuilding = newbuildings.find(({ newbuilding_id }) => newbuilding_id === editingItem.newbuilding_id);
            if (newbuilding) {
                item.newbuilding = newbuilding;

                if (editingItem.newbuilding_house_id) {
                    const newbuildingHouse =
                        newbuilding.houses &&
                        newbuilding.houses.find(({ newbuilding_house_id }) => newbuilding_house_id === editingItem.newbuilding_house_id);

                    if (newbuildingHouse) {
                        item.newbuildingHouse = newbuildingHouse;
                    }
                } else if (editingItem.newbuilding_house_id === null) {
                    item.newbuildingHouse = undefined;
                }
            }
        }

        const newItem = await super.saveItem(id);
        const estate = this.getItem(id).item;

        if (newItem && estate && estate.photos) {
            estate.photos.forEach(photo => {
                if (photo.originalname) {
                    delete photo.originalname;
                }
            });
            await this.fetchItem(id, ESTATE_BASE_MAIN, true);
        }

        this.setProperty(id, { updatingPhotos: false });
        return newItem;
    }

    @action
    clearFilter() {
        const { deltaTime, base, type } = this.listFilter;
        super.clearFilter();

        if (base) {
            this.listFilter['base'] = base;
            this.listFilter['type'] = type;
            this.listFilter['deltaTime'] = deltaTime;
        }
    }

    @action
    async fetchEstateExportHistory(estate_id: number) {
        this.setProperty(estate_id, { exportHistory: null, loadingExportHistory: true });

        const exportHistory = await exportsApi.fetchEstateExportHistory(
            estate_id,
            365, // exportPagination.pageSize,
            0 // (exportPagination.activePage - 1) * exportPagination.pageSize
        );

        this.setProperty(estate_id, { exportHistory, loadingExportHistory: false });
    }

    @action
    movePhoto(estate_id: number, oldIndex: number, newIndex: number) {
        let { photos } = this.getItem(estate_id).editingItem;
        photos = arrayMove(photos, oldIndex, newIndex);
        this.setEditingItem(estate_id, { photos });
    }

    async initNewbuildingFromProps(estate_id: number): Promise<NewBuildingHouse[]> {
        const { newbuilding_id } = this.getItem(estate_id).editingItem;
        let { newbuilding } = this.getItem(estate_id).item || {};

        if (newbuilding_id) {
            this.setProperty(estate_id, { loadingNewbuildings: true });
            newbuilding = await newbuildingsApi.fetchItem(newbuilding_id);
            this.setProperty(estate_id, { newbuildings: [newbuilding], newbuildingsQuery: newbuilding.name, loadingNewbuildings: false });
        } else if (newbuilding) {
            this.setProperty(estate_id, { newbuildingsQuery: newbuilding.name });
            await this.fetchNewbuildingsSuggestions(estate_id, 1);

            const { newbuildings } = this.getItem(estate_id).property;

            newbuilding = newbuildings.find(newbuilding => newbuilding.newbuilding_id === newbuilding_id);
            if (!newbuilding) {
                throw new Error('Newbuilding didnt find');
            }
        }

        return newbuilding ? newbuilding.houses : [];
    }

    async matchBaseHouse(estate_id: number, base: NEWBUILDING_BASES) {
        const baseUpChar = base === NEWBUILDING_BASE_YANDEX ? 'Yandex' : 'Avito';

        const { newbuilding_house_id } = this.getItem(estate_id).editingItem;
        if (newbuilding_house_id) {
            this.setProperty(estate_id, { [`loading${baseUpChar}House`]: true });

            const data = await newbuildingsApi[`match${baseUpChar}House`](newbuilding_house_id);

            this.setProperty(estate_id, {
                [`loading${baseUpChar}House`]: false,
                [`${base}House`]: data[`${base}House`],
                [`suggested${baseUpChar}Houses`]: data[`suggested${baseUpChar}Houses`],
                [`editing${baseUpChar}House`]: !data[`${base}House`]
            });
        }
    }

    async findBaseHouses(estate_id: number, search: string, base: NEWBUILDING_BASES) {
        const baseUpChar = base === NEWBUILDING_BASE_YANDEX ? 'Yandex' : 'Avito';

        this.setProperty(estate_id, { [`loading${baseUpChar}House`]: true });
        const suggestedHouses = await newbuildingsApi[`find${baseUpChar}Houses`](search);
        this.setProperty(estate_id, { [`loading${baseUpChar}House`]: false, [`suggested${baseUpChar}Houses`]: suggestedHouses });
    }

    @nProgressItem
    async updateBaseHouse(estate_id: number, base_house_id: number, base: NEWBUILDING_BASES) {
        const baseUpChar = base === NEWBUILDING_BASE_YANDEX ? 'Yandex' : 'Avito';

        const { newbuilding_house_id } = this.getItem(estate_id).editingItem;
        this.setProperty(estate_id, { [`editing${baseUpChar}House`]: false });

        if (newbuilding_house_id) {
            await newbuildingsApi[`update${baseUpChar}House`](base_house_id, newbuilding_house_id);
        }
    }

    @action
    async setAddressCoordinates(estate_id: number, geo_lat: number, geo_lon: number) {
        try {
            const yandexAddress = await addressApi.fetchYandexAddressByCoords(geo_lat, geo_lon);

            this.setEditingItem(estate_id, { ...yandexAddress, geo_lat, geo_lon });
            this.setProperty(estate_id, { searchAddressQuery: yandexAddress.address });
            if (yandexAddress.region) {
                this.fetchNearestMetro(estate_id, geo_lon, geo_lat, yandexAddress.region);
            }
        } catch (error) {
            this.getItem(estate_id).errors = [error];
        }
    }

    @action
    async findMOSCOWMAPHouse(estate_id: number) {
        const { street, streetType, houseNumber, houseNumberType, blockNumber, blockNumberType, town } =
            this.getItem(estate_id).editingItem;

        const houseNumberFull = `${houseNumberType || ''} ${houseNumber || ''} ${blockNumberType || ''} ${blockNumber || ''}`.trim();

        const moscowmapHouse = await estateApi.findMOSCOWMAPHouse(
            town || '',
            street || '',
            streetType || '',
            houseNumberFull,
            houseNumberFull
        );

        this.setProperty(estate_id, { moscowmapHouse });

        if (moscowmapHouse) {
            const { year, type, series, floors, garbage, floor_height } = moscowmapHouse;

            this.setEditingItem(estate_id, {
                buildYear: year,
                buildingSeries: series,
                materialType: type,
                floorsCount: floors,
                ceilingHeight: floor_height,
                hasGarbageChute: Boolean(garbage)
            });
        }
    }

    highlightActiveEstateIdOnMap = (estate_id: number, base: ESTATE_BASES) => {
        highlightActiveEstateIdOn(estate_id, 'estate_id', base);
    };

    disableEstateHighlight = () => {
        disableIconHiglight();
    };

    changeFilterBase = (base: ESTATE_BASES | 'group') => {
        this.resetPagination();
        // if (base === ESTATE_BASE_MAIN) {
        //     estateStore.changeFilter('showExportedOnly', true);
        // }
        this.orderBy = 'updateTime';
        this.orderDirection = 'descending';
        this.changeFilter('base', base);
        if ([ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL].includes(base)) {
            this.changeFilter('deltaTime', 50 * 86400);
            this.changeFilter('hasBonusToAgent', null);
        } else if (ESTATE_BASE_GROUP === base) {
            this.changeFilter('base', ESTATE_BASE_MAIN);
            this.changeFilter('major_user_id', []);
            this.changeFilter('group_id', [authStore.currentUser.group_id]);
        }
    };

    changeFilterBaseWithoutFetch = (base: ESTATE_BASES | 'group') => {
        this.resetPagination();
        this.orderBy = 'updateTime';
        this.orderDirection = 'descending';

        this.listFilter = deepCopy({ ...this.listFilterClear, base });
        this.filterHasChanged = null;
    };

    @nProgressItem
    @action
    async duplicateEstate(estate_id: number): Promise<number> {
        return await estateApi.duplicateEstate(estate_id);
    }

    @action
    async favoriteEstate(estate_id: number, base: ESTATE_BASES, isFavorite: boolean) {
        try {
            this.mergeItem(estate_id, { isFavorite });
        } catch {}
        this.mergeList(estate_id, { isFavorite });
        await estateApi.toggleFavoriteEstate(estate_id, base, isFavorite);
    }

    @catchOnlyError
    @action
    async hideEstate(estate_id: number, base: ESTATE_BASES, isHidden: boolean) {
        this.mergeItem(estate_id, { isHidden });
        await estateApi.toggleHideEstate(estate_id, base, isHidden);
    }

    @action async leaveEstate(estate_id: number, clearAllUsers: boolean) {
        const estate = this.getItem(estate_id).item;

        if (estate) {
            const { contact_id } = estate;
            if (contact_id) {
                await contactStore.fetchItem(contact_id);

                let major_user_ids = [];

                if (!clearAllUsers) {
                    const contact = contactStore.getItem(contact_id).item;
                    if (contact) {
                        major_user_ids = contact.major_user_ids.filter(user_id => user_id !== authStore.currentUser.user_id);
                    }
                }

                contactStore.setEditingItem(contact_id, { major_user_ids });
                await exportStore.disableExport(estate_id);
                await contactStore.saveItem(contact_id);
                this.fetchItem(estate_id);
            }
        }
    }

    @nProgressItem
    @action
    async takeEstateToWork(estate_id: number) {
        await estateApi.takeEstateToWork(estate_id);
        this.fetchItem(estate_id);
    }

    @computed get filterIsEmpty(): boolean {
        const withoutFiles: (keyof EstateFilter)[] = ['base', 'type', 'deltaTime'];
        return isEqualWithoutFields(this.listFilter, this.listFilterClear, withoutFiles);
    }

    @action
    setWishFilterFromEstate(estate_id: number, approximation: number) {
        const {
            item,
            property: { wishesLocationType }
        } = this.getItem(estate_id);

        if (item) {
            wishStore.resetPagination();
            wishStore.listFilter = deepCopy(adjustEstateFilter(item, approximation, wishesLocationType));
            wishStore.debounceFilterFetch();
        }
    }

    setFilterProblemEstatesUserId(user_id: number) {
        const { lastOutgoingCallTimesBan } = settingStore.mainConfig;

        this.listFilter = {
            ...this.listFilterClear,
            base: ESTATE_BASE_MAIN,
            showExportedOnly: true,
            noOutgoingToOwnerCallsTime: lastOutgoingCallTimesBan * 24 * 3600,
            major_user_id: [user_id]
        };
    }

    setFilterExportErrorsEstatesUserId(user_id: number) {
        this.listFilter = {
            ...this.listFilterClear,
            base: ESTATE_BASE_MAIN,
            showExportedOnly: true,
            showExportingErrorsOnly: true,
            major_user_id: [user_id]
        };
    }

    setFilterMapUserId(user_id: number) {
        this.toggleSearchOnMap();
        this.listFilter = {
            ...this.listFilterClear,
            base: ESTATE_BASE_MAIN,
            major_user_id: [user_id]
        };
    }

    @computed get filterRegions(): DropdownType[] {
        const regionsInFilter = [0, 100, 77, 50, 78, 47, 22, 16, 2, 34, 35, 44, 76, 25];
        return regionsInFilter.reduce((acc, region_id) => {
            let { title, type } = exportingBaseStore.regionsById.get(region_id) || {};
            if (region_id === 0) {
                title = 'Все регионы';
            }
            if (title) {
                if (!['область', 'край'].includes(type)) {
                    type = '';
                }
                acc.push({ key: region_id, value: region_id, text: `${title} ${type}`.trim() });
            }
            return acc;
        }, []);
    }

    @nProgressItem
    @action
    async changeCallTransactionPrice(estate_id: number, call_id: number, price: number) {
        this.setProperty(estate_id, { loadingExportHistory: true });
        await exportsApi.changeCallTransactionPrice(call_id, price);
        await this.fetchEstateExportHistory(estate_id);
    }

    async fetchSameEstates(estate_id: number, base: ESTATE_BASES, filter: TSameEstatesFilter) {
        this.setProperty(estate_id, { loadingSameEstates: true, sameEstatesErrors: [] });
        try {
            const { list, count } = await estateApi.fetchSameEstates(estate_id, base, filter);
            this.setProperty(estate_id, { sameEstates: list, loadingSameEstates: false });
        } catch (errors) {
            this.setProperty(estate_id, { sameEstatesErrors: errors, loadingSameEstates: false });
        }
    }

    @action
    async fetchOwnFixedPriceEstates(estate_id): Promise<void> {
        this.setProperty(estate_id, { loadingOwnMlsPrice: true });
        try {
            const ownFixedMlsPrice = await estateApi.fetchOwnFixedPriceEstates(estate_id);
            this.setProperty(estate_id, { ownFixedMlsPrice });
        } catch (error) {}
        this.setProperty(estate_id, { loadingOwnMlsPrice: false });
    }
}

export default new EstateStore();
