import React, { PureComponent, Fragment } from 'react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { RequestLinkState } from '../../Lists/Requests/RequestLink';
import requestStore from '~/stores/requestStore';

class RequestTabMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(requestStore, RequestLinkState, RequestTabMenu, {
    callEventModal: true
});
