import React, { useState, useRef } from 'react';
import { Button, Icon, Input, Modal, Accordion, Image, List, Form, Message } from 'semantic-ui-react';
import serverConfig from '../../config/server.config';
import authStore from '~/stores/authStore';
import { SuccessMessage } from '~ui/Message';

const CalendarIOSSettings = () => {
    const [activeIndex, setIndex] = useState(0);
    const [hasCopied, copy] = useState(false);
    const inputEl = useRef(null);

    const onCopyClick = () => {
        inputEl.current.focus();
        inputEl.current.select();
        document.execCommand('copy');
        copy(true);
        setTimeout(() => copy(false), 1500);
    };

    return (
        <Modal closeIcon trigger={<Button color="blue" icon="apple" />} size="fullscreen" dimmer="inverted">
            <Modal.Header>Экспорт календаря в iPhone</Modal.Header>
            <Modal.Content>
                {hasCopied && <SuccessMessage>Скопировано!</SuccessMessage>}
                <Form>
                    <Form.Field>
                        <label>ics-ссылка на ваш календарь</label>
                        <Input
                            type="url"
                            action={{
                                color: 'teal',
                                labelPosition: 'right',
                                content: 'Скопировать',
                                icon: 'copy',
                                onClick: onCopyClick
                            }}
                            ref={inputEl}
                            value={`${serverConfig.serverUrl}/calendar/ics?key=${authStore.currentUser.icsCalendarKey || ''}`}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Content>
                <Accordion>
                    <Accordion.Title active={activeIndex === 0} index={0} onClick={() => setIndex(activeIndex === 0 ? 1 : 0)}>
                        <Icon name="dropdown" />
                        Шаг 1
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <List bulleted>
                            <List.Item>
                                Скопируйте <b>ics-ссылку</b> на ваш календарь
                            </List.Item>
                        </List>
                    </Accordion.Content>
                    <Accordion.Title active={activeIndex === 1} onClick={() => setIndex(activeIndex === 1 ? 2 : 1)}>
                        <Icon name="dropdown" />
                        Шаг 2
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <List bulleted>
                            <List.Item>
                                Выберите в настройках <b>Пароли и учетные записи</b>
                            </List.Item>
                            <List.Item>
                                Далее <b>Новая учетная запись</b>
                            </List.Item>
                        </List>
                        <Image width="100%" src={'/static/calendar/iphone/step1.jpg'} />
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 2} onClick={() => setIndex(activeIndex === 2 ? 0 : 2)}>
                        <Icon name="dropdown" />
                        Шаг 3
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <List bulleted>
                            <List.Item>
                                Выберите <b>Другое</b>
                            </List.Item>
                            <List.Item>
                                <b>Подписной календарь</b>
                            </List.Item>
                            <List.Item>
                                В поле <b>Сервер</b> скопируйте <b>ics-ссылку</b> на ваш календарь
                            </List.Item>
                            <List.Item>
                                Нажмите <b>Далее</b>
                            </List.Item>
                        </List>
                        <Image width="100%" src={'/static/calendar/iphone/step2.jpg'} />
                    </Accordion.Content>
                </Accordion>
            </Modal.Content>
        </Modal>
    );
};

export default CalendarIOSSettings;
