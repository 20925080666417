import { observable, action } from 'mobx';
import * as shareApi from '~/api/shareApi';
import { Estate, ShareEstateId } from '~/types/estate.types';

import ListStorePrototype from './prototypes/ListStore.prototype';
import { EstateItemTypeProperty } from './helpers/estate.store.helpers';
import estateStore from './estateStore';

export type ShareFilter = { uuid: string | null };

class ShareStore extends ListStorePrototype<Estate, Estate, EstateItemTypeProperty, ShareFilter> {
    orderBy = 'updateTime';
    listFilterClear = { uuid: null };

    constructor() {
        super('estate_id', 'share', shareApi);
    }

    @observable
    loadingSharing: boolean = false;

    @action
    async fetchItem(estate_id: number) {
        const estate = this.findById(estate_id);

        this.setItem(estate_id, {
            errors: [],
            loadingItem: false,
            property: {},
            history: {},
            editingItem: {},
            item: estate
        });
    }

    @action
    shareEstateIds = async (): Promise<string> => {
        this.loadingSharing = true;
        try {
            return await shareApi.createShareEstates([...estateStore.selectedItemsIds]);
        } catch (errors) {
            this.listErrors = errors;
        } finally {
            this.loadingSharing = false;
        }
    };

    @observable
    showingEstateId: number | null = null;

    @action
    showEstateId = (estate_id: number | null) => {
        this.showingEstateId = estate_id;
    };
}

export default new ShareStore();
