import React, { useState } from 'react';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Skeleton from '@material-ui/lab/Skeleton';

import UserAvatar from '~/components/Base/ui/UserAvatar';

import authStore from '~/stores/authStore';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import contactStore from '~/stores/contactStore';
import chatStore from '~/stores/chatStore';

import UserDropdown from '~/components/ItemsDropdowns/UserDropdown';
import history from '~/history';
import { UserLinkState } from '~/components/Lists/Users/UserLink';
import Button from '~ui/Button';

const ChatListUser = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    let { user_id } = chatStore.listFilter;
    const handleChangeUserId = (user_id: number) => {
        chatStore.changeFilter('user_id', user_id);
        toggleDropdown();
    };

    const { firstName, lastName, avatarUrl } = user_id ? authStore.findUserById(user_id) : { firstName: '', lastName: '', avatarUrl: '' };
    const currentUserName = `${firstName} ${lastName}`.trim();

    const groups_id =
        authStore.getPermission(contactStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP ? [authStore.currentUser.group_id] : [];
    const canChangeUserId =
        user_id && [ACCESS_GROUP, ACCESS_COMPANY].includes(authStore.getPermission(contactStore.moduleName, ACCESS_WRITE));

    return (
        <div className="crm-Chat__sidenav_header">
            {!showDropdown && (
                <div className="crm-Chat__user_hd">
                    <div className="crm-Chat__avatar">
                        <Button
                            tooltip="Открыть карточку"
                            onClick={() => history.push(UserLinkState(user_id))}
                            style={{ padding: 0, margin: 0 }}
                        >
                            <UserAvatar
                                firstName={firstName}
                                lastName={lastName}
                                src={avatarUrl}
                                size={50}
                                showStatus={user_id === authStore.currentUser.user_id}
                                isOnline
                            />
                        </Button>
                    </div>

                    <div className="crm-Chat__userInfo">
                        <h5 className="crm-Chat__userInfo_title">{currentUserName}</h5>
                        {!user_id && <Skeleton />}
                    </div>

                    {canChangeUserId && (
                        <IconButton onClick={toggleDropdown}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    )}
                </div>
            )}
            {showDropdown && <UserDropdown user_id={user_id} onChange={handleChangeUserId} groups_id={groups_id} disableClearable />}
        </div>
    );
};

export default observer(ChatListUser);
