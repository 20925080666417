import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import {
    UserStatistics,
    UserStatisticsTable,
    UserStatisticsFilter,
    ChartStatistics,
    STAT_CHART_TYPES,
    ChartGlobalStatistics,
    UserKpi
} from '~/types/statistics.types';
import { GQUserMainFields } from './userApi';
import { CALENDAR_VIEW_TYPES } from '~/types/calendar.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { KpiGQLFields, KpiGQStatMainFields } from '~/api/kpiApi';

const GQStatisticsEventFields = `
    dailyCalls
    weekCalls
    problemEstatesCount
    exportEstateErrorsCount
    avitoDailyCalls
    
    kpis {
        kpi {
            ${KpiGQLFields}
        }
        statistics {
            ${KpiGQStatMainFields}
        }   
    }
`;

export const fetchItem = async (user_id: number): Promise<UserStatistics> => {
    const graphql = `{
        fetchStatistics(user_id: ${user_id}){
            ${GQStatisticsEventFields}
        } 
      }`;

    const data: { fetchStatistics: UserStatistics } = await fetchApi.getGQ(graphql);
    return data.fetchStatistics;
};

const GQStatisticsTableFields = `
    ${GQStatisticsEventFields}
    weekShowings
    weekMeetingEvents
    exclusives
    cianCount
    weekCianCreated
    weekCallsToEstate
    weekSmaShares
    
    major_user {
        ${GQUserMainFields}
    }
    major_user_id
`;

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    statFilter: UserStatisticsFilter,
    controller?: AbortController
): Promise<{ list: UserStatisticsTable[]; count: number }> => {
    const graphql = `{
        fetchStatisticsList(
            major_user_id: ${objectToGraphql(statFilter.major_user_id)}, 
            group_id: ${objectToGraphql(statFilter.group_id)}, 
            orderBy: "${orderBy}", sort: "${orderDirection}") 
        {
            list {
                ${GQStatisticsTableFields}
            }
            count
        } 
      }`;

    const data: { fetchStatisticsList: { list: UserStatisticsTable[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchStatisticsList;
};

export const fetchChartStatistics = async (user_id: number, chartType: STAT_CHART_TYPES): Promise<ChartStatistics[]> => {
    const graphql = `{
        fetchChartStatistics(user_id: ${user_id}, type: ${chartType}) {
            date
            count   
        } 
      }`;

    const data: { fetchChartStatistics: ChartStatistics[] } = await fetchApi.getGQ(graphql);
    return data.fetchChartStatistics;
};

export const fetchGlobalChartStatistics = async (
    period: CALENDAR_VIEW_TYPES,
    group_id: number[],
    major_user_id: number[],
    controller: AbortController
): Promise<ChartGlobalStatistics[]> => {
    const graphql = `{
        fetchGlobalChartStatistics(period: ${period}, 
                group_id: ${objectToGraphql(group_id)}, 
                major_user_id: ${objectToGraphql(major_user_id)}
        ) {
            date
            cianCount   
            cianAddedCount
            callsOutgoingCount
            callsIncomingCount
            openDealsCount
            callsToEstateCount
            showingsCount
            usersCount
        } 
      }`;

    const data: { fetchGlobalChartStatistics: ChartGlobalStatistics[] } = await fetchApi.getGQ(graphql, controller);
    return data.fetchGlobalChartStatistics;
};

export const fetchGlobalKpi = async (major_user_id: number[], group_id: number[]): Promise<UserKpi[]> => {
    const graphql = `{
        fetchGlobalKpi(
            major_user_id: ${objectToGraphql(major_user_id)},
            group_id: ${objectToGraphql(group_id)}
        ) {
            major_user {
                ${GQUserMainFields}
            }
            major_user_id
            
            exclusives
            cianCount
            openDeals
            closeDeals
            dealsAmount
            userCreateTime
            monthCalls
            monthShowings
            monthCallsToEstate
            kpi
        } 
      }`;

    const data: { fetchGlobalKpi: UserKpi[] } = await fetchApi.getGQ(graphql);
    return data.fetchGlobalKpi;
};
