import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import LifeComplexApartmentsFilter from './LifeComplexApartmentsFilter';
import LifeComplexApartmentsTable from './LifeComplexApartmentsTable';

const LifeComplexApartmentsMode = ({ newbuilding_id }: { newbuilding_id: number }) => {
    useMemo(() => {
        lifeComplexStore.resetApartmentsFilter(newbuilding_id);
        lifeComplexStore.fetchApartments(newbuilding_id);
    }, [newbuilding_id]);

    return (
        <>
            <LifeComplexApartmentsFilter newbuilding_id={newbuilding_id} />
            <LifeComplexApartmentsTable newbuilding_id={newbuilding_id} />
        </>
    );
};

export default observer(LifeComplexApartmentsMode);
