import React from 'react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';

const exportingBaseKeys = {};

const printExportingBaselVal = (key: string, value: unknown): string => {
    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(exportingBaseStore, exportingBaseKeys, printExportingBaselVal);
