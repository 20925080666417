import React, { Fragment, useState, useCallback } from 'react';

import { observer } from 'mobx-react';
import Table, { TableHead, TableRow, TableCell, TableBody } from '~ui/Table';
import Progress from '../Base/ui/Progress';
import Chip from '@material-ui/core/Chip';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import userStore from '~/stores/userStore';
import GroupModal from './GroupModal';
import authStore from '~/stores/authStore';
import UserLinkWithPreview from '../Lists/Users/UserLinkWithPreview';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ContainerHeader from '~ui/ContainerHeader';

const Groups = () => {
    const [showGroupId, setGroupId] = useState(-1);
    const handleGroupModal = (group_id: number) => {
        setGroupId(group_id);
    };

    const { groups, loadingGroups } = userStore;
    const { loadingRegions } = exportingBaseStore;

    const canEdit = authStore.canEdit('groups', []);
    const handleCreate = useCallback(() => handleGroupModal(CREATING_ITEM_ID), []);

    return (
        <Container maxWidth="lg" fixed>
            <ContainerHeader
                handleCreate={handleCreate}
                title="Офисы / Отделы"
                enableCreating
                icon={<AccountTreeIcon fontSize="large" />}
            />

            <Table maxHeight="calc(100vh - 150px)">
                <TableHead>
                    <TableRow>
                        <TableCell>Отдел</TableCell>
                        <TableCell>Руководитель</TableCell>
                        <TableCell>Регион / Агенты</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map(group => {
                        const regionTitle =
                            !loadingRegions &&
                            group.region_id &&
                            exportingBaseStore.regionsById.get(group.region_id) &&
                            exportingBaseStore.regionsById.get(group.region_id).title;

                        return (
                            <Fragment key={`group_${group.group_id}`}>
                                <TableRow>
                                    <TableCell>
                                        <Chip
                                            variant="outlined"
                                            color="secondary"
                                            label={group.name}
                                            onClick={canEdit ? () => handleGroupModal(group.group_id) : null}
                                        />
                                    </TableCell>
                                    <TableCell>{group.chief && <UserLinkWithPreview icon user={group.chief} />}</TableCell>
                                    <TableCell>
                                        {loadingRegions && <Progress show={loadingRegions} size={24} />}
                                        {regionTitle || ''}
                                    </TableCell>
                                </TableRow>
                                {group.subgroups.map(subgroup => (
                                    <TableRow key={`subgroup_${subgroup.group_id}`}>
                                        <TableCell>
                                            {canEdit && (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => handleGroupModal(subgroup.group_id)}
                                                    tabIndex={0}
                                                >
                                                    {subgroup.name}
                                                </Link>
                                            )}
                                            {!canEdit && subgroup.name}
                                        </TableCell>

                                        <TableCell>
                                            {subgroup.chief && <UserLinkWithPreview icon user={subgroup.chief} />}
                                            {!subgroup.chief && <span>---</span>}
                                        </TableCell>

                                        <TableCell>{subgroup.usersCount}</TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>

            {showGroupId > -1 && <GroupModal group_id={showGroupId} onClose={() => setGroupId(-1)} />}

            <Progress show={loadingGroups} />
        </Container>
    );
};

export default observer(Groups);
