import React, { useMemo, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PhoneIcon from '@material-ui/icons/Phone';

import Select from '~ui/Select';
import Progress from '~ui/Progress';
import TextInput from '~ui/TextInput';
import sourcePhoneStore from '~/stores/cell/sourcePhoneStore';
import Grid from '~ui/Grid';
import TagsSelect from '~/components/Base/TagsSelect';
import callSourceStore from '~/stores/cell/callSourceStore';
import { checkFormValid } from '~/common/forms';
const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const SourcePhoneModal = ({ source_phone_id, onClose }: { source_phone_id: number; onClose: () => void }) => {
    const formRef = useRef<HTMLFormElement>(null);

    useMemo(() => {
        sourcePhoneStore.fetchItem(source_phone_id);

        if (source_phone_id === CREATING_ITEM_ID) {
            sourcePhoneStore.setEditingItem(source_phone_id, { phone: '', source_id: null, title: '', tags_ids: [] });
        }
    }, []);

    const handleChange = useCallback((event, { name, value }: { name: string; value: unknown }) => {
        sourcePhoneStore.setEditingItem(source_phone_id, {
            [name]: value
        });
    }, []);

    const handleChangeTags = useCallback((tags_ids: number[]) => {
        sourcePhoneStore.setEditingItem(source_phone_id, {
            tags_ids
        });
    }, []);

    const commitChanges = (source_phone_id: number) => {
        const { errors } = sourcePhoneStore.getItem(source_phone_id);
        if (!errors.length) {
            sourcePhoneStore.fetchList();
            onClose();
        }
    };

    const handleSave = async () => {
        if (checkFormValid(formRef.current, true)) {
            if (source_phone_id === CREATING_ITEM_ID) {
                await sourcePhoneStore.createItem();
            } else {
                await sourcePhoneStore.saveItem(source_phone_id);
            }

            commitChanges(source_phone_id);
        }
    };

    const handleDelete = async () => {
        sourcePhoneStore.setEditingItem(source_phone_id, {
            enable: false
        });
        await sourcePhoneStore.saveItem(source_phone_id);

        commitChanges(source_phone_id);
    };

    const { editingItem, loadingItem, errors } = sourcePhoneStore.getItem(source_phone_id);
    const { phone, source_id, title, tags_ids } = editingItem;

    const handleChangeSource = (event, { value }: { value: number }) => {
        handleChange(event, { name: 'source_id', value });
        const { title } = callSourceStore.findById(value);
        handleChange(event, { value: title, name: 'title' });
    };

    const handleChangePhone = (event, { value }: { value: string }) => {
        handleChange(event, { name: 'phone', value: value.replace(/\D/gi, '') });
    };

    const modalActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleSave,
            label: source_phone_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            disabled: loadingItem,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (source_phone_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            disabled: loadingItem,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    const { allCallSourcesDropdown, loadingFetchAllSources } = callSourceStore;

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            loading={loadingItem}
            header={
                <>
                    <HeadsetMicIcon /> &nbsp; Телефон-источник
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                <form ref={formRef}>
                    <Grid stackable>
                        <Grid.Column width={12}>
                            <TextInput
                                value={phone}
                                name="phone"
                                onChange={handleChangePhone}
                                mask="+7 (000) 000-00-00"
                                startLabel={<PhoneIcon />}
                                pattern={phoneRegExp}
                                label="Телефон, подключенный к телефонии"
                                required
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Select
                                onChange={handleChangeSource}
                                label="Источник"
                                loading={loadingFetchAllSources}
                                name="source_id"
                                value={source_id}
                                options={allCallSourcesDropdown}
                                fullWidth
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <TextInput value={title} name="title" onChange={handleChange} minLength={2} required label="Имя, Заголовок" />
                        </Grid.Column>
                        {!loadingItem && tags_ids && (
                            <Grid.Column>
                                <TagsSelect setTagIds={handleChangeTags} tags_ids={tags_ids} />
                            </Grid.Column>
                        )}
                    </Grid>
                </form>
                {loadingItem && <Progress show />}
            </ModalContent>
        </Modal>
    );
};

export default observer(SourcePhoneModal);
