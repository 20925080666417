import React, { Component, Fragment } from 'react';
import { ComponentType } from 'react';
import { Swipeable } from 'react-swipeable';

import EditButton from '../Base/EditButton';
import CloseButton from '../Base/CloseButton';
import { Form, Ref } from 'semantic-ui-react';
import { checkFormValid } from '../../common/forms';
import SaveButton from '../Base/SaveButton';
import './item.less';
import authStore from '~/stores/authStore';
import { observer } from 'mobx-react';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import history from '../../history';
import { ErrorMessage, MessageTitle } from '~ui/Message';
import * as crmChangesApi from '~/api/crmChangesApi';

export type EditingWrappedProps = {
    item_id: number;
    editing?: boolean;
};

export type EditingBlockWrapperClassState = {
    editing: boolean;
    isError: boolean;
    editingId: symbol;
};

type EditingBlockWrapperConfig = Partial<{
    disableSwapping: boolean;
}>;

const EditingBlockWrapper = <TProps extends EditingWrappedProps>(
    store: ItemStoreInterface<any>,
    WrappedComponent: ComponentType<EditingWrappedProps & { [property: string]: any }>,
    onSave?: (index: number) => void,
    config: EditingBlockWrapperConfig = {}
) => {
    @observer
    class EditingBlockWrapperClass extends Component<TProps, EditingBlockWrapperClassState> {
        constructor(props: TProps) {
            super(props);

            this.state = {
                editing: false,
                isError: false,
                editingId: Symbol()
            };
        }

        toggleEditMode = (evt?: React.SyntheticEvent) => {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }

            // Даем время отработать getDerivedStateFromProps и очистить предыдущий блок после его закрытия
            setTimeout(() => {
                store.setEditingBlockId(this.props.item_id, this.state.editingId);
                this.setState({
                    editing: !this.state.editing
                });
            }, 0);
        };

        $form: HTMLElement | null = null;

        handleSave = () => {
            const { item_id } = this.props;

            if (this.$form && checkFormValid(this.$form, false)) {
                if (typeof onSave === 'function') {
                    onSave(item_id);
                } else {
                    store.saveItem(item_id);
                }

                this.setState({
                    editing: false
                });
            }
        };

        checkAccess = (): boolean => {
            const { item } = store.getItemForAccess(this.props.item_id);

            // const owner_id = item.major_user ? item.major_user.user_id : item.user_id;
            // return authStore.canEdit(store.moduleName, owner_id);
            const owner_ids: number[] = item
                ? item.major_user_ids && item.major_user_ids.length > 0
                    ? Array.from(item.major_user_ids)
                    : [item.major_user_id || -1]
                : [-1];

            return owner_ids.some(owner_id => authStore.canEdit(store.moduleName, owner_id));
        };

        componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
            console.log('ItemWrapper', error);
            console.log('errorInfo', errorInfo);
            crmChangesApi.sendUiError(error, errorInfo, 'EditingBlockWrapper');
            this.setState({ isError: true });
        }

        static getDerivedStateFromProps(nextProps: TProps, prevState: EditingBlockWrapperClassState) {
            let loadingItem;
            let editingBlockId;
            try {
                const item = store.getItem(nextProps.item_id);
                loadingItem = item.loadingItem;
                editingBlockId = item.editingBlockId;
            } catch (error) {
                loadingItem = false;
            }

            // Закрываем редактирование, если карточка перезагружается
            let editing = prevState.editing && loadingItem ? false : prevState.editing;

            // Закрываем редактирование, если открылся другой блок
            if (editing && editingBlockId !== prevState.editingId) {
                store.clearEditingItemOnly(nextProps.item_id);
                editing = false;
            }

            return {
                ...prevState,
                editing
            };
        }

        onSwipeLeft = () => {
            if (!config.disableSwapping) {
                history.goBack();
            }
        };

        render() {
            if (this.state.isError) {
                return (
                    <ErrorMessage>
                        <MessageTitle>Произошла ошибка в CRM</MessageTitle>
                        <p>Попробуйте закрыть эту карточку</p>
                    </ErrorMessage>
                );
            }

            let loadingItem;
            try {
                const item = store.getItem(this.props.item_id);
                loadingItem = item.loadingItem;
                // for mobx reaction:
                const { editingBlockId } = item;
            } catch (error) {
                loadingItem = false;
            }

            // Возникала ошибка, если нажать кнопку обновить карточку, а какой-то элемент был в состояние редактирования:
            const editing = !loadingItem ? this.state.editing : false;

            return (
                <div className={`crm-Item ${this.checkAccess() ? 'crm-FluentButtons' : ''}`}>
                    <Ref innerRef={$form => (this.$form = $form)}>
                        <Form>
                            {editing ? (
                                <Fragment>
                                    <SaveButton onClick={this.handleSave} />
                                    <CloseButton onClick={this.toggleEditMode} />
                                </Fragment>
                            ) : (
                                <EditButton onClick={this.toggleEditMode} />
                            )}

                            <Swipeable onSwipedLeft={this.onSwipeLeft} delta={200}>
                                <WrappedComponent
                                    handleSave={this.handleSave}
                                    toggleEditMode={this.toggleEditMode}
                                    editing={editing}
                                    {...this.props}
                                />
                            </Swipeable>
                        </Form>
                    </Ref>
                </div>
            );
        }
    }

    return EditingBlockWrapperClass;
};

export default EditingBlockWrapper;
