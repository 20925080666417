import { observable, action, runInAction } from 'mobx';
import * as cellOperatorsApi from '~/api/cell/cellOperatorsApi';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import deepCopy from '../../common/deepCopy';
import { CellOperatorType } from '~/types/cellCalls.types';
import settingStore from '~/stores/settingStore';

class CellOperatorStore extends ListStorePrototype<CellOperatorType> {
    constructor() {
        super('cell_operator_id', settingStore.moduleName, cellOperatorsApi);
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const { item } = this.getItem(id);
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }
}

export default new CellOperatorStore();
