import React, { Component, Fragment } from 'react';
import { Grid, Form, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ButtonGroup from '~/components/Base/ButtonGroup';
import Checkbox from '~ui/Checkbox';

import estateStore from '~/stores/estateStore';
import EditButton from '~/components/Base/EditButton';
import CloseButton from '~/components/Base/CloseButton';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import PropertyTypeEditing from './SubBlocks/PropertyTypeEditing';
import {
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_COTTAGE,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_HOUSE,
    ESTATE_PROPERTY_TYPE_HOUSE_PART,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_PROPERTY_TYPE_TOWNHOUSE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_BUILDING,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_TYPE_SELL_ID,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_PROPERTY_TYPE_INDUSTRY
} from '~/types/estate.types';

type TypeBlockEditingProps = HandlerEditingWrappedProps & {
    editing: boolean;
    type?: number;
    propertyType?: number;
};

const printType = (type: number, propertyType: number): string => {
    let what = '';
    switch (propertyType) {
        case ESTATE_PROPERTY_TYPE_FLAT:
            what = 'квартиры';
            break;
        case ESTATE_PROPERTY_TYPE_NEWBUILDING:
            what = 'квартиры в новостройке';
            break;
        case ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION:
            what = 'переуступки в новостройке';
            break;
        case ESTATE_PROPERTY_TYPE_ROOM:
            what = 'комнаты';
            break;
        case ESTATE_PROPERTY_TYPE_PART:
            what = 'доли в квартире';
            break;

        case ESTATE_PROPERTY_TYPE_HOUSE:
            what = 'дома/дачи';
            break;
        case ESTATE_PROPERTY_TYPE_COTTAGE:
            what = 'коттеджа';
            break;
        case ESTATE_PROPERTY_TYPE_TOWNHOUSE:
            what = 'таунхауса';
            break;
        case ESTATE_PROPERTY_TYPE_HOUSE_PART:
            what = 'части дома';
            break;
        case ESTATE_PROPERTY_TYPE_LAND:
            what = 'участка';
            break;

        case ESTATE_PROPERTY_TYPE_OFFICE:
            what = 'офиса';
            break;
        case ESTATE_PROPERTY_TYPE_FREE_PURPOSE:
            what = 'помещения свободного назначения';
            break;
        case ESTATE_PROPERTY_TYPE_COMMERCE_LAND:
            what = 'коммерческой земли';
            break;
        case ESTATE_PROPERTY_TYPE_WAREHOUSE:
            what = 'склада';
            break;
        case ESTATE_PROPERTY_TYPE_INDUSTRY:
            what = 'производства';
            break;
        case ESTATE_PROPERTY_TYPE_SHOPPING:
            what = 'торгового помещения';
            break;
        case ESTATE_PROPERTY_TYPE_BUSINESS:
            what = 'готового бизнеса';
            break;
        case ESTATE_PROPERTY_TYPE_BUILDING:
            what = 'здания';
            break;

        case ESTATE_PROPERTY_TYPE_GARAGE:
            what = 'гаража';
            break;
        case ESTATE_PROPERTY_TYPE_GARAGE_BOX:
            what = 'бокса';
            break;
        case ESTATE_PROPERTY_TYPE_CAR_PLACEMENT:
            what = 'машиноместа';
            break;

        default:
            return '';
    }
    return (type === ESTATE_TYPE_SELL_ID ? 'Продажа' : 'Аренда') + ' ' + what;
};

export type TypeBlockState = {
    editing: boolean;
    estateType: number;
};

@observer
class TypeBlockEditing extends Component<TypeBlockEditingProps, TypeBlockState> {
    state = {
        editing: false,
        estateType: 1
    };

    constructor(props: TypeBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            type: props.type || ESTATE_TYPE_SELL_ID,
            propertyType: props.propertyType || 0
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    handleEditing = () => {
        this.setState({
            editing: !this.state.editing
        });
    };

    handleEstateType = (estateType: number) => {
        this.setState({ estateType });
    };

    handleChangeLiveDraft = () => {
        estateStore.toogleLiveDraft();
    };

    render() {
        const { editing, item_id, handleChange } = this.props;
        const { estateType } = this.state;
        const { type, propertyType } = estateStore.getItem(item_id).editingItem;

        return editing || this.state.editing ? (
            <div className="crm-FluentButtons" style={this.state.editing ? { background: '#f5f6fa' } : null}>
                {this.state.editing && <CloseButton onClick={this.handleEditing} />}
                <h3>Тип объявления</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <ButtonGroup
                                size="medium"
                                buttonSet={[
                                    [1, 'Продажа'],
                                    [2, 'Аренда']
                                ]}
                                name="type"
                                value={type}
                                handleChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <ButtonGroup
                                value={estateType}
                                name="estateType"
                                buttonSet={[
                                    [1, 'Жилая'],
                                    [2, 'Коммерческая']
                                ]}
                                size="medium"
                                handleChange={(event, { value }: { value: number }) => this.handleEstateType(value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer={4} tablet={6} mobile={16}>
                            <Form.Field>
                                <label className="crm-Estate__field_label">Объект</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer={12} tablet={10} mobile={16}>
                            <PropertyTypeEditing item_id={item_id} propertyType={propertyType} estateType={estateType} type={type} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        ) : (
            <div className="crm-FluentButtons">
                <EditButton onClick={this.handleEditing} />
                <Message color="green" header="Тип объявления" content={printType(type, propertyType)} style={{ marginTop: '0' }} />
                <div className="crm-Item__liveDraft">
                    <Checkbox checked={estateStore.liveDraft} label="Живой черновик" onChange={this.handleChangeLiveDraft} />
                </div>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, TypeBlockEditing);
