import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Loader, Table, Menu, SemanticCOLORS } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ShowingsTableBody from '../../Lists/Showings/ShowingsTableBody';
import estateStore from '~/stores/estateStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { WarningMessage } from '~ui/Message';

type EstateReportsModeProps = {
    estate_id: number;
};

type PULT_MENU_ITEM = { name: string; enable: boolean; color: SemanticCOLORS | null };

const pultMenu: PULT_MENU_ITEM[] = [
    { name: 'Все', enable: true, color: null },
    { name: 'Архив', enable: false, color: 'red' }
];

const DEFAULT_ENABLE_MODE = true;

@observer
class EstateReportsMode extends Component<EstateReportsModeProps, { enableMode: boolean }> {
    constructor(props: EstateReportsModeProps) {
        super(props);

        this.state = {
            enableMode: DEFAULT_ENABLE_MODE
        };

        estateStore.showingsByEstate(props.estate_id, DEFAULT_ENABLE_MODE);
    }

    toggleEnableMode = () => {
        const { enableMode } = this.state;

        this.setState({
            enableMode: !enableMode
        });

        estateStore.showingsByEstate(this.props.estate_id, !enableMode);
    };

    render() {
        const { estate_id } = this.props;
        const { enableMode } = this.state;

        const { showings, loadingShowings } = estateStore.getItem(estate_id).property;

        return (
            <div>
                <Menu pointing secondary stackable className="crm-List__filter">
                    <Menu.Menu position="right">
                        {pultMenu.map(menu => (
                            <Menu.Item
                                key={`menu_${menu.enable.toString()}`}
                                name={menu.name}
                                color={menu.color}
                                active={enableMode === menu.enable}
                                onClick={this.toggleEnableMode}
                            />
                        ))}
                    </Menu.Menu>
                </Menu>

                {showings && showings.length > 0 && (
                    <Table structured size="small" compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Дата" />
                                <Table.HeaderCell content="Сотрудник" />
                                <Table.HeaderCell content="Клиент" />
                            </Table.Row>
                        </Table.Header>

                        <ShowingsTableBody hideEstate={true} showingsList={showings} />
                    </Table>
                )}
                {showings && showings.length === 0 && <WarningMessage>У данного объекта нет показов</WarningMessage>}

                <Button
                    as={Link}
                    to={{
                        pathname: `/showings/list/overview/${CREATING_ITEM_ID}`,
                        state: {
                            modal: true,
                            from: { storeName: 'estateStore', item_id: estate_id },
                            callback: { storeName: 'estateStore', method: 'showingsByEstate', item_id: estate_id }
                        }
                    }}
                    content="Добавить показ"
                    size="tiny"
                    color="green"
                />

                {loadingShowings && <Loader active size="large" />}
            </div>
        );
    }
}

export default EstateReportsMode;
