import React, { PureComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import EstateInfoBlock from './ShowBlocks/EstateInfoBlock';
import PriceBlockShow from './ShowBlocks/PriceBlockShow';
import PreventEditing from '../../Base/PreventEditing';
import Comments, { AdditionalComments } from '../Common/Comments';

import OwnerAssignButtonBlock from './OwnersBlocks/OwnerAssignButtonBlock';

type EstateOwnersModeProps = {
    estate_id: number;
    enable: boolean;
    contact_id?: number | null;
};

class EstateOwnersMode extends PureComponent<EstateOwnersModeProps> {
    render() {
        const { estate_id, contact_id } = this.props;

        const multiTypes: AdditionalComments[] = Number(contact_id)
            ? [
                  {
                      item_id: Number(contact_id),
                      item_type: 'CONTACT',
                      title: 'С карточки контакта'
                  }
              ]
            : [];

        return (
            <PreventEditing>
                <Grid stackable reversed="mobile">
                    <Grid.Column width={8}>
                        <EstateInfoBlock estate_id={estate_id} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <PriceBlockShow item_id={estate_id} />

                        <OwnerAssignButtonBlock estate_id={estate_id} />

                        <Comments item_id={estate_id} item_type="OWNER" multiTypes={multiTypes} />
                    </Grid.Column>
                </Grid>
            </PreventEditing>
        );
    }
}

export default EstateOwnersMode;
