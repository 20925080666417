import React, { Fragment } from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

import { WishTableType } from '~/types/wish.types';
import estateL10n from '~/L10n/estate.L10n';
import { EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';
import settingStore from '~/stores/settingStore';
import authStore from '~/stores/authStore';

import Time from '~/components/Base/Time';

import PriceMinMax from '~/components/Items/Wish/SubBlocks/PriceMinMax';
import SquareMinMax from '~/components/Items/Wish/SubBlocks/SquareMinMax';
import MetroList from '~/components/Items/Wish/SubBlocks/MetroList';
import RoomsCount from '~/components/Items/Wish/SubBlocks/RoomsCount';
import FloorMinMax from '~/components/Items/Wish/SubBlocks/FloorMinMax';

import WishTableContacts from './SubBlocks/WishTableContacts';
import WishLinkWithPreview from './WishLinkWithPreview';
import UsersLinkList from '../Users/UsersLinkList';

const WishTableBody = ({ wishList, shortMode = false }: { wishList: WishTableType[]; shortMode?: boolean }) => {
    return (
        <Table.Body>
            {wishList.length > 0 &&
                wishList.map(
                    ({
                        wish_id,
                        contact_id,
                        enable,
                        type,
                        createTime,
                        major_users,
                        roomsCount,
                        priceMin,
                        priceMax,
                        priceM2Min,
                        priceM2Max,
                        mortgageApproved,
                        main_metro_id,

                        totalAreaMin,
                        totalAreaMax,
                        livingAreaMin,
                        livingAreaMax,
                        kitchenAreaMin,
                        kitchenAreaMax,

                        transportType,
                        transportTime,

                        floorNumberMin,
                        floorNumberMax,
                        floorsCountMin,
                        lastFloorOnly,
                        town,
                        cityDistrict,
                        propertyType,
                        isBooked,
                        bookedUser,
                        lastCallTime,
                        showingsCount,
                        callsCount
                    }) => {
                        const callsStatus = matchExpiredCallStatus(lastCallTime);

                        return (
                            <Table.Row
                                key={wish_id}
                                negative={callsStatus === EXPIRED_CALL_STATUS_ERROR}
                                warning={callsStatus === EXPIRED_CALL_STATUS_WARNING}
                            >
                                <Table.Cell>
                                    <b>
                                        <WishLinkWithPreview wish={{ wish_id, enable, type }} />
                                    </b>
                                    <div>
                                        Интересует: <b>{estateL10n.PROPERTY_TYPE_FIND(propertyType)}</b>
                                        <br />
                                        <b>
                                            <Time time={createTime} />
                                        </b>
                                    </div>
                                    <div>
                                        Звонок: <Time time={lastCallTime} />
                                    </div>
                                </Table.Cell>

                                <Table.Cell>
                                    <RoomsCount short roomsCount={roomsCount} />
                                </Table.Cell>

                                <Table.Cell>
                                    <PriceMinMax
                                        short
                                        priceMin={priceMin}
                                        priceMax={priceMax}
                                        priceM2Min={priceM2Min}
                                        priceM2Max={priceM2Max}
                                        mortgageApproved={mortgageApproved}
                                    />
                                </Table.Cell>

                                <Table.Cell>
                                    <MetroList
                                        enablePopup
                                        main_metro_id={main_metro_id}
                                        transportType={transportType}
                                        transportTime={transportTime}
                                    />
                                    {town && (
                                        <div>
                                            {town}
                                            {cityDistrict ? ` (${cityDistrict})` : ''}
                                        </div>
                                    )}
                                </Table.Cell>

                                <Table.Cell>
                                    <SquareMinMax
                                        short
                                        totalAreaMin={totalAreaMin}
                                        totalAreaMax={totalAreaMax}
                                        livingAreaMin={livingAreaMin}
                                        livingAreaMax={livingAreaMax}
                                        kitchenAreaMin={kitchenAreaMin}
                                        kitchenAreaMax={kitchenAreaMax}
                                    />
                                </Table.Cell>

                                {!shortMode && (
                                    <Fragment>
                                        <Table.Cell>
                                            <FloorMinMax
                                                short={true}
                                                floorNumberMin={floorNumberMin}
                                                floorNumberMax={floorNumberMax}
                                                floorsCountMin={floorsCountMin}
                                                lastFloorOnly={lastFloorOnly}
                                            />
                                        </Table.Cell>

                                        <Table.Cell>
                                            {!settingStore?.mainConfig?.enableWishBooking && (
                                                <div>
                                                    <UsersLinkList usersList={major_users} />
                                                    {contact_id && <WishTableContacts contact_id={contact_id} />}
                                                </div>
                                            )}
                                            {settingStore?.mainConfig?.enableWishBooking && (
                                                <div>
                                                    {bookedUser && <UsersLinkList usersList={[bookedUser]} />}
                                                    {isBooked && isBooked.major_user_id === authStore.currentUser.user_id && contact_id && (
                                                        <WishTableContacts contact_id={contact_id} />
                                                    )}
                                                </div>
                                            )}
                                            <div>
                                                <Label
                                                    size="mini"
                                                    title="Количетсво звонков"
                                                    basic
                                                    color={Number(callsCount) > 0 ? 'blue' : null}
                                                >
                                                    <Icon name="phone" /> {callsCount}
                                                </Label>
                                                &nbsp;&nbsp;
                                                <Label
                                                    size="mini"
                                                    basic
                                                    title="Количетсво показов"
                                                    color={Number(showingsCount) > 0 ? 'green' : null}
                                                >
                                                    <Icon name="compass" /> {showingsCount}
                                                </Label>
                                            </div>
                                        </Table.Cell>
                                    </Fragment>
                                )}
                            </Table.Row>
                        );
                    }
                )}
        </Table.Body>
    );
};

export default WishTableBody;
