import React, { Component } from 'react';
import { Icon, Search } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { EstateAddress } from '~/types/estate.types';
import debounce from '../../../common/debounce';
import * as dadataApi from '~/api/dadataApi';
import { DaDataAddress, DaDataAddressSuggestion } from '~/types/dadata.types';

const resultRenderer = ({ title }) => title;

type AddressSearchInputState = {
    searchAddressValue: number | null;
    searchAddressQuery: string;
    loadingAddresses: boolean;
    addresses: DaDataAddressSuggestion[];
    addressFromProps: string;
};

type AddressSearchInputProps = {
    value: string | null;
    onChange: (address: EstateAddress | null) => void;
    className?: string;
};

export const addressEmpty: EstateAddress = {
    address: '',

    street: null,
    streetType: null,
    houseNumber: null,
    houseNumberType: null,
    blockNumber: null,
    blockNumberType: null,

    town: null,
    townType: null,
    region: '',
    regionType: null,
    district: null,
    districtType: null,
    cityDistrict: null,
    cityDistrictType: null,
    settlement: null,
    settlementType: null,
    kladr: null,
    fias: null,

    postcode: null,
    geo_lat: null,
    geo_lon: null
};

@observer
class AddressSearchInput extends Component<AddressSearchInputProps, AddressSearchInputState> {
    constructor(props: AddressSearchInputProps) {
        super(props);

        this.state = {
            searchAddressQuery: props.value || '',
            addressFromProps: props.value || '',
            searchAddressValue: null,

            loadingAddresses: false,
            addresses: []
        };

        // estateStore.setProperty(props.estate_id, { searchAddressQuery: props.address || '' });
    }

    static getDerivedStateFromProps(nextProps: AddressSearchInputProps, prevState: AddressSearchInputState) {
        if (nextProps.value !== prevState.addressFromProps) {
            prevState['searchAddressQuery'] = nextProps.value;
            prevState['addressFromProps'] = nextProps.value;
        }

        return { ...prevState };
    }

    debounceFetchAddress = debounce(() => {
        (async () => {
            await this.fetchAddressCount(this.addressQuery, 7);
        })();
    }, 350);
    addressQuery: string;

    findOnceAddress = async (address: string) => {
        await this.fetchAddressCount(address, 1);

        const addresses = this.state.addresses;
        let data: DaDataAddress;
        try {
            data = addresses[0].data;
        } catch (error) {
            return;
        }

        // estateStore.setProperty(estate_id, { searchAddressQuery: addresses[0].value });
        this.setState({ searchAddressQuery: addresses[0].value });

        const estate: EstateAddress = {
            address: addresses[0].value,

            street: data['street'],
            streetType: data['street_type_full'],
            houseNumber: data['house'],
            houseNumberType: data['house_type_full'],
            blockNumber: data['block'],
            blockNumberType: data['block_type_full'],

            town: data['city'] || data['settlement'],
            townType: data['city_type_full'] || data['settlement_type_full'],
            region: data['region'] || '',
            regionType: data['region_type_full'],
            district: data['area'],
            districtType: data['area_type_full'],
            cityDistrict: data['city_district'],
            cityDistrictType: data['city_district_type_full'],
            settlement: data['settlement'],
            settlementType: data['settlement_type_full'],
            kladr: data['kladr_id'],
            fias: data['fias_id'],

            postcode: data['postal_code'] ? Number(data['postal_code']) : null,
            geo_lat: parseFloat(data['geo_lat']),
            geo_lon: parseFloat(data['geo_lon'])
        };

        const flatProperties = { flat: 'flatNumber', flat_area: 'totalArea' };
        Object.keys(flatProperties).forEach(key => {
            if (data[key]) {
                estate[flatProperties[key]] = data[key];
            }
        });

        this.props.onChange(estate);
    };

    handleChange = async (event: React.SyntheticEvent, { result: { value: index } }: { result: { value: number } }) => {
        const { addresses } = this.state;
        this.setState({ searchAddressValue: index, searchAddressQuery: addresses[index].value });

        await this.findOnceAddress(addresses[index].value);
    };

    fetchAddresses = (query: string) => {
        this.addressQuery = query;
        this.debounceFetchAddress();
    };

    fetchAddressCount = async (query: string, count: number) => {
        this.setState({ loadingAddresses: true });

        const addresses = await dadataApi.fetchAddressSuggestions(query, count);

        this.setState({
            addresses,
            loadingAddresses: false
        });
    };

    searchAddress = (evt: React.SyntheticEvent, { value: searchAddressQuery }: { value: string }) => {
        this.fetchAddresses(searchAddressQuery);
        this.setState({ searchAddressQuery });
    };

    changeSelectionChangeDebounce = debounce(() => {
        this.setState({ searchAddressQuery: this.searchAddressQueryAwait });

        (async () => {
            await this.findOnceAddress(this.searchAddressQueryAwait);
            this.fetchAddresses(this.searchAddressQueryAwait);
        })();
    }, 1000);

    searchAddressQueryAwait = '';

    handleSelectionChange = (evt: React.SyntheticEvent, { result: { title } }: { result: { title: string } }) => {
        this.searchAddressQueryAwait = title;
        this.changeSelectionChangeDebounce();
    };

    handleClear = () => {
        this.setState({ searchAddressValue: null, searchAddressQuery: '' });
        this.props.onChange(null);
    };

    render() {
        const { searchAddressQuery, loadingAddresses, addresses } = this.state;
        const addressList = addresses.map(({ value }, i) => ({ key: i, value: i, title: value }));

        return (
            <Search
                className={this.props.className}
                showNoResults={false}
                size="tiny"
                loading={loadingAddresses}
                onResultSelect={this.handleChange}
                onSearchChange={this.searchAddress}
                results={addressList}
                value={searchAddressQuery}
                resultRenderer={resultRenderer}
                onSelectionChange={this.handleSelectionChange}
                icon={
                    searchAddressQuery ? (
                        <Icon
                            onClick={this.handleClear}
                            name="remove"
                            className="crm-Estate__address_newbuildingClear"
                            title="Сбросить адрес"
                        />
                    ) : (
                        'search'
                    )
                }
            />
        );
    }
}

export default AddressSearchInput;
