import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import Paper from '@material-ui/core/Paper';
import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';
import LifeComplexDeveloperEditing from '../EditBlocks/LifeComplexDeveloperEditing';
import { WarningMessage } from '~ui/Message';

const LifeComplexDeveloperShow = ({ item_id, editing }: EditingWrappedProps) => {
    const newbuilding = lifeComplexStore.getItem(item_id).item;
    if (!newbuilding) {
        return (
            <Paper style={{ padding: '1rem' }}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Paper>
        );
    }

    if (editing) {
        return <LifeComplexDeveloperEditing item_id={item_id} developer_id={newbuilding.developer_id} />;
    }

    const { developer } = newbuilding;
    if (!developer) {
        return (
            <Paper style={{ padding: '1rem' }}>
                <WarningMessage>Застройщик не указан</WarningMessage>
            </Paper>
        );
    }

    return (
        <Paper style={{ padding: '1rem' }}>
            {developer.logoUrl && <img src={developer.logoUrl} height={48} />}
            <Typography>Застройщик «{developer.name}»</Typography>
        </Paper>
    );
};

export default EditingBlockWrapper(lifeComplexStore, observer(LifeComplexDeveloperShow));
