import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import TelegramIcon from '@material-ui/icons/Telegram';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import LoaderAwait from '~/components/Base/LoaderAwait';

import UserTelegramEditing from '../EditBlocks/UserTelegramEditing';
import UserTelegramMessagesModal from './UserTelegramMessagesModal';
import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

const useStyles = makeStyles(({ spacing }) => ({
    description: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing(2)
    }
}));

const UserTelegramShowBlock = observer((props: EditingWrappedProps) => {
    const classes = useStyles();
    const { item_id, editing } = props;
    const { item, loadingItem } = userStore.getItem(item_id);
    const { telegramUsername } = item || {};
    const [showTgMessages, toggleTgMessages] = useToggle();

    return (
        <Card fluid color="green">
            <Card.Content>
                {!editing && (
                    <Fragment>
                        <Card.Description className={classes.description}>
                            <TelegramIcon style={telegramUsername ? { color: '#229ED9' } : {}} /> Telegram&nbsp;
                            {telegramUsername ? (
                                <Button
                                    size="small"
                                    color="primary"
                                    tooltip="Смотреть последние отправленные сообщения от бота @agentbase_bot"
                                    onClick={toggleTgMessages}
                                >
                                    подключен
                                </Button>
                            ) : (
                                'не подключен'
                            )}
                        </Card.Description>
                    </Fragment>
                )}
                {editing && !loadingItem && <UserTelegramEditing item_id={item_id} {...item} />}
            </Card.Content>

            <LoaderAwait active={loadingItem} dimmer size="large" />

            {showTgMessages && <UserTelegramMessagesModal onClose={toggleTgMessages} user_id={item_id} />}
        </Card>
    );
});

export default EditingBlockWrapper(userStore, UserTelegramShowBlock);
