import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';

import { EditingWrappedProps } from '../../../EditingBlockWrapper';
import exportStore from '~/stores/export/exportStore';
import MajorUserBlockEditing from '../../../Common/MajorUserBlockEditing';
import estateStore from '~/stores/estateStore';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import deepCopy from '~/common/deepCopy';
import { UserLinkType } from '~/types/users.types';
import authStore from '~/stores/authStore';
import { ACCESS_SELF, ACCESS_WRITE } from '~/types/access.types';
import Box from '@material-ui/core/Box';

import Button from '~ui/Button';
import Grid from '~ui/Grid';

@observer
class ExportMajorUsers extends Component<EditingWrappedProps, { showUserPopup: boolean }> {
    constructor(props: EditingWrappedProps) {
        super(props);

        this.state = { showUserPopup: false };

        const { item_id } = props;

        const { item } = exportStore.getItem(item_id);
        if (!item) return;

        let { major_user_ids, major_users } = item;

        if (!major_user_ids.length) {
            const estate = estateStore.getItem(item_id).item;
            if (estate && estate.major_user_ids && estate.major_user_ids.length) {
                major_user_ids = deepCopy(estate.major_user_ids);
                major_users = deepCopy(estate.major_users);
            }
        }

        exportStore.setEditingItem(item_id, { major_user_ids });
        exportStore.mergeItem(item_id, { major_users });
    }

    handleTogglePopup = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        this.setState({ showUserPopup: !this.state.showUserPopup });
    };

    handleClosePopup = (event: React.SyntheticEvent) => {
        this.handleTogglePopup(event);

        const { item_id } = this.props;

        const { major_user_ids } = exportStore.getItem(item_id).editingItem;

        const major_users: Array<UserLinkType> = major_user_ids.reduce((major_users, major_user_id) => {
            try {
                major_users.push(authStore.findUserById(major_user_id));
            } catch (error) {}
            return major_users;
        }, []);

        exportStore.mergeItem(item_id, { major_users });
    };

    render() {
        const { item_id } = this.props;
        const { showUserPopup } = this.state;

        const {
            item,
            editingItem: { major_user_ids }
        } = exportStore.getItem(item_id);

        if (!item) {
            return null;
        }

        const { major_users } = item;

        const major_user_ids_withoutDeleted = major_users.reduce((ids, majorUser) => {
            if (majorUser.enable) {
                ids.push(majorUser.user_id);
            }
            return ids;
        }, []);

        return (
            <Fragment>
                {major_users.length === 0 && <b>Необходимо указать ответсвенного</b>}
                {major_users.length > 0 && (
                    <Fragment>
                        Экспорт установлен на: &nbsp;
                        {major_users.map(major_user => (
                            <UserLinkWithPreview key={major_user.user_id} icon={true} user={major_user} />
                        ))}
                    </Fragment>
                )}
                &nbsp; &nbsp;
                {authStore.getPermission(exportStore.moduleName, ACCESS_WRITE) !== ACCESS_SELF && (
                    <Popup
                        trigger={
                            <Button size="small" secondary variant="outlined" onClick={!showUserPopup ? this.handleTogglePopup : null}>
                                {major_users.length === 0 ? `Назначить` : `Поменять`}
                            </Button>
                        }
                        flowing
                        open={showUserPopup}
                    >
                        <Grid columns={1}>
                            <Grid.Column>
                                <MajorUserBlockEditing
                                    upward={true}
                                    store={exportStore}
                                    major_user_id={major_user_ids_withoutDeleted}
                                    item_id={item_id}
                                    fluid
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button onClick={this.handleTogglePopup}>Отменить</Button>
                                    <Button
                                        disabled={
                                            major_user_ids instanceof Array && (major_user_ids.length === 0 || major_user_ids.length > 1)
                                        }
                                        primary
                                        onClick={this.handleClosePopup}
                                    >
                                        Поменять
                                    </Button>
                                </Box>
                            </Grid.Column>
                        </Grid>
                    </Popup>
                )}
            </Fragment>
        );
    }
}

export default ExportMajorUsers;
