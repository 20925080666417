import React, { Component } from 'react';
import { observer } from 'mobx-react';

import contactStore from '~/stores/contactStore';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import DatePicker from '~ui/DatePicker';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';

type AdditionalInfoBlockEditingProps = HandlerEditingWrappedProps & {
    birthday?: string;
    about?: string;
};

@observer
class AdditionalInfoBlockEditing extends Component<AdditionalInfoBlockEditingProps> {
    constructor(props: AdditionalInfoBlockEditingProps) {
        super(props);

        const item = {
            birthday: props.birthday || null,
            about: props.about || ''
        };
        contactStore.setEditingItem(props.item_id, item);
    }

    render() {
        const { item_id, handleChange } = this.props;
        const { birthday, about } = contactStore.getItem(item_id).editingItem;

        return (
            <Grid>
                <Grid.Column>
                    <TextInput
                        label="Описание"
                        value={about}
                        name="about"
                        onChange={handleChange}
                        multiline
                        rowsMax={10}
                        rows={4}
                        variant="classic"
                        fullWidth
                    />
                </Grid.Column>
                <Grid.Column>
                    <DatePicker
                        label="День Рождения"
                        value={birthday}
                        onChange={timeSec => this.props.handleDate('birthday', timeSec)}
                        openTo="year"
                        maxDate={new Date()}
                    />
                </Grid.Column>
            </Grid>
        );
    }
}

export default HandlerEditingBlockWrapper(contactStore, AdditionalInfoBlockEditing);
