import fetchApi from '../common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { Access, UserModuleAccess } from '~/types/access.types';
import { UserAndGroup } from '~/types/users.types';

const AccessGQLFields = `
    access_id
    title
    modules {
      name
      access {
        read
        write
        delete
      }
    }
    enableCommittingExport
    enableCommittingPremium
    enableApplyingPremium
    enableCommittingExclusives
    maxEnableDebt
    export_moderator_id
    chiefIsModerator
    includedInStatistics
`;

export const fetchAllUserGroups = async (): Promise<UserAndGroup[]> => {
    const graphql = `{
          allUsers {
            user_id
            group_id
            firstName
            lastName
            email
            enable
            phones {
                phone
            }
            avatarUrl
          }
        }`;

    const data: { allUsers: UserAndGroup[] } = await fetchApi.getGQ(graphql);
    return data.allUsers;
};

export const fetchAccessModes = async (): Promise<UserModuleAccess[]> => {
    const graphql = `{
        getAccessTypes { 
            title
            name
            modes
            permissions
        } 
      }`;

    const data: { getAccessTypes: UserModuleAccess[] } = await fetchApi.getGQ(graphql);
    return data.getAccessTypes;
};

export const createItem = async (access: Access): Promise<number> => {
    const graphql = `mutation { 
          createAccess(access: ${objectToGraphql(access)})
        }`;

    const data: { createAccess: number } = await fetchApi.postGQ(graphql);
    return data.createAccess;
};

export const saveItem = async (access_id: number, accessDiff: Access): Promise<number> => {
    const graphql = `mutation { 
          updateAccess(access_id: ${access_id}, accessDiff: ${objectToGraphql(accessDiff)})
        }`;

    const data: { updateAccess: number } = await fetchApi.postGQ(graphql);
    return data.updateAccess;
};

export const fetchItem = async (id: number): Promise<Access> => {
    const graphql = `{
    fetchAccess(access_id: ${id}) {
        ${AccessGQLFields}
    } 
  }`;

    const data: { fetchAccess: Access } = await fetchApi.getGQ(graphql);
    return data.fetchAccess;
};

export const fetchList = async (): Promise<{ list: Array<Access>; count: number }> => {
    const graphql = `{
            fetchAccesses{
                list {
                    ${AccessGQLFields}
                }
                count
            }
          }`;

    const data: { fetchAccesses: { list: Access[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchAccesses;
};
