import fetchApi from '../common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { KanbanBoard } from '~/types/contacts.types';

const KanbanBoardGQLFields = `
    board_id
    title
    lists {
        list_id
        order
        title
        bgColor
        textColor
        enable
    }
    enable
`;

export const createItem = async (inputKanbanBoard: Partial<KanbanBoard>): Promise<number> => {
    const graphql = `mutation { 
          createKanbanBoard(inputKanbanBoard: ${objectToGraphql(inputKanbanBoard)})
        }`;

    const data: { createKanbanBoard: number } = await fetchApi.postGQ(graphql);
    return data.createKanbanBoard;
};

export const saveItem = async (board_id: number, kanbanBoardDiff: Partial<KanbanBoard>): Promise<number> => {
    const graphql = `mutation { 
        updateKanbanBoard(board_id: ${board_id}, kanbanBoardDiff: ${objectToGraphql(kanbanBoardDiff)})
    }`;

    const data: { updateKanbanBoard: number } = await fetchApi.postGQ(graphql);
    return data.updateKanbanBoard;
};

export const fetchItem = async (board_id: number): Promise<KanbanBoard> => {
    const graphql = `{
            fetchKanbanBoard(board_id: ${board_id}) {
                ${KanbanBoardGQLFields}
            } 
          }`;

    const data: { fetchKanbanBoard: KanbanBoard } = await fetchApi.getGQ(graphql);
    return data.fetchKanbanBoard;
};

export const fetchList = async (): Promise<{ list: KanbanBoard[]; count: number }> => {
    const graphql = `{
            fetchKanbanBoards{
                list {
                    ${KanbanBoardGQLFields}
                }
                count
            }
          }`;

    const data: { fetchKanbanBoards: { list: KanbanBoard[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchKanbanBoards;
};
