import React, { useMemo, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';
import statisticsL10n from '~/L10n/statistics.L10n';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import Select from '~ui/Select';
import { NumberInput } from '~ui/TextInput';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import deepCopy from '~/common/deepCopy';
import { KpiPoint } from '~/types/kpi.types';

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        border: `1px solid ${palette.divider}`,
        padding: spacing(1, 1)
    }
}));

const emptyPoint: Partial<KpiPoint> = {
    pointType: 1,
    weight: 0,
    days: 1,
    kpi_id: 0,
    redValue: null,
    greenValue: null,
    enable: true
};

const KpiItemPointEditing = ({ kpi_id, point_id, onClose }: { kpi_id: number; point_id: number; onClose: () => void }) => {
    const classes = useStyles();

    useMemo(() => {
        const { editingItem, item } = kpiStore.getItem(kpi_id);
        const { points } = editingItem;

        if (point_id === CREATING_ITEM_ID) {
            kpiStore.setEditingItem(kpi_id, {
                points: [...(points || []), deepCopy(emptyPoint)]
            });
        } else {
            kpiStore.setEditingItem(kpi_id, { points: deepCopy(item.points) });
        }
    }, []);

    const {
        editingItem: { points }
    } = kpiStore.getItem(kpi_id);
    if (!points) {
        return null;
    }

    const editingPointIndex = point_id === CREATING_ITEM_ID ? points.length - 1 : points.findIndex(list => list.point_id === point_id);

    const { days, weight, pointType, greenValue, redValue } = points[editingPointIndex];

    const handleEdit = (prop: keyof KpiPoint) => (event: ChangeEvent<HTMLInputElement>) => {
        kpiStore.changeArrayValue(kpi_id, 'points', editingPointIndex, prop, Number(event.target.value));
    };

    const handleSave = () => {
        kpiStore.saveItem(kpi_id);
        onClose();
    };

    const handleRemove = () => {
        kpiStore.deleteKpiPoint(kpi_id, editingPointIndex);
        onClose();
    };

    return (
        <ListItem className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Select
                        fullWidth
                        value={pointType}
                        onChange={handleEdit('pointType')}
                        options={statisticsL10n.KPI_POINT_TYPE_DROPDOWN}
                    />
                </Grid>
                <Grid item xs={4}>
                    {![41, 42, 70].includes(pointType) && (
                        <Select fullWidth value={days} onChange={handleEdit('days')} options={statisticsL10n.KPI_DAYS_DROPDOWN} />
                    )}
                </Grid>

                <Grid item xs={6}>
                    <NumberInput label="Красный цвет, до" value={redValue} fullWidth onChange={handleEdit('redValue')} step={1} min={0} />
                </Grid>

                <Grid item xs={6}>
                    <NumberInput
                        label="Зеленый цвет, от"
                        value={greenValue}
                        fullWidth
                        onChange={handleEdit('greenValue')}
                        step={1}
                        min={0}
                    />
                </Grid>

                <Grid item xs={8}>
                    <NumberInput label="Вес" value={weight} onChange={handleEdit('weight')} step={0.01} />
                </Grid>

                <Grid item xs={4}>
                    {point_id !== CREATING_ITEM_ID && (
                        <Tooltip title="Удалить показатель">
                            <IconButton edge="end" onClick={handleRemove} color="secondary">
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Сохранить">
                        <IconButton edge="end" onClick={handleSave} color="primary">
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Отменить">
                        <IconButton edge="end" onClick={onClose} color="secondary">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default observer(KpiItemPointEditing);
