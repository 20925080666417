import './triggers.less';

import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import triggerStore from '~/stores/triggerStore';
import authStore from '~/stores/authStore';
import TriggerModal from './TriggerModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import ContainerHeader from '~ui/ContainerHeader';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { SettingsListPaper } from '~/components/Settings';
import LoaderAwait from '~/components/Base/LoaderAwait';

const Triggers = () => {
    const [showTriggerId, setShowTriggerId] = useState(-1);

    useEffect(() => {
        (async () => {
            await triggerStore.fetchTasksTriggers();
            await triggerStore.fetchList();
        })();
    }, []);

    const handleModal = (trigger_id: number) => {
        setShowTriggerId(trigger_id);
    };

    const { list, loadingList } = triggerStore;

    return (
        <Fragment>
            <ContainerHeader
                handleCreate={() => {
                    setShowTriggerId(CREATING_ITEM_ID);
                }}
                title="Триггеры задач"
                icon={<FlashOnIcon fontSize="large" />}
                enableCreating={authStore.canCreate(triggerStore.moduleName)}
            />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ trigger_id, taskTitle }, index) => (
                        <ListItem button key={trigger_id} onClick={() => handleModal(trigger_id)}>
                            <ListItemText primary={taskTitle} />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {showTriggerId > -1 && (
                <TriggerModal
                    item_id={showTriggerId}
                    handleClose={() => {
                        setShowTriggerId(-1);
                    }}
                />
            )}
        </Fragment>
    );
};

export default observer(Triggers);
