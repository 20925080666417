import React from 'react';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import { handlePreventDblClick } from '../../Base/PreventDblClick';

const SimpleTableCell = ({
    children,
    textAlign,
    preventDblClick,
    collapsing
}: {
    children?: ReactNode;
    textAlign?: 'left' | 'center' | 'right';
    preventDblClick?: boolean;
    collapsing?: boolean;
}) => (
    <Table.Cell
        onDoubleClick={preventDblClick ? handlePreventDblClick : null}
        textAlign={textAlign}
        title={preventDblClick ? '' : 'Двойной щелчок откроет карточку'}
        collapsing={collapsing}
    >
        {children}
    </Table.Cell>
);

export default SimpleTableCell;
