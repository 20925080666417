import React, { ReactElement, useEffect, useState } from 'react';
import { Transition } from 'semantic-ui-react';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

const ANIMATION_HEADER__BTN_INT_MS = 1500;

const HeaderAnimatedIconButton = ({
    count,
    icon,
    onToggle,
    setRef,
    defaultColor = 'default'
}: {
    count: number | string;
    icon: ReactElement;
    onToggle: () => void;
    setRef: ($ref: HTMLElement) => void;
    defaultColor?: 'inherit' | 'primary' | 'secondary' | 'default';
}) => {
    const [visibleBtnAnimation, setVisibleBtnAnimation] = useState(true);
    const [timeout, setStateTimeout] = useState<number | null>(null);

    useEffect(() => {
        if (count) {
            setStateTimeout(
                window.setTimeout(() => {
                    setVisibleBtnAnimation(!visibleBtnAnimation);
                }, ANIMATION_HEADER__BTN_INT_MS)
            );
        } else {
            clearTimeout(timeout);
        }
        return () => clearTimeout(timeout);
    }, [count, visibleBtnAnimation]);

    return (
        <Transition animation="jiggle" duration={500} visible={visibleBtnAnimation}>
            <div ref={$ref => setRef($ref)} className="crm-Header__item">
                <IconButton color={count ? 'secondary' : defaultColor} onClick={onToggle}>
                    <Badge badgeContent={count} color="error">
                        {icon}
                    </Badge>
                </IconButton>
            </div>
        </Transition>
    );
};

export default HeaderAnimatedIconButton;
