import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Grid, Form, Radio, Button, List, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ButtonGroup from '../../../Base/ButtonGroup';
import EditButton from '../../../Base/EditButton';
import CloseButton from '../../../Base/CloseButton';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import requestStore from '~/stores/requestStore';
import {
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_HUNTING,
    REQUEST_TYPE_MORTGAGE,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_SELL
} from '~/types/requests.types';
import requestL10n from '../../../../L10n/request.L10n';

type RequestTypeEditingProps = HandlerEditingWrappedProps & {
    editing: boolean;
    type?: number;
    propertyType?: number;
};

export const printRequestType = (type: number, propertyType: number): string => {
    let what = '';
    switch (propertyType) {
        case 1:
            what = 'квартиры';
            break;
        case 2:
            what = 'квартиры в новостройке';
            break;
        case 6:
            what = 'переуступки в новостройке';
            break;
        case 3:
            what = 'комнаты';
            break;
        case 4:
            what = 'доли в квартире';
            break;
        default:
            what = '';
    }
    let typeString = '';
    switch (type) {
        case REQUEST_TYPE_SELL:
            typeString = 'продажу';
            break;
        case REQUEST_TYPE_RENT:
            typeString = 'сдачу';
            break;
        case REQUEST_TYPE_BUY:
            typeString = 'покупку';
            break;
        case REQUEST_TYPE_HIRE:
            typeString = 'съем';
            break;
        case REQUEST_TYPE_NEWBUILDING:
            typeString = 'новостройку';
            break;
        case REQUEST_TYPE_MORTGAGE:
            typeString = 'ипотеку';
            break;
        case REQUEST_TYPE_HUNTING:
            typeString = 'рекрутинг';
            break;
        default:
            return '';
    }
    return typeString + ' ' + what;
};

export type RequestTypeEditingState = {
    editing: boolean;
};

@observer
class RequestTypeEditing extends Component<RequestTypeEditingProps, RequestTypeEditingState> {
    constructor(props: RequestTypeEditingProps) {
        super(props);

        this.state = {
            editing: false
        };

        const item = {
            type: this.props.type || 1,
            propertyType: this.props.propertyType || 0
        };
        requestStore.setEditingItem(props.item_id, item);
    }

    handleEditing = () => {
        this.setState({
            editing: !this.state.editing
        });
    };

    handleChange = (event: React.SyntheticEvent, { name, value }: { name: string; value: string }) => {
        this.props.handleChange(event, { type: 'string', name, value });
    };

    render() {
        const { editing, item_id } = this.props;
        const {
            editingItem: { type, propertyType }
        } = requestStore.getItem(item_id);

        const shortRequest = [REQUEST_TYPE_HUNTING, REQUEST_TYPE_MORTGAGE, REQUEST_TYPE_NEWBUILDING].includes(type);

        let propertyTypes: Array<[number, string]>;
        if (shortRequest) {
            propertyTypes = [];
        } else if (type === REQUEST_TYPE_SELL || type === REQUEST_TYPE_BUY) {
            propertyTypes = estateL10n.PROPERTY_TYPE.slice(0, 5);
        } else {
            propertyTypes = [estateL10n.PROPERTY_TYPE[0], estateL10n.PROPERTY_TYPE[3]];
        }

        return editing || this.state.editing ? (
            <div className="crm-FluentButtons">
                {this.state.editing && <CloseButton onClick={this.handleEditing} />}
                <h3>Тип заявки</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <ButtonGroup
                                size="medium"
                                buttonSet={requestL10n.REQUEST_TYPE}
                                name="type"
                                value={type}
                                handleChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {!shortRequest && (
                        <Grid.Row>
                            <Grid.Column computer={4} tablet={6} mobile={16}>
                                <Form.Field>
                                    <label className="crm-Estate__field_label">Объект</label>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={10} mobile={16}>
                                <List>
                                    {propertyTypes.map(item => (
                                        <List.Item key={`propertyType_${item[0]}`}>
                                            <Radio
                                                label={item[1]}
                                                name="propertyType"
                                                value={item[0]}
                                                onChange={this.handleChange}
                                                checked={item[0] === propertyType}
                                            />
                                        </List.Item>
                                    ))}
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </div>
        ) : (
            <div className="crm-FluentButtons">
                <EditButton onClick={this.handleEditing} />
                <Message color="orange" header="Заявка на" content={printRequestType(type, propertyType)} style={{ marginTop: '0' }} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, RequestTypeEditing);
