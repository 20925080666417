import React, { Component, Fragment, SyntheticEvent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import ListFilterWrapper from '../ListFilterWrapper';
import requestStore from '~/stores/requestStore';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import {
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_HUNTING,
    REQUEST_TYPE_MORTGAGE,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_SELL
} from '~/types/requests.types';
import FilterRequestsMoreDetails from './FilterBlocks/FilterRequestsMoreDetails';
import DateRangeSelect, { DATES_RANGE_VALUE } from '~ui/DateRangeSelect';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const StyledTab = withStyles(({ palette, shadows, spacing }) => ({
    selected: {
        backgroundColor: `${palette.primary.main} !important`,
        color: '#fff !important'
    },
    root: {
        height: '32px',
        minHeight: '32px',
        textTransform: 'capitalize',
        background: '#fafafa',
        minWidth: 0,
        padding: '0 0.725rem',
        margin: '0 0.125rem',
        borderRadius: '4px',
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.54)',
        '&:hover': {
            backgroundColor: 'rgba(24, 31, 49, 0.05)'
        }
    }
}))(Tab);

const StyledTabs = withStyles(({ palette, shadows, spacing }) => ({
    root: {
        height: '32px',
        minHeight: '32px'
    }
}))(Tabs);

@observer
class FilterRequest extends Component<ListFilterWrapperProps> {
    render() {
        const {
            listFilter: { type }
        } = requestStore;

        const handleTimesChange = ({ startTime, endTime, deltaTime }: DATES_RANGE_VALUE) => {
            requestStore.changeFilter('deltaTime', deltaTime);
            requestStore.changeFilter('startTime', startTime);
            requestStore.changeFilter('endTime', endTime);
        };

        const handleChangeType = (event: SyntheticEvent, type: number) => {
            requestStore.changeFilter('type', type);
        };

        return (
            <Fragment>
                {type !== REQUEST_TYPE_HUNTING && (
                    <StyledTabs
                        value={type}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeType}
                        variant="scrollable"
                        scrollButtons="auto"
                        TabIndicatorProps={{
                            style: {
                                display: 'none'
                            }
                        }}
                        style={{ height: '32px', maxHeight: '32px', minHeight: '32px' }}
                    >
                        {[
                            [0, 'Все'],
                            [REQUEST_TYPE_SELL, 'Продажа'],
                            [REQUEST_TYPE_RENT, 'Сдача'],
                            [REQUEST_TYPE_BUY, 'Покупка'],
                            [REQUEST_TYPE_HIRE, 'Съем'],
                            [REQUEST_TYPE_NEWBUILDING, 'Новостройка'],
                            [REQUEST_TYPE_MORTGAGE, 'Ипотека']
                        ].map(([id, title]) => (
                            <StyledTab key={id} label={title} value={id} />
                        ))}
                    </StyledTabs>
                )}

                {/*{type !== REQUEST_TYPE_HUNTING && (*/}
                {/*    <Menu.Item className="crm-List__filter_dropdown">*/}
                {/*        <ButtonGroup*/}
                {/*            buttonSet={[*/}
                {/*                [0, 'Все'],*/}
                {/*                [REQUEST_TYPE_SELL, 'Продажа'],*/}
                {/*                [REQUEST_TYPE_RENT, 'Сдача'],*/}
                {/*                [REQUEST_TYPE_BUY, 'Покупка'],*/}
                {/*                [REQUEST_TYPE_HIRE, 'Съем'],*/}
                {/*                [REQUEST_TYPE_NEWBUILDING, 'Новостройка'],*/}
                {/*                [REQUEST_TYPE_MORTGAGE, 'Ипотека']*/}
                {/*            ]}*/}
                {/*            name="type"*/}
                {/*            value={type}*/}
                {/*            handleChange={handleChange}*/}
                {/*            stackable={commonStore.isMobile}*/}
                {/*        />*/}
                {/*    </Menu.Item>*/}
                {/*)}*/}
                <DateRangeSelect onChange={handleTimesChange} initialValue={0} />
            </Fragment>
        );
    }
}

export default ListFilterWrapper(requestStore, FilterRequest, FilterRequestsMoreDetails);
