import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import showingStore from '~/stores/showingStore';

import EstateAndContactEditing from '../EditBlocks/EstateAndContactEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { SHOWING_TYPE_INNER, SHOWING_TYPE_MLS, SHOWING_TYPE_OUTER } from '~/types/showings.types';
import ContactLinkWithPreview from '../../../Lists/Contacts/ContactLinkWithPreview';
import EstateLinkWithPreview from '../../../Lists/Estate/EstateLinkWithPreview';

@observer
class EstateAndContactShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = showingStore.getItem(item_id);

        if (!item || loadingItem) {
            return null;
        }

        const { estate, contact, major_user, estateOuterUrl, showingType } = item;

        return !editing ? (
            <Fragment>
                <Card fluid color="yellow">
                    <Card.Content>
                        <Header size="tiny">Клиент (Посредник)</Header>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>{contact && <ContactLinkWithPreview contact={contact} />}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>

                <Card fluid color="red">
                    <Card.Content>
                        <Header size="tiny">Объект</Header>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    {estate && showingType === SHOWING_TYPE_INNER && <EstateLinkWithPreview estate={estate} />}
                                    {/*{estate && showingType === SHOWING_TYPE_MLS && <EstateLinkWithPreview estate={estate} isMls={true} />}*/}
                                    {showingType === SHOWING_TYPE_OUTER && (
                                        <a target="_blank" href={estateOuterUrl} rel="noreferrer">
                                            Внешний объект <Icon name="external" />
                                        </a>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>
            </Fragment>
        ) : (
            <Card fluid color="yellow">
                <Card.Content>
                    <EstateAndContactEditing item_id={item_id} editing={editing} {...item} />
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(showingStore, EstateAndContactShow);
