import React from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';

import RingVolumeIcon from '@material-ui/icons/RingVolume';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExposureIcon from '@material-ui/icons/Exposure';

import exportStatisticsStore from '~/stores/export/exportStatisticsStore';
import { AVITO_EXPORT_BASE_ID, CIAN_EXPORT_BASE_ID, DOMCLICK_EXPORT_BASE_ID, YANDEX_EXPORT_BASE_ID } from '~/types/exports.types';
import DateRangeSelect, { DATES_RANGE_VALUE } from '../../../../Base/ui/DateRangeSelect';
import { StyledToggleButtonGroup, PultDivider, Pult } from '~ui/FilterPult';

export default function StatisticsPult({ estate_id }: { estate_id: number }) {
    const { activeCharts, activeBases, deltaTimeSec } = exportStatisticsStore.getItem(estate_id).property.statisticsChartPult;

    const handleActiveBases = (event, activeBases) => {
        exportStatisticsStore.setStatisticsChartPult(estate_id, { activeBases });
    };

    const handleActiveCharts = (event, activeCharts) => {
        exportStatisticsStore.setStatisticsChartPult(estate_id, { activeCharts });
    };

    const handleTimesChange = ({ startTime, endTime, deltaTime }: DATES_RANGE_VALUE) => {
        exportStatisticsStore.setStatisticsChartPult(estate_id, { deltaTimeSec: deltaTime, fromTimeSec: startTime, toTimeSec: endTime });
        exportStatisticsStore.fetchEstateStatistics(estate_id);
    };

    return (
        <Pult>
            <StyledToggleButtonGroup size="small" value={activeBases} onChange={handleActiveBases}>
                <ToggleButton value={CIAN_EXPORT_BASE_ID}>ЦИАН</ToggleButton>
                <ToggleButton value={YANDEX_EXPORT_BASE_ID}>Яндекс</ToggleButton>
                <ToggleButton value={AVITO_EXPORT_BASE_ID}>Авито</ToggleButton>
                <ToggleButton value={DOMCLICK_EXPORT_BASE_ID}>ДомКлик</ToggleButton>
                <ToggleButton value={0}>Сумма</ToggleButton>
            </StyledToggleButtonGroup>

            <PultDivider />

            <StyledToggleButtonGroup size="small" value={activeCharts} onChange={handleActiveCharts}>
                <ToggleButton value="views">
                    <VisibilityIcon /> Просмотры
                </ToggleButton>
                <ToggleButton value="phoneShows">
                    <RingVolumeIcon /> Показы телефона
                </ToggleButton>
                <ToggleButton value="priceHistory">
                    <ExposureIcon /> Цена
                </ToggleButton>
            </StyledToggleButtonGroup>

            <PultDivider />

            <DateRangeSelect onChange={handleTimesChange} initialValue={deltaTimeSec} />
        </Pult>
    );
}
