import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import history from '../../../../history';
import EstateIDBlockEditing from '../../Common/EstateIDBlockEditing';
import HandlerEditingBlockWrapper, { HandleChangeType } from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import dealStore, { DEAL_PARTICIOANT_ADD, DEAL_SELLER } from '~/stores/dealStore';
import estateStore from '~/stores/estateStore';
import { SHOWING_TYPE_INNER, SHOWING_TYPE_MLS, SHOWING_TYPE_OUTER } from '~/types/showings.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MLS, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { DEAL_ESTATE_TYPE } from '~/types/deals.types';
import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Checkbox, { Switch } from '~ui/Checkbox';
import Select from '~ui/Select';

type PropertyBlockEditingProps = HandlerEditingWrappedProps & {
    estate_id?: number | null;
    estateType?: DEAL_ESTATE_TYPE;
    estateOuterUrl?: string | null;
    isExclusive?: boolean;
    type: number;
};

@observer
class DealPropertyEditing extends Component<PropertyBlockEditingProps, {}> {
    state = {};

    constructor(props: PropertyBlockEditingProps) {
        super(props);

        const {
            location: {
                state: { from }
            }
        } = history;

        const item = {
            estateType: props.estateType || SHOWING_TYPE_INNER,
            estate_id: props.estate_id || (from && from.storeName === estateStore.moduleName && from.item_id) || null,
            estateOuterUrl: props.estateOuterUrl || null,
            isExclusive: props.isExclusive || false
        };

        dealStore.setEditingItem(props.item_id, item);
    }

    handeEstateChange = (estate_id: number) => {
        const { item_id, editing } = this.props;

        const { estateType } = dealStore.getItem(item_id).editingItem;

        if (estateType !== SHOWING_TYPE_OUTER) {
            const base = estateType === SHOWING_TYPE_INNER ? ESTATE_BASE_MAIN : ESTATE_BASE_MLS;

            (async function () {
                if (Number(estate_id) > 0) {
                    await estateStore.fetchItem(estate_id, base);
                    const estate = estateStore.getItem(estate_id).item;
                    if (!estate) {
                        return;
                    }

                    if (!editing && estate.major_users) {
                        estate.major_users.forEach(majorUser => dealStore.addUserFee(item_id, majorUser));
                    }

                    dealStore.setEditingItem(item_id, { price: estate.price, currency: estate.currency, estate });

                    // автоматически подставляем продавцов только при создании, а не редактирование сделки:
                    if (!editing && Number(estate.contact_id) > CREATING_ITEM_ID) {
                        dealStore.changeParticipant(DEAL_SELLER, DEAL_PARTICIOANT_ADD, item_id, Number(estate.contact_id));
                    }
                } else {
                    dealStore.setEditingItem(item_id, { price: 0, currency: 1, estate: null });
                }
            })();
        }
    };

    handleChangeEstateType: HandleChangeType = (event, { value }) => {
        dealStore.setEditingItem(this.props.item_id, {
            estateType: value,
            estate_id: null,
            estateOuterUrl: null,
            isExclusive: false
        });
    };

    render() {
        const { item_id, handleChange, editing, handleCheckbox, type } = this.props;
        const { estate_id, estateType, estateOuterUrl, isExclusive } = dealStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Grid stackable spacing={0}>
                    <Grid.Column width={editing ? 6 : 3}>
                        <div style={{ marginBottom: '10px' }} className="crm-Flex__verticalCenter">
                            <label className="crm-Item__labelWide">Объект</label>
                            &nbsp;&nbsp;
                            <Select
                                name="estateType"
                                value={estateType}
                                onChange={this.handleChangeEstateType}
                                options={estateL10n.SHOWING_TYPES_DROPDOWN}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={editing ? 6 : 2}>
                        {type === ESTATE_TYPE_SELL_ID && estateType === 1 && (
                            <Switch
                                checked={Boolean(estate_id && isExclusive)}
                                name="isExclusive"
                                label={<label>Эксклюзив</label>}
                                handleChange={handleCheckbox}
                                disabled={!estate_id}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column width={editing ? 12 : 7}>
                        {estateType < 3 && (
                            <EstateIDBlockEditing
                                isMls={estateType === SHOWING_TYPE_MLS}
                                item_id={item_id}
                                store={dealStore}
                                editing={editing || estate_id === null}
                                value={Number(estate_id)}
                                onChange={this.handeEstateChange}
                                clearable
                            />
                        )}
                        {estateType === 3 && (
                            <TextInput
                                required
                                name="estateOuterUrl"
                                value={estateOuterUrl}
                                onChange={handleChange}
                                label="URL на объект или Адрес"
                                variant="classic"
                            />
                        )}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealPropertyEditing);
