import React from 'react';
import { KpiPoint } from '~/types/kpi.types';

import { TableCell, SortDirection } from '~ui/Table';
import statisticsL10n from '~/L10n/statistics.L10n';

import Tooltip from '@material-ui/core/Tooltip';

const daysTitle = (days: number): string => {
    switch (days) {
        case 1001:
            return 'сегодня';
        case 1011:
            return 'неделя';
        case 1111:
            return 'месяц';
        case 2001:
            return 'год';
        default:
            return `${days} д.`;
    }
};

const TableHeadPoint = ({
    point,
    sortPoint,
    sortDirection,
    setSort
}: {
    point: Partial<KpiPoint>;
    sortPoint: number;
    sortDirection: SortDirection;
    setSort: (point_id: number) => void;
}) => {
    const { point_id, days, pointType } = point;
    return (
        <TableCell
            key={point_id}
            align="right"
            sortDirection={sortPoint === point_id ? sortDirection : false}
            onClick={() => setSort(point_id)}
        >
            <Tooltip title={statisticsL10n.KPI_POINT_TYPE_FIND(pointType)}>
                <span>
                    <span className="crm-Estate__fieldNowrap">{statisticsL10n.KPI_POINT_TYPE_SHORT_FIND(pointType)}</span>
                    {![41, 42, 70].includes(pointType) && <span className="crm-Estate__fieldNowrap">({daysTitle(days)})</span>}
                </span>
            </Tooltip>
        </TableCell>
    );
};

export default TableHeadPoint;
