import React, { useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { checkFormValid } from '~/common/forms';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';
import developerStore from '~/stores/lifeComplexes/developerStore';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import StorageIcon from '@material-ui/icons/Storage';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import Progress from '~ui/Progress';
import { Modal, ModalContent, ModalBtnAction } from '~ui/Modal';
import Grid from '~ui/Grid';

import LifeComplexFeedOtherFields from './Blocks/LifeComplexFeedOtherFields';
import LifeComplexFeedUrlEditing from './Blocks/LifeComplexFeedUrlEditing';
import LifecomplexFeedsList from './Blocks/LifeComplexFeedsList';

const LifecomplexFeedModal = ({ developer_id, onClose }: { developer_id: number; onClose: () => void }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [saving, setSaving] = useState(false);
    const [tab, setTab] = useState<'main' | 'newbuildings' | 'feeds'>('main');

    useMemo(() => {
        developerStore.fetchItem(developer_id);
    }, [developer_id]);

    const commitChanges = () => {
        const { errors } = developerStore.getItem(developer_id);
        if (!errors.length) {
            developerStore.fetchList();
            onClose();
        }
    };

    const handleSave = async () => {
        if (checkFormValid(formRef.current, true)) {
            if (developer_id === CREATING_ITEM_ID) {
                await developerStore.createItem();
            } else {
                setSaving(true);
                await developerStore.saveItem(developer_id);
                setSaving(false);
            }

            commitChanges();
        }
    };

    const handleDelete = async () => {
        developerStore.setEditingItem(developer_id, {
            enable: false
        });
        await developerStore.saveItem(developer_id);

        commitChanges();
    };

    const { editingItem, item, loadingItem, errors, property } = developerStore.getItem(developer_id);
    const { feedsCount } = item || {};
    // const { loadingFeedInfo, developerFeedInfo } = property;

    const modalActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
        {
            onClick: handleSave,
            label: developer_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            loading: loadingItem || saving,
            color: 'primary',
            variant: 'outlined',
            startIcon: <SaveIcon />
        }
    ];

    if (developer_id > CREATING_ITEM_ID) {
        modalActions.splice(1, 0, {
            onClick: handleDelete,
            label: 'Удалить',
            loading: loadingItem || saving,
            variant: 'text',
            color: 'secondary',
            startIcon: <DeleteIcon />
        });
    }

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={tab === 'main' ? modalActions : []}
            fullScreen={commonStore.isMobile}
            loading={loadingItem || saving}
            header={
                <>
                    <StorageIcon /> &nbsp; Фид застройщика
                </>
            }
        >
            <ModalContent>
                <ListError errors={Array.from(errors || [])} />

                <form ref={formRef}>
                    {developer_id === CREATING_ITEM_ID && (
                        <Grid stackable style={{ display: tab !== 'main' ? 'none' : undefined }}>
                            <LifeComplexFeedUrlEditing developer_id={developer_id} developer_feed_id={CREATING_ITEM_ID} />
                        </Grid>
                    )}
                    {developer_id !== CREATING_ITEM_ID && (
                        <Tabs
                            value={tab}
                            onChange={(_, value) => setTab(value)}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                        >
                            <Tab value="main" label="Застройщик" />
                            <Tab
                                value="feeds"
                                label={
                                    <Badge badgeContent={feedsCount} color="secondary">
                                        Фиды
                                    </Badge>
                                }
                            />
                        </Tabs>
                    )}
                    {!loadingItem && (
                        <Grid stackable style={{ display: tab !== 'main' ? 'none' : undefined }}>
                            <LifeComplexFeedOtherFields developer_id={developer_id} />
                        </Grid>
                    )}
                    {tab === 'feeds' && <LifecomplexFeedsList developer_id={developer_id} />}
                    {/*{tab === 'newbuildings' && <LifeComplexFeedNewbuildings developer_id={developer_id} />}*/}
                </form>
                {loadingItem && <Progress show />}
            </ModalContent>
        </Modal>
    );
};

export default observer(LifecomplexFeedModal);
