import React from 'react';
import { Link } from 'react-router-dom';
import { DealLinkType } from '~/types/deals.types';
import { ESTATE_TYPE_RENT, ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL } from '~/types/estate.types';
import { DEAL_TYPE_EXCLUSIVE, DEAL_TYPE_EXCLUSIVE_ID } from '~/types/deals.types';
import dealL10n from '~/L10n/deals.L10n';

type DealLinksProps = {
    deal: DealLinkType;
    isMls?: boolean;
    currentInChain?: boolean;
};

export const DealLinkState = (deal_id: number, param: string, tabName?: string) => ({
    pathname: `/deals/${param}/overview/${deal_id}${tabName ? `/${tabName}` : ''}`,
    state: { modal: true, switchFromTab: false }
});

const DealLink = ({ deal: { deal_id, type, enable }, currentInChain }: DealLinksProps) => (
    <Link
        to={DealLinkState(
            deal_id,
            type === DEAL_TYPE_EXCLUSIVE_ID ? DEAL_TYPE_EXCLUSIVE : type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL
        )}
    >
        <span style={{ whiteSpace: 'nowrap', fontWeight: currentInChain ? 'bold' : null }} className={enable ? '' : 'crm-Item__disabled'}>
            Договор ({dealL10n.DEAL_ESTATE_TYPE_FIND(type)}) №{deal_id}
        </span>
    </Link>
);

export default DealLink;
