import React, { useState } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import '../../../../node_modules/filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import '../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import '../../../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../../../node_modules/dropzone/dist/min/dropzone.min.css';

import config from '~/config/ui.config';
import serverConfig from '~/config/server.config';
import { authHeaders } from '~/common/fetchApi';

import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginFileValidateType);

const MAX_PHOTOS_COUNT = 20;

const PhotosUpload = ({ onLoadFile }: { onLoadFile: (error: any, file: { serverId: string }) => void }) => {
    const [files, setFiles] = useState<string[]>([]);

    const onProcessFiles = () => {
        setFiles([]);
    };

    const onUpdateFiles = fileItems => {
        setFiles(fileItems.map(fileItem => fileItem.file.name));
    };

    return (
        <FilePond
            files={files}
            allowMultiple
            maxFiles={MAX_PHOTOS_COUNT}
            server={{
                url: serverConfig.photoUploadUrl,
                process: {
                    method: 'POST',
                    withCredentials: false,
                    headers: authHeaders(),
                    timeout: 60000
                }
            }}
            onupdatefiles={onUpdateFiles}
            onactivatefile={file => console.log(file)}
            name="file"
            onprocessfile={onLoadFile}
            onprocessfiles={onProcessFiles}
            imageResizeTargetWidth={config.estatePhotos.sizes[0].width}
            labelIdle={`<div data-filetype=".jpg" class="filepicker-file-icon"></div><div data-filetype=".jpeg" class="filepicker-file-icon"></div><div>Перенесите сюда фотографии или <span class="filepond--label-action"> Выберите в папке </span> Не более ${MAX_PHOTOS_COUNT} файлов за раз</div>`}
            labelInvalidField={`Поле содержит недопустимые файлы`}
            labelFileWaitingForSize={`Ожидание размера файла`}
            labelFileSizeNotAvailable={`Размер файла недопустим`}
            labelFileLoading={`Загрузка`}
            labelFileLoadError={`Ошибка при загрузке`}
            labelFileProcessing={`Отправка`}
            labelFileProcessingComplete={`Отправка завершена`}
            labelFileProcessingAborted={`Отправка прервана`}
            labelFileProcessingError={`Ошибка при отправке`}
            labelTapToCancel={`Нажмите для отмены`}
            labelTapToRetry={`Нажмите для повтора`}
            labelTapToUndo={`Нажмите для отката назад`}
            labelButtonRemoveItem={`Удалить`}
            labelButtonAbortItemLoad={`Прервать`}
            labelButtonRetryItemLoad={`Повторить`}
            labelButtonAbortItemProcessing={`Отменить`}
            labelButtonUndoItemProcessing={`Откатить`}
            labelButtonRetryItemProcessing={`Повторить`}
            labelButtonProcessItem={`Отправить`}
            maxParallelUploads={2}
            imageTransformOutputQuality={100}
            allowRevert={false}
            allowRemove={false}
            itemInsertLocation={'after'}
            acceptedFileTypes={['image/*']}
            labelFileTypeNotAllowed="Неверный формат"
            fileValidateTypeLabelExpectedTypes=""
        />
    );
};

export default PhotosUpload;
