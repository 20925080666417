import React, { Component, Fragment } from 'react';
import { ComponentType } from 'react';

import NotEnougAccess from '../Base/NotEnoughAccess';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import ItemErrors from './ItemErrors';
import { CREATING_ITEM_ID, ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import { ESTATE_BASES } from '~/types/estate.types';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import { ErrorMessage, MessageTitle } from '~ui/Message';
import * as crmChangesApi from '~/api/crmChangesApi';

type ItemWrapperProps = {
    match: {
        params: {
            item_id?: string;
            tabName?: string;
            base?: ESTATE_BASES;
            param?: string;
        };
    };
};

export type ItemWrapperState = {
    item_id: number;
    operation: 'show' | 'add';
    tabName: string;
    base: ESTATE_BASES;
    param: string;
    isError: boolean;
};

export const TAB_ID_DEFAULT = 'main';
export const TAB_ID_CREATING = 'add';
export const TAB_ID_HISTORY = 'history';

const OPERATION_ID_SHOW = 'show';
const OPERATION_ID_CREATING = 'add';

const ItemWrapper = <TProps extends ItemWrapperProps>(
    store: ListStoreInterface | ItemStoreInterface<any>,
    WrappedComponent: ComponentType<ItemWrapperState>,
    checkModuleAccess?: () => boolean
) => {
    @observer
    class ItemWrapperClass extends Component<TProps, ItemWrapperState> {
        constructor(props: TProps) {
            super(props);

            const item_id = Number(props.match.params[store.item_id]);
            const { tabName, base, param } = props.match.params;

            this.state = {
                item_id,
                operation: item_id > CREATING_ITEM_ID ? OPERATION_ID_SHOW : OPERATION_ID_CREATING,
                tabName: item_id === CREATING_ITEM_ID ? TAB_ID_CREATING : tabName || TAB_ID_DEFAULT,
                base,
                param,
                isError: false
            };

            store.fetchItem(item_id, base);
        }

        componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
            console.log('ItemWrapper', error);
            console.log('errorInfo', errorInfo);
            crmChangesApi.sendUiError(error, errorInfo, 'ItemWrapper');
            this.setState({ isError: true });
        }

        static getDerivedStateFromProps(nextProps: TProps, prevState: ItemWrapperState) {
            const item_id = Number(nextProps.match.params[store.item_id]);
            let { tabName, base, param } = nextProps.match.params;
            tabName = item_id === CREATING_ITEM_ID ? TAB_ID_CREATING : tabName || TAB_ID_DEFAULT;

            let nextState = { ...prevState };

            if (item_id !== prevState.item_id) {
                store.fetchItem(item_id, base);

                nextState = {
                    ...nextState,
                    item_id,
                    operation: item_id > CREATING_ITEM_ID ? OPERATION_ID_SHOW : OPERATION_ID_CREATING,
                    tabName,
                    base,
                    param
                };
            } else if (tabName !== prevState.tabName) {
                nextState = {
                    ...nextState,
                    tabName
                };
            }

            return nextState;
        }

        checkAccess = (): boolean => {
            const item_id = Number(this.props.match.params[store.item_id]);

            if (item_id === CREATING_ITEM_ID) {
                return authStore.canCreate(store.moduleName);
            }

            const { item } = store.getItem(item_id);
            // const owner_id = item ? (item.major_user ? item.major_user.user_id : item.user_id) : -1;
            // return authStore.canRead(store.moduleName, owner_id);

            if (item && item.restrictedAccess) {
                return true;
            }

            const owner_ids = item
                ? item.major_user_ids && item.major_user_ids.length
                    ? Array.from(item.major_user_ids)
                    : [item.major_user_id || item.user_id || -1]
                : [-1];
            return owner_ids.some((owner_id: number) => authStore.canRead(store.moduleName, owner_id));
        };

        render() {
            const { item_id, isError } = this.state;

            if (isError) {
                return (
                    <ErrorMessage>
                        <MessageTitle>Произошла ошибка в CRM</MessageTitle>
                        Попробуйте закрыть эту карточку
                    </ErrorMessage>
                );
            }

            const { item, errors, loadingItem } = store.getItem(item_id);

            if (item_id !== CREATING_ITEM_ID && errors && errors.length && !item) {
                return <ItemErrors errors={errors} />;
            }

            return (checkModuleAccess ? checkModuleAccess() : this.checkAccess()) || loadingItem ? (
                <WrappedComponent {...this.state} />
            ) : (
                <NotEnougAccess />
            );
        }
    }

    return ItemWrapperClass;
};

export default ItemWrapper;
