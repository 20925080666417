import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Search, Button, Icon, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import estateStore from '~/stores/estateStore';
import { ESTATE_PROPERTY_TYPE_BUSINESS, ESTATE_PROPERTY_TYPE_INDUSTRY, EstateAddress, MetroDistance } from '~/types/estate.types';
import FindNewBuildingBlockEditing from './FindNewBuildingBlockEditing';
import HighwayEditing from './HighwayEditing';
import MetrosBlockEditing from './MetrosBlockEditing';
import YandexHouseBlock from './SubBlocks/YandexHouseBlock';
import AvitoHouseBlock from './SubBlocks/AvitoHouseBlock';
import AddressDetailsEditing from './SubBlocks/AddressDetailsEditing';
import AddressMapEditing from './SubBlocks/AddressMapEditing';
import MoscowmapDetails from './SubBlocks/MoscowmapDetails';
import AddressSearchInput, { addressEmpty } from '~/components/Items/Common/AddressSearchInput';

import PreventDblClick from '~/components/Base/PreventDblClick';
import deepCopy from '~/common/deepCopy';
import {
    ESTATE_PROPERTY_COUNTRY_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_NEWBUILDINGS_ARR
} from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type AddressBlockEditingState = {
    searchAddressValue: number | null;
    isMapModalOpen: boolean;
    address: string;
    geo_lat: number;
    geo_lon: number;
    editingAddress: boolean;
    showAddressDetails: boolean;
    searchAddressQuery: string;
};

type AddressBlockEditingProps = {
    estate_id: number;
    editing?: boolean;
    address?: string;

    fias?: string | null;
    kladr?: string | null;
    geo_lat?: number | null;
    geo_lon?: number | null;

    street?: string | null;
    streetType?: string | null;
    houseNumber?: string | null;
    houseNumberType?: string | null;
    blockNumber?: string | null;
    blockNumberType?: string | null;

    town?: string | null;
    townType?: string | null;
    region?: string | null;
    regionType?: string | null;
    district?: string | null;
    cityDistrict?: string | null;
    districtType?: string | null;
    cityDistrictType?: string | null;
    settlement?: string | null;
    settlementType?: string | null;
    postcode?: number | null;

    newbuilding_id?: number | null;
    newbuilding_house_id?: number | null;

    propertyType?: number;

    metros?: Array<MetroDistance>;
    main_metro_id?: number;
};

@observer
class AddressBlockEditing extends Component<AddressBlockEditingProps, AddressBlockEditingState> {
    constructor(props: AddressBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.estate_id,
            address: props.address || '',

            fias: props.fias || null,
            kladr: props.kladr || null,
            geo_lat: props.geo_lat || null,
            geo_lon: props.geo_lon || null,

            street: props.street || null,
            streetType: props.streetType || null,
            houseNumber: props.houseNumber || null,
            houseNumberType: props.houseNumberType || null,
            blockNumber: props.blockNumber || null,
            blockNumberType: props.blockNumberType || null,

            town: props.town || null,
            townType: props.townType || null,
            region: props.region || '',
            regionType: props.regionType || null,
            district: props.district || null,
            cityDistrict: props.cityDistrict || null,
            districtType: props.districtType || null,
            cityDistrictType: props.cityDistrictType || null,
            settlement: props.settlement || null,
            settlementType: props.settlementType || null,
            postcode: props.postcode || null,

            metros: props.metros || [],
            main_metro_id: props.main_metro_id || 0,
            newbuilding_id: props.newbuilding_id || null,
            newbuilding_house_id: props.newbuilding_house_id || null,

            propertyType: props.propertyType || 1
        };

        this.state = {
            geo_lat: Number(estate.geo_lat),
            geo_lon: Number(estate.geo_lon),

            searchAddressQuery: '',
            searchAddressValue: null,
            isMapModalOpen: props.estate_id === CREATING_ITEM_ID,
            address: '',
            editingAddress: false,
            showAddressDetails: false
        };

        estateStore.setEditingItem(estate.estate_id, estate);
        estateStore.setProperty(props.estate_id, { searchAddressQuery: props.address || '' });
    }

    // showYandexMap = () => {
    //     const {
    //         editingItem: { geo_lat, geo_lon, address }
    //     }: { editingItem: Estate } = estateStore.getItem(this.props.estate_id);
    //
    //     const initMap = (geo_lat: number, geo_lon: number) =>
    //         initYandexMap(YandexMapId, geo_lat, geo_lon, address, (geo_lat: number, geo_lon: number, address: string) => {
    //             this.setState({
    //                 geo_lon,
    //                 geo_lat,
    //                 address
    //             });
    //         });
    //
    //     if (!geo_lat || !geo_lon) {
    //         navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
    //             initMap(latitude, longitude);
    //         });
    //     } else {
    //         initMap(geo_lat, geo_lon);
    //     }
    // };

    toggleMapModal = (event: SyntheticEvent) => {
        event.preventDefault();

        this.setState({
            isMapModalOpen: !this.state.isMapModalOpen
        });
    };

    // handleSaveMapClick = async (event: SyntheticEvent<HTMLButtonElement>) => {
    //     const { geo_lon, geo_lat, address } = this.state;
    //     estateStore.setEditingItem(this.props.estate_id, {
    //         geo_lon,
    //         geo_lat,
    //         address
    //     });
    //
    //     estateStore.setEditingItem(this.props.estate_id, { searchAddressQuery: address });
    //
    //     await estateStore.fetchAddressCount(address, 1);
    //
    //     const { addresses } = estateStore;
    //     estateStore.setEditingItem(this.props.estate_id, { searchAddressQuery: addresses[0].value });
    //     this.findOnceAddress(addresses[0].value);
    //
    //     this.toggleMapModal(event);
    // };

    toggleEditingAddress = () => {
        this.setState({ editingAddress: !this.state.editingAddress });
    };

    handleToggleAddressDetails = () => {
        this.setState({ showAddressDetails: !this.state.showAddressDetails });
    };

    handleGetAddressFromYandex = () => {
        const { estate_id } = this.props;
        const { geo_lat, geo_lon } = this.state;
        estateStore.setAddressCoordinates(estate_id, geo_lat, geo_lon);
    };

    handleAddressChange = (address: EstateAddress | null) => {
        const { estate_id } = this.props;

        if (address) {
            estateStore.setEditingItem(estate_id, { ...address });
            estateStore.fetchNearestMetro(estate_id, address.geo_lon || 0, address.geo_lat || 0, address.region);
            estateStore.findMOSCOWMAPHouse(estate_id);
            estateStore.fetchNearestNewbuildings(estate_id, 5);
            this.setState({
                geo_lon: address.geo_lon || 0,
                geo_lat: address.geo_lat || 0,
                address: address.address
            });
        } else {
            estateStore.setEditingItem(estate_id, { ...addressEmpty });
            this.setState({
                address: ''
            });
        }
    };

    render() {
        const { estate_id, editing } = this.props;
        const { geo_lat, geo_lon } = this.state;

        const {
            item,
            editingItem: { metros, main_metro_id, newbuilding_house_id, newbuilding_id, propertyType, address, houseNumber, blockNumber },
            property: { moscowmapHouse }
        } = estateStore.getItem(estate_id);

        const { isMapModalOpen, editingAddress, showAddressDetails } = this.state;

        return (
            <PreventDblClick>
                <div className="crm-Item__editingMode">
                    <div
                        className={cs({
                            'crm-Estate__address': true,
                            'crm-Estate__address_nebuildingsOnly':
                                ESTATE_PROPERTY_TYPE_NEWBUILDINGS_ARR.includes(propertyType) && !editingAddress,
                            'crm-Estate__address_editing': editing
                        })}
                    >
                        {(!ESTATE_PROPERTY_TYPE_NEWBUILDINGS_ARR.includes(propertyType) || editingAddress) && (
                            <div className="crm-Estate__address_address">
                                <label className="crm-Estate__field_label">
                                    Адрес &nbsp;&nbsp;&nbsp;
                                    <Checkbox onClick={this.handleToggleAddressDetails} label="детали" checked={showAddressDetails} />
                                    {editing && geo_lat > 0 && geo_lon > 0 && (
                                        <Button
                                            icon="crosshairs"
                                            size="mini"
                                            basic
                                            color="orange"
                                            className="crm-Estate__address_yandexAddressBtn"
                                            title="Стянуть адрес с Яндекс Карты"
                                            onClick={this.handleGetAddressFromYandex}
                                        />
                                    )}
                                </label>
                                {ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) && moscowmapHouse && (
                                    <MoscowmapDetails {...moscowmapHouse} />
                                )}
                                <AddressSearchInput
                                    className="crm-Estate__address_find crm-Estate__address_find_address"
                                    value={address}
                                    onChange={this.handleAddressChange}
                                />

                                {/*{editing && (*/}
                                <Button
                                    focus={-1}
                                    icon="marker"
                                    color={houseNumber || blockNumber ? 'green' : 'orange'}
                                    className="crm-Estate__address_map"
                                    onClick={this.toggleMapModal}
                                />
                                {/*)}*/}
                            </div>
                        )}

                        {[
                            ...ESTATE_PROPERTY_FLATS_ARR,
                            ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
                            ESTATE_PROPERTY_TYPE_BUSINESS,
                            ESTATE_PROPERTY_TYPE_INDUSTRY
                        ].includes(propertyType) && (
                            <FindNewBuildingBlockEditing
                                estate_id={estate_id}
                                newbuilding_id={newbuilding_id}
                                newbuilding_house_id={newbuilding_house_id}
                                editing={editing}
                                propertyType={propertyType}
                                address={address}
                            />
                        )}
                        {[...ESTATE_PROPERTY_COUNTRY_ARR, ESTATE_PROPERTY_TYPE_COMMERCE_LAND].includes(propertyType) && (
                            <HighwayEditing editing={editing} item_id={estate_id} {...item} />
                        )}
                    </div>
                    <div>
                        {isMapModalOpen && <AddressMapEditing item_id={estate_id} geo_lat={geo_lat} geo_lon={geo_lon} address={address} />}

                        {ESTATE_PROPERTY_TYPE_NEWBUILDINGS_ARR.includes(propertyType) &&
                            newbuilding_house_id &&
                            newbuilding_house_id > 0 &&
                            !editingAddress && (
                                <div>
                                    <b>Адрес:</b> {address}
                                    &nbsp;&nbsp;
                                    <Icon link name="edit" title="Помнять адрес в ручном режиме" onClick={this.toggleEditingAddress} />
                                </div>
                            )}

                        <AddressDetailsEditing item_id={estate_id} {...this.props} showBlock={showAddressDetails} />

                        <MetrosBlockEditing
                            item_id={estate_id}
                            metros={metros ? deepCopy(Array.from(metros)) : []}
                            main_metro_id={main_metro_id}
                        />

                        {newbuilding_house_id && newbuilding_house_id > 0 && (
                            <Fragment>
                                <YandexHouseBlock estate_id={estate_id} />
                                <AvitoHouseBlock estate_id={estate_id} />
                            </Fragment>
                        )}
                    </div>
                </div>
            </PreventDblClick>
        );
    }
}

export default AddressBlockEditing;
