import React from 'react';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';

import Grid from '~ui/Grid';

import DocumentsTable from '~/components/Documents/DocumentsTable';

const MortgagePersonDocuments = ({ item_id }) => {
    return (
        <Container maxWidth="md">
            <Grid stackable>
                <Grid.Column width={12}>
                    <DocumentsTable item_id={item_id} item_type="mortgagePerson" />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default MortgagePersonDocuments;
