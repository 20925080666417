import React, { Component, ChangeEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';

import Divider from '~ui/Divider';
import Select from '~ui/Select';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import requestStore from '~/stores/requestStore';
import tagStore from '~/stores/tagStore';
import {
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_MORTGAGE,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_SELL,
    RequestFilter
} from '~/types/requests.types';
import ButtonGroup from '~/components/Base/ButtonGroup';
import requestL10n from '~/L10n/request.L10n';

import FilterCell from '~/components/Lists/Common/FilterCell';
import Grid from '@material-ui/core/Grid';
import DateRangeSelect, { DATES_RANGE_VALUE } from '~ui/DateRangeSelect';

@observer
class FilterRequestsMoreDetails extends Component<FilterMoreDetailsProps, {}> {
    handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        requestStore.changeFilter(event.target.name as keyof RequestFilter, event.target.checked);
    };

    handleChange = (event, { type, name, value }: { type: string; name: keyof RequestFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        // @ts-ignore
        requestStore.changeFilter(name, val);
    };

    handleTagsIds = (event: React.SyntheticEvent, { value }: { value: number[] }) => {
        requestStore.changeFilter('tags_ids', value);
    };

    handleChangeStatus = (event: React.SyntheticEvent, { value }: { value: Array<number> }) => {
        requestStore.changeFilter('status', value);
    };

    handleTimesChange = ({ startTime, endTime, deltaTime }: DATES_RANGE_VALUE) => {
        requestStore.changeFilter('deltaTime', deltaTime);
        requestStore.changeFilter('startTime', startTime);
        requestStore.changeFilter('endTime', endTime);
    };

    render() {
        const { allTagsDropdown, loadingFetchAllTags } = tagStore;
        const { tags_ids, tagsTogether, status, type } = requestStore.listFilter;

        return (
            <div>
                <Dropdown
                    multiple
                    selection
                    options={allTagsDropdown}
                    search
                    placeholder="Тэги контактов"
                    value={tags_ids}
                    noResultsMessage="Тэг не найден"
                    onChange={this.handleTagsIds}
                    loading={loadingFetchAllTags}
                />

                <FormControlLabel
                    control={<Checkbox checked={tagsTogether} onChange={this.handleCheckbox} name="tagsTogether" />}
                    label="тэги вместе"
                    style={{ marginLeft: '1em' }}
                />

                <Divider />

                <ButtonGroup
                    buttonSet={[
                        [0, 'Все'],
                        [REQUEST_TYPE_SELL, 'Продажа'],
                        [REQUEST_TYPE_RENT, 'Сдача'],
                        [REQUEST_TYPE_BUY, 'Покупка'],
                        [REQUEST_TYPE_HIRE, 'Съем'],
                        [REQUEST_TYPE_NEWBUILDING, 'Новостройка'],
                        [REQUEST_TYPE_MORTGAGE, 'Ипотека']
                    ]}
                    name="type"
                    value={type}
                    handleChange={this.handleChange}
                />

                <Divider />

                <FilterCell title="Период">
                    <DateRangeSelect onChange={this.handleTimesChange} initialValue={0} />
                </FilterCell>

                <Select
                    multiple
                    options={requestL10n.REQUEST_STATUS_DROPDOWN}
                    label="Статус"
                    value={status}
                    onChange={this.handleChangeStatus}
                    fullWidth
                />
            </div>
        );
    }
}

export default FilterRequestsMoreDetails;
