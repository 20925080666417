import React, { Fragment } from 'react';
import moment from 'moment';
import { ReactNode } from 'react';
import TaskLink, { TaskLinkState } from '../Lists/Tasks/TaskLink';
import { ITEM_TYPES, ITEM_TYPES_OBJECT } from '~/types/notifications.types';
import {
    ESTATE_BASE_EXTERNAL,
    ESTATE_BASE_MAIN,
    ESTATE_BASE_MLS,
    ESTATE_BASE_OWNERS,
    ESTATE_TYPE_RENT,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL
} from '~/types/estate.types';
import ShowingLink, { ShowingLinkState } from '../Lists/Showings/ShowingLink';
import DealLink, { DealLinkState } from '../Lists/Deals/DealLink';
import EstateLink, { EstateLinkState, estateLinkTitle } from '../Lists/Estate/EstateLink';
import ContactLink, { ContactLinkState, matchContactTitle } from '../Lists/Contacts/ContactLink';
import UserLink, { UserLinkState } from '../Lists/Users/UserLink';
import WishLink, { WishLinkState, wishTypeTitle } from '../Lists/Wishes/WishLink';
import { DEAL_TYPE_EXCLUSIVE, DEAL_TYPE_EXCLUSIVE_ID } from '~/types/deals.types';
import { RequestLinkState } from '../Lists/Requests/RequestLink';
import { HistoryLocationState } from '~/history';

export const ItemState = ({
    item_type,
    item
}: {
    item_type: ITEM_TYPES;
    item: ITEM_TYPES_OBJECT;
}): { pathname: string; state: HistoryLocationState } => {
    const { estate_id, wish_id, type, task_id, user_id, request_id, showingTime, showing_id, deal_id, contact_id } = item || {};

    switch (item_type) {
        case 'task':
            return task_id ? TaskLinkState(task_id) : null;

        case 'user':
            return user_id ? UserLinkState(user_id) : null;

        case 'contact':
            return contact_id ? ContactLinkState(contact_id) : null;

        case 'estate':
            return estate_id && type
                ? EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, ESTATE_BASE_MAIN)
                : null;

        case 'mls':
            return estate_id && type
                ? EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, ESTATE_BASE_MLS)
                : null;

        case 'owner':
            return estate_id && type
                ? EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, ESTATE_BASE_OWNERS)
                : null;

        case 'externalestate':
            return estate_id && type
                ? EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, ESTATE_BASE_EXTERNAL)
                : null;

        case 'wish':
            return wish_id ? WishLinkState(wish_id) : null;

        case 'showing':
            return showingTime && showing_id ? ShowingLinkState(showing_id) : null;

        case 'request':
            return request_id ? RequestLinkState(request_id, 'list') : null;

        case 'deal':
            return deal_id && type
                ? DealLinkState(
                      deal_id,
                      type === DEAL_TYPE_EXCLUSIVE_ID
                          ? DEAL_TYPE_EXCLUSIVE
                          : type === ESTATE_TYPE_RENT_ID
                          ? ESTATE_TYPE_RENT
                          : ESTATE_TYPE_SELL
                  )
                : null;

        default:
            return null;
    }
};

export const ItemLinkTitle = ({ item_type, item }: { item_type: ITEM_TYPES; item: ITEM_TYPES_OBJECT }) => {
    const {
        estate_id,
        wish_id,
        type,
        propertyType,
        roomsCount,
        address,
        houseNumberAdjusted,
        task_id,
        createTime,
        firstName,
        lastName,
        user_id,
        enable,
        showingTime,
        showing_id,
        request_id,
        deal_id
    } = item || {};

    switch (item_type) {
        case 'task':
            return task_id && createTime ? `Задача №${task_id} (${moment(createTime * 1000).calendar()})` : null;

        case 'user':
            return firstName && lastName && user_id && enable ? `Сотрудник: ${lastName} ${firstName}` : null;

        case 'contact':
            const { contact_id, contactType, companyName } = item || {};
            return contact_id && contactType
                ? 'Контакт: ' + matchContactTitle({ contact_id, contactType, companyName, firstName, lastName, middleName: '' })
                : null;

        case 'estate':
            return estate_id && type && propertyType && address ? 'Листинг: ' + estateLinkTitle(propertyType, roomsCount, address) : null;

        case 'owner':
            return estate_id && type && propertyType && address
                ? 'Собственник: ' + estateLinkTitle(propertyType, roomsCount, address)
                : null;

        case 'externalestate':
            return estate_id && type && propertyType && address
                ? 'Внешний объект: ' + estateLinkTitle(propertyType, roomsCount, address)
                : null;

        case 'wish':
            return wish_id ? `${wishTypeTitle(type)} №${wish_id}` : null;

        case 'showing':
            return `Показ`;

        case 'deal':
            return deal_id && type ? `Сделка №${deal_id}` : null;

        case 'request':
            return request_id && type ? `Заявка №${request_id}` : null;

        default:
            // console.log(item_type);
            return null;
    }
};

const ItemLinkSimple = ({ item_type, item }: { item_type: ITEM_TYPES; item: ITEM_TYPES_OBJECT }) => {
    const {
        estate_id,
        wish_id,
        type,
        propertyType,
        roomsCount,
        address,
        houseNumberAdjusted,
        task_id,
        createTime,
        firstName,
        lastName,
        user_id,
        enable,
        showingTime,
        showing_id,
        deal_id
    } = item || {};

    switch (item_type) {
        case 'task':
            return task_id && createTime ? <TaskLink task={{ task_id, createTime }} /> : null;

        case 'user':
            return firstName && lastName && user_id && enable ? (
                <UserLink user={{ firstName, lastName, middleName: '', user_id, enable }} />
            ) : null;

        case 'contact':
            const { contact_id, contactType, companyName } = item || {};
            return contact_id && contactType ? (
                <ContactLink
                    contact={{
                        contact_id,
                        contactType,
                        companyName: companyName || '',
                        firstName: firstName || '',
                        lastName: lastName || '',
                        middleName: ''
                    }}
                />
            ) : null;

        case 'estate':
            return estate_id && type && propertyType && address ? (
                <EstateLink
                    estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, enable, houseNumberAdjusted }}
                    base={ESTATE_BASE_MAIN}
                />
            ) : null;

        case 'mls':
            return estate_id && type && propertyType && address ? (
                <EstateLink
                    estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, houseNumberAdjusted }}
                    base={ESTATE_BASE_MLS}
                />
            ) : null;

        case 'owner':
            return estate_id && type && propertyType && address ? (
                <EstateLink
                    estate={{ estate_id, type, propertyType, roomsCount: roomsCount || null, address, houseNumberAdjusted }}
                    base={ESTATE_BASE_OWNERS}
                />
            ) : null;

        case 'wish':
            return wish_id && type ? <WishLink wish={{ wish_id, enable: Boolean(enable), type }} /> : null;

        case 'showing':
            return showingTime && showing_id ? <ShowingLink showing={{ showingTime, showing_id }} /> : null;

        case 'deal':
            return deal_id && type ? <DealLink deal={{ deal_id, type, enable: Boolean(enable) }} /> : null;

        default:
            return null;
    }
};

export default ItemLinkSimple;
