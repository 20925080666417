import React, { useMemo, useState } from 'react';
import * as avitoPackagesApi from '~/api/export/avitoPackagesApi';

import Typography from '@material-ui/core/Typography';

import Modal, { ModalContent, useToggle } from '~ui/Modal';
import Table, { TableCell, TableHead, TableRow, TableBody } from '~ui/Table';

import { AvitoXLPrices } from '~/types/exports.types';
import SkeletonTable from '~ui/SkeletonTable';
import ListError from '~/components/ListError';
import Button from '~ui/Button';
import { AvitoPackageApply } from '~/api/export/avitoPackagesApi';
import Time from '~/components/Base/Time';

const avitoXLPrices = ['x2_1', 'x2_7', 'x5_1', 'x5_7', 'x10_1', 'x10_7'] as const;

const AvitoXlTariffsModal = ({ estate_id, onClose }: { estate_id: number; onClose: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [xlPrices, setXLPrices] = useState<null | AvitoXLPrices>(null);

    useMemo(() => {
        avitoPackagesApi
            .fetchAvitoXLPrices(estate_id)
            .then(setXLPrices)
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, []);

    const applyAvitoXLPrice = async (package_id: keyof AvitoXLPrices) => {
        setErrors([]);
        setLoading(true);
        try {
            await avitoPackagesApi.applyAvitoXLPrice(estate_id, package_id);
            setTimeout(onClose, 0);
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal maxWidth="md" onClose={onClose} header="Подключение пакетов XL-продвижения в Avito" loading={loading}>
            <ModalContent>
                <ListError errors={errors} />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Продвижение</TableCell>
                            <TableCell>Значение</TableCell>
                            <TableCell colSpan={2}>Цена для данного объекта</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && !xlPrices && <SkeletonTable columns={4} rows={6} />}
                        {xlPrices &&
                            avitoXLPrices.map(package_id => {
                                const matched = package_id.match(/x(\d+)\_(\d+)/);

                                return (
                                    <TableRow key={package_id}>
                                        <TableCell>
                                            <Typography color="secondary" variant="h6">
                                                {package_id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">До {matched[1]} раз больше просмотров</Typography>
                                            <Typography variant="subtitle2">
                                                На {matched[2]} {matched[2] === '1' ? 'день' : 'дней'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{xlPrices[package_id]} р.</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                onClick={() => applyAvitoXLPrice(package_id)}
                                            >
                                                Подключить
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </ModalContent>
        </Modal>
    );
};

const AvitoXlTariffs = ({ estate_id, canPushHugePrice }: { estate_id: number; canPushHugePrice: boolean }) => {
    const [loading, setLoading] = useState(true);
    const [xlPackage, setXlPackage] = useState<AvitoPackageApply | null>(null);
    const [showAvitoModal, toggleAvitoModal] = useToggle();

    const updatePackageData = () => {
        avitoPackagesApi
            .checkAvitoEnabledPackage(estate_id)
            .then(setXlPackage)
            .finally(() => setLoading(false));
    };

    useMemo(() => {
        updatePackageData();
    }, [estate_id]);

    const onCloseModal = () => {
        updatePackageData();
        toggleAvitoModal();
    };

    return (
        <div style={{ display: 'inline-block', marginLeft: '1rem', whiteSpace: 'nowrap' }}>
            {xlPackage && (
                <Typography style={{ display: 'inline' }} color="secondary" variant="subtitle1">
                    Подключен <b>{xlPackage.package_id}</b> до <Time time={xlPackage.expireTime} />
                </Typography>
            )}
            {!xlPackage && canPushHugePrice && (
                <Button
                    tooltip="Подключить доп.пакет Avito XL"
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={toggleAvitoModal}
                    disabled={loading}
                >
                    Авито XL
                </Button>
            )}
            {showAvitoModal && <AvitoXlTariffsModal estate_id={estate_id} onClose={onCloseModal} />}
        </div>
    );
};

export default AvitoXlTariffs;
