import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';

import { YANDEX_API_KEY } from '~/types/settings.types';
import ApiSettingsWrapper from '~/components/Ads/BaseApiSettings/ApiSettingsWrapper';
import YandexApiSettingsField from '~/components/Ads/BaseApiSettings/Fields/YandexApiSettingsField';

const YandexApiSettings = () => {
    return (
        <ApiSettingsWrapper apiKey={YANDEX_API_KEY} FieldComponent={YandexApiSettingsField} defaultSettingsValue={{ OAuth: '' }}>
            <Typography>
                Чтобы воспользоваться API, нужно получить OAuth-токен. Для этого следует авторизоваться на Яндексе тем логином, который
                используется для доступа в личный кабинет Яндекс.Недвижимости, и открыть ссылку:
            </Typography>
            <a
                href="https://oauth.yandex.ru/authorize?response_type=token&client_id=aa4eae0f50244d9aae9c864b349e1859"
                target="_blank"
                rel="noreferrer"
            >
                https://oauth.yandex.ru/authorize?response_type=token&client_id=aa4eae0f50244d9aae9c864b349e1859
            </a>
            <Typography>
                Нужно разрешить приложению доступ, после чего появится последовательность из букв и цифр. Это и есть OAuth-токен, который в
                дальнейшем понадобится для работы с API
            </Typography>
        </ApiSettingsWrapper>
    );
};

export default observer(YandexApiSettings);
