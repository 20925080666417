import React, { useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { AGENT_BONUS_TYPE_FIXED, AGENT_BONUS_TYPE_PERCENT } from '~/types/estate.types';
import { PriceInput, NumberInput } from '~ui/TextInput';
import { Switch } from '~ui/Checkbox';
import { useToggle } from '~ui/Modal';
import ButtonGroup from '~/components/Base/ButtonGroup';
import estateL10n from '~/L10n/estate.L10n';
import Box from '@material-ui/core/Box';

type AgentBonusBlockEditingProps = HandlerEditingWrappedProps & {
    agentBonusType: 1 | 2;
    agentBonusValue: number | null;
    agentBonusCurrency: 1 | 2 | 3;
};

const AgentBonusBlockEditing = (props: AgentBonusBlockEditingProps) => {
    useMemo(() => {
        const estate = {
            estate_id: props.item_id,
            agentBonusType: props.agentBonusType || AGENT_BONUS_TYPE_FIXED,
            agentBonusValue: props.agentBonusValue || null,
            agentBonusCurrency: props.agentBonusCurrency || 1
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }, []);

    const [showAgentBonus, toggleAgentBonus] = useToggle(Boolean(props.agentBonusType));
    const handleToggleBonus = () => {
        const { item_id } = props;
        if (showAgentBonus) {
            estateStore.setEditingItem(item_id, {
                agentBonusType: null,
                agentBonusValue: null
            });
        } else {
            estateStore.setEditingItem(item_id, {
                agentBonusType: AGENT_BONUS_TYPE_FIXED
            });
        }
        toggleAgentBonus();
    };

    const { agentBonusType, agentBonusValue, agentBonusCurrency } = estateStore.getItem(props.item_id).editingItem;
    const { handleChange, handlePrice, editing } = props;

    return (
        <Grid columns={editing ? 1 : 2}>
            <Grid.Column>
                <Switch label="Бонус агенту" checked={showAgentBonus} onChange={handleToggleBonus} />
            </Grid.Column>
            {showAgentBonus && (
                <Grid.Column>
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <ButtonGroup
                            size="small"
                            buttonSet={estateL10n.AGENT_BONUS}
                            name="agentBonusType"
                            value={agentBonusType}
                            handleChange={handleChange}
                        />
                        {agentBonusType === AGENT_BONUS_TYPE_FIXED && (
                            <PriceInput
                                label="Бонус агенту"
                                name="agentBonusValue"
                                value={agentBonusValue}
                                onChange={handlePrice}
                                variant="classic"
                            />
                        )}
                        {agentBonusType === AGENT_BONUS_TYPE_PERCENT && (
                            <NumberInput
                                label="Бонус агенту"
                                name="agentBonusValue"
                                value={agentBonusValue}
                                onChange={handleChange}
                                variant="classic"
                                max={100}
                                min={0}
                                step={0.1}
                                extraLabel="%"
                            />
                        )}
                    </Box>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default HandlerEditingBlockWrapper(estateStore, observer(AgentBonusBlockEditing));
