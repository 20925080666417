import React, { SyntheticEvent } from 'react';
import { ReactNode } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { getUnixTime, format, parse } from 'date-fns';
import commonStore from '~/stores/commonStore';

registerLocale('ru', ru);

const matchDatesFormats = () => {
    const allDaysFormats = [];
    for (const day of ['dd', 'd']) {
        for (const separator1 of ['.', '-', '/', ' ']) {
            for (const month of ['MM', 'M']) {
                for (const separator2 of ['.', '-', '/', ' ']) {
                    for (const year of ['yyyy', 'yy']) {
                        allDaysFormats.push(`${day}${separator1}${month}${separator2}${year}`);
                    }
                }
            }
        }
    }

    return allDaysFormats;
};

const allFormats = matchDatesFormats();
const allFormatsWithTime = ['dd.MM.yyyy HH:mm', ...allFormats];

const parseAllFormats = (text: string, timeIsAvailable: boolean, onChange: (time: number) => void) => {
    const date = new Date();

    for (const format of allFormats) {
        let resultDate: Date = parse(text, format, date);

        if (timeIsAvailable && !resultDate.getTime()) {
            resultDate = parse(text, format + ' HH:mm', date);
        }

        if (resultDate.getTime()) {
            onChange(getUnixTime(resultDate));
            return;
        }
    }
};

type DatePickerProps = {
    time?: number | null;
    onChange: (time: number) => void;
    timeIntervals?: number;
    showDropdowns?: boolean;
    minDate?: number;
    maxDate?: number;
    customInput?: ReactNode;
    required?: boolean;
    placeholder?: string;
    clearable?: boolean;
    disabled?: boolean;
    withPortal?: boolean;
};

const DatePicker = ({
    time,
    onChange,
    timeIntervals,
    showDropdowns,
    minDate,
    maxDate,
    customInput,
    required,
    placeholder,
    clearable,
    disabled,
    withPortal
}: DatePickerProps) => {
    if (commonStore.isMobile) {
        const date = time === null ? null : time ? new Date(time * 1000) : new Date();
        const value = date ? format(date, 'yyyy-MM-dd') + (timeIntervals ? 'T' + format(date, 'HH:mm') : '') : '';

        return (
            <input
                type={timeIntervals ? 'datetime-local' : 'date'}
                onChange={event => onChange(Math.round(Date.parse(event.target.value) / 1000))}
                value={value}
                style={{ maxWidth: '215px', minWidth: '170px' }}
            />
        );
    }

    return (
        <ReactDatePicker
            name={placeholder}
            locale="ru"
            selected={time === null ? null : time ? new Date(time * 1000) : new Date()}
            placeholderText={placeholder || 'Например: 22.12.2019'}
            onChange={date => onChange(getUnixTime(date || 0))}
            showTimeSelect={Boolean(timeIntervals)}
            timeFormat="HH:mm"
            timeIntervals={timeIntervals}
            dateFormat={timeIntervals ? allFormatsWithTime : allFormats}
            timeCaption="Время"
            autoComplete="off"
            strictParsing
            showMonthDropdown={showDropdowns}
            showYearDropdown={showDropdowns}
            dropdownMode="select"
            minDate={minDate ? new Date(minDate * 1000) : undefined}
            maxDate={maxDate ? new Date(maxDate * 1000) : undefined}
            customInput={
                customInput || (
                    <input
                        type="text"
                        onInput={(event: SyntheticEvent<HTMLInputElement> & { target: HTMLInputElement }) =>
                            parseAllFormats(event.target.value, Boolean(timeIntervals), onChange)
                        }
                    />
                )
            }
            required={required}
            disabled={disabled}
            isClearable={clearable}
            withPortal={withPortal}
        />
    );
};

export default DatePicker;
