import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header, Label, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import MaterialButtonGroup from '@material-ui/core/ButtonGroup';
import PrintIcon from '@material-ui/icons/Print';
import TuneIcon from '@material-ui/icons/Tune';
import MapIcon from '@material-ui/icons/Map';

import ButtonUs from '~ui/Button';

import config from '~/config/ui.config';

import EstateTableBody from './EstateTableBody';
import EstateMobileCardsView from './EstateMobileCardsView';
import ListFooter from '../Common/ListFooter';
import estateStore from '~/stores/estateStore';
import FilterEstate from './FilterEstate';
import FilterEstateRegion from './FilterBlocks/FilterEstateRegion';
import EstateTableHeader from './EstateTableHeader';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import authStore from '~/stores/authStore';
import ButtonGroup, { ButtonInSet } from '../../Base/ButtonGroup';
import { HandleChangeType } from '../../Items/HandlerEditingBlockWrapper';
import ManageEstatesArray from './ManageEstatesArray';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';
import {
    ESTATE_BASE_FAVORITES,
    ESTATE_BASE_GROUP,
    ESTATE_BASE_MAIN,
    ESTATE_BASE_MY,
    ESTATE_BASE_OWNERS,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_TYPE_SELL,
    ESTATE_BASE_FREE,
    ESTATE_BASE_EXTERNAL
} from '~/types/estate.types';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import contactStore from '~/stores/contactStore';
import ListPagination from '../Common/ListPagination';
import shareStore from '~/stores/shareStore';
import EstateMapFilterChips from './MapView/EstateMapFilterChips';
import PreventSwipe from '../../Base/PreventSwipe';
import ShareEstatesButton from './SubBlocks/ShareEstatesButton';
import { adjustEstateListBtns } from '~/components/Lists/Estate';

type EstatesListState = {
    reportsModalOpen: boolean;
    manageEstatesModal: boolean;
    isPrinting: boolean;
};

export const AVAILABLE_NOT_FLAT_BASES = [ESTATE_BASE_MY, ESTATE_BASE_MAIN, ESTATE_BASE_FREE];

const HeaderTypeLabels = ({ type }: { type: number }) => {
    const TriggerSale = (
        <Label size="mini" color="red" horizontal content="Продажа" onClick={() => estateStore.changeFilter('type', type === 0 ? 1 : 0)} />
    );
    const PopupSale = <Popup trigger={TriggerSale} content={type === 0 ? 'Оставить только Продажу' : 'Вернуть Аренду'} />;

    const TriggerRent = (
        <Label
            size="mini"
            color="orange"
            horizontal
            content="Аренда"
            onClick={() => estateStore.changeFilter('type', type === 0 ? 2 : 0)}
        />
    );
    const PopupRent = <Popup trigger={TriggerRent} content={type === 0 ? 'Оставить только Аренду' : 'Вернуть Продажу'} />;

    return (
        <div className="crm-List__filterType_typeLabel">
            {type === 0 && (
                <Fragment>
                    {PopupSale}
                    {PopupRent}
                </Fragment>
            )}
            {type === 1 && PopupSale}
            {type === 2 && PopupRent}
        </div>
    );
};

@observer
class EstatesList extends Component<ListWrapperProps, EstatesListState> {
    constructor(props: ListWrapperProps) {
        super(props);

        this.state = {
            reportsModalOpen: false,
            manageEstatesModal: false,
            isPrinting: false
        };
    }

    handleChangeBase: HandleChangeType = (event, { value }) => {
        estateStore.changeFilterBase(value);
    };

    handleManageEstatesModal = () => {
        this.setState({
            manageEstatesModal: !this.state.manageEstatesModal
        });
    };

    handleSearchOnMap = () => {
        estateStore.toggleSearchOnMap();
    };

    handlePrintBtn = async () => {
        await this.setState({
            isPrinting: true
        });
        await window.print();
        this.setState({
            isPrinting: false
        });
    };

    handleShareEstateIds = async () => {
        const uuid = await shareStore.shareEstateIds();
        if (uuid) {
            const url = `${config.shareUrl}?${uuid}`;
            const win = window.open(url, '_blank');
            win.focus();
        }
    };

    render() {
        const {
            loadingList,
            list,
            listCount,
            loadingCount,
            nextListLoaded,
            pagination,
            listFilter: { base, enable, propertyType, propertyTypes, showExportMajorUser, byCreateTime, type, regionId },
            listErrors,
            selectedItemsIds
        } = estateStore;
        const { isMobile } = commonStore;

        const { orderBy, orderDirection, handleSort, param } = this.props;
        const { isPrinting, reportsModalOpen, manageEstatesModal } = this.state;

        let footerColsCount = showExportMajorUser ? 7 : 6;
        if ([ESTATE_BASE_MY, ESTATE_BASE_GROUP, ESTATE_BASE_OWNERS, ESTATE_BASE_FAVORITES].includes(base)) {
            footerColsCount++;
        }

        return (
            <Fragment>
                <div className={cs({ 'crm-List': true, 'crm-Print__hidden': true })}>
                    <div className="crm-List__filterType">
                        <Header as="h3">
                            <Icon name="building outline" />
                            <Header.Content className="crm-List__filterType_headerContent">
                                <FilterEstateRegion />
                                <HeaderTypeLabels type={type} />
                            </Header.Content>
                        </Header>

                        <div className="crm-List__filterType_topBtns">
                            <ButtonGroup
                                buttonSet={adjustEstateListBtns(propertyType)}
                                name="base"
                                value={base === undefined ? null : base}
                                handleChange={this.handleChangeBase}
                                stackable={isMobile}
                            />

                            <ButtonUs
                                startIcon={<MapIcon />}
                                variant="contained"
                                color="secondary"
                                size="small"
                                disableElevation
                                onClick={this.handleSearchOnMap}
                                style={{ marginLeft: '1rem' }}
                            >
                                карта
                            </ButtonUs>
                            {!enable && <span className="crm-Estate__filter_archiveLabel">(Архив)</span>}
                        </div>

                        {listErrors && <ListError errors={listErrors} />}
                    </div>

                    {authStore.canCreate(estateStore.moduleName) && (
                        <div className="crm-List__addButton">
                            {manageEstatesModal && <ManageEstatesArray onClose={this.handleManageEstatesModal} />}

                            <Button
                                icon
                                labelPosition="left"
                                primary
                                size="tiny"
                                as={Link}
                                to={{
                                    pathname: `/estate/${param || 'sell'}/crm/${CREATING_ITEM_ID}`,
                                    state: { modal: true }
                                }}
                            >
                                <Icon name="building" /> Добавить
                            </Button>

                            {/*<ButtonUs startIcon={<ApartmentIcon />} primary size="medium" variant="contained" disableRipple>*/}
                            {/*    <Link*/}
                            {/*        to={{*/}
                            {/*            pathname: `/estate/${param}/crm/${CREATING_ITEM_ID}`,*/}
                            {/*            state: { modal: true }*/}
                            {/*        }}*/}
                            {/*        style={{ color: 'white' }}*/}
                            {/*    >*/}
                            {/*        Добавить*/}
                            {/*    </Link>*/}
                            {/*</ButtonUs>*/}
                        </div>
                    )}
                </div>

                <PreventSwipe>
                    <div className={cs({ 'crm-Print__hidden': true, 'crm-Estate__chips': true, 'crm-Estate__chips_mobile': isMobile })}>
                        <EstateMapFilterChips type="list" />
                    </div>
                </PreventSwipe>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterEstate>
                        <Segment className="crm-List__segment">
                            {!isMobile ? (
                                <Table structured size="small" sortable compact>
                                    <Table.Header fullWidth className="crm-List__pagination_header crm-Print__hidden">
                                        <ListPagination
                                            loading={false}
                                            colSpan={footerColsCount}
                                            pagination={pagination}
                                            listCount={listCount}
                                            loadingCount={loadingCount}
                                            pageChange={estateStore.pageChange}
                                            pageSizeChange={estateStore.pageSizeChange}
                                            nextListLoaded={nextListLoaded}
                                            showPult={selectedItemsIds.length > 0}
                                        >
                                            <span style={{ marginLeft: '1em' }}>
                                                <MaterialButtonGroup variant="text" color="primary">
                                                    {enable &&
                                                        selectedItemsIds.length > 0 &&
                                                        authStore.getPermission(contactStore.moduleName, ACCESS_WRITE) ===
                                                            ACCESS_COMPANY && (
                                                            <ButtonUs
                                                                color="secondary"
                                                                onClick={this.handleManageEstatesModal}
                                                                endIcon={<TuneIcon />}
                                                            >
                                                                Параметры
                                                            </ButtonUs>
                                                        )}
                                                    <ButtonUs endIcon={<PrintIcon />} onClick={this.handlePrintBtn}>
                                                        Печать
                                                    </ButtonUs>
                                                    <ShareEstatesButton />
                                                </MaterialButtonGroup>
                                            </span>
                                        </ListPagination>
                                    </Table.Header>
                                    <EstateTableHeader
                                        orderBy={orderBy}
                                        orderDirection={orderDirection}
                                        handleSort={handleSort}
                                        showExportMajorUser={Boolean(showExportMajorUser)}
                                        selectedAll={estateStore.selectedAll}
                                        filterBase={base}
                                        propertyTypes={propertyTypes}
                                    />
                                    {list && (
                                        <EstateTableBody
                                            estateList={list}
                                            showExportMajorUser={Boolean(showExportMajorUser)}
                                            byCreateTime={Boolean(byCreateTime)}
                                            selectedItemsIds={selectedItemsIds}
                                            toggleItemSelected={estateStore.toggleItemSelected}
                                            filterBase={base}
                                            loadingList={loadingList}
                                            isPrinting={isPrinting}
                                        />
                                    )}
                                    <ListFooter
                                        loading={loadingList}
                                        colSpan={footerColsCount}
                                        pagination={pagination}
                                        loadingCount={loadingCount}
                                        listCount={listCount}
                                        pageChange={estateStore.pageChange}
                                        pageSizeChange={estateStore.pageSizeChange}
                                        nextListLoaded={nextListLoaded}
                                        scrollToElementId="crm_ListTop"
                                    />
                                </Table>
                            ) : (
                                <Fragment>
                                    {selectedItemsIds.length > 0 && <ShareEstatesButton />}
                                    <EstateMobileCardsView />
                                    {selectedItemsIds.length > 0 && list.length > 2 && <ShareEstatesButton />}
                                    <Table>
                                        <ListFooter
                                            loading={loadingList}
                                            colSpan={footerColsCount}
                                            pagination={pagination}
                                            listCount={listCount}
                                            loadingCount={loadingCount}
                                            pageChange={estateStore.pageChange}
                                            pageSizeChange={estateStore.pageSizeChange}
                                            nextListLoaded={nextListLoaded}
                                            scrollToElementId="crm_ListTop"
                                        />
                                    </Table>
                                </Fragment>
                            )}
                        </Segment>
                    </FilterEstate>
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(estateStore, EstatesList);
