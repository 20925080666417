import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import StorageIcon from '@material-ui/icons/Storage';
import LinkIcon from '@material-ui/icons/Link';

import ContainerHeader from '~ui/ContainerHeader';

import LoaderAwait from '~/components/Base/LoaderAwait';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';
import developerStore from '~/stores/lifeComplexes/developerStore';
import authStore from '~/stores/authStore';
import settingStore from '~/stores/settingStore';
import Button from '~ui/Button';

import SppAgencyModal from './LifecomplexFeedModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

const LifeComplexFeeds = () => {
    const [developerId, setDeveloperId] = useState<number | null>(null);

    useMemo(() => {
        developerStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = developerStore;

    return (
        <>
            <ContainerHeader
                title="Настройка фидов застройщиков"
                handleCreate={() => setDeveloperId(CREATING_ITEM_ID)}
                enableCreating={authStore.canCreate(settingStore.moduleName)}
                icon={<StorageIcon fontSize="large" />}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List>
                    {list.map(({ developer_id, name, logoUrl, url }) => (
                        <ListItem key={developer_id} button onClick={() => setDeveloperId(developer_id)}>
                            <ListItemAvatar>
                                <Avatar src={logoUrl} />
                            </ListItemAvatar>
                            <ListItemText primary={name} />
                            {url && (
                                <ListItemSecondaryAction>
                                    <Button icon={<LinkIcon />} href={url} target="_blank" tooltip="Открыть сайт компании" />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {developerId !== null && <SppAgencyModal developer_id={developerId} onClose={() => setDeveloperId(null)} />}
        </>
    );
};

export default observer(LifeComplexFeeds);
