import uiCionfig from '../../config/ui.config';

import { ExportEstateHistory } from '~/types/exports.types';
import { MOSCOWMAP_House } from '~/types/houses.types';
import { AvitoHouse, YandexHouse, Newbuilding, NewBuildingHouse } from '~/types/newbuildings.types';
import { WishTableType } from '~/types/wish.types';
import { PaginationType } from '../prototypes/ListStore.prototype';
import { CellCall } from '~/types/cellCalls.types';
import { Contact } from '~/types/contacts.types';
import { ShowingTableItem } from '~/types/showings.types';
import { estateAbout } from '~/components/Lists/Estate/EstateLink';
import estateL10n from '../../L10n/estate.L10n';
import {
    Estate,
    ESTATE_BASE_FOREIGN,
    ESTATE_BASE_GROUP,
    ESTATE_BASE_MY,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID,
    EstateFilter,
    EstateTableItem,
    MlsEstateFixPrice
} from '~/types/estate.types';
import {
    ESTATE_BASE_ALL,
    ESTATE_BASE_MAIN,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    TRANSPORT_TYPE_WALK
} from '~/types/estate.types';
import config from '../../config/ui.config';
import { PropertyLabelsAccumulate } from '~/components/Lists/Estate/FilterBlocks/PropertyTypeBlock';
import roundPrice from '../../common/roundPrice';
import authStore from '../authStore';
import userStore from '../userStore';
import { deltaCreateTimes } from '~/components/Lists/ListFilterWrapper';
import { DAY_SEC } from '~/common/time';

export type EstateItemTypeProperty = {
    newbuildings: Newbuilding[];
    newbuildingsQuery: string;
    loadingNewbuildings: boolean;

    searchAddressQuery: string;
    moscowmapHouse?: MOSCOWMAP_House;

    contact: Contact | null;
    contactLoading: boolean;
    showings: ShowingTableItem[];
    loadingShowings: boolean;
    updatingPhotos: boolean;

    loadingExportHistory: boolean;
    exportHistory: ExportEstateHistory | null;
    exportPagination: PaginationType;

    loadingYandexHouse: boolean;
    yandexHouse: YandexHouse;
    suggestedYandexHouses: YandexHouse[];
    editingYandexHouse: boolean;

    loadingAvitoHouse: boolean;
    avitoHouse: AvitoHouse;
    suggestedAvitoHouses: AvitoHouse[];
    editingAvitoHouse: boolean;

    loadingCalls: boolean;
    calls: CellCall[];

    loadingWishes: boolean;
    wishes: WishTableType[];
    wishesLocationType: number;

    loadingSameEstates: boolean;
    sameEstates: EstateTableItem[];
    sameEstatesErrors: string[];

    loadingSMAEstates: boolean;
    smaEstates: EstateTableItem[];
    smaEstatesErrors: string[];

    loadingOwnMlsPrice: boolean;
    ownFixedMlsPrice: MlsEstateFixPrice[];
};

export const printNewbuildingTitle = ({
    newbuilding,
    newbuildingHouse
}: {
    newbuilding?: Newbuilding;
    newbuildingHouse?: NewBuildingHouse;
}) => (newbuilding ? `ЖК «${newbuilding.name}» ${newbuildingHouse ? newbuildingHouse.name : ''}` : '');

export const estateDropdownTitle = ({
    estate_id,
    address,
    price,
    currency,
    propertyType,
    roomsCount,
    totalArea,
    floorNumber,
    floorsCount,
    newbuilding,
    newbuildingHouse
}: EstateTableItem): string => {
    return `№${estate_id} :: 
            ${newbuilding ? printNewbuildingTitle({ newbuilding, newbuildingHouse }) : address}
            (${estateAbout(propertyType, roomsCount)}, ${totalArea || '-'}м², ${floorNumber || '-'}/${floorsCount || '-'})
            ${price.toLocaleString()}${estateL10n.CURRENCY_TYPES_FIND(currency)}`;
};

export const validEstate = (estate: Estate): Array<string> => {
    const errors: Array<string> = [];

    if (!estate.contact_id) {
        errors.push('Заполните контакт');
    }

    if (!estate.address) {
        errors.push('Заполните адрес');
    }

    const kitchenArea = Number(estate.kitchenArea);
    const totalArea = Number(estate.totalArea);
    const livingArea = Number(estate.livingArea);

    if (kitchenArea && kitchenArea > totalArea) {
        errors.push('Площадь кухни меньше общей площади');
    }

    if (livingArea && livingArea > totalArea) {
        errors.push('Жилая площадь меньше общей площади');
    }

    if (livingArea && kitchenArea && livingArea + kitchenArea >= totalArea - 4) {
        errors.push('Жилая площадь + площадь кухни должна быть не менее чем на 4 метра меньше общей');
    }

    if (
        [ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(estate.propertyType) &&
        !estate.newbuilding_id
    ) {
        errors.push('ЖК не указан');
    }

    if (Number(estate.floorNumber) > Number(estate.floorsCount)) {
        errors.push('Этаж больше количества этажей в доме');
    }

    if (estate.propertyType === ESTATE_PROPERTY_TYPE_OFFICE) {
        if (!estate.buildingClassType) {
            errors.push('Укажите "Класс Здания"');
        }

        if (!estate.conditionType) {
            errors.push('Укажите Состаяние помещения');
        }

        if (!estate.layout) {
            errors.push('Укажите Планировку');
        }
    }

    if (estate.price > (estate.currency === 1 ? 5000000000 : 10000000)) {
        errors.push('Слишком большая цена; Возможно, вы перепутали валюту');
    }

    if (
        !ESTATE_PROPERTY_GARAGE_ARR.includes(estate.propertyType) &&
        estate.type === ESTATE_TYPE_SELL_ID &&
        estate.currency === 1 &&
        estate.price < 100000
    ) {
        errors.push('Слишком маленькая цена объекта');
    }

    if (
        !ESTATE_PROPERTY_GARAGE_ARR.includes(estate.propertyType) &&
        estate.type === ESTATE_TYPE_RENT_ID &&
        estate.currency === 1 &&
        estate.price < 5000
    ) {
        errors.push('Слишком маленькая цена аренды');
    }

    return errors;
};

export const ListFilterInitial: EstateFilter = {
    type: 0,
    base: ESTATE_BASE_MY,
    regionId: 0,
    propertyType: 0,
    propertyTypes: [],
    group_id: [],
    major_user_id: [],
    enable: true,
    roomsCount: [],
    totalAreaMin: null,
    totalAreaMax: null,
    livingAreaMin: null,
    livingAreaMax: null,
    kitchenAreaMin: null,
    kitchenAreaMax: null,
    floorNumberMin: null,
    floorNumberMax: null,
    floorsCountMin: null,
    lastFloorOnly: false,
    materialType: [],
    buildYearMin: null,
    buildYearMax: null,
    hasLoggia: false,
    hasBalcone: false,
    wcType: 0,
    transportTime: null,
    transportType: TRANSPORT_TYPE_WALK,
    main_metro_id: [],
    deltaTime: 0,
    startTime: null,
    endTime: null,
    descriptionContains: '',
    streetContains: '',
    townContains: '',
    newbuildingContains: '',
    priceMin: null,
    priceMax: null,
    priceM2Min: null,
    priceM2Max: null,
    currency: 1,
    saleType: null,
    showExportedOnly: false,
    showExportedTop3: false,
    showExportingErrorsOnly: false,
    showNoExportedOnly: false,
    fakesAvailable: false,
    byCreateTime: false,
    showExportedBases: [],
    noAdsCallsTime: null,
    noOutgoingToOwnerCallsTime: null,
    showApartmentsOnly: false,
    search: null,
    fias: null,
    showExportMajorUser: null,
    showAvailableOnly: true,
    isAlreadySold: false,
    hasBonusToAgent: null,
    newbuilding_id: null,
    newbuilding_house_id: null
};

const FILTER_MIN_MAX = ['price', 'totalArea', 'livingArea', 'kitchenArea' /*floorNumber*/];

export const adjustEstateFilter = (inputFilter: Estate, approximation: number, locationType: number): Partial<EstateFilter> => {
    const { propertyType, type } = inputFilter;

    const filter: Partial<EstateFilter> = {
        type,
        propertyType,
        propertyTypes: [propertyType],
        base: ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) ? ESTATE_BASE_ALL : ESTATE_BASE_MAIN,
        enable: true,
        major_user_id: []
    };

    const approxKef = 1 + approximation;

    FILTER_MIN_MAX.forEach(field => {
        const value = inputFilter[field];
        if (value) {
            filter[`${field}Max`] = Math.round(value * approxKef);
            filter[`${field}Min`] = Math.round(value / approxKef);
        }
    });

    if (locationType === 1) {
        filter['main_metro_id'] = inputFilter.metros ? inputFilter.metros.map(({ metro_id }) => metro_id) : [];
    } else if (locationType === 2) {
        filter['townContains'] = inputFilter['town'];
        filter['main_metro_id'] = [];
    }

    if (inputFilter['roomsCount']) {
        filter['roomsCount'] = [inputFilter['roomsCount']];
    }

    return filter;
};

export const matchEstateThumbnailPhotoUrl = (
    filename: string,
    updatingPhotos?: boolean,
    sizeNumber: number = 2
): { src: string; srcSet?: string[]; tempSrc: string } => {
    const {
        estatePhotos: { estatePhotoUrl, sizes, estateTempPhotoUrl }
    } = config;

    // Если фотки редактировались - обнуляем их кэш (если не редактировались, то updatingPhotos===undefined):
    if (typeof updatingPhotos === 'boolean') {
        filename += '?rand=' + Math.random();
    }

    const tempSrc = estateTempPhotoUrl + filename;

    return ~filename.indexOf('://')
        ? { src: filename, tempSrc }
        : {
              src: estatePhotoUrl + sizes[sizeNumber].dir + '/' + sizes[sizeNumber].prefix + filename,
              srcSet: sizes.map(({ dir, prefix, width }) => estatePhotoUrl + dir + '/' + prefix + filename + ' ' + width + 'w'),
              tempSrc
          };
};

const checkPropertyTypes = (propertyTypes: number[], propertySet: number[]): boolean => {
    return propertyTypes.length > 0 && propertyTypes.every(type => propertySet.includes(type));
};

export const isTotalAreaAvailable = (propertyTypes: number[]): boolean => {
    return checkPropertyTypes(propertyTypes, [
        ...ESTATE_PROPERTY_FLATS_ARR,
        ...ESTATE_PROPERTY_GARAGE_ARR,
        ...ESTATE_PROPERTY_HOUSES,
        ESTATE_PROPERTY_TYPE_OFFICE,
        ESTATE_PROPERTY_TYPE_FREE_PURPOSE
    ]);
};

export const isFloorAvailable = (propertyTypes: number[]): boolean => {
    return checkPropertyTypes(propertyTypes, [
        ...ESTATE_PROPERTY_FLATS_ARR,
        ESTATE_PROPERTY_TYPE_OFFICE,
        ESTATE_PROPERTY_TYPE_FREE_PURPOSE
    ]);
};

export const printPropertyTypes = propertyTypes =>
    propertyTypes.reduce((label, propertyType) => {
        for (const { set, title } of PropertyLabelsAccumulate) {
            if (set.every(type => propertyTypes.includes(type)) && set.includes(propertyType)) {
                return propertyType === set[0] ? `${label}${label ? ', ' : ''}${title}` : label;
            }
        }

        return (
            `${label}${label ? ', ' : ''}` +
            (propertyType === ESTATE_PROPERTY_TYPE_NEWBUILDING
                ? 'от застройщика'
                : propertyType === ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION
                ? 'переуступка'
                : estateAbout(propertyType, null))
        );
    }, '');

type EstateFilterKeyof = keyof EstateFilter;

type TSearchLabel = {
    label: string;
    key: EstateFilterKeyof | EstateFilterKeyof[];
};

export const estateSearchLabels = (filter: Partial<EstateFilter>): TSearchLabel[] => {
    const {
        propertyType,
        saleType,
        propertyTypes,
        type,

        priceMin,
        priceMax,
        priceM2Min,
        priceM2Max,

        totalAreaMin,
        totalAreaMax,
        livingAreaMin,
        livingAreaMax,
        kitchenAreaMin,
        kitchenAreaMax,
        floorNumberMin,
        floorNumberMax,
        floorsCountMin,
        lastFloorOnly,

        materialType,
        buildYearMin,
        buildYearMax,

        roomsCount,

        main_metro_id,
        transportType,
        transportTime,

        hasLoggia,
        hasBalcone,
        wcType,

        showApartmentsOnly,

        base,

        group_id,
        major_user_id,
        enable,

        deltaTime,
        descriptionContains,
        streetContains,
        townContains,
        newbuildingContains,

        currency,
        showExportedOnly,
        showExportedTop3,
        showExportedBases,
        showNoExportedOnly,

        fias,
        search,

        showExportMajorUser,
        fakesAvailable,
        byCreateTime,
        showAvailableOnly,
        showExportingErrorsOnly,

        noAdsCallsTime,
        noOutgoingToOwnerCallsTime,
        isAlreadySold,
        hasBonusToAgent
    } = filter;

    const searchLabels: TSearchLabel[] = [];

    if (enable === false) {
        searchLabels.push({ label: 'Архив', key: 'enable' });
    }
    if (type !== ListFilterInitial['type']) {
        searchLabels.push({ label: type === 1 ? 'Продажа' : 'Аренда', key: 'type' });
    }
    if (saleType) {
        searchLabels.push({ label: estateL10n.SALE_TYPES_FIND(saleType), key: 'saleType' });
    }
    if (base !== ListFilterInitial['base'] && base !== ESTATE_BASE_FOREIGN) {
        const basesObj = uiCionfig.estateListMenuOrder.reduce((acc, [key, ruKey]) => {
            return { ...acc, [key]: ruKey };
        }, {});
        searchLabels.push({ label: basesObj[base], key: 'base' });
    }
    if (group_id?.length && !major_user_id?.length) {
        const label =
            `Отдел${group_id.length > 1 ? 'ы' : ''}: ` +
            group_id
                .map(gId => {
                    try {
                        const { name } = userStore.findGroupById(gId);
                        return name;
                    } catch (e) {
                        return 'архив';
                    }
                })
                .join(', ');
        searchLabels.push({ label, key: 'group_id' });
    }
    if (major_user_id?.length) {
        const label =
            `Сотрудник${major_user_id.length > 1 ? 'и' : ''}: ` +
            major_user_id
                .slice(0, 2)
                .map(user_id => {
                    try {
                        const { firstName, lastName } = authStore.findUserById(user_id);
                        return `${firstName} ${lastName}`;
                    } catch (e) {
                        return 'архив';
                    }
                })
                .join(', ') +
            (major_user_id.length > 2 ? ` +${major_user_id.length - 2}` : '');
        searchLabels.push({ label, key: 'major_user_id' });
    }
    if (deltaTime) {
        const { text } = deltaCreateTimes.find(({ value }) => value === deltaTime);
        searchLabels.push({ label: `За период:  ${text}`, key: 'deltaTime' });
    }
    if (propertyTypes?.length) {
        searchLabels.push({ label: printPropertyTypes(propertyTypes), key: 'propertyTypes' });
    }
    if (roomsCount?.length) {
        const label = 'Комнат: ' + roomsCount.map(room => estateL10n.ROOMS_COUNT_FIND(room)).join(', ');
        searchLabels.push({ label, key: 'roomsCount' });
    }
    if (priceMin || priceMax) {
        searchLabels.push({
            label: `Цена ${priceMin ? `от ${roundPrice(priceMin)} ` : ''} ${priceMax ? `до ${roundPrice(priceMax)}` : ''}`,
            key: ['priceMin', 'priceMax']
        });
    }
    if (priceM2Min || priceM2Max) {
        searchLabels.push({
            label: `За м2 ${priceM2Min ? `от ${roundPrice(priceM2Min)} ` : ''} ${priceM2Max ? `до ${roundPrice(priceM2Max)}` : ''}`,
            key: ['priceM2Min', 'priceM2Max']
        });
    }
    if (floorNumberMin || floorNumberMax) {
        searchLabels.push({
            label: `Этаж ${floorNumberMin ? `от ${floorNumberMin} ` : ''} ${floorNumberMax ? `до ${floorNumberMax}` : ''}`,
            key: ['floorNumberMin', 'floorNumberMax']
        });
    }
    if (!showAvailableOnly) {
        searchLabels.push({
            label: `Возможны занятые`,
            key: 'showAvailableOnly'
        });
    }
    if (townContains) {
        searchLabels.push({ label: `Город "${townContains}"`, key: 'townContains' });
    }
    if (streetContains) {
        searchLabels.push({ label: `Улица "${streetContains}"`, key: 'streetContains' });
    }
    if (newbuildingContains) {
        searchLabels.push({ label: `ЖК "${newbuildingContains}"`, key: 'newbuildingContains' });
    }
    if (main_metro_id?.length) {
        searchLabels.push({ label: `Станций: ${main_metro_id.length}`, key: 'main_metro_id' });
    }
    if (transportTime) {
        searchLabels.push({
            label: `До метро: ${transportTime ? `${transportTime} мин` : ''} ${estateL10n.TRANSPORT_TYPES_FIND(transportType)}`,
            key: ['transportType', 'transportTime']
        });
    }
    if (descriptionContains) {
        searchLabels.push({ label: `Описание "${descriptionContains}"`, key: 'descriptionContains' });
    }
    if (totalAreaMin || totalAreaMax || livingAreaMin || livingAreaMax || kitchenAreaMin || kitchenAreaMax) {
        let label = '';

        if (totalAreaMin || totalAreaMax) {
            label += ` общ: ${totalAreaMin || '...'}-${totalAreaMax || '...'} м2`;
        }
        if (livingAreaMin || livingAreaMax) {
            label += `${label ? '; ' : ''} жил: ${livingAreaMin || '...'}-${livingAreaMax || '...'} м2`;
        }
        if (kitchenAreaMin || kitchenAreaMax) {
            label += `${label ? '; ' : ''} кух: ${kitchenAreaMin || '...'}-${kitchenAreaMax || '...'} м2`;
        }

        label = `Площадь${label}`;

        searchLabels.push({
            label,
            key: ['totalAreaMin', 'totalAreaMax', 'livingAreaMin', 'livingAreaMax', 'kitchenAreaMin', 'kitchenAreaMax']
        });
    }
    if (showApartmentsOnly) {
        searchLabels.push({ label: `Апартаменты`, key: 'showApartmentsOnly' });
    }
    if (noAdsCallsTime !== null) {
        searchLabels.push({ label: `Нет входящих`, key: 'noAdsCallsTime' });
    }
    if (noOutgoingToOwnerCallsTime !== null) {
        searchLabels.push({ label: `Нет исходящих (${noOutgoingToOwnerCallsTime / DAY_SEC}д)`, key: 'noOutgoingToOwnerCallsTime' });
    }
    if (showExportedOnly) {
        searchLabels.push({ label: `Включен экспорт`, key: 'showExportedOnly' });
    }
    if (showNoExportedOnly) {
        searchLabels.push({ label: `Неэкспортируемые`, key: 'showNoExportedOnly' });
    }
    if (showExportedTop3) {
        searchLabels.push({ label: `Топ-3`, key: 'showExportedTop3' });
    }
    if (showExportedBases?.length) {
        searchLabels.push({ label: `Базы: ${showExportedBases.length}`, key: 'showExportedBases' });
    }
    if (floorsCountMin) {
        searchLabels.push({ label: `Этаж не ниже: ${floorsCountMin}`, key: 'floorsCountMin' });
    }
    if (lastFloorOnly) {
        searchLabels.push({ label: `Последний этаж`, key: 'lastFloorOnly' });
    }
    if (buildYearMin || buildYearMax) {
        searchLabels.push({
            label: `Год постройки ${buildYearMin ? `от ${buildYearMin} ` : ''} ${buildYearMax ? `до ${buildYearMax}` : ''}`,
            key: ['buildYearMin', 'buildYearMax']
        });
    }
    if (hasLoggia) {
        searchLabels.push({ label: `Лоджия`, key: 'hasLoggia' });
    }
    if (hasBalcone) {
        searchLabels.push({ label: `Балкон`, key: 'hasBalcone' });
    }
    if (wcType) {
        searchLabels.push({ label: estateL10n.WC_TYPE_FIND(wcType), key: 'wcType' });
    }
    if (fakesAvailable) {
        searchLabels.push({ label: `+ Фейки`, key: 'fakesAvailable' });
    }
    if (byCreateTime) {
        searchLabels.push({ label: `По дате создания`, key: 'byCreateTime' });
    }
    if (showExportMajorUser) {
        searchLabels.push({ label: `На кого установлен экспорт`, key: 'showExportMajorUser' });
    }
    if (showExportingErrorsOnly) {
        searchLabels.push({ label: `С ошибками экспорта`, key: 'showExportingErrorsOnly' });
    }
    if (materialType?.length) {
        searchLabels.push({
            label: materialType.map(mT => estateL10n.HOUSE_MATERIAL_TYPES_FIND(mT)).join(', '),
            key: 'materialType'
        });
    }
    if (isAlreadySold) {
        searchLabels.push({ label: `Только проданные`, key: 'isAlreadySold' });
    }
    if (hasBonusToAgent) {
        searchLabels.push({ label: `Бонус агенту`, key: 'hasBonusToAgent' });
    }

    return searchLabels;
};

export const estateSearchTitle = (filter: Partial<EstateFilter>): string => {
    return estateSearchLabels(filter)
        .map(({ label }) => label)
        .join(', ');
};
