import React, { useState, useEffect } from 'react';
import './chat.less';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import Toolbar from '@material-ui/core/Toolbar';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import ChatsList from './ChatList';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import commonStore from '~/stores/commonStore';
import { ChatListType } from '~/types/chat.types';

const drawerWidth = 315;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                padding: theme.spacing(2, 0)
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth
        },
        content: {
            flexGrow: 1,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap'
        }
    })
);

export default function Chat(props) {
    const {
        match: { params }
    } = props;
    const classes = useStyles({});
    const { chat_type, chatId, itemId }: { chat_type: ChatListType; chatId: string; itemId?: string } = params;

    const chat_id = Number(chatId);
    const [mobileOpen, setMobileOpen] = useState(Boolean(!chat_id && commonStore.isMobile));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} color="inherit">
                <Toolbar>
                    <IconButton color="primary" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                        <ChatIcon />
                    </IconButton>

                    {Number(itemId) > 0 && <ChatHeader item_id={Number(itemId)} chatType={chat_type} />}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                {commonStore.isMobile && (
                    <SwipeableDrawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        onOpen={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        <ChatsList onSelectChat={handleDrawerToggle} chatType={chat_type} activeItemId={chat_id} />
                    </SwipeableDrawer>
                )}
                {!commonStore.isMobile && (
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        <ChatsList chatType={chat_type} activeItemId={chat_id} />
                    </Drawer>
                )}
            </nav>
            <main className={classes.content}>{chat_id > 0 && <ChatMessages item_id={chat_id} chatType={chat_type} />}</main>
        </div>
    );
}
