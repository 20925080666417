import React, { Fragment, ReactNode } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '~ui/Divider';
import { GridSpacing } from '@material-ui/core/Grid/Grid';

type FilterCellProps = {
    title: string;
    titleFullWidth?: boolean;
    children: ReactNode;
    spacing?: GridSpacing;
    hideDivider?: boolean;
};

const FilterCell = ({ children, title, titleFullWidth, hideDivider, spacing = 2 }: FilterCellProps) => {
    return (
        <Fragment>
            <Grid container spacing={spacing} justify="flex-start" alignItems="center" className="crm-Noselect">
                <Grid item xs={titleFullWidth ? 12 : 6}>
                    <Typography variant="subtitle1">{title}</Typography>
                </Grid>

                {children}
            </Grid>
            {!hideDivider && <Divider />}
        </Fragment>
    );
};

export default FilterCell;
