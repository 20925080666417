import React, { PureComponent, Fragment } from 'react';
import history from '../../../history';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ConfirmTrigger from '~ui/ConfirmTrigger';

import { EstateLinkState } from '../../Lists/Estate/EstateLink';
import uiConfig from '../../../config/ui.config';
import exportStore from '~/stores/export/exportStore';
import estateStore from '~/stores/estateStore';
import authStore from '~/stores/authStore';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import showingStore from '~/stores/showingStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import {
    ESTATE_BASE_MLS,
    ESTATE_BASE_MAIN,
    ESTATE_BASE_OWNERS,
    ESTATE_TYPE_SELL,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_COUNTRY_ARR
} from '~/types/estate.types';
import EstatePropertyTypePopup from './SubBlocks/EstatePropertyTypePopup';
import EstateToArchiveModal from './SubBlocks/EstateToArchiveModal';

import { ACCESS_COMPANY, ACCESS_DELETE, ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import { printEstateAbout } from './ShowBlocks/EstateInfoBlock';
import DisableItemConfirm from '~/components/Items/Common/DisableItemConfirm';

type EstateTabMenuState = {
    bigWriteAccess: boolean;
};

class EstateTabMenu extends PureComponent<TabMenuWrapperProps, EstateTabMenuState> {
    state = {
        bigWriteAccess: [ACCESS_COMPANY, ACCESS_GROUP].includes(authStore.getPermission(estateStore.moduleName, ACCESS_DELETE))
    };

    handleDuplicateEstate = async () => {
        const new_estate_id = await estateStore.duplicateEstate(this.props.item_id);
        if (new_estate_id) {
            history.push(EstateLinkState(new_estate_id, ESTATE_TYPE_SELL, ESTATE_BASE_MAIN));
        }
    };

    handleLeaveEstate = async () => {
        await estateStore.leaveEstate(this.props.item_id, this.state.bigWriteAccess);
    };

    handleShareLink = async () => {
        const { item_id } = this.props;
        const { item } = estateStore.getItem(item_id);
        if (item) {
            const shareConf = {
                url: uiConfig.outerEstateLinkToSite.replace('[estate_id]', String(item_id)),
                title: printEstateAbout(item),
                text: item.address
            };
            window.navigator['share'](shareConf);
        }
    };

    handleAddShowing = () => {
        const { item_id } = this.props;
        history.push({
            pathname: `/showings/list/overview/${CREATING_ITEM_ID}`,
            state: {
                modal: true,
                from: { storeName: 'estateStore', item_id },
                callback: { storeName: 'estateStore', method: 'showingsByEstate', item_id }
            }
        });
    };

    render() {
        const { item_id, major_user_id, tabName } = this.props;
        const { bigWriteAccess } = this.state;

        const { item } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const { propertyType, base, enable } = item;
        const leaveBtnTitle = bigWriteAccess ? 'Перевести в свободные' : 'Освободить объект';

        const isExternalLinkAvailable =
            base === ESTATE_BASE_MAIN &&
            [...ESTATE_PROPERTY_FLATS_ARR, ...ESTATE_PROPERTY_COUNTRY_ARR].includes(propertyType) &&
            uiConfig.outerEstateLinkToSite;

        const canDelete = authStore.canDelete(estateStore.moduleName, major_user_id);

        if (![ESTATE_BASE_MLS, ESTATE_BASE_OWNERS].includes(tabName)) {
            return (
                <div>
                    <Divider style={{ margin: '5px 0' }} />
                    {isExternalLinkAvailable && window.navigator['share'] && (
                        <MenuItem onClick={this.handleShareLink}>
                            <Icon name="share square" />
                            Поделиться
                        </MenuItem>
                    )}
                    <MenuItem component={'a' as any} href={`/print/crm-${item_id}`} target="_blank">
                        <Icon name="print" />
                        На печать
                    </MenuItem>
                    {isExternalLinkAvailable && (
                        <MenuItem
                            component={'a' as any}
                            href={uiConfig.outerEstateLinkToSite.replace('[estate_id]', String(item_id))}
                            target="_blank"
                        >
                            <Icon name="external" />
                            Открыть на сайте
                        </MenuItem>
                    )}
                    {authStore.canEdit(showingStore.moduleName, major_user_id) && (
                        <MenuItem onClick={this.handleAddShowing}>
                            <Icon name="compass" />
                            Добавить показ
                        </MenuItem>
                    )}
                    {authStore.canDelete(exportStore.moduleName, major_user_id) && <EstatePropertyTypePopup item_id={item_id} />}
                    {authStore.canDelete(exportStore.moduleName, major_user_id) && (
                        <ConfirmTrigger
                            trigger={<MenuItem>Отменить экспорт</MenuItem>}
                            header="Снять объект с экспорта?"
                            content="Весь экспорт данного объекта будет отключен."
                            confirmButton="Снять с экспорта"
                            onConfirm={exportStore.disableExport.bind(exportStore, item_id)}
                        />
                    )}
                    {authStore.canEdit(estateStore.moduleName, major_user_id) && (
                        <ConfirmTrigger
                            trigger={
                                <MenuItem>
                                    <Icon name="copy" />
                                    Дубликат объекта
                                </MenuItem>
                            }
                            header="Создание дубликата объекта"
                            content="Хотите продублировать данный объект?"
                            confirmButton="Создать"
                            onConfirm={this.handleDuplicateEstate}
                        />
                    )}
                    {authStore.canEdit(estateStore.moduleName, major_user_id) &&
                        major_user_id instanceof Array &&
                        major_user_id.length > 0 && (
                            <div>
                                <Divider style={{ margin: '5px 0' }} />
                                <ConfirmTrigger
                                    trigger={
                                        <MenuItem>
                                            <Icon name="sign out" />
                                            <span className="text">{leaveBtnTitle}</span>
                                        </MenuItem>
                                    }
                                    header={
                                        bigWriteAccess
                                            ? 'Удалить агента(ов) из данного объекта?'
                                            : 'Вы действительно хотите отказаться от этого объекта?'
                                    }
                                    content="Объект (и закрепленный контакт Продавца) будет переведен в свободные. Экспорт будет выключен."
                                    confirmButton="Удалить"
                                    onConfirm={this.handleLeaveEstate}
                                />
                            </div>
                        )}

                    {canDelete && (
                        <div>
                            <Divider style={{ margin: '5px 0' }} />

                            {enable && (
                                <EstateToArchiveModal
                                    trigger={
                                        <MenuItem>
                                            <Icon name="trash" />
                                            <span className="text">В архив</span>
                                        </MenuItem>
                                    }
                                    estate_id={item_id}
                                />
                            )}
                            {!enable && <DisableItemConfirm item_id={item_id} enable={enable} store={estateStore} />}
                        </div>
                    )}
                </div>
            );
        }

        return null;
    }
}

export default TabMenuWrapper(estateStore, EstateLinkState, EstateTabMenu, {
    wrappedOnBottom: true,
    attachDocuments: true,
    base: ESTATE_BASE_MAIN,
    callEventModal: true,
    meetingEventModal: true,
    disableDeleteButton: true
});
