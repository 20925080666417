import React, { Component, ComponentType, ReactNode } from 'react';
import settingStore from '~/stores/settingStore';
import { observer } from 'mobx-react';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import { SuccessMessage } from '~ui/Message';

export type SettingsEditingWrapperProps = {
    handleEditValue: HandleChangeType;
    handleCheckbox: (event, { name, checked }: { name: string; checked: boolean }) => void;
    handleSave: () => Promise<void>;
};

function SettingsEditingWrapper(WrappedComponent: ComponentType<SettingsEditingWrapperProps>, settings_key: string) {
    @observer
    class SettingsEditing extends Component<{}, { successSave: boolean }> {
        state = {
            successSave: false
        };

        constructor(props) {
            super(props);
            settingStore.fetchItem(settings_key, null, true);
        }

        handleEditValue = (event, { value, name }: { value: string; name: string }) => {
            this.setState({ successSave: false });
            const { editingItem } = settingStore.getItem(settings_key);
            settingStore.setEditingItem(settings_key, { value: JSON.stringify({ ...JSON.parse(editingItem.value), [name]: value }) });
        };

        handleCheckbox = (event, { name, checked }: { name: string; checked: boolean }) => {
            this.setState({ successSave: false });
            const { editingItem } = settingStore.getItem(settings_key);
            settingStore.setEditingItem(settings_key, { value: JSON.stringify({ ...JSON.parse(editingItem.value), [name]: checked }) });
        };

        handleSave = async () => {
            this.setState({ successSave: false });
            if (await settingStore.saveItem(settings_key)) {
                this.setState({ successSave: true });
            }
        };

        render() {
            const { successSave } = this.state;

            return (
                <>
                    {successSave && <SuccessMessage enableClose>Изменения успешно сохранены</SuccessMessage>}
                    <WrappedComponent
                        handleEditValue={this.handleEditValue}
                        handleCheckbox={this.handleCheckbox}
                        handleSave={this.handleSave}
                    />
                </>
            );
        }
    }

    return SettingsEditing;
}

export default SettingsEditingWrapper;
