import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Chip from '@material-ui/core/Chip';
import TuneIcon from '@material-ui/icons/Tune';
import LinkIcon from '@material-ui/icons/Link';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from '@material-ui/core/Box';

import Button from '~ui/Button';
import serverConfig from '~/config/server.config';

import { useToggle } from '~ui/Modal';
import exportingBaseFeedStore from '~/stores/export/exportingBaseFeedStore';
import Time from '~/components/Base/Time';

import ExportFeedSettings from './ExportFeedSettings';
import ExportingFeedModal from './ExportingFeedModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { estateSearchLabels, ListFilterInitial } from '~/stores/helpers/estate.store.helpers';

const FeedListItem = observer(({ export_base_id, feed_id }: { export_base_id: number; feed_id: number }) => {
    const [showAddModal, toggleAddModal] = useToggle();
    const { name, updateTime, feedurl, searchParams } = exportingBaseFeedStore.getItemInList(export_base_id, feed_id);

    const Chips = estateSearchLabels({ ...ListFilterInitial, ...JSON.parse(searchParams) }).map(({ label, key }) => (
        <Chip key={label} label={label} size="small" style={{ marginLeft: '0.5rem' }} />
    ));

    return (
        <>
            <ListItem key={feed_id} alignItems="flex-start">
                <ListItemText
                    primary={<>{name || 'Весь фид'}</>}
                    secondary={
                        <>
                            <Time time={updateTime} />
                            {Chips}
                        </>
                    }
                />
                <ListItemSecondaryAction>
                    {feed_id !== CREATING_ITEM_ID && <Button icon={<TuneIcon />} onClick={toggleAddModal} />}
                    <Button
                        icon={<LinkIcon />}
                        tooltip="Открыть фид"
                        color="primary"
                        target="_blank"
                        href={`${serverConfig.feedUrl}${feedurl}.xml`}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            {showAddModal && <ExportingFeedModal onClose={toggleAddModal} feed_id={feed_id} export_base_id={export_base_id} />}
        </>
    );
});

const TabBaseFeeds = ({ export_base_id }: { export_base_id: number }) => {
    useMemo(() => {
        exportingBaseFeedStore.fetchItemList(export_base_id);
    }, []);

    const [showAddModal, toggleAddModal] = useToggle();
    const { list } = exportingBaseFeedStore.getItemList(export_base_id);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <ExportFeedSettings export_base_id={export_base_id} />
                <Button
                    style={{ marginTop: '1rem' }}
                    startIcon={<AddCircleOutlineIcon color="primary" />}
                    tooltip="Добавить Фид"
                    onClick={toggleAddModal}
                >
                    Добавить фид
                </Button>
            </Box>
            {showAddModal && <ExportingFeedModal feed_id={CREATING_ITEM_ID} onClose={toggleAddModal} export_base_id={export_base_id} />}

            <List>
                {list.map(({ feed_id, feedurl, name, createTime }) => (
                    <FeedListItem key={feed_id} export_base_id={export_base_id} feed_id={feed_id} />
                ))}
            </List>
        </>
    );
};

export default observer(TabBaseFeeds);
