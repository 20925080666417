import React, { PureComponent, Fragment } from 'react';

import { EditingWrappedProps } from '../EditingBlockWrapper';

import contactStore from '~/stores/contactStore';
import ItemIDBlockEditing from './ItemIDBlockEditing';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import ContactLinkWithPreview from '../../Lists/Contacts/ContactLinkWithPreview';
import { ContactLinkType } from '~/types/contacts.types';

type ContactIDBlockEditingProps = EditingWrappedProps & {
    value?: number | null;
    store: ItemStoreInterface<object, object>;
    contactKey?: string;
    direction?: 'left' | 'right';
};

class ContactIDBlockEditing extends PureComponent<ContactIDBlockEditingProps> {
    render() {
        const { editing, item_id, store, value, contactKey, direction } = this.props;

        return (
            <ItemIDBlockEditing
                searchingItemIdTitle={contactKey || contactStore.item_id}
                itemStore={contactStore}
                itemLinkEmit={(item: ContactLinkType) => <ContactLinkWithPreview contact={item} />}
                editing={editing}
                item_id={item_id}
                store={store}
                value={value}
                placeholder="Выбрать контакт"
                noResultsMessage="Контакт не найден"
                icon="vcard"
                pathname="/contacts/list/profile/0"
                createTitle="Создать контакт"
                direction={direction}
            />
        );
    }
}

export default ContactIDBlockEditing;
