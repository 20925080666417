import React, { PureComponent } from 'react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { TaskLinkState } from '~/components/Lists/Tasks/TaskLink';
import taskStore from '~/stores/taskStore';

class TaskTabMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(taskStore, TaskLinkState, TaskTabMenu, {
    disableDropdownMenu: true
});
