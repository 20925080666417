import { observable, action } from 'mobx';
import deepCopy from '../../common/deepCopy';
import isEqual from '~/common/isEqual';
import { nProgress } from '~/stores/helpers/decorators.helpers';
import { PaginationType } from '~/stores/prototypes/ListStore.prototype';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';
import debounce from '~/common/debounce';

export interface ApiModuleTypeItemLists<ItemObjectType, ItemIdType, ItemListFilter, ItemListOptions> {
    fetchItemList: (item_id: ItemKey, filter: ItemListFilter, limit: { pageSize: number; offset: number }) => Promise<ItemObjectType[]>;
    fetchItemListCount?: (item_id: ItemKey, filter: ItemListFilter) => Promise<number>;
    updateListItem?: (item_id: ItemKey, editingItemId: ItemIdType, editingItem: Partial<ItemObjectType>) => Promise<boolean>;
    updateListOptions?: (item_id: ItemKey, options: Partial<ItemListOptions>) => Promise<boolean>;
    fetchOptions?: (item_id: ItemKey) => Promise<ItemListOptions>;
}

type ItemKey = number | string;

type UpdatingItemType = {
    loading: boolean;
    errors: string[];
};

type ItemListType<ItemObjectType, ItemListFilter, ItemListOptions> = {
    list: ItemObjectType[];
    loadingList: boolean;
    filter: ItemListFilter;
    errors: string[];
    updatingItem: UpdatingItemType;
    options: ItemListOptions | null;
    pagination: PaginationType;
    listCount: number;
};

abstract class ItemListsStorePrototype<ItemObjectType, ItemIdType, ItemListFilter = {}, ItemListOptions = {}> {
    abstract adjustItemToId(item: ItemObjectType): ItemIdType;

    itemListFilterClear: ItemListFilter;
    item_id_key: keyof ItemObjectType;

    apiModule: ApiModuleTypeItemLists<ItemObjectType, ItemIdType, ItemListFilter, ItemListOptions>;

    constructor(
        item_id_key: keyof ItemObjectType,
        apiModule: ApiModuleTypeItemLists<ItemObjectType, ItemIdType, ItemListFilter, ItemListOptions>
    ) {
        this.apiModule = apiModule;
        this.item_id_key = item_id_key;
    }

    @observable
    lists: Map<ItemKey, ItemListType<ItemObjectType, ItemListFilter, ItemListOptions>> = new Map();

    initEmptyList(item_id: ItemKey): void {
        this.setList(item_id, {
            loadingList: false,
            pagination: getDefaultPagination(),
            list: [],
            updatingItem: { loading: false, errors: [] },
            filter: deepCopy(this.itemListFilterClear),
            errors: [],
            options: null,
            listCount: 0
        });
    }

    @action
    async fetchItemList(item_id: ItemKey): Promise<void> {
        const oldList = this.lists.get(item_id);
        const pagination = oldList ? oldList.pagination : getDefaultPagination();

        const preList: ItemListType<ItemObjectType, ItemListFilter, ItemListOptions> = {
            loadingList: true,
            list: oldList && oldList.list ? oldList.list : [],
            updatingItem: { loading: false, errors: [] },
            filter: oldList && oldList.filter ? oldList.filter : deepCopy(this.itemListFilterClear),
            errors: [],
            options: null,
            pagination,
            listCount: oldList ? oldList.listCount : 0
        };

        this.setList(item_id, preList);

        try {
            const { activePage, pageSize } = pagination;
            const offset = (activePage - 1) * pageSize;

            const list = await this.apiModule.fetchItemList(item_id, preList.filter, { pageSize, offset });
            this.setList(item_id, {
                ...preList,
                loadingList: false,
                list,
                pagination,
                listCount: 0
            });

            if (this.apiModule.fetchItemListCount && activePage === 1) {
                const count = await this.apiModule.fetchItemListCount(item_id, preList.filter);
                this.setListCount(item_id, count);
            }
        } catch (errors) {
            this.setList(item_id, { ...preList, loadingList: false, errors });
        }
    }

    @action
    pageChange = (item_id: ItemKey, pageNumber: number) => {
        const list = this.lists.get(item_id);
        list.pagination = { ...list.pagination, activePage: pageNumber };
        this.fetchItemList(item_id);
    };

    @action
    pageSizeChange = (item_id: ItemKey, pageSize: number) => {
        const list = this.lists.get(item_id);
        list.pagination = { pageSize: pageSize, activePage: 1, totalPages: 1 };
        this.fetchItemList(item_id);
    };

    @action
    setList<T extends ItemListType<ItemObjectType, ItemListFilter, ItemListOptions>>(item_id: ItemKey, newList: T) {
        const oldList = this.lists.get(item_id);
        if (!oldList) {
            this.lists.set(item_id, newList);
        } else {
            oldList.list = newList.list;
            oldList.loadingList = newList.loadingList;
            oldList.listCount = newList.listCount || oldList.listCount;

            if (!isEqual(newList.errors, oldList.errors)) {
                oldList.errors = newList.errors;
            }
            if (!isEqual(newList.filter, oldList.filter)) {
                oldList.filter = { ...oldList.filter, ...newList.filter };
            }
            if (!isEqual(newList.pagination, oldList.pagination)) {
                oldList.pagination = { ...oldList.pagination, ...newList.pagination };
            }
        }
    }

    @action
    setListCount(item_id: ItemKey, listCount: number) {
        const list = this.lists.get(item_id);
        list.listCount = listCount;
        list.pagination = matchPaginationTotalPages(list.pagination, listCount);
    }

    @action
    setListOptions(item_id: ItemKey, options: Partial<ItemListOptions>) {
        const item = this.getItemList(item_id);
        item.options = { ...item.options, ...options };
    }

    @action
    changeListFilter<T extends keyof ItemListFilter>(item_id: ItemKey, what: T, value: ItemListFilter[T]) {
        const itemList = this.getItemList(item_id);
        itemList.filter[what] = value;
        itemList.pagination = getDefaultPagination();
        this.fetchItemList(item_id);
    }

    getItemList(item_id: ItemKey): ItemListType<ItemObjectType, ItemListFilter, ItemListOptions> {
        const list = this.lists.get(item_id);
        if (!list) {
            throw Error(`List didn't find: #${item_id}`);
        }
        return list;
    }

    getItemInList(item_id: ItemKey, editingItemId: ItemIdType): ItemObjectType {
        let itemList = this.getItemList(item_id);
        let { list } = itemList;

        const found = list.find(item => isEqual(this.adjustItemToId(item), editingItemId));
        if (!~found) {
            throw new Error(`Запись не найдена #${editingItemId}`);
        }

        return found;
    }

    @nProgress
    @action
    async updateItemList(item_id: ItemKey, editingItemId: ItemIdType, editingItem: Partial<ItemObjectType>): Promise<boolean> {
        let itemList = this.getItemList(item_id);
        try {
            itemList.updatingItem = {
                loading: true,
                errors: []
            };
            let { list } = itemList;

            const foundIndex = list.findIndex(item => isEqual(this.adjustItemToId(item), editingItemId));
            if (!~foundIndex) {
                throw new Error(`Запись не найдена #${editingItemId}`);
            }

            list[foundIndex] = { ...list[foundIndex], ...editingItem };
            await this.apiModule.updateListItem(item_id, editingItemId, editingItem);
            return true;
        } catch (errors) {
            itemList.updatingItem.errors = errors instanceof Array ? errors : [errors.message];
            return false;
        } finally {
            itemList.updatingItem.loading = false;
        }
    }

    @nProgress
    @action
    async updateListOptions(item_id: ItemKey, options: Partial<ItemListOptions>): Promise<void> {
        this.setListOptions(item_id, options);
        await this.apiModule.updateListOptions(item_id, options);
    }

    debounceChanges: Map<ItemKey, () => void> = new Map();

    @action
    setListFilterValue<T extends keyof ItemListFilter>(item_id: ItemKey, what: T, value: ItemListFilter[T]) {
        const item = this.getItemList(item_id);
        item.filter[what] = value;
    }

    @action
    listFilterChange = <T extends keyof ItemListFilter>(item_id: ItemKey, what: T, value: ItemListFilter[T]) => {
        this.setListFilterValue(item_id, what, value);

        if (!this.debounceChanges.get(item_id)) {
            this.debounceChanges.set(
                item_id,
                debounce(() => {
                    this.fetchItemList(item_id);
                    this.debounceChanges.delete(item_id);
                }, 350)
            );
        }

        this.debounceChanges.get(item_id)();
    };

    @action
    async fetchOptions(item_id: ItemKey) {
        try {
            const options = await this.apiModule.fetchOptions(item_id);
            this.setListOptions(item_id, options);
        } catch (errors) {
            const item = this.getItemList(item_id);
            item.errors = errors;
            throw errors;
        }
    }
}

export default ItemListsStorePrototype;
