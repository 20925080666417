import React, { ComponentType } from 'react';
import { ErrorMessage, MessageTitle } from '~ui/Message';
import authStore from '~/stores/authStore';
import { ACCESS_READ, ACCESS_SELF, AccessPermission } from '~/types/access.types';
import { ITEM_TYPES } from '~/types/notifications.types';

const NotEnoughAccess = () => (
    <ErrorMessage>
        <MessageTitle>Недостаточно доступа</MessageTitle>У вас нет доступа для просмотра данного содержания
    </ErrorMessage>
);

export const AccessReadWrapper = <T extends object>(
    WrappedElement: ComponentType<T>,
    moduleName: ITEM_TYPES,
    permission: AccessPermission = ACCESS_SELF
) => {
    return (props: T) => {
        if (!authStore.matchAccess(moduleName, ACCESS_READ, permission)) {
            return <NotEnoughAccess />;
        }

        return <WrappedElement {...props} />;
    };
};

export default NotEnoughAccess;
