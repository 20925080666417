import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import wishStore from '~/stores/wishStore';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import WishFilterEdition from '../EditBlocks/WishFilterEdition';

import { EditingWrappedProps } from '../../EditingBlockWrapper';

import SquareMinMax from '../SubBlocks/SquareMinMax';
import PriceMinMax from '../SubBlocks/PriceMinMax';
import FloorMinMax from '../SubBlocks/FloorMinMax';
import MetroList from '../SubBlocks/MetroList';
import HouseMaterialAndYear from '../SubBlocks/HouseMaterialAndYear';
import RoomsCount from '../SubBlocks/RoomsCount';
import BalconeWc from '../SubBlocks/BalconeWc';

import { nl2br } from '~/api/commentsApi';
import Time from '../../../Base/Time';

@observer
class WishFilterShow extends Component<EditingWrappedProps> {
    render() {
        const { metroStationsListLoading } = estateStore;
        const { item_id, editing } = this.props;
        const { item, loadingItem } = wishStore.getItem(item_id);

        const {
            totalAreaMin,
            totalAreaMax,
            livingAreaMin,
            livingAreaMax,
            kitchenAreaMin,
            kitchenAreaMax,

            priceMin,
            priceMax,
            priceM2Min,
            priceM2Max,
            mortgageApproved,

            main_metro_id,
            transportType,
            transportTime,

            floorNumberMin,
            floorNumberMax,
            floorsCountMin,
            lastFloorOnly,

            materialType,
            buildYearMin,
            buildYearMax,

            description,
            town,
            roomsCount,

            hasLoggia,
            hasBalcone,
            wcType,

            createTime,
            updateTime
        } = item || {};

        return (
            <Card fluid color="grey">
                {!editing && (
                    <Card.Content>
                        <Header size="tiny">Описание</Header>
                        <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                    </Card.Content>
                )}
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Детали</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Комнат</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={!item} print={roomsCount && <RoomsCount roomsCount={roomsCount} />} />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Стоимость</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!item}
                                            print={
                                                <PriceMinMax
                                                    priceMin={priceMin}
                                                    priceMax={priceMax}
                                                    priceM2Min={priceM2Min}
                                                    priceM2Max={priceM2Max}
                                                    mortgageApproved={mortgageApproved}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Площадь</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!item}
                                            print={
                                                <SquareMinMax
                                                    totalAreaMin={totalAreaMin}
                                                    totalAreaMax={totalAreaMax}
                                                    livingAreaMin={livingAreaMin}
                                                    livingAreaMax={livingAreaMax}
                                                    kitchenAreaMin={kitchenAreaMin}
                                                    kitchenAreaMax={kitchenAreaMax}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Этаж</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!item}
                                            print={
                                                <FloorMinMax
                                                    floorNumberMin={floorNumberMin}
                                                    floorNumberMax={floorNumberMax}
                                                    floorsCountMin={floorsCountMin}
                                                    lastFloorOnly={lastFloorOnly}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Дом</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!item}
                                            print={
                                                <HouseMaterialAndYear
                                                    materialType={materialType}
                                                    buildYearMin={buildYearMin}
                                                    buildYearMax={buildYearMax}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Удобства</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={!item}
                                            print={<BalconeWc hasLoggia={hasLoggia} hasBalcone={hasBalcone} wcType={wcType} />}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                {town && (
                                    <Grid.Row>
                                        <Grid.Column>Город</Grid.Column>
                                        <Grid.Column>{town}</Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>

                            <b>Метро</b>
                            {item && !metroStationsListLoading && (
                                <MetroList main_metro_id={main_metro_id} transportType={transportType} transportTime={transportTime} />
                            )}
                        </Fragment>
                    )}
                    {editing && <WishFilterEdition item_id={item_id} editing={editing} {...item} />}
                </Card.Content>
                {!editing && (
                    <Card.Content>
                        <div className="date">Карточка создана {createTime && <Time time={createTime} />}</div>
                        {updateTime && updateTime !== createTime && (
                            <div className="date">Карточка обновлена {updateTime && <Time time={updateTime} />}</div>
                        )}
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(wishStore, WishFilterShow);
