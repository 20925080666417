import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import AddIcon from '@material-ui/icons/Add';

import Button from '~ui/Button';
import { InfoMessage } from '~ui/Message';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import mortgageJobStore from '~/stores/mortgageRequest/mortgageJobStore';

import JobMortgageEditing from './JobMortgageEditing';
import LoaderAwait from '~/components/Base/LoaderAwait';

const MortgageJobButton = observer(
    ({ mortgage_job_id, index, onClick }: { mortgage_job_id: number; index: number; onClick: () => void }) => {
        useMemo(() => {
            mortgageJobStore.fetchItem(mortgage_job_id);
        }, [mortgage_job_id]);

        const { editingItem, loadingItem } = mortgageJobStore.getItem(mortgage_job_id);
        const title = index === 0 ? 'Основная работа' : 'Дополнительная работа';

        return (
            <Tab
                label={
                    <div>
                        <span className="crm-Flex__center">
                            <WorkIcon />
                            &nbsp;
                            <span>{title}</span>
                        </span>
                        <LoaderAwait active={loadingItem} linear />
                    </div>
                }
                onClick={onClick}
            />
        );
    }
);

const useStyle = makeStyles(({ spacing }) => ({
    tabs: {
        marginTop: spacing(1.5),
        marginBottom: spacing(4)
    }
}));

const MortgageJobsList = ({ mortgage_person_id }: { mortgage_person_id: number }) => {
    const classes = useStyle();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { editingItem, loadingItem } = mortgagePersonStore.getItem(mortgage_person_id);
    const { jobs_ids } = editingItem;

    useMemo(() => {
        setActiveIndex(null);
    }, [mortgage_person_id]);

    useEffect(() => {
        if (jobs_ids && jobs_ids.length > 0 && activeIndex === null) {
            setActiveIndex(0);
        } else if (!jobs_ids) {
            setActiveIndex(null);
        }
    }, [jobs_ids, mortgage_person_id]);

    const handleCreate = async () => {
        await mortgageJobStore.createJob(mortgage_person_id);
    };

    const mortgage_job_id = jobs_ids && jobs_ids[activeIndex];

    return (
        <>
            {jobs_ids?.length === 0 && (
                <InfoMessage header="Место работы / Занятость">Нажмите кнопку Добавить, чтобы создать место работы</InfoMessage>
            )}

            <Tabs variant="standard" value={activeIndex ?? false} indicatorColor="primary" textColor="primary" className={classes.tabs}>
                {jobs_ids?.map((mortgage_job_id, index) => (
                    <MortgageJobButton
                        mortgage_job_id={mortgage_job_id}
                        key={mortgage_job_id}
                        onClick={() => setActiveIndex(index)}
                        index={index}
                    />
                ))}
                <Button loading={loadingItem} color="secondary" variant="text" size="medium" onClick={handleCreate} startIcon={<AddIcon />}>
                    Добавить
                </Button>
            </Tabs>

            {mortgage_job_id && <JobMortgageEditing item_id={mortgage_job_id} />}
        </>
    );
};

export default observer(MortgageJobsList);
