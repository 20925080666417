import React, { Component, ComponentType, Fragment, RefObject } from 'react';
import { observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import { ESTATE_BASES } from '~/types/estate.types';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import OutsideClick from '../../../Base/OutsideClick';
import LoadingItemPreviewContent from './LoadingItemPreviewContent';

type TInnerPreviewProps = {
    item_id: number;
    base?: ESTATE_BASES;
    context: RefObject<HTMLElement>;
    openPopup: boolean;

    handleMouseLeave: () => void;
    handleMouseEnter: () => void;
    handleClose: (event: React.SyntheticEvent) => void;
    awaitClose: () => void;
};

type TInnerPreviewState = {
    innerImgIsLoading: boolean;
};

function ItemPreviewWrapperInner<TProps>(store: ListStoreInterface, WrappedComponent: ComponentType<any>) {
    @observer
    class InnerPreviewPopup extends Component<TInnerPreviewProps, TInnerPreviewState> {
        state = {
            innerImgIsLoading: false
        };

        onInnerImgLoad = () => {
            this.setState({ innerImgIsLoading: false });
        };

        render() {
            const { item_id, openPopup, base } = this.props;
            const { innerImgIsLoading } = this.state;

            let contentIsNotReady = false;
            if (openPopup) {
                const { loadingItem, item } = store.getItem(item_id);
                contentIsNotReady = loadingItem || !item;
            }

            return (
                <Popup
                    wide
                    context={this.props.context}
                    popperDependencies={[contentIsNotReady, innerImgIsLoading]}
                    open={openPopup}
                    onMouseLeave={this.props.handleMouseLeave}
                    onMouseEnter={this.props.handleMouseEnter}
                    className="crm-Item__preview"
                >
                    <OutsideClick onOutsideClick={this.props.awaitClose}>
                        {contentIsNotReady ? (
                            <LoadingItemPreviewContent />
                        ) : (
                            <WrappedComponent
                                base={base}
                                handleClose={this.props.handleClose}
                                onImgLoad={this.onInnerImgLoad}
                                {...this.props}
                            />
                        )}
                    </OutsideClick>
                </Popup>
            );
        }
    }

    return InnerPreviewPopup;
}

export default ItemPreviewWrapperInner;
