import React from 'react';
import { observer } from 'mobx-react';

import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Button from '~ui/Button';
import settingStore from '~/stores/settingStore';
import { AVITO_EXPORT_BASE_ID } from '~/types/exports.types';
import BaseApiEditingWrapper, { BaseApiEditingWrappedProps } from '../BaseApiEditingWrapper';
import { AvitoApiConfig, AVITO_API_KEY } from '~/types/settings.types';

const AvitoApiSettingsField = ({ checkTest, handleEditValue, answer, loadingTest, apiIndex }: BaseApiEditingWrappedProps) => {
    const { editingItem, loadingItem } = settingStore.getItem(AVITO_API_KEY);

    let user_id = null;
    let client_id = '';
    let client_secret = '';
    if (editingItem?.value) {
        const settings: AvitoApiConfig = JSON.parse(editingItem?.value);
        user_id = settings[apiIndex].user_id;
        client_id = settings[apiIndex].client_id;
        client_secret = settings[apiIndex].client_secret;
    }

    return (
        <Grid justify="flex-end">
            <Grid.Column width={4}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="Номер профиля (user_id)"
                    loading={loadingItem}
                    value={user_id}
                    name="user_id"
                    onChange={handleEditValue}
                />
            </Grid.Column>
            <Grid.Column width={4}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="client_id"
                    loading={loadingItem}
                    value={client_id}
                    name="client_id"
                    onChange={handleEditValue}
                />
            </Grid.Column>
            <Grid.Column width={4}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="client_secret"
                    loading={loadingItem}
                    value={client_secret}
                    name="client_secret"
                    onChange={handleEditValue}
                />
            </Grid.Column>
            <Grid.Column width={3}>
                <Button
                    color={!answer ? 'secondary' : 'primary'}
                    loading={loadingItem || loadingTest}
                    disabled={Boolean(answer)}
                    onClick={checkTest}
                >
                    {!answer ? 'Протестировать' : 'Ключ сохранен'}
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default BaseApiEditingWrapper(observer(AvitoApiSettingsField), AVITO_EXPORT_BASE_ID, AVITO_API_KEY);
