import React, { SyntheticEvent, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import history from '~/history';

import Portal from '@material-ui/core/Portal';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import CommentIcon from '@material-ui/icons/Comment';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import RemoveIcon from '@material-ui/icons/Remove';

import commentStore from '~/stores/commentStore';
import { COMMENT_TYPE, CommentType } from '~/types/comments.types';

import CommentsSheet, { AdditionalComments } from './CommentsSheet';
import Scrollbar from '~/components/Base/Scrollbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: '1em 1em 0 0',
            padding: '1em',
            background: '#f5f6fa'
        },
        speedDial: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: 30
        },
        mouseHandler: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30px'
        },
        scrollbar: {
            maxHeight: 'calc(100vh - 150px)'
        }
    })
);

type CommentsSpeedDialBadgeProps = {
    item_id: number;
    item_type: COMMENT_TYPE;
    onClick: () => void;
    open: boolean;
};

const CommentsSpeedDialBadge = observer(({ item_id, item_type, onClick, open }: CommentsSpeedDialBadgeProps) => {
    const { comments } = commentStore.getItem(item_type, item_id);
    const classes = useStyles();

    return (
        <Badge badgeContent={comments.length} color="secondary" className={classes.speedDial}>
            <Fab disabled={open} onClick={onClick} color="default">
                <CommentIcon />
            </Fab>
        </Badge>
    );
});

type CommentsProps = {
    item_id: number;
    item_type: COMMENT_TYPE;
    multiTypes?: AdditionalComments[];
    onAddComment?: (comment: CommentType[]) => void;
    minimal?: boolean;
};

const CommentSpeedDial = (props: CommentsProps) => {
    const { item_type, item_id } = props;
    const mainRef = useRef<HTMLHeadingElement>(null);
    const [portalContainer, setPortalContainer] = useState<Element>(null);

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useMemo(() => {
        history.listen((location, action) => {
            if (action === 'PUSH') {
                handleClose();
            }
        });
    }, []);

    useEffect(() => {
        const box = mainRef.current.closest('.crm-List__sidebar_modalBox');
        setPortalContainer(box);
    }, []);

    const [$scrollRef, setRef] = useState<HTMLElement>(null);
    const handleSetRef = useCallback((ref: HTMLElement) => {
        if (!$scrollRef) {
            setRef(ref);
        }
    }, []);

    const [disabledTouchClose, setDisableTouchClose] = useState(true);
    const [timeout, setDisableTimeout] = useState<number | null>(null);

    const disableTouchMove = (event: SyntheticEvent) => {
        if (!$scrollRef.scrollTop && disabledTouchClose && !timeout) {
            const timeoutStart = window.setTimeout(() => {
                setDisableTouchClose(false);
            }, 400);
            setDisableTimeout(timeoutStart);
        }

        if ($scrollRef.scrollTop) {
            event.stopPropagation();
            if (timeout) {
                clearTimeout(timeout);
                setDisableTimeout(null);
            }
        } else if (disabledTouchClose) {
            event.stopPropagation();
        }
    };

    return (
        <div ref={mainRef}>
            <Portal container={portalContainer}>
                <CommentsSpeedDialBadge item_id={item_id} item_type={item_type} onClick={handleOpen} open={open} />
            </Portal>

            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                classes={{
                    paper: classes.paper
                }}
            >
                <div className={classes.mouseHandler}>
                    <RemoveIcon />
                </div>

                <div onTouchMove={disableTouchMove}>
                    <Scrollbar className={classes.scrollbar} containerRef={handleSetRef}>
                        <CommentsSheet {...props} />
                    </Scrollbar>
                </div>
            </SwipeableDrawer>
        </div>
    );
};

export default observer(CommentSpeedDial);
