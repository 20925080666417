import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import DealAddMode from './DealAddMode';
import DealShowMode from './DealShowMode';
import DealHistoryMode from './DealHistoryMode';
import DealTabMenu from './DealTabMenu';
import DocumentsTable from '../../Documents/DocumentsTable';

import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';
import { ItemWrapperState } from '../ItemWrapper';
import ItemErrors from '../ItemErrors';
import dealStore from '~/stores/dealStore';
import estateStore from '~/stores/estateStore';
import { MenuItem, TAB_DOCUMENTS_ID } from '../TabMenuWrapper';

import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';

@observer
class DealItem extends Component<ItemWrapperState> {
    render() {
        const DealTabs: MenuItem[] = [
            { name: 'Обзор', id: '', color: 'blue', icon: <ReceiptIcon /> },
            { name: 'История', id: TAB_ID_HISTORY, color: 'brown', icon: <HistoryIcon /> }
        ];

        const { item_id, tabName, param } = this.props;

        const { errors, item } = dealStore.getItem(item_id);
        const major_user_id = item ? item.usersFees.map(({ user_id }) => user_id) : [];

        const additionalDocuments = [];

        if (item && item.estate_id !== null && Number(item.estate_id) > 0) {
            additionalDocuments.push({
                item_type: estateStore.moduleName,
                item_id: item.estate_id,
                sourceTitle: 'С прикрепленного листинга'
            });
        }

        return (
            <Fragment>
                <DealTabMenu
                    itemTabs={DealTabs}
                    item_id={item_id}
                    param={param}
                    tabName={tabName}
                    enable={Boolean(item ? item.enable : true)}
                    documentsCount={item ? item.documentsCount : 0}
                    additionalDocuments={additionalDocuments}
                    major_user_id={major_user_id}
                />

                <div className="crm-List__sidebar_modalBox">
                    {errors && <ItemErrors errors={errors} tabName={tabName} />}
                    {tabName === TAB_ID_CREATING && <DealAddMode param={param} />}
                    {tabName === TAB_ID_DEFAULT && <DealShowMode deal_id={item_id} />}
                    {tabName === TAB_ID_HISTORY && <DealHistoryMode item_id={item_id} />}
                    {tabName === TAB_DOCUMENTS_ID && (
                        <DocumentsTable item_id={item_id} item_type={dealStore.moduleName} additionalTypes={additionalDocuments} />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(dealStore, DealItem);
