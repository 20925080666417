import React, { Component, Fragment } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { HandleChangeType } from '../../HandlerEditingBlockWrapper';

type HighwayEditingProps = {
    item_id: number;
    editing?: boolean;
    highway_id?: number | null;
    distanceFromMKAD?: number | null;
};

@observer
class HighwayEditing extends Component<HighwayEditingProps> {
    constructor(props: HighwayEditingProps) {
        super(props);

        const { item_id } = props;

        estateStore.setEditingItem(item_id, {
            highway_id: props.highway_id || null,
            distanceFromMKAD: props.distanceFromMKAD || null
        });
    }

    handleChange: HandleChangeType = (event, { name, value }) => {
        event.preventDefault();

        estateStore.setEditingItem(this.props.item_id, {
            [name]: value === '' ? null : Number(value)
        });
    };

    render() {
        const { editing, item_id } = this.props;

        const { highwaysList, metroStationsListLoading } = estateStore;
        const { highway_id, distanceFromMKAD } = estateStore.getItem(item_id).editingItem;

        const highwaysListDropdown = highwaysList.map(({ name, highway_id }) => ({
            key: highway_id,
            value: highway_id,
            text: name
        }));

        return (
            <Fragment>
                {editing && <br />}
                <div className="crm-Estate__address_newbuilding">
                    <div>
                        <label className="crm-Estate__field_label">Шоссе</label>
                        <Dropdown
                            size="mini"
                            fluid
                            selection
                            options={highwaysListDropdown}
                            value={highway_id}
                            name="highway_id"
                            placeholder="шоссе"
                            onChange={this.handleChange}
                            search
                            loading={metroStationsListLoading}
                            clearable
                            type="clearable"
                        />
                    </div>
                    <div className="crm-Estate__address_house">
                        <label className="crm-Estate__field_label">от МКАД, км</label>
                        <Input
                            type="number"
                            min={0}
                            name="distanceFromMKAD"
                            value={distanceFromMKAD || ''}
                            onChange={this.handleChange}
                            placeholder="от МКАД, км"
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HighwayEditing;
