import React, { Component, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import wishStore from '~/stores/wishStore';
import FilterMoreDetailsBlock from '../FilterBlocks/FilterWishMoreDetails';
import { WishFilter } from '~/types/wish.types';
import { AWAIT_LOADER_SHOW_TIME_MS } from '~/components/Base/LoaderAwait';
import Progress from '~ui/Progress';
import Scrollbar from '~/components/Base/Scrollbar';

@observer
class WishesMapFilter extends Component<{ onCloseFilter: () => void }> {
    handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: keyof WishFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        wishStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: keyof WishFilter; checked: boolean }) => {
        event.preventDefault();
        wishStore.changeFilter(name, checked);
    };

    handleReset = () => {
        wishStore.startLoading();
        wishStore.clearFilter();
        // чтобы LoaderAwait отобразился
        setTimeout(() => {
            wishStore.debounceFilterFetch();
        }, AWAIT_LOADER_SHOW_TIME_MS);
    };

    handleApplyFilter = () => {
        wishStore.saveListSearch();
        this.props.onCloseFilter();
    };

    render() {
        const { loadingList, listCount } = wishStore;

        return (
            <div style={{ zIndex: 1001 }}>
                <Scrollbar suppressScrollX>
                    <div style={{ padding: '1rem', maxHeight: 'calc(100vh - 140px)', maxWidth: '650px', margin: '0 auto' }}>
                        <FilterMoreDetailsBlock handleChange={this.handleChange} handleCheckbox={this.handleCheckbox} />

                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item>
                                <Button variant="text" onClick={this.handleReset}>
                                    Очистить
                                </Button>
                                <Button variant="contained" color="secondary" onClick={this.handleApplyFilter}>
                                    Показать ({listCount})
                                    <Progress show={loadingList} size={24} />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Scrollbar>
            </div>
        );
    }
}

export default WishesMapFilter;
