import React, { Fragment, Component, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { Header, Grid } from 'semantic-ui-react';
import Time from '~/components/Base/Time';
import OutsideClick from '~/components/Base/OutsideClick';
import history from '~/history';

import { TransitionGroup } from 'react-transition-group';

import ChatIcon from '@material-ui/icons/Chat';
import IconButton from '@material-ui/core/IconButton';

import Popover from '@material-ui/core/Popover';
import chatStore from '~/stores/chatStore';
import UserAvatar from '~ui/UserAvatar';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Slide from '@material-ui/core/Slide';
import { InfoMessage } from '~ui/Message';
import Divider from '~ui/Divider';

import HeaderAnimatedIconButton from './HeaderAnimatedIconButton';

type HeaderNotificationsState = {
    popupIsOpen: boolean;
};

@observer
export default class MessagesNotifications extends Component<{}, HeaderNotificationsState> {
    state = {
        popupIsOpen: false
    };

    handleReadNotification = (event: SyntheticEvent, message_id: number) => {
        event.stopPropagation();
        chatStore.readChatMessage(message_id);
    };

    readAllNotReadMessages = () => {
        chatStore.readAllNotReadMessages();
    };

    handleItemClick = (chat_id: number, item_id: number, type: 'avito' | 'users') => {
        this.setState({ popupIsOpen: false });
        // notificationStore.readNotification(noty_id);
        history.push(`/chat/${type}/${chat_id}/${item_id}`);
    };

    handleTogglePopup = () => {
        this.setState({ popupIsOpen: !this.state.popupIsOpen });
    };

    handleClosePopup = () => {
        this.setState({ popupIsOpen: false });
    };

    $triggerRef: HTMLElement;

    render() {
        const { notReadMessagesList } = chatStore;
        const { popupIsOpen } = this.state;
        const count = notReadMessagesList.length;

        return (
            <Fragment>
                <HeaderAnimatedIconButton
                    count={notReadMessagesList.length}
                    icon={<ChatIcon />}
                    onToggle={this.handleTogglePopup}
                    setRef={$ref => (this.$triggerRef = $ref)}
                />

                <Popover
                    open={popupIsOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    anchorEl={this.$triggerRef}
                >
                    <OutsideClick onOutsideClick={this.handleClosePopup} style={{ padding: '1em' }}>
                        <Grid className="crm-Header__notifications_header">
                            <Grid.Column width={16}>
                                <Header as="h4">Сообщения</Header>
                            </Grid.Column>
                        </Grid>
                        <Divider />

                        {count === 0 && <InfoMessage variant="outlined">У вас нет новых сообщений</InfoMessage>}

                        {count > 0 && (
                            <List className="crm-Header__notifications_feed" dense disablePadding>
                                <TransitionGroup>
                                    {notReadMessagesList.map(
                                        ({ message_id, chat_id, message, avito_chat_id, createTime, sender, estate_id }) => (
                                            <Slide direction="right" in key={message_id}>
                                                <ListItem
                                                    dense
                                                    disableGutters
                                                    button
                                                    onClick={this.handleItemClick.bind(
                                                        this,
                                                        avito_chat_id || chat_id,
                                                        estate_id || sender['user_id'],
                                                        avito_chat_id ? 'avito' : 'users'
                                                    )}
                                                >
                                                    <ListItemIcon>
                                                        <UserAvatar size={48} src={sender['avatarUrl']} name={''} />
                                                    </ListItemIcon>

                                                    <ListItemText
                                                        disableTypography
                                                        primary={
                                                            <Typography component="div" variant="subtitle2" color="textPrimary">
                                                                {estate_id
                                                                    ? `Авито: ${sender['name']}`
                                                                    : `${sender['lastName']} ${sender['firstName']}`}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Fragment>
                                                                <Typography component="div" variant="caption" color="textSecondary">
                                                                    <Time time={createTime} />
                                                                </Typography>
                                                                <Typography component="div" variant="body2" color="textSecondary">
                                                                    {message.substring(0, 35) + '...'}
                                                                </Typography>
                                                            </Fragment>
                                                        }
                                                    />

                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            edge="end"
                                                            title="Прочитано"
                                                            onClick={event => this.handleReadNotification(event, message_id)}
                                                        >
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </Slide>
                                        )
                                    )}
                                </TransitionGroup>
                            </List>
                        )}
                        {count > 0 && (
                            <>
                                <Divider />
                                <div style={{ display: 'flex' }}>
                                    <Button variant="text" size="small" style={{ flex: 1 }} onClick={this.readAllNotReadMessages}>
                                        Отметить все как прочитанное
                                    </Button>
                                </div>
                            </>
                        )}
                    </OutsideClick>
                </Popover>
            </Fragment>
        );
    }
}
