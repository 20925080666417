import React from 'react';
import { Table, List, Checkbox } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';

import Time from '~/components/Base/Time';
import PhonesList from '~/components/Base/PhonesList';
import { ContactTableItem } from '~/types/contacts.types';
import UsersLinkList from '../Users/UsersLinkList';
import ListComments from '../Common/ListComments';
import ListTagsPopup from './ListTagsPopup';
import EstatesLinkList from '../Estate/EstateLinkList';
import SimpleTableCell from '../Common/SimpleTableCell';
import ContactLinkWithPreview from './ContactLinkWithPreview';
import { execDaily } from '~/common/time';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
    time: {
        marginRight: spacing(0.75)
    }
}));

const ContactsTableBody = ({
    contactsList,
    selectedItemsIds,
    toggleItemSelected,
    showEstates
}: {
    contactsList: ContactTableItem[];
    selectedItemsIds?: number[];
    toggleItemSelected?: (index: number) => void;
    showEstates?: boolean;
}) => {
    const classes = useStyles();

    return (
        <Table.Body>
            {contactsList.length > 0 &&
                contactsList.map(
                    ({
                        major_users,
                        contact_id,
                        companyName,
                        contactType,
                        comments,
                        updateTime,
                        createTime,
                        phones,
                        lastCallTime,
                        firstName,
                        lastName,
                        middleName,
                        tags_ids,
                        estates,
                        nearestUncompletedCallEvent
                    }) => {
                        let callTabTitle = ``;
                        let color = 'green';

                        if (nearestUncompletedCallEvent) {
                            if (nearestUncompletedCallEvent.callTimeMax * 1000 < Date.now()) {
                                callTabTitle += '📞 просрочен';
                                color = 'red';
                            } else {
                                callTabTitle += `📞 ${execDaily(nearestUncompletedCallEvent.callTimeMin * 1000).printTime}`;
                            }
                        }

                        return (
                            <Table.Row key={`Contact_${contact_id}`}>
                                {toggleItemSelected && selectedItemsIds instanceof Array && (
                                    <SimpleTableCell preventDblClick collapsing>
                                        <Checkbox
                                            onClick={() => toggleItemSelected(contact_id)}
                                            checked={selectedItemsIds.includes(contact_id)}
                                        />
                                    </SimpleTableCell>
                                )}
                                <SimpleTableCell preventDblClick>
                                    <ContactLinkWithPreview
                                        contact={{ companyName, contactType, firstName, lastName, middleName, contact_id }}
                                    />
                                    <div>
                                        <span className={classes.time}>
                                            <Time time={updateTime} />
                                        </span>
                                        <Tooltip title="Запланированный звонок">
                                            <span className="crm-Estate__fieldNowrap" style={{ color }}>
                                                {callTabTitle}
                                            </span>
                                        </Tooltip>
                                    </div>
                                </SimpleTableCell>
                                {showEstates && (
                                    <SimpleTableCell preventDblClick>
                                        <EstatesLinkList estatesList={estates} />
                                    </SimpleTableCell>
                                )}
                                <SimpleTableCell preventDblClick>
                                    <UsersLinkList usersList={major_users} />
                                </SimpleTableCell>
                                <SimpleTableCell>
                                    <ListTagsPopup tags_ids={Array.from(tags_ids)} />
                                </SimpleTableCell>
                                <SimpleTableCell preventDblClick>
                                    <ListComments comments={comments} item_id={contact_id} item_type="CONTACT" />
                                </SimpleTableCell>
                                <SimpleTableCell preventDblClick>
                                    <List>
                                        <PhonesList phones={Array.from(phones)} />
                                        {lastCallTime && (
                                            <List.Item title="Последний состоявшийся звонок">
                                                <List.Icon name="phone square" color="green" />
                                                <List.Content>
                                                    <Time time={lastCallTime} />
                                                </List.Content>
                                            </List.Item>
                                        )}
                                    </List>
                                </SimpleTableCell>
                            </Table.Row>
                        );
                    }
                )}
        </Table.Body>
    );
};

export default ContactsTableBody;
