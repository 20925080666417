import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import history from '~/history';
import Progress from '~/components/Base/ui/Progress';
import ListError from '~/components/ListError';
import contactStore from '~/stores/contactStore';
import { ContactLinkState, matchContactTitle } from '~/components/Lists/Contacts/ContactLink';

type ChatHeaderProps = {
    item_id: number;
};

const WhatsappChatHeader = ({ item_id }: ChatHeaderProps) => {
    useMemo(() => {
        if (item_id > 0) {
            contactStore.fetchItem(item_id);
        }
    }, [item_id]);

    if (item_id === 0) {
        return null;
    }

    const { item: contact, loadingItem, errors } = contactStore.getItem(item_id);
    if (!contact || loadingItem) {
        return <Progress show size={48} />;
    }

    if (errors && errors.length) {
        return <ListError errors={errors} />;
    }

    return (
        <Button onClick={() => history.push(ContactLinkState(item_id))} style={{ padding: 0, margin: 0 }}>
            <Typography variant="h6" noWrap style={{ marginLeft: '0.5em' }}>
                {matchContactTitle(contact)}
            </Typography>
        </Button>
    );
};

export default observer(WhatsappChatHeader);
