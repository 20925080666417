import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

import UserAvatar from '~/components/Base/ui/UserAvatar';
import Typography from '@material-ui/core/Typography';
import history from '~/history';
import { UserLinkState } from '~/components/Lists/Users/UserLink';
import userStore from '~/stores/userStore';
import commonStore from '~/stores/commonStore';
import { DELTA_ONLINE_TIME } from '~/components/Base/Time';
import Progress from '~/components/Base/ui/Progress';
import ListError from '~/components/ListError';

type ChatHeaderProps = {
    item_id: number;
};

@observer
export default class UserChatHeader extends Component<ChatHeaderProps, ChatHeaderProps> {
    constructor(props: ChatHeaderProps) {
        super(props);
        userStore.fetchItem(props.item_id);
        this.state = { ...props };
    }

    static getDerivedStateFromProps(nextProps: ChatHeaderProps, currentState: ChatHeaderProps) {
        if (nextProps.item_id !== currentState.item_id) {
            userStore.fetchItem(nextProps.item_id);
        }
        return {
            item_id: nextProps.item_id
        };
    }

    render() {
        const { item: user, loadingItem, errors } = userStore.getItem(this.props.item_id);

        if (errors && errors.length) {
            return <ListError errors={errors} />;
        }

        if (!user || loadingItem) {
            return <Progress show size={48} />;
        }

        const currentUserName = `${user.firstName} ${user.lastName}`.trim();
        const isOnline = Date.now() - user.onlineTime * 1000 < DELTA_ONLINE_TIME;

        return (
            <Button onClick={() => history.push(UserLinkState(user.user_id))} style={{ padding: 0, margin: 0 }}>
                <UserAvatar
                    name={currentUserName}
                    src={user.avatarUrl}
                    size={commonStore.isMobile ? 36 : 60}
                    showStatus
                    isOnline={isOnline}
                />

                <Typography variant="h6" noWrap style={{ marginLeft: '0.5em' }}>
                    {currentUserName}
                </Typography>
            </Button>
        );
    }
}
