import React, { Component, Fragment } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const ShowingsTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell sorted={orderBy === 'showingTime' ? orderDirection : null} onClick={() => handleSort('showingTime')}>
                    Дата
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'user_id' ? orderDirection : null} onClick={() => handleSort('user_id')}>
                    Сотрудник
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'contact_id' ? orderDirection : null} onClick={() => handleSort('contact_id')}>
                    Клиент
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'user_id' ? orderDirection : null} onClick={() => handleSort('estate_id')}>
                    Объект
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};

export default ShowingsTableHeader;
