import React, { Fragment } from 'react';
import MonoString from '../../../Base/MonoString';

export default ({
    floorNumberMin,
    floorNumberMax,
    floorsCountMin,
    lastFloorOnly,
    short
}: {
    floorNumberMin: number | null;
    floorNumberMax: number | null;
    floorsCountMin: number | null;
    lastFloorOnly: boolean | null;
    short?: boolean;
}) => {
    const template = short ? '? – ?' : 'от ? до ?';
    const templateLastFloor = short ? 'последний' : 'последний этаж';

    return (
        <Fragment>
            {(floorNumberMin || floorNumberMax) && (
                <div>
                    <MonoString template={template} values={[floorNumberMin || '..', floorNumberMax || '..']} />
                </div>
            )}
            {!short && floorsCountMin && (
                <div>
                    <MonoString template="Дом не ниже ?" values={[floorsCountMin]} />
                </div>
            )}
            {lastFloorOnly && (
                <Fragment>
                    <MonoString template={templateLastFloor} />
                </Fragment>
            )}
        </Fragment>
    );
};
