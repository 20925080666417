import { observable, action, runInAction } from 'mobx';
import * as meetingEventApi from '~/api/meetingEventApi';

import CalendarEventsStorePrototype from './prototypes/CalendarEventsStore.prototype';
import { MeetingEventFilter, MeetingEvent } from '~/types/events.types';
import deepCopy from '../common/deepCopy';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import calendarStore from './calendarStore';
import contactStore from './contactStore';
import { CALL_EVENT_ITEM_TYPE_CONTACT } from '~/types/events.types';
import { CalendarEventType } from '~/types/calendar.types';

class MeetingEventStore extends CalendarEventsStorePrototype<MeetingEvent, MeetingEventFilter> {
    listFilterClear = {
        enable: true
    };

    orderBy = 'meeting_event_id';

    constructor() {
        super('meeting_event_id', 'meetingEvent', meetingEventApi);
        this.clearFilter();
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const sourceItem = this.getItem(id).item || {};
        this.setEditingItem(id, deepCopy(sourceItem));
    }

    @action
    updateContactProperty(editingItem: Partial<MeetingEvent>) {
        try {
            const { item_id } = editingItem;
            if (editingItem.item_type === CALL_EVENT_ITEM_TYPE_CONTACT && item_id) {
                contactStore.getItem(item_id);
                contactStore.meetingEventsByContact(item_id);
            }
        } catch (e) {
            console.log(e);
        }
    }

    @action
    async createItem(): Promise<number> {
        const editingItem = this.getItem(CREATING_ITEM_ID).editingItem;

        const meeting_event_id = await super.createItem();

        calendarStore.addNewEvent({
            item_id: meeting_event_id,
            item_type: this.moduleName,
            title: editingItem.goal,
            allDay: false,
            start: new Date(editingItem.startTime * 1000),
            end: new Date(editingItem.endTime * 1000)
        });

        this.updateContactProperty(editingItem);

        return meeting_event_id;
    }

    @action
    async saveItem(meeting_event_id: number): Promise<Partial<MeetingEvent> | boolean> {
        const editingItem = this.getItem(meeting_event_id).editingItem;
        await super.saveItem(meeting_event_id);

        const mergedEvent: Partial<CalendarEventType> = {};
        if (editingItem.goal) {
            mergedEvent.title = editingItem.goal;
        }

        ['startTime', 'endTime'].forEach(timeKey => {
            if (editingItem[timeKey]) {
                mergedEvent[timeKey === 'startTime' ? 'start' : 'end'] = new Date(editingItem[timeKey] * 1000);
            }
        });

        if (editingItem.enable) {
            calendarStore.mergeEvent({
                item_id: meeting_event_id,
                item_type: this.moduleName,
                allDay: false,
                ...mergedEvent
            });
        } else {
            calendarStore.removeEvent(meeting_event_id, this.moduleName);
        }

        this.updateContactProperty(editingItem);

        return Boolean(meeting_event_id);
    }

    validationItem(meetingEvent: MeetingEvent): string[] {
        const errors: string[] = [];

        if (!meetingEvent.item_id) {
            errors.push('Заполните "С Кем"');
        }

        if (!meetingEvent.assign_users_ids.length) {
            errors.push('Не назначен ответсвенный(е)');
        }

        return errors;
    }
}

const store = new MeetingEventStore();
calendarStore.registerEventsStore(store);

export default store;
