import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TableCell } from '~ui/Table';

const useStyles = makeStyles(({ palette, spacing }) => ({
    red: {
        backgroundColor: palette.error.light
    },
    green: {
        backgroundColor: palette.success.light
    }
}));

const StatisticsCell = ({ value, redValue, greenValue }: { value: number; redValue: number | null; greenValue: number | null }) => {
    const classes = useStyles();
    let className = '';
    if (greenValue && value >= greenValue) {
        className = classes.green;
    } else if (redValue && value < redValue) {
        className = classes.red;
    }

    return (
        <TableCell align="right" className={className}>
            {value.toLocaleString()}
        </TableCell>
    );
};

export default React.memo(StatisticsCell);
