import { observable, action, runInAction } from 'mobx';
import isEqual from '../../common/isEqual';
import deepCopy from '../../common/deepCopy';
import indexDB from '../../common/indexDB';
import authStore from '../authStore';
import { UserLinkType } from '~/types/users.types';
import { HistoryChange } from '~/types/historyChanges.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import { nProgressItem } from '../helpers/decorators.helpers';
import wait from '../../common/wait';

export const CREATING_ITEM_ID = 0;

export type ItemHistoryType = {
    loadingHistory?: boolean;
    history?: HistoryChange[];
};

export type ItemType<ItemObjectType extends TItemExtended, ItemPropertyType> = {
    item?: ItemObjectType | null;
    editingItem: Partial<ItemObjectType>;
    editingBlockId?: symbol;
    loadingItem: boolean;
    errors: string[];
    property: Partial<ItemPropertyType>;
    history: ItemHistoryType;
};

export interface ApiModuleType<ItemObjectType extends TItemExtended> {
    fetchItem?: (id: ItemKey, base?: string | null) => Promise<ItemObjectType>;
    saveItem?: (id: ItemKey, changedItem: Partial<ItemObjectType>) => Promise<number>;
    createItem?: (item: Partial<ItemObjectType>) => Promise<number>;
    fetchItemHistory?: (item_id: ItemKey, base?: string) => Promise<HistoryChange[]>;
}

export type TItemExtended = {
    major_user_ids?: number[];
    major_users?: UserLinkType[];
    major_user_id?: number;
    major_user?: UserLinkType;
    enable?: boolean;
};

export interface ItemStoreInterface<TItem extends TItemExtended, TProperty = {}> {
    moduleName: ITEM_TYPES;
    item_id: string;
    fetchItem(id: ItemKey, base?: string, withoutCache?: boolean): Promise<void>;
    reloadItem(id: ItemKey): Promise<void>;
    setEditingItem(id: ItemKey, editingProps: Partial<TItem>): void;
    setEditingBlockId(number, Symbol): void;
    getItem(number): ItemType<TItem, TProperty>;
    saveItem(number): Promise<Partial<TItem> | boolean>;
    getItemForAccess(number): ItemType<TItem, TProperty>;
    changeArrayValue(id: ItemKey, arrayFieldName: keyof TItem, index: number, prop: string | null, value: unknown): void;
    toggleDisableItem(number, boolean): Promise<void>;
    clearEditingItem(item_id: ItemKey): void;
    clearEditingItemOnly(item_id: ItemKey): void;
    createItem(): Promise<number>;
    fetchItemHistory(item_id: ItemKey, base?: string): Promise<void>;
}

const emptyItem = {
    item: null,
    loadingItem: false,
    editingItem: {},
    errors: [],
    history: {},
    property: {}
};

const CACHE_TIME_WITHOUT_RELOAD_MS = 300000;

type ItemKey = number | string;

class ItemStorePrototype<ItemObjectType extends TItemExtended, ItemPropertyType = {}>
    implements ItemStoreInterface<ItemObjectType, ItemPropertyType> {
    item_id: string;

    ApiModule: ApiModuleType<ItemObjectType>;
    moduleName: ITEM_TYPES;

    @observable
    liveDraft = false;

    constructor(item_id: string, moduleName: ITEM_TYPES, ApiModule: ApiModuleType<ItemObjectType>) {
        this.ApiModule = ApiModule;
        this.moduleName = moduleName;
        this.item_id = item_id;

        setTimeout(this.onAfterInitHook.bind(this), 0);
    }

    onAfterInitHook(): void {}

    @action
    toogleLiveDraft() {
        this.liveDraft = !this.liveDraft;
        if (!this.liveDraft) {
            localStorage.removeItem(`creating_${this.moduleName}`);
        }
    }

    @observable
    items: Map<ItemKey, ItemType<ItemObjectType, ItemPropertyType>> = new Map();

    @action
    async reloadItem(id: ItemKey) {
        this.setItem(id, deepCopy<ItemType<ItemObjectType, ItemPropertyType>>(emptyItem));
        this.fetchItem(id);
    }

    @action
    async fetchItem(id: ItemKey, base?: string | null, withoutCache?: boolean) {
        if (id !== CREATING_ITEM_ID) {
            const oldItem = this.items.get(id);
            let itemStored: { item: ItemObjectType; time: number } | undefined = undefined;

            const preItem = {
                loadingItem: !(oldItem && oldItem.item),
                property: oldItem && oldItem.property ? oldItem.property : {},
                history: oldItem && oldItem.history ? oldItem.history : {},
                editingItem: {},
                errors: [],
                item: oldItem && oldItem.item ? oldItem.item : null
            };
            this.setItem(id, preItem);

            if (!oldItem && !withoutCache) {
                try {
                    itemStored = await indexDB.get<{ item: ItemObjectType; time: number }>(id, `${this.moduleName}_${base || ''}`);
                    if (itemStored && itemStored.item) {
                        const { item } = itemStored;
                        this.setItem(id, deepCopy({ ...emptyItem, item }));
                    }
                } catch (error) {}
            }

            try {
                await wait(150);
                const canUseCache = itemStored && !withoutCache && Date.now() - itemStored.time < CACHE_TIME_WITHOUT_RELOAD_MS;
                const item = canUseCache && itemStored ? itemStored.item : await this.ApiModule.fetchItem(id, base);
                try {
                    if (!canUseCache) {
                        indexDB.put(id, `${this.moduleName}_${base || ''}`, item);
                    }
                } catch (e) {}
                const { history, property } = this.getItem(id);

                const newItem = {
                    loadingItem: false,
                    item,
                    property: property || {},
                    history: history || {},
                    errors: [],
                    editingItem: {}
                };
                this.setItem(id, newItem);
            } catch (errors) {
                // if !withoutCache last try to reach item in memory, may be the problem is in an internet connection
                if (!withoutCache && !itemStored) {
                    itemStored = await indexDB.get(id, `${this.moduleName}_${base || ''}`);
                }
                this.setItem(id, {
                    errors,
                    loadingItem: false,
                    property: {},
                    history: {},
                    editingItem: {},
                    item: itemStored ? itemStored.item : null
                });
            }
        } else if (!this.items.get(id)) {
            let editingItem = {};

            if (id === CREATING_ITEM_ID && this.liveDraft) {
                const cacheItem = localStorage.getItem(`creating_${this.moduleName}`);
                try {
                    editingItem = cacheItem ? JSON.parse(cacheItem) : {};
                } catch (error) {
                    editingItem = {};
                }
            }

            this.setItem(id, {
                property: {},
                history: {},
                editingItem,
                loadingItem: false,
                errors: []
            });
        }
    }

    @action setEditingBlockId(item_id: ItemKey, blockId: symbol) {
        this.getItem(item_id).editingBlockId = blockId;
    }

    getItem(id: ItemKey): ItemType<ItemObjectType, ItemPropertyType> {
        const item = this.items.get(id);
        if (!item) {
            throw Error(`Item didn't find: ${this.moduleName}; #${id}`);
        }
        return item;
    }

    @action
    clearEditingItem(id: ItemKey) {
        const item = this.getItem(id);
        item.property = {};
        this.clearEditingItemOnly(id);

        if (this.liveDraft && id === CREATING_ITEM_ID) {
            localStorage.removeItem(`creating_${this.moduleName}`);
        }
    }

    @action
    clearEditingItemOnly(id: ItemKey) {
        const item = this.getItem(id);
        item.editingItem = {};
    }

    getItemForAccess(id: ItemKey): ItemType<ItemObjectType, ItemPropertyType> {
        return this.getItem(id);
    }

    isItemExist(id: ItemKey): boolean {
        return Boolean(this.items.get(id));
    }

    @action
    setItem(id: ItemKey, item: ItemType<ItemObjectType, ItemPropertyType>) {
        const oldItem = this.items.get(id);
        if (!oldItem) {
            this.items.set(id, item);
        } else {
            oldItem.item = item.item;
            oldItem.errors = item.errors || oldItem.errors;
            oldItem.editingItem = item.editingItem;
            oldItem.loadingItem = item.loadingItem;
            oldItem.property = { ...oldItem.property, ...item.property };
            oldItem.history = { ...oldItem.history, ...item.history };
        }
    }

    validationItem(editingItem: Partial<ItemObjectType>): Array<string> {
        return [];
    }

    @action
    async createItem(): Promise<number> {
        const emptyItem = this.getItem(CREATING_ITEM_ID);

        const errors =
            typeof emptyItem.editingItem === 'object' && emptyItem.editingItem !== null ? this.validationItem(emptyItem.editingItem) : [];

        if (errors.length) {
            emptyItem.errors = errors;
            return await CREATING_ITEM_ID;
        }

        emptyItem.loadingItem = true;

        try {
            const item = { ...emptyItem.editingItem };
            delete item[this.item_id];

            const item_id = typeof this.ApiModule.createItem === 'function' ? await this.ApiModule.createItem(item) : CREATING_ITEM_ID;

            emptyItem.loadingItem = false;
            emptyItem.errors = [];

            // Уходим на следующий тик, когда роутер переведет карточку на только что созданную:
            window.setTimeout(() => {
                this.clearEditingItem(CREATING_ITEM_ID);
            }, 0);

            return item_id;
        } catch (errors) {
            console.log(errors);
            await runInAction(() => {
                emptyItem.errors = errors;
                emptyItem.loadingItem = false;
            });
            return CREATING_ITEM_ID;
        }
    }

    checkNewItemIsNotEmpty(newItem: Partial<ItemObjectType> = {}): boolean {
        return Boolean(Object.keys(newItem).length);
    }

    @nProgressItem
    @action
    async saveItem(id: ItemKey, base?: string | null): Promise<boolean | Partial<ItemObjectType>> {
        const thisItem = this.getItem(id);
        const { editingItem, item } = thisItem;

        const newItem: Partial<ItemObjectType> = {};

        if (item && editingItem) {
            Object.keys(editingItem).forEach(key => {
                if (editingItem[key] instanceof Array) {
                    if (!isEqual(Array.from(editingItem[key]), item[key] instanceof Array ? Array.from(item[key]) : [])) {
                        newItem[key] = editingItem[key] instanceof Array ? Array.from(editingItem[key]) : [];
                        // TODO: перерефакторить это, разобраться с удалением всех keys а не только photos; Протестить на ExportEditing
                        if (key === 'photos') {
                            delete editingItem[key];
                        }
                    }
                } else if (typeof editingItem[key] === 'object' && editingItem[key] !== null && !isEqual(item[key], editingItem[key])) {
                    newItem[key] = editingItem[key];
                } else if (typeof editingItem[key] !== 'object' && item[key] !== editingItem[key]) {
                    newItem[key] = editingItem[key];
                } else if (editingItem[key] === null && item[key] !== null) {
                    newItem[key] = editingItem[key];
                }
            });
        }

        this.mergeItem(id, newItem);

        if ('major_user_ids' in newItem && newItem['major_user_ids'] instanceof Array) {
            const major_users: Array<UserLinkType> = newItem.major_user_ids
                .map(major_user_id => {
                    try {
                        return authStore.findUserById(major_user_id);
                    } catch (e) {
                        return null;
                    }
                })
                .filter(user => user !== null);

            // @ts-ignore
            this.mergeItem(id, { major_users });
        }

        if (this.checkNewItemIsNotEmpty(newItem)) {
            try {
                await this.ApiModule.saveItem(id, newItem);
            } catch (errors) {
                thisItem.errors = errors;
                indexDB.delete(id, `${this.moduleName}_${this.moduleName === 'estate' ? ESTATE_BASE_MAIN : ''}`);
                return false;
            } finally {
                this.setEditingBlockId(id, Symbol());
            }
        }
        return newItem;
    }

    @action
    setEditingItem(id: ItemKey, editingProps: Partial<ItemObjectType>) {
        const item: ItemType<ItemObjectType, ItemPropertyType> = this.getItem(id);

        item.errors = [];
        item.editingItem = { ...item.editingItem, ...editingProps };

        if (this.liveDraft && id === CREATING_ITEM_ID) {
            localStorage.setItem(`creating_${this.moduleName}`, JSON.stringify(item.editingItem));
        }

        this.setItem(id, item);
    }

    @action
    changeArrayValue(id: ItemKey, arrayFieldName: keyof ItemObjectType, index: number, prop: string | null, value: unknown) {
        const { editingItem } = { ...this.getItem(id) };

        if (typeof editingItem === 'object' && editingItem !== null && editingItem[arrayFieldName] instanceof Array) {
            if (prop) {
                editingItem[arrayFieldName][index][prop] = value;
            } else {
                editingItem[arrayFieldName][index] = value;
            }
        }
    }

    @action
    setProperty(id: ItemKey, property: Partial<ItemPropertyType>) {
        const item = this.getItem(id);
        item.property = { ...item.property, ...property };
    }

    @action
    mergeItem(id: ItemKey, item: Partial<ItemObjectType>) {
        const oldItem: ItemType<ItemObjectType, ItemPropertyType> = this.getItem(id);

        const editingItem = oldItem.editingItem || {};
        if (typeof editingItem['major_user_id'] === 'number') {
            item = deepCopy({
                ...item,
                // @ts-ignore
                major_user: authStore.findUserById(editingItem.major_user_id)
            });
        }

        oldItem.item = deepCopy({ ...oldItem.item, ...item });
        //TODO: надо прокидывать base сюда и сделать: `${this.moduleName}_${base}` - ключ
        indexDB.put(
            id,
            `${this.moduleName}_${this.moduleName === 'estate' ? ESTATE_BASE_MAIN : ''}`,
            deepCopy({ ...oldItem.item, ...item })
        );
        this.setItem(id, oldItem);
    }

    @action
    async toggleDisableItem(id: ItemKey, enable: boolean) {
        // @ts-ignore
        this.setEditingItem(id, { enable });
        await this.saveItem(id);
    }

    @action
    async fetchItemHistory(id: ItemKey, base?: string) {
        const item = this.getItem(id);
        item.history = { ...item.history, loadingHistory: true, history: [] };
        const history = typeof this.ApiModule.fetchItemHistory === 'function' ? await this.ApiModule.fetchItemHistory(id, base) : [];
        item.history = { ...item.history, loadingHistory: false, history };
    }
}

export default ItemStorePrototype;
