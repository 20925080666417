import React, { Component, Fragment } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const WishTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell sorted={orderBy === 'createTime' ? orderDirection : null} onClick={() => handleSort('createTime')}>
                    Дата
                </Table.HeaderCell>
                <Table.HeaderCell>Комнат</Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'priceMax' ? orderDirection : null} onClick={() => handleSort('priceMax')}>
                    Цена
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'town' ? orderDirection : null} onClick={() => handleSort('town')}>
                    Метро / Город
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'totalAreaMax' ? orderDirection : null} onClick={() => handleSort('totalAreaMax')}>
                    Площадь
                </Table.HeaderCell>
                <Table.HeaderCell>Этаж</Table.HeaderCell>
                <Table.HeaderCell>Ответственный(е)</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};

export default WishTableHeader;
