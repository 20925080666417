import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import estateStore from '~/stores/estateStore';

import { Card, Icon } from 'semantic-ui-react';

import MetroLogo from '../../../Base/MetroLogo';
import AddressBlockEditing from '../EditBlocks/AddressBlockEditing';
import EditingBlockWrapper from '../../EditingBlockWrapper';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import {
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    MetroDistance,
    TRANSPORT_TYPE_WALK
} from '~/types/estate.types';
import { Newbuilding, NewBuildingHouse } from '~/types/newbuildings.types';

import AddressPopup from '~/components/Base/Address/AddressPopup';
import AddressOnMapBtn from '~/components/Base/Address/AddressOnMapBtn';
import LifeComplexLink from '~/components/Items/LifeComplex/LifeComplexLink';

export const PrintNewbuilding = ({
    newbuilding,
    newbuildingHouse,
    houseIsNeeded
}: {
    newbuilding: Newbuilding;
    newbuildingHouse?: NewBuildingHouse;
    houseIsNeeded?: boolean;
}) => (
    <div>
        <Icon name="building" color="grey" />
        <b>
            <LifeComplexLink newbuilding={newbuilding} />
        </b>
        {newbuildingHouse && (
            <Fragment>
                , корпус <b>{newbuildingHouse.name}</b>
            </Fragment>
        )}
        {!newbuildingHouse && houseIsNeeded && <span className="crm-Estate__list_nophoto"> НЕ УКАЗАН НОМЕР КОРПУСА</span>}
    </div>
);

export const MetrosBlockShow = ({ metros }: { metros: MetroDistance[] }) => {
    return (
        <div className="crm-Estate__metroList_showMode">
            {metros &&
                metros.map(({ name, color, time, transportType }, i) => (
                    <span key={i} className="crm-Estate__metroList_itemShow">
                        <MetroLogo color={`#${color || ''}`} />
                        &nbsp;
                        {name} ({time} мин. {transportType === TRANSPORT_TYPE_WALK ? 'пешком' : 'на машине'})
                    </span>
                ))}
        </div>
    );
};

const EstateAddressBlockShow = ({ item_id, editing }: EditingWrappedProps) => {
    const item = estateStore.getItem(item_id).item;
    const {
        address,
        metros,

        newbuilding,
        newbuildingHouse,

        propertyType,
        geo_lat,
        geo_lon
    } = item || {};

    return (
        <Fragment>
            {!editing && (
                <Fragment>
                    {address}
                    &nbsp;
                    <AddressPopup address={item} />
                    <AddressOnMapBtn address={address} geo_lat={geo_lat} geo_lon={geo_lon} />
                    {newbuilding && (
                        <PrintNewbuilding
                            newbuilding={newbuilding}
                            newbuildingHouse={newbuildingHouse}
                            houseIsNeeded={[ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(
                                propertyType
                            )}
                        />
                    )}
                    <MetrosBlockShow metros={metros} />
                </Fragment>
            )}
            {editing && (
                <Card fluid color="red">
                    <Card.Content>
                        <AddressBlockEditing editing={editing} estate_id={item_id} {...item} />
                    </Card.Content>
                </Card>
            )}
        </Fragment>
    );
};

export default EditingBlockWrapper(estateStore, observer(EstateAddressBlockShow));
