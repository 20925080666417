import React, { Component, Fragment } from 'react';
import { Input, Grid, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import { HandlerEditingWrappedProps } from '../../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../../HandlerEditingBlockWrapper';
import { HandleChangeType } from '../../../HandlerEditingBlockWrapper';
import { adjustEstateAddressLong, adjustEstateAddressShort, adjustEstateAddressStreet } from '../../../../../common/address';
import { Estate } from '~/types/estate.types';
import { WarningMessage, MessageTitle } from '~ui/Message';

type AddressDetailsEditingProps = HandlerEditingWrappedProps & {
    address?: string;

    fias?: string;
    kladr?: string;

    street?: string;
    streetType?: string;
    houseNumber?: string;
    houseNumberType?: string;
    blockNumber?: string;
    blockNumberType?: string;

    town?: string;
    townType?: string;
    region?: string;
    regionType?: string;
    district?: string;
    cityDistrict?: string;
    districtType?: string;
    cityDistrictType?: string;
    settlement?: string;
    settlementType?: string;
    postcode?: number | null;

    showBlock: boolean;
};

const addressFields: [string, keyof Estate, keyof Estate][] = [
    ['Регион', 'region', 'regionType'],
    ['Город', 'town', 'townType'],
    ['Район', 'district', 'districtType'],
    ['Поселение', 'settlement', 'settlementType'],
    ['Район города', 'cityDistrict', 'cityDistrictType'],
    ['Улица', 'street', 'streetType'],
    ['Дом', 'houseNumber', 'houseNumberType'],
    ['Блок', 'blockNumber', 'blockNumberType']
];

@observer
class AddressDetailsEditing extends Component<AddressDetailsEditingProps> {
    constructor(props: AddressDetailsEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            address: props.address || '',

            fias: props.fias || null,
            kladr: props.kladr || null,

            street: props.street || null,
            streetType: props.streetType || null,
            houseNumber: props.houseNumber || null,
            houseNumberType: props.houseNumberType || null,
            blockNumber: props.blockNumber || null,
            blockNumberType: props.blockNumberType || null,

            town: props.town || null,
            townType: props.townType || null,
            region: props.region || null,
            regionType: props.regionType || null,
            district: props.district || null,
            cityDistrict: props.cityDistrict || null,
            districtType: props.districtType || null,
            cityDistrictType: props.cityDistrictType || null,
            settlement: props.settlement || null,
            settlementType: props.settlementType || null,
            postcode: props.postcode || null
        };

        estateStore.setEditingItem(estate.estate_id, estate);
    }

    handleDetailChange: HandleChangeType = (event, data) => {
        this.props.handleChange(event, data);

        const { item_id } = this.props;
        const { editingItem } = estateStore.getItem(item_id);

        estateStore.setEditingItem(item_id, { address: adjustEstateAddressLong(editingItem) });
    };

    handleCoordinatesChange: HandleChangeType = (event, { value }) => {
        try {
            const [map_lat, map_lng] = value.split(',');
            if (Number(map_lat.trim()) && Number(map_lng.trim())) {
                estateStore.setAddressCoordinates(
                    this.props.item_id,
                    Number(Number(map_lat).toFixed(6)),
                    Number(Number(map_lng).toFixed(6))
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { item_id, showBlock } = this.props;
        const { editingItem } = estateStore.getItem(item_id);
        const { geo_lat, geo_lon } = editingItem;

        let subLocalityName = '';
        if (editingItem['cityDistrict'] || editingItem['settlement']) {
            subLocalityName = editingItem['cityDistrict']
                ? `${editingItem['cityDistrict'] || ''} ${editingItem['cityDistrictType'] || ''}`.trim()
                : `${editingItem['settlement'] || ''} ${editingItem['settlementType'] || ''}`.trim();
        }

        const addressStreet = adjustEstateAddressStreet(editingItem);

        return (
            <div className={`crm-Item__editingMode ${showBlock ? '' : 'crm-Main__hidden'}`}>
                <WarningMessage>
                    <MessageTitle>Внимание!</MessageTitle>
                    Рекомендуем пользоваться автоматическим поиском
                </WarningMessage>

                <Grid columns={3}>
                    {addressFields.map(([label, field, fieldType]) => (
                        <Fragment key={label}>
                            <Grid.Column>
                                <label className="crm-Estate__field_label">{label}</label>
                            </Grid.Column>
                            <Grid.Column>
                                <Input size="mini" value={editingItem[field] || ''} name={field} onChange={this.handleDetailChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input
                                    size="mini"
                                    value={editingItem[fieldType] || ''}
                                    name={fieldType}
                                    onChange={this.handleDetailChange}
                                />
                            </Grid.Column>
                        </Fragment>
                    ))}
                    <Grid.Column>
                        <label className="crm-Estate__field_label">Координаты</label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input size="mini" value={geo_lat ? `${geo_lat}, ${geo_lon}` : ''} onChange={this.handleCoordinatesChange} />
                    </Grid.Column>
                </Grid>

                <br />
                <br />

                <div>
                    <b>Длинный адрес (ЦИАН, Сбербанк, Винер, бесплатные)</b>
                    <br /> {adjustEstateAddressLong(editingItem)}
                </div>

                <div>
                    <b>Яндекс</b>
                    <br />
                    country: Россия
                    <br />
                    {(editingItem['settlement'] || (editingItem['town'] && editingItem['town'].trim() !== 'Москва')) && (
                        <Fragment>
                            region: {`${editingItem['region'] || ''} ${editingItem['regionType'] || ''}`.trim()}
                            <br />
                            district: {`${editingItem['district'] || ''} ${editingItem['districtType'] || ''}`.trim()}
                            <br />
                        </Fragment>
                    )}
                    locality-name: {editingItem['town'] || editingItem['settlement'] || ''}
                    <br />
                    {(editingItem['settlement'] ||
                        (editingItem['town'] && editingItem['town'].trim() !== 'Москва' && addressStreet && subLocalityName)) && (
                        <Fragment>
                            sub-locality-name: {subLocalityName}
                            <br />
                        </Fragment>
                    )}
                    {(addressStreet || subLocalityName) && (
                        <Fragment>
                            address: {addressStreet || subLocalityName}
                            <br />
                        </Fragment>
                    )}
                </div>

                <div>
                    <b>Авито</b>
                    <br />
                    {adjustEstateAddressShort(editingItem)}
                </div>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, AddressDetailsEditing);
