import React from 'react';
import { KpiStatistics } from '~/types/kpi.types';

import { TableCell, TableRow, TableHead } from '~ui/Table';

const TableKpiBody = ({ statistics }: { statistics: KpiStatistics[] }) => {
    const sumColumns: number[] = [];
    for (const { pointsValue } of statistics) {
        pointsValue.forEach(({ value }, index) => {
            sumColumns[index] = sumColumns[index] || 0;
            sumColumns[index] += value;
        });
    }

    return (
        <TableHead style={{ padding: '1rem' }}>
            <TableRow>
                <TableCell colSpan={3}>
                    <b>Итого</b>
                </TableCell>
                {sumColumns.map((value, index) => (
                    <TableCell key={`footer-${index}`} align="right">
                        {value?.toLocaleString('ru')}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableKpiBody;
