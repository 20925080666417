import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Newbuilding } from '~/types/newbuildings.types';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { LifeComplexStatAbout } from '~/components/Items/LifeComplex/ShowBlocks/LifeComplexStatShow';
import { LifeComplexLinkState } from '~/components/Items/LifeComplex/LifeComplexLink';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '200px',
        marginBottom: theme.spacing(2)
    },
    content: {
        flex: '1 0 auto'
    },
    cover: {
        width: '300px',
        height: '100%',
        padding: theme.spacing(1)
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    about: {
        width: '300px'
    }
}));

// const openLifeComplex = (newbuilding_id: number) => {
//     history.push(LifeComplexLinkState(newbuilding_id, 'crm'));
// };

const LifeComplexCard = ({ newbuilding }: { newbuilding: Newbuilding }) => {
    const classes = useStyles();
    const { developer } = newbuilding;

    return (
        <Card className={classes.root}>
            <Paper style={{ margin: '1rem', padding: '1rem' }}>
                {newbuilding['photos'].length > 0 && (
                    <CardMedia className={classes.cover} image={newbuilding['photos'][0].filename} title="ЖК NOVA" />
                )}
            </Paper>
            <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                    <Link to={LifeComplexLinkState(newbuilding['newbuilding_id'])}>ЖК «{newbuilding['name']}»</Link>
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {newbuilding['address']}
                </Typography>
                <Box p={1} display="flex" alignItems="center" style={{ gap: '1rem' }}>
                    {developer.logoUrl && <img src={developer.logoUrl} height={32} />}
                    <Typography>{developer.name}</Typography>
                </Box>
            </CardContent>
            <Paper className={classes.about}>
                <LifeComplexStatAbout newbuilding={newbuilding} />
            </Paper>
        </Card>
    );
};

export default LifeComplexCard;
