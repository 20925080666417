import { action, observable } from 'mobx';
import * as exportStatisticsApi from '~/api/export/exportStatisticsApi';
import { DAY_SEC } from '~/common/time';

import { AVITO_EXPORT_BASE_ID, CIAN_EXPORT_BASE_ID, DOMCLICK_EXPORT_BASE_ID, YANDEX_EXPORT_BASE_ID } from '~/types/exports.types';
import {
    ExportBaseIdStat,
    ExportChartBaseCost,
    ExportStat,
    ExportStatChart,
    ExportStatChartPult,
    ExportStatListPult
} from '~/types/exportStatistics.types';

import deepCopy from '../../common/deepCopy';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import authStore from '../authStore';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_READ } from '~/types/access.types';
import statisticStore from '../statisticStore';

type StatChartValue = {
    export_base_id: number;
    name: string;
    color: string;
    value: number;
};

export type TSumStatisticsMatched = {
    views: StatChartValue[];
    phoneShows: StatChartValue[];
    totalCost: StatChartValue[];
    totalPremium: StatChartValue[];
    totalRedeemCost: StatChartValue[];
    estatesCount: StatChartValue[];
    showsCount: StatChartValue[];
};

export type ExportStatisticsItemProperty = {
    chartStatistics: ExportStatChart[];
    loadingStatistics: boolean;
    sumStatisticsMatched: TSumStatisticsMatched;
    statisticsErrors: string[] | null;
    statisticsChartPult: ExportStatChartPult;
};

export const EXPORT_BASES_OPTIONS: { [key in ExportBaseIdStat]: { title: string; color: string; name: string } } = {
    0: { title: 'Все', color: '#6e6f71', name: 'all' },
    [CIAN_EXPORT_BASE_ID]: { title: 'Циан', color: '#0468ff', name: 'cian' },
    [YANDEX_EXPORT_BASE_ID]: { title: 'Яндекс.Недвижимость', color: '#ffcc00', name: 'avito' },
    [DOMCLICK_EXPORT_BASE_ID]: { title: 'ДомКлик', color: '#53b374', name: 'yandex' },
    [AVITO_EXPORT_BASE_ID]: { title: 'Авито', color: '#ff6163', name: 'domclick' }
} as const;

const DefaultStatFilter: ExportStatChartPult = {
    activeBases: [CIAN_EXPORT_BASE_ID, YANDEX_EXPORT_BASE_ID, AVITO_EXPORT_BASE_ID, DOMCLICK_EXPORT_BASE_ID],
    activeCharts: ['views', 'phoneShows', 'priceHistory'],
    deltaTimeSec: 30 * DAY_SEC,
    fromTimeSec: null,
    toTimeSec: null
};

class ExportStatisticsStore extends ListStorePrototype<ExportStat, ExportStat, ExportStatisticsItemProperty, ExportStatListPult> {
    listFilterClear: ExportStatListPult = {
        ...deepCopy(DefaultStatFilter),
        group_id: [],
        major_user_id: [],
        propertyTypes: [],
        type: 0
    };

    disablePrepareNextList: true;

    constructor() {
        super('estate_id', 'export', exportStatisticsApi);
        this.clearFilter();
    }

    async fetchItem(estate_id: number) {
        super.fetchItem(estate_id);
    }

    setInitFilter = (estate_id: number) => {
        this.setStatisticsChartPult(estate_id, deepCopy(DefaultStatFilter));
    };

    setStatisticsChartPult(estate_id: number, pult: Partial<ExportStatChartPult>) {
        const { statisticsChartPult } = this.getItem(estate_id).property;
        this.setProperty(estate_id, { statisticsChartPult: { ...statisticsChartPult, ...pult } });
    }

    @observable
    chartStatistics: ExportStatChart[];
    @observable
    sumStatisticsMatched: TSumStatisticsMatched;

    @action
    changeFilter<T extends keyof ExportStatListPult>(what: T, value: ExportStatListPult[T]) {
        this.listFilter[what] = value;
        if (!['activeCharts', 'activeBases'].includes(what)) {
            super.changeFilter(what, value);
        }
    }

    @action
    async fetchList() {
        if (!authStore.matchAccess(statisticStore.moduleName, ACCESS_READ, ACCESS_GROUP)) {
            throw Error(`Недостаточно доступа`);
        }

        this.loadingList = true;
        this.listErrors = [];

        try {
            if (this.fetchListAC) {
                this.fetchListAC.abort();
            }
            const controller = window.AbortController ? new window.AbortController() : undefined;
            // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
            if (controller) {
                window.setTimeout(() => {
                    this.fetchListAC = controller;
                }, 0);
            }

            const { list: exportStatByDay, exportCost } = await exportStatisticsApi.fetchList(
                0,
                0,
                '',
                'ascending',
                this.listFilter,
                controller
            );

            const { chartStatistics, sumStatisticsMatched } = this.matchEstateStatistics(exportStatByDay, exportCost);
            this.chartStatistics = chartStatistics;
            this.sumStatisticsMatched = sumStatisticsMatched;

            this.fetchListAC = null;
        } catch (error) {
            if (this.fetchListAC && this.fetchListAC.signal.aborted === true) {
                return;
            }

            console.error('error', error);
            this.listErrors = [error.toString()];
        }

        this.loadingList = false;
    }

    matchEstateStatistics(
        statistics: ExportStat[],
        exportCost: ExportChartBaseCost[]
    ): { chartStatistics: ExportStatChart[]; sumStatisticsMatched: TSumStatisticsMatched } {
        const sumStatistics = {};

        const chartStatistics = statistics.map(({ bases, date }) => {
            return Object.keys(EXPORT_BASES_OPTIONS).reduce(
                (accResult, export_base_id) => {
                    const data = bases.find(base => Number(export_base_id) === base['export_base_id']);
                    const { name } = EXPORT_BASES_OPTIONS[export_base_id];

                    if (data) {
                        sumStatistics[export_base_id] = sumStatistics[export_base_id] || { views: 0, phoneShows: 0 };
                        sumStatistics[export_base_id]['views'] += data['views'];
                        accResult['allViews'] += data['views'];
                        sumStatistics[export_base_id]['phoneShows'] += data['phoneShows'];
                        accResult['allPhoneShows'] += data['phoneShows'];
                        sumStatistics[export_base_id]['showsCount'] += data['showsCount'];
                        accResult['allShowsCount'] += data['showsCount'];
                    }

                    const result = {
                        date,
                        [`${name}Views`]: data ? data['views'] : null,
                        [`${name}PhoneShows`]: data ? data['phoneShows'] : null,
                        [`${name}ShowsCount`]: data ? data['showsCount'] : null
                    };

                    return { ...accResult, ...result };
                },
                {
                    date: '',
                    allViews: 0,
                    allPhoneShows: 0,
                    allShowsCount: 0,
                    cianViews: null,
                    cianPhoneShows: null,
                    cianShowsCount: null,
                    avitoViews: null,
                    avitoPhoneShows: null,
                    avitoShowsCount: null,
                    yandexViews: null,
                    yandexPhoneShows: null,
                    yandexShowsCount: null,
                    domclickViews: null,
                    domclickPhoneShows: null,
                    domclickShowsCount: null
                }
            );
        });

        const sumStatisticsMatched = Object.keys(sumStatistics).reduce(
            (acc: TSumStatisticsMatched, export_base_id) => {
                const { title, color } = EXPORT_BASES_OPTIONS[export_base_id];
                ['views', 'phoneShows', 'showsCount'].forEach(key => {
                    acc[key].push({
                        export_base_id: Number(export_base_id),
                        name: title,
                        color,
                        value: sumStatistics[export_base_id][key]
                    });
                });

                const statValues = exportCost.find(cost => cost.export_base_id === Number(export_base_id)) || {
                    total_cost: null,
                    estatesCount: null,
                    totalRedeemCost: null
                };

                ['totalCost', 'totalPremium', 'estatesCount', 'totalRedeemCost'].forEach(key => {
                    acc[key].push({
                        export_base_id: Number(export_base_id),
                        name: title,
                        color,
                        value: statValues[key]
                    });
                });

                return acc;
            },
            { views: [], phoneShows: [], showsCount: [], totalCost: [], totalPremium: [], estatesCount: [], totalRedeemCost: [] }
        );

        return {
            chartStatistics,
            sumStatisticsMatched
        };
    }

    @action
    fetchEstateStatistics = async (estate_id: number) => {
        this.setProperty(estate_id, { loadingStatistics: true, statisticsErrors: null });
        try {
            const {
                statisticsChartPult: { deltaTimeSec, fromTimeSec, toTimeSec }
            } = this.getItem(estate_id).property;
            const { exportStatByDay, exportCost } = await exportStatisticsApi.fetchExportEstateStatistics(estate_id, {
                deltaTimeSec,
                fromTimeSec,
                toTimeSec
            });
            const { chartStatistics, sumStatisticsMatched } = this.matchEstateStatistics(exportStatByDay, exportCost);
            this.setProperty(estate_id, { chartStatistics, sumStatisticsMatched });
        } catch (statisticsErrors) {
            this.setProperty(estate_id, { statisticsErrors });
        } finally {
            this.setProperty(estate_id, { loadingStatistics: false });
        }
    };
}

export default new ExportStatisticsStore();
