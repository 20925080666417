import React from 'react';
import { List } from 'semantic-ui-react';
import { ErrorMessage, MessageTitle } from '~ui/Message';

const GlobalError = ({ error }: { error?: string }) => (
    <ErrorMessage>
        <MessageTitle>Произошла ошибка</MessageTitle>
        <List bulleted>
            <List.Item as="a" onClick={() => document.location.reload()}>
                Перезагрузить страницу
            </List.Item>
            <List.Item
                as="a"
                onClick={() => {
                    window.history.back();
                    setTimeout(() => {
                        document.location.reload();
                    }, 250);
                }}
            >
                Вернуться назад
            </List.Item>
            <List.Item as="a" onClick={() => (document.location.href = '/')}>
                Перейти на главную
            </List.Item>
        </List>

        <br />
        {error && <div>Ошибка: {error}</div>}
    </ErrorMessage>
);

export default GlobalError;
