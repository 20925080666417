import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Dropdown, Grid, Form } from 'semantic-ui-react';

import EditField from '../../Base/EditField';
import triggerStore from '~/stores/triggerStore';
import { checkFormValid } from '~/common/forms';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import { Trigger } from '~/types/triggers.types';
import tasksL10n from '../../../L10n/tasks.L10n';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import Modal, { ModalContent, ModalBtnAction } from '~ui/Modal';
import commonStore from '~/stores/commonStore';
import authStore from '~/stores/authStore';

type TriggerModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

@observer
class TriggerModal extends Component<TriggerModalProps> {
    $form: HTMLFormElement;

    constructor(props: TriggerModalProps) {
        super(props);

        const { item_id } = this.props;

        triggerStore.fetchItem(item_id);

        if (item_id === CREATING_ITEM_ID) {
            const trigger: Partial<Trigger> = {
                trigger_id: item_id,
                trigger: null,
                taskTitle: '',
                taskDescription: '',
                needApprovalNop: false,
                priority: 1,
                enable: true
            };

            triggerStore.setEditingItem(item_id, trigger);
        }
    }

    handleCreateTrigger = async (event: React.SyntheticEvent) => {
        if (checkFormValid(this.$form, true)) {
            event.preventDefault();
            event.stopPropagation();

            const { item_id, handleClose } = this.props;

            if (item_id === CREATING_ITEM_ID) {
                await triggerStore.createItem();
                const { errors } = triggerStore.getItem(item_id);
                if (errors && errors.length) {
                    return;
                }
            } else {
                await triggerStore.saveItem(item_id);
            }

            handleClose();
            triggerStore.fetchList();
        }
    };

    handleDelete = async () => {
        const { item_id, handleClose } = this.props;
        triggerStore.setEditingItem(item_id, {
            enable: false
        });
        await triggerStore.saveItem(item_id);

        handleClose();

        triggerStore.fetchList();
    };

    render() {
        const { handleClose, item_id, handleChange, handleTextAreaChange, handleCheckbox } = this.props;
        const { editingItem, loadingItem, errors } = triggerStore.getItem(item_id);

        const { trigger, taskTitle, taskDescription, needApprovalNop, priority } = editingItem || {};
        const { tasksTriggers, loadingTasksTriggers } = triggerStore;

        const actions = [
            { onClick: handleClose, label: 'Отмена', variant: 'text', color: 'primary' },
            item_id > CREATING_ITEM_ID && authStore.canDelete(triggerStore.moduleName, [])
                ? {
                      onClick: this.handleDelete,
                      label: 'Удалить',
                      color: 'secondary',
                      variant: 'text'
                  }
                : null,
            {
                onClick: this.handleCreateTrigger,
                label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
                color: 'primary',
                variant: 'outlined'
            }
        ].filter(btn => btn !== null);

        return (
            <Modal
                fullScreen={commonStore.isMobile}
                maxWidth="sm"
                onClose={handleClose}
                header="Триггер задачи"
                loading={loadingItem || loadingTasksTriggers}
                actions={actions as ModalBtnAction[]}
            >
                <ModalContent dividers>
                    <ListError errors={Array.from(errors || [])} />

                    {!loadingItem && editingItem && (
                        <form ref={$el => (this.$form = $el)} className="ui form small">
                            <div className="crm-Item__editingMode crm-Triggers__modalField">
                                <EditField.Drop
                                    label="Триггер"
                                    name="trigger"
                                    value={trigger}
                                    onChange={handleChange}
                                    options={tasksTriggers}
                                />

                                <EditField.Text
                                    label="Заголовок задачи"
                                    placeholder="Заголовок задачи"
                                    value={taskTitle}
                                    name="taskTitle"
                                    onChange={handleChange}
                                    required=".{5,}"
                                />

                                <EditField.Area
                                    label="Описание задачи"
                                    value={taskDescription}
                                    name="taskDescription"
                                    onChange={handleTextAreaChange}
                                />

                                <Grid columns={2} stackable>
                                    <Grid.Column>
                                        <Dropdown
                                            selection
                                            name="priority"
                                            value={priority}
                                            onChange={handleChange}
                                            placeholder="Приоритет"
                                            options={tasksL10n.PRIORITY_DROPDOWN}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Group>
                                            <Form.Field inline>
                                                <Checkbox
                                                    checked={needApprovalNop}
                                                    name="needApprovalNop"
                                                    label={<label>Подтверждение НОПа</label>}
                                                    onClick={handleCheckbox}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </form>
                    )}
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(triggerStore, TriggerModal);
