import React, { ChangeEvent, useMemo, useState } from 'react';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import { WarningMessage } from '~ui/Message';
import { Switch } from '~ui/Checkbox';

type UserWhatsappEditingProps = HandlerEditingWrappedProps & {
    telegramUsername: string | null;
};

const UserWhatsappEditing = observer((props: UserWhatsappEditingProps) => {
    const { item_id } = props;

    useMemo(() => {
        userStore.setEditingItem(item_id, { telegramUsername: props.telegramUsername || null });
    }, []);

    const {
        editingItem: { telegramUsername }
    } = userStore.getItem(item_id);

    const handleDeactivate = async (event: ChangeEvent<HTMLInputElement>) => {
        userStore.setEditingItem(item_id, { telegramUsername: telegramUsername ? null : props.telegramUsername });
    };

    if (!props.telegramUsername) {
        return (
            <div className="crm-Item__editingMode">
                <WarningMessage>Сотрудник не активировал Telegram</WarningMessage>
            </div>
        );
    }

    return (
        <Card.Description>
            <div className="crm-Item__editingMode">
                <Switch
                    onChange={handleDeactivate}
                    checked={!!telegramUsername}
                    title="Отвязать"
                    label={`Telegram привязан @${props.telegramUsername}`}
                />
            </div>
        </Card.Description>
    );
});

export default HandlerEditingBlockWrapper(userStore, UserWhatsappEditing);
