import React, { PureComponent, Fragment } from 'react';

import { EditingWrappedProps } from '../EditingBlockWrapper';

import ItemIDBlockEditing from './ItemIDBlockEditing';
import dealStore from '~/stores/dealStore';
import DealLink from '../../Lists/Deals/DealLink';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';

type ContactIDBlockEditingProps = EditingWrappedProps & {
    value?: number;
    store: ItemStoreInterface<any>;
};

class DealIDBlockEditing extends PureComponent<ContactIDBlockEditingProps> {
    render() {
        const { editing, item_id, store, value } = this.props;

        return (
            <ItemIDBlockEditing
                searchingItemIdTitle="parent_deal_id"
                itemStore={dealStore}
                itemLinkEmit={(item: any) => <DealLink deal={item} />}
                editing={editing}
                item_id={item_id}
                store={store}
                value={value}
                placeholder="Выбрать сделку"
                noResultsMessage="Сделка не найдена"
                icon="money"
                pathname="/deals/sell/overview/0"
                createTitle="Создать сделку"
                clearable
            />
        );
    }
}

export default DealIDBlockEditing;
