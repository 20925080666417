import React from 'react';
import wishStore from '~/stores/wishStore';
import { DELETING_CAUSE_THE_PHONE_IS_WRONG } from '~/types/wish.types';
import Button from '~ui/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

type WishIsNotActuallyButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishIsNotActuallyButton = ({ wish_id, disabled }: WishIsNotActuallyButtonProps) => {
    const handleToggleIsAlreadySold = () => {
        wishStore.deleteWishWithCause(wish_id, DELETING_CAUSE_THE_PHONE_IS_WRONG);
    };

    return (
        <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleToggleIsAlreadySold}
            disabled={disabled}
            startIcon={<ErrorOutlineIcon />}
        >
            Неверный номер
        </Button>
    );
};

export default WishIsNotActuallyButton;
