import debounce from './debounce';
import { EstateLinkState } from '../components/Lists/Estate/EstateLink';
import {
    ESTATE_BASE_EXTERNAL,
    ESTATE_BASE_OWNERS,
    ESTATE_BASES,
    ESTATE_TYPE_RENT,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL
} from '~/types/estate.types';
import commonStore from '~/stores/commonStore';
import roundPrice from './roundPrice';
import ListStoreMapPrototype from '~/stores/prototypes/ListStoreMapPrototype.prototype';

type EveryObject = { [property: string]: any };

declare const DG: EveryObject;

const CLUSTER_PLUGIN_URL = 'https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js';

export default async function initializeAddressMap(
    targetId: string,
    mapLat: number,
    mapLng: number,
    address: string,
    onChange?: (a: number, b: number) => void,
    onInit?: (a: EveryObject, b: EveryObject) => void
): Promise<void> {
    // @ts-ignore
    if (!window.DG) {
        return;
    }
    let map;

    DG.then(() => {
        map = DG.map(targetId, {
            center: [mapLat, mapLng],
            zoom: 17,
            poi: false
        });

        const marker = DG.marker([mapLat, mapLng], {
            draggable: typeof onChange === 'function'
        })
            .addTo(map)
            .bindPopup(address);

        let map_lat;
        let map_lng;

        if (onChange) {
            const debounceOnChange = debounce(() => onChange && onChange(map_lat, map_lng), 350);

            marker.on('drag', function (e) {
                map_lat = Number(e.target._latlng.lat.toFixed(6));
                map_lng = Number(e.target._latlng.lng.toFixed(6));

                debounceOnChange();
            });
        }

        if (typeof onInit === 'function') {
            onInit(marker, map);
        }
    });
}

export let searchMap: EveryObject;

export const initSearchMap = async (
    targetId: string,
    mapLat: number,
    mapLng: number,
    store: ListStoreMapPrototype<any, any, any, any, any>,
    zoom: number = 10
): Promise<void> => {
    // @ts-ignore
    if (!window.DG) {
        return;
    }
    await DG.plugin(CLUSTER_PLUGIN_URL);

    DG.then(function () {
        searchMap = DG.map(targetId, {
            center: [mapLat, mapLng],
            zoom,
            fullscreenControl: false,
            zoomControl: false,
            poi: false
        });

        if (commonStore.isMobile) {
            DG.control.location({ position: 'bottomright' }).addTo(searchMap);
        } else {
            DG.control.scale({ position: 'bottomleft' }).addTo(searchMap);
        }

        const debounceOnChange = debounce(() => store.mapChange(), 350);
        searchMap.on('moveend', debounceOnChange);

        searchMap.on('click', () => {
            removeActiveClass();
            if (commonStore.isMobile) {
                store.setSelectedMapItems([]);
            }
        });

        debounceOnChange();
    });
};

export function moveAddressMarker(marker: EveryObject, map: EveryObject, mapLat: number, mapLng: number) {
    marker.setLatLng([mapLat, mapLng]);
    map.setView([mapLat, mapLng]);
}

let markers;

const removeActiveClass = () => {
    Array.from(document.getElementById('mapSearch').querySelectorAll('a.crm-Map__marker.active')).forEach(element =>
        element.classList.remove('active')
    );
};

let lastIcon: Element | null = null;
export const disableIconHiglight = () => {
    if (lastIcon) {
        lastIcon.classList.remove('active');
    }
};

export const highlightActiveEstateIdOn = (item_id: number, itemType: 'estate_id' | 'wish_id', base?: ESTATE_BASES) => {
    disableIconHiglight();
    if (!markers) {
        return;
    }

    // @ts-ignore
    const foundMarker = markers.getLayers().find(({ options }) => options[itemType] === item_id && (base ? options.base === base : true));
    if (foundMarker) {
        const visibleMarker = markers.getVisibleParent(foundMarker);
        if (visibleMarker && visibleMarker._icon) {
            lastIcon = visibleMarker._icon as Element;
            const innerIcon = lastIcon.querySelector('.crm-Map__marker');
            if (innerIcon) {
                lastIcon = innerIcon;
            }
            lastIcon.classList.add('active');
        }
    }
};

export async function updateMapCluster<T, K>(items: T[], store: ListStoreMapPrototype<any, any, any, any, any>): Promise<void> {
    try {
        if (markers) {
            markers.removeFrom(searchMap);
        }
        markers = DG.markerClusterGroup({
            maxClusterRadius: commonStore.isMobile ? 50 : 30,
            showCoverageOnHover: true,
            spiderfyOnMaxZoom: false,
            iconCreateFunction: cluster => {
                const markers = cluster.getAllChildMarkers();
                const prices = markers.map(({ options }) => options.price);
                const onlyOwners = markers.every(({ options }) => options.base === ESTATE_BASE_OWNERS);
                const onlyExternal = markers.every(({ options }) => options.base === ESTATE_BASE_EXTERNAL);
                return DG.divIcon({
                    html: `<span class="crm-Map__marker_counter">${markers.length}</span><span>от ${roundPrice(
                        Math.min(...prices)
                    )}</span>`,
                    className: `crm-Map__marker crm-Map__marker_cluster ${onlyOwners ? 'owners' : onlyExternal ? 'external' : ''}`,
                    iconSize: DG.point(70, 22)
                });
            }
        });

        markers.on('click', ({ layer }) => {
            removeActiveClass();
            store.setFilterOnMap(false);
            store.setSelectedMapItems([store.matchSelectedItemsFiled(layer.options)]);
        });

        markers.on('clusterclick', ({ layer: cluster }) => {
            store.setPreventOnMapChange();
            store.setFilterOnMap(false);
            removeActiveClass();
            let bottomCluster = cluster;

            while (bottomCluster._childClusters.length === 1) {
                bottomCluster = bottomCluster._childClusters[0];
            }

            const itemIds = cluster.getAllChildMarkers().map(({ options: { estate_id, base, wish_id } }) => ({
                estate_id,
                wish_id,
                base
            }));

            store.setSelectedMapItems(
                itemIds.slice(0, commonStore.isMobile ? 100 : store.COUNT_ITEMS_ON_MAP).map(store.matchSelectedItemsFiled)
            );
        });

        const isMini = searchMap.getZoom() < 13 && items.length > 100;
        const markerList = [];

        // TODO:
        // @ts-ignore
        items.forEach(({ estate_id, wish_id, base, geo_lat, geo_lon, type, price }) => {
            const baseClassName = base === ESTATE_BASE_OWNERS ? 'owners' : base === ESTATE_BASE_EXTERNAL ? 'external' : '';

            const myDivIcon = DG.divIcon({
                iconSize: isMini ? [6, 6] : [70, 22],
                html: `<a 
                href="${EstateLinkState(estate_id, type === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL, base).pathname}" 
                onclick="setTimeout(() => this.classList.add('active'), 0); return false;" 
                class="crm-Map__marker${isMini ? 'Mini' : ''} ${baseClassName}">
                    ${!isMini ? roundPrice(price) : ''}
                </a>`
            });

            const marker = DG.marker([geo_lat, geo_lon], { icon: myDivIcon, wish_id, estate_id, base, price, type });
            markerList.push(marker);
        });

        markers.addLayers(markerList);
        searchMap.addLayer(markers);
    } catch (e) {}
}
