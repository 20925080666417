import { observable, action, computed } from 'mobx';
import ListStorePrototype, { DropdownType } from '~/stores/prototypes/ListStore.prototype';
import {
    ExportingBase,
    ExportingBaseStatistics,
    ExportingTariffPrice,
    ExportingTariffStatistics,
    Region,
    TariffPriceHeaderOptions
} from '~/types/exports.types';
import * as exportingBaseApi from '~/api/export/exportingBaseApi';
import authStore from '~/stores/authStore';
import { nProgress } from '~/stores/helpers/decorators.helpers';
import deepCopy from '~/common/deepCopy';
import { HistoryChange } from '~/types/historyChanges.types';

type ExportingBaseProperty = {
    tariffStatistics: ExportingTariffStatistics[];
    loadingHistory: boolean;
    history: HistoryChange[];
};

class ExportingBaseStore extends ListStorePrototype<ExportingBase, ExportingBase, ExportingBaseProperty, {}> {
    listFilterClear = {};

    @observable
    exportingBases: ExportingBase[] = [];

    constructor() {
        super('export_base_id', 'export', exportingBaseApi);

        authStore.registerInitFunc(() => {
            this.fetchAllRegions();
            this.fetchExportingBases();
        });
    }

    @action
    async fetchItem(export_base_id: number) {
        await super.fetchItem(export_base_id);
        const { item } = this.getItem(export_base_id);
        if (item) {
            this.setEditingItem(export_base_id, deepCopy(item));
        }
    }

    @action
    async fetchExportingBases() {
        if (!this.exportingBases.length) {
            try {
                await this.loadExportingBases();
            } catch (errors) {
                this.listErrors = errors;
            }
        }
        this.loadingList = false;
    }

    @action
    async loadExportingBases() {
        const memKey = 'exportingBases';
        const exportingBasesJSON = localStorage.getItem(memKey);
        if (exportingBasesJSON) {
            this.exportingBases = JSON.parse(exportingBasesJSON);
        }
        const { list } = await exportingBaseApi.fetchList();
        this.exportingBases = list;
        localStorage.setItem(memKey, JSON.stringify(this.exportingBases));
    }

    @nProgress
    @action
    async toggleDisableItem(export_base_id: number, enable: boolean) {
        this.loadingList = true;
        await exportingBaseApi.toggleExportingBase(export_base_id, enable);
        await this.loadExportingBases();
        this.loadingList = false;
        await this.fetchExportingBasesStatistics();
    }

    @observable
    loadingBasesStatistics = true;
    @observable
    exportingBasesStatistics: ExportingBaseStatistics[] = [];

    @action
    async fetchExportingBasesStatistics() {
        this.loadingBasesStatistics = true;
        this.exportingBasesStatistics = await exportingBaseApi.exportingBasesStatistics();
        this.loadingBasesStatistics = false;
    }

    @observable
    regions: Region[] = [];
    @observable
    loadingRegions: boolean = false;

    @action
    fetchAllRegions = async () => {
        if (!this.regions.length) {
            this.loadingRegions = true;
            this.regions = await exportingBaseApi.fetchAllRegions();
            this.regions.unshift({ region_id: 0, title: 'Все остальные', type: '' });
            this.loadingRegions = false;
        }
    };

    @computed
    get regionsDropdown(): DropdownType[] {
        return this.regions.map(({ region_id, title, type }) => ({ key: region_id, value: region_id, text: `${title} ${type}`.trim() }));
    }

    @computed
    get regionsById(): Map<number, Region> {
        return this.regions.reduce((res, region) => {
            res.set(region.region_id, region);
            return res;
        }, new Map());
    }

    @action
    addRegionId = async (export_base_id: number, region_id: number) => {
        this.loadingList = true;
        this.loadingRegions = true;
        await exportingBaseApi.addExportRegionId(export_base_id, region_id);
        await this.loadExportingBases();
        // this.setEditingExportingBase(export_base_id);
        await this.fetchExportingBasesStatistics();
        this.loadingList = false;
        this.loadingRegions = false;
    };

    // @action
    // setEditingExportingBase(export_base_id: number) {
    //     this.errorsExportingBaseSave = [];
    //     const foundBase = this.exportingBases.find(base => export_base_id === base.export_base_id);
    //     this.exportingBaseEditing = deepCopy(foundBase);
    // }

    @computed
    get exportingBasesDropdown(): DropdownType[] {
        return this.exportingBases.map(({ export_base_id, name }, i) => ({ key: export_base_id, value: export_base_id, text: name }));
    }

    getIndexTariff = (export_base_id: number, tariff_id: number): number => {
        const { tariffs } = this.getItem(export_base_id).editingItem;
        return tariffs.findIndex(tariff => tariff.tariff_id === tariff_id);
    };

    @action
    setEditingExportingBasePrice(export_base_id: number, tariff_id: number, price_id: number, priceData: Partial<ExportingTariffPrice>) {
        const indexTariff = this.getIndexTariff(export_base_id, tariff_id);
        const { tariffs } = this.getItem(export_base_id).editingItem;
        const indexPrice = tariffs[indexTariff].prices.findIndex(price => price.price_id === price_id);
        const oldPrice = this.getItem(export_base_id).editingItem.tariffs[indexTariff].prices[indexPrice];
        this.getItem(export_base_id).editingItem.tariffs[indexTariff].prices[indexPrice] = { ...oldPrice, ...priceData };
    }

    @action
    setEditingExportingBaseExtraPrice(export_base_id: number, tariff_id: number, price_ids: number[], extraPrice: number) {
        const indexTariff = this.getIndexTariff(export_base_id, tariff_id);
        const { tariffs } = this.getItem(export_base_id).editingItem;
        for (const price_id of price_ids) {
            const indexPrice = tariffs[indexTariff].prices.findIndex(price => price.price_id === price_id);
            this.getItem(export_base_id).editingItem.tariffs[indexTariff].prices[indexPrice].extraPrice = extraPrice;
        }
    }

    // @action
    // toggleDisableTariff(tariff_id: number) {
    //     // const indexTariff = this.getIndexTariff(tariff_id);
    //     // this.exportingBaseEditing.tariffs[indexTariff].enable = !this.exportingBaseEditing.tariffs[indexTariff].enable;
    // }

    validationItem(editingItem: Partial<ExportingBase>): string[] {
        const errors: string[] = [];
        if (!editingItem.name) {
            errors.push('Заполните наименование');
        }
        return errors;
    }

    getBaseName(base_id: number): string {
        const foundBase = this.exportingBases.find(({ export_base_id }) => export_base_id === base_id);
        if (!foundBase) return '';
        return foundBase.name;
    }

    async exportingBaseTariffsStatistics(export_base_id: number): Promise<void> {
        const tariffStatistics = await exportingBaseApi.exportingBaseTariffsStatistics(export_base_id);
        this.setProperty(export_base_id, { tariffStatistics });
    }

    async updateTariffPrice(base_id: number, index: number, options: TariffPriceHeaderOptions): Promise<void> {
        await exportingBaseApi.updateTariffPrice(base_id, index, options);
    }
}

export default new ExportingBaseStore();
