import React from 'react';
import commonStore from '~/stores/commonStore';

import { Button } from 'semantic-ui-react';
import Modal, { ModalContent, useToggle } from '~ui/Modal';

import initializeAddressMap from '~/common/osmMapApi';

const YandexMapId = 'yandexmap';

const AddressOnMapBtn = ({ geo_lat, geo_lon, address }: { geo_lat: number; geo_lon: number; address: string }) => {
    const [showModal, toggleModal] = useToggle();

    const showMap = () => {
        initializeAddressMap(YandexMapId, Number(geo_lat), Number(geo_lon), address);
    };

    return (
        <>
            <Button basic size="mini" onClick={toggleModal}>
                на карте
            </Button>
            {showModal && (
                <Modal onMount={showMap} fullScreen={commonStore.isMobile} header="Объект на карте" maxWidth="md" onClose={toggleModal}>
                    <ModalContent dividers>
                        <div id={YandexMapId} className="crm-Estate__mapBox" />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default AddressOnMapBtn;
