import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import developerStore from '~/stores/lifeComplexes/developerStore';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';

const LifeComplexFeedOtherFields = ({ developer_id }: { developer_id: number }) => {
    const handleChange = useCallback((_, { name, value }: { name: string; value: unknown }) => {
        developerStore.setProperty(developer_id, { errors: [] });
        developerStore.setEditingItem(developer_id, {
            [name]: value
        });
    }, []);

    const { editingItem } = developerStore.getItem(developer_id);
    const { name, url, logoUrl } = editingItem;

    return (
        <>
            <Grid.Column width={12}>
                <TextInput value={name} name="name" onChange={handleChange} minLength={2} required label="Название Девелопера" />
            </Grid.Column>
            <Grid.Column width={12}>
                <TextInput value={logoUrl} name="logoUrl" onChange={handleChange} minLength={2} label="Url логотипа компании" />
            </Grid.Column>
            <Grid.Column width={12}>
                <TextInput value={url} name="url" onChange={handleChange} minLength={2} label="Url сайта компании" />
            </Grid.Column>
        </>
    );
};

export default observer(LifeComplexFeedOtherFields);
