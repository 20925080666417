import React, { Fragment } from 'react';
import { Table, List, Icon, Label } from 'semantic-ui-react';

import Time from '../../Base/Time';
import PhonesList from '../../Base/PhonesList';
import { UserTableItem } from '~/types/users.types';
import { USER_MALE_SEX_ID } from '~/types/users.types';
import UserLinkWithPreview from './UserLinkWithPreview';

const UsersTableBody = ({ usersList }: { usersList: Array<UserTableItem> }) => (
    <Table.Body>
        {usersList.length > 0 &&
            usersList.map(
                ({
                    user_id,
                    firstName,
                    lastName,
                    middleName,
                    avatarUrl,
                    enable,
                    weekOutgoingCalls,
                    dailyOutgoingCalls,
                    appointment,
                    group,
                    sex,
                    onlineTime,
                    dismissalTime,
                    phones
                }) => {
                    return (
                        <Table.Row key={`User_${user_id}`}>
                            <Table.Cell>
                                <UserLinkWithPreview user={{ user_id, firstName, lastName, middleName, avatarUrl, enable }} />
                                <div>
                                    {!enable &&
                                        `${sex === USER_MALE_SEX_ID ? 'Уволился' : 'Уволилась'} ${
                                            dismissalTime ? new Date(dismissalTime * 1000).toLocaleDateString('ru') : '---'
                                        }`}
                                    {enable && onlineTime && <Time time={onlineTime} status={true} />}
                                    {enable && !onlineTime && '---'}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {group && (
                                    <Fragment>
                                        {group.name} &nbsp;
                                        {group.chief && (
                                            <Fragment>
                                                (<Icon name="group" />
                                                &nbsp;
                                                <UserLinkWithPreview user={group.chief} />)
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                                <div>{appointment}</div>
                            </Table.Cell>
                            <Table.Cell>
                                {(Number(dailyOutgoingCalls) > 0 || Number(weekOutgoingCalls) > 0) && (
                                    <Fragment>
                                        <Label
                                            size="mini"
                                            basic
                                            title="Количество состоявшихся исходящих звонков за Сегодня"
                                            color={Number(dailyOutgoingCalls) > 0 ? 'teal' : null}
                                        >
                                            <Icon name="phone" /> {dailyOutgoingCalls}
                                        </Label>
                                        <Label
                                            size="mini"
                                            basic
                                            title="Количество состоявшихся исходящих звонков за Последние Семь Дней"
                                            color={Number(weekOutgoingCalls) > 0 ? 'blue' : null}
                                        >
                                            <Icon name="phone" /> {weekOutgoingCalls}
                                        </Label>
                                    </Fragment>
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <PhonesList phones={phones} />
                                </List>
                            </Table.Cell>
                        </Table.Row>
                    );
                }
            )}
    </Table.Body>
);

export default UsersTableBody;
