import React, { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';

import TextInput, { NumberInput } from '~ui/TextInput';
import Grid from '~ui/Grid';
import Select from '~ui/Select';
import Button from '~ui/Button';
import Divider from '~ui/Divider';

import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import MortgagePhonesList from './SubBlocks/MortgagePhonesList';

import { checkFormValid } from '~/common/forms';
import ListError from '~/components/ListError';

const MortgagePersonContacts = ({ item_id, handleChange }: HandlerEditingWrappedProps) => {
    const { editingItem, loadingItem, errors } = mortgagePersonStore.getItem(item_id);

    const { gender_cid, family_cid, education_cid, children_count, total_seniority, email, mobile_phones } = editingItem;

    const formRef = useRef<HTMLFormElement>(null);

    const handleSave = () => {
        if (checkFormValid(formRef.current, true)) {
            mortgagePersonStore.saveItem(item_id);
        }
    };

    const handleChangePhones = useCallback(
        (mobile_phones: string[]) => {
            mortgagePersonStore.setEditingItem(item_id, { mobile_phones });
        },
        [item_id]
    );

    return (
        <form ref={formRef}>
            <Container maxWidth="sm">
                <ListError errors={errors} />

                <Grid stackable>
                    <Grid.Column width={8}>
                        <Select
                            value={family_cid}
                            name="family_cid"
                            label="Семейное положение"
                            onChange={handleChange}
                            options={[
                                { value: 'single', text: gender_cid === 'male' ? 'Холост' : 'Не замужем' },
                                { value: 'married', text: gender_cid === 'male' ? 'Женат' : 'Замужем' },
                                { value: 'divorced', text: gender_cid === 'male' ? 'Разведен' : 'Разведена' },
                                // { value: 4, text: gender === 1 ? 'Вдовец' : 'Вдова' },
                                { value: 'civil_marriage', text: 'Гражданский брак' }
                                // { value: 6, text: `${gender === 1 ? 'Женат' : 'Замужем'}, есть брачный договор` }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <NumberInput label="Дети младше 18" min={0} name="children_count" value={children_count} onChange={handleChange} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <Select
                            value={education_cid}
                            name="education_cid"
                            label="Образование"
                            onChange={handleChange}
                            options={[
                                { value: 'academic_degree', text: 'Ученая степень' },
                                // { value: 5, text: 'Два и более высших' },
                                { value: 'higher', text: 'Высшее' },
                                { value: 'incomplete_higher', text: 'Неоконченное высшее' },
                                { value: 'specialized_secondary', text: 'Среднее специальное' },
                                { value: 'secondary', text: 'Среднее' },
                                { value: 'below', text: 'Начальное' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <NumberInput
                            onChange={handleChange}
                            min={0}
                            label="Общий трудовой стаж"
                            name="total_seniority"
                            value={total_seniority}
                            extraLabel="мес."
                        />
                    </Grid.Column>
                </Grid>
            </Container>

            <Divider />

            <Container maxWidth="sm">
                <Grid stackable alignItems="flex-start">
                    <Grid.Column width={6}>
                        {mobile_phones && (
                            <MortgagePhonesList
                                item_id={item_id}
                                phones={mobile_phones}
                                onChange={handleChangePhones}
                                label="Мобильный телефон"
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <TextInput type="email" value={email} name="email" label="Email" onChange={handleChange} />
                    </Grid.Column>
                </Grid>

                <div className="crm-Item__addMode_buttons">
                    <Button variant="outlined" color="primary" loading={loadingItem} onClick={handleSave}>
                        Сохранить
                    </Button>
                </div>
            </Container>
        </form>
    );
};

export default HandlerEditingBlockWrapper(mortgagePersonStore, observer(MortgagePersonContacts));
