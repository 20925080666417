import React from 'react';
import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import { CONTACT_TYPE_PRIVATE_PERSON_ID } from '~/types/contacts.types';
import contactStore from '~/stores/contactStore';
import kanbanStore from '~/stores/kanbanStore';

const contactKeys = {
    enable: 'Архив',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    firstName: 'Имя',
    companyName: 'Название компании',
    contactType: 'Тип контакта',

    sex: 'Пол',
    birthday: 'День рождения',
    about: 'Описание',
    tags_ids: 'Номера тэгов',
    new_tags_ids: 'Новые тэги',
    phones: 'Телефон(ы)',

    takeToWork: 'Взял(а) в работу',
    major_user_ids: 'Ответсвенный(е) сотрудник(и)',

    mergeTwoContacts: 'Был объединен с №',
    changeExportMajorUser: 'Смена ответственного на экспорт',

    moveKanbanList: 'Передвинул(а) по Канбан-доске',
    old_list_id: 'Старый столбец',
    new_list_id: 'Новый столбец',

    kanban_list_ids: 'Канбан Доски'
};

const printContactVal = (key: string, value: unknown): string => {
    switch (key) {
        case 'contactType':
            return value === CONTACT_TYPE_PRIVATE_PERSON_ID ? 'Физическое лицо' : 'Юридическое лицо';
        case 'enable':
            return value ? 'нет' : 'да';
        case 'updateTime':
        case 'createTime':
            return Number(value) > 0 ? new Date(Number(value) * 1000).toLocaleString() : 'н/д';
        case 'phones':
            if (value instanceof Array) {
                return value.map(({ phone }) => phone).join(', ');
            }
            return '';
        case 'old_list_id':
        case 'new_list_id':
            const board = kanbanStore.getListById(Number(value));
            return board && `${board.title} (${board.lists[0].title})`;
        case 'kanban_list_ids':
            if (value instanceof Array) {
                return value
                    .map(list_id => {
                        const board = kanbanStore.getListById(list_id);
                        return board && `${board.title} (${board.lists[0].title})`;
                    })
                    .join(', ');
            }
            return '';
        default:
            break;
    }

    if (value instanceof Array) {
        return value.join(', ');
    } else if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(contactStore, contactKeys, printContactVal);
