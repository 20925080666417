export const objectToGraphql = (obj_from_json: any): string => {
    if (obj_from_json instanceof Array) {
        return '[' + obj_from_json.map(obj => objectToGraphql(obj)).join(',') + ']';
    } else if (typeof obj_from_json !== 'object' || obj_from_json === null || obj_from_json === undefined) {
        return JSON.stringify(typeof obj_from_json === 'string' ? obj_from_json.replace(/\#/g, 'o!p?') : obj_from_json);
    }

    const props = Object.keys(obj_from_json)
        .map(key => `${key}:${objectToGraphql(obj_from_json[key])}`)
        .join(',');
    return `{${props}}`;
};
