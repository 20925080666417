import React from 'react';
import { observer } from 'mobx-react';

import './bottom.less';

import BottomTabs from './BottomTabs';
import CallInProgress from './CallInProgress';
import commonStore from '~/stores/commonStore';

const Bottom = () => {
    if (commonStore.isMobile) {
        return null;
    }

    return (
        <div className="crm-Bottom">
            <CallInProgress />
            <BottomTabs />
        </div>
    );
};

export default observer(Bottom);
