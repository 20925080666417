import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Accordion, Grid, Input, List, Image, Form, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import RichDropdown from '../../Base/ui/RichDropdown';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import authStore from '~/stores/authStore';
import userStore from '~/stores/userStore';
import isEqual from '../../../common/isEqual';
import UserAvatar from '~ui/UserAvatar';

type UsersAndGroupsDropdownProps = {
    store: ListStoreInterface;
    disableGroups?: boolean;
    disableUsers?: boolean;
    onOutsideClick?: (event: SyntheticEvent) => void;
    group_id?: number | null;
    shortWidth?: boolean;
};

type UsersAndGroupsDropdownState = {
    activeIndex: number;
    searchQuery: string;
    group_ids: number[];
    major_user_id: number[];
};

@observer
class UsersAndGroupsDropdown extends Component<UsersAndGroupsDropdownProps, UsersAndGroupsDropdownState> {
    swappingUserInList = true;

    constructor(props: UsersAndGroupsDropdownProps) {
        super(props);
        const { group_id } = props;

        this.state = {
            activeIndex: 0,
            searchQuery: '',
            group_ids: group_id ? [group_id] : [],
            major_user_id: []
        };
    }

    static getDerivedStateFromProps(nextProps: UsersAndGroupsDropdownProps, prevState: UsersAndGroupsDropdownState) {
        if (nextProps.group_id) {
            return { ...prevState, group_ids: [nextProps.group_id] };
        }
        return prevState;
    }

    renderLabel = ({
        text,
        value: item_id
    }: {
        text: string;
        value: number;
    }): { color?: SemanticCOLORS; content: string; icon?: SemanticICONS } | null => {
        if (this.state.major_user_id.length) {
            return {
                content: text,
                icon: 'user'
            };
        }
        if (userStore.groups.find(({ group_id }) => group_id === item_id)) {
            return {
                color: 'blue',
                content: text,
                icon: 'building'
            };
        }
        return {
            color: 'green',
            content: text,
            icon: 'group'
        };
    };

    handleOpenClick = (event: React.SyntheticEvent, { index }: { index: number }) => {
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    triggerChange = (major_user_id: number[], group_id: number[]) => {
        const { store } = this.props;
        const { listFilter } = store;

        store.changeFilter('major_user_id', major_user_id);
        store.changeFilter('group_id', group_id);
        this.setState({ major_user_id, group_ids: group_id });
    };

    handleMajorUser = (user_id: number) => {
        const { store } = this.props;
        const { group_ids } = this.state;
        let { major_user_id }: { major_user_id: number[] } = store.listFilter;
        this.swappingUserInList = true;

        major_user_id = Array.from(new Set([...major_user_id, user_id]));
        this.triggerChange(major_user_id, [...group_ids]);
    };

    toggleGroupId = (group_id: number) => {
        const { group_ids } = this.state;
        const newGroupIds = [...group_ids];
        const isGroupInSet = ~group_ids.indexOf(group_id);

        const subgroupsIds = authStore.subgroupsByGroupId[group_id] || [group_id];

        // Убираем все подгруппы
        for (const group_id of subgroupsIds) {
            const foundIndex = newGroupIds.indexOf(group_id);
            if (~foundIndex) {
                newGroupIds.splice(foundIndex, 1);
            }
        }
        // Включаем или убираем основную группу из списка
        if (!isGroupInSet) {
            newGroupIds.push(group_id);
        } else {
            const foundIndex = group_ids.indexOf(group_id);
            if (~foundIndex) {
                newGroupIds.splice(foundIndex, 1);
            }
        }
        return newGroupIds;
    };

    handleDropdownRemove = async (event: SyntheticEvent, { value }: { value: number[] }) => {
        const isUseUsers = this.state.major_user_id.length > 0;
        this.triggerChange(isUseUsers ? value : [], isUseUsers ? [] : value);
    };

    handleSearchChange = (event: React.SyntheticEvent, { value: searchQuery }: { value: string }) => {
        this.setState({ searchQuery });
    };

    handleGroupId = async (event: React.SyntheticEvent, { value: group_id }: { value: number }) => {
        const newGroupIds = this.toggleGroupId(group_id);
        this.swappingUserInList = false;
        this.triggerChange([], newGroupIds);
    };

    componentDidUpdate(prevProps: UsersAndGroupsDropdownProps, prevState: UsersAndGroupsDropdownState) {
        // Whether filter was changed / reset in the store
        const { major_user_id, group_id }: { major_user_id: number[]; group_id: number[] } = this.props.store.listFilter;
        if (!isEqual([...this.state.major_user_id].sort(), [...major_user_id].sort())) {
            this.setState({ major_user_id });
        }
        if (!isEqual([...this.state.group_ids].sort(), [...group_id].sort())) {
            this.setState({ group_ids: group_id });
        }
    }

    filterUserAndGroups = () => {
        const { userAndGroups } = authStore;
        const { searchQuery, group_ids, major_user_id } = this.state;

        let newGroupIds = authStore.matchAllSubgroupsIdsByGroupIds(group_ids);

        return userAndGroups
            .filter(({ user_id }) => (this.swappingUserInList && major_user_id.length > 0 ? !major_user_id.includes(user_id) : true))
            .filter(({ group_id }) => (newGroupIds.length > 0 ? newGroupIds.includes(group_id) : true))
            .map(({ firstName, lastName, user_id, avatarUrl }) => ({
                text: `${lastName} ${firstName}`.trim(),
                value: user_id,
                key: user_id,
                image: { avatarUrl, firstName, lastName }
            }))
            .filter(({ text }) => (searchQuery ? ~text.toUpperCase().indexOf(searchQuery.toUpperCase()) : true));
    };

    render() {
        const { disableUsers, disableGroups, onOutsideClick, shortWidth } = this.props;
        const { activeIndex, searchQuery, group_ids, major_user_id } = this.state;
        const { groups } = userStore;

        const userAndGroupsList = this.filterUserAndGroups();
        const isLabelUseUsers = major_user_id.length > 0;

        return (
            <RichDropdown
                placeholder="Отдел, Сотрудник"
                value={isLabelUseUsers ? major_user_id : group_ids}
                options={isLabelUseUsers ? authStore.userAndGroupsDropdown : userStore.getGroupsDropdown}
                onChange={this.handleDropdownRemove}
                renderLabel={this.renderLabel}
                onClick={onOutsideClick}
                shortWidth={shortWidth}
            >
                <Grid
                    stackable
                    className={cs({ 'crm-List__usersGroups': true, 'crm-List__usersGroups_short': disableUsers || disableGroups })}
                >
                    {!disableGroups && (
                        <Grid.Column width={disableUsers ? 16 : 9}>
                            <Accordion className="crm-List__usersGroups_groups">
                                {groups.map(({ name, group_id, subgroups }, index) => {
                                    const allOfficeHasChecked = group_ids.includes(group_id);

                                    return (
                                        <Fragment key={group_id}>
                                            <Accordion.Title
                                                active={activeIndex === index}
                                                content={<label className="crm-List__usersGroups_label">{name}</label>}
                                                index={index}
                                                onClick={this.handleOpenClick}
                                            />
                                            <Accordion.Content
                                                active={activeIndex === index}
                                                content={
                                                    <Form>
                                                        <Form.Group grouped>
                                                            <Form.Checkbox
                                                                indeterminate={!allOfficeHasChecked}
                                                                key={group_id}
                                                                checked={allOfficeHasChecked}
                                                                label="Весь офис"
                                                                name="group_id"
                                                                value={group_id}
                                                                onChange={this.handleGroupId}
                                                            />
                                                            {subgroups.map(({ name, group_id }) => (
                                                                <Form.Checkbox
                                                                    key={group_id}
                                                                    checked={allOfficeHasChecked || group_ids.includes(group_id)}
                                                                    label={name}
                                                                    name="group_id"
                                                                    value={group_id}
                                                                    onChange={this.handleGroupId}
                                                                    disabled={allOfficeHasChecked}
                                                                />
                                                            ))}
                                                        </Form.Group>
                                                    </Form>
                                                }
                                            />
                                        </Fragment>
                                    );
                                })}
                            </Accordion>
                        </Grid.Column>
                    )}
                    {!disableUsers && (
                        <Grid.Column width={disableGroups ? 16 : 7}>
                            <Input
                                icon="search"
                                placeholder="Поиск"
                                size="mini"
                                fluid
                                value={searchQuery}
                                onChange={this.handleSearchChange}
                            />
                            <List selection verticalAlign="middle" size="tiny" className="crm-List__usersGroups_userList">
                                {userAndGroupsList.map(({ text, value, key, image }) => (
                                    <List.Item key={String(key)} onClick={this.handleMajorUser.bind(this, Number(key))}>
                                        {image && (
                                            <Image avatar>
                                                <UserAvatar
                                                    size={30}
                                                    src={image.avatarUrl}
                                                    firstName={image.firstName}
                                                    lastName={image.lastName}
                                                />
                                            </Image>
                                        )}
                                        <List.Content>
                                            <List.Description>{text}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>
                        </Grid.Column>
                    )}
                </Grid>
            </RichDropdown>
        );
    }
}

export default UsersAndGroupsDropdown;
