import React, { Fragment } from 'react';
import { Table, Icon } from 'semantic-ui-react';

import Time from '../../Base/Time';
import history from '../../../history';
import { Task } from '~/types/tasks.types';
import TaskLink, { TaskLinkState } from './TaskLink';
import moment from 'moment';

import tasksL10n from '../../../L10n/tasks.L10n';
import UsersLinkList from '../Users/UsersLinkList';
import UserLinkWithPreview from '../Users/UserLinkWithPreview';

const TasksTableBody = ({ tasksList }: { tasksList: Array<Task> }) => {
    const currentTime = Date.now() / 1000;

    return (
        <Table.Body>
            {tasksList.length > 0 &&
                tasksList.map(({ task_id, status, priority, major_user, title, deadLine, createTime, updateTime, assign_users }) => {
                    return (
                        <Table.Row
                            key={task_id}
                            negative={status < 3 && deadLine && deadLine > 0 && deadLine < currentTime}
                            positive={status === 4}
                            warning={status === 3}
                        >
                            <Table.Cell>
                                <b>
                                    <TaskLink task={{ task_id, createTime }} />
                                </b>
                                <div>
                                    Актуальность:&nbsp;
                                    <b>
                                        <Time time={updateTime} />
                                    </b>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {priority === 2 && <Icon title="Срочная задача" name="lightning" color="red" />}
                                {priority === 3 && <Icon title="Не срочная задача" name="pause" color="grey" />}
                                {title}
                            </Table.Cell>
                            <Table.Cell>
                                {tasksL10n.STATUS_FIND(status)}
                                {deadLine && deadLine > 0 && (
                                    <Fragment>
                                        <br />
                                        <Icon name="clock" /> {moment(deadLine * 1000).calendar()}
                                    </Fragment>
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                <UsersLinkList usersList={assign_users} />
                            </Table.Cell>
                            <Table.Cell>
                                <UserLinkWithPreview icon user={major_user} />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
        </Table.Body>
    );
};

export default TasksTableBody;
