import React, { Component, Fragment } from 'react';
import { Grid, Form, Button, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import taskStore from '~/stores/taskStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import UserDropdown from '../../../ItemsDropdowns/UserDropdown';
import authStore from '~/stores/authStore';
import { ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';

type ParticipantsBlockEditingProps = HandlerEditingWrappedProps & {
    assign_users_ids?: Array<number>;
    observer_users_ids?: Array<number>;
    major_user_id?: number;
};

type ParticipantsBlockEditingState = {
    groups_id: Array<number>;
    showMajorUser: boolean;
    showObserversUser: boolean;
};

@observer
class TaskParticipantsEditing extends Component<ParticipantsBlockEditingProps, ParticipantsBlockEditingState> {
    constructor(props: ParticipantsBlockEditingProps) {
        super(props);

        const currentUserId = authStore.currentUser.user_id;

        const item = {
            task_id: props.item_id,
            assign_users_ids: props.assign_users_ids || [],
            observer_users_ids: props.observer_users_ids || [],
            major_user_id: props.major_user_id || currentUserId
        };
        taskStore.setEditingItem(item.task_id, item);

        this.state = {
            groups_id: authStore.getPermission(taskStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP ? [authStore.currentUser.group_id] : [],
            showMajorUser: item.major_user_id !== currentUserId,
            showObserversUser: item.observer_users_ids.length > 0
        };
    }

    handleShowMajorUser = (evt: React.SyntheticEvent) => {
        evt.preventDefault();
        this.setState({ showMajorUser: !this.state.showMajorUser });
    };

    handleShowObserversUsers = (evt: React.SyntheticEvent) => {
        evt.preventDefault();
        this.setState({ showObserversUser: !this.state.showObserversUser });
    };

    handleAssignUsersChange = (value: number | Array<number>) => {
        if (value instanceof Array) {
            taskStore.setEditingItem(this.props.item_id, {
                assign_users_ids: value
            });
        }
    };

    handleObserverUsersChange = (value: number | Array<number>) => {
        if (value instanceof Array) {
            taskStore.setEditingItem(this.props.item_id, {
                observer_users_ids: value
            });
        }
    };

    handleMajorUserId = (major_user_id: number | Array<number>) => {
        if (typeof major_user_id === 'number') {
            taskStore.setEditingItem(this.props.item_id, {
                major_user_id
            });
        }
    };

    render() {
        const { item_id } = this.props;
        const { groups_id, showMajorUser, showObserversUser } = this.state;
        const { assign_users_ids, major_user_id, observer_users_ids } = taskStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline size="mini" className="crm-Tasks__assignedUsers">
                        <label className="crm-Estate__field_label">Ответственный(е)</label>
                        <div className="ui tiny form crm-Tasks__assignedUsers_dropdown">
                            <UserDropdown
                                user_id={Array.from(assign_users_ids)}
                                groups_id={groups_id}
                                onChange={this.handleAssignUsersChange}
                            />
                        </div>
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field style={{ width: '100%' }}>
                        <Grid stackable>
                            <Grid.Column width={4}>
                                <label className="crm-Estate__field_label">Постановщик</label>
                            </Grid.Column>
                            <Grid.Column
                                width={showObserversUser ? 11 : 6}
                                className="ui tiny form"
                                style={showMajorUser ? { top: '-8px' } : {}}
                            >
                                {!showMajorUser && (
                                    <a href="#none" title="Поменять Постановщика" onClick={this.handleShowMajorUser}>
                                        Вы
                                    </a>
                                )}
                                {showMajorUser && (
                                    <UserDropdown user_id={major_user_id} groups_id={groups_id} onChange={this.handleMajorUserId} />
                                )}
                            </Grid.Column>
                            <Grid.Column width={5}>
                                {!showObserversUser && (
                                    <a href="#none" onClick={this.handleShowObserversUsers}>
                                        + наблюдатель
                                    </a>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Form.Field>
                </Form.Group>

                {showObserversUser && (
                    <Form.Group>
                        <Form.Field inline size="mini" className="crm-Tasks__assignedUsers">
                            <label className="crm-Estate__field_label">Наблюдатель(и)</label>
                            <div className="ui tiny form crm-Tasks__assignedUsers_dropdown">
                                <UserDropdown
                                    user_id={observer_users_ids ? Array.from(observer_users_ids) : []}
                                    groups_id={groups_id}
                                    onChange={this.handleObserverUsersChange}
                                />
                            </div>
                        </Form.Field>
                    </Form.Group>
                )}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(taskStore, TaskParticipantsEditing);
