import { DaDataAddressSuggestion, DaDataFmsUnitSuggestion, DaDataNameSuggestion, DaDataPartySuggestion } from '~/types/dadata.types';
import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';

export const fetchNamesSuggestions = async (
    query: string,
    count: number,
    parts?: ('SURNAME' | 'NAME' | 'PATRONYMIC')[]
): Promise<DaDataNameSuggestion[]> => {
    const graphql = `{
        fetchDaDataSuggestions(type: "name", query: ${objectToGraphql(query)}, params: ${objectToGraphql(parts || null)})
      }`;

    const data: { fetchDaDataSuggestions: string } = await fetchApi.getGQ(graphql);
    const suggestions: DaDataNameSuggestion[] = JSON.parse(data.fetchDaDataSuggestions);
    return suggestions.slice(0, count);
};

export const fetchAddressSuggestions = async (query: string, count: number): Promise<DaDataAddressSuggestion[]> => {
    const graphql = `{
        fetchDaDataSuggestions(type: "address", query: ${objectToGraphql(query)})
      }`;

    const data: { fetchDaDataSuggestions: string } = await fetchApi.getGQ(graphql);
    const suggestions: DaDataAddressSuggestion[] = JSON.parse(data.fetchDaDataSuggestions);
    return suggestions.slice(0, count);
};

export const fetchFmsUnitSuggestions = async (query: string, count: number): Promise<DaDataFmsUnitSuggestion[]> => {
    const graphql = `{
        fetchDaDataSuggestions(type: "fms", query: ${objectToGraphql(query)})
      }`;

    const data: { fetchDaDataSuggestions: string } = await fetchApi.getGQ(graphql);
    const suggestions: DaDataFmsUnitSuggestion[] = JSON.parse(data.fetchDaDataSuggestions);
    return suggestions.slice(0, count);
};

export const fetchPartySuggestions = async (query: string, count: number): Promise<DaDataPartySuggestion[]> => {
    const graphql = `{
        fetchDaDataSuggestions(type: "party", query: ${objectToGraphql(query)})
      }`;

    const data: { fetchDaDataSuggestions: string } = await fetchApi.getGQ(graphql);
    const suggestions: DaDataPartySuggestion[] = JSON.parse(data.fetchDaDataSuggestions);
    return suggestions.slice(0, count);
};
