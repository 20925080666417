import React, { Component, Fragment, ChangeEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox, { Switch } from '~ui/Checkbox';
import Grid from '@material-ui/core/Grid';

import Divider from '~ui/Divider';
import TextInput from '~ui/TextInput';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import contactStore from '~/stores/contactStore';
import tagStore from '~/stores/tagStore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KanbanListDropdown from '~/components/Base/KanbanListDropdown';
import FilterCell from '~/components/Lists/Common/FilterCell';
import InfoPopup from '~/components/Base/InfoPopup';
import { DEFAULT_NO_CALLS_TIME } from '~/components/Lists/Estate/FilterBlocks/FilterEstateOtherParams';

const deltaNoCallsTimes = [
    { key: 1, value: DEFAULT_NO_CALLS_TIME, text: '7 дней' },
    { key: 2, value: 10 * 86400, text: '10 дней' },
    { key: 3, value: 14 * 86400, text: '14 дней' },
    { key: 4, value: 30 * 86400, text: '30 дней' }
];

@observer
class FilterContactsMoreDetails extends Component<FilterMoreDetailsProps, {}> {
    handleTagsIds = (event: React.SyntheticEvent, { value }: { value: number[] }) => {
        contactStore.changeFilter('tags_ids', value);
    };

    handleKanbanIds = (event: React.SyntheticEvent, { value }: { value: number }) => {
        contactStore.changeFilter('kanban_list_id', [value]);
    };

    handleNoCalls = (event: ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const oldValue = contactStore.listFilter[name];
        // @ts-ignore
        contactStore.changeFilter(name, oldValue === null ? DEFAULT_NO_CALLS_TIME : null);
    };

    render() {
        const {
            search,
            tags_ids,
            withoutAgent,
            showEstates,
            tagsTogether,
            hasCallEvent,
            kanban_list_id,
            commentsContain,
            noOutgoingCallsTime
        } = contactStore.listFilter;
        const { allTagsDropdown, loadingFetchAllTags } = tagStore;
        const { handleChange, handleCheckbox } = this.props;

        return (
            <div>
                <TextInput
                    name="search"
                    onChange={handleChange}
                    label="Поиск по имени"
                    value={search}
                    fullWidth
                    startLabel={<SearchIcon />}
                />
                <TextInput
                    name="commentsContain"
                    onChange={handleChange}
                    label="Поиск по комментариям"
                    value={commentsContain}
                    fullWidth
                    startLabel={<SearchIcon />}
                />

                <Divider />

                <Dropdown
                    multiple
                    selection
                    options={allTagsDropdown}
                    search
                    placeholder="Тэги контактов"
                    value={tags_ids}
                    noResultsMessage="Тэг не найден"
                    onChange={this.handleTagsIds}
                    loading={loadingFetchAllTags}
                />

                <FormControlLabel
                    control={<Checkbox checked={tagsTogether} onChange={handleCheckbox} name="tagsTogether" />}
                    label="тэги вместе"
                    style={{ marginLeft: '1em' }}
                />

                <Divider />

                <FilterCell title="Канбан доска">
                    <Grid item sm={6} xs={12}>
                        <KanbanListDropdown
                            kanban_list_id={kanban_list_id?.length ? kanban_list_id[0] : null}
                            onChange={this.handleKanbanIds}
                        />
                    </Grid>
                </FilterCell>

                <FilterCell title="Звонки" titleFullWidth spacing={0}>
                    <Grid item xs={12}>
                        <Switch
                            checked={noOutgoingCallsTime !== null}
                            name="noOutgoingCallsTime"
                            label="Нет звонков (сообщений)"
                            onChange={this.handleNoCalls}
                        />
                        {typeof noOutgoingCallsTime === 'number' && (
                            <div className="crm-Estate__filter_exportedBases">
                                за &nbsp;
                                <Dropdown
                                    options={deltaNoCallsTimes}
                                    name="noOutgoingCallsTime"
                                    value={noOutgoingCallsTime}
                                    onChange={handleChange}
                                    closeOnChange
                                    upward
                                    inline
                                />
                            </div>
                        )}
                        <InfoPopup
                            size="small"
                            content={`Поиск только среди тех контактов, с которыми был хотя бы один звонок/переписка. 
                                      Учитываются состоявшиеся входящие или исходящие звонки, сообщения в WhatsApp на/c номер(а) телефона контакта, который указан в карточке объекта.`}
                        />
                    </Grid>
                </FilterCell>

                <FilterCell title="Установки" titleFullWidth spacing={0} hideDivider>
                    <Grid item xs={12}>
                        <Switch
                            checked={showEstates}
                            onChange={handleCheckbox}
                            name="showEstates"
                            label="Показать объекты / собственников"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox checked={hasCallEvent} onChange={handleCheckbox} name="hasCallEvent" label="Запланирован звонок" />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox checked={withoutAgent} onChange={handleCheckbox} name="withoutAgent" label="Пустые" />
                    </Grid>
                </FilterCell>
            </div>
        );
    }
}

export default FilterContactsMoreDetails;
