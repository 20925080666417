import { observable, action, computed, reaction } from 'mobx';

import { MortgagePerson, MortgageRequest } from '~/types/mortgageRequest.types';
import * as mortgagePersonApi from '~/api/mortgageRequest/mortgagePersonApi';
import mortgageRequestStore from './index';
import ItemEditingPrototype from '~/stores/prototypes/ItemEditing.prototype';
import contactStore from '~/stores/contactStore';

class MortgagePersonStore extends ItemEditingPrototype<MortgagePerson, MortgageRequest> {
    constructor() {
        super('contact_id', 'contact', mortgagePersonApi);
    }

    @action
    async createPersonItem(mortgage_request_id: number): Promise<void> {
        const { contact_id, mortgage_person_ids } = mortgageRequestStore.getItem(mortgage_request_id).editingItem;

        let emptyPerson: Partial<MortgagePerson> = { mortgage_request_id };

        if (!mortgage_person_ids.length) {
            const { firstName, lastName, middleName, sex, phones, email, birthday } = contactStore.getItem(contact_id).item;

            emptyPerson = {
                ...emptyPerson,
                name: firstName,
                surname: lastName,
                patronymic: middleName,
                email,
                birthday,
                gender_cid: sex === 1 ? 'male' : sex === 2 ? 'female' : null,
                mobile_phones: phones?.map(({ phone }) => phone)
            };
        }

        const mortgage_person_id = await super.createEmptyItem(emptyPerson);

        mortgageRequestStore.setEditingItem(mortgage_request_id, { mortgage_person_ids: [...mortgage_person_ids, mortgage_person_id] });
    }

    getParent(id: number | string): Partial<MortgageRequest> {
        const { mortgage_request_id } = this.getItem(id).editingItem;
        return mortgageRequestStore.getItem(mortgage_request_id).editingItem;
    }
}

export default new MortgagePersonStore();
