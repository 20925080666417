import { proxyL10nHandler } from '~/common/localizations';

const DOCUMENT_TYPE = [
    [1, 'Паспорт РФ'],
    [30, 'Загранпаспорт'],
    [40, 'ИНН'],
    [41, 'СНИЛС'],
    [50, 'Водительское удостоверение'],
    [55, 'СТС автомобиля'],
    [100, 'Трудовая книжка'],
    [120, 'Трудовой договор'],
    [150, '2-НДФЛ'],
    [160, 'Справка по форме банка'],
    [200, 'Согласие на обработку персональных данных']
];

const mortgageL10n = new Proxy({}, proxyL10nHandler);

mortgageL10n.DOCUMENT_TYPE = DOCUMENT_TYPE;

export default mortgageL10n;
