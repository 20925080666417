import React, { Component, Fragment } from 'react';
import { Menu, Form, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { CALL_TYPE_INCOMING, CALL_TYPE_OUTGOING } from '~/types/cellCalls.types';
import cellCallStore from '~/stores/cell/cellCallStore';

import ListFilterWrapper, { deltaCreateTimes } from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import ButtonGroup from '~/components/Base/ButtonGroup';

import FilterCellCallMoreDetails from './FilterBlocks/FilterCellCallMoreDetails';

@observer
class FilterCellCall extends Component<ListFilterWrapperProps> {
    handleChangeDirection = (event: React.SyntheticEvent, { value }: { value: string }) => {
        if (Number(value) !== CALL_TYPE_INCOMING) {
            cellCallStore.changeFilter('source_id', 0);
        }

        cellCallStore.changeFilter('direction', Number(value));
    };

    render() {
        const { deltaStartTime, direction } = cellCallStore.listFilter;
        const { handleChange } = this.props;

        return (
            <Fragment>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            placeholder="Время"
                            closeOnChange={true}
                            selection
                            options={deltaCreateTimes}
                            value={deltaStartTime}
                            onChange={handleChange}
                            name="deltaStartTime"
                        />
                    </Form>
                </Menu.Item>

                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [CALL_TYPE_INCOMING, 'Входящие'],
                                [CALL_TYPE_OUTGOING, 'Исходящие']
                            ]}
                            name="direction"
                            value={direction}
                            handleChange={this.handleChangeDirection}
                        />
                    </Form>
                </Menu.Item>
            </Fragment>
        );
    }
}

export default ListFilterWrapper(cellCallStore, FilterCellCall, FilterCellCallMoreDetails);
