import { observable, action, computed } from 'mobx';
import arrayMove from 'array-move';
import * as kanbanApi from '~/api/kanbanApi';
import * as contactsApi from '~/api/contactsApi';
import { DropdownType } from './prototypes/ListStore.prototype';
import ItemListWithPultStore from './prototypes/ItemListWithPultStore.prototype';
import deepCopy from '../common/deepCopy';
import { ContactTableItem, KanbanBoard, KanbanList } from '~/types/contacts.types';
import authStore from './authStore';
import { nProgress } from '~/stores/helpers/decorators.helpers';

export const initialBoardList: Partial<KanbanList> = {
    list_id: 0,
    title: 'Неразобранные',
    bgColor: '#e0e0e0',
    textColor: '#181f31',
    order: 0
};

type KanbanListItem<T> = {
    loading: boolean;
    items: T[];
    errors: string[];
    count: number;
    pageNumber: number;
};

type KanbanStoreFilter = {
    board_id: number | null;
    group_id: number[];
    major_user_id: number[];
    tagsTogether: boolean;
    tags_ids: number[];
};

const KANBAN_LIST_KEY = 'KANBAN_LIST_KEY';

const COUNT_PER_REQUEST = 20;

class KanbanStore extends ItemListWithPultStore<KanbanBoard, KanbanBoard, {}, KanbanStoreFilter> {
    LIST_MEM_KEY = KANBAN_LIST_KEY;

    listFilterClear: KanbanStoreFilter = { board_id: null, major_user_id: [], group_id: [], tags_ids: [], tagsTogether: false };

    constructor() {
        super('board_id', 'settings', kanbanApi);
        this.clearFilter();

        authStore.registerInitFunc(this.fetchList.bind(this));
    }

    getBoardLists(board_id: number): Partial<KanbanList>[] {
        const foundBoard = this.list.find(board => board.board_id === board_id);
        if (!foundBoard) {
            throw new Error(`board_id: #${board_id} не найден`);
        }
        return foundBoard.lists;
    }

    getListById(list_id: number): KanbanBoard | null {
        const board = this.list.find(board => {
            const list = board.lists.find(list => list.list_id === list_id);
            if (list) {
                return list;
            }
        });
        if (!board) {
            return null;
        }
        return { ...board, lists: board.lists.filter(list => list.list_id === list_id) };
    }

    @computed
    get allListsDropdown(): DropdownType[] {
        return this.list.reduce((acc, board) => {
            acc.push(...board.lists.map(({ list_id, title }) => ({ text: title, value: list_id, key: list_id })));
            return acc;
        }, []);
    }

    validationItem(board: KanbanBoard): string[] {
        const errors = [];

        if (!board.title) {
            errors.push('Задайте название доски');
        }

        return errors;
    }

    @action
    async deleteBoardList(board_id: number, listIndex: number) {
        this.changeArrayValue(board_id, 'lists', listIndex, 'enable', false);
        await this.saveItem(board_id);
    }

    @action
    async moveBoardLists(board_id: number, oldIndex: number, newIndex: number) {
        let { lists } = this.getItem(board_id).editingItem;
        lists = arrayMove(lists, oldIndex, newIndex);
        this.setEditingItem(board_id, { lists });
        await this.saveItem(board_id);
    }

    @observable
    boardListContacts: Map<number | null, KanbanListItem<ContactTableItem>> = new Map();

    debounceFilterFetch = () => {
        this.loadContactsByBoardId(this.listFilter.board_id);
    };

    @action
    loadContactsByBoardId(board_id: number) {
        this.boardListContacts = new Map();

        const boardLists = [initialBoardList, ...this.getBoardLists(board_id)];
        boardLists.forEach(({ list_id }) => {
            this.loadContactsByListId(list_id);
        });
    }

    @action
    mergeContactList(list_id: number | null, newListData: Partial<KanbanListItem<ContactTableItem>>) {
        let oldList = this.boardListContacts.get(list_id);
        if (!oldList) {
            return;
        }
        this.boardListContacts.set(list_id, {
            pageNumber: newListData.pageNumber ?? oldList.pageNumber,
            count: newListData.count ?? oldList.count,
            loading: newListData.loading ?? oldList.loading,
            errors: newListData.errors || oldList.errors,
            items: newListData.items || oldList.items
        });
    }

    async loadContactsListNexPage(list_id: number) {
        let { pageNumber, loading, count } = this.boardListContacts.get(list_id);
        if (loading || count < pageNumber * COUNT_PER_REQUEST) {
            return;
        }
        await this.loadContactsByListId(list_id, pageNumber + 1);
    }

    @action
    async loadContactsByListId(list_id: number | null, pageNumber: number = 0) {
        let { items, count } = this.boardListContacts.get(list_id) || {};
        items = items || [];
        this.boardListContacts.set(list_id, { items: items, count: 0, loading: true, errors: [], pageNumber });

        const { group_id, major_user_id, tags_ids, tagsTogether } = this.listFilter;

        try {
            const { list, count } = await contactsApi.fetchList(
                COUNT_PER_REQUEST,
                pageNumber * COUNT_PER_REQUEST,
                'updateTime',
                'descending',
                {
                    kanban_list_id: [list_id || null],
                    group_id,
                    major_user_id,
                    tagsTogether,
                    tags_ids,
                    enable: true
                },
                null,
                false
            );

            items = [...items, ...list];
            this.mergeContactList(list_id, { items, count: count, loading: false });
        } catch (errors) {
            this.mergeContactList(list_id, { count: count || 0, loading: false, errors });
        }
    }

    @nProgress
    @action
    async updateContactListId(contact_id: number, oldLineId: number, newLineId: number) {
        try {
            await contactsApi.moveKanbanList(contact_id, oldLineId, newLineId);

            const { count: count1, items: items1 } = this.boardListContacts.get(oldLineId);
            const foundIndex = items1.findIndex(item => item.contact_id === contact_id);
            if (!~foundIndex) {
                return;
            }
            const contact = deepCopy(items1[foundIndex]);
            contact.updateTime = Math.floor(Date.now() / 1000);
            items1.splice(foundIndex, 1);
            this.mergeContactList(oldLineId, { count: count1 - 1, items: items1 });

            const { count: count2, items: items2 } = this.boardListContacts.get(newLineId);
            items2.unshift(contact);
            this.mergeContactList(newLineId, { count: count2 + 1, items: items2 });
        } catch (errors) {
            this.listErrors = errors;
        }
    }
}

export default new KanbanStore();
