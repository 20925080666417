import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';
import moment from 'moment';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import RequestStatusEditing from '../EditBlocks/RequestStatusEditing';

import requestStore from '~/stores/requestStore';
import ShadowFragment from '../../../Base/ShadowFragment';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import ClientShowMode from '../../../Base/ClientShowBlock';
import requestL10n from '../../../../L10n/request.L10n';

@observer
class RequestMainBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = requestStore.getItem(item_id);
        const { createTime, updateTime, contact, major_users, status } = item || {};

        return (
            <Fragment>
                <Card fluid color="yellow">
                    <Card.Content>
                        {!editing && (
                            <Fragment>
                                <Header size="tiny">Статус: {status ? requestL10n.REQUEST_STATUS_FIND(status) : 'н/д'}</Header>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>Создана</Grid.Column>
                                        <Grid.Column>
                                            <ShadowFragment shadow={loadingItem} print={moment(createTime * 1000).calendar()} />
                                        </Grid.Column>

                                        {updateTime !== createTime && (
                                            <Fragment>
                                                <Grid.Column>Обновлена</Grid.Column>
                                                <Grid.Column>
                                                    <ShadowFragment shadow={loadingItem} print={moment(updateTime * 1000).calendar()} />
                                                </Grid.Column>
                                            </Fragment>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Fragment>
                        )}
                        {editing && <RequestStatusEditing item_id={item_id} />}
                    </Card.Content>
                </Card>

                <ClientShowMode title="Клиент (Посредник)" contact={contact} major_users={major_users} />
            </Fragment>
        );
    }
}

export default EditingBlockWrapper(requestStore, RequestMainBlockShow);
