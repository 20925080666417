function nsort(vals: number[]): number[] {
    return vals.sort((a: number, b: number) => a - b);
}

export default function median(vals: number[]) {
    if (vals.length === 0) return NaN;

    let half = (vals.length / 2) | 0;

    vals = nsort(vals);
    if (vals.length % 2) {
        // Odd length, true middle element
        return vals[half];
    } else {
        // Even length, average middle two elements
        return (vals[half - 1] + vals[half]) / 2.0;
    }
}
