import React, { Component, Fragment } from 'react';
import { Input } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';

type ShoppingBlockEditingProps = HandlerEditingWrappedProps & {
    workingDaysType: number | null;
    tenants: string | null;
    openingHoursType: number | null;
    openingHoursFrom: string | null;
    openingHoursTo: string | null;
    shoppingCenterScaleType: number | null;
    houseLineType: number | null;
};

@observer
class ShoppingBlockEditing extends Component<ShoppingBlockEditingProps> {
    constructor(props: ShoppingBlockEditingProps) {
        super(props);

        const estate = {
            workingDaysType: props.workingDaysType || null,
            tenants: props.tenants || null,
            openingHoursType: props.openingHoursType || null,
            openingHoursFrom: props.openingHoursFrom || null,
            openingHoursTo: props.openingHoursTo || null,
            shoppingCenterScaleType: props.shoppingCenterScaleType || null,
            houseLineType: props.houseLineType || null
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, item_id } = this.props;
        const {
            workingDaysType,
            tenants,
            openingHoursType,
            openingHoursFrom,
            openingHoursTo,
            shoppingCenterScaleType,
            houseLineType,
            placementType
        } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.ButtonGroup
                    label="Тип помещения"
                    name="placementType"
                    value={placementType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.SHOPPING_PLACEMENT_TYPE}
                    size="small"
                />

                <EditField.Drop
                    label="Масштаб торгового центра"
                    name="shoppingCenterScaleType"
                    value={shoppingCenterScaleType}
                    onChange={handleChange}
                    options={estateL10n.SHOPPING_CENTER_SCALE_DROPDOWN}
                    size="small"
                />

                <EditField.ButtonGroup
                    label="Линия домов"
                    name="houseLineType"
                    value={houseLineType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.SHOPPING_HOUSE_LINE_TYPE}
                    size="small"
                />

                <EditField.ButtonGroup
                    label="Рабочие дни"
                    name="workingDaysType"
                    value={workingDaysType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.SHOPPING_WORKING_DAYS}
                    size="small"
                />

                <EditField.ButtonGroup
                    label="Часы работы"
                    name="openingHoursType"
                    value={openingHoursType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.SHOPPING_OPENING_HOURS_TYPE}
                    size="small"
                >
                    {openingHoursType === 2 && (
                        <span>
                            <Input
                                label="от"
                                placeholder="часы"
                                size="mini"
                                name="openingHoursFrom"
                                value={openingHoursFrom}
                                onChange={handleChange}
                                style={{ marginLeft: '15px', width: '100px' }}
                            />

                            <Input
                                label="до"
                                placeholder="часы"
                                size="mini"
                                name="openingHoursTo"
                                value={openingHoursTo}
                                onChange={handleChange}
                                style={{ marginLeft: '15px', width: '100px' }}
                            />
                        </span>
                    )}
                </EditField.ButtonGroup>

                <EditField.Text label="Арендаторы" name="tenants" value={tenants} onChange={handleChange} size="small" />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, ShoppingBlockEditing);
