import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalActions, ModalContent } from '~ui/Modal';

import InfiniteScroll from 'react-infinite-scroller';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import commonStore from '~/stores/commonStore';
import notificationStore from '~/stores/notificationStore';
import NotyListItem from '~/components/Header/Notifications/NotyListItem';
import Progress from '~ui/Progress';
import { ITEM_TYPES, ITEM_TYPES_OBJECT } from '~/types/notifications.types';
import history from '~/history';
import { ItemState } from '~/components/Base/ItemLinkSimple';

const NotificationsArchiveModal = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        notificationStore.fetchNotiesArchivePage(0);
    }, []);
    const { notificationArchive, hasMoreInArchive } = notificationStore;

    const handleItemLinkClick = (event: React.SyntheticEvent, noty_id: number) => {
        event.stopPropagation();
        onClose();
    };

    const handleItemClick = (noty_id: number, item_type: ITEM_TYPES, item: ITEM_TYPES_OBJECT) => {
        onClose();
        history.push(ItemState({ item_type, item }));
    };

    return (
        <Modal header="Архив уведомлений" fullScreen={commonStore.isMobile} maxWidth="md" onClose={onClose}>
            <ModalContent>
                <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={page => {
                        notificationStore.fetchNotiesArchivePage(page);
                    }}
                    hasMore={hasMoreInArchive}
                    loader={
                        <div key="loading" style={{ position: 'relative' }}>
                            <Progress show />
                        </div>
                    }
                    useWindow={false}
                >
                    <List dense disablePadding style={{ minHeight: 100 }}>
                        {notificationArchive.map(noty => (
                            <NotyListItem
                                noty={noty}
                                key={noty.noty_id}
                                onItemLinkClick={handleItemLinkClick}
                                onItemClick={handleItemClick}
                            />
                        ))}
                    </List>
                </InfiniteScroll>
            </ModalContent>

            <ModalActions>
                <Button onClick={onClose} color="secondary">
                    Закрыть
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default observer(NotificationsArchiveModal);
