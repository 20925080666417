import React, { Component, Fragment } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import isEqual from '../../common/isEqual';
import Progress from '~ui/Progress';

type ProgressSIZES = 'mini' | 'tiny' | 'small' | 'medium' | 'large';

export type LoaderAwaitProps = {
    size?: ProgressSIZES | number;
    dimmer?: boolean;
    active?: boolean;
    immediately?: boolean;
    linear?: boolean;
    color?: 'primary' | 'secondary';
};

type LoaderAwaitState = {
    activeLoader: boolean;
    activeDimmer: boolean;
};

export const AWAIT_LOADER_SHOW_TIME_MS = 400;
export const AWAIT_LOADER_CLOSE_TIME_MS = 400;

class LoaderAwait extends Component<LoaderAwaitProps, LoaderAwaitState> {
    showTimeout: number | null = null;
    hideTimeout: number | null = null;

    constructor(props: LoaderAwaitProps) {
        super(props);

        this.state = {
            activeLoader: (props.immediately && props.active) || false,
            activeDimmer: Boolean(props.active)
        };
    }

    static getDerivedStateFromProps(nextProps: LoaderAwaitProps, prevState: LoaderAwaitState) {
        return {
            activeDimmer: Boolean(nextProps.active),
            activeLoader: !nextProps.active ? false : nextProps.immediately && nextProps.active ? true : prevState.activeLoader
        };
    }

    shouldComponentUpdate(nextProps: LoaderAwaitProps, nextState: LoaderAwaitState) {
        if (this.state.activeLoader === true && nextState.activeLoader === false) {
            this.hideTimeout = window.setTimeout(async () => {
                this.setState({ activeLoader: false });
                this.forceUpdate();
            }, AWAIT_LOADER_CLOSE_TIME_MS);
            return false;
        }
        return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
    }

    componentDidMount() {
        if (this.props.active) {
            this.showTimeout = window.setTimeout(() => {
                this.setState({ activeLoader: true });
                this.showTimeout = null;
            }, AWAIT_LOADER_SHOW_TIME_MS);
        }
    }

    componentDidUpdate() {
        if (this.props.active && !this.showTimeout) {
            this.showTimeout = window.setTimeout(() => {
                this.setState({ activeLoader: true });
                this.showTimeout = null;
            }, AWAIT_LOADER_SHOW_TIME_MS);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.showTimeout);
        clearTimeout(this.hideTimeout);
    }

    render() {
        const { dimmer, size, linear, color } = this.props;
        const { activeLoader, activeDimmer } = this.state;

        let progressSize: number;
        if (typeof size === 'number') {
            progressSize = size;
        } else {
            switch (size) {
                case 'mini':
                    progressSize = 16;
                    break;
                case 'tiny':
                    progressSize = 24;
                    break;
                case 'small':
                    progressSize = 32;
                    break;
                case 'medium':
                    progressSize = 48;
                    break;
                case 'large':
                    progressSize = 64;
                    break;
            }
        }

        const loader = activeLoader ? <Progress linear={linear} color={color} show size={progressSize} /> : null;

        return dimmer ? (
            activeLoader ? (
                <Backdrop
                    open={activeDimmer}
                    style={{ zIndex: 1000, color: '#fff', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                    {loader}
                </Backdrop>
            ) : null
        ) : (
            loader
        );
    }
}

export default LoaderAwait;
