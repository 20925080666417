import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import config from '../../../../config/ui.config';

import UserPhotoEditingBlock from '../EditBlocks/UserPhotoEditing';
import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import UserAvatar from '~ui/UserAvatar';

const handleSaveImage = (item_id: number) => {
    if (userStore.getItem(item_id).editingItem.photo) {
        userStore.loadAvatar(item_id);
    }
};

@observer
class UserPhotoBlock extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = userStore.getItem(item_id);

        const { avatarUrl, firstName, lastName } = item || {};
        const photoUrl = loadingItem ? config.defaultAvatarUrl : avatarUrl || null;

        return (
            <div className="crm-User__photoBox">
                {!editing && <UserAvatar variant="rounded" firstName={firstName} lastName={lastName} src={photoUrl} size={250} />}
                {editing && !loadingItem && (
                    <Fragment>
                        <br />
                        <br />
                        <UserPhotoEditingBlock item_id={item_id} />
                    </Fragment>
                )}
            </div>
        );
    }
}

export default EditingBlockWrapper(userStore, UserPhotoBlock, handleSaveImage);
