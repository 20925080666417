import { observable, action, runInAction } from 'mobx';
import calendarStore from '../calendarStore';
import contactStore from '../contactStore';
import ListStorePrototype from './ListStore.prototype';
import { nProgress } from '../helpers/decorators.helpers';
import { TItemExtended } from './ItemStore.prototype';
import { CALL_EVENT_ITEM_TYPE_CONTACT, MainEventFields } from '~/types/events.types';

export type TCalendarItemExtended = TItemExtended & MainEventFields;

class CalendarEventsStorePrototype<ItemObjectType extends TCalendarItemExtended, ItemFilterType> extends ListStorePrototype<
    ItemObjectType,
    ItemObjectType,
    {},
    ItemFilterType
> {
    @action
    async removeItem(item_id: number) {
        const { item_id: contact_id, item_type } = this.getItem(item_id).editingItem;

        // @ts-ignore
        this.setEditingItem(item_id, { enable: false });
        await this.saveItem(item_id);

        if (item_type === CALL_EVENT_ITEM_TYPE_CONTACT && contact_id) {
            contactStore.fetchCallsEventsByContact(contact_id);
        }

        calendarStore.removeEvent(item_id, this.moduleName);

        return item_id;
    }

    @nProgress
    @action
    async changeItemDates(item_id: number, startTime: number, endTime: number) {
        // @ts-ignore
        await this.ApiModule.saveItem(item_id, { startTime, endTime });
    }

    @action
    async createItem(): Promise<number> {
        return await super.superCreateItem();
    }
}

export default CalendarEventsStorePrototype;
