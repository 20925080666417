import React, { Component, Fragment } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import FilterMoreDetailsBlock from './FilterBlocks/FilterMoreDetailsBlock';
import ListFilterWrapper, { deltaCreateTimes } from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import PropertyTypeBlock from './FilterBlocks/PropertyTypeBlock';
import { ESTATE_BASE_EXTERNAL, ESTATE_BASE_OWNERS } from '~/types/estate.types';

@observer
class FilterEstate extends Component<ListFilterWrapperProps> {
    render() {
        const { deltaTime, base, propertyTypes, type } = estateStore.listFilter;

        return (
            <Fragment>
                <Menu.Item className="crm-List__filter_dropdown">
                    <div className="ui form mini">
                        <PropertyTypeBlock type={type} propertyTypes={propertyTypes} base={base} handleChange={this.props.handleChange} />
                    </div>
                </Menu.Item>
                {[ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL].includes(base) && (
                    <Menu.Item className="crm-List__filter_dropdown" fitted="vertically">
                        <div className="ui form">
                            за &nbsp;
                            <Dropdown options={deltaCreateTimes} value={deltaTime} name="deltaTime" onChange={this.props.handleChange} />
                        </div>
                    </Menu.Item>
                )}
            </Fragment>
        );
    }
}

export default ListFilterWrapper(estateStore, FilterEstate, FilterMoreDetailsBlock);
