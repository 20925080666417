import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { List } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import { NewbuildingUser } from '~/types/newbuildings.types';
import isEqual from '~/common/isEqual';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';
import Paper from '@material-ui/core/Paper';

import { WarningMessage } from '~ui/Message';
import UserAvatar from '~ui/UserAvatar';

import LifeComplexMajorUsersEditing from '../EditBlocks/LifeComplexMajorUsersEditing';
import PhonesList from '~/components/Base/PhonesList';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            marginTop: spacing(1),
            display: 'flex',
            alignItems: 'center'
        },
        content: {
            flex: '1 0 auto'
        },
        cover: {
            width: 64,
            height: 64,
            margin: spacing(1)
        },
        coverMin: {
            width: 32,
            height: 32,
            margin: spacing(1)
        },
        phonesList: {
            marginTop: spacing(0.75)
        }
    })
);

const LifeComplexMajorUsersShow = React.memo(({ major_users }: { major_users: NewbuildingUser[] }) => {
    const classes = useStyles();

    if (major_users.length === 1) {
        const { firstName, lastName, avatarUrl, phone } = major_users[0];
        const title = `${firstName} ${lastName}`.trim();

        return (
            <Card className={classes.root}>
                <CardMedia className={classes.cover} title={title}>
                    <UserAvatar src={avatarUrl} size={64} firstName={firstName} lastName={lastName} />
                </CardMedia>
                <CardContent className={classes.content}>
                    <Typography variant="subtitle1">Ответственный</Typography>
                    <Typography variant="h6">{title}</Typography>
                    {phone && (
                        <List>
                            <PhonesList phones={[{ phone, phone_id: 0 }]} />
                        </List>
                    )}
                </CardContent>
            </Card>
        );
    }

    return (
        <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6">Ответственные</Typography>
            {major_users.map(({ firstName, lastName, avatarUrl, user_id }) => {
                const title = `${firstName} ${lastName}`.trim();
                return (
                    <Card key={user_id} className={classes.root}>
                        <CardMedia className={classes.coverMin} title={title}>
                            <UserAvatar src={avatarUrl} size={32} firstName={firstName} lastName={lastName} />
                        </CardMedia>
                        <CardContent className={classes.content}>
                            <Typography variant="h6">{title}</Typography>
                        </CardContent>
                    </Card>
                );
            })}
        </Paper>
    );
}, isEqual);

const LifeComplexMajorUsersLoading = React.memo(() => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardMedia className={classes.cover}>
                <Skeleton animation="wave" variant="circle" width={80} height={80} />
            </CardMedia>
            <CardContent className={classes.content}>
                <Typography variant="subtitle1">Ответственный</Typography>
                <Typography variant="h6">
                    <Skeleton animation="wave" />
                </Typography>
            </CardContent>
        </Card>
    );
});

const LifeComplexMajorUsers = ({ item_id, editing }: EditingWrappedProps) => {
    const newbuilding = lifeComplexStore.getItem(item_id).item;

    if (!newbuilding) {
        return <LifeComplexMajorUsersLoading />;
    }

    if (editing) {
        return <LifeComplexMajorUsersEditing item_id={item_id} major_users_id={newbuilding.major_user_ids} />;
    }

    const { major_user_ids, major_users } = newbuilding;
    if (!major_user_ids.length) {
        return (
            <Paper style={{ padding: '1rem' }}>
                <WarningMessage>Ответственный не указан</WarningMessage>
            </Paper>
        );
    }

    return <LifeComplexMajorUsersShow major_users={major_users} />;
};

export default EditingBlockWrapper(lifeComplexStore, observer(LifeComplexMajorUsers));
