import { action } from 'mobx';
import * as sppApi from '~/api/sppAgencyApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import { MlsAgency } from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import deepCopy from '~/common/deepCopy';

class SppAgencyStore extends ListStorePrototype<MlsAgency> {
    constructor() {
        super('mls_company_id', 'settings', sppApi);
    }

    @action
    async fetchItem(id: number | string, base?: string | null, withoutCache?: boolean) {
        await super.fetchItem(id, base, withoutCache);
        if (id !== CREATING_ITEM_ID) {
            const { item } = this.getItem(id);
            if (item) {
                this.setEditingItem(id, deepCopy(item));
            }
        }
    }
}

export default new SppAgencyStore();
