import React from 'react';

const MetroLogo = ({ color, size = 17 }: { color: string; size?: number }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={size} height={size}>
        <path
            fill={color}
            fillRule="evenodd"
            d="M11.154 4L8 9.53 4.845 4 1.1 13.466H0v1.428h5.657v-1.428H4.81l.824-2.36L8 15l2.365-3.893.824 2.36h-.85v1.427H16v-1.428h-1.1z"
        />
    </svg>
);

export default MetroLogo;
