import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';

import PriceBlockEditing from '../EditBlocks/PriceBlockEditing';
import PrivateBlockEditing from '../EditBlocks/PrivateBlockEditing';
import PriceBlockInfo from './PriceBlockInfo';
import AgentBonusBlockEditing from '~/components/Items/Estate/EditBlocks/AgentBonusBlockEditing';
import AgentBonusShow from '~/components/Items/Estate/ShowBlocks/AgentBonusShow';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';
import OwnerContactBlock from '../OwnersBlocks/OwnerContactBlock';
import { ESTATE_BASE_EXTERNAL, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import ConfirmTrigger from '~/components/Base/ui/ConfirmTrigger';

import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import Button from '~ui/Button';
import PriceAgentBonusInfo from '~/components/Items/Estate/ShowBlocks/PriceAgentBonusInfo';

@observer
class PriceBlockShow extends Component<EditingWrappedProps> {
    takeEstateToWork = async () => {
        await estateStore.takeEstateToWork(this.props.item_id);
    };

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            major_users,
            ownerName,
            ownerPhones,

            base,
            contact_id,
            source,
            sourceInnerId,

            agentBonusType
        } = item || {};

        return (
            <Card fluid color="green">
                {!editing && (
                    <Fragment>
                        <PriceBlockInfo estate={item} loadingItem={loadingItem} />
                        {agentBonusType && <AgentBonusShow estate={item} loadingItem={loadingItem} />}
                        <Card.Content>
                            {/*{base === ESTATE_BASE_MLS && phone && (*/}
                            {/*    <Fragment>*/}
                            {/*        <Icon name="mobile" />*/}
                            {/*        <a href={`tel:+7${phone}`}>{`+7${phone}`}</a>*/}
                            {/*        {inner_id && <span className="crm-Estate__fieldNowrap">&nbsp;Лот №{inner_id}</span>}*/}
                            {/*    </Fragment>*/}
                            {/*)}*/}
                            {!loadingItem && major_users && major_users.length > 0 && <UsersLinkList usersList={major_users} avatar />}
                            {!loadingItem && major_users && major_users.length === 0 && (
                                <ConfirmTrigger
                                    trigger={
                                        <Button
                                            startIcon={<WbIncandescentOutlinedIcon />}
                                            color="secondary"
                                            fullWidth
                                            variant="outlined"
                                            size="medium"
                                        >
                                            Взять в работу
                                        </Button>
                                    }
                                    content="Вы станете ответсвенным сотрудником по контакту собственника этого объекта"
                                    header="Взять объект (и контакт) в работу?"
                                    confirmButton="Взять"
                                    onConfirm={this.takeEstateToWork}
                                />
                            )}
                            {base === ESTATE_BASE_OWNERS && ownerPhones && (
                                <OwnerContactBlock
                                    contact_id={Number(contact_id)}
                                    ownerName={ownerName}
                                    ownerPhones={ownerPhones}
                                    estate_id={item_id}
                                    source={source}
                                    sourceInnerId={sourceInnerId}
                                />
                            )}
                            {base === ESTATE_BASE_EXTERNAL && <PriceAgentBonusInfo estate_id={item_id} />}
                        </Card.Content>
                    </Fragment>
                )}
                {editing && (
                    <Card.Content>
                        <br />
                        <PriceBlockEditing item_id={item_id} {...item} editing={editing} />
                        <PrivateBlockEditing item_id={item_id} {...item} editing={editing} />
                        <AgentBonusBlockEditing item_id={item_id} {...item} editing={editing} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, PriceBlockShow);
