import fetchApi from '../common/fetchApi';
import deepCopy from '../common/deepCopy';
import { objectToGraphql } from '../common/graphql';
import { Bank, Deal, DealFilter, DealTableBodyType } from '~/types/deals.types';
import { GQMainContactFields } from './contactsApi';
import { GQEstateMainFields } from './estateApi';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const GQDealMainFields = `
    deal_id
    type
    dealType
    parent_deal_id
    
    price
    currency
    
    createTime
    enable
`;

const GQTableFields = `
    ${GQDealMainFields}
    
    estate_id
    estateType
    estateOuterUrl
    estate {
        ${GQEstateMainFields}
    }
    
    usersFees {
        user_id
        user {
            ${GQUserMainFields}
        }
        fee
        currency
    }
    
    status
    releaseTime
    
    exclusiveDeadTime
    depositDeadTime
    
    dealOpenTime
    dealDoneTime
    dealCloseTime
    dealBreakTime
    
    deposit
    depositTime
    documentsCount
    
    isMortgage
    isExclusive
`;

const GQDealFields = `
    ${GQTableFields}   

    sellers_ids
    sellers{
        ${GQMainContactFields}
    }
    buyers_ids
    buyers{
        ${GQMainContactFields}
    }

    description
    

    bank_id
    mortgagePercent
    mortgageDuration
    firstDeposit

    fee
    feeCurrency
    
    major_user_ids

    dealTree {
        ${GQDealMainFields}
    }

    updateTime
`;

export const fetchItem = async (id: number): Promise<Deal> => {
    const graphql = `{
        fetchDeal(deal_id: ${id}) {
            ${GQDealFields}
        } 
      }`;

    const data: { fetchDeal: Deal } = await fetchApi.getGQ(graphql);
    return data.fetchDeal;
};

export const createItem = async (deal: Deal): Promise<number> => {
    const { sellers, buyers, estate, ...dealInput } = deal;

    dealInput.usersFees = deepCopy(dealInput.usersFees);
    for (const userFee of dealInput.usersFees) {
        delete userFee.user;
    }

    const graphql = `mutation { 
          createDeal(dealInput: ${objectToGraphql(dealInput)})
        }`;

    const data: { createDeal: number } = await fetchApi.postGQ(graphql);
    return data.createDeal;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    dealFilter: DealFilter,
    controller?: AbortController
): Promise<{ list: DealTableBodyType[]; count: number }> => {
    const graphql = `{
            fetchDeals(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(dealFilter)}) {
                list {${GQTableFields}}
                count
            } 
          }`;

    const data: { fetchDeals: { list: Array<DealTableBodyType>; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchDeals;
};

export const saveItem = async (deal_id: number, deal: Partial<Deal>): Promise<number> => {
    const { sellers, buyers, estate, usersFees, ...dealRest } = deal;

    const dealInput: Partial<Deal> = { ...dealRest };

    if (usersFees) {
        for (const userFee of usersFees) {
            delete userFee.user;
        }
        dealInput.usersFees = deepCopy(usersFees);
    }

    const graphql = `mutation {
        updateDeal(deal_id: ${deal_id}, dealDiff: ${objectToGraphql(dealInput)})
    }`;

    const data: { updateDeal: number } = await fetchApi.postGQ(graphql);
    return data.updateDeal;
};

export const fetchBanks = async (): Promise<Bank[]> => {
    const graphql = `{
        fetchBanks {
            bank_id
            title
        }    
    }`;

    const data: { fetchBanks: Bank[] } = await fetchApi.getGQ(graphql);
    return data.fetchBanks;
};

export const dealsByContact = async (contact_id: number): Promise<Deal[]> => {
    const graphql = `{
        dealsByContact(contact_id: ${contact_id}) {
            ${GQDealMainFields}
        } 
      }`;

    const data: { dealsByContact: Deal[] } = await fetchApi.getGQ(graphql);
    return data.dealsByContact;
};

export const fetchItemHistory = async (deal_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchDealChangeHistory(deal_id: ${deal_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchDealChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchDealChangeHistory;
};
