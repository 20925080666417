import { observable, action, computed, reaction } from 'mobx';
import * as accessApi from '~/api/accessApi';
import {
    UserModuleAccess,
    Access,
    AccessModes,
    AccessPermission,
    ACCESS_DELETE,
    ACCESS_NONE,
    ACCESS_READ,
    ACCESS_WRITE,
    AccessModeType
} from '~/types/access.types';
import ListStorePrototype, { DropdownType } from './prototypes/ListStore.prototype';
import deepCopy from '../common/deepCopy';

export const ACCESS_MODES_KEYS = [ACCESS_READ, ACCESS_WRITE, ACCESS_DELETE];
export const ACCESS_MODULE_NAME = 'access';

class AccessStore extends ListStorePrototype<Access> {
    constructor() {
        super('access_id', ACCESS_MODULE_NAME, accessApi);

        reaction(
            () => this.loadingList,
            () => {
                this.accessModesDropdown = this.list.map(({ access_id, title }) => ({ key: access_id, value: access_id, text: title }));
            }
        );
    }

    accessModesDropdown: DropdownType[] = [];

    @action
    async fetchItem(id: number, base?: string | null, withoutCache?: boolean) {
        await super.fetchItem(id, null, true);
        const { item } = this.getItem(id);

        if (item) {
            // Убираем лишние модули, если они были удалены
            item.modules = item.modules.filter(({ name }) => {
                return this.accessModes.find(accessMode => accessMode.name === name);
            });

            this.setEditingItem(id, deepCopy(item));
        }
    }

    @action
    async saveItem(access_id: number): Promise<boolean> {
        this.getItem(access_id).loadingItem = true;
        await super.saveItem(access_id);
        this.getItem(access_id).loadingItem = false;
        if (!this.getItem(access_id).errors.length) {
            return true;
        }
        return false;
    }

    accessModes: UserModuleAccess[] = [];

    @observable
    accessModesLoading = true;

    @action
    insertNewModule(access_id: number, name: string) {
        const item = this.getItem(access_id);
        item.editingItem.modules.push({
            name,
            access: ACCESS_MODES_KEYS.reduce((obj, mode) => {
                obj[mode] = ACCESS_NONE;
                return obj;
            }, {} as AccessModeType)
        });
    }

    @action
    changePermission(access_id: number, moduleName: string, name: AccessModes, value: AccessPermission) {
        const { modules } = this.getItem(access_id).editingItem;
        const foundModule = modules.find(module => module.name === moduleName);
        if (foundModule) {
            foundModule[ACCESS_MODULE_NAME][name] = value;
        }
    }

    @action
    async fetchAccessModes() {
        if (!this.accessModes.length) {
            this.accessModes = await accessApi.fetchAccessModes();
            this.accessModesLoading = false;
        }
    }
}

export default new AccessStore();
