import { action } from 'mobx';
import deepCopy from '~/common/deepCopy';
import ItemStorePrototype, { CREATING_ITEM_ID, TItemExtended } from '~/stores/prototypes/ItemStore.prototype';

class ItemEditingPrototype<ItemObjectType extends TItemExtended, ItemParentType = {}> extends ItemStorePrototype<ItemObjectType> {
    @action
    async fetchItem(id: number | string, base?: string | null, withoutCache?: boolean) {
        await super.fetchItem(id, base, withoutCache);
        const { item } = this.getItem(id);
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }

    async createEmptyItem(editingProps: Partial<ItemObjectType>): Promise<number> {
        this.fetchItem(CREATING_ITEM_ID);
        this.setEditingItem(CREATING_ITEM_ID, editingProps);
        return await super.createItem();
    }

    getParent(id: number | string): Partial<ItemParentType> | null {
        return null;
    }
}

export default ItemEditingPrototype;
