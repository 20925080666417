import React, { Fragment } from 'react';
import { Popup } from 'semantic-ui-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ESTATE_PROPERTY_FLATS_ARR, ESTATE_PROPERTY_TYPE_LAND } from '~/types/estate.types';
import MonoString from '../../../Base/MonoString';

type EstateTableAreaBlockProps = {
    propertyType: number;
    totalArea?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    allRoomsArea?: string;
    landArea?: number;
    landAreaUnitType?: number;
    roomsCount: number | null;
};

const EstateTableAreaBlock = ({
    propertyType,
    totalArea,
    livingArea,
    kitchenArea,
    allRoomsArea,
    landArea,
    landAreaUnitType,
    roomsCount
}: EstateTableAreaBlockProps) => {
    const AllAreasTrigger = ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) ? (
        <b>
            <MonoString template="?/?/?" values={[totalArea || '-', livingArea || '-', kitchenArea || '-']} />
        </b>
    ) : (
        <MonoString template="?" values={[propertyType === ESTATE_PROPERTY_TYPE_LAND ? landArea || '-' : totalArea || '-']} />
    );

    const tooManyRooms = roomsCount && roomsCount > 3;

    return (
        <Popup
            trigger={
                <span className="crm-Estate__list_totalArea">
                    {AllAreasTrigger}
                    &nbsp; м²
                    {!tooManyRooms && ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) && (
                        <Fragment>
                            <br />
                            <MonoString template="?" values={[allRoomsArea || '-']} />
                        </Fragment>
                    )}
                </span>
            }
            size="small"
        >
            <List dense disablePadding>
                <ListItem disableGutters>
                    Общая:&nbsp;
                    <MonoString template="?" values={[totalArea || '-']} />
                    &nbsp;м²
                </ListItem>
                <ListItem disableGutters>
                    Жилая:&nbsp;
                    <MonoString template="?" values={[livingArea || '-']} />
                    &nbsp;м²
                </ListItem>
                <ListItem disableGutters>
                    Кухни:&nbsp;
                    <MonoString template="?" values={[kitchenArea || '-']} />
                    &nbsp;м²
                </ListItem>
                <ListItem disableGutters>
                    Площадь комнат:&nbsp;
                    <MonoString template="?" values={[allRoomsArea || '-']} />
                    &nbsp;м²
                </ListItem>
            </List>
        </Popup>
    );
};

export default React.memo(EstateTableAreaBlock);
