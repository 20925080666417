import React, { CSSProperties, ReactNode, SyntheticEvent, Ref } from 'react';
import Progress from './Progress';

import MUButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

type ButtonProps = {
    onClick?: (event: SyntheticEvent) => void;
    className?: string;
    basic?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'default' | null;
    loading?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    style?: CSSProperties;
    fullWidth?: boolean;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    tooltip?: string;
    disableElevation?: boolean;
    primary?: boolean;
    secondary?: boolean;
    disableRipple?: boolean;
    href?: string;
    target?: string;
} & ({ children: ReactNode } | { icon: ReactNode });

const Button = React.forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        onClick,
        className,
        variant,
        color,
        loading,
        disabled,
        size,
        style,
        fullWidth,
        endIcon,
        startIcon,
        tooltip,
        disableElevation,
        primary,
        disableRipple,
        href,
        target,
        secondary
    } = props;

    const mergedProps = {
        onClick,
        className,
        color: primary ? 'primary' : secondary ? 'secondary' : color ?? undefined,
        disabled: loading || disabled,
        style,
        disableRipple,
        target,
        href,
        ref
    };

    const OurButton =
        'icon' in props ? (
            <IconButton size={size === 'large' ? 'medium' : size} {...mergedProps}>
                {props.icon}
                {loading && <Progress show size={24} color="primary" />}
            </IconButton>
        ) : (
            <MUButton
                size={size}
                component="button"
                variant={variant}
                fullWidth={fullWidth}
                endIcon={endIcon}
                startIcon={startIcon}
                disableElevation={disableElevation}
                {...mergedProps}
            >
                {props.children}
                {loading && <Progress show size={24} color="primary" />}
            </MUButton>
        );

    if (tooltip && !disabled) {
        return <Tooltip title={tooltip}>{OurButton}</Tooltip>;
    }

    return OurButton;
});

export default React.memo(Button);
