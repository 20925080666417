import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Card } from 'semantic-ui-react';

import { EstatePropertyShowInfo, printEstateAbout } from './ShowBlocks/EstateInfoBlock';
import estateStore from '~/stores/estateStore';
import Grid from '~ui/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ShadowFragment from '~/components/Base/ShadowFragment';
import { MetrosBlockShow, PrintNewbuilding } from '~/components/Items/Estate/ShowBlocks/AddressBlockShow';
import PhotosBlockShow from '~/components/Items/Estate/ShowBlocks/PhotosBlockShow';
import PriceBlockInfo from '~/components/Items/Estate/ShowBlocks/PriceBlockInfo';
import LoaderAwait from '~/components/Base/LoaderAwait';
import ListError from '~/components/ListError';

const EstatePrintMode = () => {
    const { base, estateIdString }: { base?: string; estateIdString?: string } = useParams();
    const estate_id = Number(estateIdString);

    useMemo(() => {
        estateStore.fetchItem(estate_id, base);
    }, []);

    const { loadingItem, item, errors } = estateStore.getItem(estate_id);

    useMemo(() => {
        if (!loadingItem) {
            setTimeout(() => {
                window.print();
            }, 1000);
        }
    }, [loadingItem]);

    if (errors.length) {
        return <ListError errors={errors} />;
    }
    if (!item) {
        return <LoaderAwait active immediately />;
    }

    const { newbuilding, newbuildingHouse, propertyType, metros } = item;

    return (
        <Container maxWidth="md">
            <Grid alignItems="flex-start" spacing={1}>
                <Grid.Column>
                    <Typography variant="h5">{item && <ShadowFragment shadow={loadingItem} print={printEstateAbout(item)} />}</Typography>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Typography>{item?.address}</Typography>
                    {newbuilding && (
                        <PrintNewbuilding newbuilding={newbuilding} newbuildingHouse={newbuildingHouse} houseIsNeeded={false} />
                    )}
                    <MetrosBlockShow metros={metros} />
                </Grid.Column>
                <Grid.Column width={5}>
                    <Card fluid color="green">
                        <PriceBlockInfo estate={item} loadingItem={loadingItem} printMode />
                    </Card>
                </Grid.Column>

                <Grid.Column>
                    <PhotosBlockShow item_id={estate_id} />
                </Grid.Column>

                <Grid.Column className="crm-Main__notOverflow">
                    <EstatePropertyShowInfo estate_id={estate_id} propertyType={propertyType} />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default observer(EstatePrintMode);
