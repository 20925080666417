import React from 'react';
import { Grid, Image, Icon, Radio, Popup, SemanticCOLORS } from 'semantic-ui-react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import config from '~/config/ui.config';
import LoaderAwait from '~/components/Base/LoaderAwait';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type PhotoSizeType = {
    src: string;
    rotate?: number;
    isPlan?: boolean;
    isLoading?: boolean;
    watermark?: number | null;
};

type SortableHanders = {
    item_id: number;
    handleRadioPlan: (event: React.SyntheticEvent, data: { id: number }) => void;
    handleRotatePhoto: (index: number) => void;
    handleDeletePhoto: (index: number) => void;
    handleNextTrademark: (index: number) => void;
};

type SortablePhotoProps = SortableHanders &
    PhotoSizeType & {
        i: number;
        isLoading: boolean;
    };

type SortableContainerProps = SortableHanders & {
    photoSet: (PhotoSizeType & { tempSrc: string })[];
};

const ImageDragHandle = ({ src, rotate }: { src: string; rotate: number }) => {
    const ImageDragHandleNode = SortableHandle(() => (
        <Image
            title="Перетащить фотографию в другое место"
            src={src}
            bordered
            centered
            verticalAlign="middle"
            className={`crm-Estate__photolist_rotate${rotate || 0}`}
        />
    ));
    return <ImageDragHandleNode />;
};

const ImageDragHandleMemo = React.memo(ImageDragHandle);

const SortablePhotoElement = (props: SortablePhotoProps) => {
    const {
        src,
        rotate,
        isPlan,
        watermark,
        i,
        handleNextTrademark,
        handleRadioPlan,
        handleRotatePhoto,
        handleDeletePhoto,
        item_id,
        isLoading
    } = props;

    const { watermarks } = config.estatePhotos;
    const { title, color } = typeof watermark === 'number' ? watermarks[watermark] : { title: null, color: null };

    return (
        <Grid.Column index={i}>
            <div className="crm-Estate__photolist_box">
                <div className="crm-Estate__photolist_pult">
                    {!isLoading && (
                        <>
                            {typeof isPlan === 'boolean' && (
                                <Radio
                                    name="schema"
                                    value="this"
                                    checked={isPlan === true}
                                    onClick={handleRadioPlan}
                                    id={i + 1}
                                    className="crm-Estate__squareInput"
                                    label={<label title="Пометить (убрать), если эта фография является планировкой квартиры">План</label>}
                                />
                            )}
                            {item_id !== CREATING_ITEM_ID && (
                                <Icon link name="undo" title="Повернуть на 90 градусов" onClick={() => handleRotatePhoto(i)} />
                            )}
                            {typeof watermark === 'number' && (
                                <Popup
                                    size="tiny"
                                    wide
                                    trigger={
                                        <Icon
                                            link
                                            name="trademark"
                                            color={color as SemanticCOLORS}
                                            onClick={() => handleNextTrademark(i)}
                                        />
                                    }
                                >
                                    <span>
                                        Водяной знак: <b>{title}</b>
                                    </span>
                                </Popup>
                            )}
                        </>
                    )}
                    <Icon link name="trash" title="Удалить" onClick={() => handleDeletePhoto(i)} />
                </div>
                {isLoading && <LoaderAwait active size="mini" />}
                <ImageDragHandleMemo rotate={rotate} src={src} />
            </div>
        </Grid.Column>
    );
};

const SortablePhoto = SortableElement(SortablePhotoElement);

const SortablePhotosList = SortableContainer(
    ({ photoSet, handleRadioPlan, handleRotatePhoto, handleDeletePhoto, handleNextTrademark, item_id }: SortableContainerProps) => {
        return (
            <Grid.Row>
                {photoSet.map(({ src, tempSrc, isLoading, isPlan, rotate, watermark }, index) => (
                    <SortablePhoto
                        item_id={item_id}
                        src={isLoading ? tempSrc : src}
                        rotate={rotate}
                        isPlan={isPlan}
                        watermark={watermark}
                        key={src}
                        index={index}
                        i={index}
                        handleRadioPlan={handleRadioPlan}
                        handleRotatePhoto={handleRotatePhoto}
                        handleDeletePhoto={handleDeletePhoto}
                        handleNextTrademark={handleNextTrademark}
                        isLoading={isLoading}
                    />
                ))}
            </Grid.Row>
        );
    }
);

export default SortablePhotosList;
