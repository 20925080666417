import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import config from '~/config/ui.config';
import commonStore from '~/stores/commonStore';

import SortablePhotosList from '~/components/Base/Photos/SortablePhotosList';
import arrayMove from 'array-move';

const LifeComplexPhotosEditing = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const handleRadioPlan = () => {};

    const handleDeletePhoto = (index: number) => {
        let { photos } = lifeComplexStore.getItem(newbuilding_id).editingItem;
        photos = [...photos];
        photos.splice(index, 1);
        lifeComplexStore.setEditingItem(newbuilding_id, { photos });
    };

    const handleRotatePhoto = (id: number) => {
        const { photos } = lifeComplexStore.getItem(newbuilding_id).editingItem;
        const { rotate } = photos[id];

        lifeComplexStore.changeArrayValue(newbuilding_id, 'photos', id, 'rotate', ((rotate || 0) + 270) % 360);
    };

    const handleNextTrademark = (id: number) => {};

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (oldIndex !== newIndex) {
            let { photos } = lifeComplexStore.getItem(newbuilding_id).editingItem;
            photos = arrayMove(photos, oldIndex, newIndex);
            lifeComplexStore.setEditingItem(newbuilding_id, { photos });
        }
    };

    const { photos } = lifeComplexStore.getItem(newbuilding_id).editingItem;

    const {
        estatePhotos: { estatePhotoUrl, sizes, estateTempPhotoUrl }
    } = config;

    const photoSet = photos.map(({ filename, isLoading, originalname, rotate }) => {
        return {
            rotate,
            isPlan: null,
            watermark: null,
            isLoading,
            ...(originalname || isLoading
                ? {
                      src: estateTempPhotoUrl + filename + `?${rotate || 0}`,
                      tempSrc: estateTempPhotoUrl + filename
                  }
                : {
                      src: estatePhotoUrl + sizes[2].dir + '/' + sizes[2].prefix + filename,
                      tempSrc: estateTempPhotoUrl + filename,
                      srcSet: sizes.map(({ dir, prefix, width }) => estatePhotoUrl + dir + '/' + prefix + filename + ` ` + width + 'w')
                  })
        };
    });

    return (
        <Fragment>
            {photoSet && photoSet.length > 0 && (
                <Grid columns={commonStore.isMobile ? 2 : newbuilding_id === CREATING_ITEM_ID ? 5 : 3} className="crm-Estate__photolist">
                    <SortablePhotosList
                        item_id={newbuilding_id}
                        photoSet={photoSet}
                        onSortEnd={onSortEnd}
                        handleRadioPlan={handleRadioPlan}
                        handleRotatePhoto={handleRotatePhoto}
                        handleDeletePhoto={handleDeletePhoto}
                        useDragHandle
                        handleNextTrademark={handleNextTrademark}
                        axis="xy"
                    />
                </Grid>
            )}
        </Fragment>
    );
};

export default observer(LifeComplexPhotosEditing);
