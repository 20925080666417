export function throttle(this: any, func: Function, wait: number) {
    let timeout: NodeJS.Timer | number | null = null;
    let callbackArgs: IArguments | null = null;
    // eslint-disable-next-line
    const context = this;

    const later = () => {
        func.apply(context, callbackArgs);
        timeout = null;
    };

    return function () {
        if (!timeout) {
            // eslint-disable-next-line
            callbackArgs = arguments;
            timeout = setTimeout(later, wait);
        }
    };
}
