import React, { ReactNode } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';

const usePultStyles = makeStyles(({ spacing }) => ({
    pult: {
        display: 'flex',
        alignItems: 'center',
        margin: spacing(0, 0, 0.5),
        '& > *': {
            marginRight: '0.375rem'
        },
        '& > *:not(:first-child)': {
            marginLeft: '0.375rem'
        }
    }
}));

export const Pult = ({ children }: { children: ReactNode }) => {
    const classes = usePultStyles();
    return <div className={classes.pult}>{children}</div>;
};

export const StyledToggleButtonGroup = withStyles(({ spacing, shape }) => ({
    grouped: {
        margin: spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: shape.borderRadius
        },
        '&:first-child': {
            borderRadius: shape.borderRadius
        }
    }
}))(ToggleButtonGroup);

export const ToggleButtonPrimary = withStyles(({ palette, shadows }) => ({
    selected: {
        backgroundColor: `${palette.primary.main} !important`,
        boxShadow: shadows[5],
        color: '#fff !important'
    }
}))(ToggleButton);

const useDividerStyles = makeStyles(({ spacing }) => ({
    divider: {
        margin: spacing(1, 0.25)
    }
}));

export const PultDivider = () => {
    const classes = useDividerStyles();
    return <Divider flexItem orientation="vertical" className={classes.divider} />;
};

const useStylesGrow = makeStyles(({ spacing }) => ({
    grow: {
        flexGrow: 1
    }
}));

export const PultGrow = () => {
    const classes = useStylesGrow();
    return <div className={classes.grow} />;
};
