import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import contactStore from '~/stores/contactStore';
import authStore from '~/stores/authStore';

import Table, { TableHead, TableCell, TableRow } from '~ui/Table';
import { WarningMessage } from '~ui/Message';

import CellCallTableBody from '~/components/Lists/CellCalls/CellCallTableBody';
import ListFooter from '~/components/Lists/Common/ListFooterNew';
import CallEvents from '~/components/CalendarEvents/CallEventModal';
import callEventStore from '~/stores/cell/callEventStore';
import LoaderAwait from '~/components/Base/LoaderAwait';

type ContactCallsModeProps = {
    contact_id: number;
};

@observer
class ContactCallsMode extends Component<ContactCallsModeProps> {
    constructor(props: ContactCallsModeProps) {
        super(props);
        contactStore.cellCallsByContact(props.contact_id);
    }

    handlePageChange = (activePage: number) => {
        contactStore.changeCellCallsPaginationPage(this.props.contact_id, activePage);
    };

    handlePageSizeChange = (pageSize: number) => {
        contactStore.changeCellCallsPaginationPageSize(this.props.contact_id, pageSize);
    };

    render() {
        const { contact_id } = this.props;
        const { cellCalls, cellCallsLoading, cellCallsPagination, cellCallsCount } = contactStore.getItem(contact_id).property;
        const isCallEventAvailable = authStore.canCreate(callEventStore.moduleName);

        return (
            <Grid reversed="mobile" stackable>
                <Grid.Column width={isCallEventAvailable ? 10 : 16}>
                    {cellCalls && cellCalls.length > 0 && (
                        <Table size="small" compact>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата</TableCell>
                                    <TableCell />
                                    <TableCell>Сотрудник</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <CellCallTableBody hideClient hideOperations cellCallList={cellCalls} />

                            <ListFooter
                                loading={cellCallsLoading}
                                colSpan={3}
                                pagination={cellCallsPagination}
                                listCount={cellCallsCount || 0}
                                pageChange={this.handlePageChange}
                                pageSizeChange={this.handlePageSizeChange}
                                hideGoToPage
                            />
                        </Table>
                    )}
                    {!cellCallsLoading && cellCalls && cellCalls.length === 0 && <WarningMessage>Звонки не найдены</WarningMessage>}

                    <LoaderAwait active={cellCallsLoading} size="large" />
                </Grid.Column>

                {isCallEventAvailable && (
                    <Grid.Column width={6}>
                        <CallEvents contact_id={contact_id} />
                    </Grid.Column>
                )}
            </Grid>
        );
    }
}

export default ContactCallsMode;
