import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';

type OfficeDetailsBlockEditingProps = HandlerEditingWrappedProps & {
    parkingLocationType?: number | null;
    parkingPurposeType?: number | null;
    parkingType?: number | null;
    parkingPlacesCount?: number | null;
    parkingPriceEntry?: number | null;
    parkingCurrency?: number;
    parkingIsFree?: boolean | null;
};

@observer
class ParkingBlockEditing extends Component<OfficeDetailsBlockEditingProps> {
    constructor(props: OfficeDetailsBlockEditingProps) {
        super(props);

        const estate = {
            parkingLocationType: props.parkingLocationType || null,
            parkingPurposeType: props.parkingPurposeType || null,
            parkingPlacesCount: props.parkingPlacesCount || null,
            parkingPriceEntry: props.parkingPriceEntry || null,
            parkingCurrency: props.parkingCurrency || 1,
            parkingIsFree: props.parkingIsFree || null
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, handleCheckbox, item_id } = this.props;
        const { parkingLocationType, parkingPurposeType, parkingPlacesCount, parkingPriceEntry, parkingCurrency, parkingIsFree } =
            estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.ButtonGroup
                    label="Расположение парковки"
                    buttonSet={estateL10n.PARKING_LOCATION_TYPE}
                    name="parkingLocationType"
                    value={parkingLocationType}
                    handleChange={handleChange}
                />

                <EditField.ButtonGroup
                    label="Назначение парковки"
                    buttonSet={estateL10n.PARKING_PURPOSE_TYPE}
                    name="parkingPurposeType"
                    value={parkingPurposeType}
                    handleChange={handleChange}
                />

                <EditField.InputNumber
                    label="Количество мест"
                    onChange={handleChange}
                    min={1}
                    max={999}
                    value={parkingPlacesCount}
                    name="parkingPlacesCount"
                />

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Стоимость въезда</label>
                        <Input
                            type="number"
                            min={0}
                            size="mini"
                            label={
                                <Dropdown
                                    value={parkingCurrency}
                                    name="parkingCurrency"
                                    onChange={handleChange}
                                    options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                />
                            }
                            labelPosition="right"
                            placeholder="Цена"
                            value={parkingPriceEntry || ''}
                            onChange={handleChange}
                            name="parkingPriceEntry"
                            className="crm-Estate__field_rightMargin"
                            disabled={parkingIsFree}
                        />
                        в месяц&nbsp;&nbsp;
                        <Checkbox
                            checked={parkingIsFree}
                            name="parkingIsFree"
                            toggle
                            label={<label>Бесплатная</label>}
                            onClick={handleCheckbox}
                        />
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, ParkingBlockEditing);
