import { observable, action, runInAction, computed } from 'mobx';
import * as exportingBaseFeedApi from '~/api/export/exportingBaseFeedApi';

import ItemListsStorePrototype from '../prototypes/ItemListsStore.prototype';
import { ExportingBaseFeed } from '~/types/exports.types';

class ExportingBaseFeedStore extends ItemListsStorePrototype<ExportingBaseFeed, number, {}, { export_base_id: number }> {
    adjustItemToId(feed: ExportingBaseFeed): number {
        return feed.feed_id;
    }

    async createFeedInBase(export_base_id: number, feed: Partial<ExportingBaseFeed>): Promise<number> {
        let itemList = this.getItemList(export_base_id);
        itemList.updatingItem = { loading: true, errors: [] };
        try {
            return await exportingBaseFeedApi.createFeedInBase(export_base_id, feed);
        } catch (errors) {
            itemList.updatingItem.errors = errors;
            return 0;
        } finally {
            itemList.updatingItem.loading = false;
        }
    }
}

export default new ExportingBaseFeedStore('feed_id', exportingBaseFeedApi);
