import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Icon, Card, Header } from 'semantic-ui-react';

import Comments from '../Common/Comments/index';

import LabelArchive from '../Common/LabelArchive';
import requestStore from '~/stores/requestStore';
import { printRequestType } from './EditBlocks/RequestTypeEditing';
import ShadowFragment from '../../Base/ShadowFragment';

import RequestEstateShow from './ShowBlocks/RequestEstateShow';
import RequestFilterShow from './ShowBlocks/RequestFilterShow';
import RequestHuntingShow from './ShowBlocks/RequestHuntingShow';
import RequestMainBlockShow from './ShowBlocks/RequestMainBlockShow';
import {
    REQUEST_TYPE_BUY,
    REQUEST_TYPE_HIRE,
    REQUEST_TYPE_HUNTING,
    REQUEST_TYPE_MORTGAGE,
    REQUEST_TYPE_NEWBUILDING,
    REQUEST_TYPE_RENT,
    REQUEST_TYPE_SELL
} from '~/types/requests.types';
import LoaderAwait from '../../Base/LoaderAwait';
import TagsList from '../Contact/ShowBlocks/SubBlocks/TagsList';

type RequestShowModeProps = {
    request_id: number;
};

@observer
class RequestShowMode extends Component<RequestShowModeProps> {
    render() {
        const { request_id } = this.props;
        const { item, loadingItem } = requestStore.getItem(request_id);
        const { type, propertyType, enable, requestJson, tags_ids } = item || {};

        const shortRequest = [REQUEST_TYPE_HUNTING, REQUEST_TYPE_MORTGAGE, REQUEST_TYPE_NEWBUILDING].includes(type);

        return (
            <Grid stackable>
                <Grid.Column width={8}>
                    {!loadingItem && !enable && <LabelArchive />}

                    <h3>
                        <Icon name="wordpress forms" />
                        <ShadowFragment
                            shadow={loadingItem}
                            print={<span>Заявка на {!loadingItem && printRequestType(type, propertyType)}</span>}
                        />
                    </h3>

                    {(type === REQUEST_TYPE_SELL || type === REQUEST_TYPE_RENT) && <RequestEstateShow item_id={request_id} />}
                    {(type === REQUEST_TYPE_BUY || type === REQUEST_TYPE_HIRE) && <RequestFilterShow item_id={request_id} />}
                    {shortRequest && <RequestHuntingShow item_id={request_id} />}

                    <LoaderAwait active={loadingItem} dimmer />
                </Grid.Column>

                <Grid.Column width={8}>
                    <RequestMainBlockShow item_id={request_id} />
                    {tags_ids && (
                        <Card fluid color="yellow">
                            <Card.Content>
                                <Header size="tiny">
                                    <Icon name="tags" color="grey" />
                                    Тэги
                                </Header>
                                <TagsList tags_ids={tags_ids} />
                            </Card.Content>
                        </Card>
                    )}

                    {!loadingItem && <Comments item_id={request_id} item_type="REQUEST" />}
                </Grid.Column>
            </Grid>
        );
    }
}

export default RequestShowMode;
