import React, { CSSProperties, ReactNode } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Dropdown } from 'semantic-ui-react';

import { HandleChangeType } from '../Items/HandlerEditingBlockWrapper';
import { arrayToDropdown } from '~/common/localizations';
import { StyledToggleButtonGroup } from '~ui/FilterPult';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';

const toggleStyle = ({ palette, spacing }: Theme): Record<string, object> => ({
    root: {
        color: palette.text.secondary,
        textTransform: 'initial',
        padding: spacing(1, 2),
        background: palette.background.default,
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    sizeSmall: {
        padding: spacing(0.7, 1.5)
    },
    label: {
        whiteSpace: 'nowrap'
    }
});

export const TogglePrimary = withStyles(theme => ({
    ...toggleStyle(theme),
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#fff !important'
    }
}))(ToggleButton);

export const ToggleCustom = withStyles(theme => ({
    ...toggleStyle(theme),
    selected: {}
}))(ToggleButton);

type TMixed = string | number | boolean;

export type ButtonInSet =
    | [TMixed]
    | [TMixed, ReactNode]
    | [TMixed, ReactNode, boolean | null]
    | [TMixed, ReactNode, boolean | null, CSSProperties];

export type ButtonGroupType = {
    name?: string | number;
    value: TMixed | null;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
} & ({ buttonSet: ButtonInSet[] } | { options: DropdownType[] }) &
    ({ stackable?: boolean; handleChange: HandleChangeType } | { onChange: (value: TMixed) => void });

const ButtonGroup = (props: ButtonGroupType) => {
    const { name, value, size, disabled } = props;

    let buttonSet: ButtonInSet[];
    if ('buttonSet' in props) {
        buttonSet = props.buttonSet;
    } else {
        buttonSet = props.options.map(({ value, text, key }) => [value || key, text]);
    }

    return 'stackable' in props && props.stackable && 'handleChange' in props ? (
        <Dropdown
            disabled={disabled}
            selection
            options={arrayToDropdown(buttonSet)}
            name={name}
            value={value}
            onChange={props.handleChange}
        />
    ) : (
        <StyledToggleButtonGroup
            exclusive
            value={value}
            size={size || 'small'}
            onChange={(event, value) =>
                'handleChange' in props ? props.handleChange(event, { name: String(name), value }) : props.onChange(value)
            }
        >
            {buttonSet.map(param => {
                const style = param[3] && value === param[0] ? param[3] : null;

                const props = {
                    disabled: disabled || Boolean(param[2]),
                    key: `btn_${String(param[0])}`,
                    value: param[0],
                    style,
                    children: param[1] || param[0]
                };

                return style ? <ToggleCustom {...props} /> : <TogglePrimary {...props} />;
            })}
        </StyledToggleButtonGroup>
    );
};

export default React.memo(ButtonGroup);
