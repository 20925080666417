import React, { useState } from 'react';

import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Snackbar from '@material-ui/core/Snackbar';
import UserChatHeader from '~/components/Chat/ChatHeader/UserChatHeader';

import TextInput from '~ui/TextInput';
import Button from '~ui/Button';
import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';
import * as chatApi from '~/api/chatApi';
import ListError from '~/components/ListError';
import { SuccessMessage } from '~ui/Message';
import authStore from '~/stores/authStore';

const requestMessage = (comment: string, title: string): string => {
    const message = `${title} ${document.location.href}`;
    return message + (comment ? ` \n\n<b>Комментарий:</b> \n ${comment}` : '');
};

const EstateExportRequestModal = ({ btnTitle }: { btnTitle: string }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const [errors, setErrors] = useState<string[]>([]);

    const [message, setMessage] = useState('');
    const handleEditMessage = (event, { value }: { value: string }) => {
        setMessage(value);
    };

    const [loading, setLoading] = useState(false);

    const [showInfo, setShowInfo] = useState(false);
    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    const sendRequest = async () => {
        setLoading(true);
        try {
            const chat_id = await chatApi.fetchUserChatId(export_moderator_id);
            await chatApi.sendPrivateMessage(chat_id, requestMessage(message, btnTitle));
            toggleInfo();
            toggleModal();
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    const ConfirmActions: ModalBtnAction[] = [
        { onClick: toggleModal, label: 'Отмена', variant: 'text', color: 'primary', disabled: loading },
        { onClick: sendRequest, label: 'Отправить', variant: 'outlined', color: 'primary', loading }
    ];

    const export_moderator_id = authStore.getModeratorId();

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                disabled={showInfo}
                onClick={toggleModal}
                startIcon={<HeadsetMicIcon />}
                loading={loading}
            >
                {btnTitle}
            </Button>
            <Snackbar open={showInfo} autoHideDuration={3000} onClose={toggleInfo} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <SuccessMessage variant="filled">Ваш запрос на включение (изменение) экспорта объекта отправлен</SuccessMessage>
            </Snackbar>
            {showModal && (
                <Modal
                    onClose={toggleModal}
                    maxWidth="sm"
                    actions={ConfirmActions}
                    header="Отправить запрос модератору на включение (изменение) экспорта"
                >
                    <ModalContent>
                        <UserChatHeader item_id={export_moderator_id} />

                        <TextInput
                            fullWidth
                            label="Комментарий для модератора"
                            multiline
                            rowsMax={4}
                            rows={1}
                            margin="normal"
                            value={message}
                            onChange={handleEditMessage}
                        />

                        <ListError errors={errors} />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default EstateExportRequestModal;
