import React from 'react';

import { TableCell, TableRow } from '~ui/Table';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonTable = ({ rows, columns }: { rows: number; columns: number }) => {
    const rowsArr = new Array(rows).fill(0);
    const columnsArr = new Array(columns).fill(0);

    const Columns = columnsArr.map((key, indexCol) => (
        <TableCell key={indexCol}>
            <Skeleton animation="wave" />
        </TableCell>
    ));

    return (
        <>
            {rowsArr.map((key, indexRow) => (
                <TableRow key={indexRow}>{Columns}</TableRow>
            ))}
        </>
    );
};

export default React.memo(SkeletonTable);
