import React, { ChangeEvent, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { ESTATE_BASE_EXTERNAL, ESTATE_BASE_MLS, ESTATE_BASE_OWNERS } from '~/types/estate.types';

import Grid from '@material-ui/core/Grid';

import estateStore from '~/stores/estateStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import settingStore from '~/stores/settingStore';

import InfoPopup from '~/components/Base/InfoPopup';

import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import FilterCell from '~/components/Lists/Common/FilterCell';
import Checkbox, { Switch } from '~ui/Checkbox';
import UsersAndGroupsDropdown from '~/components/Lists/Common/UsersAndGroupsDropdown';

export const DEFAULT_NO_CALLS_TIME = 7 * 86400;

export const deltaNoCallsTimes = [
    { key: 0, value: 0, text: 'Все время' },
    { key: 1, value: 3 * 3600, text: '3 часа' },
    { key: 1, value: 12 * 3600, text: '12 часов' },
    { key: 1, value: 86400, text: '1 день' },
    { key: 1, value: DEFAULT_NO_CALLS_TIME, text: '7 дней' },
    { key: 2, value: 10 * 86400, text: '10 дней' },
    { key: 3, value: 14 * 86400, text: '14 дней' },
    { key: 4, value: 30 * 86400, text: '30 дней' }
];

const FilterEstateOtherParams = (props: FilterMoreDetailsProps) => {
    const handleNoCalls = (event: ChangeEvent<HTMLInputElement>) => {
        const { type, name, checked } = event.target;
        const oldValue = estateStore.listFilter[name];
        // @ts-ignore
        estateStore.changeFilter(name, oldValue === null ? DEFAULT_NO_CALLS_TIME : null);
    };

    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const { type, name, checked } = event.target;
        // @ts-ignore
        props.handleChange(event, { type, name, value: checked });
    };

    const {
        showExportedOnly,
        fakesAvailable,
        showExportedBases,
        showExportedTop3,
        showExportMajorUser,
        showNoExportedOnly,
        byCreateTime,
        noAdsCallsTime,
        noOutgoingToOwnerCallsTime,
        base,
        showAvailableOnly,
        showExportingErrorsOnly,
        enable,
        isAlreadySold,
        hasBonusToAgent
    } = estateStore.listFilter;

    const { loadingList, exportingBasesDropdown } = exportingBaseStore;
    const { handleChange } = props;

    return (
        <Fragment>
            <FilterCell title="Сотрудник(и)">
                <Grid item xs={6}>
                    <UsersAndGroupsDropdown store={estateStore} />
                </Grid>
            </FilterCell>

            {![ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL].includes(base) && (
                <FilterCell title="МЛС" titleFullWidth spacing={0}>
                    <Grid item xs={12}>
                        <Switch checked={hasBonusToAgent} name="hasBonusToAgent" label="Есть бонус агенту" onChange={handleCheckbox} />
                    </Grid>
                </FilterCell>
            )}

            {![ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL, ESTATE_BASE_MLS].includes(base) && (
                <FilterCell title="Звонки" titleFullWidth spacing={0}>
                    <Grid item xs={12}>
                        <Switch
                            checked={noOutgoingToOwnerCallsTime !== null}
                            name="noOutgoingToOwnerCallsTime"
                            label="Нет звонков (сообщений) Продавцу"
                            onChange={handleNoCalls}
                        />
                        {typeof noOutgoingToOwnerCallsTime === 'number' && (
                            <div className="crm-Estate__filter_exportedBases">
                                за &nbsp;
                                <Dropdown
                                    options={deltaNoCallsTimes}
                                    name="noOutgoingToOwnerCallsTime"
                                    value={noOutgoingToOwnerCallsTime}
                                    onChange={handleChange}
                                    closeOnChange
                                    upward
                                    inline
                                />
                            </div>
                        )}
                        <InfoPopup
                            size="small"
                            content="Учитываются состоявшиеся входящие или исходящие звонки, сообщения в WhatsApp на/c номер(а) телефона контакта, который указан в карточке объекта"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Switch
                            checked={noAdsCallsTime !== null}
                            name="noAdsCallsTime"
                            label="Нет входящих с рекламы"
                            onChange={handleNoCalls}
                        />
                        {typeof noAdsCallsTime === 'number' && (
                            <div className="crm-Estate__filter_exportedBases">
                                за &nbsp;
                                <Dropdown
                                    options={deltaNoCallsTimes}
                                    name="noAdsCallsTime"
                                    value={noAdsCallsTime}
                                    onChange={handleChange}
                                    closeOnChange
                                    upward
                                    inline
                                />
                            </div>
                        )}
                        <InfoPopup size="small" content="Не прикрепленных входящие звонков по объекту" />
                    </Grid>
                </FilterCell>
            )}

            {![ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL, ESTATE_BASE_MLS].includes(base) && (
                <>
                    <FilterCell title="Экспорт" titleFullWidth>
                        <Grid item xs={12}>
                            <Switch
                                checked={showExportedOnly}
                                name="showExportedOnly"
                                label="Экспортируемые"
                                onChange={handleCheckbox}
                                disabled={showNoExportedOnly}
                            />
                        </Grid>
                        {!showNoExportedOnly && showExportedOnly && (
                            <>
                                <Grid item xs={12}>
                                    <Dropdown
                                        options={exportingBasesDropdown}
                                        name="showExportedBases"
                                        value={showExportedBases}
                                        onChange={handleChange}
                                        multiple
                                        placeholder="Базы"
                                        closeOnChange
                                        selection
                                        loading={loadingList}
                                        upward
                                        disabled={Boolean(showExportedTop3)}
                                        className="crm-List__filter_dropdownShrink"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox
                                        name="showExportedTop3"
                                        checked={Boolean(showExportedTop3)}
                                        label="Топ-3"
                                        onChange={handleCheckbox}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox
                                        checked={showExportingErrorsOnly}
                                        name="showExportingErrorsOnly"
                                        label="С ошибками"
                                        onChange={handleCheckbox}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item sm={6} xs={12}>
                            <Checkbox
                                checked={showNoExportedOnly}
                                name="showNoExportedOnly"
                                label="Не экспортируемые"
                                onChange={handleCheckbox}
                            />
                        </Grid>
                        {settingStore.mainConfig.enableFakeEstate && (
                            <Grid item xs={6}>
                                <Checkbox checked={fakesAvailable} name="fakesAvailable" label="Фейковые" onChange={handleCheckbox} />
                            </Grid>
                        )}
                    </FilterCell>
                    <FilterCell spacing={0} title="Показать" titleFullWidth>
                        {!enable && (
                            <Grid item xs={12}>
                                <Switch
                                    checked={Boolean(isAlreadySold)}
                                    name="isAlreadySold"
                                    label="Только проданные"
                                    onChange={handleCheckbox}
                                />
                            </Grid>
                        )}
                        <Grid item sm={6} xs={12}>
                            <Switch checked={byCreateTime} name="byCreateTime" label="По дате создания" onChange={handleCheckbox} />
                            <InfoPopup size="small" content="В таблице будет использоваться дата создания, а не изменения" />
                        </Grid>
                        <Grid item xs={12}>
                            <Switch
                                checked={showExportMajorUser}
                                name="showExportMajorUser"
                                label="На кого установлен экспорт"
                                onChange={handleCheckbox}
                            />
                            <InfoPopup
                                size="small"
                                content="В таблице в контактах будет выводиться сотрудник, на кого установлен экспорт"
                            />
                        </Grid>
                    </FilterCell>
                </>
            )}

            {base === ESTATE_BASE_OWNERS && (
                <FilterCell title="Собственники" titleFullWidth>
                    <Grid item xs={12}>
                        <Switch
                            checked={Boolean(showAvailableOnly)}
                            name="showAvailableOnly"
                            label="Только свободные"
                            onChange={handleCheckbox}
                        />
                    </Grid>
                </FilterCell>
            )}
        </Fragment>
    );
};

export default observer(FilterEstateOtherParams);
