import React, { PureComponent, Fragment } from 'react';
import { ReactNode } from 'react';
import './less/HiddenBlock.less';

type HiddenBlockProps = {
    children: ReactNode;
    defaultOpen?: boolean;
    label?: string;
    labelOpen?: string;
    labelHide?: string;
    onToggle?: () => void;
};

type HiddenBlockState = {
    isOpen: boolean;
};

class HiddenBlock extends PureComponent<HiddenBlockProps, HiddenBlockState> {
    constructor(props: HiddenBlockProps) {
        super(props);

        this.state = {
            isOpen: Boolean(props.defaultOpen)
        };
    }

    handleToggleOpen = () => {
        this.setState({ isOpen: !this.state.isOpen });

        if (typeof this.props.onToggle === 'function') {
            this.props.onToggle();
        }
    };

    render() {
        const { labelOpen, labelHide, label, children } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="crm-HiddenBlock">
                <div className="crm-HiddenBlock__header">
                    <button
                        className="crm-HiddenBlock__toggle"
                        title={isOpen ? 'Закрыть блок' : 'Открыть блок'}
                        onClick={this.handleToggleOpen}
                    >
                        <span className="crm-HiddenBlock__toggle_title">
                            {isOpen ? String(labelHide || label) : String(labelOpen || label)}
                        </span>
                        <span className="crm-HiddenBlock__toggle_triangle">{isOpen ? '▲' : '▼'}</span>
                    </button>
                </div>
                <div className={`crm-HiddenBlock__box ${isOpen ? '' : 'crm-Main__hidden'}`}>{children}</div>
            </div>
        );
    }
}

export default HiddenBlock;
