import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { COMMENT_TYPE, CommentType } from '~/types/comments.types';
import commentStore from '~/stores/commentStore';
import isEqual from '../../../../common/isEqual';

import CommentSpeedDial from './CommentSpeedDial';
import CommentsSheet from './CommentsSheet';
import commonStore from '~/stores/commonStore';

export type AdditionalComments = {
    item_id: number;
    item_type: COMMENT_TYPE;
    title: string;
};

type CommentsProps = {
    item_id: number;
    item_type: COMMENT_TYPE;
    multiTypes?: AdditionalComments[];
    onAddComment?: (comment: CommentType[]) => void;
    minimal?: boolean;
};

type CommentsState = {
    item_id: number;
    item_type: COMMENT_TYPE;
    multiTypes: AdditionalComments[];
};

class Comments extends Component<CommentsProps, CommentsState> {
    updateInt: number;

    constructor(props: CommentsProps) {
        super(props);

        this.state = {
            item_id: 0,
            item_type: props.item_type,
            multiTypes: []
        };

        this.updateInt = window.setInterval(() => {
            commentStore.updateComments(this.state.item_type, this.state.item_id);
        }, 2500);
    }

    static getDerivedStateFromProps(props: CommentsProps, state: CommentsState) {
        const { item_id, item_type, multiTypes } = props;

        if (item_id !== state.item_id || item_type !== state.item_type) {
            commentStore.fetchComments(item_type, item_id);
        }

        if (!isEqual(multiTypes, state.multiTypes)) {
            multiTypes && multiTypes.forEach(({ item_id, item_type }) => commentStore.fetchComments(item_type, item_id));
        }

        return {
            item_id,
            item_type,
            multiTypes: multiTypes || []
        };
    }

    componentWillUnmount() {
        clearInterval(this.updateInt);
    }

    render() {
        return commonStore.isMobile ? <CommentSpeedDial {...this.props} /> : <CommentsSheet {...this.props} />;
    }
}

export default observer(Comments);
