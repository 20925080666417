import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { List, Header, Button, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import estateStore from '~/stores/estateStore';
import { Contact } from '~/types/contacts.types';

import { UserLinkType } from '~/types/users.types';
import { EVENT_TYPE_CALL } from '~/types/events.types';
import settingStore from '~/stores/settingStore';
import exportStore from '~/stores/export/exportStore';

import EventModal from '../../../CalendarEvents/EventModal';

import UsersLinkList from '../../../Lists/Users/UsersLinkList';
import ExpiredCallModal from '../SubBlocks/ExpiredCallModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import authStore from '~/stores/authStore';
import expiredCallStore from '~/stores/cell/expiredCallStore';
import ContactLinkWithPreview from '../../../Lists/Contacts/ContactLinkWithPreview';
import { EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING, matchExpiredCallStatus } from '~/api/cell/expiredCallsApi';
import Message, { MessageTitle } from '~ui/Message';

type ExpiredCallMessageProps = {
    item_id: number;
};

type ExpiredCallMessageState = {
    showExpiredCallModal: boolean;
    showHelpModal: boolean;
    showCallEventModal: boolean;
};

const MessageExpire = ({
    basesCount,
    major_users,
    contact,
    handleHelpModal,
    expireStatus
}: {
    basesCount: number;
    major_users: UserLinkType[] | null;
    contact: Contact;
    handleHelpModal: () => void;
    expireStatus: 3 | 2 | 1;
}) => {
    const config = settingStore.mainConfig;
    const { lastOutgoingCallTimesWarning, lastOutgoingCallTimesBan } = config;

    return (
        <Message severity={expireStatus === EXPIRED_CALL_STATUS_WARNING ? 'warning' : basesCount === 0 ? 'warning' : 'error'}>
            <MessageTitle>
                Нет звонков (сообщений) Продавцу (Посреднику)
                <Button
                    onClick={handleHelpModal}
                    floated="right"
                    size="mini"
                    basic
                    icon="question circle"
                    content="Подсказка"
                    color="red"
                    compact
                />
            </MessageTitle>
            Не найдено звонков или сообщений в WhatsApp между ответственным агентом
            {major_users && <UsersLinkList usersList={major_users} />} и продавцом объекта&nbsp;
            {contact && <ContactLinkWithPreview contact={contact} />} за последние{' '}
            {expireStatus === EXPIRED_CALL_STATUS_WARNING ? lastOutgoingCallTimesWarning : lastOutgoingCallTimesBan} дней.&nbsp;
            {basesCount > 0 && <Fragment>Скоро экспорт будет недоступен.</Fragment>}
            {expireStatus === EXPIRED_CALL_STATUS_WARNING && basesCount === 0 && <Fragment>Экспорт включить невозможно.</Fragment>}
        </Message>
    );
};

@observer
class ExpiredCallMessage extends Component<ExpiredCallMessageProps, ExpiredCallMessageState> {
    state = {
        showExpiredCallModal: false,
        showHelpModal: false,
        showCallEventModal: false
    };

    handleShowExpiredCallModal = () => {
        this.setState({ showExpiredCallModal: !this.state.showExpiredCallModal });
    };

    handleToggleHelpModal = () => {
        this.setState({ showHelpModal: !this.state.showHelpModal });
    };

    handleToggleCallEventModal = () => {
        this.setState({ showHelpModal: false, showCallEventModal: !this.state.showCallEventModal });
    };

    render() {
        const { item_id } = this.props;
        const { showExpiredCallModal, showHelpModal, showCallEventModal } = this.state;

        const { major_user_ids, lastOutgoingCallToOwnerTime, contact_id, isFake } = estateStore.getItem(item_id).item || {};
        const { contact } = estateStore.getItem(item_id).property;

        const { item } = exportStore.getItem(item_id);

        let major_users = null;
        let bases = [];

        if (item) {
            major_users = item.major_users;
            bases = item.bases;
        }

        const expireStatus = matchExpiredCallStatus(lastOutgoingCallToOwnerTime || 0, isFake);
        if (![EXPIRED_CALL_STATUS_ERROR, EXPIRED_CALL_STATUS_WARNING].includes(expireStatus)) {
            return null;
        }

        return (
            <Fragment>
                <MessageExpire
                    basesCount={bases.length}
                    contact={contact}
                    major_users={major_users}
                    handleHelpModal={this.handleToggleHelpModal}
                    expireStatus={expireStatus}
                />

                {authStore.canEdit(expiredCallStore.moduleName, major_user_ids) && (
                    <Fragment>
                        <Button size="small" color="red" onClick={this.handleShowExpiredCallModal} content="Сбросить ограничение" />
                        <br />
                        <br />
                    </Fragment>
                )}

                {showExpiredCallModal && (
                    <ExpiredCallModal item_id={CREATING_ITEM_ID} estate_id={item_id} handleClose={this.handleShowExpiredCallModal} />
                )}

                <Modal open={showHelpModal} closeIcon dimmer="inverted" onClose={this.handleToggleHelpModal}>
                    <Modal.Header>Нет подходящих тем для звонка?</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>Возможные темы разговора с Продавцом (Посредником)</Header>

                            <List bulleted>
                                <List.Item>
                                    Попробуйте подыскать подходящих покупателей по&nbsp;
                                    <Link
                                        title="Открыть вкладку с потенциальными Покупателями"
                                        to={{
                                            pathname: `/estate/sell/crm/${item_id}/wishes`,
                                            state: { modal: true, switchFromTab: true }
                                        }}
                                    >
                                        базе Покупателей
                                    </Link>
                                    &nbsp; и договориться о показе. С предложением потенциального показа Продавцу (Посреднику) звонить лучше
                                    всего.
                                </List.Item>
                                <List.Item>
                                    Если вы считаете, что цена данного объекта завышена, вы можете позвонить Продавцу (Посреднику) и:
                                    <List.List>
                                        <List.Item>Обсудить статистику просмотров объявления</List.Item>
                                        <List.Item>Рассказать о похожих объявлениях в CRM с историей снижения цены</List.Item>
                                        <List.Item>Предложить посмотреть похожие объекты</List.Item>
                                        <List.Item>Обсудить возможность (варианты) торга</List.Item>
                                    </List.List>
                                </List.Item>
                                <List.Item>Предложить заключить Эксклюзив, что даст возможность быстрее продать объект</List.Item>
                                <List.Item>Предложить альтернативную сделку/обмен на новостройку</List.Item>
                                <List.Item>
                                    Уточнить актуальную стоимость объекта, были ли у него предложения аванса и по какой цене?
                                </List.Item>
                                <List.Item>Предложить вывесить баннер</List.Item>
                                <List.Item>Предложить профессиональную фотосессию/видеоролик</List.Item>
                                <List.Item>Предложить аукционный метод продаж</List.Item>
                                <List.Item>
                                    Предложить временное размещение с заниженной стоимостью
                                    <List.List>
                                        <List.Item>
                                            Предложить выставить дешевле, чтобы понять рынок (выставить - не значит продать)
                                        </List.Item>
                                        <List.Item>Лучше получить предложение и отказаться от него, нежели не получить его вовсе</List.Item>
                                    </List.List>
                                </List.Item>
                            </List>
                            <Button size="tiny" color="green" onClick={this.handleToggleCallEventModal}>
                                Запланировать звонок
                            </Button>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {showCallEventModal && (
                    <EventModal
                        onClose={this.handleToggleCallEventModal}
                        item_id={CREATING_ITEM_ID}
                        contact_id={contact_id}
                        eventType={EVENT_TYPE_CALL}
                    />
                )}
            </Fragment>
        );
    }
}

export default ExpiredCallMessage;
