import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Input, Grid, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import { HandleChangeType, HandlerEditingWrappedProps } from '../../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../../HandlerEditingBlockWrapper';
import initializeAddressMap, { moveAddressMarker } from '../../../../../common/osmMapApi';
import config from '../../../../../config/ui.config';

type AddressDetailsEditingProps = HandlerEditingWrappedProps & {
    address?: string;

    geo_lat?: number;
    geo_lon?: number;
};

const MAP_TYPE_2GIS = 1;
const MAP_TYPE_OSM = 2;

type AddressDetailsEditingState = {
    mapType: number;
};

@observer
class AddressDetailsEditing extends Component<AddressDetailsEditingProps, AddressDetailsEditingState> {
    marker: any;
    map: any;

    constructor(props: AddressDetailsEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            address: props.address || '',
            geo_lat: props.geo_lat || config.defaultCoords[0],
            geo_lon: props.geo_lon || config.defaultCoords[1]
        };

        this.state = {
            mapType: MAP_TYPE_2GIS
        };

        initializeAddressMap(
            `editingMap-${props.item_id}`,
            estate.geo_lat,
            estate.geo_lon,
            estate.address,
            (map_lat: number, map_lng: number) => {
                estateStore.setAddressCoordinates(props.item_id, map_lat, map_lng);
            },
            (marker, map) => {
                this.marker = marker;
                this.map = map;
            }
        );

        estateStore.setEditingItem(estate.estate_id, estate);
    }

    componentDidUpdate(props: AddressDetailsEditingProps) {
        const { item_id } = props;
        const { geo_lat, geo_lon } = estateStore.getItem(item_id).editingItem;

        if (this.marker && geo_lon && geo_lat) {
            moveAddressMarker(this.marker, this.map, geo_lat, geo_lon);
        }
    }

    handleMapTypeChange: HandleChangeType = (event: SyntheticEvent, { value }) => {
        this.setState({ mapType: value });
    };

    render() {
        const { item_id } = this.props;
        const { mapType } = this.state;

        return (
            <div>
                {/*<div>*/}
                {/*<label className="crm-Estate__field_labelFlex">Карты</label>*/}
                {/*<ButtonGroup buttonSet={[[MAP_TYPE_2GIS, '2GIS'], [MAP_TYPE_OSM, 'OpenStreetMaps']]} value={mapType} handleChange={this.handleMapTypeChange} name={'aa'} />*/}
                {/*</div>*/}
                <div id={`editingMap-${item_id}`} style={{ height: '450px' }} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, AddressDetailsEditing);
