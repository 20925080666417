import React from 'react';
import { observer } from 'mobx-react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import Table, { TableCell, TableBody, TableRow, TableHead } from '~ui/Table';

import estateL10n from '~/L10n/estate.L10n';
import { TSumStatisticsMatched, EXPORT_BASES_OPTIONS } from '~/stores/export/exportStatisticsStore';
import StatisticsByExportBaseModal from './StatistiscsByExportBaseModal';
import RedeemBalanceModal from './RedeemBalanceModal';

const TableCellNoWrap = withStyles((theme: Theme) =>
    createStyles({
        root: {
            whiteSpace: 'nowrap'
        }
    })
)(TableCell);

export const printTableNumber = (count: number | string | null, postfix?: string): string =>
    Number(count) ? `${count.toLocaleString()}${postfix ? ` ${postfix}` : ''}` : '-';

const EstateStatisticsTable = ({
    sumStatisticsMatched,
    showEstatesCount
}: {
    sumStatisticsMatched: TSumStatisticsMatched;
    showEstatesCount?: boolean;
}) => {
    const { phoneShows, showsCount, totalCost, totalPremium, totalRedeemCost, estatesCount, views } = sumStatisticsMatched;

    let countSuccessRows = 0;
    let sumTotalCost = 0;
    let sumTotalPremium = 0;
    let sumRedeemCost = 0;
    let sumViews = 0;
    let sumPhoneShows = 0;

    return (
        <Table size="medium">
            {showEstatesCount && <caption>Средняя статистика за выбранный период</caption>}
            <TableHead>
                <TableRow>
                    <TableCell rowSpan={2}>База</TableCell>
                    {showEstatesCount && (
                        <TableCell align="center" rowSpan={2}>
                            Объектов
                        </TableCell>
                    )}
                    <TableCell align="center" rowSpan={2}>
                        Расход
                    </TableCell>
                    {showEstatesCount && (
                        <>
                            <TableCell align="center" rowSpan={2}>
                                Премиумы
                            </TableCell>

                            <TableCell align="center" rowSpan={2}>
                                Списание
                            </TableCell>
                        </>
                    )}
                    <TableCell align="center" colSpan={2}>
                        Просмотры
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                        Показы телефона
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="right">Количество</TableCell>
                    <TableCell align="right">Стоимость</TableCell>
                    <TableCell align="right">Количество</TableCell>
                    <TableCell align="right">Стоимость</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(EXPORT_BASES_OPTIONS)
                    .sort()
                    .slice(1)
                    .map(export_base_id_string => {
                        const export_base_id = Number(export_base_id_string);
                        const { title, color, name } = EXPORT_BASES_OPTIONS[export_base_id];
                        const phoneShowsFound = phoneShows.find(stat => stat.export_base_id === export_base_id) || { value: null };
                        const viewFound = views.find(stat => stat.export_base_id === export_base_id) || { value: null };
                        const totalCostFound = totalCost.find(stat => stat.export_base_id === export_base_id) || { value: null };
                        const totalPremiumFound = totalPremium.find(stat => stat.export_base_id === export_base_id) || { value: null };
                        const redeemCostFound = totalRedeemCost.find(stat => stat.export_base_id === export_base_id) || { value: null };
                        const estatesCountFound = estatesCount.find(stat => stat.export_base_id === export_base_id) || { value: null };

                        if (!phoneShowsFound.value && !viewFound.value && !totalCostFound.value) {
                            return null;
                        }
                        countSuccessRows++;
                        sumTotalCost += Number(totalCostFound.value);
                        sumTotalPremium += Number(totalPremiumFound.value);
                        sumRedeemCost += Number(redeemCostFound.value);
                        sumViews += Number(viewFound.value);
                        sumPhoneShows += Number(phoneShowsFound.value);

                        const avgViewCost = Number(viewFound.value) > 0 ? Number(totalCostFound.value) / viewFound.value : 0;
                        const avgPhoneShowsCost =
                            Number(phoneShowsFound.value) > 0 ? Number(totalCostFound.value) / phoneShowsFound.value : 0;

                        return (
                            <TableRow key={export_base_id}>
                                <TableCell component="th" scope="row">
                                    <b style={{ color }}>{title}</b>
                                </TableCell>
                                {showEstatesCount && (
                                    <TableCellNoWrap align="center">
                                        <StatisticsByExportBaseModal
                                            value={printTableNumber(estatesCountFound.value)}
                                            export_base_id={export_base_id}
                                        />
                                    </TableCellNoWrap>
                                )}
                                <TableCellNoWrap align="right">
                                    {printTableNumber(totalCostFound.value, estateL10n.CURRENCY_TYPES_FIND(1))}
                                </TableCellNoWrap>
                                {showEstatesCount && (
                                    <>
                                        <TableCellNoWrap align="right">
                                            {printTableNumber(totalPremiumFound.value, estateL10n.CURRENCY_TYPES_FIND(1))}
                                        </TableCellNoWrap>
                                        <TableCellNoWrap align="right">
                                            {printTableNumber(redeemCostFound.value, estateL10n.CURRENCY_TYPES_FIND(1))}
                                        </TableCellNoWrap>
                                    </>
                                )}
                                <TableCellNoWrap align="right">{printTableNumber(viewFound.value)}</TableCellNoWrap>
                                <TableCellNoWrap align="right">
                                    {printTableNumber(avgViewCost.toFixed(2), estateL10n.CURRENCY_TYPES_FIND(1))}
                                </TableCellNoWrap>
                                <TableCellNoWrap align="right">{printTableNumber(phoneShowsFound.value)}</TableCellNoWrap>
                                <TableCellNoWrap align="right">
                                    {printTableNumber(avgPhoneShowsCost.toFixed(2), estateL10n.CURRENCY_TYPES_FIND(1))}
                                </TableCellNoWrap>
                            </TableRow>
                        );
                    })}
                {countSuccessRows > 0 && (
                    <TableRow>
                        <TableCell component="th" scope="row" colSpan={showEstatesCount ? 2 : 1}>
                            <b>Итого</b>
                        </TableCell>
                        <TableCellNoWrap align="right">{printTableNumber(sumTotalCost, estateL10n.CURRENCY_TYPES_FIND(1))}</TableCellNoWrap>
                        {showEstatesCount && (
                            <>
                                <TableCellNoWrap align="right">
                                    {printTableNumber(sumTotalPremium, estateL10n.CURRENCY_TYPES_FIND(1))}
                                </TableCellNoWrap>

                                <TableCellNoWrap align="right">
                                    <RedeemBalanceModal value={printTableNumber(sumRedeemCost, estateL10n.CURRENCY_TYPES_FIND(1))} />
                                </TableCellNoWrap>
                            </>
                        )}
                        <TableCellNoWrap align="right">{printTableNumber(sumViews)}</TableCellNoWrap>
                        <TableCellNoWrap align="right">
                            {printTableNumber(
                                Number(sumViews) > 0 ? (sumTotalCost / sumViews).toFixed(2) : null,
                                estateL10n.CURRENCY_TYPES_FIND(1)
                            )}
                        </TableCellNoWrap>
                        <TableCellNoWrap align="right">{printTableNumber(sumPhoneShows)}</TableCellNoWrap>
                        <TableCellNoWrap align="right">
                            {printTableNumber(
                                Number(sumPhoneShows) > 0 ? (sumTotalCost / sumPhoneShows).toFixed(2) : null,
                                estateL10n.CURRENCY_TYPES_FIND(1)
                            )}
                        </TableCellNoWrap>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default observer(EstateStatisticsTable);
