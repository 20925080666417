import React, { Component, FormEvent, Fragment, SyntheticEvent } from 'react';
import { List, Grid, Checkbox, Divider, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import estateL10n from '../../../../L10n/estate.L10n';
import {
    ESTATE_BASE_OWNERS,
    ESTATE_BASES,
    ESTATE_PROPERTY_COMMERCE_ARR,
    ESTATE_PROPERTY_COUNTRY_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION
} from '~/types/estate.types';
import ButtonGroup from '../../../Base/ButtonGroup';
import RichDropdown, { removeSetFromArray } from '../../../Base/ui/RichDropdown';
import { estateAbout } from '../EstateLink';
import { HandleChangeType } from '../../../Items/HandlerEditingBlockWrapper';

const EstatePropertyTypeList = ({
    PROPERTY_TYPES,
    label,
    propertyTypes,
    onChange
}: {
    PROPERTY_TYPES: number[];
    label: string;
    propertyTypes: number[];
    onChange: (event: FormEvent, data: { value: any }) => void;
}) => (
    <List>
        <List.Item>
            <List.Content>
                <List.Header>
                    <Checkbox
                        label={label}
                        indeterminate={
                            PROPERTY_TYPES.some(type => propertyTypes.includes(type)) &&
                            !PROPERTY_TYPES.every(type => propertyTypes.includes(type))
                        }
                        checked={PROPERTY_TYPES.every(type => propertyTypes.includes(type))}
                        value={JSON.stringify(PROPERTY_TYPES)}
                        onChange={onChange}
                    />
                </List.Header>
            </List.Content>
        </List.Item>
        {PROPERTY_TYPES.map(type => (
            <List.Item key={type}>
                <Checkbox
                    checked={propertyTypes.includes(type)}
                    label={estateL10n.PROPERTY_TYPE_FIND(type)}
                    value={type}
                    onChange={onChange}
                />
            </List.Item>
        ))}
    </List>
);

const ALL_FLATS_TITLE = 'Городская';
const ALL_COUNTRY_TITLE = 'Загородная';
const ALL_OFFICE_TITLE = 'Коммерческая';
const ALL_CARS_TITLE = 'Гаражи / Машиноместа';

export const PropertyLabelsAccumulate: { set: number[]; color: SemanticCOLORS; title: string; icon: SemanticICONS }[] = [
    { set: ESTATE_PROPERTY_FLATS_ARR, color: 'red', title: ALL_FLATS_TITLE, icon: 'building outline' },
    { set: ESTATE_PROPERTY_COUNTRY_ARR, color: 'brown', title: ALL_COUNTRY_TITLE, icon: 'home' },
    { set: ESTATE_PROPERTY_COMMERCE_ARR, color: 'green', title: ALL_OFFICE_TITLE, icon: 'building' },
    { set: ESTATE_PROPERTY_GARAGE_ARR, color: 'blue', title: ALL_CARS_TITLE, icon: 'car' }
];

type PropertyTypeBlockProps = {
    setBlockOutsideClick?: () => void;
    handleChange: HandleChangeType;
    propertyTypes: number[];
    type: number;
    base?: ESTATE_BASES;
};

@observer
class PropertyTypeBlock extends Component<PropertyTypeBlockProps> {
    handlePropertyTypes = (event: FormEvent & { target: Node }, { value: propertyType }: { value: number | string | number[] }) => {
        if (this.props.setBlockOutsideClick) {
            this.props.setBlockOutsideClick();
        }
        let { propertyTypes } = this.props;

        if (propertyType instanceof Array) {
            let labelTitle = '';

            if (event.target.parentElement) {
                labelTitle = event.target.parentElement.textContent.trim();
            }

            switch (labelTitle) {
                case ALL_FLATS_TITLE:
                    propertyTypes = removeSetFromArray(ESTATE_PROPERTY_FLATS_ARR, propertyTypes);
                    break;
                case ALL_COUNTRY_TITLE:
                    propertyTypes = removeSetFromArray(ESTATE_PROPERTY_COUNTRY_ARR, propertyTypes);
                    break;
                case ALL_OFFICE_TITLE:
                    propertyTypes = removeSetFromArray(ESTATE_PROPERTY_COMMERCE_ARR, propertyTypes);
                    break;
                case ALL_CARS_TITLE:
                    propertyTypes = removeSetFromArray(ESTATE_PROPERTY_GARAGE_ARR, propertyTypes);
                    break;
                default:
                    propertyTypes = [...propertyType];
            }
        } else if (typeof propertyType === 'string') {
            const propertyTypeFromJson: number[] = JSON.parse(propertyType);

            if (propertyTypeFromJson instanceof Array) {
                if (propertyTypeFromJson.every(type => propertyTypes.includes(type))) {
                    propertyTypeFromJson.forEach(type => {
                        const foundIndex = propertyTypes.indexOf(type);
                        if (~foundIndex) {
                            propertyTypes.splice(foundIndex, 1);
                        }
                    });
                } else {
                    propertyTypes.push(...propertyTypeFromJson);
                }
            }
        } else {
            const foundIndex = propertyTypes.indexOf(propertyType);
            if (~foundIndex) {
                propertyTypes.splice(foundIndex, 1);
            } else {
                propertyTypes.push(propertyType);
            }
        }

        // this.props.onPropertyTypeChange(Array.from(new Set([...propertyTypes])));
        this.props.handleChange(event, { name: 'propertyTypes', value: Array.from(new Set([...propertyTypes])) });
    };

    renderLabel = ({ text, value }: { text: string; value: number }) => {
        const { propertyTypes } = this.props;

        for (const { set, color, title, icon } of PropertyLabelsAccumulate) {
            if (set.every(type => propertyTypes.includes(type)) && set.includes(value)) {
                return value === set[0]
                    ? {
                          color,
                          content: title,
                          icon
                      }
                    : null;
            }
        }

        return {
            content:
                value === ESTATE_PROPERTY_TYPE_NEWBUILDING
                    ? 'от застройщика'
                    : value === ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION
                    ? 'переуступка'
                    : estateAbout(value, null)
        };
    };

    render() {
        const { propertyTypes, type, base } = this.props;

        return (
            <RichDropdown
                placeholder="Тип: Вся недвижимость"
                value={propertyTypes}
                options={estateL10n.PROPERTY_TYPE_DROPDOWN}
                onChange={this.handlePropertyTypes}
                renderLabel={this.renderLabel}
                onClick={this.props.setBlockOutsideClick}
            >
                <Grid stackable>
                    <Grid.Column width={16}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [1, 'Продажа'],
                                [2, 'Аренда']
                            ]}
                            name="type"
                            value={type}
                            handleChange={this.props.handleChange}
                        />
                        <Divider />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <EstatePropertyTypeList
                            PROPERTY_TYPES={ESTATE_PROPERTY_FLATS_ARR}
                            label={ALL_FLATS_TITLE}
                            propertyTypes={propertyTypes}
                            onChange={this.handlePropertyTypes}
                        />

                        <Divider />
                        <EstatePropertyTypeList
                            PROPERTY_TYPES={ESTATE_PROPERTY_COUNTRY_ARR}
                            label={ALL_COUNTRY_TITLE}
                            propertyTypes={propertyTypes}
                            onChange={this.handlePropertyTypes}
                        />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <EstatePropertyTypeList
                            PROPERTY_TYPES={ESTATE_PROPERTY_COMMERCE_ARR}
                            label={ALL_OFFICE_TITLE}
                            propertyTypes={propertyTypes}
                            onChange={this.handlePropertyTypes}
                        />
                        {base !== ESTATE_BASE_OWNERS && (
                            <Fragment>
                                <Divider />

                                <EstatePropertyTypeList
                                    PROPERTY_TYPES={ESTATE_PROPERTY_GARAGE_ARR}
                                    label={ALL_CARS_TITLE}
                                    propertyTypes={propertyTypes}
                                    onChange={this.handlePropertyTypes}
                                />
                            </Fragment>
                        )}
                    </Grid.Column>
                </Grid>
            </RichDropdown>
        );
    }
}

export default PropertyTypeBlock;
