import React, { Component, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';
import isEqual from '../../../common/isEqual';
import UserAvatar from '~ui/UserAvatar';

type UserDropdownProps = {
    user_id?: number | Array<number>;
    onChange: (ids: number | Array<number>) => void;
    upward?: boolean;
    groups_id?: Array<number>;
    fluid?: boolean;
    disableClearable?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
    disabled?: boolean;
};

type UserDropdownState = {
    user_id: number | Array<number> | null;
};

const REMOVED_USER_TITLE = 'Сотрудник в Архиве';

const renderLabel = ({ text }: { text: string }) => {
    return {
        color: text === REMOVED_USER_TITLE ? 'red' : null,
        content: `${text}`,
        icon: text === REMOVED_USER_TITLE ? 'user times' : 'user'
    };
};

export const RenderAvatarDropdown = ({ firstName, lastName, src }: { firstName: string; lastName: string; src: string | null }) => {
    return (
        <span className="ui avatar image">
            <UserAvatar src={src} size={30} firstName={firstName} lastName={lastName} />
        </span>
    );
};

class UserDropdown extends Component<UserDropdownProps, UserDropdownState> {
    state = {
        user_id: null
    };

    static getDerivedStateFromProps(nextProps: UserDropdownProps, prevState: UserDropdownState) {
        const { user_id } = nextProps;
        return {
            user_id: user_id ? (user_id instanceof Array ? [...user_id] : user_id) : null
        };
    }

    handleUserId = (event: React.SyntheticEvent, { value }: { value: number | Array<number> | string }) => {
        // for clearable:
        if (typeof value === 'string') {
            value = 0;
        }
        this.props.onChange(value);
        this.setState({
            user_id: value || null
        });
    };

    shouldComponentUpdate(nextProps: UserDropdownProps, nextState: UserDropdownState) {
        return !isEqual(this.state.user_id, nextState.user_id) || this.props.disabled !== nextProps.disabled;
    }

    render() {
        const { userAndGroups } = authStore;
        const { user_id } = this.state;
        const { groups_id, upward, fluid, disableClearable, onClick, disabled } = this.props;

        let userAndGroupsList = userAndGroups;

        if (groups_id && groups_id.length) {
            const allGroupIds = authStore.matchAllSubgroupsIdsByGroupIds(groups_id);
            userAndGroupsList = userAndGroups.filter(({ group_id }) => allGroupIds.includes(group_id));
        }

        if (user_id instanceof Array) {
            userAndGroupsList = [...userAndGroupsList];
            user_id.forEach(user_id => {
                const found = userAndGroupsList.find(uAG => uAG.user_id === user_id);
                if (!found) {
                    userAndGroupsList.push({
                        firstName: REMOVED_USER_TITLE,
                        lastName: '',
                        group_id: 0,
                        user_id,
                        enable: false,
                        email: '',
                        avatarUrl: null,
                        phones: []
                    });
                }
            });
        }

        return (
            <Dropdown
                floating
                upward={upward}
                fluid={fluid}
                multiple={user_id instanceof Array}
                selection
                options={userAndGroupsList.map(({ firstName, lastName, user_id, avatarUrl }) => ({
                    text: `${lastName} ${firstName}`.trim(),
                    value: user_id,
                    key: user_id,
                    image: commonStore.isMobile ? null : <RenderAvatarDropdown src={avatarUrl} firstName={firstName} lastName={lastName} />
                }))}
                search
                placeholder="Выбрать сотудника"
                value={user_id}
                noResultsMessage="Сотрудник не найден"
                onChange={this.handleUserId}
                renderLabel={renderLabel}
                clearable={!(user_id instanceof Array) && !disableClearable}
                className="crm-Item__floatingDropdown"
                onClick={onClick}
                disabled={disabled}
            />
        );
    }
}

export default UserDropdown;
