import React, { Component, Fragment } from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import EditField from '../../../Base/EditField';

import userStore from '~/stores/userStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { UserSeo } from '~/types/users.types';

import usersL10n from '../../../../L10n/users.L10n';

type UserSEOEditingProps = HandlerEditingWrappedProps & {
    promoDescription?: string;
    isSiteProfileShow?: boolean;
    siteStatus?: number;
    siteLink?: string;
};

@observer
class UserSEOEditing extends Component<UserSEOEditingProps> {
    constructor(props: UserSEOEditingProps) {
        super(props);

        const item = {
            user_id: props.item_id,
            promoDescription: props.promoDescription,
            isSiteProfileShow: props.isSiteProfileShow,
            siteStatus: props.siteStatus,
            siteLink: props.siteLink
        };
        userStore.setEditingItem(item.user_id, item);
    }

    render() {
        const { item_id, handleChange, handleTextAreaChange, handleCheckbox } = this.props;
        const { promoDescription, isSiteProfileShow, siteStatus, siteLink }: UserSeo = userStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Field inline>
                    <label className="crm-Estate__field_label">На сайте</label>
                    <Checkbox
                        checked={isSiteProfileShow}
                        name="isSiteProfileShow"
                        toggle
                        label={<label>Отображается на сайте</label>}
                        onClick={handleCheckbox}
                    />
                </Form.Field>

                <EditField.Drop
                    onChange={handleChange}
                    label="Статус"
                    name="siteStatus"
                    value={siteStatus}
                    options={usersL10n.SITE_STATUS_DROPDOWN}
                />

                <EditField.Text label="ID (ссылка)" value={siteLink} name="siteLink" onChange={handleChange} />

                <EditField.Area
                    label="Промо Текст"
                    value={promoDescription || null}
                    name="promoDescription"
                    onChange={handleTextAreaChange}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(userStore, UserSEOEditing);
