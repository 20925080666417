import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import ContactsFilter from './ContactsFilter';
import './contacts.less';
import uiConfig from '~/config/ui.config';

import ContactsTableBody from './ContactsTableBody';
import ContactsTableHeader from './ContactsTableHeader';
import ListFooter from '../Common/ListFooter';
import contactStore from '~/stores/contactStore';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import authStore from '~/stores/authStore';
import { ContactLinkState } from './ContactLink';
import ListError from '../../ListError';
import tagStore from '~/stores/tagStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import ManageContactsArray from './SubBlocks/ManageContactsArray';
import SmsContactsArray from './SubBlocks/SmsContactsArray';
import { useToggle } from '~ui/Modal';

const ContactsList = (props: ListWrapperProps) => {
    const [manageItemModal, toggleManageItemModal] = useToggle();
    const [showSmsModal, toggleSmsModal] = useToggle();

    const { orderBy, orderDirection, handleSort } = props;
    const {
        loadingList,
        list,
        listCount,
        nextListLoaded,
        pagination,
        listFilter: { enable, showEstates },
        listErrors,
        selectedItemsIds
    } = contactStore;

    const { loadingFetchAllTags } = tagStore;
    const enableCheckbox = [ACCESS_COMPANY, ACCESS_GROUP].includes(authStore.getPermission(contactStore.moduleName, ACCESS_WRITE));

    const footerSpans = (enableCheckbox ? 5 : 4) + (showEstates ? 1 : 0);

    return (
        <Fragment>
            <div className="crm-List">
                <div className="crm-List__filterType">
                    <Header as="h3" style={{ marginBottom: 0 }}>
                        <Icon name="address card outline" />
                        Контакты
                    </Header>
                    <div className="crm-List__filterType_topBtns">
                        {!enable && <span className="crm-Estate__filter_archiveLabel">(Архив)</span>}
                    </div>
                </div>

                {authStore.canCreate(contactStore.moduleName) && (
                    <div className="crm-List__addButton">
                        {enable && selectedItemsIds.length > 0 && enableCheckbox && (
                            <>
                                {uiConfig.enableSentSmsToContact && (
                                    <Button
                                        icon
                                        labelPosition="left"
                                        color="green"
                                        size="tiny"
                                        className="crm-Estate__ownersBtn"
                                        onClick={toggleSmsModal}
                                    >
                                        <Icon name="comment alternate outline" /> Отправить SMS ({selectedItemsIds.length})
                                    </Button>
                                )}
                                <Button
                                    icon
                                    labelPosition="left"
                                    color="red"
                                    size="tiny"
                                    className="crm-Estate__ownersBtn"
                                    onClick={toggleManageItemModal}
                                >
                                    <Icon name="address card outline" /> Параметры ({selectedItemsIds.length})
                                </Button>
                            </>
                        )}

                        <Button icon labelPosition="left" primary size="tiny" as={Link} to={ContactLinkState(CREATING_ITEM_ID)}>
                            <Icon name="address card outline" /> Добавить
                        </Button>
                    </div>
                )}

                {manageItemModal && <ManageContactsArray onClose={toggleManageItemModal} />}
                {showSmsModal && <SmsContactsArray onClose={toggleSmsModal} />}
            </div>

            <Segment.Group className="crm-Segment" id="crm_ListTop">
                <ListError errors={listErrors} />
                <ContactsFilter />

                <Segment className="crm-List__segment">
                    <Table structured size="small" sortable compact>
                        <ContactsTableHeader
                            handleSort={handleSort}
                            orderDirection={orderDirection}
                            orderBy={orderBy}
                            selectedAll={contactStore.selectedAll}
                            enableCheckbox={enableCheckbox}
                            showEstates={Boolean(showEstates)}
                        />

                        <ContactsTableBody
                            contactsList={loadingFetchAllTags ? [] : list}
                            selectedItemsIds={selectedItemsIds}
                            toggleItemSelected={enableCheckbox ? contactStore.toggleItemSelected.bind(contactStore) : undefined}
                            showEstates={Boolean(showEstates)}
                        />

                        <ListFooter
                            loading={loadingList || loadingFetchAllTags}
                            pagination={pagination}
                            listCount={listCount}
                            pageChange={contactStore.pageChange}
                            pageSizeChange={contactStore.pageSizeChange}
                            colSpan={footerSpans}
                            nextListLoaded={nextListLoaded}
                            scrollToElementId="crm_ListTop"
                        />
                    </Table>
                </Segment>
            </Segment.Group>
        </Fragment>
    );
};

export default ListWrapper(contactStore, observer(ContactsList));
