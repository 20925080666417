import React, { Fragment } from 'react';
import estateL10n from '../../../../L10n/estate.L10n';
import MonoString from '../../../Base/MonoString';

export default ({ hasLoggia, hasBalcone, wcType }: { hasLoggia?: boolean | null; hasBalcone?: boolean | null; wcType?: number | null }) => (
    <Fragment>
        {(hasLoggia || hasBalcone) && (
            <MonoString template={`${hasLoggia ? 'Лоджия' : ''}${hasLoggia && hasBalcone ? ', ' : ''}${hasBalcone ? 'Балкон' : ''}`} />
        )}
        {Number(wcType) > 0 && <MonoString template={estateL10n.WC_TYPE_FIND(wcType)} />}
    </Fragment>
);
