import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import estateL10n from '~/L10n/estate.L10n';

import { ChipSelect } from '~ui/Select';
import Grid from '~ui/Grid';
import InputMinMax from '~ui/InputMinMax';
import Button from '~ui/Button';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';

import LifeComplexApartmentsHousesFilter from './LifeComplexApartmentsHousesFilter';
import { LifeComplexApartmentsFilter } from '~/types/newbuildings.types';
import Typography from '@material-ui/core/Typography';
import ListError from '~/components/ListError';

const LifeComplexApartmentsFilterPult = ({ newbuilding_id }: { newbuilding_id: number }) => {
    const { apartmentsFilter, apartmentsErrors, apartmentsCount, loadingApartments } = lifeComplexStore.getItem(newbuilding_id).property;
    const { roomsCount, priceMin, priceMax, totalAreaMin, totalAreaMax } = apartmentsFilter;

    const handleFieldChange = <T extends keyof LifeComplexApartmentsFilter>(
        _,
        { name, value }: { name: T; value: LifeComplexApartmentsFilter[T] }
    ) => {
        lifeComplexStore.changeApartmentsFilter(newbuilding_id, name, value);
    };

    return (
        <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
            <Grid spacing={2} disableGutter>
                <Grid.Column xs={12} md={4}>
                    <ChipSelect
                        value={roomsCount}
                        label="количество комнат"
                        name="roomsCount"
                        onChange={handleFieldChange}
                        options={estateL10n.ROOMS_COUNT_DROPDOWN}
                    />
                </Grid.Column>
                <Grid.Column xs={12} sm={6} md={4}>
                    <InputMinMax
                        smartValue={true}
                        minName="priceMin"
                        minValue={priceMin}
                        maxName="priceMax"
                        maxValue={priceMax}
                        title="Цена"
                        onChange={handleFieldChange}
                        min={1}
                        max={999999999}
                        adornment="rub"
                    />
                </Grid.Column>
                <Grid.Column xs={12} sm={6} md={4}>
                    <InputMinMax
                        smartValue={true}
                        minName="totalAreaMin"
                        minValue={totalAreaMin}
                        maxName="totalAreaMax"
                        maxValue={totalAreaMax}
                        title="Площадь"
                        onChange={handleFieldChange}
                        min={1}
                        max={999999999}
                        adornment="square"
                    />
                </Grid.Column>
                <Grid.Column xs={12} md={9}>
                    <LifeComplexApartmentsHousesFilter newbuilding_id={newbuilding_id} />
                </Grid.Column>
                <Grid.Column xs={12} md={3} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Box alignItems="center" display="flex" flexDirection="column">
                        <Button
                            size="small"
                            startIcon={<ClearIcon />}
                            color={lifeComplexStore.isApartmentsFilterEmpty(newbuilding_id) ? 'inherit' : 'secondary'}
                            variant="outlined"
                            tooltip="Очистить"
                            onClick={() => lifeComplexStore.clearApartmentsFilter(newbuilding_id)}
                            disabled={loadingApartments}
                        >
                            Очистить
                        </Button>
                        <Typography variant="caption">Найдено: {loadingApartments ? '...' : apartmentsCount}</Typography>
                    </Box>
                </Grid.Column>
            </Grid>
            <ListError errors={apartmentsErrors} />
        </Paper>
    );
};

export default observer(LifeComplexApartmentsFilterPult);
