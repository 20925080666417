import React, { Fragment } from 'react';
import { Popup, Icon, Label, List } from 'semantic-ui-react';
import { ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import { ESTATE_BASES } from '~/types/estate.types';
import { nl2br } from '~/api/commentsApi';
import { UserLinkType } from '~/types/users.types';
import UsersLinkList from '../../Users/UsersLinkList';
import Time from '../../../Base/Time';
import isEqual from '../../../../common/isEqual';

const EstateTableContacts = ({
    base,
    major_users,
    photosCount,
    showExportMajorUser,
    hasVideo,
    description,
    showingsCount,
    callsCount,
    lastOutgoingCallToOwnerTime
}: {
    base: ESTATE_BASES;
    major_users: Array<UserLinkType>;
    photosCount: number;
    showExportMajorUser?: boolean | null;
    hasVideo?: boolean | null;
    description: string;
    showingsCount?: number;
    callsCount?: number;
    lastOutgoingCallToOwnerTime?: number;
}) => {
    return (
        <Fragment>
            {major_users && major_users.length > 0 && <UsersLinkList usersList={major_users} />}
            {/*{base === ESTATE_BASE_MLS && (!major_users || !major_users.length) && phone && <a href={`tel:+${phone}`}>+{phone}</a>}*/}
            {base !== ESTATE_BASE_OWNERS && photosCount === 0 && <div className="crm-Estate__list_nophoto">НЕТ ФОТО</div>}
            {!showExportMajorUser && (
                <div>
                    <Popup
                        wide
                        position="left center"
                        size="tiny"
                        trigger={<Icon bordered name="info" />}
                        content={<span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />}
                    />
                    {hasVideo && <Icon title="Есть видео ролик" name="video play" color="red" />}
                    {base === ESTATE_BASE_MAIN && (
                        <Fragment>
                            &nbsp;&nbsp;
                            <Popup
                                wide
                                size="tiny"
                                position="left center"
                                trigger={
                                    <Label size="mini" basic color={Number(callsCount) > 0 ? 'blue' : null}>
                                        <Icon name="phone" /> {callsCount}
                                    </Label>
                                }
                                content={
                                    <List divided verticalAlign="middle">
                                        <List.Item>
                                            <List.Content>Звонок с собственником объекта</List.Content>
                                            <List.Content floated="right">
                                                <b>{lastOutgoingCallToOwnerTime ? <Time time={lastOutgoingCallToOwnerTime} /> : 'н/д'}</b>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content>Количетсво входящих звонков по рекламе</List.Content>
                                            <List.Content floated="right">
                                                <b>{callsCount || 0}</b>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                }
                            />
                            &nbsp;&nbsp;
                            <Label size="mini" basic title="Количетсво показов" color={Number(showingsCount) > 0 ? 'green' : null}>
                                <Icon name="compass" /> {showingsCount}
                            </Label>
                        </Fragment>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default React.memo(EstateTableContacts, isEqual);
