import React, { PureComponent, Fragment } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import OwnerCreateContactModal from './OwnerCreateContactModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ownerStore from '~/stores/ownerStore';
import history from '../../../../history';
import { ContactLinkState } from '../../../Lists/Contacts/ContactLink';

type OwnerTakeToWorkButtonProps = {
    estate_id: number;
    contact_id: number | null;
    disabled: boolean;
};

type OwnerTakeToWorkButtonState = {
    confirmationPopupIsOpen: boolean;
    createContactPopupIsOpen: boolean;
};

class OwnerTakeToWorkButton extends PureComponent<OwnerTakeToWorkButtonProps, OwnerTakeToWorkButtonState> {
    state = {
        confirmationPopupIsOpen: false,
        createContactPopupIsOpen: false
    };

    handleTakeOwner = async () => {
        if (!this.props.contact_id) {
            this.setState({
                createContactPopupIsOpen: true,
                confirmationPopupIsOpen: false
            });
        } else {
            const contact_id = await ownerStore.takeOwnerForWork(this.props.estate_id, this.props.contact_id);
            if (contact_id) {
                history.push(ContactLinkState(Number(contact_id)));
            }
        }
    };

    handleClickBtn = () => {
        this.setState({ confirmationPopupIsOpen: !this.state.confirmationPopupIsOpen });
    };

    handleCloseCreatePopup = () => {
        this.setState({
            createContactPopupIsOpen: false
        });
    };

    render() {
        const { confirmationPopupIsOpen, createContactPopupIsOpen } = this.state;
        const { contact_id, disabled } = this.props;

        return (
            <Fragment>
                <Button
                    content="Создать контакт (взять в работу)"
                    labelPosition="left"
                    icon="address card outline"
                    size="tiny"
                    color="green"
                    onClick={this.handleClickBtn}
                    fluid
                    disabled={disabled}
                />

                {confirmationPopupIsOpen && (
                    <Modal open onClose={this.handleClickBtn} closeIcon dimmer="inverted" size="small">
                        <Modal.Header>Взять в работу</Modal.Header>
                        <Modal.Content>
                            <p>
                                <b>Собственник согласился, чтобы вы взяли его объект в работу?</b>
                            </p>
                            <p>
                                Если контакт собственника уже был создан, то вам откроется доступ к нему. Если телефон собственника ранее не
                                встречался в нашей базе, то создастся новый контакт.
                                <br />
                                <br />
                                <b>
                                    Вам дается 48 часов на то, чтобы подписать листинг с Собственником. Если листинг не будет подписан,
                                    данный Собственник вернется в общую базу.
                                </b>
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={this.handleTakeOwner}
                                color="green"
                                labelPosition="left"
                                icon="male"
                                content="Взять в работу"
                                size="tiny"
                            />
                            <Button onClick={this.handleClickBtn} size="tiny">
                                Отмена
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {createContactPopupIsOpen && (
                    <OwnerCreateContactModal
                        onClose={this.handleCloseCreatePopup}
                        contact_id={contact_id || CREATING_ITEM_ID}
                        estate_id={this.props.estate_id}
                    />
                )}
            </Fragment>
        );
    }
}

export default OwnerTakeToWorkButton;
