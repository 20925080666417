import React, { PureComponent } from 'react';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import estateStore from '~/stores/estateStore';
import { ESTATE_BASES } from '~/types/estate.types';
import { DynamicActiveButton } from '../../../Base/ui/DynamicActiveButton';

type EstateIsFavoriteProps = {
    isFavorite: boolean;
    isHidden: boolean;
    estate_id: number;
    base: ESTATE_BASES;
    size?: 'small' | 'large' | 'medium';
    hiddenButton?: boolean;
};

type EstateIsFavoriteState = {
    isFavorite: boolean;
    isHidden: boolean;
    propsIsBlocking: boolean;
};

class EstateIsFavorite extends PureComponent<EstateIsFavoriteProps, EstateIsFavoriteState> {
    constructor(props: EstateIsFavoriteProps) {
        super(props);

        this.state = {
            isFavorite: props.isFavorite,
            isHidden: props.isHidden,
            propsIsBlocking: false
        };
    }

    handleFavorite = async (isFavorite: boolean) => {
        this.setState({ isFavorite, propsIsBlocking: true });

        await estateStore.favoriteEstate(this.props.estate_id, this.props.base, isFavorite);
    };

    handleHide = async (isHidden: boolean) => {
        this.setState({ isHidden, propsIsBlocking: true });
        await estateStore.hideEstate(this.props.estate_id, this.props.base, isHidden);
    };

    static getDerivedStateFromProps(nextProps: EstateIsFavoriteProps, prevState: EstateIsFavoriteState) {
        return prevState.propsIsBlocking
            ? { ...prevState, propsIsBlocking: false }
            : {
                  isFavorite: nextProps.isFavorite,
                  isHidden: nextProps.isHidden,
                  propsIsBlocking: false
              };
    }

    componentDidUpdate(prevProps: EstateIsFavoriteProps, prevState: EstateIsFavoriteState) {
        if (this.props.isFavorite && !prevState.isFavorite && !this.state.isFavorite && !prevProps.isFavorite) {
            this.setState({
                isFavorite: true
            });
        }
    }

    snackBarTimeout: number;

    componentWillUnmount() {
        clearTimeout(this.snackBarTimeout);
    }

    render() {
        const { isHidden, isFavorite } = this.state;
        const { size, base, estate_id, hiddenButton } = this.props;

        return (
            <div className="crm-Estate__isFavoriteBlock">
                {hiddenButton && (
                    <div style={{ marginRight: '20px', display: 'flex' }}>
                        <DynamicActiveButton
                            enable={isHidden}
                            positiveInfo="Объект скрыт из поиска"
                            negativeInfo="Объект снова виден в поиске"
                            titleOnEnable="Убрать из скрытых (Сейчас объект скрыт в поиске)"
                            titleOnDisable="Скрыть объект из поиска"
                            size={size || 'small'}
                            icon={
                                isHidden ? (
                                    <VisibilityOffIcon fontSize={'small'} color="secondary" />
                                ) : (
                                    <VisibilityIcon fontSize={'small'} />
                                )
                            }
                            onChange={this.handleHide}
                        />
                    </div>
                )}

                <DynamicActiveButton
                    enable={isFavorite}
                    positiveInfo="Добавлено в избранные"
                    negativeInfo="Убрано из избранных"
                    titleOnEnable="Убрать из избранных"
                    titleOnDisable="Добавить в избранные"
                    size={size || 'small'}
                    onChange={this.handleFavorite}
                />
            </div>
        );
    }
}

export default EstateIsFavorite;
