import React, { Component, Fragment } from 'react';
import { EVENT_TYPE_CALL, EVENT_TYPE_MAIN, EVENT_TYPE_MEETING } from '~/types/events.types';
import { Icon } from 'semantic-ui-react';
import ButtonGroup from '../../Base/ButtonGroup';

const EventTypeBlock = ({
    eventType,
    onChange
}: {
    eventType: number;
    onChange: (event: React.SyntheticEvent, data: { value: number }) => void;
}) => (
    <ButtonGroup
        buttonSet={[
            /*[EVENT_TYPE_MAIN, 'Событие', null, 'red'],*/
            [
                EVENT_TYPE_CALL,
                <span className="crm-Flex__center" key="key">
                    <Icon name="phone square" size="large" />
                    <span>Звонок</span>
                </span>,
                null,
                { backgroundColor: '#f2711c', color: 'white' }
            ],
            [
                EVENT_TYPE_MEETING,
                <span className="crm-Flex__center" key="key">
                    <Icon name="handshake outline" size="large" />
                    <span>Встреча</span>
                </span>,
                null,
                { backgroundColor: '#6435c9', color: 'white' }
            ]
        ]}
        value={eventType}
        name="eventType"
        handleChange={onChange}
        size="medium"
    />
);

export default EventTypeBlock;
