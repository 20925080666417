import React, { Component, Fragment } from 'react';
import { Card, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import UserGeoEditing from '../EditBlocks/UserGeoEditing';
import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import MetroList from '../../Wish/SubBlocks/MetroList';
import estateStore from '~/stores/estateStore';
import LoaderAwait from '../../../Base/LoaderAwait';

@observer
class UserGeoShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = userStore.getItem(item_id);

        const { main_metro_id, userAddress } = item || {};
        const { metroStationsListLoading } = estateStore;

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Геолокации</Header>
                            <Card.Description>
                                <b>Станции метро</b> &nbsp;
                                {!metroStationsListLoading && <MetroList main_metro_id={main_metro_id || []} enablePopup />}
                                <LoaderAwait active={metroStationsListLoading} size="mini" />
                                {userAddress && (
                                    <Fragment>
                                        <br />
                                        <b>Адрес&nbsp;</b>
                                        {userAddress.address}
                                    </Fragment>
                                )}
                            </Card.Description>
                        </Fragment>
                    )}
                    {editing && !loadingItem && (
                        <UserGeoEditing editing item_id={item_id} main_metro_id={[...main_metro_id]} userAddress={userAddress} />
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(userStore, UserGeoShow);
