import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import authStore from '~/stores/authStore';
import requestStore from '~/stores/requestStore';

import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';

import ListFooter from '../Common/ListFooterNew';
import RequestTableHeader from './RequestTableHeader';
import RequestTableBody from './RequestTableBody';
import FilterRequest from './FilterRequest';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { REQUEST_LIST_HUNTING } from '~/types/requests.types';
import ListError from '../../ListError';

import Container from '@material-ui/core/Container';

import Table, { TableHead } from '~ui/Table';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';

@observer
class RequestList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort, param } = this.props;
        const { loadingList, list, listCount, listErrors, pagination, nextListLoaded } = requestStore;

        return (
            <Container maxWidth="lg" style={{ padding: 0 }}>
                <div className="crm-List">
                    <Header as="h3">
                        <Icon name="wordpress forms" />
                        {param === REQUEST_LIST_HUNTING ? 'Рекрутинг' : 'Заявки'}
                    </Header>
                    {authStore.canCreate(requestStore.moduleName) && (
                        <div className="crm-List__addButton">
                            <Button
                                icon
                                labelPosition="left"
                                primary
                                size="tiny"
                                as={Link}
                                to={{
                                    pathname: `/requests/${param}/overview/${CREATING_ITEM_ID}`,
                                    state: {
                                        modal: true,
                                        callback: { storeName: 'requestStore', method: 'fetchList' }
                                    }
                                }}
                            >
                                <Icon name="wordpress forms" /> Добавить
                            </Button>
                        </div>
                    )}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    {listErrors && <ListError errors={listErrors} />}

                    <FilterRequest />

                    <Segment className="crm-List__segment">
                        <Table size="small">
                            <TableHead>
                                <ListPagination
                                    loading={loadingList}
                                    colSpan={5}
                                    pagination={pagination}
                                    listCount={listCount}
                                    pageChange={requestStore.pageChange}
                                    pageSizeChange={requestStore.pageSizeChange}
                                    nextListLoaded={nextListLoaded}
                                />
                            </TableHead>

                            <RequestTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <RequestTableBody requestsList={list} orderBy={orderBy} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={5}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={requestStore.pageChange}
                                pageSizeChange={requestStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Container>
        );
    }
}

export default ListWrapper(requestStore, RequestList);
