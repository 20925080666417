import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, List, Header, Placeholder, Icon } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import DealTypeEditing from '../EditBlocks/DealTypeEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import DealLink from '../../../Lists/Deals/DealLink';
import {
    DEAL_TYPE_ACCOMPANIMENT_ID,
    DEAL_TYPE_EXCLUSIVE_ID,
    DEAL_TYPE_MORTGAGE_ID,
    DEAL_TYPE_NEWBUILDING_ID,
    DealInBaseType
} from '~/types/deals.types';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import LabelArchive from '../../Common/LabelArchive';

const ListExampleTree = ({
    dealTree,
    currentDealId
}: {
    dealTree: DealInBaseType & { children?: DealInBaseType[] };
    currentDealId: number;
}) => {
    const { deal_id, type, children } = dealTree;

    return (
        <List>
            <List.Item>
                <List.Icon name="money" />
                <List.Content>
                    <List.Header>
                        <DealLink deal={{ deal_id, type, enable: true }} currentInChain={deal_id === currentDealId} />;
                    </List.Header>
                    {children &&
                        children.length > 0 &&
                        children.map(deal => <ListExampleTree key={deal.deal_id} dealTree={deal} currentDealId={currentDealId} />)}
                </List.Content>
            </List.Item>
        </List>
    );
};

const matchDealTree = (deal: DealInBaseType & { children?: DealInBaseType[] }, dealTree: DealInBaseType[]) => {
    deal.children = dealTree.filter(({ parent_deal_id }) => parent_deal_id === deal.deal_id);

    deal.children.forEach(newDeal => {
        matchDealTree(newDeal, dealTree);
    });

    return deal;
};

const printDealStatus = (type: number, deal_id: number): string => {
    switch (type) {
        case ESTATE_TYPE_SELL_ID:
            return `Сделка (продажа / покупка) №${deal_id}`;
        case ESTATE_TYPE_RENT_ID:
            return `Сделка (аренда) №${deal_id}`;
        case DEAL_TYPE_EXCLUSIVE_ID:
            return `Эксклюзив №${deal_id}`;
        case DEAL_TYPE_ACCOMPANIMENT_ID:
            return `Сопровождение №${deal_id}`;
        case DEAL_TYPE_NEWBUILDING_ID:
            return `Продажа в Новостройке №${deal_id}`;
        case DEAL_TYPE_MORTGAGE_ID:
            return `Ипотека №${deal_id}`;
        default:
            return '';
    }
};

@observer
class DealTypeShowing extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        if (editing) {
            return <DealTypeEditing item_id={item_id} editing={editing} {...item} />;
        }

        const { type, dealType, dealTree } = item;
        const tree = dealTree && dealTree.length > 0 ? matchDealTree(dealTree[0], Array.from(dealTree)) : null;

        return (
            <Card fluid color="green">
                <Card.Content>
                    {item && !item.enable && <LabelArchive ribbon />}

                    <Header size="tiny">
                        <Icon name="money" />
                        {printDealStatus(type, item_id)}
                    </Header>

                    {type === 1 && <Header size="tiny">{dealType === 1 ? `Прямая продажа` : `Альтернативная сделка`}</Header>}
                    {tree && <ListExampleTree dealTree={tree} currentDealId={item_id} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealTypeShowing);
