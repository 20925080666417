import React, { Fragment } from 'react';
import { TRANSPORT_TYPE_WALK } from '~/types/estate.types';
import { MetroDistance } from '~/types/estate.types';
import MetroLogo from '../../Base/MetroLogo';

const MainMetro = ({ metros, main_metro_id }: { metros?: MetroDistance[]; main_metro_id?: number }) => {
    let index = 0;

    if (main_metro_id && metros) {
        index = metros.findIndex(({ metro_id }) => metro_id === main_metro_id);
        if (index === -1) {
            index = 0;
        }
    }

    return metros && metros.length > 0 ? (
        <span className="crm-Estate__fieldNowrap">
            <span>
                <MetroLogo color={`#${metros[index].color || ''}`} size={14} />
                &nbsp;
                {metros[index].name}
                ,&nbsp;
            </span>
            {metros[index].time} мин {metros[index].transportType === TRANSPORT_TYPE_WALK ? 'пешком' : 'на машине'}
        </span>
    ) : null;
};

export default MainMetro;
