import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Grid, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';
import { Estate } from '~/types/estate.types';

type WarehouseBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    floorMaterialTypeType: number | null;
    parkingPurposeType: number | null;
    liftType: number | null;
    liftAdditionalType: string | null;
    liftCount: number | null;
    liftLoadCapacity: number | null;
    cranageType: number | null;
    cranageLoadCapacity: number | null;
    cranageCount: number | null;
    gatesType: number | null;
    columnGrid: string | null;
};

export const WAREHOUSE_FIELDS: [keyof Estate, string][] = [
    ['hasSafeCustody', 'Ответственное хранение'],
    ['isCustoms', 'Таможня'],
    ['hasTransportServices', 'Транспортные услуги']
];

export const INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasBuffet', 'Буфет'],
    ['hasCanteen', 'Столовая'],
    ['hasCentralReception', 'Центральная рецепция'],
    ['hasHotel', 'Гостиница'],
    ['hasOfficeSpace', 'Офисные помещения']
];

@observer
class WarehouseBlockEditing extends Component<WarehouseBlockEditingProps> {
    constructor(props: WarehouseBlockEditingProps) {
        super(props);

        const estate = {
            propertyType: props.propertyType,
            floorMaterialTypeType: props.floorMaterialTypeType || null,
            parkingPurposeType: props.parkingPurposeType || null,
            liftType: props.liftType || null,
            liftAdditionalType: props.liftAdditionalType || null,
            liftCount: props.liftCount || null,
            liftLoadCapacity: props.liftLoadCapacity || null,
            cranageType: props.cranageType || null,
            cranageLoadCapacity: props.cranageLoadCapacity || null,
            cranageCount: props.cranageCount || null,
            gatesType: props.gatesType || null,
            columnGrid: props.columnGrid || null
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, handleCheckbox, item_id } = this.props;
        const { editingItem } = estateStore.getItem(item_id);
        const {
            floorMaterialTypeType,
            liftType,
            liftAdditionalType,
            liftCount,
            liftLoadCapacity,
            cranageType,
            cranageLoadCapacity,
            cranageCount,
            gatesType,
            columnGrid
        } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Drop
                    label="Материал пола"
                    name="floorMaterialTypeType"
                    value={floorMaterialTypeType}
                    onChange={handleChange}
                    options={estateL10n.WAREHOUSE_FLOOR_MATERIAL_TYPE_DROPDOWN}
                    size="small"
                />

                <EditField.Drop
                    label="Тип лифта"
                    name="liftType"
                    value={liftType}
                    onChange={handleChange}
                    options={estateL10n.WAREHOUSE_LIFT_TYPE_DROPDOWN}
                    size="small"
                >
                    <div className="crm-Estate__fieldNowrap">
                        <Input
                            type="text"
                            placeholder="Дополнительный тип"
                            size="small"
                            value={liftAdditionalType || ''}
                            name="liftAdditionalType"
                            onChange={handleChange}
                        />
                    </div>
                </EditField.Drop>

                <EditField.InputNumber
                    label="Количество лифтов"
                    onChange={handleChange}
                    min={0}
                    max={100}
                    value={liftCount}
                    name="liftCount"
                    step={1}
                    size="small"
                />

                <EditField.InputNumber
                    label="Грузоподъёмность лифтов"
                    onChange={handleChange}
                    min={0}
                    max={100000}
                    value={liftLoadCapacity}
                    name="liftLoadCapacity"
                    step={0.1}
                    size="small"
                />

                <EditField.Drop
                    label="Тип кранового оборудования"
                    name="cranageType"
                    value={cranageType}
                    onChange={handleChange}
                    options={estateL10n.WAREHOUSE_CRANAGE_TYPE_DROPDOWN}
                    size="small"
                />

                <EditField.InputNumber
                    label="Количество кранов"
                    onChange={handleChange}
                    min={0}
                    max={100}
                    value={cranageCount}
                    name="cranageCount"
                    step={1}
                    size="small"
                />

                <EditField.InputNumber
                    label="Грузоподъёмность кранов"
                    onChange={handleChange}
                    min={0}
                    max={100000}
                    value={cranageLoadCapacity}
                    name="cranageLoadCapacity"
                    step={0.1}
                    size="small"
                />

                <EditField.ButtonGroup
                    label="Тип ворот"
                    name="gatesType"
                    value={gatesType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.WAREHOUSE_GATES_TYPE}
                    size="small"
                />

                <EditField.Text label="Сетка колонн" name="columnGrid" value={columnGrid} onChange={handleChange} size="small" />

                <Divider />

                <Form.Group>
                    <Form.Field inline>
                        <Grid columns={3}>
                            {WAREHOUSE_FIELDS.map(field => (
                                <Grid.Column key={field[0]}>
                                    <Checkbox
                                        checked={Boolean(editingItem[field[0]])}
                                        name={field[0]}
                                        label={<label>{field[1]}</label>}
                                        onChange={handleCheckbox}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Form.Field>
                </Form.Group>

                <Divider />

                <Form.Group>
                    <Form.Field inline>
                        <Grid columns={3}>
                            {INFRASTURCTURE_FIELDS.map(field => (
                                <Grid.Column key={field[0]}>
                                    <Checkbox
                                        checked={Boolean(editingItem[field[0]])}
                                        name={field[0]}
                                        label={<label>{field[1]}</label>}
                                        onChange={handleCheckbox}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, WarehouseBlockEditing);
