import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Header } from 'semantic-ui-react';

import requestStore from '~/stores/requestStore';
import { nl2br } from '~/api/commentsApi';

@observer
class RequestHuntingShow extends Component<{ item_id: number }> {
    render() {
        const { item_id } = this.props;
        const { item } = requestStore.getItem(item_id);
        const { requestJson } = item || {};

        let requestObj: { description?: string } = {};

        try {
            requestObj = JSON.parse(requestJson || '');
        } catch (error) {
            return null;
        }

        if (requestObj) {
            const { description } = requestObj;

            return (
                <Card fluid color="red">
                    <Card.Content>
                        <Header size="tiny">Описание</Header>
                        <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                    </Card.Content>
                </Card>
            );
        } else {
            return null;
        }
    }
}

export default RequestHuntingShow;
