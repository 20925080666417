import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Icon } from 'semantic-ui-react';

import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import estateStore from '~/stores/estateStore';
import {
    Estate,
    ESTATE_BASE_MAIN,
    ESTATE_BASE_OWNERS,
    ESTATE_BASES,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID
} from '~/types/estate.types';
import estateL10n from '../../../../L10n/estate.L10n';
import { PrintNewbuilding } from '../ShowBlocks/AddressBlockShow';
import { printEstateAbout } from '../ShowBlocks/EstateInfoBlock';

type EstatePreviewItemProps = {
    item_id: number;
    base: ESTATE_BASES;
};

const bulletStyles = {
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    }
};

const Bull = withStyles(bulletStyles as any)(({ classes }: { classes: any }) => <span className={classes.bullet}>•</span>);

export const EstatePreviewCardContent = ({ estate }: { estate: Estate }) => {
    const { address, newbuilding, newbuildingHouse, propertyType, floorNumber, floorsCount, exclusiveDealId } = estate;

    return (
        <>
            <Typography variant="subtitle2" gutterBottom>
                <span className={cs({ 'crm-Estate__exclusiveLink': exclusiveDealId })}>{printEstateAbout(estate)}</span>
                {floorNumber !== null && floorsCount !== null && (
                    <Fragment>
                        &nbsp;
                        <Bull /> {floorNumber}/{floorsCount}
                    </Fragment>
                )}
            </Typography>

            {newbuilding && (
                <Typography variant="subtitle2" gutterBottom>
                    <PrintNewbuilding
                        newbuilding={newbuilding}
                        newbuildingHouse={newbuildingHouse}
                        houseIsNeeded={[ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType)}
                    />
                </Typography>
            )}

            <Typography variant="body2" color="textSecondary" component="p">
                {address}
            </Typography>
        </>
    );
};

const EstatePreviewContent = (props: EstatePreviewItemProps) => {
    const { item_id, base } = props;
    const { item: estate } = estateStore.getItem(item_id);
    if (!estate) {
        return null;
    }

    const { price, currency, type, utilitiesIncluded, propertyType, totalArea, priceHistory } = estate;

    const priceLength = priceHistory && priceHistory.length;
    const priceGrewUp = priceLength > 1 && priceHistory[priceLength - 1].price < priceHistory[priceLength - 2].price;

    const priceByMeter =
        type === ESTATE_TYPE_SELL_ID &&
        ([ESTATE_PROPERTY_TYPE_FLAT, 2, 6].includes(propertyType) || propertyType === ESTATE_PROPERTY_TYPE_OFFICE) &&
        Number(totalArea) > 0
            ? Math.round(price / Number(totalArea)).toLocaleString()
            : '';

    return (
        <CardContent>
            <Typography variant="body1" gutterBottom>
                {base === ESTATE_BASE_MAIN && <Icon name="building outline" />}
                {base === ESTATE_BASE_OWNERS && <Icon name="male" color="brown" />}
                {price.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(currency)}&nbsp;
                {priceLength > 1 && priceGrewUp && <ArrowUpwardIcon fontSize={'small'} style={{ color: 'green' }} />}
                {priceLength > 1 && !priceGrewUp && <ArrowDownwardIcon fontSize={'small'} style={{ color: 'red' }} />}
                {type === ESTATE_TYPE_RENT_ID && ` в месяц ${!utilitiesIncluded ? '+ коммуналка' : 'с коммуналкой'}`}
                {priceByMeter && (
                    <Fragment>
                        <Bull />({priceByMeter} {estateL10n.CURRENCY_TYPES_FIND(currency)} за м²)
                    </Fragment>
                )}
            </Typography>
            <EstatePreviewCardContent estate={estate} />
        </CardContent>
    );
};

export default observer(EstatePreviewContent);
