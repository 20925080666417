import React, { ChangeEvent, useMemo, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import kanbanStore from '~/stores/kanbanStore';
import BgColorPicker, { colorSet } from './BgColorPicker';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import deepCopy from '~/common/deepCopy';
import ConfirmTrigger from '~ui/ConfirmTrigger';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    success: {
        color: palette.success.dark
    },
    titleField: {
        display: 'flex',
        marginRight: spacing(6)
    }
}));

const BoardListFieldEditing = ({ board_id, onClose, list_id }: { board_id: number; onClose: () => void; list_id: number }) => {
    const classes = useStyles();

    useMemo(() => {
        const { editingItem, item } = kanbanStore.getItem(board_id);
        const { lists } = editingItem;

        if (list_id === CREATING_ITEM_ID) {
            const { textColor, bgColor } = colorSet[0];
            kanbanStore.setEditingItem(board_id, {
                lists: [
                    ...lists,
                    {
                        title: '',
                        order: 0,
                        list_id: 0,
                        textColor,
                        bgColor,
                        enable: true
                    }
                ]
            });
        } else {
            kanbanStore.setEditingItem(board_id, { lists: deepCopy(item.lists) });
        }
    }, []);

    const {
        editingItem: { lists }
    } = kanbanStore.getItem(board_id);

    const editingListIndex = list_id === CREATING_ITEM_ID ? lists.length - 1 : lists.findIndex(list => list.list_id === list_id);

    const { title, bgColor } = lists[editingListIndex];

    const handleEditTitle = (event: ChangeEvent<HTMLInputElement>) => {
        kanbanStore.changeArrayValue(board_id, 'lists', editingListIndex, 'title', event.target.value);
    };

    const handleChangeColor = useCallback((colorIndex: number) => {
        const { textColor, bgColor } = colorSet[colorIndex];
        kanbanStore.changeArrayValue(board_id, 'lists', editingListIndex, 'bgColor', bgColor);
        kanbanStore.changeArrayValue(board_id, 'lists', editingListIndex, 'textColor', textColor);
    }, []);

    const handleCancel = () => {
        const {
            editingItem: { lists },
            item
        } = kanbanStore.getItem(board_id);

        if (list_id === CREATING_ITEM_ID) {
            lists.splice(lists.length - 1, 1);
            kanbanStore.setEditingItem(board_id, { lists: [...lists] });
        } else {
            kanbanStore.setEditingItem(board_id, { lists: deepCopy(item.lists) });
        }
        onClose();
    };

    const handleDelete = async () => {
        await kanbanStore.deleteBoardList(board_id, editingListIndex);
        onClose();
    };

    const handleAdd = () => {
        kanbanStore.saveItem(board_id);
        onClose();
    };

    return (
        <ListItem dense disableGutters>
            <ListItemIcon onClick={handleCancel}>
                <Tooltip title="Отмена">
                    <IconButton edge="start" color="secondary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={
                    <div className={classes.titleField}>
                        <TextField
                            margin="none"
                            size="small"
                            value={title}
                            onChange={handleEditTitle}
                            autoFocus
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                        />
                        <BgColorPicker color={bgColor} onChange={handleChangeColor} />
                    </div>
                }
            />
            <ListItemSecondaryAction>
                {list_id > CREATING_ITEM_ID && (
                    <ConfirmTrigger
                        trigger={
                            <Tooltip title="Удалить">
                                <IconButton edge="end" color="secondary">
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        header={'Удалить список?'}
                        content={'Все карточки из этого списка станут Неразобранными'}
                        confirmButton={'Удалить'}
                        onConfirm={handleDelete}
                    />
                )}

                <Tooltip title="Сохранить">
                    <IconButton edge="end" onClick={handleAdd} className={classes.success}>
                        {list_id > CREATING_ITEM_ID ? <DoneIcon /> : <AddIcon />}
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default observer(BoardListFieldEditing);
