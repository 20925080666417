import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Sidebar, Segment } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import isEqual from './common/isEqual';
import cs from 'classnames';
import * as crmChangesApi from '~/api/crmChangesApi';

import Header from './components/Header';
import LeftMenu from './components/LeftMenu';
import Bottom from './components/Bottom/index';

import Home from './components/Home';

import Groups from './components/Groups';
import Access from './components/Settings/Access';
import Analytics from './components/Analytics';
import Kpi from './components/PultToolbar/Kpi';
import Learning from './components/Learning';
import Ads from './components/Ads';
import Chat from './components/Chat';
import Settings from './components/Settings';

import UsersList from './components/Lists/Users';
import Contacts from './components/Lists/Contacts';
import ContactsKanban from './components/PultToolbar/ContactsKanban';
import EstateList from './components/Lists/Estate';
import ShowingList from './components/Lists/Showings';
import CellCallList from './components/Lists/CellCalls';
import CallEventsList from './components/Lists/CallEvents';
import TasksList from './components/Lists/Tasks';
import RequestList from './components/Lists/Requests';
import DealList from './components/Lists/Deals';
import WishList from './components/Lists/Wishes';
import MlsFixPrices from './components/Lists/MlsFixPrices';
import Developers from './components/Lists/Developers';

import User from './components/Items/User';
import Contact from './components/Items/Contact';
import Estate from './components/Items/Estate';
import EstatePrintMode from './components/Items/Estate/EstatePrintMode';
import ShowingReport from './components/Items/ShowingReport';
import Task from './components/Items/Task';
import Request from './components/Items/Request';
import Deal from './components/Items/Deal';
import Wish from './components/Items/Wish';
import CallEstate from './components/Items/CallEstate';
import ExportFeed from '~/components/Items/ExportFeed';
import LifeComplex from '~/components/Items/LifeComplex';

import EventModal from './components/CalendarEvents/EventModal';

import { EVENT_TYPE_CALL, EVENT_TYPE_MEETING } from './types/events.types';
import history, { HistoryLocation } from './history';
import commonStore from './stores/commonStore';
import GlobalError from './components/Base/GlobalError';

const preventEvent = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

const SidebarSwitch = ({ location, fullSize }: { location?: HistoryLocation; fullSize: boolean }) => {
    const SidebarContent = (
        <div className={cs({ 'crm-List__sidebar_fullSizePage': fullSize })}>
            <Switch location={location}>
                <Route exact path="/users/list/profile/:user_id/:tabName" component={User} />
                <Route exact path="/users/list/profile/:user_id" component={User} />
                <Route exact path="/contacts/:param/profile/:contact_id/:tabName" component={Contact} />
                <Route exact path="/contacts/:param/profile/:contact_id" component={Contact} />
                <Route exact path="/estate/:param/:base/:estate_id/:tabName" component={Estate} />
                <Route exact path="/estate/:param/:base/:estate_id" component={Estate} />
                <Route exact path="/complex/:param/overview/:newbuilding_id/:tabName" component={LifeComplex} />
                <Route exact path="/complex/:param/overview/:newbuilding_id" component={LifeComplex} />
                <Route exact path="/showings/list/overview/:showing_id/:tabName" component={ShowingReport} />
                <Route exact path="/showings/list/overview/:showing_id" component={ShowingReport} />
                <Route exact path="/tasks/list/overview/:task_id/:tabName" component={Task} />
                <Route exact path="/tasks/list/overview/:task_id" component={Task} />
                <Route exact path="/requests/:param/overview/:request_id/:tabName" component={Request} />
                <Route exact path="/requests/:param/overview/:request_id" component={Request} />
                <Route exact path="/deals/:param/overview/:deal_id/:tabName" component={Deal} />
                <Route exact path="/deals/:param/overview/:deal_id/" component={Deal} />
                <Route exact path="/wishes/list/overview/:wish_id/:tabName" component={Wish} />
                <Route exact path="/wishes/list/overview/:wish_id" component={Wish} />
                <Route exact path="/ads/:param/base/:export_base_id/:tabName" component={ExportFeed} />
                <Route exact path="/ads/:param/base/:export_base_id" component={ExportFeed} />

                <Route exact path="/calls/list/incoming/:phone" component={CallEstate} />
            </Switch>
        </div>
    );

    if (commonStore.isMobile) {
        // Без onScroll={preventEvent} не работает на мобилках кнопка с открытием комментов всплывающая. Она не фиксируется на месте в новом окне
        return <span onScroll={preventEvent}>{SidebarContent}</span>;
    }

    return (
        <span onScroll={preventEvent}>
            <PerfectScrollbar>{SidebarContent}</PerfectScrollbar>
        </span>
    );
};

const MainSwitch = ({ location }: { location?: HistoryLocation }) => (
    <Switch location={location}>
        <Route exact path="/" component={Home} />
        <Route path="/modal/" component={Home} />
        <Route path="/users/groups" component={Groups} />
        <Route path="/users/access" component={Access} />
        <Route path="/users" component={UsersList} />
        <Route path="/tasks" component={TasksList} />
        <Route path="/settings/:tabName" component={Settings} />
        <Route path="/settings" component={Settings} />
        <Route path="/contacts/kanban" component={ContactsKanban} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/showings" component={ShowingList} />
        <Route path="/estate/:param" component={EstateList} />
        <Route path="/calls/list" component={CellCallList} />
        <Route path="/calls/events" component={CallEventsList} />
        <Route path="/requests/:param" component={RequestList} />
        <Route path="/deals/:param" component={DealList} />
        <Route path="/wishes" component={WishList} />
        <Route path="/analytics" component={Analytics} />
        <Route path="/kpi" component={Kpi} />
        <Route path="/learning/:learning_id" component={Learning} />
        <Route path="/learning" component={Learning} />
        <Route path="/ads/:param" component={Ads} />
        <Route path="/ads" component={Ads} />
        <Route path="/chat/:chat_type/:chatId/:itemId" component={Chat} />
        <Route path="/chat/:chat_type/:chatId" component={Chat} />
        <Route path="/chat" component={Chat} />
        <Route path="/spp/fixPrices" component={MlsFixPrices} />
        <Route path="/complex/:param" component={Developers} />

        <Route exact path="/print/:base-:estateIdString" component={EstatePrintMode} />
    </Switch>
);

const SidebarSwitchModal = ({ visible, color, children }) => (
    <Sidebar
        as={Segment}
        animation={commonStore.isMobile ? 'push' : 'overlay'}
        width="very wide"
        direction="right"
        visible={visible}
        basic
        color={color}
        className="crm-List__sidebar_modal"
    >
        {children}
    </Sidebar>
);

const ModalSwitch = ({ location, onClose }: { location?: HistoryLocation; onClose: () => void }) => (
    <Switch location={location}>
        <Route
            exact
            path="/modal/events/:item_type/:item_id"
            component={({ match: { params } }) => {
                const { item_type, item_id } = params;
                return (
                    <EventModal
                        onClose={onClose}
                        item_id={Number(item_id)}
                        contact_id={null}
                        eventType={item_type === 'meetingEvent' ? EVENT_TYPE_MEETING : EVENT_TYPE_CALL}
                    />
                );
            }}
        />
    </Switch>
);

type MainRouterProps = {
    location: HistoryLocation;
    fullSize: boolean;
};

type MainRouterState = {
    isSidebar: boolean;
    isModal: boolean;
    switchFromTab: boolean;
    pathname: string;
    isError: string | null;
};

// @ts-ignore
@withRouter
class MainRouter extends Component<MainRouterProps, MainRouterState> {
    previousLocations = [];

    constructor(props: MainRouterProps) {
        super(props);

        const { pathname } = document.location;
        // TODO: надо понять, когда открывать модальное окно
        const isModal = pathname.includes('/modal/') || pathname.includes('/chat/');
        const isSidebar = !isModal && (pathname.replace(/\/$/, '').match(/\//g) || []).length >= 3;

        this.state = {
            isSidebar,
            isModal,
            switchFromTab: false,
            isError: null,
            pathname
        };

        if (isSidebar || isModal) {
            // При первом заходе с модальным окном, меняем историю, чтобы работала кнопка "назад" (закрыть) окно
            history.replace(isSidebar ? '/' + pathname.split('/').slice(1, 3).join('/') : '/');
            this.previousLocations = [{ ...history.location }];

            history.push(pathname, {
                modal: isSidebar
            });
            props.location.state = { modal: true };

            this.previousLocations.push({ ...history.location });
        } else {
            this.previousLocations = [{ ...history.location }];
        }
    }

    toggleVisibility = () => {
        if (this.state.isSidebar || this.state.isModal) {
            history.goBack();
        }
    };

    static getDerivedStateFromProps(nextProps: MainRouterProps, prevState: MainRouterState) {
        // Skip 2 attempts, because first attempt calls after constructor, second after componentDidMount
        const {
            location: { state, pathname }
        } = nextProps;

        if (state && typeof state.leftMenuIsOpen === 'boolean') {
            commonStore.toggleMenu(state.leftMenuIsOpen);
            history.replace(pathname, { ...state, leftMenuIsOpen: null });
        }

        const isModal = pathname.includes('/modal/');
        const isSidebar = Boolean(state && state.modal && !state.dialog);
        const switchFromTab = Boolean(state && state.switchFromTab);

        return { ...prevState, isSidebar, isModal, switchFromTab, pathname };
    }

    shouldComponentUpdate(nextProps: MainRouterProps, nextState: MainRouterState) {
        if (nextProps.fullSize !== this.props.fullSize) {
            return true;
        } else if (isEqual(nextState, this.state)) {
            return false;
        }

        const { switchFromTab } = nextState;
        const { location } = nextProps;

        if (history.action === 'PUSH' && !switchFromTab) {
            this.previousLocations.push(location);
        } else if (history.action === 'POP') {
            this.previousLocations.pop();
        } else if (history.action === 'REPLACE' || switchFromTab) {
            this.previousLocations[this.previousLocations.length - 1] = location;
        }

        return true;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log('componentDidCatch', error);
        console.log('errorInfo', errorInfo);
        crmChangesApi.sendUiError(error, errorInfo, 'mainRouter');
        this.setState({ isError: error.message });
    }

    render() {
        const { isSidebar, switchFromTab, isModal, isError } = this.state;
        const { location, fullSize } = this.props;

        if (isError) {
            return <GlobalError error={isError} />;
        }

        let subSidebar = false;
        const prevLoc = this.previousLocations[this.previousLocations.length - 2];

        if (isSidebar && prevLoc && prevLoc.state && prevLoc.state.modal && !isModal) {
            subSidebar = true;
        }

        let lastNoneModalLocation;
        let firstModalLocation: HistoryLocation;

        if (isSidebar || isModal) {
            const reverseLocation = [...this.previousLocations].reverse();
            const foundIndex = reverseLocation.findIndex(loc => !loc.state || !loc.state.modal);
            lastNoneModalLocation = reverseLocation[foundIndex];
            firstModalLocation = reverseLocation[foundIndex - 1];
        } else {
            lastNoneModalLocation = location;
        }

        return (
            <Fragment>
                <Sidebar.Pushable>
                    <SidebarSwitchModal visible={isSidebar} color="blue">
                        {isSidebar && (
                            <Sidebar.Pushable>
                                <SidebarSwitchModal visible={subSidebar} color="red">
                                    {subSidebar && <SidebarSwitch fullSize={fullSize} />}
                                </SidebarSwitchModal>
                                <Sidebar.Pusher
                                    dimmed={subSidebar}
                                    onClick={subSidebar ? this.toggleVisibility : null}
                                    className="crm-List__sidebar_pusher"
                                >
                                    <SidebarSwitch fullSize={fullSize} location={firstModalLocation} />
                                </Sidebar.Pusher>
                            </Sidebar.Pushable>
                        )}
                    </SidebarSwitchModal>

                    <Sidebar.Pusher
                        dimmed={isSidebar}
                        onClick={isSidebar ? this.toggleVisibility : null}
                        className="crm-List__sidebar_pusher"
                    >
                        <MainSwitch location={lastNoneModalLocation} />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>

                <ModalSwitch location={location} onClose={this.toggleVisibility} />
            </Fragment>
        );
    }
}

class MainRouterApp extends Component<MainRouterProps> {
    shouldComponentUpdate(nextProps: MainRouterProps) {
        return this.props.fullSize !== nextProps.fullSize;
    }

    render() {
        const { fullSize } = this.props;
        return (
            <Fragment>
                <LeftMenu fullSize={fullSize} />
                <Header fullSize={fullSize} />

                <main className={cs({ 'crm-Container': true, 'crm-Container__fullSize': fullSize })}>
                    <MainRouter {...this.props} />
                </main>

                <Bottom />
            </Fragment>
        );
    }
}

export default MainRouterApp;
