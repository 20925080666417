import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import HouseBlockEditing from '../EditBlocks/HouseBlockEditing';
import ShadowFragment from '../../../Base/ShadowFragment';
import EditingBlockWrapper from '../../EditingBlockWrapper';

import estateL10n from '../../../../L10n/estate.L10n';
import { EditingWrappedProps } from '../../EditingBlockWrapper';

@observer
class HouseBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            buildYear,
            materialType,
            buildingSeries,
            ceilingHeight,
            passengerLiftsCount,
            cargoLiftsCount,
            hasGarbageChute,
            hasRamp,
            parking,
            buildingIsNotReadyYet,
            floorsCount
        } = item || {};

        return (
            <Card fluid color="brown">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Дом</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Год постройки</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={`${buildYear || ''} ${buildingIsNotReadyYet ? ' - еще не сдан' : ''}`}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Этажей</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={floorsCount} />
                                    </Grid.Column>
                                    <Grid.Column>Тип дома</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.HOUSE_MATERIAL_TYPES_FIND(materialType)} />
                                    </Grid.Column>
                                    <Grid.Column>Серия</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={buildingSeries} />
                                    </Grid.Column>
                                    <Grid.Column>Высота потолков</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={ceilingHeight && `${ceilingHeight} см.`} />
                                    </Grid.Column>
                                    <Grid.Column>Лифт</Grid.Column>
                                    <Grid.Column>
                                        {passengerLiftsCount === 0 && cargoLiftsCount === 0 && 'нет'}
                                        {passengerLiftsCount === 1 && '1 пасажирский '}
                                        {passengerLiftsCount > 1 && `${passengerLiftsCount} пасажирских `}
                                        {cargoLiftsCount === 1 && '1 грузовой '}
                                        {cargoLiftsCount > 1 && `${cargoLiftsCount} грузовых `}
                                    </Grid.Column>
                                    <Grid.Column>Мусоропровод</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={typeof hasGarbageChute === 'boolean' && (hasGarbageChute ? 'есть' : 'нет')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Пандус</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={typeof hasRamp === 'boolean' && (hasRamp ? 'есть' : 'нет')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Парковка</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.HOUSE_PARKING_TYPES_FIND(parking)} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <HouseBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, HouseBlockShow);
