import { observable, action } from 'mobx';
import { io as socketIoClient } from 'socket.io-client';

import * as cellCallsApi from '~/api/cell/cellCallsApi';
import { CellCall, CellCallEstateType, CellCallFilter } from '~/types/cellCalls.types';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import deepCopy from '../../common/deepCopy';
import { CALL_TYPE_INCOMING, CONTACT_PHONE_TYPE_ID, USER_PHONE_TYPE_ID } from '~/types/cellCalls.types';
import contactStore from '../contactStore';
import { CREATING_ITEM_ID } from '../prototypes/ItemStore.prototype';
import estateStore from '../estateStore';
import { matchContactTitle } from '~/components/Lists/Contacts/ContactLink';
import { nProgress } from '../helpers/decorators.helpers';
import authStore from '../authStore';
import userStore from '../userStore';
import serverConfig from '~/config/server.config';
import commonStore from '../commonStore';
import { Contact } from '~/types/contacts.types';

type CellCallTypeExtended = CellCallEstateType & CellCall;

class CellCallStore extends ListStorePrototype<CellCallTypeExtended, CellCall, {}, CellCallFilter> {
    orderBy = 'startTime';

    listFilterClear: CellCallFilter = {
        enable: true,
        major_user_id: [],
        group_id: [],
        deltaStartTime: 0,
        direction: 0,
        source_id: 0,
        durationFromSec: null,
        tags_ids: [],
        tagsTogether: false,
        search: ''
    };

    constructor() {
        // @ts-ignore
        super('call_id', 'cellCall', cellCallsApi);
        this.clearFilter();
        if (!commonStore.isMobile) {
            authStore.registerInitFunc(this.initSocketConnection.bind(this));
        }
    }

    async fetchItem(call_id: number) {
        await super.fetchItem(call_id);

        if (call_id > CREATING_ITEM_ID) {
            const { estate_id, source_id, comment } = this.getItem(call_id).item;

            this.setEditingItem(call_id, {
                estate_id,
                source_id,
                comment
            });
        }
    }

    @action
    async changeCallEstateId(call_id: number, estate_id: number): Promise<boolean> {
        this.setEditingItem(call_id, { estate_id, changeOnlyCallEstate: true });
        if (await super.saveItem(call_id)) {
            return true;
        }
        return false;
    }

    checkNewItemIsNotEmpty(newItem?: Partial<CellCallTypeExtended>): boolean {
        const { setMajorUsersFromEstate, ...restCellCall } = newItem;
        return Boolean(Object.keys(restCellCall).length);
    }

    @action
    async saveItem(call_id: number): Promise<Record<string, any> | boolean> {
        this.listErrors = [];

        let { item_id, type, phone } = this.getItem(call_id).item || {};
        let itemContact: Partial<Contact> = {};

        if (type !== USER_PHONE_TYPE_ID) {
            if (!item_id) {
                let { contact_id, ...contact } = deepCopy(contactStore.getItem(item_id || CREATING_ITEM_ID).editingItem);

                contact = { ...contact, phones: [{ phone, phone_id: 0 }] };
                itemContact = contact;

                this.setEditingItem(call_id, { contact_id: Number(item_id), contact });
            } else {
                itemContact = deepCopy(contactStore.getItem(item_id || CREATING_ITEM_ID).editingItem);
                contactStore.saveItem(item_id);
            }
            // if (item_id && Number(item_id) !== CREATING_ITEM_ID) {
            //     await contactStore.saveItem(item_id);
            // } else {
            //     contactStore.setEditingItem(CREATING_ITEM_ID, { phones: [{ phone, phone_id: 0 }] });
            //     item_id = await contactStore.createItem();
            //     await contactStore.fetchItem(item_id);
            // }
        }

        const answer = await super.saveItem(call_id);
        const { errors } = this.getItem(call_id);

        if (errors && errors.length) {
            this.listErrors = errors;
        } else {
            const newContacts = { itemContact, item_id, type: CONTACT_PHONE_TYPE_ID };

            // Дожидаемся предыдущего обновления списка
            window.setTimeout(() => {
                // @ts-ignore
                this.mergeItem(call_id, newContacts);

                this.list.forEach((call, index) => {
                    if (call.phone === phone) {
                        // @ts-ignore
                        this.list[index] = { ...this.list[index], ...newContacts };
                    }
                });

                this.list = [...this.list];

                this.loadingList = true;
                window.setTimeout(() => {
                    this.loadingList = false;
                }, 100);
            }, 0);
        }

        return answer;
    }

    @nProgress
    @action
    async removeCellCall(call_id: number, estate_id: number) {
        this.listErrors = [];

        try {
            await cellCallsApi.removeCellCall(call_id);
            this.mergeList(call_id, { estate_id: undefined });

            try {
                estateStore.getItem(estate_id);
                await estateStore.callsByEstate(estate_id);
            } catch (error) {}
        } catch (errors) {
            this.listErrors = errors;
        }
    }

    @action
    async fetchItemDropdownOptions(search: string) {
        this.loadingDropdownOptions = true;
        this.dropdownList = (
            await cellCallsApi.fetchList(100, 0, 'startTime', 'descending', {
                direction: CALL_TYPE_INCOMING,
                enable: true,
                durationFromSec: null,
                search
            })
        ).list;

        this.itemDropdownOptions = this.dropdownList.map(call => {
            const date = new Date(call.startTime);
            let text = `+${String(call.phone)}`;

            if (call.itemContact) {
                text += ` (${matchContactTitle(call.itemContact) || `№${call.itemContact.contact_id}`})`;
            }
            text += ` :: ${date.toLocaleString()}`;

            return {
                key: call.call_id,
                value: call.call_id,
                text
            };
        });

        this.loadingDropdownOptions = false;
    }

    initSocketConnection() {
        if (serverConfig.callsServerUrl) {
            const socket = socketIoClient(serverConfig.callsServerUrl, {
                forceNew: true,
                autoConnect: true,
                query: { token: authStore.token }
            });
            socket.on('connect', function () {
                console.log('initSocketConnection authenticated');
            });

            socket.on('message', message => {
                const call: CellCall = JSON.parse(message);
                this.receiveCallFromSocket(call);
            });

            socket.on('error', message => {
                console.log('error', message);
            });

            socket.on('reconnect', message => {
                console.log('reconnect', message);
            });

            // socket.on('ping', message => {
            //     console.log('ping', message);
            // });
        }
    }

    @action
    receiveCallFromSocket = (call: CellCall) => {
        // Фетчим контакт только при входящем звонке, а не когда он заканчивается, иначе может быть сбой, если открыта карточка контакта
        if (!call.releaseTime) {
            if (call.type === USER_PHONE_TYPE_ID && call.item_id) {
                userStore.fetchItem(call.item_id);
            } else if (call.type === CONTACT_PHONE_TYPE_ID && call.item_id) {
                contactStore.fetchItem(call.item_id);
            }
        }

        this.callInProgress = call;
    };

    @action
    removeCallInProgress() {
        this.callInProgress = null;
    }

    @observable
    callInProgress: Partial<CellCall> | null = null;
}

export default new CellCallStore();
