import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { List } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import estateStore from '~/stores/estateStore';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import PhonesList from '~/components/Base/PhonesList';
import { Phone } from '~/types/users.types';
import isEqual from '~/common/isEqual';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            marginTop: spacing(1.5),
            display: 'flex'
        },
        content: {
            flex: '1 0 auto'
        },
        cover: {
            width: 80,
            height: 80,
            margin: spacing(1)
        },
        phonesList: {
            marginTop: spacing(0.75)
        }
    })
);

const MlsAgencyShow = React.memo(
    ({
        loading,
        logoUrl,
        title,
        color,
        url,
        inner_id,
        externalPhones
    }: {
        loading: boolean;
        logoUrl: string;
        title: string;
        color: string;
        url: string;
        inner_id: number;
        externalPhones: Phone[];
    }) => {
        const classes = useStyles();

        return (
            <Card className={classes.root}>
                <CardMedia className={classes.cover} image={logoUrl} title={title}>
                    {loading && <Skeleton animation="wave" variant="circle" width={80} height={80} />}
                </CardMedia>
                <CardContent className={classes.content}>
                    <Typography
                        component="a"
                        href={url}
                        target="_blank"
                        variant="h6"
                        style={{ color: `#${color}` }}
                        title="Открыть сайт компании"
                    >
                        {loading ? <Skeleton animation="wave" /> : title}
                    </Typography>
                    <Typography component="p" variant="subtitle1">
                        {loading && <Skeleton animation="wave" />}
                        {!loading && `Внутренний № ${inner_id}`}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" className={classes.phonesList}>
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            <List>
                                <PhonesList phones={externalPhones} />
                            </List>
                        )}
                    </Typography>
                </CardContent>
            </Card>
        );
    },
    isEqual
);

const MlsSubagentShow = React.memo(({ avatarUrl, firstName, lastName }: { avatarUrl: string; firstName: string; lastName: string }) => {
    const classes = useStyles();
    const title = `${firstName} ${lastName}`.trim();

    return (
        <Card className={classes.root}>
            <CardMedia className={classes.cover} image={avatarUrl} title={title} />
            <CardContent className={classes.content}>
                <Typography variant="subtitle1">Агент</Typography>
                <Typography variant="h6">{title}</Typography>
            </CardContent>
        </Card>
    );
});

const MlsContactsShow = ({ estate_id, isForeign }: { estate_id: number; isForeign: boolean }) => {
    const { item, loadingItem } = estateStore.getItem(estate_id);
    let { agency, externalPhones, inner_id, subagent } = item || {};
    const loading = loadingItem || !agency;

    if (isForeign) {
        subagent = { firstName: 'Марина', lastName: 'Фокина', avatarUrl: '', email: '' };
        externalPhones = [{ phone: '79259015490', phone_id: 0 }];
    }

    const { color, title, logoUrl, url } = agency || {};
    const { avatarUrl, email, firstName, lastName } = subagent || {};

    return (
        <>
            <MlsAgencyShow
                loading={loading}
                externalPhones={externalPhones}
                title={title}
                color={color}
                logoUrl={logoUrl}
                url={url}
                inner_id={inner_id}
            />
            {subagent && <MlsSubagentShow firstName={firstName} lastName={lastName} avatarUrl={avatarUrl} />}
        </>
    );
};

export default observer(MlsContactsShow);
