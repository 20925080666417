import React, { useState } from 'react';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import RichDropdown from '~/components/Base/ui/RichDropdown';
import kanbanStore from '~/stores/kanbanStore';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';

type UsersAndGroupsDropdownProps = {
    kanban_list_id: number | null;
    onChange: HandleChangeType;
};

const KanbanListDropdown = ({ kanban_list_id, onChange }: UsersAndGroupsDropdownProps) => {
    const [activeBoardId, setActiveBoardId] = useState<number | null>(null);

    const boardLists = activeBoardId ? kanbanStore.getBoardLists(activeBoardId) : [];
    const { list: boards } = kanbanStore;

    return (
        <RichDropdown
            notMultiple
            placeholder="Доска, Лист"
            value={kanban_list_id}
            options={kanbanStore.allListsDropdown}
            onChange={(event, { value }) => onChange(event, { name: 'kanban_list_id', value: value === '' ? null : value })}
        >
            <Grid container spacing={3} style={{ minWidth: '330px' }}>
                <Grid item xs>
                    <List dense disablePadding>
                        {boards.map(({ board_id, title }) => (
                            <ListItem button key={board_id} onClick={() => setActiveBoardId(board_id)}>
                                <ListItemText primary={title} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>

                <Grid item xs>
                    <List dense disablePadding>
                        {boardLists.map(({ list_id, title, bgColor, textColor }) => (
                            <ListItem
                                button
                                dense
                                disableGutters
                                key={list_id}
                                onClick={event => onChange(event, { name: 'kanban_list_id', value: list_id })}
                            >
                                <ListItemText primary={title} style={{ background: bgColor, color: textColor }} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </RichDropdown>
    );
};

export default observer(KanbanListDropdown);
