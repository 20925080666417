import React, { useState, useMemo, useEffect, Fragment, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { Modal, ModalContent, ModalBtnAction } from '~/components/Base/ui/Modal';
import estateStore from '~/stores/estateStore';

import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import commonStore from '~/stores/commonStore';
import { EstateFilter } from '~/types/estate.types';
import FilterMoreDetailsMain from '~/components/Lists/Estate/FilterBlocks/FilterMoreDetailsMain';
import Grid from '@material-ui/core/Grid';
import FilterCell from '~/components/Lists/Common/FilterCell';
import FilterEstateDeltaTime from '~/components/Lists/Estate/FilterBlocks/FilterEstateDeltaTime';
import { AWAIT_LOADER_SHOW_TIME_MS } from '~/components/Base/LoaderAwait';

const FindEstatesModal = ({ estate_id }: { estate_id: number }) => {
    useMemo(() => {
        estateStore.clearFilter();
    }, []);
    useEffect(() => {
        return () => {
            estateStore.clearFilter();
        };
    }, []);

    const { isMobile } = commonStore;

    const [showFilter, setShowFilter] = useState(false);
    const handleToggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const { loadingList, listCount } = estateStore;

    const handleReset = () => {
        estateStore.startLoading();
        estateStore.clearFilter();
        // чтобы LoaderAwait отобразился
        setTimeout(() => {
            estateStore.debounceFilterFetch();
        }, AWAIT_LOADER_SHOW_TIME_MS);
    };

    const modalActions: ModalBtnAction[] = [
        { onClick: handleReset, label: 'Очистить', variant: 'text' },
        {
            onClick: handleToggleFilter,
            label: `Закрыть (${listCount.toLocaleString()})`,
            variant: 'contained',
            color: 'secondary',
            loading: loadingList
        }
    ];

    const handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: keyof EstateFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        estateStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    return (
        <Fragment>
            <Tooltip title="Настройка фильтра">
                <IconButton onClick={handleToggleFilter}>
                    <FilterListIcon fontSize="large" />
                </IconButton>
            </Tooltip>
            {showFilter && (
                <Modal
                    fullScreen={isMobile}
                    header="Уточните детали для поиска"
                    onClose={handleToggleFilter}
                    actions={modalActions}
                    maxWidth="sm"
                    contrastHeader
                >
                    <ModalContent dividers contrast>
                        <FilterMoreDetailsMain handleChange={handleChange} />

                        <FilterCell title="Актуальность" hideDivider>
                            <Grid item xs={6}>
                                <FilterEstateDeltaTime />
                            </Grid>
                        </FilterCell>
                    </ModalContent>
                </Modal>
            )}
        </Fragment>
    );
};

export default observer(FindEstatesModal);
