import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import authStore from '~/stores/authStore';
import wishStore from '~/stores/wishStore';

import WishTableBody from './WishTableBody';
import WishTableHeader from './WishTableHeader';

import MapIcon from '@material-ui/icons/Map';

import ListFooter from '../Common/ListFooter';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import FilterWishes from './FilterWishes';
import ListError from '../../ListError';
import { MIN_LIST_COUNT_TO_DEADER_PAGIN } from '../Estate';
import commonStore from '~/stores/commonStore';
import ListPagination from '../Common/ListPagination';
import ButtonGroup, { ButtonInSet } from '~/components/Base/ButtonGroup';
import { ESTATE_BASE_FREE, ESTATE_BASE_MAIN, ESTATE_BASE_MY } from '~/types/estate.types';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import settingStore from '~/stores/settingStore';
import ButtonUs from '~ui/Button';
import WishesMapView from './MapView/WishesMapView';
import FilterEstateRegion from '~/components/Lists/Estate/FilterBlocks/FilterEstateRegion';

export const wishesListMenuOrder: ButtonInSet[] = [
    [ESTATE_BASE_MY, 'Мои'],
    [ESTATE_BASE_FREE, 'Свободные'],
    [ESTATE_BASE_MAIN, 'Компания']
];

const WishList = ({ orderBy, orderDirection, handleSort }: ListWrapperProps) => {
    const handleChangeRegion: HandleChangeType = (event, { value }) => {
        wishStore.changeFilter('regionId', value);
    };

    const handleChangeBase: HandleChangeType = (event, { value }) => {
        wishStore.changeFilterBase(value);
    };

    const { loadingList, list, listCount, pagination, listErrors, nextListLoaded, listFilter, modeSearchOnMap } = wishStore;
    const { enable, base, regionId } = listFilter;
    const { isMobile } = commonStore;

    if (modeSearchOnMap) {
        return <WishesMapView />;
    }

    return (
        <Fragment>
            <div className="crm-List">
                <div className="crm-List__filterType">
                    <Header as="h3">
                        <Icon name="spy" />
                        <Header.Content className="crm-List__filterType_headerContent">
                            <FilterEstateRegion onChange={handleChangeRegion} value={regionId} />
                        </Header.Content>
                    </Header>

                    <div className="crm-List__filterType_topBtns">
                        {settingStore?.mainConfig?.enableWishBooking && (
                            <ButtonGroup
                                buttonSet={wishesListMenuOrder}
                                name="base"
                                value={base}
                                handleChange={handleChangeBase}
                                stackable={isMobile}
                            />
                        )}

                        {!commonStore.isMobile && (
                            <ButtonUs
                                startIcon={<MapIcon />}
                                variant="contained"
                                color="secondary"
                                size="small"
                                disableElevation
                                onClick={() => wishStore.toggleSearchOnMap()}
                                style={{ marginLeft: '1rem' }}
                            >
                                карта
                            </ButtonUs>
                        )}

                        {!enable && <span className="crm-Estate__filter_archiveLabel">(Архив)</span>}
                    </div>

                    {listErrors && <ListError errors={listErrors} />}
                </div>

                {authStore.canCreate(wishStore.moduleName) && (
                    <div className="crm-List__addButton">
                        <Button
                            icon
                            labelPosition="left"
                            primary
                            size="tiny"
                            as={Link}
                            to={{
                                pathname: `/wishes/list/overview/${CREATING_ITEM_ID}`,
                                state: {
                                    modal: true,
                                    callback: { storeName: 'wishStore', method: 'fetchList' }
                                }
                            }}
                        >
                            <Icon name="spy" /> Добавить
                        </Button>
                    </div>
                )}

                <ListError errors={listErrors} />
            </div>

            <Segment.Group className="crm-Segment" id="crm_ListTop">
                <FilterWishes>
                    <Segment className="crm-List__segment">
                        <Table structured size="small" sortable compact>
                            {!commonStore.isMobile && listCount > MIN_LIST_COUNT_TO_DEADER_PAGIN && (
                                <Table.Header fullWidth className="crm-List__pagination_header">
                                    <ListPagination
                                        loading={false}
                                        colSpan={6}
                                        pagination={pagination}
                                        listCount={listCount}
                                        pageChange={wishStore.pageChange}
                                        pageSizeChange={wishStore.pageSizeChange}
                                        nextListLoaded={nextListLoaded}
                                    />
                                </Table.Header>
                            )}

                            <WishTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />
                            <WishTableBody wishList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={6}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={wishStore.pageChange}
                                pageSizeChange={wishStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </FilterWishes>
            </Segment.Group>
        </Fragment>
    );
};

export default ListWrapper(wishStore, observer(WishList));
