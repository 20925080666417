import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';

import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';
import LoaderAwait from '~/components/Base/LoaderAwait';
import { UploadingPhoto } from '~/types/estate.types';
import PhotoGalleryLightbox from '~/components/Base/Photos/PhotoGalleryLightbox';

const AWAIT_FOR_PHOTOS_SHOWING_MS = 400;

type PhotoGalleryProps = {
    photos: UploadingPhoto[];
    updatingPhotos: boolean;
    loadingItem: boolean;
};

const PhotoGalleryDesktop = ({ photos, updatingPhotos, loadingItem }: PhotoGalleryProps) => {
    const [waitingForShowing, setWaitingForShowing] = useState(true); // wait while the card will be opened (for good performance)
    const waitingInterval = window.setTimeout(() => {
        setWaitingForShowing(false);
    }, AWAIT_FOR_PHOTOS_SHOWING_MS);

    useEffect(() => {
        return () => {
            clearInterval(waitingInterval);
        };
    }, []);

    const [photosModalShow, setPhotosModalShow] = useState(false);
    const togglePhotosModalShow = () => {
        setPhotosModalShow(!photosModalShow);
    };

    const [currentImage, setCurrentImage] = useState<number | null>(null);
    const handleImageNumber = (index: number) => {
        setPhotosModalShow(true);
        setCurrentImage(index);
    };

    const photoSet = !updatingPhotos ? photos && photos.map(({ filename }) => matchEstateThumbnailPhotoUrl(filename, updatingPhotos)) : [];

    const loadingHasDone = !waitingForShowing && !loadingItem && !updatingPhotos;

    return (
        <Fragment>
            <Grid columns={3} className="crm-Estate__photolist">
                <Grid.Row>
                    {(waitingForShowing || updatingPhotos || loadingItem) &&
                        [0, 1, 2].map(key => (
                            <Grid.Column key={key}>
                                <LoaderAwait active size="medium" />
                            </Grid.Column>
                        ))}

                    {loadingHasDone &&
                        photoSet.slice(0, 3).map(({ src, tempSrc }, i) => {
                            const label = i === 2 &&
                                photos.length > 3 && {
                                    as: 'button',
                                    color: 'brown',
                                    content: `Всего: ${photos.length} фото`,
                                    icon: 'plus',
                                    ribbon: 'right'
                                };

                            return (
                                <Grid.Column key={i}>
                                    {!photos[i].isLoading ? (
                                        <Image
                                            src={src}
                                            bordered
                                            label={label}
                                            onClick={() => handleImageNumber(i)}
                                            style={{ cursor: 'zoom-in' }}
                                        />
                                    ) : (
                                        <Fragment>
                                            <Image src={tempSrc} bordered label={label} />
                                            <LoaderAwait active size="medium" />
                                        </Fragment>
                                    )}
                                </Grid.Column>
                            );
                        })}
                </Grid.Row>
            </Grid>
            {currentImage !== null && (
                <PhotoGalleryLightbox photoSet={photoSet} onClose={() => setCurrentImage(null)} initPhotoIndex={currentImage} />
            )}
        </Fragment>
    );
};

export default PhotoGalleryDesktop;
