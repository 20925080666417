import React from 'react';
import { observer } from 'mobx-react';

import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Button from '~ui/Button';
import settingStore from '~/stores/settingStore';
import { CIAN_EXPORT_BASE_ID } from '~/types/exports.types';

import BaseApiEditingWrapper, { BaseApiEditingWrappedProps } from '../BaseApiEditingWrapper';
import { CIAN_API_KEY, CianApiConfig } from '~/types/settings.types';

const CianApiSettingsField = ({ checkTest, handleEditValue, answer, loadingTest, apiIndex }: BaseApiEditingWrappedProps) => {
    const { editingItem, loadingItem } = settingStore.getItem(CIAN_API_KEY);

    let apiKey = '';
    if (editingItem?.value) {
        const settings: CianApiConfig = JSON.parse(editingItem?.value);
        apiKey = settings[apiIndex].apiKey;
    }

    return (
        <Grid>
            <Grid.Column width={9}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="ACCESS KEY"
                    loading={loadingItem}
                    value={apiKey}
                    name="apiKey"
                    onChange={handleEditValue}
                />
            </Grid.Column>
            <Grid.Column width={3}>
                <Button
                    color={!answer ? 'secondary' : 'primary'}
                    loading={loadingItem || loadingTest}
                    disabled={Boolean(answer)}
                    onClick={checkTest}
                >
                    {!answer ? 'Протестировать' : 'Ключ сохранен'}
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default BaseApiEditingWrapper(observer(CianApiSettingsField), CIAN_EXPORT_BASE_ID, CIAN_API_KEY);
