import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as newbuildingsApi from '~/api/newbuildingsApi';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';

import useDebounce from '~/common/useDebounce';
import { Newbuilding } from '~/types/newbuildings.types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Button from '~ui/Button';
import Progress from '~ui/Progress';
import ListError from '~/components/ListError';

const SaveNewbuildingBtn = observer(
    ({
        newbuilding_id,
        developer_feed_id,
        domclick_lc_id
    }: {
        newbuilding_id: number | null;
        developer_feed_id: number;
        domclick_lc_id: number;
    }) => {
        const { developerFeedInfo } = developerFeedStore.getItem(developer_feed_id).property;

        const [loading, setLoading] = useState(false);
        const [errors, setErrors] = useState<string[]>([]);

        const { address, geo_lat, geo_lon, title } = developerFeedStore.getDomclickComplex(developer_feed_id, domclick_lc_id);

        const handleSave = async (): Promise<void> => {
            try {
                setErrors([]);
                setLoading(true);
                await newbuildingsApi.updateDomclickNewbuilding({
                    developer: developerFeedInfo.developer.name,
                    domclick_lc_id,
                    address,
                    newbuilding_id,
                    geo_lat,
                    geo_lon,
                    title
                });
                await developerFeedStore.checkDomclickFeed(developer_feed_id);
                await developerFeedStore.fetchNewbuildingHouses(developer_feed_id, domclick_lc_id);
            } catch (errors) {
                setErrors(errors);
            } finally {
                setLoading(false);
            }
        };

        return (
            <>
                <ListError errors={errors} />
                <Button variant="contained" primary disabled={!newbuilding_id} onClick={handleSave} loading={loading}>
                    Сохранить
                </Button>
            </>
        );
    }
);

const renderNewbuilding = ({ address, title }: { address: string; title: string }) => (
    <Box>
        <Typography>{title}</Typography>
        <Typography variant="caption">{address}</Typography>
    </Box>
);

const LCFeedNewbuildingFind = ({ developer_feed_id, domclick_lc_id }: { developer_feed_id: number; domclick_lc_id: number }) => {
    const [newbuildingsQuery, setNewbuildingsQuery] = useState('');
    const [newbuildings, setNewbuildings] = useState<Newbuilding[]>([]);
    const [loading, setLoading] = useState(false);
    const [newbuilding_id, setNewbuildingId] = useState<number | null>(null);

    const debounceQuery = useDebounce(newbuildingsQuery);

    const handleTypingValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewbuildingsQuery(event.target.value);
    };

    useEffect(() => {
        if (!debounceQuery) return;

        setLoading(true);
        newbuildingsApi
            .fetchNewbuildingsSuggestions(debounceQuery, null, 20)
            .then(setNewbuildings)
            .finally(() => setLoading(false));
    }, [debounceQuery]);

    const newbuildingsList = newbuildings.map(({ address, name, newbuilding_id }, i) => ({
        key: newbuilding_id,
        id: newbuilding_id,
        address,
        title: name
    }));

    const handleChange = (_, { id, title }: { id: number; title: string }) => {
        setNewbuildingId(id);
        setNewbuildingsQuery(title);
    };

    return (
        <>
            <Box style={{ width: '100%' }}>
                <Autocomplete
                    freeSolo
                    options={newbuildingsList}
                    disableClearable
                    filterOptions={opt => opt}
                    getOptionLabel={({ title, address }) => title}
                    onChange={handleChange as any}
                    renderOption={renderNewbuilding}
                    renderInput={params => (
                        <TextField
                            onChange={handleTypingValue}
                            label="Поиск ЖК"
                            variant="outlined"
                            fullWidth
                            InputProps={{ ...params.InputProps, type: 'search' }}
                            inputProps={{ ...params.inputProps, value: newbuildingsQuery || '' }}
                            // style={variant === 'classic' ? { background: 'white' } : null}
                            size="medium"
                        />
                    )}
                />
                <Progress show={loading} linear />
            </Box>
            <SaveNewbuildingBtn newbuilding_id={newbuilding_id} developer_feed_id={developer_feed_id} domclick_lc_id={domclick_lc_id} />
        </>
    );
};

export default observer(LCFeedNewbuildingFind);
