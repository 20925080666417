import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';

import wishStore from '~/stores/wishStore';

import WishAddMode from './WishAddMode';
import WishShowMode from './WishShowMode';
import WishTabMenu from './WishTabMenu';
import WishEstatesMode from './WishEstatesMode';
import WishHistoryMode from './WishHistoryMode';
import { matchTabLabel, MenuItem } from '../TabMenuWrapper';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PageviewIcon from '@material-ui/icons/Pageview';
import HistoryIcon from '@material-ui/icons/History';

const SEARCH_ESTATE_TAB_ID = 'search';

@observer
class WishItem extends Component<ItemWrapperState> {
    componentDidUpdate() {
        const { item_id } = this.props;
        const { item, loadingItem, property } = wishStore.getItem(item_id);
        if (item && !loadingItem && !property.locationType) {
            wishStore.firstFetchEstatesByWish(item_id);
        }
    }

    render() {
        const { item_id, tabName, param } = this.props;

        const { errors, item, property } = wishStore.getItem(item_id);
        const { enable, major_user_ids, contact_id } = item || {};

        const foundEstatesCount = property.estates ? property.estates.length : undefined;

        const wishTabs: MenuItem[] = [
            { name: 'Описание', id: '', color: 'blue', icon: <AccountBalanceWalletIcon /> },
            {
                name: 'Подходящие объекты',
                id: SEARCH_ESTATE_TAB_ID,
                color: 'yellow',
                label: matchTabLabel(foundEstatesCount),
                icon: <PageviewIcon />
            },
            { name: 'История', id: TAB_ID_HISTORY, color: 'brown', icon: <HistoryIcon /> }
        ];

        return (
            <Fragment>
                <WishTabMenu
                    itemTabs={wishTabs}
                    item_id={item_id}
                    param={param}
                    tabName={tabName}
                    enable={Boolean(enable)}
                    major_user_id={major_user_ids ? Array.from(major_user_ids) : []}
                    contact_id={Number(contact_id)}
                />

                <div className="crm-List__sidebar_modalBox">
                    <ItemErrors errors={errors} tabName={tabName} />
                    {tabName === TAB_ID_DEFAULT && <WishShowMode wish_id={item_id} />}
                    {tabName === TAB_ID_CREATING && <WishAddMode />}
                    {tabName === SEARCH_ESTATE_TAB_ID && <WishEstatesMode wish_id={item_id} />}
                    {tabName === TAB_ID_HISTORY && <WishHistoryMode item_id={item_id} />}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(wishStore, WishItem, () => true);
