import React, { ReactNode } from 'react';
import { KPI_TYPE, KpiPoint, KpiStatistics } from '~/types/kpi.types';

import { TableCell, TableRow, SortDirection } from '~ui/Table';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';

import StatisticsCell from './StatisticsCell';

const POINT_KPI = 0;

const takeSortPointValue = (stat: KpiStatistics, sortPoint: number): number => {
    if (sortPoint === POINT_KPI) {
        return stat['kpi'];
    }
    const point = stat.pointsValue.find(({ point_id }) => point_id === sortPoint);
    return point?.value;
};

const sortStat = (statistics: KpiStatistics[], sortDirection: SortDirection, sortPoint: number): KpiStatistics[] => {
    if (!sortDirection) {
        return statistics;
    }
    return statistics.sort((s1, s2) => {
        if (sortDirection === 'asc') {
            return takeSortPointValue(s1, sortPoint) > takeSortPointValue(s2, sortPoint) ? 1 : -1;
        } else {
            return takeSortPointValue(s1, sortPoint) > takeSortPointValue(s2, sortPoint) ? -1 : 1;
        }
    });
};

const TableKpiBody = ({
    statistics,
    sortDirection,
    sortPoint,
    points,
    redValue,
    greenValue
}: {
    statistics: KpiStatistics[];
    sortDirection: SortDirection;
    sortPoint: number;
    points: Partial<KpiPoint>[];
    redValue: number;
    greenValue: number;
}) => {
    return (
        <>
            {sortStat(statistics, sortDirection, sortPoint).map((stat, index) => {
                const { kpi, pointsValue } = stat;

                let AboutCell: ReactNode;
                let key: number;

                if (stat.kpiType === KPI_TYPE.single) {
                    const { major_user, major_user_id } = stat;
                    key = major_user_id;
                    AboutCell = (
                        <TableCell>
                            <UserLinkWithPreview avatar user={major_user} />
                        </TableCell>
                    );
                } else {
                    const { group, group_id, usersAccessCount } = stat;
                    key = group_id;
                    AboutCell = (
                        <>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>{group.chief ? <UserLinkWithPreview icon user={group.chief} /> : '---'}</TableCell>
                            <TableCell className="crm-Estate__fieldNowrap">
                                {usersAccessCount} ({group.usersCount})
                            </TableCell>
                        </>
                    );
                }

                return (
                    <TableRow key={key}>
                        <TableCell>{index + 1}</TableCell>
                        {AboutCell}
                        <StatisticsCell value={kpi} greenValue={greenValue} redValue={redValue} />
                        {pointsValue.map(({ value, point_id, pointType }, index) => {
                            const { greenValue, redValue } = points[index];
                            return <StatisticsCell key={point_id} value={value} greenValue={greenValue} redValue={redValue} />;
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};

export default TableKpiBody;
