import React, { Component, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Switch, Route, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import ExploreIcon from '@material-ui/icons/Explore';
import HistoryIcon from '@material-ui/icons/History';

import ItemErrors from '../ItemErrors';
import { ItemWrapperState, TAB_ID_HISTORY } from '../ItemWrapper';
import ItemWrapper, { TAB_ID_CREATING, TAB_ID_DEFAULT } from '../ItemWrapper';
import showingStore from '~/stores/showingStore';

import ShowingReportAddMode from './ShowingReportAddMode';
import ShowingReportShowMode from './ShowingReportShowMode';
import ShowingReportTabMenu from './ShowingReportTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';
import ShowingHistoryMode from '~/components/Items/ShowingReport/ShowingHistoryMode';

type ShowingReportItemProps = RouteComponentProps<{}> & ItemWrapperState;

@observer
class ShowingReportItem extends Component<ShowingReportItemProps> {
    render() {
        const { item_id, tabName, param } = this.props;

        const { errors, loadingItem, item } = showingStore.getItem(item_id);

        const enable = Boolean(item ? item.enable : true);
        const user_id = Number(item && item.major_user_id);
        const contact_id = Number(item && item.contact_id);

        const showingTabs: MenuItem[] = [
            { name: 'Описание', id: '', icon: <ExploreIcon /> },
            { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
        ];

        return (
            <Fragment>
                <ShowingReportTabMenu
                    itemTabs={showingTabs}
                    item_id={item_id}
                    param={param}
                    tabName={tabName}
                    enable={enable}
                    major_user_id={user_id}
                    contact_id={contact_id}
                />

                <div className="crm-List__sidebar_modalBox">
                    {errors && <ItemErrors errors={errors} tabName={tabName} />}
                    {tabName === TAB_ID_DEFAULT && <ShowingReportShowMode loading={loadingItem} showing_id={item_id} enable={enable} />}
                    {tabName === TAB_ID_CREATING && <ShowingReportAddMode />}
                    {tabName === TAB_ID_HISTORY && <ShowingHistoryMode item_id={item_id} />}
                </div>
            </Fragment>
        );
    }
}

export default ItemWrapper(showingStore, withRouter(ShowingReportItem));
