import React, { Component, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import contactStore from '~/stores/contactStore';

type ItemDropdownProps = {
    item_id?: number;
    onChange: (contact_id: number) => void;
    upward?: boolean;
};

type ItemDropdownState = {
    item_id: number | null;
};

@observer
class ContactDropdown extends Component<ItemDropdownProps, ItemDropdownState> {
    constructor(props: ItemDropdownProps) {
        super(props);

        this.state = {
            item_id: props.item_id || null
        };

        contactStore.changeItemDropdownSearch('');
    }

    UNSAFE_componentWillReceiveProps(nextProps: ItemDropdownProps) {
        this.setState({
            item_id: nextProps.item_id || null
        });
    }

    handleItemId = (event: React.SyntheticEvent, { value }: { value: number }) => {
        this.props.onChange(value);
        this.setState({
            item_id: value
        });
    };

    handleSearchChange = (event: React.SyntheticEvent, { searchQuery }: { searchQuery: string }) => {
        contactStore.changeItemDropdownSearch(searchQuery);
    };

    render() {
        const { itemDropdownOptions, loadingDropdownOptions } = contactStore;

        const { item_id } = this.state;

        return (
            <Dropdown
                upward={this.props.upward}
                fluid
                selection
                options={itemDropdownOptions}
                search
                placeholder="Выбрать контакт"
                value={item_id}
                noResultsMessage="Контакт не найден"
                loading={loadingDropdownOptions}
                onSearchChange={this.handleSearchChange}
                onChange={this.handleItemId}
            />
        );
    }
}

export default ContactDropdown;
