import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon, Popup, Loader } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';

import UserSEOEditing from '../EditBlocks/UserSEOEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';

import usersL10n from '../../../../L10n/users.L10n';
import estateStore from '~/stores/estateStore';

@observer
class UserSEOShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = userStore.getItem(item_id);
        const { promoDescription, isSiteProfileShow, siteStatus } = item || {};

        return (
            <Card fluid color="olive">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Сайт (SEO)</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        Отображение на сайте:&nbsp;
                                        {isSiteProfileShow ? (
                                            <Icon name="check" title="Отображается" color="green" />
                                        ) : (
                                            <Icon name="close" color="red" title="Отображается" />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Popup
                                            trigger={
                                                <b>
                                                    <Icon
                                                        circular
                                                        color="blue"
                                                        name="info"
                                                        size="small"
                                                        title="Промо Текст"
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    Промо Текст
                                                </b>
                                            }
                                            flowing
                                            on="hover"
                                            size="tiny"
                                            position="bottom left"
                                            wide="very"
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: promoDescription || 'Текст не задан' }} />
                                        </Popup>
                                    </Grid.Column>
                                    <Grid.Column>Промо статус</Grid.Column>
                                    <Grid.Column>{usersL10n.SITE_STATUS_FIND(siteStatus)}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <UserSEOEditing item_id={item_id} editing={editing} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(userStore, UserSEOShow);
