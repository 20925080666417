import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { ESTATE_BASE_FOREIGN, ESTATE_PROPERTY_FLATS_ARR } from '~/types/estate.types';

import Grid from '@material-ui/core/Grid';

import estateStore from '~/stores/estateStore';

import FilterEstateMain from './FilterEstateMain';
import PropertyTypeBlock from './PropertyTypeBlock';
import FilterCell from '~/components/Lists/Common/FilterCell';

import { WarningMessage } from '~ui/Message';
import TextInput from '~ui/TextInput';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';

const FilterMoreDetailsMain = ({ handleChange }: { handleChange: HandleChangeType }) => {
    const checkPropertyTypes = (propertySet: number[]): boolean => {
        const { propertyTypes } = estateStore.listFilter;
        return propertyTypes.length > 0 && propertyTypes.every(type => propertySet.includes(type));
    };

    const isFlatsOnly = (): boolean => {
        return checkPropertyTypes(ESTATE_PROPERTY_FLATS_ARR);
    };

    const { descriptionContains, propertyTypes, type, base, streetContains, townContains, newbuildingContains } = estateStore.listFilter;
    const magicProps: any = {};

    const isForeign = base === ESTATE_BASE_FOREIGN;

    return (
        <Fragment>
            <FilterCell title="Тип недвижимости">
                <Grid item sm={6} xs={12}>
                    <PropertyTypeBlock propertyTypes={propertyTypes} type={type} base={base} handleChange={handleChange} />
                </Grid>
                {propertyTypes.length === 0 && (
                    <Grid item xs={12}>
                        <WarningMessage>Уточните тип недвижимости для расширения фильтра</WarningMessage>
                    </Grid>
                )}
            </FilterCell>

            <FilterEstateMain
                isForeign={isForeign}
                simpleMode={isForeign}
                store={estateStore}
                {...estateStore.listFilter}
                handleChange={handleChange}
                {...magicProps}
            />

            <FilterCell title="Разное" titleFullWidth>
                <Grid item xs={6}>
                    <TextInput
                        type="search"
                        value={descriptionContains}
                        name="descriptionContains"
                        onChange={handleChange}
                        label="Описание содержит"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput type="search" value={streetContains} name="streetContains" onChange={handleChange} label="Улица" fullWidth />
                </Grid>

                <Grid item xs={6}>
                    <TextInput label="Город" type="search" value={townContains} name="townContains" onChange={handleChange} fullWidth />
                </Grid>
                {!isForeign && isFlatsOnly() && (
                    <Grid item xs={6}>
                        <TextInput
                            type="search"
                            value={newbuildingContains}
                            name="newbuildingContains"
                            onChange={handleChange}
                            label="ЖК"
                            fullWidth
                        />
                    </Grid>
                )}
            </FilterCell>
        </Fragment>
    );
};

export default observer(FilterMoreDetailsMain);
