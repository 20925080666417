import React, { ChangeEvent, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { KPI_TYPE, KpiType } from '~/types/kpi.types';

import accessStore from '~/stores/accessStore';
import kpiStore from '~/stores/kpiStore';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';
import { NumberInput } from '~ui/TextInput';
import { StyledToggleButtonGroup } from '~ui/FilterPult';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ConfirmTrigger from '~ui/ConfirmTrigger';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Switch } from '~ui/Checkbox';
import InfoPopup from '~/components/Base/InfoPopup';

const useStyles = makeStyles(({ palette, spacing }) => ({
    success: {
        color: palette.success.dark
    },
    titleField: {
        display: 'flex',
        marginRight: spacing(6)
    },
    root: {
        borderBottom: `2px solid ${palette.divider}`
    },
    right: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    }
}));

const MainTableInfo = () => (
    <InfoPopup
        size="small"
        content={
            <>
                <p>Данная таблица будет в разделе "Сводная таблица показателей" вместо стандартной таблицы</p>
                <p>Включить можно только одну таблицу, если включена другая, то предыдущая выключится</p>
            </>
        }
    />
);

const KpiFieldEditing = ({ kpi_id, onClose }: { kpi_id: number; onClose: () => void }) => {
    const classes = useStyles();

    const {
        editingItem: { title, access_ids, redValue, greenValue, kpiType, showOnDesk, useAsMainTable }
    } = kpiStore.getItem(kpi_id);

    const handleEditProp =
        (prop: keyof KpiType) =>
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            kpiStore.setEditingItem(kpi_id, { [prop]: prop === 'title' ? value : Number(value) });
        };

    const handleEditShowInDesk = () => {
        kpiStore.setEditingItem(kpi_id, { showOnDesk: !showOnDesk });
    };

    const handleEditMainTable = () => {
        kpiStore.setEditingItem(kpi_id, { useAsMainTable: !useAsMainTable });
    };

    const handleCancel = () => {
        const { item } = kpiStore.getItem(kpi_id);

        kpiStore.setEditingItem(kpi_id, { title: item.title });
        onClose();
    };

    const handleDelete = async () => {
        await kpiStore.toggleDisableItem(kpi_id, false);
        onClose();
    };

    const handleSave = () => {
        kpiStore.saveItem(kpi_id);
        onClose();
    };

    const { accessModesDropdown } = accessStore;

    const handleAccessIds = (event: ChangeEvent, values: DropdownType[]) => {
        kpiStore.setEditingItem(kpi_id, { access_ids: values.map(({ value }) => Number(value)) });
    };

    const handleKpiType = (event: MouseEvent, kpiType: KPI_TYPE) => {
        kpiStore.setEditingItem(kpi_id, { kpiType });
    };

    return (
        <ListItem dense disableGutters className={classes.root}>
            <ListItemIcon onClick={handleCancel}>
                <Tooltip title="Отмена">
                    <IconButton edge="start" color="secondary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        margin="none"
                        size="small"
                        value={title}
                        onChange={handleEditProp('title')}
                        autoFocus
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item xs={7}>
                    <StyledToggleButtonGroup exclusive size="small" value={kpiType} onChange={handleKpiType}>
                        {[
                            { title: 'Личный', id: KPI_TYPE.single },
                            { title: 'Отдел', id: KPI_TYPE.group }
                        ].map(({ title, id }) => (
                            <ToggleButton value={id} key={id}>
                                {title}
                            </ToggleButton>
                        ))}
                    </StyledToggleButtonGroup>
                </Grid>
                <Grid item xs={5} alignItems="center">
                    <Switch label="Основная таблица" onChange={handleEditMainTable} checked={useAsMainTable} />
                    <MainTableInfo />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={accessModesDropdown || []}
                        getOptionLabel={option => option.text}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map(({ value, text }, index) => {
                                return <Chip key={text} label={text} {...getTagProps({ index })} />;
                            })
                        }
                        defaultValue={(accessModesDropdown || []).filter(({ value }) => access_ids.includes(Number(value)))}
                        renderInput={params => (
                            <TextField {...params} variant="outlined" label="Добавить сотрудников по доступу" placeholder="Доступы" />
                        )}
                        onChange={handleAccessIds}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        label="Красный цвет, до"
                        value={redValue}
                        onChange={handleEditProp('redValue')}
                        fullWidth
                        step={1}
                        min={0}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        label="Зеленый цвет, от"
                        value={greenValue}
                        onChange={handleEditProp('greenValue')}
                        fullWidth
                        step={1}
                        min={0}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Switch label="Показывать сотрудникам" onChange={handleEditShowInDesk} checked={showOnDesk} />
                </Grid>
                <Grid xs={6} className={classes.right}>
                    <ConfirmTrigger
                        trigger={
                            <Tooltip title="Удалить">
                                <IconButton edge="end" color="secondary">
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        header="Удалить kpi и все параметры?"
                        content="Вся история коэффициента kpi удалится"
                        confirmButton="Удалить Kpi"
                        onConfirm={handleDelete}
                    />

                    <Tooltip title="Сохранить">
                        <IconButton edge="end" onClick={handleSave} className={classes.success}>
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default observer(KpiFieldEditing);
