import React, { MouseEvent } from 'react';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Time from '../../Base/Time';
import { estateSearchTitle } from '~/stores/helpers/estate.store.helpers';
import estateStore from '~/stores/estateStore';
import { WarningMessage } from '~ui/Message';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '500px'
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.1em',
        marginTop: '-3px'
    }
}));

export const ListFilterHistoryList = ({ filters, onSelect }: { filters: { time: number; filter: any }[]; onSelect?: () => void }) => {
    const handleSetFilter = (index: number) => {
        estateStore.applySavedFilter(index);
        if (onSelect) {
            onSelect();
        }
    };

    const handleRemoveFilter = (index: number) => {
        estateStore.removeSavedFilter(index);
    };

    return (
        <List dense>
            {filters.length === 0 && <WarningMessage>Нет сохраненных поисков</WarningMessage>}
            {filters.map(({ time, filter }, index) => (
                <ListItem key={time} button onClick={() => handleSetFilter(index)}>
                    <ListItemText primary={estateSearchTitle(filter)} secondary={<Time time={time} />} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleRemoveFilter(index)}>
                            <CloseIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default function ListFilterHistory({ filters }: { filters: { time: number; filter: any }[] }) {
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <button onClick={handlePopoverOpen} className={classes.button}>
                Мои поиски <ExpandMoreIcon color="primary" />
            </button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: classes.paper
                }}
            >
                <ListFilterHistoryList filters={filters} onSelect={handlePopoverClose} />
            </Popover>
        </div>
    );
}
