import React, { Fragment } from 'react';
import InfoPopup from '../../../../Base/InfoPopup';

const NewbuildingBasePopup = () => (
    <InfoPopup
        size="small"
        content={
            <Fragment>
                <p>
                    Весь наш справочник ЖК взят из базы ЦИАН, а названия Жилых Комплексов и их корпусов в разных базах (Циан, Яндекс, Авито)
                    отличаются между собой. Но все базы требует указать номер ЖК из своего внутреннего справочника. Поэтому необходимо
                    вручную один раз выбрать данный корпус в справочнике ЖК этой базы. В следующий раз, при добавление объекта в данном
                    корпусе, сопоставление не потребуется, т.к. ваш выбор будет сохранен.
                </p>
                <p>Искать нужный корпус вы можете как по части адреса (улице, поселку), так и по названию Жилого Комлекса.</p>
                <p>
                    Если вы не сопоставите данный корпус со справочником данной площадки, то ваш объект не выйдет в рекламу в Яндекс или
                    Авито, соответственно.
                </p>
            </Fragment>
        }
    />
);

export default NewbuildingBasePopup;
