import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Icon, Header, Label, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import DealPropertyEditing from '../EditBlocks/DealPropertyEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { EstateLinkType } from '~/types/estate.types';
import EstateLinkWithPreview from '../../../Lists/Estate/EstateLinkWithPreview';

export const DealEstateLink = ({
    estate,
    estateType,
    estateOuterUrl
}: {
    estate?: EstateLinkType | null;
    estateType?: number;
    estateOuterUrl?: string;
}) => {
    const isLink = estateType === 3 && estateOuterUrl && estateOuterUrl.match(/^https?:\/\//);

    return (
        <Fragment>
            {estate && estateType === 1 && <EstateLinkWithPreview estate={estate} />}
            {/*{estate && estateType === 2 && <EstateLinkWithPreview estate={estate} isMls={true} />}*/}
            {isLink && (
                <a target="_blank" rel="noreferrer" href={estateOuterUrl}>
                    Внешний объект <Icon name="external" />
                </a>
            )}
            {!isLink && estateType === 3 && estateOuterUrl && <span>{estateOuterUrl}</span>}
        </Fragment>
    );
};

@observer
class DealPropertyShowing extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { estate, estateType, estateOuterUrl, isExclusive } = item;

        return (
            <Card fluid color="yellow">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">
                                Объект{' '}
                                {isExclusive && (
                                    <Label horizontal color="green">
                                        Эксклюзив
                                    </Label>
                                )}
                            </Header>

                            {item && <DealEstateLink estate={estate} estateType={estateType} estateOuterUrl={String(estateOuterUrl)} />}
                        </Fragment>
                    )}
                    {editing && <DealPropertyEditing item_id={item_id} editing={editing} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealPropertyShowing);
