import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { WishLinkType } from '~/types/wish.types';
import { ESTATE_TYPE_SELL_ID } from '~/types/estate.types';

export type WishLinkProps = {
    wish: WishLinkType;
};

export const WishLinkState = (wish_id: number) => ({
    pathname: `/wishes/list/overview/${wish_id}`,
    state: { modal: true, switchFromTab: false }
});

export const wishTypeTitle = (type: number): string => (type === ESTATE_TYPE_SELL_ID ? 'Покупатель' : 'Арендатор');

const WishLink = ({ wish: { wish_id, type, enable } }: WishLinkProps) => {
    return (
        <Link to={WishLinkState(wish_id)} className={cs({ 'crm-Estate__link': true, 'crm-Item__disabled': !enable })}>
            {wishTypeTitle(type)} №{wish_id}
        </Link>
    );
};

export default WishLink;
