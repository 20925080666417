import React from 'react';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

import { TableHead, TableRow, TableCell } from '~ui/Table';

const adjustDirection = (direction: OrderDirectionType): 'asc' | 'desc' => (direction === 'descending' ? 'desc' : 'asc');

const CellCallTableHeader = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    sortDirection={orderBy === 'startTime' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('startTime')}
                >
                    Дата
                </TableCell>
                <TableCell
                    sortDirection={orderBy === 'item_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('item_id')}
                >
                    Контакт
                </TableCell>
                <TableCell>Тэги</TableCell>
                <TableCell />
                <TableCell
                    sortDirection={orderBy === 'user_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('major_user_id')}
                >
                    Сотрудник
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};

export default CellCallTableHeader;
