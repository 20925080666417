import { ContactLinkType } from './contacts.types';
import { UserLinkType } from './users.types';
import { EstateLinkType } from './estate.types';

export const DEAL_STATUS_OPEN = 1;
export const DEAL_STATUS_DONE = 2;
export const DEAL_STATUS_CLOSE = 3;
export const DEAL_STATUS_BREAK = 5;

export const DEAL_STATUS_MORTGAGE_INIT = 11;
export const DEAL_STATUS_MORTGAGE_APPLY = 12;
export const DEAL_STATUS_MORTGAGE_APPROVE = 13;
export const DEAL_STATUS_MORTGAGE_EXECUTE = 14;
export const DEAL_STATUS_MORTGAGE_EXPIRED = 15;
export const DEAL_STATUS_MORTGAGE_REJECT = 16;

export const DEAL_TYPE_EXCLUSIVE = 'exclusive';
export const DEAL_TYPE_EXCLUSIVE_ID = 3;
export const DEAL_TYPE_ACCOMPANIMENT = 'accompaniment';
export const DEAL_TYPE_ACCOMPANIMENT_ID = 4;
export const DEAL_TYPE_NEWBUILDING = 'newbuilding';
export const DEAL_TYPE_NEWBUILDING_ID = 5;
export const DEAL_TYPE_MORTGAGE = 'mortgage';
export const DEAL_TYPE_MORTGAGE_ID = 6;

export type DealStatusType = null | 0 | 1 | 2 | 3 | 4 | 5 | 11 | 12 | 13 | 14 | 15 | 16;

export const dealOperationsId = {
    sell: 1,
    rent: 2,
    [DEAL_TYPE_EXCLUSIVE]: DEAL_TYPE_EXCLUSIVE_ID,
    [DEAL_TYPE_ACCOMPANIMENT]: DEAL_TYPE_ACCOMPANIMENT_ID,
    [DEAL_TYPE_NEWBUILDING]: DEAL_TYPE_NEWBUILDING_ID
};

export type DealOperationType = keyof typeof dealOperationsId;

export type DealLinkType = {
    readonly deal_id: number;
    type: number;
    enable: boolean;
};

export type DealInBaseType = DealLinkType & {
    dealType: number;

    estateType: DEAL_ESTATE_TYPE;
    estate_id: number | null;
    estateOuterUrl: string | null;

    price: number;
    currency: number;
    isExclusive: boolean;
    deposit: number | null;
    depositTime: number | null;

    releaseTime: number | null;
    exclusiveDeadTime: number | null;
    depositDeadTime: number | null;

    dealOpenTime: number | null;
    dealDoneTime: number | null;
    dealCloseTime: number | null;
    dealBreakTime: number | null;

    status: DealStatusType;
    documentsCount: number;

    parent_deal_id: number | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type DEAL_ESTATE_TYPE = 1 | 2 | 3;

export type DealTableBodyType = DealInBaseType & {
    estate?: EstateLinkType | null;
    usersFees: UserFee[];
};

export type Deal = DealTableBodyType & {
    sellers_ids: number[];
    sellers: ContactLinkType[];
    buyers_ids: number[];
    buyers: ContactLinkType[];

    description: string;

    isMortgage: boolean | null;
    bank_id: number | null;
    mortgagePercent: number | null;
    mortgageDuration: number | null;
    firstDeposit: number | null;

    fee: number;
    feeCurrency: number;

    dealTree: DealInBaseType[];

    major_user_ids: number[];
    major_users: UserLinkType[];
};

export type UserFeeInBase = {
    user_id: number;
    deal_id: number;
    fee: number;
    currency: number;
};

export type UserFee = UserFeeInBase & {
    user?: UserLinkType;
};

export type DealFilter = {
    enable?: boolean;
    group_id?: Array<number>;
    major_user_id?: Array<number>;
    search: string;
    type?: number;
    dealType?: number;
    releaseTimeMin?: number;
    releaseTimeMax?: number;
    deltaCreateTime?: number;
    isExclusive?: boolean | null;
    status?: number | null;
};

export type Bank = {
    bank_id: number;
    enable: boolean;
    title: string;
};
