import React, { Component, Fragment } from 'react';
import { Menu, Input, Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import taskStore from '~/stores/taskStore';

import ListFilterWrapper from '../ListFilterWrapper';
import { TaskFilter } from '~/types/tasks.types';

@observer
class FilterTasks extends Component<{}> {
    render() {
        return null;
    }
}

export default ListFilterWrapper(taskStore, FilterTasks);
