import React, { Fragment } from 'react';
import { Popup } from 'semantic-ui-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ESTATE_PROPERTY_COUNTRY_ARR } from '~/types/estate.types';
import MonoString from '../../../Base/MonoString';
import estateL10n from '../../../../L10n/estate.L10n';

type EstateTableFloorAndHouseBlockProps = {
    propertyType: number;
    floorsCount?: number | null;
    floorNumber?: number | null;
    materialType?: number | null;
    buildYear?: number | null;
    buildingIsNotReadyYet?: boolean | null;
};

export const EstateTableFloors = ({
    floorsCount,
    floorNumber,
    propertyType
}: {
    propertyType: number;
    floorsCount?: number | null;
    floorNumber?: number | null;
}) => {
    return ESTATE_PROPERTY_COUNTRY_ARR.includes(propertyType) ? (
        <MonoString template="?" values={[floorsCount || '-']} />
    ) : (
        <MonoString template="?/?" values={[floorNumber || '-', floorsCount || '-']} />
    );
};

const EstateTableFloorAndHouseBlock = ({
    propertyType,
    floorsCount,
    floorNumber,
    materialType,
    buildYear,
    buildingIsNotReadyYet
}: EstateTableFloorAndHouseBlockProps) => {
    const materialTypeString = materialType
        ? ESTATE_PROPERTY_COUNTRY_ARR.includes(propertyType)
            ? estateL10n.COUNTRY_MATERIAL_TYPE_FIND(materialType)
            : estateL10n.HOUSE_MATERIAL_TYPES_FIND(materialType)
        : '';

    let materialTypeStringShort = materialTypeString;

    if (materialTypeStringShort.length > 5) {
        materialTypeStringShort = materialTypeStringShort.slice(0, 5) + '.';
    }

    return (
        <Popup
            trigger={
                <span>
                    <EstateTableFloors propertyType={propertyType} floorNumber={floorNumber} floorsCount={floorsCount} />
                    <br />
                    {materialTypeStringShort}
                </span>
            }
            size="small"
        >
            <List dense disablePadding>
                <ListItem disableGutters>
                    <EstateTableFloors propertyType={propertyType} floorNumber={floorNumber} floorsCount={floorsCount} />
                </ListItem>
                <ListItem disableGutters>{materialTypeString}</ListItem>
                <ListItem disableGutters>
                    {buildYear && `${buildYear} г. постройки`} {buildingIsNotReadyYet && 'еще не сдан'}
                </ListItem>
            </List>
        </Popup>
    );
};

export default React.memo(EstateTableFloorAndHouseBlock);
