import React, { Fragment, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import kanbanStore from '~/stores/kanbanStore';
import BoardListFieldEditing from './BoardListFieldEditing';
import BoardFieldEditing from './BoardFieldEditing';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { KanbanList } from '~/types/contacts.types';
import LoaderAwait from '~/components/Base/LoaderAwait';
import ListError from '~/components/ListError';

const DragHandle = SortableHandle(() => (
    <ListItemIcon style={{ cursor: 'move' }} title="Поменять местами">
        <DragHandleIcon />
    </ListItemIcon>
));

type BoardListItemProps = {
    list_id: number;
    title: string;
    bgColor: string;
    textColor: string;
    onEdit: (list_id: number | null) => void;
};

const BoardListItem = React.memo(({ list_id, title, bgColor, textColor, onEdit }: BoardListItemProps) => {
    return (
        <ListItem dense disableGutters>
            <DragHandle />
            <ListItemText primary={title} style={{ background: bgColor, color: textColor }} />
            <ListItemSecondaryAction>
                <Tooltip title="Редактировать">
                    <IconButton edge="end" onClick={() => onEdit(list_id)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
});

const SortableItem = SortableElement((props: BoardListItemProps & { editingListId: number | null; board_id: number }) => {
    const { list_id, editingListId, board_id, onEdit, bgColor, textColor, title } = props;

    return (
        <Fragment key={list_id}>
            {editingListId !== props.list_id && (
                <BoardListItem bgColor={bgColor} textColor={textColor} title={title} list_id={list_id} onEdit={onEdit} />
            )}
            {editingListId === props.list_id && (
                <BoardListFieldEditing list_id={list_id} board_id={board_id} onClose={() => onEdit(null)} />
            )}
        </Fragment>
    );
});

const SortableListContainer = SortableContainer(
    ({
        boardLists,
        onEdit,
        editingListId,
        board_id
    }: {
        boardLists: Partial<KanbanList>[];
        onEdit: (list_id: number | null) => void;
        editingListId: number;
        board_id: number;
    }) => (
        <div>
            {boardLists.map(({ list_id, textColor, title, bgColor }, index) => (
                <SortableItem
                    key={list_id}
                    list_id={list_id}
                    board_id={board_id}
                    editingListId={editingListId}
                    index={index}
                    title={title}
                    bgColor={bgColor}
                    textColor={textColor}
                    onEdit={onEdit}
                />
            ))}
        </div>
    )
);

const BoardItemEditing = observer(({ board_id }: { board_id: number }) => {
    const {
        editingItem: { title },
        loadingItem
    } = kanbanStore.getItem(board_id);

    const [editing, setEditing] = useState(false);
    const handleEditing = () => {
        setEditing(!editing);
    };

    if (editing) {
        return <BoardFieldEditing board_id={board_id} onClose={handleEditing} />;
    }

    return (
        <ListItem>
            {loadingItem && <Skeleton variant="text" width={200} />}
            {!editing && (
                <>
                    <ListItemText primary={<Typography variant="h6">{title}</Typography>} />
                    <ListItemSecondaryAction>
                        <Tooltip title="Редактировать Доску">
                            <IconButton edge="end" onClick={handleEditing}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </>
            )}
        </ListItem>
    );
});

const BoardListsEditing = ({ board_id }: { board_id: number }) => {
    useMemo(() => {
        kanbanStore.fetchItem(board_id);
    }, [board_id]);

    let boardLists;
    try {
        boardLists = kanbanStore.getBoardLists(board_id);
    } catch (err) {
        boardLists = [];
    }
    const [editingListId, setEditingListId] = useState<number | null>(null);

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        kanbanStore.moveBoardLists(board_id, oldIndex, newIndex);
    };

    const { loadingItem, errors } = kanbanStore.getItem(board_id);

    return (
        <List dense disablePadding>
            <ListError errors={errors} />

            <BoardItemEditing board_id={board_id} />

            <SortableListContainer
                board_id={board_id}
                boardLists={boardLists}
                onEdit={setEditingListId}
                editingListId={editingListId}
                useDragHandle
                lockAxis="y"
                onSortEnd={onSortEnd}
            />

            {editingListId !== CREATING_ITEM_ID && (
                <ListItem dense disableGutters>
                    <Button color="secondary" variant="text" startIcon={<AddIcon />} onClick={() => setEditingListId(CREATING_ITEM_ID)}>
                        Добавить список
                    </Button>
                </ListItem>
            )}

            {editingListId === CREATING_ITEM_ID && (
                <BoardListFieldEditing list_id={CREATING_ITEM_ID} board_id={board_id} onClose={() => setEditingListId(null)} />
            )}

            <LoaderAwait active={loadingItem} dimmer />
        </List>
    );
};

export default observer(BoardListsEditing);
