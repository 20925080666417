import React, { Component, Fragment } from 'react';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '../../../Base/EditField';
import estateStore from '~/stores/estateStore';
import AddressSearchInput from '../../Common/AddressSearchInput';
import { EstateAddress } from '~/types/estate.types';
import deepCopy from '../../../../common/deepCopy';

type UserInfoBlockEditiongProps = HandlerEditingWrappedProps & {
    main_metro_id?: number[];
    userAddress?: EstateAddress;
};

@observer
class UserGeoEditing extends Component<UserInfoBlockEditiongProps> {
    constructor(props: UserInfoBlockEditiongProps) {
        super(props);

        const user = {
            main_metro_id: props.main_metro_id || [],
            userAddress: deepCopy(props.userAddress)
        };
        userStore.setEditingItem(props.item_id, user);
    }

    handleChangeAddress = (userAddress: EstateAddress) => {
        userStore.setEditingItem(this.props.item_id, { userAddress });
    };

    render() {
        const { item_id, handleChange } = this.props;

        const { main_metro_id, userAddress } = userStore.getItem(item_id).editingItem;

        const address = userAddress ? userAddress.address : '';
        const { metroStationsListLoading, metroStationsListDropdown } = estateStore;

        return (
            <Card.Meta>
                <div className="crm-Item__editingMode">
                    <EditField.Drop
                        label="Метро"
                        loading={metroStationsListLoading}
                        options={metroStationsListDropdown}
                        value={main_metro_id}
                        name="main_metro_id"
                        onChange={handleChange}
                        multiple
                        search
                    />

                    <label className="crm-Estate__field_label">Адрес</label>

                    <AddressSearchInput className="crm-Estate__address_find" value={address} onChange={this.handleChangeAddress} />
                </div>
            </Card.Meta>
        );
    }
}

export default HandlerEditingBlockWrapper(userStore, UserGeoEditing);
