import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Table, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import statisticStore from '~/stores/statisticStore';
import { Bar, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Area, ResponsiveContainer } from 'recharts';
import { CALENDAR_VIEW_TYPES } from '~/types/calendar.types';
import deepCopy from '~/common/deepCopy';

export const StatChartTooltip = ({
    payload,
    label
}: {
    payload?: Array<{ value: number; fill: string; name: string }>;
    label?: string;
}) => {
    return (
        <div className="crm-Statistics__chart_tooltip">
            <Table size="small" compact="very">
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Дата: {`${label || ''}`}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {payload &&
                        payload.map(({ value, fill, name }) => (
                            <Table.Row key={name}>
                                <Table.Cell>
                                    <b>{name}</b>
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                    <b>{value}</b>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </div>
    );
};

const chartMargins = {
    top: 5,
    right: 30,
    left: 20,
    bottom: 5
};

type ChartStatisticsProps = {
    period: CALENDAR_VIEW_TYPES;
};

@observer
class ChartStatistics extends Component<ChartStatisticsProps> {
    constructor(props: ChartStatisticsProps) {
        super(props);
        statisticStore.fetchGlobalChartStatistics(props.period);
    }

    handleChartActive = (index: number) => {
        const { allCharts } = statisticStore;
        allCharts[index].active = !allCharts[index].active;
        statisticStore.allCharts = [...allCharts];
    };

    renderLegend = () => (
        <div>
            {statisticStore.allCharts.map(({ title, color, active }, index) => (
                <Checkbox
                    className="crm-Statistics__chart_legendChecbox"
                    key={`item-${index}`}
                    label={{ children: <b style={{ color }}>{title}</b> }}
                    checked={active}
                    onClick={this.handleChartActive.bind(this, index)}
                />
            ))}
        </div>
    );

    render() {
        const { loadingList, chartStatistics, allChartsByKey } = statisticStore;

        const callsOutgoingChart = allChartsByKey.get('callsOutgoingCount');
        const callsIncomingChart = allChartsByKey.get('callsIncomingCount');
        const openDealsCountChart = allChartsByKey.get('openDealsCount');
        const cianCountChart = allChartsByKey.get('cianCount');
        const cianAddedCountChart = allChartsByKey.get('cianAddedCount');
        const callsToEstateChart = allChartsByKey.get('callsToEstateCount');
        const showingsCountChart = allChartsByKey.get('showingsCount');
        const usersCountChart = allChartsByKey.get('usersCount');

        if (
            !(
                callsOutgoingChart &&
                cianAddedCountChart &&
                callsIncomingChart &&
                openDealsCountChart &&
                cianCountChart &&
                callsToEstateChart &&
                showingsCountChart &&
                usersCountChart
            )
        ) {
            return null;
        }

        return (
            <div className="crm-Statistics__chart">
                <ResponsiveContainer>
                    <ComposedChart data={chartStatistics} margin={chartMargins}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />

                        {(cianCountChart.active ||
                            cianAddedCountChart.active ||
                            callsToEstateChart.active ||
                            showingsCountChart.active ||
                            usersCountChart.active) && <YAxis yAxisId="left" />}
                        {openDealsCountChart.active && <YAxis yAxisId="deals" />}
                        {(callsOutgoingChart.active || callsIncomingChart.active) && <YAxis yAxisId="right" orientation="right" />}

                        <Tooltip content={<StatChartTooltip />} />
                        <Legend verticalAlign="top" height={36} content={this.renderLegend} />

                        {callsOutgoingChart.active && (
                            <Area
                                yAxisId="right"
                                name={callsOutgoingChart.title}
                                type="monotone"
                                dataKey={callsOutgoingChart.key}
                                stroke={callsOutgoingChart.color}
                                fill={callsOutgoingChart.color}
                            />
                        )}
                        {callsIncomingChart.active && (
                            <Area
                                yAxisId="right"
                                name={callsIncomingChart.title}
                                type="monotone"
                                dataKey={callsIncomingChart.key}
                                stroke={callsIncomingChart.color}
                                fill={callsIncomingChart.color}
                            />
                        )}
                        {openDealsCountChart.active && (
                            <Bar
                                yAxisId="deals"
                                name={openDealsCountChart.title}
                                dataKey={openDealsCountChart.key}
                                fill={openDealsCountChart.color}
                            />
                        )}
                        {cianCountChart.active && (
                            <Line
                                yAxisId="left"
                                name={cianCountChart.title}
                                type="monotone"
                                dataKey={cianCountChart.key}
                                fill={cianCountChart.color}
                                stroke={cianCountChart.color}
                            />
                        )}
                        {cianAddedCountChart.active && (
                            <Line
                                yAxisId="left"
                                name={cianAddedCountChart.title}
                                type="monotone"
                                dataKey={cianAddedCountChart.key}
                                fill={cianAddedCountChart.color}
                                stroke={cianAddedCountChart.color}
                            />
                        )}
                        {callsToEstateChart.active && (
                            <Line
                                yAxisId="left"
                                name={callsToEstateChart.title}
                                type="monotone"
                                dataKey={callsToEstateChart.key}
                                fill={callsToEstateChart.color}
                                stroke={callsToEstateChart.color}
                            />
                        )}
                        {showingsCountChart.active && (
                            <Line
                                yAxisId="left"
                                name={showingsCountChart.title}
                                type="monotone"
                                dataKey={showingsCountChart.key}
                                fill={showingsCountChart.color}
                                stroke={showingsCountChart.color}
                            />
                        )}
                        {usersCountChart.active && (
                            <Line
                                yAxisId="left"
                                name={usersCountChart.title}
                                type="monotone"
                                dataKey={usersCountChart.key}
                                fill={usersCountChart.color}
                                stroke={usersCountChart.color}
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>

                <Dimmer active={loadingList} inverted>
                    <Loader active size="medium" />
                </Dimmer>
            </div>
        );
    }
}

export default ChartStatistics;
