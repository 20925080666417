import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import whatsappStore from '~/stores/whatsappStore';

import ListError from '~/components/ListError';

import WAChatHeader from './WhatsappBlocks/WAChatHeader';
import WAContactChat from './WhatsappBlocks/WAContactChat';
import deepCopy from '~/common/deepCopy';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 135px)'
    }
}));

const ContactWhatsappMode = ({ contact_id }: { contact_id: number }) => {
    const classes = useStyles();

    useMemo(() => {
        whatsappStore.initEmptyList(contact_id);
    }, []);

    const { errors, filter, options } = whatsappStore.getItemList(contact_id);
    console.log('filter.whatsapp_chat_id', deepCopy(filter), deepCopy(options));

    return (
        <div className={classes.root}>
            <ListError errors={errors} />
            <WAChatHeader contact_id={contact_id} />

            {filter.whatsapp_chat_id && <WAContactChat contact_id={contact_id} />}
        </div>
    );
};

export default observer(ContactWhatsappMode);
