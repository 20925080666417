import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Header, Icon } from 'semantic-ui-react';

import kanbanStore from '~/stores/kanbanStore';
import contactStore from '~/stores/contactStore';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import KanbanListBlockEditing from '../EditBlocks/KanbanListBlockEditing';
import { EditingWrappedProps } from '../../EditingBlockWrapper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const KanbanBlockList = ({ kanban_list_ids }: { kanban_list_ids: number[] }) => {
    return (
        <List disablePadding dense>
            {kanban_list_ids?.map(list_id => {
                const board = kanbanStore.getListById(list_id);
                return board ? (
                    <ListItem key={list_id}>
                        <ListItemText primary={board.title} secondary={board.lists[0].title} />
                    </ListItem>
                ) : null;
            })}
        </List>
    );
};

@observer
class KanbanBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item } = contactStore.getItem(item_id);
        const { kanban_list_ids } = item || {};

        return (
            <Card fluid color="grey">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">
                                <Icon name="table" color="grey" />
                                Канбан-доски
                            </Header>
                            {kanban_list_ids?.length === 0 && <Typography variant="subtitle1">В неразобранных</Typography>}
                            {kanban_list_ids?.length > 0 && <KanbanBlockList kanban_list_ids={kanban_list_ids} />}
                        </Fragment>
                    )}
                    {editing && <KanbanListBlockEditing contact_id={item_id} kanban_list_ids_default={kanban_list_ids || []} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(contactStore, KanbanBlockShow);
