import React, { ChangeEvent, Component, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import wishStore from '~/stores/wishStore';

import FilterEstateMain from '../../Estate/FilterBlocks/FilterEstateMain';
import { FilterMoreDetailsProps } from '../../ListFilterWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { WarningMessage } from '~ui/Message';
import FilterCell from '~/components/Lists/Common/FilterCell';
import TextInput from '~ui/TextInput';
import { Switch } from '~ui/Checkbox';
import InfoPopup from '~/components/Base/InfoPopup';
import { DEFAULT_NO_CALLS_TIME, deltaNoCallsTimes } from '~/components/Lists/Estate/FilterBlocks/FilterEstateOtherParams';

@observer
class FilterWishMoreDetails extends Component<FilterMoreDetailsProps> {
    handleChangePropertyType = (event: React.SyntheticEvent, { value }: { value: number }) => {
        wishStore.changeFilter('propertyType', value);
        wishStore.changeFilter('propertyTypes', [value]);
    };

    handleNoCalls = (event: ChangeEvent<HTMLInputElement>) => {
        const { type, name, checked } = event.target;
        const oldValue = wishStore.listFilter[name];
        // @ts-ignore
        wishStore.changeFilter(name, oldValue === null ? DEFAULT_NO_CALLS_TIME : null);
    };

    handleMetroChange = (metroIds: number[]) => {
        wishStore.changeFilter('main_metro_id', metroIds);
    };

    render() {
        const { descriptionContains, townContains, propertyType, propertyTypes, noOutgoingToOwnerCallsTime } = wishStore.listFilter;
        const { handleChange } = this.props;

        return (
            <Fragment>
                <FilterCell title="Тип недвижимости">
                    <Grid item sm={6} xs={12}>
                        <Dropdown
                            placeholder="Тип"
                            closeOnChange
                            selection
                            options={estateL10n.PROPERTY_TYPE_DROPDOWN}
                            value={propertyType}
                            onChange={this.handleChangePropertyType}
                            name="propertyType"
                        />
                    </Grid>
                    {propertyTypes.length === 0 && (
                        <Grid item xs={12}>
                            <WarningMessage>Уточните тип недвижимости для расширения фильтра</WarningMessage>
                        </Grid>
                    )}
                </FilterCell>

                <FilterEstateMain
                    store={wishStore}
                    simpleMode
                    {...(wishStore.listFilter as any)}
                    handleChange={handleChange}
                    handleMetroChange={this.handleMetroChange}
                />

                <FilterCell title="Разное" titleFullWidth>
                    <Grid item xs={6}>
                        <TextInput
                            type="search"
                            value={descriptionContains}
                            name="descriptionContains"
                            onChange={handleChange}
                            label="Описание содержит"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput type="search" value={townContains} name="townContains" onChange={handleChange} label="Город" fullWidth />
                    </Grid>
                </FilterCell>

                <FilterCell title="Звонки" titleFullWidth spacing={0} hideDivider>
                    <Grid item xs={12}>
                        <Switch
                            checked={noOutgoingToOwnerCallsTime !== null}
                            name="noOutgoingToOwnerCallsTime"
                            label="Нет звонков Покупателю"
                            onChange={this.handleNoCalls}
                        />
                        {typeof noOutgoingToOwnerCallsTime === 'number' && (
                            <div className="crm-Estate__filter_exportedBases">
                                за &nbsp;
                                <Dropdown
                                    options={deltaNoCallsTimes}
                                    name="noOutgoingToOwnerCallsTime"
                                    value={noOutgoingToOwnerCallsTime}
                                    onChange={handleChange}
                                    closeOnChange
                                    upward
                                    inline
                                />
                            </div>
                        )}
                        <InfoPopup
                            size="small"
                            content="Учитываются состоявшиеся входящие или исходящие звонки, сообщения в WhatsApp на/c номер(а) телефона контакта, который указан в карточке объекта"
                        />
                    </Grid>
                </FilterCell>
            </Fragment>
        );
    }
}

export default FilterWishMoreDetails;
