import React, { Fragment, Component } from 'react';
import { Button } from 'semantic-ui-react';
import ownerStore from '~/stores/ownerStore';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import { adjustPhoneString } from '../../../Items/Common/ItemContacts/ItemContactsEditing';
import { OwnerPhone } from '~/types/owners.types';

type OwnerTableContactsProps = {
    estate_id: number;
    ownerPhone: OwnerPhone;
    ownerName?: string | null;
};

@observer
class OwnerTableContacts extends Component<OwnerTableContactsProps> {
    constructor(props: OwnerTableContactsProps) {
        super(props);

        ownerStore.fetchItem(props.estate_id);
    }

    handleOpenPhone = () => {
        ownerStore.assignOwner(this.props.estate_id);
    };

    render() {
        const {
            estate_id,
            ownerPhone: { phone },
            ownerName
        } = this.props;
        const { item, loadingItem } = ownerStore.getItem(estate_id);
        if (!item) {
            return <Button icon="phone" size="mini" basic loading disabled />;
        }

        const { assignOwnersHistory } = item;
        let theOwnerHasAssigned = false;

        if (assignOwnersHistory) {
            theOwnerHasAssigned = assignOwnersHistory.some(({ user_id }) => user_id === authStore.currentUser.user_id);
        }

        return theOwnerHasAssigned ? (
            <div className="crm-Estate__ownerContactTable">
                <a href={`tel:+${phone}`}>{adjustPhoneString(phone)}</a>
                {ownerName && <b>{ownerName}</b>}
            </div>
        ) : (
            <Button
                icon="phone"
                size="mini"
                basic
                title="Открыть телефон (Получить доступ к Собственнику)"
                onClick={this.handleOpenPhone}
                loading={loadingItem}
                disabled={loadingItem}
            />
        );
    }
}

export default OwnerTableContacts;
