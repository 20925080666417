import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import TableKpi from './TableKpi';
import KpiModal from './KpiModal';
import kpiStore from '~/stores/kpiStore';

import Container from '@material-ui/core/Container';

import TopPultWithButton from '../Common/TopPultWithButton';
import accessStore from '~/stores/accessStore';
import UsersAndGroupsDropdown from '~/components/Lists/Common/UsersAndGroupsDropdown';
import { KPI_TYPE } from '~/types/kpi.types';
import { AccessReadWrapper } from '~/components/Base/NotEnoughAccess';
import statisticStore from '~/stores/statisticStore';
import { ACCESS_COMPANY } from '~/types/access.types';

const Kpi = () => {
    const {
        list,
        listFilter: { kpi_id }
    } = kpiStore;

    const setActiveKpiId = (kpi_id: number | null) => {
        kpiStore.fetchItem(kpi_id);
        kpiStore.changeFilter('kpi_id', kpi_id);
    };

    useEffect(() => {
        accessStore.fetchList();
    }, []);

    useEffect(() => {
        if (list.length > 0) {
            setTimeout(() => {
                const { kpi_id } = list[0];
                setActiveKpiId(kpi_id);
            }, 0);
        }
    }, [list.length]);

    let disableUsers = false;

    try {
        const { kpiType } = kpiStore.getItem(kpi_id).item;
        if (kpiType === KPI_TYPE.group) {
            disableUsers = true;
        }
    } catch {}

    return (
        <TopPultWithButton
            ModalNode={KpiModal}
            store={kpiStore}
            toggleButtons={list.map(({ kpi_id, title }) => ({
                id: kpi_id,
                title
            }))}
            activeToggleId={kpi_id}
            changeToggleId={setActiveKpiId}
            PultFilter={<UsersAndGroupsDropdown disableUsers={disableUsers} store={kpiStore} shortWidth />}
        >
            <Container maxWidth="xl">{list.length > 0 && kpi_id > 0 && <TableKpi kpi_id={kpi_id} />}</Container>
        </TopPultWithButton>
    );
};

export default AccessReadWrapper(observer(Kpi), statisticStore.moduleName, ACCESS_COMPANY);
