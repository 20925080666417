import React, { Component, Fragment } from 'react';
import Divider from '~ui/Divider';
import { observer } from 'mobx-react';
import EditField from '../../../Base/EditField';

import requestStore from '~/stores/requestStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import FilterEstateMain from '../../../Lists/Estate/FilterBlocks/FilterEstateMain';
import { EstateFilterMain, ESTATE_BASES } from '~/types/estate.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type RequestFilterEditingProps = HandlerEditingWrappedProps & {
    requestObj: EstateFilterMain & {
        base: ESTATE_BASES;
        description?: string;
    };
};

@observer
class RequestFilterEditing extends Component<RequestFilterEditingProps> {
    constructor(props: RequestFilterEditingProps) {
        super(props);

        const request = props.requestObj;

        const item = {
            requestObj: {
                base: request.base,

                propertyType: request.propertyType,
                type: request.type,

                priceMin: request.priceMin || null,
                priceMax: request.priceMax || null,
                priceM2Min: request.priceM2Min || null,
                priceM2Max: request.priceM2Max || null,

                totalAreaMin: request.totalAreaMin || null,
                totalAreaMax: request.totalAreaMax || null,
                livingAreaMin: request.livingAreaMin || null,
                livingAreaMax: request.livingAreaMax || null,
                kitchenAreaMin: request.kitchenAreaMin || null,
                kitchenAreaMax: request.kitchenAreaMax || null,

                floorNumberMin: request.floorNumberMin || null,
                floorNumberMax: request.floorNumberMax || null,
                floorsCountMin: request.floorsCountMin || null,
                lastFloorOnly: request.lastFloorOnly || null,

                materialType: request.materialType || [],
                buildYearMin: request.buildYearMin || null,
                buildYearMax: request.buildYearMax || null,

                roomsCount: request.roomsCount || [],

                main_metro_id: request.main_metro_id || [],
                transportType: request.transportType,
                transportTime: request.transportTime || null,

                description: request.description || ''
            }
        };
        requestStore.setEditingItem(props.item_id, item);
    }

    handleChangeEstateFilter = (event: React.SyntheticEvent, { type, name, value }: { type: string; name: string; value: unknown }) => {
        let { requestObj } = requestStore.getItem(CREATING_ITEM_ID).editingItem;

        requestObj = { ...requestObj, [name]: value };

        requestStore.setEditingItem(CREATING_ITEM_ID, {
            requestObj
        });
    };

    handleMetroChange = (metroIds: number[]) => {
        let { requestObj } = requestStore.getItem(CREATING_ITEM_ID).editingItem;

        requestObj = { ...requestObj, main_metro_id: metroIds };

        requestStore.setEditingItem(CREATING_ITEM_ID, {
            requestObj
        });
    };

    handleDescription = ({ name, value }: { name: string; value: string }) => {
        let { requestObj } = requestStore.getItem(CREATING_ITEM_ID).editingItem;

        requestObj = { ...requestObj, [name]: value.replace(/"/g, '') };

        requestStore.setEditingItem(CREATING_ITEM_ID, {
            requestObj
        });
    };

    render() {
        const { item_id } = this.props;
        const { requestObj } = requestStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Area
                    label="Описание"
                    value={requestObj ? requestObj.description : ''}
                    name="description"
                    onChange={this.handleDescription}
                />

                <Divider />

                <div style={{ maxWidth: '650px', margin: '0 auto' }}>
                    <FilterEstateMain
                        simpleMode
                        {...requestObj}
                        propertyTypes={requestObj && requestObj.propertyType ? [requestObj.propertyType] : []}
                        handleMetroChange={this.handleMetroChange}
                        handleChange={this.handleChangeEstateFilter}
                    />
                </div>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, RequestFilterEditing);
