import React, { Component, Fragment } from 'react';
import { Menu, Form, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import callEventStore from '~/stores/cell/callEventStore';

import ListFilterWrapper, { deltaCreateTimes } from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import ButtonGroup from '../../Base/ButtonGroup';

@observer
class FilterCallEvents extends Component<ListFilterWrapperProps> {
    render() {
        const { deltaCallTime, itemType, callHasReleased } = callEventStore.listFilter;
        const { handleChange } = this.props;

        return (
            <Fragment>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            placeholder="Время"
                            closeOnChange
                            selection
                            options={deltaCreateTimes}
                            value={deltaCallTime}
                            onChange={handleChange}
                            name="deltaCallTime"
                        />
                    </Form>
                </Menu.Item>

                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [1, 'Контакт'],
                                [2, 'Собственник']
                            ]}
                            name="itemType"
                            value={itemType}
                            handleChange={handleChange}
                        />
                    </Form>
                </Menu.Item>

                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <ButtonGroup
                            buttonSet={[
                                [null, 'Все'],
                                [true, 'Состоялись'],
                                [false, 'Не состоялись']
                            ]}
                            name="callHasReleased"
                            value={callHasReleased}
                            handleChange={handleChange}
                        />
                    </Form>
                </Menu.Item>
            </Fragment>
        );
    }
}

export default ListFilterWrapper(callEventStore, FilterCallEvents);
