import { observable, action, reaction, runInAction } from 'mobx';
import { LearningMaterial, LearningMaterialFileTemp } from '~/types/learning.types';
import * as learningApi from '~/api/learningApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import deepCopy from '../common/deepCopy';

type LearningMaterialProperty = {
    tempFiles: LearningMaterialFileTemp[];
};

class LearningStore extends ListStorePrototype<LearningMaterial, LearningMaterial, LearningMaterialProperty> {
    constructor() {
        super('material_id', 'learning', learningApi);
    }

    @observable
    errors: string[] = [];

    @observable
    loadingLearningMaterials = false;

    @action
    addTempFile(file: LearningMaterialFileTemp, item_id: number) {
        const {
            editingItem: { tempFiles = [] }
        } = this.getItem(item_id);
        tempFiles.push(file);
        this.setEditingItem(item_id, { tempFiles: [...tempFiles] });
    }

    @action
    async toggleDisableItem(item_id: number) {
        const item = this.findById(item_id);
        this.setItem(item_id, { item: deepCopy(item), property: {}, history: {}, editingItem: {}, loadingItem: false, errors: [] });
        await super.toggleDisableItem(item_id, false);
    }
}

export default new LearningStore();
