import React, { Component, Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import showingStore from '~/stores/showingStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import DatePicker from '../../../Base/DatePicker';
import EditField from '../../../Base/EditField';

type ShowingReportDetailsEditingProps = HandlerEditingWrappedProps & {
    showingTime?: number;
    description?: string;
};

@observer
class ShowingReportDetailsEditing extends Component<ShowingReportDetailsEditingProps> {
    constructor(props: ShowingReportDetailsEditingProps) {
        super(props);

        const item = {
            showingTime: props.showingTime,
            description: props.description || ''
        };
        showingStore.setEditingItem(props.item_id, item);
    }

    render() {
        const { item_id } = this.props;
        const { showingTime, description } = showingStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline>
                        <span className="crm-Item__labelWide crm-Estate__reportTime_label">Время показа</span>

                        <div className="crm-Estate__reportTime_picker">
                            <DatePicker
                                time={showingTime || null}
                                onChange={time => this.props.setUnixTime('showingTime', time)}
                                timeIntervals={15}
                                required
                            />
                        </div>
                    </Form.Field>
                </Form.Group>
                <EditField.Area
                    name="description"
                    label="Информация"
                    value={description || ''}
                    onChange={this.props.handleTextAreaChange}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(showingStore, ShowingReportDetailsEditing);
