import React, { useState, useEffect, SyntheticEvent } from 'react';
import fetchApi from '~/common/fetchApi';

import TextInput from '~ui/TextInput';
import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';

const checkPassword = async (passport: string): Promise<boolean> => {
    const graphql = `{
        checkInvalidPassportNumber(passport: "${passport}")
      }`;

    const data = await fetchApi.getGQ<{ checkInvalidPassportNumber: boolean }>(graphql);
    return data.checkInvalidPassportNumber;
};

export const isCorrectPassport = (series: string, number: string): boolean =>
    `${series || ''}`.trim().length === 4 && `${number || ''}`.length === 6;

const PassportNumberInput = React.memo(({ item_id, series, number }: { item_id: number; series: string; number: string }) => {
    const [invalidPassport, setInvalidPassport] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangePassport = (event: SyntheticEvent, { value }: { value? }) => {
        const [series, number] = String(value).split(' ');
        mortgagePersonStore.setEditingItem(item_id, { series: series || null, number: number || null });
    };

    const passportNumber = `${series || ''} ${number || ''}`.trim();

    useEffect(() => {
        setInvalidPassport(false);
        if (isCorrectPassport(series, number)) {
            setLoading(true);
            checkPassword(series + number)
                .then(isInvalid => {
                    setInvalidPassport(isInvalid);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [series, number]);

    const helperText = !invalidPassport ? (
        '0000 000000'
    ) : (
        <span style={{ color: 'red' }}>Паспорт найден в базе ФМС как недействительный</span>
    );

    return (
        <TextInput
            label="Серия и номер паспорта"
            helperText={helperText}
            mask="0000 000000"
            pattern={`^\\d{4}\\s\\d{6}$`}
            value={passportNumber}
            name="passportNumber"
            onChange={handleChangePassport}
            loading={loading}
            disabled={loading}
        />
    );
});

export default React.memo(PassportNumberInput);
