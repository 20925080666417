import React, { Component, Fragment } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import YandexHouseBlockEditing from './YandexHouseBlockEditing';
import config from '../../../../../config/ui.config';

type YandexHouseBlockProps = {
    estate_id: number;
};

@observer
class YandexHouseBlock extends Component<YandexHouseBlockProps> {
    handleToggleEditing = () => {
        estateStore.setProperty(this.props.estate_id, { editingYandexHouse: true });
    };

    render() {
        const { estate_id } = this.props;

        const { yandexHouse, loadingYandexHouse, editingYandexHouse } = estateStore.getItem(estate_id).property;

        if (editingYandexHouse) {
            return <YandexHouseBlockEditing estate_id={estate_id} />;
        }

        if (loadingYandexHouse) {
            return (
                <div>
                    <Loader active={true} size="mini" />
                </div>
            );
        }

        if (!yandexHouse) {
            return null;
        }

        return (
            <div>
                <br />
                <img src={config.publicUrl + 'yandex.png'} height={16} alt="Дом в Яндекс.Недвижимости" />
                <a target="_blank" href={yandexHouse.url} title="Дом в Яндекс.Недвижимости" rel="noreferrer">
                    <b>{yandexHouse.name}</b>, &nbsp;
                    {yandexHouse.address}, &nbsp;
                    {yandexHouse.buildingPhase}
                </a>
                &nbsp;&nbsp;
                <Icon link name="edit" title="Помнять Дом в Яндексе" onClick={this.handleToggleEditing} />
            </div>
        );
    }
}

export default YandexHouseBlock;
