import React, { Component, Fragment } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import requestStore from '~/stores/requestStore';

import AddressBlockEditing from '../../Estate/EditBlocks/AddressBlockEditing';
import PriceBlockEditing from '../../Estate/EditBlocks/PriceBlockEditing';
import PropertyBlockEditing from '../../Estate/EditBlocks/PropertyBlockEditing';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type RequestEstateEditingProps = RouteComponentProps<{}> & HandlerEditingWrappedProps;

@observer
class RequestEstateEditing extends Component<RequestEstateEditingProps> {
    render() {
        const {
            editingItem: { type }
        } = requestStore.getItem(CREATING_ITEM_ID);

        return (
            <Fragment>
                <AddressBlockEditing estate_id={CREATING_ITEM_ID} />
                <PropertyBlockEditing item_id={CREATING_ITEM_ID} simpleMode={true} />
                <Divider />
                <PriceBlockEditing type={type} item_id={CREATING_ITEM_ID} />
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, withRouter(RequestEstateEditing));
