import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Header, Icon } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import contactStore from '~/stores/contactStore';
import TagsBlockEditing from '../EditBlocks/TagsBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import TagsList from './SubBlocks/TagsList';
import { WarningMessage } from '~ui/Message';

@observer
class TagsBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item } = contactStore.getItem(item_id);
        const { tags_ids } = item || {};

        return (
            <Card fluid color="grey">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">
                                <Icon name="tags" color="grey" />
                                Тэги
                            </Header>
                            {tags_ids && <TagsList tags_ids={tags_ids} />}
                            {tags_ids && tags_ids.length === 0 && <WarningMessage>Тэги не заданы</WarningMessage>}
                        </Fragment>
                    )}
                    {editing && <TagsBlockEditing item_id={item_id} editing={editing} tags_ids={Array.from(tags_ids)} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(contactStore, TagsBlockShow);
