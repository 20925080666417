import React, { Component, Fragment } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import AvitoHouseBlockEditing from './AvitoHouseBlockEditing';
import config from '../../../../../config/ui.config';

type AvitoHouseBlockProps = {
    estate_id: number;
};

@observer
class AvitoHouseBlock extends Component<AvitoHouseBlockProps> {
    handleToggleEditing = () => {
        estateStore.setProperty(this.props.estate_id, { editingAvitoHouse: true });
    };

    render() {
        const { estate_id } = this.props;
        const { avitoHouse, loadingAvitoHouse, editingAvitoHouse } = estateStore.getItem(estate_id).property;

        if (editingAvitoHouse) {
            return <AvitoHouseBlockEditing estate_id={estate_id} />;
        }

        if (loadingAvitoHouse) {
            return (
                <div>
                    <Loader active={true} size="mini" />
                </div>
            );
        }

        if (!avitoHouse) {
            return null;
        }

        return (
            <div title="Дом в Авито">
                <br />
                <img src={config.publicUrl + 'avito.png'} height={16} alt="ЖК в Авито" />
                <b>{avitoHouse.name}</b>, &nbsp;
                {avitoHouse.address}
                &nbsp;&nbsp;
                <Icon link name="edit" title="Помнять Дом в Авито" onClick={this.handleToggleEditing} />
            </div>
        );
    }
}

export default AvitoHouseBlock;
