import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalActions, ModalContent } from '~ui/Modal';
import { NumberInput } from '~ui/TextInput';

import commonStore from '~/stores/commonStore';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(({ spacing }) => ({
    box: {
        margin: spacing(1, 0),
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap'
    }
}));

const SmaEstateAlreadySoldModal = ({ onClose }: { onClose: () => void }) => {
    const classes = useStyles();

    const [soldPrice, setSoldPrice] = useState(0);
    const handleChangePrice = (event: React.ChangeEvent, { value }: { value: number }) => {
        setSoldPrice(value);
    };

    const [toggleConfirmPrice, setToggleConfirmPrice] = useState(true);
    const handleToggleConfirmPrice = () => {
        setToggleConfirmPrice(!toggleConfirmPrice);
    };

    return (
        <Modal onClose={onClose} fullScreen={commonStore.isMobile} maxWidth="sm" header="Объект уже продан">
            <ModalContent dividers>
                <p>
                    <b>Собственник подтвердил, что уже продал объект?</b>
                </p>
                <p>
                    Удаление собственника произойдет не только в этой таблице СМА, но и в общей базе собственников. Если вы хотите пометить,
                    что объект продан только в таблице СМА, то воспользуйтесь другим пунктом меню (Изменить цену / Даты).
                </p>

                <div className={classes.box}>
                    <NumberInput
                        label="За какую цену был продан?"
                        value={soldPrice}
                        min={0}
                        onChange={handleChangePrice}
                        fullWidth={false}
                        extraLabel="руб."
                        disabled={!toggleConfirmPrice}
                    />

                    <FormControlLabel
                        control={<Checkbox checked={toggleConfirmPrice} onChange={handleToggleConfirmPrice} />}
                        label="Я уточнил(а) цену продажи"
                    />
                </div>
            </ModalContent>

            <ModalActions>
                <Button onClick={onClose} color="secondary">
                    Отменить
                </Button>
                <div style={{ position: 'relative' }}>
                    <Button variant="outlined" onClick={() => {}} color="primary">
                        Подтверждаю, Продан
                    </Button>
                </div>
            </ModalActions>
        </Modal>
    );
};

export default SmaEstateAlreadySoldModal;
