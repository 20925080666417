import React, { useState } from 'react';
import history from '~/history';

import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';
import authStore from '~/stores/authStore';

import ExportBlockShow from '../ShowBlocks/ExportBlockShow';
import ExportBlockEditing from './ExportBlockEditing';
import EstateExportRequestModal from './EstateExportRequestModal';

import Button from '~ui/Button';

const EstateExportSettingsTab = ({ estate_id, isProgress }: { estate_id: number; isProgress: boolean }) => {
    const [editing, setEditing] = useState(Boolean(history?.location?.state['editing']));
    const toggleEditing = () => {
        setEditing(!editing);
    };

    const estate = estateStore.getItem(estate_id).item;
    if (!estate) {
        return null;
    }
    const { propertyType, type } = estate;

    let canEdit = false;
    try {
        const { major_user_ids } = exportStore.getItem(estate_id).item;
        canEdit = authStore.canEdit(exportStore.moduleName, [...estate.major_user_ids, ...major_user_ids]);
    } catch (e) {}

    return (
        <>
            {!editing && (
                <>
                    <ExportBlockShow item_id={estate_id} extendMode />
                    {!isProgress && canEdit && (
                        <div className="crm-Flex__center">
                            <Button variant="contained" primary onClick={toggleEditing}>
                                Установить экспорт
                            </Button>
                        </div>
                    )}
                    {!isProgress && !canEdit && authStore.getModeratorId() && (
                        <div className="crm-Flex__center">
                            <EstateExportRequestModal btnTitle="Запрос на включение (изменение) экспорта" />
                        </div>
                    )}
                </>
            )}
            {!isProgress && editing && (
                <ExportBlockEditing item_id={estate_id} type={type} propertyType={propertyType} toggleEditMode={toggleEditing} />
            )}
        </>
    );
};

export default EstateExportSettingsTab;
