import fetchApi from '~/common/fetchApi';
import { HistoryItemChange } from '~/types/historyChanges.types';
import { GQItemObjects } from '~/api/notificationsApi';
import { objectToGraphql } from '~/common/graphql';

export const GQHistoryBaseChange = `
    change_id
    changeJson
    releaseTime
    user_id  
`;

const GQHistoryItemChange = `
    ${GQHistoryBaseChange}
    item_type
    item_id
    item {
        ${GQItemObjects}
    } 
`;

export const fetchItemList = async (
    user_id: number,
    filter: {},
    limit: { pageSize: number; offset: number }
): Promise<HistoryItemChange[]> => {
    const graphql = `{
        fetchHistoryChangesByUser(user_id: ${user_id}, limit: ${objectToGraphql(limit)}) {
            ${GQHistoryItemChange}
        }
      }`;

    const data: { fetchHistoryChangesByUser: HistoryItemChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchHistoryChangesByUser;
};

export const fetchItemListCount = async (user_id: number): Promise<number> => {
    const graphql = `{
        fetchHistoryChangesByUserCount(user_id: ${user_id})
      }`;

    const data: { fetchHistoryChangesByUserCount: number } = await fetchApi.getGQ(graphql);
    return data.fetchHistoryChangesByUserCount;
};
