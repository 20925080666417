import React, { Component } from 'react';
import { List, Radio, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Confirm from '~ui/Confirm';

import estateStore from '~/stores/estateStore';

import { HandleChangeType, HandlerEditingWrappedProps } from '../../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../../HandlerEditingBlockWrapper';
import estateL10n from '~/L10n/estate.L10n';
import {
    DISABLED_RENT_PROPERTY_TYPES,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_TYPE_RENT_ID
} from '~/types/estate.types';

type PropertyTypeEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    estateType: number;
    type: number;
    strictTypes?: boolean;
};

type PropertyTypeFilter = (type_id: number) => boolean;

const propertyTypeFilter = (estateType: number, dealType: number, strictTypes: boolean): PropertyTypeFilter => {
    if (estateType === 2) {
        return type_id => type_id >= ESTATE_PROPERTY_TYPE_OFFICE;
    }

    if (dealType === ESTATE_TYPE_RENT_ID) {
        return type_id => type_id > 0 && type_id < ESTATE_PROPERTY_TYPE_OFFICE && !DISABLED_RENT_PROPERTY_TYPES.includes(type_id);
    }

    return type_id => type_id > 0 && type_id < ESTATE_PROPERTY_TYPE_OFFICE;
};

@observer
class PropertyTypeEditing extends Component<PropertyTypeEditingProps, { preparePropertyType: null | number }> {
    constructor(props: PropertyTypeEditingProps) {
        super(props);

        const item = {
            estate_id: props.item_id,
            propertyType: props.propertyType
        };
        estateStore.setEditingItem(item.estate_id, item);

        this.state = {
            preparePropertyType: null
        };
    }

    handlePropertyTypeChange: HandleChangeType = (event, { value }) => {
        this.setState({ preparePropertyType: value });
    };

    handleConfirmPropertyType = () => {
        estateStore.setEditingItem(this.props.item_id, { propertyType: this.state.preparePropertyType });
        this.closeConfirmPopup();
    };

    closeConfirmPopup = () => {
        this.setState({ preparePropertyType: null });
    };

    render() {
        const { preparePropertyType } = this.state;
        const { item_id, estateType, type, strictTypes } = this.props;
        const { propertyType } = estateStore.getItem(item_id).editingItem;

        let onlyTypes: number[] = [];

        if (strictTypes) {
            if (ESTATE_PROPERTY_FLATS_ARR.includes(propertyType)) {
                onlyTypes = ESTATE_PROPERTY_FLATS_ARR;
            } else if (ESTATE_PROPERTY_HOUSES.includes(propertyType)) {
                onlyTypes = ESTATE_PROPERTY_HOUSES;
            } else if (ESTATE_PROPERTY_GARAGE_ARR.includes(propertyType)) {
                onlyTypes = ESTATE_PROPERTY_GARAGE_ARR;
            } else {
                onlyTypes = [propertyType];
            }
        }

        const propertyTypes: Array<number>[] = estateL10n.PROPERTY_TYPE.filter(([type_id]) => {
            if (onlyTypes.length && !onlyTypes.includes(type_id)) {
                return false;
            }
            return propertyTypeFilter(estateType, type, strictTypes)(type_id);
        });

        // if (estateType === 1) {
        //     propertyTypes = type === 1 ? estateL10n.PROPERTY_TYPE.slice(1, 6) : [estateL10n.PROPERTY_TYPE[1], estateL10n.PROPERTY_TYPE[4]];
        // } else {
        //     propertyTypes = estateL10n.PROPERTY_TYPE.slice(6, 7);
        // }

        return (
            <>
                <Grid columns={2}>
                    <Grid.Column>
                        <List>
                            {propertyTypes
                                .filter(propertyType => propertyType[0] < (estateType === 1 ? 10 : 200))
                                .map(item => (
                                    <List.Item key={`propertyType_${item[0]}`}>
                                        <Radio
                                            label={item[1]}
                                            name="propertyType"
                                            value={item[0]}
                                            onChange={this.handlePropertyTypeChange}
                                            checked={item[0] === propertyType}
                                        />
                                    </List.Item>
                                ))}
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List>
                            {propertyTypes
                                .filter(propertyType => propertyType[0] > (estateType === 1 ? 10 : 200))
                                .map(item => (
                                    <List.Item key={`propertyType_${item[0]}`}>
                                        <Radio
                                            label={item[1]}
                                            name="propertyType"
                                            value={item[0]}
                                            onChange={this.handlePropertyTypeChange}
                                            checked={item[0] === propertyType}
                                        />
                                    </List.Item>
                                ))}
                        </List>
                    </Grid.Column>
                </Grid>

                {Boolean(preparePropertyType) && (
                    <Confirm
                        header="Подтвердите Тип Объявления"
                        content={
                            'Если тип объявления указан неверно, то ЦИАН, Авито и другие базы могут заблокировать ВСЕ ваши объявления. Нельзя указывать тип Квартира, если это Переуступка или новостройка от Застройщика и наоборот.'
                        }
                        confirmButton={'Подтверждаю'}
                        onCancel={this.closeConfirmPopup}
                        onConfirm={this.handleConfirmPropertyType}
                    />
                )}
            </>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, PropertyTypeEditing);
