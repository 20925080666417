import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import ShoppingBlockEditing from '../EditBlocks/ShoppingBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';

@observer
class ShoppingBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            placementType,
            workingDaysType,
            tenants,
            openingHoursType,
            openingHoursFrom,
            openingHoursTo,
            shoppingCenterScaleType,
            houseLineType
        } = item;

        return (
            <Card fluid color="yellow">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Торговый центр</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Тип помещени</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.SHOPPING_PLACEMENT_TYPE_FIND(placementType)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Рабочие дни</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.SHOPPING_WORKING_DAYS_FIND(workingDaysType)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Линия домов</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.SHOPPING_HOUSE_LINE_TYPE_FIND(houseLineType)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Масштаб торгового центра</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.SHOPPING_CENTER_SCALE_FIND(shoppingCenterScaleType)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Рабочие дни</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.SHOPPING_WORKING_DAYS_FIND(workingDaysType)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Часы работы</Grid.Column>
                                    <Grid.Column>
                                        {openingHoursType !== 2 && (
                                            <ShadowFragment
                                                shadow={loadingItem}
                                                print={estateL10n.SHOPPING_OPENING_HOURS_TYPE_FIND(openingHoursType)}
                                            />
                                        )}
                                        {openingHoursType === 2 && (
                                            <span>
                                                с {openingHoursFrom} до {openingHoursTo}
                                            </span>
                                        )}
                                    </Grid.Column>
                                    <Grid.Column>Арендаторы</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={tenants} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <ShoppingBlockEditing item_id={item_id} editing={editing} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, ShoppingBlockShow);
