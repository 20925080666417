import fetchApi, { grabMessageFromError } from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { ContactFilter, Contact, ContactTableItem, ManageContactArrayType } from '~/types/contacts.types';
import { Phone } from '~/types/users.types';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { ContactLinkType } from '~/types/contacts.types';
import { GQComments } from './commentsApi';
import { GQEstateMainFields } from './estateApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

export const GQMainUserFields = `
    major_user {
        ${GQUserMainFields}
    }
`;

export const GQMainContactFields = `
    companyName
    contactType
    contact_id
    firstName
    lastName
    middleName
`;

export const GQContactPhones = `
    phones {
        phone_id
        phone
    }
`;

const GQContactsListFields = `
    ${GQMainContactFields}
    sex
    email
    inn
    ${GQContactPhones}
    tags_ids
    major_user_ids
    major_users {
        ${GQUserMainFields}
    }
    createTime
    updateTime
    enable
    restrictedAccess
    comments {
        ${GQComments}
    }
    estates {
        ${GQEstateMainFields}
        base
    }
    lastCallTime
    
    nearestUncompletedCallEvent {
        callTimeMin  
        callTimeMax  
    }
`;

const GQContactsFields = `
    ${GQContactsListFields}
    
    mortgage_request_id
    kanban_list_ids
    
    documentsCount
    cellCallsCount
    birthday
    about
    enable
`;

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    contactsFilter: Partial<ContactFilter>,
    controller?: AbortController,
    withoutCount?: boolean
): Promise<{ list: ContactTableItem[]; count: number }> => {
    const graphql = `{
            fetchContacts(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(contactsFilter)}, withoutCount: ${Boolean(withoutCount)}) {
                list {${GQContactsListFields}}
                count
            } 
          }`;

    const data: { fetchContacts: { list: ContactTableItem[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchContacts;
};

export const fetchItem = async (contact_id: number): Promise<Contact> => {
    const graphql = `{
        fetchContact(contact_id: ${contact_id}) {
            ${GQContactsFields}
        } 
      }`;

    const data: { fetchContact: Contact } = await fetchApi.getGQ(graphql);
    return data.fetchContact;
};

export const saveItem = async (contact_id: number, contact: Partial<Contact>): Promise<number> => {
    const graphql = `mutation { 
              updateContact(contact_id: ${contact_id}, contactInput: ${objectToGraphql(contact)})
            }`;

    const data: { updateContact: number } = await fetchApi.postGQ(graphql);
    return data.updateContact;
};

export const saveContacts = async (
    item_id: number,
    contacts: any
): Promise<{ email: string; phones: Array<Phone>; innerPhones?: string[] }> => {
    const graphql = `mutation { 
          saveItemContacts(type:CONTACT, item_id: ${item_id}, contacts: ${objectToGraphql(contacts)}) {
                email,
                phones {
                    phone_id
                    phone
                }
              }
        }`;

    const data: { saveItemContacts: { email: string; phones: Array<Phone>; innerPhones?: string[] } } = await fetchApi.postGQ(graphql);
    return data.saveItemContacts;
};

export const createItem = async (contact: Contact): Promise<number> => {
    const graphql = `mutation { 
          createContact(contactInput: ${objectToGraphql(contact)})
        }`;

    const data: { createContact: number } = await fetchApi.postGQ(graphql);
    return data.createContact;
};

export const checkWhetherPhoneIsNotUsed = async (contact_id: number, phone: string): Promise<ContactLinkType | null> => {
    const graphql = `{
        checkWhetherPhoneIsNotUsed(phone: "${phone}", contact_id: ${contact_id}) {
            ${GQMainContactFields}
            ${GQUserMainFields}
        } 
      }`;

    const data: { checkWhetherPhoneIsNotUsed: ContactLinkType | null } = await fetchApi.getGQ(graphql);
    return data.checkWhetherPhoneIsNotUsed;
};

export const fetchItemHistory = async (contact_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchContactChangeHistory(contact_id: ${contact_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchContactChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchContactChangeHistory;
};

export const manageItemArray = async (contact_ids: number[], params: ManageContactArrayType): Promise<boolean> => {
    const graphql = `mutation { 
        manageContactsArray(contact_ids: ${objectToGraphql(contact_ids)}, 
            params: ${objectToGraphql(params)}
        )}`;

    const data: { manageContactsArray: boolean } = await fetchApi.postGQ(graphql);
    return data.manageContactsArray;
};

export const mergeTwoContacts = async (contact_id: number, merge_with_contact_id: number): Promise<number> => {
    const graphql = `mutation { 
          mergeTwoContacts(contact_id: ${contact_id}, merge_with_contact_id: ${merge_with_contact_id})
        }`;

    const data: { mergeTwoContacts: number } = await fetchApi.postGQ(graphql);
    return data.mergeTwoContacts;
};

export const takeContactToWork = async (contact_id: number): Promise<boolean> => {
    const graphql = `mutation {
        takeContactToWork(contact_id: ${contact_id})
      }`;

    const data: { takeContactToWork: boolean } = await fetchApi.getGQ(graphql);
    return data.takeContactToWork;
};

export const moveKanbanList = async (contact_id: number, old_list_id: number, new_list_id: number): Promise<boolean> => {
    const graphql = `mutation {
        moveKanbanList(contact_id: ${contact_id}, old_list_id: ${old_list_id}, new_list_id: ${new_list_id})
      }`;

    const data: { moveKanbanList: boolean } = await fetchApi.getGQ(graphql);
    return data.moveKanbanList;
};

export const sendSmsToContactsArray = async (contact_ids: number[], message: string): Promise<boolean> => {
    const graphql = `mutation { 
        sendSmsToContactsArray(contact_ids: ${objectToGraphql(contact_ids)}, 
            message: ${objectToGraphql(message)}
        )}`;

    const data = await fetchApi.postGQ<{ sendSmsToContactsArray: boolean }>(graphql);
    return data.sendSmsToContactsArray;
};
