import fetchApi from '~/common/fetchApi';
import {
    AvitoHouse,
    YandexHouse,
    Newbuilding,
    DomclickNewbuildingInput,
    NewBuildingHouse,
    DomclickNewbuildingHouseInput,
    NebuildingHouseInfo,
    LifeComplexApartmentsFilter
} from '~/types/newbuildings.types';
import { objectToGraphql } from '~/common/graphql';
import { GQDeveloperFields } from '~/api/developerApi';
import { EstateTableItem } from '~/types/estate.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

export const GQAddressFields = `
    street
    streetType
    houseNumber
    houseNumberType
    blockNumber
    blockNumberType

    town
    townType
    region
    regionType
    district
    cityDistrict
    districtType
    cityDistrictType
    settlement
    settlementType
    postcode
    
    address
    fias
    kladr
    geo_lat
    geo_lon
`;

const GQYandexHouse = `
    yandex_house_id
    yandex_newobject_id
    newbuilding_id
    newbuilding_house_id
    
    name
    address
    url
    
    buildYear
    readyQuarter
    buildingPhase
`;

const GQAvitoHouse = `
    avito_house_id
    avito_newobject_id
    newbuilding_id
    newbuilding_house_id
    
    name
    address
    developer
`;

export const GQNewBuildingFields = `
    newbuilding_id
    name
    
    ${GQAddressFields}
`;

export const GQNewBuildingHouseFields = `
    newbuilding_house_id
    newbuilding_id
    name
    
    ${GQAddressFields}
`;

const GQNewbuildingWithHouses = `
    ${GQNewBuildingFields}
            
    houses {
      ${GQNewBuildingHouseFields}
    }
`;

export const GQNewBuildingFieldsFull = `
    ${GQNewbuildingWithHouses}
    
    developer_id
    developer {
        ${GQDeveloperFields}
    }
    
    buildYear
    readyQuarter
    
    about
    lifeComplexClass
    floorsCount
    materialType
    ceilingHeight
    
    minPrice
    maxPrice
    minArea
    maxArea
    
    photos {
        filename
        isLoading
    }
    
    decorations
    parkingTypes
    
    agentBonusType
    agentBonusValue
    
    major_user_ids
    major_users {
        newbuilding_user_id
        database
        user_id
        email
        phone
        firstName
        lastName
        avatarUrl
        enable
    }
`;

export const fetchNewbuildingsSuggestions = async (query: string, region: string | null, limit: number): Promise<Newbuilding[]> => {
    const graphql = `{
        findNewBuildingByName(name: "${query}", region: "${region || ''}", limit: ${limit}) {
            ${GQNewbuildingWithHouses}
          }
      }`;

    const data: { findNewBuildingByName: Newbuilding[] } = await fetchApi.getGQ(graphql);
    return data.findNewBuildingByName;
};

export const fetchHousesByNewbuildingId = async (newbuilding_id: number): Promise<NewBuildingHouse[]> => {
    const graphql = `{
        fetchHousesByNewbuildingId(newbuilding_id: ${newbuilding_id}) {
            ${GQNewBuildingHouseFields}
          }
      }`;

    const data = await fetchApi.getGQ<{ fetchHousesByNewbuildingId: NewBuildingHouse[] }>(graphql);
    return data.fetchHousesByNewbuildingId;
};

export const fetchNearestNewbuildings = async (geo_lat: number, geo_lon: number, limit: number): Promise<Newbuilding[]> => {
    const graphql = `{
        fetchNearestNewbuildings(geo_lat: ${geo_lat}, geo_lon: ${geo_lon}, limit: ${limit}) {
            ${GQNewbuildingWithHouses}
          }
      }`;

    const data: { fetchNearestNewbuildings: Newbuilding[] } = await fetchApi.getGQ(graphql);
    return data.fetchNearestNewbuildings;
};

export const fetchItem = async (newbuilding_id: number): Promise<Newbuilding> => {
    const graphql = `{
        fetchNewbuildingById(newbuilding_id: ${newbuilding_id}) {
            ${GQNewBuildingFieldsFull}
          }
      }`;

    const data = await fetchApi.getGQ<{ fetchNewbuildingById: Newbuilding }>(graphql);
    return data.fetchNewbuildingById;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    lifeComplexFilter: {}
): Promise<{ list: Newbuilding[]; count: number }> => {
    const graphql = `{
        fetchNewbuildings(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(lifeComplexFilter)}) {
                list {${GQNewBuildingFieldsFull}}
                count
          }
      }`;

    const data = await fetchApi.getGQ<{ fetchNewbuildings: { list: Newbuilding[]; count: number } }>(graphql);
    return data.fetchNewbuildings;
};

export const saveItem = async (newbuilding_id: number, newbuilding: Newbuilding): Promise<number> => {
    const graphql = `mutation { 
        updateNewbuilding(newbuilding_id: ${newbuilding_id}, newbuildingDiff: ${objectToGraphql(newbuilding)})
    }`;

    const data: { updateNewbuilding: number } = await fetchApi.postGQ(graphql);
    return data.updateNewbuilding;
};

export const matchYandexHouse = async (
    newbuilding_house_id: number
): Promise<{ yandexHouse: YandexHouse; suggestedYandexHouses: YandexHouse[] }> => {
    const graphql = `{
        matchYandexHouse(newbuilding_house_id: ${newbuilding_house_id}) {
            yandexHouse {
                ${GQYandexHouse}
            }
            suggestedYandexHouses {
                ${GQYandexHouse}
            }
        } 
      }`;

    const data: { matchYandexHouse: { yandexHouse: YandexHouse; suggestedYandexHouses: YandexHouse[] } } = await fetchApi.getGQ(graphql);
    return data.matchYandexHouse;
};

export const matchAvitoHouse = async (
    newbuilding_house_id: number
): Promise<{ avitoHouse: AvitoHouse; suggestedAvitoHouses: AvitoHouse[] }> => {
    const graphql = `{
        matchAvitoHouse(newbuilding_house_id: ${newbuilding_house_id}) {
            avitoHouse {
                ${GQAvitoHouse}
            }
            suggestedAvitoHouses {
                ${GQAvitoHouse}
            }
        } 
      }`;

    const data: { matchAvitoHouse: { avitoHouse: AvitoHouse; suggestedAvitoHouses: AvitoHouse[] } } = await fetchApi.getGQ(graphql);
    return data.matchAvitoHouse;
};

export const findYandexHouses = async (search: string): Promise<YandexHouse[]> => {
    const graphql = `{
        findYandexHouses(search: "${search}", limit: 30) {
            ${GQYandexHouse}
        } 
      }`;

    const data: { findYandexHouses: YandexHouse[] } = await fetchApi.getGQ(graphql);
    return data.findYandexHouses;
};

export const updateYandexHouse = async (yandex_house_id: number, newbuilding_house_id: number): Promise<number> => {
    const graphql = `mutation { 
          updateYandexHouse(yandex_house_id: ${yandex_house_id}, newbuilding_house_id: ${newbuilding_house_id})
        }`;

    const data: { updateYandexHouse: number } = await fetchApi.postGQ(graphql);
    return data.updateYandexHouse;
};

export const findAvitoHouses = async (search: string): Promise<AvitoHouse[]> => {
    const graphql = `{
        findAvitoHouses(search: "${search}", limit: 30) {
            ${GQAvitoHouse}
        } 
      }`;

    const data: { findAvitoHouses: AvitoHouse[] } = await fetchApi.getGQ(graphql);
    return data.findAvitoHouses;
};

export const updateAvitoHouse = async (avito_house_id: number, newbuilding_house_id: number): Promise<number> => {
    const graphql = `mutation { 
          updateAvitoHouse(avito_house_id: ${avito_house_id}, newbuilding_house_id: ${newbuilding_house_id})
        }`;

    const data: { updateAvitoHouse: number } = await fetchApi.postGQ(graphql);
    return data.updateAvitoHouse;
};

const NewbuildingApartmentsGQFields = `
    estate_id
    newbuilding_house_id
            
    propertyType
    floorNumber
    floorsCount
    roomsCount
    
    totalArea
    livingArea
    kitchenArea
    allRoomsArea
    
    photos {
        filename
    }

    price
`;

export const fetchNewbuildingApartments = async (
    newbuilding_id: number,
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    filter: LifeComplexApartmentsFilter,
    controller?: AbortController
): Promise<{ list: EstateTableItem[]; count: number }> => {
    const graphql = `{
        fetchNewbuildingApartments(filter: ${objectToGraphql({
            ...filter,
            newbuilding_id
        })}, limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", sort: "${orderDirection}") {
            list {
                ${NewbuildingApartmentsGQFields}
            }
            count
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchNewbuildingApartments: { list: EstateTableItem[]; count: number } }>(graphql, controller);
    return data.fetchNewbuildingApartments;
};

export const updateDomclickNewbuilding = async (complex: DomclickNewbuildingInput): Promise<number> => {
    const graphql = `mutation { 
        updateDomclickNewbuilding(complex: ${objectToGraphql(complex)})
    }`;

    const data = await fetchApi.postGQ<{ updateDomclickNewbuilding: number }>(graphql);
    return data.updateDomclickNewbuilding;
};

export const updateDomclickNewbuildingHouse = async (house: DomclickNewbuildingHouseInput): Promise<number> => {
    const graphql = `mutation { 
        updateDomclickNewbuildingHouse(house: ${objectToGraphql(house)})
    }`;

    const data = await fetchApi.postGQ<{ updateDomclickNewbuildingHouse: number }>(graphql);
    return data.updateDomclickNewbuildingHouse;
};

export const fetchNewbuildingHousesInfo = async (newbuilding_id: number): Promise<NebuildingHouseInfo[]> => {
    const graphql = `{
        fetchNewbuildingHousesInfo(newbuilding_id: ${newbuilding_id}) {
            newbuilding_house_id
            name
            flatsInSaleCount
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchNewbuildingHousesInfo: NebuildingHouseInfo[] }>(graphql);
    return data.fetchNewbuildingHousesInfo;
};
