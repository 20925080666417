import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import GarageBlockEditing, { GARAGE_INFRASTURCTURE_FIELDS, CAR_PLACEMENT_INFRASTURCTURE_FIELDS } from '../EditBlocks/GarageBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { nl2br } from '~/api/commentsApi';
import { ESTATE_PROPERTY_TYPE_CAR_PLACEMENT, ESTATE_PROPERTY_TYPE_GARAGE, ESTATE_PROPERTY_TYPE_GARAGE_BOX } from '~/types/estate.types';

@observer
class GarageBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const { description, buildingName, totalArea, garageStatus, materialType, propertyType, garageType } = item;

        return (
            <Card fluid color="blue">
                {!editing && (
                    <Fragment>
                        <Card.Content>
                            <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                        </Card.Content>
                        <Card.Content>
                            <Header size="tiny">Детали</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    {propertyType === ESTATE_PROPERTY_TYPE_GARAGE && (
                                        <Fragment>
                                            <Grid.Column>Тип гаража</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment shadow={loadingItem} print={estateL10n.GARAGE_TYPE_FIND(garageType)} />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    {propertyType === ESTATE_PROPERTY_TYPE_GARAGE_BOX && (
                                        <Fragment>
                                            <Grid.Column>Тип бокса</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment
                                                    shadow={loadingItem}
                                                    print={estateL10n.GARAGE_BOX_MATERIAL_TYPE_FIND(materialType)}
                                                />
                                            </Grid.Column>
                                        </Fragment>
                                    )}

                                    <Grid.Column>Статус</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.GARAGE_STATUS_FIND(garageStatus)} />
                                    </Grid.Column>

                                    <Grid.Column>Общая площадь</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={`${totalArea || '-'} м²`} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Header size="tiny">Инфраструктура</Header>
                            <Grid columns={3}>
                                <Grid.Row>
                                    {GARAGE_INFRASTURCTURE_FIELDS.map(
                                        field => item[field[0]] && <Grid.Column key={field[0]}>{field[1]}</Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>

                            {propertyType === ESTATE_PROPERTY_TYPE_CAR_PLACEMENT && (
                                <Fragment>
                                    <Header size="tiny">Здание</Header>
                                    <Grid columns={3}>
                                        <Grid.Row>
                                            <Grid.Column>Название</Grid.Column>
                                            <Grid.Column>
                                                <ShadowFragment shadow={loadingItem} print={buildingName} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            {CAR_PLACEMENT_INFRASTURCTURE_FIELDS.map(
                                                field => item[field[0]] && <Grid.Column key={field[0]}>{field[1]}</Grid.Column>
                                            )}
                                        </Grid.Row>
                                    </Grid>
                                </Fragment>
                            )}
                        </Card.Content>
                    </Fragment>
                )}
                {editing && (
                    <Card.Content>
                        <GarageBlockEditing item_id={item_id} editing={editing} {...item} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, GarageBlockShow);
