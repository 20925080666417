import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import InfrastructureBlockEditing, { INFRASTURCTURE_FIELDS } from '../EditBlocks/ShoppingInfrastructureBlockEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';

@observer
class ShoppingInfrastructureBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }

        return (
            <Card fluid color="brown">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Инфраструктура</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    {INFRASTURCTURE_FIELDS.map(
                                        field => item[field[0]] && <Grid.Column key={field[0]}>{field[1]}</Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <InfrastructureBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, ShoppingInfrastructureBlockShow);
