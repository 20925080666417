import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import estateL10n from '~/L10n/estate.L10n';
import { AGENT_BONUS_TYPE_FIXED, AGENT_BONUS_TYPE_PERCENT } from '~/types/estate.types';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import { NumberInput, PriceInput } from '~ui/TextInput';
import Grid from '~ui/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ButtonGroup from '~/components/Base/ButtonGroup';

type LifeComplexAgentBonusEditingProps = HandlerEditingWrappedProps & {
    agentBonusType: 1 | 2 | null;
    agentBonusValue: number | null;
};

const LifeComplexAgentBonusEditing = (props: LifeComplexAgentBonusEditingProps) => {
    const { item_id, handleChange, handlePrice } = props;

    useMemo(() => {
        const item = {
            agentBonusType: props.agentBonusType || AGENT_BONUS_TYPE_PERCENT,
            agentBonusValue: props.agentBonusValue || null
        };
        lifeComplexStore.setEditingItem(props.item_id, item);
    }, [item_id]);

    const { agentBonusType, agentBonusValue } = lifeComplexStore.getItem(item_id).editingItem;

    return (
        <Paper style={{ padding: '1rem' }}>
            <Grid.Column>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <ButtonGroup
                        size="small"
                        buttonSet={estateL10n.AGENT_BONUS}
                        name="agentBonusType"
                        value={agentBonusType}
                        handleChange={handleChange}
                    />
                    {agentBonusType === AGENT_BONUS_TYPE_FIXED && (
                        <PriceInput
                            label="Бонус агенту"
                            name="agentBonusValue"
                            value={agentBonusValue}
                            onChange={handlePrice}
                            variant="standard"
                        />
                    )}
                    {agentBonusType === AGENT_BONUS_TYPE_PERCENT && (
                        <NumberInput
                            label="Бонус агенту"
                            name="agentBonusValue"
                            value={agentBonusValue}
                            onChange={handleChange}
                            variant="classic"
                            max={100}
                            min={0}
                            step={0.1}
                            extraLabel="%"
                            fullWidth
                        />
                    )}
                </Box>
            </Grid.Column>
        </Paper>
    );
};

export default HandlerEditingBlockWrapper(lifeComplexStore, observer(LifeComplexAgentBonusEditing));
