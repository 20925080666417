import { Phone, UserLinkType } from './users.types';
import { CommentType } from './comments.types';
import { EstateLinkTypeWithBase } from './estate.types';

export const CONTACT_TYPE_PRIVATE_PERSON_ID = 1;
export const CONTACT_TYPE_COMPANY_ID = 2;

export type ContactFilter = {
    group_id: number[];
    major_user_id: number[];
    tags_ids: number[];
    search: string | null;
    commentsContain: string | null;
    enable: boolean | null;
    withoutAgent: boolean | null;
    hasCallEvent: boolean | null;
    tagsTogether: boolean | null;
    showEstates: boolean | null;
    kanban_list_id: number[];
    noOutgoingCallsTime: number | null;
};

export type ContactLinkType = {
    readonly contact_id: number;
    contactType: number;
    companyName: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
};

export type ContactLinkWithPhonesType = ContactLinkType & {
    phones: Phone[];
};

export type ContactInBaseType = ContactLinkType & {
    sex: number | null;
    inn: string | null;
    birthday: string | null;
    email: string | null;
    about: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type ContactTableItem = ContactInBaseType & {
    lastCallTime?: number;
    phones: Phone[];

    major_user_ids: number[];
    major_users: UserLinkType[];

    comments?: CommentType[];
    estates?: EstateLinkTypeWithBase[];
    nearestUncompletedCallEvent?: NearestUncompletedCallEvent;

    tags_ids: number[];
};

export type Contact = ContactTableItem & {
    cellCallsCount?: number;
    documentsCount?: number;

    restrictedAccess?: boolean;
    mortgage_request_id: number | null;

    kanban_list_ids: number[];

    // TODO: удалить эти поля, это для flow
    innerPhones?: string[];
    onlineTime?: number;
    dismissalTime?: number;
    changeExportMajorUser?: boolean;
};

export type TagSourceOptions = {
    title: string;
    color: Color;
    description: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type Tag = TagSourceOptions & {
    tag_id: number;
};

type NearestUncompletedCallEvent = {
    callTimeMin: number;
    callTimeMax: number;
};

export type Color =
    | 'red'
    | 'orange'
    | 'yellow'
    | 'olive'
    | 'green'
    | 'teal'
    | 'blue'
    | 'violet'
    | 'purple'
    | 'pink'
    | 'brown'
    | 'grey'
    | 'black';

export type ManageContactArrayType = {
    major_user_ids: number[];
    disableAll: boolean;
    tag_ids: number[];
    shiftCallEvents: boolean;
};

export type KanbanBoardInBase = {
    board_id: number;
    title: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type KanbanBoard = KanbanBoardInBase & {
    lists: Partial<KanbanList>[];
};

export type KanbanList = {
    list_id: number;
    board_id: number;
    order: number;
    title: string;
    bgColor: string;
    textColor: string;
    createTime: number;
    updateTime: number;
    enable: boolean;
};
