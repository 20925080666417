import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import {
    EstateLinkType,
    ESTATE_BASES,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_BASE_MAIN,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_COTTAGE,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_HOUSE,
    ESTATE_PROPERTY_TYPE_HOUSE_PART,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_PROPERTY_TYPE_TOWNHOUSE,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_TYPE_RENT,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_BASE_FOREIGN,
    ESTATE_PROPERTY_TYPE_BUILDING
} from '~/types/estate.types';
import estateL10n from '../../../L10n/estate.L10n';

const MAX_ADDRESS_LENGTH = 35;

export type EstateLinksProps = {
    estate: EstateLinkType;
    base?: ESTATE_BASES;
    textNoWrap?: boolean;
    isForeign?: boolean;
};

export const EstateLinkState = (estate_id: number, param: string, base: ESTATE_BASES = ESTATE_BASE_MAIN) => ({
    pathname: `/estate/${param}/${base}/${estate_id}`,
    state: { modal: true, switchFromTab: false }
});

export const estateAbout = (propertyType: number, roomsCount: number | null): string => {
    switch (propertyType) {
        case ESTATE_PROPERTY_TYPE_ROOM:
            return `комната`;
        case ESTATE_PROPERTY_TYPE_PART:
            return `доля`;
        case ESTATE_PROPERTY_TYPE_HOUSE:
            return 'дом/дача';
        case ESTATE_PROPERTY_TYPE_COTTAGE:
            return 'коттедж';
        case ESTATE_PROPERTY_TYPE_TOWNHOUSE:
            return 'таунхаус';
        case ESTATE_PROPERTY_TYPE_HOUSE_PART:
            return 'часть дома';
        case ESTATE_PROPERTY_TYPE_OFFICE:
            return 'офис';
        case ESTATE_PROPERTY_TYPE_LAND:
            return 'участок';
        case ESTATE_PROPERTY_TYPE_GARAGE:
            return 'гараж';
        case ESTATE_PROPERTY_TYPE_GARAGE_BOX:
            return 'бокс';
        case ESTATE_PROPERTY_TYPE_CAR_PLACEMENT:
            return 'машиноместо';
        case ESTATE_PROPERTY_TYPE_FREE_PURPOSE:
            return 'ПСН';
        case ESTATE_PROPERTY_TYPE_COMMERCE_LAND:
            return 'земля';
        case ESTATE_PROPERTY_TYPE_WAREHOUSE:
            return 'склад';
        case ESTATE_PROPERTY_TYPE_SHOPPING:
            return 'торговая площадь';
        case ESTATE_PROPERTY_TYPE_BUSINESS:
            return 'готовый бизнес';
        case ESTATE_PROPERTY_TYPE_INDUSTRY:
            return 'производство';
        case ESTATE_PROPERTY_TYPE_BUILDING:
            return 'здание';
        default:
            return estateL10n.ROOMS_COUNT_SHORT_FIND(roomsCount) || 'Квартира';
    }
};

export const estateLinkTitle = (propertyType: number, roomsCount: number | null, address: string): string => {
    return `${estateAbout(propertyType, roomsCount)}, ${
        address.length > MAX_ADDRESS_LENGTH ? address.slice(0, MAX_ADDRESS_LENGTH) + '...' : address
    }`;
};

const addressAdjusted = (address: string, houseNumberAdjusted?: string): string => {
    if (!address) {
        return null;
    }

    if (address.length > MAX_ADDRESS_LENGTH && houseNumberAdjusted && address.length - MAX_ADDRESS_LENGTH > houseNumberAdjusted.length) {
        return address.slice(0, MAX_ADDRESS_LENGTH) + '...' + houseNumberAdjusted;
    } else if (address.length > MAX_ADDRESS_LENGTH + 8) {
        return address.slice(0, MAX_ADDRESS_LENGTH + 8) + '...';
    }

    return address;
};

export const EstateLinkElement = ({
    estate: { estate_id, type, propertyType, roomsCount, address, enable, houseNumberAdjusted },
    base = ESTATE_BASE_MAIN,
    textNoWrap = false
}: EstateLinksProps) => (
    <span title={enable === false ? 'Объект в Архиве' : ''} className={textNoWrap ? 'crm-Estate__fieldNowrap' : ''}>
        <span className="crm-Estate__link_about">{estateAbout(propertyType, roomsCount)}</span>{' '}
        <span className={`crm-Estate__link_address ${enable === false ? 'crm-Item__disabled' : ''}`}>
            {addressAdjusted(address, houseNumberAdjusted)}
        </span>
    </span>
);

const stopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
};

const EstateLink = ({ estate, base = ESTATE_BASE_MAIN, textNoWrap = false, isForeign = false }: EstateLinksProps) => (
    <Link
        className="crm-Estate__link"
        to={EstateLinkState(
            estate['estate_id'],
            isForeign ? ESTATE_BASE_FOREIGN : estate['type'] === ESTATE_TYPE_RENT_ID ? ESTATE_TYPE_RENT : ESTATE_TYPE_SELL,
            base
        )}
        onClick={stopPropagation}
    >
        <EstateLinkElement estate={estate} textNoWrap={textNoWrap} base={base} />
    </Link>
);

export default EstateLink;
