import React, { MouseEvent } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import cs from 'classnames';
import history from '~/history';

import GlobalSearch from './GlobalSearch';
import SettingsDropdown from './SettingsDropdown';
import HeaderNotifications from './Notifications/HeaderNotifications';
import MessagesNotifications from './Notifications/MessagesNotifications';
import HeaderWhatsappStatus from '~/components/Header/Notifications/HeaderWhatsappStatus';

const HeaderAppBar = ({ fullSize }: { fullSize: boolean }) => {
    const handleShowMenu = (event: MouseEvent) => {
        event.preventDefault();
        const {
            location: { pathname, state }
        } = history;
        history.replace(pathname, { ...state, leftMenuIsOpen: false });
        history.push(pathname, { ...state, leftMenuIsOpen: true });
    };

    return (
        <header className={cs({ 'crm-Header': true, 'crm-Main__opacity': fullSize, 'crm-Print__hidden': true })}>
            <div className="crm-Header__burger">
                <IconButton edge="start" color="secondary" onClick={handleShowMenu}>
                    <MenuIcon />
                </IconButton>
            </div>

            <GlobalSearch />

            <div className="crm-Header__box">
                <HeaderWhatsappStatus />
                <HeaderNotifications />
                <MessagesNotifications />
                <SettingsDropdown />
            </div>
        </header>
    );
};

export default HeaderAppBar;
