import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import tagStore from '~/stores/tagStore';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';

type TagsSelectProps = {
    setTagIds: (tags_ids: number[]) => void;
    tags_ids: number[];
    label?: string;
};

const TagsSelect = ({ setTagIds, tags_ids, label }: TagsSelectProps) => {
    const { allTagsDropdown } = tagStore;

    const handleChange = (event: ChangeEvent, values: DropdownType[]) => {
        setTagIds(values.map(({ value }) => Number(value)));
    };

    return (
        <Autocomplete
            multiple
            options={allTagsDropdown}
            getOptionLabel={option => option.text}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map(({ value, text }, index) => {
                    const { color } = tagStore.findById(Number(value));
                    return <Chip key={text} style={{ background: color, color: 'white' }} label={text} {...getTagProps({ index })} />;
                })
            }
            value={tags_ids?.map(tag_id => {
                const { title } = tagStore.findById(tag_id);
                return { value: tag_id, text: title };
            })}
            renderInput={params => <TextField {...params} variant="outlined" label={label || 'Добавить тэг(и)'} placeholder="Тэги" />}
            onChange={handleChange}
        />
    );
};

export default observer(TagsSelect);
