import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import Progress from '~ui/Progress';
import userStore from '~/stores/userStore';
import UserLink from '~/components/Lists/Users/UserLink';

const CallEstateUserPhone = ({ user_id }: { user_id: number }) => {
    useMemo(() => {
        userStore.fetchItem(user_id);
    }, [user_id]);

    const { item: user, loadingItem } = userStore.getItem(user_id);

    return (
        <div>
            {loadingItem && <Progress show size={16} />}
            {user && <UserLink user={user} />}
            {user?.phones && (
                <ul style={{ margin: '0.5rem' }}>
                    {user.phones.map(({ phone }) => (
                        <li key={phone}>
                            {phone.slice(1)} {user.innerPhones?.length > 0 && <>(Внутренний ID: {user.innerPhones.join(' , ')})</>}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default observer(CallEstateUserPhone);
