import React, { Component, Fragment } from 'react';
import { ReactNode } from 'react';

export const handlePreventDblClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

const PreventDblClick = ({ children }: { children: ReactNode }) => <span onDoubleClick={handlePreventDblClick}>{children}</span>;

export default PreventDblClick;
