import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Dropdown } from 'semantic-ui-react';

import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { Modal, ModalContent, ModalBtnAction } from '../../../Base/ui/Modal';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import cellCallStore from '~/stores/cell/cellCallStore';
import ContactDropdown from '../../../ItemsDropdowns/ContactDropdown';
import commonStore from '~/stores/commonStore';
import { adjustPhoneNumber } from '../../../Base/PhonesList';
import contactStore from '~/stores/contactStore';
import deepCopy from '../../../../common/deepCopy';
import ItemErrors from '../../../Items/ItemErrors';

const UpdateExistingContact = ({ phone, onClose }: { phone: string; onClose: () => void }) => {
    const [loadingContact, setLoadingContact] = useState(false);
    const [contact_id, setContactId] = useState<number | null>(null);

    const [errors, setErrors] = useState<string[]>([]);

    const changeContactId = async (contact_id: number) => {
        setContactId(contact_id);
        setErrors([]);

        setLoadingContact(true);
        await contactStore.fetchItem(contact_id);
        setLoadingContact(false);
    };

    const handleSave = async () => {
        const {
            item: { phones }
        } = contactStore.getItem(contact_id);
        setLoadingContact(true);
        setErrors([]);

        const newPhones = deepCopy(phones);
        newPhones.push({ phone, phone_id: CREATING_ITEM_ID });

        contactStore.setEditingItem(contact_id, { phones: newPhones });
        await contactStore.saveContacts(contact_id);
        setLoadingContact(false);

        const { errors } = contactStore.getItem(contact_id);

        if (!errors.length) {
            onClose();
            cellCallStore.fetchList();
        } else {
            setErrors([...errors]);
        }
    };

    const modalButtons: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена' },
        {
            onClick: handleSave,
            label: 'Добавить номер',
            disabled: loadingContact || !contact_id,
            color: 'primary',
            startIcon: <AddIcCallIcon />,
            loading: loadingContact
        }
    ];

    return (
        <Modal
            onClose={onClose}
            header={`Выберите контакт для номера ${adjustPhoneNumber(phone)}`}
            maxWidth={'sm'}
            actions={modalButtons}
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ItemErrors errors={errors} />
                <div style={{ minHeight: '20em' }}>
                    <ContactDropdown onChange={changeContactId} item_id={contact_id} />
                </div>
            </ModalContent>
        </Modal>
    );
};

const CellCallToContactEdit = ({ phone }: { phone: string }) => {
    const [showUpdateModal, setUpdateModal] = useState(false);
    const handleToggleModal = () => {
        setUpdateModal(!showUpdateModal);
    };

    return (
        <>
            <Dropdown item icon="setting" inline>
                <Dropdown.Menu>
                    <Dropdown.Item
                        as={Link}
                        to={{
                            pathname: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                            state: {
                                modal: true,
                                editingData: { phone },
                                callback: {
                                    storeName: 'cellCallStore',
                                    method: 'fetchList'
                                }
                            }
                        }}
                    >
                        <Icon name="address card outline" />
                        Создать новый контакт
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleToggleModal}>
                        <Icon name="phone" />
                        Добавить номер в уже созданный контакт
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {showUpdateModal && <UpdateExistingContact phone={phone} onClose={handleToggleModal} />}
        </>
    );
};

export default CellCallToContactEdit;
