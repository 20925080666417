import React from 'react';
import ItemWrapper, { ItemWrapperState, TAB_ID_DEFAULT } from '~/components/Items/ItemWrapper';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import ItemErrors from '~/components/Items/ItemErrors';

import LifeComplexMenu from './LifeComplexMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

import LifeComplexShowMode from './LifeComplexShowMode';
import LifeComplexApartmentsMode from './ApartmentsMode';

const TAB_APARTMENTS = 'apartments';

const LifeComplex = ({ item_id, tabName }: ItemWrapperState) => {
    const { errors, item } = lifeComplexStore.getItem(item_id);

    const lifeComplexTabs: MenuItem[] = [
        { name: 'Описание', id: '', icon: <ApartmentIcon /> },
        { name: 'Квартиры', id: TAB_APARTMENTS, icon: <ViewComfyIcon /> }
    ];

    return (
        <>
            <LifeComplexMenu itemTabs={lifeComplexTabs} item_id={item_id} tabName={tabName} enable={true} major_user_id={-1} />
            <div className="crm-List__sidebar_modalBox">
                {errors && <ItemErrors errors={errors} tabName={tabName} />}
                {tabName === TAB_ID_DEFAULT && <LifeComplexShowMode newbuilding_id={item_id} />}
                {tabName === TAB_APARTMENTS && <LifeComplexApartmentsMode newbuilding_id={item_id} />}
            </div>
        </>
    );
};

export default ItemWrapper(lifeComplexStore, LifeComplex, () => true);
