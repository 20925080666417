import React, { useMemo, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as whatsappApi from '~/api/whatsappApi';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import ContainerHeader from '~ui/ContainerHeader';

import LoaderAwait from '~/components/Base/LoaderAwait';
import settingStore from '~/stores/settingStore';
import { SettingsListPaper } from '~/components/Settings';
import ListError from '~/components/ListError';
import TextInput from '~ui/TextInput';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';

import { WAUsedAccount } from '~/types/whatsapp.types';

const WHATSAPP_MEM_KEY = 'whatsapp';

const WhatsappSettings = () => {
    const [accounts, setAccounts] = useState<WAUsedAccount[]>([]);

    useMemo(() => {
        settingStore.fetchItem(WHATSAPP_MEM_KEY);
        whatsappApi.fetchWhatsappUsedAccounts().then(data => setAccounts(data));
    }, []);

    const { item, loadingItem, errors } = settingStore.getItem(WHATSAPP_MEM_KEY);

    let apiKey = '';
    let accountsAvailable = 0;
    let accountsVacant = 0;
    if (item?.value) {
        const settings: { apiKey: string; accountsAvailable: number } = JSON.parse(item?.value);
        apiKey = settings.apiKey;
        accountsAvailable = settings.accountsAvailable;
        accountsVacant = accountsAvailable - accounts.length;
    }

    return (
        <Fragment>
            <ContainerHeader title="Настройка Whatsapp" enableCreating={false} icon={<WhatsAppIcon fontSize="large" />} />

            <ListError errors={errors} />

            <Container maxWidth="md" fixed>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <TextInput value={apiKey} variant="filled" label="apiKey" fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput value={accountsAvailable} variant="filled" label="Аккаунтов" fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput value={accountsVacant} variant="filled" label="Доступно" fullWidth />
                    </Grid>
                </Grid>
            </Container>

            <SettingsListPaper>
                <List dense>
                    {accounts.map(({ phone, user_id, user }, index) => (
                        <ListItem alignItems="flex-start" button key={phone} onClick={() => {}}>
                            <ListItemText primary={<UserLinkWithPreview user={user} />} secondary={adjustPhoneNumber(phone)} />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingItem} linear />
            </SettingsListPaper>
        </Fragment>
    );
};

export default observer(WhatsappSettings);
