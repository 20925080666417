import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import TextInput, { NumberInput } from '~ui/TextInput';
import Grid from '~ui/Grid';
import Select from '~ui/Select';

import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import Paper from '@material-ui/core/Paper';
import estateL10n from '~/L10n/estate.L10n';
import { DropdownType } from '~/stores/prototypes/ListStore.prototype';

type LifeComplexAboutEditingProps = HandlerEditingWrappedProps & {
    about?: string;
    lifeComplexClass?: number;
    floorsCount?: number;
    materialType?: number;
    ceilingHeight?: number;
    buildYear?: number;
    readyQuarter?: number;
};

const LifeComplexAboutEditing = (props: LifeComplexAboutEditingProps) => {
    const { item_id, handleChange } = props;
    const [buildYearDropdown, setBuildYearDropdown] = useState<DropdownType[]>([]);

    useMemo(() => {
        const item = {
            about: props.about || '',
            lifeComplexClass: props.lifeComplexClass || null,
            floorsCount: props.floorsCount || null,
            materialType: props.materialType || null,
            ceilingHeight: props.ceilingHeight || null,
            readyQuarter: props.readyQuarter || null,
            buildYear: props.buildYear || null
        };
        lifeComplexStore.setEditingItem(props.item_id, item);

        const date = new Date();
        let buildYearDropdown: DropdownType[] = [];
        for (let year = date.getFullYear() - 5; year < date.getFullYear() + 10; year++) {
            buildYearDropdown.push({
                value: year,
                key: year,
                text: String(year)
            });
        }
        setBuildYearDropdown(buildYearDropdown);
    }, [item_id]);

    const { about, ceilingHeight, floorsCount, materialType, lifeComplexClass, buildYear, readyQuarter } =
        lifeComplexStore.getItem(item_id).editingItem;

    return (
        <Paper style={{ padding: '1rem' }}>
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Select
                        label="Год сдачи"
                        value={buildYear || null}
                        name="buildYear"
                        fullWidth
                        onChange={handleChange}
                        options={buildYearDropdown}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        label="Квартал сдачи"
                        value={readyQuarter || null}
                        name="readyQuarter"
                        fullWidth
                        onChange={handleChange}
                        options={estateL10n.NEWBUILDING_READY_QUARTER_DROPDOWN}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        label="Тип дома"
                        value={materialType || null}
                        name="materialType"
                        fullWidth
                        onChange={handleChange}
                        options={estateL10n.HOUSE_MATERIAL_TYPES_DROPDOWN}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        label="Класс"
                        value={lifeComplexClass || null}
                        name="lifeComplexClass"
                        fullWidth
                        onChange={handleChange}
                        options={estateL10n.NEWBUILDING_CLASS_DROPDOWN}
                    />
                </Grid.Column>
                <Grid.Column>
                    <NumberInput
                        label="Количество этажей"
                        size="small"
                        value={floorsCount || null}
                        name="floorsCount"
                        onChange={handleChange}
                        variant="classic"
                        fullWidth
                    />
                </Grid.Column>
                <Grid.Column>
                    <NumberInput
                        label="Высота потолков"
                        size="small"
                        value={ceilingHeight || null}
                        name="ceilingHeight"
                        onChange={handleChange}
                        maxLength={12}
                        variant="classic"
                        step={0.01}
                        extraLabel={'м.'}
                        fullWidth
                    />
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column>
                    <TextInput
                        label="Описание"
                        value={about}
                        name="about"
                        onChange={handleChange}
                        multiline
                        rowsMax={20}
                        rows={8}
                        variant="classic"
                        fullWidth
                    />
                </Grid.Column>
            </Grid>
        </Paper>
    );
};

export default HandlerEditingBlockWrapper(lifeComplexStore, observer(LifeComplexAboutEditing));
