import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LoaderAwait from '~/components/Base/LoaderAwait';
import Typography from '@material-ui/core/Typography';
import ListError from '~/components/ListError';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    root: {
        borderBottom: '2px solid #fff',
        paddingBottom: spacing(0.5),
        marginBottom: spacing(0.5),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center'
    }
}));

const BoardLineHeader = ({ label, title, loading, errors }: { label: string; title: string; loading: boolean; errors: string[] }) => {
    const classes = useStyles();

    return (
        <div key={label}>
            <header className={classes.root}>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography variant="subtitle2">{label}</Typography>
            </header>
            <ListError errors={errors} />
            <LoaderAwait linear active={loading} />
        </div>
    );
};

export default React.memo(BoardLineHeader);
