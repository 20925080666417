import React, { Fragment, useState } from 'react';
import { LearningMaterial } from '~/types/learning.types';

import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { execDaily } from '~/common/time';
import { Icon } from 'semantic-ui-react';

import ConfirmTrigger from '../Base/ui/ConfirmTrigger';
import authStore from '~/stores/authStore';
import learningStore from '~/stores/learningStore';
import UserAvatar from '~ui/UserAvatar';

type LearningMaterialCardProps = {
    learningMaterial: LearningMaterial;
    onHandleEdit: () => void;
    onHandleDelete: () => Promise<void>;
    onOpenModal: () => void;
};

const LearningMaterialCard = ({ learningMaterial, onHandleEdit, onOpenModal, onHandleDelete }: LearningMaterialCardProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const {
        major_user: { firstName, lastName, avatarUrl },
        major_user_id,
        updateTime,
        title
    } = learningMaterial;
    const { printTime } = execDaily(updateTime * 1000);

    const canEdit = authStore.canEdit(learningStore.moduleName, major_user_id);
    const canDelete = authStore.canDelete(learningStore.moduleName, major_user_id);

    return (
        <Card>
            <CardHeader
                avatar={<UserAvatar src={avatarUrl} firstName={firstName} lastName={lastName} />}
                action={
                    canEdit || canDelete ? (
                        <Fragment>
                            <IconButton onClick={handleClickMenu as any}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl} onClose={handleCloseMenu}>
                                {canEdit && (
                                    <MenuItem onClick={handleCloseMenu}>
                                        <div onClick={onHandleEdit}>
                                            <Icon name="edit" />
                                            Редактировать
                                        </div>
                                    </MenuItem>
                                )}
                                {canEdit && canDelete && <Divider />}
                                {canDelete && (
                                    <ConfirmTrigger
                                        as={'div'}
                                        trigger={
                                            <MenuItem onClick={handleCloseMenu}>
                                                <Icon name="trash" />В архив
                                            </MenuItem>
                                        }
                                        header="Отправить материал в Архив"
                                        content="Подтвердите, что вы хотите удалить этот материал"
                                        confirmButton="В архив"
                                        onConfirm={onHandleDelete}
                                    />
                                )}
                            </Menu>
                        </Fragment>
                    ) : null
                }
                title={`${lastName} ${firstName}`}
                subheader={printTime}
            />
            <CardActionArea onClick={onOpenModal}>
                <CardContent>
                    <Typography variant="subtitle1" color="textPrimary" component="p">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default LearningMaterialCard;
