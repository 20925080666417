import React from 'react';
import { Progress } from 'semantic-ui-react';
import {
    DEAL_STATUS_MORTGAGE_APPLY,
    DEAL_STATUS_MORTGAGE_APPROVE,
    DEAL_STATUS_MORTGAGE_EXECUTE,
    DEAL_STATUS_MORTGAGE_EXPIRED,
    DEAL_STATUS_MORTGAGE_INIT,
    DEAL_STATUS_MORTGAGE_REJECT,
    DEAL_TYPE_MORTGAGE_ID,
    DealStatusType
} from '~/types/deals.types';
import { DEAL_STATUS_BREAK, DEAL_STATUS_CLOSE, DEAL_STATUS_DONE, DEAL_STATUS_OPEN } from '~/types/deals.types';
import dealL10n from '~/L10n/deals.L10n';

const DealProgress = ({ status, type, size }: { status: DealStatusType; size?: 'tiny' | 'small'; type: number }) => {
    let progressColor = null;

    switch (status) {
        case DEAL_STATUS_OPEN:
        case DEAL_STATUS_MORTGAGE_INIT:
            progressColor = 'grey';
            break;
        case DEAL_STATUS_DONE:
        case DEAL_STATUS_MORTGAGE_APPLY:
            progressColor = 'yellow';
            break;
        case DEAL_STATUS_CLOSE:
        case DEAL_STATUS_MORTGAGE_APPROVE:
        case DEAL_STATUS_MORTGAGE_EXECUTE:
            progressColor = 'green';
            break;
        case DEAL_STATUS_BREAK:
        case DEAL_STATUS_MORTGAGE_EXPIRED:
        case DEAL_STATUS_MORTGAGE_REJECT:
            progressColor = 'red';
            break;

        default:
            break;
    }

    return (
        <Progress
            size={size || 'tiny'}
            total={2}
            value={(status === DEAL_STATUS_BREAK ? DEAL_STATUS_CLOSE : Number(status)) - 1}
            color={progressColor}
            label={
                <span className="crm-Estate__fieldNowrap">
                    {type === DEAL_TYPE_MORTGAGE_ID
                        ? dealL10n.DEAL_STATUS_MORTGAGE_TYPE_FIND(status)
                        : dealL10n.DEAL_STATUS_TYPE_FIND(status)}
                </span>
            }
        />
    );
};

export default React.memo(DealProgress);
