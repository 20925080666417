import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import Button from '~ui/Button';
import FixMlsPriceModal from '../SubBlocks/FixMlsPriceModal';
import { useToggle } from '~ui/Modal';
import Grid from '~ui/Grid';
import Time from '~/components/Base/Time';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import AgentBonus from '~/components/Base/AgentBonus';
import Price from '~/components/Base/Price';

const OwnFixedMlsPrices = observer(({ estate_id }: { estate_id: number }) => {
    useMemo(() => {
        estateStore.fetchOwnFixedPriceEstates(estate_id);
    }, []);

    const { ownFixedMlsPrice, loadingOwnMlsPrice } = estateStore.getItem(estate_id).property;
    if (!ownFixedMlsPrice || !ownFixedMlsPrice.length) {
        return null;
    }

    return (
        <List>
            {ownFixedMlsPrice.map(
                ({
                    estate_fix_price_id,
                    phone,
                    status,
                    agentBonusCurrency,
                    agentBonusType,
                    price,
                    currency,
                    agentBonusValue,
                    createTime,
                    updateTime
                }) => (
                    <ListItem key={estate_fix_price_id}>
                        <ListItemText
                            primary={
                                <>
                                    <Time time={createTime} />, Телефон: {adjustPhoneNumber(phone)}
                                </>
                            }
                            secondary={
                                <Grid columns={2} disableGutter>
                                    <Grid.Column>
                                        Цена: <Price price={price} currency={currency} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        Бонус:&nbsp;
                                        <AgentBonus
                                            agentBonusCurrency={agentBonusCurrency}
                                            agentBonusType={agentBonusType}
                                            agentBonusValue={agentBonusValue}
                                        />
                                    </Grid.Column>
                                </Grid>
                            }
                        />
                    </ListItem>
                )
            )}
        </List>
    );
});

const FixPriceBlockShow = (props: EditingWrappedProps) => {
    const { item_id } = props;
    const { loadingItem, item } = estateStore.getItem(item_id);

    const [showModal, toggleModal] = useToggle();

    return (
        <Card fluid color="green">
            <Card.Content>
                <Button onClick={toggleModal} fullWidth variant="outlined" color="secondary" disabled={loadingItem}>
                    Зафиксировать цену
                </Button>
                {!loadingItem && <OwnFixedMlsPrices estate_id={item_id} />}
                {showModal && item && <FixMlsPriceModal estate_id={item_id} base={item.base} onClose={toggleModal} />}
            </Card.Content>
        </Card>
    );
};

export default observer(FixPriceBlockShow);
