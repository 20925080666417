import { observable, action, computed } from 'mobx';
import * as exportReportsApi from '~/api/export/exportReportsApi';
import { EstateExportStatus, ExportReportsFilter } from '~/types/exportReports.types';
import { EXPORT_REPORT_STATUS_ERROR, EXPORT_REPORT_STATUS_WARNING } from '~/types/exports.types';
import deepCopy from '~/common/deepCopy';

const LIST_COUNT = 50;

const initFilter: ExportReportsFilter = { export_base_id: [], status: [EXPORT_REPORT_STATUS_ERROR, EXPORT_REPORT_STATUS_WARNING] };

class ExportReportsStore {
    @observable
    exportReportErrorsList: EstateExportStatus[] = [];
    @observable
    hasMore = true;
    @observable
    filter: ExportReportsFilter = deepCopy(initFilter);
    @observable
    currentPage: number = 0;
    @observable
    isLoading: boolean = false;
    @observable
    loadingErrors: string[] = [];

    @action
    changeFilter<T extends keyof ExportReportsFilter>(what: T, value: ExportReportsFilter[T]) {
        this.filter[what] = value;
        this.currentPage = 0;
        this.fetchExportErrorsList();
    }

    @action
    loadNextPage = () => {
        if (!this.isLoading) {
            this.currentPage++;
            this.fetchExportErrorsList();
        }
    };

    @action
    fetchExportErrorsList = async () => {
        this.isLoading = true;
        this.loadingErrors = [];
        if (this.currentPage === 0) {
            this.exportReportErrorsList = [];
            this.hasMore = true;
        }
        try {
            const errorsList = await exportReportsApi.fetchExportErrorsList(this.filter, this.currentPage * LIST_COUNT, LIST_COUNT);
            if (errorsList.length < LIST_COUNT) {
                this.hasMore = false;
            }
            this.exportReportErrorsList = [...this.exportReportErrorsList, ...errorsList];
        } catch (errors) {
            console.log(errors);
            this.loadingErrors = errors;
        } finally {
            this.isLoading = false;
        }
    };
}

export default new ExportReportsStore();
