import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { Estate, ShareEstateId } from '~/types/estate.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { ShareFilter } from '~/stores/shareStore';

export const createShareEstates = async (estateIds: ShareEstateId[]): Promise<string> => {
    const graphql = `mutation {
        createShareEstates(estateIds: ${objectToGraphql(estateIds)})
      }`;

    const data: { createShareEstates: string } = await fetchApi.postGQ(graphql);
    return data.createShareEstates;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    filter: ShareFilter,
    controller?: AbortController
): Promise<{ list: Estate[]; count: number }> => {
    const data: { estates: Estate[] } = await fetchApi.get(`/share?uuid=${filter.uuid}`, controller);
    return await { list: data.estates, count: data.estates.length };
};

export const fetchItem = async (id: number, base?: string | null): Promise<Estate> => {
    const graphql = `{}`;

    const data: { fetchEstate: Estate } = await fetchApi.postGQ(graphql);
    return data.fetchEstate;
};
