import React, { Fragment } from 'react';
import { ReactNode } from 'react';
import { Placeholder } from 'semantic-ui-react';

const ShadowContent = React.memo(() => (
    <Placeholder>
        <Placeholder.Line length="full" />
    </Placeholder>
));

const ShadowFragment = ({ print, shadow }: { print: any; shadow?: boolean }) => {
    let result: string | ReactNode = '';

    if (typeof shadow === 'boolean') {
        result = shadow ? <ShadowContent /> : print;
    } else {
        result = print === '' || print ? print : <ShadowContent />;
    }

    return <Fragment>{result || null}</Fragment>;
};

export default ShadowFragment;
