import { objectToGraphql } from '../common/graphql';
import fetchApi from '../common/fetchApi';
import { RequestType, RequestTableType, RequestFilter } from '~/types/requests.types';
import { GQContactPhones, GQMainContactFields } from './contactsApi';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQComments } from '~/api/commentsApi';
import { HistoryChange } from '~/types/historyChanges.types';

const GQRequestMainFields = `
    request_id
    type
    propertyType
    enable  
`;

const GQRequestFields = `
    ${GQRequestMainFields}

    contact_id
    
    requestJson
    status
    
    major_user_ids
    major_users {
        ${GQUserMainFields}    
    }
    tags_ids
    
    contact {
        ${GQMainContactFields}
        ${GQContactPhones}
    }

    createTime
    updateTime
`;

const GQRequestTableFields = `
    ${GQRequestFields}
    
    comments {
        ${GQComments}
    }  
`;

export const fetchItem = async (id: number): Promise<RequestType> => {
    const graphql = `{
        fetchRequest(request_id: ${id}) {
            ${GQRequestFields}
        } 
      }`;

    const data: { fetchRequest: RequestType } = await fetchApi.getGQ(graphql);
    return data.fetchRequest;
};

export const createItem = async (request: RequestType): Promise<number> => {
    const graphql = `mutation { 
          createRequest(requestInput: ${objectToGraphql(request)})
        }`;

    const data: { createRequest: number } = await fetchApi.postGQ(graphql);
    return data.createRequest;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    requestFilter: RequestFilter,
    controller?: AbortController
): Promise<{ list: RequestTableType[]; count: number }> => {
    const graphql = `{
            fetchRequests(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(requestFilter)}) {
                list {${GQRequestTableFields}}
                count
            } 
          }`;

    const data: { fetchRequests: { list: RequestTableType[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchRequests;
};

export const saveItem = async (request_id: number, request: RequestType): Promise<number> => {
    const graphql = `mutation { 
        updateRequest(request_id: ${request_id}, requestDiff: ${objectToGraphql(request)})
    }`;

    const data: { updateRequest: number } = await fetchApi.postGQ(graphql);
    return data.updateRequest;
};

export const requestsByContact = async (contact_id: number): Promise<RequestTableType[]> => {
    const graphql = `{
        requestsByContact(contact_id: ${contact_id}) {
            ${GQRequestMainFields}
        } 
      }`;

    const data: { requestsByContact: RequestTableType[] } = await fetchApi.getGQ(graphql);
    return data.requestsByContact;
};

export const fetchItemHistory = async (request_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchRequestChangeHistory(request_id: ${request_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchRequestChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchRequestChangeHistory;
};
