import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Statistic, Icon, Button } from 'semantic-ui-react';
import { BarChart, Bar, Cell, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import statisticStore from '~/stores/statisticStore';
import UserDropdown from '../ItemsDropdowns/UserDropdown';
import authStore from '~/stores/authStore';
import ListError from '../ListError';
import { ACCESS_COMPANY, ACCESS_GROUP, ACCESS_READ, ACCESS_WRITE } from '~/types/access.types';
import HomeStatisticsModal from './Statistics';
import statisticsConfig from '../../config/statistics.config';
import { DAILY_CALLS_KEY, STAT_TYPE_BY_DAY, STAT_TYPE_BY_WEEK, WEEK_CALLS_KEY } from '~/types/statistics.types';
import { STAT_CHART_TYPES } from '~/types/statistics.types';
import LoaderAwait from '../Base/LoaderAwait';
import estateStore from '~/stores/estateStore';
import history from '../../history';
import commonStore from '~/stores/commonStore';
import Scrollbar from '../Base/Scrollbar';
import UserEstateMap from './UserEstateMap';
import ButtonGroup from '~/components/Base/ButtonGroup';
import UserKpisStat from './Statistics/UserKpisStat';
import settingStore from '~/stores/settingStore';
import { CRM_SETTINGS_KEY } from '~/types/settings.types';

type HomeStatisticsState = {
    selectedUserId: number;
    showStatModal: boolean;
    chartType: STAT_CHART_TYPES;
};

export const matchColor = (count: number, { green, red }: { green: number; red: number }): 'green' | 'red' | 'yellow' => {
    return count > green ? 'green' : count < red ? 'red' : 'yellow';
};

const matchColorChart = (count: number, { green, red }: { green: number; red: number }): string => {
    return count > green ? '#21BA45' : count < red ? '#DB2828' : '#FBBD08';
};

const StatChartTooltip = ({ payload, label }: { payload?: Array<{ value: number }>; label?: string }) => {
    return (
        <div>
            <p>
                Звонков: <b>{`${payload && payload[0] ? payload[0].value : ''}`}</b>
            </p>
            <p>{`${label || ''}`}</p>
        </div>
    );
};

@observer
class HomeStatistics extends Component<{}, HomeStatisticsState> {
    constructor(props: {}) {
        super(props);
        settingStore.fetchItem(CRM_SETTINGS_KEY);

        this.state = {
            selectedUserId: authStore.currentUser.user_id,
            showStatModal: false,
            chartType: STAT_TYPE_BY_DAY
        };

        statisticStore.fetchItem(this.state.selectedUserId);
        statisticStore.fetchUserChartData(this.state.selectedUserId, this.state.chartType);
    }

    handleToggleStatModal = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        this.setState({ showStatModal: !this.state.showStatModal });
    };

    handleChangeUserId = (user_id: number | Array<number>) => {
        const selectedUserId = typeof user_id === 'number' ? user_id : authStore.currentUser.user_id;

        statisticStore.fetchItem(selectedUserId);
        statisticStore.fetchUserChartData(selectedUserId, this.state.chartType);

        this.setState({ selectedUserId });
    };

    handleRefresh = () => {
        statisticStore.fetchItem(this.state.selectedUserId);
        statisticStore.fetchUserChartData(this.state.selectedUserId, this.state.chartType);
    };

    handleChangeType = () => {
        const chartType = this.state.chartType === STAT_TYPE_BY_DAY ? STAT_TYPE_BY_WEEK : STAT_TYPE_BY_DAY;

        this.setState({ chartType });
        statisticStore.fetchUserChartData(this.state.selectedUserId, chartType);
    };

    handleShowBadEstates = () => {
        estateStore.setFilterProblemEstatesUserId(this.state.selectedUserId);

        history.push({
            pathname: `/estate/crm`
        });
    };

    handleShowExportErrorsEstates = () => {
        estateStore.setFilterExportErrorsEstatesUserId(this.state.selectedUserId);

        history.push({
            pathname: `/estate/crm`
        });
    };

    render() {
        const { selectedUserId, showStatModal, chartType } = this.state;
        const { isMobile } = commonStore;

        const { errors, loadingItem, item, property } = statisticStore.getItem(selectedUserId);
        const { loadingItem: loadingMainSettings } = settingStore.getItem(CRM_SETTINGS_KEY);

        const dailyCalls = item ? item.dailyCalls?.toLocaleString() : '...';
        const weekCalls = item ? item.weekCalls?.toLocaleString() : '...';
        const avitoDailyCalls = item ? item.avitoDailyCalls?.toLocaleString() : '...';
        const problemEstatesCount = item ? item.problemEstatesCount : 0;
        const exportEstateErrorsCount = item ? item.exportEstateErrorsCount : 0;

        let dailyColor = null;
        let weekColor = null;

        if (item && !loadingItem) {
            dailyColor = matchColor(item.dailyCalls, statisticsConfig[DAILY_CALLS_KEY]);
            weekColor = matchColor(item.weekCalls, statisticsConfig[WEEK_CALLS_KEY]);
        }

        const groups_id =
            authStore.getPermission(statisticStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP ? [authStore.currentUser.group_id] : [];

        const whetherCanChangeUser = [ACCESS_GROUP, ACCESS_COMPANY].includes(
            authStore.getPermission(statisticStore.moduleName, ACCESS_READ)
        );

        const { chartData = [], loadingChart = false } = property || {};

        return (
            <Scrollbar suppressScrollX disableOnMobile style={{ maxHeight: 'calc(100vh - 80px)' }}>
                <Card fluid style={isMobile ? null : { maxWidth: '300px' }}>
                    <ListError errors={errors} />

                    <Card.Content>
                        <Card.Header>
                            Статистика
                            <Button basic size="tiny" floated="right" icon="refresh" title="Обновить" onClick={this.handleRefresh} />
                        </Card.Header>
                    </Card.Content>
                    {whetherCanChangeUser && (
                        <Card.Content>
                            <UserDropdown user_id={selectedUserId} groups_id={groups_id} onChange={this.handleChangeUserId} fluid />
                        </Card.Content>
                    )}
                    {exportEstateErrorsCount > 0 && (
                        <Card.Content>
                            <Statistic.Group horizontal size="mini">
                                <Statistic color="red">
                                    <Statistic.Value className="crm-Estate__fieldNowrap">
                                        <Icon name="warning circle" />
                                        {exportEstateErrorsCount.toLocaleString()}
                                    </Statistic.Value>
                                    <Statistic.Label
                                        size="mini"
                                        as={Button}
                                        title="Смотреть в поиске"
                                        icon
                                        labelPosition="right"
                                        onClick={this.handleShowExportErrorsEstates}
                                    >
                                        Ошибки экспорта <Icon name="search" />
                                    </Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </Card.Content>
                    )}
                    {problemEstatesCount > 0 && (
                        <Card.Content>
                            <Statistic.Group horizontal size="mini">
                                <Statistic color="red">
                                    <Statistic.Value className="crm-Estate__fieldNowrap">
                                        <Icon name="building outline" />
                                        {problemEstatesCount.toLocaleString()}
                                    </Statistic.Value>
                                    <Statistic.Label
                                        size="mini"
                                        as={Button}
                                        title="Смотреть в поиске"
                                        icon
                                        labelPosition="right"
                                        onClick={this.handleShowBadEstates}
                                        loading={loadingMainSettings}
                                    >
                                        Проблемные объекты <Icon name="search" />
                                    </Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </Card.Content>
                    )}

                    <UserKpisStat user_id={selectedUserId} />

                    <Card.Content>
                        <Statistic.Group horizontal size="tiny">
                            <Statistic
                                color={dailyColor}
                                title={`Количество исходящих звонков за сегодня:
                                    <${statisticsConfig[DAILY_CALLS_KEY].red} - красный цвет;
                                    >${statisticsConfig[DAILY_CALLS_KEY].green} - зеленый цвет`}
                            >
                                <Statistic.Value>
                                    <Icon name="phone" />
                                    {dailyCalls}
                                </Statistic.Value>
                                <Statistic.Label>за сегодня</Statistic.Label>
                            </Statistic>
                            <Statistic
                                color={weekColor}
                                title={`Количество исходящих звонков за 7 дней:
                                    <${statisticsConfig[WEEK_CALLS_KEY].red} - красный цвет;
                                    >${statisticsConfig[WEEK_CALLS_KEY].green} - зеленый цвет`}
                            >
                                <Statistic.Value>
                                    <Icon name="phone" />
                                    {weekCalls}
                                </Statistic.Value>
                                <Statistic.Label>за 7 дней</Statistic.Label>
                            </Statistic>
                            <Statistic title={`Количество уникальных исходящих звонков на avito.ru за сегодня`}>
                                <Statistic.Value>
                                    <Icon name="phone" />
                                    {avitoDailyCalls}
                                </Statistic.Value>
                                <Statistic.Label>Авито</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Card.Content>

                    <Card.Content textAlign="center">
                        <ButtonGroup
                            size="medium"
                            buttonSet={[
                                [STAT_TYPE_BY_DAY, 'День', null, { background: '#21BA45', color: 'white' }],
                                [STAT_TYPE_BY_WEEK, 'Неделя', null, { background: '#21BA45', color: 'white' }]
                            ]}
                            name="chartType"
                            value={chartType}
                            handleChange={this.handleChangeType}
                        />

                        <div style={{ height: '200px' }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={chartData}
                                    margin={{
                                        top: 15,
                                        right: 0,
                                        left: 0,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <Tooltip content={<StatChartTooltip />} />
                                    <Bar dataKey="count">
                                        {chartData.map(({ count }, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={matchColorChart(
                                                    count,
                                                    statisticsConfig[chartType === STAT_TYPE_BY_WEEK ? WEEK_CALLS_KEY : DAILY_CALLS_KEY]
                                                )}
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card.Content>

                    <Button fluid basic color="green" onClick={this.handleToggleStatModal}>
                        <Icon name="chart bar" />
                        Сводная таблица
                    </Button>

                    {!isMobile && (
                        <Fragment>
                            <br />
                            <UserEstateMap user_id={selectedUserId} />
                        </Fragment>
                    )}

                    <LoaderAwait active={loadingItem || loadingChart} />
                    {showStatModal && <HomeStatisticsModal onClose={this.handleToggleStatModal} />}
                </Card>
            </Scrollbar>
        );
    }
}

export default HomeStatistics;
