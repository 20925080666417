import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Icon, Placeholder } from 'semantic-ui-react';

import showingStore from '~/stores/showingStore';
import EstateAndContactShow from './EstateAndContactShow';
import MajorUserShowBlock from './MajorUserShowBlock';

@observer
class AddedItemsShow extends Component<{ item_id: number }> {
    render() {
        const { item_id } = this.props;
        const { item, loadingItem } = showingStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        return (
            <Fragment>
                <MajorUserShowBlock item_id={item_id} />
                <EstateAndContactShow item_id={item_id} />
            </Fragment>
        );
    }
}

export default AddedItemsShow;
