import fetchApi, { grabMessageFromError } from '../common/fetchApi';

import { objectToGraphql } from '../common/graphql';
import { TaskTrigger, Trigger } from '~/types/triggers.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const TriggerGQLFields = `
    trigger_id
    trigger
    taskTitle
    taskDescription
    needApprovalNop
    priority
    enable
`;

export const fetchTasksTriggers = async (): Promise<Array<TaskTrigger>> => {
    const graphql = `{
          fetchTaskTriggers {
            trigger
            title
          }
        }`;

    const data: { fetchTaskTriggers: TaskTrigger[] } = await fetchApi.getGQ(graphql);
    return data.fetchTaskTriggers;
};

export const createItem = async (trigger: Trigger): Promise<number> => {
    const graphql = `mutation { 
          createTrigger(inputTrigger: ${objectToGraphql(trigger)})
        }`;

    const data: { createTrigger: number } = await fetchApi.postGQ(graphql);
    return data.createTrigger;
};

export const saveItem = async (trigger_id: number, triggerDiff: Trigger): Promise<number> => {
    const graphql = `mutation { 
        updateTrigger(trigger_id: ${trigger_id}, triggerDiff: ${objectToGraphql(triggerDiff)})
    }`;

    const data: { updateTrigger: number } = await fetchApi.postGQ(graphql);
    return data.updateTrigger;
};

export const fetchItem = async (id: number): Promise<Trigger> => {
    const graphql = `{
            fetchTrigger(trigger_id: ${id}) {
                ${TriggerGQLFields}
            } 
          }`;

    const data: { fetchTrigger: Trigger } = await fetchApi.getGQ(graphql);
    return data.fetchTrigger;
};

export const fetchList = async (): Promise<{ list: Array<Trigger>; count: number }> => {
    const graphql = `{
        fetchTriggers{
            list {
                ${TriggerGQLFields}
            }
            count
        }
      }`;

    const data: { fetchTriggers: { list: Trigger[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchTriggers;
};
