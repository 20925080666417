import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import Button from '~ui/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Pult } from '~ui/FilterPult';
import Grid from '~ui/Grid';

import whatsappStore from '~/stores/whatsappStore';
import estateSMAShareStore from '~/stores/estateSMAShareStore';

import SmaShareModal from './SmaShareModal';
import SmaShareTable from './SmaShareTable';
import WhatsappDropdown from '~/components/ItemsDropdowns/WhatsappDropdown';
import { WhatsappChatTitle } from '~/types/whatsapp.types';

const SmaShareShow = ({ sma_id, estate_id }: { sma_id: number; estate_id: number }) => {
    useMemo(() => {
        estateSMAShareStore.fetchItemList(sma_id);
    }, []);

    const [showShareModal, setShowShareModal] = useState(false);
    const toggleShareModal = () => {
        setShowShareModal(!showShareModal);
    };

    const [currentWaTitle, setWaTitle] = useState<WhatsappChatTitle | null>(null);

    const { list } = estateSMAShareStore.getItemList(sma_id);

    return (
        <>
            <Pult>
                <Grid columns={2} stackable>
                    <Grid.Column>
                        <WhatsappDropdown value={currentWaTitle} onChange={setWaTitle} />
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<WhatsAppIcon />}
                            disableRipple
                            disabled={whatsappStore.whatsappStatus !== 'ready' || currentWaTitle === null}
                            onClick={toggleShareModal}
                        >
                            Отправить
                        </Button>
                    </Grid.Column>
                </Grid>

                {showShareModal && (
                    <SmaShareModal onClose={toggleShareModal} sma_id={sma_id} estate_id={estate_id} waTitle={currentWaTitle} />
                )}
            </Pult>
            <SmaShareTable list={list} />
        </>
    );
};

export default observer(SmaShareShow);
