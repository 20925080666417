import React, { PureComponent, Fragment } from 'react';
import { Table, Header, Card } from 'semantic-ui-react';
import { OwnerBookedUser } from '~/types/owners.types';
import UserLinkWithPreview from '../../../Lists/Users/UserLinkWithPreview';
import Time from '../../../Base/Time';

const OwnerBookedInfo = ({ major_user, bookTime, expireTime }: OwnerBookedUser) => (
    <div className="crm-Estate__owner_booked">
        <Header as="h3" color="red">
            Собственник зарезервирован
        </Header>
        <Table color="red">
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell>Кем</Table.HeaderCell>
                    <Table.HeaderCell>Когда</Table.HeaderCell>
                    <Table.HeaderCell>Освободится</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <UserLinkWithPreview user={major_user} />
                    </Table.Cell>
                    <Table.Cell>
                        <Time time={bookTime} />
                    </Table.Cell>
                    <Table.Cell>
                        <Time time={expireTime} />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </div>
);

export default OwnerBookedInfo;
