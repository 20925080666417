import fetchApi from '~/common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { CallSource } from '~/types/cellCalls.types';

const SourceGQLFields = `
    source_id
    title
    color
    description
    createTime
    updateTime
    enable
`;

export const createItem = async (callSource: CallSource): Promise<number> => {
    const graphql = `mutation { 
          createCallSource(inputCallSource: ${objectToGraphql(callSource)})
        }`;

    const data: { createCallSource: number } = await fetchApi.postGQ(graphql);
    return data.createCallSource;
};

export const saveItem = async (source_id: number, callSourceDiff: CallSource): Promise<number> => {
    const graphql = `mutation { 
        updateCallSource(source_id: ${source_id}, callSourceDiff: ${objectToGraphql(callSourceDiff)})
    }`;

    const data: { updateCallSource: number } = await fetchApi.postGQ(graphql);
    return data.updateCallSource;
};

export const fetchItem = async (id: number): Promise<CallSource> => {
    const graphql = `{
            fetchCallSource(source_id: ${id}) {
                ${SourceGQLFields}
            } 
          }`;

    const data: { fetchCallSource: CallSource } = await fetchApi.getGQ(graphql);
    return data.fetchCallSource;
};

export const fetchList = async (): Promise<{ list: Array<CallSource>; count: number }> => {
    const graphql = `{
            fetchCallSources{
                list {
                    ${SourceGQLFields}
                }
                count
            }
          }`;

    const data: { fetchCallSources: { list: CallSource[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchCallSources;
};

export const fetchAllCallSources = async (): Promise<Array<CallSource>> => {
    const graphql = `{
        fetchAllCallSources{
            ${SourceGQLFields}
        }
      }`;

    const data: { fetchAllCallSources: CallSource[] } = await fetchApi.getGQ(graphql);
    return data.fetchAllCallSources;
};
