import React, { ReactNode, CSSProperties, SyntheticEvent } from 'react';

import MUCheckbox from '@material-ui/core/Checkbox';
import MUSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

type CheckboxProps = {
    label?: ReactNode;
    value?: string | number;
    name?: string;
    checked?: boolean | null;
    disabled?: boolean;
    title?: string;
    style?: CSSProperties;
    size?: 'medium' | 'small';
} & (
    | {
          onChange?:
              | ((event: SyntheticEvent) => void)
              | ((event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => void);
      }
    | { handleChange?: (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => void }
    | { onClick: (...args: any[]) => void }
);

export const Checkbox = (props: CheckboxProps) => {
    const { label, value, name, checked, disabled, title, style, size } = props;

    const handleChange = (event: SyntheticEvent) => {
        if ('onClick' in props) {
            props.onClick();
            return;
        }

        'onChange' in props
            ? props.onChange(event, { name, checked: !checked })
            : 'handleChange' in props
            ? props.handleChange(event, { name, checked: !checked })
            : null;
    };

    const Element = (
        <FormControlLabel
            control={
                <MUCheckbox
                    checked={Boolean(checked)}
                    disabled={disabled}
                    onClick={handleChange}
                    name={name}
                    value={value}
                    style={style}
                    size={size}
                />
            }
            label={label}
        />
    );

    if (title) {
        return <Tooltip title={title}>{Element}</Tooltip>;
    }

    return Element;
};

export const Switch = (props: CheckboxProps) => {
    const { label, value, name, checked, disabled, title } = props;

    const handleChange = (event: SyntheticEvent) => {
        if ('onClick' in props) {
            props.onClick();
            return;
        }

        'onChange' in props
            ? props.onChange(event, { name, checked: !checked })
            : 'handleChange' in props
            ? props.handleChange(event, { name, checked: !checked })
            : null;
    };

    const Element = (
        <FormControlLabel
            control={<MUSwitch checked={Boolean(checked)} disabled={disabled} onClick={handleChange} name={name} value={value} />}
            label={label}
        />
    );

    if (title) {
        return <Tooltip title={title}>{Element}</Tooltip>;
    }

    return Element;
};

export default Checkbox;
