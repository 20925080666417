import React from 'react';
import { AGENT_BONUS_TYPE_FIXED, AGENT_BONUS_TYPE_PERCENT } from '~/types/estate.types';
import Price from '~/components/Base/Price';

const AgentBonus = ({
    agentBonusType,
    agentBonusValue,
    agentBonusCurrency
}: {
    agentBonusType: number | null;
    agentBonusValue: number | null;
    agentBonusCurrency?: number | null;
}) => {
    return (
        <>
            {agentBonusType === AGENT_BONUS_TYPE_FIXED && <Price price={agentBonusValue} currency={agentBonusCurrency} />}
            {agentBonusType === AGENT_BONUS_TYPE_PERCENT && <>{agentBonusValue}%</>}
        </>
    );
};

export default React.memo(AgentBonus);
