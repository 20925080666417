import React, { useState, useEffect } from 'react';
import { ESTATE_BASES } from '~/types/estate.types';

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { DynamicActiveButton } from '~/components/Base/ui/DynamicActiveButton';
import estateSMAStore from '~/stores/estateSMAStore';

type EstateTableSmaButtonProps = {
    estate_id: number;
    base: ESTATE_BASES;
    sma_estate_id: number;
    whetherIsInSma: boolean;
};

const EstateTableSmaButton = ({ estate_id, base, sma_estate_id, whetherIsInSma }: EstateTableSmaButtonProps) => {
    const [isInSmaTable, setIsInSmaTable] = useState(whetherIsInSma);
    const handleToggleSmaButton = async isInSmaTable => {
        estateSMAStore.estateToSma(sma_estate_id, estate_id, base, isInSmaTable);
        setIsInSmaTable(isInSmaTable);
    };

    useEffect(() => {
        setIsInSmaTable(whetherIsInSma);
    }, [whetherIsInSma]);

    return (
        <div className="crm-Estate__isFavoriteBlock" style={{ marginRight: '0.5em' }}>
            <DynamicActiveButton
                enable={isInSmaTable}
                positiveInfo="Добавлено в СМА"
                negativeInfo="Убрано из СМА"
                titleOnEnable="Убрать из списка СМА"
                titleOnDisable="Добавить в список СМА"
                size="small"
                onChange={handleToggleSmaButton}
                icon={!isInSmaTable ? <PlaylistAddIcon fontSize="small" /> : <PlaylistAddCheckIcon fontSize="small" color="primary" />}
            />
        </div>
    );
};

export default EstateTableSmaButton;
