import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'clsx';
import serverConfig from '~/config/server.config';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RedoIcon from '@material-ui/icons/Redo';
import ReplyIcon from '@material-ui/icons/Reply';

import Time from '~/components/Base/Time';
import isEqual from '~/common/isEqual';
import { WAChatMessage, WAMediaFile } from '~/types/whatsapp.types';

const defaultChatMsgStyles = makeStyles(({ palette, spacing }) => {
    const radius = spacing(2.5);
    const size = spacing(4);
    const rightBgColor = '#dcf8c6'; // palette.primary.main;

    return {
        avatar: {
            width: size,
            height: size
        },
        leftRow: {
            textAlign: 'left'
        },
        rightRow: {
            textAlign: 'right'
        },
        msg: {
            padding: spacing(1, 2),
            borderRadius: 4,
            marginBottom: '4px !important',
            display: 'inline-block',
            wordBreak: 'break-word',
            fontSize: '14px'
        },
        left: {
            borderTopRightRadius: radius,
            borderBottomRightRadius: radius,
            backgroundColor: '#b8f2ff'
        },
        right: {
            borderTopLeftRadius: radius,
            borderBottomLeftRadius: radius,
            backgroundColor: rightBgColor
            // color: palette.common.white
        },
        leftFirst: {
            borderTopLeftRadius: radius
        },
        leftLast: {
            borderBottomLeftRadius: radius
        },
        rightFirst: {
            borderTopRightRadius: radius
        },
        rightLast: {
            borderBottomRightRadius: radius
        },
        doneIcon: {
            fontSize: '1em',
            marginLeft: '0.5em'
        }
    };
});

type ChatMsgProps = {
    avatar?: string;
    messages: WAChatMessage[];
    side?: 'left' | 'right';
};

const PrintWaMedia = ({ filename, mimetype, originalname }: WAMediaFile) => {
    const url = serverConfig.whatsappFilesUrl + filename;
    if (mimetype.indexOf('image') === 0) {
        return <img width={240} src={url} />;
    } else if (mimetype.indexOf('video') === 0) {
        return (
            <video controls width="240">
                <source src={url} type={mimetype} />
            </video>
        );
    } else if (mimetype.indexOf('audio') === 0) {
        return (
            <audio controls>
                <source src={url} type={mimetype} />
            </audio>
        );
    }
    return null;
};

const WAChatMsg = ({ avatar, messages, side = 'left' }: ChatMsgProps) => {
    const classes = defaultChatMsgStyles();

    const attachClass = (index: number): string => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return '';
    };

    return (
        <div>
            <Grid container spacing={2} justify={side === 'right' ? 'flex-end' : 'flex-start'}>
                {side === 'left' && (
                    <Grid item>
                        <Avatar src={avatar} className={classes.avatar} />
                    </Grid>
                )}
                <Grid item xs={8}>
                    {messages.map((waMessage, i) => {
                        const {
                            message: { message, createTime, message_id, isForwarded, hasQuotedMsg, status },
                            media,
                            links
                        } = waMessage;

                        return (
                            <div key={message_id} className={classes[`${side}Row`]}>
                                <div className={cx(classes.msg, classes[side], attachClass(i))}>
                                    {/*<div>status: ${status}</div>*/}
                                    {isForwarded && (
                                        <Typography variant="caption">
                                            <RedoIcon className={classes.doneIcon} /> Переслано
                                        </Typography>
                                    )}
                                    {hasQuotedMsg && (
                                        <Typography variant="caption">
                                            <ReplyIcon className={classes.doneIcon} /> Ответ
                                        </Typography>
                                    )}
                                    {links.length > 0 && (
                                        <div>
                                            <ul>
                                                {links.map(link => (
                                                    <li key={link}>
                                                        <a href={link} target="_blank" rel="noreferrer">
                                                            {link.slice(0, 120)}
                                                            {link.length > 120 ? '...' : ''}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {message && <Typography align="left">{message}</Typography>}
                                    {media && (
                                        <div>
                                            <PrintWaMedia {...media} />
                                        </div>
                                    )}
                                    <Typography variant="caption">
                                        <Time time={createTime} />
                                        {side === 'right' && <DoneIcon className={classes.doneIcon} />}
                                        {side === 'left' && <DoneAllIcon className={classes.doneIcon} color="primary" />}
                                    </Typography>
                                </div>
                            </div>
                        );
                    })}
                </Grid>
                {side === 'right' && (
                    <Grid item>
                        <Avatar src={avatar} className={classes.avatar} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default React.memo(WAChatMsg, isEqual);
