import React from 'react';
import { observer } from 'mobx-react';

import { Bar, ComposedChart, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer, Area } from 'recharts';
import { StatChartTooltip } from '../../../../Analytics/ChartStatistics';

import commonStore from '~/stores/commonStore';
import { EXPORT_BASES_OPTIONS } from '~/stores/export/exportStatisticsStore';

import { ExportStatChart, ExportStatChartPult, ExportStatListPult } from '~/types/exportStatistics.types';

const chartMargins = {
    top: 5,
    right: 0,
    left: 0,
    bottom: 5
};

export type PriceHistorySet = { [key: string]: number };

const EstateStatisticsChart = ({
    chartStatistics,
    statisticsChartPult,
    prices
}: {
    prices?: PriceHistorySet;
    chartStatistics: ExportStatChart[];
    statisticsChartPult: Partial<ExportStatListPult> | ExportStatChartPult;
}) => {
    const { activeCharts, activeBases } = statisticsChartPult;
    const { isMobile } = commonStore;

    return (
        <ResponsiveContainer>
            <ComposedChart data={chartStatistics} margin={chartMargins}>
                <CartesianGrid strokeDasharray="3 5" />
                <XAxis dataKey="date" />
                <YAxis width={prices ? 40 : 60} mirror={isMobile} />

                {/* Баг в recharts, невозможн овынести этот график от сюда: */}
                {activeCharts.includes('priceHistory') &&
                    prices &&
                    Object.keys(prices).map((date, index) => {
                        const deltaPrice = prices[date];
                        return (
                            <ReferenceLine
                                key={date}
                                x={date}
                                stroke={deltaPrice > 0 ? 'green' : 'red'}
                                strokeWidth={3}
                                /* @ts-ignore */
                                label={`${deltaPrice > 0 ? '+' : ''}${deltaPrice.toLocaleString()}ք`}
                            />
                        );
                    })}

                {!isMobile && <Tooltip content={<StatChartTooltip />} />}

                {activeCharts.includes('views') &&
                    activeBases.map(export_base_id => {
                        const { title, color, name } = EXPORT_BASES_OPTIONS[export_base_id];
                        return (
                            <Line
                                key={export_base_id}
                                name={`${title} число просмотров`}
                                type="monotone"
                                dataKey={`${name}Views`}
                                stroke={color}
                                strokeWidth={2}
                                activeDot={{ r: 6 }}
                                dot={false}
                                animationDuration={500}
                            />
                        );
                    })}

                {activeCharts.includes('phoneShows') &&
                    activeBases.map(export_base_id => {
                        const { title, color, name } = EXPORT_BASES_OPTIONS[export_base_id];
                        return (
                            <Bar key={name} name={`${title} просмотров телефона`} dataKey={`${name}PhoneShows`} fill={color} barSize={20} />
                        );
                    })}

                {activeCharts.includes('showsCount') &&
                    activeBases.map(export_base_id => {
                        const { title, color, name } = EXPORT_BASES_OPTIONS[export_base_id];
                        return (
                            <Area
                                key={name}
                                name={`${title} просмотров в поиске`}
                                dataKey={`${name}ShowsCount`}
                                type="monotone"
                                stroke={color}
                                fill={color}
                            />
                        );
                    })}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default observer(EstateStatisticsChart);
