import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import estateL10n from '~/L10n/estate.L10n';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PriceAgentBonusInfo = ({ estate_id }: { estate_id: number }) => {
    const { item: estate } = estateStore.getItem(estate_id);
    if (!estate || !estate.agentBonusType || !estate.agentBonusValue) return null;

    const { price, agentBonusType, agentBonusValue } = estate;

    return (
        <Box display="flex" justifyContent="space-between">
            <Typography>Ваша комиссия</Typography>
            <Typography>
                {agentBonusType === 2 && (
                    <>
                        {Math.round((agentBonusValue / 100) * price).toLocaleString()}
                        {estateL10n.CURRENCY_TYPES_FIND(1)} ({agentBonusValue}%)
                    </>
                )}
                {agentBonusType === 1 && (
                    <>
                        {agentBonusValue} {estateL10n.CURRENCY_TYPES_FIND(1)}
                    </>
                )}
            </Typography>
        </Box>
    );
};

export default observer(PriceAgentBonusInfo);
