import { action, observable } from 'mobx';
import * as developerApi from '~/api/developerApi';
import ListStorePrototype from '~/stores/prototypes/ListStore.prototype';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import deepCopy from '~/common/deepCopy';
import { Developer } from '~/types/newbuildings.types';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';

class DeveloperStore extends ListStorePrototype<Developer, Developer> {
    constructor() {
        super('developer_id', 'settings', developerApi);
    }

    @action
    async fetchItem(developer_id: number | string, base?: string | null, withoutCache?: boolean) {
        await super.fetchItem(developer_id, base, withoutCache);
        if (developer_id !== CREATING_ITEM_ID) {
            const { item, errors } = this.getItem(developer_id);
            if (item && !errors?.length) {
                const { feedsCount, ...restItem } = deepCopy(item);
                this.setEditingItem(developer_id, restItem);
            }
        } else {
            this.clearEditingItem(CREATING_ITEM_ID);
        }
    }

    @action
    async createItem(): Promise<number> {
        const developer_id = await super.createItem();
        developerFeedStore.setEditingItem(CREATING_ITEM_ID, { developer_id });
        developerFeedStore.listFilter.developer_id = developer_id;
        await developerFeedStore.createItem();
        return developer_id;
    }
}

export default new DeveloperStore();
