import React from 'react';
import { Grid } from 'semantic-ui-react';
import EstateInfoBlock from './ShowBlocks/EstateInfoBlock';
import PriceBlockShow from './ShowBlocks/PriceBlockShow';
import MlsContactsShow from './ShowBlocks/MlsContactsShow';
import PreventEditing from '../../Base/PreventEditing';

import FixPriceBlockShow from './ShowBlocks/FixPriceBlockShow';

const EstateMlsMode = ({ estate_id, isForeign }: { estate_id: number; isForeign: boolean }) => {
    return (
        <PreventEditing>
            <Grid stackable reversed="mobile">
                <Grid.Column width={10}>
                    <EstateInfoBlock estate_id={estate_id} />
                </Grid.Column>
                <Grid.Column width={6}>
                    <PriceBlockShow item_id={estate_id} />
                    {isForeign && <FixPriceBlockShow item_id={estate_id} />}
                    <MlsContactsShow estate_id={estate_id} isForeign={isForeign} />
                </Grid.Column>
            </Grid>
        </PreventEditing>
    );
};

export default EstateMlsMode;
