import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Item, Icon, List } from 'semantic-ui-react';

import ItemPreviewWrapper, { ItemPreviewWrapperProps } from '../Common/ItemPreview';
import PhonesList from '../../Base/PhonesList';
import contactStore from '~/stores/contactStore';
import ContactLink from '../../Lists/Contacts/ContactLink';
import TagsList from './ShowBlocks/SubBlocks/TagsList';
import UsersLinkList from '../../Lists/Users/UsersLinkList';

@observer
class ContactPreview extends Component<ItemPreviewWrapperProps> {
    render() {
        const { item_id, handleClose } = this.props;
        const { item } = contactStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { phones, tags_ids, major_users } = item;

        return (
            <Item.Group className="crm-Item__preview_empty">
                <Item>
                    <Item.Content>
                        <Item.Header className="crm-Estate__fieldNowrap" onClick={handleClose}>
                            <Icon name="address card outline" />
                            <ContactLink contact={item} />
                        </Item.Header>

                        <Item.Description>
                            <TagsList tags_ids={tags_ids} />
                        </Item.Description>

                        <Item.Description>
                            <List>
                                <PhonesList phones={phones} />
                            </List>
                        </Item.Description>

                        <Item.Meta>
                            {!major_users.length && 'Ответственный не назначен'}
                            <UsersLinkList usersList={major_users} avatar />
                        </Item.Meta>
                    </Item.Content>
                </Item>
            </Item.Group>
        );
    }
}

export default ItemPreviewWrapper(contactStore, ContactPreview);
