import { ButtonInSet } from '~/components/Base/ButtonGroup';

type mixed = string | number | boolean;

type DropdownType = {
    key: string | number;
    value: mixed;
    text: string;
};

export const arrayToDropdown = (list: ButtonInSet[]): DropdownType[] =>
    list.filter(item => item[2] !== true).map((item, i) => ({ key: i, value: item[0], text: String(item[1]) }));

export const findValue = (value: mixed, list: ButtonInSet[]): string => {
    const found = list.find(item => item[0] === value);
    return found ? String(found[1] || found[0]) : '';
};

export const proxyL10nHandler = {
    set: function (obj: any, prop: string, list: ButtonInSet[]) {
        obj[prop] = list;
        obj[prop + '_DROPDOWN'] = arrayToDropdown(list);
        obj[prop + '_FIND'] = (key: mixed) => findValue(key, list);

        return true;
    }
};
