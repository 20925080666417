import React, { useState, SyntheticEvent } from 'react';
import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';
import * as userApi from '~/api/userApi';

import MoneyIcon from '@material-ui/icons/Money';
import { PriceInput } from '~ui/TextInput';
import ListError from '~/components/ListError';
import userStore from '~/stores/userStore';

const BalancePaymentModal = ({ onClose, user_id }: { onClose: () => void; user_id: number }) => {
    const [payment, setPayment] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleUserPayment = async () => {
        setLoading(true);
        setErrors([]);
        try {
            await userApi.addBalancePayment(user_id, payment);
            userStore.getUserBalanceInfo(user_id);
            onClose();
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    const actions: ModalBtnAction[] = [
        {
            onClick: onClose,
            label: 'Отмена',
            disabled: loading,
            color: 'primary',
            variant: 'text'
        },
        {
            onClick: handleUserPayment,
            label: 'Зачислить',
            disabled: !payment || loading,
            loading: loading,
            color: 'secondary',
            startIcon: <MoneyIcon />,
            variant: 'outlined'
        }
    ];

    const handlePayment = (event: SyntheticEvent, { value }: { value: number }) => {
        setPayment(value);
    };

    return (
        <Modal
            maxWidth="xs"
            actions={actions}
            onClose={onClose}
            header={
                <>
                    <MoneyIcon />
                    &nbsp;Начисление (списание) на рекламу
                </>
            }
        >
            <ModalContent>
                <ListError errors={errors} />
                <PriceInput label="Сумма зачисления (списания)" name="maxEnableDebt" value={payment} onChange={handlePayment} fullWidth />
            </ModalContent>
        </Modal>
    );
};

export default BalancePaymentModal;
