import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import { TAB_ID_HISTORY } from '../ItemWrapper';
import ExportHistoryBlock from './ExportBlocks/ExportHistoryBlock';
import EstateExportHistoryMode from './ExportBlocks/EstateExportHistoryMode';
import EstateStatisticsMode from './ExportBlocks/EstateStatisticsMode';
import EstateExportSettingsTab from './ExportBlocks/EstateExportSettingsTab';

import RestoreIcon from '@material-ui/icons/Restore';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ListIcon from '@material-ui/icons/List';

import ProgressiveTabs, { ProgressiveTab } from '~/components/Base/ui/ProgressiveTabs';
import { AccessReadWrapper } from '~/components/Base/NotEnoughAccess';

const TAB_BASES = 'bases';
const TAB_TRANSACTIONS = 'transactions';
const TAB_STATISTICS = 'stat';

const EstateExportMode = ({ estate_id }: { estate_id: number }) => {
    useEffect(() => {
        exportStore.fetchItem(estate_id);
        exportStore.getItem(estate_id).loadingItem = true;
    }, []);

    const estate = estateStore.getItem(estate_id).item;
    if (!estate) {
        return null;
    }

    let item, loadingItem;

    try {
        item = exportStore.getItem(estate_id).item;
        loadingItem = exportStore.getItem(estate_id).loadingItem;
    } catch (e) {}

    let isProgress = !item || !exportingBaseStore.exportingBases.length || loadingItem;

    const tabs: ProgressiveTab[] = [
        {
            label: 'Настройка экспорта',
            value: TAB_BASES,
            icon: <ListIcon />,
            tabElement: <EstateExportSettingsTab estate_id={estate_id} isProgress={isProgress} />
        },
        { label: 'Статистика', value: TAB_STATISTICS, icon: <EqualizerIcon />, tabElement: <EstateStatisticsMode estate_id={estate_id} /> },
        { label: 'Расход', value: TAB_TRANSACTIONS, icon: <RestoreIcon />, tabElement: <EstateExportHistoryMode estate_id={estate_id} /> },
        {
            label: 'История изменений',
            value: TAB_ID_HISTORY,
            icon: <AccountBalanceIcon />,
            tabElement: !isProgress ? <ExportHistoryBlock item_id={estate_id} /> : null
        }
    ];

    return <ProgressiveTabs tabs={tabs} defaultValue={TAB_BASES} />;
};

export default AccessReadWrapper(observer(EstateExportMode), exportStore.moduleName);
