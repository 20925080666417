import fetchApi from '~/common/fetchApi';

import { ExportEstateHistory, ExportingEstate, ExportUserHistory } from '~/types/exports.types';
import { objectToGraphql } from '~/common/graphql';
import { GQHistoryChange, GQUserMainFields } from '../userApi';
import { GQEstateMainFields } from '../estateApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { TestReportAnswer } from '~/types/exports.types';
import { ExportBaseIdStat } from '~/types/exportStatistics.types';

export const saveItem = async (estate_id: number, exportingEstate: ExportingEstate): Promise<number> => {
    const graphql = `mutation { 
          applyExportEstate(estate_id: ${estate_id}, exportInput: ${objectToGraphql(exportingEstate)})
        }`;

    const data: { applyExportEstate: number } = await fetchApi.postGQ(graphql);
    return data.applyExportEstate;
};

export const fetchItem = async (id: number): Promise<ExportingEstate> => {
    const graphql = `{
        fetchExportEstate(estate_id: ${id}) {
            estate_id
            totalCost
            bases {
                export_base_id
                price_id
                extra
                bet
                title
                expireTime
            }
            externalStatuses {
                export_base_id
                externalStatus {
                    status
                    externalId
                    message
                    reportTime
                }
            }
            major_user_ids
            major_users {
                ${GQUserMainFields}
            } 
        } 
      }`;

    const data: { fetchExportEstate: ExportingEstate } = await fetchApi.getGQ(graphql);
    return data.fetchExportEstate;
};

export const disableExport = async (estate_id: number): Promise<number> => {
    const graphql = `mutation { 
          disableExporting(estate_id: ${estate_id})
        }`;

    const data: { disableExporting: number } = await fetchApi.postGQ(graphql);
    return data.disableExporting;
};

const TRANSACTION_GQ = `
    export_base_id
    price
    assigned_user_id
    assigned_user {
        ${GQUserMainFields}
    }
    redeemTime
`;

export const fetchEstateExportHistory = async (estate_id: number, limit: number, offset: number): Promise<ExportEstateHistory> => {
    const graphql = `{
        fetchEstateExportHistory(estate_id: ${estate_id}, limit: ${limit}, offset: ${offset}) {
            totalCost
            days {
                dayCost
                time
                transactions {
                    transaction_id
                    extra
                    ${TRANSACTION_GQ}
                }
                calls {
                    call_id
                    ${TRANSACTION_GQ}
                    executeTime
                }
            }
        } 
      }`;

    const data: { fetchEstateExportHistory: ExportEstateHistory } = await fetchApi.getGQ(graphql);
    return data.fetchEstateExportHistory;
};

export const fetchUserExportHistory = async (user_id: number, limit: number, offset: number): Promise<ExportUserHistory> => {
    const dayGQ = `
        cost
        redeemTime
        estate {
            ${GQEstateMainFields}
        }
    `;

    const graphql = `{
        fetchUserExportHistory(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            count
            list {
                dayCost
                time
                dayEstates {
                    ${dayGQ}    
                }
                dayCalls {
                    ${dayGQ} 
                }  
            }
        } 
      }`;

    const data: { fetchUserExportHistory: ExportUserHistory } = await fetchApi.getGQ(graphql);
    return data.fetchUserExportHistory;
};

export const fetchItemHistory = async (estate_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchExportEstateChangeHistory(estate_id: ${estate_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchExportEstateChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchExportEstateChangeHistory;
};

export const changeCallTransactionPrice = async (call_id: number, price: number): Promise<boolean> => {
    const graphql = `mutation { 
          changeCallTransactionPrice(call_id: ${call_id}, price: ${price})
        }`;

    const data: { changeCallTransactionPrice: boolean } = await fetchApi.postGQ(graphql);
    return data.changeCallTransactionPrice;
};

export const redeemTransaction = async (transaction_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          redeemTransaction(transaction_id: ${transaction_id})
        }`;

    const data: { redeemTransaction: boolean } = await fetchApi.postGQ(graphql);
    return data.redeemTransaction;
};

export const makeTestLoadReport = async (
    export_base_id: ExportBaseIdStat,
    configJSON: string,
    apiIndex: number
): Promise<TestReportAnswer> => {
    const graphql = `mutation { 
          makeTestLoadReport(export_base_id: ${export_base_id}, configJSON: ${objectToGraphql(configJSON)}, apiIndex: ${apiIndex}) {
            goodCount   
            errorsCount
            warningsCount
          }
        }`;

    const data = await fetchApi.postGQ<{ makeTestLoadReport: TestReportAnswer }>(graphql);
    return data.makeTestLoadReport;
};
