import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Tabs from '@material-ui/core/Tabs';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import LoaderAwait from '~/components/Base/LoaderAwait';

const LifeComplexApartmentsHousesFilter = ({ newbuilding_id }: { newbuilding_id: number }) => {
    useMemo(() => {
        lifeComplexStore.fetchHousesInfo(newbuilding_id);
    }, [newbuilding_id]);

    const { loadingHousesInfo, housesInfo, apartmentsFilter } = lifeComplexStore.getItem(newbuilding_id).property;
    const { newbuilding_house_id: selected_newbuilding_house_id } = apartmentsFilter;

    const handleSetHouseId = (newbuilding_house_id: number) => {
        lifeComplexStore.changeApartmentsFilter(newbuilding_id, 'newbuilding_house_id', newbuilding_house_id);
    };

    return (
        <Tabs
            value={0}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="desktop"
            TabIndicatorProps={{
                style: {
                    display: 'none'
                }
            }}
            style={{ height: '32px', maxHeight: '32px', minHeight: '32px' }}
        >
            <LoaderAwait active={loadingHousesInfo} dimmer color="secondary" size={24} />
            <Chip
                label="Все корпуса"
                color={selected_newbuilding_house_id === null ? 'primary' : 'default'}
                variant={selected_newbuilding_house_id === null ? 'default' : 'outlined'}
                size="medium"
                // avatar={avatar ? <Avatar>{avatar}</Avatar> : unÏdefined}
                style={{ margin: '0 0.15rem', cursor: 'pointer' }}
                onClick={() => handleSetHouseId(null)}
            />
            {housesInfo.map(({ name, flatsInSaleCount, newbuilding_house_id }) => (
                <Tooltip title="Выбрать корпус" key={newbuilding_house_id}>
                    <Chip
                        label={name}
                        color={selected_newbuilding_house_id === newbuilding_house_id ? 'primary' : 'default'}
                        variant={selected_newbuilding_house_id === newbuilding_house_id ? 'default' : 'outlined'}
                        avatar={<Avatar>{flatsInSaleCount}</Avatar>}
                        style={{ margin: '0 0.5rem', cursor: 'pointer' }}
                        onClick={() => handleSetHouseId(newbuilding_house_id)}
                    />
                </Tooltip>
            ))}
        </Tabs>
    );
};

export default observer(LifeComplexApartmentsHousesFilter);
