import React, { useState } from 'react';
import { observer } from 'mobx-react';
import config from '~/config/ui.config';
import wait from '~/common/wait';

import shareStore from '~/stores/shareStore';
import estateStore from '~/stores/estateStore';

import ShareIcon from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Button from '~ui/Button';
import Grid from '~ui/Grid';
import Modal, { ModalContent } from '~ui/Modal';
import Progress from '~ui/Progress';

import WhatsappDropdown from '~/components/ItemsDropdowns/WhatsappDropdown';
import WhatsappTitleItem from '~/components/ItemsDropdowns/WhatsappDropdown/WhatsappTitleItem';
import { ChatSendBox } from '~/components/Chat/ChatSendArea';

import { WhatsappChatTitle } from '~/types/whatsapp.types';
import whatsappStore from '~/stores/whatsappStore';
import { InfoMessage } from '~ui/Message';
import { makeStyles, Theme } from '@material-ui/core/styles';

const matchShareUrl = async (): Promise<string | null> => {
    const uuid = await shareStore.shareEstateIds();
    if (uuid) {
        return `${config.shareUrl}?${uuid}`;
    }
    return null;
};

const ShareEstatesByWhatsapp = ({ waTitle, onSend }: { waTitle: WhatsappChatTitle; onSend: () => void }) => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');

    const { phone } = waTitle;

    const sendByWhatsapp = async () => {
        setLoading(true);

        const url = await matchShareUrl();
        if (url) {
            let alreadySend = true;
            if (message.trim()) {
                alreadySend = await whatsappStore.sendMessageToPhone(phone, message);
            }
            if (alreadySend) {
                alreadySend = await whatsappStore.sendMessageToPhone(phone, url);
            }
            if (alreadySend) {
                setInfoMessage('Сообщение отправлено');
                await wait(2000);
                return onSend();
            }
        }

        setLoading(false);
    };

    return (
        <>
            <WhatsappTitleItem waTitle={waTitle} />

            {infoMessage && <InfoMessage>{infoMessage}</InfoMessage>}

            {!infoMessage && (
                <>
                    <Progress show={loading} />
                    <ChatSendBox
                        label="Комментарий для получателя"
                        onSend={sendByWhatsapp}
                        onChange={setMessage}
                        message={message}
                        disabled={loading}
                    />
                </>
            )}
        </>
    );
};

const useStyles = makeStyles(({ spacing }) => ({
    waTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        color: '#25D366',
        '& > *': {
            marginRight: spacing(1)
        }
    }
}));

const ShareEstatesModal = ({ onClose }: { onClose: () => void }) => {
    const classes = useStyles();

    const [currentWaTitle, setWaTitle] = useState<WhatsappChatTitle | null>(null);
    const [loading, setLoading] = useState(false);

    const handleShareEstateIds = async () => {
        setLoading(true);

        const url = await matchShareUrl();
        if (url) {
            const win = window.open(url, '_blank');
            win.focus();
        }

        onClose();
    };

    return (
        <Modal header="Поделиться объектами" onClose={onClose} maxWidth="sm">
            <ModalContent>
                <div className={classes.waTitle}>
                    <WhatsAppIcon />
                    <Typography variant="subtitle1">Отправить на Whatsapp</Typography>
                </div>
                <WhatsappDropdown value={currentWaTitle} onChange={setWaTitle} />

                {currentWaTitle && <ShareEstatesByWhatsapp waTitle={currentWaTitle} onSend={() => setWaTitle(null)} />}
            </ModalContent>
            {!currentWaTitle && (
                <ModalContent>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Typography align="center" variant="subtitle1">
                                Ссылка на выборку
                            </Typography>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                loading={loading}
                                onClick={handleShareEstateIds}
                                disableRipple
                                primary
                                endIcon={<OpenInNewIcon />}
                                size="medium"
                                fullWidth
                            >
                                Открыть ссылку
                            </Button>
                        </Grid.Column>
                    </Grid>
                </ModalContent>
            )}
        </Modal>
    );
};

const ShareEstatesButton = ({ short }: { short?: boolean }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const { loadingSharing } = shareStore;
    const { selectedItemsIds } = estateStore;

    return (
        <>
            <Button
                size={short ? 'small' : 'medium'}
                disabled={loadingSharing || selectedItemsIds.length === 0}
                endIcon={<ShareIcon />}
                color="secondary"
                onClick={toggleModal}
            >
                {short ? '' : 'Поделиться '}({selectedItemsIds.length})
            </Button>
            {showModal && <ShareEstatesModal onClose={toggleModal} />}
        </>
    );
};

export default observer(ShareEstatesButton);
