import React from 'react';
import { observer } from 'mobx-react';
import history from '~/history';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import exportStore from '~/stores/export/exportStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import ExportBasesEditing from '../EditBlocks/SubBlocks/ExportBasesEditing';
import estateStore from '~/stores/estateStore';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import { DealLinkState } from '~/components/Lists/Deals/DealLink';
import EstateExportRequestModal from './EstateExportRequestModal';

import Button from '~ui/Button';
import Divider from '~ui/Divider';
import authStore from '~/stores/authStore';

type ExportBlockEditingProps = EditingWrappedProps & {
    type: number;
    propertyType: number;
    toggleEditMode: () => void;
};

const ExportBlockEditing = ({ item_id, toggleEditMode, type, propertyType }: ExportBlockEditingProps) => {
    const handleSave = async (evt: React.SyntheticEvent) => {
        await exportStore.saveItem(item_id);
        toggleEditMode();
    };

    const { exclusiveDealId } = estateStore.getItem(item_id).item || {};

    let item;
    let loadingItem;

    try {
        item = exportStore.getItem(item_id).item;
        loadingItem = exportStore.getItem(item_id).loadingItem;
    } catch (e) {}

    if (!item || !exportingBaseStore.exportingBases) {
        return null;
    }

    const handleDealId = () => {
        history.push(DealLinkState(Number(exclusiveDealId), ESTATE_TYPE_SELL));
    };

    const canPushHugePrice = authStore.currentUser.access.enableApplyingPremium;

    return (
        <Paper style={{ padding: '1em 1em 2.5em' }}>
            {Number(exclusiveDealId) > 0 && (
                <Button tooltip="Открыть договор" onClick={handleDealId} color="secondary" size="small" variant="outlined">
                    эксклюзив
                </Button>
            )}

            <ExportBasesEditing item_id={item_id} type={type} propertyType={propertyType} />

            <Divider />

            <Box display="flex" justifyContent="flex-end" style={{ gap: '1.5rem' }}>
                {!canPushHugePrice && authStore.getModeratorId() && (
                    <EstateExportRequestModal btnTitle="Запрос на включение Премиум-тарифа(ов)" />
                )}

                <Button onClick={toggleEditMode} variant="outlined">
                    Отмена
                </Button>

                <Button
                    disabled={item.major_users.length === 0 || item.major_users.length > 1}
                    endIcon={<CheckIcon />}
                    onClick={handleSave}
                    loading={loadingItem}
                    primary
                    variant="contained"
                >
                    Сохранить
                </Button>
            </Box>
        </Paper>
    );
};

export default observer(ExportBlockEditing);
