import React, { useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import TextInput from '~ui/TextInput';
import DadataInput from '~ui/DadataInput';
import Grid from '~ui/Grid';
import ButtonGroup from '~/components/Base/ButtonGroup';
import DatePicker from '~ui/DatePicker';
import Checkbox from '~ui/Checkbox';
import Select from '~ui/Select';
import Divider from '~ui/Divider';
import Button from '~ui/Button';

import { DaDataFio, DaDataFmsUnit } from '~/types/dadata.types';
import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import mortgageRequestStore from '~/stores/mortgageRequest/index';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

import PassportNumberInput from './SubBlocks/PassportNumberInput';
import InnInputType from './SubBlocks/InnInputType';

import { checkFormValid } from '~/common/forms';
import ListError from '~/components/ListError';

const PassportMortgageEditing = ({ item_id, handleChange, handleDate }: HandlerEditingWrappedProps) => {
    const { editingItem, loadingItem, errors } = mortgagePersonStore.getItem(item_id);

    const {
        person_type,
        surname,
        name,
        patronymic,
        tax_number,
        insurance_number,

        series,
        number,
        birthday,
        issued_at,
        gender_cid,
        birth_place,
        issuer_code,
        issued_by_name,

        registration_address,
        actual_address,
        reason_for_living,
        previous_surname,
        previous_name,
        previous_patronymic
    } = editingItem;

    const [hasChangeName, setHasChangeName] = useState(false);
    const toggleHasChangeName = () => {
        mortgagePersonStore.setEditingItem(
            item_id,
            hasChangeName
                ? { previous_surname: null, previous_name: null, previous_patronymic: null }
                : { previous_surname: surname, previous_name: name, previous_patronymic: patronymic }
        );
        setHasChangeName(!hasChangeName);
    };

    const [sameAddress, setSameAddress] = useState(true);
    const toggleSameAddress = () => {
        mortgagePersonStore.setEditingItem(item_id, { actual_address: null, reason_for_living: null });
        setSameAddress(!sameAddress);
    };

    const changeGender = useCallback(
        ({ gender }: DaDataFio) => {
            switch (gender) {
                case 'MALE':
                    mortgagePersonStore.setEditingItem(item_id, { gender_cid: 'male' });
                    break;
                case 'FEMALE':
                    mortgagePersonStore.setEditingItem(item_id, { gender_cid: 'female' });
                    break;
            }
        },
        [item_id]
    );

    const changeFMS = useCallback(
        ({ code }: DaDataFmsUnit) => {
            mortgagePersonStore.setEditingItem(item_id, { issuer_code: code });
        },
        [item_id]
    );

    const formRef = useRef<HTMLFormElement>(null);

    const handleSave = () => {
        if (checkFormValid(formRef.current, true)) {
            mortgagePersonStore.saveItem(item_id);
        }
    };

    const handleDelete = async () => {
        await mortgagePersonStore.toggleDisableItem(item_id, false);
        const { mortgage_request_id } = mortgagePersonStore.getItem(item_id).item;
        mortgageRequestStore.fetchItem(mortgage_request_id, null, true);
    };

    return (
        <form ref={formRef}>
            <Container maxWidth="sm">
                <ListError errors={errors} />

                <Grid stackable disableGutter>
                    <Grid.Column width={4}>
                        <Typography variant="subtitle1">Роль</Typography>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <ButtonGroup
                            value={person_type}
                            name="person_type"
                            handleChange={handleChange}
                            options={[
                                { value: 'borrower', text: 'Основной заемщик' },
                                { value: 'coborrower', text: 'Созаемщик' },
                                { value: 'guarantor', text: 'Поручитель' }
                            ]}
                        />
                    </Grid.Column>
                </Grid>
                <Grid columns={2} stackable disableGutter>
                    <Grid.Column>
                        <DadataInput
                            type="SURNAME"
                            label="Фамилия"
                            value={surname}
                            name="surname"
                            onDaDataSelect={changeGender}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DadataInput
                            type="NAME"
                            label="Имя"
                            value={name}
                            name="name"
                            onDaDataSelect={changeGender}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DadataInput
                            type="PATRONYMIC"
                            label="Отчество"
                            value={patronymic}
                            name="patronymic"
                            onDaDataSelect={changeGender}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <ButtonGroup
                            buttonSet={[
                                ['male', 'Мужчина'],
                                ['female', 'Женщина']
                            ]}
                            name="gender_cid"
                            value={gender_cid}
                            handleChange={handleChange}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Checkbox
                            checked={!hasChangeName}
                            label={`Не менял${gender_cid === 'female' ? 'а' : ''} фамилию, имя`}
                            onChange={toggleHasChangeName}
                        />
                    </Grid.Column>
                    <Grid.Column>{null}</Grid.Column>
                </Grid>
                {hasChangeName && (
                    <Grid columns={2} stackable disableGutter>
                        <Grid.Column>
                            <TextInput
                                label="Предыдущая фамилия"
                                value={previous_surname}
                                name="previous_surname"
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <TextInput label="Предыдущее имя" value={previous_name} name="previous_name" onChange={handleChange} />
                        </Grid.Column>
                        <Grid.Column>
                            <TextInput
                                label="Предыдущее отчество"
                                value={previous_patronymic}
                                name="previous_patronymic"
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid>
                )}

                <Grid stackable alignItems="flex-start">
                    <Grid.Column width={8}>
                        <TextInput label="Место рождения" name="birth_place" value={birth_place} onChange={handleChange} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <DatePicker
                            label="Дата рождения"
                            variant="standard"
                            openTo="year"
                            value={birthday}
                            name="birthday"
                            onChange={handleDate}
                        />
                    </Grid.Column>
                </Grid>
            </Container>

            <Divider />

            <Container maxWidth="sm">
                <Grid stackable alignItems="flex-start" disableGutter>
                    <Grid.Column width={8}>
                        <PassportNumberInput item_id={item_id} series={series} number={number} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <DatePicker
                            label="Дата выдачи"
                            variant="standard"
                            openTo="year"
                            value={issued_at}
                            name="issued_at"
                            onChange={handleDate}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DadataInput
                            type="FMS_UNIT"
                            label="Наименование подразделения"
                            value={issued_by_name}
                            name="issued_by_name"
                            onChange={handleChange}
                            onDaDataSelect={changeFMS}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <TextInput
                            label="Код подразделения"
                            helperText="000-000"
                            pattern={`^\\d{3}-\\d{3}$`}
                            mask="000-000"
                            value={issuer_code}
                            name="issuer_code"
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid>

                <Grid stackable alignItems="flex-start">
                    <Grid.Column width={12}>
                        <DadataInput
                            type="ADDRESS"
                            label="Адрес регистрации"
                            value={registration_address}
                            name="registration_address"
                            onChange={handleChange}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <Checkbox
                            checked={sameAddress}
                            label="Фактический адрес совпадает с адресом регистрации"
                            onChange={toggleSameAddress}
                        />
                    </Grid.Column>

                    {!sameAddress && (
                        <>
                            <Grid.Column width={12}>
                                <DadataInput
                                    type="ADDRESS"
                                    label="Фактический адрес"
                                    value={actual_address}
                                    name="actual_address"
                                    onChange={handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Select
                                    value={reason_for_living}
                                    name="reason_for_living"
                                    label="Основание проживания"
                                    onChange={handleChange}
                                    options={[
                                        { value: 'rent', text: 'Аренда' },
                                        { value: 'accommodation_with_relatives', text: 'Проживание у родственников' },
                                        { value: 'property', text: 'Собственность' },
                                        { value: 'other', text: 'Другое' }
                                    ]}
                                    fullWidth
                                />
                            </Grid.Column>
                        </>
                    )}

                    <Grid.Column width={6}>
                        <InnInputType
                            item_id={item_id}
                            issued_at={issued_at}
                            birthday={birthday}
                            name={name}
                            number={number}
                            tax_number={tax_number}
                            patronymic={patronymic}
                            series={series}
                            surname={surname}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <TextInput
                            label="Снилс"
                            helperText="11 цифр"
                            pattern={`^[0-9]{11}$`}
                            mask="00000000000"
                            value={insurance_number}
                            name="insurance_number"
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid>

                <div className="crm-Item__addMode_buttons">
                    <Button variant="text" color="secondary" loading={loadingItem} onClick={handleDelete}>
                        Удалить
                    </Button>
                    <Button variant="outlined" color="primary" loading={loadingItem} onClick={handleSave}>
                        Сохранить
                    </Button>
                </div>
            </Container>
        </form>
    );
};

export default HandlerEditingBlockWrapper(mortgagePersonStore, observer(PassportMortgageEditing));
