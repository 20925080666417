import React, { Component, Fragment } from 'react';
import { Grid, Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import taskStore from '~/stores/taskStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import DatePicker from '../../../Base/DatePicker';
import { getUnixTime } from 'date-fns';

type DeadLineBlockEditingProps = HandlerEditingWrappedProps & {
    deadLine?: number;
    needApproval?: boolean;
};

@observer
class DeadLineBlockEditing extends Component<DeadLineBlockEditingProps> {
    constructor(props: DeadLineBlockEditingProps) {
        super(props);

        const item = {
            task_id: props.item_id,
            deadLine: props.deadLine || null,
            needApproval: Boolean(props.needApproval)
        };
        taskStore.setEditingItem(item.task_id, item);
    }

    render() {
        const { item_id, setUnixTime, handleCheckbox } = this.props;
        const { deadLine, needApproval } = taskStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline>
                        <span className="crm-Item__labelWide crm-Tasks__deadLine_label">Крайний срок</span>

                        <div className="crm-Tasks__deadLine_picker">
                            <DatePicker
                                time={deadLine || null}
                                onChange={time => setUnixTime('deadLine', time)}
                                timeIntervals={15}
                                minDate={getUnixTime(new Date())}
                            />
                        </div>

                        <span style={{ width: '180px', position: 'relative', top: '10px' }}>
                            <Checkbox name="needApproval" checked={needApproval} label="с подтверждением" onChange={handleCheckbox} />
                        </span>
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(taskStore, DeadLineBlockEditing);
