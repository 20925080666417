import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';
import estateL10n from '~/L10n/estate.L10n';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';
import LifeComplexAgentBonusEditing from '../EditBlocks/LifeComplexAgentBonusEditing';
import AgentBonus from '~/components/Base/AgentBonus';
import { Newbuilding } from '~/types/newbuildings.types';

const printNumber = (val: number | null | undefined): string => {
    if (!val) return '-';
    if (val < 1000) {
        return Number(val.toFixed(1)).toLocaleString('ru');
    }
    return val.toLocaleString('ru');
};

export const LifeComplexStatAbout = ({ newbuilding }: { newbuilding: Newbuilding }) => {
    const {
        lifeComplexClass,
        buildYear,
        readyQuarter,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        agentBonusType,
        agentBonusValue
    } = newbuilding;

    return (
        <>
            <List disablePadding style={{ paddingTop: '1rem' }}>
                {lifeComplexClass && (
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary={estateL10n.NEWBUILDING_CLASS_FIND(lifeComplexClass)} secondary="Класс жилья" />
                    </ListItem>
                )}
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemText primary={`от ${printNumber(minArea)} до ${printNumber(maxArea)} м²`} secondary="Квартиры" />
                </ListItem>
                <ListItem divider={!buildYear} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemText primary={`от ${printNumber(minPrice)} до ${printNumber(maxPrice)} ₽`} secondary="Цена" />
                </ListItem>
                {buildYear && (
                    <ListItem divider style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText
                            primary={`${buildYear}${readyQuarter && `, ${estateL10n.NEWBUILDING_READY_QUARTER_FIND(readyQuarter)} кв`}`}
                            secondary="Срок сдачи"
                        />
                    </ListItem>
                )}
                {typeof agentBonusValue === 'number' && (
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText
                            primary={
                                <Typography variant="h6">
                                    <AgentBonus agentBonusType={agentBonusType} agentBonusValue={agentBonusValue} />
                                </Typography>
                            }
                            secondary="Комиссия"
                        />
                    </ListItem>
                )}
            </List>
        </>
    );
};

const LifeComplexStatShow = ({ editing, item_id }: EditingWrappedProps) => {
    const newbuilding = lifeComplexStore.getItem(item_id).item;
    if (!newbuilding) {
        return (
            <Paper style={{ padding: '1rem' }}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Paper>
        );
    }

    const { agentBonusType, agentBonusValue } = newbuilding;

    if (editing) {
        return <LifeComplexAgentBonusEditing item_id={item_id} agentBonusType={agentBonusType} agentBonusValue={agentBonusValue} />;
    }

    return (
        <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6">Об объекте</Typography>
            <LifeComplexStatAbout newbuilding={newbuilding} />
        </Paper>
    );
};

export default EditingBlockWrapper(lifeComplexStore, observer(LifeComplexStatShow));
