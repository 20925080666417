import React, { PureComponent, Fragment } from 'react';
import { ComponentType } from 'react';
import NotEnoughAccess from '../Base/NotEnoughAccess';
import Scrollbar from '../Base/Scrollbar';

import './list.less';
import authStore from '~/stores/authStore';
import { ACCESS_READ, ACCESS_SELF } from '~/types/access.types';
import { ListStoreInterface, OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

type ListWrapperState = {
    orderBy: string;
    orderDirection: OrderDirectionType;
    param: string;
};

export type ListProps = {
    match?: {
        params?: {
            param?: string;
        };
    };
};

export type ListWrapperProps = ListWrapperState & {
    handleSort: (str: string) => void;
};

const ListWrapper = (store: ListStoreInterface, WrappedComponent: ComponentType<ListWrapperProps>) => {
    return class extends PureComponent<ListProps, ListWrapperState> {
        constructor(props: ListProps) {
            super(props);

            const { match } = props;
            const param = match && match.params && match.params.param ? match.params.param : '';

            this.state = {
                orderBy: store.orderBy,
                orderDirection: store.orderDirection,
                param
            };

            store.setRouteFilter(param);
            store.fetchList();
        }

        static getDerivedStateFromProps(nextProps: ListProps, prevState: ListWrapperState) {
            const { match } = nextProps;
            const param = match && match.params ? match.params.param : '';

            if (param && param !== prevState.param) {
                store.setRouteFilter(param);
                store.fetchList(prevState.orderBy, prevState.orderDirection);
            }

            return {
                ...prevState,
                param
            };
        }

        handleSort = (clickedColumn: string) => {
            let { orderBy, orderDirection } = this.state;

            if (orderBy !== clickedColumn) {
                orderBy = clickedColumn;
                orderDirection = 'ascending';

                this.setState({
                    orderBy,
                    orderDirection
                });
            } else {
                orderDirection = orderDirection === 'ascending' ? 'descending' : 'ascending';

                this.setState({
                    orderDirection
                });
            }

            store.fetchList(orderBy, orderDirection);
        };

        render() {
            const { orderBy, orderDirection, param } = this.state;

            return authStore.matchAccess(store.moduleName, ACCESS_READ, ACCESS_SELF) ? (
                <Scrollbar disableOnMobile>
                    <WrappedComponent
                        handleSort={this.handleSort}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        param={param}
                        {...this.props}
                    />
                </Scrollbar>
            ) : (
                <NotEnoughAccess />
            );
        }
    };
};

export default ListWrapper;
