import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import estateL10n from '~/L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import EditField from '~/components/Base/EditField';
import ButtonGroup from '~/components/Base/ButtonGroup';

type BuildingPropertyBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    hasFurniture: boolean | null;
    inputType: number | null;
    conditionType: number | null;
    layout: number | null;
    taxNumber: number | null;
    description: string | null;
};

@observer
class BusinessBlockEditing extends Component<BuildingPropertyBlockEditingProps> {
    constructor(props: BuildingPropertyBlockEditingProps) {
        super(props);

        const estate = {
            propertyType: props.propertyType,
            hasFurniture: props.hasFurniture,
            inputType: props.inputType,
            conditionType: props.conditionType,
            layout: props.layout,
            taxNumber: props.taxNumber,
            description: props.description
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, item_id } = this.props;
        const { hasFurniture, inputType, conditionType, layout, taxNumber, description } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Drop
                    label="Состояние"
                    name="conditionType"
                    value={conditionType}
                    onChange={handleChange}
                    options={estateL10n.BUILDING_CONDITION_TYPE_DROPDOWN}
                />

                <EditField.InputNumber
                    label="Номер налоговой"
                    onChange={handleChange}
                    min={1}
                    max={999999}
                    value={taxNumber}
                    name="taxNumber"
                />

                <EditField.Drop
                    label="Вход"
                    name="inputType"
                    value={inputType}
                    onChange={handleChange}
                    options={estateL10n.FREE_PURPOSE_INPUT_TYPE_DROPDOWN}
                />

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Планировка</label>
                        <ButtonGroup buttonSet={estateL10n.LAYOUTS} name="layout" value={layout} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>

                <EditField.ButtonGroup
                    label="Мебель в комнатах"
                    buttonSet={[
                        [false, 'нет'],
                        [true, 'есть']
                    ]}
                    name="hasFurniture"
                    value={hasFurniture}
                    handleChange={handleChange}
                />

                <EditField.Area name="description" label="Описание" value={description} onChange={this.props.handleTextAreaChange} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, BusinessBlockEditing);
