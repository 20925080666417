import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Form, Icon } from 'semantic-ui-react';
import Divider from '~ui/Divider';

import EditField from '../../Base/EditField';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import meetingEventStore from '~/stores/meetingEventStore';
import ButtonGroup from '../../Base/ButtonGroup';
import UserDropdown from '../../ItemsDropdowns/UserDropdown/index';
import HiddenBlock from '../../Base/HiddenBlock';

import EventDatesPickers from '../SubBlocks/EventDatesPickers';
import EventNoty from '../SubBlocks/EventNoty';
import { ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import authStore from '~/stores/authStore';
import ContactIDBlockEditing from '../../Items/Common/ContactIDBlockEditing';
import { getDayOfYear, getStepDayMidday } from '../EventModal';
import DatePicker from '../../Base/DatePicker';
import { CALL_EVENT_ITEM_TYPE_CONTACT, CALL_EVENT_ITEM_TYPE_OWNER } from '~/types/events.types';
import { ESTATE_BASE_OWNERS } from '~/types/estate.types';
import estateStore from '~/stores/estateStore';
import { CALL_EVENT_ITEM_TYPE } from '~/types/events.types';
import LoaderAwait from '../../Base/LoaderAwait';
import EstateLinkWithPreview from '../../Lists/Estate/EstateLinkWithPreview';

type MeetingEventBlockProps = HandlerEditingWrappedProps & {
    item_id: number;
    onClose: () => void;
    contact_id: number | null;
    owner_id: number | null;
    startTime?: number;
    endTime?: number;
};

type MeetingEventBlockState = {
    showTimeDetails: boolean;
    groups_id: Array<number>;
    dateSetValue: number;
    item_type: CALL_EVENT_ITEM_TYPE;
};

const DEFAULT_CALL_TIME_MAX_DELTA = 1800;
const DEFAULT_DATE_SET_VALUE = 1;

@observer
class MeetingEventBlock extends Component<MeetingEventBlockProps, MeetingEventBlockState> {
    constructor(props: MeetingEventBlockProps) {
        super(props);

        this.state = {
            showTimeDetails: false,
            groups_id:
                authStore.getPermission(meetingEventStore.moduleName, ACCESS_WRITE) === ACCESS_GROUP
                    ? [authStore.currentUser.group_id]
                    : [],
            dateSetValue: DEFAULT_DATE_SET_VALUE,
            item_type: props.owner_id ? CALL_EVENT_ITEM_TYPE_OWNER : CALL_EVENT_ITEM_TYPE_CONTACT
        };

        if (props.owner_id && this.state.item_type === CALL_EVENT_ITEM_TYPE_OWNER) {
            estateStore.fetchItem(props.owner_id, ESTATE_BASE_OWNERS);
        }
    }

    handleToggleTimeDetail = () => {
        this.setState({ showTimeDetails: !this.state.showTimeDetails });
    };

    handleAssignUsersChange = (value: number | Array<number>) => {
        if (value instanceof Array) {
            meetingEventStore.setEditingItem(this.props.item_id, {
                assign_users_ids: value
            });
        }
    };

    handleDateSetChange = (event: React.SyntheticEvent, { value }: { value: number }) => {
        this.setState({ dateSetValue: value });

        const startTime = getStepDayMidday(value);
        meetingEventStore.setEditingItem(this.props.item_id, {
            startTime,
            endTime: startTime + DEFAULT_CALL_TIME_MAX_DELTA
        });
    };

    handleStartTimeChange = (time: number) => {
        this.props.setUnixTime('startTime', time);
        this.props.setUnixTime('endTime', time + DEFAULT_CALL_TIME_MAX_DELTA);
    };

    static getDerivedStateFromProps(props: MeetingEventBlockProps, state: MeetingEventBlockState) {
        const editingItem = meetingEventStore.getItem(props.item_id).editingItem;

        if (editingItem.item_id && editingItem.item_type === CALL_EVENT_ITEM_TYPE_OWNER && state.item_type !== CALL_EVENT_ITEM_TYPE_OWNER) {
            estateStore.fetchItem(editingItem.item_id, ESTATE_BASE_OWNERS);
            state.item_type = CALL_EVENT_ITEM_TYPE_OWNER;
        }

        const { startTime } = editingItem || {};

        if (startTime) {
            return {
                ...state,
                dateSetValue: getDayOfYear(startTime * 1000) - getDayOfYear(Date.now())
            };
        }
        return state;
    }

    render() {
        const { item_id: meeting_event_id, handleChange, handleTextAreaChange, setUnixTime } = this.props;
        const { showTimeDetails, groups_id, dateSetValue } = this.state;
        const { editingItem, loadingItem } = meetingEventStore.getItem(meeting_event_id);

        if (!Object.keys(editingItem).length) {
            return null;
        }

        const { startTime, endTime, goal, description, assign_users_ids, item_id, item_type, notificationEnable, notificationTime } =
            editingItem;

        let estate;
        let loadingEstate;

        if (this.state.item_type === CALL_EVENT_ITEM_TYPE_OWNER && item_id) {
            estate = estateStore.getItem(item_id).item;
            loadingEstate = estateStore.getItem(item_id).loadingItem;
        }

        return (
            <Fragment>
                <Grid stackable verticalAlign="middle">
                    <Grid.Column width={2}>
                        <label className="crm-Item__labelWide">С кем</label>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        {item_type === CALL_EVENT_ITEM_TYPE_CONTACT && (
                            <ContactIDBlockEditing
                                store={meetingEventStore}
                                editing={item_id === null}
                                item_id={meeting_event_id}
                                value={item_id}
                                contactKey="item_id"
                            />
                        )}
                        {item_type === CALL_EVENT_ITEM_TYPE_OWNER && estate && (
                            <Fragment>
                                <Icon tilte="Собственник" name="male" color="brown" />
                                <EstateLinkWithPreview estate={estate} base={ESTATE_BASE_OWNERS} />
                            </Fragment>
                        )}
                        <LoaderAwait active={item_type === CALL_EVENT_ITEM_TYPE_OWNER && loadingEstate} size="mini" />
                    </Grid.Column>
                </Grid>

                <Divider />

                <Grid stackable>
                    <Grid.Column width={10}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Сегодня'],
                                [1, 'Завтра'],
                                [2, 'Послезавтра'],
                                [3, 'Через 3 дня']
                            ]}
                            value={dateSetValue}
                            name="dateSetValue"
                            handleChange={this.handleDateSetChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {!loadingItem && (
                            <EventNoty
                                store={meetingEventStore}
                                item_id={meeting_event_id}
                                startTime={startTime}
                                notificationEnable={notificationEnable}
                                notificationTime={notificationTime}
                            />
                        )}
                    </Grid.Column>
                </Grid>
                <br />

                {!showTimeDetails && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">Когда</label>

                            <div className="crm-Estate__reportTime_picker">
                                <DatePicker time={startTime} onChange={this.handleStartTimeChange} timeIntervals={15} />
                            </div>
                        </Form.Field>
                    </Form.Group>
                )}

                <HiddenBlock labelOpen="детально" labelHide="кратко" onToggle={this.handleToggleTimeDetail}>
                    <EventDatesPickers
                        store={meetingEventStore}
                        item_id={meeting_event_id}
                        startTime={startTime}
                        endTime={endTime}
                        setUnixTime={setUnixTime}
                    />
                </HiddenBlock>

                <Divider />

                <EditField.Text label="Цель встречи" value={goal} name="goal" onChange={handleChange} fluid />

                <HiddenBlock label="дополнительно" defaultOpen={Boolean(description)}>
                    <Fragment>
                        <EditField.Area label="Описание" onChange={handleTextAreaChange} value={description} name="description" />
                        <Form.Group>
                            <Form.Field inline size="mini" className="crm-Tasks__assignedUsers">
                                <label className="crm-Estate__field_label">Ответственный(е)</label>
                                <div className="ui tiny form crm-Tasks__assignedUsers_dropdown">
                                    <UserDropdown
                                        user_id={assign_users_ids}
                                        groups_id={groups_id}
                                        onChange={this.handleAssignUsersChange}
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                    </Fragment>
                </HiddenBlock>
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(meetingEventStore, MeetingEventBlock);
