import { AvitoXLPrices } from '~/types/exports.types';
import fetchApi from '~/common/fetchApi';

export type AvitoPackageApply = {
    estate_id: number;
    user_id: number;
    package_id: string;
    price: number;

    expireTime: number;
    createTime: number;
};

const avitoPackagesMemo = new Map<number, AvitoPackageApply | null>();

export const fetchAvitoXLPrices = async (estate_id: number): Promise<AvitoXLPrices> => {
    const graphql = `{
        fetchAvitoXLPrice(estate_id: ${estate_id}) {
            x10_1
            x10_7
            x2_1
            x2_7
            x5_1
            x5_7   
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchAvitoXLPrice: AvitoXLPrices }>(graphql);
    return data.fetchAvitoXLPrice;
};

export const applyAvitoXLPrice = async (estate_id: number, package_id: keyof AvitoXLPrices): Promise<number> => {
    avitoPackagesMemo.delete(estate_id);

    const graphql = `mutation { 
          applyAvitoXLPrice(estate_id: ${estate_id}, package_id: "${package_id}")
        }`;

    const data = await fetchApi.postGQ<{ applyAvitoXLPrice: number }>(graphql);
    return data.applyAvitoXLPrice;
};

export const checkAvitoEnabledPackage = async (estate_id: number): Promise<AvitoPackageApply> => {
    if (avitoPackagesMemo.has(estate_id)) {
        return avitoPackagesMemo.get(estate_id);
    }

    const graphql = `{
        checkAvitoEnabledPackage(estate_id: ${estate_id}) {
            estate_id
            user_id
            package_id
            price
        
            expireTime
            createTime  
        } 
      }`;

    const data = await fetchApi.getGQ<{ checkAvitoEnabledPackage: AvitoPackageApply }>(graphql);
    avitoPackagesMemo.set(estate_id, data.checkAvitoEnabledPackage);
    return data.checkAvitoEnabledPackage;
};
