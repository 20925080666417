import React, { Fragment } from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import contactStore from '~/stores/contactStore';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

const ContactsTableHeader = ({
    handleSort,
    orderDirection,
    orderBy,
    selectedAll,
    enableCheckbox,
    showEstates
}: {
    handleSort: (str: string) => void;
    orderDirection: OrderDirectionType;
    orderBy: string;
    selectedAll: boolean;
    enableCheckbox: boolean;
    showEstates: boolean;
}) => (
    <Table.Header>
        <Table.Row>
            {enableCheckbox && (
                <Table.HeaderCell title="Выбрать все" align="center">
                    <Checkbox onClick={contactStore.toggleAllItems.bind(contactStore)} checked={selectedAll} />
                </Table.HeaderCell>
            )}
            <Table.HeaderCell sorted={orderBy === 'lastName' ? orderDirection : null} onClick={() => handleSort('lastName')}>
                Имя
            </Table.HeaderCell>
            {showEstates && <Table.HeaderCell>Объект(ы)</Table.HeaderCell>}
            <Table.HeaderCell>Сотрудник</Table.HeaderCell>
            <Table.HeaderCell>Тэги</Table.HeaderCell>
            <Table.HeaderCell sorted={orderBy === 'lastCommentTime' ? orderDirection : null} onClick={() => handleSort('lastCommentTime')}>
                Комментарий
            </Table.HeaderCell>
            <Table.HeaderCell sorted={orderBy === 'lastCallTime' ? orderDirection : null} onClick={() => handleSort('lastCallTime')}>
                Контакты
            </Table.HeaderCell>
        </Table.Row>
    </Table.Header>
);

export default ContactsTableHeader;
