import React from 'react';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';

import exportStatisticsStore from '~/stores/export/exportStatisticsStore';
import Progress from '../../Base/ui/Progress';
import ListError from '../../ListError';
import CardWithStatPie from '../../Items/Estate/SubBlocks/StatisticsMode/CardWithStatPie';
import EstateStatisticsChart from '../../Items/Estate/SubBlocks/StatisticsMode/EstateStatisticsChart';
import EstateStatisticsTable from '../../Items/Estate/SubBlocks/StatisticsMode/EstateStatisticsTable';
import { WarningMessage } from '~ui/Message';

const ExportStatisticsShow = () => {
    const { chartStatistics, sumStatisticsMatched, listErrors, loadingList, listFilter } = exportStatisticsStore;

    if (listErrors.length) {
        return <ListError errors={listErrors} />;
    }

    if (loadingList || !sumStatisticsMatched) {
        return <Progress show />;
    }

    const isStatChartEnable = Boolean(chartStatistics && chartStatistics.length > 1);
    const { views, phoneShows } = sumStatisticsMatched;

    const deltaDays = chartStatistics.length;
    const timeDuration = `За ${deltaDays} ${deltaDays === 1 ? 'день' : deltaDays < 5 ? 'дня' : 'дней'}`;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {isStatChartEnable && (
                    <div style={{ height: '400px' }}>
                        <EstateStatisticsChart chartStatistics={chartStatistics} statisticsChartPult={listFilter} />
                    </div>
                )}
                {!isStatChartEnable && <WarningMessage>Статистика пока недоступна</WarningMessage>}
            </Grid>

            <Grid item xs={12}>
                <EstateStatisticsTable sumStatisticsMatched={sumStatisticsMatched} showEstatesCount />
            </Grid>

            <Grid item xs={12} sm={6}>
                <CardWithStatPie data={views} title="Просмотры" subTitle={timeDuration} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <CardWithStatPie data={phoneShows} title="Показы телефона" subTitle={timeDuration} />
            </Grid>
        </Grid>
    );
};

export default observer(ExportStatisticsShow);
