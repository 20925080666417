import { observable, action, computed } from 'mobx';
import * as notificationsApi from '~/api/notificationsApi';
import serverConfig from '../config/server.config';

import { Notification } from '~/types/notifications.types';
import authStore from './authStore';
import wait from '../common/wait';

const NOTIFICATIONS_UPDATE_TIME = 10000;
const ERROR_THE_PASSWORD_EXPIRED = 'Пароль устарел';
const ERROR_THERE_ARE_NO_CONNECTION = 'Failed to fetch';

const ARCHIVE_NOTIES_IN_PAGE = 50;

class NotificationStore {
    @observable
    notificationsList: Notification[] = [];
    @observable
    lastUpdate = 0;
    intervalId: number;
    fetchIntervalId: number;
    notifyPermission: string;

    constructor() {
        document.title = notificationsApi.INITAL_DOC_TITLE;
        authStore.registerInitFunc(this.init.bind(this));
        authStore.registerCloseFunc(this.stop.bind(this));
    }

    async init() {
        this.fetchNotiesList();

        // if (
        //     !document.location.host.includes('localhost') &&
        //     serverConfig.pushNotifications &&
        //     serverConfig.pushNotifications.operator === 'onesignal'
        // ) {
        //     // @ts-ignore
        //     const OneSignal = window.OneSignal || [];
        //     OneSignal.push(function () {
        //         OneSignal.init({
        //             appId: serverConfig.pushNotifications.appId
        //         });
        //         OneSignal.showNativePrompt();
        //         OneSignal.sendTags({
        //             user_id: authStore.currentUser.user_id
        //         });
        //     });
        // } else {
        //     try {
        //         this.notifyPermission = await window.Notification.requestPermission();
        //     } catch (e) {}
        // }

        this.fetchIntervalId = window.setInterval(this.fetchNotiesList, NOTIFICATIONS_UPDATE_TIME);
        this.toggleNoty();
    }

    async stop() {
        clearInterval(this.fetchIntervalId);

        // @ts-ignore
        // const OneSignal = window.OneSignal || {};
        // try {
        //     await Promise.race([Promise.all([OneSignal.deleteTag('user_id'), OneSignal.setSubscription(false)]), wait(1500)]);
        // } catch (e) {
        //     console.log(e);
        // }
    }

    // Проверяем, есть ли сообщения, не прочитанные более 7 дней
    @computed get isTooLongNotReadMessages(): boolean {
        return Math.min(...this.notificationsList.map(({ createTime }) => createTime)) + 7 * 24 * 3600 < Date.now() / 1000;
    }

    @observable
    thereAreNoInternetConnection: boolean = false;

    @action
    fetchNotiesList = async () => {
        try {
            const list = await notificationsApi.fetchNotifications();
            const lastUpdate = Math.max(...list.map(({ updateTime }) => updateTime));

            if (this.lastUpdate !== lastUpdate) {
                this.notificationsList = list;
                this.lastUpdate = lastUpdate;
            }

            this.thereAreNoInternetConnection = false;
            this.toggleNoty();
        } catch (errors) {
            if (errors?.message === 'No token provided.' || errors?.some(error => error === ERROR_THE_PASSWORD_EXPIRED)) {
                authStore.logout();
            } else if (errors?.some(error => error === ERROR_THERE_ARE_NO_CONNECTION)) {
                this.thereAreNoInternetConnection = true;
            }
        }
    };

    @observable
    notificationArchive: Notification[] = [];
    @observable
    hasMoreInArchive = true;

    @action
    fetchNotiesArchivePage = async (page: number) => {
        try {
            if (page === 0) {
                this.notificationArchive = [];
                this.hasMoreInArchive = true;
            }
            const archive = await notificationsApi.fetchNotifications(true, page * ARCHIVE_NOTIES_IN_PAGE, ARCHIVE_NOTIES_IN_PAGE);
            if (archive.length < ARCHIVE_NOTIES_IN_PAGE) {
                this.hasMoreInArchive = false;
            }
            this.notificationArchive = [...this.notificationArchive, ...archive];
        } catch (errors) {
            console.log(errors);
        }
    };

    @action
    readNotification = async (noty_id: number) => {
        const noty = this.notificationsList.find(noty => noty.noty_id === noty_id);
        if (noty) {
            notificationsApi.readNotification(noty_id);
            noty.enable = false;

            this.toggleNoty();
        }
    };

    @action
    readAllNotifications = async () => {
        notificationsApi.readAllNotifications();
        this.notificationsList = [];
        this.toggleNoty();
    };

    async toggleNoty() {
        if (this.notificationsList.length > 0) {
            this.handleNotifyInterval();
        } else {
            clearInterval(this.intervalId);

            window.setTimeout(() => {
                document.title = notificationsApi.INITAL_DOC_TITLE;
            }, 0);
            window.setTimeout(() => {
                document.title = notificationsApi.INITAL_DOC_TITLE;
            }, 100);
        }
    }

    handleNotifyInterval() {
        clearInterval(this.intervalId);

        this.intervalId = window.setInterval(() => {
            notificationsApi.blinkTitle();
        }, 1000);
    }
}

export default new NotificationStore();
