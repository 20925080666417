import React, { PureComponent, Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid, Modal, Button, Divider } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';

import exportStore from '~/stores/export/exportStore';
import estateStore from '~/stores/estateStore';
import { ESTATE_PROPERTY_TYPE_OFFICE } from '~/types/estate.types';
import PropertyTypeEditing from '../EditBlocks/SubBlocks/PropertyTypeEditing';
import ButtonGroup from '../../../Base/ButtonGroup';
import { HandleChangeType } from '../../HandlerEditingBlockWrapper';
import { WarningMessage } from '~ui/Message';

type EstatePropertyTypePopupProps = {
    item_id: number;
};

type EstatePropertyTypePopupState = {
    propertyTypeChanging: boolean;
    modalIsOpen: boolean;
};

@observer
class EstatePropertyTypePopup extends Component<EstatePropertyTypePopupProps, EstatePropertyTypePopupState> {
    state = {
        propertyTypeChanging: false,
        modalIsOpen: false
    };

    handleSavePropertyType = async (estate_id: number) => {
        this.setState({ propertyTypeChanging: true });

        await estateStore.saveItem(estate_id);
        await exportStore.disableExport(estate_id);

        this.setState({ propertyTypeChanging: false, modalIsOpen: false });
    };

    handleChangeType: HandleChangeType = (e, { value }) => {
        e.stopPropagation();
        estateStore.setEditingItem(this.props.item_id, { type: value });
    };

    onOpenPopup = () => {
        const { propertyType, type } = estateStore.getItem(this.props.item_id).item || {};

        estateStore.setEditingItem(this.props.item_id, {
            propertyType,
            type
        });
    };

    toggleOpenModal = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen });
    };

    render() {
        const { propertyTypeChanging, modalIsOpen } = this.state;
        const { item_id } = this.props;
        const { propertyType, type } = estateStore.getItem(this.props.item_id).editingItem;

        return (
            <Modal
                trigger={<MenuItem onClick={this.toggleOpenModal}>Поменять тип объекта</MenuItem>}
                open={modalIsOpen}
                closeIcon
                onOpen={this.onOpenPopup}
                onClose={this.toggleOpenModal}
            >
                <Modal.Content>
                    <Grid columns={1}>
                        <Grid.Column>
                            <WarningMessage>Внимание! После изменения типа экспорт будет выключен</WarningMessage>
                            <ButtonGroup
                                size="medium"
                                buttonSet={[
                                    [1, 'Продажа'],
                                    [2, 'Аренда']
                                ]}
                                name="type"
                                value={type}
                                handleChange={this.handleChangeType}
                            />
                            <Divider />
                            <PropertyTypeEditing
                                item_id={item_id}
                                propertyType={propertyType}
                                estateType={propertyType < ESTATE_PROPERTY_TYPE_OFFICE ? 1 : 2}
                                type={type}
                                strictTypes
                            />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        size="tiny"
                        disabled={propertyTypeChanging}
                        loading={propertyTypeChanging}
                        primary
                        onClick={this.handleSavePropertyType.bind(this, item_id)}
                    >
                        Поменять
                    </Button>
                    <Button size="tiny" disabled={propertyTypeChanging} loading={propertyTypeChanging} onClick={this.toggleOpenModal}>
                        Отменить
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EstatePropertyTypePopup;
