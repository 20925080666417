import { observable, action, computed, reaction } from 'mobx';

import { MortgageJob, MortgagePerson, MortgageRequest } from '~/types/mortgageRequest.types';
import * as mortgageJobApi from '~/api/mortgageRequest/mortgageJobApi';
import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';
import ItemEditingPrototype from '~/stores/prototypes/ItemEditing.prototype';

class MortgageJobStore extends ItemEditingPrototype<MortgageJob, MortgagePerson> {
    constructor() {
        super('contact_id', 'contact', mortgageJobApi);
    }

    @action
    async createJob(mortgage_person_id: number): Promise<void> {
        const mortgage_job_id = await super.createEmptyItem({ mortgage_person_id });

        const { main_job_id, jobs_ids } = mortgagePersonStore.getItem(mortgage_person_id).editingItem;
        if (!main_job_id) {
            mortgagePersonStore.setEditingItem(mortgage_person_id, { main_job_id: mortgage_job_id });
            mortgagePersonStore.saveItem(mortgage_person_id);
        }
        mortgagePersonStore.setEditingItem(mortgage_person_id, { jobs_ids: [...jobs_ids, mortgage_job_id] });
    }

    getParent(id: number | string): Partial<MortgageRequest> {
        const { mortgage_person_id } = this.getItem(id).editingItem;
        return mortgagePersonStore.getItem(mortgage_person_id).editingItem;
    }
}

export default new MortgageJobStore();
