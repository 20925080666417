import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { TestReportAnswer } from '~/types/exports.types';
import { SuccessMessage } from '~ui/Message';

const TestReportAnswerBlock = ({ answer }: { answer: TestReportAnswer }) => {
    return (
        <>
            <SuccessMessage header="Отчет успешно загружен">Теперь все отчеты будут грузиться регулярно автоматически</SuccessMessage>
            <List dense disablePadding>
                <ListItem>
                    <ListItemText primary="Опубликованных успешно" secondary={answer.goodCount} />

                    <ListItemText primary="С предупреждением" secondary={answer.warningsCount} />

                    <ListItemText primary="Не вышли. Ошибка" secondary={answer.errorsCount} />
                </ListItem>
            </List>
        </>
    );
};

export default TestReportAnswerBlock;
