import { EstateAddressStrict } from '~/types/estate.types';

export const emptyAddressObject = {
    address: '',

    street: '',
    streetType: '',
    houseNumber: '',
    houseNumberType: '',
    blockNumber: '',
    blockNumberType: '',

    town: '',
    townType: '',
    region: '',
    regionType: '',
    cityDistrict: '',
    district: '',
    cityDistrictType: '',
    settlement: '',
    settlementType: '',
    districtType: '',
    kladr: '0',
    fias: '',

    postcode: 0,
    geo_lat: 0,
    geo_lon: 0
};

export const adjustHouseNumber = (estate: Partial<EstateAddressStrict>): string => {
    const { blockNumber, blockNumberType, houseNumberType, houseNumber } = estate;

    let address = '';

    if (houseNumber) {
        switch (houseNumberType) {
            case 'дом':
                address = `д.${houseNumber}`;
                break;
            case 'владение':
                address = `вл.${houseNumber}`;
                break;
            case 'строение':
                address = `стр.${houseNumber}`;
                break;
            case 'корпус':
                address = `к.${houseNumber}`;
                break;
            default:
                address = `${houseNumberType ? ` ${houseNumberType}` : ''} ${houseNumber}`.trim();
                break;
        }
    }

    if (blockNumber) {
        switch (blockNumberType) {
            case 'строение':
                address += ` стр.${blockNumber}`;
                break;
            case 'корпус':
                address += ` к.${blockNumber}`;
                break;
            default:
                address += `${blockNumberType || ''} ${blockNumber}`.trim();
                break;
        }
    }

    return address;
};

export const adjustEstateAddressShort = (estate: Partial<EstateAddressStrict>): string => {
    const { region, town, street, streetType, settlementType, settlement } = estate;

    const regionAddress = `${region === 'Москва' && region !== town ? region : ''}`.trim();

    let secondAddress = '';

    if (street) {
        switch (streetType) {
            case 'улица':
                secondAddress = `ул. ${street}`;
                break;
            case 'проспект':
                secondAddress = `${street} пр-т`;
                break;
            case 'переулок':
                secondAddress = `пер. ${street}`;
                break;
            case 'шоссе':
                secondAddress = `${street} ш.`;
                break;
            case 'набережная':
                secondAddress = `${street} наб.`;
                break;
            case 'бульвар':
                secondAddress = `${street} бул.`;
                break;
            case 'площадь':
                secondAddress = `пл. ${street}`;
                break;
            case 'тупик':
                secondAddress = `${street} туп.`;
                break;
            default:
                secondAddress = `${streetType || ''} ${street}`.trim();
                break;
        }
    } else {
        secondAddress = `${estate.districtType || estate.cityDistrictType || ''} ${estate.district || estate.cityDistrict || ''}`.trim();
    }

    let townAddress = `${town || ''}`.trim();

    if (settlement) {
        let settlementTypeShort = '';
        switch (settlementType) {
            case 'поселок':
                settlementTypeShort = 'пос';
                break;
            case 'дачный поселок':
                settlementTypeShort = 'дач.пос';
                break;
            case 'рабочий поселок':
                settlementTypeShort = 'раб.пос';
                break;
            case 'микрорайон':
                settlementTypeShort = 'мкрн';
                break;
            case 'село':
                settlementTypeShort = 'село';
                break;
            case 'деревня':
                settlementTypeShort = 'дер';
                break;
            case 'территория':
                settlementTypeShort = 'тер';
                break;
            case 'садовое товарищество':
                settlementTypeShort = 'сад-тов';
                break;
            case 'территория днт':
                settlementTypeShort = 'тер.днт';
                break;
            case 'квартал':
                settlementTypeShort = 'квл';
                break;
            default:
                settlementTypeShort = settlementType || '';
                break;
        }
        townAddress += `${townAddress ? ', ' : ''}${settlementTypeShort} ${settlement}`.trim();
    }

    let thirdAddress = adjustHouseNumber(estate);

    const lastAddress = [secondAddress || '', thirdAddress || '']
        .filter(val => Boolean(val))
        .join(', ')
        .trim();

    return `${regionAddress ? `${regionAddress}, ` : ''}${townAddress}${lastAddress ? `, ${lastAddress}` : ''}`.trim();
};

export const adjustEstateAddressLong = (estate: Partial<EstateAddressStrict>): string => {
    const {
        region,
        regionType,
        town,
        townType,
        street,
        districtType,
        cityDistrictType,
        district,
        cityDistrict,
        streetType,
        blockNumber,
        blockNumberType,
        houseNumberType,
        houseNumber,
        settlementType,
        settlement
    } = estate;

    const regionArr = [regionType || '', region || ''];

    let address = (regionType === 'город' ? regionArr : regionArr.reverse()).join(' ').trim();

    address = [
        [townType, town],
        [districtType, district],
        [settlementType, settlement],
        [cityDistrict, cityDistrictType],
        [streetType, street],
        [houseNumberType, houseNumber],
        [blockNumberType, blockNumber]
    ].reduce((address, [propType, propAddress]) => {
        const nextAddressDetail = propAddress !== 'Москва' ? `${propType || ''} ${propAddress || ''}`.trim() : '';
        if (nextAddressDetail) {
            address += `${nextAddressDetail ? ' ,' : ''} ${nextAddressDetail}`.trim();
        }
        return address;
    }, address);

    return address;
};

export const adjustEstateAddressStreet = (estate: Partial<EstateAddressStrict>): string => {
    const { street, streetType, blockNumber, blockNumberType, houseNumberType, houseNumber } = estate;

    let address = `${street || ''} ${streetType || ''}`.trim();

    address = [
        [houseNumberType, houseNumber],
        [blockNumberType, blockNumber]
    ].reduce((address, [propType, propAddress]) => {
        const nextAddressDetail = `${propType || ''} ${propAddress || ''}`.trim();
        if (nextAddressDetail) {
            address += `${address && nextAddressDetail ? ' ,' : ''} ${nextAddressDetail}`.trim();
        }
        return address;
    }, address);

    return address;
};
