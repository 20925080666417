import React, { useState } from 'react';
import { observer } from 'mobx-react';

import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Badge from '@material-ui/core/Badge';

import LCFeedNewbuildingModal from './LCFeedNewbuildingModal';
import { LifeComplexDomclickInfoBuilding } from '~/types/newbuildings.types';

const whetherBuildingsCompleted = (buildings: LifeComplexDomclickInfoBuilding[]): boolean =>
    buildings.every(building => building.newbuilding_house_id);

const LifeComplexFeedNewbuildings = ({ developer_feed_id }: { developer_feed_id: number }) => {
    const {
        developerFeedInfo: { lifeComplexes }
    } = developerFeedStore.getItem(developer_feed_id).property;

    const [domclick_lc_id, setBuildingId] = useState<number | null>(null);
    const closeModal = () => {
        setBuildingId(null);
    };

    return (
        <>
            <List>
                {lifeComplexes.map(({ domclick_lc_id, title, address, newbuilding_id, buildings }) => (
                    <ListItem key={domclick_lc_id} button onClick={() => setBuildingId(domclick_lc_id)}>
                        <ListItemText
                            primary={title}
                            secondary={address}
                            secondaryTypographyProps={{ color: newbuilding_id ? 'primary' : 'error' }}
                        />
                        <ListItemSecondaryAction style={{ fontWeight: 'bold' }}>
                            <Badge badgeContent={buildings.length} color={whetherBuildingsCompleted(buildings) ? 'primary' : 'error'}>
                                <ApartmentIcon />
                            </Badge>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            {typeof domclick_lc_id === 'number' && (
                <LCFeedNewbuildingModal onClose={closeModal} developer_feed_id={developer_feed_id} domclick_lc_id={domclick_lc_id} />
            )}
        </>
    );
};

export default observer(LifeComplexFeedNewbuildings);
