import React, { SyntheticEvent, useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import Modal, { ModalContent, ModalActions } from '~ui/Modal';
import Button from '~ui/Button';
import { WarningMessage } from '~ui/Message';
import ButtonGroup from '~/components/Base/ButtonGroup';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MY, ESTATE_BASES } from '~/types/estate.types';
import ItemDropdown from '~/components/Items/Common/ItemDropdow';
import estateStore from '~/stores/estateStore';
import cellCallStore from '~/stores/cell/cellCallStore';
import ListError from '~/components/ListError';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import commonStore from '~/stores/commonStore';

const ChangeCallEstateModal = ({ call_id, onClose }: { call_id: number; onClose: () => void }) => {
    const [base, setBase] = useState<ESTATE_BASES>(ESTATE_BASE_MY);
    const [estate_id, setEstateId] = useState<number | null>(null);

    useMemo(() => {
        estateStore.fetchItemDropdownBase = ESTATE_BASE_MY;
        cellCallStore.fetchItem(call_id).then(() => {
            const { estate_id } = cellCallStore.getItem(call_id).item;
            setEstateId(estate_id);
        });
    }, []);

    const handleChangeBase = (event: SyntheticEvent, { value: base }: { value: ESTATE_BASES }) => {
        estateStore.fetchItemDropdownBase = base;
        setBase(base);
        estateStore.debounceItemDropdownOptions();
    };

    const handleEstateChange: HandleChangeType = (event, { value: estate_id }: { value: number }) => {
        if (estate_id) {
            estateStore.fetchItem(estate_id);
        }
        setEstateId(estate_id);
    };

    const handleSave = async () => {
        if (await cellCallStore.changeCallEstateId(call_id, estate_id)) {
            onClose();
        }
    };

    const { loadingItem, errors } = cellCallStore.getItem(call_id);

    return (
        <Modal maxWidth="sm" onClose={onClose} header="Поменять объект, на который был звонок" fullScreen={commonStore.isMobile}>
            <ModalContent style={{ minHeight: '450px' }}>
                <ListError errors={errors} />

                <div className="crm-CellCalls__estateModal_listOrder">
                    Выберите объект &nbsp;&nbsp;
                    <ButtonGroup
                        size="small"
                        buttonSet={[
                            [ESTATE_BASE_MY, 'Мои'],
                            [ESTATE_BASE_MAIN, 'Агентство']
                        ]}
                        name="base"
                        value={base}
                        handleChange={handleChangeBase}
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <ItemDropdown
                        loading={loadingItem}
                        name="estate_id"
                        store={estateStore}
                        item_id={estate_id}
                        onChange={handleEstateChange}
                        placeholder="Выбрать объект"
                        noResultsMessage="Объект не найден"
                    />
                </div>

                <WarningMessage header="Внимание!">
                    Если вы поменяете объект, то карточка покупателя, которая была создана при прикрепление объекта, удалится.
                    <br />
                    Вместо нее будет создана новая карточка покупателя по новому объекту.
                </WarningMessage>
            </ModalContent>
            <ModalActions>
                <Button onClick={onClose}>Закрыть</Button>
                <Button color="secondary" onClick={handleSave} variant="outlined" loading={loadingItem}>
                    Поменять
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default observer(ChangeCallEstateModal);
