import React from 'react';

import Table, { TableBody, TableCell, TableRow, TableHead } from '~ui/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import { SMAShareType } from '~/types/estateSma.types';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import Time from '~/components/Base/Time';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import { InfoMessage } from '~ui/Message';

const SmaShareTable = ({ list }: { list: SMAShareType[] }) => {
    return (
        <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
            <InfoMessage>Отправленные СМА</InfoMessage>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Когда</TableCell>
                        <TableCell>Сотрудник</TableCell>
                        <TableCell>Кому</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {list.map(({ phone, createTime, user, sma_share_id }) => {
                        return (
                            <TableRow key={sma_share_id} hover>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    <UserLinkWithPreview user={user} />
                                </TableCell>
                                <TableCell>{adjustPhoneNumber(phone)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SmaShareTable;
