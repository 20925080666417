import React from 'react';

import { getUnixTime } from 'date-fns';
import TextField from '@material-ui/core/TextField';
import { DatePicker as DatePickerMU } from '@material-ui/pickers';

interface DatePickerCommonProps {
    label?: string;
    value?: number | string | null | Date;
    maxDate?: number | Date;
    minDate?: number | Date;
    openTo?: 'date' | 'month' | 'year';
    variant?: 'outlined' | 'standard';
}

interface DatePickerPropsTime extends DatePickerCommonProps {
    onChange?: (timeSec: number, other?: never) => void;
}

interface DatePickerPropsDate extends DatePickerCommonProps {
    name: string;
    onChange: (name: string, timeSecWW: number) => void;
}

const adjustTimeToDate = (time: number | null | string | Date): Date | null => {
    if (time === null) {
        return null;
    } else if (typeof time === 'string') {
        return new Date(time);
    } else if (typeof time === 'number') {
        return new Date(time < 1000000000000 ? time * 1000 : time);
    }
    return time;
};

const DatePicker = (props: DatePickerPropsTime | DatePickerPropsDate) => {
    const { label, value, maxDate, minDate, openTo, variant = 'outlined' } = props;

    // let adjustedValue = typeof value === 'string' ? getUnixTime(new Date(value)) * 1000 : value;
    const handleChange = (date: Date) => {
        const time = date && date.getTime();
        if (!isNaN(time) && props.onChange) {
            const currentTimeSec = Math.floor(time / 1000);

            if ('name' in props) {
                props.onChange(props.name, currentTimeSec);
            } else {
                props.onChange(currentTimeSec);
            }
        }
    };

    return (
        <DatePickerMU
            label={label}
            value={adjustTimeToDate(value ?? null)}
            openTo={openTo}
            views={['year', 'month', 'date']}
            onChange={handleChange}
            mask="__.__.____"
            renderInput={props => <TextField size="small" fullWidth variant={variant} {...props} helperText="дд.мм.гггг" />}
            minDate={adjustTimeToDate(minDate)}
            maxDate={adjustTimeToDate(maxDate)}
        />
    );
};

export default DatePicker;
