import React, { Component, Fragment } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ItemAddModeWrapperProps } from '../ItemAddModeWrapper';

import taskStore from '~/stores/taskStore';
import ItemAddModeWrapper from '../ItemAddModeWrapper';
import { TaskLinkState } from '../../Lists/Tasks/TaskLink';

import TaskTitleEditing from './EditBlocks/TaskTitleEditing';
import TaskParticipantsEditing from './EditBlocks/TaskParticipantsEditing';
import TaskDeadLineEditing from './EditBlocks/TaskDeadLineEditing';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CreationButtonsSet from '../Common/CreationButtonsSet';

type TaskAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

@observer
class TaskAddMode extends Component<TaskAddModeProps> {
    render() {
        const { loadingItem } = taskStore.getItem(CREATING_ITEM_ID);

        return (
            <Fragment>
                <TaskTitleEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <TaskParticipantsEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <TaskDeadLineEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <CreationButtonsSet loading={loadingItem} onCreate={this.props.handleCreate} onClearForm={this.props.clearForm} />
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(taskStore, TaskLinkState, withRouter(TaskAddMode));
