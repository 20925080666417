import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Comment, Form, Button, Ref, Divider, Header } from 'semantic-ui-react';
import { COMMENT_TYPE, CommentType } from '~/types/comments.types';
import commentStore from '~/stores/commentStore';
import { checkFormValid } from '~/common/forms';
import ItemErrors from '../../ItemErrors';
import CommentsFeed from './CommentsFeed';
import LoaderAwait from '../../../Base/LoaderAwait';

export type AdditionalComments = {
    item_id: number;
    item_type: COMMENT_TYPE;
    title: string;
};

type CommentsProps = {
    item_id: number;
    item_type: COMMENT_TYPE;
    multiTypes?: AdditionalComments[];
    onAddComment?: (comment: CommentType[]) => void;
    minimal?: boolean;
};

type CommentsSheetState = {
    isFocused: boolean;
};

class CommentsSheet extends Component<CommentsProps, CommentsSheetState> {
    constructor(props: CommentsProps) {
        super(props);

        this.state = {
            isFocused: false
        };
    }

    static getDerivedStateFromProps(props: CommentsProps, state: CommentsSheetState) {
        return {
            isFocused: state.isFocused
        };
    }

    handleSubmitComment = async () => {
        if (checkFormValid(this.$form, false)) {
            const { item_id, item_type, onAddComment } = this.props;
            const { comment } = commentStore.getItem(item_type, item_id);
            if (!comment.trim()) {
                return;
            }
            this.setState({ isFocused: false });

            const comments = await commentStore.addComment(item_type, item_id);

            if (onAddComment) {
                onAddComment(comments);
            }
        }
    };

    handleComment = ({ target: { value: comment } }: { target: { value: string } }) => {
        const { item_id, item_type } = this.props;
        commentStore.setComment(item_type, item_id, comment);
    };

    handleKeyPress = (event: { key: string }) => {
        if (event.key === 'Enter' && this.props.onAddComment) {
            this.handleSubmitComment();
        }
    };

    handleFocus = () => {
        this.setState({ isFocused: !this.state.isFocused });
    };

    $form: any = null;

    componentDidMount() {
        if (this.props.onAddComment) {
            this.$form.querySelector('textarea').focus();
        }
    }

    setReply = (reply_to: number | null) => {
        const { item_id, item_type } = this.props;
        commentStore.setReplyTo(item_type, item_id, reply_to);
    };

    sendReply = async (comment: string) => {
        const { item_id, item_type } = this.props;
        commentStore.setComment(item_type, item_id, comment);
        commentStore.addComment(item_type, item_id);
        commentStore.setReplyTo(item_type, item_id, null);
    };

    removeComment = (comment_id: number) => {
        const { item_id, item_type } = this.props;
        commentStore.removeComment(item_type, item_id, comment_id);
    };

    render() {
        const { item_id, item_type, onAddComment, minimal, multiTypes } = this.props;
        const { comments, loading, errors, comment, reply_to } = commentStore.getItem(item_type, item_id);
        const { isFocused } = this.state;

        if (errors && errors.length) {
            return <ItemErrors errors={errors} />;
        }

        const otherComments = (multiTypes || []).map(({ item_type, item_id }) => commentStore.getItem(item_type, item_id));

        return (
            <div className="crm-Item__comments">
                <Comment.Group minimal={minimal}>
                    <CommentsFeed
                        comments={comments}
                        minimal={minimal}
                        setReply={this.setReply}
                        reply_to={reply_to}
                        sendComment={this.sendReply}
                        removeComment={this.removeComment}
                    />

                    {!loading && comments.length === 0 && <div>нет комментариев</div>}

                    <LoaderAwait active={loading} size="large" />

                    {otherComments.map(({ errors, loading, comments }, index) => (
                        <Fragment key={index}>
                            {comments.length > 0 && (
                                <Divider horizontal>
                                    <Header as="h5" className="crm-Item__listDivider">
                                        {multiTypes[index].title}
                                    </Header>
                                </Divider>
                            )}
                            <CommentsFeed
                                comments={comments}
                                setReply={this.setReply}
                                reply_to={reply_to}
                                sendComment={this.sendReply}
                                removeComment={this.removeComment}
                            />
                        </Fragment>
                    ))}

                    {reply_to === null && (
                        <Ref innerRef={$form => (this.$form = $form)}>
                            <Form>
                                <br />
                                <Form.Field
                                    control="textarea"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleFocus}
                                    rows={(isFocused || comment.length) && !onAddComment ? 2 : 1}
                                    required={Boolean(comment.length)}
                                    value={comment}
                                    onChange={this.handleComment}
                                    onKeyPress={this.handleKeyPress}
                                    placeholder="напишите комментарий"
                                />
                                <Form.Field>
                                    <Button
                                        content="Отправить"
                                        loading={loading}
                                        labelPosition="left"
                                        icon="send"
                                        size="tiny"
                                        primary
                                        disabled={!comment.length}
                                        onClick={this.handleSubmitComment}
                                    />
                                </Form.Field>
                            </Form>
                        </Ref>
                    )}
                </Comment.Group>
            </div>
        );
    }
}

export default observer(CommentsSheet);
