import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

type ProgressProps = {
    show: boolean;
    size?: number;
    linear?: boolean;
    color?: 'primary' | 'secondary';
};

const Progress = ({ show, size = 54, color = 'primary', linear }: ProgressProps) =>
    show ? (
        linear ? (
            <LinearProgress color={color} />
        ) : (
            <CircularProgress
                color={color}
                size={size}
                style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -(size / 2), marginLeft: -(size / 2) }}
            />
        )
    ) : null;

export default Progress;
