import React from 'react';

import { WhatsappChatTitle } from '~/types/whatsapp.types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import Time from '~/components/Base/Time';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';

import UserAvatar from '~ui/UserAvatar';

const WhatsappTitleItem = ({ waTitle }: { waTitle: WhatsappChatTitle }) => {
    const { lastMessage, phone, waContact } = waTitle;
    const waName = waContact.contactName || waContact.pushname;

    return (
        <List>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <UserAvatar name={waName} src={waContact.photoUrl} />
                </ListItemAvatar>
                <ListItemText
                    primary={`${waName} (${adjustPhoneNumber(phone)})`}
                    secondary={
                        <>
                            <Typography component="span" variant="body2" color="textPrimary">
                                {lastMessage.message.substring(0, 25) + '...'}
                            </Typography>
                            <Time time={lastMessage.createTime} />
                        </>
                    }
                />
            </ListItem>
        </List>
    );
};

export default WhatsappTitleItem;
