import React, { ChangeEvent, ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import commonStore from '~/stores/commonStore';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw'
    }
});

export type ProgressiveTab = {
    label: string;
    value: string | number;
    icon: ReactElement;
    disabled?: boolean;
    tabElement: ReactElement;
};

type ProgressiveTabsProps = {
    tabs: ProgressiveTab[];
    defaultValue?: string | number;
    showBottomLabels?: boolean;
};

const ProgressiveTabs = ({ tabs, defaultValue, showBottomLabels }: ProgressiveTabsProps) => {
    const classes = useStyles();
    const { isMobile } = commonStore;

    const [tabId, setTabId] = useState(defaultValue || tabs[0].value);
    const handleTabChange = (event: ChangeEvent, value: number | string) => {
        setTabId(value);
    };

    const foundTab = tabs.find(tab => tab.value === tabId);
    if (!foundTab) {
        return null;
    }

    if (!tabs.length) {
        return null;
    }

    return (
        <div className="crm-Item__bottomNav">
            {!isMobile && (
                <Tabs value={tabId} onChange={handleTabChange} indicatorColor="primary" textColor="primary" style={{ marginBottom: '1em' }}>
                    {tabs.map(({ label, value, icon, disabled }) => (
                        <Tab key={value} label={label} value={value} disabled={disabled} />
                    ))}
                </Tabs>
            )}

            {foundTab.tabElement}

            {isMobile && (
                <BottomNavigation showLabels={showBottomLabels} value={tabId} onChange={handleTabChange} className={classes.root}>
                    {tabs.map(({ label, value, icon, disabled }) => (
                        <BottomNavigationAction key={value} label={label} value={value} icon={icon} disabled={disabled} />
                    ))}
                </BottomNavigation>
            )}
        </div>
    );
};

export default React.memo(ProgressiveTabs);
