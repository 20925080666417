import React, { useState, useMemo, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { FeedFilterType, ExportingBaseFeed } from '~/types/exports.types';

import exportingBaseFeedStore from '~/stores/export/exportingBaseFeedStore';
import commonStore from '~/stores/commonStore';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import PropertyTypeBlock from '~/components/Lists/Estate/FilterBlocks/PropertyTypeBlock';
import FilterEstateRegion from '~/components/Lists/Estate/FilterBlocks/FilterEstateRegion';
import ButtonGroup from '~/components/Base/ButtonGroup';
import UsersAndGroupsDropdownNew from '~/components/Lists/Common/UsersAndGroupsDropdownNew';

import Progress from '~ui/Progress';
import Grid from '~ui/Grid';
import Divider from '~ui/Divider';
import Button from '~ui/Button';
import { Modal, ModalActions, ModalContent } from '~ui/Modal';
import TextInput from '~ui/TextInput';
import Select from '~ui/Select';
import estateL10n from '~/L10n/estate.L10n';
import ConfirmTrigger from '~ui/ConfirmTrigger';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const ExportingFeedModal = ({ export_base_id, feed_id, onClose }: { export_base_id: number; feed_id: number; onClose: () => void }) => {
    let editingFeed: Partial<ExportingBaseFeed> = {
        name: '',
        format: 'cian',
        config: '{}'
    };
    const [filter, setFilter] = useState<FeedFilterType>({
        type: 0,
        propertyTypes: [],
        group_id: [],
        major_user_id: [],
        regionId: 0
    });

    useMemo(() => {
        if (feed_id !== CREATING_ITEM_ID) {
            const { name, searchParams, format, config } = exportingBaseFeedStore.getItemInList(export_base_id, feed_id);
            editingFeed.name = name;
            editingFeed.format = format;
            editingFeed.searchParams = searchParams;
            editingFeed.config = config;
            setFilter(JSON.parse(searchParams));
        } else {
            editingFeed.searchParams = JSON.stringify(filter);
        }
    }, []);

    const [emptyFeed, setEmptyFeed] = useState<Partial<ExportingBaseFeed>>(editingFeed);

    const handleFilterChange = <T extends keyof FeedFilterType>(event, { name, value }: { name: T; value: FeedFilterType[T] }) => {
        const newFilter = { ...filter, [name]: value };
        setFilter(newFilter);
        setEmptyFeed({ ...emptyFeed, searchParams: JSON.stringify(newFilter) });
    };

    const handleChange = <T extends keyof ExportingBaseFeed>(event, { name, value }: { name: T; value: ExportingBaseFeed[T] }) => {
        const newEmptyFeed = { ...emptyFeed, [name]: value };
        setEmptyFeed(newEmptyFeed);
    };

    const handleChangePhone = async (event: SyntheticEvent, { value }: { value: string }) => {
        const config = JSON.parse(emptyFeed.config);
        const newEmptyFeed = { ...emptyFeed, config: JSON.stringify({ ...config, defaultPhone: value.replace(/\D/gi, '') }) };
        setEmptyFeed(newEmptyFeed);
    };

    const handleChangeOtherXmlFeed = async (event: SyntheticEvent, { value }: { value: string }) => {
        const config = JSON.parse(emptyFeed.config);
        const newEmptyFeed = { ...emptyFeed, config: JSON.stringify({ ...config, otherXmlFeed: value }) };
        setEmptyFeed(newEmptyFeed);
    };

    const handleCreate = async () => {
        if (
            feed_id
                ? await exportingBaseFeedStore.updateItemList(export_base_id, feed_id, emptyFeed)
                : await exportingBaseFeedStore.createFeedInBase(export_base_id, emptyFeed)
        ) {
            exportingBaseFeedStore.fetchItemList(export_base_id);
            onClose();
        }
    };

    const handleDelete = async () => {
        if (await exportingBaseFeedStore.updateItemList(export_base_id, feed_id, { enable: false })) {
            exportingBaseFeedStore.fetchItemList(export_base_id);
            onClose();
        }
    };

    const { errors, loading } = exportingBaseFeedStore.getItemList(export_base_id).updatingItem;
    const { name, format, config } = emptyFeed;
    const { type, propertyTypes, group_id, major_user_id, regionId } = filter;

    let configObj = { defaultPhone: '', otherXmlFeed: '' };
    try {
        configObj = JSON.parse(config);
    } catch (err) {}

    return (
        <Modal
            onClose={!loading ? onClose : null}
            fullScreen={commonStore.isMobile}
            fullWidth
            maxWidth="sm"
            header={`${feed_id ? 'Редактирование' : 'Создание'} фида`}
        >
            <ModalContent dividers>
                <ListError errors={errors} />
                <Grid columns={2} stackable spacing={1}>
                    <Grid.Column>
                        <TextInput label="Наименование" required value={name} name="name" onChange={handleChange} error={!name} fullWidth />
                    </Grid.Column>
                    <Grid.Column>
                        <Select
                            value={format}
                            name="format"
                            label="Формат"
                            onChange={handleChange}
                            fullWidth
                            options={estateL10n.EXPORT_FORMAT_NAME_DROPDOWN}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            margin="none"
                            label="Телефон по умолчанию"
                            value={configObj?.defaultPhone || ''}
                            onChange={handleChangePhone}
                            fullWidth
                            mask={'+7 (000) 000-00-00'}
                            pattern={phoneRegExp}
                        />
                    </Grid.Column>
                    {format === 'avito' && (
                        <Grid.Column>
                            <TextInput
                                margin="none"
                                label="Склеить с другим фидом"
                                value={configObj?.otherXmlFeed || ''}
                                onChange={handleChangeOtherXmlFeed}
                                fullWidth
                            />
                        </Grid.Column>
                    )}
                </Grid>

                <Divider />
                <Grid spacing={1}>
                    <Grid.Column width={6}>
                        <ButtonGroup
                            buttonSet={[
                                [0, 'Все'],
                                [1, 'Продажа'],
                                [2, 'Аренда']
                            ]}
                            name="type"
                            value={type}
                            handleChange={handleFilterChange}
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <FilterEstateRegion onChange={handleFilterChange} fullWidth value={regionId} />
                    </Grid.Column>

                    <Grid.Column>
                        <PropertyTypeBlock propertyTypes={propertyTypes} type={type} handleChange={handleFilterChange} />
                    </Grid.Column>

                    <Grid.Column>
                        <UsersAndGroupsDropdownNew group_id={group_id} major_user_id={major_user_id} handleChange={handleFilterChange} />
                    </Grid.Column>
                </Grid>
            </ModalContent>

            <ModalActions>
                <Button disabled={loading} onClick={onClose} color="secondary">
                    Отменить
                </Button>

                {feed_id !== CREATING_ITEM_ID && (
                    <ConfirmTrigger
                        trigger={
                            <Button color="secondary" fullWidth variant="outlined">
                                Удалить
                            </Button>
                        }
                        header="Удалить фид?"
                        content="Объекты с экспорта не снимаются, фид перестанет обновляться"
                        confirmButton="Удалить"
                        onConfirm={handleDelete}
                    />
                )}

                <Button disabled={loading} variant="outlined" onClick={handleCreate} color="primary" loading={loading}>
                    {feed_id !== CREATING_ITEM_ID ? 'Сохранить' : 'Создать'}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default observer(ExportingFeedModal);
