import React from 'react';
import { Icon } from 'semantic-ui-react';
import './less/FluentButtons.less';

const SaveButton = ({ onClick }: { onClick: () => void }) => (
    <button className="crm-FluentButtons__saveBtn" onClick={onClick}>
        <Icon link name="check" color="green" title="Сохранить" />
        сохранить
    </button>
);

export default SaveButton;
