import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line } from 'recharts';

import Grid from '@material-ui/core/Grid';
import userStatisticsStore from '~/stores/userStatisticsStore';
import UserStatisticsPult from './UserStatisticsPult';

import { StatChartTooltip } from '~/components/Analytics/ChartStatistics';
import commonStore from '~/stores/commonStore';
import { toDateString } from '~/common/time';
import { KpiHistory } from '~/types/kpi.types';

const chartMargins = {
    top: 5,
    right: 0,
    left: 0,
    bottom: 5
};

const adjustList = (list: KpiHistory[]): { kpi: number; date: string }[] =>
    list.map(({ kpi, time }) => ({ kpi, date: toDateString(time) }));

type OffsetsChar = {
    green: number;
    yellow: number;
    red: number;
};

const gradientOffset = (list: KpiHistory[], greenValue: number, redValue: number): OffsetsChar => {
    let offsets = {
        green: 0,
        yellow: 1,
        red: 0
    };

    const kpiValues = list.map(({ kpi }) => kpi);

    if (!kpiValues.length) {
        return offsets;
    }

    const kpiMax = Math.max(...kpiValues);
    const kpiMin = Math.min(...kpiValues);

    const kpiDelta = kpiMax - kpiMin;
    if (kpiDelta <= 0) {
        return offsets;
    }

    offsets['green'] = (kpiMax - greenValue) / kpiDelta;
    offsets['red'] = (redValue - kpiMin) / kpiDelta;

    if (kpiMax <= greenValue) {
        offsets['green'] = 0;
    }
    if (kpiMin >= redValue) {
        offsets['red'] = 0;
    }

    offsets['yellow'] = 1 - offsets['red'] - offsets['green'];

    if (offsets['green'] > 1) {
        offsets['red'] = 0;
        offsets['yellow'] = 0;
    }
    if (offsets['red'] > 1) {
        offsets['green'] = 0;
        offsets['yellow'] = 0;
    }

    return offsets;
};

const UserStatisticsKpiCharts = observer(({ user_id }: { user_id: number }) => {
    const { list, filter, options } = userStatisticsStore.getItemList(user_id);
    const { isMobile } = commonStore;

    const { kpi_id } = filter;
    const userKpi = options && options.userKpis.find(userKpi => userKpi.kpi_id === kpi_id);

    let offsets: null | OffsetsChar = null;
    if (userKpi) {
        offsets = gradientOffset(list, userKpi.greenValue, userKpi.redValue);
    }

    return (
        <ResponsiveContainer>
            <ComposedChart data={adjustList(list)} margin={chartMargins}>
                <CartesianGrid strokeDasharray="3 5" />
                <XAxis dataKey="date" />
                <YAxis width={40} mirror={isMobile} />

                {offsets && (
                    <defs>
                        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                            {offsets['green'] > 0 && <stop offset={0} stopColor="green" stopOpacity={1} />}
                            {offsets['yellow'] > 0 && <stop offset={offsets['yellow']} stopColor="yellow" stopOpacity={1} />}
                            {offsets['red'] > 0 && <stop offset={1} stopColor="red" stopOpacity={1} />}
                        </linearGradient>
                    </defs>
                )}

                <Line
                    name={`kpi (${userKpi?.title})`}
                    type="monotone"
                    dataKey={'kpi'}
                    stroke={'url(#splitColor)'}
                    // stroke={'#00abd1'}
                    strokeWidth={3}
                    dot={false}
                    animationDuration={500}
                />

                {!isMobile && <Tooltip content={<StatChartTooltip />} />}
            </ComposedChart>
        </ResponsiveContainer>
    );
});

const UserStatisticsCharts = ({ user_id }: { user_id: number }) => {
    return (
        <Grid item xs={12}>
            <UserStatisticsPult user_id={user_id} />

            <div style={{ height: '400px' }}>
                <UserStatisticsKpiCharts user_id={user_id} />
            </div>
        </Grid>
    );
};

export default React.memo(UserStatisticsCharts);
