import { observable, action, runInAction, computed } from 'mobx';

import debounce from '~/common/debounce';
import deepCopy from '~/common/deepCopy';
import isEqual from '~/common/isEqual';
import { getDefaultPagination, matchPaginationTotalPages } from '~/common/pagination';

import ItemStorePrototype, { TItemExtended } from './ItemStore.prototype';
import { ItemStoreInterface } from './ItemStore.prototype';
import { ApiModuleType } from './ItemStore.prototype';
import authStore from '../authStore';
import accessStore from '../accessStore';

import { ITEM_TYPES } from '~/types/notifications.types';
import { ACCESS_READ, ACCESS_SELF } from '~/types/access.types';
import { ShareEstateId } from '~/types/estate.types';

export interface ApiModuleTypeList<ItemObjectType extends TItemExtended, ItemListType, ItemListFilter>
    extends ApiModuleType<ItemObjectType> {
    fetchList: (
        limit: number,
        offset: number,
        orderBy: string,
        orderDirection: OrderDirectionType,
        filter: Partial<ItemListFilter>,
        controller?: AbortController,
        withoutCount?: boolean
    ) => Promise<{ list: Array<ItemListType>; count: number }>;
    fetchListCount?: (filter: Partial<ItemListFilter>, orderBy: string, controller?: AbortController) => Promise<number>;
    manageItemArray?: (items: (number | ShareEstateId)[], params: any) => Promise<boolean>;
}

export type PaginationType = {
    activePage: number;
    totalPages: number;
    pageSize: number;
};

export type DropdownType = {
    key?: number | string;
    value: number | string | boolean | null;
    text: string;
};

export type OrderDirectionType = 'ascending' | 'descending';

type SavedSearch<ItemListFilter> = {
    time: number;
    filter: Partial<ItemListFilter>;
    orderBy: string;
    orderDirection: OrderDirectionType;
};

export interface ListStoreInterface extends ItemStoreInterface<any> {
    changeItemDropdownSearch(string): void;
    loadingDropdownOptions: boolean;
    itemDropdownOptions: Array<DropdownType>;
    item_id: string;
    changeFilter(string, unknown): void;
    setRouteFilter(string): void;
    fetchList: (orderBy?: string, orderDirection?: OrderDirectionType) => Promise<void>;
    clearFilter(): void;
    debounceFilterFetch(): void;
    listFilter: any;
    listCount: number;
    loadingList: boolean;
    readonly filterIsEmpty: boolean;
    startLoading(): void;
    saveListSearch(): void;
    listFiltersSaved: SavedSearch<any>[];
    orderBy: string;
    orderDirection: OrderDirectionType;
}

class ListStorePrototype<ItemObjectType extends TItemExtended, ItemListType = ItemObjectType, ItemPropertyType = {}, ItemListFilter = {}>
    extends ItemStorePrototype<ItemObjectType, ItemPropertyType>
    implements ListStoreInterface
{
    @observable
    list: ItemListType[] = [];
    nextList: ItemListType[] = [];

    @observable
    listCount = 0;
    @observable
    loadingList = false;
    @observable
    nextListLoaded = false;

    @observable
    listFilter: Partial<ItemListFilter> = {};

    listFilterClear: ItemListFilter;

    orderBy = '';
    orderDirection: OrderDirectionType = 'descending';

    item_id: string;

    ApiModuleList: ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter>;

    @observable
    pagination: PaginationType = getDefaultPagination();

    constructor(item_id: string, moduleName: ITEM_TYPES, ApiModule: ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter>) {
        super(item_id, moduleName, ApiModule);

        this.ApiModuleList = ApiModule;
        this.item_id = item_id;
        this.orderBy = this.orderBy || item_id;

        const listFiltersSavedJson = localStorage.getItem(`lfs_${this.moduleName}`);
        if (listFiltersSavedJson) {
            this.listFiltersSaved = JSON.parse(listFiltersSavedJson);
        }
    }

    @action
    resetPagination() {
        this.pagination.activePage = 1;
        this.pagination.totalPages = 1;
    }

    @action
    clearFilter() {
        this.resetPagination();
        this.filterHasChanged = null;
        this.selectedItemsIds = [];
        this.listFilter = deepCopy(this.listFilterClear);
    }

    @action
    setRouteFilter(param: string) {}

    findById(item_id: number): ItemListType {
        const foundItem = this.list.find(item => item[this.item_id] === item_id);
        if (!foundItem) throw Error('findById Error');
        return foundItem;
    }

    @observable
    listErrors: string[] = [];

    matchFilterField(): Partial<ItemListFilter> {
        const filterFields = {};
        Object.keys(this.listFilter).forEach(key => {
            if (this.listFilter[key] !== null) {
                filterFields[key] = this.listFilter[key];
            }
        });
        return filterFields;
    }

    fetchListAC: AbortController | null;

    matchListAccessRead(): void {
        if (
            !['chat', 'share', accessStore.moduleName].includes(this.moduleName) &&
            !authStore.matchAccess(this.moduleName, ACCESS_READ, ACCESS_SELF)
        ) {
            throw Error(`Недостаточно доступа`);
        }
    }

    @action
    matchPagination() {
        this.pagination = matchPaginationTotalPages(this.pagination, this.listCount);
    }

    enableLoadingCount = false;
    @observable loadingCount = false;
    loadingCountAC: AbortController | null;

    @action
    async loadListCount(): Promise<void> {
        if (!this.enableLoadingCount || this.filterHasChanged === false) {
            return;
        }

        try {
            if (this.loadingCountAC) {
                this.loadingCountAC.abort();
            }

            const filterFields = this.matchFilterField();
            const controller = window.AbortController ? new window.AbortController() : undefined;
            // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
            if (controller) {
                this.loadingCountAC = controller;
            }
            this.loadingCount = true;
            if (this.ApiModuleList.fetchListCount) {
                this.listCount = await this.ApiModuleList.fetchListCount(filterFields, this.orderBy, this.loadingCountAC);
            }
        } catch (err) {
            this.filterHasChanged = true;
        } finally {
            this.loadingCount = false;
        }
        this.loadingCountAC = null;
    }

    @action
    async loadList(): Promise<void> {
        const filterFields = this.matchFilterField();

        const { activePage, pageSize } = this.pagination;
        const offset = (activePage - 1) * pageSize;

        let list = [];
        let count = 0;

        try {
            if (this.fetchListAC) {
                this.fetchListAC.abort();
            }
            const controller = window.AbortController ? new window.AbortController() : undefined;
            // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
            if (controller) {
                window.setTimeout(() => {
                    this.fetchListAC = controller;
                }, 0);
            }

            const request = await this.ApiModuleList.fetchList(
                pageSize,
                offset,
                this.orderBy,
                this.orderDirection,
                filterFields,
                controller,
                this.filterHasChanged === false
            );

            this.fetchListAC = null;
            list = request.list;
            count = request.count;
        } catch (error) {
            if (this.fetchListAC && this.fetchListAC.signal.aborted === true) {
                return;
            }

            console.error('error', error);
            this.listErrors = [error.toString()];
        }

        if (~count && !this.enableLoadingCount) {
            this.listCount = count;
        }
        this.list = list;
        this.loadingList = false;
    }

    @action
    async fetchList(orderBy?: string, orderDirection?: OrderDirectionType) {
        this.matchListAccessRead();

        this.nextList = [];
        this.nextListLoaded = false;

        this.orderBy = orderBy || this.orderBy || this.item_id;
        if (orderDirection) {
            this.orderDirection = orderDirection;
        }

        this.loadingList = true;
        this.listErrors = [];

        await Promise.all([this.loadList(), this.loadListCount()]);

        this.matchPagination();

        if (this.filterHasChanged === null) {
            this.filterHasChanged = false;
        }
        if (!this.disablePrepareNextList && this.pagination.activePage < this.pagination.totalPages) {
            this.prepareNextList();
        }
    }

    disablePrepareNextList: boolean = false;

    @action async prepareNextList() {
        this.nextList = [];
        this.nextListLoaded = false;

        const { activePage, pageSize } = this.pagination;
        const offset = activePage * pageSize;

        const filterFields = this.matchFilterField();

        try {
            const { list } = await this.ApiModuleList.fetchList(
                pageSize,
                offset,
                this.orderBy,
                this.orderDirection,
                filterFields,
                null,
                true
            );
            this.nextList = list;
            this.nextListLoaded = true;
        } catch (error) {}
    }

    debounceFilterFetch = debounce(() => {
        this.fetchList(this.orderBy, this.orderDirection);
        this.filterHasChanged = false;
    }, 350);

    filterHasChanged: boolean | null = null;

    @action
    changeFilter<T extends keyof ItemListFilter>(what: T, value: ItemListFilter[T]) {
        this.filterHasChanged = true;
        this.listFilter[what] = value;
        this.pagination.activePage = 1;
        this.debounceFilterFetch();
    }

    @action
    pushUserToFilter(user_id: number) {
        if (this.listFilter['major_user_id'] instanceof Array && this.listFilter['major_user_id'].indexOf(user_id) === -1) {
            this.listFilter['major_user_id'] = [...this.listFilter['major_user_id'], user_id];
        }

        this.debounceFilterFetch();
    }

    @action
    async saveItem(id: number): Promise<Partial<ItemObjectType> | boolean> {
        const newItem: boolean | Partial<ItemObjectType> = await super.saveItem(id);
        if (newItem && typeof newItem === 'object') {
            this.mergeList(id, newItem);
        }
        return newItem;
    }

    @action
    mergeList(id: number, item: Partial<ItemObjectType>) {
        const foundIndex = this.list.findIndex(obj => parseInt(obj[this.item_id]) === id);
        if (~foundIndex) {
            this.list[foundIndex] = { ...this.list[foundIndex], ...item };
            this.list = [...this.list];
        }
    }

    @action
    pageChange = (pageNumber: number) => {
        const { activePage } = this.pagination;
        this.pagination = { ...this.pagination, activePage: pageNumber };
        if (pageNumber - activePage === 1 && this.nextList.length) {
            this.loadingList = true;
            this.list = [...this.nextList];
            this.loadingList = false;
            this.prepareNextList();
        } else {
            this.fetchList();
        }
    };

    @action
    pageSizeChange = (pageSize: number) => {
        this.pagination = { pageSize: pageSize, activePage: 1, totalPages: 1 };
        this.fetchList();
    };

    @action
    async createItem(): Promise<number> {
        const item_id = await super.createItem();
        if (item_id) {
            this.fetchList();
        }
        return item_id;
    }

    @action
    async superCreateItem() {
        return await super.createItem();
    }

    @observable
    loadingDropdownOptions = false;
    itemDropdownOptions = [];
    searchQuery: string;

    dropdownList: Array<Partial<ItemListType>> = [];

    @action
    async fetchItemDropdownOptions(search: string) {}

    debounceItemDropdownOptions = debounce(() => {
        this.fetchItemDropdownOptions(this.searchQuery);
    }, 350);

    findFromList = (item_id: number): Partial<ItemListType> => {
        const item = this.dropdownList.find(item => item[this.item_id] === item_id);
        if (!item) {
            throw new Error('Error');
        }
        return item;
    };

    changeItemDropdownSearch(searchQuery: string) {
        this.searchQuery = searchQuery;
        this.debounceItemDropdownOptions();
    }

    @observable
    selectedItemsIds: (number | ShareEstateId)[] = [];

    @action
    toggleItemSelected = (item_id: number | ShareEstateId) => {
        const foundIndex = this.selectedItemsIds.findIndex(itemId => isEqual(itemId, item_id));

        if (~foundIndex) {
            this.selectedItemsIds.splice(foundIndex, 1);
        } else {
            this.selectedItemsIds.push(item_id);
        }
    };

    @observable
    loadingManageItemArray = false;
    @observable
    manageItemArrayErrors: string[] = [];

    @action
    async manageItemArray(params: any): Promise<boolean> {
        if (typeof this.ApiModuleList.manageItemArray !== 'function') {
            return false;
        }

        this.loadingManageItemArray = true;
        this.manageItemArrayErrors = [];
        try {
            await this.ApiModuleList.manageItemArray(this.selectedItemsIds, params);

            runInAction(() => {
                this.selectedItemsIds = [];
            });

            this.fetchList();

            return true;
        } catch (errors) {
            this.manageItemArrayErrors = errors;
            return false;
        } finally {
            this.loadingManageItemArray = false;
        }
    }

    @observable
    selectedAll = false;

    matchSelectedItemsFiled(item: ItemListType): number | ShareEstateId {
        return item[this.item_id];
    }

    @action
    async toggleAllItems() {
        const selectedItemsIds = [];

        if (!this.selectedAll) {
            for (const item of this.list) {
                const foundIndex = this.selectedItemsIds.findIndex(item_id => isEqual(item_id, item[this.item_id]));
                if (!~foundIndex) {
                    selectedItemsIds.push(this.matchSelectedItemsFiled(item));
                }
            }
        }

        this.selectedAll = !this.selectedAll;
        this.selectedItemsIds = selectedItemsIds;
    }

    @action
    startLoading() {
        this.loadingList = true;
    }

    @computed get filterIsEmpty(): boolean {
        return isEqual(this.listFilter, this.listFilterClear);
    }

    @observable
    listFiltersSaved: SavedSearch<ItemListFilter>[] = [];

    @action saveListSearch() {
        const time = Math.floor(Date.now() / 1000);
        this.listFiltersSaved.unshift({
            time,
            filter: deepCopy(this.listFilter),
            orderBy: this.orderBy,
            orderDirection: this.orderDirection
        });
        this.listFiltersSaved = [...this.listFiltersSaved].slice(0, 10);
        localStorage.setItem(`lfs_${this.moduleName}`, JSON.stringify(this.listFiltersSaved));
    }

    @action
    applySavedFilter(index: number) {
        const { filter, orderBy, orderDirection } = this.listFiltersSaved[index];
        this.listFilter = deepCopy(filter);
        this.fetchList(orderBy, orderDirection);
    }

    @action
    removeSavedFilter(index: number) {
        this.listFiltersSaved.splice(index, 1);
        this.listFiltersSaved = [...this.listFiltersSaved];
    }

    @action
    async toggleDisableItem(id: number, enable: boolean) {
        await super.toggleDisableItem(id, enable);
        this.fetchList();
    }
}

export default ListStorePrototype;
