import { observable, action, runInAction } from 'mobx';
import * as expiredCallsApi from '~/api/cell/expiredCallsApi';
import { TExpiredCalls } from '~/types/expiredCalls.types';
import ItemStorePrototype from '../prototypes/ItemStore.prototype';
import estateStore from '../estateStore';

class ExpiredCallStore extends ItemStorePrototype<TExpiredCalls> {
    orderBy = 'startTime';

    constructor() {
        super('expired_call_id', 'expiredCall', expiredCallsApi);
    }

    @action
    async createExpiredCall(bound_estate_id: number): Promise<number> {
        const expired_call_id = await this.createItem();
        const estate = estateStore.getItem(bound_estate_id).item;
        if (estate) {
            const changedData = { lastOutgoingCallToOwnerTime: Date.now() };
            estateStore.mergeItem(bound_estate_id, changedData);
            estateStore.mergeList(bound_estate_id, changedData);
        }
        return expired_call_id;
    }
}

export default new ExpiredCallStore();
