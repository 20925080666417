import React from 'react';

import LaunchIcon from '@material-ui/icons/Launch';
import { matchSourceUrl } from '../ShowBlocks/ExternalBlockShow';

const EstateSmaOuterLink = ({
    source,
    sourceInnerId,
    externalUrlAvailable = true
}: {
    source: string;
    sourceInnerId: string;
    externalUrlAvailable?: boolean;
}) => {
    return (
        <a
            title="Открыть в новом окне ссылку на объявление"
            target="_blank"
            href={matchSourceUrl(source, sourceInnerId)}
            className="crm-Flex__center"
            style={!externalUrlAvailable ? { textDecoration: 'line-through' } : null}
            rel="noreferrer"
        >
            {source}&nbsp;
            <LaunchIcon fontSize="small" />
        </a>
    );
};

export default React.memo(EstateSmaOuterLink);
