import React, { Component, Fragment } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Table, Checkbox } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_FAVORITES, ESTATE_BASE_GROUP, ESTATE_BASE_MY, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { isFloorAvailable, isTotalAreaAvailable } from '~/stores/helpers/estate.store.helpers';

const EstateTableHeader = ({
    orderBy,
    orderDirection,
    handleSort,
    showExportMajorUser,
    selectedAll,
    filterBase,
    propertyTypes
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
    showExportMajorUser: boolean;
    selectedAll?: boolean;
    filterBase?: string;
    propertyTypes: number[];
}) => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell title="Выбрать все" className="crm-Print__hidden">
                    <Checkbox onClick={estateStore.toggleAllItems.bind(estateStore)} checked={selectedAll} />
                </Table.HeaderCell>
                <Table.HeaderCell
                    style={{ minWidth: '90px' }}
                    sorted={orderBy === 'updateTime' ? orderDirection : null}
                    onClick={() => handleSort('updateTime')}
                >
                    Дата
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'street' ? orderDirection : null} onClick={() => handleSort('street')}>
                    Местоположение
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={orderBy === 'totalArea' ? orderDirection : null}
                    onClick={isTotalAreaAvailable(propertyTypes) ? () => handleSort('totalArea') : null}
                >
                    Площадь
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={orderBy === 'floorNumber' ? orderDirection : null}
                    onClick={isFloorAvailable(propertyTypes) ? () => handleSort('floorNumber') : null}
                >
                    Этаж
                </Table.HeaderCell>
                <Table.HeaderCell sorted={orderBy === 'price' ? orderDirection : null} onClick={() => handleSort('price')}>
                    Цена
                </Table.HeaderCell>
                {!showExportMajorUser &&
                    [ESTATE_BASE_MY, ESTATE_BASE_GROUP, ESTATE_BASE_OWNERS, ESTATE_BASE_FAVORITES].includes(filterBase) && (
                        <Table.HeaderCell
                            sorted={orderBy === 'lastCommentTime' ? orderDirection : null}
                            onClick={() => handleSort('lastCommentTime')}
                        >
                            Комментарий
                        </Table.HeaderCell>
                    )}
                <Table.HeaderCell
                    sorted={orderBy === 'major_user_ids' ? orderDirection : null}
                    onClick={() => handleSort('major_user_ids')}
                >
                    Контакты
                </Table.HeaderCell>
                {showExportMajorUser && <Table.HeaderCell>На кого экспорт</Table.HeaderCell>}
            </Table.Row>
        </Table.Header>
    );
};

export default EstateTableHeader;
