import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import whatsappStore from '~/stores/whatsappStore';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import UserAvatar from '~ui/UserAvatar';
import TwoAvatars from '~ui/TwoAvatars';
import Toolbar from '@material-ui/core/Toolbar';
import Progress from '~ui/Progress';
import { WarningMessage } from '~ui/Message';

const useStylesOptions = makeStyles(({ spacing }) => ({
    contactName: {
        marginLeft: spacing(2),
        flexGrow: 1
    },
    menuTitle: {
        marginLeft: spacing(2)
    }
}));

const WAChatHeaderOptions = ({ contact_id }: { contact_id: number }) => {
    const classes = useStylesOptions();

    const {
        options: { chats },
        filter
    } = whatsappStore.getItemList(contact_id);

    const foundIndex = chats.findIndex(({ whatsapp_chat_id }) => whatsapp_chat_id === filter.whatsapp_chat_id);
    const waContact = chats[foundIndex].waContact;
    const user = chats[foundIndex].waChat.user;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (whatsapp_chat_id: number) => {
        whatsappStore.changeListFilter(contact_id, 'whatsapp_chat_id', whatsapp_chat_id);
        handleClose();
    };

    return (
        <>
            <TwoAvatars
                mainAvatarUrl={waContact.photoUrl}
                mainTitle={waContact.contactName}
                secondAvatarUrl={user.avatarUrl}
                secondTitle={user.firstName}
            />

            <Typography variant="h6" noWrap className={classes.contactName}>
                {waContact ? waContact.contactName || waContact.pushname : '...'}
            </Typography>

            <IconButton onClick={handleClick} edge="end">
                <KeyboardArrowDownIcon color="secondary" />
            </IconButton>

            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {chats.map(({ whatsapp_chat_id, waChat: { user }, waContact: { contactName, pushname } }) => (
                    <MenuItem
                        key={whatsapp_chat_id}
                        selected={whatsapp_chat_id === filter.whatsapp_chat_id}
                        onClick={() => handleChange(whatsapp_chat_id)}
                    >
                        <UserAvatar name={user.firstName} size={24} src={user.avatarUrl} />
                        <span className={classes.menuTitle}>
                            {contactName || pushname} ({user.firstName} {user.lastName})
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const useStylesHeader = makeStyles(({ spacing }) => ({
    root: {
        position: 'fixed',
        zIndex: 2,
        top: '48px',
        left: 0,
        width: '100%'
    }
}));

const WAChatHeader = ({ contact_id }: { contact_id: number }) => {
    const classes = useStylesHeader();

    useMemo(() => {
        whatsappStore.fetchWAChatOptions(contact_id);
    }, []);

    const { filter, options } = whatsappStore.getItemList(contact_id);

    return (
        <>
            {options && !options.chats.length && <WarningMessage>Whatsapp чатов не найдено</WarningMessage>}
            {(!options || options.chats.length > 0) && (
                <Paper className={classes.root}>
                    <Toolbar>
                        {filter.whatsapp_chat_id && <WAChatHeaderOptions contact_id={contact_id} />}
                        <Progress show={!options} />
                    </Toolbar>
                </Paper>
            )}
        </>
    );
};

export default observer(WAChatHeader);
