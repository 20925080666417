import { observable, action, reaction, runInAction, computed } from 'mobx';
import * as authApi from '~/api/authApi';
import { User, UserAndGroup, UserGroup } from '~/types/users.types';
import * as userApi from '~/api/userApi';
import * as accessApi from '~/api/accessApi';
import { ACCESS_OFFICE, AccessModes, AccessPermission } from '~/types/access.types';
import { ACCESS_COMPANY, ACCESS_DELETE, ACCESS_GROUP, ACCESS_NONE, ACCESS_READ, ACCESS_SELF, ACCESS_WRITE } from '~/types/access.types';
import { DropdownType } from './prototypes/ListStore.prototype';
import wait from '~/common/wait';
import { ITEM_TYPES } from '~/types/notifications.types';

const JWT_NAME = 'crm-jwt';
const CURRENT_USER_MEM_KEY = 'pullCurrentUser';

const ACCESS_PERMISSIONS = [ACCESS_NONE, ACCESS_SELF, ACCESS_GROUP, ACCESS_OFFICE, ACCESS_COMPANY];

export type NEW_PASSWORD_TYPE = {
    old: string;
    new1: string;
    new2: string;
};

const ERROR_FAILED_TO_FETCH = 'Failed to fetch';

const SUBGROUPS_MEM_KEY = 'subgroupsByGroupId';

class AuthStore {
    @observable
    token = window.localStorage.getItem(JWT_NAME);

    @observable
    inProgress = false;
    @observable
    errors: string[] = [];
    @observable
    loggedIn = false;

    @observable
    currentUser: User;

    @observable
    values: { email: string; password: string } = {
        email: '',
        password: ''
    };

    allUsersInt: NodeJS.Timeout;

    constructor() {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem(JWT_NAME, token);
                } else {
                    window.localStorage.removeItem(JWT_NAME);
                }
            }
        );

        if (localStorage[SUBGROUPS_MEM_KEY]) {
            this.subgroupsByGroupId = JSON.parse(localStorage[SUBGROUPS_MEM_KEY]);
        }

        // Обновляем список новых (удаленных) сотрдуников в фоне
        this.allUsersInt = setInterval(this.pullUserAndGroupsApi, 300 * 1000);
    }

    registeredInitCallbacks: (() => void)[] = [];
    registerInitFunc(callback: () => void) {
        this.registeredInitCallbacks.push(callback);
    }

    registeredCloseCallbacks: (() => void)[] = [];
    registerCloseFunc(callback: () => void) {
        this.registeredCloseCallbacks.push(callback);
    }

    matchAccess(moduleName: ITEM_TYPES, mode: AccessModes, minimumPermission: AccessPermission): boolean {
        if (!this.currentUser?.access) {
            return false;
        }

        const {
            access: { modules }
        } = this.currentUser;

        const foundModule = modules.find(module => module.name === moduleName);
        if (!foundModule) {
            return false;
        }

        const { access } = foundModule;

        return ACCESS_PERMISSIONS.indexOf(access[mode]) >= ACCESS_PERMISSIONS.indexOf(minimumPermission);
    }

    getPermission(moduleName: ITEM_TYPES, mode: AccessModes): AccessPermission {
        const {
            access: { modules }
        } = this.currentUser;

        const foundModule = modules.find(module => module.name === moduleName);
        if (!foundModule) {
            return ACCESS_NONE;
        }

        const { access } = foundModule;
        return access[mode];
    }

    canCreate(moduleName: ITEM_TYPES): boolean {
        return this.matchAccess(moduleName, ACCESS_WRITE, ACCESS_SELF);
    }

    subgroupsByGroupId: { [key: number]: number[] } = {};
    // Если группа - один group_id, если офис - несколько
    isOwnerInGroups(owners_id: number[], group_ids: number[]) {
        return owners_id.some(owner_id => typeof this.userGroups[owner_id] === 'number' && group_ids.includes(this.userGroups[owner_id]));
    }

    adjustSubgroups(groups: UserGroup[]) {
        groups.forEach(({ group_id, subgroups }) => {
            this.subgroupsByGroupId[group_id] = [group_id];
            subgroups.forEach(({ group_id: subgroup_id }) => {
                this.subgroupsByGroupId[subgroup_id] = [subgroup_id];
                this.subgroupsByGroupId[group_id].push(subgroup_id);
            });
        });
        localStorage[SUBGROUPS_MEM_KEY] = JSON.stringify(this.subgroupsByGroupId);
    }

    matchAllSubgroupsIdsByGroupIds(group_ids: number[]): number[] {
        let newGroupIds = [];
        for (const groupId of group_ids) {
            newGroupIds.push(...(this.subgroupsByGroupId[groupId] || [groupId]));
        }
        return Array.from(new Set(newGroupIds));
    }

    canSmth(mode: AccessModes, moduleName: ITEM_TYPES, owner_id: number | number[]): boolean {
        if (!this.currentUser) {
            return false;
        }
        const { user_id, group_id } = this.currentUser;

        if (owner_id instanceof Array ? owner_id.includes(user_id) : user_id === owner_id) {
            return this.matchAccess(moduleName, mode, ACCESS_SELF);
        } else if (
            this.isOwnerInGroups(owner_id instanceof Array ? owner_id : [owner_id], this.subgroupsByGroupId[group_id] || [group_id])
        ) {
            return this.matchAccess(moduleName, mode, ACCESS_GROUP);
        } else if (mode === ACCESS_READ) {
            const { grant_users_under_ids } = this.currentUser;
            const setHasGrantedUser = grant_users_under_ids.some(grant_user_id =>
                (owner_id instanceof Array ? owner_id : [owner_id]).includes(grant_user_id)
            );
            if (setHasGrantedUser) {
                return this.matchAccess(moduleName, mode, ACCESS_SELF);
            }
        }

        return this.matchAccess(moduleName, mode, ACCESS_COMPANY);
    }

    canEdit(moduleName: ITEM_TYPES, owner_id: number | Array<number>): boolean {
        return this.canSmth(ACCESS_WRITE, moduleName, owner_id);
    }

    canRead(moduleName: ITEM_TYPES, owner_id: number | Array<number>): boolean {
        return this.canSmth(ACCESS_READ, moduleName, owner_id);
    }

    canDelete(moduleName: ITEM_TYPES, owner_id: number | Array<number>): boolean {
        return this.canSmth(ACCESS_DELETE, moduleName, owner_id);
    }

    @action
    async pullCurrentUser() {
        const currentUserStr = localStorage.getItem(CURRENT_USER_MEM_KEY);
        if (currentUserStr) {
            this.currentUser = JSON.parse(currentUserStr);
            this.pullCurrentUserApi();
        } else {
            await this.pullCurrentUserApi();
        }
    }

    @action
    async pullCurrentUserApi() {
        try {
            this.currentUser = await userApi.pullCurrentUser();
            localStorage.setItem(CURRENT_USER_MEM_KEY, JSON.stringify(this.currentUser));
            this.registeredInitCallbacks.forEach(callback => callback());
        } catch (error) {
            if (error[0] === ERROR_FAILED_TO_FETCH) {
                await wait(500);
                this.pullCurrentUserApi();
            } else {
                // if (typeof error === 'object' && error.code === 403) {
                this.logout();
                // }
            }
        }
    }

    @action
    setEmail(email: string) {
        this.values.email = email;
    }

    @action
    setPassword(password: string) {
        this.values.password = password;
    }

    @action
    setLoggedIn() {
        this.loggedIn = true;
    }

    @action
    reset() {
        this.values.email = '';
        this.values.password = '';
    }

    @action
    setProgress(progress: boolean) {
        if (progress) {
            this.answer = '';
            this.errors = [];
        }
        this.inProgress = progress;
    }

    userGroups: any = {};
    userAndGroups: UserAndGroup[] = [];

    @computed get userAndGroupsDropdown(): DropdownType[] {
        return this.userAndGroups.map(({ firstName, lastName, user_id }) => ({
            value: user_id,
            key: user_id,
            text: `${lastName} ${firstName}`.trim()
        }));
    }

    getAllUserIdsInSubgroup(group_id: number): number[] {
        return this.userAndGroups.filter(UaG => UaG.group_id === group_id).map(({ user_id }) => user_id);
    }

    async pullUserAndGroups() {
        const allUsersStr = await localStorage.getItem('fetchAllUserGroups');
        if (allUsersStr) {
            this.userAndGroups = JSON.parse(allUsersStr);

            this.userAndGroups.forEach(({ user_id, group_id }) => {
                this.userGroups[user_id] = group_id;
            });

            this.pullUserAndGroupsApi();
        } else {
            await this.pullUserAndGroupsApi();
        }
    }

    pullUserAndGroupsApi = async () => {
        this.userAndGroups = await accessApi.fetchAllUserGroups();

        this.userAndGroups.forEach(({ user_id, group_id }) => {
            this.userGroups[user_id] = group_id;
        });

        localStorage.setItem('fetchAllUserGroups', JSON.stringify(this.userAndGroups));
    };

    findUserById(user_id: number): UserAndGroup {
        const found = this.userAndGroups.find(user => user.user_id === user_id);
        if (!found) {
            throw new Error(`Users didnt find: ${user_id}`);
        }
        return found;
    }

    @action
    async login() {
        this.setProgress(true);

        try {
            const { token } = await authApi.logIn(this.values.email, this.values.password);
            runInAction(() => {
                this.token = token;
            });

            await Promise.all([this.pullCurrentUser(), this.pullUserAndGroups()]);
            this.setLoggedIn();
        } catch (err) {
            console.log('err', err);
            this.errors = [err];
        }

        this.setProgress(false);
    }

    @observable
    answer: string;

    @action
    async resetPassword() {
        this.setProgress(true);

        try {
            const { answer } = await authApi.resetPassword(this.values.email);
            this.answer = answer;
        } catch (err) {
            console.log('err', err);

            runInAction(() => {
                this.errors = [err];
            });
        }

        this.setProgress(false);
    }

    @observable
    loggingOutInProgress = false;

    @action
    async logout() {
        this.token = undefined;
        this.loggedIn = false;
        this.loggingOutInProgress = true;

        clearInterval(this.allUsersInt);

        await Promise.all(this.registeredCloseCallbacks.map(async callback => callback()));

        await localStorage.clear();
        window.location.reload();
    }

    @action
    async whatsUp() {
        this.setProgress(true);
        try {
            await Promise.all([this.pullCurrentUser(), this.pullUserAndGroups()]);
            this.setLoggedIn();
        } catch (e) {
            await this.logout();
        }
        this.setProgress(false);
    }

    @observable
    loadingPassportChange = false;
    @observable
    editingPassword: NEW_PASSWORD_TYPE = {
        old: '',
        new1: '',
        new2: ''
    };
    @observable
    passwordErrors: Array<string> = [];

    @action
    setEditingPassword(name: string, value: string) {
        this.editingPassword[name] = value;
    }

    @action
    async changePassword() {
        if (this.editingPassword['new1'].length < 6) {
            this.passwordErrors = ['Пароль должен быть не менее 6 символов'];
        } else if (this.editingPassword['new1'] !== this.editingPassword['new2']) {
            this.passwordErrors = ['Пароли не совпадают'];
        } else {
            this.loadingPassportChange = true;

            try {
                await authApi.changePassword(this.editingPassword);

                this.logout();
            } catch (errors) {
                runInAction(() => {
                    this.passwordErrors = errors;
                    this.loadingPassportChange = false;
                });
            }
        }
    }

    getModeratorId(): number | null {
        const export_moderator_id = this?.currentUser?.access?.export_moderator_id;
        const chiefIsModerator = this?.currentUser?.access?.chiefIsModerator;
        const moderator_id = chiefIsModerator ? this?.currentUser?.group?.chief_id : export_moderator_id;
        return moderator_id || null;
    }
}

export default new AuthStore();
