import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { EstateLinkState } from '../../Lists/Estate/EstateLink';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_EXTERNAL } from '~/types/estate.types';

@observer
class ExternalTabMenu extends Component<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(estateStore, EstateLinkState, ExternalTabMenu, {
    disableDropdownMenu: true,
    base: ESTATE_BASE_EXTERNAL
});
