import React, { useState } from 'react';
import { observer } from 'mobx-react';

import kanbanStore from '~/stores/kanbanStore';

import BoardListsEditing from './BoardListsEditing';
import ListTabsEditingModal from '~/components/PultToolbar/Common/CreateItemModal/ListTabsEditingModal';
import CreateItemModal from '~/components/PultToolbar/Common/CreateItemModal';

const BoardsSettingsModal = ({ onClose }: { onClose: () => void }) => {
    const { list: boards } = kanbanStore;

    const [activeBoardId, setActiveBoardId] = useState(boards[0]?.board_id || 0);

    const [showCreateModal, setCreateModal] = useState(false);
    const toggleCreateModal = () => {
        setCreateModal(!showCreateModal);
    };

    return (
        <ListTabsEditingModal
            header="Настройка канбан досок"
            onClose={onClose}
            onCreate={toggleCreateModal}
            tabs={boards.map(({ title, board_id }) => ({ title, id: board_id }))}
            tabId={activeBoardId}
            onChangeTabId={setActiveBoardId}
        >
            {activeBoardId > 0 && <BoardListsEditing board_id={activeBoardId} />}
            {showCreateModal && (
                <CreateItemModal
                    onClose={toggleCreateModal}
                    store={kanbanStore}
                    header="Добавить доску"
                    text="Введите название новой доски"
                />
            )}
        </ListTabsEditingModal>
    );
};

export default observer(BoardsSettingsModal);
