import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';

import ItemPreviewWrapper, { ItemPreviewWrapperProps } from '../Common/ItemPreview';
import WishLink from '../../Lists/Wishes/WishLink';
import wishStore from '~/stores/wishStore';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import EstateLinkWithPreview from '../../Lists/Estate/EstateLinkWithPreview';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const preventEvent = e => {
    e.stopPropagation();
    e.preventDefault();
};

@observer
export class WishPreviewItem extends Component<ItemPreviewWrapperProps & { onMouseEnter?: () => void; onMouseLeave?: () => void }> {
    render() {
        const { item_id, handleClose, onMouseEnter, onMouseLeave } = this.props;
        const { item } = wishStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const { interestedEstates } = item;

        return (
            <Card style={{ padding: '0.5rem' }} onDoubleClick={preventEvent} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <CardActionArea component="div" title="Открыть карточку">
                    <Icon name="spy" />
                    <WishLink wish={item} />
                </CardActionArea>
                <Typography variant="caption">
                    <Icon name="building" /> Интересовался объект{interestedEstates.length === 1 ? 'ом' : 'ами'}:
                </Typography>
                <List dense>
                    {interestedEstates.slice(0, 3).map(({ startTime, ...estate }) => {
                        return (
                            <ListItem key={estate.estate_id}>
                                <EstateLinkWithPreview textNoWrap estate={estate} base={ESTATE_BASE_MAIN} />
                            </ListItem>
                        );
                    })}
                    {interestedEstates.length > 3 && (
                        <ListItem>
                            <ListItemText>+ еще {interestedEstates.length - 3}</ListItemText>
                        </ListItem>
                    )}
                </List>
            </Card>
        );
    }
}

export default ItemPreviewWrapper(wishStore, WishPreviewItem);
