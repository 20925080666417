import React from 'react';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { TableHead, TableRow, TableCell } from '~ui/Table';

const adjustDirection = (direction: OrderDirectionType): 'asc' | 'desc' => (direction === 'descending' ? 'desc' : 'asc');

const CallEventsTableHeaders = ({
    orderBy,
    orderDirection,
    handleSort
}: {
    orderBy: string;
    orderDirection: OrderDirectionType;
    handleSort: (name: string) => void;
}) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    sortDirection={orderBy === 'callTimeMin' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('callTimeMin')}
                >
                    Когда
                </TableCell>
                <TableCell
                    sortDirection={orderBy === 'item_id' ? adjustDirection(orderDirection) : false}
                    onClick={() => handleSort('item_id')}
                >
                    Контакт / Собственник / Тема
                </TableCell>
                <TableCell>Ответсвенный(е)</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};

export default CallEventsTableHeaders;
