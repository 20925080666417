import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Pagination from '~ui/Pagination';

import ListError from '~/components/ListError';
import LoaderAwait from '~/components/Base/LoaderAwait';
import Scrollbar from '~/components/Base/Scrollbar';
import LifeComplexCard from './LifeComplexCard';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    scrollbar: {
        maxHeight: 'calc(100vh - 160px)',
        minHeight: '150px'
    }
}));

const Developers = () => {
    const classes = useStyles();

    useMemo(() => {
        lifeComplexStore.fetchList();
    }, []);

    const { list, loadingList, listErrors, listCount, pagination } = lifeComplexStore;

    return (
        <Container style={{ paddingTop: '1rem' }} maxWidth="lg">
            <Typography variant="h5" style={{ marginBottom: '1rem' }}>
                Новостройки
            </Typography>
            <ListError errors={listErrors} />
            <LoaderAwait active={loadingList} />
            <Scrollbar className={classes.scrollbar}>
                {list.map(newbuilding => (
                    <LifeComplexCard key={newbuilding.newbuilding_id} newbuilding={newbuilding} />
                ))}
            </Scrollbar>
            <Box mb={2} pt={2} style={{ fontWeight: 400 }}>
                <Pagination
                    listCount={listCount}
                    pageChange={lifeComplexStore.pageChange}
                    pageSizeChange={lifeComplexStore.pageSizeChange}
                    // scrollToElementId={scrollToElementId}
                    // showPult={showPult}
                    pagination={pagination}
                    loadingCount={loadingList}
                />
            </Box>
        </Container>
    );
};

export default observer(Developers);
