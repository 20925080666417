import React, { Fragment, PureComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import contactStore from '~/stores/contactStore';
import ItemDropdown from '../../Common/ItemDropdow';
import { ContactLinkState, matchContactTitle } from '../../../Lists/Contacts/ContactLink';
import ButtonGroup from '../../../Base/ButtonGroup';
import ListError from '../../../ListError';
import history from '../../../../history';
import ContactLinkWithPreview from '../../../Lists/Contacts/ContactLinkWithPreview';
import { ErrorMessage, MessageTitle } from '~ui/Message';
import commonStore from '~/stores/commonStore';

type MergeContactsModalProps = {
    contact_id: number;
    onClose: () => void;
};

type MergeContactsModalState = {
    merge_with_contact_id: number | null;
    mergeType: 1 | 2;
};

@observer
export default class MergeContactsModal extends PureComponent<MergeContactsModalProps, MergeContactsModalState> {
    constructor(props: MergeContactsModalProps) {
        super(props);
        this.state = {
            merge_with_contact_id: null,
            mergeType: 1
        };
    }

    handleChange = (event: React.SyntheticEvent, { name, value }: { name: string; value: string }) => {
        const merge_with_contact_id = Number(value);
        if (merge_with_contact_id) {
            contactStore.fetchItem(merge_with_contact_id);
            this.setState({ merge_with_contact_id });
        } else if (value === '') {
            this.setState({ merge_with_contact_id: null });
        }
    };

    handleMergeType = () => {
        this.setState({ mergeType: this.state.mergeType === 1 ? 2 : 1 });
    };

    handleMerge = async () => {
        const { merge_with_contact_id, mergeType } = this.state;
        const { contact_id } = this.props;
        if (merge_with_contact_id) {
            if (await contactStore.mergeTwoContacts(contact_id, merge_with_contact_id, mergeType)) {
                contactStore.fetchAllContact(contact_id);
                contactStore.fetchAllContact(merge_with_contact_id);
                if (mergeType === 2) {
                    history.push(ContactLinkState(merge_with_contact_id));
                }
                this.props.onClose();
            }
        }
    };

    render() {
        const { contact_id, onClose } = this.props;
        const { merge_with_contact_id, mergeType } = this.state;

        const firstContact = contactStore.getItem(contact_id).item;
        const { mergingInProgress, mergingErrors } = contactStore.getItem(contact_id).property;
        const secondContact = merge_with_contact_id ? contactStore.getItem(merge_with_contact_id).item : null;

        const actions: ModalBtnAction[] = [
            {
                onClick: this.handleMerge,
                label: 'Объединить',
                disabled: !merge_with_contact_id || mergingInProgress,
                loading: mergingInProgress,
                color: 'secondary',
                startIcon: <RecentActorsIcon />,
                variant: 'outlined'
            },
            {
                onClick: onClose,
                label: 'Отмена',
                disabled: mergingInProgress,
                color: 'primary',
                variant: 'text'
            }
        ];

        return (
            <Modal
                maxWidth="sm"
                fullScreen={commonStore.isMobile}
                onClose={onClose}
                actions={actions}
                header="Объединение двух контактов"
                style={{ overflowY: 'visible' }}
            >
                <ModalContent style={{ overflowY: 'visible' }}>
                    <ListError errors={mergingErrors} />
                    <Grid stackable>
                        <Grid.Column width={4}>
                            <label className="crm-Item__labelWide">С кем</label>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <ItemDropdown
                                name="merge_with_contact_id"
                                store={contactStore}
                                item_id={merge_with_contact_id || 0}
                                onChange={this.handleChange}
                                placeholder="Выбрать контакт"
                                noResultsMessage="Контакт не найден"
                                clearable
                            />
                        </Grid.Column>
                        {Number(merge_with_contact_id) > 0 && (
                            <Fragment>
                                <Grid.Column width={4}>
                                    <label className="crm-Item__labelWide">Кто останется</label>
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    <ButtonGroup
                                        size="small"
                                        buttonSet={[
                                            [1, `№${contact_id} :: ${firstContact ? matchContactTitle(firstContact) : ''}`],
                                            [
                                                2,
                                                `№${merge_with_contact_id || ''} :: ${
                                                    secondContact ? matchContactTitle(secondContact) : ''
                                                }`
                                            ]
                                        ]}
                                        name="mergeType"
                                        value={mergeType}
                                        handleChange={this.handleMergeType}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <ErrorMessage>
                                        <MessageTitle>Внимание</MessageTitle>
                                        После объединения контакт&nbsp;
                                        {secondContact && firstContact && (
                                            <ContactLinkWithPreview contact={mergeType === 1 ? secondContact : firstContact} />
                                        )}
                                        &nbsp; будет отправлен в Архив. Все его объекты, заявки, показы, комментарии и т.д. будут
                                        переданы&nbsp;
                                        {secondContact && firstContact && (
                                            <ContactLinkWithPreview contact={mergeType === 2 ? secondContact : firstContact} />
                                        )}
                                        , телефоны, теги и ответственные будут объединены.
                                    </ErrorMessage>
                                </Grid.Column>
                            </Fragment>
                        )}
                    </Grid>
                </ModalContent>
            </Modal>
        );
    }
}
