import React, { SyntheticEvent } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            backgroundColor: theme.palette.error.dark
        },
        message: {
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1)
        }
    })
);

export default function ThereAreNoInternetNoti() {
    const classes = useStyles({});

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open
            autoHideDuration={6000}
        >
            <SnackbarContent
                className={classes['error']}
                message={
                    <span className={classes.message}>
                        <ErrorIcon className={classes.icon} />
                        Отсутствует соединение с сервером!
                    </span>
                }
            />
        </Snackbar>
    );
}
