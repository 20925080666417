import React, { Component, Fragment } from 'react';
import { Button, Menu } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Table, { TableBody, TableRow, TableHead, TableCell } from '~ui/Table';

import estateStore from '~/stores/estateStore';
import CellCallTableBody from '../../Lists/CellCalls/CellCallTableBody';
import FindCallModal from './SubBlocks/FindCallModal';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import { TAB_ID_DEFAULT, TAB_ID_HISTORY } from '../ItemWrapper';
import contactStore from '~/stores/contactStore';
import ContactCallsMode from '../Contact/ContactCallsMode';
import LoaderAwait from '../../Base/LoaderAwait';
import { WarningMessage } from '~ui/Message';

const TAB_INCOMING_CALLS = 'incomingCalls';
const TAB_OWNER_CALLS = 'ownerCalls';

type EstateCallsModeProps = {
    estate_id: number;
};

type EstateCallsModeState = {
    showCallModal: boolean;
    activeTab: 'incomingCalls' | 'ownerCalls';
};

@observer
class EstateCallsMode extends Component<EstateCallsModeProps, EstateCallsModeState> {
    constructor(props: EstateCallsModeProps) {
        super(props);

        this.state = {
            showCallModal: false,
            activeTab: TAB_INCOMING_CALLS
        };

        estateStore.callsByEstate(props.estate_id);
    }

    handleToggleCallModal = () => {
        this.setState({ showCallModal: !this.state.showCallModal });
    };

    handleToggleActiveTab = () => {
        if (this.state.activeTab === TAB_INCOMING_CALLS) {
            const { contact_id } = estateStore.getItem(this.props.estate_id).item || {};
            if (contact_id) {
                contactStore.fetchItem(contact_id);
            }
        }
        this.setState({ activeTab: this.state.activeTab === TAB_INCOMING_CALLS ? TAB_OWNER_CALLS : TAB_INCOMING_CALLS });
    };

    render() {
        const { estate_id } = this.props;
        const { showCallModal, activeTab } = this.state;
        const {
            property: { calls, loadingCalls },
            item
        } = estateStore.getItem(estate_id);

        return (
            <>
                <div>
                    <Menu pointing secondary stackable>
                        <Menu.Item
                            tabname={TAB_ID_DEFAULT}
                            content="Входящие по объекту"
                            active={activeTab === TAB_INCOMING_CALLS}
                            onClick={this.handleToggleActiveTab}
                        />
                        <Menu.Item
                            tabname={TAB_ID_HISTORY}
                            content="Звонки Собственнику (Продавцу)"
                            active={activeTab === TAB_OWNER_CALLS}
                            onClick={this.handleToggleActiveTab}
                            color="green"
                        />
                    </Menu>

                    {activeTab === TAB_OWNER_CALLS && item && item.contact_id && <ContactCallsMode contact_id={item.contact_id} />}

                    {activeTab === TAB_INCOMING_CALLS && (
                        <Fragment>
                            <Button
                                onClick={this.handleToggleCallModal}
                                icon="phone"
                                labelPosition="left"
                                content="Прикрепить звонок"
                                size="tiny"
                                color="green"
                            />

                            {calls && calls.length > 0 && (
                                <Table size="small" style={{ marginTop: '1rem' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Дата</TableCell>
                                            <TableCell>Источник</TableCell>
                                            <TableCell>Клиент</TableCell>
                                            <TableCell />
                                            <TableCell>Сотрудник</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>

                                    <CellCallTableBody cellCallList={calls} hideEstate showSource />
                                </Table>
                            )}

                            {calls && calls.length === 0 && <WarningMessage>У данного объекта нет звонков</WarningMessage>}

                            <LoaderAwait active={loadingCalls} size="large" />
                        </Fragment>
                    )}
                </div>

                {showCallModal && (
                    <FindCallModal item_id={CREATING_ITEM_ID} estate_id={estate_id} handleClose={this.handleToggleCallModal} />
                )}
            </>
        );
    }
}

export default EstateCallsMode;
