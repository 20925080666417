import fetchApi from '~/common/fetchApi';

import {
    EstateStatInExportBase,
    ExportChartBaseCost,
    ExportRedeemBalanceFilter,
    ExportStat,
    ExportStatChartFilter,
    ExportStatListPult,
    UserBalanceReset
} from '~/types/exportStatistics.types';
import { objectToGraphql } from '~/common/graphql';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQEstateMainFields } from '~/api/estateApi';
import { GQUserMainFields } from '~/api/userApi';

export const saveItem = async (estate_id: number, exportingEstate: {}): Promise<number> => {
    return 0;
};

export const fetchItem = async (id: number): Promise<ExportStat> => {
    return await null;
};

const exportStatGQ = `
    exportStatByDay {
        date
        bases {
            export_base_id
            views
            phoneShows
            showsCount
        }
    }
    exportCost {
        totalCost
        totalPremium
        totalRedeemCost
        estatesCount
        export_base_id
    }
`;

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    filter: Partial<ExportStatListPult>,
    controller?: AbortController
): Promise<{ list: ExportStat[]; count: number; exportCost: ExportChartBaseCost[] }> => {
    const { activeBases, activeCharts, ...adjustedFilter } = filter;

    const graphql = `{ 
          fetchExportStatisticsList(filter: ${objectToGraphql(adjustedFilter)}) {
            ${exportStatGQ}      
          }
        }`;

    const data: {
        fetchExportStatisticsList: { exportStatByDay: ExportStat[]; exportCost: ExportChartBaseCost[] };
    } = await fetchApi.getGQ(graphql, controller);

    return {
        list: data.fetchExportStatisticsList.exportStatByDay,
        count: data.fetchExportStatisticsList.exportStatByDay.length,
        exportCost: data.fetchExportStatisticsList.exportCost
    };
};

export const fetchExportEstateStatistics = async (
    estate_id: number,
    filter: ExportStatChartFilter
): Promise<{ exportStatByDay: ExportStat[]; exportCost: ExportChartBaseCost[] }> => {
    const graphql = `{ 
          fetchExportEstateStatistics(estate_id: ${estate_id}, filter: ${objectToGraphql(filter)}) {
             ${exportStatGQ} 
          }
        }`;

    const data: {
        fetchExportEstateStatistics: { exportStatByDay: ExportStat[]; exportCost: ExportChartBaseCost[] };
    } = await fetchApi.getGQ(graphql);
    return data.fetchExportEstateStatistics;
};

export const fetchExportStatisticsByExportBase = async (
    export_base_id: number,
    filter: Partial<ExportStatListPult>
): Promise<EstateStatInExportBase[]> => {
    const { activeBases, activeCharts, ...adjustedFilter } = filter;

    const graphql = `{ 
          fetchExportStatisticsByExportBase(export_base_id: ${export_base_id}, filter: ${objectToGraphql(adjustedFilter)}) {
            estate_id
            estate {
                ${GQEstateMainFields}
            }
            totalCost
            daysCount
            major_user_ids
            major_users {
                ${GQUserMainFields}
            }
          }
        }`;

    const data: {
        fetchExportStatisticsByExportBase: EstateStatInExportBase[];
    } = await fetchApi.getGQ(graphql);
    return data.fetchExportStatisticsByExportBase;
};

export const fetchExportRedeemBalance = async (filter: ExportRedeemBalanceFilter): Promise<UserBalanceReset[]> => {
    const graphql = `{ 
          fetchExportRedeemBalance(filter: ${objectToGraphql(filter)}) {
            user_id
            user{
                ${GQUserMainFields}
            }
            resetBalance
            redeemTime
            major_user_id
            major_user {
                ${GQUserMainFields}
            }
          }
        }`;

    const data = await fetchApi.getGQ<{
        fetchExportRedeemBalance: UserBalanceReset[];
    }>(graphql);
    return data.fetchExportRedeemBalance;
};
