import React, { Component, Fragment } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import ButtonGroup, { ButtonInSet } from '~/components/Base/ButtonGroup';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { ESTATE_TYPE_RENT_ID } from '~/types/estate.types';

export const YES_NO_SET: ButtonInSet[] = [
    [false, 'нет'],
    [true, 'есть']
];

type ComfortBlockEditingProps = HandlerEditingWrappedProps & {
    type: number;
    hasInternet?: boolean | null;
    hasFurniture?: boolean | null;
    hasKitchenFurniture?: boolean | null;
    hasTv?: boolean | null;
    hasWasher?: boolean | null;
    hasConditioner?: boolean | null;
    hasBathtub?: boolean | null;
    hasShower?: boolean | null;
    hasDishwasher?: boolean | null;
    hasFridge?: boolean | null;
    petsAllowed?: boolean | null;
    childrenAllowed?: boolean | null;
};

@observer
class ComfortBlockEditing extends Component<ComfortBlockEditingProps> {
    constructor(props: ComfortBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: this.props.item_id,
            type: this.props.type,
            hasInternet: typeof this.props.hasInternet === 'boolean' ? this.props.hasInternet : null,
            hasFurniture: typeof this.props.hasFurniture === 'boolean' ? this.props.hasFurniture : null,
            hasKitchenFurniture: typeof this.props.hasKitchenFurniture === 'boolean' ? this.props.hasKitchenFurniture : null,
            hasTv: typeof this.props.hasTv === 'boolean' ? this.props.hasTv : null,
            hasWasher: typeof this.props.hasWasher === 'boolean' ? this.props.hasWasher : null,
            hasConditioner: typeof this.props.hasConditioner === 'boolean' ? this.props.hasConditioner : null,
            hasBathtub: typeof this.props.hasBathtub === 'boolean' ? this.props.hasBathtub : null,
            hasShower: typeof this.props.hasShower === 'boolean' ? this.props.hasShower : null,
            hasDishwasher: typeof this.props.hasDishwasher === 'boolean' ? this.props.hasDishwasher : null,
            hasFridge: typeof this.props.hasFridge === 'boolean' ? this.props.hasFridge : null,
            petsAllowed: typeof this.props.petsAllowed === 'boolean' ? this.props.petsAllowed : null,
            childrenAllowed: typeof this.props.childrenAllowed === 'boolean' ? this.props.childrenAllowed : null
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    render() {
        const {
            type,
            hasInternet,
            hasFurniture,
            hasKitchenFurniture,
            hasTv,
            hasWasher,
            hasConditioner,
            hasBathtub,
            hasShower,
            hasDishwasher,
            hasFridge,
            petsAllowed,
            childrenAllowed
        } = estateStore.getItem(this.props.item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline>
                        <Grid columns={3}>
                            <Grid.Column>
                                <label className="crm-Estate__field_label">Мебель</label>
                                <ButtonGroup
                                    buttonSet={YES_NO_SET}
                                    name="hasFurniture"
                                    value={hasFurniture}
                                    handleChange={this.props.handleChange}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <label className="crm-Estate__field_label">Кондиционер</label>
                                <ButtonGroup
                                    buttonSet={YES_NO_SET}
                                    name="hasConditioner"
                                    value={hasConditioner}
                                    handleChange={this.props.handleChange}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <label className="crm-Estate__field_label">Кухонная мебель</label>
                                <ButtonGroup
                                    buttonSet={YES_NO_SET}
                                    name="hasKitchenFurniture"
                                    value={hasKitchenFurniture}
                                    handleChange={this.props.handleChange}
                                />
                            </Grid.Column>

                            {type === ESTATE_TYPE_RENT_ID && (
                                <>
                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Интернет</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasInternet"
                                            value={hasInternet}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">ТВ</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasTv"
                                            value={hasTv}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Холодильник</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasFridge"
                                            value={hasFridge}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Посудомоечная машина</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasDishwasher"
                                            value={hasDishwasher}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Есть ванная</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasBathtub"
                                            value={hasBathtub}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Душевая кабина</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasShower"
                                            value={hasShower}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Стиральная машина</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="hasWasher"
                                            value={hasWasher}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Можно с детьми</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="childrenAllowed"
                                            value={childrenAllowed}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label className="crm-Estate__field_label">Можно с животными</label>
                                        <ButtonGroup
                                            buttonSet={YES_NO_SET}
                                            name="petsAllowed"
                                            value={petsAllowed}
                                            handleChange={this.props.handleChange}
                                        />
                                    </Grid.Column>
                                </>
                            )}
                        </Grid>
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, ComfortBlockEditing);
