import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import CianApiSettingsField from './Fields/CianApiSettingsField';
import { CIAN_API_KEY } from '~/types/settings.types';

import ApiSettingsWrapper from './ApiSettingsWrapper';

const CianApiSettings = () => {
    return (
        <ApiSettingsWrapper apiKey={CIAN_API_KEY} FieldComponent={CianApiSettingsField} defaultSettingsValue={{ apiKey: '' }}>
            <Typography>
                Для работы с API необходимо получить ключ авторизации. Для получения ключа нужно написать письмо на&nbsp;
                <a href="mailto:import@cian.ru" target="_blank" rel="noreferrer">
                    import@cian.ru
                </a>
                &nbsp;с темой "ACCESS KEY" и названием агентства, которое вы представляете. Менеджер уточнит подробности и пришлет ACCESS
                KEY. Для каждой учетной записи на ЦИАН выдается отдельный ключ авторизации.
            </Typography>
            <a href="https://public-api.cian.ru/docs/latest" target="_blank" rel="noreferrer">
                источник
            </a>
        </ApiSettingsWrapper>
    );
};

export default observer(CianApiSettings);
