import { ESTATE_BASES, ShareEstateId } from '~/types/estate.types';
import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { SmaEstateItemType, SmaOptionsType } from '~/types/estateSma.types';
import { GQMetroFiled } from './estateApi';
import { GQNewBuildingFields } from './newbuildingsApi';

const GQSmaEstateFields = `
    estate_id
    base
    type
    address
    houseNumberAdjusted
    roomsCount

    propertyType
    enable
    description
    metros {
        ${GQMetroFiled}
    }
    main_metro_id
    cityDistrict
    cityDistrictType
    
    totalArea
    livingArea
    kitchenArea

    allRoomsArea
    floorNumber
    floorsCount
    materialType

    createTime
    updateTime
    price
    currency
    photosCount
    
    buildYear
    buildingIsNotReadyYet

    source
    sourceInnerId

    newbuilding_id
    newbuilding {
        ${GQNewBuildingFields}
    }

    landArea
    landAreaUnitType
    
    isAlreadySold
    isFavorite
    isHidden
    isBooked
    
    externalUrlAvailable
    priceInSma
    createTimeInSma
    updateTimeInSma
`;

export const fetchItemList = async (estate_id: number): Promise<SmaEstateItemType[]> => {
    const graphql = `{
        fetchSMAEstates(estate_id: ${estate_id}) {
            ${GQSmaEstateFields}
        }
      }`;

    const data: { fetchSMAEstates: SmaEstateItemType[] } = await fetchApi.getGQ(graphql);
    return data.fetchSMAEstates;
};

export const toggleEstateToSma = async (
    sma_estate_id: number,
    estate_id: number,
    base: ESTATE_BASES,
    enable: boolean
): Promise<boolean> => {
    const graphql = `mutation {
        toggleEstateToSma(sma_estate_id: ${sma_estate_id}, estate_id: ${estate_id}, base: ${base}, enable: ${String(enable)})
      }`;

    const data: { toggleEstateToSma: boolean } = await fetchApi.postGQ(graphql);
    return data.toggleEstateToSma;
};

type EnableSmaEditingParams = {
    enable: boolean;
    price: number;
};

export const updateListItem = async (
    estate_id: number,
    editingId: ShareEstateId,
    editingItem: Partial<EnableSmaEditingParams>
): Promise<boolean> => {
    const graphql = `mutation {
        updateSMAItem(sma_estate_id: ${estate_id}, editingId: ${objectToGraphql(editingId)}, smaEstateDiff: ${objectToGraphql(editingItem)})
      }`;

    const data: { updateSMAItem: boolean } = await fetchApi.postGQ(graphql);
    return data.updateSMAItem;
};

export const fetchOptions = async (estate_id: number): Promise<SmaOptionsType> => {
    const graphql = `{
        fetchSMAOptions(estate_id: ${estate_id}) {
            sma_id
            uuid
            estate_id
            user_id
            createTime
            updateTime
            enable
        }
      }`;

    const { fetchSMAOptions } = await fetchApi.getGQ<{ fetchSMAOptions: SmaOptionsType }>(graphql);
    return fetchSMAOptions;
};

export const updateListOptions = async (sma_estate_id: number, smaOptions: Partial<SmaOptionsType>): Promise<boolean> => {
    const graphql = `mutation {
        updateSmaOptions(sma_estate_id: ${sma_estate_id}, smaOptionsDiff: ${objectToGraphql(smaOptions)})
      }`;

    const { updateSmaOptions } = await fetchApi.postGQ<{ updateSmaOptions: boolean }>(graphql);
    return updateSmaOptions;
};
