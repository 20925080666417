import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ShowingsTableBody from './ShowingsTableBody';
import ListFooter from '../Common/ListFooter';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import ShowingsTableHeader from './ShowingsTableHeader';
import showingStore from '~/stores/showingStore';
import FilterShowings from './FilterShowings';
import authStore from '~/stores/authStore';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

@observer
class ShowingsList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, pagination, listErrors, nextListLoaded } = showingStore;

        return (
            <Fragment>
                <div className="crm-List">
                    <Header as="h3">
                        <Icon name="compass" />
                        Показы
                    </Header>

                    {authStore.canCreate('showing') && (
                        <div className="crm-List__addButton">
                            <Button
                                icon
                                labelPosition="left"
                                primary
                                size="tiny"
                                as={Link}
                                to={{
                                    pathname: `/showings/list/overview/${CREATING_ITEM_ID}`,
                                    state: {
                                        modal: true,
                                        callback: { storeName: 'showingStore', method: 'fetchList' }
                                    }
                                }}
                            >
                                <Icon name="compass" /> Добавить
                            </Button>
                        </div>
                    )}

                    {listErrors && <ListError errors={listErrors} />}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterShowings />

                    <Segment className="crm-List__segment">
                        <Table structured size="small" sortable compact>
                            <ShowingsTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <ShowingsTableBody showingsList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={3}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={showingStore.pageChange}
                                pageSizeChange={showingStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(showingStore, ShowingsList);
