import React from 'react';

import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import taskStore from '~/stores/taskStore';

const taskKeys = {};

const printTaskVal = (key: string, value: unknown): string => {
    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(taskStore, taskKeys, printTaskVal);
