import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { ESTATE_BASES } from '~/types/estate.types';
import { EstatePreviewItem } from '~/components/Items/Estate/EstatePreview';
import LoadingItemPreviewContent from '~/components/Items/Common/ItemPreview/LoadingItemPreviewContent';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

type EstatePreviewProps = {
    estate_id: number;
    base: ESTATE_BASES;
};

@observer
export class EstatePreviewMap extends Component<EstatePreviewProps> {
    constructor(props: EstatePreviewProps) {
        super(props);
        estateStore.fetchItem(props.estate_id, props.base);
    }

    handleMouseEnter = () => {
        estateStore.highlightActiveEstateIdOnMap(this.props.estate_id, this.props.base);
    };

    handleMouseLeave = () => {
        estateStore.disableEstateHighlight();
    };

    render() {
        const { estate_id, base } = this.props;
        const { loadingItem } = estateStore.getItem(estate_id);
        if (loadingItem) {
            return (
                <Card>
                    <CardContent>
                        <LoadingItemPreviewContent />
                    </CardContent>
                </Card>
            );
        }

        return (
            <EstatePreviewItem
                item_id={estate_id}
                base={base}
                handleClose={() => {}}
                onImgLoad={() => {}}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            />
        );
    }
}

@observer
class EstateMapList extends Component<{}> {
    render() {
        const { selectedMapIds } = estateStore;

        return (
            <Fragment>
                {selectedMapIds.map(({ estate_id, base }) => (
                    <EstatePreviewMap base={base} estate_id={estate_id} key={estate_id} />
                ))}
            </Fragment>
        );
    }
}

export default EstateMapList;
