import React, { Component, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { HandleChangeType } from '../../HandlerEditingBlockWrapper';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';

type ItemDropdownProps = {
    store: ListStoreInterface;
    item_id?: number;
    onChange: HandleChangeType;
    upward?: boolean;
    placeholder: string;
    noResultsMessage: string;
    name: string;
    clearable?: boolean;
    direction?: 'left' | 'right';
    loading?: boolean;
};

type ItemDropdownState = {
    item_id: number | null;
};

@observer
class ItemDropdown extends Component<ItemDropdownProps, ItemDropdownState> {
    constructor(props: ItemDropdownProps) {
        super(props);

        this.state = {
            item_id: props.item_id || null
        };

        props.store.changeItemDropdownSearch('');
    }

    static getDerivedStateFromProps(nextProps: ItemDropdownProps) {
        return {
            item_id: nextProps.item_id || null
        };
    }

    handleItemId = (event: React.SyntheticEvent, data: { type: string; name: string; value: string; array?: string; index?: number }) => {
        this.props.onChange(event, data);
        this.setState({
            item_id: Number(data.value)
        });
        if (!data.value) {
            this.props.store.changeItemDropdownSearch('');
        }
    };

    handleSearchChange = (event: React.SyntheticEvent, { searchQuery }: { searchQuery: string }) => {
        this.props.store.changeItemDropdownSearch(searchQuery);
    };

    render() {
        const { store, placeholder, noResultsMessage, upward, name, clearable, direction, loading } = this.props;
        const { itemDropdownOptions, loadingDropdownOptions } = store;
        const { item_id } = this.state;

        return (
            <Dropdown
                name={name}
                upward={upward}
                fluid
                selection
                options={itemDropdownOptions}
                search
                placeholder={placeholder}
                value={item_id}
                noResultsMessage={noResultsMessage}
                loading={loading || loadingDropdownOptions}
                onSearchChange={this.handleSearchChange}
                onChange={this.handleItemId}
                required
                clearable={Boolean(clearable)}
                className={direction ? 'crm-Item__floatingDropdown' : ''}
                direction={direction}
                type={clearable ? 'clearable' : null}
            />
        );
    }
}

export default ItemDropdown;
