import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Grid, Dropdown } from 'semantic-ui-react';
import EditField from '../../../Base/EditField';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { Estate, ESTATE_PROPERTY_TYPE_HOUSE_PART, ESTATE_PROPERTY_TYPE_TOWNHOUSE } from '~/types/estate.types';

type CountryHouseEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    shareAmount?: string | null;
    totalArea?: number | null;
    bedroomsCount?: number | null;
    wcLocationType?: number | null;
    repairType?: number | null;

    floorsCount?: number | null;
    buildYear?: number | null;
    materialType?: number | null;
    heatingType?: number | null;

    hasPhone?: boolean | null;
    hasSecurity?: boolean | null;
    hasBathhouse?: boolean | null;
    hasGarage?: boolean | null;
    hasPool?: boolean | null;
};

export const COUNTRY_INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasBathhouse', 'баня'],
    ['hasSecurity', 'охрана'],
    ['hasGarage', 'гараж'],
    ['hasPhone', 'телефон'],
    ['hasPool', 'бассейн']
];

@observer
class CountryHouseEditing extends Component<CountryHouseEditingProps> {
    constructor(props: CountryHouseEditingProps) {
        super(props);

        estateStore.setEditingItem(props.item_id, {
            propertyType: props.propertyType,
            shareAmount: props.shareAmount || null,
            totalArea: props.totalArea || null,
            bedroomsCount: props.bedroomsCount || null,
            wcLocationType: props.wcLocationType || null,
            repairType: props.repairType || null,

            floorsCount: props.floorsCount || null,
            buildYear: props.buildYear || null,
            materialType: props.materialType || null,
            heatingType: props.heatingType || null,

            hasPhone: props.hasPhone || null,
            hasSecurity: props.hasSecurity || null,
            hasBathhouse: props.hasBathhouse || null,
            hasGarage: props.hasGarage || null,
            hasPool: props.hasPool || null
        });
    }

    render() {
        const { item_id, handleChange, handleCheckbox } = this.props;
        const { editingItem } = estateStore.getItem(item_id);
        const {
            shareAmount,
            buildYear,
            materialType,
            floorsCount,
            bedroomsCount,
            wcLocationType,
            heatingType,
            totalArea,
            repairType,
            propertyType
        } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                {[ESTATE_PROPERTY_TYPE_TOWNHOUSE, ESTATE_PROPERTY_TYPE_HOUSE_PART].includes(propertyType) && (
                    <EditField.Text label="Часть дома" onChange={handleChange} value={shareAmount} name="shareAmount" />
                )}

                <EditField.InputNumber
                    label="Год постройки"
                    onChange={handleChange}
                    min={1}
                    max={2100}
                    value={buildYear}
                    name="buildYear"
                />

                <EditField.Drop
                    onChange={handleChange}
                    label="Тип дома"
                    name="materialType"
                    value={materialType}
                    options={estateL10n.COUNTRY_MATERIAL_TYPE_DROPDOWN}
                />

                <EditField.InputNumber
                    label="Общая площадь"
                    onChange={handleChange}
                    min={1}
                    max={999999}
                    value={totalArea}
                    name="totalArea"
                    step={0.1}
                    extraLabel="м²"
                    required
                />

                <EditField.InputNumber
                    label="Этажей в доме"
                    onChange={handleChange}
                    min={0}
                    max={10}
                    value={floorsCount}
                    name="floorsCount"
                    required
                />

                <EditField.InputNumber
                    label="Количество спален"
                    onChange={handleChange}
                    min={0}
                    max={40}
                    value={bedroomsCount}
                    name="bedroomsCount"
                />

                <EditField.ButtonGroup
                    label="Санузел"
                    buttonSet={estateL10n.WC_COUNTRY_TYPE}
                    name="wcLocationType"
                    value={wcLocationType}
                    handleChange={handleChange}
                />

                <EditField.Drop
                    onChange={handleChange}
                    label="Отопление"
                    name="heatingType"
                    value={heatingType}
                    options={estateL10n.COUNTRY_HEATING_TYPE_DROPDOWN}
                />

                <EditField.ButtonGroup
                    label="Ремонт"
                    buttonSet={estateL10n.REPAIR_TYPES}
                    name="repairType"
                    value={repairType}
                    handleChange={handleChange}
                />

                <Grid columns={3}>
                    {COUNTRY_INFRASTURCTURE_FIELDS.map(field => (
                        <Grid.Column key={field[0]}>
                            <Checkbox
                                checked={Boolean(editingItem[field[0]])}
                                name={field[0]}
                                label={<label>{field[1]}</label>}
                                onChange={handleCheckbox}
                            />
                        </Grid.Column>
                    ))}
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, CountryHouseEditing);
