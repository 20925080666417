import React, { Fragment, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import authStore from '~/stores/authStore';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import UserSettingsModal from './UserSettingsModal';
import CRMChangesModal from './CRMChangesModal';
import history from '../../history';
import { UserLinkState } from '../Lists/Users/UserLink';
import UserAvatar from '~ui/UserAvatar';

type SettingsDropdownState = {
    showingChangeSettings: boolean;
    showingCRMChangeModal: boolean;
    anchorEl: null | HTMLElement;
};

@observer
export default class SettingsDropdown extends React.PureComponent<{}, SettingsDropdownState> {
    state = {
        showingChangeSettings: false,
        showingCRMChangeModal: false,
        showingMenu: false,
        anchorEl: null
    };

    handleLogOut = () => {
        authStore.logout();
    };

    handleSettingsToggle = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        this.setState({
            showingChangeSettings: !this.state.showingChangeSettings,
            anchorEl: null
        });
    };

    handleCrmChangeModalClose = () => {
        this.setState({
            showingCRMChangeModal: !this.state.showingCRMChangeModal,
            anchorEl: null
        });
    };

    handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleGoToMyProfile = () => {
        history.push(UserLinkState(authStore.currentUser.user_id));
        this.handleClose();
    };

    render() {
        const { currentUser } = authStore;
        const { showingChangeSettings, showingCRMChangeModal, anchorEl } = this.state;

        return (
            <Fragment>
                <div className="crm-Main__hiddenMobile">
                    <Button onClick={this.handleOpen}>
                        <UserAvatar
                            src={currentUser.avatarUrl}
                            size={24}
                            firstName={currentUser?.firstName}
                            lastName={currentUser?.lastName}
                        />
                        &nbsp;
                        {currentUser.firstName}
                    </Button>

                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleClose}>
                        <MenuItem onClick={this.handleGoToMyProfile}>
                            <Icon name="user" /> Мой профиль
                        </MenuItem>
                        <MenuItem onClick={this.handleSettingsToggle}>
                            <Icon name="settings" /> Личные настройки
                        </MenuItem>
                        <MenuItem onClick={this.handleCrmChangeModalClose}>
                            <Icon name="history" /> История изменений
                        </MenuItem>

                        <MenuItem onClick={this.handleLogOut}>
                            <Icon name="log out" /> Выйти
                        </MenuItem>
                    </Menu>
                </div>

                {showingChangeSettings && <UserSettingsModal onClose={this.handleSettingsToggle} />}
                {showingCRMChangeModal && <CRMChangesModal onClose={this.handleCrmChangeModalClose} />}
            </Fragment>
        );
    }
}
