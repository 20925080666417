import React, { Component, Fragment } from 'react';
import { Loader, Table, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import PreventDblClick from '../../Base/PreventDblClick';
import ownerStore from '~/stores/ownerStore';
import { CALL_TYPE_INCOMING } from '~/types/cellCalls.types';
import { secondsToMS } from '~/common/time';
import UserLinkWithPreview from '../../Lists/Users/UserLinkWithPreview';
import Time from '../../Base/Time';
import DownloadLink from '../../Lists/CellCalls/SubBlocks/DownloadLink';
import { InfoMessage, WarningMessage } from '~ui/Message';

type OwnersOutgoingModeProps = {
    estate_id: number;
};

@observer
class OwnersOutgoingCallsMode extends Component<OwnersOutgoingModeProps> {
    constructor(props: OwnersOutgoingModeProps) {
        super(props);

        ownerStore.fetchItem(props.estate_id);
        ownerStore.callsByOwner(props.estate_id);
    }

    render() {
        const { estate_id } = this.props;
        const { calls, loadingCalls } = ownerStore.getItem(estate_id).property;

        return (
            <PreventDblClick>
                <InfoMessage>
                    Исходящие звонки на <b>номер</b> данного собственника за <b>14 дней</b>
                </InfoMessage>

                <div>
                    {calls && calls.length > 0 && (
                        <Table celled size="small">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Дата</Table.HeaderCell>
                                    <Table.HeaderCell>Сотрудник</Table.HeaderCell>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {calls.map(
                                    ({
                                        call_id,
                                        abonent,
                                        direction,
                                        downloadLink,
                                        externalId,
                                        startTime,
                                        answerTime,
                                        releaseTime,
                                        major_user,
                                        outer_id
                                    }) => (
                                        <Table.Row key={call_id}>
                                            <Table.Cell>
                                                <Time time={startTime} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                {major_user && (
                                                    <Fragment>
                                                        <UserLinkWithPreview user={major_user} />
                                                    </Fragment>
                                                )}
                                                {!major_user && abonent}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    name={direction === CALL_TYPE_INCOMING ? 'arrow right' : 'arrow left'}
                                                    title={direction === CALL_TYPE_INCOMING ? 'Входящий звонок' : 'Исходящий звонок'}
                                                    color={direction === CALL_TYPE_INCOMING ? 'green' : 'red'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                {answerTime && releaseTime && (
                                                    <Fragment>
                                                        <DownloadLink
                                                            call_id={call_id}
                                                            externalId={externalId}
                                                            outer_id={outer_id}
                                                            downloadLink={downloadLink}
                                                            answerTime={answerTime}
                                                        />
                                                        <span>{secondsToMS(Math.round((releaseTime - answerTime) / 1000))}</span>
                                                    </Fragment>
                                                )}
                                                {answerTime && !releaseTime && (
                                                    <Fragment>
                                                        <Icon name="phone" color="yellow" /> разговор
                                                    </Fragment>
                                                )}
                                                {!answerTime && !releaseTime && (
                                                    <Fragment>
                                                        <Icon name="volume control phone" color="green" /> звонок
                                                    </Fragment>
                                                )}
                                                {!answerTime && releaseTime && (
                                                    <Fragment>
                                                        <Icon name="close" color="red" /> без ответа{' '}
                                                        <span style={{ color: 'red' }}>
                                                            ({secondsToMS(Math.round((releaseTime - startTime) / 1000))})
                                                        </span>
                                                    </Fragment>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                )}
                            </Table.Body>
                        </Table>
                    )}

                    {calls && calls.length === 0 && <WarningMessage>Звонки не найдены</WarningMessage>}

                    {loadingCalls && <Loader active size="large" />}
                </div>
            </PreventDblClick>
        );
    }
}

export default OwnersOutgoingCallsMode;
