import React from 'react';
import wishStore from '~/stores/wishStore';
import settingStore from '~/stores/settingStore';

import Button from '~ui/Button';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';

type WishIsNotActuallyButtonProps = {
    wish_id: number;
    disabled: boolean;
};

const WishExtendBookingButton = ({ wish_id, disabled }: WishIsNotActuallyButtonProps) => {
    const handleToggleIsAlreadySold = () => {
        wishStore.takeWishToWork(wish_id);
    };

    const { isBooked } = wishStore.getItem(wish_id).item;
    if (isBooked.expireTime === null) {
        return null;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const currentExpireTime = currentTime + settingStore?.mainConfig?.wishBookingExpirationDays * 24 * 3600;

    let isTooYearlyToExtend = false;
    if (currentExpireTime - isBooked.expireTime < 3600) {
        isTooYearlyToExtend = true;
    }

    return (
        <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={handleToggleIsAlreadySold}
            disabled={disabled || isTooYearlyToExtend}
            startIcon={<AccessAlarmsIcon />}
        >
            Продлить бронь
        </Button>
    );
};

export default WishExtendBookingButton;
