import React, { Component, Fragment } from 'react';
import { ReactNode } from 'react';
import { Swipeable, EventData } from 'react-swipeable';

export const handlePreventSwipe = ({ event }: EventData) => {
    event.stopPropagation();
    event.preventDefault();
};

const PreventSwipe = ({ children }: { children: ReactNode }) => (
    <Swipeable
        onSwiped={handlePreventSwipe}
        onSwipedUp={handlePreventSwipe}
        onSwipedDown={handlePreventSwipe}
        onSwipedLeft={handlePreventSwipe}
        onSwipedRight={handlePreventSwipe}
        trackMouse
    >
        {children}
    </Swipeable>
);

export default PreventSwipe;
