import React, { Component } from 'react';
import { observer } from 'mobx-react';

import GroupsDropdown from '~/components/Lists/Users/GroupsDropdown';
import userStore from '~/stores/userStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import accessStore from '~/stores/accessStore';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';
import DatePicker from '~ui/DatePicker';
import Select from '~ui/Select';
import Button from '~ui/Button';

type UserInfoBlockEditiongProps = HandlerEditingWrappedProps & {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    user_id?: number;
    birthday?: number;
    group_id?: number;
    access_id?: number;
};

@observer
class UserInfoEditing extends Component<UserInfoBlockEditiongProps> {
    constructor(props) {
        super(props);

        accessStore.fetchList();

        const user = {
            birthday: props.birthday || null,
            lastName: props.lastName || '',
            middleName: props.middleName || '',
            firstName: props.firstName || '',
            group_id: props.group_id || 0,
            access_id: props.access_id || 0,
            appointment: props.appointment || '',
            hiddenDescription: props.hiddenDescription || '',
            dismissalTime: props.dismissalTime || null,
            recruitmentTime: props.recruitmentTime || null,
            enable: props.enable || false
        };
        userStore.setEditingItem(props.item_id, user);
    }

    // changeBirthday = (time: number) => {
    //     console.log('return', time);
    //     // userStore.setEditingItem(this.props.item_id, {
    //     //     birthday: time * 1000
    //     // });
    // };

    changeGroupId = (group_id: number | null) => {
        userStore.setEditingItem(this.props.item_id, {
            group_id
        });
    };

    handleResetPassword = () => {
        userStore.resetPassword(this.props.item_id);
    };

    render() {
        const { item_id, handleChange, editing, setUnixTime } = this.props;

        const {
            birthday,
            firstName,
            lastName,
            middleName,
            group_id,
            access_id,
            appointment,
            hiddenDescription,
            dismissalTime,
            recruitmentTime,
            enable
        } = userStore.getItem(item_id).editingItem;

        const { list, loadingList } = accessStore;

        const accessOptions = list.map(({ access_id, title }) => ({ key: access_id, value: access_id, text: title }));

        return (
            <>
                <Grid columns={2} stackable disableGutter alignItems="flex-start">
                    <Grid.Column>
                        <TextInput
                            name="lastName"
                            variant="classic"
                            size="small"
                            label="Фамилия"
                            value={lastName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            name="firstName"
                            variant="classic"
                            size="small"
                            label="Имя"
                            value={firstName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            name="middleName"
                            variant="classic"
                            size="small"
                            label="Отчество"
                            value={middleName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DatePicker
                            label="День Рождения"
                            value={typeof birthday === 'number' ? new Date(birthday) : birthday}
                            openTo="year"
                            onChange={timeSec => this.props.handleDate('birthday', timeSec)}
                            maxDate={new Date()}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <DatePicker label="В штате с" value={recruitmentTime} onChange={time => setUnixTime('recruitmentTime', time)} />
                    </Grid.Column>
                    {!enable && editing && (
                        <Grid.Column>
                            <DatePicker
                                label="Дата увольнения"
                                value={dismissalTime}
                                onChange={time => setUnixTime('dismissalTime', time)}
                            />
                        </Grid.Column>
                    )}
                </Grid>
                <Grid columns={1} stackable disableGutter>
                    <Grid.Column>
                        <GroupsDropdown group_id={group_id} onChange={this.changeGroupId} />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            name="appointment"
                            variant="classic"
                            size="small"
                            label="Должность"
                            value={appointment}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid.Column>
                </Grid>
                <Grid columns={editing ? 2 : 1} stackable disableGutter>
                    <Grid.Column>
                        <Select
                            label="Доступ"
                            loading={loadingList}
                            options={accessOptions}
                            value={access_id}
                            name="access_id"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid.Column>
                    {editing && (
                        <Grid.Column>
                            <Button size="small" variant="outlined" onClick={this.handleResetPassword} style={{ marginLeft: '50px' }}>
                                Сбросить пароль
                            </Button>
                        </Grid.Column>
                    )}
                </Grid>
                <Grid columns={1} stackable>
                    <Grid.Column>
                        <TextInput
                            label="Скрытое описание"
                            value={hiddenDescription}
                            name="hiddenDescription"
                            onChange={handleChange}
                            multiline
                            rowsMax={10}
                            rows={4}
                            variant="classic"
                            fullWidth
                        />
                    </Grid.Column>
                </Grid>
            </>
        );
    }
}

export default HandlerEditingBlockWrapper(userStore, UserInfoEditing);
