import React, { ReactNode, PureComponent, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import getElementType from 'semantic-ui-react/src/lib/getElementType';
import Confirm from './Confirm';

type ConfirmTriggerProps = {
    as?: any;
    trigger: ReactNode;
    content: string;
    header: string;
    confirmButton: string;
    onConfirm: () => Promise<void>;
    disabled?: boolean;
};

type ConfirmTriggerState = {
    showConfirm: boolean;
    loading: boolean;
};

class ConfirmTrigger extends PureComponent<ConfirmTriggerProps, ConfirmTriggerState> {
    state = {
        showConfirm: false,
        loading: false
    };

    handleToggleConfirm = () => {
        this.setState({ showConfirm: !this.state.showConfirm });
    };

    handleConfirm = async () => {
        this.setState({ loading: true });
        await this.props.onConfirm();
        this.setState({ showConfirm: false, loading: false });
    };

    render() {
        const { trigger, header, confirmButton, content, onConfirm, disabled } = this.props;
        const { showConfirm, loading } = this.state;

        const ElementType = getElementType(Button, this.props, () => 'span');

        return (
            <Fragment>
                <ElementType onClick={!disabled ? this.handleToggleConfirm : null}>{trigger}</ElementType>

                {showConfirm && (
                    <Confirm
                        header={header}
                        content={content}
                        confirmButton={loading ? 'Загрузка...' : confirmButton}
                        onCancel={!loading ? this.handleToggleConfirm : null}
                        onConfirm={!loading ? this.handleConfirm : null}
                    />
                )}
            </Fragment>
        );
    }
}

export default ConfirmTrigger;
