import React from 'react';
import { UserLinksProps } from './UserLink';
import UserPreview from '../../Items/User/UserPreview';
import UserLink from './UserLink';

const UserLinkWithPreview = ({ user, icon, target, avatar }: UserLinksProps) => (
    <UserPreview item_id={user.user_id} trigger={<UserLink user={user} icon={icon} target={target} avatar={avatar} />} />
);

export default UserLinkWithPreview;
