import React, { Component, Fragment } from 'react';
import { Input, Dropdown, Radio, Loader, Dimmer, Button, Form } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { MetroDistance } from '~/types/estate.types';
import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

type MetrosBlockEditingProps = HandlerEditingWrappedProps & {
    metros?: Array<MetroDistance>;
    main_metro_id?: number;
};

@observer
class MetrosBlockEditing extends Component<MetrosBlockEditingProps> {
    constructor(props: MetrosBlockEditingProps) {
        super(props);

        estateStore.fetchMetroStations();

        const estate = {
            estate_id: props.item_id,
            metros: props.metros || [],
            main_metro_id: props.main_metro_id || 0
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    handleMetroChange = (event: React.SyntheticEvent, { name, value, id, type }: any) => {
        estateStore.editMetroList(this.props.item_id, id, name, type === 'number' ? Number(value) : value);
    };

    handleMetroIdChange = async (event: React.SyntheticEvent, { value, id }: { id: number; value: number }) => {
        const { metroStationsList } = estateStore;
        const { item_id } = this.props;

        const metro = metroStationsList.find(({ metro_id }) => metro_id === value);

        if (metro) {
            await estateStore.editMetroList(item_id, id, 'metro_id', value);
            await estateStore.editMetroList(item_id, id, 'color', metro.color);
            await estateStore.editMetroList(item_id, id, 'name', metro.name);

            const { main_metro_id, metros } = estateStore.getItem(this.props.item_id).editingItem;
            if (!metros.some(({ metro_id }) => main_metro_id === metro_id)) {
                estateStore.setEditingItem(item_id, { main_metro_id: value });
            }
        }
    };

    removeMetro = (event: React.SyntheticEvent, { id }: { id: number }) => {
        event.preventDefault();
        estateStore.removeMetroFromList(this.props.item_id, id);
    };

    handleAddMetro = (event: React.SyntheticEvent) => {
        event.preventDefault();
        estateStore.addMetroToList(this.props.item_id);
    };

    render() {
        const { loadingMetros, metroStationsListLoading, metroStationsListDropdown } = estateStore;
        const { main_metro_id, metros } = estateStore.getItem(this.props.item_id).editingItem;

        return (
            <div className="crm-Estate__metroList">
                <div>
                    <label className="crm-Estate__field_label">Станции метро</label>
                    <Button
                        icon="plus"
                        size="mini"
                        title="Добавить станцию метро"
                        color="green"
                        disabled={metros.length === 3}
                        onClick={this.handleAddMetro}
                    />
                </div>

                {loadingMetros && (
                    <Dimmer active inverted>
                        <Loader active size="medium" />
                    </Dimmer>
                )}

                {metros.map(({ metro_id, name, color, transportType, time }: MetroDistance, i: number) => (
                    <div className="crm-Estate__metroList_item" key={i}>
                        <Button id={i} icon="remove" onClick={this.removeMetro} size="mini" />
                        <div className="crm-Estate__metroList_item_mainRadio" style={{ background: `#${color || ''}` }}>
                            <Radio
                                onClick={(a0: any, a1: any) => this.props.handleChange(a0, a1)}
                                name="main_metro_id"
                                value={metro_id}
                                checked={main_metro_id === metro_id}
                            />
                        </div>

                        {/*<Input readOnly id={i} size="mini" placeholder="название" defaultValue={name} name="name" onChange={() => {}} />*/}

                        <div className="ui mini form">
                            <Dropdown
                                selection
                                search
                                loading={metroStationsListLoading}
                                value={metro_id}
                                options={metroStationsListDropdown}
                                id={i}
                                onChange={this.handleMetroIdChange}
                            />
                        </div>

                        <Input
                            type="number"
                            size="mini"
                            label={
                                <Dropdown
                                    options={estateL10n.TRANSPORT_TYPES_DROPDOWN}
                                    id={i}
                                    name="transportType"
                                    value={transportType}
                                    onChange={this.handleMetroChange}
                                />
                            }
                            labelPosition="right"
                            placeholder="до метро"
                            name="time"
                            id={i}
                            value={time}
                            onChange={this.handleMetroChange}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, MetrosBlockEditing);
