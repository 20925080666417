import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import ToggleButton from '@material-ui/lab/ToggleButton';

import DateRangeSelect, { DATES_RANGE_VALUE } from '~/components/Base/ui/DateRangeSelect';
import { StyledToggleButtonGroup, PultDivider, Pult } from '~ui/FilterPult';
import userStatisticsStore from '~/stores/userStatisticsStore';

const UserStatisticsPult = ({ user_id }: { user_id: number }) => {
    const {
        options,
        filter: { kpi_id, deltaTimeSec }
    } = userStatisticsStore.getItemList(user_id);

    useMemo(() => {
        if (options && options.userKpis.length > 0) {
            // при загрузке options включаем первывй kpi на пульте
            userStatisticsStore.setListFilterValue(user_id, 'kpi_id', options.userKpis[0].kpi_id);
        }
    }, [options]);

    const handleKpiId = (event, newKpiId: number) => {
        if (newKpiId) {
            // Если повторно нажать на toggleButton, то прилетит null
            userStatisticsStore.listFilterChange(user_id, 'kpi_id', newKpiId);
        }
    };

    const handleTimesChange = ({ startTime, endTime, deltaTime }: DATES_RANGE_VALUE) => {
        userStatisticsStore.listFilterChange(user_id, 'deltaTimeSec', deltaTime);
        userStatisticsStore.listFilterChange(user_id, 'fromTimeSec', startTime);
        userStatisticsStore.listFilterChange(user_id, 'toTimeSec', endTime);
    };

    return (
        <Pult>
            <StyledToggleButtonGroup exclusive size="small" value={kpi_id} onChange={handleKpiId}>
                {options &&
                    options.userKpis.map(({ kpi_id, title }) => (
                        <ToggleButton key={kpi_id} value={kpi_id}>
                            {title}
                        </ToggleButton>
                    ))}
            </StyledToggleButtonGroup>

            <PultDivider />

            <DateRangeSelect onChange={handleTimesChange} initialValue={deltaTimeSec} />
        </Pult>
    );
};

export default observer(UserStatisticsPult);
