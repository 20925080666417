import React, { useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';

import TextInput, { NumberInput, PriceInput } from '~ui/TextInput';
import Grid from '~ui/Grid';
import Checkbox from '~ui/Checkbox';
import Select from '~ui/Select';
import mortgageRequestStore from '~/stores/mortgageRequest';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import { checkFormValid } from '~/common/forms';
import Button from '~ui/Button';
import LoaderAwait from '~/components/Base/LoaderAwait';
import ListError from '~/components/ListError';

const CalculationMortgageEditing = ({ item_id, handleChange, handleCheckbox }: HandlerEditingWrappedProps) => {
    useMemo(() => {
        mortgageRequestStore.fetchItem(item_id);
    }, []);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSave = () => {
        if (checkFormValid(formRef.current, true)) {
            mortgageRequestStore.saveItem(item_id);
        }
    };

    const { editingItem, loadingItem, errors } = mortgageRequestStore.getItem(item_id);
    const {
        proof_of_income,
        insurance_scheme,
        credit_type,
        apartment_type,
        customer_type,
        credit_term,
        initial_fee,
        apartment_price,
        credit_amount,
        has_maternal_capital,
        comment
    } = editingItem;

    useEffect(() => {
        if (initial_fee && apartment_price) {
            const deltaFee = apartment_price - initial_fee;
            if (deltaFee > 0) {
                mortgageRequestStore.setEditingItem(item_id, { credit_amount: deltaFee });
            }
        }
    }, [initial_fee, apartment_price]);

    return (
        <form ref={formRef}>
            <LoaderAwait dimmer active={loadingItem} immediately />
            <ListError errors={errors} />

            <Container maxWidth="sm">
                <Grid stackable alignItems="flex-start">
                    <Grid.Column width={6}>
                        <Select
                            value={proof_of_income}
                            label="Способ подтверждения дохода"
                            name="proof_of_income"
                            onChange={handleChange}
                            options={[
                                { value: 'ndfl2', text: '2-НДФЛ' },
                                { value: 'ndfl3', text: '3-НДФЛ' },
                                { value: 'bank_form', text: 'Справка по форме банка' },
                                { value: 'no_proof_employee', text: 'Без подтверждения дохода' },
                                { value: 'no_proof_self_employed', text: 'Самозанятый, без подтверждения дохода' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Select
                            value={insurance_scheme}
                            label="Тип страхования"
                            name="insurance_scheme"
                            onChange={handleChange}
                            options={[
                                { value: 'collective', text: 'Коллективная' },
                                { value: 'agent', text: 'Агентская' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Select
                            value={credit_type}
                            label="Тип кредита"
                            name="credit_type"
                            onChange={handleChange}
                            options={[
                                { value: 'mortgage_new', text: 'Первичный рынок' },
                                { value: 'mortgage_secondary', text: 'Стандарт' },
                                { value: 'apartment_new', text: 'Апартаменты Первичный рынок' },
                                { value: 'apartment_secondary', text: 'Стандарт Апартаменты' },
                                { value: 'commercial_mortgage_new', text: 'Первичка коммерческая ипотека' },
                                { value: 'commercial_mortgage', text: 'Вторичка коммерческая ипотека' },
                                { value: 'parking_place_new', text: 'Машино-место Первичный рынок' },
                                { value: 'parking_place', text: 'Машино-место' },
                                { value: 'family_mortgage_new', text: 'Первичка семейная ипотека' },
                                { value: 'completed_accommodation', text: 'Готовое жилье' },
                                { value: 'construction_in_progress', text: 'Строящееся жилье' },
                                { value: 'refinancing', text: 'Рефинансирование' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Select
                            value={customer_type}
                            label="Тип занятости"
                            name="customer_type"
                            onChange={handleChange}
                            options={[
                                { value: 'employee', text: 'Наемный работник' },
                                { value: 'self_emoloyee', text: 'Предприниматель' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Select
                            value={apartment_type}
                            label="Тип объекта"
                            name="apartment_type"
                            onChange={handleChange}
                            options={[
                                { value: 'apartment', text: 'Квартира / Комната' },
                                { value: 'townhouse', text: 'Таунхаус' },
                                { value: 'commercial', text: 'Коммерческая' },
                                { value: 'house_with_land', text: 'Дом / участок' }
                            ]}
                            fullWidth
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <PriceInput label="Стоимость объекта" name="apartment_price" value={apartment_price} onChange={handleChange} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <PriceInput label="Сумма первоначального взноса" name="initial_fee" value={initial_fee} onChange={handleChange} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <PriceInput label="Сумма кредита" name="credit_amount" value={credit_amount} onChange={handleChange} />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <NumberInput
                            label="Срок кредита"
                            name="credit_term"
                            value={credit_term}
                            extraLabel="мес."
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Checkbox
                            label="Есть материнский капитал"
                            name="has_maternal_capital"
                            checked={has_maternal_capital}
                            onChange={handleCheckbox}
                        />
                    </Grid.Column>

                    <Grid.Column width={12}>
                        <TextInput
                            label="Комментарий для банка"
                            name="comment"
                            value={comment}
                            multiline
                            rows={1}
                            rowsMax={8}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid>

                <div className="crm-Item__addMode_buttons">
                    <Button variant="outlined" color="primary" loading={loadingItem} onClick={handleSave}>
                        Сохранить
                    </Button>
                </div>
            </Container>
        </form>
    );
};

export default HandlerEditingBlockWrapper(mortgageRequestStore, observer(CalculationMortgageEditing));
