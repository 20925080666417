import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import requestStore from '~/stores/requestStore';

import { EstateFilter } from '~/types/estate.types';
import estateL10n from '../../../../L10n/estate.L10n';
import { nl2br } from '~/api/commentsApi';
import MonoString from '../../../Base/MonoString';

@observer
class RequestFilterShow extends Component<{ item_id: number }> {
    render() {
        const { item_id } = this.props;
        const { item } = requestStore.getItem(item_id);
        const { requestJson } = item || {};

        let filter: (EstateFilter & { description: string }) | null = null;

        try {
            filter = requestJson ? JSON.parse(requestJson) : null;
        } catch (error) {}

        if (filter) {
            const { roomsCount, description, priceMin, priceMax, totalAreaMin, totalAreaMax } = filter;

            return (
                <Card fluid color="red">
                    <Card.Content>
                        <Header size="tiny">Описание</Header>
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </Card.Content>
                    <Card.Content>
                        <Fragment>
                            <Header size="tiny">Детали</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Комнат</Grid.Column>
                                    <Grid.Column>
                                        {roomsCount && roomsCount.map(room => estateL10n.ROOMS_COUNT_FIND(room)).join(', ')}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Цена</Grid.Column>
                                    <Grid.Column>
                                        <MonoString template="от ? до ? ₽" values={[priceMin || '-', priceMax || '-']} />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Общая площадь</Grid.Column>
                                    <Grid.Column>
                                        <MonoString template="от ? до ? м²" values={[totalAreaMin || '-', totalAreaMax || '-']} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    </Card.Content>
                </Card>
            );
        } else {
            return null;
        }
    }
}

export default RequestFilterShow;
