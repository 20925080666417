import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import serverConfig from '~/config/server.config';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import config from '~/config/ui.config';
import history from '~/history';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StorageIcon from '@material-ui/icons/Storage';

import Progress from '~ui/Progress';
import { useToggle } from '~ui/Modal';

import AddExportBaseModal from './AddExportBaseModal';
import estateL10n from '~/L10n/estate.L10n';
import Button from '~ui/Button';

import ListError from '~/components/ListError';
import ButtonGroup from '~/components/Base/ButtonGroup';

export const ExportBaseLinkState = (export_base_id: number) => ({
    pathname: `/ads/price/base/${export_base_id}`,
    state: { modal: true, switchFromTab: false }
});

export const ExportBaseLink = ({ export_base_id, name }: { export_base_id: number; name: string }) => {
    return <Link to={ExportBaseLinkState(export_base_id)}>Реклама/Фид ({name})</Link>;
};

const Export = () => {
    useEffect(() => {
        exportingBaseStore.fetchExportingBasesStatistics();
    }, []);

    const [showAddFeedModal, toggleAddModal] = useToggle();
    const { loadingList, exportingBases, loadingBasesStatistics, exportingBasesStatistics, listErrors } = exportingBaseStore;

    const [toggleBtn, setToggleBtn] = useState<string>('Активные');
    const isEnable = toggleBtn === 'Активные';

    return (
        <Fragment>
            <Box display="flex" alignItems="center" style={{ gap: '1rem', padding: '0.5rem 1rem 0' }}>
                <StorageIcon />
                <Typography variant="h5">Рекламные площадки (цены на экспорт)</Typography>

                <ButtonGroup buttonSet={[['Активные'], ['Архив']]} value={toggleBtn} onChange={(value: string) => setToggleBtn(value)} />
                <Button tooltip="Добавить Рекламную площадку" icon={<AddCircleOutlineIcon color="primary" />} onClick={toggleAddModal} />
            </Box>

            <ListError errors={listErrors} />

            <Box position="relative">
                <Progress show={loadingList || loadingBasesStatistics} size={54} />

                <List dense>
                    {exportingBases
                        .filter(({ enable }) => enable === isEnable)
                        .map(({ name, export_base_id, image, format, feedurl, enable }) => {
                            const baseStatistics = exportingBasesStatistics.find(stat => stat.export_base_id === export_base_id);

                            return (
                                <ListItem key={export_base_id} button onClick={() => history.push(ExportBaseLinkState(export_base_id))}>
                                    <ListItemAvatar>
                                        <Avatar src={config.publicUrl + image} style={!enable ? { filter: 'grayscale()' } : {}}>
                                            {name}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={name}
                                        secondary={
                                            <Fragment>
                                                <b>{baseStatistics ? baseStatistics['countActiveEstates'] : '..'} объектов в фиде</b>&nbsp;
                                                <i>Формат: {estateL10n.EXPORT_FORMAT_NAME_FIND(format)}</i>
                                            </Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Открыть xml-фид">
                                            <Button
                                                icon={<LinkIcon />}
                                                color="primary"
                                                target="_blank"
                                                href={`${serverConfig.feedUrl}${feedurl}.xml`}
                                            />
                                        </Tooltip>

                                        {/*<ConfirmTrigger*/}
                                        {/*    trigger={<Checkbox edge="end" checked={enable} />}*/}
                                        {/*    header={enable ? 'Выключение фида' : 'Включение фида'}*/}
                                        {/*    content={*/}
                                        {/*        enable*/}
                                        {/*            ? 'Внимание, весь экспорт в данную базу будет выключен!'*/}
                                        {/*            : 'Данную базу можно будет выбрать в экспорте объекта'*/}
                                        {/*    }*/}
                                        {/*    confirmButton={enable ? 'Выключить' : 'Включить'}*/}
                                        {/*    onConfirm={() => exportingBaseStore.toggleDisableItem(export_base_id, !enable)}*/}
                                        {/*/>*/}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            </Box>

            {showAddFeedModal && <AddExportBaseModal onClose={toggleAddModal} />}
        </Fragment>
    );
};

export default observer(Export);
