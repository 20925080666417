import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import history from '~/history';

import estateL10n from '~/L10n/estate.L10n';
import dealL10n from '~/L10n/deals.L10n';
import { Deal, DEAL_TYPE_NEWBUILDING_ID, DEAL_TYPE_EXCLUSIVE_ID } from '~/types/deals.types';
import { ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import dealStore from '~/stores/dealStore';

import DealIDBlockEditing from '../../Common/DealIDBlockEditing';

import InfoPopup from '../../../Base/InfoPopup';
import Grid from '~ui/Grid';
import ButtonGroup from '~/components/Base/ButtonGroup';

type DealStatusEditingProps = HandlerEditingWrappedProps & {
    type?: number;
    dealType?: number;
    parent_deal_id?: number;
};

@observer
class DealTypeEditing extends Component<DealStatusEditingProps> {
    constructor(props: DealStatusEditingProps) {
        super(props);

        const {
            location: {
                state: { from }
            }
        } = history;

        const deal = {
            type: props.type || ESTATE_TYPE_SELL_ID,
            dealType: props.dealType || (from && from.storeName === 'dealStore' ? 2 : 1),
            parent_deal_id: props.parent_deal_id || (from && from.storeName === 'dealStore' ? from.item_id : null)
        };
        dealStore.setEditingItem(props.item_id, deal);
    }

    handleTypeChange = (event: React.SyntheticEvent, { value: type }: { value: number }) => {
        const { item_id } = this.props;

        const editingItem: Partial<Deal> = { type };
        const { type: oldType } = dealStore.getItem(item_id).editingItem;

        editingItem['isExclusive'] = [type, oldType].includes(DEAL_TYPE_EXCLUSIVE_ID);

        dealStore.setEditingItem(item_id, editingItem);
    };

    render() {
        const { item_id, handleChange, editing } = this.props;

        const { type, dealType, parent_deal_id } = dealStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <h3>Тип договора</h3>
                <Grid stackable>
                    <Grid.Column width={12}>
                        <ButtonGroup
                            size="small"
                            buttonSet={dealL10n.DEAL_ESTATE_TYPE}
                            name="type"
                            value={type}
                            handleChange={this.handleTypeChange}
                        />
                    </Grid.Column>

                    {[ESTATE_TYPE_SELL_ID, DEAL_TYPE_NEWBUILDING_ID].includes(type) && (
                        <Grid.Column width={12}>
                            <ButtonGroup
                                size="small"
                                buttonSet={estateL10n.SALE_TYPES}
                                name="dealType"
                                value={dealType}
                                handleChange={handleChange}
                            />
                        </Grid.Column>
                    )}
                    {dealType === 2 && (
                        <Grid.Column md={12} width={editing ? 12 : 3}>
                            <b>Прикрепить к сделке</b>&nbsp;&nbsp;
                            <InfoPopup content="Выберите другую АЛЬТЕРНАТИВНУЮ сделку, к которой хотите прикрепить данную сделку (если в этом списке нет нужной сделки, проверьте, стоит ли у нужной сделки тип: Альтернатива)" />
                        </Grid.Column>
                    )}
                    {dealType === 2 && (
                        <Grid.Column md={12} width={editing ? 12 : 9}>
                            <DealIDBlockEditing
                                store={dealStore}
                                editing={editing || parent_deal_id === null}
                                item_id={item_id}
                                value={Number(parent_deal_id)}
                            />
                        </Grid.Column>
                    )}
                </Grid>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(dealStore, DealTypeEditing);
