import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        padding: '0.725rem',
        minWidth: 0
    }
}));

export const colorSet = [
    { bgColor: '#e0e0e0', textColor: '#181f31', text: 'Серый' },
    { bgColor: '#ba7931', textColor: '#ffffff', text: 'Коричневый' },
    { bgColor: '#ffac33', textColor: '#181f31', text: 'Оранжевый' },
    { bgColor: '#81c784', textColor: '#ffffff', text: 'Зеленый' },
    { bgColor: '#a2cf6e', textColor: '#181f31', text: 'Оливковый' },
    { bgColor: '#64b5f6', textColor: '#ffffff', text: 'Синий' },
    { bgColor: '#33c9dc', textColor: '#181f31', text: 'Морской волны' },
    { bgColor: '#ffb74d', textColor: '#ffffff', text: 'Желтый' },
    { bgColor: '#ffcd38', textColor: '#181f31', text: 'Янтарный' },
    { bgColor: '#e57373', textColor: '#ffffff', text: 'Красный' },
    { bgColor: '#ed4b82', textColor: '#181f31', text: 'Розовый' }
];

const BgColorPicker = ({ color, onChange }: { color: string; onChange: (colorNumber: number) => void }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip title="Цвет списка">
                <Button variant="contained" size="small" className={classes.button} onClick={handleClick} style={{ background: color }} />
            </Tooltip>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {colorSet.map(({ text, textColor, bgColor }, colorIndex) => (
                    <MenuItem
                        key={text}
                        dense
                        style={{ background: bgColor, color: textColor }}
                        onClick={() => {
                            onChange(colorIndex);
                            handleClose();
                        }}
                    >
                        {text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default React.memo(BgColorPicker);
