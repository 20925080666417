import React, { Component } from 'react';
import { Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import mlsFixPriceStore from '~/stores/mlsFixPriceStore';

import Container from '@material-ui/core/Container';

import { ListWrapperProps } from '~/components/Lists/ListWrapper';
import ListWrapper from '~/components/Lists/ListWrapper';
import ListFooter from '~/components/Lists/Common/ListFooterNew';
import ListError from '~/components/ListError';
import Table, { TableHead } from '~ui/Table';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';

import MlsFixPricesTableBody from './MlsFixPricesTableBody';
import MlsFixPricesTableHeader from './MlsFixPricesTableHeader';

@observer
class MlsFixPricesList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort, param } = this.props;
        const { loadingList, list, listCount, listErrors, pagination, nextListLoaded } = mlsFixPriceStore;

        return (
            <Container maxWidth="lg" style={{ padding: 0 }}>
                <div className="crm-List">
                    <Header as="h3">
                        <Icon name="wordpress forms" />
                        Фиксация цены
                    </Header>
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    {listErrors && <ListError errors={listErrors} />}

                    <Segment className="crm-List__segment">
                        <Table size="small">
                            <TableHead>
                                <ListPagination
                                    loading={loadingList}
                                    colSpan={5}
                                    pagination={pagination}
                                    listCount={listCount}
                                    pageChange={mlsFixPriceStore.pageChange}
                                    pageSizeChange={mlsFixPriceStore.pageSizeChange}
                                    nextListLoaded={nextListLoaded}
                                />
                            </TableHead>

                            <MlsFixPricesTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <MlsFixPricesTableBody list={list} orderBy={orderBy} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={4}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={mlsFixPriceStore.pageChange}
                                pageSizeChange={mlsFixPriceStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Container>
        );
    }
}

export default ListWrapper(mlsFixPriceStore, MlsFixPricesList);
