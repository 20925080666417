import React, { Component, Fragment, RefObject, createRef } from 'react';
import { observer } from 'mobx-react';
import { Popup, Icon } from 'semantic-ui-react';
import { COMMENT_TYPE, CommentType } from '~/types/comments.types';
import Time from '../../Base/Time';
import Comments from '../../Items/Common/Comments';
import commentStore from '~/stores/commentStore';
import { AWAIT_POPUP_TIME_CLOSE_MS, PopupTrigger } from '../../Items/Common/ItemPreview';
import commonStore from '~/stores/commonStore';

const MAX_COMMENT_LENGTH = 30;

const CommentsTrigger = ({ comments }: { comments?: CommentType[] }) => {
    const lastComment = comments && comments.length > 0 ? comments[comments.length - 1] : null;

    return lastComment ? (
        <div>
            <div className="crm-List__tableComment_comment">
                <Icon name="comments outline" color="grey" />{' '}
                {lastComment.comment.length > MAX_COMMENT_LENGTH
                    ? lastComment.comment.slice(0, MAX_COMMENT_LENGTH) + '...'
                    : lastComment.comment}
                {comments && comments.length > 1 && <b className="crm-List__tableComment_plus">+{comments.length - 1}</b>}
            </div>
            <div className="crm-List__tableComment_time">
                <Icon name="clock" color="grey" />
                <Time time={lastComment.time} />
            </div>
        </div>
    ) : (
        <span className="crm-List__tableComment_trigger">
            <Icon name="write" color="grey" />
            <span className="crm-List__tableComment_triggerHover">написать...</span>
        </span>
    );
};

type CommentsPopupProps = {
    item_id: number;
    item_type: COMMENT_TYPE;
    context: RefObject<HTMLElement>;
    openPopup: boolean;

    handleMouseLeave: () => void;
    handleMouseEnter: () => void;
    onAddComment: (comments: CommentType[]) => void;
};

const CommentsPopup = ({
    item_id,
    item_type,
    openPopup,
    handleMouseLeave,
    handleMouseEnter,
    context,
    onAddComment
}: CommentsPopupProps) => {
    let comments = [];
    let loading = false;
    try {
        const item = commentStore.getItem(item_type, item_id);
        comments = item.comments;
        loading = item.loading;
    } catch (e) {}

    return (
        <Popup
            popperDependencies={[comments.length, loading]}
            wide
            size="tiny"
            hoverable
            className="crm-List__tableComment"
            context={context}
            open={openPopup}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            <Comments item_id={item_id} item_type={item_type} onAddComment={onAddComment} minimal />
        </Popup>
    );
};

const CommentsPopupObserver = observer(CommentsPopup);

type ListCommentsProps = {
    comments?: CommentType[];
    item_id: number;
    item_type: COMMENT_TYPE;
};

type ListCommentsState = {
    comments: CommentType[];
    openPopup: boolean;
};

class ListComments extends Component<ListCommentsProps, ListCommentsState> {
    constructor(props: ListCommentsProps) {
        super(props);

        this.state = {
            comments: props.comments || [],
            openPopup: false
        };
    }

    onAddComment = (comments: CommentType[]) => {
        this.setState({ comments });
    };

    contextRef: RefObject<HTMLElement> = createRef();

    showingTimeout: number | null = null;
    closingTimeout: number | null = null;

    onOpen = () => {
        this.setState({ openPopup: true });
        this.showingTimeout = null;
    };

    onClose = async () => {
        clearTimeout(this.showingTimeout);
        clearTimeout(this.closingTimeout);
        this.showingTimeout = null;
        this.setState({ openPopup: false });
        this.closingTimeout = window.setTimeout(async () => {
            await this.setState({ openPopup: false });
            this.render();
        }, 0);
    };

    // For outerClick
    awaitClose = () => {
        clearTimeout(this.showingTimeout);
        setTimeout(() => this.setState({ openPopup: false }), AWAIT_POPUP_TIME_CLOSE_MS);
    };

    handleClose = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        this.onClose();
    };

    handleMouseEnter = () => {
        clearTimeout(this.closingTimeout);
        this.closingTimeout = null;

        if (!this.showingTimeout) {
            this.showingTimeout = window.setTimeout(this.onOpen, AWAIT_POPUP_TIME_CLOSE_MS);
        }
    };

    handleMouseLeave = () => {
        clearTimeout(this.showingTimeout);
        this.showingTimeout = null;

        if (!this.closingTimeout) {
            this.closingTimeout = window.setTimeout(this.onClose, AWAIT_POPUP_TIME_CLOSE_MS);
        }
    };

    componentWillUnmount() {
        clearTimeout(this.showingTimeout);
        clearTimeout(this.closingTimeout);
    }

    render() {
        const { item_id, item_type } = this.props;
        const { comments, openPopup } = this.state;

        if (commonStore.isMobile) {
            return <CommentsTrigger comments={comments} />;
        }

        return (
            <Fragment>
                <PopupTrigger
                    context={this.contextRef}
                    onClick={this.onClose}
                    onMouseLeave={this.handleMouseLeave}
                    onMouseEnter={this.handleMouseEnter}
                    trigger={<CommentsTrigger comments={comments} />}
                    item_id={item_id}
                />
                <CommentsPopupObserver
                    item_id={item_id}
                    item_type={item_type}
                    openPopup={openPopup}
                    context={this.contextRef}
                    handleMouseLeave={this.handleMouseLeave}
                    handleMouseEnter={this.handleMouseEnter}
                    onAddComment={this.onAddComment}
                />
            </Fragment>
        );
    }
}

export default ListComments;
