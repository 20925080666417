import React, { Component, Fragment } from 'react';
import { Menu, Input, Segment, Dropdown, Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ListFilterWrapper, { deltaCreateTimes } from '../ListFilterWrapper';
import dealStore from '~/stores/dealStore';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import dealL10n from '../../../L10n/deals.L10n';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import { DEAL_TYPE_MORTGAGE, DEAL_TYPE_MORTGAGE_ID } from '~/types/deals.types';

@observer
class FilterDeals extends Component<ListFilterWrapperProps> {
    render() {
        const { search, deltaCreateTime, isExclusive, status, type, dealType } = dealStore.listFilter;
        const { handleChange, handleCheckbox, param } = this.props;

        return (
            <Fragment>
                <Menu.Item>
                    <Input
                        transparent
                        value={search}
                        name="search"
                        className="icon"
                        icon="search"
                        placeholder="Номер, сумма, описание, адрес"
                        onChange={handleChange}
                    />
                </Menu.Item>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            placeholder="Статус"
                            closeOnChange={true}
                            selection
                            options={
                                type === DEAL_TYPE_MORTGAGE_ID
                                    ? dealL10n.DEAL_STATUS_MORTGAGE_TYPE_DROPDOWN
                                    : dealL10n.DEAL_STATUS_TYPE_DROPDOWN
                            }
                            value={status}
                            onChange={handleChange}
                            name="status"
                            clearable
                            type="clearable"
                        />
                    </Form>
                </Menu.Item>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            placeholder="Время"
                            closeOnChange={true}
                            selection
                            options={deltaCreateTimes}
                            value={deltaCreateTime}
                            onChange={handleChange}
                            name="deltaCreateTime"
                        />
                    </Form>
                </Menu.Item>
                {param === ESTATE_TYPE_SELL && (
                    <Menu.Item>
                        <Checkbox name="isExclusive" checked={isExclusive} label="эксклюзив" onChange={handleCheckbox} />
                    </Menu.Item>
                )}
            </Fragment>
        );
    }
}

export default ListFilterWrapper(dealStore, FilterDeals);
