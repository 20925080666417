import './header.less';

import React, { SyntheticEvent } from 'react';

import { observer } from 'mobx-react';
import commonStore from '~/stores/commonStore';

import ThereAreNoInternetNoti from './ThereAreNoInternetNoti';
import notificationStore from '~/stores/notificationStore';
import HeaderAppBar from './HeaderAppBar';

@observer
export default class Header extends React.Component<{ fullSize: boolean }> {
    handleShowMenu = (event: SyntheticEvent) => {
        event.preventDefault();
        commonStore.toggleMenu();
    };

    render() {
        const { fullSize } = this.props;
        const { thereAreNoInternetConnection } = notificationStore;

        return (
            <>
                <HeaderAppBar fullSize={fullSize} />

                {thereAreNoInternetConnection && <ThereAreNoInternetNoti />}
            </>
        );
    }
}
