import React, { useState } from 'react';
import estateSMAStore from '~/stores/estateSMAStore';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import MoneyIcon from '@material-ui/icons/Money';
import Switch from '@material-ui/core/Switch';
import Divider from '~ui/Divider';

import { ESTATE_BASE_MAIN, ESTATE_BASES } from '~/types/estate.types';

import SmaEstateAlreadySoldModal from './SmaEstateAlreadySoldModal';
import SmaEstateChangeParamsModal from './SmaEstateChangeParamsModal';

type SmaEstateMenuProps = {
    sma_estate_id: number;
    estate_id: number;
    base: ESTATE_BASES;

    externalUrlAvailable: boolean;
};

const SmaEstateMenu = ({ sma_estate_id, estate_id, base, externalUrlAvailable }: SmaEstateMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const [openAlreadySoldModal, setAlreadySoldModal] = useState(false);
    const toggleAlreadySoldModal = () => {
        handleClose();
        setAlreadySoldModal(!openAlreadySoldModal);
    };

    const [openChangeParamsModal, setChangeParamsModal] = useState(false);
    const toggleChangeParamsModal = () => {
        handleClose();
        setChangeParamsModal(!openChangeParamsModal);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemoveEstateFromSma = async () => {
        handleClose();
        await estateSMAStore.estateToSma(sma_estate_id, estate_id, base, false);
        estateSMAStore.fetchItemList(sma_estate_id);
    };

    const toggleEnableLink = async () => {
        await estateSMAStore.updateItemList(sma_estate_id, { estate_id, base }, { externalUrlAvailable: !externalUrlAvailable });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={openMenu} onClose={handleClose}>
                <MenuItem onClick={toggleEnableLink}>
                    <ListItemIcon>
                        <Switch size="small" checked={externalUrlAvailable} color="primary" />
                    </ListItemIcon>
                    Показывать ссылку
                </MenuItem>

                <MenuItem onClick={toggleChangeParamsModal}>
                    <ListItemIcon>
                        <MoneyIcon fontSize="small" />
                    </ListItemIcon>
                    Изменить цену / Даты (в СМА)
                </MenuItem>

                <Divider />

                {/*{base !== ESTATE_BASE_MAIN && (*/}
                {/*    <MenuItem onClick={toggleAlreadySoldModal}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <LowPriorityIcon fontSize="small" />*/}
                {/*        </ListItemIcon>*/}
                {/*        Уже продано (Есть подтверждение)*/}
                {/*    </MenuItem>*/}
                {/*)}*/}

                <MenuItem onClick={handleRemoveEstateFromSma}>
                    <ListItemIcon>
                        <RemoveCircleOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Убрать из СМА
                </MenuItem>
            </Menu>

            {openAlreadySoldModal && <SmaEstateAlreadySoldModal onClose={toggleAlreadySoldModal} />}
            {openChangeParamsModal && (
                <SmaEstateChangeParamsModal
                    onClose={toggleChangeParamsModal}
                    sma_estate_id={sma_estate_id}
                    editingItem={{ base, estate_id }}
                />
            )}
        </>
    );
};

export default React.memo(SmaEstateMenu);
