import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import ContactIdBlockEditing from '../../Common/ContactIDBlockEditing';
import history from '../../../../history';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import requestStore from '~/stores/requestStore';

type RequestContactIdEditingProps = HandlerEditingWrappedProps & {
    contact_id?: number;
};

@observer
class RequestContactIdEditing extends Component<RequestContactIdEditingProps> {
    constructor(props: RequestContactIdEditingProps) {
        super(props);

        const {
            location: {
                state: { from }
            }
        } = history;

        const item = {
            contact_id: props.contact_id || (from && from.storeName === 'contactStore' ? from.item_id : null)
        };

        requestStore.setEditingItem(props.item_id, item);
    }

    render() {
        const { item_id } = this.props;
        const { contact_id } = requestStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <label className="crm-Estate__field_label">Контакт</label>
                <div className="crm-Item__contactEditingDropdown">
                    <ContactIdBlockEditing store={requestStore} editing={contact_id === null} item_id={item_id} value={contact_id} />
                </div>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, RequestContactIdEditing);
