import React, { useState, useMemo } from 'react';

import { fetchOptions } from '~/api/estateSMAApi';
import estateSMAShareStore from '~/stores/estateSMAShareStore';
import whatsappStore from '~/stores/whatsappStore';

import { ChatSendBox } from '~/components/Chat/ChatSendArea';
import { matchSmaUrl } from './EstateSMAShow';
import WhatsappTitleItem from '~/components/ItemsDropdowns/WhatsappDropdown/WhatsappTitleItem';

import Progress from '~ui/Progress';
import { Modal, ModalContent } from '~ui/Modal';

import { SmaOptionsType } from '~/types/estateSma.types';
import { WhatsappChatTitle } from '~/types/whatsapp.types';

const SmaShareModal = ({
    onClose,
    sma_id,
    estate_id,
    waTitle
}: {
    sma_id: number;
    estate_id: number;
    waTitle: WhatsappChatTitle;
    onClose: () => void;
}) => {
    const [options, setOptions] = useState<SmaOptionsType | null>(null);

    useMemo(() => {
        fetchOptions(estate_id).then(options => setOptions(options));
    }, []);

    const { phone } = waTitle;

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const sendSma = async () => {
        setLoading(true);

        const url = matchSmaUrl(options);

        if (message.trim()) {
            await whatsappStore.sendMessageToPhone(phone, message);
        }
        await whatsappStore.sendMessageToPhone(phone, url);
        await estateSMAShareStore.createSmaShare(sma_id, phone, message);
        estateSMAShareStore.fetchItemList(sma_id);

        setLoading(false);
        onClose();
    };

    return (
        <Modal maxWidth="sm" onClose={!loading && onClose} header="Отправить СМА по Whatsapp">
            <ModalContent dividers>
                <WhatsappTitleItem waTitle={waTitle} />

                <Progress show={!options || loading} />

                <ChatSendBox
                    label="Комментарий для получателя"
                    onSend={sendSma}
                    onChange={setMessage}
                    message={message}
                    disabled={!options || loading}
                />
            </ModalContent>
        </Modal>
    );
};

export default React.memo(SmaShareModal);
