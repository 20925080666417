import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import './bottom.less';

import { Image, Icon, Button, Card } from 'semantic-ui-react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import UserAvatar from '~ui/UserAvatar';

import cellCallStore from '~/stores/cell/cellCallStore';
import { CALL_TYPE_INCOMING, CALL_TYPE_OUTGOING, CONTACT_PHONE_TYPE_ID, USER_PHONE_TYPE_ID } from '~/types/cellCalls.types';
import { adjustPhoneNumber } from '../Base/PhonesList';
import userStore from '~/stores/userStore';
import contactStore from '~/stores/contactStore';
import { ContactLinkState, matchContactTitle } from '../Lists/Contacts/ContactLink';
import history from '~/history';
import { UserLinkState } from '../Lists/Users/UserLink';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CallEstateModal from '~/components/Lists/CellCalls/CallEstateModal';

const CallInProgressTrigger = ({
    answerTime,
    releaseTime,
    callStatus,
    togglePopup,
    setRef
}: {
    answerTime: number | null;
    releaseTime: number | null;
    callStatus: number;
    togglePopup: () => void;
    setRef: ($ref: HTMLElement) => void;
}) => (
    <div ref={$ref => setRef($ref)} className="crm-Bottom__callingTrigger" onClick={togglePopup}>
        <Icon.Group size="big">
            {!answerTime && (
                <Icon
                    loading={callStatus === CALL_STATUS_INIT}
                    size="big"
                    name={releaseTime ? 'dont' : 'spinner'}
                    color={releaseTime ? 'red' : 'green'}
                />
            )}
            {answerTime && (
                <Icon
                    loading={!releaseTime}
                    name={!releaseTime ? 'circle notch' : 'circle outline'}
                    color={releaseTime ? 'red' : 'green'}
                    size="big"
                />
            )}
            <Icon name="phone" color={releaseTime ? 'red' : 'green'} />
        </Icon.Group>
    </div>
);

type CallInProgresState = {
    popupIsChanged: boolean;
    popupIsOpen: boolean;
    callStatus: number;
    showCellCallModal: boolean;
};

const CALL_STATUS_INIT = 1;
const CALL_STATUS_SPEAKING = 2;
const CALL_STATUS_DONE = 3;
const CALL_STATUS_FAIL = 4;

const matchCallStatus = (answerTime: number | null, releaseTime: number | null): number => {
    let callStatus;
    if (!answerTime && !releaseTime) {
        callStatus = CALL_STATUS_INIT;
    } else if (answerTime && !releaseTime) {
        callStatus = CALL_STATUS_SPEAKING;
    } else if (answerTime && releaseTime) {
        callStatus = CALL_STATUS_DONE;
    } else {
        callStatus = CALL_STATUS_FAIL;
    }
    return callStatus;
};

const CLOSE_POPUP_AFTER_MS = 5000;
const REMOVE_CALL_AFTER_MS = 60000;

const snd = new Audio('/static/beep.mp3');

@observer
class CallInProgress extends Component<{}, CallInProgresState> {
    state = {
        popupIsChanged: true,
        popupIsOpen: true,
        callStatus: 0,
        showCellCallModal: false
    };

    toggleChangePopup = () => {
        this.setState({ popupIsChanged: !this.state.popupIsChanged });
    };

    handleOpenCard = () => {
        const { callInProgress } = cellCallStore;
        if (callInProgress && callInProgress.item_id && callInProgress.type) {
            history.push(
                callInProgress.type === CONTACT_PHONE_TYPE_ID
                    ? ContactLinkState(callInProgress.item_id)
                    : UserLinkState(callInProgress.item_id)
            );
        } else if (callInProgress && !callInProgress.item_id && callInProgress.phone) {
            const { phone } = callInProgress;
            history.push({
                pathname: `/contacts/list/profile/${CREATING_ITEM_ID}`,
                state: {
                    modal: true,
                    editingData: { phone }
                }
            });
        }

        this.setState({ popupIsOpen: false });
    };

    handleTogglePopup = () => {
        if (!this.state.popupIsOpen && (this.removingTimeout || this.closingTimeout)) {
            this.clearTimeouts();
        }
        this.setState({ popupIsOpen: !this.state.popupIsOpen });
    };

    handleRemoveCall = () => {
        cellCallStore.removeCallInProgress();
    };

    closingTimeout: number;
    removingTimeout: number;

    clearTimeouts = () => {
        clearTimeout(this.removingTimeout);
        clearTimeout(this.closingTimeout);
    };

    componentDidUpdate(prevProps: {}, prevState: CallInProgresState) {
        const { callInProgress } = cellCallStore;

        if (callInProgress) {
            const { answerTime, releaseTime } = callInProgress;
            const callStatus = matchCallStatus(answerTime, releaseTime);

            if (prevState.callStatus !== callStatus) {
                if (callStatus === CALL_STATUS_INIT) {
                    this.setState({ popupIsOpen: true });
                    if (callInProgress.direction === CALL_TYPE_INCOMING) {
                        try {
                            snd.play();
                        } catch (e) {}
                    }

                    this.clearTimeouts();

                    this.removingTimeout = window.setTimeout(() => {
                        this.setState({
                            popupIsOpen: false
                        });
                    }, 3 * REMOVE_CALL_AFTER_MS);
                } else {
                    const isAutoClosePopup = prevState.callStatus < CALL_STATUS_DONE && callStatus >= CALL_STATUS_DONE;

                    if (isAutoClosePopup) {
                        clearTimeout(this.closingTimeout);
                        this.closingTimeout = window.setTimeout(() => {
                            this.setState({
                                popupIsOpen: false
                            });
                        }, CLOSE_POPUP_AFTER_MS);

                        clearTimeout(this.removingTimeout);
                        this.removingTimeout = window.setTimeout(() => {
                            cellCallStore.removeCallInProgress();
                            this.setState({ callStatus: 0 });
                        }, REMOVE_CALL_AFTER_MS);
                    } else {
                        this.clearTimeouts();
                    }
                }

                this.setState({
                    callStatus
                });
            }
        }
    }

    componentWillUnmount() {
        this.clearTimeouts();
    }

    componentDidMount() {
        this.setState({ popupIsChanged: !this.state.popupIsChanged });
    }

    handleOpenCellCallModal = () => {
        this.setState({ showCellCallModal: !this.state.showCellCallModal });
    };

    $ref: HTMLElement;

    render() {
        const { callInProgress } = cellCallStore;
        const { popupIsChanged, popupIsOpen, callStatus, showCellCallModal } = this.state;

        if (!callInProgress) {
            return null;
        }

        const { phone, item_id, type, direction, answerTime, releaseTime, call_id } = callInProgress;

        let user = null;
        let contact = null;
        let contentIsLoading = false;

        if (type === USER_PHONE_TYPE_ID && item_id) {
            try {
                const userItem = userStore.getItem(item_id);
                contentIsLoading = userItem.loadingItem;
                user = userItem.item;
            } catch (e) {}
        }
        if (type === CONTACT_PHONE_TYPE_ID && item_id) {
            try {
                const contactItem = contactStore.getItem(item_id);
                contentIsLoading = contactItem.loadingItem;
                contact = contactItem.item;
            } catch (e) {}
        }

        return (
            <Fragment>
                <CallInProgressTrigger
                    answerTime={answerTime}
                    callStatus={callStatus}
                    releaseTime={releaseTime}
                    togglePopup={this.handleTogglePopup}
                    setRef={$ref => (this.$ref = $ref)}
                />

                {showCellCallModal && (
                    <CallEstateModal
                        contact_id={type === CONTACT_PHONE_TYPE_ID ? item_id : CREATING_ITEM_ID}
                        item_id={call_id}
                        estate_id={null}
                        handleClose={this.handleOpenCellCallModal}
                        goToContactOnCreate
                    />
                )}

                <Popper
                    open={popupIsOpen && Boolean(this.$ref)}
                    anchorEl={this.$ref}
                    placement="top-end"
                    // anchorOrigin={{
                    //     vertical: 'top',
                    //     horizontal: 'left'
                    // }}
                    // transformOrigin={{
                    //     vertical: 'bottom',
                    //     horizontal: 'right'
                    // }}
                >
                    <Paper style={{ border: '1px solid #B0B0B0', margin: '0.275rem', padding: '0.275rem' }}>
                        <Card>
                            <Card.Content>
                                {type === USER_PHONE_TYPE_ID && user && (
                                    <Image floated="right" size="mini">
                                        <UserAvatar
                                            onLoad={this.toggleChangePopup}
                                            src={user.avatarUrl}
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                            variant="rounded"
                                        />
                                    </Image>
                                )}
                                <Card.Header>
                                    <Icon name="phone" color="green" />
                                    {item_id && type === USER_PHONE_TYPE_ID && user && `${user.lastName} ${user.firstName}`}
                                    {item_id && type === CONTACT_PHONE_TYPE_ID && contact && matchContactTitle(contact)}
                                    {(!item_id || contentIsLoading) && phone && adjustPhoneNumber(phone)}
                                </Card.Header>
                                <Card.Meta>
                                    {callStatus === CALL_STATUS_INIT && direction === CALL_TYPE_INCOMING && 'Вам пытается дозвониться'}
                                    {callStatus === CALL_STATUS_INIT && direction === CALL_TYPE_OUTGOING && 'Вы пытаетесь дозвониться'}
                                    {callStatus === CALL_STATUS_SPEAKING && 'Идет разговор'}
                                    {callStatus === CALL_STATUS_DONE && 'Разговор завершен'}
                                    {callStatus === CALL_STATUS_FAIL && 'Разговор не состоялся'}
                                </Card.Meta>
                                <Card.Description>{!item_id && 'Данный номер не найден в базе'}</Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                {direction === CALL_TYPE_INCOMING && type !== USER_PHONE_TYPE_ID && (
                                    <Button
                                        basic
                                        color="blue"
                                        icon="building"
                                        labelPosition="left"
                                        content="Звонок по объекту"
                                        fluid
                                        onClick={this.handleOpenCellCallModal}
                                    />
                                )}
                                <div className="ui two buttons tiny">
                                    {item_id && (
                                        <Button
                                            basic
                                            color="green"
                                            icon={type === USER_PHONE_TYPE_ID ? 'user' : 'address card outline'}
                                            labelPosition="left"
                                            content="Карточка"
                                            onClick={this.handleOpenCard}
                                        />
                                    )}
                                    {!item_id && (
                                        <Button
                                            basic
                                            color="green"
                                            icon="plus"
                                            labelPosition="left"
                                            content="Создать"
                                            onClick={this.handleOpenCard}
                                        />
                                    )}
                                    <Button
                                        basic
                                        color="red"
                                        icon="remove"
                                        labelPosition="right"
                                        content="Скрыть"
                                        onClick={this.handleRemoveCall}
                                    />
                                </div>
                            </Card.Content>
                        </Card>
                    </Paper>
                </Popper>
            </Fragment>
        );
    }
}

export default CallInProgress;
