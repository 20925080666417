import React, { Fragment } from 'react';
import { Popup, Header, Icon } from 'semantic-ui-react';

import { Tag } from '~/types/contacts.types';
import tagStore from '~/stores/tagStore';
import TagsList from '../../Items/Contact/ShowBlocks/SubBlocks/TagsList';

const ListTagsPopup = ({ tags_ids }: { tags_ids: number[] }) => {
    const tagsFiltered: Tag[] | false =
        tags_ids &&
        tags_ids.length > 0 &&
        tags_ids.reduce((tags, tag_id) => {
            try {
                const tag = tagStore.findById(tag_id);
                if (tag) {
                    tags.push(tag);
                }
            } catch (error) {}
            return tags;
        }, []);

    return tagsFiltered ? (
        <Popup
            size="tiny"
            trigger={
                <div>
                    {tagsFiltered.map(({ tag_id, color }) => (
                        <Icon key={tag_id} name="tag" color={color} />
                    ))}
                </div>
            }
        >
            <span>
                <Header size="tiny">
                    <Icon name="tags" color="grey" />
                    Тэги
                </Header>
                <TagsList tags_ids={tags_ids} />
            </span>
        </Popup>
    ) : null;
};

export default ListTagsPopup;
