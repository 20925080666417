import React, { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import historyChangeStore from '~/stores/historyChangeStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from '~ui/Table';

import Pagination from '~ui/Pagination';
import Time from '~/components/Base/Time';
import ItemLink, { matchIconName } from '~/components/Base/ItemLink';
import ListError from '~/components/ListError';
import { HistoryItemFactory } from '~/components/Items/ItemHistoryModeWrapper';
import SkeletonTable from '~ui/SkeletonTable';

const UserActionsHistoryMode = ({ user_id }: { user_id: number }) => {
    useMemo(() => {
        historyChangeStore.fetchItemList(user_id);
    }, []);

    const handlePageChange = (pageNumber: number) => {
        historyChangeStore.pageChange(user_id, pageNumber);
    };

    const handlePageSizeChange = (pageSize: number) => {
        historyChangeStore.pageSizeChange(user_id, pageSize);
    };

    const { list, errors, pagination, listCount, loadingList } = historyChangeStore.getItemList(user_id);
    const elementId = `UserActionsHistoryMode_${user_id}`;

    const firstLoading = !list || !list.length;

    return (
        <div id={elementId}>
            <ListError errors={errors} />
            <Table size="small" compact>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Карточка</TableCell>
                        <TableCell>Новые значения</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadingList && firstLoading && <SkeletonTable columns={3} rows={10} />}

                    {list.map(({ change_id, releaseTime, item, item_type, changeJson }) => {
                        const iconName = matchIconName(item_type);

                        return (
                            <TableRow key={change_id}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>
                                    <Time time={releaseTime} />
                                </TableCell>
                                <TableCell>
                                    <Icon name={iconName} />
                                    <ItemLink item_type={item_type} item={item} />
                                </TableCell>
                                <TableCell>
                                    <HistoryItemFactory item_type={item_type} changeJson={changeJson} />
                                </TableCell>
                            </TableRow>
                        );
                    })}

                    <TableRow>
                        <TableCell colSpan={3}>
                            <Pagination
                                pagination={pagination}
                                pageChange={handlePageChange}
                                pageSizeChange={handlePageSizeChange}
                                listCount={listCount}
                                scrollToElementId={elementId}
                                reverseOnMobile={false}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default observer(UserActionsHistoryMode);
