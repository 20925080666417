import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Menu, SemanticCOLORS } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import EstateTableBody from '../../Lists/Estate/EstateTableBody';
import { ESTATE_BASES, ESTATE_TYPE_SELL } from '~/types/estate.types';
import estateStore from '~/stores/estateStore';
import ListError from '../../ListError';
import LoaderAwait from '../../Base/LoaderAwait';
import ButtonGroup from '../../Base/ButtonGroup';
import ApproximationInput from '../Common/ApproximationInput';
import { DEFAULT_APPROXIMATION_RANGE } from '~/stores/wishStore';
import debounce from '../../../common/debounce';
import { HandleChangeType } from '../HandlerEditingBlockWrapper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import DateRangeSelect, { DATES_RANGE_VALUE } from '../../Base/ui/DateRangeSelect';
import { TSameEstatesFilter } from '~/api/estateApi';
import { WarningMessage } from '~ui/Message';

export const RedCheckbox = withStyles({
    root: {
        color: '#DB2828',
        '&$checked': {
            color: '#DB2828'
        },
        marginLeft: '1em'
    },
    checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type PULT_MENU_ITEM = { name: string; enable: boolean; color: SemanticCOLORS | null };

const pultMenu: PULT_MENU_ITEM[] = [
    { name: 'Все', enable: true, color: null },
    { name: 'Архив', enable: false, color: 'red' }
];

type EstateSameModeProps = {
    estate_id: number;
    base: ESTATE_BASES;
};

type EstateSameModeState = {
    filter: TSameEstatesFilter;
};

export const sameEstatesDefaultFilter: TSameEstatesFilter = {
    enable: true,
    approximation: DEFAULT_APPROXIMATION_RANGE,
    sameEstateLocationType: 0,
    disableExternal: false,
    startTime: null,
    endTime: null,
    deltaTime: 50 * 24 * 3600
};

@observer
class EstateSameMode extends Component<EstateSameModeProps, EstateSameModeState> {
    debounceSame: () => void;

    constructor(props: EstateSameModeProps) {
        super(props);

        this.state = {
            filter: { ...sameEstatesDefaultFilter }
        };

        this.debounceSame = debounce(() => estateStore.fetchSameEstates(this.props.estate_id, this.props.base, this.state.filter), 350);
    }

    toggleEnableMode = () => {
        const { enable } = this.state.filter;
        const filter = { ...this.state.filter, enable: !enable };

        this.setState({
            filter
        });

        estateStore.fetchSameEstates(this.props.estate_id, this.props.base, filter);
    };

    handleChangeApproximation = (approximation: number) => {
        const filter = { ...this.state.filter, approximation };
        this.setState({ filter });
        this.debounceSame();
    };

    handleChangeLocationType: HandleChangeType = (event, { value: sameEstateLocationType }) => {
        const filter = { ...this.state.filter, sameEstateLocationType };
        this.setState({ filter });
        estateStore.fetchSameEstates(this.props.estate_id, this.props.base, filter);
    };

    handleToggleDisableExternal = () => {
        const disableExternal = !this.state.filter.disableExternal;

        const filter = { ...this.state.filter, disableExternal };
        estateStore.fetchSameEstates(this.props.estate_id, this.props.base, filter);

        this.setState({ filter });
    };

    handleTimesChange = (times: DATES_RANGE_VALUE) => {
        const filter = { ...this.state.filter, ...times };
        this.setState({ filter });
        estateStore.fetchSameEstates(this.props.estate_id, this.props.base, filter);
    };

    componentDidMount() {
        this.debounceSame();
    }

    render() {
        const { estate_id } = this.props;
        const { enable, sameEstateLocationType, approximation, disableExternal, deltaTime } = this.state.filter;
        const { loadingSameEstates, sameEstates, sameEstatesErrors } = estateStore.getItem(estate_id).property;

        return (
            <div style={{ marginTop: '-1em', position: 'relative' }}>
                <ListError errors={sameEstatesErrors} />

                <ApproximationInput approximation={approximation} onChange={this.handleChangeApproximation}>
                    <Menu.Item className="crm-List__filter_dropdown">
                        <ButtonGroup
                            buttonSet={[
                                [0, 'По-умолчанию'],
                                [1, 'По дому'],
                                [2, 'По карте']
                            ]}
                            name="sameEstateLocationType"
                            value={sameEstateLocationType}
                            handleChange={this.handleChangeLocationType}
                        />

                        <FormControlLabel
                            control={
                                <RedCheckbox checked={!disableExternal} onChange={this.handleToggleDisableExternal} value="checkedG" />
                            }
                            label="Внешние"
                            title="Объекты из вненшних баз"
                        />

                        <DateRangeSelect onChange={this.handleTimesChange} initialValue={deltaTime} />
                    </Menu.Item>

                    <Menu.Menu position="right">
                        {pultMenu.map(menu => (
                            <Menu.Item
                                key={menu.name}
                                name={menu.name}
                                color={menu.color}
                                active={enable === menu.enable}
                                onClick={this.toggleEnableMode}
                            />
                        ))}
                    </Menu.Menu>
                </ApproximationInput>

                <Table structured size="small" sortable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>Местоположение</Table.HeaderCell>
                            <Table.HeaderCell>Площадь</Table.HeaderCell>
                            <Table.HeaderCell>Этаж</Table.HeaderCell>
                            <Table.HeaderCell>Цена</Table.HeaderCell>
                            <Table.HeaderCell>Описание</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {sameEstates && <EstateTableBody smaEstateId={estate_id} estateList={sameEstates} simpleMode />}
                </Table>
                {!loadingSameEstates && sameEstates && sameEstates.length === 0 && (
                    <WarningMessage>Похожие объекты не найдены</WarningMessage>
                )}

                <LoaderAwait active={loadingSameEstates} size="large" />
            </div>
        );
    }
}

export default EstateSameMode;
