import React, { Component, ComponentType } from 'react';
import settingStore from '~/stores/settingStore';
import { ExportBaseIdStat } from '~/types/exportStatistics.types';
import { TestReportAnswer } from '~/types/exports.types';
import ListError from '~/components/ListError';
import TestReportAnswerBlock from '~/components/Ads/BaseApiSettings/TestReportAnswerBlock';
import { observer } from 'mobx-react';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';

type BaseApiEditingWrapperState = {
    loadingTest: boolean;
    errors: string[];
    answer: TestReportAnswer | null;
};

export type BaseApiEditingInnerProps = {
    apiIndex: number;
};

export type BaseApiEditingWrappedProps = BaseApiEditingInnerProps & {
    handleEditValue: HandleChangeType;
    checkTest: () => Promise<void>;
    loadingTest: boolean;
    answer: TestReportAnswer | null;
};

function BaseApiEditingWrapper(
    WrappedComponent: ComponentType<BaseApiEditingWrappedProps>,
    export_base_id: ExportBaseIdStat,
    settings_key: string
): ComponentType<BaseApiEditingInnerProps> {
    @observer
    class BaseApiEditing extends Component<BaseApiEditingInnerProps, BaseApiEditingWrapperState> {
        constructor(props) {
            super(props);

            // settingStore.fetchItem(settings_key, null, true);

            this.state = {
                loadingTest: false,
                errors: [],
                answer: null
            };
        }

        checkTest = async (): Promise<void> => {
            this.setState({ loadingTest: true, errors: [] });

            try {
                const answer = await settingStore.checkBaseApiTest(settings_key, export_base_id, this.props.apiIndex);
                this.setState({ answer });
            } catch (errors) {
                this.setState({ errors });
            } finally {
                this.setState({ loadingTest: false });
            }
        };

        handleEditValue =
            (apiIndex: number) =>
            (event, { value, name }: { value: string; name: string }) => {
                const { editingItem } = settingStore.getItem(settings_key);
                let parsedValue = JSON.parse(editingItem.value);
                parsedValue[apiIndex][name] = value;

                settingStore.setEditingItem(settings_key, { value: JSON.stringify(parsedValue) });
            };

        render() {
            const { errors, answer, loadingTest } = this.state;
            const { apiIndex } = this.props;

            return (
                <>
                    <WrappedComponent
                        handleEditValue={this.handleEditValue(apiIndex)}
                        checkTest={this.checkTest}
                        loadingTest={loadingTest}
                        answer={answer}
                        apiIndex={apiIndex}
                    />
                    <ListError errors={errors} />
                    {answer && <TestReportAnswerBlock answer={answer} />}
                </>
            );
        }
    }

    return BaseApiEditing;
}

export default BaseApiEditingWrapper;
