import { observable, action, computed } from 'mobx';

import ListStorePrototype, { ApiModuleTypeList, OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { CoordinatesCorner, CoordinatesSquare, ESTATE_BASE_FOREIGN, ShareEstateId } from '~/types/estate.types';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { initSearchMap, searchMap, updateMapCluster } from '~/common/osmMapApi';
import config from '~/config/ui.config';
import commonStore from '~/stores/commonStore';
import debounce from '~/common/debounce';
import { TItemExtended } from '~/stores/prototypes/ItemStore.prototype';
import { ITEM_TYPES } from '~/types/notifications.types';

export interface ApiModuleTypeMap<ItemObjectType extends TItemExtended, ItemListType, ItemListFilter>
    extends ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter> {
    fetchItemsOnMap: (
        filter: Partial<ItemListFilter>,
        coordinates: CoordinatesSquare,
        limit: number,
        orderBy: string,
        orderDirection: OrderDirectionType,
        controller?: AbortController
    ) => Promise<ItemListType[]>;

    fetchItemsCountOnMap: (
        filter: Partial<ItemListFilter>,
        coordinates: CoordinatesSquare,
        orderBy: string,
        ontroller?: AbortController
    ) => Promise<number>;
}

const ForeignMapDefaultConfig = {
    geo_lon: 33.76159,
    geo_lat: 44.576449,
    zoom: 10
};

abstract class ListStoreMapPrototype<
    ItemObjectType extends TItemExtended,
    ItemListType,
    ItemPropertyType,
    ItemListFilter extends { regionId: number },
    ItemListKey extends ShareEstateId | number
> extends ListStorePrototype<ItemObjectType, ItemListType, ItemPropertyType, ItemListFilter> {
    ApiModuleList: ApiModuleTypeMap<ItemObjectType, ItemListType, ItemListFilter>;

    constructor(item_id: string, moduleName: ITEM_TYPES, ApiModule: ApiModuleTypeMap<ItemObjectType, ItemListType, ItemListFilter>) {
        super(item_id, moduleName, ApiModule);
        this.ApiModuleList = ApiModule;
    }

    COUNT_ITEMS_ON_MAP: number = 18;

    @action
    async fetchList(orderBy?: string, orderDirection?: OrderDirectionType) {
        if (this.modeSearchOnMap) {
            this.orderBy = orderBy;
            this.orderDirection = orderDirection;
            this.selectedMapIds = [];
            await this.mapChange();
        } else {
            await super.fetchList(orderBy, orderDirection);
        }
    }

    debounceFilterFetch = debounce(() => {
        if (this.modeSearchOnMap) {
            this.mapChange();
        } else {
            this.fetchList(this.orderBy, this.orderDirection);
            this.filterHasChanged = false;
        }
    }, 350);

    @observable
    modeSearchOnMap: boolean = false;

    @action
    toggleSearchOnMap = () => {
        this.modeSearchOnMap = !this.modeSearchOnMap;
        if (this.modeSearchOnMap) {
            const { regionId } = this.listFilter;
            let geo_lon;
            let geo_lat;
            let zoom = 10;

            // @ts-ignore
            if (this.listFilter?.base === ESTATE_BASE_FOREIGN) {
                geo_lon = ForeignMapDefaultConfig.geo_lon;
                geo_lat = ForeignMapDefaultConfig.geo_lat;
                zoom = 4;
            } else if (regionId) {
                const region = exportingBaseStore.regionsById.get(regionId);
                if (region && region['coordinates']) {
                    geo_lon = region['coordinates'].y;
                    geo_lat = region['coordinates'].x;
                }
            }
            initSearchMap('mapSearch', geo_lon || config.defaultCoords[0], geo_lat || config.defaultCoords[1], this, zoom);
        } else {
            this.list = [];
            this.fetchList();
        }
        commonStore.toggleFullSizePage();
    };

    @observable
    selectedMapIds: ItemListKey[] = [];

    @action
    setSelectedMapItems = (itemIds: ItemListKey[]) => {
        this.selectedMapIds = itemIds;
    };

    @action
    showNextOnMap = () => {
        this.setSelectedMapItems(
            this.list
                .slice(0, this.selectedMapIds.length + (commonStore.isMobile ? 6 : this.COUNT_ITEMS_ON_MAP))
                .map(item => this.matchSelectedItemsFiled(item))
        );
    };

    abstract matchSelectedItemsFiled(item: ItemListType): ItemListKey;

    @action
    unmountSearchOnMap = () => {
        commonStore.restorePageSize();
        this.modeSearchOnMap = false;
        this.showFilterOnMap = false;
        this.selectedMapIds = [];
        this.list = [];
        this.listCount = 0;
    };

    @observable
    showFilterOnMap: boolean = false;

    @action
    setFilterOnMap(showing: boolean) {
        this.showFilterOnMap = showing;
    }

    @action
    async fetchItemsOnMap(coordinates: CoordinatesSquare, controller?: AbortController): Promise<ItemListType[]> {
        return await this.ApiModuleList.fetchItemsOnMap(
            this.listFilter,
            coordinates,
            commonStore.isMobile ? 100 : 750,
            this.orderBy || 'updateTime',
            this.orderDirection,
            controller
        );
    }

    fetchMapAC: AbortController | null;
    preventOnMapChange: boolean = false;

    setPreventOnMapChange = () => {
        this.preventOnMapChange = true;
    };

    @action
    mapChange = async () => {
        if (!searchMap) {
            return;
        }

        this.loadingList = true;
        const { _northEast, _southWest }: { _northEast: CoordinatesCorner; _southWest: CoordinatesCorner } = searchMap.getBounds();

        try {
            if (this.fetchMapAC) {
                this.fetchMapAC.abort();
            }
            const controller = window.AbortController ? new window.AbortController() : undefined;
            // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
            if (controller) {
                window.setTimeout(() => {
                    this.fetchMapAC = controller;
                }, 0);
            }

            this.loadingCount = true;
            console.time('mapChange');
            const coordinates = { bottomLeft: _southWest, topRight: _northEast };
            const list = await this.fetchItemsOnMap(coordinates, controller);
            console.timeEnd('mapChange');
            console.time('updateMapCluster');
            updateMapCluster(list, this);

            if (!this.preventOnMapChange && !commonStore.isMobile) {
                this.setSelectedMapItems(list.slice(0, this.COUNT_ITEMS_ON_MAP).map(item => this.matchSelectedItemsFiled(item)));
            } else {
                this.preventOnMapChange = false;
            }
            console.timeEnd('updateMapCluster');
            this.list = list;

            this.listCount = await this.ApiModuleList.fetchItemsCountOnMap(
                this.listFilter,
                coordinates,
                this.orderBy || 'updateTime',
                controller
            );
            this.loadingCount = false;
        } catch (error) {
            if (this.fetchMapAC && this.fetchMapAC.signal.aborted === true) {
                return;
            }

            console.error('error', error);
            this.listErrors = [error.toString()];
        } finally {
            this.loadingList = false;
        }
    };

    @computed
    get availableMoreOnMap(): boolean {
        return this.selectedMapIds.length < this.listCount - (commonStore.isMobile ? 6 : this.COUNT_ITEMS_ON_MAP);
    }
}

export default ListStoreMapPrototype;
