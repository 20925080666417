import fetchApi from '~/common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { CellOperatorType } from '~/types/cellCalls.types';

const CellOperatorGQLFields = `
    cell_operator_id
    operator
    name
    token
    externalUserId
    url

    createTime
    updateTime
    enable
`;

export const createItem = async (cellOperator: CellOperatorType): Promise<number> => {
    const graphql = `mutation { 
          createCellOperator(inputCellOperator: ${objectToGraphql(cellOperator)})
        }`;

    const data: { createCellOperator: number } = await fetchApi.postGQ(graphql);
    return data.createCellOperator;
};

export const saveItem = async (cell_operator_id: number, cellOperatorDiff: Partial<CellOperatorType>): Promise<number> => {
    const graphql = `mutation { 
        updateCellOperator(cell_operator_id: ${cell_operator_id}, cellOperatorDiff: ${objectToGraphql(cellOperatorDiff)})
    }`;

    const data: { updateCellOperator: number } = await fetchApi.postGQ(graphql);
    return data.updateCellOperator;
};

export const fetchItem = async (cell_operator_id: number): Promise<CellOperatorType> => {
    const graphql = `{
            fetchCellOperator(cell_operator_id: ${cell_operator_id}) {
                ${CellOperatorGQLFields}
            } 
          }`;

    const data: { fetchCellOperator: CellOperatorType } = await fetchApi.getGQ(graphql);
    return data.fetchCellOperator;
};

export const fetchList = async (): Promise<{ list: CellOperatorType[]; count: number }> => {
    const graphql = `{
            fetchCellOperators{
                list {
                    ${CellOperatorGQLFields}
                }
                count
            }
          }`;

    const data: { fetchCellOperators: { list: CellOperatorType[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchCellOperators;
};
