import React from 'react';
import { addDays, format, getUnixTime } from 'date-fns';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import exportStore from '~/stores/export/exportStore';
import DatePicker from '~/components/Base/DatePicker';
import Button from '~ui/Button';

const ExportExpireTime = ({ estate_id, export_base_id, expireTime }: { estate_id: number; export_base_id: number; expireTime: number }) => {
    const handleChangeExpireTime = (time: number) => {
        exportStore.changeExpireTime(estate_id, export_base_id, time);
    };

    const handleResetTime = () => {
        exportStore.changeExpireTime(estate_id, export_base_id, 0);
    };

    if (expireTime) {
        return (
            <ListItemSecondaryAction style={{ transform: 'none' }}>
                <Button onClick={handleResetTime} size="small" tooltip="Сбросить время">
                    до {expireTime === 0 ? `∞` : format(new Date(expireTime * 1000), 'd MMM')}
                </Button>
            </ListItemSecondaryAction>
        );
    }

    return (
        <ListItemSecondaryAction style={{ transform: 'none' }}>
            <DatePicker
                minDate={getUnixTime(addDays(new Date(), 1))}
                customInput={<button>до {expireTime === 0 ? `∞` : format(new Date(expireTime * 1000), 'd MMM')}</button>}
                onChange={time => handleChangeExpireTime(time)}
                withPortal
            />
        </ListItemSecondaryAction>
    );
};

export default ExportExpireTime;
