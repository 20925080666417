import React, { Component, Fragment } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { EstateLinkTypeWithBase } from '~/types/estate.types';
import EstateLink from './EstateLink';
import { ESTATE_BASE_OWNERS } from '~/types/estate.types';
import EstateLinkWithPreview from './EstateLinkWithPreview';

const EstatesLinkList = ({ estatesList }: { estatesList?: EstateLinkTypeWithBase[] }) => {
    return (
        <Fragment>
            {estatesList && estatesList.length === 1 ? (
                <Fragment>
                    {estatesList[0].base === ESTATE_BASE_OWNERS && <Icon name="male" color="brown" title="Собственник" />}
                    <EstateLinkWithPreview key={estatesList[0].estate_id} estate={estatesList[0]} base={estatesList[0].base} />
                </Fragment>
            ) : null}
            {estatesList && estatesList.length > 1 && (
                <Popup
                    size="small"
                    hoverable
                    trigger={
                        <span className="crm-User__linkList_trigger">
                            {estatesList[0].base === ESTATE_BASE_OWNERS && <Icon name="male" color="brown" title="Собственник" />}
                            <EstateLink key={estatesList[0].estate_id} estate={estatesList[0]} base={estatesList[0].base} />
                            <b>&nbsp;{`+${estatesList.length - 1}`}</b>
                        </span>
                    }
                >
                    <span>
                        {estatesList.map(estate => (
                            <Fragment key={estate.estate_id}>
                                {estate.base === ESTATE_BASE_OWNERS && <Icon name="male" color="brown" title="Собственник" />}
                                <EstateLinkWithPreview estate={estate} base={estate.base} />
                                <br />
                            </Fragment>
                        ))}
                    </span>
                </Popup>
            )}
        </Fragment>
    );
};

export default EstatesLinkList;
