import React, { useMemo } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import { ExportUserEstate } from '~/types/exports.types';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import ListFooter from '~/components/Lists/Common/ListFooterNew';
import EstateLinkWithPreview from '~/components/Lists/Estate/EstateLinkWithPreview';
import LoaderAwait from '~/components/Base/LoaderAwait';
import { WarningMessage } from '~ui/Message';
import Table, { TableBody, TableHead, TableCell, TableRow } from '~ui/Table';

const ShowExportEstates = ({ dayEstates, dayCalls }: { dayEstates: ExportUserEstate[]; dayCalls: ExportUserEstate[] }) => {
    return (
        <Popup
            trigger={
                <span>
                    <Icon circular color="red" name="info" size="small" title="Информация об экспорте" style={{ cursor: 'pointer' }} />{' '}
                    Объектов: {dayEstates.length}
                    {dayCalls.length > 0 && ` (Со звонками: ${dayCalls.length})`}&nbsp;
                </span>
            }
            wide="very"
            size="small"
            on="click"
            style={{ maxHeight: '50%', overflowY: 'auto', overflowX: 'hidden' }}
            content={
                <List>
                    {dayEstates.map(({ estate, cost, redeemTime }) => (
                        <List.Item key={estate.estate_id}>
                            <List.Content>
                                <List.Description className="crm-Estate__redeem">
                                    <b>{cost}</b>₽ :: №{estate.estate_id} &nbsp;{' '}
                                    <EstateLinkWithPreview estate={estate} base={ESTATE_BASE_MAIN} />
                                    {redeemTime > 0 && (
                                        <span className="crm-Estate__redeem_info">
                                            (Погашено: {new Date(redeemTime * 1000).toLocaleDateString('ru')})
                                        </span>
                                    )}
                                    {redeemTime === 0 && <span className="crm-Estate__redeem_info">(Частично погашено)</span>}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}

                    {dayCalls.map(({ estate, cost, redeemTime }) => (
                        <List.Item key={estate.estate_id}>
                            <List.Content>
                                <List.Description className="crm-Estate__redeem">
                                    <Icon name="phone" /> <b>{cost}</b>₽ :: №{estate.estate_id} &nbsp;{' '}
                                    <EstateLinkWithPreview estate={estate} base={ESTATE_BASE_MAIN} />
                                    {redeemTime && (
                                        <span className="crm-Estate__redeem_info">
                                            (Погашено: {new Date(redeemTime * 1000).toLocaleDateString('ru')})
                                        </span>
                                    )}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            }
            position="left center"
        />
    );
};

const RedeemTime = ({ dayEstates }: { dayEstates: ExportUserEstate[] }) => {
    const { redeemTime, redeemCount } = dayEstates.reduce(
        (acc, { redeemTime }) => {
            if (redeemTime) {
                if (acc.redeemTime < redeemTime) {
                    acc.redeemTime = redeemTime;
                }
                acc.redeemCount++;
            }
            return acc;
        },
        { redeemTime: 0, redeemCount: 0 }
    );

    if (!redeemCount) {
        return null;
    }
    const hasAllRedeem = dayEstates.length === redeemCount;

    return (
        <span className="crm-Estate__redeem_info">
            ({hasAllRedeem ? `Погашено: ` : 'Частично погашено'}
            {hasAllRedeem && <b style={{ color: 'green' }}>{new Date(redeemTime * 1000).toLocaleDateString('ru')}</b>})
        </span>
    );
};

const UserBalanceMode = ({ user_id }: { user_id: number }) => {
    useMemo(() => {
        userStore.balanceHistoryByUser(user_id);
    }, []);

    const handlePageChange = (activePage: number) => {
        userStore.changeBalancePaginationPage(user_id, activePage);
    };

    const handlePageSizeChange = (pageSize: number) => {
        userStore.changeBalancePaginationPageSize(user_id, pageSize);
    };

    const { exportHistory, loadingExportHistory, exportHistoryCount, exportHistoryPagination } = userStore.getItem(user_id).property;

    return (
        <div style={{ position: 'relative' }}>
            {exportHistory instanceof Array && (
                <Table size="small" compact>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Сумма</TableCell>
                            <TableCell>Инфо</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {exportHistory.map(({ dayCost, time, dayEstates, dayCalls }) => (
                            <TableRow key={time}>
                                <TableCell>{new Date(time * 1000).toLocaleDateString('ru')}</TableCell>
                                <TableCell>{dayCost.toLocaleString()}</TableCell>
                                <TableCell>
                                    <ShowExportEstates dayEstates={dayEstates} dayCalls={dayCalls} />
                                    <RedeemTime dayEstates={dayEstates} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <ListFooter
                        loading={loadingExportHistory}
                        colSpan={2}
                        pagination={exportHistoryPagination}
                        listCount={exportHistoryCount || 0}
                        pageChange={handlePageChange}
                        pageSizeChange={handlePageSizeChange}
                    />
                </Table>
            )}
            {!loadingExportHistory && exportHistory && exportHistory.length === 0 && (
                <WarningMessage>За данный период экспорт не найден, попробуйте переключить страницу</WarningMessage>
            )}

            {loadingExportHistory && <LoaderAwait dimmer={false} active size="large" />}
        </div>
    );
};

export default observer(UserBalanceMode);
