import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';

import TableStatisticsDefault from './TableStatisticsDefault';
import TableKpi from '~/components/PultToolbar/Kpi/TableKpi';
import LoaderAwait from '~/components/Base/LoaderAwait';

const TableStatistics = () => {
    const { loadingMainTable, mainTableKpi } = kpiStore;

    useMemo(() => {
        kpiStore.fetchItem(mainTableKpi);
        kpiStore.changeFilter('kpi_id', mainTableKpi);
    }, [mainTableKpi]);

    if (loadingMainTable) {
        return <LoaderAwait active dimmer />;
    }

    if (mainTableKpi) {
        return <TableKpi kpi_id={mainTableKpi} showStatFooter />;
    }

    return <TableStatisticsDefault />;
};

export default observer(TableStatistics);
