import fetchApi from '../common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { SettingsProperty } from '~/types/settings.types';

const SettingsGQLFields = `
    settings_key
    value
`;

export const createItem = async (settings: SettingsProperty): Promise<number> => {
    return await 0;
};

export const saveItem = async (settings_key: number, settings: SettingsProperty): Promise<number> => {
    const graphql = `mutation { 
        updateSettingsKey(settings_key: "${settings_key}", value: ${objectToGraphql(settings.value)})
    }`;

    const data: { updateSettingsKey: number } = await fetchApi.postGQ(graphql);
    return data.updateSettingsKey;
};

export const fetchItem = async (settings_key: number): Promise<SettingsProperty> => {
    const graphql = `{
            fetchSettingsKey(settings_key: "${settings_key}") {
                ${SettingsGQLFields}
            } 
          }`;

    const data: { fetchSettingsKey: SettingsProperty } = await fetchApi.getGQ(graphql);
    return data.fetchSettingsKey;
};
