import React, { Component, Fragment } from 'react';
import { Menu, Input, Dropdown, Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import contactStore from '~/stores/contactStore';

import ListFilterWrapper from '../ListFilterWrapper';
import { ListFilterWrapperProps } from '../ListFilterWrapper';
import FilterContactsMoreDetails from './FilterBlocks/FilterContactsMoreDetails';
import tagStore from '~/stores/tagStore';

@observer
class ContactsFilter extends Component<ListFilterWrapperProps> {
    changeSearch = (event: { target: { value: string } }) => {
        contactStore.changeFilter('search', event.target.value);
    };

    handleTagsIds = (event: React.SyntheticEvent, { value }: { value: Array<number> }) => {
        contactStore.changeFilter('tags_ids', value);
    };

    render() {
        const { search, tags_ids, withoutAgent, tagsTogether } = contactStore.listFilter;

        const { allTagsDropdown, loadingFetchAllTags } = tagStore;

        return (
            <Fragment>
                <Menu.Item>
                    <Input value={search} transparent className="icon" icon="search" placeholder="Поиск..." onChange={this.changeSearch} />
                </Menu.Item>
                <Menu.Item className="crm-List__filter_dropdown">
                    <Form size="mini">
                        <Dropdown
                            multiple={true}
                            selection
                            options={allTagsDropdown}
                            search
                            placeholder="Тэги контактов"
                            value={tags_ids}
                            noResultsMessage="Тэг не найден"
                            onChange={this.handleTagsIds}
                            loading={loadingFetchAllTags}
                        />
                    </Form>
                </Menu.Item>
                <Menu.Item>
                    <Checkbox name="tagsTogether" checked={tagsTogether} label="тэги вместе" onChange={this.props.handleCheckbox} />
                </Menu.Item>
            </Fragment>
        );
    }
}

export default ListFilterWrapper(contactStore, ContactsFilter, FilterContactsMoreDetails);
