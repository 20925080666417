import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';

import { MlsAgency } from '~/types/estate.types';

const MlsAgencyGQLFields = `
    mls_company_id
    url
    logoUrl
    shortTitle
    title
    format
    feedUrl
    contacts
    phone
    color
    enable
`;

export const createItem = async (mlsCompany: MlsAgency): Promise<number> => {
    const graphql = `mutation { 
          createMlsCompany(mlsCompanyInput: ${objectToGraphql(mlsCompany)})
        }`;

    const data: { createMlsCompany: number } = await fetchApi.postGQ(graphql);
    return data.createMlsCompany;
};

export const saveItem = async (mls_company_id: number, mlsCompany: MlsAgency): Promise<number> => {
    const graphql = `mutation { 
        updateMlsCompany(mls_company_id: ${mls_company_id}, mlsCompanyDiff: ${objectToGraphql(mlsCompany)})
    }`;

    const data: { updateMlsCompany: number } = await fetchApi.postGQ(graphql);
    return data.updateMlsCompany;
};

export const fetchItem = async (mls_company_id: number): Promise<MlsAgency> => {
    const graphql = `{
            fetchMlsCompany(mls_company_id: ${mls_company_id}) {
                ${MlsAgencyGQLFields}
            } 
          }`;

    const data: { fetchMlsCompany: MlsAgency } = await fetchApi.getGQ(graphql);
    return data.fetchMlsCompany;
};

export const fetchList = async (): Promise<{ list: Array<MlsAgency>; count: number }> => {
    const graphql = `{
            fetchMlsCompanies{
                list {
                    ${MlsAgencyGQLFields}
                }
                count
            }
          }`;

    const data: { fetchMlsCompanies: { list: MlsAgency[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchMlsCompanies;
};
