import React, { Component, Fragment, useEffect } from 'react';
import { Input, Dropdown, Form, Checkbox, Grid, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import ButtonGroup from '../../../Base/ButtonGroup';
import estateStore from '~/stores/estateStore';
import estateL10n from '../../../../L10n/estate.L10n';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import EditField from '../../../Base/EditField';
import {
    ESTATE_PROPERTY_COMMERCE_ARR,
    ESTATE_PROPERTY_FLATS_ARR,
    ESTATE_PROPERTY_GARAGE_ARR,
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID
} from '~/types/estate.types';
import settingStore from '~/stores/settingStore';
import Divider from '~ui/Divider';
import { PriceInput } from '~ui/TextInput';

type PriceBlockEditingProps = HandlerEditingWrappedProps & {
    type: number;
    price?: number;
    currency?: number;
    mortgage?: boolean;
    saleType?: number;
    propertyType?: number;

    utilitiesIncluded?: boolean | null;
    otherUtilities?: boolean | null;
    otherUtilitiesPayment?: number | null;
    operationalCostsIncluded?: boolean | null;
    bargainAllowed?: boolean | null;
    bargainPrice?: number | null;
    bargainConditions?: string | null;
    leaseTermType?: number;
    prepayMonths?: number;
    deposit?: number | null;

    clientFee?: number | null;
    agentFee?: number | null;

    vatType?: number | null;
    contractType?: number | null;
    isFake?: boolean | null;
};

const PriceBlockRentFlatsEditing = observer(
    ({
        estate_id,
        handleCheckbox,
        handleChange
    }: {
        estate_id: number;
        handleCheckbox: PriceBlockEditingProps['handleCheckbox'];
        handleChange: PriceBlockEditingProps['handleChange'];
    }) => {
        const { utilitiesIncluded, otherUtilities, otherUtilitiesPayment, propertyType } = estateStore.getItem(estate_id).editingItem;

        useEffect(() => {
            if (otherUtilities) {
                estateStore.setEditingItem(estate_id, { otherUtilitiesPayment: null });
            }
        }, [otherUtilities]);

        const isAdvance = [ESTATE_PROPERTY_TYPE_FLAT, ...ESTATE_PROPERTY_HOUSES].includes(propertyType);

        return (
            <>
                <Grid.Column>
                    <Checkbox
                        checked={Boolean(utilitiesIncluded)}
                        name="utilitiesIncluded"
                        label={<label>Оплата по счётчикам включена</label>}
                        onClick={handleCheckbox}
                        className="crm-Estate__field_rightMargin"
                    />
                </Grid.Column>
                {isAdvance && (
                    <>
                        <Grid.Column>
                            <Checkbox
                                checked={Boolean(otherUtilities)}
                                name="otherUtilities"
                                label={<label>Другие ЖКУ (отопление, капремонт, вывоз мусора и другие услуги) включены</label>}
                                onClick={handleCheckbox}
                                className="crm-Estate__field_rightMargin"
                            />
                        </Grid.Column>
                        {!otherUtilities && (
                            <Grid.Column>
                                <PriceInput
                                    label="Плата за другие ЖКУ (не по счетчикам)"
                                    onChange={handleChange}
                                    name="otherUtilitiesPayment"
                                    value={otherUtilitiesPayment}
                                    variant="outlined"
                                    extraLabel="₽ в месяц"
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column>
                            <Divider />
                        </Grid.Column>
                    </>
                )}
            </>
        );
    }
);

@observer
class PriceBlockEditing extends Component<PriceBlockEditingProps> {
    constructor(props: PriceBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            type: props.type,
            price: props.price || 0,
            currency: props.currency || 1,
            mortgage: Boolean(props.mortgage),
            saleType: props.saleType || 0,
            propertyType: props.propertyType || 1,

            utilitiesIncluded: props.utilitiesIncluded || null,
            otherUtilities: props.otherUtilities || null,
            otherUtilitiesPayment: props.otherUtilitiesPayment || null,
            operationalCostsIncluded: props.operationalCostsIncluded || null,
            bargainAllowed: props.bargainAllowed || null,
            bargainPrice: props.bargainPrice || null,
            bargainConditions: props.bargainConditions || null,
            leaseTermType: props.leaseTermType || 1,
            prepayMonths: props.prepayMonths || 0,
            deposit: props.deposit || null,

            vatType: props.vatType || 1,
            contractType: props.contractType || null,

            clientFee: typeof props.clientFee === 'number' ? props.clientFee : null,
            agentFee: typeof props.agentFee === 'number' ? props.agentFee : null,

            isFake: typeof props.isFake === 'boolean' ? props.isFake : null
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    handleContractType = (event: React.SyntheticEvent, { checked }: { checked: boolean }) => {
        estateStore.setEditingItem(this.props.item_id, {
            contractType: checked ? 1 : 2
        });
    };

    render() {
        const { handleChange, handleCheckbox, handlePrice, editing, item_id } = this.props;

        const {
            type,
            propertyType,
            price,
            currency,
            mortgage,
            saleType,
            utilitiesIncluded,
            operationalCostsIncluded,
            bargainAllowed,
            bargainPrice,
            bargainConditions,
            leaseTermType,
            prepayMonths,
            deposit,
            vatType,
            contractType,
            clientFee,
            agentFee,
            isFake
        } = estateStore.getItem(item_id).editingItem;

        const priceEditingBlock = saleType === 0 && type === ESTATE_TYPE_SELL_ID && propertyType < ESTATE_PROPERTY_TYPE_OFFICE;

        return (
            <Fragment>
                {type === ESTATE_TYPE_SELL_ID && propertyType < ESTATE_PROPERTY_TYPE_OFFICE && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">Тип сделки</label>
                            <ButtonGroup buttonSet={estateL10n.SALE_TYPES} name="saleType" value={saleType} handleChange={handleChange} />
                            {priceEditingBlock && (
                                <Label basic color="red" pointing="left">
                                    Укажите тип сделки
                                </Label>
                            )}
                        </Form.Field>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Цена</label>
                        <Input
                            required
                            size="small"
                            label={
                                <Dropdown
                                    value={currency}
                                    name="currency"
                                    onChange={handleChange}
                                    options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                />
                            }
                            labelPosition="right"
                            placeholder="Цена"
                            value={price ? price.toLocaleString() : ''}
                            onChange={handlePrice}
                            name="price"
                            className={cs({ 'crm-Estate__field_rightMargin': true, 'crm-Estate__field_tight': editing })}
                            disabled={priceEditingBlock}
                        />
                        {!editing && type === ESTATE_TYPE_RENT_ID && <span style={editing ? { marginLeft: '35px' } : null}> в месяц</span>}
                        {type === ESTATE_TYPE_SELL_ID &&
                            [...ESTATE_PROPERTY_FLATS_ARR, ...ESTATE_PROPERTY_HOUSES].includes(propertyType) && (
                                <Checkbox
                                    checked={mortgage}
                                    name="mortgage"
                                    toggle
                                    label={<label>Ипотека</label>}
                                    onClick={handleCheckbox}
                                />
                            )}
                    </Form.Field>
                </Form.Group>

                {type === ESTATE_TYPE_RENT_ID && ESTATE_PROPERTY_COMMERCE_ARR.includes(propertyType) && (
                    <Grid columns={editing ? 1 : 2}>
                        <Grid.Column>
                            <Checkbox
                                checked={Boolean(utilitiesIncluded)}
                                name="utilitiesIncluded"
                                label={<label>Коммунальные включены</label>}
                                onClick={handleCheckbox}
                                className="crm-Estate__field_rightMargin"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox
                                checked={Boolean(operationalCostsIncluded)}
                                name="operationalCostsIncluded"
                                label={<label>Операционные расходы включены</label>}
                                onClick={handleCheckbox}
                                className="crm-Estate__field_rightMargin"
                            />
                        </Grid.Column>
                    </Grid>
                )}

                {type === ESTATE_TYPE_RENT_ID &&
                    [...ESTATE_PROPERTY_FLATS_ARR, ...ESTATE_PROPERTY_HOUSES, ...ESTATE_PROPERTY_GARAGE_ARR].includes(propertyType) && (
                        <Fragment>
                            <Grid columns={editing ? 1 : 2}>
                                <PriceBlockRentFlatsEditing
                                    estate_id={item_id}
                                    handleCheckbox={handleCheckbox}
                                    handleChange={handleChange}
                                />
                                <Grid.Column>
                                    <Dropdown
                                        value={leaseTermType}
                                        name="leaseTermType"
                                        onChange={handleChange}
                                        options={estateL10n.LEASE_TYPES_DROPDOWN}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Dropdown
                                        value={prepayMonths}
                                        name="prepayMonths"
                                        onChange={handleChange}
                                        options={estateL10n.PREPAYMENT_TYPES_DROPDOWN}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <PriceInput
                                        label="Депозит"
                                        name="deposit"
                                        value={deposit || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column>
                                    <Form.Group>
                                        <Form.Field inline>
                                            <label className="crm-Estate__field_label">
                                                <Checkbox
                                                    checked={bargainAllowed || false}
                                                    name="bargainAllowed"
                                                    toggle
                                                    label={<label>Торг</label>}
                                                    onClick={handleCheckbox}
                                                />
                                            </label>
                                            {bargainAllowed && (
                                                <Fragment>
                                                    <div className="crm-Estate__fieldNowrap">
                                                        <label>Цена с торгом</label>
                                                        &nbsp;
                                                        <Input
                                                            type="number"
                                                            placeholder="Цена с торгом"
                                                            size="mini"
                                                            min={0}
                                                            max={9999999}
                                                            name="bargainPrice"
                                                            value={bargainPrice || ''}
                                                            onChange={handleChange}
                                                        />
                                                        &nbsp;&nbsp;
                                                    </div>
                                                    <div className="crm-Estate__fieldNowrap">
                                                        <label>Условие торга</label>
                                                        &nbsp;
                                                        <Input
                                                            placeholder="Условие торга"
                                                            size="mini"
                                                            maxLength={255}
                                                            name="bargainConditions"
                                                            value={bargainConditions || ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Form.Field>
                                    </Form.Group>
                                </Grid.Column>
                            </Grid>
                        </Fragment>
                    )}
                {type === ESTATE_TYPE_RENT_ID && ESTATE_PROPERTY_COMMERCE_ARR.includes(propertyType) && (
                    <Grid columns={editing ? 1 : 2}>
                        <Grid.Column>
                            <label>Обеспечительный платеж</label>
                            &nbsp;
                            <Input
                                type="number"
                                placeholder="Обеспечительный платеж"
                                size="mini"
                                min={0}
                                max={9999999}
                                name="deposit"
                                value={deposit || ''}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid>
                )}
                {type === ESTATE_TYPE_RENT_ID && (
                    <Grid columns={editing ? 1 : 2}>
                        <Grid.Column>
                            <label>Комиссия агентская</label>
                            &nbsp;
                            <Input
                                type="number"
                                placeholder="Агентская комиссия"
                                size="mini"
                                min={0}
                                max={9999999}
                                name="agentFee"
                                value={typeof agentFee === 'number' ? agentFee : ''}
                                onChange={handleChange}
                            />
                            %
                        </Grid.Column>
                        <Grid.Column>
                            <label>Комиссия клиентская</label>
                            &nbsp;
                            <Input
                                type="number"
                                placeholder="Клиентская комиссия"
                                size="mini"
                                min={0}
                                max={9999999}
                                name="clientFee"
                                value={typeof clientFee === 'number' ? clientFee : ''}
                                onChange={handleChange}
                            />
                            %
                        </Grid.Column>
                    </Grid>
                )}
                {ESTATE_PROPERTY_COMMERCE_ARR.includes(propertyType) && (
                    <Fragment>
                        <EditField.Drop
                            onChange={handleChange}
                            label="Налог"
                            name="vatType"
                            value={vatType}
                            options={estateL10n.VAT_TYPES_DROPDOWN}
                        />
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">&nbsp;</label>
                                <Checkbox
                                    label={<label>Переуступка права аренды</label>}
                                    name="contractType"
                                    checked={contractType === 1}
                                    onChange={this.handleContractType}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Fragment>
                )}
                {settingStore.mainConfig.enableFakeEstate && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">&nbsp;</label>
                            <Checkbox
                                checked={Boolean(isFake)}
                                name="isFake"
                                toggle
                                label={<label>Фейковый объект</label>}
                                onClick={handleCheckbox}
                            />
                        </Form.Field>
                    </Form.Group>
                )}
            </Fragment>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, PriceBlockEditing);
