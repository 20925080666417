import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Modal, Dimmer, Loader, Table } from 'semantic-ui-react';
import DropzoneComponent from 'react-dropzone-component';
import config from '../../../config/ui.config';
import { authHeaders } from '~/common/fetchApi';
import ListError from '../../ListError';
import exportStore from '~/stores/export/exportStore';
import { ExportReportsAnswer } from '~/types/exports.types';
import { ESTATE_BASE_MAIN, ESTATE_TYPE_SELL } from '~/types/estate.types';
import { SuccessMessage } from '~ui/Message';

const DZG: any = DropzoneComponent;

const componentConfig = {
    iconFiletypes: ['.xlsx', '.xml', '.html'],
    showFiletypeIcon: true,
    postUrl: config.serverUrl + '/uploadExportReportsHandler'
};

const eventHandlers = {
    drop: () => {},

    success: (file: File, { response }: { response: ExportReportsAnswer }) => {
        exportStore.stopLoadingOwners();
        exportStore.setAnswer(response);
    },

    addedfile: () => {
        exportStore.startLoadingOwners();
    },

    error: (file: File, { error }: { error: string }) => {
        exportStore.stopLoadingOwners();
        exportStore.setLoadingError(error);
    }
};

const djsConfig = {
    autoProcessQueue: true,
    addRemoveLinks: false,
    clickable: true,
    dictDefaultMessage: 'перетащите отчет сюда',
    chunksUploaded: () => {
        console.log('chunksUploaded');
    },
    headers: authHeaders()
};

const EXPORT_BASE_NAMES = {
    '1': 'ЦИАН',
    '2': 'Яндекс.Недвижимость'
};

type OwnersDropModalProps = {
    open: boolean;
    onClose: () => void;
};

@observer
export default class ExportReportsDropModal extends Component<OwnersDropModalProps> {
    render() {
        const { loadingError, loadingFile, loadingAnswer } = exportStore;
        const { open, onClose } = this.props;

        return (
            <Modal dimmer="inverted" size="large" open={open} closeIcon onClose={onClose}>
                <Modal.Header>Загрузка отчета об экспорте</Modal.Header>

                <Modal.Content>
                    {loadingError && <ListError errors={[loadingError]} />}
                    {loadingAnswer && (
                        <SuccessMessage>
                            Загружен отчет от {EXPORT_BASE_NAMES[String(loadingAnswer.export_base_id)]}; успешных объектов:{' '}
                            {loadingAnswer.goodCount}
                        </SuccessMessage>
                    )}

                    {loadingAnswer && loadingAnswer.errors.length > 0 && (
                        <Table compact="very" size="small">
                            <Table.Body>
                                {loadingAnswer.errors.map(({ estate_id, message }) => (
                                    <Table.Row key={estate_id}>
                                        <Table.Cell>
                                            <a
                                                target="_blank"
                                                href={`/estate/${ESTATE_TYPE_SELL}/${ESTATE_BASE_MAIN}/${estate_id}`}
                                                rel="noreferrer"
                                            >
                                                {estate_id}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell negative>{message}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}

                    {loadingAnswer && loadingAnswer.warnings.length > 0 && (
                        <Table compact="very" size="small">
                            <Table.Body>
                                {loadingAnswer.warnings.map(({ estate_id, message }) => (
                                    <Table.Row key={estate_id}>
                                        <Table.Cell>
                                            <a
                                                target="_blank"
                                                href={`/estate/${ESTATE_TYPE_SELL}/${ESTATE_BASE_MAIN}/${estate_id}`}
                                                rel="noreferrer"
                                            >
                                                {estate_id}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell warning>{message}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}

                    <DZG config={componentConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} />

                    {loadingFile && (
                        <Dimmer active inverted>
                            <Loader active size="medium" />
                        </Dimmer>
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}
