import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import Grid from '~ui/Grid';

import LifeComplexTitleShow from './ShowBlocks/LifeComplexTitleShow';
import LifeComplexPhotosShow from './ShowBlocks/LifeComplexPhotosShow';
import LifeComplexDeveloperShow from './ShowBlocks/LifeComplexDeveloperShow';
import LifeComplexStatShow from './ShowBlocks/LifeComplexStatShow';
import LifeComplexAboutShow from './ShowBlocks/LifeComplexAboutShow';
import LifeComplexMajorUsers from './ShowBlocks/LifeComplexMajorUsers';

const LifeComplexShowMode = ({ newbuilding_id }: { newbuilding_id: number }) => {
    return (
        <Grid disableGutter alignItems="flex-start">
            <Grid.Column width={8}>
                <LifeComplexTitleShow newbuilding_id={newbuilding_id} />
                <LifeComplexPhotosShow item_id={newbuilding_id} />
                <LifeComplexAboutShow item_id={newbuilding_id} />
            </Grid.Column>
            <Grid.Column width={4}>
                <Grid disableGutter>
                    <Grid.Column>
                        <LifeComplexStatShow item_id={newbuilding_id} />
                    </Grid.Column>
                    <Grid.Column>
                        <LifeComplexDeveloperShow item_id={newbuilding_id} />
                    </Grid.Column>
                    <Grid.Column>
                        <LifeComplexMajorUsers item_id={newbuilding_id} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
};

export default observer(LifeComplexShowMode);
