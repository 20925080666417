import { action } from 'mobx';
import * as fixMlsPriceApi from '~/api/fixMlsPriceApi';

import ListStorePrototype from './prototypes/ListStore.prototype';
import { MlsEstateFixPrice } from '~/types/estate.types';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';

type MlsEstateFixPriceProperty = {
    loadingOwnFixed: boolean;
    ownFixed: MlsEstateFixPrice[];
};

class MlsFixPriceStore extends ListStorePrototype<MlsEstateFixPrice, MlsEstateFixPrice, MlsEstateFixPriceProperty> {
    listFilterClear = {};

    orderBy = 'updateTime';

    constructor() {
        super('estate_fix_price_id', 'request', fixMlsPriceApi);
        this.clearFilter();
    }

    validationItem(fixPrice: Partial<MlsEstateFixPrice>): string[] {
        const errors: Array<string> = [];

        if (!fixPrice.phone) {
            errors.push('Заполните телефон');
        }

        return errors;
    }

    @action
    async createItem(): Promise<number> {
        const { editingItem } = this.getItem(CREATING_ITEM_ID);
        return await super.createItem();
    }
}

export default new MlsFixPriceStore();
