import React, { PureComponent, Fragment } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ownerStore from '~/stores/ownerStore';
import ConfirmTrigger from '../../../Base/ui/ConfirmTrigger';

type OwnerIsNotActuallyProps = {
    estate_id: number;
    disabled: boolean;
};

class OwnerIsNotActually extends PureComponent<OwnerIsNotActuallyProps> {
    handleToggleIsAlreadySold = async () => {
        await ownerStore.toggleDisableItem(this.props.estate_id, false, true);
    };

    render() {
        const { disabled } = this.props;

        return (
            <ConfirmTrigger
                trigger={
                    <Button
                        fluid
                        content="Это Риелтор"
                        labelPosition="left"
                        icon="remove"
                        size="tiny"
                        color="yellow"
                        disabled={disabled}
                        title="Эта кнопка активируется после вашего звонка на номер собственника"
                    />
                }
                header="Это объявление от риелтора?"
                content={`Объявление собственника уйдет в архив, контакт будет помечен как "риелтор"`}
                confirmButton="Подтверждаю"
                onConfirm={this.handleToggleIsAlreadySold}
                disabled={disabled}
            />
        );
    }
}

export default OwnerIsNotActually;
