import React, { useState } from 'react';
import { observer } from 'mobx-react';

import * as contactApi from '~/api/contactsApi';

import contactStore from '~/stores/contactStore';
import commonStore from '~/stores/commonStore';

import ListError from '~/components/ListError';

import Modal, { ModalBtnAction, ModalContent } from '~ui/Modal';
import Grid from '~ui/Grid';
import SmartTextArea from '~/components/Base/SmartTextArea';
import deepCopy from '~/common/deepCopy';

type ManageContactsArrayProps = {
    onClose: () => void;
};

const SmsContactsArray = ({ onClose }: ManageContactsArrayProps) => {
    const [message, setMessage] = useState('');
    const handleChangeMessage = ({ value }: { value: string }) => {
        setMessage(value);
    };

    const { selectedItemsIds } = contactStore;

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleSave = async () => {
        setLoading(true);
        try {
            await contactApi.sendSmsToContactsArray(deepCopy(selectedItemsIds), message);
            onClose();
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', disabled: loading },
        {
            onClick: handleSave,
            label: `Отправить (${selectedItemsIds.length} sms)`,
            loading: loading,
            disabled: loading || !message,
            variant: 'outlined',
            color: 'primary'
        }
    ];

    return (
        <Modal
            disableBackdropClick={loading}
            fullScreen={commonStore.isMobile}
            header="Отправка SMS на номера контактов"
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            style={{ overflowY: 'visible' }}
        >
            <ModalContent style={{ overflowY: 'visible' }}>
                <ListError errors={errors} />

                <Grid>
                    <Grid.Column width={12}>
                        <SmartTextArea value={message} name="message" onChange={handleChangeMessage} maxLength={670} />
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(SmsContactsArray);
