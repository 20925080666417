import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, List, Header } from 'semantic-ui-react';

import Time from '../../../Base/Time';
import { ESTATE_BASE_MAIN } from '~/types/estate.types';
import wishStore from '~/stores/wishStore';
import EstateLinkWithPreview from '../../../Lists/Estate/EstateLinkWithPreview';

type WishInterestedEstatesShowProps = {
    wish_id: number;
};

@observer
class WishInterestedEstatesShow extends Component<WishInterestedEstatesShowProps> {
    render() {
        const { wish_id } = this.props;
        const { interestedEstates } = wishStore.getItem(wish_id).item || {};

        return (
            <div className="crm-Item">
                <Card fluid color="teal">
                    <Card.Content>
                        <Card.Description>
                            <Header size="tiny">Интересовался объект{interestedEstates.length === 1 ? 'ом' : 'ами'}</Header>
                        </Card.Description>
                        <Card.Description>
                            <List divided relaxed>
                                {interestedEstates.map(({ startTime, ...estate }, i) => (
                                    <List.Item key={i}>
                                        <List.Icon name="building" verticalAlign="middle" />
                                        <List.Content>
                                            <List.Header>
                                                <EstateLinkWithPreview estate={estate} base={ESTATE_BASE_MAIN} />
                                            </List.Header>
                                            <List.Description>
                                                <Time time={startTime} />
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </div>
        );
    }
}

export default WishInterestedEstatesShow;
