import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import { ShowingLinkState } from '../../Lists/Showings/ShowingLink';
import showingStore from '~/stores/showingStore';

class ShowingReportTabMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(showingStore, ShowingLinkState, ShowingReportTabMenu, {
    callEventModal: true
});
