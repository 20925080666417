import React, { Component, Fragment } from 'react';
import { ReactNode } from 'react';
import { Input, Menu } from 'semantic-ui-react';

const MIN_APPROXIMATION_RANGE = 0;
const MAX_APPROXIMATION_RANGE = 0.2;
const APPROXIMATION_STEP = 0.01;

type ApproximationInputProps = {
    approximation: number;
    onChange: (index: number) => void;
    children?: ReactNode;
};

const ApproximationInput = ({ approximation, onChange, children }: ApproximationInputProps) => (
    <Menu pointing secondary stackable className="crm-List__filter">
        <Menu.Item>
            <div className="ui form">Погрешность: {Math.round(approximation * 100)}%</div>
        </Menu.Item>

        <Menu.Item className="crm-List__filter_dropdown">
            <Input
                min={MIN_APPROXIMATION_RANGE}
                max={MAX_APPROXIMATION_RANGE}
                step={APPROXIMATION_STEP}
                onChange={(event: React.SyntheticEvent, { value }: { value: string }) => onChange(Number(value))}
                type="range"
                value={approximation}
            />
        </Menu.Item>

        {children}
    </Menu>
);

export default ApproximationInput;
