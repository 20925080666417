import React from 'react';
import { Card, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import history from '~/history';

import userStore from '~/stores/userStore';
import authStore from '~/stores/authStore';

import Button from '~ui/Button';

const UserBalanceBlock = ({ item_id }: { item_id: number }) => {
    const { balance } = userStore.getItem(item_id).item || {};

    const handleOpenTab = () => {
        history.push({
            pathname: `/users/list/profile/${item_id}/balance`,
            state: { modal: true, switchFromTab: true }
        });
    };

    return (
        <Card fluid color="red">
            <Card.Content>
                <Grid>
                    <Grid.Column width={9} verticalAlign="middle">
                        Баланс: <b style={{ whiteSpace: 'nowrap' }}>{balance.toLocaleString()} ₽</b>
                    </Grid.Column>
                    {authStore.canDelete('balance', item_id) && (
                        <Grid.Column width={7} textAlign="right">
                            <Button size="small" color="primary" variant="contained" onClick={handleOpenTab}>
                                Детали
                            </Button>
                        </Grid.Column>
                    )}
                </Grid>
            </Card.Content>
        </Card>
    );
};

export default observer(UserBalanceBlock);
