import React, { Fragment } from 'react';
import MonoString from '../../../Base/MonoString';

export default ({
    totalAreaMin,
    totalAreaMax,
    livingAreaMin,
    livingAreaMax,
    kitchenAreaMin,
    kitchenAreaMax,
    short
}: {
    totalAreaMin: number | null;
    totalAreaMax: number | null;
    livingAreaMin: number | null;
    livingAreaMax: number | null;
    kitchenAreaMin: number | null;
    kitchenAreaMax: number | null;
    short?: boolean;
}) => {
    const templateArea = short ? '? – ?' : 'от ? до ? м²';

    return (
        <Fragment>
            {(totalAreaMin || totalAreaMax) && (
                <div>
                    <MonoString template={`Общая: ${templateArea}`} values={[totalAreaMin || '..', totalAreaMax || '..']} />
                </div>
            )}
            {(livingAreaMin || livingAreaMax) && (
                <div>
                    <MonoString template={`Жилая: ${templateArea}`} values={[livingAreaMin || '..', livingAreaMax || '..']} />
                </div>
            )}
            {(kitchenAreaMin || kitchenAreaMax) && (
                <div>
                    <MonoString template={`Кухни: ${templateArea}`} values={[kitchenAreaMin || '..', kitchenAreaMax || '..']} />
                </div>
            )}
        </Fragment>
    );
};
