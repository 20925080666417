export const calcPayment = (sum: number, periodMonths: number, rate: number): number => {
    // ставка в месяц
    const i = rate / 12 / 100;

    // коэффициент аннуитета
    const koef = (i * Math.pow(1 + i, periodMonths)) / (Math.pow(1 + i, periodMonths) - 1);

    return Math.ceil(sum * koef);
};

export const getBaseLog = (x: number, y: number): number => {
    return Math.log(y) / Math.log(x);
};

export const calsPeriod = (sum: number, monthPay: number, rate: number): number => {
    // ставка в месяц
    const i = rate / 12 / 100;

    const mm = monthPay / sum;
    const result = getBaseLog(1 + i, -mm / (i - mm));

    // округлим до целых
    return Math.ceil(+result);
};
