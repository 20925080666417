import fetchApi from '../common/fetchApi';
import { CalendarEventType, CALENDAR_ITEM_TYPE } from '~/types/calendar.types';
import { GQItemObjects } from './notificationsApi';

const GQCalendarEventFields = `
    item_id
    item_type
    title
    allDay
    startTime
    endTime
    hasReleased
    
    notificationEnable
    notificationTime
    
    attached {
        item_id
        item_type
        item {
            ${GQItemObjects}
        }
    }
`;

export const fetchCalendarEvents = async (
    user_id: number,
    startTime: number,
    endTime: number,
    itemTypes: CALENDAR_ITEM_TYPE[]
): Promise<CalendarEventType[]> => {
    const graphql = `{
        fetchCalendarEvents(user_id: ${user_id}, startTime: ${startTime}, endTime: ${endTime}, itemTypes: [${itemTypes.join(',')}]){
            ${GQCalendarEventFields}
        } 
      }`;

    const data: {
        fetchCalendarEvents: (Omit<CalendarEventType, 'start' | 'end'> & { startTime: number; endTime: number })[];
    } = await fetchApi.getGQ(graphql);
    return data.fetchCalendarEvents.map(event => {
        const newEvent = { startTime, endTime, ...event };
        return { ...newEvent, start: new Date(event.startTime), end: new Date(event.endTime) };
    });
};
