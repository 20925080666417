import React, { ReactNode, useState, CSSProperties } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import Alert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const MessageMargined = withStyles(({ spacing }) =>
    createStyles({
        root: {
            margin: spacing(0, 0, 1)
        }
    })
)(Alert);
export const MessageTitle = AlertTitle;

export const Message = (props: AlertProps & { enableClose?: boolean }) => {
    const { enableClose, ...alertProps } = props;
    const [open, setOpen] = useState(true);
    const onClose = enableClose ? () => setOpen(false) : undefined;

    return open ? <MessageMargined {...alertProps} onClose={onClose} /> : null;
};

type MessageProps = {
    children: ReactNode;
    variant?: 'standard' | 'filled' | 'outlined';
    header?: ReactNode;
    className?: string;
    enableClose?: boolean;
    style?: CSSProperties;
};

export const WarningMessage = React.memo((props: MessageProps) => (
    <Message severity="warning" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const InfoMessage = React.memo((props: MessageProps) => (
    <Message severity="info" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const ErrorMessage = React.memo((props: MessageProps) => (
    <Message severity="error" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const SuccessMessage = React.memo((props: MessageProps) => (
    <Message severity="success" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export default Message;
