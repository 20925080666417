import React, { Component, Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateL10n from '~/L10n/estate.L10n';
import estateStore from '~/stores/estateStore';

import EditField from '~/components/Base/EditField';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import ButtonGroup from '~/components/Base/ButtonGroup';
import {
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_PROPERTY_TYPE_BUILDING
} from '~/types/estate.types';

type OfficeBuildingBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    buildingName?: string | null;
    buildYear?: number | null;
    buildingType?: number | null;
    buildingClassType?: number | null;
    totalAreaBuilding?: number | null;
    landArea?: number | null;
    landType?: number;
    landAreaUnitType?: number;
    statusType?: number;
    developer?: string | null;
    managementCompany?: string | null;
    ventilationType?: number | null;
    conditioningType?: number | null;
    extinguishingSystemType?: number | null;
    heatingType?: number | null;
    totalArea?: number | null;
};

@observer
class OfficeBuildingBlockEditing extends Component<OfficeBuildingBlockEditingProps> {
    constructor(props: OfficeBuildingBlockEditingProps) {
        super(props);

        const item = {
            estate_id: props.item_id,
            propertyType: props.propertyType,
            buildingName: props.buildingName || null,
            buildYear: props.buildYear || null,
            buildingType: props.buildingType || null,
            buildingClassType: props.buildingClassType || null,
            totalAreaBuilding: props.totalAreaBuilding || null,
            totalArea: props.totalArea || null,
            landArea: props.landArea || null,
            landType: props.landType || 1,
            landAreaUnitType: props.landAreaUnitType || 1,
            statusType: props.statusType || null,
            developer: props.developer || null,
            managementCompany: props.managementCompany || null,
            ventilationType: props.ventilationType || null,
            conditioningType: props.conditioningType || null,
            extinguishingSystemType: props.extinguishingSystemType || null,
            heatingType: props.heatingType || null
        };
        estateStore.setEditingItem(item.estate_id, item);
    }

    render() {
        const { item_id, handleChange } = this.props;
        const {
            buildingName,
            buildYear,
            buildingType,
            buildingClassType,
            totalAreaBuilding,
            landArea,
            landType,
            landAreaUnitType,
            statusType,
            developer,
            managementCompany,
            ventilationType,
            conditioningType,
            extinguishingSystemType,
            heatingType,
            propertyType,
            totalArea
        } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Text
                    label="Название БЦ"
                    placeholder="Пример БЦ Омега Парк"
                    value={buildingName}
                    name="buildingName"
                    onChange={handleChange}
                />

                {[
                    ESTATE_PROPERTY_TYPE_OFFICE,
                    ESTATE_PROPERTY_TYPE_WAREHOUSE,
                    ESTATE_PROPERTY_TYPE_INDUSTRY,
                    ESTATE_PROPERTY_TYPE_BUILDING
                ].includes(propertyType) && (
                    <EditField.InputNumber
                        label="Год постройки"
                        onChange={handleChange}
                        min={1}
                        max={2100}
                        value={buildYear}
                        name="buildYear"
                    />
                )}

                <EditField.Drop
                    label="Тип здания"
                    name="buildingType"
                    value={buildingType}
                    onChange={handleChange}
                    options={estateL10n.OFFICE_BUILDING_TYPE_DROPDOWN}
                />

                {[
                    ESTATE_PROPERTY_TYPE_OFFICE,
                    ESTATE_PROPERTY_TYPE_WAREHOUSE,
                    ESTATE_PROPERTY_TYPE_SHOPPING,
                    ESTATE_PROPERTY_TYPE_INDUSTRY,
                    ESTATE_PROPERTY_TYPE_BUILDING
                ].includes(propertyType) && (
                    <EditField.ButtonGroup
                        label="Класс Здания"
                        buttonSet={estateL10n.OFFICE_CLASS_TYPE}
                        name="buildingClassType"
                        value={buildingClassType}
                        handleChange={handleChange}
                    />
                )}

                {propertyType !== ESTATE_PROPERTY_TYPE_SHOPPING && (
                    <Fragment>
                        <EditField.InputNumber
                            label="Площадь здания"
                            onChange={handleChange}
                            min={1}
                            max={999999}
                            value={propertyType === ESTATE_PROPERTY_TYPE_BUILDING ? totalArea : totalAreaBuilding}
                            name={propertyType === ESTATE_PROPERTY_TYPE_BUILDING ? 'totalArea' : 'totalAreaBuilding'}
                            step={0.1}
                            extraLabel="м²"
                        />

                        <EditField.InputNumber
                            label="Участок"
                            onChange={handleChange}
                            min={1}
                            max={9999999}
                            value={landArea}
                            name="landArea"
                            step={0.1}
                            extraLabel={
                                <Dropdown
                                    value={landAreaUnitType}
                                    name="landAreaUnitType"
                                    onChange={handleChange}
                                    options={estateL10n.LAND_AREA_TYPES_DROPDOWN}
                                />
                            }
                        >
                            &nbsp;
                            <ButtonGroup
                                buttonSet={[
                                    [1, 'В собственности'],
                                    [2, 'В аренде']
                                ]}
                                name="landType"
                                value={landType}
                                handleChange={handleChange}
                            />
                        </EditField.InputNumber>
                    </Fragment>
                )}

                {propertyType !== ESTATE_PROPERTY_TYPE_SHOPPING && (
                    <EditField.ButtonGroup
                        label="Категория"
                        buttonSet={estateL10n.OFFICE_STATUS_TYPE}
                        name="statusType"
                        value={statusType}
                        handleChange={handleChange}
                    />
                )}

                <EditField.Text label="Девелопер" value={developer} name="developer" onChange={handleChange} />

                <EditField.Text label="Управляющая компания" value={managementCompany} name="managementCompany" onChange={handleChange} />

                <EditField.ButtonGroup
                    label="Вентиляция"
                    buttonSet={estateL10n.OFFICE_VENTILATION_TYPE}
                    name="ventilationType"
                    value={ventilationType}
                    handleChange={handleChange}
                />

                <EditField.ButtonGroup
                    label="Кондиционирование"
                    buttonSet={estateL10n.OFFICE_CONDITIONING_TYPE}
                    name="conditioningType"
                    value={conditioningType}
                    handleChange={handleChange}
                />

                <EditField.Drop
                    label="Система пожаротушения"
                    options={estateL10n.OFFICE_EXTINGUISTING_SYSTEM_TYPE_DROPDOWN}
                    name="extinguishingSystemType"
                    value={extinguishingSystemType}
                    onChange={handleChange}
                />

                <EditField.ButtonGroup
                    label="Отопление"
                    buttonSet={estateL10n.OFFICE_HEATING_TYPE}
                    name="heatingType"
                    value={heatingType}
                    handleChange={handleChange}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, OfficeBuildingBlockEditing);
