import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { SmaOptionsType, SMAShareType } from '~/types/estateSma.types';
import * as estateSMAApi from './estateSMAApi';
import { GQUserMainFields } from '~/api/userApi';

export const fetchItemList = async (sma_id: number): Promise<SMAShareType[]> => {
    const graphql = `{
        fetchEstateSmaShares(sma_id: ${sma_id}) {
            sma_share_id
            sma_id
            phone
            message
            user_id
            user {
                ${GQUserMainFields}
            }
            createTime
        }
      }`;

    const data = await fetchApi.getGQ<{ fetchEstateSmaShares: SMAShareType[] }>(graphql);
    return data.fetchEstateSmaShares;
};

export const fetchOptions = async (estate_id: number): Promise<SmaOptionsType> => {
    return await estateSMAApi.fetchOptions(estate_id);
};

export const createSmaShare = async (sma_id: number, phone: string, message: string): Promise<number> => {
    const graphql = `mutation { 
          createSmaShare(smaShareInput: ${objectToGraphql({ sma_id, phone, message })})
        }`;

    const data = await fetchApi.postGQ<{ createSmaShare: number }>(graphql);
    return data.createSmaShare;
};
