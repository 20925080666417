import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Button from '~ui/Button';
import { InfoMessage } from '~ui/Message';

import mortgageRequestStore from '~/stores/mortgageRequest';
import mortgagePersonStore from '~/stores/mortgageRequest/mortgagePersonStore';

import MortgagePersonEditing from './MortgagePersonEditing';
import LoaderAwait from '~/components/Base/LoaderAwait';

const MortgagePersonButton = observer(
    ({ mortgage_person_id, index, onClick }: { mortgage_person_id: number; index: number; onClick: () => void }) => {
        useMemo(() => {
            mortgagePersonStore.fetchItem(mortgage_person_id);
        }, [mortgage_person_id]);

        const { editingItem, loadingItem } = mortgagePersonStore.getItem(mortgage_person_id);

        const { name, surname } = editingItem;
        const fullName = `${surname || ''} ${name || ''}`.trim();
        const title = fullName || `${index + 1}-й заемщик`;

        return (
            <Tab
                label={
                    <div>
                        <span className="crm-Flex__center">
                            <PersonIcon />
                            &nbsp;
                            <span>{title}</span>
                        </span>
                        <LoaderAwait active={loadingItem} linear />
                    </div>
                }
                onClick={onClick}
            />
        );
    }
);

const useStyle = makeStyles(({ spacing }) => ({
    tabs: {
        marginTop: spacing(1.5),
        marginBottom: spacing(4)
    }
}));

const MortgagePersonsList = ({ mortgage_request_id }: { mortgage_request_id: number }) => {
    const classes = useStyle();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { editingItem, loadingItem } = mortgageRequestStore.getItem(mortgage_request_id);
    const { mortgage_person_ids } = editingItem;

    useEffect(() => {
        if (mortgage_person_ids && mortgage_person_ids.length > 0 && activeIndex === null) {
            setActiveIndex(0);
        } else if (!mortgage_person_ids) {
            setActiveIndex(null);
        }
    }, [mortgage_person_ids]);

    const handleCreate = async () => {
        await mortgagePersonStore.createPersonItem(mortgage_request_id);
    };

    const mortgage_person_id = mortgage_person_ids && mortgage_person_ids[activeIndex];

    return (
        <>
            {mortgage_person_ids?.length === 0 && (
                <InfoMessage header="Заемщики / Созаемщики / Поручители">
                    Нажмите кнопку Добавить, чтобы создать участника сделки
                </InfoMessage>
            )}
            <Tabs variant="standard" value={activeIndex ?? false} indicatorColor="primary" textColor="primary" className={classes.tabs}>
                {mortgage_person_ids?.map((mortgage_person_id, index) => (
                    <MortgagePersonButton
                        mortgage_person_id={mortgage_person_id}
                        key={mortgage_person_id}
                        onClick={() => setActiveIndex(index)}
                        index={index}
                    />
                ))}
                <Button
                    loading={loadingItem}
                    color="secondary"
                    variant="text"
                    size="medium"
                    onClick={handleCreate}
                    startIcon={<PersonAddIcon />}
                >
                    Добавить
                </Button>
            </Tabs>
            {mortgage_person_id && <MortgagePersonEditing mortgage_person_id={mortgage_person_ids[activeIndex]} />}
        </>
    );
};

export default observer(MortgagePersonsList);
