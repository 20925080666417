import React, { ChangeEvent, useMemo, useEffect } from 'react';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import userStore from '~/stores/userStore';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';

import { Switch } from '~ui/Checkbox';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';
import { WarningMessage } from '~ui/Message';

type UserWhatsappEditingProps = HandlerEditingWrappedProps & {
    whatsappIsEnable: boolean | null;
};

const UserWhatsappEditing = observer((props: UserWhatsappEditingProps) => {
    const { item_id, handleChange } = props;

    useMemo(() => {
        userStore.setEditingItem(item_id, { whatsappIsEnable: props.whatsappIsEnable || false });
    }, []);

    const {
        editingItem: { whatsappIsEnable },
        item: { phones }
    } = userStore.getItem(item_id);

    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event, { name: 'whatsappIsEnable', value: !whatsappIsEnable });
    };

    if (!phones.length) {
        return (
            <div className="crm-Item__editingMode">
                <WarningMessage>У сотрудника не задан телефонный номер</WarningMessage>
            </div>
        );
    }

    return (
        <Card.Description>
            <div className="crm-Item__editingMode">
                <Switch
                    onChange={handleCheckbox}
                    checked={whatsappIsEnable}
                    title="Подключить whatsapp"
                    label={`номер ${adjustPhoneNumber(phones[0].phone)}`}
                />
            </div>
        </Card.Description>
    );
});

export default HandlerEditingBlockWrapper(userStore, UserWhatsappEditing);
