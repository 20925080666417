import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Header } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import ComfortBlockEditing from '../EditBlocks/ComfortBlockEditing';
import EditingBlockWrapper from '../../EditingBlockWrapper';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { ESTATE_TYPE_RENT_ID } from '~/types/estate.types';

@observer
class ComfortBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }

        const fields = [
            { key: 'hasInternet', positive: 'Есть интернет', negative: 'Нет интернета' },
            { key: 'hasFurniture', positive: 'Есть мебель', negative: 'Нет мебели' },
            { key: 'hasTv', positive: 'Есть тв', negative: 'Нет тв' },
            { key: 'hasKitchenFurniture', positive: 'Есть кухонная мебель', negative: 'Нет кухонной мебели' },
            { key: 'hasWasher', positive: 'Есть стиральная машина', negative: 'Нет стиральной машины' },
            { key: 'hasConditioner', positive: 'Есть кондиционер', negative: 'Нет кондиционера' },
            { key: 'hasBathtub', positive: 'Есть ванная', negative: 'Нет ванной' },
            { key: 'hasShower', positive: 'Есть душевая кабина', negative: 'Нет душевой кабины' },
            { key: 'hasDishwasher', positive: 'Есть посудомоечная машина', negative: 'Нет посудомоечной машины' },
            { key: 'hasFridge', positive: 'Есть холодильник', negative: 'Нет холодильника' },
            { key: 'petsAllowed', positive: 'Можно с домашними животными', negative: 'Нельзя с домашними животными' },
            { key: 'childrenAllowed', positive: 'Можно с детьми', negative: 'Нельзя с детьми' }
        ];

        return (
            <Card fluid color="brown">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">{item.type === ESTATE_TYPE_RENT_ID ? 'Условия' : 'Мебель'}</Header>

                            {fields
                                .filter(({ key }) => typeof item[key] === 'boolean')
                                .map(({ key, positive, negative }, i) => (
                                    <Fragment key={key}>
                                        {i > 0 && <span>; &nbsp;</span>}{' '}
                                        <div className="crm-Estate__fieldNowrap">{item[key] ? positive : negative}</div>
                                    </Fragment>
                                ))}
                        </Fragment>
                    )}
                    {editing && <ComfortBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, ComfortBlockShow);
