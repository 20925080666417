import { ExportingBase, ExportingBaseStatistics, ExportingTariffStatistics, Region, TariffPriceHeaderOptions } from '~/types/exports.types';
import fetchApi from '~/common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQHistoryChange } from '~/api/userApi';

const GQExportingBase = `
    export_base_id
    name
    image
    config
    format
    feedurl
    enable
    tariffs {
      tariff_id
      export_base_id
      region_id
      type
      propertyType
      enable
      
      prices {
        price_id
        tariff_id
        name
        minDays
        dailyPrice
        extraName
        extraPrice
        isPremium
      }
    }
    regionIds
`;

export const fetchItem = async (export_base_id: number): Promise<ExportingBase> => {
    const graphql = `{
        fetchExportingBase(export_base_id: ${export_base_id}) {
            ${GQExportingBase}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchExportingBase: ExportingBase }>(graphql);
    return data.fetchExportingBase;
};

export const fetchList = async (): Promise<{ list: ExportingBase[]; count: number }> => {
    const graphql = `{
        exportingBases {
            ${GQExportingBase}
        } 
      }`;

    const { exportingBases } = await fetchApi.getGQ<{ exportingBases: ExportingBase[] }>(graphql);
    return { list: exportingBases, count: exportingBases.length };
};

export const exportingBasesStatistics = async (): Promise<ExportingBaseStatistics[]> => {
    const graphql = `{
        exportingBasesStatistics {
            export_base_id
            countActiveEstates 
        } 
      }`;

    const data: { exportingBasesStatistics: ExportingBaseStatistics[] } = await fetchApi.getGQ(graphql);
    return data.exportingBasesStatistics;
};

export const saveItem = async (export_base_id: number, exportBaseInput: ExportingBase): Promise<number> => {
    const graphql = `mutation { 
          updateExportingBase(export_base_id: ${export_base_id}, exportBaseInput: ${objectToGraphql(exportBaseInput)})
        }`;

    const data: { updateExportingBase: number } = await fetchApi.postGQ(graphql);
    return data.updateExportingBase;
};

export const toggleExportingBase = async (export_base_id: number, enable: boolean): Promise<number> => {
    const graphql = `mutation { 
          toggleExportingBase(export_base_id: ${export_base_id}, enable: ${enable})
        }`;

    const data: { toggleExportingBase: number } = await fetchApi.postGQ(graphql);
    return data.toggleExportingBase;
};

export const createItem = async (exportBaseInput: Partial<ExportingBase>): Promise<number> => {
    const graphql = `mutation { 
          createExportingBase(exportBaseInput: ${objectToGraphql(exportBaseInput)})
        }`;

    const data: { createExportingBase: number } = await fetchApi.postGQ(graphql);
    return data.createExportingBase;
};

export const fetchAllRegions = async (): Promise<Region[]> => {
    const graphql = ` { 
          fetchAllRegions {
             region_id
             title
             type
             timezone
             coordinates {
                x
                y
              }
          }
        }`;

    const data: { fetchAllRegions: Region[] } = await fetchApi.getGQ(graphql);
    return data.fetchAllRegions;
};

export const addExportRegionId = async (export_base_id: number, region_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          addExportRegionId(export_base_id: ${export_base_id}, region_id: ${region_id})
        }`;

    const data: { addExportRegionId: boolean } = await fetchApi.postGQ(graphql);
    return data.addExportRegionId;
};

export const exportingBaseTariffsStatistics = async (export_base_id: number): Promise<ExportingTariffStatistics[]> => {
    const graphql = `{
        exportingBaseTariffsStatistics(export_base_id: ${export_base_id}) {
            tariff_id
            prices {
              price_id
              countActiveEstates
            }
        } 
      }`;

    const data: { exportingBaseTariffsStatistics: ExportingTariffStatistics[] } = await fetchApi.getGQ(graphql);
    return data.exportingBaseTariffsStatistics;
};

export const fetchItemHistory = async (export_base_id: number): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchExportingBaseChangeHistory(export_base_id: ${export_base_id}) {
            ${GQHistoryChange}    
        } 
      }`;

    const data: { fetchExportingBaseChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchExportingBaseChangeHistory;
};

export const updateTariffPrice = async (export_base_id: number, index: number, options: TariffPriceHeaderOptions): Promise<boolean> => {
    const graphql = `mutation { 
          updateTariffPrice(export_base_id: ${export_base_id}, index: ${index}, options: ${objectToGraphql(options)})
        }`;

    const data = await fetchApi.postGQ<{ updateTariffPrice: boolean }>(graphql);
    return data.updateTariffPrice;
};
