import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'semantic-ui-react';

import deepCopy from '~/common/deepCopy';
import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';

import EditingBlockWrapper, { EditingWrappedProps } from '../../EditingBlockWrapper';
import PhotosBlockEditing from '../EditBlocks/PhotosBlockEditing';

import { WarningMessage, MessageTitle } from '~ui/Message';

import PhotoGalleryMobile from '~/components/Base/Photos/PhotoGalleryMobile';
import PhotoGalleryDesktop from '~/components/Base/Photos/PhotoGalleryDesktop';
import VideoBlockShow from './VideoBlockShow';

@observer
class PhotosBlockShow extends Component<EditingWrappedProps> {
    constructor(props: EditingWrappedProps) {
        super(props);
    }

    render() {
        const { isMobile } = commonStore;
        const { item_id, editing } = this.props;
        const {
            item,
            loadingItem,
            property: { updatingPhotos }
        } = estateStore.getItem(item_id);

        const { photos, videos } = item || {};

        return (
            <div className="crm-Item__editingMode">
                {!editing && !loadingItem && photos.length === 0 && (
                    <WarningMessage>
                        <MessageTitle>У данного объекта нет фотографий!</MessageTitle>
                    </WarningMessage>
                )}

                {(updatingPhotos || loadingItem || photos.length > 0) && !editing && (
                    <Fragment>
                        {isMobile && <PhotoGalleryMobile photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                        {!isMobile && <PhotoGalleryDesktop photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                    </Fragment>
                )}

                {editing && (
                    <Card.Content>
                        <PhotosBlockEditing
                            estate_id={item_id}
                            photos={deepCopy(Array.from(photos))}
                            videos={deepCopy(Array.from(videos))}
                        />
                    </Card.Content>
                )}
                {!editing && videos?.length > 0 && <VideoBlockShow estate_id={item_id} />}
            </div>
        );
    }
}

export default EditingBlockWrapper(estateStore, PhotosBlockShow, null, { disableSwapping: true });
