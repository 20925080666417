import React, { Fragment, useState } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

import CallEstateModal from './../CallEstateModal';
import Modal, { ModalContent, ModalActions, useToggle } from '~ui/Modal';
import Button from '~ui/Button';
import ChangeCallEstateModal from './ChangeCallEstateModal';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import cellCallStore from '~/stores/cell/cellCallStore';

type CellCallTableBodyProps = {
    estate_id?: number;
    call_id: number;
    item_id: number | null;
};

const CellCallToEstateModal = ({ estate_id, call_id, item_id }: CellCallTableBodyProps) => {
    const [showCallEstateModalId, setShowCallEstateModalId] = useState(-1);
    const [showCallRemoveModal, setShowCallRemoveModal] = useState(-1);
    const [contact_id, setContactId] = useState(0);
    const [showChangeEstateModal, toggleChangeEstateModal] = useToggle();

    const handleOpenCallEstateModal = () => {
        setShowCallEstateModalId(call_id);
        setContactId(item_id || CREATING_ITEM_ID);
    };

    const handleCloseCallEstateModal = () => {
        setShowCallEstateModalId(-1);
    };

    const handleOpenRemoveCallModal = () => {
        setShowCallRemoveModal(call_id);
    };

    const handleCloseRemoveModal = () => {
        setShowCallRemoveModal(-1);
    };

    const handleRemoveCall = () => {
        cellCallStore.removeCellCall(call_id, Number(estate_id));
        handleCloseRemoveModal();
    };

    return (
        <Fragment>
            <Dropdown item icon="setting" inline>
                <Dropdown.Menu>
                    {!estate_id && (
                        <Dropdown.Item onClick={handleOpenCallEstateModal}>
                            <Icon name="plus" />
                            <Icon name="building outline" />
                            Прикрепить звонок к объекту
                        </Dropdown.Item>
                    )}

                    {estate_id && (
                        <>
                            <Dropdown.Item onClick={toggleChangeEstateModal}>
                                <Icon name="undo" />
                                <Icon name="building outline" />
                                Поменять объект
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleOpenRemoveCallModal}>
                                <Icon name="remove" color="red" />
                                <Icon name="building outline" />
                                Открепить звонок от объекта
                            </Dropdown.Item>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>

            {showCallEstateModalId > -1 && (
                <CallEstateModal
                    contact_id={contact_id}
                    item_id={showCallEstateModalId}
                    estate_id={estate_id}
                    handleClose={handleCloseCallEstateModal}
                    goToContactOnCreate={false}
                />
            )}

            {showCallRemoveModal > -1 && (
                <Modal maxWidth="sm" onClose={handleCloseRemoveModal} header="Открепить звонок от объекта">
                    <ModalContent>
                        <p>Вы уверены, что хотите открепить звонок от объекта? Созданная карточка покупателя так же будет удалена.</p>
                    </ModalContent>
                    <ModalActions>
                        <Button onClick={handleCloseRemoveModal}>Закрыть</Button>
                        <Button color="secondary" onClick={handleRemoveCall} variant="outlined">
                            Открепить
                        </Button>
                    </ModalActions>
                </Modal>
            )}

            {showChangeEstateModal && <ChangeCallEstateModal call_id={call_id} onClose={toggleChangeEstateModal} />}
        </Fragment>
    );
};

export default CellCallToEstateModal;
