import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { PaginationType } from '~/stores/prototypes/ListStore.prototype';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import authStore from '~/stores/authStore';
import taskStore from '~/stores/taskStore';
import { Task } from '~/types/tasks.types';

import TasksTableBody from './TasksTableBody';
import TasksTableHeader from './TasksTableHeader';
import ListFooter from '../Common/ListFooter';
import FilterTasks from './FilterTasks';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

@observer
class TasksList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, pagination, nextListLoaded } = taskStore;

        return (
            <Fragment>
                <div className="crm-List">
                    <Header as="h3">
                        <Icon name="tasks" />
                        Задачи
                    </Header>

                    {authStore.canCreate(taskStore.moduleName) && (
                        <div className="crm-List__addButton">
                            <Button
                                icon
                                labelPosition="left"
                                primary
                                size="tiny"
                                as={Link}
                                to={{
                                    pathname: `/tasks/list/overview/${CREATING_ITEM_ID}`,
                                    state: {
                                        modal: true,
                                        callback: { storeName: 'taskStore', method: 'fetchList' }
                                    }
                                }}
                            >
                                <Icon name="tasks" /> Добавить
                            </Button>
                        </div>
                    )}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterTasks />

                    <Segment className="crm-List__segment">
                        <Table structured size="small" sortable compact>
                            <TasksTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <TasksTableBody tasksList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={4}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={taskStore.pageChange}
                                pageSizeChange={taskStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(taskStore, TasksList);
