import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ChatCellSkeleton = () => {
    return (
        <div className="crm-Chat__user_item">
            <div className="crm-Chat__user_row">
                <div className="crm-Chat__item_avatar">
                    <div className="crm-Chat__item_avatar_mode">
                        <Skeleton variant="circle" width={40} height={40} />
                    </div>
                </div>

                <div className="crm-Chat__info">
                    <span className="crm-Chat__info_name">
                        <Skeleton variant="text" />
                    </span>
                    <div className="crm-Chat__info_des">
                        <Skeleton variant="text" />
                    </div>
                    <div className="crm-Chat__info_time">
                        <Skeleton variant="text" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ChatCellSkeleton);
