import React, { CSSProperties, PureComponent, SyntheticEvent, ReactNode } from 'react';

type OutsideAlerterProps = {
    onOutsideClick?: null | ((event: SyntheticEvent) => void | Promise<void>);
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
};

export default class OutsideClick extends PureComponent<OutsideAlerterProps> {
    wrapperRef: HTMLElement;

    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('wheel', this.handleClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('wheel', this.handleClickOutside);
    };

    setWrapperRef = (node: HTMLElement) => {
        if (node) {
            this.wrapperRef = node;
        }
    };

    handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && typeof this.props.onOutsideClick === 'function') {
            this.props.onOutsideClick(event);
        }
    };

    render() {
        return (
            <div ref={this.setWrapperRef} className={this.props.className} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}
