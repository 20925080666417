import { observable, action, computed } from 'mobx';
import * as developerFeedApi from '~/api/developerFeedApi';
import { DomclickFeedInfo } from '~/api/developerFeedApi';
import ListStorePrototype from '~/stores/prototypes/ListStore.prototype';
import deepCopy from '~/common/deepCopy';
import { DeveloperFeed, LifeComplexDomclickInfo, NewBuildingHouse } from '~/types/newbuildings.types';
import * as newbuildingsApi from '~/api/newbuildingsApi';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type DeveloperFeedProperty = {
    loadingFeedInfo: boolean;
    errors: string[];
    developerFeedInfo: DomclickFeedInfo;

    loadingHouses: boolean;
    houses: NewBuildingHouse[] | null;
};

class DeveloperFeedStore extends ListStorePrototype<DeveloperFeed, DeveloperFeed, DeveloperFeedProperty, { developer_id: number }> {
    constructor() {
        super('developer_feed_id', 'settings', developerFeedApi);
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        if (id !== CREATING_ITEM_ID) {
            const foundFeed = this.list.find(({ developer_feed_id }) => developer_feed_id === id);
            if (!foundFeed) {
                throw new Error(`Feed isnt exist: ${id}`);
            }
            this.setItem(id, {
                item: deepCopy(foundFeed),
                editingItem: deepCopy(foundFeed),
                errors: [],
                loadingItem: false,
                property: {},
                history: {}
            });
        } else {
            try {
                this.clearEditingItem(CREATING_ITEM_ID);
            } catch (e) {}
            await super.fetchItem(id);
        }
    }

    // @action
    // async createItem(): Promise<number> {
    //     const tag_id = await super.createItem();
    //     this.loadingFetchAllTags = true;
    //
    //     await tagsApi.fetchAllTags();
    //     this.loadingFetchAllTags = false;
    //
    //     return tag_id;
    // }

    @action
    async checkDomclickFeed(developer_feed_id: number): Promise<DomclickFeedInfo | null> {
        this.setProperty(developer_feed_id, { loadingFeedInfo: true, errors: [] });
        const { feedUrl } = this.getItem(developer_feed_id).editingItem;
        try {
            const developerFeedInfo = await developerFeedApi.checkDomclickFeedUrl(feedUrl);
            this.setProperty(developer_feed_id, { developerFeedInfo });
            return developerFeedInfo;
        } catch (errors) {
            this.setProperty(developer_feed_id, { errors });
            return null;
        } finally {
            this.setProperty(developer_feed_id, { loadingFeedInfo: false });
        }
        return null;
    }

    getDomclickComplex(developer_id: number, domclick_lc_id: number): LifeComplexDomclickInfo | null {
        try {
            const { developerFeedInfo } = this.getItem(developer_id).property;
            return developerFeedInfo.lifeComplexes.find(lc => lc.domclick_lc_id === domclick_lc_id);
        } catch (err) {
            return null;
        }
    }

    @action
    async fetchNewbuildingHouses(developer_id: number, domclick_lc_id: number): Promise<void> {
        const { newbuilding_id } = this.getDomclickComplex(developer_id, domclick_lc_id);
        this.setProperty(developer_id, { houses: null });
        if (newbuilding_id) {
            this.setProperty(developer_id, { houses: null, loadingHouses: true });
            const houses = await newbuildingsApi.fetchHousesByNewbuildingId(newbuilding_id);
            this.setProperty(developer_id, { houses, loadingHouses: false });
        }
    }
}

export default new DeveloperFeedStore();
