import React from 'react';
import { observer } from 'mobx-react';
import Scrollbar from '../Base/Scrollbar';
import { AvitoMessage, ChatListType, ChatMessage as ChatMessageType } from '~/types/chat.types';
import { UserLinkState } from '../Lists/Users/UserLink';
import isEqual from '~/common/isEqual';

import authStore from '~/stores/authStore';
import chatStore from '~/stores/chatStore';

import Progress from '../Base/ui/Progress';

import ChatSendArea from './ChatSendArea';
import ChatMessage from './ChatMessage';
import { WhatsappChatMessagesList } from '~/components/Items/Contact/WhatsappBlocks/WAMessagesList';
import { CianMessage } from '~/types/cian_chat.types';

type ChatMessagesProps = {
    item_id: number;
    chatType: ChatListType;
};

const ChatMessagesList = React.memo(
    ({
        messages,
        chatType,
        user_id
    }: {
        chatType: ChatListType;
        messages: (ChatMessageType | AvitoMessage | CianMessage)[];
        user_id: number;
    }) => {
        return (
            <>
                {messages.map(({ message_id, message, createTime, sender, messageType, deliveryTime, readTime }) => (
                    <ChatMessage
                        key={message_id}
                        avatar={sender.avatarUrl}
                        message={message}
                        reverse={(chatType === 'avito' && sender['user_id']) || sender['user_id'] === user_id}
                        time={createTime}
                        name={sender['name'] || `${sender['firstName']} ${sender['lastName']}`}
                        hrefTo={
                            chatType === 'avito'
                                ? sender['user_id'] !== null
                                    ? UserLinkState(sender['user_id'])
                                    : sender['userUrl']
                                : null
                        }
                        showName={chatType === 'cian'}
                        deliveryTime={deliveryTime}
                        readTime={readTime}
                    />
                ))}
            </>
        );
    },
    isEqual
);

@observer
class ChatMessages extends React.Component<ChatMessagesProps, ChatMessagesProps> {
    chatContainerRef: HTMLElement;

    constructor(props: ChatMessagesProps) {
        super(props);
        chatStore.fetchItem(props.item_id, props.chatType);
        this.state = { ...props };
    }

    static getDerivedStateFromProps(nextProps: ChatMessagesProps, currentState: ChatMessagesProps) {
        if (nextProps.item_id !== currentState.item_id) {
            chatStore.fetchItem(nextProps.item_id, nextProps.chatType, true);
        }
        return { ...nextProps };
    }

    componentDidUpdate() {
        this.chatContainerRef.scrollTop = 1000000;
    }

    render() {
        const { currentUser } = authStore;
        const { item_id, chatType } = this.props;
        const { loadingItem, item } = chatStore.getItem(item_id);
        const { user_id } = chatStore.listFilter;

        return (
            <div className="crm-Chat__mainContent">
                <div className="crm-Chat__mainContent_messages">
                    <Progress show={loadingItem} />

                    <Scrollbar suppressScrollX containerRef={$ref => (this.chatContainerRef = $ref)}>
                        {item?.chatType === 'users' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'avito' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'cian' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'whatsapp' && (
                            <WhatsappChatMessagesList
                                messages={item.messages}
                                userAvatarUrl={currentUser.avatarUrl}
                                waContactAvatarUrl={item.waContact.photoUrl}
                            />
                        )}
                    </Scrollbar>
                </div>

                {chatStore.listFilter.user_id === currentUser.user_id && <ChatSendArea item_id={item_id} chatType={chatType} />}
            </div>
        );
    }
}

export default ChatMessages;
