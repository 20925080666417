import fetchApi, { grabMessageFromError } from '../common/fetchApi';

import { CommentType, COMMENT_TYPE, CommentDropdownType } from '~/types/comments.types';
import { GQUserMainFields } from './userApi';
import { GQItemObjects } from './notificationsApi';
import { objectToGraphql } from '~/common/graphql';

const GQCommentsFields = `
    comment_id
    item_type
    item_id
    comment
    time
    user {
     	${GQUserMainFields}
    }
`;

export const GQComments = `
    ${GQCommentsFields}
    
    replies {
        reply_to
        ${GQCommentsFields}    
    }
`;

export const nl2br = (text?: string): string => {
    return text ? String(text).replace(/\n/g, '<br />') : '';
};

export const fetchComments = async (item_type: COMMENT_TYPE, item_id: number): Promise<CommentType[]> => {
    const graphql = `{
        fetchComments(item_type: ${item_type}, item_id: ${item_id}) {
            ${GQComments}
        } 
      }`;

    const data: { fetchComments: CommentType[] } = await fetchApi.getGQ(graphql);
    return data.fetchComments;
};

// export const adjustTextToGQ = (text: string): string => text.replace(/"/g, `'`).replace(/\n/g, '\\n');

export const addComment = async (
    item_type: COMMENT_TYPE,
    item_id: number,
    comment: string,
    reply_to: number | null = null
): Promise<CommentType[]> => {
    const graphql = `mutation {
            addComment(item_type: ${item_type}, item_id: ${item_id}, reply_to: ${reply_to}, comment: ${objectToGraphql(comment)}) {
                ${GQComments}
            } 
          }`;

    const data: { addComment: CommentType[] } = await fetchApi.postGQ(graphql);
    return data.addComment;
};

export const findCommentsByText = async (
    query: string,
    start: number,
    limit: number,
    abortController: AbortController | null
): Promise<{ list: CommentDropdownType[]; count: number }> => {
    const graphql = `{
        findCommentsByText(query: ${objectToGraphql(query)}, start: ${start}, limit: ${limit}) {
            list {
                ${GQComments}
                item_link_type
                item {
                    ${GQItemObjects}
                }
            }
            count
        } 
      }`;

    const data: { findCommentsByText: { list: CommentDropdownType[]; count: number } } = await fetchApi.getGQ(graphql, abortController);
    return data.findCommentsByText;
};

export const removeComment = async (item_type: COMMENT_TYPE, item_id: number, comment_id: number): Promise<CommentType[]> => {
    const graphql = `mutation {
            removeComment(item_type: ${item_type}, item_id: ${item_id}, comment_id: ${comment_id}) {
                ${GQComments}
            } 
          }`;

    const data: { removeComment: CommentType[] } = await fetchApi.postGQ(graphql);
    return data.removeComment;
};
