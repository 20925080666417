import React from 'react';
import { observer } from 'mobx-react';
import lifeComplexStore from '~/stores/lifeComplexes/lifeComplexStore';

import EditingBlockWrapper, { EditingWrappedProps } from '~/components/Items/EditingBlockWrapperNew';

import LifeComplexInfrastructureAbout from './AboutBlock/LifeComplexInfrastructureAbout';
import LifeComplexTextAbout from './AboutBlock/LifeComplexTextAbout';
import LifeComplexAboutEditing from '../EditBlocks/LifeComplexAboutEditing';

const LifeComplexAboutShow = ({ item_id, editing }: EditingWrappedProps) => {
    const newbuilding = lifeComplexStore.getItem(item_id).item;

    return (
        <>
            {!editing && (
                <>
                    <LifeComplexInfrastructureAbout newbuilding_id={item_id} />
                    <LifeComplexTextAbout newbuilding_id={item_id} />
                </>
            )}
            {editing && <LifeComplexAboutEditing item_id={item_id} editing {...newbuilding} />}
        </>
    );
};

export default EditingBlockWrapper(lifeComplexStore, observer(LifeComplexAboutShow));
