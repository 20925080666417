import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';

import { AVITO_API_KEY } from '~/types/settings.types';
import AvitoApiSettingsField from '~/components/Ads/BaseApiSettings/Fields/AvitoApiSettingsField';
import ApiSettingsWrapper from '~/components/Ads/BaseApiSettings/ApiSettingsWrapper';

const AvitoApiSettings = () => {
    return (
        <ApiSettingsWrapper
            apiKey={AVITO_API_KEY}
            FieldComponent={AvitoApiSettingsField}
            defaultSettingsValue={{ user_id: '', client_id: '', client_secret: '' }}
        >
            <Typography>
                Для работы с API от своего лица необходимо получить <b>client_id</b> и <b>client_secret</b> в{' '}
                <a href="https://www.avito.ru/professionals/api" rel="noreferrer" target="_blank">
                    личном кабинете
                </a>
                <br />А так же предоставить <b>номер профиля</b> (user_id) который можно найти на{' '}
                <a href="https://www.avito.ru/profile/settings" rel="noreferrer" target="_blank">
                    этой странице
                </a>
            </Typography>
            <a href="https://api.avito.ru/docs/api.html" target="_blank" rel="noreferrer">
                Источник
            </a>
        </ApiSettingsWrapper>
    );
};

export default observer(AvitoApiSettings);
