import React, { Component } from 'react';
import { Sidebar, Menu, Label, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';

import EstatesMapMobileInner from './EstatesMapMobileInner';
import EstateMapList from './EstateMapList';
import ShareEstatesButton from '~/components/Lists/Estate/SubBlocks/ShareEstatesButton';

@observer
class EstateMapMobile extends Component<{}> {
    componentWillUnmount() {
        estateStore.unmountSearchOnMap();
    }

    render() {
        const { selectedMapIds } = estateStore;

        return (
            <Sidebar.Pushable>
                <Sidebar
                    animation="overlay"
                    icon="labeled"
                    direction="bottom"
                    visible={selectedMapIds.length > 0}
                    width="wide"
                    className="crm-Estate__mapSearch_list"
                >
                    <Menu pointing secondary>
                        <Menu.Item name="list" active onClick={() => {}} color="yellow">
                            Выбранные объекты
                            <Label color="teal">{selectedMapIds.length}</Label>
                        </Menu.Item>
                        <Menu.Item>
                            <ShareEstatesButton short={commonStore.isMobile} />
                        </Menu.Item>

                        <Menu.Menu position="right">
                            <Menu.Item>
                                <Button
                                    basic
                                    circular
                                    icon="remove"
                                    title="Закрыть"
                                    onClick={estateStore.setSelectedMapItems.bind(estateStore, [])}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                    <EstateMapList />
                </Sidebar>

                <Sidebar.Pusher>
                    <EstatesMapMobileInner />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}

export default EstateMapMobile;
