import { EstateAddressStrict, UploadingPhoto } from './estate.types';

export const NEWBUILDING_BASE_YANDEX = 'yandex';
export const NEWBUILDING_BASE_AVITO = 'avito';

export type NEWBUILDING_BASES = 'yandex' | 'avito';

export type YandexHouse = {
    yandex_house_id: number;
    yandex_newobject_id: number;
    newbuilding_id: number;
    newbuilding_house_id: number;

    address: string;
    name: string;
    url: string;

    buildYear: number;
    readyQuarter: number;
    buildingPhase: string;
};

export type AvitoHouse = {
    avito_house_id: number;
    avito_newobject_id: number;
    newbuilding_id: number;
    newbuilding_house_id: number;

    address: string;
    name: string;
    developer: string;

    enable: boolean;
};

export type NewbuildingInBase = EstateAddressStrict & {
    readonly newbuilding_id: number;
    name: string;

    buildYear: number | null;
    readyQuarter: number | null;

    about: string | null;
    lifeComplexClass: number | null;
    floorsCount: number | null;
    materialType: number | null;
    ceilingHeight: number | null;
    decorations: string | null;
    parkingTypes: string | null;

    developer_id: number | null;

    agentBonusType: 1 | 2 | null;
    agentBonusValue: number | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type DeveloperFeed = {
    developer_feed_id: number;
    developer_id: number;

    format: 'cian2' | 'yandex' | 'domclick';
    feedUrl: string | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type DeveloperInBase = {
    developer_id: number;
    name: string;
    logoUrl: string | null;
    url: string | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type Developer = DeveloperInBase & {
    feedsCount: number;
};

export type LifeComplexDomclickInfoBuilding = {
    domclick_building_id: number;
    title: string;
    newbuilding_house_id: number | null;
    flatsInFeedCount: number;
};

export type LifeComplexDomclickInfo = {
    domclick_lc_id: number;
    address: string;
    title: string;
    geo_lat: number;
    geo_lon: number;

    newbuilding_id: number | null;

    buildings: LifeComplexDomclickInfoBuilding[];
};

export type DomclickDeveloper = {
    id: number;
    name: string;
    phone: string;
    site: string;
    logo?: string;
};

export type Newbuilding = NewbuildingInBase & {
    developer: Developer | null;
    minPrice: number | null;
    maxPrice: number | null;
    minArea: number | null;
    maxArea: number | null;
    houses: NewBuildingHouse[];
    photos: UploadingPhoto[];
    major_user_ids: number[];
    major_users: NewbuildingUser[];
};

export type NewBuildingHouse = EstateAddressStrict & {
    readonly newbuilding_house_id: number;
    newbuilding_id: number;
    name: string;
};

export type DomclickNewbuildingInput = {
    domclick_lc_id: number;
    newbuilding_id: number;
    address: string;
    geo_lat: number;
    geo_lon: number;
    title: string;
    developer: string;
};

export type DomclickNewbuildingHouseInput = {
    domclick_house_id: number;
    domclick_lc_id: number;
    newbuilding_id: number;
    newbuilding_house_id: number;
    name: string;
    enable: boolean;
};

export type NebuildingHouseInfo = {
    newbuilding_house_id: number;
    name: string;
    flatsInSaleCount: number;
};

export type LifeComplexApartmentsFilter = {
    newbuilding_house_id: number | null;
    roomsCount: number[];
    priceMin: number | null;
    priceMax: number | null;
    totalAreaMin: number | null;
    totalAreaMax: number | null;
};

export type NewbuildingUser = {
    newbuilding_user_id: number;
    database: string;
    user_id: number;
    email: string;
    phone: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};
