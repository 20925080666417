import React, { useMemo, useState } from 'react';
import { fetchWhatsappChatList } from '~/api/chatApi';
import authStore from '~/stores/authStore';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { WhatsappChatTitle } from '~/types/whatsapp.types';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';

const waTitleStr = (waChatTitle: WhatsappChatTitle): string => {
    const { waContact, phone } = waChatTitle;
    return `${waContact.contactName || waContact.pushname} (${adjustPhoneNumber(phone)})`;
};

type WhatsappDropdownProps = {
    value: WhatsappChatTitle | null;
    onChange: (value: WhatsappChatTitle | null) => void;
};

const WhatsappDropdown = ({ value, onChange }: WhatsappDropdownProps) => {
    const [waChats, setWaChats] = useState<WhatsappChatTitle[]>([]);
    useMemo(() => {
        fetchWhatsappChatList(authStore.currentUser.user_id, 1000, 0).then(list => setWaChats(list));
    }, []);

    const handleChangeWhatsappChatId = (event, data: { value: number; text: string } | null) => {
        let value = null;
        if (data) {
            const waTitle = waChats.find(({ whatsapp_chat_id }) => whatsapp_chat_id === data.value);
            value = waTitle || null;
        }
        onChange(value);
    };

    return (
        <Autocomplete
            options={waChats.map(waTitle => ({ text: waTitleStr(waTitle), value: waTitle.whatsapp_chat_id }))}
            getOptionLabel={option => option.text}
            getOptionSelected={option => value && option.value === value.whatsapp_chat_id}
            renderInput={params => <TextField {...params} label="Whatsapp Чат" variant="outlined" />}
            onChange={handleChangeWhatsappChatId}
            noOptionsText="Whatsapp чаты не найдены"
        />
    );
};

export default WhatsappDropdown;
