import React, { Component, Fragment } from 'react';
import { Icon, Popup, Loader, Feed, Divider, Button, Checkbox, Header, SemanticCOLORS } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import EventModal from '../EventModal';
import Time from '../../Base/Time';
import './callEvents.less';
import UsersLinkList from '../../Lists/Users/UsersLinkList';
import contactStore from '~/stores/contactStore';
import { nl2br } from '~/api/commentsApi';

const CALL_EVENT_ID_CLOSE = -1;

type CallEventsProps = {
    contact_id: number;
};

@observer
class CallEventModal extends Component<CallEventsProps, { showCallEventId: number }> {
    constructor(props: CallEventsProps) {
        super(props);

        this.state = {
            showCallEventId: CALL_EVENT_ID_CLOSE
        };

        contactStore.fetchCallsEventsByContact(props.contact_id);
    }

    handleModal = (call_event_id: number) => {
        this.setState({
            showCallEventId: call_event_id
        });
    };

    handleCreate = () => {
        this.setState({
            showCallEventId: CREATING_ITEM_ID
        });
    };

    handleCloseModal = () => {
        this.setState({
            showCallEventId: CALL_EVENT_ID_CLOSE
        });
    };

    handleCallHasReleased(call_event_id: number, released: boolean) {
        contactStore.changeCallEventReleased(this.props.contact_id, call_event_id, released);
    }

    render() {
        const { showCallEventId } = this.state;
        const { contact_id } = this.props;
        const { contactCallsEvents, loadingContactCallsEvents } = contactStore.getItem(contact_id).property;

        const currentTime = Date.now();

        return (
            <Fragment>
                <Button
                    size="tiny"
                    animated
                    color="green"
                    title="Добавить запланированный звонок"
                    onClick={this.handleCreate}
                    floated="right"
                >
                    <Button.Content hidden>
                        <Icon name="calendar" />
                    </Button.Content>
                    <Button.Content visible>
                        <Icon name="plus" />
                    </Button.Content>
                </Button>

                <Divider horizontal>
                    <Header as="h5" className="crm-Item__listDivider">
                        Запланированные звонки
                    </Header>
                </Divider>

                <Feed>
                    {contactCallsEvents.map(contactCallEvent => {
                        const { call_event_id, assign_users, title, callTimeMin, callTimeMax, description, callHasReleased } =
                            contactCallEvent;

                        let phone = null;

                        if (contactCallEvent.item_type === 'contact' && contactCallEvent.contact.phones) {
                            phone = contactCallEvent.contact.phones[0].phone;
                        }

                        let phoneColor: SemanticCOLORS = 'green';

                        if (currentTime >= callTimeMin * 1000 && currentTime < callTimeMax * 1000) {
                            phoneColor = 'yellow';
                        } else if (currentTime > callTimeMin * 1000) {
                            phoneColor = 'red';
                        }

                        return (
                            <Feed.Event key={`callEvent_${call_event_id}`} className="crm-callEvents__feedItem">
                                <Feed.Label>
                                    <Icon
                                        name="phone"
                                        color={phoneColor}
                                        className="crm-callEvents__phoneIcon"
                                        title="Смотреть детали"
                                        onClick={this.handleModal.bind(this, call_event_id)}
                                    />
                                </Feed.Label>

                                <Feed.Content className={callHasReleased ? 'crm-callEvents__callHasReleased' : ''}>
                                    <Feed.Summary>
                                        <Feed.Date>
                                            <Checkbox
                                                title="Звонок состоялся"
                                                onClick={this.handleCallHasReleased.bind(this, call_event_id, !callHasReleased)}
                                                className="crm-callEvents__feedItem_checkbox"
                                                checked={callHasReleased}
                                            />
                                            <Time time={callTimeMin} /> &nbsp;&nbsp;
                                            <UsersLinkList usersList={assign_users} />
                                        </Feed.Date>
                                    </Feed.Summary>
                                    <Feed.Extra text>
                                        <button
                                            title="Смотреть детали"
                                            onClick={this.handleModal.bind(this, call_event_id)}
                                            className="crm-callEvents__feedItem_titleBtn"
                                        >
                                            {title}
                                        </button>
                                        {description && (
                                            <Popup
                                                trigger={
                                                    <div className="crm-callEvents__descriptionTrigger">
                                                        <Icon name="comment" color="grey" />
                                                    </div>
                                                }
                                            >
                                                <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                                            </Popup>
                                        )}
                                        {phone && (
                                            <a
                                                href={`tel:+${phone}`}
                                                title={`Набрать номер: +${phone}`}
                                                className="crm-callEvents__feedItem_callBtn"
                                            >
                                                <Icon name="volume control phone" color="blue" circular size="small" />
                                            </a>
                                        )}
                                    </Feed.Extra>
                                </Feed.Content>
                            </Feed.Event>
                        );
                    })}
                </Feed>

                <Loader active={loadingContactCallsEvents} />

                {showCallEventId !== CALL_EVENT_ID_CLOSE && (
                    <EventModal item_id={showCallEventId} contact_id={contact_id} onClose={this.handleCloseModal} />
                )}
            </Fragment>
        );
    }
}

export default CallEventModal;
