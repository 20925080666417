import React from 'react';
import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import { CONTACT_TYPE_PRIVATE_PERSON_ID } from '~/types/contacts.types';
import userStore from '~/stores/userStore';

const userKeys = {
    lastName: 'Фамилия',
    middleName: 'Отчество',
    firstName: 'Имя',

    sex: 'Пол',
    birthday: 'День рождения',
    about: 'Описание',
    tags_ids: 'Номера тэгов',
    phones: 'Телефон(ы)',

    resetBalance: 'Списано за экспорт',
    redeemTime: 'На какую дату списано',
    addUserPayment: 'Начисление/Списание суммы',
    resetUserPayments: 'Обнуление всех начислений',

    resetPassword: 'Сброс пароля',
    hiddenDescription: 'Скрытое описание',
    changeAvatar: 'Фотография',
    main_metro_id: 'Станция метро',
    innerPhones: 'Внутренний ID',
    whatsappIsEnable: 'Whatsapp подключен',
    deactivateTelegram: 'Отключение Telegram',
    activateTelegram: 'Подключение Telegram'
};

const printUserVal = (key: string, value: unknown): string => {
    switch (key) {
        case 'contactType':
            return value === CONTACT_TYPE_PRIVATE_PERSON_ID ? 'Физическое лицо' : 'Юридическое лицо';
        case 'enable':
            return value ? 'нет' : 'да';
        case 'updateTime':
        case 'createTime':
        case 'redeemTime':
            return Number(value) > 0 ? new Date(Number(value) * 1000).toLocaleString() : 'н/д';
        case 'phones':
            if (value instanceof Array) {
                return value.map(({ phone }) => phone).join(', ');
            }
            return '';
        case 'addUserPayment':
            return `${Number(value).toLocaleString()} ₽`;
        default:
            break;
    }

    if (value instanceof Array) {
        return value.join(', ');
    } else if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(userStore, userKeys, printUserVal);
