import React, { PureComponent, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import ListPagination from './ListPagination';
import { ListPaginationProps } from './ListPagination';

class ListFooter extends PureComponent<ListPaginationProps> {
    render() {
        return (
            <Table.Footer fullWidth className="crm-Print__hidden">
                <ListPagination {...this.props} />
            </Table.Footer>
        );
    }
}

export default ListFooter;
