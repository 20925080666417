import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import userStore from '~/stores/userStore';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import LoaderAwait from '~/components/Base/LoaderAwait';

import UserWhatsappEditing from '../EditBlocks/UserWhatsappEditing';

const useStyles = makeStyles(({ spacing }) => ({
    description: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing(2)
    }
}));

const UserWhatsappShowBlock = observer((props: EditingWrappedProps) => {
    const classes = useStyles();
    const { item_id, editing } = props;
    const { item, loadingItem } = userStore.getItem(item_id);
    const { whatsappIsEnable } = item || {};

    return (
        <Card fluid color="green">
            <Card.Content>
                {!editing && (
                    <Fragment>
                        <Card.Description className={classes.description}>
                            <WhatsAppIcon style={whatsappIsEnable ? { color: 'green' } : {}} /> Whatsapp&nbsp;
                            {whatsappIsEnable ? 'подключен' : 'не подключен'}
                        </Card.Description>
                    </Fragment>
                )}
                {editing && !loadingItem && <UserWhatsappEditing item_id={item_id} {...item} />}
            </Card.Content>

            <LoaderAwait active={loadingItem} dimmer size="large" />
        </Card>
    );
});

export default EditingBlockWrapper(userStore, UserWhatsappShowBlock);
