import React, { useState, Fragment, useMemo, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import TagModal from './TagModal';
import tagStore from '~/stores/tagStore';
import authStore from '~/stores/authStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import ContainerHeader from '~ui/ContainerHeader';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SettingsListPaper } from '~/components/Settings';
import LoaderAwait from '~/components/Base/LoaderAwait';
import ListError from '~/components/ListError';

const Tags = () => {
    const [showTagId, setShowTagId] = useState(-1);

    useMemo(() => {
        tagStore.fetchList();
    }, []);

    const handleModal = (tag_id: number) => {
        setShowTagId(tag_id);
    };
    const handleCreate = useCallback(() => {
        setShowTagId(CREATING_ITEM_ID);
    }, []);

    const { list, loadingList, listErrors } = tagStore;

    return (
        <Fragment>
            <ContainerHeader
                title="Тэги"
                icon={<LocalOfferIcon fontSize="large" />}
                handleCreate={handleCreate}
                enableCreating={authStore.canCreate(tagStore.moduleName)}
            />

            <ListError errors={listErrors} />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ tag_id, title, color }, index) => (
                        <ListItem button key={tag_id} onClick={() => handleModal(tag_id)}>
                            <ListItemText
                                primary={
                                    <>
                                        <Icon name="tag" color={color} /> {title}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {showTagId > -1 && (
                <TagModal
                    item_id={showTagId}
                    handleClose={() => {
                        setShowTagId(-1);
                    }}
                />
            )}
        </Fragment>
    );
};

export default observer(Tags);
