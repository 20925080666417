import { observable, action, computed } from 'mobx';
import * as tagsApi from '~/api/tagsApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import deepCopy from '../common/deepCopy';
import { Tag } from '~/types/contacts.types';
import { DropdownType } from './prototypes/ListStore.prototype';
import authStore from './authStore';

const ALL_TAGS_KEY = 'ALL_TAGS_KEY';

class TagStore extends ListStorePrototype<Tag> {
    constructor() {
        super('tag_id', 'settings', tagsApi);
        authStore.registerInitFunc(this.fetchAllTags.bind(this));

        const memoryListJSON = window.localStorage.getItem(ALL_TAGS_KEY);
        if (memoryListJSON) {
            this.loadingFetchAllTags = false;
            this.allTags = JSON.parse(memoryListJSON);
        }
    }

    @observable
    loadingFetchAllTags = true;
    allTags: Tag[] = [];

    @action
    async fetchAllTags() {
        this.allTags = await tagsApi.fetchAllTags();
        this.loadingFetchAllTags = false;
        window.localStorage.setItem(ALL_TAGS_KEY, JSON.stringify(this.allTags));
    }

    @computed
    get allTagsDropdown(): DropdownType[] {
        return this.allTags.map(({ tag_id, title }) => ({ text: title, value: tag_id, key: tag_id }));
    }

    @action
    async fetchItem(id: number, base?: string | null) {
        await super.fetchItem(id);
        const { item } = this.getItem(id);
        if (item) {
            this.setEditingItem(id, deepCopy(item));
        }
    }

    @action
    async createItem(): Promise<number> {
        const tag_id = await super.createItem();
        this.loadingFetchAllTags = true;

        await tagsApi.fetchAllTags();
        this.loadingFetchAllTags = false;

        return tag_id;
    }

    findById(tag_id: number): Tag {
        const tag = this.allTags.find(tag => tag.tag_id === tag_id);
        if (!tag) {
            throw new Error(`Tag didn't find: ${tag_id}`);
        }

        return tag;
    }
}

export default new TagStore();
