import React, { Component, Fragment } from 'react';
import { Form, Checkbox, Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { Estate } from '~/types/estate.types';

type InfrastructureFieldsType = {
    hasCarWash?: boolean | null;
    hasBuffet?: boolean | null;
    hasCarService?: boolean | null;
    hasCanteen?: boolean | null;
    hasCentralReception?: boolean | null;
    hasHotel?: boolean | null;
    hasAtm?: boolean | null;
    hasExhibitionAndWarehouseComplex?: boolean | null;
    hasPharmacy?: boolean | null;
    hasBankDepartment?: boolean | null;
    hasCinema?: boolean | null;
    hasCafe?: boolean | null;
    hasMedicalCenter?: boolean | null;
    hasBeautyShop?: boolean | null;
    hasStudio?: boolean | null;
    hasNotaryOffice?: boolean | null;
    hasPool?: boolean | null;
    hasClothesStudio?: boolean | null;
    hasWarehouse?: boolean | null;
    hasPark?: boolean | null;
    hasRestaurant?: boolean | null;
    hasFitnessCentre?: boolean | null;
    hasSupermarket?: boolean | null;
    hasMinimarket?: boolean | null;
    hasShoppingArea?: boolean | null;
    hasConferenceRoom?: boolean | null;
    hasFoodCourt?: boolean | null;
    hasSlotMachines?: boolean | null;
    hasAquapark?: boolean | null;
    hasBabySitting?: boolean | null;
    hasRink?: boolean | null;
    hasBowling?: boolean | null;
    hasGameRoom?: boolean | null;
};

type ShoppingInfrastructureBlockEditingProps = HandlerEditingWrappedProps & InfrastructureFieldsType;

export const INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasCarWash', 'Автомойка'],
    ['hasBuffet', 'Буфет'],
    ['hasCarService', 'Автосервис'],
    ['hasCanteen', 'Столовая'],
    ['hasCentralReception', 'Центральная рецепция'],
    ['hasHotel', 'Гостиница'],
    ['hasAtm', 'Банкомат'],
    ['hasFoodCourt', 'Фуд-корт'],
    ['hasPharmacy', 'Аптека'],
    ['hasBankDepartment', 'Отделение банка'],
    ['hasSlotMachines', 'Игровые автоматы'],
    ['hasCinema', 'Кинотеатр'],
    ['hasAquapark', 'Аквапарк'],
    ['hasBabySitting', 'Беби-ситинг'],
    ['hasRink', 'Каток'],
    ['hasCafe', 'Кафе'],
    ['hasBowling', 'Боулинг'],
    ['hasMedicalCenter', 'Медицинский центр'],
    ['hasBeautyShop', 'Салон красоты'],
    ['hasStudio', 'Фотосалон'],
    ['hasPool', 'Бассейн'],
    ['hasClothesStudio', 'Ателье одежды'],
    ['hasWarehouse', 'Складские помещения'],
    ['hasRestaurant', 'Ресторан'],
    ['hasFitnessCentre', 'Фитнес-центр'],
    ['hasSupermarket', 'Супермаркет'],
    ['hasMinimarket', 'Минимаркет'],
    ['hasGameRoom', 'Игровая комната']
];

@observer
class ShoppingInfrastructureBlockEditing extends Component<ShoppingInfrastructureBlockEditingProps> {
    constructor(props: ShoppingInfrastructureBlockEditingProps) {
        super(props);

        const estate: InfrastructureFieldsType = {};
        INFRASTURCTURE_FIELDS.forEach(field => {
            estate[field[0]] = typeof props[field[0]] === 'boolean' ? props[field[0]] : null;
        });

        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { item_id, handleCheckbox } = this.props;

        const editingItem = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <Form.Group>
                    <Form.Field inline>
                        <Grid columns={3}>
                            {INFRASTURCTURE_FIELDS.map(field => (
                                <Grid.Column key={field[0]}>
                                    <Checkbox
                                        checked={Boolean(editingItem[field[0]])}
                                        name={field[0]}
                                        label={<label>{field[1]}</label>}
                                        onChange={handleCheckbox}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, ShoppingInfrastructureBlockEditing);
