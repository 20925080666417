import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import DividerMui from '@material-ui/core/Divider';

export const Divider = withStyles(() =>
    createStyles({
        root: {
            margin: '1rem 0'
        }
    })
)(DividerMui);

export default Divider;
