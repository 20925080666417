import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import EditField from '../../../Base/EditField';

import requestStore from '~/stores/requestStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type RequestHuntingEditionProps = HandlerEditingWrappedProps & {
    requestObj: {
        description?: string;
    };
};

@observer
class RequestHuntingEdition extends Component<RequestHuntingEditionProps> {
    constructor(props: RequestHuntingEditionProps) {
        super(props);

        const request = props.requestObj;

        const item = {
            requestObj: {
                description: request.description || ''
            }
        };
        requestStore.setEditingItem(props.item_id, item);
    }

    handleDescription = ({ value, name }: { name: string; value: string }) => {
        let { requestObj } = requestStore.getItem(CREATING_ITEM_ID).editingItem;

        requestObj = { ...requestObj, [name]: value.replace(/"/g, '') };

        requestStore.setEditingItem(CREATING_ITEM_ID, {
            requestObj
        });
    };

    render() {
        const { item_id } = this.props;
        const { requestObj } = requestStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Area
                    label="Описание"
                    value={requestObj ? requestObj.description : ''}
                    name="description"
                    onChange={this.handleDescription}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(requestStore, RequestHuntingEdition);
