import React, { PureComponent, Fragment, FormEvent, ChangeEventHandler, ChangeEvent } from 'react';
import { ERROR_CAPITALIZATION, ERROR_REPEAT_WORD, ERROR_TOO_MANY_ERRORS, ERROR_UNKNOWN_WORD, makeCheck } from '~/api/spellerApi';
import { Popup, List } from 'semantic-ui-react';
import { TSpellError } from '~/api/spellerApi';
import cs from 'classnames';

import './less/smartTextArea.less';
import debounce, { clearDebounce } from '../../common/debounce';

type SmartTextAreaProps = {
    value: string;
    name: string;
    onChange: (data: { name: string; value: string }) => void;
    maxLength?: number;
};

type SmartTextAreaState = {
    loading: boolean;
    isSelection: boolean;
    errors: TSpellError[];
};

class SmartTextArea extends PureComponent<SmartTextAreaProps, SmartTextAreaState> {
    debounceMakeCheck: () => void;
    innerRef: { current: null | HTMLTextAreaElement };

    constructor(props: SmartTextAreaProps) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            loading: false,
            errors: [],
            isSelection: false
        };
        this.debounceMakeCheck = debounce(this.makeSpellerCheck, 2000, false, true);

        if (props.value && props.value.length) {
            this.debounceMakeCheck();
        }
    }

    makeSpellerCheck = async () => {
        this.setState({ loading: true });
        try {
            const errors = await makeCheck(this.props.value);
            this.setState({ loading: false, isSelection: false, errors });
        } catch (errors) {
            this.setState({ loading: false, isSelection: false });
        }
    };

    onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onChange({ value: event.target.value, name: this.props.name });
        this.debounceMakeCheck();
    };

    selectWord = (index: number) => {
        const $textarea = this.innerRef.current;
        if ($textarea) {
            $textarea.focus();
            const { errors } = this.state;
            const { value } = this.props;
            const { word } = errors[index];
            const founIndex = value.indexOf(word);
            $textarea.setSelectionRange(founIndex, founIndex + word.length);
            this.setState({ isSelection: true });
        }
    };

    unselectWord = () => {
        const $textarea = this.innerRef.current;
        if ($textarea) {
            $textarea.setSelectionRange(0, 0);
            this.setState({ isSelection: false });
        }
    };

    replaceError = (index: number, newWord: string) => {
        const { errors } = this.state;
        let { value, name } = this.props;
        const { word } = errors[index];
        value = value.replace(word, newWord);
        errors.splice(index, 1);
        this.setState({
            errors: [...errors],
            isSelection: false
        });
        this.props.onChange({ value, name });
    };

    componentWillUnmount() {
        clearDebounce(this.debounceMakeCheck);
    }

    render() {
        const { loading, errors, isSelection } = this.state;
        const { maxLength } = this.props;
        const { value } = this.props;

        return (
            <Fragment>
                <textarea
                    ref={this.innerRef}
                    className={cs({
                        'crm-SmartTextArea__loading': loading,
                        'crm-SmartTextArea__selection': isSelection,
                        'crm-Estate__description_textarea': true
                    })}
                    onChange={this.onChange}
                    value={value}
                    maxLength={maxLength || 9999}
                />
                {errors.length > 0 && (
                    <Fragment>
                        <div>
                            <b>Возможные ошибки</b>
                        </div>

                        <div className="crm-SmartTextArea__box">
                            {errors.map(({ word, suggests, code }, index) => {
                                return (
                                    <Popup
                                        onMouseEnter={this.selectWord.bind(this, index)}
                                        onMouseLeave={this.unselectWord}
                                        hoverable
                                        key={`word-${word}`}
                                        trigger={
                                            <span
                                                className="crm-SmartTextArea__error"
                                                onMouseEnter={this.selectWord.bind(this, index)}
                                                onMouseLeave={this.unselectWord}
                                            >
                                                {word}
                                            </span>
                                        }
                                    >
                                        <List>
                                            <List.Item>
                                                <List.Header>
                                                    {code === ERROR_UNKNOWN_WORD && 'Возможные замены'}
                                                    {code === ERROR_REPEAT_WORD && 'Повторяющееся слово'}
                                                    {code === ERROR_CAPITALIZATION && 'Проблема в заглавной букве'}
                                                    {code === ERROR_TOO_MANY_ERRORS && 'Слишком много ошибок'}
                                                </List.Header>
                                            </List.Item>
                                            {suggests.map(suggest => (
                                                <List.Item
                                                    key={suggest}
                                                    as={'a'}
                                                    content={suggest}
                                                    onClick={this.replaceError.bind(this, index, suggest)}
                                                />
                                            ))}
                                        </List>
                                    </Popup>
                                );
                            })}
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default SmartTextArea;
