import { UserGroup, UserGroupInBase, UserLinkType } from './users.types';
import { ExportStatChartFilter } from './exportStatistics.types';

export enum KPI_TYPE {
    single = 1,
    group = 2
}

export type KpiInBase = {
    kpi_id: number;
    kpiType: KPI_TYPE;
    title: string;
    showOnDesk: boolean;
    useAsMainTable: boolean | null;
    redValue: number | null;
    greenValue: number | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type KpiType = KpiInBase & {
    access_ids: number[];
    points: Partial<KpiPoint>[];
};

export type KpiPoint = {
    point_id: number;
    kpi_id: number;
    pointType: number;
    days: number;
    weight: number;
    redValue: number | null;
    greenValue: number | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type PointValue = {
    point_id: number;
    pointType: number;
    value: number;
    weight: number;
    redValue: number | null;
    greenValue: number | null;
};

export type KpiStatistics = {
    kpi: number;
    pointsValue: PointValue[];
} & (
    | {
          kpiType: KPI_TYPE.single;
          major_user_id: number;
          major_user: UserLinkType;
      }
    | {
          kpiType: KPI_TYPE.group;
          usersAccessCount: number;
          group_id: number;
          group: UserGroup;
      }
);

export type KpiStoreFilter = {
    kpi_id: number | null;
    major_user_id: number[];
    group_id: number[];
};

export type UserKpiStatistics = {
    kpi: KpiInBase;
    statistics: KpiStatistics;
};

export type KpiHistory = {
    kpi_history_id: number;
    kpi_id: number;
    item_id: number;
    kpi: number;
    time: number;
};

export type UserStatisticsFilter = ExportStatChartFilter & {
    kpi_id: number;
};
