import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';

import { EstateLinkState } from '~/components/Lists/Estate/EstateLink';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import estateStore from '~/stores/estateStore';
import { ESTATE_BASE_MLS } from '~/types/estate.types';

@observer
class MlsTabMenu extends Component<TabMenuWrapperProps> {
    render() {
        const { item_id } = this.props;

        return (
            <MenuItem component={'a' as any} href={`/print/mls-${item_id}`} target="_blank">
                <Icon name="print" />
                На печать
            </MenuItem>
        );
    }
}

export default TabMenuWrapper(estateStore, EstateLinkState, MlsTabMenu, {
    base: ESTATE_BASE_MLS,
    disableDeleteButton: true
});
