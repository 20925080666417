import fetchApi from '../common/fetchApi';

const yandexSpellerUrl = `https://speller.yandex.net/services/spellservice.json/checkText?options=12&text=`;

export const ERROR_UNKNOWN_WORD = 1;
export const ERROR_REPEAT_WORD = 2;
export const ERROR_CAPITALIZATION = 3;
export const ERROR_TOO_MANY_ERRORS = 4;

export type TSpellError = {
    word: string;
    code: 1 | 2 | 3 | 4;
    suggests: string[];
};

export const makeCheck = async (text: string): Promise<TSpellError[]> => {
    const errorsJson = await fetchApi.loadPlain(yandexSpellerUrl + text);
    const errors = JSON.parse(errorsJson);
    return errors.map(({ word, code, s }: { word: string; code: 1 | 2 | 3 | 4; s: string[] }) => ({ word, code, suggests: s }));
};
