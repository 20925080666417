import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import requestStore from '~/stores/requestStore';

import { Estate } from '~/types/estate.types';
import { nl2br } from '~/api/commentsApi';
import estateL10n from '../../../../L10n/estate.L10n';
import MonoString from '../../../Base/MonoString';

@observer
class RequestEstateShow extends Component<{ item_id: number }, { estate: Estate | null }> {
    render() {
        const { item_id } = this.props;
        const { item } = requestStore.getItem(item_id);
        const { requestJson } = item || {};

        let estate: Estate | null = null;

        try {
            estate = requestJson ? JSON.parse(requestJson) : null;
        } catch (e) {}

        return estate ? (
            <Card fluid color="red">
                <Card.Content>
                    <Header size="tiny">Детали</Header>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>Адрес</Grid.Column>
                            <Grid.Column>{estate.address}</Grid.Column>

                            <Grid.Column>Количество комнат</Grid.Column>
                            <Grid.Column>
                                <MonoString template="?" values={[estate.roomsCount || '-']} />
                            </Grid.Column>

                            <Grid.Column>Площадь</Grid.Column>
                            <Grid.Column>
                                <MonoString
                                    template="?/?/? м²"
                                    values={[estate.totalArea || '-', estate.livingArea || '-', estate.kitchenArea || '-']}
                                />
                            </Grid.Column>

                            <Grid.Column>Этаж</Grid.Column>
                            <Grid.Column>
                                <MonoString template="? из ?" values={[estate.floorNumber || '-', estate.floorsCount || '-']} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
                <Card.Content>
                    <Header size="tiny">Описание</Header>
                    <span dangerouslySetInnerHTML={{ __html: estate.description }} />
                </Card.Content>
                <Card.Content>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>Стоимость</Grid.Column>
                            <Grid.Column>
                                {estate.price && `${estate.price.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(estate.currency)}`}
                                {estate.type === 2 && ` / в месяц`}
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column>
                                {estate.type === 1
                                    ? estateL10n.SALE_TYPES_FIND(estate.saleType)
                                    : estateL10n.LEASE_TYPES_FIND(estate.leaseTermType)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        ) : null;
    }
}

export default RequestEstateShow;
