import React, { ReactElement, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import commonStore from '~/stores/commonStore';

const useStyles = makeStyles(() => ({
    header: {
        alignItems: 'center',
        margin: '1rem 0 0.725rem',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridGap: '0.725rem'
    }
}));

const ContainerHeader = observer(
    ({
        handleCreate,
        icon,
        title,
        enableCreating
    }: {
        handleCreate?: () => void;
        icon: ReactElement;
        title: ReactNode;
        enableCreating: boolean;
    }) => {
        const classes = useStyles();
        const { isMobile } = commonStore;

        return (
            <div className={classes.header}>
                {icon}
                <Typography variant="h5">{title}</Typography>
                {handleCreate && enableCreating && !isMobile && (
                    <Button color="primary" variant="contained" startIcon={icon} onClick={handleCreate}>
                        Добавить
                    </Button>
                )}
                {handleCreate && enableCreating && isMobile && (
                    <IconButton color="primary" onClick={handleCreate}>
                        <AddCircleIcon fontSize="large" />
                    </IconButton>
                )}
            </div>
        );
    }
);

export default React.memo(ContainerHeader, () => true);
