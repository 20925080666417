import React, { Fragment } from 'react';
import { Divider, Header, Icon, List, SemanticICONS } from 'semantic-ui-react';
import { ESTATE_BASE_OWNERS } from '~/types/estate.types';

import ShowingLink from '../../../../Lists/Showings/ShowingLink';
import RequestLink from '../../../../Lists/Requests/RequestLink';
import DealLink from '../../../../Lists/Deals/DealLink';

import Time from '../../../../Base/Time';
import WishLink from '../../../../Lists/Wishes/WishLink';
import estateStore from '~/stores/estateStore';
import showingStore from '~/stores/showingStore';
import requestStore from '~/stores/requestStore';
import dealStore from '~/stores/dealStore';
import wishStore from '~/stores/wishStore';
import meetingEventStore from '~/stores/meetingEventStore';
import MeetingEventLink from '../../../../CalendarEvents/MeetingEvents/MeetingEventLink';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import EstateLinkWithPreview from '../../../../Lists/Estate/EstateLinkWithPreview';

const InsertedItemsList = ({
    items,
    itemsLoading,
    icon,
    module,
    emptySetTitle
}: {
    items: any[];
    itemsLoading: boolean;
    icon: SemanticICONS;
    module: ListStoreInterface;
    emptySetTitle: string;
}) => (
    <Fragment>
        {items && items.length > 0 && (
            <List divided={module !== estateStore} relaxed>
                {items.map((item, i) => (
                    <Fragment key={i}>
                        {module === estateStore && (i === 0 || item.base !== items[i - 1].base) && (
                            <Divider horizontal>
                                <Header as="h5" className="crm-Item__listDivider">
                                    {item.base === ESTATE_BASE_OWNERS ? (
                                        <Fragment>
                                            <Icon name="male" color="brown" />
                                            Собственник
                                        </Fragment>
                                    ) : (
                                        'Листинг'
                                    )}
                                </Header>
                            </Divider>
                        )}

                        <List.Item>
                            <List.Icon name={icon} verticalAlign="middle" />
                            <List.Content>
                                <List.Header>
                                    {module === estateStore && (
                                        <Fragment>
                                            {item.base === ESTATE_BASE_OWNERS && <Icon title="Собственник" name="male" color="brown" />}
                                            <EstateLinkWithPreview estate={item} base={item.base} />
                                        </Fragment>
                                    )}
                                    {module === showingStore && <ShowingLink showing={item} />}
                                    {module === requestStore && <RequestLink request={item} />}
                                    {module === dealStore && <DealLink deal={item} />}
                                    {module === wishStore && <WishLink wish={item} />}
                                    {module === meetingEventStore && <MeetingEventLink meetingEvent={item} />}
                                </List.Header>
                                <List.Description>
                                    <Time time={item.createTime} />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </Fragment>
                ))}
            </List>
        )}
        {!itemsLoading && items.length === 0 && emptySetTitle}
    </Fragment>
);

export default InsertedItemsList;
