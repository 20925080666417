import React, { Fragment, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { ESTATE_PROPERTY_TYPE_ROOM } from '~/types/estate.types';
import { ROOMS_SEPARATOR_REG, ROOMS_SEPARATOR } from '~/stores/helpers/export.store.helpers';

type RoomsAreasProps = {
    roomsCount: number;
    allRoomsArea: string;
    onChange: (str: string) => void;
    propertyType: number;
};

const RoomsAreas = ({ roomsCount, allRoomsArea, onChange, propertyType }: RoomsAreasProps) => {
    const [roomsAreas, setRoomsAreas] = useState<number[]>([]);

    useEffect(() => {
        const roomsAreas = Array(roomsCount).fill(0);
        try {
            (String(allRoomsArea).match(ROOMS_SEPARATOR_REG) || []).map((value, index) => {
                if (index < roomsCount) {
                    roomsAreas[index] = Number(value && value.replace(',', '.')) || 0;
                }
            });
        } catch (e) {
            console.log(e.message);
        }
        setRoomsAreas(roomsAreas);
    }, [allRoomsArea, roomsCount]);

    const handleRoomsArea = (event, { name, value }: { name: number; value: string }) => {
        const newRoomsAreas = [...roomsAreas];
        newRoomsAreas[name] = Number(value);
        onChange(newRoomsAreas.join(ROOMS_SEPARATOR));
    };

    const rooms = [];

    for (let i = 0; i < roomsCount; i++) {
        rooms.push(
            <Fragment key={`room_${i}`}>
                {i > 0 && <span className="crm-Estate__roomsSeparator">{ROOMS_SEPARATOR}</span>}
                <Input
                    required={propertyType === ESTATE_PROPERTY_TYPE_ROOM}
                    step={0.1}
                    type="number"
                    min={0}
                    max={9999}
                    value={roomsAreas[i] || ''}
                    size="mini"
                    name={i}
                    onChange={handleRoomsArea}
                    className="crm-Estate__squareInput crm-Estate__squareInput_room"
                />
            </Fragment>
        );
    }

    return <>{rooms}</>;
};

export default RoomsAreas;
