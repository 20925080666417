import React, { useMemo, ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { Modal, ModalContentText, ModalContent, ModalBtnAction } from '~ui/Modal';
import TextField from '@material-ui/core/TextField';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ListError from '~/components/ListError';
import kpiStore from '~/stores/kpiStore';
import kanbanStore from '~/stores/kanbanStore';

type CreateItemModalProps = {
    onClose: () => void;
    store: typeof kpiStore | typeof kanbanStore;
    header: string;
    text: string;
};

const CreateItemModal = ({ onClose, store, header, text }: CreateItemModalProps) => {
    useMemo(() => {
        store.fetchItem(CREATING_ITEM_ID);

        store.setEditingItem(CREATING_ITEM_ID, {
            title: ''
        });
    }, []);

    const { editingItem, errors } = store.getItem(CREATING_ITEM_ID);
    const handleTitle = (event: ChangeEvent<HTMLInputElement>) => {
        store.setEditingItem(CREATING_ITEM_ID, { title: event.target.value });
    };

    const createBoard = async () => {
        const item_id = await store.createItem();
        if (item_id) {
            store.fetchItem(item_id);
            onClose();
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'secondary' },
        { onClick: createBoard, label: 'Создать', variant: 'text', color: 'primary' }
    ];

    return (
        <Modal onClose={onClose} maxWidth="sm" header={header} actions={actions}>
            <ModalContent>
                <ModalContentText>{text}</ModalContentText>

                <ListError errors={errors} />
                <TextField
                    value={editingItem['title']}
                    onChange={handleTitle}
                    autoFocus
                    margin="dense"
                    label="Название"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                />
            </ModalContent>
        </Modal>
    );
};

export default observer(CreateItemModal);
