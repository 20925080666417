import React, { Fragment } from 'react';
import { Popup } from 'semantic-ui-react';

import Time from '../../../Base/Time';
import { DAY_MS, printDeltaDaysMs } from '~/common/time';

type EstateTableTimesProps = {
    byCreateTime: boolean;
    enable: boolean;
    createTime: number;
    updateTime: number;
};

const EstateTableTimes = ({ byCreateTime, enable, createTime, updateTime }: EstateTableTimesProps) => {
    const deltaDays = Math.ceil(((enable ? Date.now() : updateTime * 1000) - createTime * 1000) / DAY_MS);

    return (
        <div className="crm-Estate__list_time">
            <Popup
                hoverable
                trigger={
                    <span>
                        <Time time={byCreateTime ? createTime : updateTime} maxDaysToTime={14} />
                        {deltaDays > 0 && <Fragment>&nbsp;({deltaDays}д)</Fragment>}
                    </span>
                }
                size="small"
                wide
            >
                <table>
                    <tbody>
                        <tr>
                            <td>Дата создания</td>
                            <td>{new Date(createTime * 1000).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>Дата обновления</td>
                            <td>{new Date(updateTime * 1000).toLocaleString()}</td>
                        </tr>
                        {deltaDays > 0 && (
                            <tr>
                                <td>{enable ? 'В' : 'Был в'} экспозиции</td>
                                <td>{printDeltaDaysMs(createTime * 1000, enable ? Date.now() : updateTime * 1000)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Popup>
        </div>
    );
};

export default React.memo(EstateTableTimes);
