import React, { Component, Fragment } from 'react';
import { Label, Icon } from 'semantic-ui-react';

const LabelArchive = ({ ribbon }: { ribbon?: boolean }) => (
    <Label as="a" color="red" ribbon={ribbon} tag={!ribbon}>
        Архив
    </Label>
);

export default LabelArchive;
