import { action } from 'mobx';
import * as wishApi from '~/api/wishApi';
import * as estateApi from '~/api/estateApi';
import deepCopy from '../common/deepCopy';

import { OrderDirectionType } from './prototypes/ListStore.prototype';
import { Wish, WishFilter, WishTableType } from '~/types/wish.types';
import estateStore from './estateStore';
import { ESTATE_BASE_GROUP, ESTATE_BASES, EstateFilter, EstateTableItem } from '~/types/estate.types';
import { ESTATE_BASE_ALL, ESTATE_BASE_MAIN, ESTATE_BASE_MY, ESTATE_PROPERTY_FLATS_ARR, TRANSPORT_TYPE_WALK } from '~/types/estate.types';
import { HistoryChange } from '~/types/historyChanges.types';
import { catchItemError } from './helpers/decorators.helpers';
import authStore from '~/stores/authStore';
import { disableIconHiglight, highlightActiveEstateIdOn } from '~/common/osmMapApi';
import ListStoreMapPrototype from '~/stores/prototypes/ListStoreMapPrototype.prototype';

export const DEFAULT_APPROXIMATION_RANGE = 0.1;

export type WishItemTypeProperty = {
    loadingEstates: boolean;
    estates: EstateTableItem[] | null;
    locationType: number;

    loadingHistory: boolean;
    history: HistoryChange[];
};

const FILTER_FIELDS = [
    'floorsCountMin',
    'materialType',
    'buildYearMin',
    'buildYearMax',

    'roomsCount',

    'transportType',
    'transportTime',

    'hasLoggia',
    'hasBalcone',
    'wcType'
];

const FILTER_MIN_MAX = ['price', 'priceM2', 'totalArea', 'livingArea' /*floorNumber*/];

const FILTER_MIN = ['floorNumber'];
const FILTER_MAX = ['floorNumber'];

export class WishStoreClass extends ListStoreMapPrototype<Wish, WishTableType, WishItemTypeProperty, WishFilter, number> {
    listFilterClear: WishFilter = {
        type: 1,
        regionId: 0,
        propertyType: 0,
        propertyTypes: [],
        group_id: [],
        major_user_id: [],
        enable: true,
        roomsCount: [],
        totalAreaMin: null,
        totalAreaMax: null,
        livingAreaMin: null,
        livingAreaMax: null,
        kitchenAreaMin: null,
        kitchenAreaMax: null,
        floorNumberMin: null,
        floorNumberMax: null,
        floorsCountMin: null,
        lastFloorOnly: false,
        materialType: [],
        buildYearMin: null,
        buildYearMax: null,
        hasLoggia: false,
        hasBalcone: false,
        wcType: 0,
        transportTime: null,
        transportType: TRANSPORT_TYPE_WALK,
        main_metro_id: [],
        deltaTime: 0,
        descriptionContains: '',
        townContains: '',
        priceMin: null,
        priceMax: null,
        priceM2Min: null,
        priceM2Max: null,
        showApartmentsOnly: false,
        saleType: null,
        noOutgoingToOwnerCallsTime: null,
        base: ESTATE_BASE_MY
    };

    orderBy = 'createTime';

    COUNT_ITEMS_ON_MAP = 36;

    constructor() {
        super('wish_id', 'wish', wishApi);
        this.clearFilter();
    }

    matchSelectedItemsFiled(wish: WishTableType): number {
        return wish['wish_id'];
    }

    async fetchItem(wish_id: number, base?: string | null, withoutCache?: boolean) {
        await super.fetchItem(wish_id);
    }

    @action
    async fetchList(orderBy = '', orderDirection: OrderDirectionType = 'descending') {
        await estateStore.fetchMetroStations();
        super.fetchList(orderBy, orderDirection);
    }

    validationItem(wish: Wish): Array<string> {
        const errors: Array<string> = [];

        if (!wish.contact_id) {
            errors.push('Заполните контакт');
        }

        return errors;
    }

    static adjustEstateFilter(wish: Partial<Wish>, approximation: number, locationType: number): Partial<EstateFilter> {
        const { propertyType, type } = wish;

        const filter: Partial<EstateFilter> = {
            propertyType,
            type,
            base: ESTATE_PROPERTY_FLATS_ARR.includes(propertyType) ? ESTATE_BASE_ALL : ESTATE_BASE_MAIN,
            enable: true
        };
        filter.propertyTypes = wish['propertyTypes'];

        const approxKef = 1 + approximation;

        FILTER_MIN_MAX.forEach(field => {
            if (wish[`${field}Max`]) {
                filter[`${field}Max`] = Math.round(wish[`${field}Max`] * approxKef);
                if (!wish[`${field}Min`]) {
                    filter[`${field}Min`] = Math.round(wish[`${field}Max`] / approxKef);
                }
            }

            if (wish[`${field}Min`]) {
                filter[`${field}Min`] = Math.round(wish[`${field}Min`] / approxKef);
                if (!wish[`${field}Max`]) {
                    filter[`${field}Max`] = Math.round(wish[`${field}Min`] * approxKef);
                }
            }
        });

        FILTER_MIN.forEach(field => {
            if (wish[`${field}Min`]) {
                filter[`${field}Min`] = Math.round(wish[`${field}Min`] / approxKef);
            }
        });

        FILTER_MAX.forEach(field => {
            if (wish[`${field}Max`]) {
                filter[`${field}Max`] = Math.round(wish[`${field}Max`] * approxKef);
            }
        });

        FILTER_FIELDS.forEach(field => {
            // @ts-ignore
            if (wish[field]) {
                filter[field] = wish[field];
            }
        });

        filter['main_metro_id'] = [];

        if (locationType === 1 && wish['main_metro_id']) {
            filter['main_metro_id'] = wish['main_metro_id'];
        } else if (locationType === 2 && wish['town']) {
            filter['townContains'] = wish['town'];
        }

        return filter;
    }

    @action
    async fetchEstatesByWish(wish_id: number, approximation: number) {
        this.setProperty(wish_id, { estates: null, loadingEstates: true });
        const {
            item,
            property: { locationType }
        } = this.getItem(wish_id);

        if (item) {
            const filter = WishStoreClass.adjustEstateFilter(item, approximation, locationType);
            // @ts-ignore
            const { list } = await estateApi.fetchList(100, 0, 'estate_id', 'descending', filter);

            this.setProperty(wish_id, { estates: list, loadingEstates: false });
        }
    }

    @action
    async firstFetchEstatesByWish(wish_id: number) {
        const { item } = this.getItem(wish_id);
        if (item) {
            const locationType = item.town === 'Москва' ? 1 : 2;
            this.setProperty(wish_id, { locationType });
            await this.fetchEstatesByWish(wish_id, DEFAULT_APPROXIMATION_RANGE);
        }
    }

    @action
    setEstateFilterFromWish(wish_id: number, approximation: number) {
        const {
            item,
            property: { locationType }
        } = this.getItem(wish_id);

        if (item) {
            estateStore.resetPagination();
            // @ts-ignore
            estateStore.listFilter = deepCopy(WishStoreClass.adjustEstateFilter(item, approximation, locationType));
            estateStore.debounceFilterFetch();
        }
    }

    @catchItemError
    async deleteWishWithCause(wish_id: number, deleteCause: number) {
        const { item } = this.getItem(wish_id);

        if (item) {
            await wishApi.deleteWishWithCause(wish_id, deleteCause);
            this.fetchList();
            item.enable = false;
        }
    }

    @catchItemError
    async freeWishBook(wish_id: number) {
        const { item } = this.getItem(wish_id);

        if (item) {
            await wishApi.freeWishBook(wish_id);
            this.fetchList();
            item.isBooked = null;
        }
    }

    @catchItemError
    async takeWishToWork(wish_id: number) {
        await wishApi.takeWishToWork(wish_id);
        this.fetchItem(wish_id, null, true);
        this.fetchList();
    }

    changeFilterBase = (base: ESTATE_BASES & 'group') => {
        this.resetPagination();

        this.orderBy = 'updateTime';
        this.orderDirection = 'descending';
        this.changeFilter('base', base);
        if (ESTATE_BASE_GROUP === base) {
            this.changeFilter('base', ESTATE_BASE_MAIN);
            this.changeFilter('major_user_id', []);
            this.changeFilter('group_id', [authStore.currentUser.group_id]);
        }
    };

    highlightActiveEstateIdOnMap = (wish_id: number) => {
        highlightActiveEstateIdOn(wish_id, 'wish_id');
    };

    disableEstateHighlight = () => {
        disableIconHiglight();
    };
}

export default new WishStoreClass();
