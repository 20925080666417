import fetchApi from '../common/fetchApi';

import { objectToGraphql } from '~/common/graphql';
import { KpiStatistics, KpiStoreFilter, KpiType } from '~/types/kpi.types';
import { GQGroupsFields, GQUserMainFields } from '~/api/userApi';

export const KpiGQLFields = `
    kpi_id
    kpiType
    title
    showOnDesk
    useAsMainTable
    redValue
    greenValue
    enable
    
    points {
        point_id
        kpi_id
        pointType
        days
        weight
        redValue
        greenValue
        enable    
    }
    
    access_ids
`;

export const createItem = async (inputKpi: Partial<KpiType>): Promise<number> => {
    const graphql = `mutation { 
          createKpi(inputKpi: ${objectToGraphql(inputKpi)})
        }`;

    const data: { createKpi: number } = await fetchApi.postGQ(graphql);
    return data.createKpi;
};

export const saveItem = async (kpi_id: number, inputKpiDiff: Partial<KpiType>): Promise<number> => {
    const graphql = `mutation { 
        updateKpi(kpi_id: ${kpi_id}, inputKpiDiff: ${objectToGraphql(inputKpiDiff)})
    }`;

    const data: { updateKpi: number } = await fetchApi.postGQ(graphql);
    return data.updateKpi;
};

export const fetchItem = async (kpi_id: number): Promise<KpiType> => {
    const graphql = `{
            fetchKpi(kpi_id: ${kpi_id}) {
                ${KpiGQLFields}
            } 
          }`;

    const data: { fetchKpi: KpiType } = await fetchApi.getGQ(graphql);
    return data.fetchKpi;
};

export const fetchMainTableKpi = async (): Promise<number | null> => {
    const graphql = `{
            fetchMainTableKpi
          }`;

    const data = await fetchApi.getGQ<{ fetchMainTableKpi: number | null }>(graphql);
    return data.fetchMainTableKpi;
};

export const fetchList = async (): Promise<{ list: KpiType[]; count: number }> => {
    const graphql = `{
            fetchKpiList{
                list {
                    ${KpiGQLFields}
                }
                count
            }
          }`;

    const data: { fetchKpiList: { list: KpiType[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchKpiList;
};

export const KpiGQStatMainFields = `
    kpiType
    kpi
    pointsValue {
        point_id
        pointType
        value
        weight
        redValue
        greenValue
    }    
`;

export const fetchKpiStatistics = async (kpi_id: number, filter: Partial<KpiStoreFilter>): Promise<KpiStatistics[]> => {
    const graphql = `{
            fetchKpiStatistics(kpi_id: ${kpi_id}, filter: ${objectToGraphql(filter)}) {
                ${KpiGQStatMainFields}
                
                major_user_id
                major_user {
                    ${GQUserMainFields}
                    avatarUrl
                }
                usersAccessCount
                
                group_id
                group {
                    ${GQGroupsFields}
                }
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchKpiStatistics: KpiStatistics[] }>(graphql);
    return data.fetchKpiStatistics;
};
