import React, { Component, Fragment } from 'react';
import { Card, Header, List } from 'semantic-ui-react';
import { UserLinkType } from '~/types/users.types';
import ShadowFragment from './ShadowFragment';
import UsersLinkList from '../Lists/Users/UsersLinkList';
import { adjustPhoneString } from '../Items/Common/ItemContacts/ItemContactsEditing';
import { ContactLinkWithPhonesType } from '~/types/contacts.types';
import ContactLinkWithPreview from '../Lists/Contacts/ContactLinkWithPreview';

export const ContactWithPhonesList = ({ contact }: { contact?: ContactLinkWithPhonesType | null }) => (
    <List>
        <List.Item
            title="Открыть карточку контакта"
            icon="address card outline"
            content={
                <div style={{ minWidth: '200px' }}>
                    <ShadowFragment shadow={!contact} print={contact && <ContactLinkWithPreview contact={contact} />} />
                </div>
            }
        />
        {contact &&
            contact.phones &&
            contact.phones.map(({ phone, phone_id }) => (
                <List.Item
                    title="Позвонить на номер контакта"
                    icon="phone"
                    key={phone_id}
                    content={<a href={`tel:+${phone}`}>{adjustPhoneString(phone)}</a>}
                />
            ))}
    </List>
);

const ClientShowMode = ({
    contact,
    major_users,
    title
}: {
    contact?: ContactLinkWithPhonesType | null;
    major_users?: Array<UserLinkType>;
    title: string;
}) => (
    <Card fluid color="yellow">
        <Card.Content>
            <Header size="tiny">{title}</Header>
            <ContactWithPhonesList contact={contact} />
        </Card.Content>
        <Card.Content extra>
            <UsersLinkList usersList={major_users} avatar />
        </Card.Content>
    </Card>
);

export default ClientShowMode;
