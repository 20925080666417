import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import UserDropdown from '../../ItemsDropdowns/UserDropdown';
import authStore from '~/stores/authStore';
import { ACCESS_GROUP, ACCESS_WRITE } from '~/types/access.types';
import { ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';

type MajorUserBlockEditingProps = {
    item_id: number;
    store: ItemStoreInterface<any>;
    major_user_id: number | Array<number>;
    upward?: boolean;
    enableAllGroups?: boolean;
    fluid?: boolean;
};

@observer
class MajorUserBlockEditing extends Component<MajorUserBlockEditingProps, { groups_id: Array<number>; multiple: boolean }> {
    constructor(props: MajorUserBlockEditingProps) {
        super(props);

        const multiple = props.major_user_id instanceof Array;

        const item = {
            [multiple ? 'major_user_ids' : 'major_user_id']: props.major_user_id
        };

        const { item_id, store } = props;
        store.setEditingItem(item_id, item);

        this.state = {
            groups_id:
                !props.enableAllGroups && authStore.getPermission(store.moduleName, ACCESS_WRITE) === ACCESS_GROUP
                    ? [authStore.currentUser.group_id]
                    : [],
            multiple
        };
    }

    handleChangeUserId = (user_id: number | Array<number>) => {
        const { multiple } = this.state;
        const { store, item_id } = this.props;

        store.setEditingItem(item_id, {
            [multiple ? 'major_user_ids' : 'major_user_id']: user_id
        });
    };

    render() {
        const { item_id, store, upward, fluid } = this.props;
        const { groups_id, multiple } = this.state;
        const { major_user_id, major_user_ids }: { major_user_id?: number; major_user_ids?: number[] } = store.getItem(item_id).editingItem;

        // if (authStore.getPermission(store.moduleName, ACCESS_WRITE) === ACCESS_SELF) return null;
        // if (authStore.getPermission(store.moduleName, ACCESS_WRITE) === ACCESS_NONE) return null;

        return (
            <UserDropdown
                groups_id={groups_id}
                upward={upward}
                user_id={multiple && major_user_ids ? Array.from(major_user_ids) : major_user_id}
                onChange={this.handleChangeUserId}
                fluid={fluid}
            />
        );
    }
}

export default MajorUserBlockEditing;
