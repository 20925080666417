import { action, runInAction } from 'mobx';
import ListStorePrototype from './ListStore.prototype';
import { ApiModuleTypeList } from './ListStore.prototype';
import isEqual from '../../common/isEqual';
import { Phone } from '~/types/users.types';
import { ITEM_TYPES } from '~/types/notifications.types';
import arrayMove from 'array-move';
import { catchItemError } from '../helpers/decorators.helpers';
import { TItemExtended } from './ItemStore.prototype';

type TItemContactsExtended = TItemExtended & {
    email: string | null;
    phones: Phone[];
    innerPhones?: string[];
};

export type ApiModuleTypeListContacts<ItemObjectType extends TItemContactsExtended, ItemListType, ItemListFilter> = ApiModuleTypeList<
    ItemObjectType,
    ItemListType,
    ItemListFilter
> & {
    saveContacts: (number, Object) => Promise<TItemContactsExtended>;
};

class ListWithContactsStorePrototype<
    ItemObjectType extends TItemContactsExtended,
    ItemListType,
    ItemPropertyType,
    ItemListFilter
> extends ListStorePrototype<ItemObjectType, ItemListType, ItemPropertyType, ItemListFilter> {
    ApiModuleContacts: ApiModuleTypeListContacts<ItemObjectType, ItemListType, ItemListFilter>;

    constructor(
        item_id: string,
        moduleName: ITEM_TYPES,
        ApiModule: ApiModuleTypeListContacts<ItemObjectType, ItemListType, ItemListFilter>
    ) {
        super(item_id, moduleName, ApiModule);

        this.ApiModuleContacts = ApiModule;
    }

    @catchItemError
    @action
    async saveContacts(id: number) {
        const item = this.getItem(id);
        const { editingItem } = item;
        const oldItem = item.item;
        const newContacts = {};

        if (typeof editingItem === 'object' && editingItem !== null) {
            Object.keys(editingItem).forEach(key => {
                if (editingItem[key] instanceof Array) {
                    if (!isEqual(Array.from(oldItem[key]), Array.from(editingItem[key]))) {
                        newContacts[key] = Array.from(editingItem[key]);
                    }
                } else if (oldItem[key] !== editingItem[key]) {
                    newContacts[key] = editingItem[key];
                }
            });
        }

        if (Object.keys(newContacts).length) {
            const contactsFromServer = await this.ApiModuleContacts.saveContacts(id, newContacts);

            // @ts-ignore
            const adjustedNewContacts: Partial<ItemObjectType> = {
                phones: contactsFromServer.phones || oldItem.phones,
                email: typeof contactsFromServer.email === 'string' ? contactsFromServer.email : oldItem.email,
                innerPhones: contactsFromServer.innerPhones || oldItem.innerPhones
            };

            this.mergeItem(id, adjustedNewContacts);
            this.mergeList(id, adjustedNewContacts);
        }
    }

    movePhones(item_id: number, oldIndex: number, newIndex: number) {
        let phones: Phone[];
        phones = this.getItem(item_id).editingItem.phones;
        phones = arrayMove(phones, oldIndex, newIndex);
        [phones[oldIndex]['phone_id'], phones[newIndex]['phone_id']] = [phones[newIndex].phone_id, phones[oldIndex].phone_id];
        // @ts-ignore
        this.setEditingItem(item_id, { phones });
    }
}

export default ListWithContactsStorePrototype;
