import React, { Component, Fragment, MouseEvent, ReactNode } from 'react';
import { Icon } from 'semantic-ui-react';

import authStore from '~/stores/authStore';
import DocumentsDropModal from '../Documents/DocumentsDropModal';
import EventModal from '../CalendarEvents/EventModal';
import { ESTATE_BASES } from '~/types/estate.types';
import { CREATING_ITEM_ID, ItemStoreInterface } from '~/stores/prototypes/ItemStore.prototype';
import callEventStore from '~/stores/cell/callEventStore';
import { AdditionalDocType } from '~/types/documents.types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { EVENT_TYPE_CALL, EVENT_TYPE_MEETING } from '~/types/events.types';
import DisableItemConfirm from '~/components/Items/Common/DisableItemConfirm';

export type TabMenuDropdownProps = {
    item_id: number;

    enable: boolean;
    major_user_id: number | Array<number>;
    owner_id?: number | null;
    contact_id?: number | null;

    toggleDocModal: boolean;
    additionalDocuments?: AdditionalDocType[];

    store: ItemStoreInterface<any>;
    config: Partial<TabMenuConfigType>;
    onClose: () => void;

    dropdownAnchorEl: null | HTMLElement;
    children: ReactNode;
};

export type TabMenuDropdownState = {
    majorUserPopupOpen: boolean;
    showDocModal: boolean;
    showCallEventModal: boolean;
    showMeetingEventModal: boolean;
};

export type TabMenuConfigType = {
    attachDocuments?: boolean;
    showDocumentsForAll?: boolean;
    base?: ESTATE_BASES;
    callEventModal?: boolean;
    meetingEventModal?: boolean;
    wrappedOnBottom?: boolean;
    disableDropdownMenu?: boolean;
    disableDeleteButton?: boolean;
};

class TabMenuDropdown extends Component<TabMenuDropdownProps, TabMenuDropdownState> {
    constructor(props: TabMenuDropdownProps) {
        super(props);

        this.state = {
            majorUserPopupOpen: false,
            showDocModal: false,
            showCallEventModal: false,
            showMeetingEventModal: false
        };
    }

    handleToggleCallEventModal = () => {
        this.setState({ showCallEventModal: !this.state.showCallEventModal });
    };

    handleShowDocModal = () => {
        this.setState({ showDocModal: !this.state.showDocModal });
    };

    handleToggleShowEventModal = () => {
        this.setState({ showMeetingEventModal: !this.state.showMeetingEventModal });
    };

    componentDidUpdate(prevProps: TabMenuDropdownProps) {
        if (prevProps.toggleDocModal !== this.props.toggleDocModal && !this.state.showDocModal) {
            this.setState({ showDocModal: true });
        }
    }

    render() {
        const {
            item_id,
            enable,
            major_user_id,
            owner_id,
            store,
            config,
            children,
            onClose,
            dropdownAnchorEl,
            contact_id,
            additionalDocuments
        } = this.props;
        const { showCallEventModal, showMeetingEventModal, showDocModal } = this.state;

        const canDelete = authStore.canDelete(store.moduleName, major_user_id);
        const canEdit = authStore.canEdit(store.moduleName, major_user_id);

        return (
            <Fragment>
                <Menu anchorEl={dropdownAnchorEl} open={Boolean(dropdownAnchorEl)} keepMounted onClose={onClose} onClick={onClose}>
                    {(canEdit || owner_id || config.showDocumentsForAll) && (
                        <div>
                            {(canEdit || owner_id) && !config.wrappedOnBottom && children}

                            {config.callEventModal && authStore.canCreate(callEventStore.moduleName) && (
                                <MenuItem onClick={this.handleToggleCallEventModal}>
                                    <Icon name="call" />
                                    Запланировать звонок
                                </MenuItem>
                            )}

                            {config.meetingEventModal && authStore.canCreate(callEventStore.moduleName) && (
                                <MenuItem onClick={this.handleToggleShowEventModal}>
                                    <Icon name="handshake outline" />
                                    Добавить встречу
                                </MenuItem>
                            )}

                            {config.attachDocuments && (canEdit || config.showDocumentsForAll) && (
                                <MenuItem onClick={this.handleShowDocModal}>
                                    <Icon name="file" />
                                    Прикрепить документ
                                </MenuItem>
                            )}
                        </div>
                    )}

                    {(canEdit || owner_id) && config.wrappedOnBottom && children}

                    {canDelete && !config.disableDeleteButton && (
                        <div>
                            {canEdit && <Divider style={{ margin: '5px 0' }} />}
                            <DisableItemConfirm enable={enable} item_id={item_id} store={store} />
                        </div>
                    )}
                </Menu>

                {showCallEventModal && (
                    <EventModal
                        onClose={this.handleToggleCallEventModal}
                        item_id={CREATING_ITEM_ID}
                        contact_id={owner_id ? null : contact_id}
                        owner_id={owner_id || null}
                        eventType={EVENT_TYPE_CALL}
                    />
                )}

                {showMeetingEventModal && (
                    <EventModal
                        onClose={this.handleToggleShowEventModal}
                        item_id={CREATING_ITEM_ID}
                        contact_id={owner_id ? null : contact_id}
                        owner_id={owner_id || null}
                        eventType={EVENT_TYPE_MEETING}
                    />
                )}

                {showDocModal && (
                    <DocumentsDropModal
                        onClose={this.handleShowDocModal}
                        item_id={item_id}
                        item_type={store.moduleName}
                        additionalTypes={additionalDocuments}
                    />
                )}
            </Fragment>
        );
    }
}

export default TabMenuDropdown;
