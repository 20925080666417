import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import CountryHouseEditing, { COUNTRY_INFRASTURCTURE_FIELDS } from '../EditBlocks/CountryHouseEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { ESTATE_PROPERTY_TYPE_HOUSE_PART, ESTATE_PROPERTY_TYPE_TOWNHOUSE } from '~/types/estate.types';

@observer
class CountryHouseShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const {
            shareAmount,
            propertyType,
            buildYear,
            materialType,
            floorsCount,
            bedroomsCount,
            wcLocationType,
            heatingType,
            totalArea,
            repairType
        } = item;

        return (
            <Card fluid color="blue">
                {!editing && (
                    <Card.Content>
                        <Header size="tiny">Дом</Header>
                        <Grid columns={2}>
                            <Grid.Row>
                                {[ESTATE_PROPERTY_TYPE_TOWNHOUSE, ESTATE_PROPERTY_TYPE_HOUSE_PART].includes(propertyType) && (
                                    <Fragment>
                                        <Grid.Column>Часть дома</Grid.Column>
                                        <Grid.Column>
                                            <ShadowFragment shadow={loadingItem} print={shareAmount} />
                                        </Grid.Column>
                                    </Fragment>
                                )}

                                <Grid.Column>Год постройки</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={buildYear} />
                                </Grid.Column>

                                <Grid.Column>Тип дома</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={estateL10n.COUNTRY_MATERIAL_TYPE_FIND(materialType)} />
                                </Grid.Column>

                                <Grid.Column>Общая площадь</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={`${totalArea || '-'} м²`} />
                                </Grid.Column>

                                <Grid.Column>Этажей в доме</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={floorsCount} />
                                </Grid.Column>

                                <Grid.Column>Количество спален</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={bedroomsCount} />
                                </Grid.Column>

                                <Grid.Column>Санузел</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={estateL10n.WC_COUNTRY_TYPE_FIND(wcLocationType)} />
                                </Grid.Column>

                                <Grid.Column>Отопление</Grid.Column>
                                <Grid.Column>
                                    <ShadowFragment shadow={loadingItem} print={estateL10n.COUNTRY_HEATING_TYPE_FIND(heatingType)} />
                                </Grid.Column>

                                <Grid.Column>Ремонт</Grid.Column>
                                <Grid.Column>{estateL10n.REPAIR_TYPES_FIND(repairType)}</Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Header size="tiny">Инфраструктура в доме</Header>
                        <Grid columns={3}>
                            <Grid.Row>
                                {COUNTRY_INFRASTURCTURE_FIELDS.map(
                                    field => item[field[0]] && <Grid.Column key={field[0]}>{field[1]}</Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                )}
                {editing && (
                    <Card.Content>
                        <CountryHouseEditing item_id={item_id} editing={editing} {...item} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, CountryHouseShow);
