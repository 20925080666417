import React, { Component, Fragment } from 'react';
import { List, Popup, Icon } from 'semantic-ui-react';
import { MOSCOWMAP_House } from '~/types/houses.types';

export default ({ house_id, address, year, series, floors, flats, garbage, floor_height, porchs, elevators }: MOSCOWMAP_House) => (
    <span className="crm-Estate__moscowmapBtn">
        <Popup
            trigger={
                <a target="_blank" href={`https://www.moscowmap.ru/adress_all.asp?dom=${house_id}`} rel="noreferrer">
                    <Icon name="check" color="green" />
                    &nbsp; Moscowmap &nbsp;
                    <Icon name="external" />
                </a>
            }
            hideOnScroll
            size="tiny"
            wide="very"
        >
            <List>
                <List.Item>
                    <List.Header>Адрес</List.Header>
                    {address || ''}
                </List.Item>
                <List.Item>
                    <List.Header>Год постройки</List.Header>
                    {year || '-'}
                </List.Item>
                <List.Item>
                    <List.Header>Серия</List.Header>
                    {series || '-'}
                </List.Item>
                <List.Item>
                    <List.Header>Этажей</List.Header>
                    {floors || '-'}
                </List.Item>
                <List.Item>
                    <List.Header>Квартир в доме</List.Header>
                    {flats || '-'}
                </List.Item>

                <List.Item>
                    <List.Header>Мусоропровод</List.Header>
                    {garbage ? 'есть' : '-'}
                </List.Item>

                <List.Item>
                    <List.Header>Высота потолков</List.Header>
                    {floor_height ? `${floor_height} см.` : '-'}
                </List.Item>

                <List.Item>
                    <List.Header>Количество подъездов</List.Header>
                    {porchs || '-'}
                </List.Item>

                <List.Item>
                    <List.Header>Лифт(ы)</List.Header>
                    {elevators || '-'}
                </List.Item>
            </List>
        </Popup>
    </span>
);
