import React, { useState, useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import AccessModal from './AccessModal';
import accessStore from '~/stores/accessStore';
import authStore from '~/stores/authStore';

import ContainerHeader from '~ui/ContainerHeader';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LoaderAwait from '~/components/Base/LoaderAwait';
import { SettingsListPaper } from '~/components/Settings';

const Access = () => {
    const [showAccessId, setShowAccessId] = useState(-1);

    useEffect(() => {
        (async () => {
            await accessStore.fetchAccessModes();
            await accessStore.fetchList();
        })();
    }, []);

    const { list, loadingList } = accessStore;

    return (
        <Fragment>
            <ContainerHeader
                handleCreate={() => setShowAccessId(CREATING_ITEM_ID)}
                title="Настройки доступа"
                enableCreating={authStore.canCreate(accessStore.moduleName)}
                icon={<VpnKeyIcon fontSize="large" />}
            />

            <SettingsListPaper>
                <List dense>
                    {list.map(({ access_id, title }, index) => (
                        <ListItem button key={access_id} onClick={() => setShowAccessId(access_id)}>
                            <ListItemText primary={title} />
                        </ListItem>
                    ))}
                </List>

                <LoaderAwait active={loadingList} linear />
            </SettingsListPaper>

            {showAccessId > -1 && (
                <AccessModal
                    item_id={showAccessId}
                    handleClose={() => {
                        setShowAccessId(-1);
                    }}
                />
            )}
        </Fragment>
    );
};

export default observer(Access);
