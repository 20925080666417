import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import exportingBaseStore from '~/stores/export/exportingBaseStore';

import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Select from '~ui/Select';
import Button from '~ui/Button';

import estateL10n from '~/L10n/estate.L10n';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import deepCopy from '~/common/deepCopy';

const keyNames = {
    agencyName: 'Название агентства',
    defaultPhone: 'Телефон по умолчанию',
    agencyUrl: 'Сайт агентства',
    estateUrl: 'Ссылка на объявление (от estate_id)',
    defaultName: 'Название агентства',
    otherXmlFeed: 'Склеить с другим фидом'
} as const;

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const ExportFeedSettingsEditing = ({ export_base_id, onClose }: { export_base_id: number; onClose?: () => void }) => {
    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const { value: name } = event.target;
        exportingBaseStore.setEditingItem(export_base_id, { name });
    };

    const handleChangeConfigProp = (propName: string, value: string) => {
        let { config } = exportingBaseStore.getItem(export_base_id).editingItem;
        const configObj = JSON.parse(config);
        configObj[propName] = value;
        exportingBaseStore.setEditingItem(export_base_id, { config: JSON.stringify(configObj) });
    };

    const handleChangeFormat = (event, { value: format }: { value: 'cian' | 'yandex' | 'avito' | 'irr' | 'nebo' }) => {
        exportingBaseStore.setEditingItem(export_base_id, { format });
    };

    const handleSave = async () => {
        if (await exportingBaseStore.saveItem(export_base_id)) {
            onClose && onClose();
        }
    };

    const { item, loadingItem, editingItem } = exportingBaseStore.getItem(export_base_id);
    if (export_base_id > CREATING_ITEM_ID && (!item || loadingItem || !editingItem?.name)) {
        return null;
    }

    const { format, name, config } = editingItem;

    const configJson = JSON.parse(config);
    const disableMainFieldsEditing = export_base_id > CREATING_ITEM_ID && export_base_id < 100;

    return (
        <Grid columns={2} stackable spacing={1}>
            <Grid.Column>
                <TextInput
                    margin="none"
                    label="Наименование"
                    required
                    value={name}
                    onChange={handleChangeName}
                    error={!name}
                    fullWidth
                    disabled={disableMainFieldsEditing}
                />
            </Grid.Column>
            <Grid.Column>
                <Select
                    value={format}
                    label="Формат"
                    onChange={handleChangeFormat}
                    fullWidth
                    options={estateL10n.EXPORT_FORMAT_NAME_DROPDOWN}
                />
            </Grid.Column>

            {Object.keys(configJson).map(key => (
                <Grid.Column key={key}>
                    <TextInput
                        margin="none"
                        label={keyNames[key] || key}
                        value={configJson[key] || ''}
                        onChange={(event, { value }) => handleChangeConfigProp(key, value)}
                        fullWidth
                        mask={key === 'defaultPhone' ? '+7 (000) 000-00-00' : undefined}
                        pattern={key === 'defaultPhone' ? phoneRegExp : undefined}
                    />
                </Grid.Column>
            ))}

            {onClose && (
                <Grid.Column className="crm-Flex__center">
                    <Button variant="outlined" color="secondary" onClick={handleSave} loading={loadingItem}>
                        Сохранить
                    </Button>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default observer(ExportFeedSettingsEditing);
