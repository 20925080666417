import React, { Fragment, PureComponent, SyntheticEvent, ReactNode } from 'react';
import { List, Popup, Icon, Button } from 'semantic-ui-react';
import { Phone } from '~/types/users.types';
import commonStore from '~/stores/commonStore';
import { makeCall } from '~/api/cell/cellCallsApi';
import OutsideClick from './OutsideClick';
import serverConfig from '~/config/server.config';
import Message from '~ui/Message';

export const adjustPhoneNumber = (phone: string): string => {
    if (phone.length === 11 && phone[0] === '7') {
        return `+${phone[0]} ${phone.slice(1, 4)} ${phone.slice(4, 7)}-${phone.slice(7, 9)}-${phone.slice(9, 11)}`;
    }
    return `+${phone}`;
};

const DEFAULT_SUCCESS_MESSAGE = 'Ответьте на входящий звонок, после чего вас переадресует на вызываемый номер';
const DEFAULT_CALLING = 'Идет связь с сервером';
const OPEN_MESSAGE_POPUP_MS = 10000;

type PhonesWithCallListProps = {
    phone: string;
    showExport?: boolean;
    withoutItemWrapper?: boolean;
};

type PhonesWithCallListState = {
    calling: boolean;
    message: string | null;
    isError: boolean;
};

const PhoneListItem = React.memo(
    ({
        calling,
        children,
        showExport,
        onClick
    }: {
        calling: boolean;
        children: ReactNode;
        showExport: boolean;
        onClick: (event: SyntheticEvent) => void;
    }) => {
        return (
            <List.Item
                icon={calling ? 'phone volume' : 'phone'}
                color="red"
                content={children}
                style={showExport ? { color: '#21BA45' } : null}
                title={showExport ? 'Этот номер телефона идет на экспорт в базы недвижимости' : ''}
                onClick={onClick}
            />
        );
    }
);

export class PhonesWithCallList extends PureComponent<PhonesWithCallListProps, PhonesWithCallListState> {
    state = {
        calling: false,
        message: null,
        isError: false
    };

    closePopupTimeout: number;

    handleCall = async (event: React.SyntheticEvent) => {
        if (!commonStore.isMobile && serverConfig.crmOutgoingCalls) {
            event.preventDefault();
            this.setState({ calling: true, message: DEFAULT_CALLING });

            let message: string;
            let isError = false;

            try {
                await makeCall(this.props.phone);
                message = DEFAULT_SUCCESS_MESSAGE;
            } catch (errors) {
                message = errors.join('; ');
                isError = true;
            }
            this.setState({
                message,
                calling: false,
                isError
            });

            this.closePopupTimeout = window.setTimeout(() => {
                this.setState({ message: null, isError: false });
            }, OPEN_MESSAGE_POPUP_MS);
        }
    };

    handleClosePopup = () => {
        this.setState({ message: null, isError: false });
    };

    componentWillUnmount() {
        clearTimeout(this.closePopupTimeout);
    }

    handleHrefClick = (event: SyntheticEvent) => {
        if (!commonStore.isMobile && serverConfig.crmOutgoingCalls) {
            event.preventDefault();
        }
    };

    render() {
        const { phone, showExport, withoutItemWrapper } = this.props;
        const { calling, message, isError } = this.state;

        const PopupTrigger = <a href={`tel:+${phone}`}>{adjustPhoneNumber(phone)}</a>;

        if (!serverConfig.crmOutgoingCalls || commonStore.isMobile) {
            if (withoutItemWrapper) {
                return PopupTrigger;
            }
            return (
                <PhoneListItem calling={calling} showExport={showExport} onClick={this.handleHrefClick}>
                    {PopupTrigger}
                </PhoneListItem>
            );
        }

        const CallingPopup = (
            <Popup
                size="small"
                trigger={PopupTrigger}
                popperDependencies={[message, calling]}
                hoverable
                wide
                mouseEnterDelay={350}
                mouseLeaveDelay={500}
            >
                <OutsideClick onOutsideClick={this.handleClosePopup}>
                    {!message && (
                        <Button icon="phone" size="mini" labelPosition="left" color="green" content="Позвонить" onClick={this.handleCall} />
                    )}
                    {message && (
                        <Message
                            icon={calling ? <Icon name="circle notched" loading /> : undefined}
                            severity={isError ? 'error' : !calling && !isError ? 'success' : 'info'}
                        >
                            {message}
                        </Message>
                    )}
                </OutsideClick>
            </Popup>
        );

        if (withoutItemWrapper) {
            return CallingPopup;
        }

        return (
            <PhoneListItem calling={calling} showExport={showExport} onClick={this.handleHrefClick}>
                {CallingPopup}
            </PhoneListItem>
        );
    }
}

type PhonesListProps = {
    phones: Phone[];
    showExport?: boolean;
};

const PhonesList = ({ phones, showExport }: PhonesListProps) => (
    <Fragment>
        {phones && phones.length
            ? phones.map(({ phone }, index) => (
                  <PhonesWithCallList key={`phone_${phone}_${index}`} phone={phone} showExport={showExport && index === 0} />
              ))
            : null}
    </Fragment>
);

export default PhonesList;
