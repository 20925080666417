import React, { useEffect, useState } from 'react';
import history from '~/history';
import { DeveloperFeed } from '~/types/newbuildings.types';
import * as developerFeedApi from '~/api/developerFeedApi';
import { DeveloperFeedInfo } from '~/api/developerFeedApi';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Progress from '~ui/Progress';
import { Modal, ModalContent, ModalBtnAction, useToggle } from '~ui/Modal';
import Button from '~ui/Button';

import ListError from '~/components/ListError';
import { EstateLinkState } from '~/components/Lists/Estate/EstateLink';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';

const LoadingFeedInfo = ({ feedInfo, makeSyncEstates }: { feedInfo: DeveloperFeedInfo; makeSyncEstates: boolean }) => {
    return (
        <Box pb={1}>
            <Typography>
                Объектов в фиде: <b>{feedInfo.estatesCount}</b>
            </Typography>
            <Typography>
                Количество ошибок: <b>{feedInfo.errors.length}</b>
            </Typography>
            {makeSyncEstates && (
                <Typography>
                    Загружено в CRM: <b>{feedInfo.estateIds.length}</b>
                </Typography>
            )}
            {feedInfo.estateIds.slice(0, 500).map(estate_id => (
                <Button
                    tooltip="Открыть в новом окне"
                    href={EstateLinkState(estate_id, 'all', 'external').pathname}
                    target="_blank"
                    key={estate_id}
                >
                    {estate_id}
                </Button>
            ))}
        </Box>
    );
};

const DeveloperFeedTestModal = ({
    feedUrl,
    format,
    developer_id,
    onClose
}: {
    feedUrl: string;
    format: DeveloperFeed['format'];
    developer_id: number;
    onClose: () => void;
}) => {
    const [feedInfo, setFeedInfo] = useState<DeveloperFeedInfo | null>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [makeSyncEstates, toggleMakeSync] = useToggle();

    useEffect(() => {
        setFeedInfo(null);
        developerFeedApi.parseDeveloperFeed(feedUrl, format, developer_id, makeSyncEstates).then(setFeedInfo).catch(setErrors);
    }, [feedUrl, format, developer_id, makeSyncEstates]);

    const modalActions: ModalBtnAction[] = [{ onClick: onClose, label: 'Закрыть', variant: 'text' }];

    const isLoading = !feedInfo && !errors.length;

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={modalActions}
            fullScreen={commonStore.isMobile}
            loading={isLoading}
            header={makeSyncEstates ? 'Загрузка фида' : 'Тест фида'}
        >
            <ModalContent>
                <ListError errors={[...errors, ...(feedInfo?.errors || [])]} />
                {feedInfo && <LoadingFeedInfo feedInfo={feedInfo} makeSyncEstates={makeSyncEstates} />}
                {developer_id !== CREATING_ITEM_ID && feedInfo && !makeSyncEstates && (
                    <Button variant="outlined" primary onClick={toggleMakeSync}>
                        Загрузить фид (≈{Math.round(feedInfo.estatesCount / 12)} минут)
                    </Button>
                )}
                <Progress show={isLoading} />
            </ModalContent>
        </Modal>
    );
};

export default DeveloperFeedTestModal;
