import React, { Component, Fragment } from 'react';
import { Search, Button, Icon, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import debounce from '../../../../../common/debounce';
import NewbuildingBasePopup from './NewbuildingBasePopup';

import estateStore from '~/stores/estateStore';
import { NEWBUILDING_BASE_YANDEX } from '~/types/newbuildings.types';

type YandexHouseBlockEditingProps = {
    estate_id: number;
};

type YandexHouseBlockEditingState = {
    yandexSearch: string;
    yandex_house_id: number;
    isOpen: boolean;
};

const resultRenderer = ({
    address,
    title,
    buildyear,
    readyquarter
}: {
    address: string;
    title: string;
    buildyear: number;
    readyquarter: number;
}) => (
    <div>
        <b>{title}</b>
        <br />
        {address} {buildyear && readyquarter && `// ${buildyear} г., ${['', 'I', 'II', 'III', 'IV'][readyquarter]} квартал`}
    </div>
);

@observer
class YandexHouseBlockEditing extends Component<YandexHouseBlockEditingProps, YandexHouseBlockEditingState> {
    state = {
        yandexSearch: '',
        isOpen: false,
        yandex_house_id: 0
    };

    handleOnFocus = () => this.setState({ isOpen: true });

    handleOnBlur = () => {
        // Даем возможность отработать this.handleSearchChange
        setTimeout(() => {
            this.setState({ isOpen: false });
        }, 100);
    };

    handleSearchChange = (event: React.SyntheticEvent, { value }: { value: string }) => {
        this.setState({ yandexSearch: value, isOpen: true });
        this.debounceYandexSearch();
    };

    debounceYandexSearch = debounce(() => {
        (async () => {
            await estateStore.findBaseHouses(this.props.estate_id, this.state.yandexSearch, NEWBUILDING_BASE_YANDEX);
        })();
    }, 350);

    handleChange = (evt: React.SyntheticEvent, { result: { value } }: { result: { value: number } }) => {
        const { estate_id } = this.props;

        const { suggestedYandexHouses } = estateStore.getItem(estate_id).property;

        const house = suggestedYandexHouses.find(({ yandex_house_id }) => yandex_house_id === value);
        if (!house) {
            return;
        }

        this.setState({ yandex_house_id: value, isOpen: false, yandexSearch: house.name });

        estateStore.setProperty(estate_id, { yandexHouse: house });
    };

    handleSave = () => {
        estateStore.updateBaseHouse(this.props.estate_id, this.state.yandex_house_id, NEWBUILDING_BASE_YANDEX);
    };

    render() {
        const { estate_id } = this.props;
        const { yandexSearch, isOpen } = this.state;

        const { yandexHouse, suggestedYandexHouses, loadingYandexHouse } = estateStore.getItem(estate_id).property;

        const suggestedHousesList =
            suggestedYandexHouses && suggestedYandexHouses.length
                ? suggestedYandexHouses.map(({ yandex_house_id, address, buildYear, readyQuarter, name }) => ({
                      key: yandex_house_id,
                      value: yandex_house_id,
                      address,
                      buildyear: buildYear,
                      readyquarter: readyQuarter,
                      title: name
                  }))
                : [];

        return (
            <Message color="orange">
                <Message.Header>
                    Нет сопоставления с базой ЖК Яндекс.Недвижимость&nbsp;
                    <NewbuildingBasePopup />
                </Message.Header>
                <p>Для того, чтобы был возможен экспорт в Яндекс.Недвижимость этот дом надо найти в базе ЖК от Яндекс.</p>

                <Search
                    icon="search"
                    className="crm-Estate__address_find crm-Estate__address_yandexSearch"
                    showNoResults={false}
                    size="tiny"
                    loading={loadingYandexHouse}
                    onResultSelect={this.handleChange}
                    onSearchChange={this.handleSearchChange}
                    results={suggestedHousesList}
                    value={yandexSearch}
                    resultRenderer={resultRenderer}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    open={isOpen}
                />

                {yandexHouse && (
                    <div>
                        <b>
                            <Icon name="building" />
                            {yandexHouse.name}
                        </b>
                        <br />
                        <a target="_blank" href={yandexHouse.url} rel="noreferrer">
                            {yandexHouse.address}
                        </a>
                        <br />
                        {yandexHouse.buildYear}
                        г., {['', 'I', 'II', 'III', 'IV'][yandexHouse.readyQuarter]} Квартал, &nbsp;
                        {yandexHouse.buildingPhase}
                        &nbsp;&nbsp;
                        <Button primary size="mini" onClick={this.handleSave}>
                            Сохранить
                        </Button>
                    </div>
                )}
            </Message>
        );
    }
}

export default YandexHouseBlockEditing;
