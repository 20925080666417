import fetchApi from '../../common/fetchApi';
import { objectToGraphql } from '../../common/graphql';
import { GQUserMainFields } from '../userApi';
import { CallEvent, CallEventFilter } from '~/types/events.types';
import { GQMainContactFields } from '../contactsApi';
import { GQEstateMainFields } from '../estateApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQCallsMainFields } from './cellCallsApi';

const GQCallEventMainFields = `
    call_event_id
    title
    
    item_id
    item_type
    contact {
        ${GQMainContactFields}
        phones {
            phone_id
            phone
        }
    }
    estate {
        ${GQEstateMainFields}
        ownerPhones {
            phone
        }
    }
    
    callTimeMin
    callTimeMax
    
    assign_users_ids
    assign_users {
        ${GQUserMainFields}    
    }
    
    notificationEnable
    notificationTime
    callHasReleased
    
    enable
`;

const GQCallEventFields = `
    ${GQCallEventMainFields}
    
    call_id
    call {
        ${GQCallsMainFields}
    }
    
    description
    
    createTime
    updateTime   
`;

export const fetchItem = async (id: number): Promise<CallEvent> => {
    const graphql = `{
        fetchCallEvent(call_event_id: ${id}) {
            ${GQCallEventFields}
        } 
      }`;

    const data: { fetchCallEvent: CallEvent } = await fetchApi.getGQ(graphql);
    return data.fetchCallEvent;
};

export const createItem = async (callEvent: CallEvent): Promise<number> => {
    const graphql = `mutation { 
          createCallEvent(callEventInput: ${objectToGraphql(callEvent)})
        }`;

    const data: { createCallEvent: number } = await fetchApi.postGQ(graphql);
    return data.createCallEvent;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    callEventFilter: CallEventFilter,
    controller?: AbortController
): Promise<{ list: Array<CallEvent>; count: number }> => {
    const graphql = `{
        fetchCallEvents(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
        sort: "${orderDirection}", filter: ${objectToGraphql(callEventFilter)}) {
            list {${GQCallEventFields}}
            count
        } 
      }`;

    const data: { fetchCallEvents: { list: Array<CallEvent>; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchCallEvents;
};

export const saveItem = async (call_event_id: number, callEvent: Partial<CallEvent>): Promise<number> => {
    const graphql = `mutation { 
        updateCallEvent(call_event_id: ${call_event_id}, callEventDiff: ${objectToGraphql(callEvent)})
    }`;

    const data: { updateCallEvent: number } = await fetchApi.postGQ(graphql);
    return data.updateCallEvent;
};

export const fetchCallsEventsByContact = async (contact_id: number): Promise<CallEvent[]> => {
    const graphql = `{
        fetchCallsEventsByContact(contact_id: ${contact_id}) {
            ${GQCallEventMainFields}
        } 
      }`;

    const data: { fetchCallsEventsByContact: CallEvent[] } = await fetchApi.getGQ(graphql);
    return data.fetchCallsEventsByContact;
};

export const changeCallReleased = async (call_event_id: number, released: boolean): Promise<number> => {
    const graphql = `mutation { 
        changeCallReleased(call_event_id: ${call_event_id}, released: ${String(released)})
    }`;

    const data: { changeCallReleased: number } = await fetchApi.postGQ(graphql);
    return data.changeCallReleased;
};
