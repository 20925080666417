import fetchApi from '~/common/fetchApi';
import { DeveloperFeed, DomclickDeveloper, LifeComplexDomclickInfo } from '~/types/newbuildings.types';
import { objectToGraphql } from '~/common/graphql';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

export const GQDeveloperFeedFields = `
    developer_feed_id
    developer_id
    format
    feedUrl    
`;

export const fetchItem = async (developer_feed_id: number): Promise<DeveloperFeed> => {
    const graphql = `{
        fetchDeveloperFeed(developer_feed_id: ${developer_feed_id}) {
            ${GQDeveloperFeedFields}
        } 
      }`;

    const data = await fetchApi.getGQ<{ fetchDeveloperFeed: DeveloperFeed }>(graphql);
    return data.fetchDeveloperFeed;
};

export const saveItem = async (developer_feed_id: number, developerFeed: Partial<DeveloperFeed>): Promise<number> => {
    const graphql = `mutation { 
        updateDeveloperFeed(developer_feed_id: ${developer_feed_id}, developerFeedDiff: ${objectToGraphql(developerFeed)})
    }`;

    const data = await fetchApi.postGQ<{ updateDeveloperFeed: number }>(graphql);
    return data.updateDeveloperFeed;
};

export const createItem = async (developerFeed: Partial<DeveloperFeed>): Promise<number> => {
    const graphql = `mutation { 
        createDeveloperFeed(developerFeed: ${objectToGraphql(developerFeed)})
    }`;

    const data = await fetchApi.postGQ<{ createDeveloperFeed: number }>(graphql);
    return data.createDeveloperFeed;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    { developer_id }: { developer_id: number },
    controller?: AbortController
): Promise<{ list: DeveloperFeed[]; count: number }> => {
    const graphql = `{ 
        fetchDeveloperFeeds(developer_id: ${developer_id}) {
            ${GQDeveloperFeedFields}  
        }
    }`;

    const data = await fetchApi.getGQ<{ fetchDeveloperFeeds: DeveloperFeed[] }>(graphql, controller);
    return {
        list: data.fetchDeveloperFeeds,
        count: data.fetchDeveloperFeeds.length
    };
};

export type DomclickFeedInfo = {
    developer: DomclickDeveloper;
    lifeComplexes: LifeComplexDomclickInfo[];
};

export const checkDomclickFeedUrl = async (url: string): Promise<DomclickFeedInfo> => {
    const graphql = `{
        checkDomclickFeedUrl(url: ${objectToGraphql(url)}) {
            developer {
                id
                name
                phone
                site
                logo
            }
            lifeComplexes {
                domclick_lc_id
                address
                title
                geo_lat
                geo_lon

                newbuilding_id

                buildings {
                    domclick_building_id
                    title
                    newbuilding_house_id
                    flatsInFeedCount
                }
            }
        }
    }`;

    const data = await fetchApi.postGQ<{ checkDomclickFeedUrl: DomclickFeedInfo }>(graphql);
    return data.checkDomclickFeedUrl;
};

export type DeveloperFeedInfo = {
    errors: string[];
    estatesCount: number;
    estateIds: number[];
};

export const parseDeveloperFeed = async (
    feedUrl: string,
    format: string,
    developer_id: number,
    makeSyncEstates: boolean
): Promise<DeveloperFeedInfo> => {
    const graphql = `{ 
        parseDeveloperFeed(
            feedUrl: ${objectToGraphql(feedUrl)}, 
            format: "${format}", 
            developer_id: ${developer_id}, 
            makeSyncEstates: ${makeSyncEstates}
        ) {
            errors
            estatesCount
            estateIds
        }
    }`;

    const data = await fetchApi.postGQ<{ parseDeveloperFeed: DeveloperFeedInfo }>(graphql);
    return data.parseDeveloperFeed;
};
