import React from 'react';
import { observer } from 'mobx-react';
import config from '~/config/ui.config';

import Avatar from '@material-ui/core/Avatar';
import HistoryIcon from '@material-ui/icons/History';
import MoneyIcon from '@material-ui/icons/Money';

import exportingBaseStore from '~/stores/export/exportingBaseStore';

import TabExportTariffs from './TabExportTariffs';
import TabBaseFeeds from './TabBaseFeeds';
import ExportFeedTabMenu from './ExportFeedTabMenu';
import { MenuItem } from '~/components/Items/TabMenuWrapper';
import ItemWrapper, { ItemWrapperState, TAB_ID_DEFAULT, TAB_ID_HISTORY } from '~/components/Items/ItemWrapper';

import LoaderAwait from '~/components/Base/LoaderAwait';
import ExportingBaseHistoryMode from './ExportingBaseHistoryMode';

const TAB_ID_PRICES = 'prices';

const avatarStyle = { width: '32px', height: '32px' };

const ExportFeedModal = ({ item_id, tabName }: ItemWrapperState) => {
    const { editingItem, loadingItem } = exportingBaseStore.getItem(item_id);
    if (!editingItem || loadingItem) {
        return <LoaderAwait active />;
    }

    const { image, name, enable } = editingItem;

    const exportFeedTabs: MenuItem[] = [
        {
            name: name,
            id: '',
            icon: (
                <Avatar style={avatarStyle} src={config.publicUrl + image}>
                    {name}
                </Avatar>
            )
        },
        { name: 'Цены', id: TAB_ID_PRICES, icon: <MoneyIcon /> },
        { name: 'История', id: TAB_ID_HISTORY, icon: <HistoryIcon /> }
    ];

    return (
        <>
            <ExportFeedTabMenu
                item_id={item_id}
                tabName={tabName || TAB_ID_DEFAULT}
                itemTabs={exportFeedTabs}
                enable={enable}
                major_user_id={[]}
            />

            <div className="crm-List__sidebar_modalBox">
                {tabName === TAB_ID_DEFAULT && <TabBaseFeeds export_base_id={item_id} />}
                {tabName === TAB_ID_PRICES && <TabExportTariffs export_base_id={item_id} />}
                {tabName === TAB_ID_HISTORY && <ExportingBaseHistoryMode item_id={item_id} />}
            </div>
        </>
    );
};

export default ItemWrapper(exportingBaseStore, observer(ExportFeedModal), () => true);
