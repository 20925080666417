import React, { PureComponent, Fragment } from 'react';
import { Grid, Card } from 'semantic-ui-react';

import PersonalBlockShow from './ShowBlocks/PersonalBlockShow';
import TagsBlockShow from './ShowBlocks/TagsBlockShow';
import KanbanBlockShow from './ShowBlocks/KanbanBlockShow';
import ItemContacts from '../Common/ItemContacts';

import Comments from '../Common/Comments/index';
import contactStore from '~/stores/contactStore';
import InsertedItemsWrapper from './ShowBlocks/InsertedItemsWrapper';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import showingStore from '~/stores/showingStore';
import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import requestStore from '~/stores/requestStore';
import wishStore from '~/stores/wishStore';
import dealStore from '~/stores/dealStore';
import meetingEventStore from '~/stores/meetingEventStore';
import LoaderAwait from '../../Base/LoaderAwait';
import { WarningMessage } from '~ui/Message';

type ContactShowModeProps = {
    loading: boolean;
    restrictedAccess: boolean;
    contact_id: number;
    major_user_ids?: number[];
};

export default class ContactShowMode extends PureComponent<ContactShowModeProps> {
    render() {
        const { loading, contact_id, restrictedAccess, major_user_ids } = this.props;

        return (
            <Fragment>
                {restrictedAccess && <WarningMessage>У вас ограниченный доступ к данному контакту</WarningMessage>}
                <Grid stackable>
                    <Grid.Column width={7}>
                        <PersonalBlockShow item_id={contact_id} />
                        <ItemContacts store={contactStore} item_id={contact_id} />
                        <TagsBlockShow item_id={contact_id} />
                        <KanbanBlockShow item_id={contact_id} />

                        {!loading && <Comments item_id={contact_id} item_type="CONTACT" />}

                        <LoaderAwait active={loading} dimmer />
                    </Grid.Column>

                    {!loading && (
                        <Grid.Column width={9}>
                            <div className="crm-Contacts__itemList">
                                <Card fluid className="crm-Contacts__itemList_card">
                                    <InsertedItemsWrapper
                                        item_id={contact_id}
                                        module={estateStore}
                                        title="Недвижимость"
                                        creatingTitle="Добавить листинг"
                                        color="yellow"
                                        icon="building"
                                        createPath={restrictedAccess ? null : `/estate/sell/crm/${CREATING_ITEM_ID}`}
                                        emptySetTitle="Объекты не найдены"
                                    />
                                    {major_user_ids && authStore.canRead(meetingEventStore.moduleName, major_user_ids) && (
                                        <InsertedItemsWrapper
                                            item_id={contact_id}
                                            module={meetingEventStore}
                                            title="Встречи"
                                            creatingTitle="Создать встречу"
                                            color="violet"
                                            icon="handshake outline"
                                            createPath={`/modal/events/meetingEvent/${CREATING_ITEM_ID}`}
                                            emptySetTitle="Встречи не найдены"
                                        />
                                    )}
                                    <InsertedItemsWrapper
                                        item_id={contact_id}
                                        module={showingStore}
                                        title="Показы"
                                        creatingTitle="Создать показ"
                                        color="green"
                                        icon="compass"
                                        createPath={`/showings/list/overview/${CREATING_ITEM_ID}`}
                                        emptySetTitle="Показы не найдены"
                                    />
                                    <InsertedItemsWrapper
                                        item_id={contact_id}
                                        module={requestStore}
                                        title="Заявки"
                                        creatingTitle="Добавить заявку"
                                        color="red"
                                        icon="wordpress forms"
                                        createPath={restrictedAccess ? null : `/requests/list/overview/${CREATING_ITEM_ID}`}
                                        emptySetTitle="Заявки не найдены"
                                    />
                                    <InsertedItemsWrapper
                                        item_id={contact_id}
                                        module={wishStore}
                                        title="Покупатель"
                                        creatingTitle="Добавить покупателя"
                                        color="grey"
                                        icon="spy"
                                        createPath={restrictedAccess ? null : `/wishes/list/overview/${CREATING_ITEM_ID}`}
                                        emptySetTitle="Покупатель не найден"
                                    />
                                    <InsertedItemsWrapper
                                        item_id={contact_id}
                                        module={dealStore}
                                        title="Сделки"
                                        creatingTitle="Создать сделку"
                                        color="brown"
                                        icon="money"
                                        createPath={restrictedAccess ? null : `/deals/sell/overview/${CREATING_ITEM_ID}`}
                                        emptySetTitle="Сделки не найдены"
                                    />
                                </Card>
                            </div>
                        </Grid.Column>
                    )}
                </Grid>
            </Fragment>
        );
    }
}
