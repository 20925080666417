import React, { PureComponent } from 'react';
import TabMenuWrapper from '../TabMenuWrapper';
import { TabMenuWrapperProps } from '../TabMenuWrapper';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { ExportBaseLinkState } from '~/components/Ads/Export';

class ExportFeedTabMenu extends PureComponent<TabMenuWrapperProps> {
    render() {
        return null;
    }
}

export default TabMenuWrapper(exportingBaseStore, ExportBaseLinkState, ExportFeedTabMenu);
