import React, { Component, Fragment } from 'react';
import { Input, Form, Checkbox, Grid, Dropdown } from 'semantic-ui-react';
import EditField from '../../../Base/EditField';
import { observer } from 'mobx-react';
import DatePicker from '../../../Base/DatePicker';

import estateStore from '~/stores/estateStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import ButtonGroup from '../../../Base/ButtonGroup';

type CommerceLandEditingProps = HandlerEditingWrappedProps & {
    layout?: number | null;
    availableFrom?: number;
    taxNumber?: number | null;

    landArea?: number | null;
    landAreaUnitType?: number;
    landStatus?: number | null;

    description?: string;

    possibleToChangeLandStatus?: boolean | null;
    permittedUseType?: number | null;
    possibleToChangePermittedUseType?: boolean | null;
    hasInvestmentProject?: boolean | null;
    hasEncumbrances?: boolean | null;
    electricityLocationType?: number | null;
    electricityPossibleToConnect?: boolean | null;
    electricityPower?: number | null;
    gasLocationType?: number | null;
    gasPossibleToConnect?: boolean | null;
    gasCapacity?: number | null;
    gasPressureType?: number | null;
    drainageLocationType?: number | null;
    drainagePossibleToConnect?: boolean | null;
    drainageCapacity?: number | null;
    drainageType?: number | null;
    waterLocationType?: number | null;
    waterPossibleToConnect?: boolean | null;
    waterCapacity?: number | null;
    waterType?: number | null;
    drivewayType?: number | null;
};

@observer
class CommerceLandEditing extends Component<CommerceLandEditingProps> {
    constructor(props: CommerceLandEditingProps) {
        super(props);

        estateStore.setEditingItem(props.item_id, {
            estate_id: props.item_id,

            layout: props.layout || null,
            availableFrom: props.availableFrom || null,
            taxNumber: props.taxNumber || null,

            landArea: props.landArea || null,
            landAreaUnitType: props.landAreaUnitType || 2,
            landStatus: props.landStatus || null,

            description: props.description || '',

            possibleToChangeLandStatus: props.possibleToChangeLandStatus || null,
            permittedUseType: props.permittedUseType || null,
            possibleToChangePermittedUseType: props.possibleToChangePermittedUseType || null,
            hasInvestmentProject: props.hasInvestmentProject || null,
            hasEncumbrances: props.hasEncumbrances || null,
            electricityLocationType: props.electricityLocationType || null,
            electricityPossibleToConnect: props.electricityPossibleToConnect || null,
            electricityPower: props.electricityPower || null,
            gasLocationType: props.gasLocationType || null,
            gasPossibleToConnect: props.gasPossibleToConnect || null,
            gasCapacity: props.gasCapacity || null,
            gasPressureType: props.gasPressureType || null,
            drainageLocationType: props.drainageLocationType || null,
            drainagePossibleToConnect: props.drainagePossibleToConnect || null,
            drainageCapacity: props.drainageCapacity || null,
            drainageType: props.drainageType || null,
            waterLocationType: props.waterLocationType || null,
            waterPossibleToConnect: props.waterPossibleToConnect || null,
            waterCapacity: props.waterCapacity || null,
            waterType: props.waterType || null,
            drivewayType: props.drivewayType || null
        });
    }

    render() {
        const { item_id, handleChange, handleCheckbox, handleTextAreaChange, setUnixTime } = this.props;
        const editingItem = estateStore.getItem(item_id).editingItem;
        const {
            taxNumber,
            availableFrom,
            layout,
            landArea,
            landAreaUnitType,
            landStatus,
            description,
            possibleToChangeLandStatus,
            permittedUseType,
            possibleToChangePermittedUseType,
            hasInvestmentProject,
            hasEncumbrances,
            electricityLocationType,
            electricityPossibleToConnect,
            electricityPower,
            gasLocationType,
            gasPossibleToConnect,
            gasCapacity,
            gasPressureType,
            drainageLocationType,
            drainagePossibleToConnect,
            drainageCapacity,
            drainageType,
            waterLocationType,
            waterPossibleToConnect,
            waterCapacity,
            waterType,
            drivewayType
        } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.InputNumber
                    label="Номер налоговой"
                    onChange={handleChange}
                    min={1}
                    max={999999}
                    value={taxNumber}
                    name="taxNumber"
                />

                <Form.Group>
                    <Form.Field inline>
                        <span className="crm-Item__labelWide crm-Estate__availableLine_label">Дата освобождения</span>

                        <div className="crm-Tasks__deadLine_picker">
                            <DatePicker time={availableFrom || null} onChange={time => setUnixTime('availableFrom', time)} clearable />
                        </div>
                    </Form.Field>
                </Form.Group>

                <EditField.InputNumber
                    label="Участок"
                    onChange={handleChange}
                    min={1}
                    max={9999999}
                    value={landArea}
                    name="landArea"
                    step={0.1}
                    required
                    extraLabel={
                        <Dropdown
                            value={landAreaUnitType}
                            name="landAreaUnitType"
                            onChange={handleChange}
                            options={estateL10n.LAND_AREA_TYPES_DROPDOWN}
                            clearable
                            type="clearable"
                        />
                    }
                />

                <EditField.Drop
                    onChange={handleChange}
                    label="Категория земли"
                    name="landStatus"
                    value={landStatus}
                    options={estateL10n.COMMERCE_LAND_AREA_STATUS_TYPE_DROPDOWN}
                    clearable
                >
                    {landStatus && (
                        <Fragment>
                            &nbsp;
                            <Checkbox
                                onChange={handleCheckbox}
                                checked={possibleToChangeLandStatus}
                                name="possibleToChangeLandStatus"
                                label="Возможно изменить статус"
                            />
                        </Fragment>
                    )}
                </EditField.Drop>

                {/*<EditField.Drop*/}
                {/*onChange={handleChange}*/}
                {/*label="Планировка"*/}
                {/*name="layout"*/}
                {/*value={layout}*/}
                {/*options={estateL10n.LAYOUTS_DROPDOWN}*/}
                {/*clearable*/}
                {/*/>*/}

                <EditField.Drop
                    onChange={handleChange}
                    label="Вид разрешённого использования"
                    name="permittedUseType"
                    value={permittedUseType}
                    options={estateL10n.COMMERCE_PERMITTED_USE_TYPE_DROPDOWN}
                    clearable
                >
                    {permittedUseType && (
                        <Fragment>
                            &nbsp;
                            <Checkbox
                                onChange={handleCheckbox}
                                checked={possibleToChangePermittedUseType}
                                name="possibleToChangePermittedUseType"
                                label="Возможно изменить"
                            />
                        </Fragment>
                    )}
                </EditField.Drop>

                <Grid columns={2}>
                    <Grid.Column>
                        <Checkbox
                            onChange={handleCheckbox}
                            checked={hasInvestmentProject}
                            name="hasInvestmentProject"
                            label="Есть инвестпроект"
                            toggle
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            toggle
                            onChange={handleCheckbox}
                            checked={hasEncumbrances}
                            name="hasEncumbrances"
                            label="Есть обременение"
                        />
                    </Grid.Column>
                </Grid>
                <br />

                <EditField.Drop
                    onChange={handleChange}
                    label="Электроснабжение"
                    name="electricityLocationType"
                    value={electricityLocationType}
                    options={estateL10n.ELECTRICITY_LOCATION_TYPE_DROPDOWN}
                    clearable
                >
                    {electricityLocationType && (
                        <Fragment>
                            &nbsp;
                            {electricityLocationType === 3 && (
                                <Checkbox
                                    onChange={handleCheckbox}
                                    checked={electricityPossibleToConnect}
                                    name="electricityPossibleToConnect"
                                    label="Возможно подключить"
                                />
                            )}
                            {electricityLocationType !== 3 && (
                                <div className="crm-Estate__fieldNowrap">
                                    <Input
                                        type="number"
                                        placeholder="Мощность"
                                        size="mini"
                                        value={electricityPower || ''}
                                        name="electricityPower"
                                        onChange={handleChange}
                                        min={0}
                                        label="кВТ"
                                        labelPosition="right"
                                    />
                                </div>
                            )}
                        </Fragment>
                    )}
                </EditField.Drop>

                <EditField.Drop
                    onChange={handleChange}
                    label="Газоснабжение"
                    name="gasLocationType"
                    value={gasLocationType}
                    options={estateL10n.ELECTRICITY_LOCATION_TYPE_DROPDOWN}
                    clearable
                >
                    {gasLocationType && (
                        <Fragment>
                            &nbsp;
                            {gasLocationType === 3 && (
                                <Checkbox
                                    onChange={handleCheckbox}
                                    checked={gasPossibleToConnect}
                                    name="gasPossibleToConnect"
                                    label="Возможно подключить"
                                />
                            )}
                            {gasLocationType !== 3 && (
                                <Fragment>
                                    <div className="crm-Estate__fieldNowrap">
                                        <Input
                                            type="number"
                                            placeholder="Ёмкость"
                                            size="mini"
                                            value={gasCapacity || ''}
                                            name="gasCapacity"
                                            onChange={handleChange}
                                            min={0}
                                            label="м³/час"
                                            labelPosition="right"
                                        />
                                    </div>
                                    &nbsp;
                                    <Dropdown
                                        selection
                                        name="gasPressureType"
                                        value={gasPressureType}
                                        onChange={handleChange}
                                        placeholder="Давление"
                                        options={estateL10n.GAS_PRESSURE_TYPE_DROPDOWN}
                                        clearable
                                        type="clearable"
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </EditField.Drop>

                <EditField.Drop
                    onChange={handleChange}
                    label="Канализация"
                    name="drainageLocationType"
                    value={drainageLocationType}
                    options={estateL10n.ELECTRICITY_LOCATION_TYPE_DROPDOWN}
                    clearable
                >
                    {drainageLocationType && (
                        <Fragment>
                            &nbsp;
                            {drainageLocationType === 3 && (
                                <Checkbox
                                    onChange={handleCheckbox}
                                    checked={drainagePossibleToConnect}
                                    name="drainagePossibleToConnect"
                                    label="Возможно подключить"
                                />
                            )}
                            {drainageLocationType !== 3 && (
                                <Fragment>
                                    <div className="crm-Estate__fieldNowrap">
                                        <Input
                                            type="number"
                                            placeholder="Объем"
                                            size="mini"
                                            value={drainageCapacity || ''}
                                            name="drainageCapacity"
                                            onChange={handleChange}
                                            min={0}
                                            label="м³/сутки"
                                            labelPosition="right"
                                        />
                                    </div>
                                    &nbsp;
                                    <ButtonGroup
                                        buttonSet={estateL10n.DRAINAGE_TYPE}
                                        name="drainageType"
                                        value={drainageType || null}
                                        handleChange={handleChange}
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </EditField.Drop>

                <EditField.Drop
                    onChange={handleChange}
                    label="Водоснабжение"
                    name="waterLocationType"
                    value={waterLocationType}
                    options={estateL10n.ELECTRICITY_LOCATION_TYPE_DROPDOWN}
                    clearable
                >
                    {waterLocationType && (
                        <Fragment>
                            &nbsp;
                            {waterLocationType === 3 && (
                                <Checkbox
                                    onChange={handleCheckbox}
                                    checked={waterPossibleToConnect}
                                    name="waterPossibleToConnect"
                                    label="Возможно подключить"
                                />
                            )}
                            {waterLocationType !== 3 && (
                                <Fragment>
                                    <div className="crm-Estate__fieldNowrap">
                                        <Input
                                            type="number"
                                            placeholder="Объём"
                                            size="mini"
                                            value={waterCapacity || ''}
                                            name="waterCapacity"
                                            onChange={handleChange}
                                            min={0}
                                            label="м³/сутки"
                                            labelPosition="right"
                                        />
                                    </div>
                                    &nbsp;
                                    <Dropdown
                                        selection
                                        name="waterType"
                                        value={waterType}
                                        onChange={handleChange}
                                        placeholder="Тип водоснабжения"
                                        options={estateL10n.COMMERCE_LAND_WATER_TYPE_DROPDOWN}
                                        clearable
                                        type="clearable"
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </EditField.Drop>

                <EditField.ButtonGroup
                    label="Подъездные пути"
                    buttonSet={estateL10n.COMMERCE_LAND_DRIVEWAY_TYPE}
                    handleChange={handleChange}
                    value={drivewayType || null}
                    name="drivewayType"
                />

                <EditField.Area name="description" label="Описание" value={description} onChange={handleTextAreaChange} />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, CommerceLandEditing);
