import React from 'react';
import { observer } from 'mobx-react';

import Container from '@material-ui/core/Container';
import { Switch } from '~ui/Checkbox';
import Grid from '~ui/Grid';

import accessStore from '~/stores/accessStore';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import UserDropdown from '~/components/ItemsDropdowns/UserDropdown';
import { PriceInput } from '~ui/TextInput';
import exportStore from '~/stores/export/exportStore';
import { ACCESS_NONE } from '~/types/access.types';
import { InfoMessage } from '~ui/Message';

const AccessExportEditing = ({ item_id, handleCheckbox, handleChange }: HandlerEditingWrappedProps) => {
    const { editingItem } = accessStore.getItem(item_id);
    const {
        enableCommittingExport,
        enableCommittingPremium,
        enableApplyingPremium,
        enableCommittingExclusives,
        export_moderator_id,
        chiefIsModerator,
        maxEnableDebt,
        includedInStatistics,
        modules
    } = editingItem;

    const handleChangeUserId = (export_moderator_id: number) => {
        accessStore.setEditingItem(item_id, { export_moderator_id: export_moderator_id || null });
    };

    const accessExportMode = modules.find(({ name }) => name === exportStore.moduleName);
    const disableExport = accessExportMode?.access['write'] === ACCESS_NONE;

    const enableModeration = disableExport || !enableApplyingPremium;

    return (
        <Container maxWidth="sm">
            <Grid>
                <Grid.Column>
                    <Switch
                        checked={enableCommittingExport}
                        name="enableCommittingExport"
                        label="Не премиальные тарифы списываются"
                        onChange={handleCheckbox}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        checked={enableCommittingPremium}
                        name="enableCommittingPremium"
                        label="Премиум-тарифы списываются"
                        onChange={handleCheckbox}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        checked={enableCommittingExclusives}
                        name="enableCommittingExclusives"
                        label="Эксклюзивы списываются"
                        onChange={handleCheckbox}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        checked={enableApplyingPremium}
                        name="enableApplyingPremium"
                        label="Возможность включить Премиум-тарифы"
                        onChange={handleCheckbox}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <label>Лимит экспорта</label>
                </Grid.Column>
                <Grid.Column width={8}>
                    <PriceInput
                        label="Максимальный долг за экспорт"
                        name="maxEnableDebt"
                        value={maxEnableDebt}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid.Column>
                {!enableModeration && (
                    <Grid.Column>
                        <InfoMessage header="Модерация">
                            Чтобы включить модерацию, отключите доступ к изменению "экспорта" или "Возможность включить Премиум-тарифы"
                        </InfoMessage>
                    </Grid.Column>
                )}
                <Grid.Column width={12}>
                    <Switch
                        checked={chiefIsModerator}
                        name="chiefIsModerator"
                        label="Модерация через Руководителя отдела"
                        onChange={handleCheckbox}
                        disabled={!enableModeration}
                    />
                </Grid.Column>
                <Grid.Column width={4}>Модератор</Grid.Column>
                <Grid.Column width={8}>
                    <UserDropdown
                        disabled={chiefIsModerator || !enableModeration}
                        onChange={handleChangeUserId}
                        user_id={export_moderator_id}
                        upward
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        checked={includedInStatistics}
                        name="includedInStatistics"
                        label="Учитывается в общей таблице статистики"
                        onChange={handleCheckbox}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default HandlerEditingBlockWrapper(accessStore, observer(AccessExportEditing));
