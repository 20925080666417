import React, { useState } from 'react';
import authStore from '~/stores/authStore';

import Modal, { ModalContent } from '~ui/Modal';
import TabsMenu, { TabPanel, TabType } from '~/components/Base/TabsMenu';

import PasswordChangeTab from './PasswordChangeTab';
import TelegramConnectTab from './TelegramConnectTab';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TelegramIcon from '@material-ui/icons/Telegram';

type UserSettingsModalProps = {
    onClose: (event: React.SyntheticEvent) => void;
};

const CHANGE_PASSWORD_TAB = 1;
const TELEGRAM_TAB = 2;

const UserSettingsModal = ({ onClose }: UserSettingsModalProps) => {
    const [tabName, setTabName] = useState<number>(CHANGE_PASSWORD_TAB);

    const { loadingPassportChange } = authStore;

    const adsTabs: TabType<number>[] = [
        {
            label: 'Пароль',
            value: CHANGE_PASSWORD_TAB,
            icon: <VpnKeyIcon />
        },
        {
            label: 'Telegram',
            value: TELEGRAM_TAB,
            icon: <TelegramIcon />
        }
    ];

    return (
        <Modal onClose={onClose} header="Управление личными настройками CRM" maxWidth="sm" disableBackdropClick={loadingPassportChange}>
            <ModalContent>
                <TabsMenu value={tabName} tabs={adsTabs} handleChange={value => setTabName(value)}>
                    <TabPanel value={tabName} index={CHANGE_PASSWORD_TAB}>
                        <PasswordChangeTab />
                    </TabPanel>

                    <TabPanel value={tabName} index={TELEGRAM_TAB}>
                        <TelegramConnectTab />
                    </TabPanel>
                </TabsMenu>
            </ModalContent>
        </Modal>
    );
};

export default UserSettingsModal;
