import React, { Fragment } from 'react';
import { Card, Grid, Icon, Popup } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import Time from '../../../Base/Time';
import estateL10n from '~/L10n/estate.L10n';
import {
    Estate,
    ESTATE_PROPERTY_COMMERCE_ARR,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID
} from '~/types/estate.types';
import authStore from '~/stores/authStore';

import PriceHistoryPopup from './PriceHistoryPopup';

const PrintCadastral = React.memo(({ cadastralNumber }: { cadastralNumber: string }) => (
    <>
        {cadastralNumber}&nbsp;
        {cadastralNumber.match(/\d+\:\d+\:\d+\:\d+/) && (
            <a
                target="_blank"
                title="Смотреть информацию на сайте www.rosreestr.net"
                rel="noopener noreferrer"
                href={`https://rosreestr.net/kadastr/${cadastralNumber.replace(/:/g, '-')}`}
            >
                <Icon name="external" />
            </a>
        )}
    </>
));

const PriceBlockInfo = ({ estate, loadingItem, printMode }: { estate: Estate; loadingItem: boolean; printMode?: boolean }) => {
    const {
        type,
        price,
        currency,
        totalArea,
        createTime,
        updateTime,
        saleType,
        propertyType,
        cadastralNumber,
        lotCadastralNumber,
        flatNumber,
        priceHistory,

        leaseTermType,
        utilitiesIncluded,
        operationalCostsIncluded,
        bargainAllowed,
        bargainPrice,
        bargainConditions,
        prepayMonths,
        deposit,

        vatType,
        contractType,

        clientFee,
        agentFee,
        major_user_ids
    } = estate || {};

    const priceByMeter =
        ([ESTATE_PROPERTY_TYPE_FLAT, 2, 6].includes(propertyType) || propertyType === ESTATE_PROPERTY_TYPE_OFFICE) && Number(totalArea) > 0
            ? Math.round(price / Number(totalArea)).toLocaleString()
            : '';

    const priceLength = priceHistory && priceHistory.length;

    return (
        <Card.Content>
            <Card.Header>
                <ShadowFragment
                    shadow={loadingItem}
                    print={price && `${price.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(currency)}`}
                />
                <PriceHistoryPopup priceHistory={priceHistory} />
                {type === ESTATE_TYPE_RENT_ID && ` / в месяц ${!utilitiesIncluded ? '+ коммуналка' : 'с коммуналкой'}`}
                {type === ESTATE_TYPE_RENT_ID && operationalCostsIncluded && (
                    <div>
                        <b>операционные расходы включены</b>
                    </div>
                )}
                &nbsp;&nbsp;
                {bargainAllowed && (
                    <Popup
                        size="tiny"
                        trigger={<Icon name="legal" color="red" size="small" />}
                        content={
                            <Grid columns={2}>
                                <Grid.Column>Цена с торгом</Grid.Column>
                                <Grid.Column>{bargainPrice}</Grid.Column>
                                <Grid.Column>Условие</Grid.Column>
                                <Grid.Column>{bargainConditions}</Grid.Column>
                            </Grid>
                        }
                    />
                )}
            </Card.Header>
            {type === ESTATE_TYPE_SELL_ID && (
                <Card.Meta>{priceByMeter ? `${priceByMeter} ${estateL10n.CURRENCY_TYPES_FIND(currency)} за м²` : ''}</Card.Meta>
            )}
            {!printMode && type === ESTATE_TYPE_RENT_ID && (
                <Card.Meta>
                    {clientFee !== null && <span className="crm-Estate__fee">{clientFee}% - клиентская</span>}
                    {clientFee === null && <span className="crm-Estate__fee crm-Estate__fee_empty">Клиентская комиссия не задана</span>}
                    &nbsp;
                    {agentFee !== null && <span className="crm-Estate__fee">{agentFee}% - агентская</span>}
                    {agentFee === null && <span className="crm-Estate__fee crm-Estate__fee_empty">Aгентская комиссия не задана</span>}
                </Card.Meta>
            )}
            {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && vatType > 0 && (
                <Card.Description>
                    {estateL10n.VAT_TYPES_FIND(vatType)}
                    {contractType === 1 && (
                        <div>
                            <b>Переуступка права аренды</b>
                        </div>
                    )}
                </Card.Description>
            )}
            {type === ESTATE_TYPE_RENT_ID && (
                <Card.Meta>
                    {estateL10n.PREPAYMENT_TYPES_FIND(prepayMonths)}
                    <br />
                    {deposit || 0}
                    {estateL10n.CURRENCY_TYPES_FIND(currency)}
                    {ESTATE_PROPERTY_COMMERCE_ARR.includes(propertyType) ? ' обеспечительный платеж' : ' депозит'}
                </Card.Meta>
            )}
            <Card.Description>
                {propertyType < ESTATE_PROPERTY_TYPE_OFFICE && (
                    <Fragment>
                        <b>
                            {type === ESTATE_TYPE_SELL_ID
                                ? estateL10n.SALE_TYPES_FIND(saleType)
                                : estateL10n.LEASE_TYPES_FIND(leaseTermType)}
                        </b>
                        <br />
                    </Fragment>
                )}
                <br />

                {(flatNumber || cadastralNumber || lotCadastralNumber) && (
                    <Grid columns={1}>
                        <Grid.Row>
                            {authStore.canEdit(estateStore.moduleName, major_user_ids) && flatNumber && (
                                <Grid.Column>
                                    Квартира №<b>{flatNumber || '---'}</b>
                                </Grid.Column>
                            )}
                            {cadastralNumber && (
                                <Fragment>
                                    <Grid.Column>
                                        {propertyType !== ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION ? 'Кадастровый номер' : 'Номер ДДУ'}
                                        &nbsp;
                                        <b>указан</b>
                                    </Grid.Column>
                                </Fragment>
                            )}
                            {lotCadastralNumber && (
                                <Fragment>
                                    <Grid.Column>
                                        <b>Кадастровый номер участка</b>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <PrintCadastral cadastralNumber={lotCadastralNumber} />
                                    </Grid.Column>
                                </Fragment>
                            )}
                        </Grid.Row>
                    </Grid>
                )}

                {!printMode && (
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>Создано</Grid.Column>
                            <Grid.Column>
                                <ShadowFragment shadow={loadingItem} print={<Time time={createTime} />} />
                            </Grid.Column>
                            {updateTime !== createTime && (
                                <Fragment>
                                    <Grid.Column>Обновлено</Grid.Column>
                                    <Grid.Column>
                                        <Time time={updateTime} />
                                    </Grid.Column>
                                </Fragment>
                            )}
                        </Grid.Row>
                    </Grid>
                )}
            </Card.Description>
        </Card.Content>
    );
};

export default React.memo(PriceBlockInfo);
