import fetchApi from '../common/fetchApi';
import { CRMChange } from '~/stores/commonStore';
import { objectToGraphql } from '~/common/graphql';

export const fetchCRMChanges = async (): Promise<CRMChange[]> => {
    const graphql = `{
        fetchCRMChanges {
            releaseTime
            title
        } 
      }`;

    const data: { fetchCRMChanges: CRMChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchCRMChanges;
};

export const sendUiError = async (error: Error, errorInfo: React.ErrorInfo, component: string): Promise<number> => {
    const uiError = {
        url: document.location.href,
        errorInfo: errorInfo.componentStack.toString(),
        error: error.message.toString(),
        component,
        stack: error.stack.toString()
    };

    const graphql = `mutation { 
          sendUiError(uiError: ${objectToGraphql(uiError)})
        }`;

    const data: { sendUiError: number } = await fetchApi.postGQ(graphql);
    return data.sendUiError;
};
