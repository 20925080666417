import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Menu, Form } from 'semantic-ui-react';

import showingStore from '~/stores/showingStore';
import ListFilterWrapper from '../ListFilterWrapper';
import ItemDropdown from '../../Items/Common/ItemDropdow';
import estateStore from '~/stores/estateStore';
import { ListFilterWrapperProps } from '../ListFilterWrapper';

@observer
class FilterShowings extends Component<ListFilterWrapperProps> {
    render() {
        const {
            listFilter: { estate_id }
        } = showingStore;

        return (
            <Menu.Item className="crm-List__filter_dropdown">
                <Form size="mini">
                    <ItemDropdown
                        name="estate_id"
                        store={estateStore}
                        item_id={estate_id}
                        onChange={this.props.handleChange}
                        placeholder="Выбрать объект"
                        noResultsMessage="Объект не найден"
                    />
                </Form>
            </Menu.Item>
        );
    }
}

export default ListFilterWrapper(showingStore, FilterShowings);
