import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Label, List, Image } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import authStore from '~/stores/authStore';
import { Link } from 'react-router-dom';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import exportStore from '~/stores/export/exportStore';
import ListError from '../../../ListError';
import UsersLinkList from '../../../Lists/Users/UsersLinkList';
import { ESTATE_TYPE_SELL } from '~/types/estate.types';
import { DealLinkState } from '../../../Lists/Deals/DealLink';
import ExpiredCallMessage from '../SubBlocks/ExpiredCallMessage';
import config from '../../../../config/ui.config';
import InfoPopup from '../../../Base/InfoPopup';
import PrintExportExternalStatus from '../EditBlocks/SubBlocks/PrintExportExternalStatus';
import { ExportedEstate, ExternalStatusInBase } from '~/types/exports.types';
import EditButton from '../../../Base/EditButton';
import history from '../../../../history';
import Skeleton from '@material-ui/lab/Skeleton';
import deepCopy from '~/common/deepCopy';

type ExportBlockShowProps = EditingWrappedProps & {
    extendMode?: boolean;
    item_id: number;
};

export const openEstateExportEditionState = (estate_id: number, switchFromTab: boolean) => ({
    pathname: `/estate/sell/crm/${estate_id}/export`,
    state: { modal: true, switchFromTab, editing: true }
});

@observer
class ExportBlockShow extends Component<ExportBlockShowProps> {
    constructor(props: ExportBlockShowProps) {
        super(props);
        exportStore.fetchItem(props.item_id);
    }

    render() {
        const { item_id, extendMode } = this.props;
        const estate = estateStore.getItem(item_id).item;
        if (!estate) {
            return null;
        }
        let { exclusiveDealId, dealId, type, propertyType, major_user_ids } = estate;

        const {
            item,
            property: { exportingBaseNames },
            errors,
            loadingItem
        } = exportStore.getItem(item_id);

        let bases: ExportedEstate[] = [];
        let externalStatuses: ExternalStatusInBase[] = [];
        let totalCost = 0;
        let major_users = null;

        if (item) {
            bases = item.bases;
            externalStatuses = item.externalStatuses;
            totalCost = item.totalCost;
            major_users = item.major_users;
            major_user_ids = [...major_user_ids, ...item.major_user_ids];
        }

        const exportingBases = exportStore.filterTariffs(type, propertyType, estate);

        const canEdit = authStore.canEdit(exportStore.moduleName, major_user_ids);

        return (
            <Card fluid color={bases && bases.length === 0 ? 'red' : 'yellow'} className={`crm-Item crm-FluentButtons`}>
                {canEdit && !extendMode && <EditButton onClick={() => history.replace(openEstateExportEditionState(item_id, true))} />}

                <Card.Content>
                    <ExpiredCallMessage item_id={item_id} />
                    {errors && <ListError errors={errors} />}

                    <Card.Header>
                        Экспорт объекта&nbsp;
                        {Number(exclusiveDealId) > 0 && (
                            <Label
                                title="Открыть договор"
                                color="green"
                                as={Link}
                                to={DealLinkState(Number(exclusiveDealId), ESTATE_TYPE_SELL)}
                            >
                                эксклюзив
                            </Label>
                        )}
                        {!exclusiveDealId && Number(dealId) > 0 && (
                            <Label title="Открыть договор" color="red" as={Link} to={DealLinkState(Number(dealId), ESTATE_TYPE_SELL)}>
                                сделка №{dealId}
                            </Label>
                        )}
                    </Card.Header>
                    {!loadingItem && (
                        <Card.Meta>
                            Потрачено
                            <InfoPopup
                                size="tiny"
                                content="Расход на рекламу этого объекта всеми агентами, кто брал его в работу, за все время.
                                Если вы взяли объект в работу, то расход других агентов на вас не переводится."
                            />{' '}
                            {totalCost || 0}₽
                        </Card.Meta>
                    )}

                    <Card.Description>
                        {!extendMode && exportingBaseNames && exportingBaseNames.join(', ')}
                        {extendMode && (
                            <List>
                                {bases.map(({ export_base_id, title, price_id }) => {
                                    const foundBase = exportingBases.find(base => base.export_base_id === export_base_id);
                                    if (!foundBase) return null;

                                    const { name, image, tariff, regionName } = foundBase;
                                    const price = tariff.prices.find(price => price.price_id === price_id);

                                    const foundStatus = externalStatuses.find(
                                        externalStatus => externalStatus.export_base_id === export_base_id
                                    );
                                    const externalStatus = foundStatus ? foundStatus.externalStatus : null;

                                    return (
                                        <List.Item key={export_base_id}>
                                            <Image avatar src={image ? `${config.publicUrl}${image}` : null} />
                                            <List.Content style={{ lineHeight: '1.5em' }}>
                                                <List.Description>
                                                    <b>{name}</b>
                                                    {externalStatus && (
                                                        <PrintExportExternalStatus
                                                            export_base_id={export_base_id}
                                                            externalStatus={externalStatus}
                                                        />
                                                    )}
                                                </List.Description>
                                                <List.Description>
                                                    {price && price.name}{' '}
                                                    {regionName && (
                                                        <span>
                                                            ({regionName}{' '}
                                                            <InfoPopup content="Тариф согласно указанному региону" size="tiny" />)
                                                        </span>
                                                    )}
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        )}
                        {!loadingItem && bases && bases.length === 0 && <b>Не экспортируется</b>}
                        {loadingItem && (
                            <Fragment>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Fragment>
                        )}
                    </Card.Description>
                </Card.Content>
                {major_users && major_users.length > 0 && (
                    <Card.Content extra>
                        <UsersLinkList avatar usersList={major_users} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default ExportBlockShow;
