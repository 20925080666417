import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import userStore from '~/stores/userStore';

import Select, { SelectMenuType } from '~ui/Select';

type GroupsDropdownProps = {
    onChange: (id: number | null) => void;
    group_id: number;
};

@observer
class GroupsDropdown extends Component<GroupsDropdownProps> {
    setGroupId = (event: React.SyntheticEvent, { value }: { value: number | null }) => {
        this.props.onChange(value);
    };

    render() {
        const { groups } = userStore;
        const { group_id } = this.props;

        const groupsOptions = groups?.reduce((result: SelectMenuType[], { group_id, name, subgroups }) => {
            result.push({
                key: group_id,
                value: group_id,
                text: name,
                style: { fontWeight: 700 }
            });

            if (subgroups?.length) {
                result.push(
                    ...subgroups.map(({ group_id, name, chief }) => ({
                        key: group_id,
                        value: group_id,
                        text: `${name}${chief ? ` (${chief.firstName} ${chief.lastName})` : ''}`
                    }))
                );
            }

            return result;
        }, []);

        return (
            <Select clearable label="Офис / Отдел" value={group_id} onChange={this.setGroupId} name="" options={groupsOptions} fullWidth />
        );
    }
}

export default GroupsDropdown;
