import { ESTATE_BASES, EstateFilterMain, EstateLinkType } from './estate.types';
import { ContactLinkWithPhonesType } from './contacts.types';
import { UserLinkType } from './users.types';

export const DELETING_CAUSE_IS_ALREADY_BUY = 1;
export const DELETING_CAUSE_IS_REALTOR = 2;
export const DELETING_CAUSE_THE_PHONE_IS_WRONG = 3;

export type WishLinkType = {
    readonly wish_id: number;
    type: number;
    enable: boolean;
};

export type WishInBaseType = WishLinkType & {
    town: string | null;
    cityDistrict: string | null;
    description: string;
    contact_id: number | null;
    mortgageApproved: boolean | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type WishTableType = EstateFilterMain &
    WishInBaseType & {
        major_user_ids: number[];
        major_users: UserLinkType[];
        isBooked: WishBookUsers | null;
        bookedUser: UserLinkType | null;
        lastCallTime: number | null;
        callsCount: number | null;
        showingsCount: number | null;
    };

export type Wish = WishTableType & {
    contact: ContactLinkWithPhonesType;
    interestedEstates: InterestedEstate[];
};

export type WishBookUsers = {
    wish_book_id: number;
    wish_id: number;
    major_user_id: number;
    notificationHasCalled: boolean;
    bookTime: number;
    expireTime: number;
};

export type InterestedEstate = EstateLinkType & { startTime: number };

export type WishFilter = EstateFilterMain & {
    group_id: number[] | null;
    major_user_id: number[] | null;
    enable: boolean;
    deltaTime: number | null;
    descriptionContains: string | null;
    townContains: string | null;
    noOutgoingToOwnerCallsTime: number | null;
    base: ESTATE_BASES;
};
