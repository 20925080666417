import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';
import { Newbuilding } from '~/types/newbuildings.types';
import * as newbuildingsApi from '~/api/newbuildingsApi';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import Divider from '~ui/Divider';
import Button from '~ui/Button';
import { useToggle } from '~ui/Modal';

import LCFeedNewbuildingFind from './LCFeedNewbuildingFind';
import LifeComplexLink from '~/components/Items/LifeComplex/LifeComplexLink';

const LCFeedNewbuildingBlock = ({ developer_feed_id, domclick_lc_id }: { developer_feed_id: number; domclick_lc_id: number }) => {
    const { buildings, ...restInfo } = developerFeedStore.getDomclickComplex(developer_feed_id, domclick_lc_id);
    const { address, newbuilding_id } = restInfo;

    const [newbuilding, setNewbuilding] = useState<Newbuilding | null>(null);
    const [loadingNewbuilding, setLoadingNewbuilding] = useState<boolean>(false);
    const [showEditing, toggleEditing] = useToggle();

    useEffect(() => {
        if (newbuilding_id) {
            setLoadingNewbuilding(true);
            newbuildingsApi
                .fetchItem(newbuilding_id)
                .then(setNewbuilding)
                .finally(() => setLoadingNewbuilding(false));
        }
    }, [newbuilding_id]);

    return (
        <Paper elevation={2} style={{ marginBottom: '2rem', padding: '1rem' }}>
            <Typography variant="caption">Адрес</Typography>
            <Typography>{address}</Typography>
            <Divider />

            {loadingNewbuilding && <Skeleton variant="rect" height={30} width={'100%'} />}
            {((!loadingNewbuilding && !newbuilding) || showEditing) && (
                <Box style={{ gap: '1rem', alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                    <LCFeedNewbuildingFind developer_feed_id={developer_feed_id} domclick_lc_id={domclick_lc_id} />
                </Box>
            )}
            {!showEditing && !loadingNewbuilding && newbuilding && (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <LifeComplexLink newbuilding={newbuilding} target="_blank" />
                    <Button onClick={toggleEditing}>Изменить</Button>
                </Box>
            )}
        </Paper>
    );
};

export default observer(LCFeedNewbuildingBlock);
