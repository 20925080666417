const townsMoCian101 = [
    'Истра',
    'Красногорск',
    'Видное',
    'Люберцы',
    'Мытищи',
    'Одинцово',
    'Пушкино',
    'Щелково',
    'Химки',
    'Реутов',
    'Королев',
    'Балашиха',
    'Дзержинский',
    'Долгопрудный',
    'Домодедово',
    'Железнодорожный',
    'Котельники',
    'Лобня',
    'Лыткарино',
    'Подольск'
];

const districtsMoCian101 = [
    'Истринский',
    'Красногорский',
    'Ленинский',
    'Люберецкий',
    'Мытищинский',
    'Одинцовский',
    'Пушкинский',
    'Щелковский'
];

const exportRegionId = (export_base_id, regions, address) => {
    if (export_base_id === 1 && ~address.region.indexOf('Московская')) {
        let region_id = 102;
        let regionName = 'Дальняя МО';
        if (townsMoCian101.includes(address.town)) {
            region_id = 101;
            regionName = 'Ближняя МО';
        } else if (districtsMoCian101.some(district => ~(address.district || '').indexOf(district))) {
            region_id = 101;
            regionName = 'Ближняя МО';
        }
        return { region_id, regionName };
    } else {
        const region = regions.find(({ title }) => ~address.region.indexOf(title));
        if (region) {
            return { region_id: region.region_id, regionName: `${region.title}` };
        }
    }
    return { region_id: 0, regionName: '' };
};

module.exports = exportRegionId;
