import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import commonStore from '~/stores/commonStore';
import developerFeedStore from '~/stores/lifeComplexes/developerFeedStore';

import ApartmentIcon from '@material-ui/icons/Apartment';

import { Modal, ModalContent } from '~ui/Modal';

import LCFeedNewbuildingAbout from './LCFeedNewbuildingAbout';
import LCFeedNewbuildingBuilding from './LCFeedNewbuildingBuilding';

const LCFeedNewbuildingModal = ({
    onClose,
    developer_feed_id,
    domclick_lc_id
}: {
    onClose: () => void;
    developer_feed_id: number;
    domclick_lc_id: number;
}) => {
    const { developerFeedInfo, loadingHouses } = developerFeedStore.getItem(developer_feed_id).property;
    const lifeComplex = developerFeedInfo.lifeComplexes.find(lc => lc.domclick_lc_id === domclick_lc_id);
    const { buildings, title } = lifeComplex;

    useEffect(() => {
        developerFeedStore.fetchNewbuildingHouses(developer_feed_id, domclick_lc_id);
    }, [lifeComplex.newbuilding_id]);

    return (
        <Modal
            maxWidth="md"
            onClose={onClose}
            fullScreen={commonStore.isMobile}
            // loading={loadingItem || saving}
            header={
                <>
                    <ApartmentIcon /> &nbsp; ЖК «{title}»
                </>
            }
        >
            <ModalContent>
                <LCFeedNewbuildingAbout developer_feed_id={developer_feed_id} domclick_lc_id={domclick_lc_id} />
                {buildings.map(({ domclick_building_id }) => (
                    <LCFeedNewbuildingBuilding
                        key={domclick_building_id}
                        developer_feed_id={developer_feed_id}
                        domclick_lc_id={domclick_lc_id}
                        domclick_building_id={domclick_building_id}
                    />
                ))}
            </ModalContent>
        </Modal>
    );
};

export default observer(LCFeedNewbuildingModal);
