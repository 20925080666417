import React, { Fragment, Component } from 'react';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import contactStore from '~/stores/contactStore';
import { ContactWithPhonesList } from '../../../Base/ClientShowBlock';

type WishTableContactsProps = {
    contact_id: number;
};

type WishTableContactsState = {
    contactHasOpened: boolean;
};

@observer
class WishTableContacts extends Component<WishTableContactsProps, WishTableContactsState> {
    state = {
        contactHasOpened: false
    };

    handleOpenPhone = () => {
        contactStore.fetchItem(this.props.contact_id);
        this.setState({ contactHasOpened: true });
    };

    render() {
        const { contact_id } = this.props;
        const { contactHasOpened } = this.state;
        if (!contactHasOpened) {
            return (
                <div style={{ margin: '3px 0 0 -7px' }}>
                    <Button icon="phone" size="mini" basic title="Показать контакт" onClick={this.handleOpenPhone} />
                </div>
            );
        }

        const { item: contact, loadingItem } = contactStore.getItem(contact_id);
        return <ContactWithPhonesList contact={loadingItem ? null : contact} />;
    }
}

export default WishTableContacts;
