import React, { useState } from 'react';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import KpiFiledEditing from './KpiFiledEditing';
import accessStore from '~/stores/accessStore';

const KpiItemEditing = ({ kpi_id }: { kpi_id: number }) => {
    const {
        editingItem: { title, access_ids },
        loadingItem
    } = kpiStore.getItem(kpi_id);

    const [editing, setEditing] = useState(false);
    const handleEditing = () => {
        setEditing(!editing);
    };

    if (editing) {
        return <KpiFiledEditing kpi_id={kpi_id} onClose={handleEditing} />;
    }

    const { accessModesDropdown } = accessStore;

    return (
        <ListItem>
            {(loadingItem || !title) && <Skeleton variant="text" width={200} />}
            {!editing && !loadingItem && (
                <>
                    <ListItemText
                        primary={<Typography variant="h6">{title}</Typography>}
                        secondary={
                            access_ids &&
                            accessModesDropdown
                                .filter(({ value }) => access_ids.includes(Number(value)))
                                .map(({ text }) => text)
                                .join(', ')
                        }
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title="Редактировать kpi">
                            <IconButton edge="end" onClick={handleEditing}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </>
            )}
        </ListItem>
    );
};

export default observer(KpiItemEditing);
